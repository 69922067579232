import { DATE_FORMAT, convertTableDateFormat } from '../util/CommonUtils'

export function ToCollectionCreateRequest(userId) {
  return {
    created_by: userId,
  }
}
export function ToCollectionUdateRequest(updateRequest) {
  const {
    name = '',
    marketingPid = '',
    notes = '',
    locationId: [{ location_id = '' }],
  } = updateRequest
  return {
    name: name,
    marketing_pid: marketingPid,
    notes: notes,
    location_id: location_id,
    active_f: true,
  }
}

export function collectionResponseToCollections(collections = []) {
  return collections.map((collection) =>
    collectionResponseToCollection(collection)
  )
}

export function collectionResponseToCollection(collectionResponse) {
  if (collectionResponse !== null) {
    const {
      collection_id = '',
      project_id = '',
      name: collectionName = '',
      marketing_pid = '',
      notes = '',
      location = {},
      created_by = '',
      created_by_id = '',
      sample_count = '',
      created_ts = '',
    } = collectionResponse
    return {
      id: collection_id || project_id,
      name: collectionName,
      marketingPid: marketing_pid,
      notes: notes,
      locationName: location !== null ? location.building : null,
      locationId: location !== null ? location.location_id : null,
      createdBy: created_by,
      createdById: created_by_id,
      sampleCount: sample_count !== null ? sample_count : '',
      createDate: convertTableDateFormat(created_ts, DATE_FORMAT),
    }
  }
}

export function collectionNamesResponseToSelect(response) {
  let returnArray = []
  const {
    data: { collection_names = [] },
  } = response
  collection_names.map((collectionName = '') =>
    returnArray.push({
      value: collectionName,
      label: collectionName,
    })
  )

  return returnArray
}

export function collectionNamesResponse(response) {
  let returnArray = []
  const {
    data: { collections = [] },
  } = response

  collections.map(({ collection_id, name }) =>
    returnArray.push({
      value: collection_id,
      label: name,
    })
  )
  return returnArray
}
