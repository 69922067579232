import * as types from './actionType'
import * as inventoryApi from '../../services/inventoryService'
import * as reportsAPi from '../../services/externalReportsService'
import {
  samplesResponseToSamples,
  locationResponseToLocationId,
  filtersToFiltersRequest,
  filtersResponseToFilters,
  brandNamesResponseToSelect,
  propsToPropsRequest,
  scenicToScenicRequests,
} from '../../mappers/inventoryMapper'
import { PROPS, SCENIC } from 'enums/Tabs'
import * as itemApi from '../../services/itemService'
import * as shipmentApi from '../../services/shipmentService'
import * as shootShipmentApi from '../../services/shootShipmentService'
import * as barcodeApi from '../../services/barcodeService'
import * as shipmentMapper from '../../mappers/shipmentMapper'
import * as itemMapper from '../../mappers/itemMapper'
import * as userAPI from '../../services/userService'
import * as ordersApi from '../../services/ordersService'
import * as orderMapper from '../../mappers/OrderMapper'
import * as barcodeMapper from '../../mappers/barcodeMapper'
import { fetchFilteredLocations, getBuildings, getLLBuildingByLocation } from '../../services/locationService'
import { showNotification } from '../../store/notification/actionCreator'
import { download } from '../../util/DownloadCsv'
import {
  editableFieldsMapper,
  getKeyByValue,
  DATE_FORMAT,
  convertTableDateFormat,
  getSubCategories,
  getPegasusID,
} from '../../util/CommonUtils'
import { isEmpty, first } from 'lodash'
import moment from 'moment'
import { SAMPLE, SAMPLES } from '../../enums/Tabs'
import { setAddPropState } from 'store/addProp/actionCreator'
import { locationResponseToSelect } from 'mappers/locationMapper'

export const updateSampleFields =
  (payload, callback = () => {}) =>
  async (dispatch, getState) => {
    const { sample_id = '' } = payload
    const promise = inventoryApi.updateSampleFields(payload)
    promise
      .then((response = {}) => {
        dispatch(updateSampleFieldsSuccess(response, sample_id, callback))
      })
      .catch((error) => {
        dispatch(
          showNotification(true, 'We encountered an error while updating')
        )
      })
  }

const updateSampleFieldsSuccess = (
  response,
  sample_id,
  callback = () => {}
) => {
  const { data: responseData = {} } = response
  const editableFieldsMapperObj = editableFieldsMapper()
  const finalData = {}
  // Inside response data fields like vendor notes, disposition notes/date can change
  Object.keys(responseData?.sample_data).map((obj) => {
    const objKey = getKeyByValue(editableFieldsMapperObj, obj)
    if (!isEmpty(objKey)) {
      finalData[objKey] = responseData?.sample_data[obj]
    }
    return obj
  })
  return (dispatch) => {
    // dispatch(updateSampleFieldsData({ data: finalData, sample_id }))
    dispatch(callback({ data: finalData, sample_id }))
  }
}

export function updateSampleFieldsData(data = {}) {
  return {
    type: types.CALL_EDIT_FIELDS_API,
    payload: data,
  }
}

export function clearSearchData() {
  return {
    type: types.SEARCH_INVENTORY_CLEAR_DATA,
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_SEARCH_INVENTORY_PAGE_POPUP,
  }
}

export function setPropsMetadata(data = []) {
  return {
    type: types.SET_PROPS_METADATA,
    payload: data,
  }
}

export function setScenicMetadata(data = []) {
  return {
    type: types.SET_SCENIC_METADATA,
    payload: data,
  }
}
export function scenicMetadataFailure() {
  return {
    type: types.SET_SCENIC_METADATA_FAILED,
  }
}
export function propsMetadataFailure() {
  return {
    type: types.SET_PROPS_METADATA_FAILED,
  }
}

export function setPropsMetadataSubcat(data = []) {
  return {
    type: types.SET_PROPS_METADATA_SUBCAT,
    payload: {
      data: data,
    },
  }
}
export function propsMetadataSubcatFail() {
  return {
    type: types.SET_PROPS_METADATA_SUBCAT_FAILED,
  }
}

export function setAllBrands(data = []) {
  return {
    type: types.SET_ALL_BRANDS,
    payload: { data },
  }
}
export function setAllBrandsFailure() {
  return {
    type: types.SET_ALL_BRANDS_FAILED,
  }
}

export function updateHistoryData(data = []) {
  return {
    type: types.CALL_HISTORY_API,
    payload: data,
  }
}
export function historyDataFailure() {
  return {
    type: types.CALL_HISTORY_API_FAILED,
  }
}
export function updateHistoryFilters(data = []) {
  return {
    type: types.CALL_HISTORY_FILTERS_API,
    payload: data,
  }
}
export function historyFiltersFailure() {
  return {
    type: types.CALL_HISTORY_FILTERS_API_FAILED,
  }
}

export const getLastShipDate =
  (sampleData = {}, callback = () => {}) =>
  async (dispatch, getState) => {
    const { sampleId = '' } = sampleData
    let shootShipmentMap = new Map()
    shootShipmentApi
      .fetchShootShipmentByPegId(sampleId)
      .then((shipments = {}) => {
        const { data: responseData = [] } = shipments
        let lastShipDate = ''
        if (!isEmpty(responseData)) {
          responseData.find(({ shootShipment }) => {
            const { shipDate = '' } = shootShipment
            if (!isEmpty(shipDate)) {
              lastShipDate = shipDate
              return true
            }
            return null
          })
        }

        shootShipmentMap.set(
          sampleId,
          !isEmpty(lastShipDate) ? moment(lastShipDate).format('L') : ''
        )
      })
      .catch(() => shootShipmentMap.set(sampleId, []))
      .finally(() => {
        const finalSampleData = Object.assign({}, sampleData, {
          lastShipDate: convertTableDateFormat(
            shootShipmentMap.get(sampleId),
            DATE_FORMAT
          ),
        })
        callback(finalSampleData)
      })
  }

export const setColumnSettings =
  (payload = [], lanId = '', callBack = () => {}, type = SAMPLE) =>
  async (dispatch) => {
    const columnType = type === SAMPLES ? SAMPLE : type
    dispatch(setColumnSettingsPending(true))
    const promise = inventoryApi.saveColumnSettings(payload, lanId, columnType)
    promise
      .then((data = {}) => {
        dispatch(setColumnSettingsPending(false))
        dispatch(
          showNotification(true, 'Columns saved successfully', 'success')
        )
        callBack()
      })
      .catch((error) => {
        dispatch(setColumnSettingsPending(false))
        dispatch(
          showNotification(
            true,
            'We encountered an error while saving the columns'
          )
        )
      })
  }

export const getColumnSettings =
  (type = SAMPLE) =>
  async (dispatch) => {
    const columnType = type === SAMPLES ? SAMPLE : type
    return inventoryApi
      .getColumnSettings(columnType)
      .then((response = {}) => {
        const { data: responseData = {} } = response
        const { fields: fetchedColumns = [] } = responseData
        dispatch(updateColumnSettings(fetchedColumns))
      })
      .catch((error) => {
        dispatch(updateColumnSettings([]))
      })
  }

export function updateColumnSettings(data = {}) {
  return {
    type: types.CALL_SEARCH_FIELDS,
    payload: { searchedColumns: data },
  }
}

export function resetIsCallSearchFields() {
  return {
    type: types.RESET_IS_CALL_SEARCH_FIELDS,
  }
}

export const getDropdownData =
  (selectedTab = PROPS) =>
  async (dispatch, getState) => {
    const promise =
      selectedTab !== SCENIC
        ? inventoryApi.fetchPropsMetadata()
        : inventoryApi.fetchScenicMetadata()
    return promise
      .then((response = {}) => {
        dispatch(setPropsMetadata(response))
      })
      .catch((error) => {
        dispatch(propsMetadataFailure())
      })
  }

export const setSubcatsByCatIds =
  (categoryIds = []) =>
  async (dispatch, getState) => {
    const {
      searchInventory: { originalPropsMetadata = {} },
    } = getState() || {}
    const { categories = [] } = originalPropsMetadata
    const selectedCategories = categories?.filter(
      (obj) => categoryIds.indexOf(obj.category_id) !== -1
    )
    const frmtCategories = getSubCategories(selectedCategories)
    dispatch(setPropsMetadataSubcat(frmtCategories))
  }

export const getAllBrands = () => async (dispatch, getState) => {
  const promise = inventoryApi.getAllBrands()
  return promise
    .then((response = {}) => {
      const allBrands = brandNamesResponseToSelect(response)
      dispatch(setAllBrands(allBrands))
    })
    .catch((error) => {
      console.log(error)
      dispatch(setAllBrandsFailure())
    })
}

export const getHistoryData =
  (payload = {}) =>
  async (dispatch, getState) => {
    const promise = inventoryApi.fetchHistoryData(payload)
    promise
      .then((data = {}) => {
        dispatch(updateHistoryData(data))
      })
      .catch((error) => {
        dispatch(historyDataFailure())
      })
  }

export function updateSamples(
  updateData = [],
  userId,
  params,
  successCallBack = () => {}
) {
  return (dispatch) => {
    dispatch(updateSamplesRequestPending(true))
    ordersApi
      .updateOrder(orderMapper.samplesToSearchUpdateRequest(updateData))
      .then((res) => {
        successCallBack()
        dispatch(updateSamplesRequestPending(false))
        dispatch(
          showNotification(true, 'Samples saved successfully', 'success')
        )
        dispatch(getData(params))
      })
      .catch(() => {
        dispatch(updateSamplesRequestFailure())
        dispatch(updateSamplesRequestPending(false))
        dispatch(
          showNotification(
            true,
            'We encountered an error while updating the samples'
          )
        )
      })
      .finally(() => {
        dispatch(updateSamplesRequestPending(false))
      })
  }
}

export function updateSamplesRequestFailure(failure = false) {
  return {
    type: types.SEARCH_INVENTORY_UPDATE_SAMPLES_REQUEST_FAILURE,
  }
}

export function updateSamplesRequestPending(pending = false) {
  return {
    type: types.SEARCH_INVENTORY_SAMPLES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export const getHistoryFilters =
  (payload = {}) =>
  async (dispatch, getState) => {
    const promise = inventoryApi.fetchHistoryFilters(payload)
    promise
      .then((data = {}) => {
        dispatch(updateHistoryFilters(data))
      })
      .catch((error) => {
        dispatch(historyFiltersFailure())
      })
  }

/**
 * Items api related actions. Use the exported wrapper function to abstract
 * away usage of redux for cases where we are dealing with an API lifecycle
 */
export function getData(
  params,
  successCallback = () => {},
  failCallback = () => {},
  sample_type = SAMPLES
) {
  return (dispatch, getState = () => {}) => {
    const currentState = getState() || {}
    const {
      searchInventory: { totalRowsCount: currentTotalRows = Number(0) } = {},
    } = currentState
    const { requestParams = {} } = params
    const currentPage = requestParams.get('page')
    if (currentPage > 1 && Number(currentTotalRows) > Number(100)) {
      params?.requestParams?.append('total', currentTotalRows)
    }
    let totalRowsCount
    let filterNFList = {}
    dispatch(fetchDataRequestPending(true))
    return inventoryApi
      .fetchInventorySamples(params, sample_type)
      .then((orderResult) => {
        const {
          data: { total_count = 0, filter_list_notfound = {} },
        } = orderResult
        totalRowsCount = total_count
        filterNFList = Object.assign({}, filter_list_notfound)
        return samplesResponseToSamples(orderResult)
      })
      .then((samples = []) => {
        return shipmentApi
          .fetchShipments(
            samples
              .map((it) => it.shipmentId)
              .filter((shipmentId) => shipmentId)
          )
          .then((shipmentResult) =>
            shipmentMapper.shipmentsResponseToShipments(
              shipmentResult.data.shipments
            )
          )
          .then((shipments) => {
            const listOfTcins = samples
              .map((it) => it.tcin)
              .filter((tcin) => !!tcin)
            const finalListOfTcins = Array.from(new Set([...listOfTcins]))
            let shipmentMap = new Map(
              shipments.map((shipment) => [shipment.shipmentId, shipment])
            )
            let orderResults = [].concat.apply(
              samples.map((order) =>
                Object.assign({}, order, shipmentMap.get(order.shipmentId))
              )
            )

            if (isEmpty(finalListOfTcins) || !finalListOfTcins[0]) {
              // Handling for props with no tcins
              dispatch(fetchDataSuccess(orderResults))
              dispatch(setTotalRowsCount(totalRowsCount))
              dispatch(setFilterNFList(filterNFList))
              dispatch(setFilterNFListProp(filterNFList))
              successCallback()
            } else {
              return itemApi
                .getItemsGraphQL(finalListOfTcins)
                .then((itemResults) =>
                  itemMapper.itemsResponseToItems(itemResults)
                )
                .then((items) => {
                  let itemMap = new Map(
                    items.map((item) => [item?.tcin?.toString(), item])
                  )
                  orderResults = [].concat.apply(
                    orderResults.map((order) =>
                      Object.assign(
                        {},
                        order,
                        itemMap.get(order?.tcin?.toString())
                      )
                    )
                  )
                  dispatch(fetchDataSuccess(orderResults))
                  dispatch(setTotalRowsCount(totalRowsCount))
                  dispatch(setFilterNFList(filterNFList))
                  successCallback()
                })
            }
          })
      })
      .catch((error) => {
        dispatch(fetchDataFailure())
        dispatch(
          showNotification(
            true,
            'We encountered an error while fetching the data'
          )
        )
        failCallback()
      })
      .finally(() => {
        dispatch(fetchDataRequestPending(false))
      })
  }
}
export function addScenic(params, callBack = () => {}) {
  delete (params || {})['propTcinImage']
  delete (params || {})['imageUrl']
  const formattedParams = scenicToScenicRequests(params)
  return (dispatch) => {
    dispatch(addAPropStatus('pending'))
    return inventoryApi
      .addAScenic(formattedParams)
      .then((response = {}) => samplesResponseToSamples(response))
      .then((samples = []) => {
        const samplesObj = first(samples) || {}
        dispatch(setAddPropData(samples))
        dispatch(addAPropStatus('success'))
        dispatch(setAddPropState('PROPCREATED'))
        callBack({
          callType: 'success',
          message: '',
          sampleId: samplesObj?.sampleId,
        })
      })
      .catch((error = {}) => {
        const { response: { data = '' } = {} } = error
        const message = data?.split('Error: ')?.pop()

        dispatch(setAddPropData())
        dispatch(addAPropStatus('failure'))
        dispatch(setAddPropState('NOPROPCREATED'))
        const errorNew = message
          ? message
          : 'We encountered an error while adding a prop'
        callBack({ callType: 'failure', message: errorNew })
        dispatch(showNotification(true, errorNew))
      })
  }
}

export function addAProp(params, callBack = () => {}) {
  delete (params || {})['propTcinImage']
  delete (params || {})['imageUrl']
  const formattedParams = propsToPropsRequest(params)
  return (dispatch) => {
    dispatch(addAPropStatus('pending'))
    return inventoryApi
      .addAProp(formattedParams)
      .then((response = {}) => samplesResponseToSamples(response))
      .then((samples = []) => {
        const samplesObj = first(samples) || {}
        dispatch(setAddPropData(samples))
        dispatch(addAPropStatus('success'))
        dispatch(setAddPropState('PROPCREATED'))
        callBack({
          callType: 'success',
          message: '',
          sampleId: samplesObj?.sampleId,
        })
      })
      .catch((error = {}) => {
        const { response: { data = '' } = {} } = error
        const message = data?.split('Error: ')?.pop()

        dispatch(setAddPropData())
        dispatch(addAPropStatus('failure'))
        dispatch(setAddPropState('NOPROPCREATED'))
        const errorNew = message
          ? message
          : 'We encountered an error while adding a prop'
        callBack({ callType: 'failure', message: errorNew })
        dispatch(showNotification(true, errorNew))
      })
  }
}

export function editProps(params, callBack = () => {}) {
  delete (params || {})['propTcinImage']
  delete (params || {})['imageUrl']
  const formattedParams = propsToPropsRequest(params, 'edit')
  return (dispatch) => {
    dispatch(addAPropStatus('pending'))
    return inventoryApi
      .editProp(formattedParams)
      .then((response = {}) => samplesResponseToSamples(response))
      .then((samples = []) => {
        const samplesObj = first(samples) || {}
        dispatch(setAddPropData(samples))
        dispatch(addAPropStatus('success'))
        dispatch(setAddPropState('PROPCREATED'))
        callBack({
          callType: 'success',
          message: '',
          sampleId: samplesObj?.sampleId,
        })
      })
      .catch((error = {}) => {
        const { response: { data = '' } = {} } = error
        const message = data?.split('Error: ')?.pop()

        dispatch(setAddPropData())
        dispatch(addAPropStatus('failure'))
        dispatch(setAddPropState('NOPROPCREATED'))
        const errorNew = message
          ? message
          : 'We encountered an error while adding a prop'
        callBack({ callType: 'failure', message: errorNew })
        dispatch(showNotification(true, errorNew))
      })
  }
}
export function editScenics(params, callBack = () => {}) {
  delete (params || {})['propTcinImage']
  delete (params || {})['imageUrl']
  const formattedParams = scenicToScenicRequests(params, 'edit')
  return (dispatch) => {
    dispatch(addAPropStatus('pending'))
    return inventoryApi
      .editScenic(formattedParams)
      .then((response = {}) => samplesResponseToSamples(response))
      .then((samples = []) => {
        const samplesObj = first(samples) || {}
        dispatch(setAddPropData(samples))
        dispatch(addAPropStatus('success'))
        dispatch(setAddPropState('PROPCREATED'))
        callBack({
          callType: 'success',
          message: '',
          sampleId: samplesObj?.sampleId,
        })
      })
      .catch((error = {}) => {
        const { response: { data = '' } = {} } = error
        const message = data?.split('Error: ')?.pop()

        dispatch(setAddPropData())
        dispatch(addAPropStatus('failure'))
        dispatch(setAddPropState('NOPROPCREATED'))
        const errorNew = message
          ? message
          : 'We encountered an error while adding a prop'
        callBack({ callType: 'failure', message: errorNew })
        dispatch(showNotification(true, errorNew))
      })
  }
}
export function setAddPropData(samples = []) {
  return {
    type: types.SET_ADD_PROP_DATA,
    payload: { samples: samples },
  }
}

export function fetchLocationDetails(params, successCallback = () => {}) {
  return (dispatch) => {
    return fetchFilteredLocations(params)
      .then((filteredLocations = {}) => {
        const {
          data: { locations: dataLocations = [] },
        } = filteredLocations
        return dataLocations.map((locations) => ({
          value: locations.location_id || '',
          label: locationResponseToLocationId(locations),
        }))
      })
      .then((locations = []) => {
        dispatch(fetchLocationSuccess(locations))
        successCallback()
      })
      .catch((error) => {})
  }
}

export function fetchLocationSuccess(location = []) {
  return {
    type: types.FETCH_LOCATION_SUCCESS,
    payload: { location: location },
  }
}

export function showPopup(item) {
  let itemCopy = Object.assign({}, item)
  if (itemCopy.userId) {
    return (dispatch) => {
      userAPI
        .getUser(itemCopy.userId)
        .then((user) => {
          itemCopy.userId = user.data.first_name + ' ' + user.data.last_name
          dispatch(displayPopup(itemCopy))
        })
        .catch(() => {
          dispatch(displayPopup(itemCopy))
        })
    }
  } else {
    return (dispatch) => {
      dispatch(displayPopup(itemCopy))
    }
  }
}

export function downloadSamples(params) {
  return (dispatch) => {
    inventoryApi
      .downloadInventorySamplesPost(params)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}
export function downloadLoading(data = false, downloadType='') {
  return {
    type: types.DOWNLOAD_LOADING,
    data: data,
    downloadType: downloadType
  }
}

export function getAllLocationList(){
  return (dispatch) =>{
    getBuildings().then((response)=>{
      let locationData = locationResponseToSelect(response)
      dispatch(setLocationFilterData(locationData))
    }).catch((error)=>{
      dispatch(
        showNotification(
          true,
          'We encountered an error while fetching location data'
        )
      )
    })
  }
}

export function setLocationFilterData(data = []) {
  return {
    type: types.LOCATION_FILTER_DATA,
    data: data,
  }
}

export function downloadContactSheet(
  params = {},
  downloadType = '',
  itemType = SAMPLE
) {
  return (dispatch) => {
    dispatch(downloadLoading(true, downloadType))
    inventoryApi
      .downloadContactSheetPost(params, downloadType, itemType)
      .then((response) => {
        download(response, dispatch, downloadType)
      })
      .catch((error) => {
        dispatch(downloadLoading(false, downloadType))
        const { response = {} } = error
        const { data = {}, status = '' } = response
        if (data.constructor.name === 'Blob' && status === 500) {
          data.text().then((csvText) => {
            dispatch(showNotification(true, csvText))
          })
        } else {  
          dispatch(
            showNotification(
              true,
              'We encountered an error while trying to download'
            )
          )
        }
      })
  }
}

export function downloadLaunchDateReport() {
  return (dispatch) => {
    reportsAPi
      .downloadLaunchDateReport()
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'Encountered an error while trying to download launch date report'
          )
        )
      })
  }
}

export function addAPropStatus(status) {
  return {
    type: types.ADD_A_PROP_API_STATUS,
    payload: { addAPropAPIStatus: status },
  }
}

export function fetchDataFailure() {
  return {
    type: types.FETCH_INVENTORY_DATA_FAILURE,
    payload: { fetchDataError: 'Failed to fetch data' },
  }
}

export function fetchDataRequestPending(pending = false) {
  return {
    type: types.FETCH_INVENTORY_DATA_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function setColumnSettingsPending(pending = false) {
  return {
    type: types.SET_COLUMN_SETTINGS_PENDING,
    payload: { pending: pending },
  }
}

export function fetchDataSuccess(data = []) {
  return {
    type: types.FETCH_INVENTORY_DATA_SUCCESS,
    payload: { data: [...data] },
  }
}

export function setCheckBox(selected) {
  return {
    type: types.SEARCH_INVENTORY_SET_CHECKBOX,
    payload: { selected: selected },
  }
}

export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.SEARCH_INVENTORY_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}

export function setInvalidData(data = []) {
  return {
    type: types.SEARCH_INVENTORY_SET_INVALID_DATA,
    payload: { data: data },
  }
}

export function displayPopup(item) {
  return {
    type: types.SHOW_SEARCH_INVENTORY_PAGE_POPUP,
    payload: { item: item },
  }
}

export function setFilters(filters) {
  return {
    type: types.SEARCH_INVENTORY_SET_FILTERS,
    payload: { data: filters },
  }
}
export function setPage(page = 1) {
  return {
    type: types.SEARCH_INVENTORY_SET_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage = 25) {
  return {
    type: types.SEARCH_INVENTORY_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function setTotalRowsCount(totalRowsCount = 99) {
  return {
    type: types.SEARCH_INVENTORY_SET_TOTAL_ROWS_COUNT,
    payload: { totalRowsCount: totalRowsCount },
  }
}

export function setFilterNFList(filterNFList = {}) {
  return {
    type: types.SEARCH_INVENTORY_SET_FILTER_NOTFOUND_LIST,
    payload: { filterNFList: filterNFList },
  }
}
export function setFilterNFListProp(filterNFList = {}) {
  return {
    type: types.SEARCH_INVENTORY_SET_FILTER_NOTFOUND_LIST_PROP,
    payload: { filterNFListProp: filterNFList },
  }
}

export function saveFilters(filterRequest) {
  return (dispatch) => {
    dispatch(saveFiltersPending(true))
    inventoryApi
      .saveFilters(filtersToFiltersRequest(filterRequest))
      .then((response) => {
        dispatch(saveFiltersSuccess(filtersResponseToFilters(response)))
        dispatch(saveFiltersPending(false))
      })
      .catch((err) => {
        dispatch(saveFiltersFailure())
        dispatch(saveFiltersPending(false))
      })
  }
}

export function saveFiltersPending(pending = false) {
  return {
    type: types.SEARCH_INVENTORY_SAVE_FILTERS_PENDING,
    payload: { pending: pending },
  }
}

export function saveFiltersSuccess(filters = {}) {
  return {
    type: types.SEARCH_INVENTORY_SAVE_FILTERS_SUCCESS,
    payload: { filters: filters },
  }
}

export function saveFiltersFailure() {
  return {
    type: types.SEARCH_INVENTORY_SAVE_FILTERS_FAILURE,
  }
}

export function getFilters() {
  return (dispatch) => {
    dispatch(getFiltersPending(true))
    inventoryApi
      .getFilters()
      .then((response) => {
        dispatch(getFiltersSuccess(filtersResponseToFilters(response)))
        dispatch(getFiltersPending(false))
      })
      .catch((err) => {
        dispatch(getFiltersFailure())
        dispatch(getFiltersPending(false))
      })
  }
}

export function getFiltersSuccess(filters = {}) {
  return {
    type: types.SEARCH_INVENTORY_GET_FILTERS_SUCCESS,
    payload: { availableFilters: filters },
  }
}

export function getFiltersPending(pending = false) {
  return {
    type: types.SEARCH_INVENTORY_GET_FILTERS_PENDING,
    payload: { pending: pending },
  }
}

export function getFiltersFailure() {
  return {
    type: types.SEARCH_INVENTORY_GET_FILTERS_FAILURE,
  }
}

export function duplicateSamplesAS(samples = [], userId = '') {
  const itemIdList = samples.map((obj) => obj.itemId) || []
  return (dispatch) => {
    dispatch(duplicateSamplesPending(true))
    return itemApi
      .getItemsGraphQL(itemIdList)
      .then((itemResponse) => itemMapper.itemsResponseToItems(itemResponse))
      .then((items) => {
        let itemMap = new Map(items.map((item) => [item.tcin.toString(), item]))
        return userAPI.getUser(userId).then((userInfo) => {
          let finalRequest = samples.map((obj) =>
            Object.assign({}, obj, {
              location: userInfo.data.default_location,
              shipTo: userInfo.data.default_location,
            })
          )
          return ordersApi
            .duplicateSamplesAS({
              samples: orderMapper.samplesToSampleCreateRequests(finalRequest),
            })
            .then((res) => orderMapper.samplesResponseToSamples(res))
            .then((sampleResponse) => {
              return sampleResponse.map((s) =>
                Object.assign({}, s, itemMap.get(s.tcin.toString()), {
                  ...s,
                  generalDescription:
                    items.find((sam) => sam.tcin === s.tcin.toString())
                      .description || '',
                })
              )
            })
            .then((result) => {
              dispatch(duplicateSamplesPending(false))
              dispatch(duplicateSamplesSuccess(result))
              return result
            })
            .catch((error) => {
              try {
                if (error.response.data.errors[0].field === 'brandId') {
                  dispatch(
                    showNotification(
                      true,
                      "Couldn't duplicate this sample because it has no primary brand"
                    )
                  )
                } else if (error.response.data.errors[0].field === 'vendorId') {
                  dispatch(
                    showNotification(
                      true,
                      "Couldn't duplicate this sample because it has no primary vendor"
                    )
                  )
                }
              } catch (e) {
                dispatch(
                  showNotification(true, "Couldn't duplicate this sample")
                )
              }
              dispatch(duplicateSamplesFailure())
              dispatch(duplicateSamplesPending(false))
            })
        })
      })
  }
}

export function duplicateSamples(sample = {}) {
  return (dispatch) => {
    dispatch(duplicateSamplesPending(true))
    return itemApi
      .getItemsGraphQL([sample.itemId])
      .then((itemResponse) => itemMapper.itemsResponseToItems(itemResponse))
      .then((items) => {
        let itemMap = new Map(items.map((item) => [item.tcin.toString(), item]))
        let orderResults = Object.assign(
          {},
          sample,
          itemMap.values().next().value
        )
        return userAPI.getUser(sample.userId).then((userInfo) => {
          let finalRequest = Object.assign({}, orderResults, {
            ...orderResults,
            location: userInfo.data.default_location,
            shipTo: userInfo.data.default_location,
          })
          return ordersApi
            .duplicateSamples(
              orderMapper.sampleToSampleCreateRequest(finalRequest)
            )
            .then((res) => orderMapper.samplesResponseToSamples(res))
            .then((sampleResponse) => {
              return sampleResponse.map((s) =>
                Object.assign({}, s, itemMap.get(s.tcin.toString()), {
                  ...s,
                  generalDescription:
                    items.find((sam) => sam.tcin === s.tcin.toString())
                      .description || '',
                })
              )
            })
            .then((result) => {
              dispatch(duplicateSamplesPending(false))
              return result
            })
            .catch((error) => {
              try {
                if (error.response.data.errors[0].field === 'brandId') {
                  dispatch(
                    showNotification(
                      true,
                      "Couldn't duplicate this sample because it has no primary brand"
                    )
                  )
                } else if (error.response.data.errors[0].field === 'vendorId') {
                  dispatch(
                    showNotification(
                      true,
                      "Couldn't duplicate this sample because it has no primary vendor"
                    )
                  )
                }
              } catch (e) {
                dispatch(
                  showNotification(true, "Couldn't duplicate this sample")
                )
              }
              dispatch(duplicateSamplesFailure())
              dispatch(duplicateSamplesPending(false))
            })
        })
      })
  }
}
export function reverseSampleStatus(pegasusIds = [], selectedTab) {
  return (dispatch) => {
    return inventoryApi
      .reverseSampleStatus(
        pegasusIds.map((id) => getPegasusID(id)),
        selectedTab
      )
      .then((sampleResult) =>
        dispatch(showNotification(true, 'Sample Status Reversed', 'success'))
      )
      .catch(() => {
        dispatch(
          showNotification(
            true,
            "Couldn't reverse the status at this time.  Please try again."
          )
        )
      })
  }
}
export function getDuplicateBarcode(samples = [], type = 'MULTILINE') {
  return (dispatch) => {
    return barcodeApi
      .getBarcode(barcodeMapper.samplesToBarcodeRequest(samples), type)
      .then((barcodeResult) => barcodeResult.data)
      .then((barcodePdf) => {
        return URL.createObjectURL(
          new Blob([barcodePdf], { type: 'application/pdf' })
        )
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            "Couldn't retrieve barcodes at this time.  Please try again."
          )
        )
      })
  }
}

export function duplicateSamplesPending(pending = false) {
  return {
    type: types.SEARCH_INVENTORY_DUPLICATE_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export function duplicateSamplesSuccess(data = []) {
  return {
    type: types.SEARCH_INVENTORY_DUPLICATE_SAMPLES_SUCCESS,
    payload: { duplicateSamples: data },
  }
}

export function duplicateSamplesFailure() {
  return {
    type: types.SEARCH_INVENTORY_DUPLICATE_SAMPLES_FAILURE,
  }
}

export function emptySelectedData() {
  return {
    type: types.EMPTY_SELECTED_DATA,
  }
}

export function autoRefreshSearch(autoRefreshData = false) {
  return {
    type: types.AUTO_REFRESH_SEARCH,
    payload: { autoRefreshData: autoRefreshData },
  }
}

// Cancel sample requests
export function cancelOrderedSample(selectedData) {
  return (dispatch) => {
    dispatch(cancelOrderedSampleRequestPending(true))
    return inventoryApi
      .cancelOrderedSamples(
        selectedData.map((it) => it.pegasusId.slice(2, it.pegasusId.length))
      )
      .then((cancelResult) => {
        dispatch(cancelOrderedSampleRequestSuccess(selectedData))
        dispatch(closeAlert())
      })
      .catch(() => {
        dispatch(cancelOrderedSampleRequestFailure())
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to cancel samples'
          )
        )
      })
      .finally(() => {
        dispatch(cancelOrderedSampleRequestPending(false))
      })
  }
}

export const cancelOrderedSampleRequestPending = (pending) => {
  return {
    type: types.SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export const cancelOrderedSampleRequestSuccess = (selectedData) => {
  return {
    type: types.SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_SUCCESS,
    payload: { data: selectedData },
  }
}

export const cancelOrderedSampleRequestFailure = () => {
  return {
    type: types.SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_FAILURE,
  }
}

export function showAlert(selectedData = []) {
  const editableStatuses = ['SAMPLE_ORDERED', 'SAMPLE_SHIPPED']
  const nonEditableData = selectedData.filter(
    (item) => editableStatuses.indexOf(item.sample_status) === -1
  )
  const editableData = selectedData.filter(
    (item) => editableStatuses.indexOf(item.sample_status) !== -1
  )
  let message = ''
  let response = true
  let dialogTitle = ''

  //if any samples selected
  switch (true) {
    case !isEmpty(nonEditableData) && !isEmpty(editableData): {
      let nonEditableIds = nonEditableData
        .map((item) => item.pegasusId)
        .join(', ')
      message = `The following samples are having invalid sample status: ${nonEditableIds}.
       Only items with status SAMPLE_ORDERED can be cancelled.`
      message = !isEmpty(editableData)
        ? message +
          ` Are you sure you want to cancel sample/s ${editableData
            .map((item) => item.pegasusId)
            .join(', ')} ordered?`
        : message
      dialogTitle = 'Cancel Sample Orders?'
      break
    }
    //if nonEditableData selected
    case !isEmpty(nonEditableData): {
      let nonEditableIds = nonEditableData
        .map((item) => item.pegasusId)
        .join(', ')
      message = `The following samples are having invalid sample status: ${nonEditableIds}.
          Only items with status SAMPLE_ORDERED can be cancelled.`
      response = false
      dialogTitle = 'Invalid Sample Status'
      break
    }
    //if only ordered data selected
    case !isEmpty(editableData): {
      message = ` Are you sure you want to cancel sample/s ${editableData
        .map((item) => item.pegasusId)
        .join(', ')} ordered?`
      dialogTitle = 'Cancel Sample Orders?'
      break
    }
    default:
      break
  }
  return {
    type: types.SEARCH_INVENTORY_PAGE_ALERT,
    payload: { message, response, dialogTitle },
  }
}

export function closeAlert() {
  return {
    type: types.CLOSE_SEARCH_INVENTORY_PAGE_ALERT,
  }
}
