import * as types from './actionType'
import * as locationAPI from '../../services/locationService'
import * as orderMapper from '../../mappers/OrderMapper'
import * as scenicRoomMapper from '../../mappers/scenicRoomMapper'
import * as scenicRoomAPI from '../../services/scenicRoomService'
import { showNotification } from '../notification/actionCreator'
import { download } from '../../util/DownloadCsv'
import { SAMPLE, SCENIC } from 'enums/Tabs'
import { fetchInventorySamples } from 'services/inventoryService'
import { samplesResponseToSamples } from 'mappers/inventoryMapper'
import { isEmpty } from 'lodash'
import { isHavingPrefix } from 'util/CommonUtils'

export function setCheckBox(selectedData) {
  return {
    type: types.SCENIC_SET_CHECKBOX,
    payload: { selected: selectedData },
  }
}

export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.SCENIC_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}

// Validate arguments and remove invalid pegasus id's
export function validateArguments(args = []) {
  const scenicOrPegasusIds = []
  args = Array.from(new Set(args))
  const pegasusIds = []
  args.forEach((id) => {
    const isHavingScenicPrefix = isHavingPrefix(id)
    let pegasusId = id
    if (isHavingScenicPrefix) {
      pegasusId = id.slice(2, id.length)
      pegasusIds.push(pegasusId?.toString() || '')
    } else {
      scenicOrPegasusIds.push(id)
    }
  })
  return [pegasusIds, scenicOrPegasusIds]
}

export function createScenicRoom(scenicRoomDetails, callback = () => {}) {
  const scenicRoomRequestPayload =
    scenicRoomMapper.scenicRoomToScenicRoomUpdateRequest(scenicRoomDetails)

  return (dispatch) => {
    dispatch(createScenicRoomRequestPending(true))
    return scenicRoomAPI
      .createScenicRoom(scenicRoomRequestPayload)
      .then((scenicRoomResult) => {
        dispatch(
          showNotification(true, 'Scenic Room successfully created', 'success')
        )
        dispatch(
          createScenicRoomRequestSuccess(
            scenicRoomMapper.scenicRoomResponseToScenicRoom(
              scenicRoomResult.data
            )
          )
        )
        dispatch(getScenicRoom(scenicRoomResult.data?.room_id))
        callback('scenicRoom', 'create', scenicRoomResult.data)
      })
      .catch(() => {
        dispatch(createScenicRoomRequestFailure())
      })
      .finally(() => {
        dispatch(createScenicRoomRequestPending(false))
      })
  }
}

export function createScenicRoomRequestSuccess(data) {
  return {
    type: types.SCENIC_CREATE_REQUEST_SUCCESS,
    payload: { data: data },
  }
}

export function createScenicRoomRequestPending(pending = false) {
  return {
    type: types.SCENIC_CREATE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function createScenicRoomRequestFailure() {
  return {
    type: types.SCENIC_CREATE_REQUEST_FAILURE,
  }
}

export function getScenicRoom(scenicRoomId, page, rowsPerPage) {
  return (dispatch) => {
    if (!scenicRoomId) {
      return
    }
    dispatch(getScenicRoomRequestPending(true))
    dispatch(setSampleData())
    const requestPayload = {
      requestPayload: {
        include_filters: { scenic_room_ids: [Number(scenicRoomId)] },
        exclude_filters: {},
      },
    }

    try {
      scenicRoomAPI
        .getScenicRoom(scenicRoomId)
        .then((res) =>
          scenicRoomMapper.scenicRoomResponseToScenicRoom(res?.data)
        )
        .then((scenicRoomData) => {
          dispatch(getScenicRoomRequestSuccess(scenicRoomData))
        })

      fetchInventorySamples(requestPayload, SCENIC, page, rowsPerPage)
        .then(async (result) => {
          const mappedScenicData = await samplesResponseToSamples(result)
          const {total_count = 0, pages_count =0} = result?.data || {}
          dispatch(getScenicRoomItemsSuccess(mappedScenicData, total_count, pages_count))
          dispatch(getScenicRoomRequestPending(false))
        })
        .catch((e) => {
          dispatch(getScenicRoomRequestFailure())
        })
        .finally(() => {
          dispatch(getScenicRoomRequestPending(false))
        })
    } catch (err) {
      console.log('error', err)
    }
  }
}

export function getScenicRoomRequestSuccess(scenicRoomData) {
  return {
    type: types.SCENIC_GET_REQUEST_SUCCESS,
    payload: { scenicRoomData },
  }
}

export function getScenicRoomItemsSuccess(scenicRoomItemsData, total_count, pages_count) {
  return {
    type: types.SCENIC_ITEM_GET_REQUEST_SUCCESS,
    payload: { scenicRoomItemsData, total_count, pages_count },
  }
}

export function getScenicRoomRequestPending(pending = false) {
  return {
    type: types.SCENIC_GET_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function getScenicRoomRequestFailure() {
  return {
    type: types.SCENIC_GET_REQUEST_FAILURE,
  }
}

export function deleteScenicRoom(
  scenicRoomId,
  scenicRoomData,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(deleteScenicRoomRequestPending(true))
    return scenicRoomAPI
      .deleteScenicRoom(scenicRoomId, scenicRoomData)
      .then(() => {
        dispatch(deleteScenicRoomRequestSuccess())
        callback('scenicRooms', 'delete')
      })
      .catch(() => {
        dispatch(deleteScenicRoomRequestFailure())
        dispatch(showNotification(true, 'Unable to delete this Scenic Room'))
      })
      .finally(() => {
        dispatch(deleteScenicRoomRequestPending(false))
      })
  }
}

export const deleteScenicRoomRequestSuccess = () => {
  return {
    type: types.SCENIC_DELETE_REQUEST_SUCCESS,
    payload: { data: true },
  }
}

export function deleteScenicRoomRequestPending(pending = false) {
  return {
    type: types.SCENIC_DELETE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function deleteScenicRoomRequestFailure() {
  return {
    type: types.SCENIC_DELETE_REQUEST_FAILURE,
  }
}

export function downloadConflictSamples(
  pegasusIds = [],
  userId = '',
  scenicRoomId = '',
  shipFromLocation = ''
) {
  const [validatedPegasusIds] = validateArguments(pegasusIds)
  return (dispatch) => {
    if (validatedPegasusIds.length > 0) {
      return scenicRoomAPI
        .downloadConflictSamples(scenicRoomId, pegasusIds, shipFromLocation)
        .then((response) => {
          download(response)
        })
        .catch(() => {
          dispatch(
            showNotification(
              true,
              'We encountered an error while trying to download'
            )
          )
        })
    }
  }
}
export function downloadScenicRoomContactSheet(
  params = {},
  downloadType = '',
  itemType = SAMPLE
) {
  return (dispatch) => {
    return scenicRoomAPI
      .downloadScenicRoomContactSheetPost(params, downloadType, itemType)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export const getUnknownSamplesList = (
  validSamples = [],
  errorSampleIDs = [],
  validatedPegasusIds = [],
  scenicOrPegasusIds = []
) => {
  let sampleFound = []
  let samplesNotFound = []

  sampleFound = validSamples.map((it) => it?.pg_id.toString())
  validatedPegasusIds.forEach((id = '') => {
    if (
      errorSampleIDs.indexOf(id.toString()) === -1 &&
      sampleFound.indexOf(id.toString()) === -1 &&
      id.toString() !== '' &&
      scenicOrPegasusIds.indexOf(id.toString()) === -1
    ) {
      samplesNotFound.push(id)
    }
  })
  return samplesNotFound
}

export const callForUnknownSamples = (
  validSamples = [],
  errorSampleIDs = [],
  validatedPegasusIds = [],
  scenicOrPegasusIds = []
) => {
  return (dispatch) => {
    let samplesNotFound = []
    samplesNotFound =
      getUnknownSamplesList(
        validSamples,
        errorSampleIDs,
        validatedPegasusIds,
        scenicOrPegasusIds
      ) || []
    if (samplesNotFound.length > 0) {
      dispatch(setUnknownSamples(samplesNotFound))
    }
  }
}

export function setInvalidIds(invalidIds = []) {
  return {
    type: types.SCENIC_SET_INVALID_IDS,
    payload: [...invalidIds],
  }
}

export function addScenic(pegasusIds, scenicRoomId, checkRequired = true) {
  const [validatedPegasusIds, scenicOrPegasusIds] =
    validateArguments(pegasusIds)

  return (dispatch) => {
    let mergeData = []
    if (validatedPegasusIds.length > 0 || scenicOrPegasusIds.length > 0) {
      dispatch(addSamplesRequestPending(true))
      scenicRoomAPI
        .addScenicRoom(
          scenicRoomId,
          validatedPegasusIds,
          scenicOrPegasusIds,
          checkRequired
        )
        .then((sampleResult) => {
          const { status: currentHttpStatus = '' } = sampleResult
          let returnObj = {}
          if (currentHttpStatus === 207) {
            const { data: { result: response = {} } = {} } = sampleResult
            const {
              errors: errorSamples = [],
              scenicRoomItemsResponse: validSamples = [],
            } = response
            returnObj = {
              errorSamples: errorSamples || [],
              validSamples: validSamples || [],
            }
          } else {
            const { data: validSamples = [] } = sampleResult
            returnObj = {
              errorSamples: [],
              validSamples: validSamples || [],
            }
          }
          return returnObj
        })
        .then(({ validSamples = [], errorSamples = [] }) => {
          const errorSampleIDs = errorSamples.map((obj) => {
            const { pgId } = obj
            return pgId?.toString()
          })
          let invalidIds = []
          errorSamples.map((obj) => {
            const { invalidIds: invalidIdsLocal } = obj
            if (invalidIdsLocal) invalidIds.push(...invalidIdsLocal)
          })
          dispatch(
            callForUnknownSamples(
              validSamples,
              errorSampleIDs,
              validatedPegasusIds,
              scenicOrPegasusIds
            )
          )
          dispatch(setInvalidIds(invalidIds))
          if (validSamples.length > 0) {
            const updatedValidSamples =
              orderMapper.samplesResponseToSamplesNew(validSamples)
            // Response not complete and had to make another GET call for the Scenic room items
            dispatch(addSamplesRequestSuccess(updatedValidSamples, true))
          } else {
            // TODO: remove since this will be empty ?
            dispatch(addSamplesRequestSuccess(mergeData))
          }
          if (errorSamples.length > 0) {
            dispatch(unavailableScenicSuccess(errorSamples))
          } else if (validSamples.length > 0) {
            dispatch(
              showNotification(
                true,
                'Scenic Asset(s) added successfully',
                'success'
              )
            )
          }
          dispatch(addSamplesRequestPending(false))
        })
        .catch((err = {}) => {
          const { response: { status: responseStatus = '' } = {} } = err
          dispatch(addSamplesRequestPending(false))
          if (responseStatus === 404) {
            dispatch(setUnknownSamples(orderMapper.getUnknownSamples(err)))
          } else if (responseStatus === 400) {
            const { response = {} } = err
            const { data = {} } = response
            const { errors = {} } = data
            const { invalidIds = [] } = errors
            if (!isEmpty(invalidIds)) {
              dispatch(setInvalidIds(invalidIds))
            } else {
              dispatch(
                showNotification(
                  true,
                  'We encountered an error while trying to add scenic assets'
                )
              )
            }
          }
        })
    }
  }
}

export function addSamplesRequestSuccess(data = [], isFlyout = false) {
  return {
    type: types.ADD_SCENIC_REQUEST_SUCCESS,
    payload: { data: data, isFlyout },
  }
}

export function addSamplesRequestPending(pending = false) {
  return {
    type: types.ADD_SCENIC_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function addSamplesRequestFailure() {
  return {
    type: types.ADD_SCENIC_REQUEST_FAILURE,
  }
}

export function unavailableScenicSuccess(data = []) {
  return {
    type: types.UNAVAILABLE_SCENIC_SUCCESS,
    payload: { data: data },
  }
}

export function unavailableScenicPending(pending = false) {
  return {
    type: types.UNAVAILABLE_SCENIC_PENDING,
    payload: { pending: pending },
  }
}

export function unavailableScenicFailure() {
  return {
    type: types.UNAVAILABLE_SCENIC_FAILURE,
  }
}

export function closeErrorPopUp() {
  return {
    type: types.CLOSE_UNAVAILABLE_SCENIC_POPUP,
  }
}
export function setSampleData() {
  return {
    type: types.CLEAR_STATE_DATA,
  }
}

export function updateScenicRoom(
  scenicRoomId,
  updateDetails,
  callback = () => {}
) {
  return async (dispatch) => {
    dispatch(updateScenicRoomRequestPending(true))
    return scenicRoomAPI
      .updateScenicRoom(
        scenicRoomId,
        scenicRoomMapper.scenicRoomToScenicRoomUpdateRequest(updateDetails)
      )
      .then(async (scenicRoomResult) => {
        dispatch(updateScenicRoomRequestSuccess(scenicRoomResult))
        await dispatch(
          updateScenicRoomRequestSuccess(
            scenicRoomMapper.scenicRoomResponseToScenicRoom(
              scenicRoomResult.data
            )
          )
        )
        callback('scenicRoom', 'update', scenicRoomResult.data)
        dispatch(
          showNotification(true, 'Scenic Room successfully updated', 'success')
        )
        dispatch(setSampleData([]))
      })
      .catch((err) => {
        dispatch(updateScenicRoomRequestFailure())
        if (err.response.status === 400) {
          let errorMessages = orderMapper.getErrorMessages(err)
          dispatch(showNotification(true, errorMessages[0]))
        } else {
          dispatch(showNotification(true, 'Unable to save this Scenic Room'))
        }
      })
      .finally(() => {
        dispatch(updateScenicRoomRequestPending(false))
      })
  }
}

export function updateScenicRoomRequestSuccess(scenicRoomResult) {
  return {
    type: types.SCENIC_UPDATE_REQUEST_SUCCESS,
    payload: { data: scenicRoomResult },
  }
}

export function updateScenicRoomRequestPending(pending = false) {
  return {
    type: types.SCENIC_UPDATE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function updateScenicRoomRequestFailure() {
  return {
    type: types.SCENIC_UPDATE_REQUEST_FAILURE,
  }
}

export function removeScenic(selectedData, scenicRoomId) {
  return (dispatch) => {
    dispatch(removeScenicRequestPending(true))
    return scenicRoomAPI
      .removeScenic(selectedData, scenicRoomId)
      .then(() => {
        dispatch(removeScenicRequestSuccess(selectedData))
      })
      .catch(() => {
        dispatch(removeSampleRequestFailure())
      })
      .finally(() => {
        dispatch(removeScenicRequestPending(false))
      })
  }
}

export function removeScenicRequestSuccess(selectedData) {
  return {
    type: types.REMOVE_SAMPLE_REQUEST_SUCCESS,
    payload: { data: selectedData },
  }
}

export function removeScenicRequestPending(pending = false) {
  return {
    type: types.REMOVE_SAMPLE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function removeSampleRequestFailure() {
  return {
    type: types.REMOVE_SAMPLE_REQUEST_FAILURE,
  }
}

export function getShipFromLocation() {
  return (dispatch) => {
    dispatch(fetchShipFromLocationRequestPending(true))
    return locationAPI
      .getCheckInLocation()
      .then((buildingResult) => {
        dispatch(fetchShipFromLocationRequestSuccess(buildingResult))
      })
      .catch(() => {
        dispatch(fetchShipFromLocationRequesFailure())
      })
      .finally(() => {
        dispatch(fetchShipFromLocationRequestPending(false))
      })
  }
}

export function getShipToLocation() {
  return (dispatch) => {
    dispatch(fetchShipFromLocationRequestPending(true))
    return locationAPI
      .getBuildings()
      .then((buildingResult) => {
        dispatch(fetchShipToLocationRequestSuccess(buildingResult))
      })
      .catch(() => {
        dispatch(fetchShipFromLocationRequesFailure())
      })
      .finally(() => {
        dispatch(fetchShipFromLocationRequestPending(false))
      })
  }
}

export function updateBundleQuantity(scenicRoomId, pegasusId, updatedQuantity) {
  return {
    type: types.UPDATE_BUNDLED_QUANTITY,
    payload: { scenicRoomId, pegasusId, updatedQuantity },
  }
}

export const fetchShipFromLocationRequestSuccess = (buildingResult) => {
  return {
    type: types.FETCH_SHIP_FROM_LOCATION_REQUEST_SUCCESS,
    payload: { data: buildingResult },
  }
}

export const fetchShipToLocationRequestSuccess = (buildingResult) => {
  return {
    type: types.FETCH_SHIP_TO_LOCATION_REQUEST_SUCCESS,
    payload: { data: buildingResult },
  }
}

export function fetchShipFromLocationRequestPending(pending = false) {
  return {
    type: types.FETCH_SHIP_LOCATION_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchShipFromLocationRequesFailure() {
  return {
    type: types.FETCH_SHIP_LOCATION_REQUEST_FAILURE,
  }
}

export function setUnknownSamples(data = []) {
  return {
    type: types.SHOOT_SAMPLE_SET_UNKNOWN_DATA,
    payload: { data: data },
  }
}

export function setPage(page = 0) {
  return {
    type: types.SHOOT_SAMPLE_SET_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage = 20) {
  return {
    type: types.SHOOT_SAMPLE_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function clearSearchData() {
  return {
    type: types.SHOOT_SAMPLE_CLEAR_DATA,
  }
}

export function showAlert(message) {
  return {
    type: types.SHOW_SCENIC_PAGE_ALERT,
    payload: { message: message },
  }
}

export function closeAlert() {
  return {
    type: types.CLOSE_SCENIC_PAGE_ALERT,
  }
}

export function showPopup(item) {
  return {
    type: types.SHOW_SCENIC_POPUP,
    payload: { item: item },
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_SCENIC_POPUP,
  }
}

export function resetNewScenicRoomState() {
  return {
    type: types.RESET_NEW_SCENIC_ROOM_STATE,
  }
}

export function setRedirectToNewScenicRoom(goNextFlag) {
  return {
    type: types.SET_REDIRECT_TO_NEW_SCENIC_ROOM,
    payload: { goNextFlag: goNextFlag },
  }
}

export function updateSampleFieldsData(data = {}) {
  return {
    type: types.SCENIC_CALL_EDIT_FIELDS_API,
    payload: data,
  }
}
