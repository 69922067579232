export const NewShipmentStyles = (theme) => ({
  pageHeader: {
    width: '100%',
    marginTop: '30px',
    marginBottom: '15px',
    height: '40px',
    position: 'relative',
  },
  pageHeaderButton: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    top: 8,
    right: 80,
  },
  shipmentPageHeaderText: {
    fontSize: 'x-large',
  },
  leftColumn: {
    width: 'calc(72% - 14px)',
    float: 'left',
    marginRight: '27px',
  },
  rightColumn: {
    width: 'calc(28% - 14px)',
    float: 'right',
  },
  detailsHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  divider: {
    height: theme.spacing(3),
  },
  locationHeader: {
    fontSize: '12px',
  },
  newShipmentPage: {
    flexGrow: 1,
  },
  newShipmentPageIntro: {
    fontSize: 'xx-large',
    textAlign: 'center',
  },
  newShipmentPageLocation: {
    fontSize: 'x-large',
    textAlign: 'center',
    width: '100%',
    color: '#cc0000',
  },
  textField: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 'normal',
    fontSize: 16,
    marginBottom: '20px',
    width: '100%',
  },
  textFieldMarginLess: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 'normal',
    fontSize: 16,
    width: '100%',
  },
  notesTextField: {
    height: '80px !important',
  },
  shipmentLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    alignItems: 'center',
    color: 'white !important',
  },
  swipeableDrawerElementsContainer: {
    backgroundColor: '#366cd9',
    overflow: 'hidden',
  },
  SwipeableDrawerElements: {
    // fontSize: 'large',
    textAlign: 'left',
    overflow: 'hidden',
  },
  removeButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
    color: 'white !important',
    borderColor: 'white !important',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  marginRightSave: {
    marginRight: '24px',
  },
  deleteButton: {
    marginLeft: '24px',
    color: '#B85300',
  },
  errorColorClass: {
    color: '#B85300 !important',
  },
  errorColorClassNew: {
    borderColor: '#B85300 !important',
    '&:.MuiOutlinedInput-notchedOutline': {
      borderColor: '#B85300 !important',
    },
  },
  container: {
    position: 'relative',
  },
  input: {
    width: '100%',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    marginBottom: '20px',
    color: '#aaa !important',
  },
  inputFocused: {
    outline: 'none',
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '284px',
    padding: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    whiteSpace: 'pre-wrap',
  },
  shipFromLocConflict: {
    margin: '10px 0px',
    textAlign: 'center',
    fontSize: '24px',
  },
  conflictFonSize: {
    fontSize: '14px',
    paddingTop: '5px',
  },
  shipToLoc: {
    width: '100%',
    marginBottom: '20px',
  },
  SelectDropdown: {
    width: '100%',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
  filler: {
    flexGrow: '1',
  },
})
