//

// eslint-disable-next-line no-unused-vars
import * as React from 'react'

export function Header(props) {
  const { children, ...additionalProps } = props
  return <div {...additionalProps}>{children}</div>
}

Header.displayName = 'Header'

export default Header
