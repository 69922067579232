import * as types from './actionType'

export const initialState = (window.Cypress && window.initialState) || {
  historyData: [],
  historyFilters: {},
}

export default function history(state = initialState, action = {}) {
  switch (action.type) {
    case types.CALL_HISTORY_API:
      return Object.assign({}, state, {
        ...state,
        historyData: action.payload.data,
      })
    case types.CALL_HISTORY_API_FAILED:
      return Object.assign({}, state, {
        ...state,
        historyData: [],
      })
    case types.CALL_HISTORY_FILTERS_API:
      return Object.assign({}, state, {
        ...state,
        historyFilters: action.payload.data,
      })
    case types.CALL_HISTORY_FILTERS_API_FAILED:
      return Object.assign({}, state, {
        ...state,
        historyFilters: {},
      })
    default:
      return state
  }
}
