import * as types from './actionType'
import * as ordersAPI from '../../services/ordersService'
import * as OrderMapper from '../../mappers/OrderMapper'

export function raiseSamplesOrder(data = [], tabValue = '') {
  return (dispatch) => {
    dispatch(raiseSamplesOrderPending(true))
    return (
      tabValue === 'READYTOORDER'
        ? ordersAPI.autoOrderSamples(
            OrderMapper.samplesToSampleAutoOrderRequests(data)
          )
        : ordersAPI.orderSamples(
            OrderMapper.samplesToSampleCreateRequests(data)
          )
    )
      .then((res) => OrderMapper.samplesResponseToSamples(res))
      .then((samples) => {
        return samples.map((sample) => ({
          ...sample,
          generalDescription:
            data.find((s) => s.tcin === sample.tcin.toString()).description ||
            '',
        }))
      })
      .then((result) => {
        dispatch(raiseSamplesOrderSuccess(result))
      })
      .catch(() => {
        dispatch(raiseSamplesOrderFailure())
      })
      .finally(() => {
        dispatch(raiseSamplesOrderPending(false))
      })
  }
}

export function raiseSamplesOrderPending(pending = false) {
  return {
    type: types.RAISE_SAMPLES_ORDER_PENDING,
    payload: { pending: pending },
  }
}
export function raiseSamplesOrderSuccess(samples = []) {
  return {
    type: types.RAISE_SAMPLES_ORDER_SUCCESS,
    payload: { samples: samples },
  }
}
export function raiseSamplesOrderFailure() {
  return {
    type: types.RAISE_SAMPLES_ORDER_FAILURE,
  }
}

export function resetOrderSamplesState() {
  return {
    type: types.RESET_ORDER_SAMPLES_STATE,
  }
}
