export const SHIPMENT_BARCODE_SET_CARRIER = 'SHIPMENT_BARCODE_SET_CARRIER'
export const SHIPMENT_BARCODE_SET_DATA = 'SHIPMENT_BARCODE_SET_DATA'
export const SHIPMENT_BARCODE_SET_PAGE = 'SHIPMENT_BARCODE_SET_PAGE'
export const SHIPMENT_BARCODE_SET_ROWS_PER_PAGE =
  'SHIPMENT_BARCODE_SET_ROWS_PER_PAGE'
export const SHIPMENT_BARCODE_SET_TRACKING_NUMBER =
  'SHIPMENT_BARCODE_SET_TRACKING_NUMBER'
export const SHIPMENT_BARCODE_CLEAR_TRACKING_INFO =
  'SHIPMENT_BARCODE_CLEAR_TRACKING_INFO'
export const SHIPMENT_BARCODE_SET_VISIBILITY = 'SHIPMENT_BARCODE_SET_VISIBILITY'
export const SHIPMENT_BARCODE_SELECT_SORT = 'SHIPMENT_BARCODE_SELECT_SORT'
export const SHIPMENT_BARCODE_SUBMIT_ORDER = 'SHIPMENT_BARCODE_SUBMIT_ORDER'
export const SHIPMENT_BARCODE_REQUEST_FAILURE =
  'SHIPMENT_BARCODE_REQUEST_FAILURE'
export const SHIPMENT_BARCODE_REQUEST_PENDING =
  'SHIPMENT_BARCODE_REQUEST_PENDING'
export const SHIPMENT_BARCODE_REQUEST_SUCCESS =
  'SHIPMENT_BARCODE_REQUEST_SUCCESS'
export const SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_PENDING =
  'SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_PENDING'
export const SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_FAILURE =
  'SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_FAILURE'
export const SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_SUCCESS =
  'SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_SUCCESS'
export const SHIPMENT_BARCODE_SHOW_POPUP = 'SHIPMENT_BARCODE_SHOW_POPUP'
export const SHIPMENT_BARCODE_CLOSE_POPUP = 'SHIPMENT_BARCODE_CLOSE_POPUP'
