import ChipArray from '../../components/Chips/ChipArray'
import copy from 'copy-to-clipboard'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import Grid from '@material-ui/core/Grid'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import React from 'react'
import Search from '../../components/Search/Search'
import * as propTypes from 'prop-types'
import { showNotification } from '../../store/notification/actionCreator'
import { withStyles } from '@material-ui/core/styles'
import withHOCs from 'util/withHocs'
import { findIndex } from 'lodash'
import {
  getVendorEmailsById,
  setSampleData,
} from '../../store/order/actionCreator'
import {
  clearSearchData,
  closePopup,
  getData,
  setCheckBox,
  setCheckBoxHeaderChange,
  showPopup,
  resetSearchState,
} from '../../store/search/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import {
  makePopUpTable,
  reOrganizePopupItem,
  getTCINAsTitle,
} from '../../util/Table'
import {
  ITME_TAB_LABEL,
  itemTabDataKeyLabels,
  dataTabLabelsShipped,
} from '../../enums/tableTabs'

const styles = {
  searchPage: {
    flexGrow: 1,
  },
  searchPageIntro: {
    fontSize: 'xx-large',
    textAlign: 'center',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
}

const StyledSearch = withStyles((theme) => ({
  textField: {
    width: '100%',
  },
  card: {
    padding: theme.spacing(1),
    boxShadow: 'unset',
  },
  actionButton: {},
}))(Search)

class SearchPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checkAll: true,
      popupItem: {},
      popupIsVisible: false,
      selectedData: [],
    }
  }

  componentDidMount() {
    const { setHeaderTitleArray = () => {}, resetSearchState = () => {} } =
      this.props
    setHeaderTitleArray([{ title: 'Order Samples' }])
    resetSearchState()
  }

  onSelected = (selectedData, selected) => {
    const { tcin = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.tcin === tcin
      }) !== -1
    )
  }

  handleChipClick = (value) => {
    const { handleChipClick } = this.props
    copy(value)
    handleChipClick()
  }

  orderItems(index, data) {
    const { getVendorEmailsById, setSampleData } = this.props
    const { lanId } = this.props.auth.session.userInfo
    const newSelected = data.map((i) => ({
      ...i,
      dueDate: null,
      vendorEmail: '',
      emailVmm: null,
      quantity: 1,
      shipTo: null,
      studioNotes: '',
      vendorNotes: '',
      userId: lanId,
    }))

    setSampleData(newSelected)
    getVendorEmailsById(data.map((data) => data.vendorId))
  }

  disableButton = () => {
    return this.props.selectedData.length === 0
  }

  makeTabTables = () => {
    const { popupItem = {}, classes = {} } = this.props

    const newPopUpItem = reOrganizePopupItem(popupItem, classes)

    let newTable = new Map()
    newTable.set(
      ITME_TAB_LABEL,
      makePopUpTable(newPopUpItem, itemTabDataKeyLabels)
    )
    return newTable
  }

  render() {
    const {
      checkBoxEnabled,
      classes,
      clearSearchCallback,
      closePopup,
      columnData,
      data,
      fetchData,
      invalidData,
      numSelected,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      onClick,
      popupIsVisible,
      popupItem,
      selectedData,
      showPopup,
      clickableColumn = [],
    } = this.props

    let chipData = invalidData.map((data, index) => ({
      label: data.tcin,
      key: index,
    }))
    const dataFieldKeys = columnData.map((column) => column.id)

    return (
      <div className={classes.searchPage}>
        <Grid container>
          {/* <Grid item xs={12}>
            <p className={classes.searchPageIntro} style={styles.header}>
              Welcome to Pegasus
            </p>
          </Grid> */}
          <Grid item xs={12}>
            <StyledSearch
              label="Search"
              helperText="TCIN, DPCI, or UPC"
              buttonName="Search"
              buttonVariant="outlined"
              submitSearch={fetchData}
              clearSearchCallback={clearSearchCallback}
              fromSearch={true}
            />
          </Grid>
          <Grid item xs={12}>
            {invalidData.length > 0 && (
              <ChipArray
                className={classes.invalidTcins}
                data={chipData}
                enableCopyAllButton
                handleClick={this.handleChipClick}
                title="Invalid TCIN/DPCI/UPCs"
              />
            )}
          </Grid>
          {/* <Grid item xs={6}>
            <Link to="/OrderSamples">
              <Button
                color="primary"
                id="orderButton"
                disabled={this.disableButton()}
                onClick={(value) => this.orderItems(value, selectedData)}
                variant="contained"
                style={{ position: 'absolute', right: '40px', top: '300px' }}
              >
                ORDER
              </Button>
            </Link>
          </Grid> */}
          <Grid item xs={12}>
            <FlexibleTable
              cascadeFieldKeys={[]}
              checkBoxEnabled={checkBoxEnabled}
              columnData={columnData}
              clickableColumn={clickableColumn}
              data={data}
              dataFieldKeys={dataFieldKeys}
              numSelected={numSelected}
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
              onClick={onClick}
              onSelected={this.onSelected}
              selected={selectedData}
              showPopup={showPopup}
            />
          </Grid>
          <TabbedPopUp
            dataTabLabels={dataTabLabelsShipped}
            isVisible={popupIsVisible}
            data={this.makeTabTables()}
            onClose={closePopup}
            title={getTCINAsTitle(popupItem)}
            fullWidth={true}
          />
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    checkBoxEnabled: state.search.checkBoxEnabled,
    columnData: state.search.columnData,
    clickableColumn: state.search.clickableColumn,
    data: state.search.data,
    invalidData: state.search.invalidData,
    numSelected: state.search.numSelected,
    onCheckBoxChange: state.search.onCheckBoxChange,
    onCheckBoxHeaderChange: state.search.onCheckBoxHeaderChange,
    onClick: state.search.onClick,
    popupIsVisible: state.search.popupIsVisible,
    popupItem: state.search.item,
    selectedData: state.search.selectedData,
    showPopup: state.search.showPopup,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearSearchCallback: () => {
      dispatch(clearSearchData())
    },
    closePopup: () => {
      dispatch(closePopup())
    },
    fetchData: (tcins = [], dpcis = []) => {
      dispatch(getData(tcins, dpcis))
    },
    getVendorEmailsById: (ids = []) => dispatch(getVendorEmailsById(ids)),
    handleChipClick: () =>
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info')),
    onCheckBoxChange: (selected) => dispatch(setCheckBox(selected)),
    onCheckBoxHeaderChange: (selected) =>
      dispatch(setCheckBoxHeaderChange(selected)),
    setSampleData: (samples) => {
      dispatch(setSampleData(samples))
    },
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    resetSearchState: () => {
      dispatch(resetSearchState())
    },
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
  }
}

SearchPage.propTypes = {
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  data: propTypes.array,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onClick: propTypes.func,
}
export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(SearchPage)
