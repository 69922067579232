import React from 'react'
import { shape, func } from 'prop-types'
import NotFound from '../../praxis-dependencies/NotFound'

class NotFoundPage extends React.Component {
  static propTypes = {
    layoutActions: shape({
      setHeaderTitle: func,
    }),
  }

  render() {
    return (
      <div>
        <NotFound />
      </div>
    )
  }
}

export default NotFoundPage
