import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import idx from 'idx'
import { withAuth } from '@praxis/component-auth'
import { showNotification } from '../store/notification/actionCreator'
import apiConfig from '../apiConfig'
import Delayed from '../components/DelayedRender'

const TEXT_ERROR_DEFAULT =
  'Pegasus is experiencing a problem.<br /> Please try again.'

/*

Interceptor adds useful handlers to axios requests and responses.

If the user has an accessToken, it is used as the value for the
Authorization header. This applies to all requests to .target.com and
.tgt domains. You can disable this per request by passing in this config:
{headers: {Authorization: false}}

If an axios response is an error, it is handled based on the error code:
- 401: prompt the user to sign in
- 403: notify the user that they don't have permission to do that
- Other: show the axios error message to the user

When the component unmounts, the interceptors are removed.

*/
export class Interceptor extends React.Component {
  static state = {
    authInterceptor: undefined,
    errorInterceptor: undefined,
  }

  componentDidMount() {
    this.addAuthInterceptor()
    // this.addErrorInterceptor()
  }

  componentDidUpdate(prevProps, prevState) {
    const { lanId } = this.props
    // Error interceptor will be added before props are mapped leaving lanId = ''
    if (prevProps.lanId !== lanId) {
      // this.addErrorInterceptor()
    }
  }

  componentWillUnmount() {
    this.removeAuthInterceptor()
    this.removeErrorInterceptor()
  }

  addAuthInterceptor = () => {
    const authInterceptor = axios.interceptors.request.use(
      (config) => {
        const { auth, apiKey } = this.props
        const { session = {} } = auth
        const { userInfo = {} } = session || {}
        const { accessToken = '' } = userInfo
        // eslint-disable-next-line no-prototype-builtins
        if (!config.headers.hasOwnProperty('authorization')) {
          if (accessToken && this.isTargetDomain(config.url)) {
            config['headers']['Authorization'] = accessToken
          }
        } else if (!config.headers.Authorization) {
          delete config.headers.Authorization
        }
        // Fallback if config params are not defined
        if (!config.params) {
          config['params'] = new URLSearchParams()
        }
        // Fallback if apiKey is not defined
        if (!config.params?.key && apiKey) {
          const isUrlParam = !!config?.params?.entries
          if (isUrlParam) {
            config?.params?.append('key', apiKey)
          } else {
            config['params']['key'] = apiKey
          }
        }
        return config
      },
      (error) => Promise.reject(error)
    )
    this.setState({ authInterceptor })
  }

  removeAuthInterceptor = () => {
    axios.interceptors.request.eject(this.state.authInterceptor)
    this.setState({ authInterceptor: undefined })
  }

  addErrorInterceptor = () => {
    const {
      retryLimit = 2,
      apiForRetry = [],
      apiToIgnoreErrors = [],
      uploadRetryLimit = 5,
    } = apiConfig
    const errorInterceptor = axios.interceptors.response.use(
      (response = {}) => {
        return response
      },
      (error) => {
        if (error.response) {
          const requestUrl = idx(error, (_) => _.response.config.url)

          let apiExistsInRetryList =
            apiForRetry.some((obj = '') => {
              const retryAPIRegex = new RegExp('\\/' + obj + '(:?\\/|\\?)')
              return retryAPIRegex.test(requestUrl)
            }) || false

          let apiExistsIgnoreErrors =
            apiToIgnoreErrors.some((obj = '') => {
              const errorIgnoreRegex = new RegExp('\\/' + obj + '(:?\\/|\\?)')
              return errorIgnoreRegex.test(requestUrl)
            }) || false

          const code = error.response.status
          if (code === 401) {
            this.props.showNotification(true, '401', 'error')
          } else if (code === 403) {
            this.props.showNotification(true, '403 - No Access', 'error')
          } else if (
            code > 0 &&
            ((code !== 404 && code !== 400) || apiExistsIgnoreErrors)
          ) {
            let message = TEXT_ERROR_DEFAULT
            if (code === 500 || code === 501) {
              message = TEXT_ERROR_DEFAULT
            } else if (code === 409) {
              error.config.retryCount = retryLimit
              message = error.response.data.message
            } else if (error.message) {
              message = error.message
            }
            error.config.retryCount = error.config.retryCount || Number(0)
            if (
              apiExistsInRetryList &&
              (error.config.retryCount < retryLimit ||
                (apiExistsIgnoreErrors &&
                  error.config.retryCount < uploadRetryLimit))
            ) {
              error.config.retryCount++
              return axios.request(error.config)
            } else if (!apiExistsIgnoreErrors) {
              this.props.showNotification(true, message, 'error')
            }
          }
        }
        return Promise.reject(error)
      }
    )
    this.setState({ errorInterceptor })
  }

  removeErrorInterceptor = () => {
    axios.interceptors.request.eject(this.state.errorInterceptor)
    this.setState({ errorInterceptor: undefined })
  }

  // hostname ends with .target.com or .tgt
  isTargetDomain = (url) =>
    /^([^/]+:)?\/{2,3}[^/]+?(\.target\.com|\.tgt)(:|\/|$)/i.test(url)

  render() {
    const { auth, children } = this.props
    const { session = {} } = auth
    const { userInfo = {} } = session || {}
    const { accessToken = '' } = userInfo
    const waitTime = accessToken ? 100 : 2000

    return <Delayed waitBeforeShow={waitTime}>{children}</Delayed>
  }
}

Interceptor.propTypes = {
  accessToken: PropTypes.string,
  lanId: PropTypes.string,
  promptToSignIn: PropTypes.func,
  showNotification: PropTypes.func,
  promptToNoAccess: PropTypes.func,
  xApiKey: PropTypes.string,
  apiKey: PropTypes.string,
  xIdToken: PropTypes.string,
}

const mapStateToProps = (state) => {
  const { user = {} } = state
  const { lanId = '', xIdToken = '' } = user
  return {
    accessToken: user.accessToken,
    xApiKey: apiConfig.key,
    apiKey: apiConfig.apiKey,
    lanId: lanId,
    xIdToken: xIdToken,
  }
}

const mapDispatchToProps = {
  showNotification,
}

export default withAuth()(
  connect(mapStateToProps, mapDispatchToProps)(Interceptor)
)
