import React from 'react'
import withHOCs from 'util/withHocs'
import { isEmpty, isEqual, findKey } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CreatableSelect from '../ReactSelect/CreatableSelect'
import CheckBoxAutoComplete from '../../pages/SearchPage/CheckBoxAutoComplete' //need to convert into component
import { showNotification } from '../../store/notification/actionCreator'

// import ClearAllIcon from '@material-ui/icons/ClearAll'
// import Clear from '@material-ui/icons/Clear'

import { locationLevels, locationsLevelLabels } from '../../enums/locations'
import {
  resetSelectedValues,
  convertToDropdownOptions,
} from '../../util/locations'
import {
  findCurrentUserLocation,
  findSubLocationById,
  findSubLocationsByIdList,
  findSubLocationByIdSuccess,
  resetToInitState,
} from '../../store/locations/actionCreator'
import {
  getLocationsByLevels,
  getCurrentLocationId,
  getLevelsWithLabelsStr,
  getLevelsWithLabels,
  getCurrentBuilding,
  getLevelsToCreateLocation,
  getIsNewLocationCreated,
  getCreatedLocationData,
} from '../../store/locations/selectors'

const styles = (theme) => ({
  inputLabel: {
    transform: 'translate(0, 20px) scale(1)',
    '&.shrink': {
      transform: 'translate(0, -6px) scale(0.75)',
    },
  },
  inputLabelControl: {
    margin: 8,
    minWidth: 120,
    width: '165px',
  },
  inputLabelControlAuto: {
    margin: 8,
    minWidth: 120,
    width: 'auto',
  },
  inputLabelControlZero: {
    margin: 8,
    minWidth: 120,
    width: '165px',
  },
  barcodeInput: {
    minWidth: 120,
    width: '165px',
  },
  vl: {
    borderLeft: '2px solid grey',
    height: '35px',
  },
  greyClass: {
    color: 'grey',
    fontSize: '22px',
  },
  activeStepClass: {
    color: '#2684FF',
    fontSize: '22px',
  },
  greenClass: {
    color: 'green',
    fontSize: '20px',
  },
  greenClassIcon: {
    color: 'green',
    fontSize: '45px',
  },
  errorColor: {
    color: '#B85300',
  },
  paper: {
    width: '97%',
    marginTop: 20,
    // align: 'center',
    backgroundColor: '#F7F7F7',
  },
  locationDetailPaper: {
    width: '97%',
    marginTop: 20,
    marginBottom: 40,
    backgroundColor: '#F7F7F7',
  },
  clearAll: {
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  locationMultiDropdownsWrapper: {
    marginBottom: '10px',
    fontSize: '22px',
  },
})

const StyledCreatableSelect = withStyles((theme) => ({
  addFilter: {
    width: 'auto',
    boxShadow: 'unset',
    '&:hover': {
      boxShadow: 'unset',
    },
    borderBottom: '1px solid rgba(51, 51, 51, 0.5)',
    borderRadius: 'unset',
  },
  locationDetailPaper: {},
  paper: {},
  errorColor: {},
  greenClassIcon: {},
  greenClass: {},
  activeStepClass: {},
  vl: {},
  barcodeInput: {},
  inputLabelControlZero: {},
  inputLabelControlAuto: {},
  inputLabelControl: {},
  inputLabel: {},
  greyClass: {},
}))(CreatableSelect)

class LocationDropdowns extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dropdownValue: '',
      nextLevel: 'building',
      isNewOption: false,
      toggleAddContainer: false,
      selectedValueByLevel: {},
      clearLocations: false,
      hasError: false,
    }
    const { auth } = this.props
    const { session = {} } = auth
    const { userInfo = {} } = session || {}
    const { lanId = '' } = userInfo || {}
    this.currentUserId = lanId
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    this.props.showNotification(true, 'We encountered an error')
  }

  componentDidMount() {
    this.pageLoadRender()
  }
  pageLoadRender = () => {
    const { findCurrentUserLevel1Locs = () => {} } = this.props
    const { nextLevel = '' } = this.state
    findCurrentUserLevel1Locs(
      this.currentUserId,
      locationLevels[nextLevel],
      nextLevel,
      this.props.componentType
    )
    this.setState({ nextLevel: locationLevels[nextLevel] })
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      locationId = '',
      isNewLocationCreated,
      updateDropdownValue = () => {},
      handleDropDownTrigger,
      handleTriggerProps = {},
      multiLocationSelection,
    } = this.props
    const {
      locationId: prevLocationId = '',
      isNewLocationCreated: prevIsNewLocationCreated,
      handleDropDownTrigger: prevHandleDropDownTrigger,
    } = prevProps
    const {
      selectedValueByLevel: { container = {} } = {},
      toggleAddContainer,
    } = this.state
    const {
      selectedValueByLevel: { container: prevContainer = {} } = {},
      toggleAddContainer: prevToggleAddContainer,
    } = prevState
    if (!isEqual(prevLocationId, locationId)) {
      // console.log('enter did update location id')
      this.setState(
        {
          dropdownValue: locationId,
        },
        () => {
          updateDropdownValue(locationId)
        }
      )
    }
    if (isEmpty(container) && !isEqual(container, prevContainer)) {
      this.setState({
        toggleAddContainer: false,
      })
    }

    if (
      !!toggleAddContainer &&
      !isEqual(prevToggleAddContainer, toggleAddContainer)
    ) {
      if (!multiLocationSelection) {
        this.handleDropdownChange(
          {
            currentLevel: 'container',
            value: 'NEW',
            label: 'Container-NEW',
          },
          { action: 'create-option' }
        )
      }
    }

    if (
      !!handleDropDownTrigger &&
      !isEqual(prevHandleDropDownTrigger, handleDropDownTrigger)
    ) {
      if (multiLocationSelection) {
        this.handleAutoCompleteChange(
          handleTriggerProps,
          {},
          this.props.handleTriggerCallback()
        )
      } else {
        this.handleDropdownChange(
          handleTriggerProps,
          {},
          this.props.handleTriggerCallback()
        )
      }
    }

    if (
      !!isNewLocationCreated &&
      !isEqual(prevIsNewLocationCreated, isNewLocationCreated)
    ) {
      this.setState(
        {
          dropdownValue: '',
          nextLevel: 'building',
          isNewOption: false,
          toggleAddContainer: false,
          selectedValueByLevel: {},
        }
        // ,
        // () => {
        //   resetToInitState() //todo
        // }
      )
    }

    if (
      (!!this.props.resetLocations &&
        this.props.resetLocations !== prevProps.resetLocations) ||
      (!!this.state.clearLocations &&
        this.state.clearLocations !== prevState.clearLocations)
    ) {
      const { pageLoadRender = () => {} } = this
      this.setState(
        {
          dropdownValue: '',
          nextLevel: 'building',
          isNewOption: false,
          toggleAddContainer: false,
          selectedValueByLevel: {},
          clearLocations: false,
        },
        () => {
          pageLoadRender()
        }
      )
    }
  }

  handleDropdownChange = (event, actionMeta = {}, callBack = () => {}) => {
    const { selectedValueByLevel = {} } = this.state
    const {
      findSubLocationById = () => {},
      locationsByLevels = {},
      findSubLocationByIdSuccess = () => {},
      setIsNewOption = () => {},
      onDropdownChange = () => {},
      componentType: propsComponentType = '',
    } = this.props
    const { action: ddOptionCreateType = '' } = actionMeta

    const {
      currentLevel = '',
      value: currentOptionValue = '',
      label: currentOptionLabel = '',
    } = event
    const isNewOption = ddOptionCreateType === 'create-option' ? true : false
    let locationsListCopy = ''

    const { nextLevel = '' } = this.state
    const fetchLevel =
      Object.keys(locationsByLevels).indexOf(currentLevel) !== -1
        ? currentLevel
        : nextLevel

    if (!isNewOption) {
      findSubLocationById(
        event,
        currentOptionValue,
        locationLevels[fetchLevel],
        fetchLevel,
        currentOptionLabel,
        propsComponentType,
        callBack
      )
    } else {
      if (fetchLevel === 'container' || propsComponentType === 'new') {
        locationsListCopy = [{ location_id: 'NEW', container: 'Container-NEW' }]
      } else {
        locationsListCopy = [
          { location_id: currentOptionValue, [fetchLevel]: currentOptionLabel },
        ]
      }
      findSubLocationByIdSuccess(
        event,
        locationsListCopy,
        locationLevels[fetchLevel] || fetchLevel,
        currentOptionValue,
        fetchLevel,
        currentOptionLabel,
        propsComponentType
      )
    }
    this.setState(
      {
        dropdownValue: currentOptionValue,
        nextLevel: locationLevels[currentLevel] || currentLevel,
        isNewOption: isNewOption,
        selectedValueByLevel: resetSelectedValues(
          fetchLevel,
          event,
          selectedValueByLevel
        ),
      },
      () => {
        setIsNewOption(isNewOption)
        onDropdownChange(String(currentOptionValue || ''))
      }
    )
  }

  handleAutoCompleteChange =
    (label) =>
    (
      event,
      changedData = [],
      reason = '',
      actionMeta = {},
      callBack = () => {}
    ) => {
      const { selectedValueByLevel = {} } = this.state
      const {
        findSubLocationsByIdList = () => {},
        locationsByLevels = {},
        onDropdownChange = () => {},
        componentType: propsComponentType = '',
      } = this.props

      let currentOptionValues = changedData.map((data) => data.value)
      const currentOptionLabels = changedData.map((data) => data.label)
      const currentLevel = Object.keys(locationsLevelLabels).find(
        (key) => locationsLevelLabels[key] === label
      )

      const { nextLevel = '' } = this.state
      const fetchLevel =
        Object.keys(locationsByLevels).indexOf(currentLevel) !== -1
          ? currentLevel
          : nextLevel

      findSubLocationsByIdList(
        changedData,
        currentOptionValues,
        locationLevels[fetchLevel],
        fetchLevel,
        currentOptionLabels,
        propsComponentType,
        callBack
      )
      this.setState(
        {
          dropdownValue: currentOptionValues,
          nextLevel: locationLevels[currentLevel] || currentLevel,
          isNewOption: false,
          selectedValueByLevel: resetSelectedValues(
            fetchLevel,
            changedData,
            selectedValueByLevel
          ),
        },
        () => {
          onDropdownChange(String(currentOptionValues?.join() || ''))
        }
      )
    }

  toggleAddContainerFun = () => {
    const { toggleAddContainer: stateToggleAddContainer } = this.state
    this.setState({
      toggleAddContainer: !stateToggleAddContainer,
    })
  }

  renderDropdownNew = () => {
    const index = 0
    const {
      classes = {},
      locationsByLevels = {
        aisle: [],
        section: [],
        shelf: [],
        container: [],
      },
    } = this.props
    const {
      dropdownValue = '',
      toggleAddContainer,
      selectedValueByLevel = {},
      isNewOption,
    } = this.state
    const selectedOptionValue = ''
    const containerPrevLevel = findKey(locationLevels, (v) => v === 'container')
    const disableAddContainer =
      Object.entries(selectedValueByLevel[containerPrevLevel] || {}).length > 0
    return Object.keys(locationsByLevels).map((level) => {
      const currentLevelLocList = locationsByLevels[level] || []
      const dropdownOptions = convertToDropdownOptions(
        currentLevelLocList,
        level
      )
      const sortedOptions = Object.values(dropdownOptions).sort((a, b) =>
        this.SortAlphaNum(a.label, b.label)
      )

      let isDisabledCurLevel = true
      if (
        !isEmpty(this.props.levelsWithLabels[level]) ||
        level === this.state.nextLevel
      ) {
        isDisabledCurLevel = false
      }

      // console.log(this.state.nextLevel)

      const activeStateClass = isDisabledCurLevel
        ? classes.greyClass
        : classes.activeStepClass
      return (
        <Grid item container style={{ marginBottom: '15px' }}>
          <Grid item container>
            <Grid item style={{ marginLeft: '-10px' }}>
              <FiberManualRecordIcon className={activeStateClass} />
            </Grid>
            <Grid item style={{ margin: '0px 0px 0px 15px' }}>
              {locationsLevelLabels[level] || ''}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item className={classes.vl}></Grid>
            <Grid item style={{ margin: '0px 0px 0px 15px' }}>
              {!toggleAddContainer && level === 'container' ? (
                <>
                  <Button
                    startIcon={
                      <AddCircleOutlineIcon
                        style={{ fontSize: 15, marginRight: 5 }}
                      />
                    }
                    onClick={
                      disableAddContainer
                        ? () => {
                            this.toggleAddContainerFun()
                          }
                        : undefined
                    }
                    disabled={!disableAddContainer}
                  >
                    Add Container
                  </Button>
                </>
              ) : (
                <CreatableSelect
                  index={index}
                  distinct={sortedOptions}
                  classes={classes}
                  dropdownValue={dropdownValue}
                  selectedValue={selectedValueByLevel[level]}
                  selectedOptionValue={selectedOptionValue}
                  handleDropdownChange={this.handleDropdownChange}
                  nextLevel={level}
                  isCreatable={level !== 'container' ? true : false}
                  isReadOnly={level !== 'container' ? false : true}
                  componentLabel={locationsLevelLabels[level] || ''}
                  isNewOption={isNewOption}
                  isDisabled={isDisabledCurLevel}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  SortAlphaNum(a, b) {
    if (a && b) {
      const reA = /[^a-zA-Z]/g
      const reN = /[^0-9]/g
      const aA = a.replace(reA, '')
      const bA = b.replace(reA, '')
      if (aA === bA) {
        const aN = parseInt(a.replace(reN, ''), 10)
        const bN = parseInt(b.replace(reN, ''), 10)
        return aN === bN ? 0 : aN > bN ? 1 : -1
      } else {
        return aA > bA ? 1 : -1
      }
    } else if (a === null) {
      return 1
    } else if (b === null) {
      return -1
    }
  }

  clearLocations = () => {
    this.setState({
      clearLocations: true,
    })
  }

  renderDropdownEdit = () => {
    const index = 0
    const {
      classes = {},
      locationsByLevels = {
        aisle: [],
        section: [],
        shelf: [],
        container: [],
      },
      // levelsToCreateLocation = {},
      multiLocationSelection,
    } = this.props
    const {
      dropdownValue = '',
      selectedValueByLevel = {},
      isNewOption,
    } = this.state
    const selectedOptionValue = ''
    if (multiLocationSelection) {
      return (
        <Grid>
          {Object.keys(locationsByLevels).map((level) => {
            const currentLevelLocList = locationsByLevels[level] || []

            const dropdownOptions = convertToDropdownOptions(
              currentLevelLocList,
              level,
              locationsByLevels
            )

            let sortedOptions = Object.values(dropdownOptions).sort((a, b) =>
              this.SortAlphaNum(a.label, b.label)
            )
            sortedOptions = sortedOptions.sort((a, b) =>
              this.SortAlphaNum(a.parentLabel, b.parentLabel)
            )

            sortedOptions = sortedOptions.reduce((acc, option) => {
              option.group_by = option.parentLabel
              if (!option.label) {
                return acc
              }
              acc.push(option)
              return acc
            }, [])
            let selectedValue = selectedValueByLevel[level]

            if (selectedValue && !Array.isArray(selectedValue)) {
              selectedValue = []
            }

            return (
              <Grid item className={classes.locationMultiDropdownsWrapper}>
                <CheckBoxAutoComplete
                  options={sortedOptions}
                  label={locationsLevelLabels[level] || ''}
                  styles={{ alignSelf: 'center' }}
                  selectedValue={selectedValue || []}
                  onChange={this.handleAutoCompleteChange}
                  index={index}
                  distinct={sortedOptions}
                  classes={classes}
                  dropdownValue={dropdownValue}
                  nextLevel={level}
                  isNewOption={isNewOption}
                  isDisabled={isEmpty(sortedOptions)}
                  isMulti
                />
              </Grid>
            )
          })}
        </Grid>
      )
    } else {
      return (
        <Grid container spacing={4} alignItems={'center'}>
          {Object.keys(locationsByLevels).map((level) => {
            const currentLevelLocList = locationsByLevels[level] || []
            const dropdownOptions = convertToDropdownOptions(
              currentLevelLocList,
              level
            )
            const sortedOptions = Object.values(dropdownOptions).sort((a, b) =>
              this.SortAlphaNum(a.label, b.label)
            )
            return (
              <Grid item>
                <StyledCreatableSelect
                  index={index}
                  distinct={sortedOptions}
                  classes={classes}
                  dropdownValue={dropdownValue}
                  selectedValue={selectedValueByLevel[level]}
                  selectedOptionValue={selectedOptionValue}
                  handleDropdownChange={this.handleDropdownChange}
                  nextLevel={level}
                  isCreatable={false}
                  componentLabel={locationsLevelLabels[level] || ''}
                  isNewOption={isNewOption}
                  buttonVariant={'text'}
                  isDisabled={isEmpty(sortedOptions)}
                  isMulti
                />
              </Grid>
            )
          })}
        </Grid>
      )
    }
  }

  renderByComponentType() {
    switch (this.props.componentType) {
      case 'new':
        return this.renderDropdownNew()
      case 'edit':
        return this.renderDropdownEdit()
      default:
        return this.renderDropdownEdit()
    }
  }
  render() {
    return this.renderByComponentType()
  }
}

const mapStateToProps = (state, ownProps) => {
  const { componentType = '' } = ownProps
  return {
    findCurrentUserLocationPending:
      state.locations.findCurrentUserLocationPending,
    locationsByLevels: getLocationsByLevels(componentType)(state),
    locationId: getCurrentLocationId(componentType)(state),
    levelsWithLabelsStr: getLevelsWithLabelsStr(componentType)(state),
    levelsWithLabels: getLevelsWithLabels(componentType)(state),
    currentBuilding: getCurrentBuilding(componentType)(state),
    levelsToCreateLocation: getLevelsToCreateLocation(componentType)(state),
    isNewLocationCreated: getIsNewLocationCreated(componentType)(state),
    createdLocationData: getCreatedLocationData(componentType)(state),
    // barcodeType: state.user.barcodeType,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    findCurrentUserLevel1Locs: (
      userId = '',
      level = '',
      initLevel = '',
      componentType = ''
    ) => {
      dispatch(findCurrentUserLocation(userId, level, initLevel, componentType))
    },
    findSubLocationById: (
      selectedOption = {},
      locationId = '',
      level = '',
      initLevel = '',
      currentOptionLabel = '',
      componentType = '',
      callBack = () => {}
    ) => {
      dispatch(
        findSubLocationById(
          selectedOption,
          locationId,
          level,
          initLevel,
          currentOptionLabel,
          componentType,
          callBack
        )
      )
    },
    findSubLocationsByIdList: (
      selectedOption = {},
      locationId = '',
      level = '',
      initLevel = '',
      currentOptionLabel = '',
      componentType = '',
      callBack = () => {}
    ) => {
      dispatch(
        findSubLocationsByIdList(
          selectedOption,
          locationId,
          level,
          initLevel,
          currentOptionLabel,
          componentType,
          callBack
        )
      )
    },
    findSubLocationByIdSuccess: (
      selectedOption = {},
      locationsList = [],
      level = '',
      locationId = '',
      initLevel = '',
      locationLabel = '',
      componentType = ''
    ) => {
      dispatch(
        findSubLocationByIdSuccess(
          selectedOption,
          locationsList,
          level,
          locationId,
          initLevel,
          locationLabel,
          componentType
        )
      )
    },
    resetToInitState: (componentType = '') => {
      dispatch(resetToInitState(componentType))
    },
    showNotification: (isShown, message) =>
      dispatch(showNotification(isShown, message)),
  }
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(LocationDropdowns)
