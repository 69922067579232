import React from 'react'
import { func, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import withHOCs from 'util/withHocs'
import BarcodePage from 'pages/Barcode/BarcodePage'
import Grid from '@material-ui/core/Grid'
import Notifications from '../Notifications/Notifications'
import {
  closeSideNav,
  openSideNav,
  openBarcodeSideNav,
  closeBarcodeSideNav,
} from '../../store/layout/actionCreator'
import AppHeader from '../Header/Header'
import { saveUser } from '../../store/user/actionCreator'
import { isSessionActive } from '../../util/CommonUtils'
import LayoutRouters from './LayoutRouters'
import LayoutSideNav from './LayoutSideNav'

const styles = (theme) => ({
  header: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  contentContainer: {
    padding: theme.spacing(1), // Default <Grid container> spacing is 16. Use 8px padding for bug in material-ui Grid. See https://github.com/callemall/material-ui/issues/7466#issuecomment-316356427
  },
  sideNavFooterList: {
    color: theme.typography.body1.color,
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: theme.typography.caption.fontSize,
    listStyle: 'none',
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    '& li': {
      padding: theme.spacing(1 / 2),
    },
  },
  displayName: {
    overflow: 'hidden',
    flexShrink: 1,
    textOverflow: 'ellipsis',
  },
  companyName: {
    overflow: 'hidden',
    flexShrink: 1,
    textOverflow: 'ellipsis',
    fontSize: 12,
    fontWeight: 700,
  },
  tempAlert: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  tempAlertBlock: {
    backgroundColor: '#fff',
  },
})

export class Layout extends React.Component {
  static propTypes = {
    classes: object,
    openSideNav: func,
    closeSideNav: func,
  }

  static defaultProps = {
    classes: {},
    layoutActions: {},
    notificationActions: {},
  }

  handleMenuButtonClick = () => {
    if (this.props.sideNavIsOpen) {
      this.props.closeSideNav()
    } else {
      this.props.openSideNav()
    }
  }

  handleBarcodeAction = () => {
    const { barcodeSideNavIsOpen = false } = this.props
    if (barcodeSideNavIsOpen) {
      this.props.closeBarcodeSideNav()
    } else {
      this.props.openBarcodeSideNav()
    }
  }
  componentDidMount() {
    const { auth = {} } = this.props
    const { isAuthenticated, hasExpired, login = () => {} } = auth
    if (hasExpired || !isAuthenticated()) {
      login({})
    } else {
      this.authChecks()
    }
  }

  componentDidUpdate() {
    this.authChecks()
  }

  authChecks = () => {
    const {
      auth = {},
      saveUser = () => {},
    } = this.props
    const { login = () => {} } = auth
    const isSessionActiveCheck = isSessionActive({auth})
    if (!isSessionActiveCheck) {
      login({})
    } else {
      saveUser()
    }
  }

  render() {
    const {
      classes,
      sideNavIsOpen,
      barcodeSideNavIsOpen = false,
      location = {},
    } = this.props

    const { pathname = '' } = location
    return (
      <div>
        <Helmet defaultTitle="pegasus" titleTemplate="%s - pegasus" />
        <Notifications />
        <LayoutSideNav sideNavIsOpen={sideNavIsOpen} />
        <AppHeader
          menuAction={() => this.handleMenuButtonClick()}
          barcodeAction={() => this.handleBarcodeAction()}
        />
        <div className={classes.contentContainer}>
          <Grid container>
            <Grid item xs={12}>
              <LayoutRouters barcodeSideNavIsOpen={barcodeSideNavIsOpen} />
            </Grid>
          </Grid>
          {!pathname.includes('batchScanner') ? (
            <div>
              {barcodeSideNavIsOpen && (
                <BarcodePage barcodeAction={() => this.handleBarcodeAction()} />
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  sideNavIsOpen: state.layout.sideNavIsOpen,
  barcodeSideNavIsOpen: state.layout.barcodeSideNavIsOpen,
  searchTabValue: state.addFilters.currentTabValue,
})

const mapDispatchToProps = {
  openSideNav,
  closeSideNav,
  saveUser,
  closeBarcodeSideNav,
  openBarcodeSideNav,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(Layout)
