import { useEffect, useState, startTransition, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { isEmpty, cloneDeep, groupBy } from 'lodash'
import Grid from '@mui/material/Grid'
import cx from 'classnames'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CancelIcon from '@mui/icons-material/Cancel'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import withHOCs from 'util/withHocs'
import ImageUploadContainer from './ImageUploadContainer'
import {
  removeFileFromUploadList,
  startUploadPropImages,
  deletePropImages,
} from 'store/addProp/actionCreator'
import {
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { getDisplayImageWithProps } from 'util/CommonUtils'
import { ArtTrack, List } from '@material-ui/icons'
import { SAMPLES } from 'enums/Tabs'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    minWidth: '1200px',
    width: 'auto',
  },
  chipFilterTextField: {
    width: '100%',
  },
  defaultButton: {
    maxWidth: '400px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  rootDiv: {
    width: '750px',
    display: 'flex',
    flexFlow: 'column wrap',
  },
  appBarToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    // zIndex: 3200,
  },
  appBar: {
    zIndex: '3200 !important',
  },
  successIcon: {
    color: 'green',
  },
  errorChip: {
    margin: theme.spacing(1 / 2),
    color: '#B85300 !important',
    border: '1px solid #B85300 !important',
    fontWeight: 700,
  },
  errorIcon: {
    display: 'flex',
    color: '#B85300',
    alignItems: 'center',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px dashed #B85300',
    backgroundColor: '#f0d3d8',
    paddingBottom: '16px !important',
  },
  progressBar: {
    padding: '7px',
    color: '#333333',
  },
  buttonCls: {
    border: '1px solid !important',
    borderRadius: '4px !important',
    marginRight: '10px !important',
  },
})
const AddPropImage = ({
  classes = {},
  lanId = '',
  item = {},
  toggleDrawer = () => {},
  sampleType = SAMPLES,
}) => {
  const itemCopy = Object.assign({}, item)
  const { sampleId = '', pegasusId = '' } = itemCopy
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [acceptedFiles, setAcceptedFiles] = useState([])
  const [rejectedFiles, setRejectedFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [uploadFiles, setUploadFiles] = useState([])
  const [currentPropImages, setCurrentPropImages] = useState([])
  const [uploadAllClick, setUploadAllClick] = useState(false)
  const [previewImageIndex, setPreviewImageIndex] = useState(Number(0))
  const [selectedTab, setSelectedTab] = useState('images')
  const { uploadFiles: storeUploadFiles } = useSelector(
    ({ addProp: { uploadFiles = [] } }) => {
      return {
        uploadFiles,
      }
    },
    shallowEqual
  )

  const useHasChanged = (val) => {
    const prevVal = usePrevious(val)
    return (
      (!isEmpty(prevVal) && prevVal !== val) ||
      (!isEmpty(val) && prevVal !== val)
    )
  }

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }
  const isUploadFilesChanged = useHasChanged(storeUploadFiles)
  const storeUploadFilesMok = cloneDeep(storeUploadFiles)

  const onUpload = () => {
    startTransition(() => {
      setUploadFiles([...acceptedFiles])
      setUploadAllClick(true)
    })
  }

  useEffect(() => {
    uploadFiles.length > 0 &&
      dispatch(startUploadPropImages(uploadFiles, sampleId, sampleType))
    return function cleanup() {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, uploadFiles])

  useEffect(() => {
    const currentPropImages = storeUploadFilesMok?.filter(
      (obj) => obj.pegasusId === sampleId
    )
    const uploadedFilesByStatus = groupBy(currentPropImages || [], 'status')
    const uploadedFilenames =
      (uploadedFilesByStatus?.completed || [])?.map((obj) => obj.fileName) || []
    const pendingAcceptedFiles = acceptedFiles?.filter(
      (obj) => uploadedFilenames?.indexOf(obj.name) === -1
    )
    if (
      Object.keys(uploadedFilesByStatus).length &&
      Object.keys(uploadedFilesByStatus).indexOf('inprogress') === -1
    ) {
      setUploadAllClick(false)
      setAcceptedFiles(pendingAcceptedFiles)
    }
    if (isUploadFilesChanged) {
      setCurrentPropImages(currentPropImages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeUploadFiles, sampleId])

  useEffect(() => {
    const nonTcinImages = currentPropImages?.filter(
      (obj) => obj.status !== 'nodelete'
    )
    const currentSelFiles = cloneDeep([
      ...acceptedFiles,
      ...rejectedFiles,
      ...nonTcinImages,
    ])
    const allSelFiles = cloneDeep([...acceptedFiles, ...rejectedFiles])
    const maxLimit = sampleType === SAMPLES ? Number(2) : Number(3)
    const errorMessage =
      currentSelFiles?.length > maxLimit
        ? `Only ${maxLimit} files allowed at a time`
        : ''
    setErrorMessage(errorMessage)
    setSelectedFiles(allSelFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles, rejectedFiles])

  const onDrop = (curAcceptedFiles = [], curRejectedFiles = []) => {
    const allAcceptedFiles = [...acceptedFiles, ...curAcceptedFiles]
    const allRejectedFiles = [...rejectedFiles, ...curRejectedFiles]
    startTransition(() => {
      setRejectedFiles(allRejectedFiles)
      setAcceptedFiles(allAcceptedFiles)
    })
  }
  const onCloseClick = (event) => {
    toggleDrawer()
  }
  const onFileDelete = async (fileName = '') => {
    const newFilesSet = acceptedFiles.filter((obj) => obj.name !== fileName)
    await dispatch(removeFileFromUploadList([fileName], sampleId))
    await setAcceptedFiles(newFilesSet)
  }

  const renderFileErrors = (errors = []) => {
    const CHUNK_SIZE = Number(2 * 1024 * 1024)
    return errors.map((errorObj = {}) => {
      const { message: errorMessage = '' } = errorObj
      const newErrorMessage = errorMessage?.replace(CHUNK_SIZE, '2MB')
      return (
        <Grid item container alignItems={'center'}>
          <WarningAmberIcon className={classes.errorIcon} />
          {newErrorMessage}
        </Grid>
      )
    })
  }
  const renderStatusIcons = ({
    status,
    imageUrlOriginal = '',
    pegasusId = '',
    fileName = '',
  }) => {
    switch (status) {
      case 'nodelete':
        return <span>{'-'}</span>
      case 'inprogress':
        return <CircularProgress className={classes.progressBar} />
      case 'completed':
        return (
          <IconButton
            id="deleteFile"
            size="small"
            aria-label="delete file"
            onClick={(event) => {
              event.preventDefault()
              const params = {
                pegasus_id: pegasusId,
                image_url_list: [imageUrlOriginal],
              }
              dispatch(deletePropImages(params, [fileName]))
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        )
      case 'failed':
        return <ErrorOutlineIcon className={classes.errorIcon} />
      default:
        break
    }
  }
  const renderUploadedFiles = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>File Name</TableCell>
              <TableCell>Status/Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPropImages.map((currentFile) => {
              const {
                fileName = '',
                status = '',
                imageUrl = null,
              } = currentFile
              return (
                <TableRow
                  key={fileName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{imageUrl || ''}</TableCell>
                  <TableCell component="th" scope="row">
                    {fileName}
                  </TableCell>
                  <TableCell>
                    {renderStatusIcons(currentFile) || status}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  const renderSelectedFiles = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Image Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedFiles.map((currentFile) => {
              const {
                name: afileName = '',
                file: { name: rfileName = '' } = {},
                errors = [],
              } = currentFile

              const fileName = afileName || rfileName
              return (
                <TableRow
                  key={fileName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {fileName}
                  </TableCell>
                  <TableCell>
                    {errors.length > 0
                      ? renderFileErrors(errors)
                      : 'Can upload'}
                  </TableCell>
                  <TableCell>
                    {!errors.length && (
                      <IconButton
                        id="deleteFile"
                        size="small"
                        aria-label="delete file"
                        onClick={(event) => {
                          event.preventDefault()
                          onFileDelete(fileName)
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  const renderAppbar = (content = '') => {
    return (
      <AppBar
        position="sticky"
        color="transparent"
        elevation={1}
        sx={{
          bottom: 0,
          fontWeight: 500,
          backgroundColor: '#333333',
          color: '#ffffff',
        }}
      >
        <Toolbar className={classes.appBarToolBar}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} component="div">
            {content}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseClick}
            sx={{ color: '#ffffff' }}
          >
            <CancelIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
  const prevImage = () => {
    setPreviewImageIndex(previewImageIndex - Number(1))
  }
  const nextImage = () => {
    setPreviewImageIndex(previewImageIndex + Number(1))
  }
  const renderUploadall = () => {
    const allPropImages = cloneDeep(
      [...acceptedFiles, ...currentPropImages] || []
    )
    return (
      <Grid container justifyContent={'center'}>
        <Grid item>
          <Button
            onClick={onUpload}
            color="primary"
            aria-label="upload"
            variant="contained"
            className={classes.uploadButton}
            disabled={uploadAllClick || allPropImages?.length > 3}
          >
            UPLOAD ALL
          </Button>
        </Grid>
      </Grid>
    )
  }
  const renderPreviewImages = () => {
    const uploadedFilenames = getUploadedFiles()
    const previewImageUrl =
      uploadedFilenames[Number(previewImageIndex)] &&
      uploadedFilenames[Number(previewImageIndex)]['imageUrlOriginal']
    return (
      <div>
        {!!uploadedFilenames?.length &&
          getDisplayImageWithProps(previewImageUrl, 400, 400)}
      </div>
    )
  }

  const getUploadedFiles = () => {
    const uploadedFilesByStatus = groupBy(currentPropImages || [], 'status')
    const uploadedFilenames = uploadedFilesByStatus?.completed || []
    return uploadedFilenames
  }

  return (
    <div style={{ textAlign: '-webkit-center' }}>
      {renderAppbar(`${'View/Upload Image - ' + pegasusId}`)}
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          {
            <ImageUploadContainer
              selectedFiles={acceptedFiles}
              onDropFiles={onDrop}
              currentPropImages={currentPropImages}
              sampleType={sampleType}
            />
          }
        </Grid>
        <Grid item xs={12}>
          {errorMessage?.length > 0 && (
            <Grid
              container
              style={{ marginBottom: 10 }}
              justifyContent={'center'}
            >
              <Grid item>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <WarningAmberIcon className={classes.errorIcon} />
                    {errorMessage}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={12}>
              {!isEmpty(selectedFiles) && renderSelectedFiles()}
            </Grid>
          </Grid>
          {!isEmpty(acceptedFiles) && renderUploadall()}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {!!currentPropImages?.length && (
              <Grid item container justifyContent={'flex-end'}>
                <IconButton
                  className={cx(classes.buttonCls)}
                  onClick={() => {
                    setSelectedTab('images')
                  }}
                  // disabled={selectedTab === 'images'}
                  color={selectedTab === 'images' ? 'primary' : 'default'}
                  size={'large'}
                >
                  <List fontSize="inherit" />
                </IconButton>
                <IconButton
                  className={cx(classes.buttonCls)}
                  onClick={() => {
                    setSelectedTab('preview')
                  }}
                  disabled={!getUploadedFiles()?.length}
                  color={selectedTab === 'preview' ? 'primary' : 'default'}
                  size={'large'}
                >
                  <ArtTrack fontSize="inherit" />
                </IconButton>
              </Grid>
            )}
            {selectedTab === 'images' && (
              <Grid item xs={12}>
                {!!currentPropImages?.length && renderUploadedFiles()}
              </Grid>
            )}
            {selectedTab === 'preview' && (
              <Grid item xs={12}>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                  <Grid item>
                    {' '}
                    <IconButton
                      onClick={prevImage}
                      disabled={Number(previewImageIndex) === Number(0)}
                      size={'large'}
                    >
                      <ChevronLeft fontSize="inherit" />
                    </IconButton>
                  </Grid>
                  <Grid item> {renderPreviewImages()}</Grid>
                  <Grid item>
                    {' '}
                    <IconButton
                      onClick={nextImage}
                      disabled={
                        Number(previewImageIndex) ===
                        Number((getUploadedFiles()?.length || 3) - 1)
                      }
                      size={'large'}
                    >
                      <ChevronRight fontSize="inherit" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default withHOCs({ auth: true, nav: true, styles })(AddPropImage)
