import axios from 'axios'
import apiConfig from '../apiConfig'
import { PROP, PROPS, SAMPLE, SAMPLES } from '../enums/Tabs'

const {
  managements: { url: managementsUrl = '' },
  user: { url: usersUrl = '' },
  uploads: { url: uploadsUrl = '' },
  history: { url: historyUrl = '' } = {},
  apiKey = '',
} = apiConfig

export function updateSampleFields(params) {
  const { field_name = '', field_value = '', sample_id = '' } = params
  const updatedList = {
    field_name: field_name.toUpperCase(),
    field_value: field_value,
  }
  if (sample_id) {
    const updateSampleFieldsURL = `${managementsUrl}samples/notes/${sample_id}`
    return axios.put(updateSampleFieldsURL, updatedList, {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiKey,
      },
    })
  }
}

export function fetchPropsMetadata() {
  return axios.get(`${managementsUrl}props/propsMetaData`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function fetchScenicMetadata() {
  return axios.get(`${managementsUrl}scenic/metadata`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function fetchHistoryData(params = {}) {
  let { sampleId = '', ...requestParams } = params
  return axios.get(`${historyUrl}sample_histories/${sampleId}`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      ...requestParams,
      key: apiKey,
    },
  })
}

export function fetchHistoryFilters(params = {}) {
  let { sampleId = '' } = params

  return axios.get(`${historyUrl}sample_users_and_events/${sampleId}`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function fetchInventorySamples(
  params,
  sample_type = SAMPLE,
  page = 1,
  rowsPerPage = 25
) {
  let {
    requestParams = new URLSearchParams(),
    requestPayload = {
      include_filters: {},
      exclude_filters: {},
    },
  } = params
  const sampleType = sample_type === SAMPLES ? SAMPLE : sample_type
  requestParams.append('sample_type', sampleType)
  requestParams.append('page', page)
  requestParams.append('per_page', rowsPerPage)
  return axios.post(managementsUrl + 'search', requestPayload, {
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    params: requestParams,
  })
}

export function addAProp(params = {}) {
  return axios.post(managementsUrl + 'props', params, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function addAScenic(params = {}) {
  return axios.post(managementsUrl + 'scenic', params, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function editProp(params = {}) {
  return axios.put(managementsUrl + 'props', params, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}
export function editScenic(params = {}) {
  return axios.put(managementsUrl + 'scenic', params, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function downloadInventorySamples(params) {
  return axios.get(managementsUrl + 'search', {
    headers: { Accept: 'text/csv' },
    params: params,
  })
}

export function downloadInventorySamplesPost(params) {
  const {
    requestParams = {},
    requestPayload = {
      include_filters: {},
      exclude_filters: {},
    },
  } = params
  return axios.post(managementsUrl + 'search', requestPayload, {
    headers: { Accept: 'text/csv' },
    params: requestParams,
  })
}
export function downloadContactSheet(params = {}, downloadType = '') {
  return axios.get(managementsUrl + 'search?downloadAs=' + downloadType, {
    headers: { Accept: '*/*' },
    params: params,
    responseType: 'blob',
  })
}

/**
 * Common service to download CSV, XLSX, PDF files in search page
 * @param {*} params
 * @param {*} downloadType
 * @param {SAMPLE, PROPS} sampleType
 * @returns
 */
export function downloadContactSheetPost(
  params = {},
  downloadType = '',
  sampleType = SAMPLE
) {
  const {
    requestParams = {},
    requestPayload = {
      include_filters: {},
      exclude_filters: {},
    },
  } = params
  return axios.post(
    managementsUrl +
      'search?downloadAs=' +
      downloadType +
      '&sample_type=' +
      sampleType,
    requestPayload,
    {
      headers: { Accept: '*/*' },
      params: requestParams,
      responseType: 'blob',
    }
  )
}

export function saveFilters(filterRequest = {}) {
  return axios.post(managementsUrl + 'search/filters', filterRequest, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function getFilters(params = {}) {
  return axios.get(managementsUrl + 'search/filters', {
    params: Object.assign({}, params, {
      key: apiKey,
    }),
  })
}

export const deleteFilters = (filterId = '') => {
  return axios.delete(managementsUrl + 'search/filter/' + filterId, {
    params: {
      key: apiKey,
    },
  })
}

export function getAllBrands() {
  return axios.get(managementsUrl + 'search/brands', {
    params: {
      key: apiKey,
    },
  })
}

export function getAllUsers() {
  return axios.get(usersUrl + 'users/active', {
    params: {
      key: apiKey,
    },
  })
}

export function saveColumnSettings(
  columnsToSave = [],
  lanId = '',
  type = SAMPLES
) {
  const reqObj = {
    lan_id: lanId,
    fields: columnsToSave,
    type,
  }
  return axios.post(managementsUrl + 'search/searchFields', reqObj, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function getColumnSettings(type = SAMPLES) {
  return axios.get(managementsUrl + 'search/searchFields', {
    params: {
      key: apiKey,
      type,
    },
  })
}

export async function reverseSampleStatus(pegasusIds = [], type = SAMPLE) {
  const url =
    type === PROPS
      ? 'props/status_update_completions'
      : 'samples/status_update_completions'
  return axios.post(
    managementsUrl + url,
    {
      pegasus_ids: pegasusIds,
    },
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export function cancelOrderedSamples(pegasusIds) {
  return axios.post(
    managementsUrl + 'samples/cancellations',
    {
      pegasus_ids: pegasusIds,
    },
    {
      headers: { 'Content-Type': 'application/json' },
      params: {
        key: apiKey,
      },
    }
  )
}

export async function uploadPropImage(
  fileToUpload = {},
  pegasusID = '',
  sampleType = SAMPLES
) {
  let formdata = new window.FormData()
  const formatedSampleType =
    sampleType === SAMPLES ? SAMPLE : sampleType === PROPS ? PROP : sampleType

  formdata.append(
    'imageUploadRequest',
    new window.Blob(
      [
        JSON.stringify({
          pegasus_id: pegasusID,
          image_name: fileToUpload.name,
          is_primary: true,
          sample_type: formatedSampleType,
        }),
      ],
      {
        type: 'application/json',
      }
    )
  )
  formdata.append('file', fileToUpload)
  return await axios.post(uploadsUrl + '/uploads', formdata, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    params: {
      key: apiKey,
    },
  })
}

export const deleteImage = (params = {}) => {
  return axios.delete(
    uploadsUrl,
    { data: params },
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export const downloadImage = (imageUrl = '') => {
  return fetch(imageUrl)
}
