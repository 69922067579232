import { isEmpty } from 'lodash'
import { projectResponseToProject } from '../mappers/projectMapper'
import {
  DATE_FORMAT,
  convertTableDateFormat,
  getDisplayImage,
} from '../util/CommonUtils'
import { STATUS_ACTIVE } from 'enums/Tabs'

export const getPayloadFilters = (filters = {}) => {
  const { users = [] } = filters
  const createdBy = users?.map((obj) => obj.value)
  const payload = Object.assign(
    {},
    {
      created_by: createdBy,
    }
  )
  const resPayload = Object.entries(payload).reduce(
    (a, [k, v]) => (isEmpty(v) ? a : ((a[k] = v), a)),
    {}
  )
  return resPayload
}

export const scenicRoomToScenicRoomUpdateRequest = (
  scenicRoomFormData = {}
) => {
  const {
    roomName,
    selectedCateogory,
    selectedSubCateogory,
    selectedSource,
    selectedBrand = '',
    notes = '',
    selectedImage = null,
  } = scenicRoomFormData

  const updateDetails = {
    name: roomName,
    category_id: selectedCateogory?.category_id,
    sub_category_id: selectedSubCateogory?.sub_category_id,
    source_id: selectedSource?.source_id,
    target_brand: selectedBrand,
    status: STATUS_ACTIVE,
    notes,
    selectedImage,
  }

  return updateDetails
}

export const activeScenicRoomToNewScenicRoom = (updateDetails) => {
  const { data: scenicRoomData = {} } = updateDetails
  return {
    carrier: scenicRoomData.carrier || '',
    scenicRoomId: scenicRoomData.shoot_scenicRoom_id,
    shipFromLocId: scenicRoomData.ship_from
      ? scenicRoomData.ship_from.location_id
      : '',
    shipFromLocName: scenicRoomData.ship_from
      ? scenicRoomData.ship_from.location_name
      : '',
    shipToLocation: {
      location_id: scenicRoomData.ship_to?.location_id,
      building: scenicRoomData.ship_to?.location_name || '',
    },
    shipToLocName: updateDetails.data.ship_to
      ? updateDetails.data.ship_to.location_name
      : '',
    scenicRoomChannel: scenicRoomData.scenicRoom_channel || '',
    scenicRoomName: scenicRoomData.shoot_scenicRoom_name || '',
    scenicBrand: scenicRoomData.brand || '',
    status: scenicRoomData.status || '',
    otherContacts: scenicRoomData.other_contacts || '',
    tracking: scenicRoomData.tracking_id || '',
    notes: scenicRoomData.notes || '',
    project: projectResponseToProject(scenicRoomData.project),
    shipDate: scenicRoomData.ship_date,
    scenicRoomCreator: scenicRoomData.created_by,
    scenicRoomPartners: scenicRoomData.scenicRoom_partners || [],
    marketingPid: scenicRoomData.marketing_pid || [],
  }
}

/**
 * Maps ScenicRoom API response containing multiple scenicRooms to internal model
 * @param scenicRooms a JSON struct comprised of a list of ScenicRoom JSON models
 * @returns a mapped list of scenicRooms
 */
export const scenicRoomsResponseToScenicRooms = (
  scenicRooms,
  allUsers = []
) => {
  return scenicRooms.data?.scenicRooms?.map((scenicRoomInstance) =>
    scenicRoomResponseToScenicRoom(scenicRoomInstance, allUsers)
  )
}

/**
 * Maps ScenicRoom API JSON response to internal model
 * @param scenicRoomResponse Json representation of a scenicRoom
 * @returns mapped internal entity
 */
export const scenicRoomResponseToScenicRoom = (
  scenicRoomResponse = {},
  allUsers = []
) => {
  const mappedData = {
    scenicRoomId: scenicRoomResponse?.room_id || scenicRoomResponse?.roomId,
    roomImageUrl: scenicRoomResponse?.imageUrl || scenicRoomResponse?.image_url,
    scenicBrand:
      scenicRoomResponse?.targetBrand || scenicRoomResponse?.target_brand,
    roomName: scenicRoomResponse?.name,
    roomCategory:
      scenicRoomResponse?.category_id || scenicRoomResponse?.categoryId, // Getting category_id in list rooms and categoryId in single room query
    roomSubCategory:
      scenicRoomResponse?.sub_category_id || scenicRoomResponse?.subCategoryId,
    totalCost: scenicRoomResponse?.total_cost || 0,
    // scenicAssets: scenicRoomResponse?.pegasus_ids || [],
    createdOn: convertTableDateFormat(
      scenicRoomResponse?.create_ts || scenicRoomResponse?.createTs,
      DATE_FORMAT
    ),
    updateDate: convertTableDateFormat(
      scenicRoomResponse?.update_ts || scenicRoomResponse?.updateTs,
      DATE_FORMAT
    ),
    createdBy: scenicRoomResponse?.created_by?.first_name ||  scenicRoomResponse?.created_by?.last_name
      ? `${scenicRoomResponse?.created_by?.first_name} ${scenicRoomResponse?.created_by?.last_name}`
      : allUsers?.find(
          (userData) => userData.value === scenicRoomResponse?.created_by
        )?.label ||
        scenicRoomResponse?.created_by?.lan_id ||
        '',
    modifiedBy: scenicRoomResponse?.modified_by?.first_name
      ? `${scenicRoomResponse?.modified_by?.first_name} ${scenicRoomResponse?.modified_by?.last_name}`
      : allUsers?.find(
          (userData) => userData.value === scenicRoomResponse?.modified_by
        )?.label ||
        scenicRoomResponse?.modified_by?.lan_id ||
        '',
    status: scenicRoomResponse?.status,
    source: scenicRoomResponse?.source_id || scenicRoomResponse?.sourceId,
    notes: scenicRoomResponse?.notes,
    scenicAssets: scenicRoomResponse?.scenic_asset_count || Number(0),
    // isBundle: sce
  }
  return mappedData
}

/**
 *
 * @param data List of scenicRoom contents to pull relevant information from
 * @returns {{carrier: (*|string), tracking_num: (*|string), vendor_id: (*|number|module:prop-types.number), pegasus_ids: *, ship_to_loc: *}[]}
 */
export const scenicRoomToScenicRoomRequest = (data) => {
  let pegasusIds = data.map((i) => {
    return {
      pegasus_id: (i.pegasusId || 'PG' || 'WH').substring(
        2,
        i.pegasusId.length
      ),
      vendor_id: i.vendorId,
      vendor_name: i.vendorName,
    }
  })
  return {
    pegasus_ids: pegasusIds,
    ship_to_loc: data[0].shipToLoc || null,
    carrier: data[0].carrier || null,
    tracking_num: data[0].trackingNumber || null,
    scenicRoom_status: data[0].scenicRoomStatus,
    brand_id: data[0].brandId || null,
    vendor_created: true,
  }
}

/**
 * Maps internal order samples to scenicRoom details
 * @param samples a list of samples to be converted to scenicRoom items
 * @returns list of scenicRoom contents
 */
export const orderDataToScenicRoomData = (samples) => {
  return samples.map((sample) => ({
    pegasusId: sample.pegasusId,
    tcin: sample.tcin,
    description: sample.description,
    vendorId: sample.vendorId,
    vendorName: sample.vendorName,
    vendorNotes: sample.vendorNotes,
    shipToDestination: sample.shipToDestination,
    shipToLoc: sample.shipTo,
    // popup item details
    imageUrl:
      sample.imageUrl !== undefined ? (
        <img alt={sample.tcin} height={80} src={sample.imageUrl} />
      ) : null,
    className: sample.className,
    departmentId: sample.departmentId,
    departmentName: sample.departmentName,
    classId: sample.className,
    dueDate: sample.dueDate,
    manufacturerStyle: sample.manufacturerStyle,
    color: sample.color,
    size: sample.size,
    manufacturerBrand: sample.manufacturerBrand,
    brandId: sample.brandId,
    brand: sample.brand || '',
    dpci: sample.dpci || '',
    launchDate: sample.launchDate || '',
  }))
}

export const scenicRoomToUpdateRequest = (scenicRoomDetail) => {
  return {
    scenicRoom_id: scenicRoomDetail.scenicRoomId,
    scenicRoom_status: scenicRoomDetail.scenicRoomStatus,
    ship_to_loc: scenicRoomDetail.shipToLoc,
    pegasus_ids: scenicRoomDetail.pegasusIds,
    tracking_num: scenicRoomDetail.trackingNum,
    carrier: scenicRoomDetail.carrier,
  }
}

export const sampleToScenicRoomRequest = (sample = {}, vendorCreated) => {
  return {
    pegasus_ids: [
      {
        pegasus_id: sample.pegasusId.slice(2, sample.pegasusId.length),
        vendor_id: sample.vendorId,
        vendor_name: null,
      },
    ],
    ship_to_loc: sample.shipTo,
    brand_id: sample.brandId,
    vendor_created: vendorCreated,
  }
}

export const getSingleScenicRoomWithMetaDataMapping = (
  scenicRoom = {},
  metaData = {},
  allUsers = []
) => {
  if (isEmpty(scenicRoom)) {
    return {}
  }
  const {
    roomCategory = '',
    roomSubCategory = '',
    source = '',
    modifiedBy = {},
    createdBy = {},
  } = scenicRoom

  const updateScenicRoom = { ...scenicRoom }
  const roomCategoryData = metaData?.categories?.find(
    (category) => category.category_id === Number(roomCategory)
  )
  updateScenicRoom.roomCategory =
    roomCategoryData?.name || Number(roomCategory) || ''

  updateScenicRoom.roomSubCategory =
    roomCategoryData?.scenic_sub_categories.find(
      (subCategory) => subCategory.sub_category_id === Number(roomSubCategory)
    )?.name ||
    Number(roomSubCategory) ||
    ''

  updateScenicRoom.source =
    metaData?.sources?.find(
      (sourceData) => sourceData.source_id === Number(source)
    )?.source_name ||
    Number(source) ||
    ''

  updateScenicRoom.modifiedBy = modifiedBy
  updateScenicRoom.createdBy = createdBy

  updateScenicRoom.roomImageUrl = getDisplayImage(scenicRoom?.roomImageUrl)
  updateScenicRoom.scenicAssets = scenicRoom?.scenicAssets

  return updateScenicRoom
}

export const getScenicRoomsDataWithMetaDataMapping = (
  scenicRooms = [],
  metaData = {},
  allUsers = []
) => {
  if (isEmpty(scenicRooms)) {
    return []
  }
  const scenicRoomsDataWithMetaDataMapping = scenicRooms.map((scenicRoom) =>
    getSingleScenicRoomWithMetaDataMapping(scenicRoom, metaData, allUsers)
  )
  return scenicRoomsDataWithMetaDataMapping
}

export function scenicRoomResponse(response) {
  let returnArray = []
  const { data: { scenic_rooms = [] } = {} } = response

  scenic_rooms?.map(({ room_id, name }) => {
    returnArray.push({
      value: room_id,
      label: name,
    })
  })
  return returnArray
}
