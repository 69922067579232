import React from 'react'
import DialogBox from '../DialogBox/DialogBox'
import UseCallbackPrompt from '../../components/Router/UseCallbackPrompt'

export default function RouterLeavePrompt(props) {
  const { content: modalContent = '' } = props
  const [showPrompt, confirmNavigation, cancelNavigation] =
    UseCallbackPrompt(true)
  return (
    <React.Fragment>
      <DialogBox
        isAlertVisible={showPrompt}
        message={modalContent}
        onClose={cancelNavigation}
        response={false}
        onAgree={confirmNavigation}
        title={'Unsaved Changes'}
        closeButtonText={'OK'}
      />
    </React.Fragment>
  )
}
