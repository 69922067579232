//

import { keys } from '@material-ui/core/styles/createBreakpoints'

// [ 'XS', 'SM', 'MD', 'LG', 'XL' ]
export const BREAKPOINT_KEY_LIST = keys.map((key) => key.toUpperCase())

export const BREAKPOINTS_MAP = {
  XS: 360,
  SM: 600,
  MD: 960,
  LG: 1280,
  XL: 1920,
}

export const NAV_INSET_MAP = {
  XS: 72,
  SM: 72,
  MD: 80,
  LG: 80,
  XL: 80,
}

const MAX_NAV_WIDTH = BREAKPOINTS_MAP.MD

export const MAX_TABS_LIST_WIDTH = MAX_NAV_WIDTH - NAV_INSET_MAP.MD

export const MAX_TAB_WIDTH = 264

const TAB_PADDING_SM = 12
const TAB_PADDING_LG = 24

export const TAB_PADDING_MAP = {
  XS: TAB_PADDING_SM,
  SM: TAB_PADDING_SM,
  MD: TAB_PADDING_LG,
  LG: TAB_PADDING_LG,
  XL: TAB_PADDING_LG,
}

export const MAX_TAB_CONTENT_WIDTH_MAP = {
  XS: MAX_TAB_WIDTH - TAB_PADDING_MAP.XS * 2,
  SM: MAX_TAB_WIDTH - TAB_PADDING_MAP.SM * 2,
  MD: MAX_TAB_WIDTH - TAB_PADDING_MAP.MD * 2,
  LG: MAX_TAB_WIDTH - TAB_PADDING_MAP.LG * 2,
  XL: MAX_TAB_WIDTH - TAB_PADDING_MAP.XL * 2,
}

export const ARROW_ICON_PADDING = 7

export const hasSecondaryNavClass = 'hasSecondaryNav'
export const hasSecondaryNavTallClass = 'hasSecondaryNav--tall'
export const appBarStyles = {
  [`.${hasSecondaryNavClass} &`]: {
    paddingBottom: 48,
  },
}

/**
 * Returns first entry who's value is larger than the browsers width
 * @return {String}
 */
export function getScreenSizeKey() {
  return (
    BREAKPOINT_KEY_LIST.find(
      (key) => window.innerWidth < BREAKPOINTS_MAP[key]
    ) || BREAKPOINT_KEY_LIST[BREAKPOINT_KEY_LIST.length - 1]
  )
}

export const ELLIPSIS = '…'
