import { combineReducers } from 'redux'
import layout from '../store/layout/reducer'
import search from '../store/search/reducer'
import order from '../store/order/reducer'
import orderConfirmation from '../store/orderConfirmation/reducer'
import orderSummary from '../store/orderSummary/reducer'
import orderReceipt from '../store/orderReceipt/reducer'
import notification from './notification/reducer'
import searchInventory from './searchInventory/reducer'
import searchArchivedSamples from './searchArchivedSamples/reducer'
import settings from './settings/reducer'
import shipmentBarcode from '../store/shipmentBarcode/reducer'
import user from './user/reducer'
import shipmentSummary from '../store/shipmentSummary/reducer'
import checkIn from '../store/checkIn/reducer'
import myOrder from '../store/myOrders/reducer'
import projects from './projects/reducer'
import collections from './collections/reducer'
import shipSamples from './shipSamples/reducer'
import newShipment from './newShipment/reducer'
import newShootType from './newShootType/reducer'
import manageLocations from '../store/manageLocations/reducer'
import addFilters from './filters/reducer'
import locations from '../store/locations/reducer'
import orderSamples from '../store/orderSamples/reducer'
import autoOrderSamples from '../store/autoOrderSamples/reducer'
import autoOrderFilters from '../store/autoOrderFilters/reducer'
import addSamples from '../store/addSample/reducer'
import history from '../store/history/reducer'
import addProp from '../store/addProp/reducer'
import batchReducer from './batchScanner/reducer'
import { getCurrentPageNewState } from 'util/CommonUtils'
import scenicRoomReducer from './scenicRoomMain/reducer'
import scenicRoomItemsReducer from './scenicRoomItems/reducer'
import projectPicklist from './projectPicklist/reducer'

const combineReducer = combineReducers({
  layout,
  search,
  order,
  orderConfirmation,
  orderSummary,
  orderReceipt,
  notification,
  projects,
  collections,
  searchInventory,
  searchArchivedSamples,
  settings,
  shipmentBarcode,
  user,
  shipmentSummary,
  checkIn,
  myOrder,
  shipSamples,
  newShipment,
  newShootType,
  manageLocations,
  addFilters,
  locations,
  orderSamples,
  autoOrderSamples,
  autoOrderFilters,
  addSamples,
  history,
  addProp,
  batchReducer,
  scenicRoom: scenicRoomReducer,
  scenicRoomItems: scenicRoomItemsReducer,
  projectPicklist: projectPicklist
})

const rootReducer = (state, action) => {
  const appInitState = window.__PRELOADED_STATE__
  let updatedState = { ...state }
  const { type: actionType = '' } = action

  updatedState = getCurrentPageNewState(appInitState, updatedState, actionType)
  return combineReducer(updatedState, action)
}

export default rootReducer
