import { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import * as propTypes from 'prop-types'
import { isEmpty, isEqual } from 'lodash'

const styles = (theme) => ({
  root: {
    padding: '10px',
    margin: '10px',
  },
  textField: {
    width: '30%',
  },
  typography: {
    padding: '10px',
  },
})

class ShipToCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      editContentVisible: false,
      selectedLocation: '',
    }
  }

  handleSaveClick = async () => {
    this.setState((state) => ({ ...state, editContentVisible: false }))
    const { handleSaveClick, lanId } = this.props
    if (this.handleSaveClick) {
      await handleSaveClick({
        defaultLocation: this.state.selectedLocation,
        lanId: lanId,
      })
    }
    if (
      this.state.selectedLocation !== 'Royalston - Samples & Props' &&
      this.state.selectedLocation !== 'Target Plaza'
    ) {
      this.props.setBarcodeCallback()
    }
  }

  handleEditIconClick = () => {
    this.setState((state) => ({ ...state, editContentVisible: true }))
  }

  handleCancelClick = () => {
    const { defaultLocation } = this.props
    this.setState((state) => ({
      ...state,
      editContentVisible: false,
      selectedLocation: defaultLocation,
    }))
  }

  handleLocationChange = (value) => {
    this.setState((state) => ({
      ...state,
      selectedLocation: value,
    }))
  }

  static getDerivedStateFromProps(props, state) {
    const { defaultLocation = '' } = props
    const { selectedLocation = '' } = state
    if (
      !isEmpty(defaultLocation) &&
      isEmpty(selectedLocation) &&
      !isEqual(defaultLocation, selectedLocation)
    ) {
      return {
        selectedLocation: defaultLocation,
      }
    }
    return null
  }

  getLocationNames = (locations) => {
    const locationNames = locations.map((it) =>
      Object.assign(
        {},
        {
          name: it.building,
        }
      )
    )

    return locationNames.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  render() {
    const { classes, locations } = this.props
    const { editContentVisible, selectedLocation } = this.state

    return (
      <div>
        {!editContentVisible ? (
          <Card className={classes.root}>
            <CardHeader
              title="Default Location"
              action={
                <IconButton onClick={this.handleEditIconClick}>
                  <Edit />
                </IconButton>
              }
            />
            <CardContent>
              <Typography>
                Your current location is marked as <b> {selectedLocation} </b>
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <Card className={classes.root}>
            <CardHeader title="Default Location" />
            <CardContent>
              <TextField
                className={classes.textField}
                onChange={(value) =>
                  this.handleLocationChange(value.target.value)
                }
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                  native: true,
                }}
                style={{ width: '200px' }}
                value={selectedLocation}
              >
                <option> None </option>
                {this.getLocationNames(locations).map((option) => (
                  <option value={option.name} key={option.name}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </CardContent>
            <CardActions>
              <Button color="primary" onClick={this.handleCancelClick}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleSaveClick}
              >
                Save
              </Button>
            </CardActions>
          </Card>
        )}
      </div>
    )
  }
}

ShipToCard.defaultProps = {
  locations: [],
  defaultLocation: '',
  onChangeShipToLocation: () => {},
  handleSaveClick: () => {},
  lanId: '',
}

ShipToCard.propTypes = {
  defaultLocation: propTypes.string,
  handleSaveClick: propTypes.func,
  lanId: propTypes.string,
  locations: propTypes.array,
}

export default withStyles(styles)(ShipToCard)
