import { Accordion, AccordionDetails } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'

const NewShipmentAccordion = ({
  newShipmentForm,
  shipmentAccordionSummary,
}) => {
  return (
    <Accordion defaultExpanded>
      {shipmentAccordionSummary}
      <Divider />

      <AccordionDetails style={{ padding: '25px' }}>
        {newShipmentForm}
      </AccordionDetails>
    </Accordion>
  )
}

export default NewShipmentAccordion
