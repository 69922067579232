import * as types from './actionType'
import { sortMyArray } from '../../util/Merge'
import * as orderMapper from '../../mappers/OrderMapper'
import {
  checkIsEnabledRow,
  getPegasusID,
  getSampleIDPrefix,
} from '../../util/CommonUtils'
import { getDisplayImage } from '../../util/CommonUtils'
import { DATE_FORMAT, convertTableDateFormat } from '../../util/CommonUtils'
import { findIndex, cloneDeep, filter, isEmpty, sortBy } from 'lodash'
import { SAMPLE, SCENIC } from 'enums/Tabs'

export const valueToCheckAgainst = {
  keyToCheckAgainst: 'isShippedEntity',
  value: false,
}

export const initialState = {
  alertIsVisible: false,
  alertMessage: '',
  checkBoxEnabled: true,
  clickableColumn: ['imageUrl', 'description'],
  columnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Pegasus ID',
      textAlign: 'right',
    },
    {
      id: 'imageUrl', // id: 'roomImageUrl',
      numeric: false,
      disablePadding: true,
      label: 'Scenic Image',
      textAlign: 'right',
    },
    {
      id: 'scenicDescription',
      numeric: false,
      disablePadding: true,
      label: 'Description',
      textAlign: 'right',
    },
    {
      id: 'createDate',
      numeric: true,
      disablePadding: false,
      label: 'Created On',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'scenicCategoryName',
      numeric: false,
      disablePadding: true,
      label: 'Category',
      textAlign: 'right',
    },
    {
      id: 'scenicSubcategoryName',
      numeric: false,
      disablePadding: false,
      label: 'Sub Category',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'scenicSourceName',
      numeric: false,
      disablePadding: true,
      label: 'Source',
      textAlign: 'right',
    },
    {
      id: 'scenicCost',
      numeric: false,
      disablePadding: true,
      label: 'Cost ($)',
      textAlign: 'right',
    },
    {
      id: 'scenicBundledView',
      numeric: false,
      disablePadding: true,
      label: 'Is Bundled',
      textAlign: 'right',
      // type: 'boolean',
    },
    {
      id: 'scenicId',
      label: 'Filemaker ID',
    },
  ],
  unavailableSampleColumnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Pegasus ID',
      textAlign: 'right',
    },
    {
      id: 'roomName',
      numeric: false,
      disablePadding: false,
      label: 'Room Name',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'scenicRoomId',
      numeric: false,
      disablePadding: true,
      label: 'Scenic Room ID',
      textAlign: 'right',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: true,
      label: 'Scenic Room Creator',
      textAlign: 'right',
    },
    {
      id: 'totalCost',
      numeric: false,
      disablePadding: false,
      label: 'Total Cost',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Current Status',
      textAlign: 'right',
    },
  ],
  data: [],
  goNextFlag: false,
  isVisible: false,
  unknownPegasusIds: [],
  item: {},
  numSelected: 0,
  onCheckBoxChange: () => {},
  onCheckBoxHeaderChange: () => {},
  onClick: () => {},
  order: 'asc',
  orderBy: 'pegasusIds',
  scenicRoomId: 0,
  scenicRoomStatus: '',
  response: false,
  page: 1,
  scenicRoomData: {},
  projectNames: [],
  shipToLocations: [],
  shipFromLocations: [],
  popupIsVisible: false,
  rowCount: 25,
  rowsPerPage: 25,
  searchField: '',
  selectedData: [],
  addSamplesRequestFailure: false,
  addSamplesRequestPending: false,
  scenicRoomCreateRequestFailure: false,
  scenicRoomCreateRequestPending: false,
  scenicRoomDeleteRequestFailure: false,
  scenicRoomDeleteRequestPending: false,
  scenicRoomUpdateRequestFailure: false,
  scenicRoomUpdateRequestPending: false,
  scenicRoomGetRequestFailure: false,
  scenicRoomGetRequestPending: false,
  scenicRoomGetRequestSuccess: false,
  removeSampleFailure: false,
  removeSamplePending: false,
  fetchLocationRequestFailure: false,
  fetchLocationRequestPending: false,
  addSamplesResponse: {},
  unavailableScenic: [],
  unavailableScenicRequestPending: false,
  unavailableScenicRequestFailure: false,
  unavailableSamplePopUpIsVisible: false,
}

function getProjectNames(projects) {
  const projectDetails = projects.map(function (obj) {
    return {
      projectDetail: obj.name.concat(
        ' - ',
        convertTableDateFormat(obj.shootStartDate, DATE_FORMAT),
        ' - ',
        convertTableDateFormat(obj.shootEndDate, DATE_FORMAT)
      ),
    }
  })
  return projectDetails.map((x) => x.projectDetail).join(', ')
}

export default function scenicRoomItems(state = initialState, action = {}) {
  switch (action.type) {
    case types.SCENIC_SET_CHECKBOX: {
      const {
        payload: { selected: payloadSelectedData = {} },
      } = action
      const { selectedData: overlaySelectedData = [] } = state
      const selectedIndex = overlaySelectedData.find(
        (obj) => obj.pegasusId === payloadSelectedData.pegasusId
      )
      let newSelected = []
      if (selectedIndex === -1 || !selectedIndex) {
        newSelected = newSelected.concat(
          overlaySelectedData,
          payloadSelectedData
        )
      } else {
        newSelected = overlaySelectedData.filter(
          (item) => item.pegasusId !== payloadSelectedData.pegasusId
        )
      }

      return Object.assign({}, state, {
        numSelected: newSelected.length,
        selectedData: newSelected,
      })
    }
    case types.SCENIC_SET_CHECKBOX_HEADER: {
      const { selectedData = [] } = state
      const {
        payload: { selected: selectedItems = {} },
      } = action
      let selectedIds = selectedItems.filter((item) =>
        checkIsEnabledRow(valueToCheckAgainst, item)
      )
      const selectedDiffCount = selectedData.filter(
        (i) =>
          findIndex(selectedIds, function (obj) {
            return obj.pegasusId === i.pegasusId
          }) !== -1
      ).length

      var newDataObj = [].concat.apply(selectedData, selectedIds)

      let newSelected = []
      if (selectedDiffCount === 0) {
        newSelected = newDataObj
      } else {
        newSelected = selectedData.filter(
          (i) =>
            findIndex(selectedIds, function (obj) {
              return obj.pegasusId === i.pegasusId
            }) === -1
        )
      }

      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.SCENIC_CREATE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        scenicRoomId: action.payload.data.scenicRoomId,
        goNextFlag: true,
        scenicRoomStatus: action.payload.data.status,
      })

    case types.SCENIC_CREATE_REQUEST_PENDING:
      return Object.assign({}, state, {
        scenicRoomCreateRequestPending: action.payload.pending,
      })

    case types.SCENIC_CREATE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        scenicRoomCreateRequestFailure: true,
      })

    case types.SCENIC_ITEM_GET_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload.scenicRoomItemsData,
        totalRowsCount: action.payload.total_count,
        scenicRoomGetRequestSuccess: true,
      })
    case types.SCENIC_GET_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        scenicRoomGetRequestSuccess: true,
        scenicRoomData: action.payload.scenicRoomData,
      })
    // TODO: Remove below props after re-checking
    // return Object.assign({}, state, {
    //   scenicRoomData: action.payload.scenicRoomData,
    //   scenicRoomId: action.payload.scenicRoomData.scenicRoomId,
    //   scenicRoomStatus: action.payload.scenicRoomData.status,
    //   scenicRoomGetRequestSuccess: true,
    // })

    case types.SCENIC_GET_REQUEST_PENDING:
      return Object.assign({}, state, {
        scenicRoomGetRequestPending: action.payload.pending,
        scenicRoomGetRequestSuccess: !action.payload.pending,
      })

    case types.SCENIC_GET_REQUEST_FAILURE:
      return Object.assign({}, state, {
        scenicRoomGetRequestFailure: true,
        scenicRoomGetRequestSuccess: false,
      })

    case types.SCENIC_DELETE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        goNextFlag: false,
        scenicRoomStatus: '',
      })

    case types.SCENIC_DELETE_REQUEST_PENDING:
      return Object.assign({}, state, {
        scenicRoomDeleteRequestPending: action.payload.pending,
      })

    case types.SCENIC_DELETE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        scenicRoomDeleteRequestFailure: true,
        goNextFlag: false,
      })
    case types.CLEAR_STATE_DATA:
      return Object.assign({}, state, {
        data: [],
      })

    case types.ADD_SCENIC_REQUEST_SUCCESS: {
      const projectName = []
      const actionPayloadData = action.payload.data || []
      const finalDataArr = sortMyArray(actionPayloadData, 'updateTs') || []
      const payloadData = finalDataArr.map((item) => ({
        ...item,
        imagePopupUrl: item.imageUrl,
        imageUrl:
          item.imageUrl !== undefined &&
          item.imageUrl !== null &&
          item.imageUrl !== ''
            ? getDisplayImage(item.imageUrl) // TODO PROPS ?
            : 'Image Not Found',
        currentProjects:
          item.projects !== null ? getProjectNames(item.projects) : null,
        projectsName:
          item.projects !== undefined &&
          item.projects !== null &&
          item.projects !== ''
            ? item.projects.map(function (obj) {
                return projectName.push({
                  projectId: obj.id,
                  projectName: obj.name,
                  projectChannel: obj.channel,
                })
              })
            : null,
      }))

      const uniqueProject = Array.from(
        new Set(state.projectNames.concat(projectName))
      ).reduce((acc, current) => {
        const x = acc.find((item) => item.projectId === current.projectId)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])

      const nonBundleData = filter(payloadData, function (o) {
        return !o.isBundle
      })
      const bundleData = filter(payloadData, function (o) {
        return o.isBundle
      })
      const updatedData = isEmpty(nonBundleData)
        ? state.data
        : [...nonBundleData, ...state.data]
      const addSamplesResponse = action.payload.isFlyout ? payloadData : {}

      const sortedData = sortBy(
        [...bundleData, ...updatedData],
        'addedToScenicRoomDate'
      )

      return Object.assign({}, state, {
        ...state,
        data: sortedData,
        projectNames: uniqueProject,
        addSamplesResponse,
      })
    }

    case types.ADD_SCENIC_REQUEST_PENDING:
      return Object.assign({}, state, {
        addSamplesRequestPending: action.payload.pending,
        addSamplesResponse: {},
      })

    case types.ADD_SCENIC_REQUEST_FAILURE:
      return Object.assign({}, state, {
        addSamplesRequestFailure: true,
        addSamplesResponse: {},
      })

    case types.UNAVAILABLE_SCENIC_SUCCESS: {
      const {
        payload: { data: payloadData = [] },
      } = action
      const unavailableScenic = payloadData.map((item) => {
        const { resource: itemResource = {} } = item
        const { pegasusId: itemPegasusId = '', type = SAMPLE } = itemResource
        return {
          ...item,
          resource: Object.assign({}, itemResource, {
            pegasusId: getSampleIDPrefix(itemPegasusId, type),
          }),
        }
      })
      return Object.assign({}, state, {
        ...state,
        unavailableScenic: unavailableScenic,
        unavailableSamplePopUpIsVisible: true,
      })
    }

    case types.UNAVAILABLE_SCENIC_PENDING:
      return Object.assign({}, state, {
        unavailableScenicRequestPending: action.payload.pending,
      })

    case types.UNAVAILABLE_SCENIC_FAILURE:
      return Object.assign({}, state, {
        unavailableScenicRequestFailure: true,
      })

    case types.SCENIC_UPDATE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        goNextFlag: false,
        scenicRoomStatus: '',
      })

    case types.SCENIC_UPDATE_REQUEST_PENDING:
      return Object.assign({}, state, {
        scenicRoomUpdateRequestPending: action.payload.pending,
      })

    case types.SCENIC_UPDATE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        scenicRoomUpdateRequestFailure: true,
      })

    case types.REMOVE_SAMPLE_REQUEST_SUCCESS: {
      const idSet = new Set(action.payload.data.map((item) => item.pegasusId))
      return Object.assign({}, state, {
        ...state,
        data: state.data.filter((sample) => {
          let shouldFilter =
            sample.isShippedEntity || !idSet.has(sample.pegasusId)
          return shouldFilter
        }),
        selectedData: [],
        numSelected: 0,
      })
    }

    case types.REMOVE_SAMPLE_REQUEST_PENDING:
      return Object.assign({}, state, {
        removeSamplePending: action.payload.pending,
      })

    case types.REMOVE_SAMPLE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        removeSampleFailure: true,
      })

    case types.FETCH_SHIP_FROM_LOCATION_REQUEST_SUCCESS: {
      const shipFromLocations = action.payload.data.data.buildings.sort(
        (a, b) => {
          const aBuilding = a.building?.toLowerCase()
          const bBuilding = b.building?.toLowerCase()
          if (aBuilding < bBuilding) {
            //sort string ascending
            return -1
          }
          if (aBuilding > bBuilding) {
            return 1
          }
          return 0 //default return value (no sorting)
        }
      )
      return Object.assign({}, state, {
        ...state,
        shipFromLocations,
      })
    }

    case types.FETCH_SHIP_TO_LOCATION_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        shipToLocations: action.payload.data.data.buildings,
      })
    }

    case types.FETCH_SHIP_LOCATION_REQUEST_PENDING:
      return Object.assign({}, state, {
        fetchLocationRequestPending: action.payload.pending,
      })

    case types.FETCH_SHIP_LOCATION_REQUEST_FAILURE:
      return Object.assign({}, state, {
        fetchLocationRequestFailure: true,
      })

    case types.SHOOT_SAMPLE_SET_PAGE: {
      return Object.assign({}, state, {
        page: action.payload.page,
      })
    }
    case types.SHOOT_SAMPLE_SET_ROWS_PER_PAGE: {
      return Object.assign({}, state, {
        rowsPerPage: action.payload.rowsPerPage,
      })
    }
    case types.SHOOT_SAMPLE_CLEAR_DATA:
      return Object.assign({}, state, {
        ...state,
        unknownPegasusIds: [],
        searchField: '',
      })
    case types.SHOOT_SAMPLE_SET_UNKNOWN_DATA:
      return Object.assign({}, state, {
        ...state,
        unknownPegasusIds: orderMapper.sampleIdsBuilder(
          action.payload.data,
          SCENIC
        ),
      })
    case types.SCENIC_SET_INVALID_IDS:{
      const newState={
        ...state,
        invalidScenicIds: action.payload
      }
      return newState
    }
    case types.SHOW_SCENIC_PAGE_ALERT: {
      const newState = {
        ...state,
        alertIsVisible: true,
        alertMessage: action.payload.message,
        response: true,
      }
      return newState
    }
    case types.CLOSE_SCENIC_PAGE_ALERT: {
      const newState = {
        ...state,
        alertIsVisible: false,
      }
      return newState
    }
    case types.SHOW_SCENIC_POPUP:
      return Object.assign({}, state, {
        ...state,
        popupIsVisible: true,
        item: action.payload.item,
      })

    case types.CLOSE_SCENIC_POPUP:
      return Object.assign({}, state, {
        ...state,
        popupIsVisible: false,
      })

    case types.CLOSE_UNAVAILABLE_SCENIC_POPUP:
      return Object.assign({}, state, {
        ...state,
        unavailableSamplePopUpIsVisible: false,
        unavailableScenic: [],
      })

    case types.RESET_NEW_SCENIC_ROOM_STATE:
      return Object.assign({}, initialState)

    case types.SET_REDIRECT_TO_NEW_SCENIC_ROOM:
      return Object.assign({}, state, {
        ...state,
        goNextFlag: action.payload.goNextFlag,
      })
    case types.SCENIC_CALL_EDIT_FIELDS_API: {
      const { data: stateData = [] } = state
      const {
        payload: { data: payloadData, sample_id },
      } = action
      const newDataObj = (stateData || []).map((obj) => {
        if (obj.pegasusId.indexOf(sample_id) !== -1) {
          obj = Object.assign({}, obj, payloadData)
        }
        return obj
      })
      return Object.assign({}, state, {
        ...state,
        data: newDataObj,
      })
    }
    case types.UPDATE_BUNDLED_QUANTITY: {
      const {
        payload: { pegasusId, updatedQuantity },
      } = action
      const { data } = state
      const dataList = cloneDeep(data)
      const updatedData = dataList.reduce((acc, data) => {
        if (
          !data.isShippedEntity &&
          getPegasusID(data.pegasusId) === pegasusId?.toString()
        ) {
          data.scenicRoomBundleCount = Number(updatedQuantity)
        }
        acc.push(data)
        return acc
      }, [])
      return Object.assign({}, state, {
        ...state,
        data: updatedData,
      })
    }
    default:
      return state
  }
}
