import { Button, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import { withStyles } from '@material-ui/core'
import ShowIf from './ShowIf'

const styles = () => ({
  imageUploadWrapper: {
    // paddingLeft: '25px',
  },
  uploadBtnInput: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  },
  uploadBtnWrapper: {
    marginTop: '250px',
  },
  removeBtn: {
    float: 'right',
    marginTop: '20px',
  },
})

const UploadAndDisplayImage = ({
  classes,
  disabled,
  selectedImage = null,
  setSelectedImage = () => {},
  disbaledScenicImage = false,
  uploadScenicAssetImage = () => {},
  fileSizeError = false,
}) => (
  <div className={classes.imageUploadWrapper}>
    <ShowIf condition={selectedImage}>
      <div>
        <img
          alt="not found"
          height="235px"
          width="405px"
          src={selectedImage ? URL?.createObjectURL(selectedImage) : ''}
        />
        <div className={classes.removeBtn}>
          <Button
            component="label"
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => setSelectedImage(null)}
          >
            Remove
          </Button>
        </div>
      </div>
    </ShowIf>
    <ShowIf condition={!selectedImage || fileSizeError}>
      <br />
      <br />

      <Button
        component="label"
        variant="contained"
        className={classes.uploadBtnWrapper}
        startIcon={<CloudUploadIcon />}
        disabled={disabled}
      >
        Upload Room Image
        <input
          type="file"
          accept={{
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png'],
          }}
          className={classes.uploadBtnInput}
          onChange={(event) => {
            setSelectedImage(
              event.target?.files[0],
              event.target?.files[0].size
            )
          }}
        />
      </Button>
      <br />
      <em>*Browse and select images from your desktop</em>
      <br />
      <Typography>OR</Typography>
      <br />
      <Button
        component="label"
        variant="contained"
        className={classes.uploadBtnWrapper}
        startIcon={<CloudUploadIcon />}
        disabled={disbaledScenicImage}
        onClick={(e) => uploadScenicAssetImage(true)}
      >
        Upload Existing Scenic Assets Image
      </Button>
      <br />
      <em>
        *Select a scenic asset using the checkbox provided next to them below
        and click on this button to upload the assets primary image as the
        room's image
      </em>
      <br />
        {fileSizeError && <Typography color="red">*File size is greated than 2MB</Typography>}
    </ShowIf>
  </div>
)

export default withStyles(styles)(UploadAndDisplayImage)
