import classNames from 'classnames'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import MoreVert from '@material-ui/icons/MoreVert'
import Typography from '@material-ui/core/Typography'
import * as propTypes from 'prop-types'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/core/styles'
import GetApp from '@material-ui/icons/GetApp'
import ListAltIcon from '@material-ui/icons/ListAlt'
import SettingsIcon from '@material-ui/icons/Settings'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'
import { isEmpty } from 'lodash'
import { Box } from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import { CircularProgress } from '@mui/material'

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 auto',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  searchExtended: {
    width: '400px',
  },
  title: {
    marginRight: '10px',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 250,
  },
  csDiv: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  emailInput: {
    width: '100% !important',
    marginLeft: '10%',
  },
  vendorEmailAutoComplete: {
    width: '350px',
    display: 'flex',
    alignItems: 'flex-end',
    overflowY: 'auto',
  },
  vendorEmailACText: {
    height: 'auto',
    width: '1000px',
  },
})

class FlexibleTableToolbar extends React.PureComponent {
  constructor(props) {
    super(props)
    const { savedSearchValue = '' } = this.props
    this.state = {
      searchOpen: false,
      apiSearchOpen: false,
      searchValue: savedSearchValue,
      apiSearchValue: '',
    }
    this.textInput = React.createRef()
    this.apiTextInput = React.createRef()
  }

  openSearchBar = () => {
    setTimeout(() => {
      this.textInput.current.focus()
    }, 300)
    this.setState({
      searchOpen: true,
    })
  }

  handleCloseSearch = () => {
    const { clearData, saveFilterValue = () => {} } = this.props
    this.setState(
      {
        searchOpen: false,
        searchValue: '',
      },
      () => {
        clearData()
        saveFilterValue('')
      }
    )
  }

  handleChange = (event) => {
    const {
      filterData,
      saveFilterValue = () => {},
      enableMultiSearch = false,
      saveSearchQuery = () => {},
    } = this.props
    let filterValue = event.target.value
    let searchQuery = filterValue
    if (enableMultiSearch) {
      try {
        searchQuery = filterValue
          ?.replace(/[/+//()/[\\]/g, '\\$&') // Escapes special characters
          ?.trim()
          .split(/[,\r\n]+/g) // Regex to split by comma and newline
          ?.filter(String) // removes empty string from search list
        const wholeString = searchQuery?.join(' ') // Add whole string in case space separated string was searched
        searchQuery.unshift(wholeString)
      } catch (e) {
        console.log('Error in special character search', e)
      }
    }
    saveSearchQuery(searchQuery)
    this.setState(
      {
        searchValue: filterValue,
      },
      () => {
        filterData(searchQuery)
        saveFilterValue(filterValue)
      }
    )
  }

  openAPISearchBar = () => {
    setTimeout(() => {
      this.apiTextInput.current.focus()
    }, 300)
    this.setState({
      apiSearchOpen: true,
    })
  }

  handleAPISearchChange = (event) => {
    const searchedValue = event.target.value || ''
    const { callSearchAPI = () => {} } = this.props
    this.setState(
      {
        apiSearchValue: searchedValue,
      },
      () => {
        callSearchAPI(searchedValue)
      }
    )
  }

  handleAPISearchClose = (event) => {
    const { callSearchAPI = () => {} } = this.props
    this.setState(
      {
        apiSearchOpen: false,
        apiSearchValue: '',
      },
      () => {
        callSearchAPI()
      }
    )
  }
  renderSuggestion = (suggestion) => <div> {suggestion.name} </div>
  renderApiSearchBox = () => {
    const { enableAPISearch } = this.props
    const { apiSearchOpen, apiSearchValue = '' } = this.state
    return enableAPISearch ? (
      <div>
        <Tooltip
          style={{
            display: !apiSearchOpen ? 'block' : 'none',
          }}
          title="Search list"
        >
          <IconButton aria-label="Search list" onClick={this.openAPISearchBar}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <TextField
          placeholder="Search"
          value={apiSearchValue}
          onChange={this.handleAPISearchChange}
          style={{ display: apiSearchOpen ? 'inline' : 'none' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Clear search"
                  onClick={this.handleAPISearchClose}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputRef={this.apiTextInput}
        />
      </div>
    ) : (
      ''
    )
  }

  render() {
    const {
      classes,
      enableSearch,
      enableDownload,
      enableMenu,
      enableSettings,
      enableMultiSearch,
      tableTitle,
      tableTitleVariant = 'h6',
      downloadFunction,
      downloadCSFunction,
      downloadCSFunctionPDF,
      menuClickFunction,
      settingsClickFunction,
      enableContactSheetDownload,
      additionalActions = false,
      enableDisclaimer = false,
      downloadLoadingStatus = false,
      downloadLoadingStatusPdf = false,
      downloadLoadingStatusXlsx = false,
      additionalFiltersContent = () => {
        return <div></div>
      },
    } = this.props
    const { searchOpen, searchValue } = this.state
    return (
      <Toolbar className={classes.root}>
        <div className={classes.title}>
          <Typography variant={tableTitleVariant} data-cy="tableTitle">
            {tableTitle}
          </Typography>
        </div>
        {additionalActions && (
          <div className={classes.actions}>{additionalFiltersContent()}</div>
        )}
        <div className={classes.spacer} />
        {enableDisclaimer ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {enableDisclaimer}
          </div>
        ) : (
          <div />
        )}
        <div className={classes.enableAPISearch}>
          {this.renderApiSearchBox()}
        </div>
        <div className={classes.actions}>
          {enableSearch ? (
            <div>
              <Tooltip
                style={{
                  display: searchOpen === false ? 'block' : 'none',
                  float: 'right',
                }}
                title="Search list"
              >
                <IconButton
                  aria-label="Search list"
                  onClick={this.openSearchBar}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>

              <Box
                className={classNames(classes.expand, {
                  [classes.searchExtended]: enableMultiSearch,
                })}
              >
                <TextField
                  data-cy="searchText"
                  placeholder="Search"
                  fullWidth
                  multiline
                  value={searchValue}
                  onChange={this.handleChange}
                  style={{
                    display:
                      searchOpen || !isEmpty(searchValue) ? 'inline' : 'none',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Clear search"
                          onClick={this.handleCloseSearch}
                          data-cy="clearSearch"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputRef={this.textInput}
                />
              </Box>
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className={classes.actions}>
          {enableDownload ? (
            <div>
              <Tooltip title="Download">
                <LoadingButton
                  loading={downloadLoadingStatus}
                  loadingIndicator={
                    <CircularProgress
                      color="primary"
                      style={{ color: '#2F4F4F' }}
                      size={50}
                    />
                  }
                >
                  <IconButton
                    data-cy="downloadAll"
                    id="downloadAll"
                    aria-label="Download"
                    onClick={downloadFunction}
                  >
                    <GetApp />
                  </IconButton>
                </LoadingButton>
              </Tooltip>
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className={classes.csDiv}>
          {enableContactSheetDownload ? (
            <>
              <div>
                <Tooltip title="Contact Sheet XLSX">
                  <LoadingButton
                    loading={downloadLoadingStatusXlsx}
                    loadingIndicator={
                      <CircularProgress
                        color="primary"
                        style={{ color: '#2F4F4F' }}
                        size={50}
                      />
                    }
                  >
                    <IconButton
                      data-cy="contactSheetDownload"
                      id="contactSheetDownload"
                      aria-label="Contact Sheet XLSX Download"
                      onClick={downloadCSFunction}
                    >
                      <ListAltIcon />
                    </IconButton>
                  </LoadingButton>
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Contact Sheet PDF">
                  <LoadingButton
                    loading={downloadLoadingStatusPdf}
                    loadingIndicator={
                      <CircularProgress
                        color="primary"
                        style={{ color: '#2F4F4F' }}
                        size={50}
                      />
                    }
                  >
                    <IconButton
                      id="contactSheetDownloadPDF"
                      aria-label="Contact Sheet PDF Download"
                      onClick={downloadCSFunctionPDF}
                    >
                      <PictureAsPdfOutlinedIcon />
                    </IconButton>
                  </LoadingButton>
                </Tooltip>
              </div>
            </>
          ) : (
            <div />
          )}
        </div>
        <div className={classes.actions}>
          {enableMenu ? (
            <div>
              <Tooltip title="Options">
                <IconButton
                  aria-label="Options"
                  onClick={(event) => {
                    menuClickFunction(event)
                  }}
                >
                  <MoreVert />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className={classes.actions}>
          {enableSettings ? (
            <div>
              <Tooltip title="Column Settings">
                <IconButton
                  aria-label="Column Settings"
                  onClick={(event) => {
                    settingsClickFunction(event)
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div />
          )}
        </div>
      </Toolbar>
    )
  }
}

FlexibleTableToolbar.propTypes = {
  clearData: propTypes.func,
  enableSearch: propTypes.bool,
  enableAPISearch: propTypes.bool,
  callSearchAPI: propTypes.func,
  enableDownload: propTypes.bool,
  enableContactSheetDownload: propTypes.bool,
  enableMenu: propTypes.bool,
  enableSettings: propTypes.bool,
  filterData: propTypes.func,
  tableTitle: propTypes.string,
  additionalActions: propTypes.bool,
  saveFilterValue: propTypes.func,
  enableMultiSearch: propTypes.bool,
  autoCompleteOptions: propTypes.array,
}

export default withStyles(styles)(FlexibleTableToolbar)
