import * as types from './actionType'
import { cloneDeep, findIndex } from 'lodash'
import { getDisplayImage } from 'util/CommonUtils'

export const initialState = {
  data: [],
  uploadFiles: [],
  propState: '',
}

export default function addPropReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADDPROP_FETCH_DATA_SUCCESS: // Request has succeeded and we can set the data state
      return Object.assign({}, state, {
        ...state,
        data: action?.payload?.data || [],
      })
    case types.ADDPROP_CLEAR_DATA: // Data reset to initial state
      return Object.assign({}, state, {
        ...state,
        data: [],
      })
    case types.ADDPROP_FILE_UPLOAD_PROGRESS: {
      const { uploadFiles = [] } = state
      const uploadFilesMok = cloneDeep(uploadFiles)
      const {
        payload: { pegasusId = '', fileName = '', status = '', imageUrl = '' },
      } = action
      const fileExists = findIndex(uploadFilesMok, {
        pegasusId: pegasusId,
        fileName: fileName,
      })
      const renderImage = getDisplayImage(imageUrl)
      fileExists !== -1
        ? uploadFilesMok?.map((obj) => {
            if (obj?.pegasusId === pegasusId && obj?.fileName === fileName) {
              obj.status = status
              obj.imageUrl = renderImage
              obj.imageUrlOriginal = imageUrl
            }
            return obj
          })
        : uploadFilesMok?.push({
            pegasusId: pegasusId,
            fileName: fileName,
            status: status,
            imageUrl: renderImage,
            imageUrlOriginal: imageUrl,
            name: fileName,
          })

      return Object.assign({}, state, {
        ...state,
        uploadFiles: cloneDeep(uploadFilesMok),
      })
    }
    case types.ADDPROP_REMOVE_FILE_FROM_UPLOADLIST: {
      const { uploadFiles = [] } = state
      const {
        payload: { pegasusId = '', fileNames = [] },
      } = action
      const uploadFilesMok = cloneDeep(uploadFiles)
      const newUploadFilesMok = pegasusId
        ? uploadFilesMok?.filter(
            (obj) =>
              obj.pegasusId === pegasusId &&
              fileNames.indexOf(obj.fileName) === -1
          )
        : uploadFiles
      return Object.assign({}, state, {
        uploadFiles: cloneDeep([...newUploadFilesMok]) || [],
      })
    }
    case types.ADD_PROP_STATE: {
      return Object.assign({}, state, {
        ...state,
        propState: action?.payload?.propState,
      })
    }
    case types.RESET_ADDPROP_DATA: {
      return initialState
    }
    default:
      return state
  }
}
