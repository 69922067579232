import { createContext, useContext } from 'react'

const ScenicRoomContext = createContext(null)

export function useScenicRoomContext() {
  const context = useContext(ScenicRoomContext)
  if (!context) {
    throw new Error('Empty context')
  }
  return context
}

export default ScenicRoomContext
