import CircularPageLoader from 'components/Loader/CircularPageLoader'
import FlexibleTable from 'components/EnhancedTable/FlexibleTable'
import TabbedPopUp from 'components/PopUp/TabbedPopUp'
import {
  dataTabLabelsShipped,
  dataTabLabelsShippedWithProps,
} from 'enums/tableTabs'
import { makeTabTables, getTitle } from 'util/Table'
import { withStyles } from '@material-ui/core/styles'
import { valueToCheckAgainst } from 'store/scenicRoomItems/reducer'
import { SCENIC } from 'enums/Tabs'
import { createCsvUtil } from 'pages/SearchInventoryPage/SearchUtils'

const FlexibleTableNew = withStyles((theme) => ({
  root: {
    boxShadow: 'unset !important',
  },
  container: {
    maxHeight: 'unset !important',
  },
  header: {
    backgroundColor: 'unset',
    fontWeight: 700,
  },
}))(FlexibleTable)

const ScenicRoomTableData = ({
  addSamplesRequestPending,
  checkBoxEnabled,
  columnData,
  renderData,
  onClick,
  onEditChange,
  numSelected,
  onCheckBoxChange,
  onCheckBoxHeaderChange,
  page,
  rowsPerPage,
  selectedData,
  setPage,
  setRowsPerPage,
  showPopup,
  clickableColumn,
  isReuseScenicRoom = false,
  isScenicRoomInfoReadOnly,
  isShipped = false,
  dataScenicRoomId = '',
  popupIsVisible,
  popupItem = {},
  totalRowsCount = 0,
  closePopup = () => {},
  updateSampleFieldsData = () => {},
  classes = {},
  downloadContactSheet = () => {},
}) => {
  const dataFieldKeys = columnData.map((column) => column.id)

  const onSelected = (selectedData = [], selected = {}) => {
    const { pegasusId: selectedPSID = '' } = selected
    return selectedData.some((obj) => {
      return !selected.isShippedEntity ? obj.pegasusId === selectedPSID : false
    })
  }
  let readOnlyProps = {}

  const downloadCSFunction =
    (scenicRoomId = '', downloadType = '') =>
    () => {
      const paramsCopy = {
        include_filters: {},
        exclude_filters: {},
      }

      const itemType = SCENIC

      downloadContactSheet(
        Object.assign({}, paramsCopy, {
          ...paramsCopy,
          include_filters: {
            scenic_room_ids: [scenicRoomId],
          },
        }),
        downloadType,
        itemType
      )
    }

  const itemType = SCENIC

  let downloadCSVFunction = downloadCSFunction(dataScenicRoomId, 'CSVSHIP')
  if (itemType === SCENIC) {
    // TODO: CSVSHIP implementation pending from BE
    downloadCSVFunction = downloadCSFunction(dataScenicRoomId, 'CSV')
  } else if (itemType === 'COMBINED') {
    // TODO: FOR COMBINED CSV use BE download, now using FE download
    downloadCSVFunction = (e) => {
      const csvData = new Blob([createCsvUtil(renderData)], {
        type: 'text/csv',
      })
      const csvUrl = URL.createObjectURL(csvData)
      const link = document.createElement('a')
      link.href = csvUrl
      link.setAttribute('download', 'Room.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const isScenicRoomTableEditable = isShipped ? isReuseScenicRoom : true
  if (!isScenicRoomTableEditable) {
    readOnlyProps = {
      checkBoxEnabled: false,
    }
  }

  const renderTabbedPopUp = () => {
    const { type: itemType = '' } = popupItem
    const tabsList =
      itemType === SCENIC ? dataTabLabelsShippedWithProps : dataTabLabelsShipped
    return (
      <TabbedPopUp
        dataTabLabels={tabsList}
        isVisible={popupIsVisible}
        data={makeTabTables(
          popupItem,
          classes,
          tabsList,
          updateSampleFieldsData
        )}
        onClose={closePopup}
        title={getTitle(popupItem)}
        fullWidth={true}
      />
    )
  }

  return (
    <>
      <div>
        {addSamplesRequestPending ? (
          <div>
            <CircularPageLoader open={addSamplesRequestPending} />
          </div>
        ) : (
          <div style={{ marginTop: '34px' }}>
            <FlexibleTableNew
              cascadeFieldKeys={[]}
              checkBoxEnabled={checkBoxEnabled}
              columnData={columnData}
              data={renderData}
              dataFieldKeys={dataFieldKeys}
              onClick={onClick}
              onEditChange={onEditChange}
              editEnabled={false}
              numSelected={numSelected}
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
              onSelected={onSelected}
              page={page}
              rowCount={renderData.length}
              rowsPerPage={rowsPerPage}
              selected={selectedData}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              showPopup={showPopup}
              paginationAtApiLevel={true}
              clickableColumn={clickableColumn}
              enableSearch
              enableMultiSearch
              enableSpacer={false}
              valueToCheckAgainst={valueToCheckAgainst}
              disableItems
              enableDownload
              enableContactSheetDownload
              downloadFunction={downloadCSVFunction}
              downloadCSFunction={downloadCSFunction(dataScenicRoomId, 'XLSX')}
              downloadCSFunctionPDF={downloadCSFunction(
                dataScenicRoomId,
                'PDF'
              )}
              totalRowsCount={totalRowsCount}
              {...readOnlyProps}
            />
            {!!popupIsVisible && renderTabbedPopUp()}
          </div>
        )}
      </div>
    </>
  )
}

export default ScenicRoomTableData
