import { PureComponent } from 'react'
import * as propTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  IconButton,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: '0px 8px',
    // color: 'white',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

class PopUp extends PureComponent {
  handleCancel = () => {
    const { onClose } = this.props
    onClose()
  }

  handleProceed = () => {
    const { onProceed } = this.props
    onProceed()
  }

  render() {
    const {
      accessibilityDescription,
      classes,
      content,
      isVisible,
      title,
      isButtonVisible,
      isButtonDisabled = false,
      isCancelButtonDisabled = false,
      maxWidth = 'sm',
      fullWidth = false,
      primaryButtonText = 'ADD ANYWAY',
      secondaryButtonText = 'OK',
      onPopupClose,
    } = this.props

    return (
      <Dialog
        aria-labelledby={title}
        aria-describedby={accessibilityDescription}
        scroll="paper"
        open={isVisible}
        onClose={onPopupClose}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        {/* <DialogTitle>{title}</DialogTitle> */}
        <DialogTitle>
          <Typography variant="h6">{title}</Typography>
          {onPopupClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onPopupClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button
            className={classes.defaultButton}
            variant="outlined"
            color="primary"
            id="confirmDialog"
            onClick={this.handleCancel}
            disabled={isCancelButtonDisabled}
          >
            {secondaryButtonText}
          </Button>
          {isButtonVisible ? (
            <Button
              className={classes.defaultButton}
              variant="contained"
              color="primary"
              id="proceedDialog"
              onClick={this.handleProceed}
              disabled={isButtonDisabled}
            >
              {primaryButtonText}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    )
  }
}

PopUp.defaultProps = {
  isVisible: false,
  title: '',
  accessibilityDescription: '',
  onClose: () => {},
  onProceed: () => {},
}

PopUp.propTypes = {
  isVisible: propTypes.bool,
  title: propTypes.string,
  accessibilityDescription: propTypes.string,
  content: propTypes.object,
  onClose: propTypes.func,
  onProceed: propTypes.func,
  isButtonVisible: propTypes.bool,
  isButtonDisabled: propTypes.bool,
  isCancelButtonDisabled: propTypes.bool,
}

export default withStyles(styles)(PopUp)
