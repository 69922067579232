import axios from 'axios'
import apiConfig from '../apiConfig'
import { ShootTypes } from '../enums/shootTypes'
import { getPegasusID } from 'util/CommonUtils'

const {
  managements: { url = '' },
  apiKey = '',
} = apiConfig

export const addSamples = (
  shootTypeId,
  shootType,
  pegasusIds,
  projectName,
  projectChannel,
  collectionName,
  checkFlag = false,
  propOrPegasusIds
) => {
  if (shootType === ShootTypes.PROJECT) {
    const requestPayload = {
      pegasus_ids: pegasusIds,
      check_required: checkFlag,
      name: projectName,
      channel: projectChannel,
    }
    if (propOrPegasusIds && propOrPegasusIds.length > 0) {
      requestPayload.prop_ids = propOrPegasusIds
    }
    return axios.put(
      url + 'projects/' + shootTypeId + '/add_samples',
      requestPayload,
      {
        params: {
          key: apiKey,
        },
      }
    )
  } else {
    return axios.put(
      url + 'collections/' + shootTypeId + '/add_samples',
      {
        pegasus_ids: pegasusIds.map((id) => getPegasusID(id)), // Only remove prefix if present
        check_required: checkFlag,
        name: collectionName,
      },
      {
        params: {
          key: apiKey,
        },
      }
    )
  }
}

export const removeSamples = (selectedData, shootType, shootTypeId) => {
  const pegasusIds = selectedData.map((data) => data.pegasusId)
  if (shootType === ShootTypes.PROJECT) {
    return axios.put(
      url + 'projects/' + shootTypeId + '/remove_samples',
      {
        pegasus_ids: pegasusIds.map((id) => id.slice(2, id.length)),
      },
      {
        params: {
          key: apiKey,
        },
      }
    )
  } else {
    return axios.put(
      url + 'collections/' + shootTypeId + '/remove_samples',
      {
        pegasus_ids: pegasusIds.map((id) => id.slice(2, id.length)),
      },
      {
        params: {
          key: apiConfig.apiKey,
        },
      }
    )
  }
}
