import { getSubCategories } from 'util/CommonUtils'

export const getAddFilterOptions =
  (pageName = '') =>
  ({ autoOrderFilters = {} }) => {
    return pageName === 'RequestedSamples'
      ? autoOrderFilters?.requestedFilterOptions
      : pageName === 'Props'
        ? autoOrderFilters.propsFiltersOptions
        : pageName === 'OrderedSamples'
          ? autoOrderFilters?.orderedSamplesOptions
          : autoOrderFilters?.addFilterOptions
  }

export const getAllBrandOptions =
  () =>
  ({ autoOrderFilters = {} }) => {
    return autoOrderFilters?.allBrands
  }

export const getAllDepartmentsOptions =
  () =>
  ({ autoOrderFilters = {} }) => {
    return autoOrderFilters?.allDepartments
  }
export const getAllCategoryOptions =
  () =>
  ({ searchInventory = {} }) => {
    return searchInventory?.propsMetadata?.categories
  }

export const getFilterOptionsByFilter =
  () =>
  ({ autoOrderFilters = {}, searchInventory = {} }) => {
    const propsCategories = searchInventory?.propsMetadata?.categories?.map(
      (obj) =>
        Object.assign({}, obj, {
          displayName: obj.label || obj.displayName,
          label: obj.value || obj.label,
        })
    )
    const propsSubCategories =
      searchInventory?.propsMetadata?.subCategories?.map((mobj) =>
        Object.assign({}, mobj, {
          label: `${mobj.value || mobj.label}`,
          value: `${mobj?.categoryId}-${mobj.value || mobj.label}`,
          displayName: mobj?.categoryId
            ? `${mobj?.categoryName} - ${mobj.label || mobj.displayName || ''}`
            : `${mobj?.categoryName}`,
          group_by: mobj?.categoryName
            ? `${mobj?.categoryId} - ${mobj?.categoryName}`
            : `${mobj?.categoryId}`,
          group_by_id: mobj?.categoryId,
          id: `${mobj?.categoryId}-${mobj.value || mobj.label}`,
        })
      )
    const propsColors = searchInventory?.propsMetadata?.colorFamily?.map(
      (obj) =>
        Object.assign({}, obj, {
          displayName: obj.label || obj.displayName,
          label: obj.value || obj.label,
        })
    )
    const itemCategories = searchInventory?.originalPropsMetadata?.categories
    const itemCatSubCatList = getSubCategories(itemCategories)
    return Object.assign({}, autoOrderFilters?.filterOptionsByFilter, {
      Category: propsCategories,
      'Sub Category': propsSubCategories || [],
      orgPropSubcategory: itemCatSubCatList,
      'Props Color': propsColors || [],
      Location: searchInventory?.locationFilterData || []
    })
  }

export const getSelectedFilters =
  (pageName = '') =>
  ({ autoOrderFilters = {} }) => {
    const autoOrderFiltersCopy = Object.assign({}, autoOrderFilters)
    const { selectedFilters = [] } = autoOrderFiltersCopy
    return selectedFilters[pageName] || []
  }
