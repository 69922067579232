import { Accordion, AccordionDetails } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'

const NewScenicRoomAccordion = ({
  newScenicRoomForm,
  scenicRoomAccordionSummary,
}) => {
  return (
    <Accordion defaultExpanded>
      {scenicRoomAccordionSummary}
      <Divider />

      <AccordionDetails style={{ padding: '25px' }}>
        {newScenicRoomForm}
      </AccordionDetails>
    </Accordion>
  )
}

export default NewScenicRoomAccordion
