import * as types from './actionType'
import * as itemApi from '../../services/itemService'
import * as ordersApi from '../../services/ordersService'
import * as shipmentApi from '../../services/shipmentService'
import * as orderMapper from '../../mappers/OrderMapper'
import * as shipmentMapper from '../../mappers/shipmentMapper'
import * as itemMapper from '../../mappers/itemMapper'
import { showNotification } from '../../store/notification/actionCreator'
import { isEmpty } from 'lodash'
export function getAutoOrderSamples(params) {
  return (dispatch, getState = () => {}) => {
    const currentState = getState() || {}
    const {
      autoOrderSamples: { totalRowsCount: currentTotalRows = Number(0) } = {},
    } = currentState
    const { requestParams = {} } = params
    const currentPage = requestParams.get('page')
    if (currentPage > 1 && Number(currentTotalRows) > Number(100)) {
      params?.requestParams?.append('total', currentTotalRows)
    }
    let filterNFList = {}
    let totalRowsCount
    dispatch(getAutoOrderSamplesPending(true))
    ordersApi
      .fetchSamplesByUserPost(params)
      .then((orderResult) => {
        const {
          data: { total_count = 0, filter_list_notfound = {} },
        } = orderResult
        filterNFList = Object.assign({}, filter_list_notfound)
        totalRowsCount = total_count
        return orderMapper.samplesResponseToSamples(orderResult)
      })
      .then((samples) => {
        shipmentApi
          .fetchShipments(
            samples
              .map((it) => it.shipmentId)
              .filter((shipmentId) => shipmentId)
          )
          .then((shipmentResult) =>
            shipmentMapper.shipmentsResponseToShipments(
              shipmentResult.data.shipments
            )
          )
          .then((shipments) => {
            const listOfTcins = samples
              .map((it) => it.tcin)
              .filter((tcin) => !!tcin)
            const finalListOfTcins = Array.from(new Set([...listOfTcins]))
            let shipmentMap = new Map(
              shipments.map((shipment) => [shipment.shipmentId, shipment])
            )
            let orderResults = [].concat.apply(
              samples.map((order) =>
                Object.assign({}, order, shipmentMap.get(order.shipmentId))
              )
            )

            if (isEmpty(finalListOfTcins) || !finalListOfTcins[0]) {
              // Handling for props with no tcins
              dispatch(getAutoOrderSamplesSuccess(orderResults))
              dispatch(setFilterNFList(filterNFList))
              dispatch(setTotalRowsCount(totalRowsCount))
            } else {
              return itemApi
                .getItemsGraphQL(finalListOfTcins)
                .then((itemResults) =>
                  itemMapper.itemsResponseToItems(itemResults)
                )
                .then((items) => {
                  let itemMap = new Map(
                    items.map((item) => [item.tcin.toString(), item])
                  )
                  orderResults = [].concat.apply(
                    orderResults.map((order) =>
                      Object.assign(
                        {},
                        order,
                        itemMap.get(order.tcin.toString())
                      )
                    )
                  )
                  dispatch(getAutoOrderSamplesSuccess(orderResults))
                  dispatch(setFilterNFList(filterNFList))
                  dispatch(setTotalRowsCount(totalRowsCount))
                })
            }
          })
      })
      .catch((err) => {
        dispatch(getAutoOrderSamplesFailure())
      })
      .finally(() => {
        dispatch(getAutoOrderSamplesPending(false))
      })
  }
}

export function removeAutoOrderSamples(samples = [], callBack = () => {}) {
  return (dispatch) => {
    dispatch(removeAutoOrderSamplesPending(true))
    ordersApi
      .removeAutoOrderSamples(samples)
      .then((response = {}) => {
        callBack()
        dispatch(showNotification(true, 'Successfully deleted', 'success'))
        dispatch(removeAutoOrderSamplesFulfilled(true))
      })
      .catch((error) => {
        dispatch(removeAutoOrderSamplesFulfilled(false))
        dispatch(showNotification(true, 'Failed to delete items'))
      })
      .finally(() => {
        dispatch(removeAutoOrderSamplesPending(false))
      })
  }
}

export function getSizes() {
  return (dispatch) => {
    ordersApi
      .getSizesApi()
      .then((response = {}) => {
        const { data = {} } = response
        !isEmpty(data) &&
          data.unshift({
            name: 'ANY',
            status: 'Active',
          })
        dispatch(updateSizeData(data))
      })
      .catch((error) => {
        dispatch(showNotification(true, 'Failed to load items'))
      })
  }
}
export function updateSizeData(data = []) {
  return {
    type: types.UPDATE_SIZE_DATA,
    payload: data,
  }
}
export function refreshAutoOrderSamples(status) {
  return {
    type: types.REFRESH_AUTO_ORDER_SAMPLES,
    payload: { status: status },
  }
}

export function removeAutoOrderSamplesPending(pending = false) {
  return {
    type: types.REMOVE_AUTO_ORDER_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export function removeAutoOrderSamplesFulfilled(pending = false) {
  return {
    type: types.REMOVE_AUTO_ORDER_SAMPLES_FULFILLED,
    payload: { status: pending },
  }
}

export function getAutoOrderSamplesPending(pending = false) {
  return {
    type: types.GET_AUTO_ORDER_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export function getAutoOrderSamplesFailure() {
  return {
    type: types.GET_AUTO_ORDER_SAMPLES_FAILURE,
  }
}

export function setPage(page) {
  return {
    type: types.AUTO_ORDER_SET_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage) {
  return {
    type: types.AUTO_ORDER_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function setTotalRowsCount(totalRowsCount = 0) {
  return {
    type: types.AUTO_ORDER_SET_TOTAL_ROWS_COUNT,
    payload: { totalRowsCount: totalRowsCount },
  }
}

export function getAutoOrderSamplesSuccess(data = []) {
  return {
    type: types.GET_AUTO_ORDER_SAMPLES_SUCCESS,
    payload: { data: data },
  }
}

export function setFilterNFList(filterNFList = {}) {
  return {
    type: types.AUTO_ORDER_SET_FILTER_NOTFOUND_LIST,
    payload: { filterNFList: filterNFList },
  }
}

export function setCheckBox(selected) {
  return {
    type: types.AUTO_ORDER_SET_CHECKBOX,
    payload: { selected: selected },
  }
}

export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.AUTO_ORDER_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}

export function unsetSelectedData() {
  return {
    type: types.UNSET_SELECTED_DATA,
  }
}

export function resetFetchDataRequestSuccess() {
  return {
    type: types.RESET_FETCH_DATA_REQUEST_SUCCESS,
  }
}

export function fetchAutoOrderLoading(data = false) {
  return {
    type: types.FETCH_AUTO_ORDER_LOADING,
    payload: data,
  }
}

export function fetchAutoOrderGridSample(params, fetchLocation = '') {
  return (dispatch) => {
    dispatch(fetchAutoOrderLoading(true))
    return ordersApi
      .fetchAutoOrderGrid()
      .then((res) => {
        dispatch(
          setAutoOrderSamplesSuccess(
            orderMapper.autoOrderSamplesResponseToSamples(res),
            params
          )
        )
        dispatch(fetchAutoOrderLoading(false))
      })
      .catch((error) => {
        dispatch(fetchAutoOrderLoading(false))
        dispatch(fetchAutoOrderGridSampleFailure())
      })
  }
}

export function setAutoOrderSamplesSuccess(data = [], filters = {}) {
  return {
    type: types.FETCH_AUTOORDER_SAMPLES_SUCCESS,
    payload: { autoOrderSamples: data, filters },
  }
}

export function fetchAutoOrderGridSampleFailure() {
  return {
    type: types.FETCH_AUTOORDER_SAMPLES_FAILURE,
  }
}

export function openAutoOrder(data = '') {
  return {
    type: types.AUTO_ORDER_OPEN,
    payload: data,
  }
}
