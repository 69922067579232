import * as types from './actionType'
import * as inventoryApi from '../../services/inventoryService'
import * as itemApi from '../../services/itemService'
import { showNotification } from '../notification/actionCreator'
import { isEmpty } from 'lodash'
import { addLeadingZeros } from '../../util/Filter'
export function getAllBrands() {
  return (dispatch) => {
    dispatch(getAllBrandsRequestPending(true))
    return inventoryApi
      .getAllBrands()
      .then((response = {}) => {
        const {
          data: { brand_names = [] },
        } = response
        dispatch(getAllBrandsRequestPending(false))
        dispatch(getAllBrandsRequestSuccess(brand_names))
      })
      .catch(() => {
        dispatch(getAllBrandsRequestPending(false))
        dispatch(showNotification(true, 'Unable to get brands'))
      })
  }
}

export function getAllDepartments() {
  return (dispatch) => {
    dispatch(getAllDepartmentsRequestPending(true))
    return itemApi
      .getAllDepartments()
      .then((response = {}) => {
        let {
          data: { departments = [] },
        } = response

        const formattedDepts = departments.map((obj = {}) => {
          const { department_id } = obj
          return Object.assign({}, obj, {
            org_department_id: department_id,
            department_id: addLeadingZeros(String(department_id), 3),
          })
        })
        dispatch(getAllDepartmentsRequestPending(false))
        dispatch(getAllDepartmentsRequestSuccess(formattedDepts))
      })
      .catch(() => {
        dispatch(getAllDepartmentsRequestPending(false))
        dispatch(showNotification(true, 'Unable to get departments'))
      })
  }
}
export function editSubmit(requestBody = [], refreshAutoOrderGrid = () => {}) {
  return (dispatch) => {
    dispatch(getAllDepartmentsRequestPending(true))
    return itemApi
      .editAutoOrder(requestBody)
      .then((response = {}) => {
        dispatch(editFlyOut(false))
        dispatch(showNotification(true, 'Data Updated Succesfully', 'success'))
        refreshAutoOrderGrid()
      })
      .catch(() => {
        dispatch(showNotification(true, 'We encountered an error'))
      })
  }
}
export function getClassByMultiDepartment(deptId = []) {
  return (dispatch) => {
    dispatch(getClassByDepartmentRequestPending(true))
    const allDeptApis = deptId.map((obj) => {
      return itemApi.getClassByDepartment(obj)
    })
    Promise.all(allDeptApis)
    return Promise.all(allDeptApis)
      .then((response = []) => {
        let finalClassList = []
        response.map((resObj) => {
          let {
            data: { classes = [] },
          } = resObj
          finalClassList = [...finalClassList, ...classes]
          return resObj
        })
        const formattedClasses = finalClassList.map((obj = {}) => {
          const { class_id, department_id } = obj
          return Object.assign({}, obj, {
            class_id: addLeadingZeros(String(class_id), 2),
            department_id: addLeadingZeros(String(department_id), 3),
            org_department_id: department_id,
            org_class_id: class_id,
          })
        })
        dispatch(getClassByDepartmentRequestPending(false))
        dispatch(getClassByDepartmentRequestSuccess(formattedClasses))
      })
      .catch(() => {
        dispatch(getClassByDepartmentRequestPending(false))
        dispatch(showNotification(true, 'Unable to get classes'))
      })
  }
}

export function getClassByDepartment(deptId = '') {
  return (dispatch) => {
    dispatch(getClassByDepartmentRequestPending(true))
    return itemApi
      .getClassByDepartment(deptId)
      .then((response = {}) => {
        let {
          data: { classes = [] },
        } = response
        dispatch(getClassByDepartmentRequestPending(false))
        dispatch(getClassByDepartmentRequestSuccess(classes))
      })
      .catch(() => {
        dispatch(getClassByDepartmentRequestPending(false))
        dispatch(showNotification(true, 'Unable to get classes'))
      })
  }
}

export function getAllBrandsRequestPending(pending = false) {
  return {
    type: types.GET_AUTO_ORDER_BRANDS_PENDING,
    payload: { pending: pending },
  }
}
export function editFlyOut(data = false, message = '') {
  return {
    type: types.EDIT_FLYOUT,
    payload: data,
  }
}
export function getAllBrandsRequestSuccess(data = []) {
  return {
    type: types.GET_AUTO_ORDER_BRANDS_SUCCESS,
    payload: { allBrands: data },
  }
}

export function getAllDepartmentsRequestPending(pending = false) {
  return {
    type: types.GET_AUTO_ORDER_DEPARTMENTS_PENDING,
    payload: { pending: pending },
  }
}

export function getAllDepartmentsRequestSuccess(data = []) {
  return {
    type: types.GET_AUTO_ORDER_DEPARTMENTS_SUCCESS,
    payload: { allDepartments: data },
  }
}

export function getClassByDepartmentRequestPending(pending = false) {
  return {
    type: types.GET_AUTO_ORDER_CLASS_PENDING,
    payload: { pending: pending },
  }
}

export function getClassByDepartmentRequestSuccess(data = []) {
  return {
    type: types.GET_AUTO_ORDER_CLASS_SUCCESS,
    payload: { deptAllClasses: data },
  }
}

export function setSelectedFilters(filters = [], pageName = '') {
  if (!isEmpty(pageName)) {
    return {
      type: types.SET_AUTO_ORDER_SELECTED_FILTERS,
      payload: { data: { [pageName]: filters } },
    }
  }
}
