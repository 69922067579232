import * as types from './actionType'

export const initialState = (window.Cypress && window.initialState) || {
  columnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: false,
      label: 'PG ID',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      textAlign: 'right',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'generalDescription',
      numeric: false,
      disablePadding: false,
      label: 'Item Description',
      textAlign: 'right',
    },
    {
      id: 'vendorName',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Name',
      textAlign: 'right',
    },
    {
      id: 'vendorId',
      numeric: true,
      disablePadding: false,
      label: 'Vendor ID',
      textAlign: 'right',
    },
  ],
  reviewOrderColumnData: [
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      textAlign: 'right',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class ID',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Item Description',
      textAlign: 'right',
    },
    {
      id: 'vendorId',
      numeric: true,
      disablePadding: false,
      label: 'Vendor ID',
      textAlign: 'right',
    },
    {
      id: 'vendorName',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Name',
      textAlign: 'right',
    },
    {
      id: 'vendorBPIdsDisplay',
      numeric: false,
      disablePadding: false,
      label: 'Partners IDs',
      textRestriction: false,
    },
    {
      id: 'quantity',
      numeric: true,
      disablePadding: false,
      label: 'Quantity',
      textAlign: 'right',
    },
    {
      id: 'shipTo',
      numeric: false,
      disablePadding: false,
      label: 'Ship To Location',
      textAlign: 'right',
    },
    {
      id: 'dueDate',
      numeric: false,
      disablePadding: false,
      label: 'Due Date',
    },
    {
      id: 'projectName',
      numeric: false,
      disablePadding: false,
      label: 'Projects/Collections',
      textAlign: 'right',
    },
    {
      id: 'vendorNotes',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Notes',
      textAlign: 'right',
    },
    {
      id: 'studioNotes',
      numeric: false,
      disablePadding: false,
      label: 'Studio Notes',
      textAlign: 'right',
    },
  ],
  data: [],
  enableCheckbox: false,
  numSelected: 0,
  onCheckBoxChange: () => {},
  onClick: () => {},
  orderNumber: null,
  initiateOrderFailure: false,
  initiateOrderPending: false,
  initiateOrderSuccess: false,
}

export default function orderConfirmation(state = initialState, action = {}) {
  switch (action.type) {
    case types.ORDER_CONFIRMATION_SUBMIT_ORDER: {
      return Object.assign({}, state, {
        data: action.payload.data,
        orderNumber:
          action.payload.data.length > 0
            ? action.payload.data[0].orderNum
            : null,
      })
    }
    case types.ORDER_CONFIRMATION_INITIATE_SAMPLES_PENDING: {
      return Object.assign({}, state, {
        initiateOrderPending: action.payload.pending,
      })
    }
    case types.ORDER_CONFIRMATION_INITIATE_SAMPLES_FAILURE: {
      return Object.assign({}, state, {
        initiateOrderFailure: true,
        data: [],
      })
    }
    case types.ORDER_CONFIRMATION_INITIATE_SAMPLES_SUCCESS: {
      return Object.assign({}, state, {
        initiateOrderSuccess: true,
        data: action.payload.samples,
        orderNumber: action.payload.samples[0].orderNum || null,
      })
    }
    case types.RESET_ORDER_CONFIRMATION_STATE: {
      return Object.assign({}, initialState)
    }
    default:
      return state
  }
}
