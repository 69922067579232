import * as types from './actionType'
import { AccountCircle, HelpOutline, LocalShipping } from '@material-ui/icons'
import SettingsIcon from '@material-ui/icons/Settings'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import apiConfig from 'apiConfig'

export const initialState = {
  selectedSection: 'personal_information',
  settingsSections: [
    {
      key: 'personal_information',
      label: 'Personal Information',
      icon: <AccountCircle />,
    },
    {
      key: 'vendor_address',
      label: 'Ship to location',
      icon: <LocalShipping />,
    },
    {
      key: 'Auto_Order_Settings',
      label: 'Auto Order Settings',
      icon: <SettingsIcon />,
    },
    {
      key: 'admin_operations',
      label: 'Admin Operations',
      icon: <VerifiedUserIcon />,
      role: apiConfig.roles.admin,
    },
    { key: 'help_information', label: 'Help', icon: <HelpOutline /> },
  ],
  default_ship_to: 'None',
  getCheckInLocationsPending: false,
  getCheckInLocationsFailure: false,
  columnData: [
    {
      id: 'building',
      numeric: false,
      disablePadding: false,
      label: 'Building',
      align: 'center',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: 'Address',
      align: 'center',
    },
  ],
}

export default function settings(state = initialState, action = {}) {
  switch (action.type) {
    case types.SETTINGS_PAGE_SET_SECTION: {
      return Object.assign({}, state, {
        selectedSection: action.payload.section,
      })
    }
    case types.SETTINGS_PAGE_SET_DEFAULT_SHIP_TO: {
      return Object.assign({}, state, {
        accountInformation: {
          ...state.accountInformation,
          default_ship_to: action.payload.shipToLocation,
        },
      })
    }
    case types.CHECKIN_LOCATIONS_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        checkInLocations: action.payload.checkInLocations,
      })
    }

    case types.CHECKIN_LOCATIONS_REQUEST_PENDING:
      return Object.assign({}, state, {
        getCheckInLocationsPending: action.payload.pending,
      })

    case types.CHECKIN_LOCATIONS_REQUEST_FAILURE:
      return Object.assign({}, state, {
        getCheckInLocationsFailure: true,
      })
    default:
      return state
  }
}
