import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Header from '../../components/Header/Header'
import Internal from './Internal'
import External from './External'

const styles = {
  body: {
    background: '#ddd',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    left: '0',
    top: '0',
    width: '100%',
  },
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: '20px 30px',
  },
  unAuthContent: {
    textAlign: 'center',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '34px',
    margin: '0',
    color: '#CC0000',
  },
  subheading: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '1.5',
    margin: '1.8em 10px 2em',
  },
}

class UnAuthorized extends React.Component {
  isVendor = () => {
    return (
      window &&
      window.location &&
      window.location.host.endsWith('partnersonline.com')
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.wrapper}>
        <Header />
        <div className={classes.content}>
          <div className={classes.unAuthContent}>
            <div className={classes.heading}>Un Authorized</div>
            <div className={classes.subheading}>
              Not authorized to view this page
            </div>
          </div>
          {this.isVendor() ? <External /> : <Internal />}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(UnAuthorized)
