export const columnData = [
  {
    id: 'pegasusId',
    numeric: false,
    disablePadding: false,
    label: 'Pegasus Id',
    align: 'center',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    align: 'center',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Location',
    align: 'center',
  },
  {
    id: 'dpci',
    numeric: false,
    disablePadding: true,
    label: 'DPCI',
    align: 'center',
  },
]

export const data = [
  {
    pegasusId: 12345678,
    description: 'description',
    location: 'Winter Street',
    dpci: '12 - 34 - 5678',
  },
  {
    pegasusId: 345678909,
    description: 'description',
    location: 'Winter Street',
    dpci: '12 - 34 - 5678',
  },
  {
    pegasusId: 98765432,
    description: 'description',
    location: 'Winter Street',
    dpci: '12 - 34 - 5678',
  },
  {
    pegasusId: 43456677,
    description: 'description',
    location: 'Winter Street',
    dpci: '12 - 34 - 5678',
  },
  {
    pegasusId: 57543536,
    description: 'description',
    location: 'Winter Street',
    dpci: '12 - 34 - 5678',
  },
]
