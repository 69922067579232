import PropTypes from 'prop-types'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import NodeContentModule from './NodeContentModule'

class RadioSelect extends NodeContentModule {
  constructor(props) {
    super(props)
    this.state = { value: null }
  }

  onTransitionForward = (_, callback) => {
    const { onTransitionForward } = this.props
    onTransitionForward(this.state.value, callback)
  }

  handleChange = (event) => {
    const { onChange } = this.props
    onChange(event.target.value)
  }

  render() {
    const { currentValue, description, formLabel, options } = this.props

    return (
      <FormControl component={'fieldset'}>
        <FormLabel component={'legend'}>{description}</FormLabel>
        <RadioGroup
          aria-label={formLabel}
          name={formLabel}
          value={currentValue}
          onChange={this.handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              control={<Radio />}
              key={option.label}
              label={option.label}
              value={option.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }
}

RadioSelect.defaultProps = {
  onChange: () => {},
}

RadioSelect.propTypes = {
  currentValue: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onTransitionForward: PropTypes.func,
}

export default RadioSelect
