import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import * as propTypes from 'prop-types'
import {
  TextField,
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import {
  closePopup,
  getBarcodes,
  selectSort,
  setCarrier,
  setPage,
  setRowsPerPage,
  setTrackingNumber,
  clearTrackingInfo,
  setVisibility,
  showPopup,
  submitShipment,
  updateShipmentSummary,
} from '../../store/shipmentBarcode/actionCreator'
import { carriers } from '../../util/ShippingCarriers'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import { makeTabTables, getTCINAsTitle } from '../../util/Table'
import { dataTabLabelsShippedVendor } from '../../enums/tableTabs'

const styles = (theme) => ({
  card: {
    padding: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '20px',
    fontWeight: 500,
  },
  root: {
    display: 'grid',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    width: '100%',
  },
  shipmentBarcodePageHeader: {
    textAlign: 'center',
    marginBottom: '8px',
    fontSize: '34px',
    fontWeight: 400,
    float: 'left',
  },
  textField: {
    width: '50%',
  },
  textbox: {
    marginBottom: '34px',
  },

  printButton: {
    float: 'right',
    marginRight: '12px',
  },
  processShipmentsButton: {
    float: 'right',
    marginRight: '9px',
  },

  checkboxLabel: {
    fontSize: '15px',
  },

  requiredFieldText: {
    position: 'absolute',
    top: '155px',
    right: '125px',
    fontSize: '12px',
    color: '#484848',
    fontWeight: 'normal',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
  shipToLocLable: {
    textIndent: '20px',
  },
  shipToLocBlock: {
    padding: '15px',
    height: '288px',
  },
})

export class ShipmentBarcodePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      carrier: '',
      text: '',
      provideDetailsLater: false,
      printButton: false,
      isVisibleOtherCarrier: false,
      newCarrier: '',
    }
  }

  componentDidMount() {
    const { setHeaderTitleArray } = this.props
    setHeaderTitleArray([{ title: 'Shipment Barcode' }])
  }

  disableButton = () => {
    /**
     * Process Shipment Button should be enabled only if the following critera is met:
     * 1. printButton must always be triggered
     * 2. either tracking number and carrier are supplied or provideDetailsLater is checked
     *
     * returns true if button should be disabled, false otherwise
     */

    // returns boolean on true, if all tracking information fields are populated
    let hasTrackingInfo = this.props.data.flatMap(
      (shipping) => !!shipping.trackingNumber && !!shipping.carrier && true
    )
    // ensure that either tracking info is fully provided or flag to defer information is selected [XOR operation used]
    let shipmentInfoUndefined = !hasTrackingInfo[0]
      ? !this.state.provideDetailsLater
      : !!this.state.provideDetailsLater

    return shipmentInfoUndefined || !this.state.printButton
  }

  disableShippingTextFields = () => {
    return this.state.provideDetailsLater
  }

  handleTrackingNumberChange(trackingNumber, shipmentNumber) {
    const { onTrackingNumberChange } = this.props
    const trackingNumVal = trackingNumber.target.value
    this.setState((state) => ({
      ...state,
      text: trackingNumVal,
    }))
    onTrackingNumberChange({
      trackingNumber: trackingNumber.target.value,
      shipmentNumber: shipmentNumber,
    })
  }

  handleCarrierChange = (carrier, shipmentNumber, carrierType = '') => {
    const { onCarrierChange = () => {} } = this.props
    if (carrierType === 'new') {
      this.setState((state) => ({
        ...state,
        newCarrier: carrier,
      }))
    } else {
      this.setState((state) => ({
        ...state,
        carrier: carrier,
        newCarrier: '',
        isVisibleOtherCarrier: false,
      }))
    }
    onCarrierChange({
      carrier: carrier,
      shipmentNumber: shipmentNumber,
    })
  }

  handleTextbox(carrier, shipmentNumber) {
    const carrierVal = carrier.target.value
    if (carrierVal === 'OTHER') {
      this.setState((state) => ({
        ...state,
        isVisibleOtherCarrier: true,
        carrier: 'OTHER',
      }))
    } else {
      this.handleCarrierChange(carrierVal, shipmentNumber)
    }
  }

  handleGenerateBarcode = () => {
    this.props.getBarcodes(this.props.data, (barcodes) => {
      const file = new Blob([barcodes], { type: 'application/pdf' })
      let fileURL
      if (window.navigator.msSaveOrOpenBlob) {
        fileURL = window.navigator.msSaveOrOpenBlob(file, 'barcodeLabel.pdf')
      } else {
        fileURL = URL.createObjectURL(file)
        window.open(fileURL).print()
      }
    })

    this.setState((state) => ({
      ...state,
      printButton: true,
    }))
  }

  handleCheckBoxChange = () => {
    const { clearTrackingInfo, parentCallback = () => {} } = this.props
    const { provideDetailsLater } = this.state
    this.setState((state) => ({
      ...state,
      carrier: '',
      text: '',
      newCarrier: '',
      provideDetailsLater: !state.provideDetailsLater,
      isVisibleOtherCarrier: false,
    }))
    parentCallback(!provideDetailsLater)
    clearTrackingInfo()
  }

  handleProcessShipment = (event) => {
    const { data, processShipment } = this.props
    processShipment(data)
  }

  render() {
    const {
      classes = {},
      closePopup,
      columnData,
      data,
      onClick,
      onSortSelectedChange,
      order,
      orderBy,
      page,
      popUpIsVisible,
      popUpItem = {},
      rowsPerPage,
      setPage,
      setRowsPerPage,
      shipmentDestination = '',
      shipmentNumber,
      showPopup,
    } = this.props

    const {
      provideDetailsLater,
      carrier = '',
      text = '',
      newCarrier = '',
      isVisibleOtherCarrier = false,
    } = this.state

    const dataFieldKeys = columnData.map((column) => column.id)
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Paper>
                <Grid
                  container
                  spacing={2}
                  className={classes.shipToLocBlock}
                  direction={'column'}
                >
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Shipping Address
                    </Typography>
                  </Grid>
                  <Grid item>
                    {shipmentDestination.split(',').map((line) => (
                      <div className={classes.shipToLocLable}>{line}</div>
                    ))}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper>
                <Grid container spacing={2} style={{ padding: '15px' }}>
                  <Grid item xs={12}>
                    <Grid container justify={'space-between'}>
                      <Grid item>
                        <Typography variant="h6" gutterBottom>
                          Add Shipping Details (Optional)
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          You will have the opportunity to provide this
                          information later
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="provideDetailsLater"
                                checked={provideDetailsLater}
                                onChange={() => this.handleCheckBoxChange()}
                              />
                            }
                            label="Provide Shipping Details Later"
                            classes={{ label: classes.checkboxLabel }}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={this.disableShippingTextFields()}
                      // className={classes.textbox}
                      id="carrier"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Select Carrier"
                      onChange={(value) =>
                        this.handleTextbox(value, shipmentNumber)
                      }
                      select
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                        native: true,
                      }}
                      style={{ width: '100%' }}
                      value={carrier}
                      variant="outlined"
                    >
                      {carriers.map((option) => (
                        <option key={option.value}>{option.value}</option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    {isVisibleOtherCarrier ? (
                      <TextField
                        // className={classes.textbox}
                        id="Other carrier"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Enter Other Carrier"
                        onChange={(event) => {
                          const carrierValue = event.target.value
                          this.handleCarrierChange(
                            carrierValue,
                            shipmentNumber,
                            'new'
                          )
                        }}
                        style={{ width: '100%' }}
                        value={newCarrier}
                        variant="outlined"
                        disabled={this.disableShippingTextFields()}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textbox}
                      id="trackingNumber"
                      multiline
                      onChange={(value) =>
                        this.handleTrackingNumberChange(value, shipmentNumber)
                      }
                      placeholder="Tracking Number"
                      style={{ width: '100%' }}
                      disabled={this.disableShippingTextFields()}
                      value={text}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FlexibleTable
            cascadeFieldKeys={[]}
            columnData={columnData}
            data={data}
            dataFieldKeys={dataFieldKeys}
            onClick={onClick}
            onSortSelectedChange={onSortSelectedChange}
            order={order}
            orderBy={orderBy}
            page={page}
            rowCount={data.length}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            showPopup={showPopup}
          />
        </Grid>
        <TabbedPopUp
          dataTabLabels={dataTabLabelsShippedVendor}
          isVisible={popUpIsVisible}
          data={makeTabTables(popUpItem, classes, dataTabLabelsShippedVendor)}
          onClose={closePopup}
          title={getTCINAsTitle(popUpItem)}
          fullWidth={true}
        />
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    barcodes: state.shipmentBarcode.shipmentBarcodeRequestFileStream,
    columnData: state.shipmentBarcode.stepOneColumnData,
    data: state.shipmentBarcode.data,
    onClick: state.shipmentBarcode.onClick,
    order: state.shipmentBarcode.order,
    orderBy: state.shipmentBarcode.orderBy,
    page: state.shipmentBarcode.page,
    popUpIsVisible: state.shipmentBarcode.popUpIsVisible,
    popUpItem: state.shipmentBarcode.popUpItem,
    rowCount: state.shipmentBarcode.rowCount,
    rowsPerPage: state.shipmentBarcode.rowsPerPage,
    shipmentDestination: state.shipmentBarcode.shipmentDestination,
    shipmentNumber: state.shipmentBarcode.shipmentNumber,
    visible: state.shipmentBarcode.visible,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closePopup: () => dispatch(closePopup()),
    getBarcodes: (samples, showBarcodesCallback) =>
      dispatch(getBarcodes(samples, showBarcodesCallback)),
    onCarrierChange: (event) => dispatch(setCarrier(event)),
    onSortSelectedChange: (orderBy, order) =>
      dispatch(selectSort(orderBy, order)),
    onTrackingNumberChange: (event) => dispatch(setTrackingNumber(event)),
    clearTrackingInfo: () => dispatch(clearTrackingInfo()),
    onVisibliltyChange: (event) => dispatch(setVisibility(event)),
    processShipment: (data) => dispatch(submitShipment(data)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    showPopup: (item) => dispatch(showPopup(item)),
    updateOrderSummary: (orderNum, status) =>
      dispatch(updateShipmentSummary(orderNum, status)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
  }
}

ShipmentBarcodePage.propTypes = {
  data: propTypes.array,
  onSortSelectedChange: propTypes.func,
  page: propTypes.number,
  rowsPerPage: propTypes.number,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ShipmentBarcodePage))
