export const widthOfColumns = {
  description: 200,
  shipTo: 160,
  size: 60,
  color: 60,
  quantity: 60,
  vendorId: 60,
  vendorNotes: 120,
  studioNotes: 120,
  dueDate: 110,
  project: 200,
  vendorEmail: 180,
  user: 100,
  batch_name: 200,
  import_date: 160,
  roomName: 200,
  notes:150,
  shipmentBundleCount: 150,
  vendorBPIdsDisplay: 'auto'
}
