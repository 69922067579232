export const FETCH_SAMPLES_REQUEST_SUCCESS = 'FETCH_SAMPLES_REQUEST_SUCCESS'
export const FETCH_SAMPLES_REQUEST_PENDING = 'FETCH_SAMPLES_REQUEST_PENDING'
export const FETCH_SAMPLES_REQUEST_FAILURE = 'FETCH_SAMPLES_REQUEST_FAILURE'
export const CHECKIN_CLEAR_DATA = 'CHECKIN_CLEAR_DATA'
export const CHECKIN_REMOVE_SAMPLE = 'CHECKIN_REMOVE_SAMPLE'
export const CHECKIN_REMOVE_SAMPLES = 'CHECKIN_REMOVE_SAMPLES'
export const CHECKIN_SET_UNKNOWN_DATA = 'CHECKIN_SET_UNKNOWN_DATA'
export const CHECKIN_UPDATE_SAMPLE_ID = 'CHECKIN_UPDATE_SAMPLE_ID'
export const CHECKIN_UPDATE_SAMPLE_STATUS_SUCCESS =
  'CHECKIN_UPDATE_SAMPLE_STATUS_SUCCESS'
export const SHOW_CHECKIN_PAGE_POPUP = 'SHOW_CHECKIN_PAGE_POPUP'
export const CLOSE_CHECKIN_PAGE_POPUP = 'CLOSE_CHECKIN_PAGE_POPUP'
export const CHECKIN_REQUEST_SUCCESS = 'CHECKIN_REQUEST_SUCCESS'
export const CHECKIN_REQUEST_PENDING = 'CHECKIN_REQUEST_PENDING'
export const CHECKIN_ADD_SAMPLE_TO_PRINT_QUEUE =
  'CHECKIN_ADD_SAMPLE_TO_PRINT_QUEUE'
export const CHECKIN_REMOVE_SAMPLE_TO_PRINT_QUEUE =
  'CHECKIN_REMOVE_SAMPLE_TO_PRINT_QUEUE'
export const CHECKIN_REMOVE_SAMPLE_TO_PRINT_QUEUE_ALL =
  'CHECKIN_REMOVE_SAMPLE_TO_PRINT_QUEUE_ALL'
export const CHECKIN_REQUEST_FAILURE = 'CHECKIN_REQUEST_FAILURE'
export const LOCATION_REQUEST_SUCCESS = 'LOCATION_REQUEST_SUCCESS'
export const LOCATION_REQUEST_PENDING = 'LOCATION_REQUEST_PENDING'
export const LOCATION_REQUEST_FAILURE = 'LOCATION_REQUEST_FAILURE'
export const ASSIGN_LOCATION_REQUEST_SUCCESS = 'ASSIGN_LOCATION_REQUEST_SUCCESS'
export const ASSIGN_LOCATION_REQUEST_PENDING = 'ASSIGN_LOCATION_REQUEST_PENDING'
export const CHECKIN_SET_PREVIOUS_STATE = 'CHECKIN_SET_PREVIOUS_STATE'
export const FETCH_SAMPLES_CHECKIN_SUCCESS = 'FETCH_SAMPLES_CHECKIN_SUCCESS'
export const CHECKIN_REMOVE_UNKNOWN_SAMPLE_FROM_LIST =
  'CHECKIN_REMOVE_UNKNOWN_SAMPLE_FROM_LIST'
export const CHECKIN_REMOVE_DUPLICATE_SAMPLE_FROM_LIST =
  'CHECKIN_REMOVE_DUPLICATE_SAMPLE_FROM_LIST'
export const CHECKIN_SET_DUPLICATE_DATA = 'CHECKIN_SET_DUPLICATE_DATA'
export const FETCH_NO_SAMPLES = 'FETCH_NO_SAMPLES'
export const FETCH_LOCATION_DETAILS_SUCCESS = 'FETCH_LOCATION_DETAILS_SUCCESS'
export const CALL_EDIT_FIELDS_API_CHECKIN = 'CALL_EDIT_FIELDS_API_CHECKIN'
export const MOVESAMPLES_REMOVE_UNKNOWN_SAMPLE_FROM_LIST =
  'MOVESAMPLES_REMOVE_UNKNOWN_SAMPLE_FROM_LIST'
export const CHECKIN_UPDATE_SAMPLE_STATUS_SUCCESS_NEXT =
  'CHECKIN_UPDATE_SAMPLE_STATUS_SUCCESS_NEXT'
