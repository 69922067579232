import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import ShowIf from 'components/ShowIf'

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

export default function MarketingList({
  pidData = [],
  isReadOnly = false,
  onRemove = () => {},
}) {
  return (
    <>
      <ShowIf condition={isReadOnly}>
        <div
          style={{
            fontSize: '12px',
            lineHeight: '14px',
            color: '#333333',
            marginBottom: '8px',
          }}
        >
          Marketing PIDs
        </div>
      </ShowIf>
      <Paper
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          m: 0,
        }}
      >
        {pidData?.map((pid) => {
          const onDelete = isReadOnly ? undefined : () => onRemove(pid)
          return (
            <ListItem key={pid}>
              <Chip label={pid} onDelete={onDelete} />
            </ListItem>
          )
        })}
      </Paper>
    </>
  )
}
