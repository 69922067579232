import * as types from './actionType'
import * as ordersAPI from '../../services/ordersService'
import * as OrderMapper from '../../mappers/OrderMapper'

export function initiateSamples(data = []) {
  return (dispatch) => {
    dispatch(initiateSamplesRequestPending(true))
    return ordersAPI
      .createSamples(OrderMapper.samplesToSampleCreateRequests(data))
      .then((res) => OrderMapper.samplesResponseToSamples(res))
      .then((samples) => {
        return samples.map((sample) => ({
          ...sample,
          generalDescription:
            data.find((s) => s.tcin === sample.tcin.toString()).description ||
            '',
        }))
      })
      .then((result) => {
        dispatch(initiateSamplesRequestSuccess(result))
        dispatch(initiateSamplesRequestPending(false))
      })
      .catch(() => {
        dispatch(initiateSamplesRequestFailure())
        dispatch(initiateSamplesRequestPending(false))
      })
  }
}

export function initiateSamplesRequestPending(pending = false) {
  return {
    type: types.ORDER_CONFIRMATION_INITIATE_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export function initiateSamplesRequestSuccess(samples = []) {
  return {
    type: types.ORDER_CONFIRMATION_INITIATE_SAMPLES_SUCCESS,
    payload: { samples: samples },
  }
}

export function initiateSamplesRequestFailure() {
  return {
    type: types.ORDER_CONFIRMATION_INITIATE_SAMPLES_FAILURE,
  }
}

export function submitOrder(data = []) {
  return {
    type: types.ORDER_CONFIRMATION_SUBMIT_ORDER,
    payload: { data: data },
  }
}

export function resetOrderConfirmationState() {
  return {
    type: types.RESET_ORDER_CONFIRMATION_STATE,
  }
}
