import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ height: 'auto', minHeight: '500px' }}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
}))

export default function FullWidthTabs(props = {}) {
  const {
    tabLabels = [],
    renderTabsContent = () => {},
    defaultTab = '',
    showTabsPanel = false,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const [tabValue, setTabValue] = React.useState(defaultTab)

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setTabValue(index)
  }

  useEffect(() => {
    const { setCurrentTabValue = () => {} } = props
    setCurrentTabValue(tabValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue])

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        style={{ borderBottom: '1px solid #E0E0E0' }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          aria-label="tabs"
        >
          {tabLabels.map((obj) => {
            const { label: objLabel = '', value: objValue = '' } = obj
            return (
              <Tab
                key={objLabel}
                data-cy="tabsTitle"
                label={objLabel}
                value={objValue}
                {...a11yProps(0)}
              />
            )
          })}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabValue}
        onChangeIndex={handleChangeIndex}
      >
        {showTabsPanel ? (
          tabLabels.map((obj) => {
            return (
              <TabPanel
                value={tabValue}
                index={tabValue}
                dir={theme.direction}
                key={tabValue}
              >
                {renderTabsContent()}
              </TabPanel>
            )
          })
        ) : (
          <TabPanel value={tabValue} index={tabValue} dir={theme.direction}>
            {renderTabsContent()}
          </TabPanel>
        )}
      </SwipeableViews>
    </div>
  )
}
