export const ADD_SCENIC_REQUEST_SUCCESS = 'ADD_SCENIC_REQUEST_SUCCESS'
export const ADD_SCENIC_REQUEST_PENDING = 'ADD_SCENIC_REQUEST_PENDING'
export const ADD_SCENIC_REQUEST_FAILURE = 'ADD_SCENIC_REQUEST_FAILURE'
export const CLOSE_SCENIC_PAGE_ALERT = 'CLOSE_SCENIC_PAGE_ALERT'
export const FETCH_SHIP_TO_LOCATION_REQUEST_SUCCESS =
  'FETCH_SHIP_TO_LOCATION_REQUEST_SUCCESS'
export const FETCH_SHIP_FROM_LOCATION_REQUEST_SUCCESS =
  'FETCH_SHIP_FROM_LOCATION_REQUEST_SUCCESS'
export const FETCH_SHIP_LOCATION_REQUEST_PENDING =
  'FETCH_SHIP_LOCATION_REQUEST_PENDING'
export const FETCH_SHIP_LOCATION_REQUEST_FAILURE =
  'FETCH_SHIP_LOCATION_REQUEST_FAILURE'
export const SCENIC_SET_CHECKBOX = 'SCENIC_SET_CHECKBOX'
export const SCENIC_SET_CHECKBOX_HEADER = 'SCENIC_SET_CHECKBOX_HEADER'
export const SHOOT_SAMPLE_CLEAR_DATA = 'SHOOT_SAMPLE_CLEAR_DATA'
export const SHOOT_SAMPLE_SET_UNKNOWN_DATA = 'SHOOT_SAMPLE_SET_UNKNOWN_DATA'
export const SHOOT_SAMPLE_SET_PAGE = 'SHOOT_SAMPLE_SET_PAGE'
export const SHOOT_SAMPLE_SET_ROWS_PER_PAGE = 'SHOOT_SAMPLE_SET_ROWS_PER_PAGE'
export const SHOW_SCENIC_PAGE_ALERT = 'SHOW_SCENIC_PAGE_ALERT'
export const SCENIC_CREATE_REQUEST_SUCCESS = 'SCENIC_CREATE_REQUEST_SUCCESS'
export const SCENIC_CREATE_REQUEST_PENDING = 'SCENIC_CREATE_REQUEST_PENDING'
export const SCENIC_CREATE_REQUEST_FAILURE = 'SCENIC_CREATE_REQUEST_FAILURE'
export const SCENIC_DELETE_REQUEST_SUCCESS = 'SCENIC_DELETE_REQUEST_SUCCESS'
export const SCENIC_DELETE_REQUEST_PENDING = 'SCENIC_DELETE_REQUEST_PENDING'
export const SCENIC_DELETE_REQUEST_FAILURE = 'SCENIC_DELETE_REQUEST_FAILURE'
export const SCENIC_UPDATE_REQUEST_SUCCESS = 'SCENIC_UPDATE_REQUEST_SUCCESS'
export const SCENIC_UPDATE_REQUEST_PENDING = 'SCENIC_UPDATE_REQUEST_PENDING'
export const SCENIC_UPDATE_REQUEST_FAILURE = 'SCENIC_UPDATE_REQUEST_FAILURE'
export const REMOVE_SAMPLE_REQUEST_SUCCESS = 'REMOVE_SAMPLE_REQUEST_SUCCESS'
export const REMOVE_SAMPLE_REQUEST_PENDING = 'REMOVE_SAMPLE_REQUEST_PENDING'
export const REMOVE_SAMPLE_REQUEST_FAILURE = 'REMOVE_SAMPLE_REQUEST_FAILURE'
export const SHOW_SCENIC_POPUP = 'SHOW_SCENIC_POPUP'
export const CLOSE_SCENIC_POPUP = 'CLOSE_SCENIC_POPUP'
export const SCENIC_GET_REQUEST_PENDING = 'SCENIC_GET_REQUEST_PENDING'
export const SCENIC_ITEM_GET_REQUEST_SUCCESS = 'SCENIC_ITEM_GET_REQUEST_SUCCESS'
export const SCENIC_GET_REQUEST_SUCCESS = 'SCENIC_GET_REQUEST_SUCCESS'
export const SCENIC_GET_REQUEST_FAILURE = 'SCENIC_GET_REQUEST_FAILURE'
export const RESET_NEW_SCENIC_ROOM_STATE = 'RESET_NEW_SCENIC_ROOM_STATE'
export const SET_REDIRECT_TO_NEW_SCENIC_ROOM = 'SET_REDIRECT_TO_NEW_SCENIC_ROOM'
export const UNAVAILABLE_SCENIC_SUCCESS = 'UNAVAILABLE_SCENIC_SUCCESS'
export const UNAVAILABLE_SCENIC_PENDING = 'UNAVAILABLE_SCENIC_PENDING'
export const UNAVAILABLE_SCENIC_FAILURE = 'UNAVAILABLE_SCENIC_FAILURE'
export const CLOSE_UNAVAILABLE_SCENIC_POPUP = 'CLOSE_UNAVAILABLE_SCENIC_POPUP'
export const SCENIC_CALL_EDIT_FIELDS_API = 'SCENIC_CALL_EDIT_FIELDS_API'
export const UPDATE_BUNDLED_QUANTITY = 'UPDATE_BUNDLED_QUANTITY'
export const CLEAR_STATE_DATA = 'CLEAR_STATE_DATA'
export const SCENIC_SET_INVALID_IDS = 'SCENIC_SET_INVALID_IDS'
