import * as types from './actionType'
import * as collectionAPI from '../../services/collectionsService'
import * as collectionMapper from '../../mappers/collectionMapper'
import { download, createErrorString } from '../../util/DownloadCsv'
import { showNotification } from '../../store/notification/actionCreator'
import { getShootType } from '../../store/newShootType/actionCreator'
import { ShootTypes } from '../../enums/shootTypes'
import { sortMyArray } from '../../util/Merge'

export function getCollections(params = {}) {
  return (dispatch) => {
    dispatch(getCollectionsPending(true))
    return collectionAPI
      .getAllCollections(params)
      .then((res) =>
        collectionMapper.collectionResponseToCollections(res.data.collections)
      )
      .then((collections) => {
        dispatch(getCollectionsSuccess(collections))
        if (
          Object.keys(params).indexOf('created_by') === -1 &&
          Object.keys(params).indexOf('location_id') === -1
        ) {
          const collectionCreators = collections
            .filter((creatorObj, index, arr) => {
              return (
                creatorObj.createdById &&
                arr.findIndex(
                  (obj) => obj.createdById === creatorObj.createdById
                ) === index
              )
            })
            .map((obj) => {
              return {
                value: obj.createdById,
                label: obj.createdBy,
              }
            })
            .sort()
          const collectionLocations = collections
            .filter((locationObj, index, arr) => {
              return (
                locationObj.locationId &&
                arr.findIndex(
                  (obj) => obj.locationId === locationObj.locationId
                ) === index
              )
            })
            .map((obj) => {
              return {
                value: obj.locationId,
                label: obj.locationName,
              }
            })
            .sort()
          const collectionCreatorsSorted = sortMyArray(
            collectionCreators,
            'label',
            'asc'
          )
          const collectionLocationsSorted = sortMyArray(
            collectionLocations,
            'label',
            'asc'
          )

          dispatch(
            updateCollectionFilters(
              collectionCreatorsSorted,
              collectionLocationsSorted
            )
          )
        }
      })
      .catch(() => {
        dispatch(getCollectionsFailure())
      })
      .finally(() => {
        dispatch(getCollectionsPending(false))
      })
  }
}

export function getACollectionSuccess(collections = []) {
  return {
    type: types.CURRENT_COLLECTION_REQUEST_SUCCESS,
    payload: { collections: collections },
  }
}

export function getACollection(collectionID = '', page, rowsPerPage) {
  return (dispatch) => {
    dispatch(getCollectionsPending(true))
    return collectionAPI
      .getCollection(collectionID)
      .then((res) => {
        const currentCollectionData =
          collectionMapper.collectionResponseToCollection(res.data)
        dispatch(getACollectionSuccess(currentCollectionData))
        return currentCollectionData
      })
      .then((collectionData) => {
        dispatch(
          getShootType(collectionData, ShootTypes.COLLECTION, page, rowsPerPage)
        )
      })
      .catch(() => {
        dispatch(getCollectionsFailure())
      })
      .finally(() => {
        dispatch(getCollectionsPending(false))
      })
  }
}

export function downloadCollectionSamples(collectionId) {
  return (dispatch) => {
    return collectionAPI
      .downloadCollectionSamples(collectionId)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function updateCollectionStatus(params = {}, callback = () => {}) {
  return async (dispatch) => {
    dispatch(updateCollectionStatusPending(true))
    return collectionAPI
      .updateCollectionStatus(params)
      .then((response) => {
        callback()
        dispatch(
          showNotification(true, 'Status updated successfully', 'success')
        )
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while updating the status'
          )
        )
      })
      .finally(() => {
        dispatch(updateCollectionStatusPending(false))
      })
  }
}

export function downloadAllCollectionSamples(collections) {
  return (dispatch) => {
    let erroredCollections = []
    let promises = []
    for (let index in collections) {
      promises.push(
        collectionAPI
          .downloadCollectionSamples(collections[index].id)
          .then((response) => {
            download(response)
          })
          .catch((e) => {
            erroredCollections.push(collections[index].name)
          })
      )
    }

    return Promise.all(promises).then(() => {
      if (erroredCollections.length) {
        dispatch(showNotification(true, createErrorString(erroredCollections)))
      }
    })
  }
}

export function downloaCollectionExcel(collections) {
  return (dispatch) => {
    let erroredCollections = []
    let promises = []
    for (let index in collections) {
      promises.push(
        collectionAPI
          .downloadCollectionExcel(collections[index].id)
          .then((response) => {
            download(response)
          })
          .catch((e) => {
            erroredCollections.push(collections[index].name)
          })
      )
    }

    return Promise.all(promises).then(() => {
      if (erroredCollections.length) {
        dispatch(showNotification(true, createErrorString(erroredCollections)))
      }
    })
  }
}

export function getCollectionsPending(pending = false) {
  return {
    type: types.GET_COLLECTIONS_REQUEST_PENDING,
    payload: { pending: pending },
  }
}
export function updateCollectionStatusPending(pending = false) {
  return {
    type: types.UPDATE_COLLECTIONS_STATUS_PENDING,
    payload: { pending: pending },
  }
}

export function getCollectionsFailure() {
  return {
    type: types.GET_COLLECTIONS_REQUEST_FAILURE,
  }
}

export function getCollectionsSuccess(collections = []) {
  return {
    type: types.GET_COLLECTIONS_REQUEST_SUCCESS,
    payload: { allCollections: collections },
  }
}

export function updateCollectionFilters(
  collectionCreators = [],
  collectionLocations = []
) {
  return {
    type: types.UPDATE_COLLECTION_FILTERS,
    payload: {
      collectionCreators: collectionCreators,
      collectionLocations: collectionLocations,
    },
  }
}

export function setFilter(filteredData) {
  return {
    type: types.COLLECTIONS_SET_FILTER,
    payload: { filter: filteredData },
  }
}

export function clearFilter() {
  return {
    type: types.COLLECTIONS_CLEAR_FILTER,
  }
}

export function showCreateCollectionPopup() {
  return {
    type: types.COLLECTIONS_SHOW_CREATE_POPUP,
  }
}

export function closeCreateCollectionPopup() {
  return {
    type: types.COLLECTIONS_CLOSE_CREATE_POPUP,
  }
}

export function setCheckBox(selected = {}) {
  return {
    type: types.COLLECTIONS_SET_CHECKBOX,
    payload: { selected: selected },
  }
}

export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.COLLECTIONS_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}
export function setCollectionTabvalue(tabValue = '') {
  return {
    type: types.SET_COLLECTION_TABVALUE,
    payload: { tabValue: tabValue },
  }
}
export function setCreatorValue(creatorValue = {}) {
  return {
    type: types.SET_CREATOR_VALUE,
    payload: { creatorValue: creatorValue },
  }
}
export function setLocationValue(locationValue = {}) {
  return {
    type: types.SET_LOCATION_VALUE,
    payload: { locationValue: locationValue },
  }
}

export const saveCollectionSearchValue = (filterValue = '') => {
  return {
    type: types.SET_COLLECTIONS_SEARCH_VALUE,
    payload: { filterValue },
  }
}
