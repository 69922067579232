import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import withHOCs from 'util/withHocs'
import Grid from '@material-ui/core/Grid'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel'
import { getCheckInLocations } from '../../store/settings/actionCreator'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { RenderEditFields } from './EditSamplesConstants'
import { CustomPaper } from '../../util/CommonUtils'
const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    minWidth: '1200px',
    width: 'auto',
  },
  chipFilterTextField: {
    width: '100%',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  rootDiv: {
    width: '400px',
    display: 'flex',
    flexFlow: 'column wrap',
    marginTop: 20,
    marginBottom: 20,
  },
  appBarToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    // zIndex: 3200,
  },
  appBar: {
    zIndex: '3200 !important',
  },
  errorIcon: {
    color: '#B85300',
  },
  successIcon: {
    color: 'green',
  },
  hr: {
    background: 'grey',
    color: 'grey',
    height: '1px',
    width: '100vw',
  },
  errorChip: {
    margin: theme.spacing(1 / 2),
    color: '#B85300 !important',
    border: '1px solid #B85300 !important',
    fontWeight: 700,
  },
  CancelButton: {
    marginRight: 10,
  },
})
const EditSamplesFlyout = (props) => {
  const dispatch = useDispatch()
  const {
    classes = {},
    closePopup = () => {},
    selectedOptions = [],
    handleSubmit = () => {},
  } = props
  const [selectedLocation, setselectedLocation] = useState('')
  const [quantity, setQuantity] = useState('')
  const [error, setError] = useState('')
  const onCloseClick = (event) => {
    closePopup()
  }

  useEffect(() => {
    dispatch(getCheckInLocations())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { checkInLocations = [] } = useSelector(
    ({ settings: { checkInLocations = [] } }) => {
      return { checkInLocations }
    }
  )
  const renderAppbar = (content = '') => {
    return (
      <AppBar
        position="sticky"
        color="transparent"
        elevation={1}
        sx={{
          bottom: 0,
          fontWeight: 500,
          backgroundColor: '#ffffff',
          color: '#333333',
        }}
      >
        <Toolbar className={classes.appBarToolBar}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} component="div">
            {content}
            <Typography sx={{ marginTop: 1 }}>
              For {selectedOptions.length} TCINs selected
            </Typography>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseClick}
            sx={{ color: '#333333' }}
          >
            <CancelIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
  const handleChange = (fieldName = '', newValue = '') => {
    const re = /^[0-9\b]+$/
    switch (fieldName) {
      case 'quantity':
        return (re.test(newValue.target.value) &&
          parseInt(newValue.target.value) > 0) ||
          isEmpty(newValue.target.value)
          ? (setQuantity(newValue.target.value), setError(''))
          : setError(true)
      case 'Location':
        return setselectedLocation(newValue)
      default:
        return ''
    }
  }
  const getLocationNames = (locations) => {
    const locationNames = locations.map((it) =>
      Object.assign(
        {},
        {
          label: it.building,
        }
      )
    )

    return locationNames.sort((a, b) => (a.name > b.name ? 1 : -1))
  }
  const renderAutoComplete = (renderEditFieldObject) => {
    const { renderValue = '', label = '' } = renderEditFieldObject
    const fieldValue = renderValue === 'Location' ? selectedLocation : ''
    return (
      <Grid item xs={12} style={{ margin: '15px 25px' }}>
        <Autocomplete
          id="aisle"
          style={{ width: 'auto' }}
          onChange={(event, newValue) => {
            handleChange(renderValue, newValue)
          }}
          PaperComponent={CustomPaper}
          getOptionLabel={(option) => option.label}
          options={getLocationNames(checkInLocations)}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={fieldValue}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      </Grid>
    )
  }
  const renderInputField = (renderEditFieldObject) => {
    const { renderValue = '', label = '' } = renderEditFieldObject
    const fieldValue = renderValue === 'quantity' ? quantity : ''
    return (
      <Grid item xs={12} style={{ margin: '15px 25px' }}>
        <TextField
          {...(error && {
            error: true,
          })}
          label={label}
          value={fieldValue}
          onChange={(e, newValue) => handleChange(renderValue, e)}
        />
      </Grid>
    )
  }
  const onSubmitEditClick = () => {
    let requestBody = []

    selectedOptions.map((selectedOption) => {
      let fieldObject = {}
      let { tcin_shoot_type_id = '' } = selectedOption
      fieldObject['quantity'] = quantity
      fieldObject['ship_to_location'] = selectedLocation.label
      fieldObject['tcin_shoot_type_id'] = tcin_shoot_type_id
      requestBody.push(fieldObject)
      return {}
    })
    handleSubmit(requestBody)
  }
  const disabledSubmit = !isEmpty(quantity) || !isEmpty(selectedLocation)
  return (
    <div>
      {renderAppbar(' Edit Ship To Location and/or Quantity')}
      <Grid container className={classes.rootDiv}>
        {RenderEditFields.map((renderEditFieldObject) => {
          const { renderType = '' } = renderEditFieldObject
          switch (renderType) {
            case 'AutoComplete':
              return renderAutoComplete(renderEditFieldObject)
            case 'Text':
              return renderInputField(renderEditFieldObject)
            default:
              return ''
          }
        })}
        <hr className={classes.hr} />
        <Grid style={{ marginTop: 30, marginLeft: '05%' }}>
          <Button
            variant="outlined"
            style={{ marginLeft: 10 }}
            onClick={onCloseClick}
          >
            Cancel
          </Button>
          <Button
            disabled={!disabledSubmit}
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={onSubmitEditClick}
          >
            update
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

// function mapStateToProps(state) {
//   return {
//     locations: state.settings.checkInLocations,
//   }
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     getCheckInLocations: () => dispatch(getCheckInLocations()),
//   }
// }

export default withHOCs({
  auth: true,
  nav: true,
  styles,
})(EditSamplesFlyout)
