import axios from 'axios'
import apiConfig from '../apiConfig'

const {
  user: { url = '' },
  apiKey = '',
} = apiConfig

export function getDetails() {
  return axios.get(url, {
    params: {
      key: apiKey,
    },
  })
}

export function getUser(lanId) {
  return axios.get(url + 'users/' + lanId, {
    params: {
      key: apiKey,
    },
  })
}

export function updateUser(updateUser = null) {
  return axios.put(url, updateUser, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function updateUserDept(userDepts = {}) {
  return axios.post(
    url + 'departments',
    { departments: userDepts },
    {
      headers: { 'Content-Type': 'application/json' },
      params: {
        key: apiKey,
      },
    }
  )
}

export function getUserDepts(lanId) {
  return axios.get(url + 'departments/' + lanId, {
    params: {
      key: apiKey,
    },
  })
}
