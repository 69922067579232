import * as types from './actionType'
import { getDisplayImage } from '../../util/CommonUtils'
import { isEmpty } from 'lodash'

function sampleLocationResponseToLocation(location = {}) {
  if (
    location !== undefined &&
    location !== null &&
    Object.entries(location).length > 0
  ) {
    return (
      ((location.building !== null && location.building) || '') +
      ((location.aisle && ' > ' + location.aisle) || '') +
      ((location.section && ' > ' + location.section) || '') +
      ((location.shelf !== null && ' > ' + location.shelf) || '') +
      ((location.container !== null && ' > ' + location.container) || '')
    )
  }
  return location
}

export const initialState = (window.Cypress && window.initialState) || {
  alertIsVisible: false,
  alertMessage: '',
  clickableColumn: ['pegasusId', 'description'],
  columnData: [
    {
      id: 'imageUrl',
      numeric: false,
      disablePadding: true,
      label: 'Image',
      textAlign: 'right',
    },
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Pegasus Id',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Description',
      textAlign: 'right',
    },
    {
      id: 'checkInStatus',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      textAlign: 'right',
    },
    {
      id: ' ',
      numeric: false,
      disablePadding: true,
      label: 'Action',
      textAlign: 'right',
    },
  ],
  data: [],
  isVisible: false,
  unknownPegasusIds: [],
  unknownLocationIds: [],
  duplicatePegasusIdsForMove: [],
  item: {},
  numSelected: 0,
  onClick: () => {},
  location: '',
  printQueue: [],
  popupIsVisible: false,
  assignLocationRequestPending: false,
  fetchSamplesRequestFailure: false,
  fetchSamplesRequestPending: false,
  locationRequestFailure: false,
  locationRequestPending: false,
  checkinRequestFailure: false,
  checkinRequestPending: false,
  previousPage: '',
  isFetchNoSamples: false,
  fetchedLocationData: {},
  assignedSamplesSuccess: false,
}

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_NO_SAMPLES: {
      const {
        payload: { locationIds = [] },
      } = action
      return Object.assign({}, state, {
        isFetchNoSamples: true,
        unknownLocationIds: locationIds,
      })
    }
    case types.FETCH_LOCATION_DETAILS_SUCCESS: {
      const {
        payload: { locationData = {} },
      } = action
      return Object.assign({}, state, {
        fetchedLocationData: locationData,
      })
    }
    case types.FETCH_SAMPLES_REQUEST_SUCCESS: {
      const {
        payload: { data: payloadData = [] },
      } = action
      const {
        data: stateData = [],
        duplicatePegasusIdsForMove: stateDuplicates = [],
      } = state

      let i = state.data.length
      let payloadItems = []
      payloadData.forEach((it) => {
        i++
        payloadItems.push(Object.assign({}, it, { id: i + '_' + it.pegasusId }))
      })

      let items =
        payloadItems.filter(
          (obj) => !stateData.some((obj1) => obj1.pegasusId === obj.pegasusId)
        ) || []

      const duplicateSampels =
        payloadItems
          .filter((obj) =>
            stateData.some((obj1) => obj1.pegasusId === obj.pegasusId)
          )
          .map((obj) => obj.pegasusId) || []

      const combinedArr = [...items, ...stateData]
      const duplicatePegasusIds = Array.from(
        new Set([...duplicateSampels, ...stateDuplicates])
      )
      return Object.assign({}, state, {
        data: combinedArr,
        duplicatePegasusIdsForMove: duplicatePegasusIds,
        isFetchNoSamples: false,
      })
    }

    case types.FETCH_SAMPLES_CHECKIN_SUCCESS: {
      const pegasusIds = action.payload.pegasusIds
      const samples = action.payload.data
      let items = state.data.slice(0)
      let i = items.length
      pegasusIds.forEach((id) => {
        i++
        const newSample = samples.find(
          (it) => it.pegasusId === id || it.sampleId === id
        )
        if (!isEmpty(newSample)) {
          if (items.some((it) => it.pegasusId === id)) {
            items.unshift(
              Object.assign({}, newSample, {
                checkInStatus: 'DUPLICATE',
                id: i + '_' + newSample.pegasusId,
              })
            )
          } else {
            items.unshift(
              Object.assign({}, newSample, {
                id: i + '_' + newSample.pegasusId,
              })
            )
          }
        }
      })

      return Object.assign({}, state, {
        data: items,
        isFetchNoSamples: false,
      })
    }
    case types.FETCH_SAMPLES_REQUEST_PENDING:
      return Object.assign({}, state, {
        fetchSamplesRequestPending: action.payload.pending,
      })
    case types.FETCH_SAMPLES_REQUEST_FAILURE:
      return Object.assign({}, state, {
        fetchSamplesRequestFailure: true,
      })
    case types.CHECKIN_REQUEST_SUCCESS: {
      let newObj = {},
        newStateObj = []
      state.data.map((sample) => {
        const { pegasusId: sample_pegasusId = '' } = sample
        const findObj = !isEmpty(String(sample_pegasusId))
          ? action.payload.data.locations.find((item) =>
              String(sample_pegasusId).includes(item.pegasus_id)
            )
          : {}
        newObj = Object.assign({}, sample, {
          location: sampleLocationResponseToLocation(findObj),
        })
        newStateObj = [...newStateObj, newObj]
        return sample
      })

      return Object.assign({}, state, {
        data: newStateObj,
        isFetchNoSamples: false,
      })
    }
    case types.CHECKIN_REQUEST_PENDING:
      return Object.assign({}, state, {
        checkinRequestPending: action.payload.pending,
      })
    case types.CHECKIN_REQUEST_FAILURE:
      return Object.assign({}, state, {
        checkinRequestFailure: true,
      })
    case types.LOCATION_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        location: action.payload.data.data.default_location,
      })
    case types.LOCATION_REQUEST_PENDING:
      return Object.assign({}, state, {
        locationRequestPending: action.payload.pending,
      })
    case types.LOCATION_REQUEST_FAILURE:
      return Object.assign({}, state, {
        locationRequestFailure: true,
      })
    case types.CHECKIN_ADD_SAMPLE_TO_PRINT_QUEUE: {
      return Object.assign({}, state, {
        printQueue: [...state.printQueue, action.payload.id],
      })
    }
    case types.CHECKIN_REMOVE_SAMPLE_TO_PRINT_QUEUE: {
      const { printQueue = [] } = state
      const updatedPrintQueue = printQueue.filter(
        (obj) => obj !== action.payload.id
      )
      return Object.assign({}, state, {
        printQueue: [...updatedPrintQueue],
      })
    }
    case types.CHECKIN_REMOVE_SAMPLE_TO_PRINT_QUEUE_ALL: {
      return Object.assign({}, state, {
        printQueue: [],
      })
    }
    case types.CHECKIN_REMOVE_SAMPLE: {
      return Object.assign({}, state, {
        data: state.data.filter((sample) => sample.id !== action.payload.id),
      })
    }
    case types.CHECKIN_REMOVE_SAMPLES: {
      const {
        payload: { samplesList = [] },
      } = action
      const sampleIds = samplesList.map((obj) => obj.id)
      return Object.assign({}, state, {
        data: state.data.filter(
          (sample) => sampleIds.indexOf(sample.id) === -1
        ),
      })
    }
    case types.CHECKIN_CLEAR_DATA:
      return Object.assign({}, state, {
        data: [],
        unknownPegasusIds: [],
        printQueue: [],
        duplicatePegasusIdsForMove: [],
        isFetchNoSamples: false,
        fetchedLocationData: {},
        assignedSamplesSuccess: false,
      })
    case types.CHECKIN_SET_UNKNOWN_DATA:
      return Object.assign({}, state, {
        unknownPegasusIds: action.payload.data,
      })
    case types.CHECKIN_SET_DUPLICATE_DATA: {
      const { payload: { data: duplicateSampels = [] } = {} } = action
      const { duplicatePegasusIdsForMove: stateDuplicates = [] } = state
      const duplicatePegasusIds = Array.from(
        new Set([...duplicateSampels, ...stateDuplicates])
      )
      return Object.assign({}, state, {
        duplicatePegasusIdsForMove: duplicatePegasusIds,
      })
    }
    case types.CHECKIN_REMOVE_UNKNOWN_SAMPLE_FROM_LIST: {
      const { payload: { sampleId = '', type: payloaType = '' } = {} } = action
      const { unknownPegasusIds = [] } = state
      const newList =
        payloaType === 'all'
          ? []
          : unknownPegasusIds.filter((obj) => obj !== sampleId)
      return Object.assign({}, state, {
        unknownPegasusIds: [...newList],
      })
    }
    case types.MOVESAMPLES_REMOVE_UNKNOWN_SAMPLE_FROM_LIST: {
      return Object.assign({}, state, {
        unknownLocationIds: [],
      })
    }
    case types.CHECKIN_REMOVE_DUPLICATE_SAMPLE_FROM_LIST: {
      const { payload: { sampleId = '', type: payloaType = '' } = {} } = action
      const { duplicatePegasusIdsForMove = [] } = state
      const newList =
        payloaType === 'all'
          ? []
          : duplicatePegasusIdsForMove.filter((obj) => obj !== sampleId)
      return Object.assign({}, state, {
        duplicatePegasusIdsForMove: [...newList],
      })
    }
    // case types.CHECKIN_UPDATE_SAMPLE_ID: {
    //   let newDataObj = []
    //   const { data: stateData = [] } = state
    //   const {
    //     payload: { id: payloadId = '', sample: payloadSample = [] },
    //   } = action
    //   const dataWithoutExistingId = (stateData || []).filter(
    //     (obj) => payloadId.indexOf(obj.pegasusId) === -1
    //   )
    //   newDataObj = [...dataWithoutExistingId, ...payloadSample]
    //   return Object.assign({}, state, {
    //     data: newDataObj,
    //   })
    // }
    case types.CHECKIN_UPDATE_SAMPLE_ID: {
      const { data: stateData = [] } = state
      const {
        payload: { id: payloadId = '', sample: payloadSample = [] },
      } = action

      const pegasusIds = payloadSample.map((obj) => obj.pegasusId)
      let i = stateData.length
      const dataWithoutExistingId = (stateData || []).filter(
        (obj) => payloadId.indexOf(obj.pegasusId) === -1
      )
      let items = dataWithoutExistingId.slice(0)
      pegasusIds.forEach((id) => {
        i++
        const newSample = payloadSample.find((it) => it.pegasusId === id)
        if (!isEmpty(newSample)) {
          if (items.some((it) => it.pegasusId === id)) {
            items.push(
              Object.assign({}, newSample, {
                checkInStatus: 'DUPLICATE',
                id: i + '_' + newSample.pegasusId,
              })
            )
          } else {
            items.push(
              Object.assign({}, newSample, {
                id: i + '_' + newSample.pegasusId,
              })
            )
          }
        }
      })

      return Object.assign({}, state, {
        data: items,
      })
    }

    case types.SHOW_CHECKIN_PAGE_POPUP: {
      const {
        payload: { item: payloadItem = {} },
      } = action
      const { imageUrl = '' } = payloadItem
      const payloadItemFinal = Object.assign({}, payloadItem, {
        imagePopupUrl: imageUrl,
        imageUrl: getDisplayImage(imageUrl),
      })
      return Object.assign({}, state, {
        popupIsVisible: true,
        item: payloadItemFinal,
      })
    }
    case types.CLOSE_CHECKIN_PAGE_POPUP: {
      return Object.assign({}, state, {
        popupIsVisible: false,
        item: {},
      })
    }
    case types.ASSIGN_LOCATION_REQUEST_PENDING: {
      return Object.assign({}, state, {
        assignLocationRequestPending: action.payload.pending,
      })
    }
    case types.ASSIGN_LOCATION_REQUEST_SUCCESS: {
      let newDataObj = []
      let assignedSamples = []
      const { data: stateData = [] } = state
      const {
        payload: { samples: payloadSamples = {}, operation = 'CHECKIN' },
      } = action
      if (operation === 'MOVE_SAMPLES') {
        assignedSamples = payloadSamples.map((obj) => {
          return Object.assign(obj, { isMoved: true })
        })
      } else if (operation === 'CHECKIN') {
        assignedSamples = payloadSamples.map((obj) => {
          return Object.assign(obj, { isAssigned: true })
        })
      } else {
        assignedSamples = payloadSamples.map((obj) => obj)
      }
      const payloadIds = assignedSamples.map((obj) => obj.pegasusId)
      const dataWithoutExistingId = (stateData || []).filter(
        (obj) => payloadIds.indexOf(obj.pegasusId) === -1
      )
      newDataObj = [...dataWithoutExistingId, ...assignedSamples]
      return Object.assign({}, state, {
        data: newDataObj,
        assignedSamplesSuccess: true,
      })
    }
    case types.CHECKIN_UPDATE_SAMPLE_STATUS_SUCCESS: {
      const samples = Object.assign([], state.data)
      const sampleIds = samples.map((sample) => sample.pegasusId)

      // replace all updated samples
      action.payload.samples.forEach(
        (it) => (samples[sampleIds.indexOf(it.pegasusId)] = it)
      )
      return Object.assign({}, state, {
        data: samples,
      })
    }

    case types.CHECKIN_UPDATE_SAMPLE_STATUS_SUCCESS_NEXT: {
      const { data: stateData = [] } = state
      const {
        payload: { samples: payloadSamples = {} },
      } = action
      const sampleIds = payloadSamples.map((sample) => sample.pegasusId)
      const newDataObj = (stateData || []).map((obj) => {
        if (sampleIds.indexOf(obj.pegasusId) !== -1) {
          const findObj = payloadSamples.find(
            (fobj) => fobj.pegasusId === obj.pegasusId
          )
          obj = Object.assign({}, obj, findObj)
        }
        return obj
      })
      return Object.assign({}, state, {
        data: newDataObj,
      })
    }

    case types.CHECKIN_SET_PREVIOUS_STATE:
      return Object.assign({}, state, {
        previousPage: action.payload.previousPage,
      })
    case types.CALL_EDIT_FIELDS_API_CHECKIN: {
      const { data: stateData = [] } = state
      const {
        payload: { data: payloadData, sample_id },
      } = action
      const newDataObj = (stateData || []).map((obj) => {
        if (obj.pegasusId.indexOf(sample_id) !== -1) {
          obj = Object.assign({}, obj, payloadData)
        }
        return obj
      })
      return Object.assign({}, state, {
        ...state,
        data: newDataObj,
      })
    }
    default:
      return state
  }
}
