import axios from 'axios'
import apiConfig from '../apiConfig'

const {
  managements: { url = '' },
  apiKey = '',
} = apiConfig

export function getAllProjects(params = {}) {
  return axios.get(url + 'projects', {
    headers: { 'Content-type': 'application/json' },
    params: Object.assign({}, params, { key: apiKey }),
  })
}

export function getProjectPicklist(
  projectId = '',
  page = Number(1),
  rowsPerPage = Number(10)
) {
  return axios.get(`${url}picklists/${projectId}`, {
    headers: { 'Content-type': 'application/json' },
    params: { key: apiKey, page: page, per_page: rowsPerPage },
  })
}
export function addItemToPicklist(params) {
  return axios.post(url + 'picklists', params, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function deleteItemsFromPicklist(params) {
  return axios.delete(
    url + 'picklists',
    { data: params },
    {
      headers: { 'Content-type': 'application/json' },

      params: {
        key: apiKey,
      },
    }
  )
}

export function getAllProjectNames() {
  return axios.get(url + 'projects/project_names', {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function getProject(projectId) {
  return axios.get(`${url}projects/${projectId}`, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function getProjectSamples(projectId, page, rowsPerPage) {
  return axios.get(url + 'projects/' + projectId + '/samples', {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
      page: page,
      per_page: rowsPerPage,
    },
  })
}

export function getProjectRequesetSamples(
  projectId,
  page,
  rowsPerPage = 50,
  currentFilters = {}
) {
  const defaultParams = {
    key: apiKey,
    page: page,
    per_page: rowsPerPage === 0 ? 50 : rowsPerPage,
  }
  const params = Object.assign({}, defaultParams, currentFilters)
  const { dpci: dpciList = [] } = params
  params['dpcis'] = dpciList
  delete params['dpci']
  return axios.get(url + 'projects/' + projectId + '/requested_samples', {
    headers: { 'Content-type': 'application/json' },
    params: params,
  })
}

export async function updateProjectRequesetSamples(
  tcinShootTypeId,
  updateRequest
) {
  return await axios.put(
    `${url}projects/request_samples/${tcinShootTypeId}`,
    updateRequest,
    {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiKey,
      },
    }
  )
}

export function downloadProjectSamples(projectId) {
  return axios.get(url + 'projects/' + projectId, {
    headers: { Accept: 'text/csv' },
    params: {
      key: apiKey,
    },
  })
}

export function downloadRequestedSamples(projectId) {
  return axios.get(
    url + 'projects/' + projectId + '/downloads/requested_samples',
    {
      headers: { Accept: 'text/csv' },
      params: {
        key: apiKey,
      },
    }
  )
}
export function downloadCSVPicklist(projectId) {
  return axios.get(
    `${url}picklists/csv/${projectId}`,
    {
      headers: { Accept: 'text/csv' },
      params: {
        key: apiKey,
      },
    }
  )
}

export function downloadCSVRequestedSamples(projectId, shootTypeIds = []) {
  return axios.get(
    url + 'projects/' + projectId + '/downloads/selected_requested_samples',
    {
      headers: { Accept: 'text/csv' },
      params: {
        key: apiKey,
        tcin_shoot_type_ids: (shootTypeIds || []).join(','),
      },
    }
  )
}

export function downloadProjectContactSheet(params = {}, downloadType = '') {
  return axios.get(url + 'search?downloadAs=' + downloadType, {
    headers: { Accept: '*/*' },
    params: Object.assign(
      {},
      {
        key: apiKey,
      },
      params
    ),
    responseType: 'blob',
  })
}

export function downloadProjectContactSheetPost(
  params = {},
  downloadType = ''
) {
  return axios.post(url + 'search?downloadAs=' + downloadType, params, {
    headers: { Accept: '*/*' },
    params: {
      key: apiKey,
    },
    responseType: 'blob',
  })
}

export function createProject(project) {
  return axios.post(url + 'projects', project, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function updateProject(shootTypeId, updateRequest) {
  return axios.put(url + 'projects/' + shootTypeId, updateRequest, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export const deleteProject = (shootTypeId) => {
  return axios.delete(url + 'projects/' + shootTypeId, {
    params: {
      key: apiKey,
    },
  })
}

export const getShootTypeChannel = () => {
  return axios.get(url + 'projects/project_channel', {
    params: {
      key: apiKey,
    },
  })
}

export const getShootTypeEditors = () => {
  return axios.get(url + 'projects/users/editors', {
    params: {
      key: apiKey,
    },
  })
}

export async function uploadCSVFile(fileToUpload, projectID = '') {
  let formdata = new window.FormData()
  formdata.append('file', fileToUpload)

  // Adding params key in both url and config
  return await axios.put(
    url + 'projects/' + projectID + '/request_samples?key=' + apiKey,
    formdata,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
      params: {
        key: apiKey,
      },
    }
  )
}

export function requestSamplesWithOutCSV(itemList = [], projectID = '') {
  return axios.put(
    url + 'projects/' + projectID + '/request_samples',
    itemList,
    {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiKey,
      },
    }
  )
}

export function removeRequestedSamples(itemList = []) {
  return axios.delete(url + 'projects/request_samples', {
    params: {
      id: itemList?.join(','),
      key: apiKey,
    },
  })
}

export function downloadInvenSampBySelect(shootTypeId, selectedPGIds) {
  return axios.get(
    url + 'projects/' + shootTypeId + '/downloads/selected_samples',
    {
      headers: { Accept: 'text/csv' },
      params: {
        sample_ids: selectedPGIds?.join(','),
        key: apiKey,
      },
    }
  )
}

export function submitOrderForSelected(shootTypeIds = []) {
  return axios.put(
    url + 'projects/request_samples/submit_for_order',
    {
      tcin_shoot_type_ids: shootTypeIds?.join(','),
    },
    {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiKey,
        tcin_shoot_type_ids: shootTypeIds?.join(','),
      },
    }
  )
}

export function editSamplesSubmit(params = [], lanId = '') {
  return axios.put(url + 'projects/request_samples', [...params], {
    params: {
      key: apiKey,
    },
  })
}
