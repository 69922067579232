import { Accordion, AccordionDetails, Divider } from '@material-ui/core'
import ShipmentReadOnlyList from '../Partners/ShipmentReadOnlyList'

const ViewShipmentAccordion = ({
  classes,
  shipmentData,
  showReuseShipment = false,
  setEditShipment,
  shipmentAccordionSummary,
}) => {
  const fieldList = getFieldList(shipmentData)
  return (
    <Accordion defaultExpanded>
      {shipmentAccordionSummary}
      <Divider />

      <AccordionDetails style={{ padding: '25px' }}>
        <ShipmentReadOnlyList
          fieldList={fieldList}
          classes={classes}
          showReuseShipment={showReuseShipment}
          setEditShipment={setEditShipment}
        />
      </AccordionDetails>
    </Accordion>
  )
}

const getFieldList = (shipmentData) => {
  return [
    { lable: 'Shipment Name', value: shipmentData.shipmentName },
    { lable: 'Shipped from', value: shipmentData.shipFromLocName },
    { lable: 'Shipped to', value: shipmentData.shipToLocName },
    { lable: 'Carrier', value: shipmentData.carrier },
    { lable: 'Tracking', value: shipmentData.tracking },
    { lable: 'Shipment Channel', value: shipmentData.shipmentChannel },
    { lable: 'Shipment Creator', value: shipmentData.shipmentCreator },
    // {
    //   lable: 'Project Channel',
    //   value:
    //     shipmentData.project === null || shipmentData.project === undefined
    //       ? ''
    //       : shipmentData.project.channel,
    // },
    {
      lable: 'Shoot Location',
      value:
        shipmentData.project === null || shipmentData.project === undefined
          ? ''
          : shipmentData.project.locationName === null ||
            shipmentData.project.locationName === undefined
          ? ''
          : shipmentData.project.locationName,
    },
    {
      lable: 'Shoot Start Date',
      value:
        shipmentData.project === null || shipmentData.project === undefined
          ? ''
          : shipmentData.project.shootStartDate === null ||
            shipmentData.project.shootStartDate === undefined
          ? ''
          : shipmentData.project.shootStartDate,
    },
    {
      lable: 'Shoot End Date',
      value:
        shipmentData.project === null || shipmentData.project === undefined
          ? ''
          : shipmentData.project.shootEndDate === null ||
            shipmentData.project.shootEndDate === undefined
          ? ''
          : shipmentData.project.shootEndDate,
    },
    { lable: 'Shipment Notes', value: shipmentData.notes },
    {
      lable: 'Project Name',
      value:
        shipmentData.project === null || shipmentData.project === undefined
          ? null
          : shipmentData.project.name,
      gridSize: 4,
    },
    {
      lable: 'Marketing PIDs',
      value: shipmentData.marketingPid || [],
      fieldType: 'MarketingList',
      gridSize: 10,
      addDivider: true,
    },
    {
      lable: 'Partners',
      value: shipmentData.shipmentPartners || [],
      fieldType: 'PartnersList',
      gridSize: 10,
      addDivider: true,
    },
  ]
}

export default ViewShipmentAccordion
