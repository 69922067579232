//

import * as React from 'react'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'

import Footer from './Footer'
import Header from './Header'
import Link from './Link'

const isHeader = (child) => {
  return child && child.type && child.type === Header
}

const isFooter = (child) => {
  return child && child.type && child.type === Footer
}

export function SideNav(props) {
  const { children, className, classes, isOpen, onClose, variant } = props

  const childrenArray = React.Children.toArray(children)
  const footer =
    childrenArray.length > 0 ? childrenArray.filter(isFooter) : null
  const header =
    childrenArray.length > 0 ? childrenArray.filter(isHeader) : null
  const listItems = childrenArray
    .filter((child) => {
      if (child.type && (isHeader(child) || isFooter(child))) {
        return false
      }
      return true
    })
    .map((_child, key) => {
      let child = _child

      if (child.type === Link) {
        const childClasses = child.props.classes || {}

        child = React.cloneElement(child, {
          activeClassName: child.props.activeClassName || classes.activeLink,
          classes: {
            root: childClasses.root || classes.linkRoot,
            ...childClasses,
          },
          onClose,
        })
      }

      return <li key={key}>{child}</li>
    })

  return (
    <Drawer
      open={isOpen}
      onClose={() => onClose()}
      classes={{
        paper: classnames(
          classes.paper,
          {
            [classes.paper__isFixed]: variant === 'temporary',
          },
          className
        ),
      }}
      variant={variant}
    >
      {header ? header[0] : null}
      {listItems.length > 0 ? (
        <List
          aria-label="navigation"
          disablePadding
          role="navigation"
          classes={{
            root: classes.list,
          }}
        >
          {listItems}
        </List>
      ) : null}
      {footer ? footer[0] : null}
    </Drawer>
  )
}

SideNav.defaultProps = {
  classes: {},
  variant: 'temporary',
}

const styles = (theme) => ({
  activeLink: {
    backgroundColor: grey[400],
  },
  linkRoot: {
    color: grey[900],
    '&:focus, &:hover': {
      backgroundColor: theme.palette.action.hover,
      outline: 'none',
    },
  },
  paper: {
    width: 250,
    position: 'static',
  },
  paper__isFixed: {
    position: 'fixed',
  },
  list: {
    overflow: 'visible',
    flex: '1 1 auto',
  },
})

export default withStyles(styles)(SideNav)
