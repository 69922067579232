//

import * as React from 'react'
import Accent from './Accent'
import { withStyles } from '@material-ui/core/styles'

export class AnimationAccent extends React.Component {
  static defaultProps = {
    classes: {},
  }

  state = {
    left: NaN,
    width: NaN,
  }

  componentWillReceiveProps(nextProps) {
    const { from, isAnimating, offset = 0, to } = nextProps

    if (from && from.left && from.width) {
      this.setState({
        left: from.left + offset * -1,
        width: from.width,
      })
    }

    if (isAnimating) {
      window.requestAnimationFrame(() => {
        if (to && to.left && to.width) {
          this.setState({
            left: to.left + offset * -1,
            width: to.width,
          })
        }

        setTimeout(() => {
          this.props.onAnimationEnd()
        }, 200)
      })
    }
  }

  render() {
    const { classes, isAnimating } = this.props
    const { left, width } = this.state

    const styles = {
      opacity: isAnimating ? 1 : 0,
    }

    if (!isNaN(left)) {
      styles.left = left
    }

    if (!isNaN(width)) {
      styles.width = width
    }

    return (
      <div className={classes.wrapper} style={styles}>
        <Accent />
      </div>
    )
  }
}

const styleSheet = ({ transitions }) => {
  return {
    wrapper: {
      bottom: 0,
      position: 'absolute',
      transition: `
        left ${transitions.duration.shortest}ms ${transitions.easing.sharp},
        width ${transitions.duration.shortest}ms ${transitions.easing.sharp}
      `,
      willChange: 'left, width',
    },
  }
}

export default withStyles(styleSheet)(AnimationAccent)
