import * as shootShipmentAPI from '../../services/shootShipmentService'
import * as shootShipmentMapper from '../../mappers/shootShipmentMapper'
import * as types from './actionType'
import * as partnersAPI from '../../services/partnersService'

export const getPendingShootShipment = () => {
  return (dispatch) => {
    dispatch(getPendingShootShipmentPending(true))
    return shootShipmentAPI
      .fetchPendingShipments()
      .then((res) => shootShipmentMapper.shipmentsResponseToShipments(res))
      .then((shipments = []) => {
        dispatch(getPendingShootShipmentSuccess(shipments))
      })
      .catch((error) => {
        dispatch(getPendingShootShipmentFailure())
      })
      .finally(() => {
        dispatch(getPendingShootShipmentPending(false))
      })
  }
}

export const getPendingShootShipmentPending = (pending = false) => {
  return {
    type: types.PENDING_SHIP_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export const getPendingShootShipmentFailure = () => {
  return {
    type: types.PENDING_SHIP_SAMPLES_FAILURE,
  }
}

export const getPendingShootShipmentSuccess = (pendingShipments) => {
  return {
    type: types.PENDING_SHIP_SAMPLES_SUCCESS,
    payload: { pendingShipments: pendingShipments },
  }
}

export const getShippedShootShipment = (payload = {}) => {
  const { filters = [] } = payload
  const payloadFilters = shootShipmentMapper.getPayloadFilters(filters)
  payload['filtersList'] = payloadFilters
  return (dispatch) => {
    dispatch(getShippedShootShipmentPending(true))
    return shootShipmentAPI
      .fetchShippedShipments(payload)
      .then((res) => ({
        shipments: shootShipmentMapper.shipmentsResponseToShipments(res),
        totalCount: res?.data?.total_count || Number(0),
      }))
      .then((result) => {
        dispatch(getShippedShootShipmentSuccess(result))
      })
      .catch((error) => {
        dispatch(getShippedShootShipmentFailure())
      })
      .finally(() => {
        dispatch(getShippedShootShipmentPending(false))
      })
  }
}

export const getShippedShootShipmentPending = (pending = false) => {
  return {
    type: types.SHIPPED_SHIP_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export const getShippedShootShipmentFailure = () => {
  return {
    type: types.SHIPPED_SHIP_SAMPLES_FAILURE,
  }
}

export const getShippedShootShipmentSuccess = ({ shipments, totalCount }) => {
  return {
    type: types.SHIPPED_SHIP_SAMPLES_SUCCESS,
    payload: { shippedShipments: shipments, totalCount },
  }
}

export const savePendingFilterValue = (filterValue) => {
  return {
    type: types.SHIPMENTS_TABLE_FILTER_VALUE,
    payload: { filterValue },
  }
}
export const saveShippedFilterValue = (filterValue) => {
  return {
    type: types.SHIPMENTS_TABLE_SHIPPED_FILTER_VALUE,
    payload: { filterValue },
  }
}

export const saveShipmentsTabValue = (status = '') => {
  return {
    type: types.SHIPMENTS_SET_TAB_VALUE,
    payload: { tabValue: status },
  }
}

export const saveShipmentsTabsAttributes = (tabBasedAttributes = {}) => {
  return {
    type: types.SHIPMENTS_SET_TAB_ATTRIBUTES,
    payload: { tabBasedAttributes: tabBasedAttributes },
  }
}

export function getPartners() {
  return async (dispatch) => {
    dispatch(fetchPartnersRequestPending(true))
    try {
      const { data: partnersResult } = await partnersAPI.getPartners()
      await dispatch(fetchPartnersRequestSuccess(partnersResult))
    } catch (err) {
      await dispatch(fetchPartnersRequestFailure())
    }

    dispatch(fetchPartnersRequestPending(false))
  }
}

export function fetchPartnersRequestPending(pending = false) {
  return {
    type: types.FETCH_PARTNERS_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export const fetchPartnersRequestSuccess = (allPartnersList) => {
  return {
    type: types.FETCH_PARTNERS_REQUEST_SUCCESS,
    payload: { allPartnersList },
  }
}

export function fetchPartnersRequestFailure() {
  return {
    type: types.FETCH_PARTNERS_REQUEST_FAILURE,
  }
}
