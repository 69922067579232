export const sampleShootTypeResponseToSampleShootTypes = (sampleShootTypes) => {
  return sampleShootTypes.data.sampleShootTypes.map((sampleShootType) =>
    sampleShootTypeResponseToSampleShootType(sampleShootType)
  )
}

export const sampleShootTypeResponseToSampleShootTypesNew = (
  sampleShootTypes
) => {
  return sampleShootTypes.sampleShootTypes.map((sampleShootType = {}) =>
    sampleShootTypeResponseToSampleShootTypeNew(sampleShootType)
  )
}
function sampleShootTypeResponseToSampleShootTypeNew(sampleShootType = {}) {
  const {
    id: sampleShootTypeId = '',
    shootTypeId = '',
    pegasusId = '',
    createdBy = '',
    prop_id = '',
  } = sampleShootType
  return {
    sampleShootTypeId: sampleShootTypeId,
    ShootTypeId: shootTypeId,
    pegasusId: pegasusId,
    createdBy: createdBy,
    propId: prop_id,
  }
}

export function sampleShootTypeResponseToSampleShootType(sampleShootType) {
  return {
    sampleShootTypeId: sampleShootType.sample_shoot_type_id,
    ShootTypeId: sampleShootType.shoot_type_id,
    pegasusId: sampleShootType.pegasus_id,
    createdBy: sampleShootType.created_by,
    propId: sampleShootType?.prop_id,
  }
}
