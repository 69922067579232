import { createRef } from 'react'
import Dropzone from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import { cloneDeep, uniqBy } from 'lodash'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import withHOCs from 'util/withHocs'
import { SAMPLES } from 'enums/Tabs'

const dropzoneRef = createRef()
const CHUNK_SIZE = Number(2 * 1024 * 1024)

const useStyles = makeStyles({
  card: {
    backgroundColor: '#FAFAFA',
    width: '80%',
    height: '100%',
    marginTop: 20,
    color: (props) => (props.fileLimitExceed ? 'grey !important' : 'auto'),
  },
  assetCardContent: {
    // minHeight: '45vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px dashed grey',
    backgroundColor: '#EFEFEF',
  },
  uploadButton: {
    backgroundColor: '#188295',
    color: '#FFFFFF',
    padding: '15px 30px',
    letterSpacing: '0px',
    '&:hover': {
      backgroundColor: '#002171',
    },
  },
  disableButton: {
    padding: '15px 30px',
    letterSpacing: '0px',
  },
  dropDesc: {
    margin: '10px',
    fontSize: '14px',
  },
})

function ImageUploadContainer(props) {
  const {
    onDropFiles = () => {},
    currentPropImages = [],
    selectedFiles = [],
    sampleType = '',
  } = props
  const nonTcinImages = currentPropImages?.filter(
    (obj) => obj.status !== 'nodelete'
  )
  const allPropNonTcinImages = uniqBy(
    cloneDeep([...nonTcinImages, ...selectedFiles] || []),
    'name'
  )
  const maxLimit = sampleType === SAMPLES ? 2 : 3
  const fileLimitExceed = allPropNonTcinImages?.length >= maxLimit
  const classes = useStyles({ fileLimitExceed })

  const openSystemDialog = (e) => {
    e.preventDefault()
    if (dropzoneRef && dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  const onDrop = (acceptedfiles = [], rejectedFiles = []) => {
    const { handleClose = () => {} } = props
    onDropFiles(acceptedfiles, rejectedFiles)
    handleClose()
  }

  return (
    <Dropzone
      noClick
      style={{ position: 'relative', width: '100%', height: '100%' }}
      accept={{
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
        'image/png': ['.png'],
      }}
      onDrop={onDrop}
      minSize={5000}
      maxSize={CHUNK_SIZE}
      ref={dropzoneRef}
      disabled={fileLimitExceed}
    >
      {({ getRootProps, getInputProps }) => (
        <section className={classes.dropzone}>
          <div {...getRootProps()} style={{ textAlign: '-webkit-center' }}>
            <input {...getInputProps()} />
            <>
              <Card className={classes.card}>
                <CardContent className={classes.assetCardContent}>
                  {
                    <div>
                      To upload drag files here or
                      <Button
                        onClick={openSystemDialog}
                        color="primary"
                        aria-label="upload"
                        href="#"
                        className={classes.uploadButton}
                        disabled={fileLimitExceed}
                      >
                        SELECT IMAGES
                      </Button>
                      <div className={classes.dropDesc}>
                        {`(Upto ${maxLimit} images, only JPG or PNG)`}
                      </div>
                    </div>
                  }
                </CardContent>
              </Card>
            </>
          </div>
        </section>
      )}
    </Dropzone>
  )
}

export default withHOCs({ auth: true, nav: true })(ImageUploadContainer)
