import axios from 'axios'
import apiConfig from '../apiConfig'
import { locationToLocationRequest } from '../mappers/locationMapper'

const {
  managements: { url: managementsUrl = '' },
  barcode: { url: barcodeUrl = '' },
  apiKey = '',
} = apiConfig

export function fetchSubLocationById(locationId = '') {
  return axios.get(managementsUrl + 'locations/sub_locations/' + locationId, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

/**
 * API to sublocations wrt the parent location ID list in string comma separated formate
 * similar to fetchSubLocationById but with multiple locations ID support
 * @param {String} parentIds
 * @returns
 */
export function fetchSubLocationsByIdList(parentIds = {}) {
  return axios.get(managementsUrl + 'locations/sub_locations', {
    headers: { 'Content-Type': 'application/json' },
    params: {
      parent_ids: parentIds,
      key: apiKey,
    },
  })
}

export function fetchFilteredLocations(params) {
  return axios.get(managementsUrl + 'locations', {
    headers: { 'Content-Type': 'application/json' },
    params: params,
  })
}

export function createANewLocation(params = {}) {
  return axios.post(managementsUrl + 'locations', params, {
    params: {
      key: apiKey,
    },
  })
}

export function locationPrintLabel(params = [], type = 'dymo') {
  return axios.post(barcodeUrl + 'location_barcodes', params, {
    params: {
      key: apiKey,
      barcode_type: type,
    },
    responseType: 'blob',
  })
}

export function getCheckInLocation() {
  return axios.get(managementsUrl + 'locations/checkins', {
    params: {
      key: apiKey,
    },
  })
}

export function addNewShipToLocation(shipToLocationInfo) {
  return axios.post(
    managementsUrl + 'locations/studio_location',
    shipToLocationInfo,
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export function getLLBuildingByLocation(location, operation = 'generic') {
  return axios.get(managementsUrl + 'locations/buildings/' + location, {
    params: {
      key: apiKey,
      operation,
    },
  })
}

export function getBuildings() {
  return axios.get(managementsUrl + 'locations/buildings', {
    params: {
      key: apiKey,
    },
  })
}

export function getAisles(location = {}) {
  return axios.post(
    managementsUrl + 'locations/aisles',
    locationToLocationRequest(location),
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export function getSections(location = {}) {
  return axios.post(
    managementsUrl + 'locations/sections',
    locationToLocationRequest(location),
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export function getShelves(location = {}) {
  return axios.post(
    managementsUrl + 'locations/shelves',
    locationToLocationRequest(location),
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export function getContainers(location = {}) {
  return axios.post(
    managementsUrl + 'locations/containers',
    locationToLocationRequest(location),
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export function findLocationByBuilding(location = {}) {
  return axios.post(managementsUrl + 'locations/location_ids', location, {
    params: {
      key: apiKey,
    },
  })
}

export function findLocation(location = {}) {
  return axios.post(
    managementsUrl + 'locations/location_ids',
    locationToLocationRequest(location),
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export function getLocationById(id = 0) {
  return axios.get(managementsUrl + 'locations/' + id, {
    params: {
      key: apiKey,
    },
  })
}

export function fetchDownloadLocations(params) {
  return axios.get(managementsUrl + 'locations/download_locations', {
    params: params,
  })
}

export function deleteLocations(params) {
  return axios.delete(managementsUrl + 'locations', {
    headers: { 'Content-type': 'application/json' },
    params: Object.assign({}, params, { key: apiKey }),
  })
}

export function renameLocationsServ(location = {}) {
  return axios.put(managementsUrl + 'locations', location, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}
