import * as types from './actionType'
import * as locationAPI from '../../services/locationService'
import * as locationMapper from '../../mappers/locationMapper'
import { download } from '../../util/DownloadCsv'
import { showNotification } from '../../store/notification/actionCreator'

export function callAisles(location = {}) {
  return async (dispatch) => {
    dispatch(fetchAislesRequestPending(true))
    return await locationAPI
      .getAisles(location)
      .then((allAisles) => {
        dispatch(fetchAislesSuccess(allAisles))
        dispatch(fetchAislesRequestPending(false))
      })
      .catch(() => {
        dispatch(fetchAislesFailure())
      })
      .finally(() => {
        dispatch(fetchAislesRequestPending(false))
      })
  }
}

export function fetchAislesFailure() {
  return {
    type: types.FETCH_AISLES_FAILURE,
    payload: { fetchDataError: 'Failed to fetch data' },
  }
}

export function fetchAislesRequestPending(pending = false) {
  return {
    type: types.FETCH_AISLES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchAislesSuccess(allAisles = []) {
  return {
    type: types.FETCH_AISLES_SUCCESS,
    payload: { data: allAisles },
  }
}

export function callSections(location = {}) {
  return async (dispatch) => {
    dispatch(fetchSectionsPending(true))
    return await locationAPI
      .getSections(location)
      .then((allSections) => {
        dispatch(fetchSectionsSuccess(allSections))
        dispatch(fetchSectionsPending(false))
      })
      .catch(() => {
        dispatch(fetchSectionsFailure())
      })
      .finally(() => {
        dispatch(fetchSectionsPending(false))
      })
  }
}

export function fetchSectionsFailure() {
  return {
    type: types.FETCH_SECTIONS_FAILURE,
    payload: { fetchDataError: 'Failed to fetch data' },
  }
}

export function fetchSectionsPending(pending = false) {
  return {
    type: types.FETCH_SECTIONS_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchSectionsSuccess(allSections = []) {
  return {
    type: types.FETCH_SECTIONS_SUCCESS,
    payload: { data: allSections },
  }
}

export function callShelves(location = {}) {
  return async (dispatch) => {
    dispatch(fetchShelvesRequestPending(true))
    return await locationAPI
      .getShelves(location)
      .then((allShelves) => {
        dispatch(fetchShelvesSuccess(allShelves))
        dispatch(fetchShelvesRequestPending(false))
      })
      .catch(() => {
        dispatch(fetchShelvesFailure())
      })
      .finally(() => {
        dispatch(fetchShelvesRequestPending(false))
      })
  }
}

export function fetchShelvesFailure() {
  return {
    type: types.FETCH_SHELVES_FAILURE,
    payload: { fetchDataError: 'Failed to fetch data' },
  }
}

export function fetchShelvesRequestPending(pending = false) {
  return {
    type: types.FETCH_SHELVES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchShelvesSuccess(allShelves = []) {
  return {
    type: types.FETCH_SHELVES_SUCCESS,
    payload: { data: allShelves },
  }
}

export function callContainer(location = {}) {
  return async (dispatch) => {
    dispatch(fetchContainerRequestPending(true))
    return await  locationAPI
      .getContainers(location)
      .then((allContainer) => {
        dispatch(fetchContainerSuccess(allContainer))
        dispatch(fetchContainerRequestPending(false))
      })
      .catch(() => {
        dispatch(fetchContainerFailure())
      })
      .finally(() => {
        dispatch(fetchContainerRequestPending(false))
      })
  }
}

export function fetchContainerFailure() {
  return {
    type: types.FETCH_CONTAINER_FAILURE,
    payload: { fetchDataError: 'Failed to fetch data' },
  }
}

export function fetchContainerRequestPending(pending = false) {
  return {
    type: types.FETCH_CONTAINER_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchContainerSuccess(allContainer = []) {
  return {
    type: types.FETCH_CONTAINER_SUCCESS,
    payload: { data: allContainer },
  }
}

export function filterLocations(params = new Map()) {
  const location_ids = params.has('location_ids')
    ? params.get('location_ids')
    : ''
  return (dispatch) => {
    dispatch(fetchFilterPending(true))
    return locationAPI
      .fetchFilteredLocations(params)
      .then((filteredLocations = {}) => {
        const {
          data: {
            locations: dataLocations = [],
            pages_count = Number(0),
            total_count = Number(0),
          },
        } = filteredLocations
        return {
          locations: locationMapper.locationResponseToLocations([
            ...dataLocations,
          ]),
          pages_count: pages_count,
          total_count: total_count,
        }
      })
      .then((newLocations = {}) => {
        dispatch(fetchFilterSuccess(newLocations, location_ids))
        dispatch(fetchFilterPending(false))
      })
      .catch((error) => {
        dispatch(fetchFilterFailure())
      })
      .finally(() => {
        dispatch(fetchFilterPending(false))
      })
  }
}

export function setPage(page = 1) {
  return {
    type: types.MANAGE_LOCATIONS_SET_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage = 100) {
  return {
    type: types.MANAGE_LOCATIONS_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function fetchFilterFailure() {
  return {
    type: types.FETCH_FILTER_FAILURE,
    payload: { fetchDataError: 'Failed to fetch data' },
  }
}

export function fetchFilterPending(pending = false) {
  return {
    type: types.FETCH_FILTER_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchFilterSuccess(
  filteredLocations = [],
  filteredLocation = ''
) {
  return {
    type: types.FETCH_FILTER_SUCCESS,
    payload: { data: filteredLocations, filteredLocation: filteredLocation },
  }
}

export function downloadStudioLocation(params) {
  return (dispatch) => {
    locationAPI
      .fetchDownloadLocations(params)
      .then((DownloadedLocations) => {
        download(DownloadedLocations)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function handleFieldUpdate(field, value, locationId) {
  return {
    type: types.MANAGE_LOCATIONS_EDIT_SET_FIELD_VALUE,
    payload: {
      field: field,
      value: value,
      location_id: locationId,
    },
  }
}

export function renameLocationsPending(pending = false) {
  return {
    type: types.RENAME_LOCATION_PENDING,
    payload: { pending: pending },
  }
}

export function renameLocations(params = {}, callback = () => {}) {
  return (dispatch) => {
    dispatch(renameLocationsPending(true))
    locationAPI
      .renameLocationsServ(params)
      .then((response) => {
        return locationMapper.locationResponseToLocation(response.data)
      })
      .then((filteredLocations) => {
        dispatch(renameLocationSuccessFun(filteredLocations))
        dispatch(renameLocationSuccessStatus(true))
        callback('success', params)
      })
      .catch((error = {}) => {
        const {
          response: { data: errorMessage = '' },
        } = error
        dispatch(showNotification(true, errorMessage))
        dispatch(renameLocationFailure())
      })
      .finally(() => {
        dispatch(renameLocationsPending(false))
      })
  }
}

export function renameLocationSuccessFun(data) {
  return {
    type: types.RENAME_LOCATION_SUCCESS,
    payload: { data: data },
  }
}

export function renameLocationSuccessStatus(status = false) {
  return {
    type: types.RENAME_LOCATION_SUCCESS_STATUS,
    payload: { status: status },
  }
}

export function renameLocationFailure() {
  return {
    type: types.RENAME_LOCATION_FAILURE,
  }
}

export function deleteLocations(params = {}, callback = () => {}) {
  return (dispatch) => {
    locationAPI
      .deleteLocations(params)
      .then((response) => {
        callback()
        dispatch(showNotification(true, 'Successfully deleted', 'success'))
        dispatch(deleteLocationsSuccessFun(true))
      })
      .catch((error = {}) => {
        const {
          response: {
            data: {
              errors: {
                message: errorMessage = '',
                samples: errorSamples = {},
                sub_locations: errorSubLocations = {},
              },
            },
          },
        } = error
        const errorLocations = [
          ...(Object.keys(errorSamples) || []),
          ...(Object.keys(errorSubLocations) || []),
        ]
        const detailDeleteErrors = {
          samples: errorSamples,
          locations: errorSubLocations,
        }
        dispatch(showNotification(true, errorMessage))
        dispatch(deleteLocationsFailure(errorLocations, detailDeleteErrors))
      })
  }
}

export function deleteLocationsFailure(
  errorLocations = [],
  detailDeleteErrors = {}
) {
  return {
    type: types.DELETE_LOCATIONS_FAILURE,
    payload: {
      errorLocations: errorLocations,
      detailDeleteErrors: detailDeleteErrors,
    },
  }
}

export function deleteLocationsSuccessFun(status = false) {
  return {
    type: types.DELETE_LOCATIONS_SUCCESS,
    payload: {
      status: status,
    },
  }
}

// export function showAlert(message) {
//   return {
//     type: types.SHOW_ML_DELETE_ALERT,
//     payload: { message: message },
//   }
// }

export function resetStartOver() {
  return {
    type: types.RESET_TO_START_OVER,
  }
}

export function resetToInit() {
  return {
    type: types.RESET_TO_INIT_STATE_ML,
  }
}

export function setCheckBox(selected) {
  return {
    type: types.MANAGE_LOCATIONS_SET_CHECKBOX,
    payload: { selected: selected },
  }
}

export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.MANAGE_LOCATIONS_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}
export function setSortedData(sortedData = []) {
  return {
    type: types.MANAGE_LOCATIONS_SET_SORTED_DATA,
    payload: { data: sortedData },
  }
}
