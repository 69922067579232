import * as types from './actionType'
import { checkIsEnabledRow, getDisplayImage } from '../../util/CommonUtils'
import { findIndex } from 'lodash'

export const initialState = {
  alertIsVisable: false,
  alertMessage: '',
  checkBoxEnabled: true,
  clickableColumn: [
    'description',
    'sampleStatus',
    'launchDate',
    'vendorEmail',
    'dueDate',
    'shipTo',
  ],
  pageName: 'myOrders',
  columnData: [
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      textAlign: 'right',
    },
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: false,
      label: 'Pegasus ID',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      textAlign: 'right',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'subClassName',
      numeric: true,
      disablePadding: false,
      label: 'Sub Class',
      textAlign: 'right',
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class ID',
      textAlign: 'right',
    },
    {
      id: 'sampleStatus',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      textAlign: 'right',
    },
    {
      id: 'launchDate',
      numeric: false,
      disablePadding: false,
      label: 'Launch Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'vendorEmail',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Emails',
      textAlign: 'right',
    },
    {
      id: 'dueDate',
      numeric: true,
      disablePadding: false,
      label: 'Due Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'shipTo',
      numeric: true,
      disablePadding: false,
      label: 'Ship To',
      textAlign: 'right',
    },
    {
      id: 'vendorNotes',
      numeric: true,
      disablePadding: false,
      label: 'Vendor Notes',
      textAlign: 'right',
    },
    {
      id: 'studioNotes',
      numeric: true,
      disablePadding: false,
      label: 'Studio Notes',
      textAlign: 'right',
    },
    {
      id: 'shippingInfo',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Shipment',
      textAlign: 'right',
    },
  ],
  duplicateSamplesPending: false,
  duplicateSamplesFailure: false,
  data: [],
  dataKeyLabels: [
    { key: 'pegasusId', label: 'Pegasus ID' },
    { key: 'sampleStatus', label: 'Sample Status' },
    { key: 'projects', label: 'Projects/Collections' },
    { key: 'createDate', label: 'Sample Ordered Date' },
    { key: 'orderNum', label: 'Order #' },
    { key: 'vendorEmail', label: 'Vendor Email Addresses' },
    { key: 'shipDate', label: 'Vendor Ship Date' },
    { key: 'shipmentId', label: 'Vendor Shipment #' },
    { key: 'shipToDestination', label: 'Vendor Shipment Destination' },
    { key: 'trackingNum', label: 'Vendor Shipment Tracking #' },
    { key: 'carrier', label: 'Vendor Shipment Carrier' },
    { key: 'receivedDate', label: 'Sample Received Date' },
    { key: 'location', label: 'Sample Current Location' },
    { key: 'dispositionNotes', label: 'Disposition Notes' },
    { key: 'warehouseNotes', label: 'Warehouse Notes' },
  ],
  disableItems: true,
  editColumnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: false,
      label: 'Pegasus Id',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      textAlign: 'right',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'subClassName',
      numeric: true,
      disablePadding: false,
      label: 'Sub Class',
      textAlign: 'right',
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class ID',
      textAlign: 'right',
    },
    {
      id: 'dueDate',
      numeric: true,
      disablePadding: false,
      label: 'Due Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'shipTo',
      numeric: true,
      disablePadding: false,
      label: 'Ship To',
      textAlign: 'right',
    },
    {
      id: 'project',
      numeric: false,
      disablePadding: false,
      label: 'Projects/Collections',
      textAlign: 'right',
    },
    {
      id: 'vendorNotes',
      numeric: true,
      disablePadding: false,
      label: 'Vendor Notes',
      textAlign: 'right',
    },
    {
      id: 'studioNotes',
      numeric: true,
      disablePadding: false,
      label: 'Studio Notes',
      textAlign: 'right',
    },
  ],
  editEnabled: true,
  editItems: [],
  editIsVisible: false,
  item: [],
  numSelected: 0,
  onCheckBoxChange: () => {},
  onCheckBoxHeaderChange: () => {},
  popUpDataKeyLabels: [
    { key: 'imageUrl', label: 'Image' },
    { key: 'tcin', label: 'TCIN' },
    { key: 'dpci', label: 'DPCI' },
    { key: 'lifecycleState', label: 'Lifecycle State' },
    { key: 'sampleLaunchDate', label: 'Launch Date' },
    { key: 'classId', label: 'Class ID' },
    { key: 'className', label: 'Class Name' },
    { key: 'departmentId', label: 'Department ID' },
    { key: 'departmentName', label: 'Department Name' },
    { key: 'subClassId', label: 'Sub Class ID' },
    { key: 'subClassName', label: 'Sub Class' },
    { key: 'description', label: 'Description' },
    { key: 'vendorId', label: 'Vendor ID' },
    { key: 'vendorName', label: 'Vendor Name' },
    { key: 'manufacturerStyle', label: 'Manufacturer Style' },
    { key: 'barcodeNumber', label: 'Barcode Number' },
    { key: 'relationshipType', label: 'Relationship Type' },
    { key: 'color', label: 'Color' },
    { key: 'size', label: 'Size' },
    { key: 'countryOfOrigin', label: 'Country of Origin' },
    { key: 'manufacturerBrand', label: 'Manufacturer Brand' },
    { key: 'parentTcins', label: 'Parent TCIN' },
  ],
  popupIsVisible: false,
  response: false,
  valueToCheckAgainst: {
    keyToCheckAgainst: 'sampleStatus',
    value: 'Sample Ordered',
  },
  selectedData: [],
  updateData: [],
  userId: ' ',
  page: 1,
  rowsPerPage: 10,
  totalRowsCount: 0,
  myorderUserRequestFailure: false,
  myorderUserRequestPending: false,
  cancelSampleRequestFailure: false,
  cancelSampleRequestPending: false,
  myOrderSamplesRequestPending: false,
  myOrderSamplesRequestFailure: false,
  filterNFList: {},
}

function getItem(data, item) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].pegasusId === item.pegasusId && data[i].tcin === item.tcin) {
      return data[i]
    }
  }
}

function onSelected(selectedData, selected) {
  for (let i = 0; i < selectedData.length; i++) {
    if (selectedData[i].pegasusId === selected.pegasusId) {
      return true
    }
  }
  return false
}

function removeItems(items, data) {
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < items.length; j++) {
      if (data[i].pegasusId === items[j].pegasusId) {
        data.splice(i, 1)
      }
    }
  }
  return data
}

function getProjectNames(projects) {
  const projectNames = projects.map(function (obj) {
    return {
      projectName: obj.name,
    }
  })
  return projectNames.map((x) => x.projectName).join()
}

export default function myOrder(state = initialState, action = {}) {
  switch (action.type) {
    case types.MY_ORDERS_SET_FILTERS: {
      return Object.assign({}, state, {
        ...state,
        filters: action.payload.data,
      })
    }
    case types.MY_ORDERS_SAVE_FILTERS_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        filters: action.payload.filters,
      })
    }
    case types.MY_ORDERS_SAVE_FILTERS_FAILURE: {
      return Object.assign({}, state, {
        saveFiltersFailure: true,
      })
    }
    case types.MY_ORDERS_SAVE_FILTERS_PENDING: {
      return Object.assign({}, state, {
        saveFiltersPending: action.payload.pending,
      })
    }
    case types.MY_ORDERS_GET_FILTERS_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        availableFilters: action.payload.availableFilters,
      })
    }
    case types.MY_ORDERS_GET_FILTERS_FAILURE: {
      return Object.assign({}, state, {
        getFiltersFailure: true,
      })
    }
    case types.MY_ORDERS_GET_FILTERS_PENDING: {
      return Object.assign({}, state, {
        getFiltersPending: action.payload.pending,
      })
    }
    case types.MY_ORDERS_SUBMIT_ORDER: {
      return Object.assign({}, state, {})
    }
    case types.MY_ORDERS_SET_DATA: {
      return Object.assign({}, state, {
        data: action.payload.data,
      })
    }
    case types.SHOW_MY_ORDERS_PAGE_POPUP: {
      const newState = {
        ...state,
        popupIsVisible: true,
        item: getItem(state.data, action.payload.item),
      }
      return newState
    }
    case types.CLOSE_MY_ORDERS_PAGE_POPUP: {
      const newState = {
        ...state,
        popupIsVisible: false,
      }
      return newState
    }
    case types.SHOW_MY_ORDERS_PAGE_ALERT: {
      const newState = {
        ...state,
        alertIsVisible: true,
        alertMessage: action.payload.message,
      }
      return newState
    }
    case types.CLOSE_MY_ORDERS_PAGE_ALERT: {
      const newState = {
        ...state,
        alertIsVisible: false,
      }
      return newState
    }
    case types.MY_ORDERS_SET_CHECKBOX: {
      const selectedIndex = onSelected(
        state.selectedData,
        action.payload.selected
      )
      let newSelected = []
      if (selectedIndex === false) {
        newSelected = newSelected.concat(
          state.selectedData,
          action.payload.selected
        )
      } else if (selectedIndex === state.selectedData.length - 1) {
        newSelected = newSelected.concat(state.selectedData.slice(0, -1))
      } else if (selectedIndex === true) {
        newSelected = removeItems([action.payload.selected], state.selectedData)
      }

      return Object.assign({}, state, {
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.MY_ORDERS_SET_CHECKBOX_HEADER: {
      const { selectedData = [] } = state
      const {
        payload: { selected: selectedItems = {} },
      } = action
      let selectedIds = selectedItems.filter((item) =>
        checkIsEnabledRow(initialState.valueToCheckAgainst, item)
      )
      const selectedDiffCount = selectedData.filter(
        (i) =>
          findIndex(selectedIds, function (obj) {
            return obj.pegasusId === i.pegasusId
          }) !== -1
      ).length

      var newDataObj = [].concat.apply(selectedData, selectedIds)

      let newSelected = []
      if (selectedDiffCount === 0) {
        newSelected = newDataObj
      } else {
        newSelected = selectedData.filter(
          (i) =>
            findIndex(selectedIds, function (obj) {
              return obj.pegasusId === i.pegasusId
            }) === -1
        )
      }

      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.MY_ORDERS_SET_QUANTITY:
      return Object.assign({}, state, {
        data: state.data.map((i) => ({
          ...i,
          quantity:
            i.tcin === action.payload.event.tcin
              ? action.payload.event.number
              : i.quantity,
        })),
      })
    case types.MY_ORDERS_SET_DATE:
      return Object.assign({}, state, {
        data: state.data.map((i) => ({
          ...i,
          due_date:
            i.tcin === action.payload.event.tcin
              ? action.payload.event.date
              : i.due_date,
        })),
      })
    case types.MY_ORDERS_SET_SHIP_TO:
      return Object.assign({}, state, {
        data: state.data.map((i) => ({
          ...i,
          ship_to:
            i.tcin === action.payload.event.tcin
              ? action.payload.event.location
              : i.ship_to,
        })),
      })
    case types.MY_ORDERS_SET_VENDOR_NOTES:
      return Object.assign({}, state, {
        data: state.data.map((i) => ({
          ...i,
          vendor_notes:
            i.tcin === action.payload.event.tcin
              ? action.payload.event.notes
              : i.vendor_notes,
        })),
      })
    case types.MY_ORDERS_SET_STUDIO_NOTES:
      return Object.assign({}, state, {
        data: state.data.map((i) => ({
          ...i,
          studio_notes:
            i.tcin === action.payload.event.tcin
              ? action.payload.event.notes
              : i.status_code,
        })),
      })
    case types.MY_ORDERS_PAGE_OPEN_EDIT: {
      const newState = {
        ...state,
        editIsVisible: true,
        selectedData: action.payload,
      }
      return newState
    }
    case types.MY_ORDERS_PAGE_CLOSE_EDIT: {
      const newState = {
        ...state,
        editIsVisible: false,
      }
      return newState
    }
    case types.MY_ORDERS_PAGE_ALERT: {
      const newState = {
        ...state,
        alertIsVisible: true,
        alertMessage: action.payload.message,
        response: true,
      }
      return newState
    }
    case types.CANCEL_SAMPLE_REQUEST_SUCCESS: {
      const idSet = new Set(action.payload.data.map((item) => item.pegasusId))
      return Object.assign({}, state, {
        ...state,
        data: state.data.filter((sample) => !idSet.has(sample.pegasusId)),
        alertIsVisable: false,
        selectedData: [],
        numSelected: 0,
      })
    }
    case types.CANCEL_SAMPLE_REQUEST_PENDING: {
      return Object.assign({}, state, {
        cancelSampleRequestPending: action.payload.pending,
      })
    }
    case types.CANCEL_SAMPLE_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        cancelSampleRequestFailure: true,
      })
    }

    case types.MY_ORDERS_SET_FILTER_NOTFOUND_LIST: {
      return Object.assign({}, state, {
        ...state,
        filterNFList: action.payload.filterNFList,
      })
    }

    case types.MYORDER_USER_ORDER_REQUEST_SUCCESS: {
      const payloadData = action.payload.data.map((item) => ({
        ...item,
        imagePopupUrl: item.imageUrl,
        imageUrl:
          item.imageUrl !== undefined &&
          item.imageUrl !== null &&
          item.imageUrl !== ''
            ? getDisplayImage(item.imageUrl)
            : 'Image Not Found',
        projectNames:
          item.projects !== null ? getProjectNames(item.projects) : null,
        shippingInfo:
          item.sampleStatus === 'Sample Shipped' ? (
            <a
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              href={
                `https://www.google.com/search?q=` +
                encodeURI(`${item.carrier} ${item.trackingNum}`)
              }
              rel="noreferrer"
            >
              {item.carrier} {item.trackingNum}
            </a>
          ) : (
            ''
          ),
      }))
      const newState = Object.assign({}, state, {
        ...state,
        data: payloadData,
        editIsVisible: false,
        // selectedData: [],
        // numSelected: 0,
      })
      return newState
    }

    case types.MYORDER_USER_ORDER_REQUEST_PENDING: {
      return Object.assign({}, state, {
        myorderUserRequestPending: action.payload.pending,
      })
    }
    case types.MYORDER_USER_ORDER_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        myorderUserRequestFailure: true,
      })
    }

    case types.MYORDER_UPDATE_SAMPLES_REQUEST_PENDING: {
      return Object.assign({}, state, {
        myOrderSamplesRequestPending: true,
      })
    }
    case types.MYORDER_UPDATE_SAMPLES_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        myOrderSamplesRequestFailure: true,
      })
    }
    case types.MY_ORDERS_DUPLICATE_SAMPLES_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        data: [].concat.apply(state.data, action.payload.samples),
      })
    }
    case types.MY_ORDERS_DUPLICATE_SAMPLES_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        duplicateSamplesFailure: true,
      })
    }
    case types.MY_ORDERS_DUPLICATE_SAMPLES_PENDING: {
      return Object.assign({}, state, {
        ...state,
        duplicateSamplesPending: action.payload.pending,
      })
    }
    case types.MY_ORDERS_SET_PAGE: {
      return Object.assign({}, state, {
        page: action.payload.page,
      })
    }
    case types.MY_ORDERS_SET_ROWS_PER_PAGE: {
      return Object.assign({}, state, {
        rowsPerPage: action.payload.rowsPerPage,
      })
    }
    case types.MY_ORDERS_SET_TOTAL_ROWS_COUNT: {
      return Object.assign({}, state, {
        totalRowsCount: action.payload.totalRowsCount,
      })
    }
    case types.MY_ORDERS_EMPTY_SELECTED_DATA:
      return Object.assign({}, state, {
        ...state,
        selectedData: [],
        numSelected: Number(0),
      })
    default:
      return state
  }
}
