import React from 'react'
import { withAuth } from '@praxis/component-auth'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import apiConfig from '../../apiConfig'

const styles = {
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    left: '0',
    top: '0',
    width: '100%',
    margin: '1.8em 10px 2em',
    lineHeight: '1.8',
  },
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: '20px 30px',
  },
  primaryTextEmail: {
    textDecoration: 'unset',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'unset',
    },
  },
}

class HelpPage extends React.Component {
  componentDidMount = () => {
    const { setHeaderTitleArray } = this.props
    setHeaderTitleArray([{ title: 'Help', link: '/help' }])
  }

  render() {
    const { classes, auth: { isAuthorized = () => {} } = {} } = this.props

    return (
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Typography variant="h6" gutterBottom>
            Please use the following documents to assist with any questions you
            have in using Pegasus:
            <br />
            <ul>
              {isAuthorized(apiConfig.roles.helpUsers) && (
                <>
                  <li>
                    <a href="/pegasusdocs/PegasusUserGuide.docx" download>
                      Pegasus User Guide
                    </a>
                  </li>
                  <li>
                    <strong>
                      <a href="/pegasusdocs/FAQForInternalUsers.docx" download>
                        Pegasus FAQ
                      </a>
                    </strong>
                  </li>
                </>
              )}
              {isAuthorized(apiConfig.roles.helpvendor) && (
                <>
                  <li>
                    <strong>
                      <a href="/pegasusdocs/FAQForVendors2020.docx" download>
                        Pegasus FAQ
                      </a>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <a
                        href="/pegasusdocs/PegasusVendorTraining.docx"
                        download
                      >
                        Pegasus Vendor Training
                      </a>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <a
                        href="/pegasusdocs/VendorSampleShipmentGuide.docx"
                        download
                      >
                        Vendor Sample Shipment Guide
                      </a>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <a
                        href="/pegasusdocs/BMLabelingprocessforvendors.pptx"
                        download
                      >
                        BM Labeling process for vendors
                      </a>
                    </strong>
                  </li>
                </>
              )}
            </ul>
            If you still have questions about Pegasus, please reach out to the
            Pegasus team by contacting the following email address:
            <Link
              href={'mailto:TargetTech-Pegasus@target.com'}
              className={classes.primaryTextEmail}
            >
              {'TargetTech-Pegasus@target.com'}
            </Link>
            .
            <br />
            <br />
            Thanks, Pegasus Product Team.
          </Typography>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
  }
}
export default connect(
  null,
  mapDispatchToProps
)(withAuth()(withStyles(styles)(HelpPage)))
