import React from 'react'
import * as propTypes from 'prop-types'
import { isEqual, capitalize, isEmpty, cloneDeep } from 'lodash'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import FilterListIcon from '@material-ui/icons/FilterList'
import withHOCs from 'util/withHocs'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import {
  getProjects,
  downloadAllProjectSamples,
  setTabvalue,
  saveProjectSearchValue,
} from '../../store/projects/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import {
  createShootType,
  getShootType,
} from '../../store/newShootType/actionCreator'
import { ShootTypes } from '../../enums/shootTypes'
import { projectTabLabels, defaultProjectsTab } from '../../enums/Tabs'
import CustomTabs from '../../components/Tabs/Tabs'
import { selectPageFilters } from '../../store/filters/selectors'
import { setFilters } from '../../store/filters/actionCreator'

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  pageHeader: {
    width: '100%',
    height: '40px',
    position: 'relative',
  },
  pageHeaderButton: {
    display: 'inline',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  add: {
    marginRight: '10px',
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'grid',
    overflowX: 'auto',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
})

class ProjectsPage extends React.Component {
  constructor(props) {
    super(props)
    const { filters: propsFilter = {}, tabValue = '' } = this.props
    this.state = {
      tooltipIsOpen: false,
      currentTabValue: tabValue,
      filters: propsFilter,
    }
  }

  componentDidMount() {
    const {
      setHeaderTitleArray,
      setFilters = () => {},
      setTabvalue = () => {},
    } = this.props
    setHeaderTitleArray([{ title: 'Projects', link: '/projects' }])
    setFilters({}, 'projects')
    setTabvalue(defaultProjectsTab)
  }

  saveFilters() {
    const { setFilters, setTabvalue = () => {} } = this.props
    const { filters = {}, currentTabValue = '' } = this.state
    const filterCopy = Object.assign({}, filters)
    if (!isEmpty(filterCopy)) {
      setFilters(filterCopy, 'projects')
    }
    setTabvalue(currentTabValue)
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentTabValue = '', filters = {} } = this.state
    const { getProjects = () => {} } = this.props
    const {
      currentTabValue: prevCurrentTabValue = '',
      filters: prevFilters = {},
    } = prevState

    if (
      !isEqual(currentTabValue, prevCurrentTabValue) ||
      !isEqual(filters, prevFilters)
    ) {
      getProjects(filters)
    }
  }

  componentWillUnmount() {
    const locationHref = window.location.href
    if (locationHref.indexOf('projects') === -1) {
      this.saveSearchValue('')
    }
  }

  handleTooltipOpen = () => {
    this.setState({ tooltipIsOpen: !this.state.tooltipIsOpen })
    this.autoCloseTimer = setTimeout(this.handleTooltipClose.bind(this), 10000)
  }

  handleTooltipClose = () => {
    this.setState({ tooltipIsOpen: false })
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer)
    }
  }

  handleNewShootType() {
    this.props.navigate(`/projects/create`)
  }

  handleGetShootType(e = {}) {
    this.saveFilters()
    const { id: projectID = '' } = e
    this.props.navigate(`/projects/${projectID}/view`)
  }

  setCurrentTabValue = (currentTab = '') => {
    const { filters: stateFilters = {} } = this.state
    const { filters: propsFilters = {} } = this.props
    if (!propsFilters['created_by']) {
      delete stateFilters['created_by']
    }
    this.setState({
      currentTabValue: currentTab,
      filters: Object.assign({}, stateFilters, {
        status: currentTab,
      }),
    })
  }

  filterClick = () => {
    const {
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
    } = this.props

    const { filters: stateFilters = {} } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    const currentFilterExists =
      Object.keys(filtersCopy).indexOf('created_by') !== -1
    if (currentFilterExists) {
      delete filtersCopy['created_by']
      this.setFilters(Object.assign({}, filtersCopy))
    } else {
      this.setFilters(Object.assign({}, stateFilters, { created_by: lanId }))
    }
  }

  setFilters = (filters = {}) => {
    this.setState({
      filters: filters,
    })
  }

  additionalFiltersContent = () => {
    const { classes = {} } = this.props
    const { filters: stateFilters = {} } = this.state
    const currentFilterExists =
      Object.keys(stateFilters).indexOf('created_by') !== -1

    return (
      <Tooltip title={'My Projects'}>
        <Button
          data-cy="btnMyProjects"
          color="secondary"
          className={classes.filterButton}
          variant={currentFilterExists ? 'contained' : 'outlined'}
          onClick={() => {
            this.filterClick()
          }}
          startIcon={<FilterListIcon />}
        >
          {'My Projects'}
        </Button>
      </Tooltip>
    )
  }

  saveSearchValue = (searchValue = '') => {
    const { saveProjectSearchValue = () => {} } = this.props
    saveProjectSearchValue(searchValue)
  }
  getFormattedData = (projectsDetails = []) => {
    return projectsDetails.reduce((acc, option) => {
      if (option.hasPicklist) {
        option['isAnAdditionalInfoRow'] = true
      } else {
        option['isAnAdditionalInfoRow'] = false
      }
      acc.push(option)
      return acc
    }, [])
  }
  renderTabsContent = () => {
    const {
      columnData,
      projects,
      getProjectsPending = false,
      searchValue = '',
    } = this.props
    const { currentTabValue = '' } = this.state
    const currentTabValueObj =
      projectTabLabels.find((obj) => obj.value === currentTabValue) || {}
    const { label: currentTabLabel = '' } = currentTabValueObj
    const tableLabel = `${capitalize(currentTabLabel) + ' Projects'}`
    const projectData = this.getFormattedData(cloneDeep(projects))
    return (
      <FlexibleTable
        cascadeFieldKeys={[]}
        checkBoxEnabled={false}
        columnData={columnData}
        data={projectData}
        uniqueIdentifierInData={'id'}
        dataFieldKeys={[]}
        editEnabled={false}
        enableSearch
        additionalIconInfoContent={<ShoppingCartIcon />}
        // enableDownload={true}
        // downloadFunction={() => downloadAllProjectSamples(projects)}
        showPopup={(e) => this.handleGetShootType(e)}
        tableTitle={tableLabel}
        disableTablePagination={true}
        disableItemCount={true}
        clickableRowButton="OPEN"
        additionalActions={true}
        additionalFiltersContent={this.additionalFiltersContent}
        isLoading={getProjectsPending}
        saveFilterValue={this.saveSearchValue}
        savedSearchValue={searchValue}
        enableMultiSearch
      />
    )
  }

  render() {
    const { classes = {} } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.pageHeader}>
          <Typography variant="h4" gutterBottom data-cy="projectsTitle">
            Projects
          </Typography>
          <div className={classes.pageHeaderButton}>
            <Button
              id="createNewProject"
              data-id={ShootTypes.PROJECT}
              data-cy="newProjectBtn"
              color="primary"
              className={classes.defaultButton}
              type="button"
              variant="contained"
              onClick={this.handleNewShootType.bind(this)}
              startIcon={<AddIcon />}
            >
              New Project
            </Button>
          </div>
        </div>
        <Paper className={classes.paper} id={'projectsTab'}>
          <CustomTabs
            tabLabels={projectTabLabels}
            renderTabsContent={this.renderTabsContent}
            setCurrentTabValue={this.setCurrentTabValue}
            defaultTab={this.props.tabValue || defaultProjectsTab}
            showTabsPanel={false}
          />
        </Paper>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    projects: {
      columnData = [],
      projects = [],
      getProjectsPending = false,
      tabValue = '',
      filterValue = '',
    },
    newShootType: {
      goNextUpdatePageFlag = false,
      shootType = '',
      goNextViewPageFlag = false,
      page = 1,
      rowsPerPage = 50,
    },
  } = state
  return {
    columnData,
    goNextUpdatePageFlag,
    shootType,
    projects,
    goNextViewPageFlag,
    page,
    rowsPerPage,
    getProjectsPending,
    filters: selectPageFilters('projects')(state),
    tabValue,
    searchValue: filterValue,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProjects: (params = {}) => {
      dispatch(getProjects(params))
    },
    downloadAllProjectSamples: (projects) => {
      dispatch(downloadAllProjectSamples(projects))
    },
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    createShootType: (userId, shootType) =>
      dispatch(createShootType(userId, shootType)),
    getShootType: (row, shootType, page, rowsPerPage) =>
      dispatch(getShootType(row, shootType, page, rowsPerPage)),
    setFilters: (filters, pageName) =>
      dispatch(setFilters(filters, 'projects')),
    setTabvalue: (tabValue) => dispatch(setTabvalue(tabValue)),
    saveProjectSearchValue: (searchValue) =>
      dispatch(saveProjectSearchValue(searchValue)),
  }
}

ProjectsPage.propTypes = {
  columnData: propTypes.array,
  projects: propTypes.array,
  navigate: propTypes.func,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(ProjectsPage)
