import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextField, Card } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { getInvalidPegasusIdListBasedOnPrefixAndType } from 'util/CommonUtils'

const styles = (theme) => ({
  textField: {
    width: (props) => (props.hideButtons ? '100%' : 'calc(100% - 184px)'),
    margin: (props) => (props.hideBorder ? '11px 0 0 0' : '11px'),
  },
  card: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: (props) =>
      props.hideBorder
        ? 'none'
        : '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  clearButton: {
    marginRight: 15,
  },
})

class Search extends PureComponent {
  constructor(props) {
    super(props)
    this.handleSearch = this.handleSearch.bind(this)
    this.state = {
      text: [],
    }
  }

  handleChange = (event) => {
    this.updateSearchText(event.target.value)
  }

  updateSearchText(text) {
    const { onChangeText = () => {} } = this.props
    this.setState({
      rawText: text,
    })
    const sampleCount = !isEmpty(text)
      ? (text.trim(), text.split(/[,\r\n\s]+/g).length)
      : ''
    onChangeText(sampleCount)
  }

  handleSearch() {
    const { clearOnSubmit } = this.props
    if (!this.state.rawText) {
      return
    }
    this.setState({
      text: [],
    })

    const {
      getSearchValue,
      submitSearch,
      userId,
      shipmentNumber,
      shipFromLocation,
      scenicRoomId,
      shootType,
      projectData = {},
      collectionData = {},
      sampleType,
      showNotification = () => {},
    } = this.props
    const { name: projectName = '', channel: projectChannel = '' } = projectData
    const { name: collectionName = '' } = collectionData
    var temp = this.state.rawText.replace(/,/gi, '\n').toUpperCase()
    var array = temp.replace(/ /gi, '\n').split('\n')

    const invalidPegasusIds = getInvalidPegasusIdListBasedOnPrefixAndType(
      array,
      sampleType
    )
    if (sampleType && invalidPegasusIds && invalidPegasusIds.length > 0) {
      // TODO: Restrict Scenic filemaker's ID from Prop's Tab from BE
      showNotification(
        true,
        `These Pegasus IDs are not ${sampleType}: ${invalidPegasusIds.toString()}`,
        'error'
      )
      return
    }

    var filtered = array.filter(function (el) {
      return el
    })
    this.state.text.push(filtered)
    submitSearch(
      ...this.state.text,
      userId,
      scenicRoomId ? scenicRoomId : shipmentNumber,
      shootType,
      projectName,
      projectChannel,
      collectionName,
      shipFromLocation
    )

    if (shootType !== undefined) {
      getSearchValue(...this.state.text)
    }

    if (clearOnSubmit) {
      this.setState({
        rawText: '',
        text: [],
      })
    }
  }

  handleSearchClear = () => {
    const { onChangeText = () => {} } = this.props
    this.setState({
      text: [],
      rawText: '',
    })
    const { clearSearchCallback } = this.props
    clearSearchCallback()
    onChangeText(0)
  }

  catchReturn = (event) => {
    const { submitOnEnter } = this.props
    if (event.key === 'Enter' && submitOnEnter) {
      this.handleSearch()
      event.preventDefault()
    }
  }

  render() {
    const {
      classes,
      label,
      buttonName,
      buttonVariant,
      style,
      helperText,
      hideButtons = false,
    } = this.props
    return (
      <Card className={classes.card} style={style}>
        <TextField
          className={classes.textField}
          id="searchTextField"
          label={label}
          multiline
          rows="1"
          rowsMax="6"
          variant="outlined"
          value={this.state.rawText}
          onChange={this.handleChange}
          onKeyPress={this.catchReturn}
          helperText={helperText}
        />
        {!hideButtons && (
          <>
            <Button
              color="secondary"
              variant="text"
              className={classes.clearButton}
              id="clearSearch"
              onClick={this.handleSearchClear}
            >
              Clear
            </Button>
            <Button
              className="searchButton"
              id="handleSearch"
              variant={buttonVariant || 'contained'}
              color="primary"
              onClick={this.handleSearch}
            >
              {buttonName}
            </Button>
          </>
        )}
      </Card>
    )
  }
}

Search.defaultProps = {
  getSearchValue: undefined,
  submitSearch: undefined,
  scenicRoomId: undefined,
  userId: undefined,
  shootType: undefined,
  clearSearchCallback: () => {},
  clearOnSubmit: false,
  label: undefined,
  helperText: undefined,
  buttonName: undefined,
  submitOnEnter: false,
  onChangeText: undefined,
}
Search.propTypes = {
  getSearchValue: PropTypes.func,
  submitSearch: PropTypes.func,
  scenicRoomId: PropTypes.number,
  clearSearchCallback: PropTypes.func,
  clearOnSubmit: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  buttonName: PropTypes.string,
  userId: PropTypes.string,
  shootType: PropTypes.string,
  submitOnEnter: PropTypes.bool,
  onChangeText: PropTypes.func,
}

export default withStyles(styles)(Search)
