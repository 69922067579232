import * as propTypes from 'prop-types'
import Button from '@material-ui/core/Button/Button'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import React from 'react'
import { camelCase, isEmpty, findIndex } from 'lodash'
import withHOCs from 'util/withHocs'
import Paper from '@material-ui/core/Paper'
import { ArrowBack, Create } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import Grid from '@material-ui/core/Grid'
import Add from '@material-ui/icons/Add'
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Box,
} from '@material-ui/core'
// import CustomTabs from '../../components/Tabs/Tabs'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {
  ShootTypes,
  ShootTypePagename,
  additionalColumn,
} from '../../enums/shootTypes'
import {
  resetNewShootTypeState,
  moveToEditPage,
  showPopup,
  closePopup,
  getShootType,
  setPage,
  setRowsPerPage,
  updateSampleFieldsData,
  isRowsPerPageUpdated,
  resetIsRowsUpdated,
  setCheckBox,
  setCheckBoxHeaderChange,
} from '../../store/newShootType/actionCreator'
import {
  downloadCollectionSamples,
  getACollection,
} from '../../store/collections/actionCreator'
import {
  downloadProjectSamples,
  downloadProjectContactSheet,
  getAProject,
} from '../../store/projects/actionCreator'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import { makeTabTables, getTitle } from '../../util/Table'
import { dataTabLabelsShipped } from '../../enums/tableTabs'

import {
  projectDetailsTabs,
  defaultProjectsDetailsTab,
  REQUESTED,
} from '../../enums/Tabs'
import AddSampleToProject from './ProjectDetails/AddSampleToProject'

const styles = (theme) => ({
  pageHeader: {
    marginBottom: '20px',
    height: '40px',
    position: 'relative',
    fontSize: '34px',
  },
  pageHeaderText: {
    position: 'absolute',
    top: -0,
  },
  pageHeaderButton1: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 250,
  },
  pageHeaderButton2: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 20,
  },
  itemColumnMargin: {
    flexBasis: '435px',
  },
  descriptionPaper: {
    padding: '24px 24px 48px 24px',
    marginTop: '50px',
  },
  sampleListTable: {
    marginTop: '34px',
  },
  shootTypeLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rootDiv: {
    margin: '0px 28px',
  },
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '284px',
    padding: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    whiteSpace: 'pre-wrap',
  },
  filterTitle: {
    width: '300px',
    paddingTop: '10px',
    paddingBottom: '20px',
  },
  toolBar: {
    display: 'flex',
  },
  editButton: {
    width: 'auto',
    margin: theme.spacing(1),
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
  tabsWrapper: {
    marginLeft: '40px',
  },
  addSamplesBtn: {
    float: 'right',
    margin: '10px 15px 10px 0',
    border: `1px solid ${theme.palette.divider}`,
  },
})

const ProjectDetailsTabs = ({
  projectDetailsTabs,
  currentTabValue,
  handleTabChange,
  classes,
}) => {
  return (
    <Tabs
      value={currentTabValue}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      variant="standard"
      aria-label="project-tabs"
    >
      {projectDetailsTabs.map((obj, index) => {
        const { label = '', value = '' } = obj
        return (
          <Tab
            data-cy={label}
            label={label}
            value={value}
            className={classes.tabsWrapper}
          />
        )
      })}
    </Tabs>
  )
}
class ShootTypeView extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      rowsCount: 10,
      currentTabValue: defaultProjectsDetailsTab,
      isAddSamplesSliderOpen: false,
    }
  }
  componentDidMount = () => {
    const {
      shootTypesData: { name: shootTypeName = '' },
      setHeaderTitleArray,
      match: { params: { pageId: shootTypeID = '' } = {} } = {},
      location: { pathname: currentURI = '' } = {},
      pageName = '',
    } = this.props
    this.getRowsPerPage()
    setHeaderTitleArray([
      { title: `${camelCase(pageName || '')}`, link: `/${pageName}` },
      { title: shootTypeName || shootTypeID, link: currentURI },
    ])
  }

  getRowsPerPage = () => {
    const {
      match: { params: { pageId: shootTypeID = '' } = {} } = {},
      sampleCount = [],
      setRowsPerPage = () => {},
      isRowsUpdated = () => {},
      pageName = '',
    } = this.props
    let count =
      sampleCount.find((s) => (s.key || '').toString() === shootTypeID) ||
      Number(0)
    if (pageName === ShootTypePagename.PROJECT) {
      switch (true) {
        case count?.value >= 1000:
          setRowsPerPage(1000)
          break
        case count?.value >= 100:
          setRowsPerPage(count?.value)
          break
        case count?.value < 10:
          setRowsPerPage(10)
          break
        case count?.value <= 50:
          setRowsPerPage(50)
          break
        case count?.value < 100:
          setRowsPerPage(100)
          break
        case isEmpty(count?.value):
          setRowsPerPage(9999)
          break
        default:
          setRowsPerPage(10)
          break
      }
    } else setRowsPerPage(10)
    isRowsUpdated()
  }

  getShootTypeSamples = () => {
    const {
      pageName = '',
      match: { params: { pageId: shootTypeID = '' } = {} } = {},
      page = 1,
      rowsPerPage = 10,
      getAProject = () => {},
      getACollection = () => {},
    } = this.props
    switch (pageName) {
      case ShootTypePagename.PROJECT: {
        getAProject(shootTypeID, page, rowsPerPage, this.state.currentTabValue)
        break
      }
      case ShootTypePagename.COLLECTION:
        getACollection(shootTypeID, page, rowsPerPage)
        break
      default:
        break
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      page,
      rowsPerPage,
      shootType,
      shootTypesData,
      match: { params: { pageId: projectID = '' } = {} } = {},
      location: { pathname: currentURI = '' } = {},
      pageName = '',
      getShootType = () => {},
      setPage = () => {},
      setHeaderTitleArray = () => {},
      isRowsPerPageUpdated,
      resetIsRowsUpdated = () => {},
      data = [],
      getProjectsPending,
    } = this.props
    const { name: projectName = '' } = shootTypesData
    if (
      (!!isRowsPerPageUpdated &&
        (page !== prevProps.page || rowsPerPage !== prevProps.rowsPerPage)) ||
      this.state.currentTabValue !== prevState.currentTabValue
    ) {
      await this.getShootTypeSamples()
      if (isRowsPerPageUpdated) await resetIsRowsUpdated()
    } else if (
      !getProjectsPending &&
      (page !== prevProps.page ||
        rowsPerPage !== prevProps.rowsPerPage ||
        this.state.currentTabValue !== prevState.currentTabValue)
    ) {
      getShootType(
        shootTypesData,
        shootType,
        page,
        rowsPerPage,
        this.state.currentTabValue
      )
    }

    if (!data.length && page !== 1) {
      setPage(1)
    }
    setHeaderTitleArray([
      { title: `${camelCase(pageName || '')}`, link: `/${pageName}` },
      { title: projectName || projectID, link: currentURI },
    ])
  }

  handleEdit(e) {
    const { shootTypesData = {}, pageName = '' } = this.props
    const { id: shootTypeID = '' } = shootTypesData
    this.props.history.push(`/${pageName}/${shootTypeID}/edit`)
  }

  handleBackToProjects() {
    const { pageName = '' } = this.props
    this.props.history.push(`/${pageName}`)
  }

  componentWillUnmount() {
    this.props.onBackClick()
  }

  downloadCSFunction =
    (downloadType = '') =>
    (event) => {
      const {
        downloadContactSheet = () => {},
        shootTypesData: { name: shootTypeName = '' },
      } = this.props
      const paramsCopy = {
        include_filters: {},
        exclude_filters: {},
      }

      downloadContactSheet(
        Object.assign({}, paramsCopy, {
          ...paramsCopy,
          include_filters: {
            project_name: [shootTypeName],
          },
        }),
        downloadType
      )
    }

  onSelected = (selectedData, selected) => {
    const { pegasusId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.pegasusId === pegasusId
      }) !== -1
    )
  }

  render() {
    const {
      classes = {},
      clickableColumn,
      closePopup,
      downloadProjectSamples,
      downloadCollectionSamples,
      data,
      onClick,
      popupIsVisible,
      popupItem = {},
      shootTypesData,
      showPopup,
      shootType,
      shootTypeId,
      page,
      rowsPerPage,
      totalRowsCount,
      setPage,
      setRowsPerPage,
      fetchShootTypeDataPending = false,
      columnData: initialColumnData,
      checkBoxEnabled,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      selectedData,
    } = this.props
    const {
      name = '',
      channel = '',
      locationName = '',
      shootStartDate = '',
      shootEndDate = '',
      marketingPid = '',
      createdBy = '',
      createDate = '',
      notes = '',
    } = shootTypesData
    const { currentTabValue, isAddSamplesSliderOpen } = this.state

    const columnData = [...initialColumnData]
    if (currentTabValue === REQUESTED) {
      columnData.push({
        id: 'quantity',
        numeric: true,
        disablePadding: true,
        label: 'Quantity',
        textAlign: 'right',
      })
    }
    const dataFieldKeys = columnData.map((column) => column.id)

    const handleTabChange = (e, newValue) => {
      this.setState({ currentTabValue: newValue })
    }

    const toggleAddSamplesSlider = () => {
      this.setState({ isAddSamplesSliderOpen: !isAddSamplesSliderOpen })
    }

    return (
      <div className={classes.rootDiv}>
        <Grid container>
          <Grid item xs={6} className={classes.pageHeader}>
            <span className={classes.pageHeaderText}>{name}</span>
          </Grid>
          <Grid item xs={3} className={classes.pageHeaderButton1}>
            <Button
              id="back"
              data-id="BACK"
              data-cy="btnBackTo"
              className={classes.editButton}
              variant="outlined"
              color="secondary"
              onClick={this.handleBackToProjects.bind(this)}
              disabled={!(Number(shootTypeId) > 0)}
              startIcon={<ArrowBack />}
            >
              <span> BACK TO {shootType + 's'}</span>
            </Button>
          </Grid>
          <Grid item xs={3} className={classes.pageHeaderButton2}>
            <Button
              id="editShootType"
              data-cy="btnEditShootType"
              data-id="shootType"
              color="primary"
              className={classes.editButton}
              type="button"
              variant="contained"
              disabled={!(Number(shootTypeId) > 0)}
              onClick={this.handleEdit.bind(this)}
              startIcon={<Create />}
            >
              Edit {shootType}
            </Button>
          </Grid>
        </Grid>
        {shootType === ShootTypes.PROJECT && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className={classes.toolBar}>
                <span className={classes.filterTitle}>
                  <Typography variant="h5">{shootType} Info</Typography>{' '}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item className={classes.itemColumnMargin}>
                  <span className={classes.shootTypeLabel}>Project Name: </span>
                  <span> {name}</span>
                </Grid>
                <Grid item className={classes.itemColumnMargin}>
                  <span className={classes.shootTypeLabel}>Channel: </span>
                  <span> {channel}</span>
                </Grid>
                <Grid item className={classes.itemColumnMargin}>
                  <span className={classes.shootTypeLabel}>
                    Photoshoot Location:{' '}
                  </span>
                  <span>{isEmpty(locationName) ? '' : locationName}</span>
                </Grid>
                <Grid item className={classes.itemColumnMargin}>
                  <span className={classes.shootTypeLabel}>Start Date:</span>
                  <span>{isEmpty(shootStartDate) ? '' : shootStartDate}</span>
                </Grid>
                <Grid item className={classes.itemColumnMargin}>
                  <span className={classes.shootTypeLabel}>End Date:</span>
                  <span>{isEmpty(shootEndDate) ? '' : shootEndDate}</span>
                </Grid>
                <Grid item className={classes.itemColumnMargin}>
                  <span className={classes.shootTypeLabel}>
                    Marketing PID:{' '}
                  </span>
                  <span>{isEmpty(marketingPid) ? '' : marketingPid}</span>
                </Grid>
                <Grid item className={classes.itemColumnMargin}>
                  <span className={classes.shootTypeLabel}>Created By: </span>
                  <span>{createdBy}</span>
                </Grid>
                <Grid item className={classes.itemColumnMargin}>
                  <span className={classes.shootTypeLabel}>Created Date: </span>
                  <span>{createDate}</span>
                </Grid>
                <Grid item className={classes.itemColumnMargin}>
                  <span className={classes.shootTypeLabel}>Notes: </span>
                  <span>{isEmpty(notes) ? '' : notes}</span>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {shootType === ShootTypes.COLLECTION && (
          <Paper className={classes.descriptionPaper}>
            <div className={classes.toolBar}>
              <span className={classes.filterTitle}>
                <Typography variant="h5">{shootType} Info</Typography>{' '}
              </span>
            </div>
            <Grid container spacing={2}>
              <Grid item className={classes.itemColumnMargin}>
                <span className={classes.shootTypeLabel}>
                  Collection Name:{' '}
                </span>
                <span> {name}</span>
                <br />
                <br />
              </Grid>
              <Grid item className={classes.itemColumnMargin}>
                <span className={classes.shootTypeLabel}>COP/VAP #: </span>
                <span> {marketingPid}</span>
                <br />
                <br />
              </Grid>
              <Grid item className={classes.itemColumnMargin}>
                <span className={classes.shootTypeLabel}>
                  Collection Location:{' '}
                </span>
                <span>{isEmpty(locationName) ? '' : locationName}</span>
              </Grid>
              <Grid item className={classes.itemColumnMargin}>
                <span className={classes.shootTypeLabel}>Created By: </span>
                <span>{createdBy}</span>
              </Grid>
              <Grid item className={classes.itemColumnMargin}>
                <span className={classes.shootTypeLabel}>Created Date: </span>
                <span>{createDate}</span>
              </Grid>
              <Grid item className={classes.itemColumnMargin}>
                <span className={classes.shootTypeLabel}>Notes: </span>
                <span>{isEmpty(notes) ? '' : notes}</span>
              </Grid>
            </Grid>
          </Paper>
        )}
        <div className={classes.sampleListTable}>
          <Paper>
            {shootType === ShootTypes.PROJECT && (
              <>
                <ProjectDetailsTabs
                  projectDetailsTabs={projectDetailsTabs}
                  currentTabValue={currentTabValue}
                  handleTabChange={handleTabChange}
                  classes={classes}
                />
                <Button
                  id="openAddSamplesSliderBtn"
                  color="primary"
                  className={classes.addSamplesBtn}
                  onClick={toggleAddSamplesSlider}
                >
                  <Add style={{ marginRight: '5px' }} /> Add Samples
                </Button>
              </>
            )}
            <Drawer
              anchor="right"
              variant={'persistent'}
              open={isAddSamplesSliderOpen}
              // onOpen={toggleAddSamplesSlider}
              // onClose={toggleAddSamplesSlider}
            >
              <Box
                sx={{
                  width: 750,
                }}
                // onClick={toggleAddSamplesSlider}
                // onKeyDown={toggleAddSamplesSlider}
                role="presentation"
              >
                <AddSampleToProject closeDrawer={toggleAddSamplesSlider} />
              </Box>
            </Drawer>
            <FlexibleTable
              cascadeFieldKeys={[]}
              checkBoxEnabled={checkBoxEnabled}
              columnData={
                shootType === ShootTypes.COLLECTION
                  ? columnData.filter(
                      (columnFields) => columnFields.id !== additionalColumn
                    )
                  : columnData
              }
              clickableColumn={clickableColumn}
              data={data}
              dataFieldKeys={dataFieldKeys}
              onClick={onClick}
              editEnabled={false}
              onSelected={this.onSelected}
              // rowCount={data.length}
              enableSearch
              enableDownload={true}
              enableContactSheetDownload={true}
              downloadFunction={
                shootType === ShootTypes.PROJECT
                  ? () => downloadProjectSamples(shootTypeId)
                  : () => downloadCollectionSamples(shootTypeId)
              }
              downloadCSFunction={this.downloadCSFunction('XLSX')}
              downloadCSFunctionPDF={this.downloadCSFunction('PDF')}
              showPopup={showPopup}
              paginationAtApiLevel={true}
              page={page}
              rowsPerPage={rowsPerPage}
              totalRowsCount={totalRowsCount}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              showAllRows
              isLoading={fetchShootTypeDataPending}
              enableMultiSearch
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
              selected={selectedData}
            />
          </Paper>
        </div>
        <TabbedPopUp
          dataTabLabels={dataTabLabelsShipped}
          isVisible={popupIsVisible}
          data={makeTabTables(
            popupItem,
            classes,
            dataTabLabelsShipped,
            updateSampleFieldsData,
            shootTypesData
          )}
          onClose={closePopup}
          title={getTitle(popupItem)}
          fullWidth={true}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    columnData: state.newShootType.columnData,
    clickableColumn: state.newShootType.clickableColumn,
    data: state.newShootType.data,
    goNextUpdatePageFlag: state.newShootType.goNextUpdatePageFlag,
    goNextViewPageFlag: state.newShootType.goNextViewPageFlag,
    headerTitle: state.layout.headerTitle,
    onClick: state.newShootType.onClick,
    order: state.newShootType.order,
    orderBy: state.newShootType.orderBy,
    rowCount: state.newShootType.rowCount,
    shootTypesData: state.newShootType.shootTypesData,
    shootType: state.newShootType.shootType,
    shootTypeId: state.newShootType.shootTypeId,
    showPopup: state.newShootType.showPopup,
    popupIsVisible: state.newShootType.popupIsVisible,
    popupItem: state.newShootType.item,
    page: state.newShootType.page,
    rowsPerPage: state.newShootType.rowsPerPage,
    totalRowsCount: state.newShootType.totalRowsCount,
    fetchShootTypeDataPending: state.newShootType.fetchShootTypeDataPending,
    sampleCount: state.projects.sampleCount,
    isRowsPerPageUpdated: state.newShootType.isRowsPerPageUpdated,
    getProjectsPending: state.projects.getProjectsPending,
    checkBoxEnabled: state.newShootType.checkBoxEnabled,
    onCheckBoxChange: state.newShootType.onCheckBoxChange,
    selectedData: state.newShootType.selectedData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    downloadProjectSamples: (projectId) => {
      dispatch(downloadProjectSamples(projectId))
    },
    downloadContactSheet: (params = {}, downloadType = '') => {
      dispatch(downloadProjectContactSheet(params, downloadType))
    },
    downloadCollectionSamples: (collectionId) => {
      dispatch(downloadCollectionSamples(collectionId))
    },
    onBackClick: () => dispatch(resetNewShootTypeState()),
    onEditClick: () => dispatch(moveToEditPage()),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    closePopup: () => {
      dispatch(closePopup())
    },
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    getShootType: (row, shootType, page, rowsPerPage, sampleType) =>
      dispatch(getShootType(row, shootType, page, rowsPerPage, sampleType)),
    getAProject: (projectID, page, rowsPerPage, sampleType) =>
      dispatch(getAProject(projectID, page, rowsPerPage, sampleType)),
    getACollection: (projectID, page, rowsPerPage) =>
      dispatch(getACollection(projectID, page, rowsPerPage)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
    isRowsUpdated: () => dispatch(isRowsPerPageUpdated()),
    resetIsRowsUpdated: () => dispatch(resetIsRowsUpdated()),
    onCheckBoxChange: (selectedData) => dispatch(setCheckBox(selectedData)),
    onCheckBoxHeaderChange: (selected = []) =>
      dispatch(setCheckBoxHeaderChange(selected)),
  }
}

ShootTypeView.propTypes = {
  columnData: propTypes.array,
  clickableColumn: propTypes.array,
  data: propTypes.array,
  onClick: propTypes.func,
  order: propTypes.string,
  orderBy: propTypes.string,
  rowCount: propTypes.number,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(ShootTypeView)
