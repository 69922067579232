export const getShippedShipments = ({ shipSamples = {} }) => {
  const { shippedShipments = [] } = shipSamples
  return shippedShipments
}
export const getPendingShipments = ({ shipSamples = {} }) => {
  const { pendingShipments = [] } = shipSamples
  return pendingShipments
}

export const getPartnersList = ({ shipSamples = {} }) => {
  const { allPartnersList = [] } = shipSamples
  const allPartners = allPartnersList?.map((obj) => {
    return Object.assign({}, obj, {
      value: obj?.partner_name ?? '',
      label: `${obj?.partner_type + ': ' + obj?.partner_name ?? ''}`,
    })
  })
  return allPartners
}

export const getShipToLocationList = ({ newShipment = {} }) => {
  const { shipToLocations = [] } = newShipment
  const allLocations = shipToLocations?.map((obj) => {
    return Object.assign({}, obj, {
      value: obj?.location_id ?? '',
      label: obj?.building ?? '',
    })
  })
  return allLocations
}
