import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { TextField, Card, CardActions, CardContent } from '@material-ui/core'
import { isEmpty } from 'lodash'

const styles = (theme) => ({
  textField: {
    width: '100%',
  },
  card: {
    padding: theme.spacing(1),
  },
  actionButton: {},
})

class Search extends PureComponent {
  constructor(props) {
    super(props)
    this.handleSearch = this.handleSearch.bind(this)
    this.state = {
      text: [],
      rawText: '',
    }
    this.apiTextInput = this.props.childRef
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.apiTextInput && this.apiTextInput.current) {
        const inputRefVal = this.apiTextInput.current.value
        this.apiTextInput.current.value = '' //clear the value of the element
        this.apiTextInput.current.value = inputRefVal
        this.apiTextInput.current.focus()
      }
    }, 1000)
  }

  handleChange = (event) => {
    this.updateSearchText(event.target.value)
  }

  updateSearchText(text) {
    const { onChangeText = () => {} } = this.props
    this.setState({
      rawText: text,
    })
    const sampleCount = !isEmpty(text)
      ? (text.trim(), text.split(/[,\r\n\s]+/g).length)
      : ''
    onChangeText(sampleCount)
  }

  handleSearch() {
    const { clearOnSubmit } = this.props
    this.setState({
      text: [],
    })

    const { submitSearch, userId, shipmentNumber } = this.props
    const { rawText: stateRawText = '' } = this.state
    var temp = stateRawText.replace(/,/gi, '\n').toUpperCase()
    var array = temp.trim().replace(/ /gi, '\n').split('\n')

    for (var i = 0; i < array.length - 1; i++) {
      if (array[i] === '' || array[i] === ',') {
        array.splice(i, 1)
      }
    }
    this.state.text.push(array)
    submitSearch(...this.state.text, userId, shipmentNumber)

    if (clearOnSubmit) {
      this.setState({
        rawText: '',
        text: [],
      })
    }
  }

  handleSearchClear = () => {
    const { onChangeText = () => {} } = this.props
    this.setState({
      text: [],
      rawText: '',
    })
    const { clearSearchCallback } = this.props
    clearSearchCallback()
    onChangeText(0)
  }

  catchReturn = (event) => {
    const { submitOnEnter } = this.props
    if (event.key === 'Enter' && submitOnEnter) {
      this.handleSearch()
      event.preventDefault()
    }
  }

  render() {
    const {
      classes,
      label,
      helperText,
      buttonName,
      isClearVisible = true,
      buttonVariant,
    } = this.props
    const { rawText: stateRawText = '' } = this.state
    return (
      <Card className={classes.card}>
        <CardContent>
          <TextField
            className={classes.textField}
            id="searchTextField"
            label={label}
            multiline
            rows="1"
            rowsMax="6"
            value={stateRawText}
            onChange={this.handleChange}
            helperText={helperText}
            onKeyPress={this.catchReturn}
            inputRef={this.apiTextInput}
            autoFocus
          />
        </CardContent>
        <CardActions className={classes.actionButton}>
          {isClearVisible ? (
            <Button
              color="primary"
              className="clearButton"
              id="clearSearch"
              onClick={this.handleSearchClear}
            >
              Clear
            </Button>
          ) : null}
          <Button
            className="searchButton"
            id="handleSearch"
            variant={buttonVariant || 'contained'}
            color="primary"
            onClick={this.handleSearch}
          >
            {buttonName}
          </Button>
        </CardActions>
      </Card>
    )
  }
}

Search.defaultProps = {
  submitSearch: undefined,
  shipmentNumber: undefined,
  userId: undefined,
  clearSearchCallback: () => {},
  clearOnSubmit: false,
  label: undefined,
  helperText: undefined,
  buttonName: undefined,
  submitOnEnter: false,
  onChangeText: undefined,
}
Search.propTypes = {
  submitSearch: PropTypes.func,
  shipmentNumber: PropTypes.number,
  clearSearchCallback: PropTypes.func,
  clearOnSubmit: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  buttonName: PropTypes.string,
  userId: PropTypes.string,
  submitOnEnter: PropTypes.bool,
  isClearVisible: PropTypes.bool,
  onChangeText: PropTypes.func,
}

export default withStyles(styles)(Search)
