import * as types from './actionType'
import { findIndex } from 'lodash'

export const initialState = {
  picklistData: [],
  picklistDataLoading: false,
  totalRowsCount: 10,
  selectedData: [],
}

export default function projectPicklist(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_PROJECT_PICKLIST_DATA:
      return Object.assign({}, state, {
        ...state,
        picklistData: action.payload.data,
      })

    case types.PROJECT_PICKLIST_LOADING:
      return Object.assign({}, state, {
        ...state,
        picklistDataLoading: action.payload.data,
      })
    case types.SET_ROWS_COUNT:
      return Object.assign({}, state, {
        ...state,
        totalRowsCount: action.payload.data,
      })
    case types.PICKLIST_CHECKBOX_CHANGE: {
      const { pegasusId: selectedPegId } = action.payload.data
      const selectedIndex = findIndex(state?.selectedData, function (obj = {}) {
        return obj.pegasusId === selectedPegId
      })

      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(state?.selectedData, action.payload.data)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state?.selectedData.slice(1))
      } else if (selectedIndex === state?.selectedData.length - 1) {
        newSelected = newSelected.concat(state?.selectedData.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state?.selectedData.slice(0, selectedIndex),
          state?.selectedData.slice(selectedIndex + 1)
        )
      }
      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
      })
    }

    default:
      return state
  }
}
