import moment from 'moment'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import defaultThumbnail from '../images/defaultThumbnail.jpg'
import EditableFields from './EditableFields'
import { getDisplayThumbnailList, getImageMagnifier } from './CommonUtils'
import { isEmpty } from 'lodash'
import {
  ITME_TAB_LABEL,
  DETAILS_TAB_LABEL,
  MARKDOWN_TAB_LABEL,
  HISTORY_TAB_LABEL,
  PROPS_TAB_LABEL,
  SCENIC_TAB_LABEL,
  sampleTabDataKeyLabels,
  propsTabDataKeyLabels,
  scenicTabDataKeyLabels,
  propsTabDataKeyLabelsView,
  itemTabDataKeyLabels,
  markdownTabDataKeyLabels,
  scenicTabDataKeyLabelsView,
} from '../enums/tableTabs'
import HistoryPage from '../pages/SearchInventoryPage/HistoryPage'
import MarkDownPage from '../pages/SearchInventoryPage/MarkdownPage'
import PropsTabPage from '../pages/SearchInventoryPage/PropsTabPage'
import ItemTabPage from '../pages/SearchInventoryPage/ItemTabPage'
import SampleTabPage from '../pages/SearchInventoryPage/SampleTabPage'
// A simple function to take an object, and an enumeration of its keys w/ labels and produce
// a simple table representation. For use in the popup components

const getItemLabel = (
  itemCopy = {},
  itemDataKey = '',
  isEditable = false,
  editType = 'textField'
) => {
  const itemDataLabel = itemCopy[itemDataKey] || ''
  const returnStr =
    isEditable && editType === 'dropDown' && typeof itemDataLabel === 'object'
      ? itemDataLabel.label
      : itemDataLabel
  return returnStr
}
export const makePopUpTable = (
  item = {},
  dataLabels = [],
  updateSamplePageCallback = () => {},
  shootTypesData = [],
  propsMetaData = {},
  pageName=''
) => {
  let itemCopy = Object.assign({}, item)
  const { sampleId = '' } = itemCopy
  let projectsString = ''
  let collectionsString = ''

  if (itemCopy.projects) {
    for (let index in itemCopy.projects) {
      projectsString =
        projectsString +
        'Project ' +
        String(parseInt(index) + 1) +
        ': ' +
        itemCopy.projects[index].name +
        ', ' +
        itemCopy.projects[index].createdBy +
        ', ' +
        itemCopy.projects[index].shootStartDate +
        ', ' +
        itemCopy.projects[index].shootEndDate +
        '\n'
    }
    itemCopy.projects = projectsString
  }

  if (itemCopy.collections) {
    for (let index in itemCopy.collections) {
      collectionsString =
        collectionsString +
        'Collection ' +
        String(parseInt(index) + 1) +
        ': ' +
        itemCopy.collections[index].name +
        ', ' +
        itemCopy.collections[index].createdBy +
        ', ' +
        itemCopy.collections[index].createDate +
        '\n'
    }
    itemCopy.collections = collectionsString
  }

  const isPropsExists = Object.entries(propsMetaData || {}).length
  const imageUrlsList = ['sampleImageDPURL', 'sampleImageDPURLView', 'imageUrl']
  return (
    <Table>
      <TableBody>
        {dataLabels.map((keyDetailData = {}) => {
          const {
            isEditable = false,
            editType = 'textField',
            key: itemDataKey = '',
            label: itemDataLabel = '',
            autoEdit = false,
          } = keyDetailData
          return (
            <TableRow key={itemDataKey}>
              {imageUrlsList?.indexOf(itemDataKey) === -1 ? (
                <TableCell style={isPropsExists ? { width: '20%' } : {}}>
                  {itemDataLabel}
                </TableCell>
              ) : (
                ''
              )}
              <TableCell
                colSpan={
                  imageUrlsList?.indexOf(itemDataKey) !== -1
                    ? Number(2)
                    : Number(1)
                }
                align={
                  imageUrlsList?.indexOf(itemDataKey) !== -1 ? 'center' : 'left'
                }
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {editableFieldsRender(
                  getItemLabel(itemCopy, itemDataKey, isEditable),
                  itemDataKey,
                  isEditable,
                  editType,
                  sampleId,
                  updateSamplePageCallback,
                  shootTypesData,
                  editType === 'dropDown'
                    ? [...(propsMetaData[itemDataKey] || [])]
                    : [],
                  autoEdit,
                  pageName
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

const editableFieldsRender = (
  tableCellLabel = '', //value
  tableCellKey = '',
  isEditable = false,
  editType = 'textField',
  sampleId = '',
  updateSamplePageCallback = () => {},
  shootTypesData = [],
  editOptions = [],
  autoEdit = false,
  pageName=''
) => {
  return (
    <EditableFields
      tableCellLabel={tableCellLabel}
      tableCellKey={tableCellKey}
      isEditable={isEditable}
      editType={editType}
      sampleId={sampleId}
      updateSamplePageCallback={updateSamplePageCallback}
      shootTypesData={shootTypesData}
      editOptions={editOptions}
      autoEdit={autoEdit}
      pageName={pageName}
    />
  )
}

export const findClosestShootDate = (item = {}) => {
  if (!item.projects) {
    return item
  }
  let itemCopy = Object.assign({}, item)
  if (itemCopy.projects) {
    let futureShootDates = []
    let pastShootDates = []
    for (let index in itemCopy.projects) {
      if (
        moment(itemCopy.projects[index].shootStartDate).isSameOrAfter(
          moment().startOf('day')
        )
      ) {
        futureShootDates.push(
          moment(itemCopy.projects[index].shootStartDate).toDate()
        )
      } else {
        pastShootDates.push(
          moment(itemCopy.projects[index].shootStartDate).toDate()
        )
      }
    }
    if (futureShootDates.length) {
      itemCopy.shootDate = moment(
        Math.min.apply(null, futureShootDates)
      ).format('MM/DD/YYYY')
    } else {
      itemCopy.shootDate = moment(Math.max.apply(null, pastShootDates)).format(
        'MM/DD/YYYY'
      )
    }
  }
  return itemCopy
}

export const reOrganizePopupItem = (popupItem = {}, classes = {}) => {
  const newPopupItem = Object.assign({}, popupItem)
  const {
    trackingNum = '',
    imagePopupUrl = '',
    carrier = '',
    sampleImagePopupUrl = [],
    sampleImagePopupUrlView = [],
  } = popupItem
  let newTranckingNum = ''
  let newPopupImage = ''
  if (!isEmpty(trackingNum)) {
    newTranckingNum = (
      <a
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        href={
          `https://www.google.com/search?q=` +
          encodeURI(`${carrier} ${trackingNum}`)
        }
        rel="noreferrer"
      >
        {trackingNum}
      </a>
    )
    newPopupItem['trackingNum'] = newTranckingNum
  }
  newPopupImage = (
    <div id="newPopupImage" className={classes.zoomCls}>
      {getImageMagnifier(imagePopupUrl)}
    </div>
  )
  const newPropsPopupImage = (
    <div id="newPopupImage" className={classes.zoomCls}>
      {sampleImagePopupUrl?.length ? (
        getDisplayThumbnailList(sampleImagePopupUrl, true)
      ) : (
        <img
          height={80}
          width={80}
          src={'imageUrl'}
          alt="Not Found"
          onError={(e) => {
            e.target.src = defaultThumbnail
          }}
        />
      )}
    </div>
  )
  const newPopupImageView = (
    <div id="newPopupImage" className={classes.zoomCls}>
      {sampleImagePopupUrlView?.length ? (
        getDisplayThumbnailList(sampleImagePopupUrlView, true)
      ) : (
        <img
          height={80}
          width={80}
          src={'imageUrl'}
          alt="Not Found"
          onError={(e) => {
            e.target.src = defaultThumbnail
          }}
        />
      )}
    </div>
  )
  newPopupItem['imageUrl'] = newPopupImage
  newPopupItem['sampleImageDPURL'] = newPropsPopupImage
  newPopupItem['sampleImageDPURLView'] = newPopupImageView
  newPopupItem['newLaunchDate'] =
    newPopupItem['sampleLaunchDate'] || newPopupItem['launchDate']
  delete newPopupItem['sampleLaunchDate']
  delete newPopupItem['launchDate']
  return newPopupItem
}

export const getTitle = (popupItem = {}) => {
  const newPopupItem = Object.assign({}, popupItem)
  const { pegasusId = '' } = newPopupItem
  const titleString = !isEmpty(pegasusId) ? pegasusId + ' Details' : ''
  return titleString
}

export const getTCINAsTitle = (popupItem = {}) => {
  const newPopupItem = Object.assign({}, popupItem)
  const { tcin = '' } = newPopupItem
  const titleString = !isEmpty(tcin) ? tcin + ' Details' : ''
  return titleString
}

export const makeTabTables = (
  popupItem = {},
  classes = {},
  tableTabsList = [],
  updateSamplePageCallback = () => {},
  shootTypesData = [],
  pageName = '',
  type = '',
  selectedTab = ''
) => {
  const newPopUpItem = reOrganizePopupItem(popupItem, classes)
  let newTable = new Map()
  if (!isEmpty(tableTabsList) && tableTabsList.indexOf(ITME_TAB_LABEL) !== -1) {
    newTable.set(
      ITME_TAB_LABEL,
      <ItemTabPage
        sampleData={newPopUpItem}
        markdownTabDataKeyLabels={itemTabDataKeyLabels}
        updateSamplePageCallback={updateSamplePageCallback}
      />
    )
  }

  if (
    !isEmpty(tableTabsList) &&
    tableTabsList.indexOf(DETAILS_TAB_LABEL) !== -1
  ) {
    newTable.set(
      DETAILS_TAB_LABEL,
      <SampleTabPage
        sampleData={newPopUpItem}
        markdownTabDataKeyLabels={sampleTabDataKeyLabels}
        updateSamplePageCallback={updateSamplePageCallback}
        pageName={pageName}
      />
    )
  }
  if (
    !isEmpty(tableTabsList) &&
    tableTabsList.indexOf(MARKDOWN_TAB_LABEL) !== -1
  ) {
    newTable.set(
      MARKDOWN_TAB_LABEL,
      <MarkDownPage
        sampleData={newPopUpItem}
        markdownTabDataKeyLabels={markdownTabDataKeyLabels}
        updateSamplePageCallback={updateSamplePageCallback}
        shootTypesData={shootTypesData}
        pageName={pageName}
      />
    )
  }
  if (
    !isEmpty(tableTabsList) &&
    tableTabsList.indexOf(HISTORY_TAB_LABEL) !== -1
  ) {
    newTable.set(
      HISTORY_TAB_LABEL,
      <HistoryPage sampleData={newPopUpItem} pageName={pageName} />
    )
  }
  if (
    !isEmpty(tableTabsList) &&
    tableTabsList.indexOf(PROPS_TAB_LABEL) !== -1
  ) {
    newTable.set(
      PROPS_TAB_LABEL,
      <PropsTabPage
        sampleData={newPopUpItem}
        markdownTabDataKeyLabels={
          pageName === 'ADDPROP'
            ? propsTabDataKeyLabels
            : propsTabDataKeyLabelsView
        }
        updateSamplePageCallback={updateSamplePageCallback}
        pageName={pageName}
        type={type}
        selectedTab={selectedTab}
      />
    )
  }
  if (
    !isEmpty(tableTabsList) &&
    tableTabsList.indexOf(SCENIC_TAB_LABEL) !== -1
  ) {
    newTable.set(
      SCENIC_TAB_LABEL,
      <PropsTabPage
        sampleData={newPopUpItem}
        markdownTabDataKeyLabels={
          pageName === 'ADDSCENIC'
            ? scenicTabDataKeyLabels
            : scenicTabDataKeyLabelsView
        }
        updateSamplePageCallback={updateSamplePageCallback}
        pageName={pageName}
        type={type}
        selectedTab={selectedTab}
      />
    )
  }
  return newTable
}
