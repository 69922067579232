import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import { CustomPaper } from '../../util/CommonUtils'
export default function CheckboxesTags({
  options = [],
  label = '',
  styles = {},
  onChange = () => {},
  selectedValue = [],
  getOptionDisabled = () => {},
  filterSelectedOptions = false,
  filterOption = 'label',
  limitTags,
}) {
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option[filterOption],
  })
  return (
    <Autocomplete
      multiple
      limitTags={limitTags}
      id="multi-select-auto-complete"
      style={styles}
      options={options}
      onChange={onChange(label)}
      groupBy={(option = {}) => option.group_by || ''}
      getOptionLabel={(option = {}) => option.displayName || option.label}
      getOptionDisabled={getOptionDisabled}
      PaperComponent={CustomPaper}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={label} />
      )}
      disablePortal={true}
      value={selectedValue}
      filterOptions={filterOptions}
      renderTags={(tagValue, getTagProps) => {
        const tagValueCopy = tagValue.filter(
          (obj) => obj && obj.value !== 'classId'
        )
        return tagValueCopy.map((option, index) => (
          <Chip
            label={option.displayName || option.label}
            {...getTagProps({ index })}
            disabled={['Class'].indexOf(option.label) !== -1}
          />
        ))
      }}
      filterSelectedOptions={filterSelectedOptions}
    />
  )
}
