import React from 'react'
import * as propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, TextField, DialogActions } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Logo from '../../images/arrow-split-horizontal.svg'
import TableHead from '@material-ui/core/TableHead'
import IconButton from '@material-ui/core/IconButton'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Clear from '@material-ui/icons/Clear'
// import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { getCheckInLocations } from '../../store/settings/actionCreator'
import moment from 'moment'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Popovertheme } from '../../config/themeConfig'
import { DATE_FORMAT } from '../../util/CommonUtils'
import { isEqual, isEmpty, get } from 'lodash'
import { MenuItem, ListSubheader } from '@mui/material'
import { getProjects } from '../../store/projects/actionCreator'
import { getCollections } from '../../store/collections/actionCreator'
import { createTheme } from '@mui/material/styles'

// const FlexibleTableNew = withStyles((theme) => ({
//   container: {
//     maxHeight: '350px !important',
//   },
//   root: {
//     width: '100%',
//     display: 'grid',
//     boxShadow: 'unset !important',
//   },
//   table: {
//     minWidth: 500,
//   },
//   tableWithScrolls: {
//     minWidth: 500,
//     minHeight: 500,
//     // '& tr:not(:nth-child(1))': {
//     '& td:nth-child(1)': {
//       position: 'sticky',
//       left: 0,
//       backgroundColor: 'white',
//       // zIndex: 3,
//     },
//     '& th:nth-child(1)': {
//       position: 'sticky',
//       left: 0,
//       zIndex: 3,
//     },
//     '& td:nth-child(2)': {
//       position: 'sticky',
//       left: 65,
//       backgroundColor: 'white',
//       borderBottom: '1px solid rgba(224, 224, 224, 1)',
//       // zIndex: 7,
//     },
//     '& th:nth-child(2)': {
//       // '& td:first-child,th:first-child': {
//       position: 'sticky',
//       left: 65,
//       // backgroundColor: 'white',
//       borderBottom: '1px solid rgba(224, 224, 224, 1)',
//       zIndex: 7,
//     },
//     '& td:nth-child(3)': {
//       position: 'sticky',
//       left: 102,
//       backgroundColor: 'white',
//       borderBottom: '1px solid rgba(224, 224, 224, 1)',
//       // zIndex: 7,
//     },
//     '& th:nth-child(3)': {
//       position: 'sticky',
//       left: 102,
//       // backgroundColor: 'white',
//       borderBottom: '1px solid rgba(224, 224, 224, 1)',
//       zIndex: 7,
//     },
//     '& td:last-child': {
//       position: 'sticky',
//       right: 0,
//       backgroundColor: 'white',
//       // zIndex: 3,
//     },
//     '& th:last-child': {
//       position: 'sticky',
//       right: 0,
//       // backgroundColor: '#ddd',
//       zIndex: 4,
//     },
//   },
//   tableWrapper: {
//     overflow: 'auto',
//   },
//   tableBody: {
//     height: 'auto',
//   },
//   paginationAction: {
//     flexShrink: 0,
//     color: theme.palette.text.secondary,
//     marginLeft: theme.spacing(2.5),
//   },
//   spacer: {
//     height: 40,
//   },
//   counter: {
//     // fontSize: 'large',
//     textAlign: 'left',
//     color: '#333333',
//     fontWeight: 'bold',
//     padding: '5px',
//   },
//   /* do not remove these classes, there is some dependency */
//   checkBoxElem: {},
//   tableRow: {},
//   header: {
//     top: 0,
//     left: 0,
//     zIndex: 2,
//     position: 'sticky',
//     backgroundColor: '#F7F7F7',
//   },
//   errorBlock: { padding: '0px 0px 0px 15px !important' },
//   errorIcon: {
//     display: 'flex',
//     color: '#B85300',
//     alignItems: 'center',
//   },
//   progressBarDiv: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   progressBar: {
//     padding: '7px',
//     color: '#333333',
//   },
// }))(FlexibleTable)
const menuItemTheme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: '300px',
          whiteSpace: 'pre-wrap',
          marginLeft: 10,
        },
      },
    },
  },
})

const styles = (theme) => ({
  root: {
    padding: '100px',
    margin: '100px',
    width: '100%',
    maxWidth: '100%',
  },
  textField: {
    // padding: '0px 10px',
    // margin: '0px 10px',
    width: '200px',
  },
  textFieldDropDown: {
    width: '200px',
  },
  typography: {
    padding: '10px',
  },
  datePickerClass: {
    width: '225px',
    // zIndex: 3,
  },
  tableContainer: {
    maxHeight: '350px !important',
  },
  tableHeader: {
    top: 0,
    left: 0,
    zIndex: 2,
    position: 'sticky',
    backgroundColor: '#F7F7F7',
  },
  menuPaper: {
    height: '500px',
    whiteSpace: 'pre-wrap',
  },
  menuList: {
    height: '200px',
    margin: 5,
  },
  dialogActions: {
    padding: '16px 24px',
  },
})

export function CustomRow({ key, sx, data, children }) {
  return (
    <TableRow key={key} sx={sx} data={data}>
      {children}
    </TableRow>
  )
}
function rowsPropsAreEqual(prevNotes, nextNotes) {
  return isEqual(prevNotes.data, nextNotes.data)
}
export const MemoCustomRow = React.memo(CustomRow, rowsPropsAreEqual)

export function CustomCell({ key, currentValue, scope, children }) {
  return (
    <TableCell key={key} currentValue={currentValue} scope={scope}>
      {children}
    </TableCell>
  )
}
function cellPropsAreEqual(prevNotes, nextNotes) {
  return (
    prevNotes.currentValue === nextNotes.currentValue &&
    prevNotes.parentId === nextNotes.parentId
  )
}
export const MemoCustomCell = React.memo(CustomCell, cellPropsAreEqual)

export function NotesField({
  classes,
  id,
  name,
  onChange,
  placeholder,
  value,
}) {
  const onChangeCB = React.useCallback(onChange, [onChange, value])
  return (
    <TextField
      className={classes.textField}
      id={id}
      multiline
      name={name}
      onChange={onChangeCB}
      placeholder={placeholder}
      value={value}
    />
  )
}
function notesPropsAreEqual(prevNotes, nextNotes) {
  return (
    prevNotes.value === nextNotes.value &&
    prevNotes.id === nextNotes.id &&
    prevNotes.uniqueId === nextNotes.uniqueId &&
    prevNotes.rowId === nextNotes.rowId
  )
}
export const MemoizedNotesField = React.memo(NotesField, notesPropsAreEqual)

class ItemEditCard extends React.PureComponent {
  constructor(props) {
    super(props)
    const { items = [] } = this.props
    this.tableRef = React.createRef()
    this.state = {
      editIsVisible: false,
      itemsToEdit: items,
      editInformation: items.slice(0, 10),
      totalEditItemsCount: items.length,
      isFetching: false,
      loadMoreIndex: Number(0),
    }

    // this.handleChange = debounce(this.handleChange, 200)
  }

  componentDidMount = () => {
    const {
      getCheckInLocations,
      sourcePage = '',
      getProjects = () => {},
      getCollections = () => {},
    } = this.props
    if (sourcePage !== 'searchInventory') {
      getCheckInLocations()
      getProjects({ status: 'PENDING' })
      getCollections({ status: '' })
    }
    if (this.tableRef.current) {
      this.tableRef.current.addEventListener('scroll', () => {
        if (
          this.tableRef.current.scrollTop +
            this.tableRef.current.clientHeight >=
          this.tableRef.current.scrollHeight
        ) {
          this.loadMoreItems()
        }
      })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { items = [] } = this.props
    const { items: prevItems = [] } = prevProps

    const { loadMoreIndex = Number(0), itemsToEdit = [] } = this.state
    const { loadMoreIndex: prevLoadMoreIndex = Number(0) } = prevState

    if (
      loadMoreIndex >= Number(0) &&
      loadMoreIndex <= items.length &&
      !isEqual(loadMoreIndex, prevLoadMoreIndex) &&
      items.length > 0 &&
      isEqual(items, prevItems)
    ) {
      this.setState((state) => ({
        ...state,
        editInformation: itemsToEdit.slice(0, 10 + loadMoreIndex),
        isFetching: false,
      }))
    }

    if (items.length > 0 && !isEqual(items, prevItems)) {
      this.setState((state) => ({
        ...state,
        editInformation: items.slice(0, 10),
        itemsToEdit: items,
        loadMoreIndex: Number(0),
        isFetching: false,
        totalEditItemsCount: items.length,
      }))
    }
  }

  handleSaveClick = () => {
    this.setState((state) => ({ ...state, editIsVisible: false }))
    const { handleSaveClick, userId } = this.props
    const { itemsToEdit = [] } = this.state

    if (this.handleSaveClick) {
      handleSaveClick(itemsToEdit, userId)
    }
  }

  handleCancelClick = () => {
    const { onClose = () => {}, items = [] } = this.props
    onClose()
    this.setState((state) => ({
      ...state,
      editIsVisible: false,
      editInformation: items.slice(0, 10),
      totalEditItemsCount: items.length,
    }))
  }

  handleChangeUpdate = async (event, pegasusId) => {
    event.persist()

    const caretStart = event.target.selectionStart
    const caretEnd = event.target.selectionEnd
    // update the state and reset the caret
    await this.handleChange(event, pegasusId)
    event.target.setSelectionRange(caretStart, caretEnd)
  }
  handleChange(event, pegasusId) {
    let field = event.target.name
    let value = event.target.value
    this.setState((state) => {
      // const { editInformation: stateEditInformation = [] } = state
      // return {
      //   ...state,
      //   editInformation: stateEditInformation.map((i) => ({
      //     ...i,
      //     [field]: i.pegasusId === pegasusId ? value : i[field],
      //   })),
      // }
      const { itemsToEdit: stateEditInformation = [] } = state
      const { loadMoreIndex = Number(0) } = this.state
      const newList = stateEditInformation.map((i) => ({
        ...i,
        [field]: i.pegasusId === pegasusId ? value : i[field],
      }))
      return {
        ...state,
        itemsToEdit: newList,
        editInformation: newList.slice(0, 10 + loadMoreIndex),
      }
    })
  }

  handleCascadeChange = (event) => {
    let field = event.column
    let cascadeValue = event.value
    const { itemsToEdit: stateEditInformation = [] } = this.state
    const { loadMoreIndex = Number(0) } = this.state
    let newList = []
    if (field !== 'project') {
      newList = stateEditInformation.map((i) => ({
        ...i,
        [field]: cascadeValue,
      }))
    } else {
      let updatedProjectValue = []
      updatedProjectValue = !isEmpty(
        this.getProjectsOption().filter(
          ({ id = '' }) => cascadeValue.indexOf(id) !== -1
        )
      )
        ? this.getProjectsOption().filter(
            ({ id = '' }) => cascadeValue.indexOf(id) !== -1
          )
        : this.getCollectionsOption().filter(
            ({ id = '' }) => cascadeValue.indexOf(id) !== -1
          )
      newList = stateEditInformation.map((i) => ({
        ...i,
        projects: updatedProjectValue,
      }))
    }
    this.setState((state) => ({
      ...state,
      itemsToEdit: newList,
      editInformation: newList.slice(0, 10 + loadMoreIndex),
    }))
  }

  handleDueDateChange(event, pegasusId, fieldName = '') {
    if (!isEmpty(fieldName)) {
      let newDate = event
        ? moment(event?.toISOString()).format('YYYY-MM-DD')
        : null
      const {
        itemsToEdit: stateEditInformation = [],
        loadMoreIndex = Number(0),
      } = this.state
      this.setState((state) => ({
        ...state,
        itemsToEdit: stateEditInformation.map((i) => ({
          ...i,
          [fieldName]: i.pegasusId === pegasusId ? newDate : i[fieldName],
        })),
        editInformation: stateEditInformation
          .map((i) => ({
            ...i,
            [fieldName]: i.pegasusId === pegasusId ? newDate : i[fieldName],
          }))
          .slice(0, 10 + loadMoreIndex),
      }))
    }
  }

  handleProjectsChange(event, pegasusId = '', projects = []) {
    let newValue = event.target.value
    const { itemsToEdit: stateEditInformation = [] } = this.state
    const { loadMoreIndex = Number(0) } = this.state
    const projectNames = this.getProjectsOption()
    let updatedProjectName = []
    if (projectNames.map((ele) => ele.id).indexOf(newValue) !== -1) {
      updatedProjectName = projectNames.filter((obj) => obj.id === newValue)
    } else {
      updatedProjectName = this.getCollectionsOption().filter(
        (obj) => obj.id === newValue
      )
    }
    if (!isEmpty(updatedProjectName)) {
      const newList = stateEditInformation.map((i) => ({
        ...i,
        projects:
          i.pegasusId === pegasusId
            ? [Object.assign({}, get(updatedProjectName, 0, {}))]
            : !isEmpty(i.projects)
            ? i.projects
            : i.collections,
      }))
      this.setState((state) => ({
        ...state,
        itemsToEdit: newList,
        editInformation: newList.slice(0, 10 + loadMoreIndex),
      }))
    }
  }

  getLocationNames = (locations) => {
    const locationNames = locations.map((it) =>
      Object.assign(
        {},
        {
          name: it.building,
        }
      )
    )

    return locationNames.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  getProjectsOption = () => {
    const { projectsPending = [] } = this.props
    return [...projectsPending].sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  getCollectionsOption = () => {
    const { allCollections = [] } = this.props
    return [...allCollections].sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  getOptions = (projects = []) => {
    return projects?.map(({ id = '' }) => id)
  }

  dataToEditableFieldsDefault = () => {
    const { editInformation = [] } = this.state
    const { classes = {}, locations = [] } = this.props
    const newItems = editInformation.map((i) => ({
      ...i,
      dueDate: (
        <MuiThemeProvider theme={Popovertheme} data-value={i.dueDate}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                variant="inline"
                clearable={'true'}
                disablePast
                keyboard
                inputProps={{ readOnly: true }}
                autoOk
                InputProps={{
                  startAdornment: i.dueDate && (
                    <IconButton
                      size="small"
                      aria-label="clear"
                      onClick={() =>
                        this.handleDueDateChange(null, i.pegasusId, 'dueDate')
                      }
                      disabled={!i.dueDate}
                      style={{ order: 2 }}
                    >
                      <Clear />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                  style: { order: 2, marginLeft: 0 },
                }}
                onChange={(value) =>
                  this.handleDueDateChange(value, i.pegasusId, 'dueDate')
                }
                value={
                  !isEmpty(i.dueDate)
                    ? moment(i.dueDate).format('YYYY-MM-DD')
                    : null
                }
                format={DATE_FORMAT}
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                disableOpenOnEnter
                className={classes.datePickerClass}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      ),
      shipTo: (
        <TextField
          error={i.shipTo === null}
          InputLabelProps={{
            shrink: true,
          }}
          label="Select Location"
          name="shipTo"
          onChange={(value) => this.handleChange(value, i.pegasusId)}
          select
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
            native: true,
          }}
          className={classes.textFieldDropDown}
          value={i.shipTo}
        >
          {locations !== undefined
            ? this.getLocationNames(locations).map((option) => (
                <option value={option.name} key={option.name}>
                  {option.name}
                </option>
              ))
            : ''}
        </TextField>
      ),
      vendorNotes: (
        <TextField
          className={classes.textField}
          id="vendorNotes"
          multiline
          name="vendorNotes"
          onChange={(event) => this.handleChangeUpdate(event, i.pegasusId)}
          placeholder="Notes for Vendor"
          value={i.vendorNotes}
        />
      ),
      studioNotes: (
        <TextField
          className={classes.textField}
          name="studioNotes"
          id="studioNotes"
          multiline
          onChange={(value) => this.handleChangeUpdate(value, i.pegasusId)}
          placeholder="Notes for Studio"
          value={i.studioNotes}
        />
      ),
      project: (
        <TextField
          style={{
            width: '200px',
            whiteSpace: 'pre-wrap',
          }}
          id="project"
          name="projects"
          select
          placeholder="Select Projects/Collections"
          value={
            !isEmpty(i.projects)
              ? this.getOptions(i.projects)
              : this.getOptions(i.collections)
          }
          onChange={(event) => {
            this.handleProjectsChange(event, i.pegasusId, i.projects)
          }}
          variant="standard"
          SelectProps={{
            MenuProps: {
              classes: {
                paper: classes.menuPaper,
                list: classes.menuList,
              },
            },
            native: false,
          }}
        >
          <ListSubheader>PROJECTS</ListSubheader>
          {this.getProjectsOption().map((option) => (
            <MenuItem key={option.id} value={option.id} theme={menuItemTheme}>
              {option.name}
            </MenuItem>
          ))}
          <ListSubheader>COLLECTIONS</ListSubheader>
          {this.getCollectionsOption().map((option) => (
            <MenuItem key={option.id} value={option.id} theme={menuItemTheme}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      ),
    }))
    return newItems
  }

  dataToEditableFieldsSearchInv = () => {
    const { editInformation = [] } = this.state
    const { classes = {} } = this.props
    const newItems = editInformation.map((i, index) => ({
      ...i,
      disposition_override_date: (
        <MuiThemeProvider
          theme={Popovertheme}
          data-value={i.disposition_override_date}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                variant="inline"
                clearable={'true'}
                disablePast
                keyboard
                autoOk
                inputProps={{ readOnly: true }}
                InputProps={{
                  startAdornment: i.disposition_override_date && (
                    <IconButton
                      size="small"
                      aria-label="clear"
                      onClick={() =>
                        this.handleDueDateChange(
                          null,
                          i.pegasusId,
                          'disposition_override_date'
                        )
                      }
                      disabled={!i.disposition_override_date}
                      style={{ order: 2 }}
                    >
                      <Clear />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                  style: { order: 2, marginLeft: 0 },
                }}
                onChange={(value) =>
                  this.handleDueDateChange(
                    value,
                    i.pegasusId,
                    'disposition_override_date'
                  )
                }
                value={
                  !isEmpty(i.disposition_override_date)
                    ? moment(i.disposition_override_date).format('YYYY-MM-DD')
                    : null
                }
                format={DATE_FORMAT}
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                disableOpenOnEnter
                className={classes.datePickerClass}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      ),
      dispositionNotes: (
        // <TextField
        //   className={classes.textField}
        //   id="dispositionNotes"
        //   multiline
        //   name="dispositionNotes"
        //   onChange={(value) => this.handleChangeUpdate(value, i.pegasusId)}
        //   placeholder="Disposition Notes"
        //   value={i.dispositionNotes || ''}
        // />
        <MemoizedNotesField
          classes={classes}
          id={'dispositionNotes'}
          name={'dispositionNotes'}
          onChange={(value) => this.handleChangeUpdate(value, i.pegasusId)}
          placeholder={'Disposition Notes'}
          value={i.dispositionNotes || ''}
          uniqueId={i.pegasusId}
          rowId={index}
        />
      ),
      warehouseNotes: (
        // <TextField
        //   className={classes.textField}
        //   name="warehouseNotes"
        //   id="warehouseNotes"
        //   multiline
        //   onChange={(value) => this.handleChangeUpdate(value, i.pegasusId)}
        //   placeholder="Warehouse Notes"
        //   value={i.warehouseNotes || ''}
        // />
        <MemoizedNotesField
          classes={classes}
          id={'warehouseNotes'}
          name={'warehouseNotes'}
          onChange={(value) => this.handleChangeUpdate(value, i.pegasusId)}
          placeholder={'Warehouse Notes'}
          value={i.warehouseNotes || ''}
          uniqueId={i.pegasusId}
          rowId={index}
        />
      ),
    }))
    return newItems
  }

  dataToEditableFields = () => {
    const { sourcePage = '' } = this.props
    switch (sourcePage) {
      case 'searchInventory': {
        return this.dataToEditableFieldsSearchInv()
      }
      default:
        return this.dataToEditableFieldsDefault()
    }
  }

  loadMoreItems = () => {
    const { loadMoreIndex = Number(0) } = this.state
    this.setState({
      isFetching: true,
      loadMoreIndex: loadMoreIndex + Number(10),
    })
    // setTimeout(() => {
    //   this.setState({ items: this.state.items + 20, isFetching: false })
    // }, 2000)
  }

  renderItemsInTable = () => {
    const {
      editColumnData,
      classes = {},
      // onSortSelectedChange,
      // order,
      // orderBy,
      // page,
      // rowCount,
      // rowsPerPage,
      // setPage,
      // setRowsPerPage,
      cascadeFieldKeys = [
        'dueDate',
        'shipTo',
        'vendorNotes',
        'studioNotes',
        'project',
      ],
    } = this.props
    const dataFieldKeys = editColumnData.map((column) => column.id)
    const newItems = this.dataToEditableFields()
    // const {
    //   isFetching,
    //   loadMoreIndex,
    //   totalEditItemsCount = Number(0),
    // } = this.state
    return (
      <>
        {/* <FlexibleTableNew
          columnData={editColumnData}
          cascadeFieldKeys={cascadeFieldKeys}
          data={newItems}
          dataFieldKeys={dataFieldKeys}
          onCascadeChange={this.handleCascadeChange}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSortSelectedChange={onSortSelectedChange}
          onPopupChange={() => {}}
          order={order}
          orderBy={orderBy}
          page={page}
          rowCount={rowCount}
          rowsPerPage={rowsPerPage}
          showPopup={() => {}}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalRowsCount={newItems.length || Number(0)}
          disableTablePagination={true}
          loadMore={this.loadMoreItems}
          loadItemsOnScroll={
            Number(totalEditItemsCount) !== Number(loadMoreIndex)
          }
          isLoading={isFetching}
          enableSpacer={false}
          // enableSearch
          disableItemCount
        /> */}
        <div style={{ overflow: 'auto' }}>
          <TableContainer
            component={Paper}
            ref={this.tableRef}
            onScroll={(event) => {
              if (
                event.target.scrollTop + event.target.clientHeight >=
                event.target.scrollHeight - 1
              ) {
                this.loadMoreItems()
              }
            }}
            className={classes.tableContainer}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {editColumnData.map((i = {}) => {
                    return (
                      <TableCell
                        key={i.id}
                        style={i.style}
                        // sortDirection={
                        //   this.state.orderBy === i.id ? this.state.order : false
                        // }
                        className={classes.tableHeader}
                      >
                        {i.label}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {newItems.map((item, index) => (
                  <MemoCustomRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    data={item}
                  >
                    {dataFieldKeys.map((key = '', keyIndex) => {
                      return (
                        <MemoCustomCell
                          key={`${index}_${keyIndex}`}
                          currentValue={item[key]}
                          parentId={index}
                          scope="row"
                        >
                          <div style={{ display: 'flex' }}>
                            <div>{item[key]}</div>
                            <div>
                              {cascadeFieldKeys.indexOf(key) !== -1 ? (
                                <IconButton
                                  onClick={() =>
                                    this.handleCascadeChange({
                                      value:
                                        item[key].props.value ||
                                        item[key].props['data-value'] ||
                                        item[key].props.defaultValue,
                                      column: key,
                                    })
                                  }
                                  style={{ width: '45px' }}
                                >
                                  <Logo />
                                </IconButton>
                              ) : null}
                            </div>
                          </div>
                        </MemoCustomCell>
                      )
                    })}
                  </MemoCustomRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* {isFetching && <CircularPageLoader open={true} />} */}
        {/* {!isFetching && <button onClick={this.loadMoreItems}>Load more</button>} */}
      </>
    )
  }

  render() {
    const { isVisible, classes = {} } = this.props
    const { totalEditItemsCount = Number(0) } = this.state
    return (
      <div>
        <Dialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          scroll="paper"
          open={isVisible}
          onClose={this.handleClose}
          fullWidth
          maxWidth={'lg'}
        >
          <DialogTitle>{`Edit ${totalEditItemsCount} Items`}</DialogTitle>
          <DialogContent>{this.renderItemsInTable()}</DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button color="primary" onClick={this.handleCancelClick}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSaveClick}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    settings: { checkInLocations = [] },
    projects: { projects = [] },
    collections: { allCollections = [] },
  } = state
  return {
    locations: checkInLocations,
    projectsPending: projects,
    allCollections: allCollections,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCheckInLocations: () => dispatch(getCheckInLocations()),
    getProjects: (params) => dispatch(getProjects(params)),
    getCollections: (status) => dispatch(getCollections(status)),
  }
}

ItemEditCard.defaultProps = {
  isVisible: false,
  onClose: () => {},
  items: [],
  dataKeyLabels: [],
  title: '',
  handleSaveClick: () => {},
  editColumnData: [],
  userId: '',
  onClick: () => {},
  onSortSelectedChange: () => {},
  onPopupChange: () => {},
  order: '',
  orderBy: '',
  page: 0,
  rowCount: 0,
  rowsPerPage: 10,
}

ItemEditCard.propTypes = {
  isVisible: propTypes.bool,
  onClose: propTypes.func,
  title: propTypes.string,
  userId: propTypes.string,
  items: propTypes.array,
  dataKeyLabels: propTypes.array,
  editColumnData: propTypes.array,
  onClick: propTypes.func,
  onSortSelectedChange: propTypes.func,
  onPopupChange: propTypes.func,
  order: propTypes.string,
  orderBy: propTypes.string,
  page: propTypes.number,
  rowCount: propTypes.number,
  rowsPerPage: propTypes.number,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ItemEditCard))
