export const SHOOT_TYPE_CREATE_REQUEST_PENDING =
  'SHOOT_TYPE_CREATE_REQUEST_PENDING'
export const SHOOT_TYPE_CREATE_REQUEST_SUCCESS =
  'SHOOT_TYPE_CREATE_REQUEST_SUCCESS'
export const SHOOT_TYPE_CREATE_REQUEST_FAILURE =
  'SHOOT_TYPE_CREATE_REQUEST_FAILURE'
export const SHOOT_TYPE_UPDATE_REQUEST_PENDING =
  'SHOOT_TYPE_UPDATE_REQUEST_PENDING'
export const SHOOT_TYPE_UPDATE_REQUEST_SUCCESS =
  'SHOOT_TYPE_UPDATE_REQUEST_SUCCESS'
export const SHOOT_TYPE_UPDATE_REQUEST_FAILURE =
  'SHOOT_TYPE_UPDATE_REQUEST_FAILURE'
export const SHOOT_TYPE_DELETE_REQUEST_SUCCESS =
  'SHOOT_TYPE_DELETE_REQUEST_SUCCESS'
export const SHOOT_TYPE_DELETE_REQUEST_PENDING =
  'SHOOT_TYPE_DELETE_REQUEST_PENDING'
export const SHOOT_TYPE_DELETE_REQUEST_FAILURE =
  'SHOOT_TYPE_DELETE_REQUEST_FAILURE'
export const ADD_SHOOT_TYPE_SAMPLES_REQUEST_SUCCESS =
  'ADD_SHOOT_TYPE_SAMPLES_REQUEST_SUCCESS'
export const ADD_SHOOT_TYPE_SAMPLES_REQUEST_PENDING =
  'ADD_SHOOT_TYPE_SAMPLES_REQUEST_PENDING'
export const ADD_SHOOT_TYPE_SAMPLES_REQUEST_FAILURE =
  'ADD_SHOOT_TYPE_SAMPLES_REQUEST_FAILURE'
export const UNAVAILABLE_SHOOT_TYPE_SAMPLES_SUCCESS =
  'UNAVAILABLE_SHOOT_TYPE_SAMPLES_SUCCESS'
export const UNAVAILABLE_SHOOT_TYPE_SAMPLES_PENDING =
  'UNAVAILABLE_SHOOT_TYPE_SAMPLES_PENDING'
export const UNAVAILABLE_SHOOT_TYPE_SAMPLES_FAILURE =
  'UNAVAILABLE_SHOOT_TYPE_SAMPLES_FAILURE'
export const REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_SUCCESS =
  'REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_SUCCESS'
export const REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_PENDING =
  'REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_PENDING'
export const REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_FAILURE =
  'REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_FAILURE'
export const SHOOT_TYPE_CHANNEL_REQUEST_SUCCESS =
  'SHOOT_TYPE_CHANNEL_REQUEST_SUCCESS'
export const SHOOT_TYPE_CHANNEL_REQUEST_PENDING =
  'SHOOT_TYPE_CHANNEL_REQUEST_PENDING'
export const SHOOT_TYPE_CHANNEL_REQUEST_FAILURE =
  'SHOOT_TYPE_CHANNEL_REQUEST_FAILURE'
export const SHOOT_TYPE_FETCH_DATA_PENDING = 'SHOOT_TYPE_FETCH_DATA_PENDING'
export const SHOOT_TYPE_FETCH_DATA_FAILURE = 'SHOOT_TYPE_FETCH_DATA_FAILURE'
export const SHOOT_TYPE_FETCH_DATA_SUCCESS = 'SHOOT_TYPE_FETCH_DATA_SUCCESS'
export const SHOOT_TYPE_SET_UNKNOWN_DATA = 'SHOOT_TYPE_SET_UNKNOWN_DATA'
export const SHOW_SHOOT_TYPE_PAGE_ALERT = 'SHOW_SHOOT_TYPE_PAGE_ALERT'
export const CLOSE_SHOOT_TYPE_PAGE_ALERT = 'CLOSE_SHOOT_TYPE_PAGE_ALERT'
export const RESET_NEW_SHOOT_TYPE_STATE = 'RESET_NEW_SHOOT_TYPE_STATE'
export const SHOOT_TYPE_SET_CHECKBOX = 'SHOOT_TYPE_SET_CHECKBOX'
export const SHOOT_TYPE_SET_PAGE = 'SHOOT_TYPE_SET_PAGE'
export const SHOOT_TYPE_SET_ROWS_PER_PAGE = 'SHOOT_TYPE_SET_ROWS_PER_PAGE'
export const SHOOT_TYPE_SET_TOTAL_ROWS_COUNT = 'SHOOT_TYPE_SET_TOTAL_ROWS_COUNT'
export const SHOOT_TYPE_CLEAR_DATA = 'SHOOT_TYPE_CLEAR_DATA'
export const SHOW_SHOOT_TYPE_POPUP = 'SHOW_SHOOT_TYPE_POPUP'
export const CLOSE_SHOOT_TYPE_POPUP = 'CLOSE_SHOOT_TYPE_POPUP'
export const CLOSE_UNAVAILABLE_SAMPLES_POPUP = 'CLOSE_UNAVAILABLE_SAMPLES_POPUP'
export const BACK_TO_LANDING_PAGE = 'BACK_TO_LANDING_PAGE'
export const MOVE_TO_EDIT_PAGE = 'MOVE_TO_EDIT_PAGE'
export const MOVE_TO_VIEW_PAGE = 'MOVE_TO_VIEW_PAGE'
export const SHOOT_TYPE_CLEAR_TABLE_DATA = 'SHOOT_TYPE_CLEAR_TABLE_DATA'
export const SHOOT_TYPE_SET_OVERLAY_CHECKBOX = 'SHOOT_TYPE_SET_OVERLAY_CHECKBOX'
export const SHOOT_TYPE_SET_OVERLAY_HEADER_CHECKBOX =
  'SHOOT_TYPE_SET_OVERLAY_HEADER_CHECKBOX'
export const SHOOT_TYPE_RESET_OVERLAY_CHECKBOX =
  'SHOOT_TYPE_RESET_OVERLAY_CHECKBOX'
export const SHOOT_TYPE_SET_CHECKBOX_HEADER = 'SHOOT_TYPE_SET_CHECKBOX_HEADER'
export const SHOOT_TYPE_CALL_EDIT_FIELDS_API = 'SHOOT_TYPE_CALL_EDIT_FIELDS_API'
export const ROWS_PER_PAGE_UPDATED = 'ROWS_PER_PAGE_UPDATED'
export const RESET_ROWS_PER_PAGE_UPDATED = 'RESET_ROWS_PER_PAGE_UPDATED'
export const ADD_SAMPLES_RESPONSE = 'ADD_SAMPLES_RESPONSE'
export const UPDATE_REQUEST_SAMPLE_QUANTITY = 'UPDATE_REQUEST_SAMPLE_QUANTITY'
export const SET_SHOOTTYPE_TABVALUE = 'SET_SHOOTTYPE_TABVALUE'
export const REMOVE_REQUESTED_SAMPLE_REQUEST_SUCCESS =
  'REMOVE_REQUESTED_SAMPLE_REQUEST_SUCCESS'
export const REMOVE_REQUESTED_SAMPLE_REQUEST_PENDING =
  'REMOVE_REQUESTED_SAMPLE_REQUEST_PENDING'
export const REMOVE_REQUESTED_SAMPLE_REQUEST_FAILURE =
  'REMOVE_REQUESTED_SAMPLE_REQUEST_FAILURE'
export const CASCADE_REQUEST_SAMPLE_QUANTITY = 'CASCADE_REQUEST_SAMPLE_QUANTITY'
export const SHOOT_TYPE_GET_EDITORS_PENDING = 'SHOOT_TYPE_GET_EDITORS_PENDING'
export const SHOOT_TYPE_GET_EDITORS_FAILURE = 'SHOOT_TYPE_GET_EDITORS_FAILURE'
export const SHOOT_TYPE_GET_EDITORS_SUCCESS = 'SHOOT_TYPE_GET_EDITORS_SUCCESS'
export const OPEN_PICK_LIST = 'OPEN_PICK_LIST'
