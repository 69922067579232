import React from 'react'
import * as propTypes from 'prop-types'
import withHOCs from 'util/withHocs'
import copy from 'copy-to-clipboard'
import { isEmpty } from 'lodash'
import { Typography, SwipeableDrawer, Grid, Button } from '@material-ui/core'
import DialogBox from '../../components/DialogBox/DialogBox'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import ItemEditCard from '../../components/EditCard/ItemEditCard'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import CircularPageLoader from '../../components/Loader/CircularPageLoader'
import { getTitle, makeTabTables } from '../../util/Table'
import { dataTabLabelsShipped } from '../../enums/tableTabs'
import { showNotification } from '../../store/notification/actionCreator'
import {
  paramsFromFilters,
  MYORDER_SELECTABLE_FILTERS,
  MYORDER_VENDOR_SELECTABLE_FILTERS,
  autocompleteSelectData,
} from '../AddFilters/constants'
import { getNotFoundBlock } from '../AddFilters/FilterUtils'
import AddFilters from '../AddFilters/AddFilters'
import {
  closeAlert,
  closeEdit,
  closePopup,
  duplicateSamples,
  cancelSample,
  getUserOrdersById,
  getBarcodes,
  openEdit,
  setCheckBox,
  setCheckBoxHeaderChange,
  showAlert,
  showPopup,
  updateSamples,
  setPage,
  setRowsPerPage,
  emptySelectedData,
} from '../../store/myOrders/actionCreator'
import { downloadSamples } from '../../store/searchInventory/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import NewSamplePopUp from './NewSamplePopUp'
import { getUserName } from '../../util/CommonUtils'
import { selectPageFilters } from '../../store/filters/selectors'
import { setFilters } from '../../store/filters/actionCreator'
import Add from '@material-ui/icons/Add'
import BottomBar from 'components/BottomBar'

const styles = (theme) => ({
  root: {
    margin: theme.spacing(3),
    overflowX: 'visible',
  },
  paperRoot: {
    overflowX: 'visible',
  },
  sectionMargin: {
    // margin: '0px 24px',
  },
  sectionMargin1: {
    margin: '24px 0px',
  },
  myOrderPageHeader: {
    fontSize: 'xx-large',
    textAlign: 'center',
    paddingBottom: '2%',
  },
  myOrderPagePagination: {
    overflowX: 'auto',
  },
  textField: {
    width: '50%',
  },
  table: {
    padding: '0px',
    paddingRight: '0px',
    paddingLeft: '0px',
    textAlign: 'left',
  },
  swipeableDrawer: {
    paddingRight: '1%',
    paddingLeft: '1%',
    paddingTop: '1%',
    overflow: 'hidden',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
    color: '#fff',
  },
  SwipeableDrawerElements: {
    textAlign: 'left',
    overflow: 'hidden',
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addDeptClassButton: {
    height: '35px',
    float: 'right',
  },
  clearAll: {
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  clearAllIcon: {
    width: '18px',
    height: '18px',
    margin: '8px',
  },
  applyButton: {
    width: '70px',
    height: '35px',
    float: 'right',
  },
  chipFilterTextField: {
    width: '100%',
  },
  chipRoot: {
    height: '26px',
  },
  addFilter: {
    margin: '0 0px 17px 0px',
    height: '48px',
    width: '250px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 2px 3px rgba(0,0,0,0.16), 0 2px 3px rgba(0,0,0,0.23)',
    },
  },
  addASample: {
    marginTop: '15px',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
})

class MyOrderPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      auth: {
        session: { userInfo = {} },
        isAuthorized = () => {},
      },
      filters = {},
    } = this.props
    this.state = {
      checkAll: true,
      text: [],
      rawText: '',
      newSamplePopUpIsVisible: false,
      selectableFilters: isAuthorized(['APP-PEG-MKT-VENDOR'])
        ? MYORDER_VENDOR_SELECTABLE_FILTERS
        : MYORDER_SELECTABLE_FILTERS,
      filters: filters,
      isFilterUpdated: false,
      sortParam: '',
      sortOrder: '',
    }

    this.userProfilename = getUserName(userInfo)
    const { lanId = '' } = userInfo
    const defaultStatusList = autocompleteSelectData['Status'].map(
      (obj) => obj.value
    )
    this.defaultParams = {
      status: defaultStatusList,
      ordered_by: [lanId],
    }
  }

  onSelected = (selectedData, selected) => {
    for (let i = 0; i < selectedData.length; i++) {
      if (selectedData[i].pegasusId === selected.pegasusId) {
        return true
      }
    }
    return false
  }

  componentDidMount = () => {
    // get Orders by user
    const {
      setHeaderTitleArray,
      setFilters = () => {},
      pageName = '',
    } = this.props
    setHeaderTitleArray([{ title: 'My Order History', link: `/${pageName}` }])
    setFilters({}, pageName)
  }

  componentWillUnmount() {
    const { setFilters, pageName = '' } = this.props
    const { filters = {} } = this.state
    const filterCopy = Object.assign({}, filters)
    if (!isEmpty(filterCopy)) {
      setFilters(filterCopy, pageName)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page: propsPage,
      rowsPerPage: propsRowsPerPage,
      getUserOrdersById = () => {},
      data: propsData = [],
      setPage = () => {},
    } = this.props
    const {
      filters: stateFilters = {},
      isFilterUpdated: stateIsFilterUpdated,
    } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    const { page: prevPropsPage, rowsPerPage: prevPropsRowsPerPage } = prevProps
    const { isFilterUpdated: prevStateIsFilterUpdated } = prevState
    if (
      propsPage > 1 &&
      (propsPage !== prevPropsPage || propsRowsPerPage !== prevPropsRowsPerPage)
    ) {
      getUserOrdersById(
        paramsFromFilters(
          filtersCopy,
          propsPage,
          propsRowsPerPage,
          this.userProfilename,
          this.defaultParams,
          this.state.sortParam,
          this.state.sortOrder
        )
      )
    }

    if (
      (propsPage === Number(1) &&
        (propsPage !== prevPropsPage ||
          propsRowsPerPage !== prevPropsRowsPerPage)) ||
      (!!stateIsFilterUpdated &&
        stateIsFilterUpdated !== prevStateIsFilterUpdated)
    ) {
      this.refreshTableWithNewData(filtersCopy, stateIsFilterUpdated)
      this.setState(
        {
          isFilterUpdated: false,
        },
        () => {
          setPage(1)
        }
      )
    }

    if (!propsData.length && propsPage !== 1) {
      setPage(1)
    }
  }

  handleEditChange = () => {
    const { onEditChange, selectedData } = this.props
    onEditChange(selectedData)
  }

  handleCancelItems = (event) => {
    const { showAlert } = this.props
    const message = 'Are you sure you want to cancel this order?'
    showAlert(message)
  }

  itemCardSaveClick = (editInformation, userId) => {
    const { page, rowsPerPage, saveEditInformation = () => {} } = this.props
    const { filters: stateFilters = {} } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    saveEditInformation(
      editInformation,
      userId,
      paramsFromFilters(
        filtersCopy,
        page,
        rowsPerPage,
        this.userProfilename,
        this.defaultParams
      ),
      this.itemCardSaveClickCB
    )
  }

  itemCardSaveClickCB = () => {
    const { emptySelectedData = () => {} } = this.props
    emptySelectedData()
  }

  toggleOpen = () => {
    const { numSelected } = this.props
    if (numSelected !== 0) {
      return true
    } else {
      return false
    }
  }

  openSampleForm = () => {
    this.setState({
      newSamplePopUpIsVisible: true,
    })
  }

  handleClose = () => {
    this.setState({
      newSamplePopUpIsVisible: false,
    })
  }

  duplicateSamples = (sample) => {
    const {
      duplicateSamples,
      getBarcodes,
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
    } = this.props
    let newSample = Object.assign({}, sample, {
      ...sample,
      userId: lanId,
    })
    duplicateSamples(newSample).then((samples) => {
      getBarcodes(samples).then((pdf) => {
        if (pdf !== undefined) {
          window.open(pdf).print()
        }
      })
    })
    this.setState({
      newSamplePopUpIsVisible: false,
    })
  }

  setFiltersUpdated = (
    isFilterUpdated = false,
    filters = {},
    sortParam,
    sortOrder
  ) => {
    const filtersCopy = Object.assign({}, filters)
    this.setState({
      isFilterUpdated: isFilterUpdated,
      filters: filtersCopy,
      sortParam: sortParam,
      sortOrder: sortOrder,
    })
  }

  refreshTableWithNewData = (filters = {}, stateIsFilterUpdated = false) => {
    const { page, rowsPerPage, getUserOrdersById = () => {} } = this.props
    const filtersCopy = Object.assign({}, filters)
    this.setState({
      filters: filtersCopy,
    })
    const filtersFromParams = paramsFromFilters(
      filtersCopy,
      stateIsFilterUpdated ? Number(1) : page,
      rowsPerPage,
      this.userProfilename,
      this.defaultParams,
      this.state.sortParam,
      this.state.sortOrder
    )
    getUserOrdersById(filtersFromParams)
  }

  handleAddSamples = (e) => {
    return this.props.history.push('/myOrders/addSample')
  }

  renderBottomBar = () => {
    const { classes = {}, selectedData = [] } = this.props
    const numSelected = selectedData?.length > 0 || Number(0)

    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={numSelected > 0}
        onOpen={() => null}
        onClose={() => null}
        showClearAll={numSelected > 0}
        actionType={'REST_ALL_MYORDER'}
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          <Grid container className={classes.swipeableDrawer} spacing={4}>
            <Grid item>
              <p className={classes.SwipeableDrawerElements}>
                Number of Selected Items: {numSelected}
              </p>
            </Grid>
            <Grid item>
              <Button
                className={classes.SwipeableDrawerElements}
                onClick={this.handleEditChange}
                variant="contained"
              >
                Edit Items
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.SwipeableDrawerElements}
                onClick={this.handleCancelItems}
                variant="contained"
              >
                Cancel Items
              </Button>
            </Grid>
          </Grid>
        </div>
      </BottomBar>
    )
  }

  render() {
    const {
      alertIsVisible,
      alertMessage,
      cancelAgree,
      checkBoxEnabled,
      classes = {},
      clickableColumn,
      closeAlert,
      closeEdit,
      closePopup,
      columnData,
      data,
      disableItems,
      downloadSamples,
      editColumnData,
      editIsVisible,
      numSelected,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      onEditChange,
      page,
      popUpDataKeyLabels,
      popupIsVisible,
      response,
      rowsPerPage,
      valueToCheckAgainst,
      selectedData,
      setPage,
      setRowsPerPage,
      showPopup,
      totalRowsCount,
      filterNFList = {},
      handleChipClick = () => {},
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
      pageName = '',
      popupItem = {},
      myorderUserRequestPending = false,
    } = this.props

    const {
      filters: stateFilters = {},
      newSamplePopUpIsVisible = false,
      selectableFilters = [],
    } = this.state

    const dataFieldKeys = columnData.map((column) => column.id)
    return (
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.headerContent}>
              <Typography variant="h4" gutterBottom>
                My Order History
              </Typography>
              <Button
                id="addASample"
                color="primary"
                style={{
                  marginRight: '10px',
                  fontSize: '15px',
                  height: '35px',
                }}
                type="button"
                variant="contained"
                onClick={(e) => this.handleAddSamples(e)}
              >
                <Add style={{ marginRight: '5px' }} /> Add Samples
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin}>
            <AddFilters
              selectableFilters={selectableFilters}
              refreshTableWithNewData={this.refreshTableWithNewData}
              setFiltersUpdated={this.setFiltersUpdated}
              filterCompType={'include_filters'}
              mergedFilters={stateFilters}
              pageName={pageName}
            />
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin}>
            {getNotFoundBlock(filterNFList, stateFilters, handleChipClick)}
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin1}>
            {myorderUserRequestPending ? (
              <CircularPageLoader open={myorderUserRequestPending} />
            ) : (
              <FlexibleTable
                cascadeFieldKeys={[]}
                checkBoxEnabled={checkBoxEnabled}
                columnData={columnData}
                clickableColumn={clickableColumn}
                data={data}
                uniqueIdentifierInData={'pegasusId'}
                dataFieldKeys={dataFieldKeys}
                disableItems={disableItems}
                editEnabled={false}
                enableSearch
                numSelected={numSelected}
                onCheckBoxChange={onCheckBoxChange}
                onCheckBoxHeaderChange={onCheckBoxHeaderChange}
                onEditChange={onEditChange}
                onSelected={this.onSelected}
                valueToCheckAgainst={valueToCheckAgainst}
                selected={selectedData}
                showPopup={showPopup}
                paginationAtApiLevel={true}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRowsCount={totalRowsCount}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                enableDownload={true}
                pageName={pageName}
                downloadFunction={() =>
                  downloadSamples(
                    paramsFromFilters(
                      stateFilters,
                      page,
                      rowsPerPage,
                      this.userProfilename,
                      this.defaultParams
                    )
                  )
                }
              />
            )}
          </Grid>
          <TabbedPopUp
            dataTabLabels={dataTabLabelsShipped}
            isVisible={popupIsVisible}
            data={makeTabTables(popupItem, classes, dataTabLabelsShipped)}
            onClose={closePopup}
            title={getTitle(popupItem)}
          />
          <ItemEditCard
            dataKeyLabels={popUpDataKeyLabels}
            handleSaveClick={this.itemCardSaveClick}
            userId={lanId}
            isVisible={editIsVisible}
            items={selectedData}
            onClose={closeEdit}
            title="Item Details"
            editColumnData={editColumnData}
            dataFieldKeys={dataFieldKeys}
            onChangeRowsPerPage={() => {}}
            onPopupChange={() => {}}
            cascadeFieldKeys={[
              'dueDate',
              'shipTo',
              'vendorNotes',
              'studioNotes',
              'project',
            ]}
            sourcePage={''}
          />
          <DialogBox
            isAlertVisible={alertIsVisible}
            message={alertMessage}
            onClose={closeAlert}
            response={response}
            items={selectedData}
            onAgree={cancelAgree}
            title="Cancel Order?"
            primaryButtonText="Yes, Cancel Order"
            secondaryButtonText="Nevermind"
          />
          <NewSamplePopUp
            isVisible={newSamplePopUpIsVisible}
            onClose={this.handleClose}
            duplicateSamples={this.duplicateSamples}
          />
          {this.renderBottomBar()}
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alertIsVisible: state.myOrder.alertIsVisible,
    alertMessage: state.myOrder.alertMessage,
    checkBoxEnabled: state.myOrder.checkBoxEnabled,
    clickableColumn: state.myOrder.clickableColumn,
    columnData: state.myOrder.columnData,
    data: state.myOrder.data,
    dataKeyLabels: state.myOrder.dataKeyLabels,
    disableItems: state.myOrder.disableItems,
    editColumnData: state.myOrder.editColumnData,
    editEnabled: state.myOrder.editEnabled,
    editItems: state.myOrder.editItems,
    editIsVisible: state.myOrder.editIsVisible,
    numSelected: state.myOrder.numSelected,
    onCheckBoxChange: state.myOrder.onCheckBoxChange,
    onCheckBoxHeaderChange: state.myOrder.onCheckBoxHeaderChange,
    onEditChange: state.myOrder.onEditChange,
    orderNumber: state.myOrder.orderNumber,
    popUpDataKeyLabels: state.myOrder.popUpDataKeyLabels,
    popupIsVisible: state.myOrder.popupIsVisible,
    popupItem: state.myOrder.item,
    response: state.myOrder.response,
    valueToCheckAgainst: state.myOrder.valueToCheckAgainst,
    selectedData: state.myOrder.selectedData,
    showAlert: state.myOrder.showAlert,
    showPopup: state.myOrder.showPopup,
    page: state.myOrder.page,
    rowsPerPage: state.myOrder.rowsPerPage,
    totalRowsCount: state.myOrder.totalRowsCount,
    filterNFList: state.myOrder.filterNFList,
    filters: selectPageFilters(state.myOrder.pageName)(state),
    pageName: state.myOrder.pageName,
    myorderUserRequestPending: state.myOrder.myorderUserRequestPending,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cancelAgree: (selectedData) => dispatch(cancelSample(selectedData)),
    closeAlert: () => dispatch(closeAlert()),
    closeEdit: () => dispatch(closeEdit()),
    closePopup: () => dispatch(closePopup()),
    duplicateSamples: (samples) => dispatch(duplicateSamples(samples)),
    downloadSamples: (params) => dispatch(downloadSamples(params)),
    getUserOrdersById: (params) => dispatch(getUserOrdersById(params)),
    getBarcodes: (samples) => dispatch(getBarcodes(samples)),
    onCheckBoxChange: (selectedData) => dispatch(setCheckBox(selectedData)),
    onCheckBoxHeaderChange: (selectedData) =>
      dispatch(setCheckBoxHeaderChange(selectedData)),
    onEditChange: (event) => dispatch(openEdit(event)),
    saveEditInformation: (
      itemInformation,
      userId,
      params,
      callback = () => {}
    ) => dispatch(updateSamples(itemInformation, userId, params, callback)),
    showAlert: (message) => dispatch(showAlert(message)),
    showNotification: (isShown, message) =>
      dispatch(showNotification(isShown, message)),
    showPopup: (item) => dispatch(showPopup(item)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    handleChipClick: (chip) => {
      copy(chip.target.innerText)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    setFilters: (filters, pageName) => dispatch(setFilters(filters, pageName)),
    emptySelectedData: () => dispatch(emptySelectedData()),
  }
}

MyOrderPage.propTypes = {
  checkBoxEnabled: propTypes.bool,
  clickableColumn: propTypes.array,
  columnData: propTypes.array,
  data: propTypes.array,
  disableItems: propTypes.bool,
  editColumnData: propTypes.array,
  editEnabled: propTypes.bool,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  orderNumber: propTypes.number,
  valueToCheckAgainst: propTypes.array,
  filterNFList: propTypes.object,
  filters: propTypes.object,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(MyOrderPage)
