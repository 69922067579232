import { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid'

import TabbedPopUp from 'components/PopUp/TabbedPopUp'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import FlexibleTable from 'components/EnhancedTable/FlexibleTable'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { TableCellthemeNew } from 'config/themeConfig'

import { dataTabLabelsShipped } from 'enums/tableTabs'
import { makeTabTables, getTitle } from 'util/Table'

import { ReportProblemOutlined } from '@mui/icons-material'
import { scenicRoomResponseToScenicRoom } from 'mappers/scenicRoomMapper'
import { Divider } from '@material-ui/core'

const UnavailableScenic = ({
  unavailableScenic,
  unavailableSampleColumnData,
  clickableColumn,
  downloadConflict = () => {},
  classes = {},
  fetchSenic,
  clearSearch,
  setIsAddSamplesSliderOpen,
  scenicRoomId,
}) => {
  const [roomConflicts, setRoomConflicts] = useState([])
  const [overlaySelectedData, setOverlaySelectedData] = useState([])
  const [invalidIds, setInvalidIds]= useState({})
  const { allUsers=[] } = useSelector(
    ({ scenicRoom: { allUsers = [] } }) => {
      return { allUsers }
    }
  ) 

  useEffect(() => {
    const roomConflictsData = unavailableScenic.filter((obj) =>
      obj.reason.includes('already present in other room')
    )
    const invalidData = unavailableScenic.filter((obj) => 
      obj.reason.includes('Invalid Id')
    )
    const invalidIds = invalidData.map((obj)=> obj.invalidIds)
    const roomConflicts = roomConflictsData.map((obj) => {
      const conflictRoomData = obj?.scenicRoomResponse
        ? scenicRoomResponseToScenicRoom(obj?.scenicRoomResponse[0], allUsers)
        : []
      return { ...conflictRoomData, pegasusId: `PS${obj.pgId}` }
    })
    setInvalidIds(invalidIds)
    setRoomConflicts(roomConflicts)
    setOverlaySelectedData([])
  }, [unavailableScenic])

  const forceAddScenic = () => {
    const pgIds = roomConflicts.map((conflict) => conflict.pegasusId)
    fetchSenic(pgIds, null, scenicRoomId, false)
    closeFlyout()
  }

  const closeFlyout = () => {
    clearSearch()
    setIsAddSamplesSliderOpen(false)
  }

  const onOverlaySelected = (selectedData, selected) => {
    const { pegasusId: selectedPegId = '' } = selected
    const isSelected = selectedData.some(
      (pegasusId) => pegasusId === selectedPegId
    )
    return isSelected
  }

  const onOverlayCheckBoxChange = (selectedData) => {
    const selectedIndex = overlaySelectedData.find(
      (obj) => obj === selectedData.pegasusId
    )
    let newSelected = []
    if (selectedIndex === -1 || !selectedIndex) {
      newSelected = newSelected.concat(
        overlaySelectedData,
        selectedData.pegasusId
      )
    } else {
      newSelected = overlaySelectedData.filter(
        (item) => item !== selectedData.pegasusId
      )
    }
    setOverlaySelectedData(newSelected)
  }

  const handleRemoveSample = () => {
    const updatedSelection = roomConflicts.reduce((acc, obj) => {
      if (
        !overlaySelectedData.some(
          (selectedData) => selectedData === obj.pegasusId
        )
      ) {
        acc.push(obj)
      }
      return acc
    }, [])
    setRoomConflicts(updatedSelection)
  }

  const onOverlayCheckBoxHeadChange = (selectedData=[]) => {
    let newSelected =[]
    if (selectedData.length > overlaySelectedData.length) {
      newSelected = selectedData.map((it) => it.pegasusId)
    } else if (selectedData.length === overlaySelectedData.length) {
      newSelected = []
    }
    setOverlaySelectedData(newSelected)
  }

  const FlexibleTableNew = withStyles((theme) => ({
    root: {
      boxShadow: 'unset !important',
    },
    container: {
      maxHeight: 'unset !important',
    },
    header: {
      backgroundColor: 'unset',
      fontWeight: 700,
    },
  }))(FlexibleTable)
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}></Grid>
      {roomConflicts.length > 0 && (
        <>
          <Grid item xs={12}>
            <span
              style={{
                margin: '0 10px 0 10px',
                color: '#FF7A00',
                float: 'left',
              }}
            >
              <ReportProblemOutlined />
            </span>
            <div>
              Scenic Asset(s) <b>already included in other rooms </b> are listed
              below. You can remove them or if they need to be in more than one
              room go ahead and add them to this room too!
            </div>
          </Grid>
          <Grid item xs={12}>
            <MuiThemeProvider theme={TableCellthemeNew}>
              <FlexibleTableNew
                cascadeFieldKeys={[]}
                data={roomConflicts}
                disableItems
                disableTablePagination
                disableTableSorting
                columnData={unavailableSampleColumnData}
                checkBoxEnabled={true}
                disableItemCount
                showPopup={() => {}}
                clickableColumn={clickableColumn}
                onCheckBoxChange={onOverlayCheckBoxChange}
                onSelected={onOverlaySelected}
                selected={overlaySelectedData}
                enableToolbar={false}
                enableSpacer={false}
                cellTheme={TableCellthemeNew}
                enableMultiSearch
                numSelected={overlaySelectedData.length}
                onCheckBoxHeaderChange={onOverlayCheckBoxHeadChange}
              />
            </MuiThemeProvider>
            <Divider />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Button
          className={classes.defaultButton}
          color="primary"
          onClick={handleRemoveSample}
          variant="outlined"
          style={{
            marginLeft: '25px',
          }}
        >
          Remove
        </Button>
        <Divider />
        <div className={classes.customButton}>
          <Button
            id="handleCancel"
            data-id="CANCEL"
            data-cy="cancel"
            className={classes.defaultButton}
            variant="outlined"
            color="primary"
            // disabled={this.disableCreateButton()}
            onClick={closeFlyout}
          >
            Close
          </Button>
          <Button
            id="handleUpdate"
            data-id="UPDATE"
            data-cy="projectSave"
            className={classes.defaultButton}
            variant="contained"
            color="primary"
            disabled={!(roomConflicts.length > 0 && overlaySelectedData.length > 0)}
            onClick={forceAddScenic}
          >
            ADD SCENIC ASSETS
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const showUnAvailSamplesPopup = (unavailableScenic = []) => {
  return (
    unavailableScenic.length > 0 &&
    unavailableScenic.some(
      (obj) =>
        [
          'Scenic asset is already present in other room',
          // 'scenic_room_conflict', // TODO use obj.reason
          // 'status_conflict',
        ].indexOf(obj.reason) !== -1
    )
  )
}

const downloadConflictSampleCSV = (
  scenicRoomId = '',
  unavailableScenic = [],
  auth = {},
  downloadConflictSamples
) => {
  const {
    session: {
      userInfo: { lanId = '' },
    },
  } = auth
  const pgIds = [
    ...new Set(
      unavailableScenic.map((error = {}) => {
        const {
          resource: { pegasusId = '' },
        } = error
        return pegasusId.toString().substring(2, pegasusId.length)
      })
    ),
  ]
  downloadConflictSamples(pgIds, lanId, scenicRoomId)
}

const UnavailableScenicRoomItems = ({
  fetchSenic,
  clearSearch,
  setIsAddSamplesSliderOpen,
  classes,
  popupIsVisible,
  popupItem,
  shipmentData,
  closePopup,
  unavailableSamplePopUpIsVisible,
  unavailableScenic = [],
  unavailableSampleColumnData,
  unavailableSampleLocStatusData,
  updateSampleFieldsData,
  clickableColumn,

  scenicRoomId = '',
  auth = {},
  downloadConflictSamples,
}) => {
  const downloadConflict = () =>
    downloadConflictSampleCSV(
      scenicRoomId,
      unavailableScenic,
      auth,
      downloadConflictSamples
    )

  return (
    <>
      <TabbedPopUp
        dataTabLabels={dataTabLabelsShipped}
        isVisible={popupIsVisible}
        data={makeTabTables(
          popupItem,
          classes,
          dataTabLabelsShipped,
          updateSampleFieldsData,
          shipmentData
        )}
        onClose={closePopup}
        title={getTitle(popupItem)}
        fullWidth={true}
      />
      {showUnAvailSamplesPopup(unavailableScenic) ? (
        <UnavailableScenic
          unavailableScenic={unavailableScenic}
          unavailableSampleColumnData={unavailableSampleColumnData}
          clickableColumn={clickableColumn}
          downloadConflict={downloadConflict}
          classes={classes}
          fetchSenic={fetchSenic}
          clearSearch={clearSearch}
          setIsAddSamplesSliderOpen={setIsAddSamplesSliderOpen}
          scenicRoomId={scenicRoomId}
        />
      ) : (
        <></>
      )}
    </>
  )
}

const styles = () => ({
  customButton: {
    display: 'inline',
    marginLeft: '55%',
  },
  defaultButton: {
    maxWidth: '300px',
    width: 'auto',
  },
})

export default withStyles(styles)(UnavailableScenicRoomItems)
