import * as types from './actionType'
import { isEmpty, findIndex } from 'lodash'

export const initialState = {
  checkBoxEnabled: true,
  clickableColumn: ['description', 'size', 'departmentName', 'className'],
  columnData: [
    {
      id: 'location_id',
      numeric: true,
      disablePadding: false,
      label: 'Location ID',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'aisle',
      numeric: false,
      disablePadding: false,
      label: 'Level 1',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'section',
      numeric: false,
      disablePadding: false,
      label: 'Level 2',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'shelf',
      numeric: false,
      disablePadding: false,
      label: 'Level 3',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'container',
      numeric: false,
      disablePadding: false,
      label: 'Level 4',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'dateAdded',
      numeric: false,
      disablePadding: false,
      label: 'Date Added',
      textAlign: 'right',
      type: '',
    },
  ],
  data: [],
  allAisles: [],
  allSections: [],
  allShelves: [],
  allContainer: [],
  resetFlag: false,
  fetchAislesRequestFailure: false,
  fetchAislesRequestPending: false,
  fetchAislesRequestSuccess: false,
  fetchSectionsRequestFailure: false,
  fetchSectionsRequestPending: false,
  fetchSectionsRequestSuccess: false,
  fetchShelvesRequestFailure: false,
  fetchShelvesRequestPending: false,
  fetchSShelvesRequestSuccess: false,
  fetchContainerRequestFailure: false,
  fetchContainerRequestPending: false,
  fetchContainerRequestSuccess: false,
  renameLocationFailure: false,
  renameLocationSuccess: false,
  renameLocationPending: false,
  deleteLocationsFailure: false,
  deleteLocationsSuccess: false,
  errorLocations: [],
  detailDeleteErrors: {},
  isVisible: false,
  item: {},
  numSelected: Number(0),
  onCheckBoxChange: () => {},
  onCheckBoxHeaderChange: () => {},
  onClick: () => {},
  selectedData: [],
  totalRowsCount: 100,
  pagesCount: 1,
  rowsPerPage: 100,
  page: 1,
  // alertIsVisible: false,
  // alertMessage: '',
}

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_AISLES_REQUEST_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        fetchAislesRequestPending: action.payload.pending,
      })
    case types.FETCH_AISLES_SUCCESS: // Request has succeeded and we can set the data state
      return Object.assign({}, state, {
        ...state,
        allAisles: action.payload.data.data.aisles,
        allSections: [],
        allShelves: [],
        allContainer: [],
        resetFlag: false,
      })
    case types.FETCH_AISLES_FAILURE: // request has failed with an error message to display
      return Object.assign({}, state, {
        ...state,
        fetchDataError: action.payload.error,
        fetchAislesRequestFailure: true,
      })
    case types.FETCH_SECTIONS_REQUEST_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        fetchSectionsRequestPending: action.payload.pending,
      })
    case types.FETCH_SECTIONS_SUCCESS: // Request has succeeded and we can set the data state
      return Object.assign({}, state, {
        ...state,
        allSections: action.payload.data.data.sections,
        allShelves: [],
        allContainer: [],
      })
    case types.FETCH_SECTIONS_FAILURE: // request has failed with an error message to display
      return Object.assign({}, state, {
        ...state,
        fetchDataError: action.payload.error,
        fetchSectionsRequestFailure: true,
      })
    case types.FETCH_SHELVES_REQUEST_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        fetchShelvesRequestPending: action.payload.pending,
      })
    case types.FETCH_SHELVES_SUCCESS: // Request has succeeded and we can set the data state
      return Object.assign({}, state, {
        ...state,
        allShelves: action.payload.data.data.shelves,
        allContainer: [],
      })
    case types.FETCH_SHELVES_FAILURE: // request has failed with an error message to display
      return Object.assign({}, state, {
        ...state,
        fetchDataError: action.payload.error,
        fetchShelvesRequestFailure: true,
      })
    case types.FETCH_CONTAINER_REQUEST_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        fetchContainerRequestPending: action.payload.pending,
      })
    case types.FETCH_CONTAINER_SUCCESS: // Request has succeeded and we can set the data state
      return Object.assign({}, state, {
        ...state,
        allContainer: action.payload.data.data.containers,
      })
    case types.FETCH_CONTAINER_FAILURE: // request has failed with an error message to display
      return Object.assign({}, state, {
        ...state,
        fetchDataError: action.payload.error,
        fetchContainerRequestFailure: true,
      })
    case types.FETCH_FILTER_REQUEST_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        fetchFilterRequestPending: action.payload.pending,
      })
    case types.MANAGE_LOCATIONS_SET_PAGE: {
      return Object.assign({}, state, {
        ...state,
        page: action.payload.page,
      })
    }
    case types.MANAGE_LOCATIONS_SET_ROWS_PER_PAGE: {
      return Object.assign({}, state, {
        ...state,
        rowsPerPage: action.payload.rowsPerPage,
      })
    }
    case types.FETCH_FILTER_SUCCESS: {
      // Request has succeeded and we can set the data state
      const {
        payload: {
          data: {
            locations = [],
            total_count = Number(0),
            pages_count = Number(0),
          },
          filteredLocation = '',
        },
      } = action

      const locationsCopy = !isEmpty(filteredLocation)
        ? locations.filter((obj) => obj.type === 'L')
        : [...locations]

      return Object.assign({}, state, {
        ...state,
        resetFlag: false,
        data: locationsCopy,
        totalRowsCount: total_count,
        pagesCount: pages_count,
      })
    }
    case types.FETCH_FILTER_FAILURE: // request has failed with an error message to display
      return Object.assign({}, state, {
        ...state,
        fetchDataError: action.payload.error,
        fetchFilterRequestFailure: true,
      })
    case types.RESET_TO_START_OVER: {
      return Object.assign({}, state, {
        resetFlag: true,
      })
    }
    case types.MANAGE_LOCATIONS_SET_CHECKBOX: {
      const { selectedData = {} } = state
      const {
        payload: { selected = {} },
      } = action
      const { location_id: selectedLocId = '' } = selected
      const selectedIndex = findIndex(selectedData, function (obj = {}) {
        return obj.location_id === selectedLocId
      })
      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          state.selectedData,
          action.payload.selected
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selectedData.slice(1))
      } else if (selectedIndex === state.selectedData.length - 1) {
        newSelected = newSelected.concat(state.selectedData.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selectedData.slice(0, selectedIndex),
          state.selectedData.slice(selectedIndex + 1)
        )
      }

      return Object.assign({}, state, {
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.MANAGE_LOCATIONS_SET_CHECKBOX_HEADER: {
      const { selectedData = [] } = state
      const {
        payload: { selected: selectedIds = {} },
      } = action
      const selectedDiffCount = selectedData.filter(
        (i) =>
          findIndex(selectedIds, function (obj) {
            return obj.location_id === i.location_id
          }) !== -1
      ).length

      var newDataObj = [].concat.apply(selectedData, selectedIds)

      let newSelected = []
      if (selectedDiffCount === 0) {
        newSelected = newDataObj
      } else {
        newSelected = selectedData.filter(
          (i) =>
            findIndex(selectedIds, function (obj) {
              return obj.location_id === i.location_id
            }) === -1
        )
      }

      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.RESET_TO_INIT_STATE_ML: {
      return Object.assign({}, state, {
        renameLocationFailure: false,
        renameLocationPending: false,
        deleteLocationsFailure: false,
        deleteLocationsSuccess: false,
        errorLocations: [],
        detailDeleteErrors: {},
      })
    }
    case types.MANAGE_LOCATIONS_EDIT_SET_FIELD_VALUE: {
      const { data: stateData = [] } = state
      const {
        payload: {
          field: payloadField = '',
          location_id: payloadLocationId = '',
          value: payloadValue = '',
        },
      } = action
      return Object.assign({}, state, {
        data: stateData.map((i) => ({
          ...i,
          [payloadField]:
            i.location_id === payloadLocationId
              ? payloadValue
              : i[payloadField],
        })),
      })
    }
    case types.DELETE_LOCATIONS_FAILURE: {
      const {
        payload: { errorLocations = [], detailDeleteErrors = {} },
      } = action
      return Object.assign({}, state, {
        deleteLocationsFailure: true,
        errorLocations: errorLocations,
        detailDeleteErrors: detailDeleteErrors,
      })
    }
    case types.DELETE_LOCATIONS_SUCCESS: {
      return Object.assign({}, state, {
        deleteLocationsSuccess: action.payload.status,
      })
    }
    case types.RENAME_LOCATION_SUCCESS: {
      const {
        payload: { data: payloadData = {} },
      } = action
      const { data: stateData = [] } = state
      const stateDataCopy = [...stateData]
      const newObj = stateDataCopy.map((obj) => {
        if (obj.location_id === payloadData.location_id) {
          // Copy the object before mutating
          return Object.assign({}, obj, payloadData)
        }
        return obj
      })
      return Object.assign({}, state, {
        data: newObj,
        renameLocationFailure: false,
      })
    }
    case types.RENAME_LOCATION_SUCCESS_STATUS: {
      return Object.assign({}, state, {
        renameLocationSuccess: true,
      })
    }
    case types.RENAME_LOCATION_FAILURE: {
      return Object.assign({}, state, {
        renameLocationFailure: true,
      })
    }
    case types.RENAME_LOCATION_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        renameLocationPending: action.payload.pending,
      })
    case types.MANAGE_LOCATIONS_SET_SORTED_DATA: {
      return Object.assign({}, state, {
        ...state,
        data: action.payload.data,
      })
    }
    // case types.SHOW_ML_DELETE_ALERT: {
    //   const newState = {
    //     ...state,
    //     alertIsVisible: true,
    //     alertMessage: action.payload.message,
    //   }
    //   return newState
    // }
    default:
      return state
  }
}
