import React from 'react'
import { CSVLink } from 'react-csv'
import { isEqual, find, isEmpty } from 'lodash'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { Grid, Button, Paper, Typography } from '@material-ui/core'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import DialogBox from '../../components/DialogBox/DialogBox'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import ReplayIcon from '@mui/icons-material/Replay'
import DownloadIcon from '@mui/icons-material/Download'
import './OrderSamples.css'
import {
  DATE_FORMAT,
  convertTableDateFormat,
  CSV_DATE_FORMAT,
} from '../../util/CommonUtils'
import withHOCs from 'util/withHocs'

import OrderHome from '../SearchPage/OrderHome'
import OrderPage from '../OrderPage/OrderPage'
import { getProjects } from '../../store/order/actionCreator'
import { getCollections } from '../../store/collections/actionCreator'
import { getCheckInLocations } from '../../store/settings/actionCreator'
import {
  raiseSamplesOrder,
  resetOrderSamplesState,
} from '../../store/orderSamples/actionCreator'
import { unsetSelectedData } from '../../store/autoOrderSamples/actionCreator'
import { getCurrentTabSelectedData } from '../../store/order/selectors'
import ShowIf from 'components/ShowIf'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    minWidth: '1200px',
    width: 'auto',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  exportCsvButton: {
    maxWidth: '250px',
    width: 'auto',
    textDecoration: 'unset !important',
    color: '#366CD9 !important',
  },
  typography: {
    padding: 10,
  },
  typographyGreen: {
    color: 'green',
    fontSize: 18,
    textAlign: 'center',
  },
  typographyGreenSub: {
    color: 'green',
    fontSize: 10,
  },
  orderButton: {
    marginTop: 12,
    paddingRight: 15,
  },
})
const stepperLabels = [
  'Select items for sample order',
  'Review sample order details',
  'Place sample order',
  // 'Order Confirmation',
]
class OrderSamples extends React.Component {
  constructor(props) {
    super(props)
    const { location = {} } = props
    const { pathname = '' } = location
    this.state = {
      activeStep: pathname === '/OrderSamples' ? Number(1) : Number(0),
      disableReviewOrderButton: true,
      reviewData: [],
      updateProjects: false,
      orderStatus: '',
      triggerOrderItems: false,
    }
  }
  componentDidMount = () => {
    const { getProjects, getCollections, getCheckInLocations } = this.props
    const { location = {} } = this.props
    const { pathname = '' } = location
    if (pathname === '/OrderSamples') {
      this.setState({
        activeStep: Number(1),
      })
    }
    this.setState(() => {
      getProjects()
      getCollections()
      getCheckInLocations()
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { activeStep: prevActiveStep = Number(1) } = prevState
    const { activeStep = Number(1) } = this.state
    //Number 1 change
    if (!isEqual(activeStep, prevActiveStep) && activeStep === Number(1)) {
      this.setState({
        updateProjects: true,
      })
    }
  }
  componentWillUnmount() {
    const { resetOrderSamplesState = () => {} } = this.props
    resetOrderSamplesState()
  }
  resetUpdateProjects = () => {
    const { updateProjects } = this.state
    this.setState({
      updateProjects: !updateProjects,
    })
  }
  reviewButtonOnClick = (stepType = 'forward') => {
    const { reviewData = [], activeStep = Number(1) } = this.state
    const { raiseSamplesOrder = () => {}, tabValue = '' } = this.props
    const { orderSamplesFrom = '' } = this.props
    let activeStepCopy = Number(activeStep)
    if (
      stepType === 'forward' &&
      activeStepCopy <= stepperLabels.length &&
      activeStepCopy >= Number(0)
    ) {
      activeStepCopy = Number(activeStepCopy) + Number(1)
      this.setState(
        {
          activeStep: activeStepCopy,
        },
        () => {
          if (activeStepCopy === stepperLabels.length) {
            const tabValueEdited = orderSamplesFrom.includes('/projects/')
              ? 'SEARCH'
              : tabValue
            raiseSamplesOrder(reviewData, tabValueEdited)
          }
        }
      )
    }
    if (stepType === 'backward') {
      activeStepCopy =
        Number(activeStep) === Number(2)
          ? Number(activeStepCopy) - Number(1)
          : activeStep
      let updateObj = {}
      
      if (
        activeStepCopy === Number(0) &&
        orderSamplesFrom.includes('/projects/')
      ) {
        updateObj = Object.assign({}, { orderStatus: 'pending' })
      } else {
        let orderStatus = ''
        if (Number(activeStep) === Number(1)) {
          orderStatus = 'pending'
        }
        if (Number(activeStep) === Number(3)) {
          orderStatus = 'completed'
        }
        updateObj = Object.assign(
          {},
          {
            orderStatus: orderStatus,
            activeStep: activeStepCopy,
          }
        )
      }
      this.setState(updateObj)
      if(isEmpty(reviewData)){
        this.confirmDiscard()
      }
    }
  }

  triggerOrderItemsClick = (triggerStatus) => {
    this.setState({
      triggerOrderItems: triggerStatus,
    })
  }

  renderPageControls = () => {
    const { activeStep = Number(1), disableReviewOrderButton } = this.state
    const { classes = {} } = this.props
    switch (activeStep) {
      case Number(0): {
        return (
          <Button
            id="selectItemsButton"
            color="primary"
            disabled={this.disableOrderButton()}
            onClick={async (event) => {
              await this.triggerOrderItemsClick(true)
              await this.reviewButtonOnClick('forward')
            }}
            variant="contained"
            className={classes.defaultButton}
          >
            {'order'}
          </Button>
        )
      }
      case Number(1): {
        return (
          <Button
            id="orderReviewButton"
            color="primary"
            disabled={disableReviewOrderButton}
            onClick={() => {
              this.reviewButtonOnClick('forward')
            }}
            variant="contained"
            className={classes.defaultButton}
          >
            {'review order details'}
          </Button>
        )
      }
      case Number(2): {
        return (
          <Button
            color="primary"
            id="submitOrderButton"
            onClick={() => {
              this.reviewButtonOnClick('forward')
            }}
            variant="contained"
            className={classes.defaultButton}
          >
            {'Place Order'}
          </Button>
        )
      }
      case Number(activeStep === stepperLabels.length): {
        return (
          <Button
            color="primary"
            id="completeOrder"
            onClick={this.onOrderComplete}
            variant="text"
            className={classes.defaultButton}
          >
            {'Order Again'}
          </Button>
        )
      }
      default:
        break
    }
  }

  disableOrderButton = () => {
    return this.props?.orderHomeSelectedData?.length === 0
  }

  updateROrderStatus = (buttonStatus, reviewData = []) => {
    this.setState({
      disableReviewOrderButton: buttonStatus,
      reviewData: reviewData,
    })
  }

  generateTableMessage = (completedOrderNumber = '') => {
    const { completedOrderStatus = '' } = this.props 
    return completedOrderNumber ? (
      <Grid
        item
        container
        justify={'center'}
        alignItems={'center'}
        direction={'row'}
        style={{ marginTop: 10, backgroundColor: '#fff' }}
        xs
      >
        <ShowIf condition={completedOrderStatus === 'success'}>
          <Alert
            severity="success"
            style={{ border: '2px solid rgb(30, 70, 32)' }}
          >
            <AlertTitle>
              {
                <strong>
                  {`${'Order: ' + completedOrderNumber + ' has been placed'}`}
                </strong>
              }
            </AlertTitle>
            {
              'Thank you for your sample order. Email communication will be sent to your vendor(s) and copied to you'
            }
          </Alert>
        </ShowIf>
      </Grid>
    ) : (
      <Grid
        item
        container
        justify={'center'}
        alignItems={'center'}
        direction={'row'}
        style={{ marginTop: 10, backgroundColor: '#fff' }}
        xs
      >
        <ShowIf condition={completedOrderStatus === 'fail'}>
          <Alert
            severity="error"
            style={{ border: '2px solid #cc0000'}}
          >
            <AlertTitle>
              {
                <strong>
                  {`${'Order: Failed'}`}
                </strong>
              }
            </AlertTitle>
            {
              'Encountered an error while trying to submit an order request.'
            }
          </Alert>
        </ShowIf>
      </Grid>
    )
  }

  renderStepperPages = () => {
    const {
      columnData = [],
      completeOrderColumnData = [],
      completedOrderData = [],
      completedOrderNumber = '',
      locations = [],
    } = this.props
    const {
      activeStep = Number(1),
      reviewData = [],
      updateProjects,
      triggerOrderItems,
    } = this.state
    const dataFieldKeys = columnData.map((column) => column.id)
    const reviewDataCopy = reviewData.map((dataObj) => {
      return Object.assign({}, dataObj, {
        dueDate: convertTableDateFormat(dataObj.dueDate, DATE_FORMAT),
      })
    })
    const dataFieldKeysOrdered = completeOrderColumnData.map(
      (column) => column.id
    )
    const reviewDataCopyOrdered = completedOrderData.map((dataObj) => {
      const { shipTo: objShipTo = '' } = dataObj
      const currentShipTo = find(locations, { building: objShipTo }) || {}
      return Object.assign({}, dataObj, {
        dueDate: convertTableDateFormat(dataObj.dueDate, DATE_FORMAT),
        address: currentShipTo['address'] || objShipTo || '',
      })
    })
    switch (activeStep) {
      case Number(0): {
        return (
          <OrderHome
            triggerOrderItems={triggerOrderItems}
            triggerOrderItemsClick={this.triggerOrderItemsClick}
          />
        )
      }
      case Number(1): {
        return (
          <OrderPage
            getROrderStatus={this.updateROrderStatus}
            activeStep={activeStep}
            updateProjects={updateProjects}
            resetUpdateProjects={this.resetUpdateProjects}
          />
        )
      }
      case Number(2): {
        return (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FlexibleTable
                cascadeFieldKeys={[]}
                columnData={columnData}
                data={reviewDataCopy}
                dataFieldKeys={dataFieldKeys}
                onClick={() => {}}
                onPopupChange={() => {}}
                showPopup={() => {}}
              />
            </Grid>
          </Grid>
        )
      }
      case Number(3): {
        return (
          <Grid container>
            <Grid item xs={12}>
              <FlexibleTable
                cascadeFieldKeys={[]}
                columnData={completeOrderColumnData}
                data={reviewDataCopyOrdered}
                dataFieldKeys={dataFieldKeysOrdered}
                onClick={() => {}}
                onPopupChange={() => {}}
                showPopup={() => {}}
                tableMessage={this.generateTableMessage(completedOrderNumber)}
              />
            </Grid>
          </Grid>
        )
      }
      default:
        break
    }
  }
  confirmDiscardCallback = () => {
    const { orderSamplesFrom = '', da } = this.props
    const { activeStep = '' } = this.state

    const pathname = ['READYTOORDER', '/'].includes(orderSamplesFrom)
      ? '/'
      : orderSamplesFrom
    orderSamplesFrom.includes('/projects/')
      ? this.props.history.push(pathname)
      : this.setState({
          activeStep: Number(activeStep) - Number(1),
        })
  }
  confirmDiscard = () => {
    const { confirmDiscardCallback = () => {} } = this
    this.setState(
      {
        orderStatus: '',
      },
      () => {
        confirmDiscardCallback()
      }
    )
  }
  closeConfirmModal = () => {
    this.setState({
      orderStatus: '',
    })
  }
  onOrderComplete = () => {
    const { orderSamplesFrom = '', unsetSelectedData = () => {} } = this.props
    const pathname = ['READYTOORDER', '/'].includes(orderSamplesFrom)
      ? '/'
      : orderSamplesFrom || '/'
    unsetSelectedData()
    orderSamplesFrom.includes('/projects/')
      ? this.props.history.push(pathname)
      : this.setState({
          activeStep: Number(0),
        })
  }
  render() {
    const {
      activeStep = Number(1),
      orderStatus = '',
      reviewData = [],
    } = this.state
    const {
      classes = {},
      completedOrderData = [],
      completeOrderColumnData = [],
      completedOrderStatus = '',
    } = this.props
    const csvHeaders = completeOrderColumnData.map((column) => ({
      label: column.label,
      key: column.id,
    }))
    const csvData = completedOrderData.map((i) => ({
      ...i,
      dueDate: convertTableDateFormat(i.dueDate, CSV_DATE_FORMAT).toString(),
    }))
    const routerLeaveMessage = `${'You have not completed the steps to place an order. The information you entered will not be saved and you will have to start your order again.'}`
    return (
      <Grid container>
        <Grid item xs>
          <Paper style={{ margin: '20px 0px' }}>
            <Grid item container xs>
              <Typography variant="h6" className={classes.typography}>
                {'Order Samples'}
              </Typography>
            </Grid>
            <Grid item container>
              <Grid item xs>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  nonLinear
                  sx={{ border: '10px' }}
                >
                  {stepperLabels.map((label, index) => {
                    const completed = activeStep > index
                    return (
                      <Step key={label}>
                        <StepLabel completed={completed}>{label}</StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
              </Grid>
              <Grid item className={classes.orderButton}>
                <Grid item container alignItems={'center'}>
                  {activeStep !== Number(0) && (
                    <Grid item>
                      {activeStep < Number(3) ? (
                        <Button
                          color="primary"
                          id="backward"
                          onClick={() => {
                            this.reviewButtonOnClick('backward')
                          }}
                          variant="outlined"
                          className={classes.defaultButton}
                        >
                          BACK
                        </Button>
                      ) : (
                        <>
                          <ShowIf condition={completedOrderStatus === 'success'}>
                            <Button
                              color="primary"
                              id="backward"
                              variant="text"
                              className={classes.defaultButton}
                              startIcon={<DownloadIcon />}
                            >
                              <CSVLink
                                id={'downloadCSV'}
                                className={classes.exportCsvButton}
                                data={csvData}
                                headers={csvHeaders}
                                filename="orderConfirmation.csv"
                              >
                                Download CSV
                              </CSVLink>
                            </Button>
                          </ShowIf>
                          <Button
                            color="primary"
                            id="completeOrder"
                            onClick={this.onOrderComplete}
                            variant="text"
                            className={classes.defaultButton}
                            startIcon={<ReplayIcon />}
                          >
                            {'Order More'}
                          </Button>
                        </>
                      )}
                    </Grid>
                  )}
                  <Grid item>{this.renderPageControls()}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {this.renderStepperPages()}
        </Grid>
        <DialogBox
          isAlertVisible={orderStatus === 'pending'}
          message={routerLeaveMessage}
          onClose={this.closeConfirmModal}
          response={true}
          onAgree={this.confirmDiscard}
          title={'Discard Order?'}
          closeButtonText={'OK'}
          primaryButtonText={'DISCARD'}
        />
      </Grid>
    )
  }
}
function mapStateToProps(state) {
  return {
    columnData: state.orderConfirmation.reviewOrderColumnData,
    completeOrderColumnData: state.orderSamples.columnData,
    completedOrderData: state.orderSamples.data,
    completedOrderStatus: state.orderSamples.orderStatus,
    completedOrderNumber: state.orderSamples.orderNumber,
    tabValue: state.order.tabValue,
    orderSamplesFrom: state.order.orderSamplesFrom,
    locations: state.settings.checkInLocations,
    orderHomeSelectedData: getCurrentTabSelectedData()(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCollections: () => dispatch(getCollections()),
    getProjects: () => dispatch(getProjects()),
    getCheckInLocations: () => dispatch(getCheckInLocations()),
    raiseSamplesOrder: (samples = [], tabValue = '') =>
      dispatch(raiseSamplesOrder(samples, tabValue)),
    resetOrderSamplesState: () => dispatch(resetOrderSamplesState()),
    unsetSelectedData: () => dispatch(unsetSelectedData()),
  }
}

export default withHOCs({
  auth: false,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(OrderSamples)
