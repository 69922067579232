import { Component } from 'react'
import PropTypes from 'prop-types'

export default class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log('Error boundary', error, errorInfo)
    this.setState({ errorInfo })
  }
  render() {
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

const SUPPRESSED_ERRORS = ['validateDOMNesting']

// Converting warnings/ Errors to logs, so that end user don't see the details
console.warn = function filterWarnings(msg, ...args) {
  // hide warning
  // if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
  //   const errorData = { error: '', msg, args }
  //   const errorString = JSON.stringify(errorData)
  //   logData(errorString)
  // }
}

console.error = function filterWarnings(msg, ...args) {
  if (
    !SUPPRESSED_ERRORS.some((entry) =>
      msg?.message ? ~msg.message.indexOf(entry) : ~msg.indexOf(entry)
    )
  ) {
    const errorData = { error: '', msg, args }
    const errorString = JSON.stringify(errorData)
    logData(errorString)
  }
}

const logData = (message = '', ...args) => {
  console.log(message, ...args)
}
