import ChipArray from '../../components/Chips/ChipArray'
import { getSampleIDPrefix } from '../../util/CommonUtils'

export const getNotFoundBlock = (
  filterNFList = {},
  stateFilters = {},
  handleChipClick = () => {}
) => {
  let latestFilterStr = Object.keys(stateFilters).find(
    (obj) => !!stateFilters[obj]['islatest']
  )
  const latestFilterKey = (latestFilterStr || '')
    .replace(' ', '_')
    .toLowerCase()

  const latestFilterList = filterNFList[latestFilterKey] || []
  const chipArrayData = latestFilterList.reduce(function (
    result,
    item = '',
    index,
    array
  ) {
    const itemStr =
      latestFilterKey === 'sample_id' ? getSampleIDPrefix(item) : item
    result[index] = { key: itemStr, label: itemStr }
    return result
  }, [])
  const nfFilterCount = (latestFilterList || []).length
  if (nfFilterCount > 1) {
    latestFilterStr += 's'
  }
  return nfFilterCount > 0 ? (
    <ChipArray
      data={chipArrayData}
      enableCopyAllButton
      handleClick={handleChipClick}
      title={`${nfFilterCount} ${latestFilterStr} not found`}
      textAlign={'left'}
      variant={'h6'}
    />
  ) : (
    ''
  )
}
