import * as propTypes from 'prop-types'
import moment from 'moment'
import Button from '@material-ui/core/Button/Button'
import ChipArray from '../../components/Chips/ChipArray'
import copy from 'copy-to-clipboard'
import DialogBox from '../../components/DialogBox/DialogBox'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import PopUp from '../../components/PopUp/PopUp'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import SampleSearch from '../../components/Search/SampleSearch'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import withHOCs from 'util/withHocs'
import { showNotification } from '../../store/notification/actionCreator'
import { withStyles } from '@material-ui/core/styles'
import {
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@material-ui/core'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Delete } from '@material-ui/icons'
import CircularPageLoader from '../../components/Loader/CircularPageLoader'
import { isEmpty, findIndex } from 'lodash'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { TableCellthemeNew } from '../../config/themeConfig'
import MomentUtils from '@date-io/moment'
import Autosuggest from 'react-autosuggest'
import { ShootTypes, ShootTypePagename } from '../../enums/shootTypes'
import {
  addSamples,
  closeAlert,
  closeErrorPopUp,
  clearSearchData,
  getProjectChannel,
  updateShootType,
  deleteShootType,
  showAlert,
  showPopup,
  closePopup,
  clearTableData,
  getShootType,
  setPage,
  setCheckBox,
  setCheckBoxHeaderChange,
  setOverlayCheckBox,
  setOverlayHeaderCheckBox,
  setRowsPerPage,
  removeSamples,
  resetNewShootTypeState,
  createShootType,
  updateSampleFieldsData,
} from '../../store/newShootType/actionCreator'
import { getAProject } from '../../store/projects/actionCreator'
import { getACollection } from '../../store/collections/actionCreator'
import { getShipToLocation } from '../../store/newShipment/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { DATE_FORMAT, convertTableDateFormat } from '../../util/CommonUtils'
import { makeTabTables, getTitle } from '../../util/Table'
import { dataTabLabelsShipped } from '../../enums/tableTabs'
import BottomBar from 'components/BottomBar'

const styles = (theme) => ({
  pageHeader: {
    marginBottom: '20px',
    height: '40px',
    position: 'relative',
    fontSize: '34px',
  },
  pageHeaderText: {
    position: 'absolute',
    top: -30,
  },
  pageHeaderButton1: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 250,
  },
  pageHeaderButton2: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 20,
  },
  detailsHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  textField: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 'normal',
    fontSize: 16,
    width: '100%',
  },
  label: {
    fontSize: '20px',
    fontWeight: 'bold',
    position: 'relative',
    padding: '10px 0 20px 0',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '2%',
    overflow: 'hidden',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
  },
  SwipeableDrawerElements: {
    // fontSize: 'large',
    textAlign: 'left',
    overflow: 'hidden',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  container: {
    position: 'relative',
    zIndex: 10,
  },
  input: {
    width: '100%',
    height: 55,
    padding: '10px 20px',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    border: '1px solid #aaa',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: 'none',
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  sampleSearch: {
    marginTop: '20px',
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 51,
    width: 280,
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 1,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    display: 'block',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '284px',
    padding: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    whiteSpace: 'pre-wrap',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
})

const pageNameLabels = {
  projects: 'Project',
  collections: 'Collection',
}

const DEFAULT_ROWS_PER_PAGE = '9999'

const getSuggestionValue = (suggestion) => suggestion.name

const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>

class NewShootTypePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      callAction: '',
      page: 0,
      rowsPerPage: 20,
      tooltipIsOpen: false,
      suggestions: [],
      pegasusIds: [],
      projectData: {},
      collectionData: {},
      isStartDatePickerOpen: false,
      isEndDatePickerOpen: false,
      sampleidsCount: 0,
    }
  }

  onSelected = (selectedData, selected) => {
    const { pegasusId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.pegasusId === pegasusId
      }) !== -1
    )
  }
  onOverlaySelected = (selectedData, selected) => {
    const { pegasusId: selectedPegId = '' } = selected
    return selectedData.find((obj = {}) => obj.pegasusId === selectedPegId)
  }

  componentWillMount() {
    if (!Object.keys(this.props.shootTypesData).length) {
      this.props.clearTableData()
    } else if (this.props.shootType) {
      this.props.getShootType(
        this.props.shootTypesData,
        this.props.shootType,
        1,
        9999,
        false
      )
    }
  }

  componentDidMount = () => {
    const { getProjectLocation } = this.props
    this.setShootTypeHeader()
    this.triggerActionBasedOnURI()
    getProjectLocation()
  }

  // Trigger action based on URI params(action and shoottype)
  triggerActionBasedOnURI = () => {
    const {
      auth,
      pageName = '',
      subPageName = '',
      createShootType,
      match: { params: { pageId: projectID = '' } = {} } = {},
      rowsPerPage = '9999',
      page = 1,
      getAProject = () => {},
      getACollection = () => {},
    } = this.props
    const {
      session: {
        userInfo: { lanId = '' },
      },
    } = auth
    const currentRowsPerpage = DEFAULT_ROWS_PER_PAGE || rowsPerPage
    if (!isEmpty(pageName)) {
      switch (subPageName) {
        case 'create':
          createShootType(lanId, pageNameLabels[pageName] || '')
          break
        case 'edit':
          switch (pageName) {
            case ShootTypePagename.PROJECT:
              getAProject(projectID, page, currentRowsPerpage)
              break
            case ShootTypePagename.COLLECTION:
              getACollection(projectID, page, currentRowsPerpage)
              break
            default:
              break
          }
          break
        default:
          break
      }
    }
  }
  // Set breadcrumb based on shoottype
  setShootTypeHeader = () => {
    const { pageName, setHeaderTitleArray, getProjectChannel } = this.props
    switch (pageName) {
      case ShootTypePagename.PROJECT:
        getProjectChannel()
        setHeaderTitleArray([
          { title: 'Projects', link: '/projects' },
          { title: 'Create/Edit Project' },
        ])
        break
      case ShootTypePagename.COLLECTION:
        setHeaderTitleArray([
          { title: 'Collections', link: '/collections' },
          { title: 'Create/Edit Collection' },
        ])
        break
      default:
        break
    }
  }

  disableCreateButton = () => {
    const { projectData = {}, collectionData = {} } = this.state
    const { name = '', channel = '' } = projectData
    const {
      name: collectionName = '',
      marketingPid: collectionMarketingPid = '',
    } = collectionData
    if ((name && channel) || (collectionName && collectionMarketingPid)) {
      return false
    } else {
      return true
    }
  }

  getSuggestions = (value) => {
    const { shipToLocations = [] } = this.props
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const filteredLocation =
      inputLength === 0
        ? []
        : shipToLocations.filter(
            (loc) =>
              loc.building.toLowerCase().slice(0, inputLength) === inputValue
          )

    const locations = filteredLocation.map((item) =>
      Object.assign(
        {},
        {
          name: item.building,
        }
      )
    )
    return locations
  }

  handleRemoveSample = () => {
    const { onEditChange, selectedData, shootType, shootTypeId } = this.props
    onEditChange(selectedData, shootType, shootTypeId)
  }

  handleAlertMessage = (e) => {
    const { showAlert } = this.props

    this.setState({ callAction: e.currentTarget.dataset.id })

    let message = ''
    if (e.currentTarget.dataset.id === 'DELETE') {
      message = 'Are you sure you want to delete?'
    }
    showAlert(message)
  }

  handleUpdate = (e) => {
    const {
      shootTypeId,
      pageName = '',
      onShootTypeUpdate,
      shootType: currentShoottype = '',
      shipToLocations = [],
    } = this.props
    const shootType = pageNameLabels[pageName] || currentShoottype || ''
    let updateRequest = {}

    switch (shootType) {
      case ShootTypes.PROJECT:
        {
          const {
            name,
            channel,
            locationName,
            shootStartDate,
            shootEndDate,
            marketingPid,
            notes,
          } = this.state.projectData

          updateRequest = {
            name: name,
            channel: channel,
            locationId: shipToLocations.filter(
              (x) => x.building === locationName
            ),
            shootStartDate: shootStartDate,
            shootEndDate: shootEndDate,
            marketingPid: marketingPid,
            notes: notes,
          }
        }
        break
      case ShootTypes.COLLECTION:
        {
          const { name, marketingPid, notes, locationName } =
            this.state.collectionData
          updateRequest = {
            name: name,
            marketingPid: marketingPid,
            notes: notes,
            locationId: shipToLocations.filter(
              (x) => x.building === locationName
            ),
          }
        }
        break
      default:
        break
    }

    if (!updateRequest.locationId || updateRequest.locationId.length === 0) {
      updateRequest.locationId = [{ location_id: '' }]
    }

    if (Object.entries(updateRequest).length > 0) {
      onShootTypeUpdate(
        shootTypeId,
        updateRequest,
        shootType,
        this.onShootTypeUpdateCB
      )
    }
  }

  onShootTypeUpdateCB = (updatedType = '', status = '') => {
    if (!isEmpty(updatedType) && status === 'success') {
      this.props.history.push(`/${updatedType}`)
    }
  }

  onAlertAgree = () => {
    const {
      handleShootTypeDelete,
      shootTypeId,
      pageName: shootType = '',
    } = this.props
    handleShootTypeDelete(shootTypeId, shootType, this.onShootTypeUpdateCB)
  }

  onPhotoshootLocation = (event, { newValue }) => {
    this.setState((prevState) => ({
      projectData: {
        ...prevState.projectData,
        locationName: newValue,
      },
    }))
  }

  onCollectionPhotoshootLocation = (event, { newValue }) => {
    this.setState((prevState) => ({
      collectionData: {
        ...prevState.collectionData,
        locationName: newValue,
      },
    }))
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  onBlur = (e) => {
    let existCheck = this.props.shipToLocations.filter(
      (loc) => loc.building === e.target.defaultValue
    )

    if (existCheck.length === 0) {
      this.setState((prevState) => ({
        projectData: {
          ...prevState.projectData,
          shipFromLocName: '',
        },
      }))
    }
  }

  toggleOpen = () => {
    const { numSelected } = this.props
    if (numSelected !== 0) {
      return true
    } else {
      return false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { shootTypesData = {} } = this.props
    const {
      name = '',
      channel = '',
      locationName = '',
      shootStartDate = '',
      shootEndDate = '',
      marketingPid = '',
      notes = '',
    } = shootTypesData
    if (prevProps.projectData !== this.props.projectData) {
      this.setState({ projectData: this.props.projectData })
    }

    if (prevProps.shootTypesData !== shootTypesData) {
      this.setState((prevState) => ({
        projectData: {
          ...prevState.projectData,
          name: name,
          channel: channel,
          locationName: locationName,
          shootStartDate: shootStartDate,
          shootEndDate: shootEndDate,
          marketingPid: marketingPid,
          notes: notes,
        },
        collectionData: {
          ...prevState.collectionData,
          name: name,
          marketingPid: marketingPid,
          locationName: locationName,
          notes: notes,
        },
      }))
    }
  }

  componentWillUnmount() {
    this.props.resetNewShootTypeState()
  }

  getProjectChannelNames = (channels) => {
    const channelNames = channels.map((it) =>
      Object.assign(
        {},
        {
          id: it.id,
          name: it.channel,
        }
      )
    )
    return channelNames.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  unavailableSamplesRender = (unavailableSamples = []) => {
    const FlexibleTableNew = withStyles((theme) => ({
      root: {
        boxShadow: 'unset !important',
        background: 'transparent',
      },
      container: {
        maxHeight: 'unset !important',
      },
      header: {
        backgroundColor: 'unset',
      },
    }))(FlexibleTable)
    const newData = unavailableSamples.map((dataObj) => {
      const { pegasusId = '', projects = [], imageUrl = '' } = dataObj
      const projectsList = projects.map((obj) =>
        Object.assign({}, obj, {
          pegasusId: pegasusId,
          shootStartDate: convertTableDateFormat(
            obj.shootStartDate,
            DATE_FORMAT
          ),
          shootEndDate: convertTableDateFormat(obj.shootEndDate, DATE_FORMAT),
        })
      )
      return {
        pegasusId: pegasusId,
        checkboxLabel: (
          <Grid container spacing={3} direction={'column'}>
            <Grid item>{pegasusId}</Grid>
            <Grid item>{imageUrl}</Grid>
          </Grid>
        ),
        projectsList: (
          <MuiThemeProvider theme={TableCellthemeNew}>
            <FlexibleTableNew
              cascadeFieldKeys={[]}
              data={projectsList}
              disableTablePagination
              disableTableSorting
              columnData={this.props.unavailableSampleProjectColumnData}
              disableItemCount
              enableToolbar={false}
              enableSpacer={false}
              stickyHeader={false}
              tableTitleVariant={'subtitle2'}
              showPopup={() => {}}
              tableRowHover={false}
              cellTheme={TableCellthemeNew}
              enableMultiSearch
            />
          </MuiThemeProvider>
        ),
      }
    })
    return newData
  }

  displayUnavailableSamples = () => {
    const {
      unavailableSamples,
      unavailableSampleColumnData,
      onOverlayCheckBoxChange = () => {},
      overlaySelectedData = [],
      overlayNumSelected = 0,
      onOverlayCheckBoxHeadChange = () => {},
    } = this.props
    const FlexibleTableNew = withStyles((theme) => ({
      root: {
        boxShadow: 'unset !important',
        // background: 'transparent',
      },
      checkBoxElem: {
        verticalAlign: 'baseline !important',
        paddingTop: '27px',
      },
      container: {
        maxHeight: 'unset !important',
      },
      tableRow: {
        verticalAlign: 'baseline !important',
        paddingTop: '27px',
        // '&:hover': {
        //   backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
        // },
        // '&$selected, &$selected:hover': {
        //   backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
        // },
      },
      header: {
        backgroundColor: 'unset',
      },
    }))(FlexibleTable)
    return (
      <div>
        <FlexibleTableNew
          cascadeFieldKeys={[]}
          data={this.unavailableSamplesRender(unavailableSamples)}
          // disableItems
          disableTablePagination
          disableTableSorting
          columnData={unavailableSampleColumnData}
          disableItemCount
          enableToolbar={false}
          enableSpacer={false}
          checkBoxEnabled={true}
          onCheckBoxChange={onOverlayCheckBoxChange}
          onSelected={this.onOverlaySelected}
          selected={overlaySelectedData}
          editEnabled={false}
          stickyHeader={false}
          showPopup={() => {}}
          tableRowHover={true}
          numSelected={overlayNumSelected}
          onCheckBoxHeaderChange={onOverlayCheckBoxHeadChange}
          // cellTheme={TableCellthemeNew}
          enableMultiSearch
        />
      </div>
    )
  }

  handleCloseErrorPopUp = () => {
    const { closeErrorPopUp = () => {} } = this.props
    closeErrorPopUp()
  }

  handleForceSave = () => {
    const {
      addSamplesWithoutCheck,
      auth,
      shootTypeId,
      shootType,
      overlaySelectedData = [],
    } = this.props
    const {
      session: {
        userInfo: { lanId = '' },
      },
    } = auth
    const {
      projectData: { name: projectName = '', channel: projectChannel = '' },
      collectionData: { name: collectionName = '' },
    } = this.state
    const selectedIds = overlaySelectedData.map((obj) => obj.pegasusId)
    addSamplesWithoutCheck(
      selectedIds,
      lanId,
      shootTypeId,
      shootType,
      projectName,
      projectChannel,
      collectionName
    )
  }

  getSearchValue = (pgIds) => {
    this.setState({ pegasusIds: pgIds })
  }

  setStartDatePickerStatus = (status) => {
    this.setState({
      isStartDatePickerOpen: status,
    })
  }

  setEndDatePickerStatus = (status) => {
    this.setState({
      isEndDatePickerOpen: status,
    })
  }

  renderChannelOptions = () => {
    const { channels = [] } = this.props
    return this.getProjectChannelNames(channels).map((option) => (
      <option value={option.name} key={option.id}>
        {option.name}
      </option>
    ))
  }
  getSamplesCount = (sampleCount) => {
    this.setState({
      sampleidsCount: sampleCount,
    })
  }
  render() {
    const {
      auth,
      alertIsVisible,
      alertMessage,
      classes = {},
      clearSearch,
      closeAlert,
      closePopup,
      columnData,
      clickableColumn,
      checkBoxEnabled,
      data,
      fetchSamples,
      handleChipClick,
      unknownPegasusIds,
      numSelected,
      onClick,
      onEditChange,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      page,
      popupIsVisible,
      popupItem = {},
      rowsPerPage,
      response,
      selectedData,
      setPage,
      setRowsPerPage,
      showPopup,
      shootTypeId,
      pageName = '',
      shootType: currentShoottype = '',
      unavailableSamplePopUpIsVisible,
      unavailableSamples = [],
      // totalRowsCount,
      overlayNumSelected = 0,
      fetchShootTypeDataPending = false,
      shootTypesData,
      subPageName = '',
      updateSampleFieldsData = () => {},
    } = this.props
    const {
      session: {
        userInfo: { lanId = '' },
      },
    } = auth
    const primaryButtonTextOL = !overlayNumSelected
      ? `ADD SAMPLES TO PROJECT`
      : `ADD ${overlayNumSelected} SAMPLES TO PROJECT`

    const shootType = pageNameLabels[pageName] || currentShoottype || ''

    const {
      suggestions,
      isEndDatePickerOpen,
      isStartDatePickerOpen,
      projectData = {},
      collectionData = {},
      sampleidsCount,
    } = this.state

    const {
      name = '',
      channel = '',
      shootStartDate,
      shootEndDate,
      marketingPid = '',
      notes = '',
      locationName = '',
    } = projectData

    const {
      name: collectionName = '',
      marketingPid: collectionMarketingPid = '',
      notes: collectionNotes = '',
      locationName: collectionLocationName = '',
    } = collectionData
    const shootStartDateTs = !isEmpty(shootStartDate)
      ? new Date(shootStartDate).getTime()
      : null
    const shootEndDateTs = !isEmpty(shootEndDate)
      ? new Date(shootEndDate).getTime()
      : null
    const nowTs = new Date().getTime()

    let chipData = unknownPegasusIds.map((data, index) => ({
      label: data,
      key: index,
    }))

    const locationProps = {
      id: 'Location',
      label: 'Photoshoot Location',
      placeholder: 'Photoshoot Location',
      value: locationName || '',
      maxLength: 100,
      onChange: this.onPhotoshootLocation,
    }

    const collectionLocationProps = {
      id: 'Location',
      label: 'Collection Location',
      placeholder: 'Collection Location',
      value: collectionLocationName || '',
      maxLength: 100,
      onChange: this.onCollectionPhotoshootLocation,
    }

    const dataFieldKeys = columnData.map((column) => column.id)
    return (
      <div style={{ margin: '0px 28px' }}>
        <Grid container>
          <Grid item xs={6} className={classes.pageHeader}>
            <span>
              {shootType} - {shootTypeId}
            </span>
          </Grid>
          <Grid item xs={3} className={classes.pageHeaderButton1}>
            <Button
              id="handleDelete"
              data-id="DELETE"
              data-cy="projectDelete"
              className={classes.defaultButton}
              variant="outlined"
              color="secondary"
              onClick={this.handleAlertMessage.bind(this)}
              startIcon={<Delete />}
            >
              DELETE {shootType}
            </Button>
          </Grid>
          <Grid item xs={3} className={classes.pageHeaderButton2}>
            <Button
              id="handleUpdate"
              data-id="UPDATE"
              data-cy="projectSave"
              className={classes.defaultButton}
              variant="outlined"
              color="primary"
              disabled={this.disableCreateButton()}
              onClick={this.handleUpdate.bind(this)}
            >
              SAVE {shootType}
            </Button>
          </Grid>
        </Grid>
        <div>
          {shootType === ShootTypes.PROJECT && subPageName === 'create' && (
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span className={classes.label}>Project Info</span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      id="Name"
                      label="Project Name*"
                      inputProps={{
                        maxLength: 100,
                      }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Name"
                      variant="outlined"
                      value={name || ''}
                      onChange={(event) => {
                        const name = event.target.value
                        this.setState((prevState) => ({
                          projectData: {
                            ...prevState.projectData,
                            name: name,
                          },
                        }))
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      name="Channel"
                      id="Channel"
                      label="Channel*"
                      placeholder="Channel"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => {
                        const channel = event.target.value
                        this.setState((prevState) => ({
                          projectData: {
                            ...prevState.projectData,
                            channel: channel,
                          },
                        }))
                      }}
                      select
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                        native: true,
                      }}
                      value={channel || ''}
                    >
                      <option> option </option>
                      {this.renderChannelOptions()}
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={locationProps}
                      InputLabelProps={{ shrink: true }}
                      theme={{
                        container: classes.container,
                        suggestionsContainerOpen:
                          classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                        suggestionHighlighted: classes.suggestionHighlighted,
                        suggestionsContainer: classes.suggestionsContainer,
                        input: classes.input,
                        inputFocused: classes.inputFocused,
                        inputOpen: classes.inputFocused,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        onClick={() => this.setStartDatePickerStatus(true)}
                        onClose={() => this.setStartDatePickerStatus(false)}
                        open={isStartDatePickerOpen}
                        className={classes.textField}
                        variant="inline"
                        inputVariant="outlined"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ readOnly: true }}
                        id="ShootStartDate"
                        label="Start Date"
                        autoOk
                        clearable={'true'}
                        disablePast
                        error={isEmpty(shootStartDate)}
                        onChange={(date) => {
                          const selectedDate = date
                          this.setState((prevState) => ({
                            projectData: {
                              ...prevState.projectData,
                              shootStartDate: selectedDate,
                            },
                          }))
                        }}
                        value={
                          shootStartDate === undefined
                            ? ''
                            : moment(shootStartDate).format('YYYY/MM/DD')
                        }
                        format={DATE_FORMAT}
                        placeholder={'__/__/____'}
                        disableOpenOnEnter
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        onClick={() => this.setEndDatePickerStatus(true)}
                        onClose={() => this.setEndDatePickerStatus(false)}
                        open={isEndDatePickerOpen}
                        className={classes.textField}
                        variant="inline"
                        inputVariant="outlined"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ readOnly: true }}
                        id="ShootEndDate"
                        label="End Date"
                        autoOk
                        clearable={'true'}
                        disablePast
                        error={
                          isEmpty(shootEndDate) ||
                          shootEndDateTs < shootStartDateTs ||
                          shootEndDateTs < nowTs
                        }
                        onChange={(date) => {
                          const selectedDate = date
                          this.setState((prevState) => ({
                            projectData: {
                              ...prevState.projectData,
                              shootEndDate: selectedDate,
                            },
                          }))
                        }}
                        value={
                          shootEndDate === undefined
                            ? ''
                            : moment(shootEndDate).format('YYYY/MM/DD')
                        }
                        format={DATE_FORMAT}
                        placeholder={'__/__/____'}
                        disableOpenOnEnter
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      id="marketingPid"
                      label="Marketing PID"
                      placeholder="marketingPid"
                      inputProps={{
                        maxLength: 100,
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      value={marketingPid || ''}
                      onChange={(event) => {
                        const marketingPid = event.target.value
                        this.setState((prevState) => ({
                          projectData: {
                            ...prevState.projectData,
                            marketingPid: marketingPid,
                          },
                        }))
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      id="notes"
                      label="Notes"
                      placeholder="Notes"
                      inputProps={{
                        maxLength: 250,
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      value={notes || ''}
                      onChange={(event) => {
                        const notes = event.target.value
                        this.setState((prevState) => ({
                          projectData: {
                            ...prevState.projectData,
                            notes: notes,
                          },
                        }))
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          {shootType === ShootTypes.PROJECT && subPageName === 'edit' && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                data-cy="projects-header"
              >
                <span className={classes.label}>Project Info</span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      data-cy="projectName"
                      id="Name"
                      label="Project Name*"
                      inputProps={{
                        maxLength: 100,
                      }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Name"
                      variant="outlined"
                      value={name || ''}
                      onChange={(event) => {
                        const name = event.target.value
                        this.setState((prevState) => ({
                          projectData: {
                            ...prevState.projectData,
                            name: name,
                          },
                        }))
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      name="Channel"
                      id="Channel"
                      label="Channel*"
                      placeholder="Channel"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) => {
                        const channel = event.target.value
                        this.setState((prevState) => ({
                          projectData: {
                            ...prevState.projectData,
                            channel: channel,
                          },
                        }))
                      }}
                      select
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                        native: true,
                      }}
                      value={channel || ''}
                    >
                      <option> option </option>
                      {this.renderChannelOptions()}
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={locationProps}
                      InputLabelProps={{ shrink: true }}
                      theme={{
                        container: classes.container,
                        suggestionsContainerOpen:
                          classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                        suggestionHighlighted: classes.suggestionHighlighted,
                        suggestionsContainer: classes.suggestionsContainer,
                        input: classes.input,
                        inputFocused: classes.inputFocused,
                        inputOpen: classes.inputFocused,
                      }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        onClick={() => this.setStartDatePickerStatus(true)}
                        onClose={() => this.setStartDatePickerStatus(false)}
                        open={isStartDatePickerOpen}
                        className={classes.textField}
                        variant="inline"
                        inputVariant="outlined"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ readOnly: true }}
                        id="ShootStartDate"
                        label="Start Date"
                        autoOk
                        clearable={'true'}
                        disablePast
                        error={isEmpty(shootStartDate)}
                        onChange={(date) => {
                          const selectedDate = date
                          this.setState((prevState) => ({
                            projectData: {
                              ...prevState.projectData,
                              shootStartDate: selectedDate,
                            },
                          }))
                        }}
                        value={
                          shootStartDate === undefined
                            ? ''
                            : moment(shootStartDate).format('YYYY/MM/DD')
                        }
                        format={DATE_FORMAT}
                        placeholder={'__/__/____'}
                        disableOpenOnEnter
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        onClick={() => this.setEndDatePickerStatus(true)}
                        onClose={() => this.setEndDatePickerStatus(false)}
                        open={isEndDatePickerOpen}
                        className={classes.textField}
                        variant="inline"
                        inputVariant="outlined"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ readOnly: true }}
                        id="ShootEndDate"
                        label="End Date"
                        autoOk
                        clearable={'true'}
                        disablePast
                        error={
                          isEmpty(shootEndDate) ||
                          shootEndDateTs < shootStartDateTs ||
                          shootEndDateTs < nowTs
                        }
                        onChange={(date) => {
                          const selectedDate = date
                          this.setState((prevState) => ({
                            projectData: {
                              ...prevState.projectData,
                              shootEndDate: selectedDate,
                            },
                          }))
                        }}
                        value={
                          shootEndDate === undefined
                            ? ''
                            : moment(shootEndDate).format('YYYY/MM/DD')
                        }
                        format={DATE_FORMAT}
                        placeholder={'__/__/____'}
                        disableOpenOnEnter
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      id="marketingPid"
                      label="Marketing PID"
                      placeholder="marketingPid"
                      inputProps={{
                        maxLength: 100,
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      value={marketingPid || ''}
                      onChange={(event) => {
                        const marketingPid = event.target.value
                        this.setState((prevState) => ({
                          projectData: {
                            ...prevState.projectData,
                            marketingPid: marketingPid,
                          },
                        }))
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.textField}
                      id="notes"
                      label="Notes"
                      placeholder="Notes"
                      inputProps={{
                        maxLength: 250,
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      value={notes || ''}
                      onChange={(event) => {
                        const notes = event.target.value
                        this.setState((prevState) => ({
                          projectData: {
                            ...prevState.projectData,
                            notes: notes,
                          },
                        }))
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}

          {shootType === ShootTypes.COLLECTION && (
            <Paper style={{ padding: '16px 24px', marginTop: '12px' }}>
              <span className={classes.label}>Collection Info</span>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    className={classes.textField}
                    id="Name"
                    label="Collection Name *"
                    inputProps={{
                      maxLength: 100,
                    }}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Collection Name"
                    variant="outlined"
                    value={collectionName || ''}
                    onChange={(event) => {
                      const name = event.target.value
                      this.setState((prevState) => ({
                        collectionData: {
                          ...prevState.collectionData,
                          name: name,
                        },
                      }))
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className={classes.textField}
                    id="marketingPid"
                    label="COP/VAP #"
                    inputProps={{
                      maxLength: 100,
                    }}
                    InputLabelProps={{ shrink: true }}
                    placeholder="COP/VAP #"
                    variant="outlined"
                    value={collectionMarketingPid || ''}
                    onChange={(event) => {
                      const marketingPid = event.target.value
                      this.setState((prevState) => ({
                        collectionData: {
                          ...prevState.collectionData,
                          marketingPid: marketingPid,
                        },
                      }))
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                      this.onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequested
                    }
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={collectionLocationProps}
                    InputLabelProps={{ shrink: true }}
                    theme={{
                      container: classes.container,
                      suggestionsContainerOpen:
                        classes.suggestionsContainerOpen,
                      suggestionsList: classes.suggestionsList,
                      suggestion: classes.suggestion,
                      suggestionHighlighted: classes.suggestionHighlighted,
                      suggestionsContainer: classes.suggestionsContainer,
                      input: classes.input,
                      inputFocused: classes.inputFocused,
                      inputOpen: classes.inputFocused,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className={classes.textField}
                    id="notes"
                    label="Notes"
                    placeholder="Notes"
                    inputProps={{
                      maxLength: 250,
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={collectionNotes || ''}
                    onChange={(event) => {
                      const notes = event.target.value
                      this.setState((prevState) => ({
                        collectionData: {
                          ...prevState.collectionData,
                          notes: notes,
                        },
                      }))
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </div>
        <div>
          <div className={classes.sampleSearch}>
            <SampleSearch
              label="Scan or Enter PGID"
              buttonName="Enter"
              buttonVariant="text"
              submitSearch={fetchSamples}
              getSearchValue={this.getSearchValue}
              userId={lanId}
              shipmentNumber={shootTypeId}
              shootType={shootType}
              projectData={projectData}
              collectionData={collectionData}
              leftColumn
              clearSearchCallback={clearSearch}
              clearOnSubmit
              onChangeText={this.getSamplesCount}
              helperText={
                sampleidsCount
                  ? `${sampleidsCount} Pegasus Id(s)`
                  : 'Pegasus Id(s)'
              }
            />
          </div>
          {unknownPegasusIds.length > 0 && (
            <div style={{ marginTop: '34px' }}>
              <ChipArray
                data={chipData}
                enableCopyAllButton
                handleClick={handleChipClick}
                title="Unknown Sample Ids or Already added"
              />
            </div>
          )}
        </div>
        {fetchShootTypeDataPending ? (
          <div>
            <CircularPageLoader open={fetchShootTypeDataPending} />
          </div>
        ) : (
          <div style={{ marginTop: '34px' }}>
            <FlexibleTable
              cascadeFieldKeys={[]}
              checkBoxEnabled={checkBoxEnabled}
              clickableColumn={clickableColumn}
              columnData={columnData}
              data={data}
              dataFieldKeys={dataFieldKeys}
              onClick={onClick}
              onEditChange={onEditChange}
              editEnabled={false}
              numSelected={numSelected}
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
              onSelected={this.onSelected}
              page={page}
              rowCount={data.length}
              rowsPerPage={rowsPerPage}
              selected={selectedData}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              showPopup={showPopup}
              disableTablePagination={true}
              enableSearch
              enableMultiSearch
              // paginationAtApiLevel={true}
              // totalRowsCount={totalRowsCount}
            />
          </div>
        )}
        <TabbedPopUp
          dataTabLabels={dataTabLabelsShipped}
          isVisible={popupIsVisible}
          data={makeTabTables(
            popupItem,
            classes,
            dataTabLabelsShipped,
            updateSampleFieldsData,
            shootTypesData
          )}
          onClose={closePopup}
          title={getTitle(popupItem)}
          fullWidth={true}
        />
        <DialogBox
          isAlertVisible={alertIsVisible}
          message={alertMessage}
          onClose={closeAlert}
          response={response}
          onAgree={this.onAlertAgree}
          title={'Delete ' + shootType + '?'}
          primaryButtonText={'Delete ' + shootType}
          secondaryButtonText="Nevermind"
        />
        <PopUp
          title={`${unavailableSamples.length} Sample(s) in use`}
          content={this.displayUnavailableSamples()}
          isButtonVisible={true}
          isButtonDisabled={!overlayNumSelected}
          onProceed={this.handleForceSave.bind(this)}
          isVisible={unavailableSamplePopUpIsVisible}
          disableSave
          onClose={this.handleCloseErrorPopUp}
          maxWidth={'md'}
          primaryButtonText={primaryButtonTextOL}
          secondaryButtonText={`DON'T ADD THESE SAMPLES`}
          isCancelButtonDisabled={overlayNumSelected > 0}
        />
        <BottomBar
          anchor="bottom"
          variant={'persistent'}
          open={this.toggleOpen()}
          onOpen={() => null}
          onClose={() => null}
          showClearAll={numSelected > 0}
          actionType={'REST_ALL_NEWSHOOTTYPE'}
        >
          <div className={classes.swipeableDrawerElementsContainer}>
            <Grid container className={classes.swipeableDrawer} spacing={4}>
              <Grid item>
                <p className={classes.SwipeableDrawerElements}>
                  Number of Selected Items: {numSelected}
                </p>
              </Grid>
              <Grid item>
                <Button
                  className={classes.defaultButton}
                  color="primary"
                  onClick={this.handleRemoveSample}
                  variant="outlined"
                  startIcon={<Delete />}
                >
                  Remove Samples
                </Button>
              </Grid>
            </Grid>
          </div>
        </BottomBar>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alertIsVisible: state.newShootType.alertIsVisible,
    alertMessage: state.newShootType.alertMessage,
    clickableColumn: state.newShootType.clickableColumn,
    columnData: state.newShootType.columnData,
    checkBoxEnabled: state.newShootType.checkBoxEnabled,
    channels: state.newShootType.channels,
    data: state.newShootType.data,
    goNextUpdatePageFlag: state.newShootType.goNextUpdatePageFlag,
    headerTitle: state.layout.headerTitle,
    numSelected: state.newShootType.numSelected,
    overlayNumSelected: state.newShootType.overlayNumSelected,
    onCheckBoxChange: state.newShootType.onCheckBoxChange,
    onOverlayCheckBoxHeadChange: state.newShootType.onOverlayCheckBoxHeadChange,
    onOverlayCheckBoxChange: state.newShootType.onOverlayCheckBoxChange,
    onClick: state.newShootType.onClick,
    onEditChange: state.newShootType.onEditChange,
    order: state.newShootType.order,
    orderBy: state.newShootType.orderBy,
    response: state.newShootType.response,
    rowCount: state.newShootType.rowCount,
    rowsPerPage: state.newShootType.rowsPerPage,
    unknownPegasusIds: state.newShootType.unknownPegasusIds,
    shipToLocations: state.newShipment.shipToLocations,
    shootType: state.newShootType.shootType,
    shootTypeId: state.newShootType.shootTypeId,
    shootTypesData: state.newShootType.shootTypesData,
    selectedData: state.newShootType.selectedData,
    overlaySelectedData: state.newShootType.overlaySelectedData,
    showPopup: state.newShootType.showPopup,
    showAlert: state.newShootType.showAlert,
    projectNames: state.newShootType.projectNames,
    page: state.newShootType.page,
    popupIsVisible: state.newShootType.popupIsVisible,
    popupItem: state.newShootType.item,
    unavailableSamples: state.newShootType.unavailableSamples,
    unavailableSampleColumnData: state.newShootType.unavailableSampleColumnData,
    unavailableSampleProjectColumnData:
      state.newShootType.unavailableSampleProjectColumnData,
    totalRowsCount: state.newShootType.totalRowsCount,
    unavailableSamplePopUpIsVisible:
      state.newShootType.unavailableSamplePopUpIsVisible,
    fetchShootTypeDataPending: state.newShootType.fetchShootTypeDataPending,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addSamplesWithoutCheck: (
      pgIds,
      userId,
      shootTypeId,
      shootType,
      name,
      channel,
      collectionName
    ) => {
      dispatch(
        addSamples(
          pgIds,
          userId,
          shootTypeId,
          shootType,
          name,
          channel,
          collectionName,
          false
        )
      )
    },
    clearSearch: () => {
      dispatch(clearSearchData())
    },
    closeAlert: () => dispatch(closeAlert()),
    closeErrorPopUp: () => dispatch(closeErrorPopUp()),
    fetchSamples: (
      pgIds,
      userId,
      shootTypeId,
      shootType,
      name,
      channel,
      collectionName
    ) => {
      dispatch(
        addSamples(
          pgIds,
          userId,
          shootTypeId,
          shootType,
          name,
          channel,
          collectionName,
          true
        )
      )
    },
    getProjectChannel: () => dispatch(getProjectChannel()),
    getProjectLocation: () => dispatch(getShipToLocation()),
    getShootType: (row, shootType, page, rowsPerPage, goToViewPage) =>
      dispatch(getShootType(row, shootType, page, rowsPerPage, goToViewPage)),
    handleShootTypeDelete: (shootTypeId, shootType, callback = () => {}) =>
      dispatch(deleteShootType(shootTypeId, shootType, callback)),
    handleChipClick: (chip) => {
      let c = chip.target.innerText
      copy(c)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    onOverlayCheckBoxChange: (selectedData = {}) => {
      const { pegasusId = '' } = selectedData
      dispatch(setOverlayCheckBox({ pegasusId: pegasusId }))
    },
    onOverlayCheckBoxHeadChange: (selected = []) => {
      const newData = selected.map((obj = {}) => ({
        pegasusId: obj.pegasusId,
      }))
      dispatch(setOverlayHeaderCheckBox(newData))
    },
    onCheckBoxChange: (selectedData) => dispatch(setCheckBox(selectedData)),
    onCheckBoxHeaderChange: (selected = []) =>
      dispatch(setCheckBoxHeaderChange(selected)),
    onShootTypeUpdate: (
      shootTypeId,
      updateRequest,
      shootType,
      callback = () => {}
    ) =>
      dispatch(
        updateShootType(shootTypeId, updateRequest, shootType, callback)
      ),
    onEditChange: (selectedData, shootType, shipmentId) =>
      dispatch(removeSamples(selectedData, shootType, shipmentId)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    showAlert: (message) => dispatch(showAlert(message)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    closePopup: () => {
      dispatch(closePopup())
    },
    clearTableData: () => {
      dispatch(clearTableData())
    },
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    resetNewShootTypeState: () => dispatch(resetNewShootTypeState()),
    createShootType: (userId, shootType) =>
      dispatch(createShootType(userId, shootType)),
    getAProject: (projectID, page, rowsPerPage) => {
      dispatch(getAProject(projectID, page, rowsPerPage))
    },
    getACollection: (projectID, page, rowsPerPage) =>
      dispatch(getACollection(projectID, page, rowsPerPage)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
  }
}

NewShootTypePage.propTypes = {
  alertIsVisible: propTypes.bool,
  onCreate: propTypes.func,
  callAction: propTypes.string,
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  clickableColumn: propTypes.array,
  data: propTypes.array,
  handleShootTypeDelete: propTypes.func,
  handleUpdate: propTypes.func,
  numSelected: propTypes.number,
  overlayNumSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onOverlayCheckBoxHeadChange: propTypes.func,
  onClick: propTypes.func,
  selectedData: propTypes.array,
  overlaySelectedData: propTypes.array,
  shipFrom: propTypes.string,
  order: propTypes.string,
  orderBy: propTypes.string,
  page: propTypes.number,
  rowCount: propTypes.number,
  rowsPerPage: propTypes.number,
  userId: propTypes.string,
  unavailableSamples: propTypes.array,
  unavailableSampleColumnData: propTypes.array,
  unavailableSampleProjectColumnData: propTypes.array,
  unavailableSamplePopUpIsVisible: propTypes.bool,
  getSearchValue: propTypes.func,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(NewShootTypePage)
