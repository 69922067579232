import { isEmpty } from 'lodash'
import { projectResponseToProject } from '../mappers/projectMapper'
import { DATE_FORMAT, convertTableDateFormat } from '../util/CommonUtils'

/**
 * Maps Shipment API response containing multiple shipments to internal model
 * @param shipments a JSON struct comprised of a list of Shipment JSON models
 * @returns a mapped list of shipments
 */
export const shipmentsResponseToShipments = (shipments) => {
  return shipments.data.shoot_shipments.map((shipment) =>
    shipmentResponseToShipment(shipment)
  )
}

export const getPayloadFilters = (filters = {}) => {
  const { locations = [], partners = [], users = [] } = filters
  const createdBy = users?.map((obj) => obj.value)
  const shipmentPartners = partners?.map((obj) => obj.value)
  const shipTo = locations?.map((obj) => obj.building)
  const payload = Object.assign(
    {},
    {
      ship_to: shipTo,
      created_by: createdBy,
      shipment_partners: shipmentPartners,
    }
  )
  const resPayload = Object.entries(payload).reduce(
    (a, [k, v]) => (isEmpty(v) ? a : ((a[k] = v), a)),
    {}
  )
  return resPayload
}

/**
 * Maps Shipment API JSON response to internal model
 * @param shipmentResponse Json representation of a shipment
 * @returns mapped internal entity
 */
export const shipmentResponseToShipment = (shipmentResponse) => {
  return {
    shipmentId: shipmentResponse.shoot_shipment_id,
    shipmentName: shipmentResponse.shoot_shipment_name,
    shipToLocId:
      shipmentResponse.ship_to && shipmentResponse.ship_to.location_id
        ? shipmentResponse.ship_to.location_id
        : null,
    shipToLocName:
      shipmentResponse.ship_to && shipmentResponse.ship_to.location_name
        ? shipmentResponse.ship_to.location_name
        : null,
    shipmentChannel:
      shipmentResponse.shipment_channel && shipmentResponse.shipment_channel
        ? shipmentResponse.shipment_channel
        : null,
    status: shipmentResponse.status,
    shipFromLocId:
      shipmentResponse.ship_from && shipmentResponse.ship_from.location_id
        ? shipmentResponse.ship_from.location_id
        : null,
    shipFromLocName:
      shipmentResponse.ship_from && shipmentResponse.ship_from.location_name
        ? shipmentResponse.ship_from.location_name
        : null,
    shipmentNotes: shipmentResponse.notes,
    carrier: shipmentResponse.carrier,
    trackingId: shipmentResponse.tracking_id,
    contacts: shipmentResponse.other_contacts,
    shipmentDate: convertTableDateFormat(
      shipmentResponse.ship_date,
      DATE_FORMAT
    ),
    projects: shipmentResponse.projects,
    createDate: convertTableDateFormat(shipmentResponse.create_ts, DATE_FORMAT),
    modifiedDate: convertTableDateFormat(
      shipmentResponse.modify_ts,
      DATE_FORMAT
    ),
    createdBy: shipmentResponse.created_by,
    modifiedBy: shipmentResponse.modified_by,
    sampleCount: shipmentResponse.sample_count || Number(0),
    propCount: shipmentResponse.prop_count || Number(0),
    scenicCount: shipmentResponse.scenic_count || 0,
    shipmentPartners: shipmentResponse.shipment_partners || [],
    marketingPid: shipmentResponse.marketing_pid || [],
  }
}

export const shipmentToShipmentUpdateRequest = (updateDetails = {}) => {
  return {
    ship_from: {
      location_id: null,
      location_name: updateDetails?.shipFromLocName,
    },
    ship_to: {
      location_id: updateDetails?.shipToLocation?.location_id,
      location_name: updateDetails?.shipToLocation?.building,
    },
    shoot_shipment_name: updateDetails?.shipmentName,
    shipment_channel: updateDetails?.shipmentChannel,
    other_contacts: updateDetails?.otherContacts,
    carrier: updateDetails?.carrier,
    tracking_id: updateDetails?.tracking,
    notes: updateDetails?.notes,
    status: updateDetails?.status,
    project_id: updateDetails?.projectId,
    shipment_partners: updateDetails?.shipmentPartners,
    marketing_pid: updateDetails?.marketingPid,
  }
}

export const pendingShipmentToNewShipment = (updateDetails) => {
  const { data: shipmentData = {} } = updateDetails
  return {
    carrier: shipmentData.carrier || '',
    shipmentId: shipmentData.shoot_shipment_id,
    shipFromLocId: shipmentData.ship_from
      ? shipmentData.ship_from.location_id
      : '',
    shipFromLocName: shipmentData.ship_from
      ? shipmentData.ship_from.location_name
      : '',
    shipToLocation: {
      location_id: shipmentData.ship_to?.location_id,
      building: shipmentData.ship_to?.location_name || '',
    },
    shipToLocName: updateDetails.data.ship_to
      ? updateDetails.data.ship_to.location_name
      : '',
    shipmentChannel: shipmentData.shipment_channel || '',
    shipmentName: shipmentData.shoot_shipment_name || '',
    status: shipmentData.status || '',
    otherContacts: shipmentData.other_contacts || '',
    tracking: shipmentData.tracking_id || '',
    notes: shipmentData.notes || '',
    project: projectResponseToProject(shipmentData.project),
    shipDate: shipmentData.ship_date,
    shipmentCreator: shipmentData.created_by,
    shipmentPartners: shipmentData.shipment_partners || [],
    marketingPid: shipmentData.marketing_pid || [],
  }
}
