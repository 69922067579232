import moment from 'moment'
export const responseToBatchScanner = (batches = []) => {
  let localBatches = batches?.map((batch) => {
    return {
      batch_id: batch.batch_id,
      batch_name_actual: batch.batch_name,
      batch_name: getBatchName(batch.batch_name),
      import_date:
        batch.import_date &&
        moment(batch.import_date).format('MM/DD/YYYY - HH:MM'),
      importedIds: batch.importedIds,
      user: getUser(batch?.user),
    }
  })
  return localBatches
}

const getUser = (user = {}) => {
  return `${user?.first_name} ${user?.last_name}`
}

const getBatchName = (batchName = '') => {
  let returnValue = ''
  if (batchName.length > 15) {
    returnValue = batchName.substring(0, 15) + '...'
  } else {
    returnValue = batchName
  }
  return returnValue
}
