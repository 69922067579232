export const SHOW_SEARCH_ARCHIVED_PAGE_POPUP = 'SHOW_SEARCH_ARCHIVED_PAGE_POPUP'
export const CLOSE_SEARCH_ARCHIVED_PAGE_POPUP =
  'CLOSE_SEARCH_ARCHIVED_PAGE_POPUP'
export const FETCH_ARCHIVED_DATA_FAILURE = 'FETCH_ARCHIVED_DATA_FAILURE'
export const FETCH_ARCHIVED_DATA_REQUEST_PENDING =
  'FETCH_ARCHIVED_DATA_REQUEST_PENDING'
export const FETCH_ARCHIVED_DATA_SUCCESS = 'FETCH_ARCHIVED_DATA_SUCCESS'
export const SEARCH_ARCHIVED_CLEAR_DATA = 'SEARCH_ARCHIVED_CLEAR_DATA'
export const SEARCH_ARCHIVED_SET_CHECKBOX = 'SEARCH_ARCHIVED_SET_CHECKBOX'
export const SEARCH_ARCHIVED_SET_CHECKBOX_HEADER =
  'SEARCH_ARCHIVED_SET_CHECKBOX_HEADER'
export const SEARCH_ARCHIVED_SET_INVALID_DATA =
  'SEARCH_ARCHIVED_SET_INVALID_DATA'
export const SEARCH_ARCHIVED_SET_FILTERS = 'SEARCH_ARCHIVED_SET_FILTERS'
export const SEARCH_ARCHIVED_SET_PAGE = 'SEARCH_ARCHIVED_SET_PAGE'
export const SEARCH_ARCHIVED_SET_ROWS_PER_PAGE =
  'SEARCH_ARCHIVED_SET_ROWS_PER_PAGE'
export const SEARCH_ARCHIVED_SET_TOTAL_ROWS_COUNT =
  'SEARCH_ARCHIVED_SET_TOTAL_ROWS_COUNT'
export const SHOW_SELECTED_REPRINT_LABELS_POPUP =
  'SHOW_SELECTED_REPRINT_LABELS_POPUP'
export const SEARCH_ARCHIVED_SAVE_FILTERS_FAILURE =
  'SEARCH_ARCHIVED_SAVE_FILTERS_FAILURE'
export const SEARCH_ARCHIVED_SAVE_FILTERS_PENDING =
  'SEARCH_ARCHIVED_SAVE_FILTERS_PENDING'
export const SEARCH_ARCHIVED_SAVE_FILTERS_SUCCESS =
  'SEARCH_ARCHIVED_SAVE_FILTERS_SUCCESS'
export const SEARCH_ARCHIVED_GET_FILTERS_SUCCESS =
  'SEARCH_ARCHIVED_GET_FILTERS_SUCCESS'
export const SEARCH_ARCHIVED_GET_FILTERS_FAILURE =
  'SEARCH_ARCHIVED_GET_FILTERS_FAILURE'
export const SEARCH_ARCHIVED_GET_FILTERS_PENDING =
  'SEARCH_ARCHIVED_GET_FILTERS_PENDING'
export const SEARCH_ARCHIVED_DUPLICATE_SAMPLES_PENDING =
  'SEARCH_ARCHIVED_DUPLICATE_SAMPLES_PENDING'
export const SEARCH_ARCHIVED_DUPLICATE_SAMPLES_FAILURE =
  'SEARCH_ARCHIVED_DUPLICATE_SAMPLES_FAILURE'
export const SEARCH_ARCHIVED_SET_FILTER_NOTFOUND_LIST =
  'SEARCH_ARCHIVED_SET_FILTER_NOTFOUND_LIST'
export const SEARCH_ARCHIVED_UPDATE_SAMPLES_REQUEST_FAILURE =
  'SEARCH_ARCHIVED_UPDATE_SAMPLES_REQUEST_FAILURE'
export const SEARCH_ARCHIVED_SAMPLES_REQUEST_PENDING =
  'SEARCH_ARCHIVED_SAMPLES_REQUEST_PENDING'

export const CALL_EDIT_FIELDS_API = 'CALL_EDIT_FIELDS_API'

export const CALL_HISTORY_API = 'CALL_HISTORY_API'
export const CALL_HISTORY_API_FAILED = 'CALL_HISTORY_API_FAILED'
export const CALL_HISTORY_FILTERS_API = 'CALL_HISTORY_FILTERS_API'
export const CALL_HISTORY_FILTERS_API_FAILED = 'CALL_HISTORY_FILTERS_API_FAILED'
export const CALL_SEARCH_FIELDS = 'CALL_SEARCH_FIELDS'
export const RESET_IS_CALL_SEARCH_FIELDS = 'RESET_IS_CALL_SEARCH_FIELDS'
export const SET_COLUMN_SETTINGS_PENDING = 'SET_COLUMN_SETTINGS_PENDING'
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS'
export const EMPTY_SELECTED_DATA = 'EMPTY_SELECTED_DATA'
export const AUTO_REFRESH_SEARCH = 'AUTO_REFRESH_SEARCH'
export const SEARCH_ARCHIVED_DUPLICATE_SAMPLES_SUCCESS =
  'SEARCH_ARCHIVED_DUPLICATE_SAMPLES_SUCCESS'
export const SEARCH_UNARCHIVED_SAMPLES_REQUEST_FAILURE =
  'SEARCH_UNARCHIVED_SAMPLES_REQUEST_FAILURE'
export const SEARCH_UNARCHIVED_SAMPLES_REQUEST_SUCCESS =
  'SEARCH_UNARCHIVED_SAMPLES_REQUEST_SUCCESS'
export const SEARCH_UNARCHIVED_SAMPLES_REQUEST_PENDING =
  'SEARCH_UNARCHIVED_SAMPLES_REQUEST_PENDING'
export const SEARCH_ARCHIVED_PAGE_ALERT = 'SEARCH_ARCHIVED_PAGE_ALERT'
export const CLOSE_SEARCH_ARCHIVED_PAGE_ALERT =
  'CLOSE_SEARCH_ARCHIVED_PAGE_ALERT'
