import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ShowIf } from 'components/ShowIf'
import { NewScenicRoomStyles } from './NewScenicRoomStyles'
import { AccordionSummary, withStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ScenicRoomAccordionSummary = ({
  classes,
  scenicRoomData,
  scenicRoomId,
  handleAlertMessage,
  handleScenicRoomUpdate,
  isCreatePage = false,
  isArchivedRoom = false,
}) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="scenicRoom-content"
      id="scenicRoom-header"
      style={{ minHeight: '70px' }}
    >
      <div className={classes.toolBar}>
        <span className={classes.filterTitle}>
          <Typography variant="h5">Room Info</Typography>{' '}
        </span>
      </div>
      <ShowIf condition={!isCreatePage}>
        <>
          <div style={{ marginLeft: '100px', marginTop: '8px' }}>
            <span>Room ID:</span>{' '}
            <span className={classes.scenicRoomLabel}> {scenicRoomId} </span>
          </div>
          <div style={{ marginLeft: '60px', marginTop: '8px' }}>
            <span>Room Created by:</span>
            <span className={classes.scenicRoomLabel}>
              {' '}
              {scenicRoomData.createdBy}
            </span>
          </div>
          <div className={classes.filler}></div>
          <ShowIf condition={!isArchivedRoom}>
            <div>
              <Button
                id="handleArchive"
                data-id="ARCHIVE"
                // className={clsx(classes.deleteButton)}
                style={{
                  marginRight: '24px',
                  color: '#B85300',
                }}
                variant="outlined"
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  handleAlertMessage(e)
                }}
              >
                Archive
              </Button>
            </div>
          </ShowIf>
        </>
      </ShowIf>
    </AccordionSummary>
  )
}

export default withStyles(NewScenicRoomStyles)(ScenicRoomAccordionSummary)
