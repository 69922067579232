import React from 'react'
import withHOCs from 'util/withHocs'
import * as propTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton } from '@material-ui/core'
import { regexExpressions } from '../AddFilters/constants'
import {
  Grid,
  Button,
  SwipeableDrawer,
  Box,
  Badge,
  Container,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import SettingsIcon from '@material-ui/icons/Settings'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import FilterListIcon from '@material-ui/icons/FilterList'
import { findIndex } from 'lodash'
import Filters from './Filters'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { clearSearchData } from '../../store/search/actionCreator'
import { getData } from '../../store/searchInventory/actionCreator'
import {
  getCurrentPageName,
  getCurrentPageNumber,
  getCurrentPageRowsPerPage,
  getFetchDataRequestPending,
  getFetchedData,
  getColumnData,
  getClickableColumns,
  getNumSelected,
  getCheckBoxEnabled,
  getTotalRowsCount,
  getSelectedData,
  getFetchDataRequestSuccess,
  getRefreshAutoOrderSamples,
} from '../../store/autoOrderSamples/selectors'
import { downloadSamples } from '../../store/searchInventory/actionCreator'
import { TextField } from '@material-ui/core'
import {
  getAutoOrderSamples,
  setRowsPerPage,
  setPage,
  setCheckBoxHeaderChange,
  setCheckBox,
  resetFetchDataRequestSuccess,
  refreshAutoOrderSamples,
  openAutoOrder,
} from '../../store/autoOrderSamples/actionCreator'
import {
  getAutoOrderFilters,
  setIsSavedFilters,
} from '../../store/filters/actionCreator'
import { getUserName } from '../../util/CommonUtils'
import { paramsFromAutoOrderFiltersPost } from '../AddFilters/constants'
import {
  selectPageFilters,
  getIsSavedFilters,
} from '../../store/filters/selectors'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
  },
  filtersContentCls: {
    right: 0,
    left: '40%',
  },
  topMenu: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 40,
  },
})

class AutoOrderSamples extends React.Component {
  constructor(props) {
    super(props)
    const {
      filters = {},
      auth: {
        session: { userInfo = {} },
      },
    } = this.props
    this.state = {
      filters: filters,
      isFilterUpdated: false,
      sortParam: '',
      sortOrder: '',
      anchor: '',
      anchorOpen: false,
      isLoadingFilterData: true,
      searchQuery: '',
    }
    this.userProfilename = getUserName(userInfo)
  }

  componentDidMount = async () => {
    const {
      setHeaderTitleArray = () => {},
      getAutoOrderFilters = () => {},
      resetFetchDataRequestSuccess = () => {},
    } = this.props
    this.defaultParams = {
      status: ['SAMPLE_INITIATED'],
    }
    setHeaderTitleArray([{ title: 'Order Samples' }])
    await resetFetchDataRequestSuccess()
    await getAutoOrderFilters('AutoOrder', { feature: 'AUTO_ORDER' })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page: propsPage,
      rowsPerPage: propsRowsPerPage,
      getAutoOrderSamples = () => {},
      data: propsData = [],
      setPage = () => {},
      fetchDataRequestSuccess,
      resetFetchDataRequestSuccess = () => {},
      refreshAutoOrderSamples,
      refreshAutoOrderSamplesFun = () => {},
      filters: propsFilters = {},
      isSavedFilters,
      setIsSavedFilters = () => {},
    } = this.props
    const {
      filters: stateFilters = {},
      isFilterUpdated: stateIsFilterUpdated,
      sortOrder: stateSortOrder,
      sortParam: stateSortParam,
      anchor = '',
      type = {},
    } = this.state
    const filtersCopy = Object.assign({}, stateFilters, type)
    const {
      page: prevPropsPage,
      rowsPerPage: prevPropsRowsPerPage,
      fetchDataRequestSuccess: prevFetchDataRequestSuccess,
      // filters: prevPropsFilters = {},
    } = prevProps
    const { isFilterUpdated: prevStateIsFilterUpdated } = prevState
    const callAutoOrderSamples =
      propsPage !== prevPropsPage ||
      propsRowsPerPage !== prevPropsRowsPerPage ||
      (!!stateIsFilterUpdated &&
        stateIsFilterUpdated !== prevStateIsFilterUpdated) ||
      refreshAutoOrderSamples
    if (callAutoOrderSamples) {
      const currentPage = stateIsFilterUpdated ? Number(1) : propsPage
      getAutoOrderSamples(
        paramsFromAutoOrderFiltersPost(
          filtersCopy,
          currentPage,
          propsRowsPerPage,
          this.userProfilename,
          this.defaultParams,
          stateSortParam,
          stateSortOrder
        )
      )
    }

    if (refreshAutoOrderSamples) {
      refreshAutoOrderSamplesFun(false)
    }

    if (isSavedFilters) {
      this.setFiltersUpdated(true, propsFilters)
      setIsSavedFilters(false)
    }

    if (
      !!stateIsFilterUpdated &&
      stateIsFilterUpdated !== prevStateIsFilterUpdated
    ) {
      this.setState(
        {
          isFilterUpdated: false,
        },
        () => {
          setPage(1)
        }
      )
    }

    if (
      fetchDataRequestSuccess &&
      fetchDataRequestSuccess !== prevFetchDataRequestSuccess
    ) {
      setTimeout(() => {
        this.setState(() => {
          resetFetchDataRequestSuccess()
          /**
           * anchorOpen is already set to false initially and during filter apply,
           * hence again setting it false will close the filter again if opened after applying filters
           * wrt bug:https://jira.target.com/browse/PEG-2053
           */
          return { ...this.state, anchor: anchor, isLoadingFilterData: false }
        })
      }, 1500)
    }

    if (!propsData.length && propsPage !== 1) {
      setPage(1)
    }
  }

  setFiltersUpdated = (
    isFilterUpdated = false,
    filters = {},
    sortParam,
    sortOrder
  ) => {
    const filtersCopy = Object.assign({}, filters)
    this.setState({
      isLoadingFilterData: true,
      isFilterUpdated: isFilterUpdated,
      filters: filtersCopy,
      sortParam: sortParam,
      sortOrder: sortOrder,
    })
  }

  onSelected = (selectedData, selected) => {
    const { pegasusId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.pegasusId === pegasusId
      }) !== -1
    )
  }

  renderFilters = () => (
    <Box sx={{ width: 'auto', minWidth: 600 }} role="presentation">
      {/* Filters - renders the filter component with header, content */}
      <Filters
        toggleDrawer={this.toggleDrawer}
        setFiltersUpdated={this.setFiltersUpdated}
      />
    </Box>
  )
  onClickAutoOrder = () => {
    const { openAutoOrder = () => {} } = this.props
    openAutoOrder('Auto_Order_Settings')
  }
  handleElasticSearchQuery(searchValue = '1') {
    const { page = Number(0), rowsPerPage = Number(0) } = this.props
    const { sortParam: stateSortParam, sortOrder: stateSortOrder } = this.state
    const { getAutoOrderSamples = () => {} } = this.props
    getAutoOrderSamples(
      paramsFromAutoOrderFiltersPost(
        this.state.type,
        page,
        rowsPerPage,
        this.userProfilename,
        this.defaultParams,
        stateSortParam,
        stateSortOrder
      )
    )
  }
  handleChange = (event) => {
    const searchValue = event.target.value || ''
    const idList = searchValue
      ?.trim()
      .split(/[,\r\n\s]+/g) // Regex to split by space, comma, newline
      ?.filter(String) // removes empty string from search list
    const dpciPattern = regexExpressions['DPCI'] // example: 067-08-5901
    const tcinPattern = regexExpressions['TCIN']
    const upcPattern = regexExpressions['Barcode Number']
    const newObj = {
      TCIN: {
        requestParamValue: '',
        value: '',
      },
      DPCI: {
        requestParamValue: '',
        value: '',
      },
      'Barcode Number': {
        requestParamValue: '',
        value: '',
      },
    }
    idList.map((idListInstance) => {
      let type = ''
      switch (true) {
        case dpciPattern.test(idListInstance):
          type = 'DPCI'
          newObj[type]['requestParamValue'] = newObj[type]['requestParamValue']
            ? newObj[type]['requestParamValue'] + ',' + idListInstance
            : idListInstance
          newObj[type]['value'] = idListInstance
          break
        case tcinPattern.test(idListInstance):
          type = 'TCIN'
          newObj[type]['requestParamValue'] = newObj[type]['requestParamValue']
            ? newObj[type]['requestParamValue'] + ',' + idListInstance
            : idListInstance
          newObj[type]['value'] = idListInstance
          break
        case upcPattern.test(idListInstance):
          type = 'Barcode Number'
          newObj[type]['requestParamValue'] = newObj[type]['requestParamValue']
            ? newObj[type]['requestParamValue'] + ',' + idListInstance
            : idListInstance
          newObj[type]['value'] = idListInstance
          break
        default:
          break
      }
      return {}
    })
    this.setState({
      searchQuery: searchValue,
      type: { ...newObj },
    })
  }
  handleClearClick = () => {
    const { page = Number(0), rowsPerPage = Number(0) } = this.props
    const {
      sortParam: stateSortParam,
      sortOrder: stateSortOrder,
      filters: stateFilters,
    } = this.state
    const { getAutoOrderSamples = () => {} } = this.props
    const filtersCopy = Object.assign({}, stateFilters)
    this.setState({
      searchQuery: '',
      type: {},
    })
    getAutoOrderSamples(
      paramsFromAutoOrderFiltersPost(
        filtersCopy,
        page,
        rowsPerPage,
        this.userProfilename,
        this.defaultParams,
        stateSortParam,
        stateSortOrder
      )
    )
  }
  additionalFiltersContent = () => {
    const { classes = {} } = this.props
    const { filters: stateFilters = {} } = this.state
    const filtersCount = Object.entries(stateFilters).length || Number(0)

    const { searchQuery = '' } = this.state
    return (
      <Container maxWidth={false}>
        <Grid container className={classes.topMenu}>
          <Grid item>
            <TextField
              id="searchTextField"
              type="text"
              size="small"
              variant="outlined"
              value={searchQuery}
              style={{ width: '55vw' }}
              onChange={this.handleChange}
              label="enter TCINs or DPCIs or UPCs"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => this.handleClearClick()}>
                    <ClearIcon
                      sx={{
                        visibility: searchQuery !== '' ? 'visible' : 'hidden',
                      }}
                    >
                      {' '}
                    </ClearIcon>
                  </IconButton>
                ),

                style: {
                  height: 40,
                },
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color="primary"
              startIcon={<SearchIcon />}
              onClick={() => this.handleElasticSearchQuery()}
            >
              Search
            </Button>
          </Grid>
          <Grid item>
            <Badge color="error" badgeContent={filtersCount}>
              <Button
                id="Filters"
                color="primary"
                onClick={this.toggleDrawer('right', true)}
                className={classes.defaultButton}
                startIcon={<FilterListIcon />}
                variant={'outlined'}
                disabled={this.state.isLoadingFilterData}
              >
                Filters
              </Button>
            </Badge>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              component={Link}
              to="/Settings/"
              startIcon={<SettingsIcon />}
              className={classes.filtersContentCls}
              onClick={() => this.onClickAutoOrder()}
            >
              {'AUTO ORDERING SETTINGS'}
            </Button>
          </Grid>
        </Grid>
      </Container>
    )
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (
      (event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')) ||
      this.state.isLoadingFilterData
    ) {
      return
    }
    this.setState({ anchor: anchor, anchorOpen: open })
  }

  render() {
    const {
      fetchDataRequestPending,
      data = [],
      classes = {},
      clickableColumn = [],
      columnData = [],
      numSelected = Number(0),
      checkBoxEnabled,
      page = Number(0),
      totalRowsCount = Number(0),
      rowsPerPage = Number(0),
      setPage = () => {},
      setRowsPerPage = () => {},
      onCheckBoxChange = () => {},
      onCheckBoxHeaderChange = () => {},
      selectedData = [],
      downloadSamples = () => {},
    } = this.props
    const { filters: stateFilters = {}, anchor = 'right' } = this.state
    const dataFieldKeys = columnData.map((column) => column.id)
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <FlexibleTable
              cascadeFieldKeys={[]}
              checkBoxEnabled={checkBoxEnabled}
              columnData={columnData}
              clickableColumn={clickableColumn}
              data={data}
              uniqueIdentifierInData={'tcin'}
              dataFieldKeys={dataFieldKeys}
              editEnabled={false}
              numSelected={numSelected}
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
              onSelected={this.onSelected}
              selected={selectedData}
              paginationAtApiLevel={true}
              page={page}
              rowsPerPage={rowsPerPage}
              totalRowsCount={totalRowsCount}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              isLoading={fetchDataRequestPending}
              downloadFunction={() =>
                downloadSamples(
                  paramsFromAutoOrderFiltersPost(
                    stateFilters,
                    page,
                    rowsPerPage,
                    this.userProfilename
                  )
                )
              }
              additionalActions={true}
              additionalFiltersContent={this.additionalFiltersContent}
              showPopup={() => {}}
            />
          </Grid>
        </Grid>
        {this.state.anchorOpen && (
          <React.Fragment key={anchor}>
            <SwipeableDrawer
              anchor={anchor}
              open={this.state.anchorOpen}
              onClose={() => {}}
              onOpen={this.toggleDrawer(anchor, true)}
            >
              {this.renderFilters()}
            </SwipeableDrawer>
          </React.Fragment>
        )}
      </div>
    )
  }
}

AutoOrderSamples.propTypes = {
  pageName: propTypes.string,
  page: propTypes.number,
  rowsPerPage: propTypes.number,
  fetchDataRequestPending: propTypes.bool,
  data: propTypes.array,
  columnData: propTypes.array,
  clickableColumn: propTypes.array,
  numSelected: propTypes.number,
  checkBoxEnabled: propTypes.bool,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  setRowsPerPage: propTypes.func,
  setPage: propTypes.func,
  selectedData: propTypes.array,
  totalRowsCount: propTypes.number,
  downloadSamples: propTypes.func,
  fetchDataRequestSuccess: propTypes.bool,
}

function mapStateToProps(state) {
  return {
    pageName: getCurrentPageName()(state),
    page: getCurrentPageNumber()(state),
    rowsPerPage: getCurrentPageRowsPerPage()(state),
    fetchDataRequestPending: getFetchDataRequestPending()(state),
    data: getFetchedData()(state),
    columnData: getColumnData()(state),
    clickableColumn: getClickableColumns()(state),
    numSelected: getNumSelected()(state),
    checkBoxEnabled: getCheckBoxEnabled()(state),
    totalRowsCount: getTotalRowsCount()(state),
    selectedData: getSelectedData()(state),
    fetchDataRequestSuccess: getFetchDataRequestSuccess()(state),
    filters: selectPageFilters('AutoOrder')(state),
    isSavedFilters: getIsSavedFilters(state),
    refreshAutoOrderSamples: getRefreshAutoOrderSamples()(state),
    invalidData: state.search.invalidData,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setHeaderTitleArray: (headerTitleArray = []) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    getAutoOrderSamples: (params = {}) => dispatch(getAutoOrderSamples(params)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    onCheckBoxChange: (selected) => {
      dispatch(setCheckBox(selected))
    },
    onCheckBoxHeaderChange: (selected = []) => {
      dispatch(setCheckBoxHeaderChange(selected))
    },
    fetchData: (tcins = [], dpcis = []) => {
      dispatch(getData(tcins, dpcis))
    },
    clearSearchCallback: () => {
      dispatch(clearSearchData())
    },
    downloadSamples: (params) => dispatch(downloadSamples(params)),
    resetFetchDataRequestSuccess: () =>
      dispatch(resetFetchDataRequestSuccess()),
    refreshAutoOrderSamplesFun: (status) => {
      dispatch(refreshAutoOrderSamples(status))
    },
    openAutoOrder: (data) => dispatch(openAutoOrder(data)),
    getAutoOrderFilters: (pageName = '', addParams = {}) =>
      dispatch(getAutoOrderFilters(pageName, addParams)),
    setIsSavedFilters: (isSaved) => dispatch(setIsSavedFilters(isSaved)),
  }
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(AutoOrderSamples)
