import React from 'react'
import * as propTypes from 'prop-types'
import Button from '@material-ui/core/Button/Button'
import copy from 'copy-to-clipboard'
import defaultThumbnail from 'images/defaultThumbnail.jpg'
import DialogBox from '../../components/DialogBox/DialogBox'
import RouterLeavePrompt from '../../components/PopUp/RouterLeavePrompt'
import withHOCs from 'util/withHocs'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { showNotification } from '../../store/notification/actionCreator'
import { isEmpty, cloneDeep } from 'lodash'
import apiConfig from 'apiConfig'
import {
  addSamples,
  downloadConflictSamples,
  closePopup,
  closeErrorPopUp,
  clearSearchData,
  closeAlert,
  deleteShootShipment,
  getShipFromLocation,
  getShipToLocation,
  updateShootShipment,
  removeSamples,
  setPage,
  setCheckBox,
  setCheckBoxHeaderChange,
  setRowsPerPage,
  showAlert,
  showPopup,
  resetNewShipmentState,
  getShootShipment,
  createShootShipment,
  updateSampleFieldsData,
  updateShipmentBundleQuantity,
  downloadShipmentContactSheet,
} from '../../store/newShipment/actionCreator'
import { getPartners } from '../../store/shipSamples/actionCreator'
import { getProjectChannel } from '../../store/newShootType/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import AddSampleAndProps from './AddToShipment'
import NewShipmentAccordion from './ShipmentForms'
import { NewShipmentStyles } from './ShipmentForms/NewShipmentStyles'
import { ShowIf, ShowIfElse } from 'components/ShowIf'
import ShipmentTableData from './ShipmentTableData'
import { Divider, Grid, Paper, SwipeableDrawer } from '@material-ui/core'
import { PROPS, SAMPLE, SCENIC } from 'enums/Tabs'
import BundledPropsQuantity from './ShipmentForms/BundledPropsQuantity'
import ViewShipmentAccordion from './ShipmentForms/ViewShipmentAccordion'
import { Delete } from '@material-ui/icons'
import CircularPageLoader from 'components/Loader/CircularPageLoader'
import { ArrowBack } from '@mui/icons-material'
import { getDisplayThumbnailList } from 'util/CommonUtils'
import BottomBar from 'components/BottomBar'

class NewShipmentPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      callAction: 'PENDING',
      page: 0,
      rowsPerPage: 20,
      tooltipIsOpen: false,
      isAddSamplesSliderOpen: false,
      isShipmentInfoReadOnly: true,
      isReuseShipment: false,
      shipmentData: this.props.shipmentData
        ? this.props.shipmentData
        : {
            carrier: '',
            shipmentId: null,
            shipFromLocId: '',
            shipFromLocName: '',
            shipToLocId: '',
            shipToLocName: '',
            shipToLocation: {
              location_id: '',
              building: '',
            },
            shipmentName: '',
            shipmentChannel: '',
            shipmentCreator: '',
            status: '',
            otherContacts: '',
            tracking: '',
            notes: '',
            shipmentPartners: [],
            marketingPid: [],
            project: {
              name: '',
              id: null,
              type: '',
              location: {
                building: '',
              },
              shootStartDate: '',
              shootEndDate: '',
              marketing_pid: '',
              notes: '',
            },
          },
      sampleidsCount: 0,
    }
  }

  componentDidMount = () => {
    const {
      getShipFromLocation,
      getShipToLocation,
      getProjectChannel,
      getPartners,
    } = this.props
    this.triggerActionBasedOnURI()
    this.setShootTypeHeader()
    getShipFromLocation()
    getShipToLocation()
    getProjectChannel()
    getPartners()
    window.addEventListener('beforeunload', this.beforeUnload)
  }
  // Set breadcrumb based on page type
  setShootTypeHeader = () => {
    const {
      setHeaderTitleArray,
      match: { params: { pageId: shipmentID = '' } = {} } = {},
      history: { location: { pathname: currentURI = '' } = {} } = {},
      subPageName = '',
    } = this.props
    switch (subPageName) {
      case 'edit':
      case 'view':
        setHeaderTitleArray([
          { title: `Ship Samples`, link: `/${'shipSamples'}` },
          { title: `Shipment ${shipmentID}`, link: currentURI },
        ])
        break
      case 'create':
        setHeaderTitleArray([
          { title: `Ship Samples`, link: `/${'shipSamples'}` },
          { title: 'Create Shipment' },
        ])
        break
      default:
        break
    }
  }

  triggerActionBasedOnURI = () => {
    const {
      subPageName = '',
      match: { params: { pageId: shipmentId = '' } = {} } = {},
      getShootShipment = () => {},
    } = this.props

    if (subPageName === 'edit' || subPageName === 'view') {
      // Don't create shipment immediately once the user lands on create shipment page
      getShootShipment(shipmentId)
    }
  }

  handleAlertMessage = (e) => {
    const { showAlert } = this.props

    this.setState({ callAction: e.currentTarget.dataset.id })

    let message = ''
    if (e.currentTarget.dataset.id === 'DELETE') {
      message =
        'Are you sure you want to delete this shipment? All samples will be released from their hold and all shipping info will be removed.'
    } else {
      message =
        'Once you have selected “Mark as Shipped” you will no longer be able to edit or delete this shipment. If you are not ready to mark these samples as “Shipped”, Cancel and select the HOLD FOR PHOTOGRAPHY option.'
    }
    showAlert(message)
  }

  /**
   * On Successful shipment reroute based on updatedType
   * @param {*} updatedType
   * @param {*} status
   */
  onShootTypeUpdateCB = (updatedType = '', status = '', shipment = {}) => {
    if (!isEmpty(updatedType)) {
      let newRoute = ''
      const { shoot_shipment_id = '' } = shipment
      switch (status) {
        case 'create':
        case 'update':
          newRoute = `/shipSamplesList/${shoot_shipment_id}/edit`
          break
        case 'delete':
          newRoute = `/${updatedType}`
          break
        default:
          newRoute = `/${updatedType}`
      }
      this.handleReRoute(newRoute)
    }
  }

  handleShipmentCreateAndUpdate = async (e) => {
    const {
      onShipmentUpdate,
      shipmentId,
      subPageName = '',
      createShootShipment,
    } = this.props
    const { onShootTypeUpdateCB = () => {} } = this
    const isCreatePage = subPageName === 'create'
    const isEditPage = e?.currentTarget?.dataset?.id === 'EDITED'

    const {
      shipFromLocId,
      shipFromLocName,
      shipToLocation,
      shipmentName,
      shipmentChannel,
      otherContacts,
      carrier,
      tracking,
      notes,
      project,
      marketingPid = [],
      shipmentPartners = [],
    } = this.state.shipmentData
    // TODO handle NOPROMPT
    const shipmentStatus = isEditPage
      ? 'EDITED'
      : this.state.callAction === 'NOPROMPT'
      ? 'PENDING'
      : this.state.callAction

    const updateDetails = {
      shipFromLocId: shipFromLocId,
      shipFromLocName,
      shipToLocation,
      shipmentName,
      shipmentChannel,
      otherContacts, // TODO: remove otherContacts
      carrier,
      tracking,
      notes,
      status: shipmentStatus, // CREATED or NOPROMPT or PENDING status
      projectId: project ? project.id : null,
      marketingPid: marketingPid,
      shipmentPartners: shipmentPartners,
    }

    if (isCreatePage) {
      createShootShipment(updateDetails, onShootTypeUpdateCB)
    } else {
      await onShipmentUpdate(shipmentId, updateDetails, onShootTypeUpdateCB)
      // GET samples and shipment after shipment update, since samples status will be updated
      this.triggerActionBasedOnURI()
      this.setState({ isShipmentInfoReadOnly: true, isReuseShipment: false })
    }
  }

  onAlertAgree = (toDoAction = '') => {
    if (toDoAction === 'DELETE') {
      const { handleShipmentDelete, shipmentId } = this.props
      const { onShootTypeUpdateCB = () => {} } = this
      this.setState({ callAction: 'NOPROMPT' }, () => {
        handleShipmentDelete(shipmentId, onShootTypeUpdateCB)
      })
    } else {
      this.handleShipmentCreateAndUpdate()
    }
    this.onAlertClose()
  }

  onAlertClose = (toDoAction = '') => {
    if (toDoAction !== 'SHIPPED') {
      const { closeAlert = () => {} } = this.props
      this.setState({ callAction: 'PENDING' }, () => {
        closeAlert()
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shipmentData !== this.props.shipmentData) {
      this.setState({ shipmentData: cloneDeep(this.props.shipmentData) })
    }
  }

  componentWillUnmount() {
    this.props.resetNewShipmentState()
    window.removeEventListener('beforeunload', this.beforeUnload)
  }

  beforeUnload = (e) => {
    e.preventDefault()
    return (e.returnValue = 'new shipment')
  }

  getSamplesCount = (sampleCount) => {
    this.setState({
      sampleidsCount: sampleCount,
    })
  }

  handleReRoute = (routeUrl = '') => {
    this.props.navigate(routeUrl)
  }

  renderBottomBar = () => {
    const {
      classes = {},
      selectedData = [],
      onEditChange = () => {},
      shipmentId,
    } = this.props
    const numSelected = selectedData?.length || Number(0)

    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={numSelected > 0}
        onOpen={() => null}
        onClose={() => null}
        showClearAll={numSelected > 0}
        actionType={'REST_ALL_NEWSHIPMENT'}
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          <Grid container className={classes.swipeableDrawer} spacing={4}>
            <Grid item>
              <p className={classes.SwipeableDrawerElements}>
                Number of Selected Items: {numSelected}
              </p>
            </Grid>
            <Grid item>
              <Button
                className={classes.removeButton}
                onClick={() => onEditChange(selectedData, shipmentId)}
                variant="outlined"
                startIcon={<Delete />}
              >
                Remove Items
              </Button>
            </Grid>
          </Grid>
        </div>
      </BottomBar>
    )
  }

  render() {
    const {
      alertIsVisible,
      alertMessage,
      classes = {},
      clearSearch,
      closePopup,
      checkBoxEnabled,
      fetchSamples,
      handleChipClick,
      unknownPegasusIds,
      numSelected,
      onClick,
      onEditChange,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      projectNames,
      page,
      popupIsVisible,
      popupItem = {},
      rowsPerPage,
      response,
      selectedData,
      setPage,
      setRowsPerPage,
      showPopup,
      shipmentId,
      shipmentStatus,
      unavailableSamplePopUpIsVisible,
      clickableColumn,
      unavailableSamples = [],
      addSamplesRequestPending = false,
      updateSampleFieldsData,
      unavailableSampleColumnData,
      unavailableSampleLocStatusData,
      subPageName = '',
      allPartnersList,
      columnData: sampleColumnData,
      data: tableData,
      propsColumnData,
      updateShipmentBundleQuantity,
      auth: { isAuthorized = () => {} } = {},
      downloadConflictSamples,
      downloadContactSheet,
      navigate,
      shipmentGetRequestSuccess = false,
    } = this.props

    const {
      shipmentData = {},
      sampleidsCount,
      isAddSamplesSliderOpen,
      isShipmentInfoReadOnly,
      isReuseShipment,
    } = this.state
    const { shipFromLocName = '' } = shipmentData
    const isShippedShipment = shipmentStatus === 'SHIPPED'

    let chipData = unknownPegasusIds?.map((data, index) => ({
      label: data,
      key: index,
    }))
    let data = cloneDeep(tableData).sort((a, b) =>
      new Date(a.addedToShipmentDateTime).getTime() <
      new Date(b.addedToShipmentDateTime).getTime()
        ? 1
        : -1
    )

    let columnData = [...sampleColumnData]

    const isAuthorizedToViewProps = isAuthorized(apiConfig.roles.viewProps) || isAuthorized(apiConfig.roles.scenic)
    if (isAuthorizedToViewProps) {
      columnData = [...sampleColumnData, ...propsColumnData]
      const isShipmentEditable = isShippedShipment ? isReuseShipment : true

      data = cloneDeep(data)?.map((instanceData = {}) => {
        const {
          sampleImagePopupUrl = [],
          type = SAMPLE,
          propDescription = '',
          shipmentBundleCount = 1,
          pegasusId,
          isBundle = false,
          isShippedEntity = false,
          scenicDescription = ''
        } = instanceData
        if (type === PROPS || type === SCENIC) {
          return Object.assign({}, instanceData, {
            description: propDescription || scenicDescription,
            // propsImageDPURL: ??
            imageUrl: (
              <div id="newPopupImage" className={classes.zoomCls}>
                {sampleImagePopupUrl?.length ? (
                  getDisplayThumbnailList(sampleImagePopupUrl, true)
                ) : (
                  <img
                    height={80}
                    width={80}
                    src={'imageUrl'}
                    alt="Not Found"
                    onError={(e) => {
                      e.target.src = defaultThumbnail
                    }}
                  />
                )}
              </div>
            ),
            shipmentBundleCount: (
              <BundledPropsQuantity
                bundledQuantity={shipmentBundleCount}
                pegasusId={pegasusId}
                isBundle={isBundle}
                // Disabled for non shipped until user press Reuse Shipment
                isReadOnly={isShippedEntity || !isShipmentEditable}
                updateBundleQuantity={(pegasusId, updatedBundledQuantity) =>
                  updateShipmentBundleQuantity(
                    shipmentId,
                    pegasusId,
                    updatedBundledQuantity
                  )
                }
              />
            ),
          })
        } else {
          instanceData.shipmentBundleCount = <>N/A</>
        }
        return instanceData
      })
    }

    // NOTE: Logic to check if shipment has samples from different current locations,
    // Since reuse shipment is allowed, there could be samples from different locations
    // if (shipmentUpdateRequestFailure) {
    //   const updatedData = data.map((obj = {}) => {
    //     const { building: objLoc = '' } = obj
    //     const newObj = Object.assign({}, obj, {
    //       isAnErrorRow: objLoc !== shipFromLocName,
    //     })
    //     return newObj
    //   })
    //   data = sortMyArray(updatedData, 'isAnErrorRow')
    // }
    // const shipFromLocSampleExists = data.some((obj) => !!obj.isAnErrorRow)

    const isCreatePage = subPageName === 'create'

    const routerLeaveMessage = `${'Before leaving this shipment, please "HOLD FOR PHOTOGRAPHY", "Mark as Shipped", or "Delete" this shipment so that Pegasus retains accurate data about these samples.'}`
    const setIsAddSamplesSliderOpen = (newValue) =>
      this.setState({ isAddSamplesSliderOpen: newValue })

    if (!shipmentGetRequestSuccess && !isCreatePage) {
      return <CircularPageLoader open={true} />
    }

    const hasAnySampleOrPropAdded = tableData.length > 0

    const hasUnCommitedSampleOrProp = tableData.some(
      (data) => !data.addedToShipmentDate
    )

    const isReRoutingBlocked =
      subPageName !== 'create' &&
      hasUnCommitedSampleOrProp &&
      this.state.callAction !== 'NOPROMPT'

    return (
      <div style={{ margin: '0px 28px' }}>
        <div className={classes.pageHeader}>
          <ShowIfElse
            condition={isCreatePage}
            success={
              <div className={classes.shipmentPageHeaderText}>
                Create Shipment
              </div>
            }
            fail={
              <div className={classes.pageHeaderText}>
                <Button
                  id="handleBackToShipment"
                  data-id="BACK_TO_SHIPMENTS"
                  className={classes.defaultButton}
                  style={{ marginLeft: '20px', float: 'right' }}
                  variant="text"
                  color="primary"
                  startIcon={<ArrowBack />}
                  onClick={() => this.handleReRoute('/shipSamples')}
                >
                  BACK TO SHIPMENTS
                </Button>
              </div>
            }
          />
        </div>
        <ShowIfElse
          condition={isShipmentInfoReadOnly && subPageName !== 'create'}
          success={
            <ViewShipmentAccordion
              classes={classes}
              shipmentData={shipmentData}
              showReuseShipment={isShippedShipment && !isReuseShipment}
              setEditShipment={() =>
                this.setState({ isShipmentInfoReadOnly: false })
              }
              shipmentAccordionSummary={
                <NewShipmentAccordion.ShipmentAccordionSummary
                  classes={classes}
                  shipmentId={shipmentId}
                  hasUnCommitedSampleOrProp={hasUnCommitedSampleOrProp}
                  hasAnySampleOrPropAdded={hasAnySampleOrPropAdded}
                  handleAlertMessage={this.handleAlertMessage.bind(this)}
                  handleShipmentUpdate={this.handleShipmentCreateAndUpdate}
                  shipmentData={shipmentData}
                  isCreatePage={isCreatePage}
                  showReuseShipment={isShippedShipment && !isReuseShipment}
                  setIsReuseShipment={() => {
                    this.setState({ isReuseShipment: true })
                  }}
                />
              }
            />
          }
          fail={
            <NewShipmentAccordion
              shipmentAccordionSummary={
                <NewShipmentAccordion.ShipmentAccordionSummary
                  shipmentId={shipmentId}
                  hasUnCommitedSampleOrProp={hasUnCommitedSampleOrProp}
                  hasAnySampleOrPropAdded={hasAnySampleOrPropAdded}
                  handleAlertMessage={this.handleAlertMessage.bind(this)}
                  handleShipmentUpdate={this.handleShipmentCreateAndUpdate}
                  shipmentData={shipmentData}
                  isCreatePage={isCreatePage}
                />
              }
              newShipmentForm={
                <NewShipmentAccordion.NewShipmentForm
                  allPartnersList={allPartnersList}
                  shipmentData={shipmentData}
                  projectNames={projectNames}
                  shipFromLocName={shipFromLocName}
                  shipToLocations={this.props.shipToLocations}
                  channels={this.props.channels}
                  isCreatePage={isCreatePage}
                  onCancel={() => {
                    if (isCreatePage) {
                      navigate('/shipSamples')
                      return
                    }
                    this.setState({ isShipmentInfoReadOnly: true })
                  }}
                  handleShipmentUpdate={this.handleShipmentCreateAndUpdate}
                  shipFromLocations={this.props.shipFromLocations}
                  updateShipment={(fieldName, newValue) => {
                    this.setState((prevState) => ({
                      shipmentData: {
                        ...prevState.shipmentData,
                        [fieldName]: newValue,
                      },
                    }))
                  }}
                />
              }
            />
          }
        />

        <ShowIf
          condition={subPageName !== 'create' && !isEmpty(shipFromLocName)}
        >
          <Paper style={{ marginTop: '16px' }}>
            <div
              style={{
                display: 'grid',
              }}
            >
              <Button
                id="openAddSamplesSliderBtn_shipsample"
                color="primary"
                onClick={() => setIsAddSamplesSliderOpen(true)}
                startIcon={<AddCircleOutlineIcon />}
                variant={'contained'}
                style={{
                  margin: '16px 16px 16px auto',
                }}
                disabled={
                  isShippedShipment &&
                  isShipmentInfoReadOnly &&
                  !isReuseShipment
                }
              >
                ADD ITEMS
              </Button>

              <Divider />
            </div>
            <ShipmentTableData
              isShipped={isShippedShipment}
              isReuseShipment={isReuseShipment}
              isShipmentInfoReadOnly={isShipmentInfoReadOnly}
              addSamplesRequestPending={addSamplesRequestPending}
              checkBoxEnabled={checkBoxEnabled}
              columnData={columnData}
              renderData={data}
              onClick={onClick}
              onEditChange={onEditChange}
              numSelected={numSelected}
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
              page={page}
              rowsPerPage={rowsPerPage}
              selectedData={selectedData}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              showPopup={showPopup}
              clickableColumn={clickableColumn}
              downloadContactSheet={downloadContactSheet}
              dataShipmentId={shipmentData.shipmentId}
              popupIsVisible={popupIsVisible}
              popupItem={popupItem}
              closePopup={closePopup}
              updateSampleFieldsData={updateSampleFieldsData}
              classes={classes}
              isAuthorized={isAuthorized}
            />
            {this.renderBottomBar()}
            <AddSampleAndProps
              isAddSamplesSliderOpen={isAddSamplesSliderOpen}
              setIsAddSamplesSliderOpen={setIsAddSamplesSliderOpen}
              searchAndAdd={
                <AddSampleAndProps.SearchAndAdd
                  shipFromLocName={shipFromLocName}
                  fetchSamples={fetchSamples}
                  shipmentId={shipmentId}
                  clearSearch={clearSearch}
                  sampleidsCount={sampleidsCount}
                  unknownPegasusIds={unknownPegasusIds}
                  chipData={chipData}
                  handleChipClick={handleChipClick}
                  userId={this.props.auth.session.userInfo.userId}
                  getSamplesCount={this.getSamplesCount}
                  setIsAddSamplesSliderOpen={setIsAddSamplesSliderOpen}
                  addSamplesResponse={this.props.addSamplesResponse}
                />
              }
              unavailableShipmentSamplesAndProps={
                <AddSampleAndProps.UnavailableShipmentSamplesAndProps
                  numSelected={numSelected}
                  downloadConflictSamples={downloadConflictSamples}
                  classes={classes}
                  popupIsVisible={popupIsVisible}
                  popupItem={popupItem}
                  shipmentData={shipmentData}
                  closePopup={closePopup}
                  unavailableSamplePopUpIsVisible={
                    unavailableSamplePopUpIsVisible
                  }
                  unavailableSamples={unavailableSamples}
                  updateSampleFieldsData={updateSampleFieldsData}
                  unavailableSampleColumnData={unavailableSampleColumnData}
                  unavailableSampleLocStatusData={
                    unavailableSampleLocStatusData
                  }
                  clickableColumn={clickableColumn}
                  shipmentId={shipmentId}
                  auth={this.props.auth}
                  shipFromLocName={shipFromLocName}
                />
              }
            />
          </Paper>
        </ShowIf>

        <DialogBox
          isAlertVisible={alertIsVisible}
          message={alertMessage}
          onClose={this.onAlertClose}
          response={response}
          items={this.state.callAction}
          onAgree={this.onAlertAgree}
          primaryButtonText="Confirm"
          data-testid={'confirmationDialog'}
        />
        <ShowIf condition={isReRoutingBlocked}>
          <RouterLeavePrompt
            history={this.props.history}
            content={routerLeaveMessage}
          />
        </ShowIf>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alertIsVisible: state.newShipment.alertIsVisible,
    alertMessage: state.newShipment.alertMessage,
    columnData: state.newShipment.columnData,
    propsColumnData: state.newShipment.propsColumnData,
    checkBoxEnabled: state.newShipment.checkBoxEnabled,
    channels: state.newShootType.channels,
    data: state.newShipment.data,
    goNextFlag: state.newShipment.goNextFlag,
    headerTitle: state.layout.headerTitle,
    location: state.newShipment.location,
    numSelected: state.newShipment.numSelected,
    onCheckBoxChange: state.newShipment.onCheckBoxChange,
    onCheckBoxHeaderChange: state.newShipment.onCheckBoxHeaderChange,
    onClick: state.newShipment.onClick,
    onEditChange: state.newShipment.onEditChange,
    order: state.newShipment.order,
    orderBy: state.newShipment.orderBy,
    response: state.newShipment.response,
    rowCount: state.newShipment.rowCount,
    rowsPerPage: state.newShipment.rowsPerPage,
    unknownPegasusIds: state.newShipment.unknownPegasusIds,
    shipToLocations: state.newShipment.shipToLocations,
    shipFromLocations: state.newShipment.shipFromLocations,
    allPartnersList: state.shipSamples.allPartnersList,
    shipmentId: state.newShipment.shipmentId,
    shipmentStatus: state.newShipment.shipmentStatus,
    shipmentData: state.newShipment.shipmentData,
    addSamplesResponse: state.newShipment.addSamplesResponse,
    shipmentGetRequestSuccess: state.newShipment.shipmentGetRequestSuccess,
    selectedData: state.newShipment.selectedData,
    showPopup: state.newShipment.showPopup,
    showAlert: state.newShipment.showAlert,
    projectNames: state.newShipment.projectNames,
    page: state.newShipment.page,
    popupIsVisible: state.newShipment.popupIsVisible,
    popupItem: state.newShipment.item,
    unavailableSamples: state.newShipment.unavailableSamples,
    unavailableSampleColumnData: state.newShipment.unavailableSampleColumnData,
    unavailableSampleLocStatusData:
      state.newShipment.unavailableSampleLocStatusData,
    unavailableSamplePopUpIsVisible:
      state.newShipment.unavailableSamplePopUpIsVisible,
    clickableColumn: state.newShipment.clickableColumn,
    shipmentUpdateRequestFailure:
      state.newShipment.shipmentUpdateRequestFailure,
    addSamplesRequestPending: state.newShipment.addSamplesRequestPending,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearSearch: () => {
      dispatch(clearSearchData())
      dispatch(closeErrorPopUp())
    },
    closeAlert: () => dispatch(closeAlert()),
    closeErrorPopUp: () => dispatch(closeErrorPopUp()),
    closePopup: () => {
      dispatch(closePopup())
    },
    fetchSamples: (
      pgIds,
      userId,
      shipmentNumber,
      shootType = '',
      projectName = '',
      projectChannel = '',
      collectionName = '',
      shipFromLocation
    ) => {
      dispatch(addSamples(pgIds, userId, shipmentNumber, shipFromLocation))
    },
    downloadConflictSamples: (
      pgIds,
      userId,
      shipmentNumber = '',
      shipFromLocName = ''
    ) => {
      dispatch(
        downloadConflictSamples(pgIds, userId, shipmentNumber, shipFromLocName)
      )
    },
    getProjectChannel: () => dispatch(getProjectChannel()),
    getShipFromLocation: () => dispatch(getShipFromLocation()),
    getShipToLocation: () => dispatch(getShipToLocation()),
    getPartners: () => dispatch(getPartners()),
    handleShipmentDelete: (shipmentId, callback = () => {}) =>
      dispatch(deleteShootShipment(shipmentId, callback)),
    handleChipClick: (chip) => {
      let c = chip.target.innerText
      copy(c)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    onEditChange: (selectedData, shipmentId) =>
      dispatch(removeSamples(selectedData, shipmentId)),
    onCheckBoxChange: (selectedData) => {
      dispatch(setCheckBox(selectedData))
    },
    onCheckBoxHeaderChange: (selected = []) =>
      dispatch(setCheckBoxHeaderChange(selected)),
    createShootShipment: (shipmentDetails, callback = () => {}) =>
      dispatch(createShootShipment(shipmentDetails, callback)),
    onShipmentUpdate: async (shipmentId, updateDetails, callback = () => {}) =>
      await dispatch(updateShootShipment(shipmentId, updateDetails, callback)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    showAlert: (message) => dispatch(showAlert(message)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    resetNewShipmentState: () => dispatch(resetNewShipmentState()),
    getShootShipment: (data) => dispatch(getShootShipment(data)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
    updateShipmentBundleQuantity: (
      shipmentId,
      pegasusId,
      updatedBundledQuantity
    ) =>
      dispatch(
        updateShipmentBundleQuantity(
          shipmentId,
          pegasusId,
          updatedBundledQuantity
        )
      ),

    downloadContactSheet: (params, downloadType = '', itemType = SAMPLE) => {
      dispatch(downloadShipmentContactSheet(params, downloadType, itemType))
    },
  }
}

NewShipmentPage.propTypes = {
  alertIsVisible: propTypes.bool,
  onShipmentUpdate: propTypes.func,
  callAction: propTypes.string,
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  data: propTypes.array,
  handleShipmentDelete: propTypes.func,
  handleShipmentCreateAndUpdate: propTypes.func,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onClick: propTypes.func,
  selectedData: propTypes.array,
  shipmentId: propTypes.number,
  shipmentName: propTypes.string,
  shipmentChannel: propTypes.string,
  shipFrom: propTypes.string,
  order: propTypes.string,
  orderBy: propTypes.string,
  page: propTypes.number,
  rowCount: propTypes.number,
  rowsPerPage: propTypes.number,
  userId: propTypes.string,
  unavailableSamples: propTypes.array,
  unavailableSampleColumnData: propTypes.array,
  unavailableSampleLocStatusData: propTypes.array,
  unavailableSamplePopUpIsVisible: propTypes.bool,
  clickableColumn: propTypes.array,
}
export default withHOCs({
  auth: true,
  nav: true,
  styles: NewShipmentStyles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(NewShipmentPage)
