import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Clear from '@material-ui/icons/Clear'

const styles = (theme) => ({
  dialog: {
    width: '500px',
  },
  headerText: {
    float: 'left',
    fontSize: '24px',
    fontWeight: 500,
    margin: '24px 0px 15px 25px',
  },
  descriptionText: {
    position: 'relative',
    bottom: '8px',
    fontSize: '12px',
    fontWeight: 'normal',
    margin: '0px 10px 25px 32px',
    cursor: 'pointer',
  },
  clearIcon: {
    float: 'right',
    margin: '24px 28px 0px 0px',
    cursor: 'pointer',
    height: '30px',
    width: '30px',
  },
})

class BarcodeTypePopUp extends React.Component {
  constructor(props) {
    super(props)
    const { barcodeType = 'MULTILINE' } = this.props
    this.state = {
      barcodeType: barcodeType,
    }
  }

  handleSelect = (event) => {
    this.setState({ barcodeType: event.target.value })
  }

  handleSave = () => {
    this.props.setterCallback(this.state.barcodeType)
    this.props.onClose()
  }

  render() {
    const { classes, onClose, isVisible } = this.props
    return (
      <Dialog
        aria-labelledby="Select a barcode type"
        aria-describedby="Radio buttons where you can select either multiline or dymo"
        open={isVisible}
        onClose={onClose}
      >
        <div>
          <span className={classes.headerText}>
            Select Print Format for Labels
          </span>
          <Clear className={classes.clearIcon} onClick={onClose} />
        </div>
        <DialogContent className={classes.dialog}>
          <RadioGroup
            value={this.state.barcodeType}
            onChange={this.handleSelect}
            variant
            style={{ marginLeft: '15px' }}
          >
            <FormControlLabel
              value="MULTILINE"
              label="Label Paper"
              control={<Radio color={'primary'} />}
            />
            <p
              className={classes.descriptionText}
              onClick={this.handleSelect.bind(this, {
                target: { value: 'MULTILINE' },
              })}
            >
              Select this option if you would like to print on either label
              paper or regular letter paper.
            </p>
            <FormControlLabel
              value="DYMO"
              label="Dymo Printer"
              control={<Radio color={'primary'} />}
            />
            <p
              className={classes.descriptionText}
              onClick={this.handleSelect.bind(this, {
                target: { value: 'DYMO' },
              })}
            >
              Select this option if you would like to print to a Dymo label
              printer.
            </p>
          </RadioGroup>
          <Button
            onClick={this.handleSave.bind(this)}
            style={{
              float: 'right',
              width: '127px',
              margin: '10px 4px 20px 0px',
            }}
            color={'primary'}
            variant={'contained'}
          >
            SAVE
          </Button>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(BarcodeTypePopUp)
