import {
  OPEN_SIDE_NAV,
  CLOSE_SIDE_NAV,
  SHOW_AUTH_POPUP,
  SET_HEADER_TITLE,
  SET_HEADER_TITLE_ARRAY,
  OPEN_BARCODE_SIDE_NAV,
  CLOSE_BARCODE_SIDE_NAV,
} from './actionType'

export function openSideNav() {
  return {
    type: OPEN_SIDE_NAV,
  }
}

export function closeSideNav() {
  return {
    type: CLOSE_SIDE_NAV,
  }
}
export function openBarcodeSideNav() {
  return {
    type: OPEN_BARCODE_SIDE_NAV,
  }
}

export function closeBarcodeSideNav() {
  return {
    type: CLOSE_BARCODE_SIDE_NAV,
  }
}

export function showAuthPopup(popupType) {
  return {
    type: SHOW_AUTH_POPUP,
    payload: popupType,
  }
}

export function setHeaderTitle(headerTitle) {
  return {
    type: SET_HEADER_TITLE,
    payload: headerTitle,
  }
}

export function setHeaderTitleArray(headerTitleArray) {
  return {
    type: SET_HEADER_TITLE_ARRAY,
    payload: headerTitleArray,
  }
}
