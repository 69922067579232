import { withStyles } from '@material-ui/core/styles'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import React from 'react'
import { Link } from 'react-router-dom'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import Paper from '@material-ui/core/Paper'
import ViewList from '@material-ui/icons/ViewList'
import History from '@material-ui/icons/History'
import LocalShipping from '@material-ui/icons/LocalShipping'

const styles = (theme) => ({
  card: {
    width: '377px',
    height: '350px',
    padding: '34px 16px',
    cursor: 'pointer',
    boxShadow:
      '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    '&:hover': {
      boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    },
  },
  cardTitle: {
    fontSize: '18px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'center',
    margin: '0px',
  },
  cardText: {
    fontSize: '16px',
    marginTop: '48px',
    textAlign: 'center',
    lineHeight: 1.75,
    letterSpacing: '0.15px',
  },
  icon: {
    color: theme.palette.primary.main,
    width: '85px',
    height: '85px',
    margin: '0px 8px',
  },
  vendorLoginIntro: {
    fontSize: 'xx-large',
    textAlign: 'center',
    marginBottom: '59px',
    marginTop: '59px',
  },
  logo: {
    height: '150px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-20px',
    textDecoration: 'none',
    fill: '#333333',
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    padding: '1%',
    fontSize: 'larger',
    width: '500px',
  },
})

class VendorLoginPage extends React.Component {
  componentDidMount() {
    const { setHeaderTitleArray } = this.props
    setHeaderTitleArray([])
  }

  static propTypes = {
    classes: object,
  }

  render() {
    const { classes } = this.props

    return (
      <div>
        <p className={classes.vendorLoginIntro}>Pegasus Sample Management</p>
        <div style={{ width: '804px', margin: 'auto' }}>
          <Link to="/orderSummary" style={{ textDecoration: 'none' }}>
            <Paper className={classes.card} style={{ float: 'left' }}>
              <p className={classes.cardTitle}>REQUESTED SAMPLES</p>
              <div style={{ margin: '37px 0px', textAlign: 'center' }}>
                <ViewList className={classes.icon} />
              </div>
              <div>
                <p className={classes.cardText}>
                  See the samples Target has requested and let Target know your
                  samples are on the way.
                </p>
              </div>
            </Paper>
          </Link>
          <Link to="/shipmentSummary" style={{ textDecoration: 'none' }}>
            <Paper className={classes.card} style={{ float: 'right' }}>
              <p className={classes.cardTitle}>SAMPLE HISTORY</p>
              <div style={{ margin: '37px 0px', textAlign: 'center' }}>
                <History className={classes.icon} />
                <LocalShipping className={classes.icon} />
              </div>
              <div>
                <p className={classes.cardText}>
                  View the history of past shipments and add missing shipping
                  details.
                </p>
              </div>
            </Paper>
          </Link>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(VendorLoginPage))
