import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import FlexibleTable from 'components/EnhancedTable/FlexibleTable'
import { useSelector, useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { SwipeableDrawer } from '@material-ui/core'
import { textFieldData, headerFieldData } from './BarcodeConstants'
import AppBar from '@material-ui/core/AppBar'
import Box from '@mui/material/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@material-ui/core/TextField'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { columnData } from './BarcodeConstants'
import { Button } from '@material-ui/core'
import { findIndex, first } from 'lodash'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ShowIf from 'components/ShowIf'
import { getAllUsers } from 'services/inventoryService'
import { CloseRounded } from '@mui/icons-material'
import { userNamesResponseToSelect } from 'mappers/inventoryMapper'
import EditPopover from 'components/EditPopover'
import { Autocomplete } from '@material-ui/lab'
import { CustomPaper } from 'util/CommonUtils'
import RenderConfirmModal from '../SearchPage/RenderConfirmModal'
import {
  uploadBatchFile,
  getBatches,
  copyBatch,
  editBatch,
  deleteBatches,
  deleteBatchConfirm,
} from 'store/batchScanner/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import BottomBar from 'components/BottomBar'
const useStyles = makeStyles((theme) => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    left: '0',
    top: '0',
    width: '100%',
  },
  defaultButton: {
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: '20px 269px',
  },
  unAuthContent: {
    textAlign: 'center',
  },
  containerStyle: {
    paddingBottom: 10,
    float: 'right',
  },
  appBar: {
    backgroundColor: 'white',
    position: 'relative',
    height: '35px',
    textAlign: 'center',
    width: '100%',
    float: 'left',
    boxShadow: 'none',
  },
  appBarFlyout: {
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    height: '55px',
    textAlign: 'center',
    width: '100%',
    boxShadow: 'none',
  },
  popoverText: {
    float: 'left',
    color: 'black',
    fontSize: 15,
    fontWeight: 'bold',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '34px',
    margin: '0',
    color: '#CC0000',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    height: '60px',
    alignItems: 'center',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
  },
  subheading: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '1.5',
    margin: '1.8em 10px 2em',
  },
  pageHeader: {
    width: '100%',
    height: '40px',
    position: 'relative',
    marginTop: '5px',
  },
  drawer: {
    '& .MuiDrawer-paperAnchorBottom': {
      position: 'fixed',
      left: 'calc(100% - 900px)',
    },
  },
  uploadButton: {
    maxWidth: '400px',
    width: 'auto',
    margin: theme.spacing(1),
  },
}))
export default function BarcodePage(props) {
  const { barcodeAction = () => {} } = props
  const CHUNK_SIZE = Number(5242880)
  const classes = useStyles()
  const { pathname } = useLocation()
  const dispatchData = useDispatch()
  const [mouseLocationX, setLocationX] = React.useState('')
  const [mouseLocationY, setLocationY] = React.useState(false)
  const [selectedData, setSelectedData] = React.useState([])
  const [iconClick, setIconClick] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [userIds, setUserIds] = React.useState([])
  const [userOptions, setUserOptions] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const { barcodeSideNavIsOpen } = useSelector(
    ({ layout: { barcodeSideNavIsOpen = false } }) => {
      return { barcodeSideNavIsOpen }
    }
  )
  const {
    batchScannerData,
    totalCount,
    fetchDataRequestPending,
    copyBatchScannerData,
    deleteBatchConfirmModal,
  } = useSelector(
    ({
      batchReducer: {
        batchScannerData = [],
        totalCount = Number(0),
        fetchDataRequestPending = false,
        copyBatchScannerData = [],
        deleteBatchConfirmModal = false,
      },
    }) => {
      return {
        batchScannerData,
        totalCount,
        fetchDataRequestPending,
        copyBatchScannerData,
        deleteBatchConfirmModal,
      }
    }
  )
  const initialState = {}
  React.useEffect(() => {
    getAllUsers().then((data) => {
      setUserOptions(userNamesResponseToSelect(data))
    })
    dispatchData(getBatches(page, rowsPerPage))
    pathname.includes('batchScanner') &&
      dispatchData(setHeaderTitleArray([{ title: 'Batch Scanning' }]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    dispatchData(getBatches(page, rowsPerPage, userIds))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage])
  React.useEffect(() => {
    dispatchData(getBatches(1, 10, userIds))
    setPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIds])
  React.useEffect(() => {
    if (copyBatchScannerData && copyBatchScannerData.length > 0) {
      copyData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyBatchScannerData.length])
  const copyData = () => {
    copy(copyBatchScannerData.map((item) => item || '').join('\r\n'), {
      format: 'text/plain',
    })
  }
  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_NAME': {
        let local = state
        local.batch_name_actual = action.name
        return { ...local }
      }
      case 'SET_DATA':
        return { ...action.data }
      default:
        return state
    }
  }
  const [columnDataSelected, dispatch] = useReducer(reducer, initialState)
  const onCopytoClipboard = () => {
    dispatchData(copyBatch(selectedData))
  }
  const onDrop = (acceptedFiles = [], rejectedFiles = []) => {
    if (acceptedFiles.length > Number(1)) {
      setErrorMessage('Only 1 files allowed at a time')
    } else {
      if (rejectedFiles.length < 1) {
        const currentFile = first(acceptedFiles)
        if (currentFile.size > parseInt(CHUNK_SIZE)) {
          setErrorMessage('File size exceeds 5MB')
        } else {
          dispatchData(uploadBatchFile(currentFile))
          setPage(1)
          setRowsPerPage(10)
          setErrorMessage('')
        }
      }
    }
  }
  const onDropRejected = (rejectedFiles = []) => {
    setErrorMessage('One or more files are not a valid')
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.CSV'],
    },
    onDropRejected: onDropRejected,
  })
  const handleChange = (event) => {
    dispatch({ type: 'UPDATE_NAME', name: event.target.value })
  }
  const onCheckBoxChange = (dataValue = []) => {
    const selectedIndex = selectedData
      .map((e) => e.batch_id)
      .indexOf(dataValue.batch_id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedData, dataValue)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedData.slice(1))
    } else if (selectedIndex === selectedData.length - 1) {
      newSelected = newSelected.concat(selectedData.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      )
    }
    setSelectedData([...newSelected])
  }
  const onCheckBoxHeaderChange = (dataValue = []) => {
    let newSelected = []
    if (dataValue.length > selectedData.length) {
      newSelected = [...dataValue]
    } else if (dataValue.length === selectedData.length) {
      newSelected = []
    }
    setSelectedData([...newSelected])
  }
  const onSelected = (selectedData, selected) => {
    const { batch_id = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.batch_id === batch_id
      }) !== -1
    )
  }
  const toggleOpen = () => {
    if (selectedData.length !== 0) {
      return true
    } else {
      return false
    }
  }
  const deleteBatchConfirmFunc = () => {
    dispatchData(deleteBatchConfirm())
  }
  const deleteBatch = () => {
    dispatchData(deleteBatches(selectedData, rowsPerPage, page, userIds))
    dispatchData(deleteBatchConfirm())
    setSelectedData([])
  }
  const additionalFiltersContent = () => {
    return (
      <Grid container justifyContent="flex-start">
        <Typography
          style={{
            marginTop: 35,
            fontWeight: 'bold',
            fontSize: 15,
            color: 'black',
          }}
        >
          Filter By
        </Typography>
        <Autocomplete
          id="aisle"
          style={{ width: 500, margin: 15 }}
          onChange={(event, newValue) => {
            autoCompleteChange(event, newValue)
          }}
          getOptionLabel={(option) => option.label}
          options={userOptions}
          autoComplete
          multiple
          includeInputInList
          filterSelectedOptions
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Imported By"
              id="aisle"
              variant="outlined"
              placeholder="Imported By"
            />
          )}
        />
      </Grid>
    )
  }
  const renderSwipeableDrawer = (constant = {}) => {
    const numSelected = selectedData?.length || Number(0)
    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={numSelected > 0}
        onOpen={() => null}
        onClose={() => null}
        showClearAll={numSelected > 0}
        className={classes.drawer}
        onClearAllClick={() => {
          setSelectedData([])
        }}
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          <Grid container className={classes.swipeableDrawer}>
            <Typography
              variant="body1"
              component="body1"
              className={classes.defaultButton}
            >
              {selectedData.length} Batches Selected
            </Typography>
            <Button
              id="copyToClipboard"
              data-id="Copy To Clipboard"
              className={classes.defaultButton}
              startIcon={<ContentCopyIcon />}
              onClick={onCopytoClipboard}
            >
              Copy To Clipboard
            </Button>
            {pathname.includes('batchScanner') ? (
              <Button
                className={classes.defaultButton}
                startIcon={<DeleteIcon />}
                onClick={() => deleteBatchConfirmFunc()}
              >
                Delete
              </Button>
            ) : (
              <div></div>
            )}
          </Grid>
        </div>
      </BottomBar>
    )
  }

  const renderTable = () => {
    return (
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          {deleteBatchConfirmModal && (
            <RenderConfirmModal
              open={deleteBatchConfirmModal}
              alertMessage={'Confirmation'}
              message={`Are You Sure You Want to Delete the Batch?`}
              onConfirm={() => deleteBatch()}
              onClose={() => dispatchData(deleteBatchConfirm())}
            />
          )}
          <Button
            id="rsf_uploadcsv"
            color="primary"
            onClick={() => {}}
            className={classes.defaultButton}
            variant="contained"
            style={{ float: 'right', marginBottom: 10 }}
            {...getRootProps()}
          >
            Import new scanned batch
          </Button>
          <input {...getInputProps()} />
        </Grid>
        {errorMessage !== '' ? (
          <Typography style={{ color: 'red' }}> *{errorMessage}</Typography>
        ) : null}
        {renderEditFlyout()}
      </Grid>
    )
  }
  const autoCompleteChange = (event, value) => {
    let localUserIds = value.map((valueInstance) => valueInstance.value)
    setUserIds(localUserIds)
  }
  const cascadeFieldKeys = ['batch_name']
  const onIconClick = (event, selectedColumnData, keyName, data) => {
    let newObject = {
      [data.batch_id]: true,
    }
    setIconClick(newObject)
    setLocationX(event.clientX)
    setLocationY(event.clientY)
    dispatch({ type: 'SET_DATA', data: selectedColumnData })
  }
  const renderEditFlyout = () => {
    return (
      <Grid item xs={12}>
        <FlexibleTable
          cascadeFieldKeys={cascadeFieldKeys}
          cascadeFieldLogo={<EditIcon style={{ color: 'grey' }} />}
          editFieldKeys={[]}
          checkBoxEnabled
          columnData={columnData}
          data={batchScannerData}
          dataFieldKeys={dataFieldKeys}
          onCheckBoxChange={onCheckBoxChange}
          onCheckBoxHeaderChange={onCheckBoxHeaderChange}
          selected={selectedData}
          numSelected={selectedData.length}
          textRestriction
          onSelected={onSelected}
          cascadeIcon={false}
          onIconClick={onIconClick}
          autoCompleteOptions={userOptions}
          renderAutoComplete
          autoCompleteChange={autoCompleteChange}
          page={page}
          rowsPerPage={rowsPerPage}
          totalRowsCount={totalCount}
          paginationAtApiLevel={true}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          disableTableSorting
          isLoading={fetchDataRequestPending}
          enableSearch
          enableColumnEditKeys={cascadeFieldKeys}
          additionalFiltersContent={additionalFiltersContent}
          additionalActions
          uniqueIdentifierInData='batch_id'
        />
        <ShowIf condition={toggleOpen()}>{renderSwipeableDrawer()}</ShowIf>
      </Grid>
    )
  }

  const saveBatchName = () => {
    dispatchData(editBatch(columnDataSelected, rowsPerPage, page, userIds))
    setIconClick(false)
  }
  const dataFieldKeys = columnData.map((column) => column.id)
  return (
    <div>
      <Grid container>
        {iconClick[columnDataSelected.batch_id] && (
          <div
            style={{
              zIndex: 10000,
            }}
          >
            <EditPopover
              iconClick={iconClick[columnDataSelected.batch_id]}
              setIconClick={setIconClick}
              mouseLocationX={mouseLocationX}
              mouseLocationY={mouseLocationY}
              key={columnDataSelected?.batch_id}
              pathname={pathname}
              fields={[
                <EditPopover.HeaderField
                  headerData={headerFieldData}
                  setIconClick={setIconClick}
                  classes={classes}
                  id={columnDataSelected?.batch_id}
                />,
                <EditPopover.TextField
                  textValue={columnDataSelected?.batch_name_actual || ''}
                  onChange={handleChange}
                  textFieldData={textFieldData}
                  classes={classes}
                  iconClick={iconClick}
                />,
                <EditPopover.Buttons
                  onCloseClick={setIconClick}
                  onSaveClick={saveBatchName}
                  saveLabel="Save"
                  closeLabel="Cancel"
                />,
              ]}
            ></EditPopover>
          </div>
        )}
      </Grid>
      {pathname.includes('batchScanner') ? (
        <Grid>
          <div className={classes.pageHeader}>
            <Typography variant="h4" gutterBottom data-cy="projectsTitle">
              Batch Scanning
            </Typography>
          </div>

          {renderTable()}
        </Grid>
      ) : (
        <Grid container xs={12}>
          <SwipeableDrawer
            anchor="right"
            open={barcodeSideNavIsOpen}
            onClose={() => barcodeAction()}
          >
            <Box
              sx={{
                width: 900,
                overflowY: iconClick[columnDataSelected?.batch_id]
                  ? 'hidden'
                  : 'scroll',
              }}
              role="presentation"
            >
              <Box sx={{ width: 'auto', minWidth: 400 }} role="presentation">
                <AppBar className={classes.appBarFlyout}>
                  <div className={classes.pageHeader}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      data-cy="projectsTitle"
                    >
                      Batch Scanning
                      <IconButton style={{ float: 'right' }}>
                        <CloseRounded
                          style={{ color: 'white' }}
                          onClick={() => barcodeAction()}
                        />
                      </IconButton>
                    </Typography>
                  </div>
                </AppBar>
                {renderEditFlyout()}
              </Box>
            </Box>
          </SwipeableDrawer>
        </Grid>
      )}
    </div>
  )
}
