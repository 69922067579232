import React from 'react'

class External extends React.Component {
  render() {
    return (
      <div>
        <div>
          <div>
            <h1>Please follow below instructions to get Access</h1>
            <div>
              <h2>
                You will need to go to{' '}
                <a
                  href="https://www.partnersonline.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  POL
                </a>{' '}
                in order to set up access to the new Pegasus tool which will be
                used for Sample Management.
                <br />
                Here are some links that might be helpful: <br />
                <br />
                <a
                  href="https://www.partnersonline.com/page/library/VMM/applications/Application%20Training%20Document/95"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Add/Edit a Contact
                </a>{' '}
                – If the contact need POL access, see step 9. <br />
                <a
                  href="https://www.partnersonline.com/page/library/VMM/applications/Application%20Training%20Document/123"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Manage access to Applications
                </a>{' '}
                – These are the steps for adding an application (role) to a
                contact. <br />
                <a
                  href="https://www.partnersonline.com/page/library/VMM/applications/Application%20Training%20Document/517"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Manage Functional Responsibilities
                </a>{' '}
                – This is how the admin can add Sample Management to the
                contact. <br />
                <br />
              </h2>
              <h2>Thanks! </h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default External
