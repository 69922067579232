import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, filter, findIndex } from 'lodash'
import withHOCs from 'util/withHocs'
import Grid from '@material-ui/core/Grid'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getSizes } from '../../store/autoOrderSamples/actionCreator'
import RenderConfirmModal from './RenderConfirmModal'
import {
  RenderEditFieldsAutoOrder,
  RenderSelectionFields,
  locationList,
} from './EditAutoOrderConstants'
import { getClassByDepartment } from '../../services/itemService'
import { departmentClassResponseToSelect } from '../../mappers/inventoryMapper'
import { editSubmit } from '../../store/autoOrderFilters/actionCreator'
import { CustomPaper } from '../../util/CommonUtils'
import { Chip } from '@mui/material'
const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    minWidth: '1200px',
    width: 'auto',
  },
  chipFilterTextField: {
    width: '100%',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  rootDiv: {
    width: '400px',
    display: 'flex',
    flexFlow: 'column wrap',
    marginTop: 20,
    marginBottom: 20,
  },
  appBarToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    // zIndex: 3200,
  },
  appBar: {
    zIndex: '3200 !important',
  },
  errorIcon: {
    color: '#B85300',
  },
  successIcon: {
    color: 'green',
  },
  hr: {
    background: 'grey',
    color: 'grey',
    height: '1px',
    width: '100vw',
  },
  errorChip: {
    margin: theme.spacing(1 / 2),
    color: '#B85300 !important',
    border: '1px solid #B85300 !important',
    fontWeight: 700,
  },
  CancelButton: {
    marginRight: 10,
  },
})
const EditAutoOrderSamplesFlyout = (props) => {
  const dispatch = useDispatch()
  const {
    classes = {},
    closePopup = () => {},
    allDepartmentsOptions = [],
    data = [],
  } = props
  const { sizeData } = useSelector(
    ({ autoOrderSamples: { sizeData = '' } }) => {
      return { sizeData }
    }
  )
  const [selectedLocation, setselectedLocation] = useState('')
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [renderConfirm, setRenderConfirm] = useState(false)
  const [selectedClass, setSelectedClass] = useState([])
  const [quantity, setQuantity] = useState([])
  const [classOptions, setClassOptions] = useState([])
  const [requestBody, setRequestBody] = useState([])
  const onCloseClick = (event) => {
    closePopup(false)
  }
  useEffect(() => {
    dispatch(getSizes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    !isEmpty(selectedDepartment) &&
      selectedDepartment.id !== '' &&
      getClassOptions().then((selectData) => {
        selectData.unshift({
          label: 'ALL',
          value: 'ALL',
        })
        setClassOptions(selectData)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment])
  useEffect(() => {
    const defaultArray = filter(data, function (selectedObject) {
      return (
        selectedObject.departmentId === selectedDepartment?.id &&
        selectedObject.classId === selectedClass[0]?.value
      )
    })
    const defaultValue =
      selectedClass.length === 1 ? defaultArray[0]?.size.split(',') || [] : []
    setQuantity(defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment, selectedClass])
  const getClassOptions = () => {
    return getClassByDepartment(selectedDepartment.id).then((data) => {
      return departmentClassResponseToSelect(data)
    })
  }
  const renderAppbar = (content = '') => {
    return (
      <AppBar
        position="sticky"
        color="transparent"
        elevation={1}
        sx={{
          bottom: 0,
          fontWeight: 500,
          backgroundColor: '#ffffff',
          color: '#333333',
        }}
      >
        <Toolbar className={classes.appBarToolBar}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} component="div">
            {content}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseClick}
            sx={{ color: '#333333' }}
          >
            <CancelIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
  const handleChange = (fieldName = '', newValue = '') => {
    switch (fieldName) {
      case 'size':
        return getQuantity(newValue)
      case 'Location':
        return setselectedLocation(newValue)
      case 'department':
        return setSelectedDepartment(newValue)
      case 'class':
        return setSelectedClass(newValue)
      default:
        return ''
    }
  }
  const getQuantity = (quantityValue = []) => {
    let quantityArray = []
    quantityValue.map((quantityValueInstance) => {
      quantityArray.push(quantityValueInstance?.name || quantityValueInstance)
      return {}
    })
    setQuantity(quantityArray)
  }
  const getFieldOptions = (renderValue = '') => {
    switch (renderValue) {
      case 'Location':
        return locationList
      case 'department':
        return allDepartmentsOptions
      case 'class':
        return classOptions
      case 'size':
        return sizeData
      default:
        return ''
    }
  }
  const getFieldValue = (renderValue = '') => {
    switch (renderValue) {
      case 'Location':
        return selectedLocation
      case 'department':
        return selectedDepartment
      case 'class':
        return selectedClass
      case 'size':
        return quantity
      default:
        return ''
    }
  }
  const renderAutoComplete = (renderEditFieldObject) => {
    const { renderValue = '', label = '' } = renderEditFieldObject
    const fieldValue = getFieldValue(renderValue)
    const fieldOptions = getFieldOptions(renderValue)
    return (
      <Grid item xs={12} style={{ margin: '15px 25px' }}>
        <Autocomplete
          id="aisle"
          style={{ width: 'auto' }}
          multiple={renderValue === 'class' ? true : false}
          onChange={(event, newValue) => {
            handleChange(renderValue, newValue)
          }}
          PaperComponent={CustomPaper}
          getOptionLabel={
            renderValue !== 'department'
              ? (option) => option.label
              : (option) => option.displayName
          }
          renderTags={(value, getTagProps) =>
            renderValue === 'class' &&
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={
                  renderValue !== 'department'
                    ? option.label
                    : option.displayName
                }
                {...getTagProps({ index })}
              />
            ))
          }
          options={fieldOptions}
          autoComplete
          includeInputInList
          value={fieldValue}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      </Grid>
    )
  }
  const renderInputField = (renderEditFieldObject) => {
    const { renderValue = '', label = '' } = renderEditFieldObject
    const fieldValue = quantity
    return renderValue === 'size' ? (
      <Grid item xs={12} style={{ margin: '15px 25px' }}>
        <Autocomplete
          multiple
          id="aisle"
          defaultValue={{}}
          options={sizeData}
          value={quantity}
          autoComplete
          includeInputInList
          getOptionLabel={(option) => option?.name || 'option'}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          onChange={(event, values) => {
            handleChange(renderValue, values)
          }}
          renderInput={(params) => <TextField {...params} label="Size" />}
        />
      </Grid>
    ) : (
      <Grid item xs={12} style={{ margin: '15px 25px' }}>
        <TextField
          label={label}
          value={fieldValue}
          onChange={(e, newValue) => handleChange(renderValue, e)}
        />
      </Grid>
    )
  }
  let indexAll = findIndex(selectedClass, function (o) {
    return o.value === 'ALL'
  })
  const onSubmitEditClick = () => {
    let returnArray = []
    let requestBody = {}

    const { id: department_id } = selectedDepartment
    const { label: location_label } = selectedLocation

    let renderDataOptions =
      indexAll === -1
        ? selectedClass
        : filter(classOptions, function (filterValue) {
            return filterValue.value !== 'ALL'
          })
    renderDataOptions.map((selectedClassInstance) => {
      requestBody = {}
      const { value: selected_class } = selectedClassInstance
      requestBody['department_id'] = department_id
      requestBody['class_id'] = selected_class
      requestBody['size'] = quantity
      requestBody['ship_to_location'] = location_label
      returnArray.push(requestBody)
      return {}
    })
    setRequestBody([...returnArray])
    selectedClass.length > 1 || indexAll !== -1
      ? setRenderConfirm(true)
      : handleConfirm(returnArray, false)
  }
  const onCloseModal = () => {
    setRenderConfirm(false)
  }
  const handleConfirm = (requestBodyLocal = [], fromModal = true) => {
    const { refreshAutoOrderGrid = () => {} } = props
    const requestBodyData = fromModal ? requestBody : requestBodyLocal
    dispatch(editSubmit(requestBodyData, refreshAutoOrderGrid))
  }
  const renderEditFields = (renderFields = []) => {
    let returnComponent = renderFields.map((renderEditFieldObject) => {
      const { renderType = '' } = renderEditFieldObject
      switch (renderType) {
        case 'AutoComplete':
          return renderAutoComplete(renderEditFieldObject)
        case 'Text':
          return renderInputField(renderEditFieldObject)
        default:
          return ''
      }
    })
    return returnComponent
  }
  const disabledSubmit =
    !isEmpty(selectedDepartment) &&
    !isEmpty(selectedClass) &&
    (!isEmpty(quantity) || !isEmpty(selectedLocation))
  return (
    <div>
      {renderConfirm && (
        <RenderConfirmModal
          open={renderConfirm}
          message={`The changes will update all the ${
            indexAll === -1 ? selectedClass.length : ''
          } selected Classes and overwrite any existing data`}
          onConfirm={() => handleConfirm()}
          onClose={() => onCloseModal()}
        />
      )}
      {renderAppbar(' Edit Auto Order Settings')}
      <Grid container className={classes.rootDiv}>
        <Typography
          variant="h7"
          sx={{ fontWeight: 'bold', marginLeft: '6%' }}
          component="div"
        >
          Select Dept-Class to edit for
        </Typography>
        {renderEditFields(RenderSelectionFields)}
        <hr className={classes.hr} />
        <Typography
          variant="h7"
          sx={{ fontWeight: 'bold', marginLeft: '6%' }}
          component="div"
        >
          Enter new value for Ship To Location and/or Size
        </Typography>
        {renderEditFields(RenderEditFieldsAutoOrder)}
        <hr className={classes.hr} />

        <Grid style={{ marginTop: 30, marginLeft: '05%' }}>
          <Button
            variant="outlined"
            style={{ marginLeft: 10 }}
            onClick={onCloseClick}
          >
            Cancel
          </Button>
          <Button
            disabled={!disabledSubmit}
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={onSubmitEditClick}
          >
            update
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default withHOCs({ auth: true, nav: true, styles })(
  EditAutoOrderSamplesFlyout
)
