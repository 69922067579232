/**
 *  Adapted from Material-UI demo on a list of chips.
 *  https://material-ui.com/demos/chips/
 */

import { PureComponent } from 'react'
import * as propTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Chip,
} from '@material-ui/core'
import copy from 'copy-to-clipboard'
import Clear from '@material-ui/icons/Clear'

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(1 / 2),
  },
  errorChip: {
    margin: theme.spacing(1 / 2),
    color: '#B85300',
    border: '1px solid #B85300',
    fontWeight: 700,
  },
  paper: {
    fontSize: 'small',
    padding: '10px',
  },
  title: {
    paddingBottom: '10px',
  },
  clearAll: {
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
})

class ChipsArray extends PureComponent {
  handleActionButtonClick = () => {
    const { data } = this.props
    copy(data.map((item) => item.label).join('\r\n'), {
      format: 'text/plain',
    })
  }

  render() {
    const {
      classes,
      data = [],
      title,
      enableCopyAllButton,
      handleClick,
      textAlign = 'center',
      variant = 'subtitle1',
      onDelete = () => {},
      clearAllRequired = false,
      chipDeleteRequired = false,
      cardContentFromProps,
    } = this.props

    return (
      <Card className={classes.paper}>
        <CardContent>
          <Typography
            variant={variant}
            className={classes.title}
            align={textAlign}
          >
            {title}
          </Typography>
          {cardContentFromProps ? (
            <div align={textAlign}>{cardContentFromProps}</div>
          ) : (
            <div align={textAlign}>
              {data.map((i) => {
                return (
                  <Chip
                    id="chipLabel"
                    className={
                      i.type === 'duplicate' ? classes.errorChip : classes.chip
                    }
                    onClick={handleClick}
                    label={i.label}
                    key={i.key}
                    onDelete={
                      chipDeleteRequired
                        ? () => {
                            onDelete(i.label, i.type)
                          }
                        : undefined
                    }
                    variant="outlined"
                  />
                )
              })}
              {clearAllRequired && data.length > 0 && (
                <Button
                  id="clearall"
                  // color="primary"
                  size="small"
                  onClick={() => {
                    onDelete('', 'all')
                  }}
                  className={classes.clearAll}
                >
                  <Clear className={classes.clearAllIcon} /> CLEAR ALL
                </Button>
              )}
            </div>
          )}
        </CardContent>
        {enableCopyAllButton && (
          <CardActions>
            <Button
              id="copyAllBtn"
              color="primary"
              onClick={this.handleActionButtonClick}
            >
              Copy All To Clipboard
            </Button>
          </CardActions>
        )}
      </Card>
    )
  }
}

ChipsArray.defaultProps = {
  title: '',
  data: [],
  handleClick: () => {},
  enableCopyAllButton: false,
  textAlign: 'center',
  variant: 'subtitle1',
}

ChipsArray.propTypes = {
  title: propTypes.string,
  data: propTypes.array,
  handleClick: propTypes.func,
  enableCopyAllButton: propTypes.bool,
  textAlign: propTypes.string,
  variant: propTypes.string,
  clearAllRequired: propTypes.bool,
  chipDeleteRequired: propTypes.bool,
  onDelete: propTypes.func,
}

export default withStyles(styles)(ChipsArray)
