import React from 'react'
import * as propTypes from 'prop-types'
import Button from '@material-ui/core/Button/Button'
import copy from 'copy-to-clipboard'
import defaultThumbnail from 'images/defaultThumbnail.jpg'
import DialogBox from '../../components/DialogBox/DialogBox'
import withHOCs from 'util/withHocs'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { showNotification } from '../../store/notification/actionCreator'
import { isEmpty, cloneDeep } from 'lodash'
import apiConfig from 'apiConfig'
import {
  addScenic,
  downloadConflictSamples,
  closePopup,
  closeErrorPopUp,
  clearSearchData,
  closeAlert,
  deleteScenicRoom,
  updateScenicRoom,
  removeScenic,
  setPage,
  setCheckBox,
  setCheckBoxHeaderChange,
  setRowsPerPage,
  showAlert,
  showPopup,
  resetNewScenicRoomState,
  getScenicRoom,
  createScenicRoom,
  updateSampleFieldsData,
  downloadScenicRoomContactSheet,
  setInvalidIds,
} from '../../store/scenicRoomItems/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import AddToScenicRoom from './AddToScenicRoom'
import NewScenicRoomAccordion from './ScenicRoomForms'
import { NewScenicRoomStyles } from './ScenicRoomForms/NewScenicRoomStyles'
import { ShowIf, ShowIfElse } from 'components/ShowIf'
import ScenicRoomTableData from './ScenicRoomTableData'
import { Divider, Grid, Paper, SwipeableDrawer } from '@material-ui/core'
import { SAMPLE, SCENIC, STATUS_ARCHIVED } from 'enums/Tabs'
import ViewScenicRoomAccordion from './ScenicRoomForms/ViewScenicRoomAccordion'
import { Delete } from '@material-ui/icons'
import CircularPageLoader from 'components/Loader/CircularPageLoader'
import { ArrowBack } from '@mui/icons-material'
import { getDisplayThumbnailList } from 'util/CommonUtils'
import { fetchScenicMetadata, getAllUsers } from 'services/inventoryService'
import { userNamesResponseToSelect } from 'mappers/inventoryMapper'
import { getSingleScenicRoomWithMetaDataMapping } from 'mappers/scenicRoomMapper'
import { isEqual } from 'lodash'

class NewScenicPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      callAction: 'PENDING',
      page: 0,
      rowsPerPage: 20,
      tooltipIsOpen: false,
      isAddSamplesSliderOpen: false,
      isScenicRoomInfoReadOnly: true,
      users: [],
      metaData: {},
      scenicRoomData: this.props.scenicRoomData
        ? this.props.scenicRoomData
        : {
            roomName: '',
            selectedCateogory: {},
            selectedSubCateogory: {},
            selectedSource: {},
            notes: '',
          },
      sampleidsCount: 0,
    }
  }

  componentDidMount = () => {
    this.triggerActionBasedOnURI()
    this.setShootTypeHeader()
    this.getUsersList()
    this.fetchData()
  }
  // Set breadcrumb based on page type
  setShootTypeHeader = () => {
    const {
      setHeaderTitleArray,
      pageId: scenicRoomId = '',
      history: { location: { pathname: currentURI = '' } = {} } = {},
      subPageName = '',
    } = this.props
    switch (subPageName) {
      case 'edit':
      case 'view':
        setHeaderTitleArray([
          { title: `Scenic Rooms`, link: `/${'scenicRooms'}` },
          { title: `Room ${scenicRoomId}`, link: currentURI },
        ])
        break
      case 'create':
        setHeaderTitleArray([
          { title: `Scenic Rooms`, link: `/${'scenicRooms'}` },
          { title: 'Create Room' },
        ])
        break
      default:
        break
    }
  }

  triggerActionBasedOnURI = () => {
    const {
      subPageName = '',
      pageId: scenicRoomId = '',
      getScenicRoom = () => {},
      page = 1,
      rowsPerPage = 25,
    } = this.props
    if (subPageName === 'edit' || subPageName === 'view') {
      // Don't create scenicRoom immediately once the user lands on create scenicRoom page
      getScenicRoom(scenicRoomId, page, rowsPerPage)
    }
  }

  handleAlertMessage = (e) => {
    const { showAlert } = this.props
    let message = ''
    this.setState({ callAction: e.currentTarget.dataset.id })
    if (e.currentTarget.dataset.id === 'ARCHIVE') {
      message = 'Are you sure you want to Archive this Scenic Room ?'
      showAlert(message)
    }
  }

  /**
   * On Successful scenicRoom reroute based on updatedType
   * @param {*} updatedType
   * @param {*} status
   */
  onRoomUpdateCB = (updatedType = '', status = '', scenicRoom = {}) => {
    if (!isEmpty(updatedType)) {
      let newRoute = ''
      const { room_id = '' } = scenicRoom
      switch (status) {
        case 'create':
        case 'update':
          newRoute = `/scenicRoom/${room_id}/edit`
          break
        case 'delete':
          newRoute = `/${updatedType}`
          break
        default:
          newRoute = `/${updatedType}`
      }
      this.handleReRoute(newRoute)
    }
  }

  handleScenicRoomCreateAndUpdate = async (e, scenicRoomFormData) => {
    const {
      onScenicRoomUpdate,
      pageId: scenicRoomId,
      subPageName = '',
      createScenicRoom,
    } = this.props
    const { onRoomUpdateCB = () => {} } = this
    const isCreatePage = subPageName === 'create'

    if (isCreatePage) {
      createScenicRoom(scenicRoomFormData, onRoomUpdateCB)
    } else {
      await onScenicRoomUpdate(scenicRoomId, scenicRoomFormData, onRoomUpdateCB)
      // GET samples and scenicRoom after scenicRoom update, since samples status will be updated
      this.triggerActionBasedOnURI()
      this.setState({
        isScenicRoomInfoReadOnly: true,
      })
    }
  }

  onAlertAgree = (toDoAction = '') => {
    if (toDoAction === 'ARCHIVE') {
      const { handleScenicRoomDelete, pageId: scenicRoomId } = this.props
      const { scenicRoomData } = this.state
      const { onRoomUpdateCB = () => {} } = this
      handleScenicRoomDelete(scenicRoomId, scenicRoomData, onRoomUpdateCB)
    } else {
      this.handleScenicRoomCreateAndUpdate()
    }
    this.onAlertClose()
  }

  onAlertClose = (toDoAction = '') => {
    const { closeAlert = () => {} } = this.props
    closeAlert()
  }

  componentDidUpdate(prevProps) {
    const { page: prevPropsPage, rowsPerPage: prevRowsPerPage } = prevProps
    const { page: propsPage, rowsPerPage: propsRowsPerPage } = this.props
    if (
      !isEqual(prevPropsPage, propsPage) ||
      !isEqual(prevRowsPerPage, propsRowsPerPage)
    ) {
      this.triggerActionBasedOnURI()
    }
    if (prevProps.scenicRoomData !== this.props.scenicRoomData) {
      this.setState({ scenicRoomData: cloneDeep(this.props.scenicRoomData) })
    }
  }

  componentWillUnmount() {
    this.props.resetNewScenicRoomState()
  }

  getSamplesCount = (sampleCount) => {
    this.setState({
      sampleidsCount: sampleCount,
    })
  }

  handleReRoute = (routeUrl = '') => {
    this.props.navigate(routeUrl)
  }

  getUsersList = async () => {
    const usersResponse = await getAllUsers()
    const allUsers = userNamesResponseToSelect(usersResponse)
    this.setState({
      users: allUsers || [],
    })
  }

  fetchData = async () => {
    try {
      const { data = {} } = await fetchScenicMetadata()
      this.setState({
        metaData: data,
      })
    } catch (e) {
      console.error(e)
    }
  }

  onCloseAddItemSlider = () => {
    const { setInvalidIds = () => {} } = this.props
    this.triggerActionBasedOnURI()
    this.setIsAddSamplesSliderOpen(false)
    setInvalidIds([])
  }

  setIsAddSamplesSliderOpen = (newValue) =>
    this.setState({ isAddSamplesSliderOpen: newValue })

  render() {
    const {
      alertIsVisible,
      alertMessage,
      classes = {},
      clearSearch,
      closePopup,
      checkBoxEnabled,
      fetchSenic,
      handleChipClick,
      unknownPegasusIds,
      numSelected,
      onClick,
      onEditChange,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      page,
      popupIsVisible,
      popupItem = {},
      rowsPerPage,
      response,
      selectedData,
      setPage,
      setRowsPerPage,
      showPopup,
      pageId: scenicRoomId,
      unavailableSamplePopUpIsVisible,
      clickableColumn,
      unavailableScenic = [],
      addSamplesRequestPending = false,
      updateSampleFieldsData,
      unavailableSampleColumnData,
      unavailableSampleLocStatusData,
      subPageName = '',
      columnData: scenicColumnData,
      data: tableData,
      auth: { isAuthorized = () => {} } = {},
      downloadConflictSamples,
      downloadContactSheet,
      navigate,
      scenicRoomGetRequestSuccess = false,
      invalidScenicIds = [],
      showNotification = () => {},
      totalRowsCount = 0,
    } = this.props

    const {
      scenicRoomData = {},
      sampleidsCount,
      isAddSamplesSliderOpen,
      isScenicRoomInfoReadOnly,
      users = [],
      metaData = {},
    } = this.state
    let chipDataUnknownIds = unknownPegasusIds.map((data, index) => ({
      label: data,
      key: index,
    }))
    let chipDataInvalidIds = invalidScenicIds.map((data, index) => ({
      label: data,
      key: index,
    }))
    let data =
      tableData &&
      cloneDeep(tableData).sort((a, b) =>
        new Date(a.addedToScenicRoomDateTime).getTime() <
        new Date(b.addedToScenicRoomDateTime).getTime()
          ? 1
          : -1
      )

    let columnData = [...scenicColumnData]

    const isAuthorizedToViewScenic = isAuthorized(apiConfig.roles.scenic)
    if (isAuthorizedToViewScenic) {
      columnData = [...scenicColumnData]
      data = cloneDeep(data)?.map((instanceData = {}) => {
        const {
          scenicImageUrl = [],
          type = SAMPLE,
          propDescription = '',
        } = instanceData
        if (type === SCENIC) {
          return Object.assign({}, instanceData, {
            description: propDescription,
            // propsImageDPURL: ??
            imageUrl: (
              <div id="newPopupImage" className={classes.zoomCls}>
                {scenicImageUrl?.length ? (
                  getDisplayThumbnailList(scenicImageUrl, true)
                ) : (
                  <img
                    height={80}
                    width={80}
                    src={'imageUrl'}
                    alt="Not Found"
                    onError={(e) => {
                      e.target.src = defaultThumbnail
                    }}
                  />
                )}
              </div>
            ),
          })
        } else {
          instanceData.scenicRoomBundleCount = <>N/A</>
        }
        return instanceData
      })
    }

    const isCreatePage = subPageName === 'create'

    if (!scenicRoomGetRequestSuccess && !isCreatePage) {
      return <CircularPageLoader open={true} />
    }

    const scenicRoomsDataWithMetaDataMapping =
      getSingleScenicRoomWithMetaDataMapping(scenicRoomData, metaData, users)

    return (
      <div style={{ margin: '0px 28px' }}>
        <div className={classes.pageHeader}>
          <ShowIfElse
            condition={isCreatePage}
            success={
              <div className={classes.scenicRoomPageHeaderText}>
                Create Room
              </div>
            }
            fail={
              <div className={classes.pageHeaderText}>
                <Button
                  id="handleBackToScenicRoom"
                  data-id="BACK_TO_ROOMS"
                  className={classes.defaultButton}
                  style={{ marginLeft: '20px', float: 'right' }}
                  variant="text"
                  color="primary"
                  startIcon={<ArrowBack />}
                  onClick={() => this.handleReRoute('/scenicRooms')}
                >
                  BACK TO ROOMS
                </Button>
              </div>
            }
          />
        </div>
        <ShowIfElse
          condition={isScenicRoomInfoReadOnly && subPageName !== 'create'}
          success={
            <ViewScenicRoomAccordion
              classes={classes}
              scenicRoomData={scenicRoomsDataWithMetaDataMapping}
              setEditScenicRoom={() =>
                this.setState({ isScenicRoomInfoReadOnly: false })
              }
              scenicRoomAccordionSummary={
                <NewScenicRoomAccordion.ScenicRoomAccordionSummary
                  classes={classes}
                  scenicRoomId={scenicRoomId}
                  handleAlertMessage={this.handleAlertMessage.bind(this)}
                  handleScenicRoomUpdate={this.handleScenicRoomCreateAndUpdate}
                  scenicRoomData={scenicRoomsDataWithMetaDataMapping}
                  isCreatePage={isCreatePage}
                  isArchivedRoom={scenicRoomData.status === STATUS_ARCHIVED}
                />
              }
            />
          }
          fail={
            <NewScenicRoomAccordion
              scenicRoomAccordionSummary={
                <NewScenicRoomAccordion.ScenicRoomAccordionSummary
                  scenicRoomId={scenicRoomId}
                  handleAlertMessage={this.handleAlertMessage.bind(this)}
                  handleScenicRoomUpdate={this.handleScenicRoomCreateAndUpdate}
                  scenicRoomData={scenicRoomsDataWithMetaDataMapping}
                  isCreatePage={isCreatePage}
                />
              }
              newScenicRoomForm={
                <NewScenicRoomAccordion.NewScenicRoomForm
                  scenicRoomData={scenicRoomData}
                  isCreatePage={isCreatePage}
                  onCancel={() => {
                    if (isCreatePage) {
                      navigate('/scenicRooms')
                      return
                    }
                    this.setState({ isScenicRoomInfoReadOnly: true })
                  }}
                  handleScenicRoomUpdate={this.handleScenicRoomCreateAndUpdate}
                  updateScenicRoom={(fieldName, newValue) => {
                    this.setState((prevState) => ({
                      scenicRoomData: {
                        ...prevState.scenicRoomData,
                        [fieldName]: newValue,
                      },
                    }))
                  }}
                />
              }
            />
          }
        />

        <ShowIf condition={subPageName !== 'create'}>
          <Paper style={{ marginTop: '16px' }}>
            <div
              style={{
                display: 'grid',
              }}
            >
              <Button
                id="openAddSamplesSliderBtn"
                color="primary"
                onClick={() => this.setIsAddSamplesSliderOpen(true)}
                startIcon={<AddCircleOutlineIcon />}
                variant={'contained'}
                style={{
                  margin: '16px 16px 16px auto',
                }}
                disabled={['create', 'view'].indexOf(subPageName) > -1}
              >
                ADD Scenic Items
              </Button>

              <Divider />
            </div>
            <ScenicRoomTableData
              isScenicRoomInfoReadOnly={isScenicRoomInfoReadOnly}
              addSamplesRequestPending={addSamplesRequestPending}
              checkBoxEnabled={checkBoxEnabled}
              columnData={columnData}
              renderData={data}
              onClick={onClick}
              onEditChange={onEditChange}
              numSelected={numSelected}
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
              page={page}
              rowsPerPage={rowsPerPage}
              selectedData={selectedData}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              showPopup={showPopup}
              clickableColumn={clickableColumn}
              downloadContactSheet={downloadContactSheet}
              dataScenicRoomId={scenicRoomData.scenicRoomId}
              popupIsVisible={popupIsVisible}
              popupItem={popupItem}
              closePopup={closePopup}
              updateSampleFieldsData={updateSampleFieldsData}
              classes={classes}
              totalRowsCount={totalRowsCount}
            />

            <SwipeableDrawer
              anchor="bottom"
              variant={'persistent'}
              open={numSelected !== 0}
              onOpen={() => null}
              onClose={() => null}
            >
              <div className={classes.swipeableDrawerElementsContainer}>
                <Grid container className={classes.swipeableDrawer} spacing={4}>
                  <Grid item>
                    <p className={classes.SwipeableDrawerElements}>
                      Number of Selected Items: {numSelected}
                    </p>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.defaultButton}
                      color="primary"
                      onClick={() => onEditChange(selectedData, scenicRoomId)}
                      variant="outlined"
                      startIcon={<Delete />}
                    >
                      Remove Samples
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </SwipeableDrawer>

            <AddToScenicRoom
              isAddSamplesSliderOpen={isAddSamplesSliderOpen}
              onCloseAddItemSlider={this.onCloseAddItemSlider}
              searchAndAdd={
                <AddToScenicRoom.SearchAndAdd
                  fetchSenic={fetchSenic}
                  clearSearch={clearSearch}
                  scenicRoomId={scenicRoomId}
                  sampleidsCount={sampleidsCount}
                  unknownPegasusIds={unknownPegasusIds}
                  chipDataUnknownIds={chipDataUnknownIds}
                  chipDataInvalidIds={chipDataInvalidIds}
                  handleChipClick={handleChipClick}
                  userId={this.props.auth.session.userInfo.userId}
                  getSamplesCount={this.getSamplesCount}
                  setIsAddSamplesSliderOpen={this.onCloseAddItemSlider}
                  addSamplesResponse={this.props.addSamplesResponse}
                  unavailableScenic={unavailableScenic}
                  invalidScenicIds={invalidScenicIds}
                  sampleType={SCENIC}
                  showNotification={showNotification}
                />
              }
              unavailableScenicRoomItems={
                <AddToScenicRoom.UnavailableScenicRoomItems
                  fetchSenic={fetchSenic}
                  clearSearch={clearSearch}
                  setIsAddSamplesSliderOpen={this.onCloseAddItemSlider}
                  numSelected={numSelected}
                  downloadConflictSamples={downloadConflictSamples}
                  classes={classes}
                  popupIsVisible={popupIsVisible}
                  popupItem={popupItem}
                  scenicRoomData={scenicRoomData}
                  closePopup={closePopup}
                  unavailableSamplePopUpIsVisible={
                    unavailableSamplePopUpIsVisible
                  }
                  unavailableScenic={unavailableScenic}
                  updateSampleFieldsData={updateSampleFieldsData}
                  unavailableSampleColumnData={unavailableSampleColumnData}
                  unavailableSampleLocStatusData={
                    unavailableSampleLocStatusData
                  }
                  clickableColumn={clickableColumn}
                  scenicRoomId={scenicRoomId}
                  auth={this.props.auth}
                />
              }
            />
          </Paper>
        </ShowIf>

        <DialogBox
          isAlertVisible={alertIsVisible}
          message={alertMessage}
          onClose={this.onAlertClose}
          response={response}
          items={this.state.callAction}
          onAgree={this.onAlertAgree}
          primaryButtonText="Confirm"
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alertIsVisible: state.scenicRoomItems.alertIsVisible,
    alertMessage: state.scenicRoomItems.alertMessage,
    columnData: state.scenicRoomItems.columnData,
    checkBoxEnabled: state.scenicRoomItems.checkBoxEnabled,
    channels: state.newShootType.channels,
    data: state.scenicRoomItems.data,
    goNextFlag: state.scenicRoomItems.goNextFlag,
    headerTitle: state.layout.headerTitle,
    location: state.scenicRoomItems.location,
    numSelected: state.scenicRoomItems.numSelected,
    onCheckBoxChange: state.scenicRoomItems.onCheckBoxChange,
    onCheckBoxHeaderChange: state.scenicRoomItems.onCheckBoxHeaderChange,
    onClick: state.scenicRoomItems.onClick,
    onEditChange: state.scenicRoomItems.onEditChange,
    order: state.scenicRoomItems.order,
    orderBy: state.scenicRoomItems.orderBy,
    response: state.scenicRoomItems.response,
    rowCount: state.scenicRoomItems.rowCount,
    rowsPerPage: state.scenicRoomItems.rowsPerPage,
    unknownPegasusIds: state.scenicRoomItems.unknownPegasusIds,
    scenicRoomId: state.scenicRoomItems.scenicRoomId,
    scenicRoomStatus: state.scenicRoomItems.scenicRoomStatus,
    scenicRoomData: state.scenicRoomItems.scenicRoomData,
    addSamplesResponse: state.scenicRoomItems.addSamplesResponse,
    scenicRoomGetRequestSuccess:
      state.scenicRoomItems.scenicRoomGetRequestSuccess,
    selectedData: state.scenicRoomItems.selectedData,
    showPopup: state.scenicRoomItems.showPopup,
    showAlert: state.scenicRoomItems.showAlert,
    projectNames: state.scenicRoomItems.projectNames,
    page: state.scenicRoomItems.page,
    popupIsVisible: state.scenicRoomItems.popupIsVisible,
    popupItem: state.scenicRoomItems.item,
    unavailableScenic: state.scenicRoomItems.unavailableScenic,
    invalidScenicIds: state.scenicRoomItems.invalidScenicIds,
    unavailableSampleColumnData:
      state.scenicRoomItems.unavailableSampleColumnData,
    unavailableSampleLocStatusData:
      state.scenicRoomItems.unavailableSampleLocStatusData,
    unavailableSamplePopUpIsVisible:
      state.scenicRoomItems.unavailableSamplePopUpIsVisible,
    clickableColumn: state.scenicRoomItems.clickableColumn,
    scenicRoomUpdateRequestFailure:
      state.scenicRoomItems.scenicRoomUpdateRequestFailure,
    addSamplesRequestPending: state.scenicRoomItems.addSamplesRequestPending,
    totalRowsCount: state.scenicRoomItems.totalRowsCount,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearSearch: () => {
      dispatch(clearSearchData())
      dispatch(closeErrorPopUp())
    },
    closeAlert: () => dispatch(closeAlert()),
    closeErrorPopUp: () => dispatch(closeErrorPopUp()),
    closePopup: () => {
      dispatch(closePopup())
    },
    fetchSenic: (pgIds, userId, scenicRoomId, checkRequired = true) => {
      dispatch(addScenic(pgIds, scenicRoomId, checkRequired))
    },
    downloadConflictSamples: (pgIds, userId, scenicRoomId = '') => {
      dispatch(downloadConflictSamples(pgIds, userId, scenicRoomId))
    },
    handleScenicRoomDelete: (
      scenicRoomId,
      scenicRoomData,
      callback = () => {}
    ) => dispatch(deleteScenicRoom(scenicRoomId, scenicRoomData, callback)),
    handleChipClick: (chip) => {
      let c = chip.target.innerText
      copy(c)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    showNotification: (isShown, message, type) =>
      dispatch(showNotification(isShown, message, type)),
    onEditChange: (selectedData, scenicRoomId) =>
      dispatch(removeScenic(selectedData, scenicRoomId)),
    onCheckBoxChange: (selectedData) => {
      dispatch(setCheckBox(selectedData))
    },
    onCheckBoxHeaderChange: (selected = []) =>
      dispatch(setCheckBoxHeaderChange(selected)),
    createScenicRoom: (scenicRoomDetails, callback = () => {}) =>
      dispatch(createScenicRoom(scenicRoomDetails, callback)),
    onScenicRoomUpdate: async (
      scenicRoomId,
      updateDetails,
      callback = () => {}
    ) =>
      await dispatch(updateScenicRoom(scenicRoomId, updateDetails, callback)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    showAlert: (message) => dispatch(showAlert(message)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    resetNewScenicRoomState: () => dispatch(resetNewScenicRoomState()),
    getScenicRoom: (data, page, rowsPerPage) => dispatch(getScenicRoom(data, page, rowsPerPage)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
    downloadContactSheet: (params, downloadType = '', itemType = SAMPLE) => {
      dispatch(downloadScenicRoomContactSheet(params, downloadType, itemType))
    },
    setInvalidIds: (invalidIds = []) => {
      dispatch(setInvalidIds(invalidIds))
    },
  }
}

NewScenicPage.propTypes = {
  alertIsVisible: propTypes.bool,
  onScenicRoomUpdate: propTypes.func,
  callAction: propTypes.string,
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  data: propTypes.array,
  handleScenicRoomDelete: propTypes.func,
  handleScenicRoomCreateAndUpdate: propTypes.func,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onClick: propTypes.func,
  selectedData: propTypes.array,
  scenicRoomId: propTypes.number,
  scenicRoomName: propTypes.string,
  scenicRoomChannel: propTypes.string,
  targetBrand: propTypes.string,
  order: propTypes.string,
  orderBy: propTypes.string,
  page: propTypes.number,
  rowCount: propTypes.number,
  rowsPerPage: propTypes.number,
  userId: propTypes.string,
  unavailableScenic: propTypes.array,
  unavailableSampleColumnData: propTypes.array,
  unavailableSampleLocStatusData: propTypes.array,
  unavailableSamplePopUpIsVisible: propTypes.bool,
  clickableColumn: propTypes.array,
}
export default withHOCs({
  auth: true,
  nav: true,
  styles: NewScenicRoomStyles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(NewScenicPage)
