export const selectPageAvailableFilters =
  (pageName = '') =>
  ({ addFilters = {} }) => {
    const addFiltersCopy = Object.assign({}, addFilters)
    const { availableFilters = [] } = addFiltersCopy
    return availableFilters[pageName] || []
  }
export const selectPageFilters =
  (pageName = '') =>
  ({ addFilters = {} }) => {
    const addFiltersCopy = Object.assign({}, addFilters)
    const { pageFilters = {} } = addFiltersCopy
    return pageFilters[pageName] || {}
  }

export const getIsSavedFilters = ({ addFilters = {} }) => {
  const addFiltersCopy = Object.assign({}, addFilters)
  const { isSavedFilters } = addFiltersCopy
  return isSavedFilters
}

export const getStoreChannels =
  () =>
  ({ newShootType = {} }) => {
    const newShootTypeCopy = Object.assign({}, newShootType)
    const { channels = [] } = newShootTypeCopy
    return channels
  }

export const getLocationDetails =
  () =>
  ({ searchInventory = {} }) => {
    const searchInventoryCopy = Object.assign({}, searchInventory)
    const { locations = [] } = searchInventoryCopy
    return locations
  }
