import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Print from '@material-ui/icons/Print'

import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'

import ShipmentBarcodePage from '../ShipmentBarcodePage/ShipmentBarcodePage'

import {
  selectSort,
  setPage,
  setRowsPerPage,
  showPopup,
  getBarcodes,
  submitShipment,
  submitShipmentRequestSuccess,
} from '../../store/shipmentBarcode/actionCreator'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  typographyGreen: {
    color: 'green',
    textAlign: 'center',
    marginBottom: '40px',
  },
  iconMargin: {
    marginRight: '5px',
  },
  headerPaper: {
    width: '100%',
    padding: '10px',
  },
}))

function getSteps() {
  return ['Print Barcode Labels', 'Shipping & Address', 'Confirmation']
}

function getNextButtonLabels() {
  return ['Next', 'Mark as Shipped', 'Done']
}

export default function VendorShipSamples() {
  const classes = useStyles()
  const shipmentBarcodeStore = useSelector((state) => state.shipmentBarcode)
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = React.useState(0)
  const [printButton, setPrintButton] = React.useState(false)
  const [provideDetailsLater, setProvideDetailsLater] = React.useState(false)

  const steps = getSteps()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const onSortSelectedChange = (orderBy, order) => {
    dispatch(selectSort(orderBy, order))
  }

  const showPopupAction = (item) => {
    dispatch(showPopup(item))
  }

  const getBarcodesAction = (samples, showBarcodesCallback) => {
    dispatch(getBarcodes(samples, showBarcodesCallback))
  }

  const processShipmentAction = (data) => {
    dispatch(submitShipment(data, handleNext))
  }

  const submitShipmentRequestSuccessAction = () => {
    dispatch(submitShipmentRequestSuccess())
  }

  const handleGenerateBarcode = () => {
    const { data = [] } = shipmentBarcodeStore
    getBarcodesAction(data, (barcodes) => {
      const file = new Blob([barcodes], { type: 'application/pdf' })
      let fileURL
      if (window.navigator.msSaveOrOpenBlob) {
        fileURL = window.navigator.msSaveOrOpenBlob(file, 'barcodeLabel.pdf')
      } else {
        fileURL = URL.createObjectURL(file)
        window.open(fileURL).print()
      }
    })

    setPrintButton(true)
  }

  const handleProcessShipment = (event) => {
    const { data = [] } = shipmentBarcodeStore
    processShipmentAction(data)
  }

  const onCompleteProcess = () => {
    submitShipmentRequestSuccessAction()
    handleReset()
  }

  const getStepControls = (stepIndex) => {
    const nextButtonLabels = getNextButtonLabels()
    switch (stepIndex) {
      case 0:
        return (
          <Grid item>
            <Button
              className={classes.backButton}
              color="primary"
              onClick={handleGenerateBarcode}
              variant="outlined"
            >
              <Print className={classes.iconMargin} /> Print New Labels
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!printButton}
              onClick={handleNext}
            >
              {nextButtonLabels[stepIndex]}
            </Button>
          </Grid>
        )
      case 1:
        return (
          <Grid item>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleProcessShipment}
              disabled={disableButton()}
            >
              {nextButtonLabels[stepIndex]}
            </Button>
          </Grid>
        )
      case 2:
        return (
          <Grid item>
            {/* 
            // commenting this until we enable the functionality
            <Button
              className={classes.backButton}
              color="primary"
              onClick={handleGenerateBarcode}
              variant="outlined"
            >
              <Print className={classes.iconMargin} /> Print Packing Slip
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              disabled={!printButton}
              onClick={onCompleteProcess}
              component={Link}
              to="/orderSummary"
            >
              {nextButtonLabels[stepIndex]}
            </Button>
          </Grid>
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  const disableButton = () => {
    /**
     * Process Shipment Button should be enabled only if the following critera is met:
     * 1. printButton must always be triggered
     * 2. either tracking number and carrier are supplied or provideDetailsLater is checked
     *
     * returns true if button should be disabled, false otherwise
     */

    // returns boolean on true, if all tracking information fields are populated
    const { data = [] } = shipmentBarcodeStore
    let hasTrackingInfo = data.flatMap(
      (shipping) => !!shipping.trackingNumber && !!shipping.carrier && true
    )
    // ensure that either tracking info is fully provided or flag to defer information is selected [XOR operation used]
    let shipmentInfoUndefined = !hasTrackingInfo[0]
      ? !provideDetailsLater
      : !!provideDetailsLater

    return shipmentInfoUndefined
  }

  const enableMarkAsShipped = (provideDetailsLater = false) => {
    setProvideDetailsLater(provideDetailsLater)
  }

  const getStepContent = (stepIndex) => {
    const {
      stepOneColumnData = [],
      data = [],
      order = '',
      orderBy = '',
      page = Number(0),
      rowCount = 10,
      rowsPerPage = 10,
      shipmentDestination,
      // shipmentNumber,  //todo
      onClick = () => {},
    } = shipmentBarcodeStore

    const dataFieldKeys = stepOneColumnData.map((column) => column.id)
    switch (stepIndex) {
      case 0:
        return (
          <Grid container>
            <Grid item xs={12}>
              <FlexibleTable
                cascadeFieldKeys={[]}
                columnData={stepOneColumnData}
                data={data}
                dataFieldKeys={dataFieldKeys}
                onClick={onClick}
                onSortSelectedChange={onSortSelectedChange}
                order={order}
                orderBy={orderBy}
                page={page}
                rowCount={data.length || rowCount}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                showPopup={showPopupAction}
                totalRowsCount={data.length || Number(0)}
              />
            </Grid>
          </Grid>
        )
      case 1:
        return <ShipmentBarcodePage parentCallback={enableMarkAsShipped} />
      case 2:
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                className={classes.typographyGreen}
                gutterBottom
              >
                {`${
                  'Shipment Successful! The following samples were marked as shipped to ' +
                  shipmentDestination
                }`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FlexibleTable
                cascadeFieldKeys={[]}
                columnData={stepOneColumnData}
                data={data}
                dataFieldKeys={dataFieldKeys}
                onClick={onClick}
                onSortSelectedChange={onSortSelectedChange}
                order={order}
                orderBy={orderBy}
                page={page}
                rowCount={data.length || rowCount}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                showPopup={showPopupAction}
                totalRowsCount={data.length || Number(0)}
              />
            </Grid>
          </Grid>
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <Grid
      container
      className={classes.root}
      alignItems={'center'}
      direction={'row'}
      spacing={4}
    >
      <Grid item xs={12}>
        <Grid container>
          <Paper className={classes.headerPaper}>
            <Grid item xs={12}>
              <Grid container alignItems={'center'} justify={'space-between'}>
                <Grid item>
                  <Typography variant="h5" gutterBottom>
                    Ship Samples
                  </Typography>
                </Grid>
                {getStepControls(activeStep)}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {activeStep === steps.length ? (
            <Grid item xs={12}>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep, shipmentBarcodeStore)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
