import React from 'react'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import Paper from '@material-ui/core/Paper'
import { Button, Grid } from '@mui/material'
import { Print } from '@mui/icons-material'
import { findIndex } from 'lodash'
import Typography from '@material-ui/core/Typography'
import * as propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  fetchShipments,
  getSamplesByVendor,
  updateShipmentDetails,
  removeShipmentWithIncompleteTracking,
  setPage,
  setRowsPerPage,
  showPopup,
  closePopup,
  setCheckBox,
  setCheckBoxHeaderChange,
} from '../../store/shipmentSummary/actionCreator'
import { getBarcodes } from '../../store/shipmentBarcode/actionCreator'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { dataTabLabelsShippedVendor } from '../../enums/tableTabs'
import { makeTabTables, getTitle } from '../../util/Table'

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  paper: {
    width: '97%',
    marginTop: theme.spacing(5),
    align: 'center',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
})

class ShipmentSummaryPage extends React.Component {
  componentDidMount = () => {
    const {
      fetchShipments,
      getSamplesByVendor,
      setHeaderTitleArray,
      page,
      rowsPerPage,
    } = this.props
    setHeaderTitleArray([{ title: 'Sample History', link: '/shipmentSummary' }])
    fetchShipments()
    getSamplesByVendor(page, rowsPerPage)
  }

  componentDidUpdate(prevProps) {
    const { page, rowsPerPage } = this.props
    if (this.props.page !== prevProps.page) {
      this.props.getSamplesByVendor(page, rowsPerPage)
    } else if (this.props.rowsPerPage !== prevProps.rowsPerPage) {
      this.props.getSamplesByVendor(page, rowsPerPage)
    }

    if (!this.props.sampleData.length && this.props.page !== 1) {
      this.props.setPage(1)
    }
  }

  popSamplesWithNoTracking(samples) {
    let returnSamples = []
    for (let sampleIndex in samples) {
      if (
        !this.props.shipmentsWithIncompleteTracking
          .map((shipment) => (shipment.shipmentId || '').toString())
          .includes((samples[sampleIndex].shipmentId || '').toString())
      ) {
        returnSamples.push(samples[sampleIndex])
      }
    }
    return returnSamples
  }

  updateShipment = (updatedShipment) => {
    const { updateShipmentDetails, getSamplesByVendor, page, rowsPerPage } =
      this.props
    updateShipmentDetails(updatedShipment)

    getSamplesByVendor(page, rowsPerPage)
  }

  onSelected = (selectedData, selected) => {
    const { shipmentId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj?.shipmentId === shipmentId
      }) !== -1
    )
  }

  getSelectedShipmentSamples = (selected = []) => {
    return selected
      ?.map((obj) => {
        const ids = obj?.pegasusIds
        return ids?.map((idObj, index) => {
          const pgId =
            idObj?.toString()?.length === 7 ? 'WH' + idObj : 'PG' + idObj
          return { pegasusId: pgId }
        })
      })
      ?.flat()
  }

  getBarcodesActionCB = (barcodes = []) => {
    const file = new Blob([barcodes], { type: 'application/pdf' })
    let fileURL = URL.createObjectURL(file)
    window.open(fileURL).print()
  }
  handleGenerateBarcode = () => {
    const { getBarcodesAction = () => {}, selectedData } = this.props
    const selShipSamples = this.getSelectedShipmentSamples(selectedData)
    getBarcodesAction(selShipSamples, this.getBarcodesActionCB)
  }

  render() {
    const {
      classes = {},
      closePopup,
      columnData,
      editItems,
      sampleColumnData,
      sampleData,
      sampleTableTitle,
      shipmentsWithIncompleteTracking,
      tableTitle,
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage,
      totalRowsCount,
      getSamplesByVendorPending = false,
      fetchShipmentsPending = false,
      clickableColumn = [],
      popupIsVisible = false,
      popupItem = {},
      showPopup,
      onCheckBoxChange = () => {},
      onCheckBoxHeaderChange = () => {},
      selectedData = [],
      checkBoxEnabled,
    } = this.props

    const dataFieldKeys = columnData.map((column) => column.id)

    return (
      <div className={classes.root}>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item>
            <Typography variant="h4" gutterBottom>
              Sample History
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className={classes.backButton}
              color="primary"
              onClick={this.handleGenerateBarcode}
              variant="outlined"
            >
              <Print className={classes.iconMargin} /> Print New Labels
            </Button>
          </Grid>
        </Grid>
        {shipmentsWithIncompleteTracking.length > 0 ? (
          <Paper className={classes.paper}>
            <FlexibleTable
              cascadeFieldKeys={[]}
              columnData={columnData}
              customRowCount="Shipments"
              data={shipmentsWithIncompleteTracking}
              dataFieldKeys={dataFieldKeys}
              disableTablePagination
              uniqueIdentifierInData={'shipmentId'}
              editEnabled
              editItems={editItems}
              enableSearch
              showPopup={() => {}}
              tableTitle={tableTitle}
              totalRowsCount={totalRowsCount}
              updateData={this.updateShipment}
              isLoading={fetchShipmentsPending}
              checkBoxEnabled={checkBoxEnabled}
              onSelected={this.onSelected}
              selected={selectedData}
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
            />
          </Paper>
        ) : null}
        <Paper className={classes.paper}>
          <FlexibleTable
            cascadeFieldKeys={[]}
            columnData={sampleColumnData}
            data={sampleData}
            enableSearch
            tableTitle={sampleTableTitle}
            paginationAtApiLevel={true}
            page={page}
            rowsPerPage={rowsPerPage}
            totalRowsCount={totalRowsCount}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            isLoading={getSamplesByVendorPending}
            clickableColumn={clickableColumn}
            showPopup={showPopup}
            enableMultiSearch={true}
          />
        </Paper>
        <TabbedPopUp
          dataTabLabels={dataTabLabelsShippedVendor}
          isVisible={popupIsVisible}
          data={makeTabTables(popupItem, classes, dataTabLabelsShippedVendor)}
          onClose={closePopup}
          title={getTitle(popupItem)}
          fullWidth={true}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    columnData: state?.shipmentSummary?.columnData,
    data: state?.shipmentSummary?.data,
    editItems: state?.shipmentSummary?.editItems,
    orderBy: state?.shipmentSummary?.orderBy,
    sampleColumnData: state?.shipmentSummary?.sampleColumnData,
    sampleData: state?.shipmentSummary?.sampleData,
    sampleTableTitle: state?.shipmentSummary?.sampleTableTitle,
    tableTitle: state?.shipmentSummary?.tableTitle,
    vendorId: state?.shipmentSummary?.vendorId,
    shipmentsWithIncompleteTracking:
      state?.shipmentSummary?.shipmentsWithIncompleteTracking,
    page: state?.shipmentSummary?.page,
    rowsPerPage: state?.shipmentSummary?.rowsPerPage,
    totalRowsCount: state?.shipmentSummary?.totalRowsCount,
    getSamplesByVendorPending:
      state?.shipmentSummary?.getSamplesByVendorPending,
    fetchShipmentsPending: state?.shipmentSummary?.fetchShipmentsPending,
    updateShipmentDetailsRequestPending:
      state?.shipmentSummary?.updateShipmentDetailsRequestPending,
    clickableColumn: state?.shipmentSummary?.clickableColumn,
    showPopup: state?.shipmentSummary?.showPopup,
    popupIsVisible: state?.shipmentSummary?.popupIsVisible,
    popupItem: state?.shipmentSummary?.item,
    checkBoxEnabled: state?.shipmentSummary?.checkBoxEnabled,
    selectedData: state?.shipmentSummary?.selectedData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchShipments: () => dispatch(fetchShipments()),
    getSamplesByVendor: (page, rowsPerPage) =>
      dispatch(getSamplesByVendor(page, rowsPerPage)),
    updateShipmentDetails: (shipmentDetail) =>
      dispatch(updateShipmentDetails(shipmentDetail)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    removeShipmentWithIncompleteTracking: (shipmentId) =>
      dispatch(removeShipmentWithIncompleteTracking(shipmentId)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    closePopup: () => {
      dispatch(closePopup())
    },
    onCheckBoxChange: (selected) => dispatch(setCheckBox(selected)),
    onCheckBoxHeaderChange: (selectedList = []) =>
      dispatch(setCheckBoxHeaderChange(selectedList)),
    getBarcodesAction: (samples, showBarcodesCallback) => {
      dispatch(getBarcodes(samples, showBarcodesCallback))
    },
  }
}

ShipmentSummaryPage.propTypes = {
  columnData: propTypes.array,
  data: propTypes.array,
  editItems: propTypes.array,
  orderBy: propTypes.string,
  sampleColumnData: propTypes.array,
  sampleData: propTypes.array,
  sampleTableTitle: propTypes.string,
  tableTitle: propTypes.string,
  vendorId: propTypes.number,
  clickableColumn: propTypes.array,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ShipmentSummaryPage))
