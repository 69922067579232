import { showNotification } from 'store/notification/actionCreator'
import * as projectApi from '../../services/projectsService'
import { download } from '../../util/DownloadCsv'
import * as types from './actionType'
import {
  generatePicklistParams,
  getProjectPicklistData,
} from 'mappers/projectMapper'
import * as itemAPI from '../../services/itemService'
import * as itemMapper from '../../mappers/itemMapper'

export function getProjectPicklist(
  projectId = '',
  page = Number(1),
  rowsPerPage = Number(10)
) {
  return (dispatch) => {
    dispatch(getProjectPicklistLoading(true))
    projectApi
      .getProjectPicklist(projectId, page, rowsPerPage)
      .then(async (response) => {
        if ((response?.data?.samples || []).length > 0) {
          await itemAPI
            .getItemsGraphQL(
              response?.data?.samples.map((it) => it?.item_data?.tcin)
            )
            .then((itemResult) => itemMapper.itemsResponseToItems(itemResult))
            .then(async (items) => {
              let itemMap = new Map(
                items.map((item) => [item.tcin?.toString(), item])
              )
              let mergeData = []
              response?.data?.samples.map((sample) => {
                let item = itemMap.get(sample?.item_data?.tcin?.toString())
                let mergedObj = Object.assign({}, sample, item)
                return mergeData.push(mergedObj)
              })
              let projectPickListData = getProjectPicklistData(mergeData)
              dispatch(setProjectPicklistData(projectPickListData))
              dispatch(setTotalRowsCount(response?.data?.total_count))
            })
            .catch(() => {
              dispatch(setProjectPicklistData([]))
              dispatch(setTotalRowsCount(Number(0)))
            })
        } else {
          dispatch(getProjectPicklistLoading(false))
          dispatch(setProjectPicklistData([]))
        }
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while getting picklist details'
          )
        )
      })
      .finally(() => {
        dispatch(getProjectPicklistLoading(false))
      })
  }
}

export function getProjectPicklistLoading(data = false) {
  return {
    type: types.PROJECT_PICKLIST_LOADING,
    payload: { data: data },
  }
}
export function setTotalRowsCount(data = 10) {
  return {
    type: types.SET_ROWS_COUNT,
    payload: { data: data },
  }
}

export function setProjectPicklistData(data = []) {
  return {
    type: types.SET_PROJECT_PICKLIST_DATA,
    payload: { data: data },
  }
}

export function getProjectListFailure(data = false) {
  return {
    type: types.PROJECT_PICKLIST_FAILURE,
    payload: { data: data },
  }
}

export function onCheckBoxChange(data = {}) {
  return {
    type: types.PICKLIST_CHECKBOX_CHANGE,
    payload: { data: data },
  }
}
export function downloadCsv(projectId='') {
  return (dispatch) => {
    projectApi
      .downloadCSVPicklist(projectId)
      .then((response) => {
        download(response)
      })
      .catch((error) => {})
  }
}
export function deleteItemFromPicklist(
  pegasusId = [],
  projectID = '',
  page = Number(1),
  rowsPerPage = Number(10),
  getShootTypeSamples = () => {},
  removeSamples = false
) {
  return (dispatch) => {
    dispatch(getProjectPicklistLoading(true))
    let params = generatePicklistParams(pegasusId, projectID)
    projectApi
      .deleteItemsFromPicklist(params)
      .then((response) => {
        dispatch(getProjectPicklist(projectID, page, rowsPerPage))
        !removeSamples &&
          dispatch(
            showNotification(
              true,
              'Item(s) removed from the picklist',
              'success'
            )
          )
        getShootTypeSamples()
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while getting picklist details'
          )
        )
      })
      .finally(() => {
        dispatch(getProjectPicklistLoading(false))
      })
  }
}

export function addItemToPicklist(
  pegasusId = [],
  projectID = '',
  getShootTypeSamples = () => {}
) {
  return (dispatch) => {
    dispatch(getProjectPicklistLoading(true))
    let params = generatePicklistParams(pegasusId, projectID)
    projectApi
      .addItemToPicklist(params)
      .then((response) => {
        dispatch(
          showNotification(true, 'Added to picklist successfully', 'success')
        )
        dispatch({ type: 'REST_ALL_NEWSHOOTTYPE' })
        getShootTypeSamples()
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while getting picklist details'
          )
        )
      })
      .finally(() => {
        dispatch(getProjectPicklistLoading(false))
      })
  }
}
