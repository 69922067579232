import { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    padding: '10px',
    margin: '10px',
  },
  textField: {
    padding: '10px',
    margin: '10px',
  },
  typography: {
    padding: '10px',
  },
})

class HelpCard extends PureComponent {
  render() {
    const { classes } = this.props

    return (
      <Card className={classes.root}>
        <CardHeader title="Help" />
        <CardContent>
          <Typography>
            For help, contact TargetTech-Pegasus@Target.com
          </Typography>
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(HelpCard)
