import * as types from './actionType'
import { combine } from '../../util/Merge'
import { getDisplayImage, getDisplayName } from '../../util/CommonUtils'
import { findIndex, cloneDeep, isEmpty } from 'lodash'

export const initialState = {
  alertIsVisible: false,
  alertMessage: '',
  checkBoxEnabled: true,
  clickableColumn: [
    'imageUrl',
    'sampleStatus',
    'description',
    'location',
    'propDescription',
    'propCategoryName',
    'propSubcategoryName',
  ],
  columnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Sample Id',
      textAlign: 'right',
    },
    {
      id: 'imageUrl',
      numeric: false,
      disablePadding: true,
      label: 'Image',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'subClassName',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class',
      textAlign: 'right',
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class ID',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Description',
      textAlign: 'right',
    },
    {
      id: 'sampleStatus',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      textAlign: 'right',
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: true,
      label: 'Location',
      textAlign: 'right',
    },
    {
      id: 'dueDate',
      numeric: false,
      disablePadding: true,
      label: 'Sample Due Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'sampleUpdateDate',
      numeric: false,
      disablePadding: true,
      label: 'Last Updated',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'otherProjects',
      numeric: false,
      disablePadding: true,
      label: 'Other Projects',
      textAlign: 'right',
    },
    {
      id: 'addedToPicklist',
      numeric: false,
      disablePadding: true,
      label: 'Added to picklist',
      textAlign: 'right',
    },
  ],
  requestedSamplesColumnData: [
    {
      id: 'imageUrl',
      numeric: false,
      disablePadding: true,
      label: 'Image',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'subClassName',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class',
      textAlign: 'right',
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class ID',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Description',
      textAlign: 'right',
    },
    {
      id: 'smsPartnersElement',
      numeric: false,
      disablePadding: true,
      label: 'SMS Partners',
      textAlign: 'right',
    },
    {
      id: 'manufacturerBrand',
      numeric: false,
      disablePadding: true,
      label: 'Brand',
      textAlign: 'right',
    },
    {
      id: 'lifecycleState',
      numeric: false,
      disablePadding: true,
      label: 'Lifecycle Status',
      textAlign: 'right',
    },
    {
      id: 'vendorName',
      numeric: false,
      disablePadding: true,
      label: 'Vendor Name',
      textAlign: 'right',
    },
    {
      id: 'vendorId',
      numeric: false,
      disablePadding: true,
      label: 'Vendor Id',
      textAlign: 'right',
    },
    {
      id: 'launchDate',
      numeric: false,
      disablePadding: true,
      label: 'Launch Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'updateDate',
      numeric: false,
      disablePadding: true,
      label: 'Last Updated',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'ship_to_location',
      numeric: false,
      disablePadding: true,
      label: 'Ship To Location',
      textAlign: 'right',
    },
    {
      id: 'quantity',
      numeric: true,
      disablePadding: true,
      label: 'Quantity',
      textAlign: 'right',
    },
  ],
  propsColumnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Pegasus Id',
      textAlign: 'right',
    },
    { id: 'sampleImageDPURL', label: 'Image' },
    {
      id: 'propId',
      label: 'Filemaker ID',
    },
    {
      id: 'propCategoryName',
      label: 'Category',
    },
    {
      id: 'propSubcategoryName',
      label: 'Sub Category',
    },
    { id: 'propDescription', label: 'Description' },
    {
      id: 'propColorFamilyName',
      label: 'Color Family',
    },
    {
      id: 'propMaterialName',
      label: 'Materials',
    },
    { id: 'propCost', label: 'Price($)' },
    {
      id: 'propSourceName',
      label: 'Source',
    },
    { id: 'propNotes', label: 'Notes' },
    {
      id: 'otherProjects',
      numeric: false,
      disablePadding: true,
      label: 'Other Projects',
      textAlign: 'right',
    },
    {
      id: 'addedToPicklist',
      numeric: false,
      disablePadding: true,
      label: 'Added to picklist',
      textAlign: 'right',
    },
  ],
  unavailableSampleColumnData: [
    {
      id: 'checkboxLabel',
      numeric: false,
      disablePadding: true,
      label: 'SELECT ALL',
      textAlign: 'right',
    },
    {
      id: 'projectsList',
      numeric: false,
      disablePadding: true,
      label: '',
      textAlign: 'right',
    },
  ],
  unavailableSampleProjectColumnDataSlider: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Pegasus ID',
      textAlign: 'right',
    },
    {
      id: 'imageUrl',
      numeric: false,
      disablePadding: true,
      label: 'Image',
      textAlign: 'right',
    },
    {
      id: 'projectName',
      numeric: false,
      disablePadding: true,
      label: 'Project',
      textAlign: 'right',
    },
    {
      id: 'shootStartDate',
      numeric: false,
      disablePadding: true,
      label: 'Start Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'shootEndDate',
      numeric: false,
      disablePadding: true,
      label: 'End Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: true,
      label: 'Contact',
      textAlign: 'right',
    },
  ],
  unavailableSampleProjectColumnData: [
    {
      id: 'projectName',
      numeric: false,
      disablePadding: true,
      label: 'Project',
      textAlign: 'right',
    },
    {
      id: 'shootStartDate',
      numeric: false,
      disablePadding: true,
      label: 'Start Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'shootEndDate',
      numeric: false,
      disablePadding: true,
      label: 'End Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: true,
      label: 'Contact',
      textAlign: 'right',
    },
  ],
  data: [],
  shootTypeId: 0,
  goNextUpdatePageFlag: false,
  isVisible: false,
  unknownPegasusIds: [],
  item: {},
  numSelected: 0,
  overlayNumSelected: 0,
  onCheckBoxChange: () => {},
  onCheckBoxHeaderChange: () => {},
  onOverlayCheckBoxChange: () => {},
  onOverlayCheckBoxHeadChange: () => {},
  onClick: () => {},
  pickListSideNav: false,
  order: 'asc',
  orderBy: 'pegasusIds',
  shipmentId: 0,
  response: false,
  page: 1,
  shipmentData: {},
  projectNames: [],
  shipToLocations: [],
  popupIsVisible: false,
  totalRowsCount: 0,
  rowsPerPage: 0,
  searchField: '',
  shootType: '',
  channels: [],
  selectedData: [],
  overlaySelectedData: [],
  goNextViewPageFlag: false,
  shootTypesData: {},
  fetchShootTypeDataPending: false,
  fetchShootTypeDataFailure: false,
  addSamplesRequestFailure: false,
  addSamplesRequestPending: false,
  createShootTypeFailure: false,
  createShootTypePending: false,
  shootTypeDeleteRequestFailure: false,
  shootTypeDeleteRequestPending: false,
  shootTypeUpdateRequestFailure: false,
  shootTypeUpdateRequestPending: false,
  shipmentGetRequestFailure: false,
  shipmentGetRequestPending: false,
  removeSampleFailure: false,
  removeSamplePending: false,
  removeRequestedSampleFailure: false,
  removeRequestedSamplePending: false,
  fetchLocationRequestFailure: false,
  fetchLocationRequestPending: false,
  unavailableSamples: [],
  unavailableSamplesRequestPending: false,
  unavailableSamplesRequestFailure: false,
  unavailableSamplePopUpIsVisible: false,
  sampleCount: [],
  isRowsPerPageUpdated: false,
  addSamplesResponse: {},
  tabValue: '',
  editors: [],
}

export default function newShootType(state = initialState, action = {}) {
  switch (action.type) {
    case types.SHOOT_TYPE_SET_CHECKBOX: {
      const { selectedData = {}, tabValue = '' } = state
      const {
        payload: { selected = {} },
      } = action
      const { pegasusId: selectedPegId = '', tcin: selectedTcin = '' } =
        selected
      const selectedIndex =
        tabValue === 'REQUESTED'
          ? findIndex(selectedData, function (obj = {}) {
              return obj.tcin === selectedTcin
            })
          : findIndex(selectedData, function (obj = {}) {
              return obj.pegasusId === selectedPegId
            })
      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          state.selectedData,
          action.payload.selected
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selectedData.slice(1))
      } else if (selectedIndex === state.selectedData.length - 1) {
        newSelected = newSelected.concat(state.selectedData.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selectedData.slice(0, selectedIndex),
          state.selectedData.slice(selectedIndex + 1)
        )
      }

      return Object.assign({}, state, {
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.SHOOT_TYPE_SET_CHECKBOX_HEADER: {
      let newSelected = []
      if (action.payload.selected.length > state.selectedData.length) {
        newSelected = action.payload.selected
      } else if (action.payload.selected.length === state.selectedData.length) {
        newSelected = []
      }

      return Object.assign({}, state, {
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.SHOOT_TYPE_SET_OVERLAY_HEADER_CHECKBOX: {
      let newSelected = []
      const {
        payload: { selected: payloadSelectedData = [] },
      } = action
      const { overlaySelectedData = [] } = state
      if (payloadSelectedData.length > overlaySelectedData.length) {
        newSelected = payloadSelectedData
      } else if (payloadSelectedData.length === overlaySelectedData.length) {
        newSelected = []
      }

      return Object.assign({}, state, {
        overlaySelectedData: newSelected,
        overlayNumSelected: newSelected.length,
      })
    }
    case types.SHOOT_TYPE_SET_OVERLAY_CHECKBOX: {
      const {
        payload: { selected: payloadSelectedData = {} },
      } = action
      const { overlaySelectedData = [] } = state
      const selectedIndex = overlaySelectedData.find(
        (obj) => obj.pegasusId === payloadSelectedData.pegasusId
      )
      let newSelected = []
      if (selectedIndex === -1 || !selectedIndex) {
        newSelected = newSelected.concat(
          overlaySelectedData,
          payloadSelectedData
        )
      } else {
        newSelected = overlaySelectedData.filter(
          (item) => item.pegasusId !== payloadSelectedData.pegasusId
        )
      }

      return Object.assign({}, state, {
        overlayNumSelected: newSelected.length,
        overlaySelectedData: newSelected,
      })
    }

    case types.SHOOT_TYPE_RESET_OVERLAY_CHECKBOX: {
      return Object.assign({}, state, {
        overlayNumSelected: Number(0),
        overlaySelectedData: [],
      })
    }

    case types.CLOSE_UNAVAILABLE_SAMPLES_POPUP:
      return Object.assign({}, state, {
        ...state,
        unavailableSamplePopUpIsVisible: false,
        unavailableSamples: [],
      })
    case types.OPEN_PICK_LIST: {
      const newState = {
        ...state,
        pickListSideNav: !state.pickListSideNav,
      }
      return newState
    }

    case types.SHOOT_TYPE_CREATE_REQUEST_PENDING: {
      return Object.assign({}, state, {
        createShootTypePending: action.payload.pending,
      })
    }
    case types.SHOOT_TYPE_CREATE_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        goNextUpdatePageFlag: true,
        goNextViewPageFlag: false,
        shootType: action.payload.shootType,
        shootTypeId: action.payload.data.id,
      })
    }
    case types.SHOOT_TYPE_CREATE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        createShootTypeFailure: true,
      })

    case types.SHOOT_TYPE_UPDATE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        goNextUpdatePageFlag: false,
        goNextViewPageFlag: false,
        shootTypesData: action?.payload?.data || [],
      })

    case types.SHOOT_TYPE_UPDATE_REQUEST_PENDING:
      return Object.assign({}, state, {
        shootTypeUpdateRequestPending: action.payload.pending,
      })

    case types.SHOOT_TYPE_UPDATE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        shootTypeUpdateRequestFailure: true,
      })
    case types.SHOOT_TYPE_DELETE_REQUEST_PENDING:
      return Object.assign({}, state, {
        shootTypeDeleteRequestPending: action.payload.pending,
      })
    case types.SHOOT_TYPE_DELETE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        goNextUpdatePageFlag: false,
        goNextViewPageFlag: false,
      })
    case types.SHOOT_TYPE_DELETE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        shootTypeDeleteRequestFailure: true,
        goNextUpdatePageFlag: false,
        goNextViewPageFlag: false,
      })
    case types.ADD_SHOOT_TYPE_SAMPLES_REQUEST_SUCCESS: {
      const {
        payload: { data: payloadData = [], isExistsErrorSamples },
      } = action
      const combinepayloadData = payloadData.map((item) => ({
        ...item,
        imagePopupUrl: item.imageUrl,
        imageUrl: getDisplayImage(item.imageUrl),
      }))
      const combinedData =
        combine(combinepayloadData, state.data, 'pegasusId') || []
      // const finalDataArr = sortMyArray(combinedData, 'updateTs') || []
      return Object.assign({}, state, {
        ...state,
        data: combinedData,
        unavailableSamplePopUpIsVisible: isExistsErrorSamples || false,
      })
    }

    case types.ADD_SHOOT_TYPE_SAMPLES_REQUEST_PENDING:
      return Object.assign({}, state, {
        addSamplesRequestPending: action.payload.pending,
      })

    case types.ADD_SHOOT_TYPE_SAMPLES_REQUEST_FAILURE:
      return Object.assign({}, state, {
        addSamplesRequestFailure: true,
      })
    case types.UNAVAILABLE_SHOOT_TYPE_SAMPLES_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        unavailableSamples: action.payload.data,
        unavailableSamplePopUpIsVisible: true,
      })
    }

    case types.UNAVAILABLE_SHOOT_TYPE_SAMPLES_PENDING:
      return Object.assign({}, state, {
        unavailableSamplesRequestPending: action.payload.pending,
      })

    case types.UNAVAILABLE_SHOOT_TYPE_SAMPLES_FAILURE:
      return Object.assign({}, state, {
        unavailableSamplesRequestFailure: true,
      })
    case types.SHOOT_TYPE_CHANNEL_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        channels: action.payload.data.data.shoot_type_channels,
      })
    case types.SHOOT_TYPE_CHANNEL_REQUEST_PENDING:
      return Object.assign({}, state, {
        getShootTypeChannelRequestPending: action.payload.pending,
      })
    case types.SHOOT_TYPE_CHANNEL_REQUEST_FAILURE:
      return Object.assign({}, state, {
        getShootTypeChannelRequestFailure: true,
      })
    case types.SHOOT_TYPE_GET_EDITORS_SUCCESS: {
      const payloadData = action?.payload?.data ?? []
      const projEditors = payloadData?.map((editorObj) => {
        const objDisplayName = getDisplayName(editorObj)
        const { lan_id: lanId = '' } = editorObj
        return Object.assign({}, editorObj, {
          displayName: objDisplayName || lanId,
          label: objDisplayName || lanId,
          value: lanId,
          id: lanId,
        })
      })
      return Object.assign({}, state, {
        ...state,
        editors: projEditors ?? [],
      })
    }
    case types.SHOOT_TYPE_GET_EDITORS_FAILURE:
      return Object.assign({}, state, {
        editors: [],
      })
    case types.SHOOT_TYPE_SET_UNKNOWN_DATA:
      return Object.assign({}, state, {
        ...state,
        unknownPegasusIds: action.payload.data,
      })
    case types.SHOW_SHOOT_TYPE_PAGE_ALERT: {
      const newState = {
        ...state,
        alertIsVisible: true,
        alertMessage: action.payload.message,
        response: true,
      }
      return newState
    }
    case types.CLOSE_SHOOT_TYPE_PAGE_ALERT: {
      const newState = {
        ...state,
        alertIsVisible: false,
      }
      return newState
    }
    case types.RESET_NEW_SHOOT_TYPE_STATE: {
      const newState = {
        ...initialState,
        shootType: state.shootType,
        shootTypesData: {},
      }
      return Object.assign({}, newState)
    }

    case types.SHOOT_TYPE_SET_PAGE: {
      return Object.assign({}, state, {
        page: action.payload.page,
      })
    }
    case types.SHOOT_TYPE_SET_ROWS_PER_PAGE: {
      return Object.assign({}, state, {
        rowsPerPage: action.payload.rowsPerPage,
      })
    }
    case types.SHOOT_TYPE_SET_TOTAL_ROWS_COUNT: {
      return Object.assign({}, state, {
        totalRowsCount: action.payload.totalRowsCount,
      })
    }

    case types.REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_SUCCESS: {
      const idSet = new Set(action.payload.data.map((item) => item.pegasusId))
      return Object.assign({}, state, {
        ...state,
        data: state.data.filter((sample) => !idSet.has(sample.pegasusId)),
        selectedData: [],
        numSelected: 0,
      })
    }

    case types.REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_PENDING:
      return Object.assign({}, state, {
        removeSamplePending: action.payload.pending,
      })

    case types.REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        removeSampleFailure: true,
      })

    case types.REMOVE_REQUESTED_SAMPLE_REQUEST_SUCCESS: {
      const {
        payload: { data: payloadData = [] },
      } = action
      return Object.assign({}, state, {
        ...state,
        data: state.data.filter(
          (sample) => payloadData.indexOf(sample.tcin_shoot_type_id) === -1
        ),
        selectedData: [],
        numSelected: 0,
      })
    }

    case types.REMOVE_REQUESTED_SAMPLE_REQUEST_PENDING:
      return Object.assign({}, state, {
        removeRequestedSamplePending: action.payload.pending,
      })

    case types.REMOVE_REQUESTED_SAMPLE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        removeRequestedSampleFailure: true,
      })
    case types.SHOOT_TYPE_FETCH_DATA_SUCCESS: {
      const actionPayloadData = action.payload.data || []
      return Object.assign({}, state, {
        ...state,
        data: actionPayloadData.map((item) => ({
          ...item,
          imagePopupUrl: item.imageUrl,
          imageUrl: getDisplayImage(item.imageUrl),
        })),
        shootTypesData: isEmpty(action.payload.shootTypesData)
          ? state.shootTypesData
          : action.payload.shootTypesData,
        shootType: action.payload.shootType,
        shootTypeId:
          action.payload.shootTypesData.id || state.shootTypesData.id,
        rowsPerPage: state.rowsPerPage > 0 ? state.rowsPerPage : 50,
      })
    }

    case types.SHOOT_TYPE_FETCH_DATA_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetchShootTypeDataFailure: true,
      })

    case types.SHOOT_TYPE_FETCH_DATA_PENDING:
      return Object.assign({}, state, {
        ...state,
        fetchShootTypeDataPending: action.payload.pending,
      })

    case types.SHOOT_TYPE_CLEAR_DATA:
      return Object.assign({}, state, {
        ...state,
        unknownPegasusIds: [],
        searchField: '',
      })

    case types.SHOW_SHOOT_TYPE_POPUP:
      return Object.assign({}, state, {
        ...state,
        popupIsVisible: true,
        item: action.payload.item,
      })

    case types.CLOSE_SHOOT_TYPE_POPUP:
      return Object.assign({}, state, {
        ...state,
        popupIsVisible: false,
      })

    case types.BACK_TO_LANDING_PAGE:
      return Object.assign({}, state, {
        ...state,
        shootTypesData: {},
        goNextViewPageFlag: false,
        goNextUpdatePageFlag: false,
      })

    case types.MOVE_TO_EDIT_PAGE:
      return Object.assign({}, state, {
        ...state,
        goNextViewPageFlag: false,
        goNextUpdatePageFlag: true,
      })
    case types.MOVE_TO_VIEW_PAGE:
      return Object.assign({}, state, {
        ...state,
        goNextViewPageFlag: true,
        goNextUpdatePageFlag: false,
      })
    case types.SHOOT_TYPE_CLEAR_TABLE_DATA:
      return Object.assign({}, state, {
        data: [],
      })
    case types.SHOOT_TYPE_CALL_EDIT_FIELDS_API: {
      const { data: stateData = [] } = state
      const {
        payload: { data: payloadData, sample_id },
      } = action
      const newDataObj = (stateData || []).map((obj) => {
        if (obj.pegasusId.indexOf(sample_id) !== -1) {
          obj = Object.assign({}, obj, payloadData)
        }
        return obj
      })
      return Object.assign({}, state, {
        ...state,
        data: newDataObj,
      })
    }
    case types.ROWS_PER_PAGE_UPDATED: {
      const {
        payload: { isRowsPerPageUpdated },
      } = action
      return Object.assign({}, state, {
        ...state,
        isRowsPerPageUpdated,
      })
    }
    case types.RESET_ROWS_PER_PAGE_UPDATED: {
      const {
        payload: { isRowsPerPageUpdated },
      } = action
      return Object.assign({}, state, {
        ...state,
        isRowsPerPageUpdated,
      })
    }
    case types.ADD_SAMPLES_RESPONSE: {
      const {
        payload: { addSamplesResponse },
      } = action
      return Object.assign({}, state, {
        addSamplesResponse: addSamplesResponse,
      })
    }
    case types.UPDATE_REQUEST_SAMPLE_QUANTITY: {
      const {
        payload: { updatedQuantity, tcinShootTypeId },
      } = action
      const { data } = state
      const itemList = cloneDeep(data)
      const updatedData = itemList.reduce((acc, item) => {
        if (item.tcin_shoot_type_id === tcinShootTypeId) {
          item.quantity = updatedQuantity
        }
        // Note: imageUrl is React element which will cause state mutation if unchanged
        item.imageUrl = getDisplayImage(item.imagePopupUrl)
        acc.push(item)
        return acc
      }, [])
      return Object.assign({}, state, {
        ...state,
        data: updatedData,
      })
    }
    case types.CASCADE_REQUEST_SAMPLE_QUANTITY: {
      const {
        payload: { cascadeQuantity, tcinShootTypeId },
      } = action
      const { data } = state
      const itemList = cloneDeep(data)
      const updatedData = itemList.reduce((acc, item, index) => {
        let idx = acc.findIndex((a) => a.tcin_shoot_type_id === tcinShootTypeId)
        if (idx !== -1 && index > idx) {
          item.quantity = cascadeQuantity
        }
        // Note: imageUrl is React element which will cause state mutation if unchanged
        item.imageUrl = getDisplayImage(item.imagePopupUrl)
        acc.push(item)
        return acc
      }, [])
      return Object.assign({}, state, {
        ...state,
        data: updatedData,
      })
    }
    case types.SET_SHOOTTYPE_TABVALUE: {
      return Object.assign({}, state, {
        ...state,
        tabValue: action.payload.tabValue,
      })
    }

    default:
      return state
  }
}
