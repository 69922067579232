import React from 'react'
import { Link } from 'react-router-dom'
import * as propTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import apiConfig from '../../apiConfig'
import { deleteItemFromPicklist } from 'store/projectPicklist/actionCreator'
import {
  camelCase,
  findIndex,
  first,
  isEmpty,
  cloneDeep,
  isNull,
  isUndefined,
} from 'lodash'
import { Badge } from '@material-ui/core'
import ChipArray from 'components/Chips/ChipArray'
import Button from '@material-ui/core/Button/Button'
import Paper from '@material-ui/core/Paper'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import { SwipeableDrawer, Box } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { data } from 'pages/ProjectPickList/Constants'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Delete, GetApp } from '@material-ui/icons'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CloseIcon from '@mui/icons-material/Close'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import { Create } from '@material-ui/icons'
import FilterListIcon from '@material-ui/icons/FilterList'
import defaultThumbnail from 'images/defaultThumbnail.jpg'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import DialogBox from '../../components/DialogBox/DialogBox'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import ShowIf from 'components/ShowIf'

import ProjectInfo from './ProjectInfo'
import Filters from '../SearchPage/Filters'
import RequestedSamplesFlyout from './RequestSamplesFlyout'
import AddSampleToProject from './ProjectDetails/AddSampleToProject'
import EditSamplesFlyout from './EditSamplesFlyout'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import withHOCs from 'util/withHocs'
import {
  ShootTypes,
  ShootTypePagename,
  additionalColumn,
} from '../../enums/shootTypes'
import ProjectPickList from 'pages/ProjectPickList/ProjectPickList'
import { makeTabTables, getTitle } from '../../util/Table'
import { isDateInPast, getDisplayThumbnailList } from 'util/CommonUtils'
import {
  dataTabLabelsShipped,
  dataTabLabelsShippedWithProps,
} from '../../enums/tableTabs'
import {
  projectDetailsTabs,
  projectDetailsTabsNonPP,
  defaultProjectsDetailsTab,
  REQUESTED,
  ORDERED,
  SAMPLE,
  PROPS,
  SAMPLES,
} from 'enums/Tabs'
import { paramsFromAutoOrderFiltersPost } from 'pages/AddFilters/constants'
import { addItemToPicklist } from 'store/projectPicklist/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import {
  resetNewShootTypeState,
  moveToEditPage,
  showPopup,
  closePopup,
  getShootType,
  setPage,
  setRowsPerPage,
  updateSampleFieldsData,
  isRowsPerPageUpdated,
  resetIsRowsUpdated,
  setCheckBox,
  setCheckBoxHeaderChange,
  removeSamples,
  removeRequestedSamples,
  setTabvalue,
  cascadeSampleQuantity,
  openPickList,
} from '../../store/newShootType/actionCreator'
import {
  downloadCollectionSamples,
  getACollection,
} from '../../store/collections/actionCreator'
import {
  getVendorEmailsById,
  setSampleData,
  setOrderSamplesFrom,
} from '../../store/order/actionCreator'
import {
  downloadProjectSamples,
  downloadRequestedSamples,
  downloadCSVRequestedSamples,
  submitOrderForSelected,
  downloadProjectContactSheet,
  getAProject,
  downloadOrderSamplesBySelect,
  editFlyoutOpen,
  editSamplesSubmit,
} from '../../store/projects/actionCreator'
import {
  downloadContactSheet,
  downloadLoading,
} from 'store/searchInventory/actionCreator'
import BottomBar from 'components/BottomBar'
import { showNotification } from 'store/notification/actionCreator'

const styles = (theme) => ({
  accordionButton1: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 200,
  },
  accordionButton2: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 20,
  },
  itemColumnMargin: {
    flexBasis: '435px',
  },
  descriptionPaper: {
    padding: '24px 24px 48px 24px',
    marginTop: '50px',
  },
  sampleListTable: {
    marginTop: '34px',
  },
  shootTypeLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rootDiv: {
    margin: '0px 28px',
  },
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '284px',
    padding: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    whiteSpace: 'pre-wrap',
  },
  filterTitle: {
    width: '300px',
    paddingTop: '10px',
    paddingBottom: '20px',
  },
  toolBar: {
    display: 'flex',
  },
  editButton: {
    width: 'auto',
    margin: theme.spacing(1),
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
  tabsWrapper: {
    marginLeft: '40px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  addSamplesBtn: {
    float: 'right',
    margin: '10px 15px 10px 0',
    border: `1px solid ${theme.palette.divider}`,
    // border: `1px solid ${theme.palette.primary.main}`,
  },
  swipeableDrawer: {
    padding: '1%',
    overflow: 'hidden',
    alignItems: 'center',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
    color: '#fff',
  },
  SwipeableDrawerElements: {
    // fontSize: 'large',
    textAlign: 'left',
    overflow: 'hidden',
  },
  defaultButton: {
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
  orderButton: {
    width: 'auto',
    margin: '0px 8px',
    color: '#366cd9',
    backgroundColor: '#fff',
  },
  orderButtonLink: {
    textDecoration: 'auto',
  },
  reqFilterBtn: {
    position: 'absolute',
    top: '-52px',
    right: '22px',
  },
  textField: {
    padding: '10px',
    margin: '10px',
  },
  percentageLabel: {
    fontSize: '12px',
    fontWeight: 700,
  },
  popoverText: {
    color: 'black',
    fontSize: 15,
    fontWeight: 'bold',
  },
  popoverParent: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '200px',
  },
  popoverPaper: {
    width: '100px',
    minHeight: '100px',
    minWidth: '250px',
    padding: '10px',
  },
})

const renderSamplesTabLabel = (label, percentageSampleReceived, classes) => {
  return (
    <>
      {label}
      <br />
      <span className={classes.percentageLabel}>{`${
        '(' + percentageSampleReceived + '% received)'
      }`}</span>
    </>
  )
}

const ProjectDetailsTabs = ({
  projectDetailsTabs,
  currentTabValue,
  handleTabChange,
  classes,
  percentageSampleReceived,
}) => {
  return (
    <>
      <Tabs
        value={currentTabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="standard"
        aria-label="project-tabs"
      >
        {projectDetailsTabs.map((obj, index) => {
          const { label = '', value = '' } = obj
          const newLabel =
            label === 'SAMPLES INVENTORY' && percentageSampleReceived
              ? renderSamplesTabLabel(label, percentageSampleReceived, classes)
              : label
          return (
            <Tab
              data-cy={label}
              label={newLabel}
              value={value}
              className={classes.tabsWrapper}
            />
          )
        })}
      </Tabs>
    </>
  )
}

class ProjectCrud extends React.PureComponent {
  constructor(props) {
    super(props)
    const {
      tabValue = '',
      auth: { isAuthorized = () => {} },
    } = this.props
    this.state = {
      rowsCount: 10,
      currentTabValue:
        tabValue !== ''
          ? tabValue
          : isAuthorized(apiConfig?.roles?.defaultViewProps)
            ? 'PROPS'
            : isAuthorized(apiConfig?.projectsDefault)
              ? 'REQUESTED'
              : defaultProjectsDetailsTab,
      isAddSamplesSliderOpen: false,
      projectData: {},
      anchor: '',
      anchorOpen: false,
      flyoutType: '',
      filtersOpen: false,
      filters: {},
      // filters: filters,
      isFilterUpdated: false,
      isProjectEdited: false,
      showAlertOnDelete: false,
      alertMessageOnDelete: '',
      deleteResponse: false,
      anchorEl: null,
      picklistData: data,
    }
  }
  componentDidMount = async () => {
    const {
      shootTypesData: { name: shootTypeName = '' },
      setHeaderTitleArray,
      location: { pathname: currentURI } = {},
      params: { pageId: shootTypeID = '' } = {},
      pageName = '',
      setOrderSamplesFrom = () => {},
    } = this.props
    shootTypeID && this.getRowsPerPage()
    setHeaderTitleArray([
      { title: `${camelCase(pageName || '')}`, link: `/${pageName}` },
      { title: shootTypeName || shootTypeID, link: `${currentURI}` },
    ])
    setOrderSamplesFrom('')
  }

  getRowsPerPage = () => {
    const {
      params: { pageId: shootTypeID = '' } = {},
      sampleCount = [],
      setRowsPerPage = () => {},
      isRowsUpdated = () => {},
      pageName = '',
    } = this.props

    const { currentTabValue = '' } = this.state
    let count =
      currentTabValue === 'ORDERED'
        ? sampleCount.find((s) => (s.key || '').toString() === shootTypeID) || {
            value: Number(50),
          }
        : { value: sampleCount?.length || Number(50) }
    if (pageName === ShootTypePagename.PROJECT) {
      switch (true) {
        case count?.value >= 1000:
          setRowsPerPage(1000)
          break
        case count?.value >= 100:
          setRowsPerPage(count?.value)
          break
        case count?.value < 10:
          setRowsPerPage(10)
          break
        case count?.value <= 50:
          setRowsPerPage(50)
          break
        case count?.value < 100:
          setRowsPerPage(100)
          break
        case isEmpty(count?.value):
          setRowsPerPage(9999)
          break
        default:
          setRowsPerPage(50)
          break
      }
    } else setRowsPerPage(10)
    isRowsUpdated()
  }

  componentWillUnmount() {
    const { downloadLoading = () => {} } = this.props
    downloadLoading(false, '')
  }

  getShootTypeSamples = () => {
    const {
      pageName = '',
      params: { pageId: shootTypeID = '' } = {},
      page = 1,
      rowsPerPage = 50,
      getShootType = () => {},
      getAProject = () => {},
      shootTypesData,
      shootType,
      getACollection = () => {},
      match: { params: { pageId: projectID = '' } = {} } = {},
    } = this.props
    const { currentTabValue = '', filters: stateFilters = {} } = this.state
    const updatedRowsPerPage = rowsPerPage < 1 ? 10 : rowsPerPage
    switch (pageName) {
      case ShootTypePagename.PROJECT: {
        if (isEmpty(shootTypesData)) {
          getAProject(
            shootTypeID,
            page,
            updatedRowsPerPage,
            currentTabValue,
            stateFilters
          )
        } else {
          getShootType(
            shootTypesData,
            shootType,
            page,
            rowsPerPage,
            currentTabValue,
            stateFilters,
            {
              project_id: [projectID],
            }
          )
        }
        break
      }
      case ShootTypePagename.COLLECTION:
        getACollection(shootTypeID, page, updatedRowsPerPage)
        break
      default:
        break
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      page,
      rowsPerPage = 50,
      shootType,
      shootTypesData,
      location: { pathname: currentURI } = {},
      params: { pageId: projectID = '' } = {},
      pageName = '',
      getShootType = () => {},
      setPage = () => {},
      setHeaderTitleArray = () => {},
      isRowsPerPageUpdated,
      resetIsRowsUpdated = () => {},
      data = [],
      getProjectsPending,
      requestSamplesStatus = '',
      editSuccess = false,
    } = this.props
    const {
      isFilterUpdated: stateIsFilterUpdated,
      isProjectEdited,
      currentTabValue,
      filters: stateFilters = {},
      isAddSamplesSliderOpen,
      anchorOpen,
    } = this.state
    const {
      isFilterUpdated: prevStateIsFilterUpdated,
      isProjectEdited: prevIsProjectEdited,
      currentTabValue: prevCurrentTabValue,
      isAddSamplesSliderOpen: prevIsAddSamplesSliderOpen,
      anchorOpen: prevAnchorOpen,
    } = prevState
    const { name: projectName = '' } = shootTypesData
    const isSliderClosed = prevIsAddSamplesSliderOpen && !isAddSamplesSliderOpen
    const onRequestSamplesSuccess =
      requestSamplesStatus !== '' &&
      requestSamplesStatus !== prevProps.requestSamplesStatus &&
      ['partialsuccess', 'success'].indexOf(requestSamplesStatus) !== -1
    const isProjectEditedStatus =
      isProjectEdited && isProjectEdited !== prevIsProjectEdited
    if (
      (!!isRowsPerPageUpdated &&
        (page !== prevProps.page || rowsPerPage !== prevProps.rowsPerPage)) ||
      currentTabValue !== prevCurrentTabValue ||
      isSliderClosed ||
      onRequestSamplesSuccess ||
      isProjectEditedStatus ||
      (!!stateIsFilterUpdated &&
        stateIsFilterUpdated !== prevStateIsFilterUpdated)
    ) {
      await this.getShootTypeSamples()
      await this.props.onCheckBoxHeaderChange()
      if (isRowsPerPageUpdated) await resetIsRowsUpdated()
      if (isProjectEditedStatus) {
        await this.setState({ isProjectEdited: false })
      }
    } else if (
      !getProjectsPending &&
      (page !== prevProps.page ||
        editSuccess ||
        rowsPerPage !== prevProps.rowsPerPage ||
        currentTabValue !== prevCurrentTabValue)
    ) {
      getShootType(
        shootTypesData,
        shootType,
        page,
        rowsPerPage,
        currentTabValue,
        stateFilters,
        {
          project_id: [projectID],
        }
      )
    } else if (!anchorOpen && anchorOpen !== prevAnchorOpen) {
      await this.getShootTypeSamples()
    }

    if (
      !!stateIsFilterUpdated &&
      stateIsFilterUpdated !== prevStateIsFilterUpdated
    ) {
      this.setState({
        isFilterUpdated: false,
      })
    }

    if (!data.length && page !== 1) {
      setPage(1)
    }
    setHeaderTitleArray([
      { title: `${camelCase(pageName || '')}`, link: `/${pageName}` },
      { title: projectName || projectID, link: `${currentURI}` },
    ])
  }

  handleBackToProjects() {
    const { pageName = '' } = this.props
    this.props.history.push(`/${pageName}`)
  }

  componentWillUnmount = async () => {
    const { setTabvalue = () => {}, onBackClick = () => {} } = this.props
    const { currentTabValue = '' } = this.state
    await onBackClick()
    await setTabvalue(currentTabValue)
  }

  downloadCSFunction =
    (downloadType = '') =>
    (event) => {
      const {
        downloadContactSheet = () => {},
        downloadSearchContactSheet = () => {},
        shootTypesData: { name: shootTypeName = '', id: shootTypeId = '' },
      } = this.props
      const { currentTabValue = '' } = this.state
      const paramsCopy = {
        include_filters: {},
        exclude_filters: {},
      }

      const contactSheetData = Object.assign({}, paramsCopy, {
        ...paramsCopy,
        include_filters: {
          project_id: [shootTypeId],
        },
      })
      let tab = currentTabValue === PROPS ? PROPS : SAMPLE
      downloadSearchContactSheet(
        { requestPayload: contactSheetData },
        downloadType,
        tab
      )
    }

  onSelected = (selectedData, selected) => {
    const { pegasusId = '', tcin = '' } = selected
    const { currentTabValue = '' } = this.state
    return currentTabValue === REQUESTED
      ? findIndex(selectedData, function (obj) {
          return obj.tcin === tcin
        }) !== -1
      : findIndex(selectedData, function (obj) {
          return obj.pegasusId === pegasusId
        }) !== -1
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    this.setState({ anchor: anchor, anchorOpen: open })
  }

  additionalFiltersContent = (isProjectOwner) => {
    const { currentTabValue = '', filters: stateFilters } = this.state
    const {
      classes = {},
      data = {},
      shootTypesData: { shootEndDate = '' },
      fetchShootTypeDataPending = false,
    } = this.props
    const isCompletedProject = isDateInPast(shootEndDate)
    const filtersCount = Object.entries(stateFilters).length || Number(0)
    if (fetchShootTypeDataPending) {
      return <></>
    }

    return (
      <div className={classes.reqFilterBtn}>
        {
          <Badge color="error" badgeContent={filtersCount}>
            <Button
              id="Filters"
              color="primary"
              onClick={() => this.setState({ filtersOpen: true })}
              startIcon={<FilterListIcon />}
              variant={'outlined'}
              disabled={
                !Object.entries(stateFilters).length && data.length === 0
              }
            >
              Filters
            </Button>
          </Badge>
        }
        <ShowIf
          condition={currentTabValue === REQUESTED && !isCompletedProject}
        >
          <Button
            id="addRSLauncher"
            color="primary"
            onClick={this.toggleDrawer('right', true)}
            startIcon={<AddCircleOutlineIcon />}
            variant={'outlined'}
            style={{
              marginLeft: '10px',
            }}
            disabled={!isProjectOwner}
          >
            REQUEST SAMPLES
          </Button>
        </ShowIf>
        <ShowIf
          condition={currentTabValue === ORDERED || currentTabValue === PROPS}
        >
          <Button
            id="openAddSamplesSliderBtn"
            color="primary"
            onClick={this.toggleAddSamplesSlider}
            startIcon={<AddCircleOutlineIcon />}
            variant={'outlined'}
            style={{
              marginLeft: '10px',
            }}
            disabled={!isProjectOwner}
          >
            ADD {currentTabValue === ORDERED ? 'SAMPLES' : PROPS}
          </Button>
        </ShowIf>
      </div>
    )
  }

  renderRequestedSamples = () => {
    const { match: { params: { pageId: shootTypeID = '' } = {} } = {} } =
      this.props
    return (
      <Box sx={{ width: 'auto', minWidth: 600 }} role="presentation">
        <RequestedSamplesFlyout
          toggleDrawer={this.toggleDrawer}
          projectID={shootTypeID}
        />
      </Box>
    )
  }

  renderTabbedPopUp = () => {
    const {
      popupIsVisible,
      popupItem = {},
      classes = {},
      updateSampleFieldsData = () => {},
      closePopup = () => {},
      shootTypesData,
    } = this.props
    const { currentTabValue = '' } = this.state
    const tabsList =
      currentTabValue === PROPS
        ? dataTabLabelsShippedWithProps
        : dataTabLabelsShipped
    return (
      <TabbedPopUp
        dataTabLabels={tabsList}
        isVisible={popupIsVisible}
        data={makeTabTables(
          popupItem,
          classes,
          tabsList,
          updateSampleFieldsData,
          shootTypesData
        )}
        onClose={closePopup}
        title={getTitle(popupItem)}
        fullWidth={true}
      />
    )
  }

  toggleOpen = () => {
    const { numSelected } = this.props
    if (numSelected !== 0) {
      return true
    } else {
      return false
    }
  }

  onCopytoClipboard = () => {
    const { currentTabValue = '' } = this.state
    const { selectedData = [] } = this.props
    if (currentTabValue === REQUESTED) {
      copy(selectedData.map((item) => item.tcin).join('\r\n'), {
        format: 'text/plain',
      })
    } else {
      copy(selectedData.map((item) => item.pegasusId).join('\r\n'), {
        format: 'text/plain',
      })
    }
  }

  onCopytoSmspartners = (smsPartners = '') => {
    if (smsPartners?.length > 0) {
      copy(smsPartners)
    }
  }

  handleRemoveSample = () => {
    const { currentTabValue = '' } = this.state
    const {
      removeOrderSamples = () => {},
      removeRequestedSamples = () => {},
      selectedData,
      shootType,
      shootTypeId,
      params: { pageId: projectID = '' } = {},
      deleteItemFromPicklist = () => {},
    } = this.props
    if (currentTabValue === REQUESTED) {
      removeRequestedSamples(selectedData, this.onRemoveSamplesSuccess)
    } else {
      const picklistItems = selectedData.filter(
        (obj) => obj.addedToPicklist === 'Yes'
      )
      const picklistPegasusIds = picklistItems.map((obj) => obj?.pegasusId)
      picklistPegasusIds.length > 0 &&
        deleteItemFromPicklist(
          picklistPegasusIds,
          projectID,
          1,
          10,
          this.getShootTypeSamples,
          true
        )
      removeOrderSamples(
        selectedData,
        shootType,
        shootTypeId,
        this.onRemoveSamplesSuccess
      )
    }
  }

  onRemoveSamplesSuccess = () => {
    this.setState({
      showAlertOnDelete: false,
      alertMessageOnDelete: '',
      deleteResponse: false,
    })
  }

  handleDownloadCSV = () => {
    const { currentTabValue = '' } = this.state
    const {
      shootTypeId = '',
      downloadCSVRequestedSamples = () => {},
      downloadOrderSamplesBySelect = () => {},
      selectedData = [],
    } = this.props

    const selectedIds =
      selectedData?.map((item) => item?.tcin_shoot_type_id) || []
    if (currentTabValue === REQUESTED) {
      downloadCSVRequestedSamples(shootTypeId, selectedIds)
    } else if (currentTabValue === ORDERED) {
      const selectedPGIds = selectedData.map((item) =>
        item?.pegasusId?.slice(2)
      )
      downloadOrderSamplesBySelect(shootTypeId, selectedPGIds)
    }
  }

  handleDownloadSamples = () => {
    const { currentTabValue = '' } = this.state
    const {
      downloadProjectSamples = () => {},
      downloadRequestedSamples = () => {},
      downloadSearchContactSheet = () => {},
      shootTypeId = '',
      shootTypesData: { name: shootTypeName = '' },
    } = this.props

    if (currentTabValue === REQUESTED) {
      downloadRequestedSamples(shootTypeId)
    } else {
      const paramsCopy = paramsFromAutoOrderFiltersPost(
        this.state.filters,
        Number(1),
        Number(10),
        '',
        {},
        '',
        '',
        '',
        ShootTypes.PROJECT
      )
      let {
        requestPayload = {
          include_filters: {},
          exclude_filters: {},
        },
      } = paramsCopy
      let tab = currentTabValue === PROPS ? PROPS : SAMPLE
      const contactSheetData = Object.assign({}, requestPayload, {
        ...requestPayload,
        include_filters: {
          project_id: [shootTypeId],
          ...requestPayload?.include_filters,
        },
      })
      downloadSearchContactSheet(
        { requestPayload: contactSheetData },
        'CSV',
        tab
      )
    }
  }

  orderItems(index, data) {
    //setting default value to render order page first page
    const {
      getVendorEmailsById = () => {},
      setSampleData = () => {},
      setOrderSamplesFrom = () => {},
      showNotification = () => {},
      shootTypesData: {
        name: projectName = '',
        id: projectId = '',
        samplesRequiredBy: projectDueDate = '',
      },
    } = this.props
    const { lanId } = this.props.auth.session.userInfo
    const currentProject = projectId
      ? { id: projectId, name: projectName }
      : null
    let vendorIdEmpty = []

    const newSelected = data.map((i = {}) => {
      const { projects = [], quantity = Number(1), ship_to_location = '' } = i
      const firstKeyProject = first(projects)
      if (ship_to_location) {
        i = Object.assign({}, i, { shipTo: ship_to_location })
      }
      if (projectDueDate) {
        i = Object.assign({}, i, { dueDate: projectDueDate })
      }
      if (isNull(i.vendorId) || isUndefined(i.vendorId)) {
        vendorIdEmpty.push(i)
      }
      return {
        ...i,
        vendorEmail: '',
        emailVmm: null,
        vendorBPIds: '',
        bpidsVmm: null,
        studioNotes: '',
        vendorNotes: '',
        userId: lanId,
        project: currentProject || firstKeyProject || {},
        quantity,
      }
    })
    if (isEmpty(vendorIdEmpty)) {
      setOrderSamplesFrom(window.location.pathname)
      setSampleData(newSelected)
      getVendorEmailsById(data.map((data) => data.vendorId))
    } else {
      setOrderSamplesFrom('')
      showNotification(
        true,
        `Some of the samples selected have no vendor Id`,
        'error'
      )
    }
  }

  handleTabChange = (e, newValue) => {
    const { setTabvalue = () => {}, setPage = () => {} } = this.props
    this.setState(
      {
        currentTabValue: newValue,
      },
      () => {
        setTabvalue(newValue)
        setPage(1)
        this.getRowsPerPage()
      }
    )
    this.setState({ filters: {} })
  }

  onProjectInfoUpdate = (updateStatus) => {
    this.setState({
      isProjectEdited: updateStatus,
    })
  }

  renderProjectInfo = (isProjectOwner) => {
    const { pageName, shootTypesData, history, subPageName } = this.props
    return (
      <ProjectInfo
        subPageName={subPageName}
        shootTypesData={shootTypesData}
        projectData={shootTypesData}
        pageName={pageName}
        history={history}
        isProjectOwner={isProjectOwner}
        onProjectInfoUpdate={this.onProjectInfoUpdate}
        handlePickListAction={this.handlePickListAction}
      />
    )
  }
  toggleAddSamplesSlider = () => {
    const { isAddSamplesSliderOpen } = this.state
    this.setState({ isAddSamplesSliderOpen: !isAddSamplesSliderOpen })
  }
  handlePickListAction = () => {
    this.props.openPickList()
  }

  setFiltersUpdated = (isFilterUpdated = false, filters = {}) => {
    const filtersCopy = Object.assign({}, filters)

    this.setState({
      isFilterUpdated: isFilterUpdated,
      filters: filtersCopy,
    })
  }

  renderFilters = () => {
    const { currentTabValue = '' } = this.state
    return (
      <Box sx={{ width: 'auto', minWidth: 600 }} role="presentation">
        <Filters
          pageName={
            currentTabValue === PROPS
              ? 'Props'
              : currentTabValue === REQUESTED
                ? 'RequestedSamples'
                : 'OrderedSamples'
          }
          toggleDrawer={(direction, filtersOpen) => (e) =>
            this.setState({ filtersOpen })
          }
          setFiltersUpdated={this.setFiltersUpdated}
        />
      </Box>
    )
  }
  editSubmit = async (requestBody) => {
    const { lanId } = this.props.auth.session.userInfo
    const { editSamplesSubmit = () => {}, selectedData = [] } = this.props
    await editSamplesSubmit(requestBody, lanId)

    // updating selected data quantity based on the edit for order page
    requestBody.forEach((updatedSample) => {
      const { tcin_shoot_type_id, quantity, ship_to_location } = updatedSample
      const updateIndex = selectedData.findIndex(
        (data) => data.tcin_shoot_type_id === tcin_shoot_type_id
      )
      if (!isNaN(quantity) && Number(quantity) > 0) {
        selectedData[updateIndex].quantity = Number(quantity)
      }
      if (!isEmpty(ship_to_location)) {
        selectedData[updateIndex]['ship_to_location'] = ship_to_location
      }
    })
  }
  renderEditFlyout = () => {
    const { editFlyoutOpen = () => {}, selectedData = [] } = this.props
    return (
      <Box sx={{ width: 'auto', minWidth: 400 }} role="presentation">
        <EditSamplesFlyout
          closePopup={editFlyoutOpen}
          selectedOptions={selectedData}
          handleSubmit={this.editSubmit}
        />
      </Box>
    )
  }

  addToPicklist = (value, selectedData) => {
    const picklistItems = selectedData.filter(
      (obj) => obj.addedToPicklist === 'Yes'
    )
    this.setState({
      picklistItems: picklistItems.map((item) => item?.pegasusId),
    })
    let filteredSelectedData = selectedData.filter(
      (item) => item?.addedToPicklist === 'No'
    )
    let pegasusIds = filteredSelectedData.map((item) => item?.pegasusId)
    const {
      addItemToPicklist = () => {},
      params: { pageId: projectID = '' } = {},
    } = this.props

    filteredSelectedData.length > 0 &&
      addItemToPicklist(pegasusIds, projectID, this.getShootTypeSamples)
  }

  onRemoveSamplesClick = () => {
    const { selectedData = [] } = this.props
    const selectedDataCount = selectedData.length || Number(0)
    const message =
      'You have selected ' + selectedDataCount + ' items to delete?'

    this.setState({
      showAlertOnDelete: true,
      alertMessageOnDelete: message,
      deleteResponse: true,
    })
  }

  onSubmitOrderClick = () => {
    const { selectedData = [], submitOrderForSelected = () => {} } = this.props
    const selectedIds =
      selectedData?.map((item) => item?.tcin_shoot_type_id) || []
    submitOrderForSelected(selectedIds)
  }

  closeAlert = (e) => {
    this.setState({
      showAlertOnDelete: false,
      alertMessageOnDelete: '',
      deleteResponse: false,
    })
  }

  handleMoreClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleMoreClose = () => {
    this.setState({
      anchorEl: null,
    })
  }
  renderVendorId = (vendorId = '') => {
    let cmp = ''
    if (isNull(vendorId)) {
      cmp = <ErrorOutlineIcon style={{ color: 'red' }} />
    } else {
      cmp = vendorId
    }
    return cmp
  }
  renderExistingPicklistItems = () => {
    const { picklistItems = [] } = this.state
    let picklistItemsUpdated = picklistItems.map((data, index) => ({
      label: data,
      key: index,
      type: 'duplicate',
    }))
    const alreadAddedItemLabel =
      'Below Item(s) are already part of the picklist'
    return (
      <Grid item xs={8} id="unknownIDChips" data-cy={picklistItems.length}>
        {picklistItems.length > 0 && (
          <ChipArray
            data={picklistItemsUpdated}
            enableCopyAllButton
            handleClick={() => {}}
            title={alreadAddedItemLabel}
            onDelete={() => this.setState({ picklistItems: [] })}
            clearAllRequired={true}
            chipDeleteRequired={false}
          />
        )}
      </Grid>
    )
  }

  renderSmsPartners = (smsPartners) => {
    const { classes = {} } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    return (
      <>
        <div
          id="smsPartnersParent"
          data-cy="smsPartnersParent"
          className={classes.popoverParent}
          onClick={this.handleMoreClick}
        >
          {`${smsPartners}`}
        </div>
        {smsPartners?.length > 0 && (
          <Popover
            id={'smsPartnersPopover'}
            data-cy={'smsPartnersPopover'}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleMoreClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Paper elevation={8} className={classes.popoverPaper}>
              <Grid container>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Grid item>
                    <Typography variant="h6" className={classes.popoverText}>
                      SMS Partners
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent={'flex-end'}>
                      <Grid item>
                        <IconButton
                          size={'small'}
                          edge={'end'}
                          color={'secondry'}
                          data-cy={'btn_copyAllSMSPartners'}
                          onClick={() => this.onCopytoSmspartners(smsPartners)}
                        >
                          <CopyAllIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          size="small"
                          edge="end"
                          color="inherit"
                          data-cy={'btn_closeSMSPartners'}
                          onClick={() => this.handleMoreClose()}
                        >
                          <CloseIcon sx={{ color: 'grey' }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={'body2'}
                    sx={{ p: 2 }}
                    data-cy={'smsPartnersContainer'}
                  >
                    {smsPartners}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Popover>
        )}
      </>
    )
  }

  render() {
    const {
      classes = {},
      clickableColumn,
      downloadCollectionSamples,
      data,
      onClick,
      popupIsVisible,
      shootTypesData,
      showPopup,
      shootType,
      shootTypeId,
      page,
      rowsPerPage,
      totalRowsCount,
      setPage,
      setRowsPerPage,
      fetchShootTypeDataPending = false,
      columnData: sampleColumnData,
      requestedSamplesColumnData,
      propsColumnData,
      checkBoxEnabled,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      selectedData,
      subPageName,
      numSelected = Number(0),
      editFlyoutOpen,
      flyoutOpen,
      downloadLoadingStatus = false,
      downloadLoadingStatusPdf = false,
      downloadLoadingStatusXlsx = false,
      params: { pageId: projectID = '' } = {},
      pickListSideNav = false,
      auth: {
        isAuthorized = () => {},
        session: {
          userInfo: { lanId = '' },
        },
      } = {},
    } = this.props
    const {
      roles: {
        requestedSamples: requestedSamplesRoles = [],
        smsPartners = [],
        productProducers = [],
      },
    } = apiConfig
    const isAuthorizedToRSTab =
      requestedSamplesRoles.length > 0 && isAuthorized(requestedSamplesRoles)
    const isPPAuthorized =
      productProducers.length > 0 && isAuthorized(productProducers)
    const isSMSAuthorized = smsPartners.length > 0 && isAuthorized(smsPartners)
    let tabsToDisplay = isAuthorizedToRSTab
      ? projectDetailsTabs
      : projectDetailsTabsNonPP
    const {
      currentTabValue,
      isAddSamplesSliderOpen,
      filtersOpen,
      anchorOpen = false,
      showAlertOnDelete = false,
      alertMessageOnDelete = '',
      deleteResponse = false,
      picklistItems = [],
    } = this.state
    let columnData = [...sampleColumnData]
    let formattedData = cloneDeep(data)
    let initiatedSampleList = selectedData.filter((it) => {
      return !it.lifecycleState
    })
    let vendorIdEmpty = selectedData.filter((it) => {
      return isNull(it.vendorId) || isUndefined(it.vendorId)
    })
    const isAuthorizedToViewProps = isAuthorized(apiConfig.roles.viewProps)
    if (currentTabValue === REQUESTED) {
      columnData = requestedSamplesColumnData
      formattedData = cloneDeep(data)?.map((dobj = {}) => {
        const { smsPartners = '', vendorId = '' } = dobj
        return Object.assign({}, dobj, {
          smsPartnersElement: this.renderSmsPartners(smsPartners),
          isAnErrorRow: isNull(vendorId) || isUndefined(vendorId),
        })
      })
    } else if (currentTabValue === PROPS) {
      columnData = propsColumnData
      formattedData = cloneDeep(data)?.map((dobj = {}) => {
        const { sampleImagePopupUrl = [] } = dobj
        const newPropsPopupImage = (
          <div id="newPopupImage" className={classes.zoomCls}>
            {sampleImagePopupUrl?.length ? (
              getDisplayThumbnailList(sampleImagePopupUrl, true)
            ) : (
              <img
                height={80}
                width={80}
                src={'imageUrl'}
                alt="Not Found"
                onError={(e) => {
                  e.target.src = defaultThumbnail
                }}
              />
            )}
          </div>
        )
        return Object.assign({}, dobj, {
          sampleImageDPURL: newPropsPopupImage,
        })
      })
    }
    if (isAuthorizedToViewProps) {
      tabsToDisplay = [...tabsToDisplay, { label: PROPS, value: PROPS }]
    }
    const { percentageSampleReceived, project_owners = [] } = shootTypesData
    const isProjectOwner =
      project_owners?.indexOf(lanId) !== -1 ||
      isAuthorized(apiConfig.roles.admin)
    const dataFieldKeys = columnData.map((column) => column.id)
    return (
      <div className={classes.rootDiv}>
        {pickListSideNav && (
          <ProjectPickList
            handlePickListAction={this.handlePickListAction}
            projectId={projectID}
            getShootTypeSamples={() => this.getShootTypeSamples}
          />
        )}
        {/* {shootType === ShootTypes.PROJECT && this.renderProjectInfo()} */}
        {this.renderProjectInfo(isProjectOwner)}
        <ShowIf condition={subPageName !== 'create'}>
          <>
            <div className={classes.sampleListTable}>
              <Paper>
                {!fetchShootTypeDataPending &&
                  shootType === ShootTypes.PROJECT && (
                    <>
                      <ProjectDetailsTabs
                        projectDetailsTabs={tabsToDisplay}
                        currentTabValue={currentTabValue}
                        handleTabChange={this.handleTabChange}
                        percentageSampleReceived={percentageSampleReceived}
                        classes={classes}
                      />
                    </>
                  )}
                <SwipeableDrawer
                  anchor="right"
                  open={isAddSamplesSliderOpen || anchorOpen}
                >
                  <Box
                    sx={{
                      width: 750,
                    }}
                    role="presentation"
                  >
                    <ShowIf condition={currentTabValue === REQUESTED}>
                      {this.renderRequestedSamples()}
                    </ShowIf>
                    <ShowIf
                      condition={
                        currentTabValue === ORDERED || currentTabValue === PROPS
                      }
                    >
                      <AddSampleToProject
                        closeDrawer={this.toggleAddSamplesSlider}
                        projectData={shootTypesData}
                        sampleType={
                          currentTabValue === ORDERED ? SAMPLE : PROPS
                        }
                      />
                    </ShowIf>
                  </Box>
                </SwipeableDrawer>
                <SwipeableDrawer anchor="right" open={flyoutOpen}>
                  <Box
                    sx={{
                      width: 500,
                    }}
                    role="presentation"
                  >
                    {this.renderEditFlyout()}
                  </Box>
                </SwipeableDrawer>
                <React.Fragment>
                  <SwipeableDrawer
                    open={filtersOpen}
                    anchor="right"
                    onClose={() => this.setState({ filtersOpen: false })}
                  >
                    {this.renderFilters()}
                  </SwipeableDrawer>
                </React.Fragment>
                {this.renderExistingPicklistItems()}
                <FlexibleTable
                  cascadeFieldKeys={[]}
                  checkBoxEnabled={checkBoxEnabled}
                  columnData={
                    shootType === ShootTypes.COLLECTION
                      ? columnData.filter(
                          (columnFields) => columnFields.id !== additionalColumn
                        )
                      : columnData
                  }
                  clickableColumn={clickableColumn}
                  data={formattedData}
                  dataFieldKeys={dataFieldKeys}
                  onClick={onClick}
                  editEnabled={false}
                  onSelected={this.onSelected}
                  // rowCount={data.length}
                  enableSearch
                  enableDownload={true}
                  enableContactSheetDownload={currentTabValue !== REQUESTED}
                  downloadFunction={
                    shootType === ShootTypes.PROJECT
                      ? this.handleDownloadSamples
                      : () => downloadCollectionSamples(shootTypeId)
                  }
                  downloadCSFunction={this.downloadCSFunction('XLSX')}
                  downloadCSFunctionPDF={this.downloadCSFunction('PDF')}
                  showPopup={showPopup}
                  paginationAtApiLevel={true}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalRowsCount={totalRowsCount}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  showAllRows
                  isLoading={fetchShootTypeDataPending}
                  enableMultiSearch
                  onCheckBoxChange={onCheckBoxChange}
                  onCheckBoxHeaderChange={onCheckBoxHeaderChange}
                  selected={selectedData}
                  additionalActions={true}
                  downloadLoadingStatus={downloadLoadingStatus}
                  downloadLoadingStatusPdf={downloadLoadingStatusPdf}
                  downloadLoadingStatusXlsx={downloadLoadingStatusXlsx}
                  errorRowTooltipMessage="Vendor Id does not exist for this item"
                  additionalFiltersContent={() =>
                    this.additionalFiltersContent(isProjectOwner)
                  }
                  uniqueIdentifierInData={
                    currentTabValue === REQUESTED ? 'tcin' : 'pegasusId'
                  }
                />
              </Paper>
            </div>
            <BottomBar
              anchor="bottom"
              variant={'persistent'}
              open={this.toggleOpen()}
              onOpen={() => null}
              onClose={() => null}
              showClearAll={numSelected > 0}
              actionType={'REST_ALL_NEWSHOOTTYPE'}
            >
              <div className={classes.swipeableDrawerElementsContainer}>
                <Grid container className={classes.swipeableDrawer} spacing={4}>
                  <Grid item>
                    <Typography
                      variant="body1"
                      component="body1"
                      className={classes.defaultButton}
                    >
                      {numSelected} Selected
                    </Typography>
                  </Grid>

                  <ShowIf condition={currentTabValue !== PROPS}>
                    <Grid item>
                      <Button
                        id="downloadCsv"
                        className={classes.defaultButton}
                        startIcon={<GetApp />}
                        onClick={this.handleDownloadCSV}
                      >
                        DOWNLOAD CSV
                      </Button>
                    </Grid>
                  </ShowIf>
                  <Grid item>
                    <Button
                      id="copytoClipboardTcins"
                      data-id="copytoClipboardTcins"
                      className={classes.defaultButton}
                      onClick={this.onCopytoClipboard}
                      startIcon={<ContentCopyIcon />}
                    >
                      Copy to clipboard
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      id="removesamples"
                      className={classes.defaultButton}
                      onClick={this.onRemoveSamplesClick}
                      startIcon={<Delete />}
                      disabled={!isProjectOwner}
                    >
                      Remove Samples
                    </Button>
                  </Grid>
                  {currentTabValue === REQUESTED && (
                    <Grid item>
                      <Button
                        id="orderButton"
                        className={classes.defaultButton}
                        disabled={!isProjectOwner}
                        onClick={() => editFlyoutOpen()}
                        startIcon={<Create />}
                      >
                        EDIT
                      </Button>
                    </Grid>
                  )}
                  {currentTabValue === REQUESTED && isPPAuthorized && (
                    <Grid item>
                      <Button
                        id="submitOrderButton"
                        className={classes.orderButton}
                        onClick={this.onSubmitOrderClick}
                        variant="contained"
                        disabled={!isProjectOwner}
                      >
                        SUBMIT ORDER
                      </Button>
                    </Grid>
                  )}
                  {currentTabValue === REQUESTED && isSMSAuthorized && (
                    <Grid item>
                      <Link
                        to={
                          !initiatedSampleList.length &&
                          !vendorIdEmpty.length &&
                          '/OrderSamples'
                        }
                        className={classes.orderButtonLink}
                      >
                        <Button
                          id="orderButton"
                          className={classes.orderButton}
                          disabled={initiatedSampleList.length}
                          onClick={(value) =>
                            this.orderItems(value, selectedData)
                          }
                          variant="contained"
                        >
                          ORDER
                        </Button>
                      </Link>
                    </Grid>
                  )}
                  {currentTabValue !== REQUESTED && (
                    <Grid item>
                      <Button
                        id="orderButton"
                        className={classes.orderButton}
                        onClick={(value) =>
                          this.addToPicklist(value, selectedData)
                        }
                        variant="contained"
                      >
                        ADD TO PICKLIST
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
            </BottomBar>
            {!!popupIsVisible && this.renderTabbedPopUp()}
            <DialogBox
              isAlertVisible={showAlertOnDelete}
              message={alertMessageOnDelete}
              onClose={this.closeAlert}
              response={deleteResponse}
              onAgree={this.handleRemoveSample}
              title="DELETE ITEMS?"
              primaryButtonText="YES, DELETE ITEMS"
              secondaryButtonText="NEVERMIND"
              fullWidth
            />
          </>
        </ShowIf>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    columnData: state.newShootType.columnData,
    propsColumnData: state.newShootType.propsColumnData,
    clickableColumn: state.newShootType.clickableColumn,
    data: state.newShootType.data,
    goNextUpdatePageFlag: state.newShootType.goNextUpdatePageFlag,
    goNextViewPageFlag: state.newShootType.goNextViewPageFlag,
    headerTitle: state.layout.headerTitle,
    onClick: state.newShootType.onClick,
    order: state.newShootType.order,
    orderBy: state.newShootType.orderBy,
    rowCount: state.newShootType.rowCount,
    shootTypesData: state.newShootType.shootTypesData,
    shootType: state.newShootType.shootType,
    shootTypeId: state.newShootType.shootTypeId,
    showPopup: state.newShootType.showPopup,
    popupIsVisible: state.newShootType.popupIsVisible,
    popupItem: state.newShootType.item,
    page: state.newShootType.page,
    rowsPerPage: state.newShootType.rowsPerPage,
    totalRowsCount: state.newShootType.totalRowsCount,
    fetchShootTypeDataPending: state.newShootType.fetchShootTypeDataPending,
    sampleCount: state.projects.sampleCount,
    isRowsPerPageUpdated: state.newShootType.isRowsPerPageUpdated,
    getProjectsPending: state.projects.getProjectsPending,
    checkBoxEnabled: state.newShootType.checkBoxEnabled,
    // onCheckBoxChange: state.newShootType.onCheckBoxChange,
    selectedData: state.newShootType.selectedData,
    requestedSamplesColumnData: state.newShootType.requestedSamplesColumnData,
    numSelected: state.newShootType.numSelected,
    tabValue: state.newShootType.tabValue,
    requestSamplesStatus: state.projects.requestSamplesStatus,
    flyoutOpen: state.projects.flyoutOpen,
    editSuccess: state.projects.editSuccess,
    downloadLoadingStatus: state.searchInventory.downloadLoadingStatus,
    downloadLoadingStatusPdf: state.searchInventory.downloadLoadingStatusPdf,
    downloadLoadingStatusXlsx: state.searchInventory.downloadLoadingStatusXlsx,
    pickListSideNav: state.newShootType.pickListSideNav,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    downloadProjectSamples: (projectId) => {
      dispatch(downloadProjectSamples(projectId))
    },
    downloadOrderSamplesBySelect: (shootTypeId, selectedPGIds) => {
      dispatch(downloadOrderSamplesBySelect(shootTypeId, selectedPGIds))
    },
    downloadRequestedSamples: (projectId) => {
      dispatch(downloadRequestedSamples(projectId))
    },
    downloadCSVRequestedSamples: (projectId, shootTypeIds = []) => {
      dispatch(downloadCSVRequestedSamples(projectId, shootTypeIds))
    },
    submitOrderForSelected: (shootTypeIds = []) => {
      dispatch(submitOrderForSelected(shootTypeIds))
    },
    downloadContactSheet: (params = {}, downloadType = '') => {
      dispatch(downloadProjectContactSheet(params, downloadType))
    },
    downloadSearchContactSheet: (
      params = {},
      downloadType = '',
      itemType = PROPS
    ) => {
      dispatch(downloadContactSheet(params, downloadType, itemType))
    },
    downloadCollectionSamples: (collectionId) => {
      dispatch(downloadCollectionSamples(collectionId))
    },
    onBackClick: () => dispatch(resetNewShootTypeState()),
    onEditClick: () => dispatch(moveToEditPage()),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    closePopup: () => {
      dispatch(closePopup())
    },
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    getShootType: (
      row,
      shootType,
      page,
      rowsPerPage,
      sampleType,
      filters = {},
      defaultParams = {}
    ) =>
      dispatch(
        getShootType(
          row,
          shootType,
          page,
          rowsPerPage,
          true,
          sampleType,
          filters,
          defaultParams
        )
      ),
    getAProject: (projectID, page, rowsPerPage, sampleType, filters = {}) =>
      dispatch(getAProject(projectID, page, rowsPerPage, sampleType, filters)),
    getACollection: (projectID, page, rowsPerPage) =>
      dispatch(getACollection(projectID, page, rowsPerPage)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
    isRowsUpdated: () => dispatch(isRowsPerPageUpdated()),
    resetIsRowsUpdated: () => dispatch(resetIsRowsUpdated()),
    editSamplesSubmit: (requestBody, lanId) =>
      dispatch(editSamplesSubmit(requestBody, lanId)),
    onCheckBoxChange: (selectedData) => {
      dispatch(setCheckBox(selectedData))
    },
    onCheckBoxHeaderChange: (selected = []) => {
      dispatch(setCheckBoxHeaderChange(selected))
    },
    showNotification: (isShown, message) =>
      dispatch(showNotification(isShown, message)),
    removeOrderSamples: (
      selectedData,
      shootType,
      shootTypeId,
      callBack = () => {}
    ) =>
      dispatch(removeSamples(selectedData, shootType, shootTypeId, callBack)),
    removeRequestedSamples: (selectedData = [], callBack = () => {}) =>
      dispatch(removeRequestedSamples(selectedData, callBack)),
    getVendorEmailsById: (ids = []) => dispatch(getVendorEmailsById(ids)),
    setSampleData: (samples) => {
      dispatch(setSampleData(samples))
    },
    setTabvalue: (tabValue = '') => dispatch(setTabvalue(tabValue)),
    setOrderSamplesFrom: (pagename = '') =>
      dispatch(setOrderSamplesFrom(pagename)),
    cascadeSampleQuantity: (tcinShootTypeId, quantity) =>
      dispatch(cascadeSampleQuantity(tcinShootTypeId, quantity)),
    editFlyoutOpen: () => {
      dispatch(editFlyoutOpen())
    },
    openPickList: () => {
      dispatch(openPickList())
    },
    addItemToPicklist: (pegasusId, projectId, getShootType) => {
      dispatch(addItemToPicklist(pegasusId, projectId, getShootType))
    },
    downloadLoading: (data, downloadType) => {
      dispatch(downloadLoading(data, downloadType))
    },
    deleteItemFromPicklist: (
      pegasusIds,
      projectID,
      page,
      rowsPerPage,
      getShootTypeSamples,
      removeSamples
    ) => {
      dispatch(
        deleteItemFromPicklist(
          pegasusIds,
          projectID,
          page,
          rowsPerPage,
          getShootTypeSamples,
          removeSamples
        )
      )
    },
  }
}

ProjectCrud.propTypes = {
  columnData: propTypes.array,
  requestedSamplesColumnData: propTypes.array,
  clickableColumn: propTypes.array,
  data: propTypes.array,
  onClick: propTypes.func,
  order: propTypes.string,
  orderBy: propTypes.string,
  rowCount: propTypes.number,
  numSelected: propTypes.number,
  tabValue: propTypes.string,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(ProjectCrud)
