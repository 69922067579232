import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import defaultThumbnail from 'images/defaultThumbnail.jpg'
import VisibilityIcon from '@mui/icons-material/Visibility'

const styles = {
  thumbnailWrapper: {
    position: 'relative',
    // width: '80px',
    // height: '80px',
    '&:hover': {
      '& .thumbnailHover': {
        visibility: 'visible',
      },
    },
  },
  thumbnailImage: {
    textAlign: '-webkit-center',
    display: 'flex',
  },
  thumbnailPreview: {
    visibility: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundColor: 'grey',
    opacity: '0.5',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 auto',
  },
}

export const ThumbnailBlock = ({
  classes = {},
  imgSrc = '',
  altText = '',
  identifier = '',
  enablePreview,
  val = {},
  additionalImagesDisplay = false,
  additionalImages = null,
  previewHandler = () => {},
  previewType = '',
  tcinImagesDisplay = '',
}) => (
  <div className={classes.thumbnailWrapper}>
    <div
      data-cy="assetThumbnail"
      className={
        classes.thumbnailPreview + (enablePreview ? ' thumbnailHover' : '')
      }
      onClick={() => {
        previewHandler(val)
      }}
    >
      {previewType === 'view' ? (
        <VisibilityIcon style={{ fontSize: '30px' }} />
      ) : (
        <AddPhotoAlternateIcon style={{ fontSize: '30px' }} />
      )}
    </div>
    <div className={classes.thumbnailImage}>
      {
        <>
          {tcinImagesDisplay === 'noExists' && additionalImagesDisplay && (
            <img
              height={80}
              src={imgSrc}
              onError={(e) => {
                e.target.src = defaultThumbnail
              }}
              alt="Add Image"
            />
          )}
          {
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            additionalImagesDisplay ? (
              additionalImages
            ) : (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                height={80}
                src={imgSrc}
                onError={(e) => {
                  e.target.src = defaultThumbnail
                }}
                alt="Add Image"
              />
            )
          }
        </>
      }
    </div>
  </div>
)

ThumbnailBlock.propTypes = {
  imgSrc: PropTypes.string,
  altText: PropTypes.string,
  identifier: PropTypes.string,
  previewHandler: PropTypes.func,
  enablePreview: PropTypes.bool,
  val: PropTypes.string,
}

export default withStyles(styles)(ThumbnailBlock)
