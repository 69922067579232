import React from 'react'
import * as propTypes from 'prop-types'
import withHOCs from 'util/withHocs'
import apiConfig from '../../apiConfig'
import { isEmpty, isEqual, debounce, pick, omitBy } from 'lodash'
import copy from 'copy-to-clipboard'
import {
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  SwipeableDrawer,
  Chip,
} from '@material-ui/core'
import DialogBox from '../../components/DialogBox/DialogBox'
import BlockIcon from '@material-ui/icons/Block'
import Add from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import GetApp from '@material-ui/icons/GetApp'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Print from '@material-ui/icons/Print'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import LocationDropdowns from '../../components/LocationDropdowns'
import ChipArray from '../../components/Chips/ChipArray'
import NewLocationPopUp from '../NewLocationPopup/NewLocationPopup'
import CustomDropdown from '../../util/CustomDropdown'
import BarcodeTypePopUp from '../../components/PopUp/BarcodeTypePopUp'
import { locationDataToPrint, findLocationLevelId } from '../../util/locations'
// import { sortMyArray } from '../../util/Merge'
import { showNotification } from '../../store/notification/actionCreator'
import {
  downloadStudioLocation,
  filterLocations,
  setPage,
  setRowsPerPage,
  setCheckBox,
  setCheckBoxHeaderChange,
  deleteLocations,
  renameLocations,
  handleFieldUpdate,
  resetToInit,
  deleteLocationsSuccessFun,
  setSortedData,
} from '../../store/manageLocations/actionCreator'
import {
  printNewLocationLabel,
  resetToInitState,
} from '../../store/locations/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { setBarcodeType } from '../../store/user/actionCreator'
import {
  EDIT_LOCATIONS_COMPONENT_TYPE,
  NEW_LOCATIONS_COMPONENT_TYPE,
  locationLevels,
} from '../../enums/locations'
import {
  getLevelsToCreateLocation,
  getLocationIdsByLevel,
  getLevelsWithLabels,
} from '../../store/locations/selectors'
import { SortAlphaNum } from '../../util/CommonUtils'
import { EnhancedTableSortComparator } from '../../components/EnhancedTable/SortComparator'
import BottomBar from 'components/BottomBar'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(3),
    overflowX: 'visible',
  },
  paperRoot: {
    overflowX: 'visible',
    width: '100%',
    margin: '24px 0px',
  },
  filterTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  defaultButtonCSV: {
    maxWidth: '250px',
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
  defaultButtonSD: {
    maxWidth: '250px',
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
  typography: {
    padding: '25px',
  },
  searchInput: {
    width: '360px',
  },
  searchInputLabel: {
    color: '#666 !important',
    fontSize: '14px',
    fontWeight: 'initial',
  },
  notchedOutlineNew: {
    borderColor: '#8d8d8d !important',
    borderWidth: '2px !important',
  },
  clearAll: {
    margin: 20,
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    height: '48px',
    alignItems: 'center',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
  },
  toolBar: {
    display: 'flex',
    marginLeft: '100px',
  },
  defaultButton1: {
    maxWidth: '250px',
    width: 'auto',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  defaultButton2: {
    maxWidth: '250px',
    width: 'auto',
    color: 'white',
    textDecoration: 'underline !important',
    textTransform: 'unset !important',
  },
  errorIcon: {
    display: 'flex',
    color: '#B85300',
    alignItems: 'center',
  },
  errorColor: {
    color: '#B85300',
  },
  errorChip: {
    margin: theme.spacing(1 / 2),
    color: '#B85300',
    border: '1px solid #B85300',
    fontWeight: 700,
  },
  chip: {
    margin: theme.spacing(1 / 2),
  },
})

class ManageLocationsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedData: [],
      selectedLocationIds: [],
      isModalOpen: false,
      resetLocations: false,
      apiSearchValue: '',
      searchBy: '4levels',
      barcodeTypePopUpIsVisible: false,
      handleDropDownTrigger: false,
      handleTriggerProps: {},
      showAlertOnDelete: false,
      alertMessageOnDelete: '',
      deleteResponse: false,
    }
    this.apiTextInput = React.createRef()
    this.anchorRef = React.createRef()
    this.currentUserLocations = debounce(this.currentUserLocations, 100)
  }

  componentDidMount() {
    const { setHeaderTitleArray } = this.props
    setHeaderTitleArray([
      { title: 'Manage Locations', link: '/manageLocations' },
    ])
    this.clearLocations()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page: propsPage,
      rowsPerPage: propsRowsPerPage,
      levelsWithLabels = {},
      deleteLocationsSuccess,
      deleteLocationsSuccessFun = () => {},
    } = this.props
    const {
      page: prevPropsPage,
      rowsPerPage: prevPropsRowsPerPage,
      levelsWithLabels: prevLevelsWithLabels = {},
      deleteLocationsSuccess: prevDeleteLocationsSuccess,
    } = prevProps

    const { isModalOpen } = this.state
    const { searchBy = '4levels' } = this.state
    const { searchBy: prevSearchBy = '4levels' } = prevState
    const { currentUserLocations = () => {} } = this
    if (searchBy === '4levels' && !isEqual(searchBy, prevSearchBy)) {
      this.setState(
        {
          apiSearchValue: '',
        },
        () => {
          if (!isModalOpen) currentUserLocations()
        }
      )
    }

    if (
      (!isEmpty(levelsWithLabels) &&
        !isEqual(levelsWithLabels, prevLevelsWithLabels)) ||
      !isEqual(propsPage, prevPropsPage) ||
      !isEqual(propsRowsPerPage, prevPropsRowsPerPage)
    ) {
      this.currentUserLocations()
    }
    if (
      !!deleteLocationsSuccess &&
      !isEqual(deleteLocationsSuccess, prevDeleteLocationsSuccess)
    ) {
      this.currentUserLocations()
      deleteLocationsSuccessFun(false)
    }
  }

  currentUserLocations = async (locationId = '') => {
    const {
      filterLocations,
      levelsWithLabels = {},
      userLocation = '',
    } = this.props
    const { searchBy = '4levels' } = this.state
    let params = new URLSearchParams()
    const levelsWithLabelsByComp = Object.assign({}, levelsWithLabels)
    params.append('key', apiConfig.apiKey)
    params.append('page', this.props.page)
    params.append('per_page', this.props.rowsPerPage)

    switch (searchBy) {
      case '4levels':
        if (Object.entries(levelsWithLabelsByComp).length > 0) {
          Object.keys(levelsWithLabelsByComp).map((obj) => {
            const paramValue = levelsWithLabelsByComp[obj]
            if (!isEmpty(paramValue)) {
              params.append(obj, levelsWithLabelsByComp[obj])
            }
            return obj
          })
        }
        break
      case 'locationId':
        if (!isEmpty(locationId)) {
          params.append('location_ids', locationId)
        } else {
          params.append('building', userLocation)
        }
        break
      default:
        break
    }
    await filterLocations(params)
  }

  componentWillUnmount() {
    const { resetToInitState = () => {} } = this.props
    resetToInitState(EDIT_LOCATIONS_COMPONENT_TYPE)
    const { onCheckBoxHeaderChange = () => {} } = this.props
    onCheckBoxHeaderChange()
    this.setRowsPerPageAndRefresh()
    this.setPageAndRefresh()
  }

  downloadParams() {
    let params = new URLSearchParams()
    params.append('key', apiConfig.apiKey)
    return params
  }
  downloadCSFunction = async (event) => {
    const {
      downloadStudioLocation = () => {},
      levelsToCreateLocation = {},
      userLocation = '',
    } = this.props
    const { searchBy = '4levels', selectedLocationIds = [] } = this.state
    let params = new URLSearchParams()
    const selectedValueByLevelCopy = Object.assign({}, levelsToCreateLocation)
    params.append('key', apiConfig.apiKey)
    switch (searchBy) {
      case '4levels':
        if (Object.entries(levelsToCreateLocation).length > 0) {
          Object.keys(selectedValueByLevelCopy).map((obj) => {
            const paramValue = selectedValueByLevelCopy[obj]
            if (!isEmpty(paramValue)) {
              params.append(obj, selectedValueByLevelCopy[obj])
            }
            return obj
          })
        }
        break
      case 'locationId':
        if (!isEmpty(selectedLocationIds)) {
          params.append('location_ids', selectedLocationIds)
        } else {
          params.append('building', userLocation)
        }
        break
      default:
        break
    }
    await downloadStudioLocation(params)
  }

  downloadCSVFunction = async () => {
    const { selectedData, downloadStudioLocation = () => {} } = this.props
    let params = new URLSearchParams()
    const locationIds = selectedData.map((obj) => obj.location_id)
    params.append('key', apiConfig.apiKey)
    if (!isEmpty(locationIds)) {
      params.append('location_ids', locationIds)
    }
    await downloadStudioLocation(params)
  }

  toggleAddLocationModal = () => {
    const { isModalOpen, resetLocations } = this.state
    const { resetToInitState = () => {} } = this.props
    const { currentUserLocations = () => {} } = this
    this.setState(
      {
        isModalOpen: !isModalOpen,
        resetLocations: !resetLocations,
        searchBy: '4levels',
      },
      () => {
        const { isModalOpen } = this.state
        if (!isModalOpen) {
          currentUserLocations()
          resetToInitState(NEW_LOCATIONS_COMPONENT_TYPE)
        }
      }
    )
  }

  searchLocationByID = (locationId = '') => {
    const loctionIdString = locationId.split(/[,\r\n\s]+/g).join(',')
    const { onCheckBoxHeaderChange = () => {} } = this.props
    onCheckBoxHeaderChange()
    this.setRowsPerPageAndRefresh()
    this.setPageAndRefresh()
    this.currentUserLocations(loctionIdString)
    this.setState({
      selectedLocationIds: loctionIdString,
    })
  }

  additionalFiltersContent = () => {
    const { resetLocations } = this.state
    const { isModalOpen } = this.props
    return (
      <LocationDropdowns
        isModalOpen={isModalOpen}
        resetLocations={resetLocations}
        setIsNewOption={this.setIsNewOption}
        componentType={EDIT_LOCATIONS_COMPONENT_TYPE}
        onDropdownChange={this.searchLocationByID}
        handleDropDownTrigger={this.state.handleDropDownTrigger}
        handleTriggerProps={this.state.handleTriggerProps}
        handleTriggerCallback={this.handleTriggerCallback}
      />
    )
  }

  setPageAndRefresh = (page) => {
    this.props.setPage(page)
  }

  setRowsPerPageAndRefresh = (rowsPerPage) => {
    this.props.setRowsPerPage(rowsPerPage)
  }

  handleAPISearchChange = (event) => {
    const searchedValue = event.target.value || ''
    const { searchLocationByID = () => {} } = this
    this.setState(
      {
        apiSearchValue: searchedValue,
      },
      () => {
        searchLocationByID(searchedValue)
      }
    )
  }

  handleAPISearchClose = (event) => {
    const { searchLocationByID = () => {} } = this
    this.setState(
      {
        apiSearchValue: '',
      },
      () => {
        searchLocationByID()
      }
    )
  }

  renderSearchLocationsBox = () => {
    const { apiSearchValue = '' } = this.state
    const { classes = {} } = this.props
    return (
      <TextField
        label="Enter Location ID (Barcode)"
        value={apiSearchValue}
        onChange={this.handleAPISearchChange}
        InputProps={{
          classes: {
            input: classes.searchInput,
            notchedOutline: classes.notchedOutlineNew,
            focused: classes.notchedOutlineNew,
          },
        }}
        InputLabelProps={{
          className: classes.searchInputLabel,
        }}
        inputRef={this.apiTextInput}
      />
    )
  }

  searchByFilters = (event) => {
    const { onCheckBoxHeaderChange = () => {} } = this.props
    const {
      setRowsPerPageAndRefresh = () => {},
      setPageAndRefresh = () => {},
    } = this
    this.setState(
      {
        searchBy: event.target.value,
      },
      () => {
        onCheckBoxHeaderChange()
        setRowsPerPageAndRefresh()
        setPageAndRefresh()
      }
    )
  }

  clearLocations = () => {
    const { resetLocations, searchBy = '4levels' } = this.state
    let stateObj = {}
    if (searchBy === '4levels') {
      stateObj = { resetLocations: !resetLocations }
    }
    const { onCheckBoxHeaderChange = () => {} } = this.props
    onCheckBoxHeaderChange()
    this.setRowsPerPageAndRefresh()
    this.setPageAndRefresh()
    this.setState(stateObj)
  }

  onSelected = (selectedData = [], selected = {}) => {
    const { location_id = '' } = selected
    return selectedData.some((obj) => obj.location_id === location_id)
  }

  toggleOpen = () => {
    const { numSelected } = this.props
    return numSelected !== 0
  }

  onDeleteLocationSuccess = () => {
    const { onCheckBoxHeaderChange = () => {} } = this.props
    const {
      setRowsPerPageAndRefresh = () => {},
      setPageAndRefresh = () => {},
    } = this
    onCheckBoxHeaderChange()
    setRowsPerPageAndRefresh()
    setPageAndRefresh()
  }

  onDeleteLocation = () => {
    const { selectedData = [], deleteLocations = () => {} } = this.props
    //unique ids
    const locationIds = selectedData
      .map((obj) => obj.location_id)
      .filter((locationid, index, arr) => arr.indexOf(locationid) === index)
    const paramsObj = {
      location_ids: locationIds.join(','),
    }

    this.setState(
      {
        showAlertOnDelete: false,
        alertMessageOnDelete: '',
        deleteResponse: false,
      },
      () => {
        deleteLocations(paramsObj, this.onDeleteLocationSuccess)
      }
    )
  }

  onPrintLableSuccess = (barcodes) => {
    const file = new Blob([barcodes], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    window.open(fileURL).print()
  }

  onPrintLabel = () => {
    const {
      selectedData = [],
      printNewLocationLabel = () => {},
      barcodeType = 'MULTILINE',
    } = this.props
    const selectedDataCopy = [...selectedData]
    const dataToPrintFinalObj = selectedDataCopy.map((obj) => {
      const { location_id: createdLocationID = '' } = obj
      let dataToPrint = locationDataToPrint(obj)
      dataToPrint['location_id'] = createdLocationID
      return dataToPrint
    })
    printNewLocationLabel(
      dataToPrintFinalObj,
      this.onPrintLableSuccess,
      barcodeType
    )
  }

  handleTriggerCallback = () => {
    this.setState({
      handleDropDownTrigger: false,
      handleTriggerProps: {},
    })
  }

  renameLocationSuccessCB = (resolveType = '', requestParams = {}) => {
    const { apiKey = '' } = apiConfig
    const {
      page: propsPage,
      rowsPerPage: propsRowsPerPage,
      // filterLocations = () => {},
      levelsWithLabels = {},
      levelsToCreateLocation = {},
    } = this.props
    const selectedValueByLevelCount = Object.values(
      omitBy(Object.values(levelsToCreateLocation), isEmpty) || {}
    ).length
    if (
      resolveType === 'success' &&
      !isEmpty(requestParams) &&
      selectedValueByLevelCount > 1
    ) {
      let params = new URLSearchParams()
      const levelsWithLabelsByComp = Object.assign(
        {},
        levelsWithLabels,
        requestParams
      )
      params.append('key', apiKey)
      params.append('page', propsPage)
      params.append('per_page', propsRowsPerPage)
      if (Object.entries(levelsWithLabelsByComp).length > 0) {
        Object.keys(levelsWithLabelsByComp).map((obj) => {
          const paramValue = levelsWithLabelsByComp[obj]
          if (!isEmpty(paramValue)) {
            params.append(obj, levelsWithLabelsByComp[obj])
          }
          return obj
        })
      }
      // filterLocations(params)
      this.setState({
        handleDropDownTrigger: true,
      })
    } else {
      this.setState({
        handleDropDownTrigger: false,
      })
    }
  }

  renameLocation = (updatedData = {}) => {
    const { renameLocations = () => {} } = this.props
    const updatedDataCopy = Object.assign({}, updatedData)
    let paramsToRename = {}
    if (!isEmpty(updatedDataCopy)) {
      const currentEditedCol = updatedDataCopy['editColumn'] || []
      const currentEditedColName = currentEditedCol.slice(-1)[0]
      const currentEditedLevel = findLocationLevelId(currentEditedColName)
      paramsToRename = pick(updatedDataCopy, [
        'aisle',
        'section',
        'shelf',
        'location_id',
      ])
      paramsToRename['level'] = currentEditedLevel || null
      const currentEditedPrevCol = Object.keys(locationLevels).find(
        (key) => locationLevels[key] === currentEditedColName
      )
      const selectedLocationId =
        this.props.storeLoctionIdsByLevel[currentEditedPrevCol]
      const selectedLocationLevel = updatedDataCopy[currentEditedPrevCol]
      this.setState({
        handleTriggerProps: {
          currentLevel: currentEditedPrevCol,
          value: selectedLocationId,
          label: selectedLocationLevel,
        },
      })
    }
    if (!isEmpty(paramsToRename)) {
      renameLocations(paramsToRename, this.renameLocationSuccessCB)
    }
  }

  handleActionButtonClick = (data = []) => {
    copy(data.map((item) => item.label).join('\r\n'), {
      format: 'text/plain',
    })
  }

  renderErrorCard = (detailDeleteErrors = {}) => {
    const detailDeleteErrorsObj = Object.assign({}, detailDeleteErrors)
    const errorLocationWithSamples = detailDeleteErrorsObj['samples'] || ''
    const errorLocationWithLocations = detailDeleteErrorsObj['locations'] || ''
    const errorSamplesExists =
      Object.entries(errorLocationWithSamples).length > 0
    const errorLocationExists =
      Object.entries(errorLocationWithLocations).length > 0
    return (
      <Grid container direction={'column'} spacing={2}>
        <Grid item>
          <Grid container direction={'column'} spacing={1}>
            {errorSamplesExists &&
              Object.keys(errorLocationWithSamples).map((obj) => {
                const sampleCount =
                  errorLocationWithSamples[obj].length || Number(0)
                // const samplesObj = errorLocationWithSamples[obj] || []
                // const samplesChipsObj = [...samplesObj].map((obj) => {
                //   return {
                //     key: obj,
                //     label: obj,
                //   }
                // })
                return (
                  <>
                    <Grid item>{`${
                      'Location ' +
                      obj +
                      ' cannot be deleted. It has ' +
                      sampleCount +
                      ' samples in it.'
                    }`}</Grid>
                    {/* <Grid item>{this.renderErrorChips(samplesChipsObj)}</Grid> */}
                  </>
                )
              })}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction={'column'} spacing={1}>
            {errorLocationExists &&
              Object.keys(errorLocationWithLocations).map((obj) => {
                const locationsObj = errorLocationWithLocations[obj] || []
                const locationsChipsObj = [...locationsObj].map((obj) => {
                  return {
                    key: obj,
                    label: obj,
                  }
                })
                return (
                  <>
                    <Grid item>{`${
                      'Location ' +
                      obj +
                      ' cannot be deleted. It has sub levels in it.'
                    }`}</Grid>
                    <Grid item>
                      {this.renderErrorChips(locationsChipsObj)}
                      {locationsChipsObj.length > 0 && (
                        <Button
                          color="primary"
                          onClick={this.handleActionButtonClick(
                            locationsChipsObj
                          )}
                        >
                          Copy All To Clipboard
                        </Button>
                      )}
                    </Grid>
                  </>
                )
              })}
          </Grid>
        </Grid>
      </Grid>
    )
  }
  renderErrorChips = (data = []) => {
    const { classes = {} } = this.props
    return data.map((i) => {
      return (
        <Chip
          className={i.type === 'duplicate' ? classes.errorChip : classes.chip}
          onClick={this.props.handleChipClick}
          label={i.label}
          key={i.key}
          variant="outlined"
        />
      )
    })
  }

  handleAlertMessage = (e) => {
    const { selectedData = [] } = this.props
    const selectedDataCount = selectedData.length || Number(0)
    const message =
      'You have selected ' + selectedDataCount + ' locations to delete?'

    this.setState({
      showAlertOnDelete: true,
      alertMessageOnDelete: message,
      deleteResponse: true,
    })
  }

  closeAlert = (e) => {
    this.setState({
      showAlertOnDelete: false,
      alertMessageOnDelete: '',
      deleteResponse: false,
    })
  }

  onSortSelectedChange = (order, orderBy, orderType) => {
    const { data = [], setSortedData = () => {} } = this.props
    const dataComparator = SortAlphaNum(order, orderBy)
    let dataCopy = [...data]
    let sortedData = []
    if (orderBy === 'location_id') {
      const dataComparatorNumeric = EnhancedTableSortComparator(
        order,
        orderBy,
        orderType
      )
      sortedData = dataCopy.sort(dataComparatorNumeric)
    } else if (orderBy === 'dateAdded') {
      const dataComparatorNumeric = EnhancedTableSortComparator(
        order,
        orderBy,
        'date'
      )
      sortedData = dataCopy.sort(dataComparatorNumeric)
    } else {
      sortedData = dataCopy.sort(dataComparator)
    }
    setSortedData(sortedData)
  }
  renderBottomBar = () => {
    const {
      classes = {},
      selectedData = [],
      barcodeType = 'MULTILINE',
    } = this.props
    const numSelected = selectedData?.length || Number(0)
    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={this.toggleOpen()}
        onOpen={() => null}
        onClose={() => null}
        showClearAll={numSelected > 0}
        actionType={'REST_ALL_MANAGELOCATIONS'}
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          <Grid container className={classes.swipeableDrawer}>
            <Typography
              variant="body1"
              component="body1"
              className={classes.defaultButtonSD}
            >
              {numSelected} Selected
            </Typography>
            <div className={classes.toolBar}>
              <Button
                id="downloadCsv"
                data-id="EXPORT TO CSV"
                className={classes.defaultButtonSD}
                onClick={() => this.downloadCSVFunction()}
                startIcon={<GetApp />}
              >
                DOWNLOAD CSV
              </Button>
              <Button
                id="printLabel"
                data-id="PRINTLABEL"
                className={classes.defaultButtonSD}
                onClick={this.onPrintLabel}
                startIcon={<Print />}
              >
                PRINT LABEL
              </Button>
              <div className={classes.defaultButton1}>
                {'FORMAT: '}
                {barcodeType === 'DYMO' ? 'Dymo Printer' : 'Label Paper'}
              </div>
              <Button
                id="deleteLocation"
                data-id="DELETELOCATION"
                className={classes.defaultButtonSD}
                onClick={this.handleAlertMessage}
                startIcon={<DeleteForeverIcon />}
              >
                DELETE
              </Button>
            </div>
          </Grid>
        </div>
      </BottomBar>
    )
  }

  render() {
    const {
      classes,
      columnData,
      data = [],
      numSelected,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      onClick,
      selectedData,
      clickableColumn = [],
      totalRowsCount,
      levelsToCreateLocation = {},
      rowsPerPage = 100,
      page = 1,
      checkBoxEnabled,
      fetchFilterRequestPending,
      renameLocationPending,
      barcodeType = 'MULTILINE',
      deleteLocationsFailure,
      errorLocations = [],
      detailDeleteErrors = {},
      order = '',
      orderBy = '',
      orderType = '',
      userLocation = '',
    } = this.props

    const {
      isModalOpen,
      searchBy = '4levels',
      apiSearchValue = '',
      showAlertOnDelete = false,
      alertMessageOnDelete = '',
      deleteResponse = false,
    } = this.state
    const dataFieldKeys = columnData.map((column) => column.id)

    const allLevelsSelectedValues = Object.values(levelsToCreateLocation) || []
    const noOfLevelsSelected = allLevelsSelectedValues.filter(
      (obj) => !isEmpty(obj)
    )

    let renderData = data
    if (deleteLocationsFailure) {
      const updatedData = data.map((obj = {}) => {
        const { location_id: objLoc = '' } = obj
        const newObj = Object.assign({}, obj, {
          isAnErrorRow: errorLocations.indexOf(String(objLoc)) !== -1,
        })
        return newObj
      })
      // renderData = sortMyArray(updatedData, 'isAnErrorRow')
      renderData = [...updatedData]
    }
    const errorLocationsCopy = [...errorLocations]
    const errorLocationsCopyChips = errorLocationsCopy.map((obj) => {
      return {
        key: obj,
        label: obj,
      }
    })
    return (
      <div className={classes.root}>
        <Grid container spacing={3} justify={'space-between'}>
          <Grid item>
            <Typography variant="h4" gutterBottom>
              Manage Locations {userLocation}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              id="addLocationButton"
              color="primary"
              className={classes.defaultButton}
              type="button"
              variant="contained"
              onClick={this.toggleAddLocationModal}
              startIcon={<Add style={{ marginRight: '5px' }} />}
            >
              ADD LOCATION
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paperRoot}>
              <Grid item xs>
                <Typography variant="h6" className={classes.typography}>
                  {'Find Locations'}
                </Typography>
              </Grid>
              <Grid
                item
                container
                spacing={6}
                alignItems={'flex-end'}
                justify={'flex-end'}
                xs
              >
                <Grid item container xs={2}>
                  <CustomDropdown
                    options={{
                      locationId: 'Location ID',
                      '4levels': 'Levels',
                    }}
                    handleChange={this.searchByFilters}
                    value={searchBy}
                    id={'searchLocationBy'}
                    labelId={'Select Location ID or Levels'}
                  />
                </Grid>
                <Grid item container xs={10} spacing={8}>
                  {searchBy === '4levels' ? (
                    <Grid item xs={12}>
                      {this.additionalFiltersContent()}
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      {this.renderSearchLocationsBox()}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems={'flex-end'} justify={'flex-end'}>
                <Button
                  id="clearalllevels"
                  color="primary"
                  onClick={
                    searchBy === '4levels'
                      ? this.clearLocations
                      : this.handleAPISearchClose
                  }
                  className={classes.clearAll}
                  variant="outlined"
                  startIcon={<ClearIcon />}
                  disabled={
                    (Object.values(noOfLevelsSelected).length < 2 &&
                      searchBy === '4levels') ||
                    (isEmpty(apiSearchValue) && searchBy === 'locationId')
                  }
                >
                  Clear
                </Button>
              </Grid>
            </Paper>
          </Grid>
          {errorLocationsCopyChips.length > 0 && (
            <Grid item xs={12} className={classes.sectionMargin}>
              <ChipArray
                // data={errorLocationsCopyChips}
                // enableCopyAllButton
                handleClick={this.props.handleChipClick}
                cardContentFromProps={this.renderErrorCard(detailDeleteErrors)}
                title={
                  <Grid
                    container
                    alignItems={'center'}
                    className={classes.errorIcon}
                    spacing={2}
                  >
                    <Grid item>
                      <ErrorOutlineIcon className={classes.errorIcon} />
                    </Grid>
                    <Grid item>
                      {
                        ' You cannot delete a location with samples or sub levels'
                      }
                    </Grid>
                    {/* <Grid item>{this.renderErrorCard(detailDeleteErrors)}</Grid> */}
                  </Grid>
                }
                textAlign={'left'}
                variant={'subtitle1'}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FlexibleTable
              cascadeFieldKeys={[]}
              uniqueIdentifierInData={'location_id'}
              checkBoxEnabled={checkBoxEnabled}
              columnData={columnData}
              clickableColumn={clickableColumn}
              data={renderData}
              dataFieldKeys={dataFieldKeys}
              downloadFunction={() => this.downloadCSFunction()}
              enableDownload={true}
              numSelected={numSelected}
              onCheckBoxChange={onCheckBoxChange}
              onCheckBoxHeaderChange={onCheckBoxHeaderChange}
              onSortSelectedChange={this.onSortSelectedChange}
              order={order}
              orderBy={orderBy}
              orderType={orderType}
              onClick={onClick}
              onSelected={this.onSelected}
              customRowCount="Locations"
              disableItemCount={false}
              selected={selectedData}
              showPopup={() => {}}
              totalRowsCount={totalRowsCount}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={this.setPageAndRefresh}
              setRowsPerPage={this.setRowsPerPageAndRefresh}
              paginationAtApiLevel={true}
              isLoading={fetchFilterRequestPending}
              editDataColumn={true}
              editDataPrimaryButtonText={'RENAME'}
              updateData={this.renameLocation}
              isUpdateDone={renameLocationPending}
            />
          </Grid>
        </Grid>
        {this.renderBottomBar()}
        <NewLocationPopUp
          isModalOpen={isModalOpen}
          onClose={this.toggleAddLocationModal}
        />
        <BarcodeTypePopUp
          isVisible={this.state.barcodeTypePopUpIsVisible}
          onClose={() => this.setState({ barcodeTypePopUpIsVisible: false })}
          setterCallback={(barcodeType) =>
            this.props.setBarcodeType(barcodeType)
          }
          barcodeType={barcodeType}
        />
        <DialogBox
          isAlertVisible={showAlertOnDelete}
          message={alertMessageOnDelete}
          onClose={this.closeAlert}
          response={deleteResponse}
          // items={selectedData}
          onAgree={this.onDeleteLocation}
          title="Delete Locations?"
          primaryButtonText="YES, DELETE LOCATIONS"
          secondaryButtonText="NEVERMIND"
          fullWidth
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    columnData: state.manageLocations.columnData,
    data: state.manageLocations.data,
    levelsWithLabels: getLevelsWithLabels(EDIT_LOCATIONS_COMPONENT_TYPE)(state),
    totalRowsCount: state.manageLocations.totalRowsCount,
    page: state.manageLocations.page,
    rowsPerPage: state.manageLocations.rowsPerPage,
    userLocation: state.user.location,
    levelsToCreateLocation: getLevelsToCreateLocation(
      EDIT_LOCATIONS_COMPONENT_TYPE
    )(state),
    numSelected: state.manageLocations.numSelected,
    selectedData: state.manageLocations.selectedData,
    checkBoxEnabled: state.manageLocations.checkBoxEnabled,
    fetchFilterRequestPending: state.manageLocations.fetchFilterRequestPending,
    barcodeType: state.user.barcodeType,
    renameLocationPending: state.manageLocations.renameLocationPending,
    deleteLocationsFailure: state.manageLocations.deleteLocationsFailure,
    deleteLocationsSuccess: state.manageLocations.deleteLocationsSuccess,
    errorLocations: state.manageLocations.errorLocations,
    detailDeleteErrors: state.manageLocations.detailDeleteErrors,
    renameLocationSuccess: state.manageLocations.renameLocationSuccess,
    storeLoctionIdsByLevel: getLocationIdsByLevel(
      EDIT_LOCATIONS_COMPONENT_TYPE
    )(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    downloadStudioLocation: (params) => {
      dispatch(downloadStudioLocation(params))
    },
    filterLocations: (params) => dispatch(filterLocations(params)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    resetToInitState: (componentType = '') => {
      dispatch(resetToInitState(componentType))
    },
    resetToInit: () => {
      dispatch(resetToInit())
    },
    printNewLocationLabel: (
      params = [],
      callBack = () => {},
      printType = ''
    ) => {
      dispatch(printNewLocationLabel(params, callBack, printType))
    },
    deleteLocations: (params = [], callBack = () => {}) => {
      dispatch(deleteLocations(params, callBack))
    },
    renameLocations: (params = {}, callBack = () => {}) => {
      dispatch(renameLocations(params, callBack))
    },
    onCheckBoxChange: (selected) => {
      dispatch(resetToInit())
      dispatch(setCheckBox(selected))
    },
    onCheckBoxHeaderChange: (selected = []) => {
      dispatch(resetToInit())
      dispatch(setCheckBoxHeaderChange(selected))
    },
    setBarcodeType: (barcodeType) => dispatch(setBarcodeType(barcodeType)),
    onFieldUpdate: (field, event, locationId) =>
      dispatch(handleFieldUpdate(field, event, locationId)),
    handleChipClick: (chip) => {
      copy(chip.target.innerText)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    deleteLocationsSuccessFun: (status = false) => {
      dispatch(deleteLocationsSuccessFun(status))
    },
    setSortedData: (sortedData) => dispatch(setSortedData(sortedData)),
  }
}

ManageLocationsPage.defaultProps = {
  onSortSelectedChange: () => {},
  order: '',
  orderBy: '',
  orderType: '',
}

ManageLocationsPage.propTypes = {
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  data: propTypes.array,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onClick: propTypes.func,
  onSortSelectedChange: propTypes.func,
  order: propTypes.string,
  orderBy: propTypes.string,
  orderType: propTypes.string,
}
export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(ManageLocationsPage)
