//

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tab from './Tab'
import { NAV_INSET_MAP } from './constants'

export class TabList extends React.Component {
  static defaultProps = {
    classes: {},
  }

  list // see flow docs https://flow.org/en/docs/react/refs/

  render() {
    const { childMap, children, classes, onNavigate, showAccent } = this.props

    return (
      <ul
        className={classes.list}
        ref={(el) => {
          this.list = el
        }}
      >
        {childMap.map((tabProps) => (
          <Tab
            key={tabProps.index}
            onNavigate={onNavigate}
            showAccent={showAccent}
            children={tabProps.children}
            isRambling={tabProps.isRambling}
            index={tabProps.index}
            to={tabProps.to}
          />
        ))}
        {children && <li className={classes.listItem}>{children}</li>}
      </ul>
    )
  }
}

const styleSheet = (theme) => ({
  list: {
    margin: `0 0 0 ${NAV_INSET_MAP.MD}px`,
    display: 'flex',
    paddingLeft: 0,

    [theme.breakpoints.down('sm')]: {
      margin: `0 0 0 ${NAV_INSET_MAP.SM}px`,
    },
  },
  listItem: {
    listStyle: 'none',
  },
})

export default withStyles(styleSheet)(TabList)
