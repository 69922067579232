import * as types from './actionType'
import * as itemAPI from '../../services/itemService'
import * as itemMapper from '../../mappers/itemMapper'
import { default as _ } from 'lodash'

export function clearSearchData() {
  return {
    type: types.ADDSAMPLE_CLEAR_DATA,
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_ADDSAMPLE_PAGE_POPUP,
  }
}

export function resetSearchState() {
  return {
    type: types.RESET_ADDSAMPLE_STATE,
  }
}

export function getData(args = []) {
  args = Array.from(new Set(args))
  return (dispatch) => {
    dispatch(fetchDataRequestPending(true))
    getItems(args)
      .then((result) => getChildTcins(result))
      .then((items) => {
        let invalidItems = []
        items.forEach((it) => {
          if (
            (it.vendorId == null && !it.isParent) ||
            it.lifecycleState === 'HISTORICAL'
          ) {
            invalidItems.push(it)
          }
        })
        var validItems = items.filter(function (e) {
          return invalidItems.indexOf(e) === -1
        })
        return Promise.all([invalidItems, validItems])
      })
      .then(([invalidItems, validItems]) => {
        dispatch(fetchDataSuccess(validItems))
        dispatch(setInvalidData(invalidItems))
        dispatch(fetchDataRequestPending(false))
      })
      .catch(() => {
        dispatch(fetchDataFailure())
        dispatch(fetchDataRequestPending(false))
      })
  }
}

function getChildTcins(start = []) {
  const [parents, baseItems] = _.partition(start, (result) => result.isParent)
  return parents.length > 0
    ? Promise.all([
        baseItems,
        getItems(
          parents.map((parent) =>
            parent.childTcins.map((i) => i.item_data.tcin)
          ) //
        ).then((items) => getChildTcins(items)),
      ]).then((r) => r.flat(2))
    : baseItems
}

function getItems(ids = []) {
  return itemAPI
    .getItemsGraphQL(ids)
    .then((itemResult) => itemMapper.itemsResponseToItems(itemResult))
}

export function fetchDataFailure() {
  return {
    type: types.FETCH_DATA_FAILURE,
    payload: { fetchDataError: 'Failed to fetch data' },
  }
}

export function fetchDataRequestPending(pending = false) {
  return {
    type: types.FETCH_DATA_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchDataSuccess(data = []) {
  return {
    type: types.FETCH_DATA_SUCCESS,
    payload: { data: [...data] },
  }
}

export function setCheckBox(selected) {
  return {
    type: types.ADDSAMPLE_SET_CHECKBOX,
    payload: { selected: selected },
  }
}

export function setCheckBoxHeaderChange(selected) {
  return {
    type: types.ADDSAMPLE_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}

export function setInvalidData(data = []) {
  return {
    type: types.ADDSAMPLE_SET_INVALID_DATA,
    payload: { data: data },
  }
}

export function showPopup(item) {
  return {
    type: types.SHOW_ADDSAMPLE_PAGE_POPUP,
    payload: { item: item },
  }
}

export function unsetSearchSelectedData() {
  return {
    type: types.UNSET_ADDSAMPLE_SELECTED_DATA,
  }
}

export function updateSampleQuantity(data = {}) {
  return {
    type: types.UPDATE_SAMPLE_QUANTITY,
    payload: data,
  }
}
