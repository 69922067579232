import * as types from './actionType'
import { hasFields } from '../../util/Filter'
import { isEqual, findIndex } from 'lodash'

export const initialState = {
  createCollectionPopupVisible: false,
  columnData: [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Collection Name',
      align: 'center',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Creator',
      align: 'center',
    },
    {
      id: 'locationName',
      numeric: false,
      disablePadding: false,
      label: 'Photoshoot Location',
      align: 'center',
    },
    {
      id: 'createDate',
      numeric: false,
      disablePadding: false,
      label: 'Created Date',
      align: 'center',
      type: 'date',
    },
    {
      id: 'marketingPid',
      numeric: false,
      disablePadding: false,
      label: 'COP/VAP#',
      align: 'center',
    },
    {
      id: 'sampleCount',
      numeric: false,
      disablePadding: false,
      label: 'Samples',
      align: 'center',
    },
  ],
  order: 'asc',
  orderBy: 'name',
  page: 0,
  rowsPerPage: 25,
  createCollectionRequest: {
    name: '',
    channel: '',
  },
  allCollections: [],
  filteredCollections: [],
  filterText: '',
  createCollectionPending: false,
  createCollectionFailure: false,
  getCollectionsPending: false,
  getCollectionsFailure: false,
  collectionCreators: [],
  collectionLocations: [],
  numSelected: Number(0),
  checkBoxEnabled: true,
  selectedData: [],
  updateCollectionStatusPending: false,
  tabValue: '',
  creatorValue: {},
  locationValue: {},
  filterValue: '',
}

export default function collections(state = initialState, action) {
  switch (action.type) {
    case types.GET_COLLECTIONS_REQUEST_SUCCESS: {
      const {
        payload: { allCollections = [] },
      } = action
      return Object.assign({}, state, {
        getCollectionsFailure: false,
        allCollections: allCollections,
        filteredCollections: allCollections,
      })
    }
    case types.UPDATE_COLLECTION_FILTERS: {
      const {
        payload: { collectionCreators = [], collectionLocations = [] },
      } = action
      return Object.assign({}, state, {
        collectionCreators: collectionCreators,
        collectionLocations: collectionLocations,
      })
    }
    case types.CURRENT_COLLECTION_REQUEST_SUCCESS: {
      const { allCollections: existingCollections = [] } = state
      const {
        payload: { collections: currentCollection = {} },
      } = action
      const { id: currentCollectionID = '' } = currentCollection
      const listWOCurrentCollection = existingCollections.filter(
        (obj) => obj.id !== currentCollectionID
      )
      return Object.assign({}, state, {
        allCollections: [currentCollection, ...listWOCurrentCollection],
        filteredCollections: [currentCollection, ...listWOCurrentCollection],
      })
    }
    case types.GET_COLLECTIONS_REQUEST_PENDING: {
      return Object.assign({}, state, {
        getCollectionsPending: action.payload.pending,
      })
    }
    case types.GET_COLLECTIONS_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        getCollectionsFailure: true,
      })
    }
    case types.COLLECTIONS_CREATE_REQUEST_SUCCESS: {
      const updatedCollections = [
        ...state.allCollections,
        action.payload.allCollections,
      ]
      return Object.assign({}, state, {
        allCollections: updatedCollections,
        filteredCollections: updatedCollections.filter((i) =>
          hasFields(i, state.filterText)
        ),
      })
    }
    case types.COLLECTIONS_CREATE_REQUEST_PENDING: {
      return Object.assign({}, state, {
        createCollectionPending: action.payload.pending,
      })
    }
    case types.COLLECTIONS_CREATE_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        createCollectionFailure: true,
      })
    }
    case types.COLLECTIONS_SET_FILTER: {
      return Object.assign({}, state, {
        filterText: action.payload.filter,
        filteredCollections: state.allCollections.filter((i) =>
          hasFields(i, action.payload.filter)
        ),
      })
    }
    case types.COLLECTIONS_CLEAR_FILTER: {
      return Object.assign({}, state, {
        filterText: '',
        filteredCollections: state.allCollections,
      })
    }
    case types.COLLECTIONS_SHOW_CREATE_POPUP: {
      return Object.assign({}, state, {
        createCollectionPopupVisible: true,
      })
    }
    case types.COLLECTIONS_CLOSE_CREATE_POPUP: {
      return Object.assign({}, state, {
        createCollectionPopupVisible: false,
        createCollectionRequest: {
          name: '',
          channel: '',
        },
      })
    }
    case types.UPDATE_COLLECTIONS_STATUS_PENDING: {
      return Object.assign({}, state, {
        updateCollectionStatusPending: action.payload.pending,
      })
    }
    case types.COLLECTIONS_SET_CHECKBOX: {
      const { selectedData: stateSelectedData = [] } = state
      const {
        payload: { selected = {} },
      } = action
      const { id: selectedId = '' } = selected
      const selectedIndex = findIndex(stateSelectedData, function (obj = {}) {
        return obj.id === selectedId
      })
      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          stateSelectedData,
          action.payload.selected
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(stateSelectedData.slice(1))
      } else if (selectedIndex === stateSelectedData.length - 1) {
        newSelected = newSelected.concat(stateSelectedData.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          stateSelectedData.slice(0, selectedIndex),
          stateSelectedData.slice(selectedIndex + 1)
        )
      }

      return Object.assign({}, state, {
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.COLLECTIONS_SET_CHECKBOX_HEADER: {
      let newSelected = []
      const {
        payload: { selected: payloadSelected = [] },
      } = action
      const { selectedData: stateSelectedData = [] } = state
      if (isEqual(payloadSelected, stateSelectedData)) {
        newSelected = []
      } else {
        newSelected = payloadSelected
      }
      return Object.assign({}, state, {
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.SET_COLLECTION_TABVALUE: {
      return Object.assign({}, state, {
        tabValue: action.payload.tabValue,
      })
    }
    case types.SET_CREATOR_VALUE: {
      return Object.assign({}, state, {
        creatorValue: action.payload.creatorValue,
      })
    }
    case types.SET_LOCATION_VALUE: {
      return Object.assign({}, state, {
        locationValue: action.payload.locationValue,
      })
    }
    case types.SET_COLLECTIONS_SEARCH_VALUE: {
      const {
        payload: { filterValue = '' },
      } = action
      return Object.assign({}, state, {
        ...state,
        filterValue,
      })
    }
    default:
      return state
  }
}
