/* eslint-disable import/no-anonymous-default-export */
export default {
  container: {
    display: 'flex',
    flex: '1 0 auto',
    height: '100%',
  },
  container__isScrollable: {
    overflowX: 'scroll',
    maxWidth: '100%',
    position: 'absolute',
    top: 0,
    paddingBottom: 15,
  },
}
