import axios from 'axios'
import apiConfig from '../apiConfig'
import { default as _ } from 'lodash'

const {
  items: { graphql_url = '' },
  apiKey = '',
} = apiConfig

/* Args should both be an array of valid tcin and/or dpci strings */
export function getItemsGraphQL(ids = []) {
  let unique_ids = ids.filter(function (value, index) {
    // If there is any undefined value for TCIN in array then remove it from the unique tcin list
    return ids.indexOf(value) === index && !_.isUndefined(value)
  })
  // If there are empty or null values for TCIN list then return empty array as item's API response
  if (!ids || _.isEmpty(ids)) {
    return Promise.resolve([])
  }
  return axios
    .all(
      _.chunk(unique_ids, 25).map((slice) => {
        return axios.get(graphql_url + slice.join(','), {
          params: {
            key: apiKey,
          },
        })
      })
    )
    .then((results) => {
      return [].concat.apply(
        [],
        [].concat.apply(results.map((response) => response?.data?.items))
      )
    })
}

export function getAllDepartments() {
  return axios.get(graphql_url + 'departments', {
    params: {
      key: apiKey,
    },
  })
}

export function getClassByDepartment(departmentId = '') {
  return axios.get(graphql_url + 'deptClasses/' + departmentId, {
    params: {
      key: apiKey,
    },
  })
}
export function editAutoOrder(params = []) {
  // Adding params key in both url and config
  return axios.put(graphql_url + 'auto_order_grid', [...params], {
    params: {
      key: apiKey,
    },
  })
}
