import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumericFormatCustom from './NumericFormatCustom'
import {
  isEmpty,
  omitBy,
  isNil,
  mapValues,
  mapKeys,
  isUndefined,
  isEqual,
} from 'lodash'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import AddIcon from '@mui/icons-material/Add'
import withHOCs from 'util/withHocs'
import {
  propsTabDataKeyLabels,
  scenicTabDataKeyLabels,
} from '../../enums/tableTabs'
import DialogBox from '../../components/DialogBox/DialogBox'
import { addAProp } from '../../store/searchInventory/actionCreator'
import { Button, Grid } from '@mui/material'
import { setCurPropImages } from 'store/addProp/actionCreator'
import { getSubCategories } from 'util/CommonUtils'
import { PROPS, SCENIC } from 'enums/Tabs'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    minWidth: '1200px',
    width: 'auto',
  },
  helperText: {
    textAlign: 'right',
  },
  optionViews: {},
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'flex-start !important',
  },
})
const renderTextField = ({
  name = '',
  type = '',
  label = '',
  options = [],
  updateStateCallBack = () => {},
  value = null,
  classes = {},
  mandatory = false,
  disabled = false,
  margin = '0px',
  selectedTab = PROPS,
}) => {
  const isNumberFieldStyle =
    ['propCost', 'propBundleQuantity'].indexOf(name) !== -1
  const isNumberField =
    ['propBundleQuantity', 'propCost', 'scenicCost'].indexOf(name) !== -1
  const isSizeField =
    [
      'size_width',
      'size_height',
      'size_depth',
      'size_other_dimension',
      'size_weight',
    ].indexOf(name) !== -1

  return ['propCost', 'scenicCost'].indexOf(name) !== -1 ? (
    <TextField
      label={label}
      value={isNumberField ? Number(value) : value || ''}
      onChange={(event) => {
        updateStateCallBack(name, type, event.target.value)
      }}
      name={name}
      id="formatted-numberformat-input"
      InputProps={{
        inputComponent: NumericFormatCustomNew,
      }}
      error={mandatory && isNil(value)}
      inputProps={{
        thousandSeparator: true,
        prefix: '$',
        decimalScale: 2,
        isAllowed: (values) => {
          const { formattedValue, floatValue } = values
          return selectedTab !== SCENIC
            ? formattedValue === '' || floatValue <= 999
            : formattedValue === '' || floatValue <= 99999
        },
      }}
      variant="outlined"
    />
  ) : (
    <TextField
      className={classes.optionViews}
      variant="outlined"
      onChange={(event) => {
        const targetValue = event.target.value || ''
        const newValue = isNumberField ? Number(targetValue) : targetValue || ''
        updateStateCallBack(name, type, newValue)
      }}
      multiline={isNumberFieldStyle ? false : true}
      style={
        isNumberFieldStyle || isSizeField
          ? {
              minWidth: '100px',
              width: '150px',
              marginTop: '20px',
              marginLeft: margin,
            }
          : { minWidth: '361px' }
      }
      value={isNumberField ? Number(value) : value || ''}
      inputProps={{
        maxLength: 250,
      }}
      InputLabelProps={{
        shrink: isNumberField ? true : !isEmpty(value) ? true : false,
      }}
      error={mandatory && isNil(value)}
      type={isNumberField ? 'number' : 'text'}
      label={label}
      FormHelperTextProps={{
        classes: { root: classes.helperText },
      }}
      disabled={disabled}
      InputProps={{
        inputProps: isNumberField
          ? { inputProps: { min: 1, max: 999, step: 1 } }
          : {},
      }}
    />
  )
}
const renderImage = (imageValue = '') => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start !important',
      }}
    >
      {imageValue}
    </div>
  )
}
const RenderComponents = ({
  keyDetailData = {},
  options = [],
  updateStateCallBack = () => {},
  classes = {},
  propsMetaData = {},
  formFieldValues = {},
  pageType = '',
  selectedTab = '',
}) => {
  let {
    editType: type = 'textField',
    key: name = '',
    label = '',
    mandatory = false,
  } = keyDetailData
  const value = formFieldValues[name]
  const duplicateImage = formFieldValues[name]
  const isBundled = formFieldValues['propBundled']
  const propTcinImage = formFieldValues['propTcinImage']
  const propDuplicateImage = formFieldValues['propDuplicateImage']

  switch (name) {
    case 'propCategory':
    case 'propSubcategory':
    case 'propColorFamily':
    case 'propMaterial':
    case 'propSource':
    case 'propBrand':
    case 'scenicCategory':
    case 'scenicSubcategory':
    case 'scenicColorFamily':
    case 'scenicMaterial':
    case 'scenicChannel':
    case 'scenicSource':
    case 'scenicBrand': {
      return (
        <Autocomplete
          id="Category-tags-demo"
          options={options}
          multiple={
            name === 'propColorFamily' || name === 'scenicColorFamily'
              ? true
              : false
          }
          getOptionLabel={(option = {}) => option?.label ?? ''}
          onChange={(event, newValue, reason) => {
            updateStateCallBack(name, type, newValue)
          }}
          getOptionSelected={(option, value) => {
            return option.id === value.id
          }}
          value={getRenderValue(value, name)}
          renderOption={(option, { selected, inputValue }) => {
            return <>{option?.label}</>
          }}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={mandatory && isEmpty(value)}
              variant="outlined"
              fullWidth={true}
              style={{ width: 361 }}
              label={`${label + '(s)'}`}
              placeholder={`${label + '(s)'}`}
            />
          )}
        />
      )
    }
    case 'propSize':
    case 'scenicSize': {
      return (
        <FormGroup row>
          <FormControlLabel
            control={renderTextField({
              name: 'size_width',
              type: 'number',
              label: 'Width',
              options: [],
              updateStateCallBack,
              value: (value || {})['width'] || '',
              classes,
              mandatory: mandatory && isEmpty(value),
            })}
            label={''}
            sx={{ marginLeft: 0 }}
          />
          <FormControlLabel
            control={renderTextField({
              name: 'size_height',
              type: 'number',
              label: 'Height',
              options: [],
              updateStateCallBack,
              value: (value || {})['height'] || '',
              classes,
              mandatory: mandatory && isEmpty(value),
            })}
            label={''}
          />
          <FormControlLabel
            control={renderTextField({
              name: 'size_depth',
              type: 'number',
              label: 'Depth',
              options: [],
              updateStateCallBack,
              value: (value || {})['depth'] || '',
              classes,
              mandatory: mandatory && isEmpty(value),
            })}
            label={''}
          />
          <FormControlLabel
            control={renderTextField({
              name: 'size_other_dimension',
              type: 'number',
              label: 'Other',
              options: [],
              updateStateCallBack,
              value: (value || {})['other_dimension'] || '',
              classes,
              mandatory: mandatory && isEmpty(value),
            })}
            label={''}
          />
          {name === 'scenicSize' && (
            <FormControlLabel
              control={renderTextField({
                name: 'size_weight',
                type: 'number',
                label: 'Weight',
                options: [],
                updateStateCallBack,
                value: (value || {})['weight'] || '',
                classes,
                mandatory: mandatory && isEmpty(value),
                margin: '10px',
              })}
              label={''}
            />
          )}
        </FormGroup>
      )
    }
    case 'propBundled':
    case 'scenicBundled': {
      return (
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={value}
                onChange={(event) => {
                  const newValue = event.target.checked
                  updateStateCallBack(name, type, newValue)
                }}
                name={label}
                color="primary"
              />
            }
            label={''}
          />
        </FormGroup>
      )
    }
    case 'sampleImageDPURL': {
      const message =
        selectedTab === SCENIC
          ? 'Duplicate The Scenic Image'
          : 'Duplicate The Prop Image'
      return !isEmpty(formFieldValues['sampleImagePopupUrl']) ? (
        <>
          {renderImage(duplicateImage)}
          <div>
            <FormControlLabel
              label={message}
              control={
                <Checkbox
                  checked={propDuplicateImage || ''}
                  onChange={(event) => {
                    const newValue = event.target.checked
                    updateStateCallBack('propDuplicateImage', type, newValue)
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
          </div>
        </>
      ) : (
        ' '
      )
    }
    case 'imageUrl': {
      return formFieldValues['imagePopupUrl'] && type !== 'editProp' ? (
        <>
          {renderImage(value)}
          <div>
            <FormControlLabel
              label={'Mark tcin image as prop image'}
              control={
                <Checkbox
                  checked={propTcinImage || ''}
                  onChange={(event) => {
                    const newValue = event.target.checked
                    updateStateCallBack('propTcinImage', type, newValue)
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
          </div>
        </>
      ) : (
        ' '
      )
    }
    case '':
      return <></>
    default:
      return renderTextField({
        name,
        type,
        label,
        options,
        updateStateCallBack,
        value:
          isBundled && name === 'propBundleQuantity' && isUndefined(value)
            ? Number(1)
            : value,
        classes,
        mandatory:
          isBundled && name === 'propBundleQuantity' ? true : mandatory,
        disabled:
          name === 'propBundleQuantity' ? (isBundled ? false : true) : false,
        selectedTab
      })
  }
}

const getRenderValue = (value = '', name = '') => {
  let returnValue = !isEmpty(value)
    ? value
    : name === 'propColorFamily' || name === 'scenicColorFamily'
      ? []
      : ''
  return returnValue
}

const AddPropsTab = ({
  item = {},
  dataLabels = [],
  updateSamplePageCallback = () => {},
  shootTypesData = [],
  propsMetaData: propsMetaDataParent = {},
  classes = {},
  brandsList = [],
  auth = {},
  pageName = '',
  type = '',
  selectedTab = '',
}) => {
  const { session: { userInfo: { lanId = '' } = {} } = {} } = auth
  const dispatch = useDispatch()
  const { propsMetadata = {}, originalPropsMetadata = {} } = useSelector(
    (state) => state.searchInventory
  )
  const [formFieldValues, setFormFieldValues] = useState({})
  const [finalPropsMetadata, setPropsMetadata] = useState({})
  const [showAlertOnAdd, setShowAlertOnAdd] = useState(false)
  const [alertMessageOnAdd, setAlertMessageOnAdd] = useState('')
  const [addResponse, setAddResponse] = useState(false)
  const [finalReqPayloadObj, setFinalReqPayloadObj] = useState({})

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevItem = usePrevious(item)

  useEffect(() => {
    if (Object.entries(item).length > 0) {
      const itemCopy =
        selectedTab !== SCENIC
          ? item?.imagePopupUrl && type !== 'editProp'
            ? Object.assign(
                {},
                { propBundled: false, propTcinImage: true },
                item
              )
            : Object.assign(
                {},
                { propBundled: false, propTcinImage: false },
                item
              )
          : Object.assign({}, { scenicBundled: false }, item)
      setFormFieldValues(itemCopy)
    }
    return function cleanup() {
      setPropsMetadata({})
      setFormFieldValues({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { sampleImagePopupUrl: prevPropsImagePopupUrl = [] } = prevItem || {}
    const { sampleImagePopupUrl = [] } = item
    const imageUrlsChanged =
      (!isEmpty(prevPropsImagePopupUrl) &&
        !isEqual(prevPropsImagePopupUrl, sampleImagePopupUrl)) ||
      (!isEmpty(sampleImagePopupUrl) &&
        !isEqual(prevPropsImagePopupUrl, sampleImagePopupUrl))
    if (
      imageUrlsChanged &&
      Object.entries(item).length > 0 &&
      ['ADDSCENICVIEW', 'ADDPROPVIEW'].indexOf(pageName) !== -1
    ) {
      setFormFieldValues(item)
    }
  }, [item, pageName, prevItem])

  useEffect(() => {
    if (Object.entries(propsMetadata).length) {
      propsMetadataCB(propsMetadata)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsMetadata])

  useEffect(() => {
    const formFieldValuesCopy = omitBy(formFieldValues, isNil)
    let validFormCheck = false
    if (Object.entries(formFieldValuesCopy).length > 0) {
      let pageLabels =
        pageName === 'ADDSCENIC'
          ? scenicTabDataKeyLabels
          : propsTabDataKeyLabels
      let mandatoryFields = pageLabels
        ?.filter((fobj) => fobj.mandatory === true)
        .map((mobj) => mobj.key)
      const isBundled =
        pageName === 'ADDSCENIC'
          ? formFieldValues['scenicBundled']
          : formFieldValues['propBundled']
      if (isBundled && pageName === 'ADDSCENIC') {
        mandatoryFields = [].concat.apply(mandatoryFields, [
          'scenicBundleQuantity',
        ])
      } else if (isBundled) {
        mandatoryFields = [].concat.apply(mandatoryFields, [
          'propBundleQuantity',
        ])
      }
      const filledFields = Object.keys(formFieldValuesCopy) || []
      const reqFilledFields = mandatoryFields.filter(
        (x) => !filledFields.includes(x)
      )
      validFormCheck = reqFilledFields.length > 0 ? true : false
    } else {
      validFormCheck = true
    }
    updateSamplePageCallback({
      disableSubmit: validFormCheck,
      formFieldValues: formFieldValues,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldValues])

  useEffect(() => {
    Object.entries(finalReqPayloadObj).length > 0 &&
      dispatch(addAProp(finalReqPayloadObj))
    return function cleanup() {
      setShowAlertOnAdd(false)
      setAlertMessageOnAdd('')
      setAddResponse(false)
    }
  }, [dispatch, finalReqPayloadObj])

  const propsMetadataCB = async (propsMetadataRes = {}) => {
    const {
      colorFamily: propColorFamily = [],
      materials: propMaterial = [],
      sources: propSource = [],
      categories: propCategory = [],
      channels: scenicChannel = [],
    } = propsMetadataRes || {}

    const { categories: itemCategories = [] } = originalPropsMetadata
    const itemCatSubCatList = getSubCategories(itemCategories)
    const propsMetaDataNew = {
      propColorFamily,
      propMaterial,
      propSource,
      propCategory,
      propSubcategory: [],
      orgPropSubcategory: itemCatSubCatList,
    }
    const scenicMetaDataNew = {
      scenicColorFamily: propColorFamily,
      scenicMaterial: propMaterial,
      scenicCategory: propCategory,
      orgScenicSubcategory: itemCatSubCatList,
      scenicSource: propSource,
      scenicChannel: scenicChannel,
    }
    if (pageName === 'ADDSCENIC') {
      await setPropsMetadata(scenicMetaDataNew)
    } else {
      setPropsMetadata(propsMetaDataNew)
    }
  }

  const updateStateCallBack = (fieldName = '', fieldType = '', fieldValue) => {
    if (fieldName) {
      if (fieldName.startsWith('size')) {
        const currentValue =
          selectedTab !== SCENIC
            ? formFieldValues['propSize']
            : formFieldValues['scenicSize']
        const currentField = fieldName.replace('size_', '')
        const sizeValues = currentField
          ? Object.assign({}, currentValue, {
              [currentField]: fieldValue,
            })
          : {}
        const newSizeValues = omitBy(sizeValues, isEmpty)
        const newValues =
          selectedTab !== SCENIC
            ? Object.assign({}, formFieldValues, {
                propSize: newSizeValues,
              })
            : Object.assign({}, formFieldValues, {
                scenicSize: newSizeValues,
              })

        setFormFieldValues(newValues)
      } else {
        if (fieldName === 'propCategory') {
          formFieldValues['propSubcategory'] = null
        }
        const newValues = Object.assign({}, formFieldValues, {
          [fieldName]: fieldValue,
        })
        setFormFieldValues(newValues)
      }
    }
    if (['propBundled', 'scenicBundled'].indexOf(fieldName) !== -1) {
      if (!fieldValue) {
        formFieldValues['propBundleQuantity'] = undefined
      } else if (
        (fieldValue && isUndefined(formFieldValues['propBundleQuantity'])) ||
        isEmpty(formFieldValues['propBundleQuantity'])
      ) {
        if (fieldName === 'propBundled') {
          formFieldValues['propBundleQuantity'] = Number(1)
        } else {
          formFieldValues['scenicBundleQuantity'] = Number(1)
        }
      }
      const newValues = Object.assign({}, formFieldValues, {
        [fieldName]: fieldValue,
      })
      setFormFieldValues(newValues)
    }
    if (fieldName === 'propCategory' && pageName !== 'ADDSCENIC') {
      const { id: currentCatId = '' } = fieldValue || {}
      const currentSubCategoryList = [
        ...(finalPropsMetadata['orgPropSubcategory'] || []),
      ]?.filter((sobj) => sobj.categoryId === currentCatId)
      const propsMetaDataNew = Object.assign({}, finalPropsMetadata, {
        propSubcategory: currentSubCategoryList,
      })

      setPropsMetadata(propsMetaDataNew)
    } else if (fieldName === 'scenicCategory') {
      const { id: currentCatId = '' } = fieldValue || {}
      const currentSubCategoryList = [
        ...(finalPropsMetadata['orgScenicSubcategory'] || []),
      ]?.filter((sobj) => sobj.categoryId === currentCatId)
      const propsMetaDataNew = Object.assign({}, finalPropsMetadata, {
        scenicSubcategory: currentSubCategoryList,
      })

      setPropsMetadata(propsMetaDataNew)
    }
  }
  // TODO: RELOOK
  // const submitAddPropForm = () => {
  //   const currentFilledColCount = Object.keys(formFieldValues || {}).length
  //   if (currentFilledColCount < 5) {
  //     const message = `${'You have entered minial data to create a Prop. Do you still want to proceed to create a Prop?'}`
  //     setShowAlertOnAdd(true)
  //     setAlertMessageOnAdd(message)
  //     setAddResponse(true)
  //   } else {
  //     submitAddPropFormConfirm()
  //   }
  // }

  const closeAlert = (e) => {
    setShowAlertOnAdd(false)
    setAlertMessageOnAdd('')
    setAddResponse(false)
  }

  const submitAddPropFormConfirm = () => {
    const newFormFieldValues = mapValues(formFieldValues, function (o, index) {
      let newValue = o?.value || o
      return newValue
    })
    const reqPayloadObj = mapKeys(newFormFieldValues, function (value, key) {
      const refObj = propsTabDataKeyLabels.find((obj) => obj.key === key) || {}
      return refObj?.apiName || key
    })
    const finalReqPayloadObj = {
      props: [
        Object.assign({}, reqPayloadObj, {
          item_quantity: 1,
          item_details: null,
          user_id: lanId,
        }),
      ],
    }
    setFinalReqPayloadObj(finalReqPayloadObj)
  }

  const toggleShowPropsImage = async (itemObj = {}) => {
    const { sampleId = '', sampleImagePopupUrl = [] } = itemObj
    await updateSamplePageCallback({ addImagesProps: { itemObj } })
    await dispatch(setCurPropImages(sampleImagePopupUrl, sampleId))
  }

  const renderImageWithAdd = (imageValue = '', itemObj = {}) => {
    return (
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item>{renderImage(imageValue)}</Grid>
        <Grid item>
          <Button
            variant="text"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => toggleShowPropsImage(itemObj)}
          >
            {'Add Images'}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const getComponentOptions = (itemDataKey) => {
    let componentOptions =
      (itemDataKey === 'propBrand' || itemDataKey === 'scenicBrand'
        ? brandsList
        : [...(finalPropsMetadata[itemDataKey] || [])]) || []

    if (itemDataKey === 'propSubcategory' && pageName !== 'ADDSCENIC') {
      const { id: currentCatId = '' } = formFieldValues['propCategory'] || {}
      const currentSubCategoryList = [
        ...(finalPropsMetadata['orgPropSubcategory'] || []),
      ]?.filter((sobj) => sobj.categoryId === currentCatId)
      componentOptions = currentSubCategoryList
    } else if (itemDataKey === 'scenicSubcategory' && pageName !== 'ADDPROP') {
      const { id: currentCatId = '' } = formFieldValues['scenicCategory'] || {}
      const currentSubCategoryList = [
        ...(finalPropsMetadata['orgScenicSubcategory'] || []),
      ]?.filter((sobj) => sobj.categoryId === currentCatId)
      componentOptions = currentSubCategoryList
    }
    return componentOptions
  }
  const dataLabelsCopy =
    ['convertToProp', 'CONVERT_SAMPLE_TO_PROP', 'CONVERT_TCIN_TO_PROP'].indexOf(
      type
    ) !== -1
      ? [...dataLabels, { key: 'imageUrl', label: 'Tcin Image' }]
      : ['duplicateProp', 'DUPLICATE_PROP'].indexOf(type) !== -1 &&
          !isEmpty(formFieldValues['sampleImagePopupUrl'])
        ? [...dataLabels, { key: 'sampleImageDPURL', label: 'Prop Image' }]
        : ['DUPLICATE_SCENIC', 'duplicateScenic'].indexOf(type) !== -1
          ? [...dataLabels, { key: 'sampleImageDPURL', label: 'Scenic Image' }]
          : [...dataLabels]
  return (
    <>
      <Grid
        container
        justifyContent={'cennter'}
        spacing={2}
        style={{ marginTop: 10 }}
      >
        {dataLabelsCopy.map((keyDetailData = {}) => {
          let { key: itemDataKey = '', label: itemDataLabel = '' } =
            keyDetailData
          const componentOptions = getComponentOptions(itemDataKey)
          const objValue =
            itemDataKey === 'imageUrl' && !formFieldValues['imagePopupUrl']
              ? false
              : true
          return (
            /**xs={6} for two column grid*/
            objValue ? (
              <Grid item container xs={6} alignItems={'center'}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  {itemDataLabel}
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                  {['ADDSCENICVIEW', 'ADDPROPVIEW'].indexOf(pageName) !== -1
                    ? itemDataKey === 'sampleImageDPURL'
                      ? renderImageWithAdd(formFieldValues[itemDataKey], item)
                      : formFieldValues[itemDataKey]
                    : itemDataKey !== 'propTcinImage'
                      ? RenderComponents({
                          keyDetailData,
                          options: componentOptions,
                          updateStateCallBack: updateStateCallBack,
                          classes: classes,
                          propsMetaData: finalPropsMetadata,
                          formFieldValues: formFieldValues,
                          pageType: type,
                          selectedTab: selectedTab,
                        })
                      : ''}
                </Grid>
              </Grid>
            ) : (
              ''
            )
          )
        })}
      </Grid>
      {showAlertOnAdd && (
        <DialogBox
          isAlertVisible={showAlertOnAdd}
          message={alertMessageOnAdd}
          onClose={closeAlert}
          response={addResponse}
          onAgree={submitAddPropFormConfirm}
          title="Add Props?"
          primaryButtonText="YES, ADD PROP"
          secondaryButtonText="NEVERMIND"
          fullWidth
        />
      )}
    </>
  )
}

const NumericFormatCustomNew = React.forwardRef((props, ref) => (
  <NumericFormatCustom {...props} ref={ref} />
))

export default withHOCs({
  auth: true,
  nav: true,
  styles,
})(AddPropsTab)
