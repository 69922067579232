import * as types from './actionType'

const primaryFilterOptions = [
  { label: 'TCIN', value: 'tcin', type: 'listInput' },
  { label: 'DPCI', value: 'dpci', type: 'listInput' },
]

const commonFilterOptions = [
  ...primaryFilterOptions,
  {
    label: 'Department',
    value: 'departmentId',
    type: 'autoComplete',
    displayName: 'Dept-Class',
  },
  { label: 'Class', value: 'classId', type: 'autoComplete' },
  { label: 'Vendor ID', value: 'vendor_id', type: 'listInput' },
]

export const initialState = (window.Cypress && window.initialState) || {
  pageName: 'autoOrderFilters',
  fetchDataRequestPending: false,
  addFilterOptions: [
    {
      label: 'Department',
      value: 'departmentId',
      type: 'autoComplete',
      displayName: 'Dept-Class',
    },
    { label: 'Class', value: 'classId', type: 'autoComplete' },
    { label: 'Vendor ID', value: 'vendor_id', type: 'listInput' },
    { label: 'Launch Date', value: 'launchDate', type: 'dateInput' },
    { label: 'Brand', value: 'brand', type: 'autoComplete' },
    
  ],
  orderedSamplesOptions: [
    { label: 'Pegasus ID', value: 'sample_id', type: 'listInput' },
    ...commonFilterOptions,
    { label: 'Brand', value: 'brand', type: 'autoComplete' },
    { label: 'Launch Date', value: 'launchDate', type: 'dateInput' },
    { label: "Location", value:"location", type: 'autoComplete'}
  ],
  requestedFilterOptions: [...commonFilterOptions],
  propsFiltersOptions: [
    { label: 'Pegasus ID', value: 'sample_id', type: 'listInput' },
    {
      label: 'Category',
      value: 'Props Category- Sub Category',
      type: 'autoComplete',
      displayName: 'Props Category- Sub Category',
    },
    {
      label: 'Sub Category',
      value: 'PropsSubCategory',
      type: 'autoComplete',
    },
    { label: 'Filemaker ID', value: 'prop_ids', type: 'listInput' },
    {
      label: 'Props Color',
      value: 'color_ids',
      type: 'autoComplete',
      displayName: 'Color Family',
    },
    { label: "Location", value:"location", type: 'autoComplete'},
    ...primaryFilterOptions,
  ],
  allBrands: [],
  allDepartments: [],
  deptAllClasses: [],
  filterOptionsByFilter: {},
  selectedFilters: {},
  editFlyoutOpen: false,
}

const getFormattedOptions = (
  optionsList = [],
  optionProps = {},
  isRegdisplayName = false
) => {
  const { label: optionPropsLabel = '', value: optionsPropsValue = '' } =
    optionProps
  // Removing null fileds
  const filteredOptionsList = optionsList.filter((obj) => obj)

  const formattedOptions = filteredOptionsList.map((obj) => {
    switch (optionPropsLabel) {
      case 'class_id': {
        return {
          label: `${obj['department_id']}-${obj[optionPropsLabel]}`,
          value: `${obj['department_id']}-${obj[optionPropsLabel]}`,
          displayName: obj[optionsPropsValue]
            ? `${obj[optionPropsLabel]} - ${obj[optionsPropsValue] || ''}`
            : `${obj[optionPropsLabel]}`,
          group_by: obj['department_name']
            ? `${obj['department_id']} - ${obj['department_name']}`
            : `${obj['department_id']}`,
          group_by_id: obj['department_id'],
          id: `${obj['department_id']}-${obj[optionPropsLabel]}`,
        }
      }
      case 'department_id': {
        return {
          label: isNaN(obj[optionPropsLabel])
            ? obj[optionPropsLabel] || obj
            : obj[optionPropsLabel],
          value: isNaN(obj[optionPropsLabel])
            ? obj[optionsPropsValue] || obj
            : obj[optionsPropsValue],
          displayName: obj[optionsPropsValue]
            ? `${obj[optionPropsLabel]} - ${obj[optionsPropsValue] || ''}`
            : `${obj[optionPropsLabel]}`,
          id: obj['org_department_id'],
        }
      }
      default: {
        return {
          label: isNaN(obj[optionPropsLabel])
            ? obj[optionPropsLabel] || obj
            : obj[optionPropsLabel],
          value: isNaN(obj[optionPropsLabel])
            ? obj[optionsPropsValue] || obj
            : obj[optionsPropsValue],
          displayName: `${obj[optionsPropsValue] || obj}`,
          id: isNaN(obj[optionPropsLabel])
            ? obj[optionsPropsValue] || obj
            : obj[optionsPropsValue],
        }
      }
    }
  })
  return formattedOptions
}

export default function AutoOrderFilters(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_AUTO_ORDER_FILTERS_PENDING: {
      return Object.assign({}, state, {
        fetchDataRequestPending: action.payload.pending,
      })
    }
    case types.GET_AUTO_ORDER_BRANDS_SUCCESS: {
      const { filterOptionsByFilter = {} } = state
      const {
        payload: { allBrands = [] },
      } = action
      const brandsList = getFormattedOptions(allBrands)
      return Object.assign({}, state, {
        ...state,
        allBrands: brandsList,
        filterOptionsByFilter: Object.assign({}, filterOptionsByFilter, {
          Brand: brandsList,
        }),
      })
    }
    case types.GET_AUTO_ORDER_DEPARTMENTS_SUCCESS: {
      const { filterOptionsByFilter = {} } = state
      const {
        payload: { allDepartments = [] },
      } = action
      const departmentsList = getFormattedOptions(
        allDepartments,
        {
          label: 'department_id',
          value: 'department_name',
        },
        true
      )
      return Object.assign({}, state, {
        ...state,
        allDepartments: departmentsList,
        filterOptionsByFilter: Object.assign({}, filterOptionsByFilter, {
          Department: departmentsList,
        }),
      })
    }
    case types.GET_AUTO_ORDER_CLASS_SUCCESS: {
      const { filterOptionsByFilter = {} } = state
      const {
        payload: { deptAllClasses = [] },
      } = action
      const deptClassesList = getFormattedOptions(
        deptAllClasses,
        {
          label: 'class_id',
          value: 'class_name',
        },
        true
      )
      return Object.assign({}, state, {
        ...state,
        deptAllClasses: deptClassesList,
        filterOptionsByFilter: Object.assign({}, filterOptionsByFilter, {
          Class: deptClassesList,
        }),
      })
    }
    case types.EDIT_FLYOUT: {
      return Object.assign({}, state, {
        editFlyoutOpen: action.payload,
      })
    }
    case types.SET_AUTO_ORDER_SELECTED_FILTERS: {
      const { payload: { data: payloadData = [] } = {} } = action
      return Object.assign(
        {},
        {
          ...state,
          selectedFilters: Object.assign({}, payloadData),
        }
      )
    }
    default:
      return state
  }
}
