import * as types from './actionType'
import * as orderAPI from '../../services/ordersService'
import * as inventoryApi from '../../services/inventoryService'
import * as itemAPI from '../../services/itemService'
import * as itemMapper from '../../mappers/itemMapper'
import * as orderMapper from '../../mappers/OrderMapper'
import { showNotification } from '../../store/notification/actionCreator'
import * as projectAPI from '../../services/projectsService'
import * as collectionAPI from '../../services/collectionsService'
import * as shootTypeAPI from '../../services/shootTypeService'
import * as shipmentApi from '../../services/shipmentService'
import * as projectMapper from '../../mappers/projectMapper'
import * as collectionMapper from '../../mappers/collectionMapper'
import * as sampleShootTypeMapper from '../../mappers/sampleShootTypeMapper'
import * as shipmentMapper from '../../mappers/shipmentMapper'
import { ShootTypes, ShootTypePagename } from '../../enums/shootTypes'
import { getDisplayImage, isHavingPrefix } from '../../util/CommonUtils'
import { ORDERED, REQUESTED, PROPS, SAMPLE } from 'enums/Tabs'
import { updateSuccess } from '../../store/projects/actionCreator'
import { paramsFromAutoOrderFiltersPost } from '../../pages/AddFilters/constants'

export function setCheckBox(selectedData) {
  return {
    type: types.SHOOT_TYPE_SET_CHECKBOX,
    payload: { selected: selectedData },
  }
}
export function openPickList() {
  return {
    type: types.OPEN_PICK_LIST,
  }
}
export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.SHOOT_TYPE_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}
export function setOverlayCheckBox(selectedData = {}) {
  return {
    type: types.SHOOT_TYPE_SET_OVERLAY_CHECKBOX,
    payload: { selected: selectedData },
  }
}
export function resetOverlayCheckBox() {
  return {
    type: types.SHOOT_TYPE_RESET_OVERLAY_CHECKBOX,
  }
}
export function setOverlayHeaderCheckBox(selectedData = []) {
  return {
    type: types.SHOOT_TYPE_SET_OVERLAY_HEADER_CHECKBOX,
    payload: { selected: selectedData },
  }
}

export function closeErrorPopUp() {
  return {
    type: types.CLOSE_UNAVAILABLE_SAMPLES_POPUP,
  }
}

// Validate arguments and remove invalid pegasus id's
export function validateArguments(args = [], sampleType = SAMPLE) {
  args = Array.from(new Set(args))
  const pegasusIds = []
  const propOrPegasusIds = []

  args.forEach((id) => {
    const isHavingPegasusPrefix = isHavingPrefix(id)

    let pegasusId = id
    if (isHavingPegasusPrefix) {
      pegasusId = id.slice(2, id.length)
    } else if (!isHavingPegasusPrefix && sampleType === PROPS) {
      propOrPegasusIds.push(id)
    }

    if (pegasusId.toString() !== '') {
      if (!isNaN(pegasusId)) {
        pegasusIds.push(pegasusId.toString())
      }
    }
  })
  return [pegasusIds, propOrPegasusIds]
}

export function createShootType(userId, shootType) {
  return (dispatch) => {
    dispatch(createShootTypePending(true))
    switch (shootType) {
      case ShootTypes.PROJECT:
        return projectAPI
          .createProject(projectMapper.ToProjectCreateRequest(userId))
          .then((res) => projectMapper.projectResponseToProject(res.data))
          .then((result) => {
            dispatch(createShootTypeSuccess(result, shootType))
          })
          .catch(() => {
            dispatch(createShootTypeFailure())
            dispatch(createShootTypePending(false))
          })
      case ShootTypes.COLLECTION:
        return collectionAPI
          .createCollection(collectionMapper.ToCollectionCreateRequest(userId))
          .then((res) =>
            collectionMapper.collectionResponseToCollection(res.data)
          )
          .then((result) => {
            dispatch(createShootTypeSuccess(result, shootType))
          })
          .catch(() => {
            dispatch(createShootTypeFailure())
            dispatch(createShootTypePending(false))
          })
      default:
        break
    }
  }
}

export function createShootTypePending(pending = false) {
  return {
    type: types.SHOOT_TYPE_CREATE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function createShootTypeFailure() {
  return {
    type: types.SHOOT_TYPE_CREATE_REQUEST_FAILURE,
  }
}

export function createShootTypeSuccess(result, shootType) {
  return {
    type: types.SHOOT_TYPE_CREATE_REQUEST_SUCCESS,
    payload: { data: result, shootType: shootType },
  }
}

export function updateShootType(
  shootTypeId,
  updateDetails,
  shootType,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(updateShootTypeRequestPending(true))
    switch (shootType) {
      case ShootTypes.PROJECT:
        return projectAPI
          .updateProject(
            shootTypeId,
            projectMapper.ToProjectUpdateRequest(updateDetails)
          )
          .then((res) => projectMapper.projectResponseToProject(res.data))
          .then((shootTypeResult) => {
            dispatch(updateShootTypeRequestSuccess(shootTypeResult))
            callback(ShootTypePagename.PROJECT, 'success', shootTypeResult.id)
          })
          .catch(() => {
            dispatch(updateShootTypeRequestFailure())
            dispatch(updateShootTypeRequestPending(false))
            dispatch(
              showNotification(true, 'We were unable to save this Project')
            )
          })
      case ShootTypes.COLLECTION:
        return collectionAPI
          .updateCollection(
            shootTypeId,
            collectionMapper.ToCollectionUdateRequest(updateDetails)
          )
          .then((shootTypeResult) => {
            dispatch(updateShootTypeRequestSuccess(shootTypeResult))
            callback(ShootTypePagename.COLLECTION, 'success')
          })
          .catch(() => {
            dispatch(updateShootTypeRequestFailure())
            dispatch(updateShootTypeRequestPending(false))
            dispatch(
              showNotification(true, 'We were unable to save this Collection')
            )
          })
      default:
        break
    }
  }
}

export function updateShootTypeRequestSuccess(shootTypeResult) {
  return {
    type: types.SHOOT_TYPE_UPDATE_REQUEST_SUCCESS,
    payload: { data: shootTypeResult },
  }
}

export function updateShootTypeRequestPending(pending = false) {
  return {
    type: types.SHOOT_TYPE_UPDATE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function updateShootTypeRequestFailure() {
  return {
    type: types.SHOOT_TYPE_UPDATE_REQUEST_FAILURE,
  }
}

export function deleteShootType(shootTypeId, shootType, callback = () => {}) {
  return (dispatch) => {
    dispatch(deleteShootTypeRequestPending(true))
    switch (shootType) {
      case ShootTypePagename.PROJECT:
        return projectAPI
          .deleteProject(shootTypeId)
          .then(() => {
            dispatch(deleteShootTypeRequestSuccess())
            callback(ShootTypePagename.PROJECT, 'success')
          })
          .catch(() => {
            dispatch(deleteShootTypeRequestFailure())
            dispatch(deleteShootTypeRequestPending(false))
            dispatch(showNotification(true, 'Unable to delete this project'))
          })
      case ShootTypePagename.COLLECTION:
        return collectionAPI
          .deleteCollection(shootTypeId)
          .then(() => {
            dispatch(deleteShootTypeRequestSuccess())
            callback(ShootTypePagename.COLLECTION, 'success')
          })
          .catch(() => {
            dispatch(deleteShootTypeRequestFailure())
            dispatch(deleteShootTypeRequestPending(false))
            dispatch(showNotification(true, 'Unable to delete this collection'))
          })
      default:
        break
    }
  }
}

export const deleteShootTypeRequestSuccess = () => {
  return {
    type: types.SHOOT_TYPE_DELETE_REQUEST_SUCCESS,
    payload: { data: true },
  }
}

export function deleteShootTypeRequestPending(pending = false) {
  return {
    type: types.SHOOT_TYPE_DELETE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function deleteShootTypeRequestFailure() {
  return {
    type: types.SHOOT_TYPE_DELETE_REQUEST_FAILURE,
  }
}

export const errorSamplesDispatch = (errorSamples = [], sampleType) => {
  return (dispatch) => {
    return itemAPI
      .getItemsGraphQL(errorSamples.map((it) => it.tcin))
      .then((itemResult) => itemMapper.itemsResponseToItems(itemResult))
      .then((items) => {
        let itemMap = new Map()
        items.map((item = {}) =>
          itemMap.set(item.tcin.toString(), {
            ...item,
            imageUrl:
              item.imageUrl !== undefined &&
              item.imageUrl !== null &&
              item.imageUrl !== ''
                ? getDisplayImage(item.imageUrl)
                : 'Image Not Found',
          })
        )
        const newerrorSamples =
          errorSamples.map((sample = {}) => {
            const { tcin: sampleTcin = '' } = sample
            let item = itemMap.get(sampleTcin.toString())
            let mergedObj = Object.assign({}, sample, item)
            return mergedObj
          }) || errorSamples
        dispatch(
          unavailableSamplesSuccess(
            orderMapper.addSamplesErrorRequestsNew(newerrorSamples, sampleType)
          )
        )
      })
  }
}

function getSampleDetails(
  sampleShootTypes = [],
  validatedPegasusIds = [],
  pegasusIds = [],
  errorSamples = [],
  sampleType
) {
  let mergeData = []
  return (dispatch) => {
    sampleShootTypes.length > 0 &&
      orderAPI
        .getSamples(sampleShootTypes.map((it) => it.pegasusId))
        .then((sampleResult) =>
          orderMapper.samplesResponseToSamples(sampleResult)
        )
        .then((samples = []) => {
          if (samples.length > 0) {
            itemAPI
              .getItemsGraphQL(samples.map((it = {}) => it.tcin))
              .then((itemResult = []) =>
                itemMapper.itemsResponseToItems(itemResult)
              )
              .then((items = []) => {
                let itemMap = new Map()
                let samplesNotFound = []
                items.map((item = {}) =>
                  itemMap.set(item.tcin.toString(), item)
                )
                samples.map((sample = {}) => {
                  const { tcin: sampleTcin = '' } = sample
                  let item = itemMap.get(sampleTcin.toString())
                  let mergedObj = Object.assign({}, sample, item)
                  return mergeData.push(mergedObj)
                })
                let sampleFound = []
                sampleFound = samples.map((it = {}) => {
                  const { pegasusId = '' } = it
                  return pegasusId.slice(2, pegasusId.length)
                })
                validatedPegasusIds.forEach((id = '') => {
                  if (
                    !errorSamples.some(
                      (obj) => obj.pegasusId === id.toString()
                    ) &&
                    sampleFound.indexOf(id.toString()) === -1 &&
                    id.toString() !== ''
                  ) {
                    samplesNotFound.push(orderMapper.sampleIdBuilder(id))
                  }
                })
                dispatch(
                  addSamplesRequestSuccess(mergeData, errorSamples.length > 0)
                )
              })
          } else {
            dispatch(
              addSamplesRequestSuccess(mergeData, errorSamples.length > 0)
            )
          }
          if (errorSamples.length > 0) {
            dispatch(errorSamplesDispatch(errorSamples, sampleType))
          }
        })
  }
}

export const getUnknownSamplesList = (
  validSamples = [],
  errorSampleIDs = [],
  validatedPegasusIds = [],
  sampleType = SAMPLE,
  propOrPegasusIds = []
) => {
  let sampleFound = []
  let samplesNotFound = []
  sampleFound = validSamples.map((it = {}) => {
    const { pegasusId = '', propId = '' } = it
    return `${propId?.toString()}${pegasusId.toString()}`
  })
  validatedPegasusIds.forEach((id = '') => {
    const isPresentInSampleFound = sampleFound.find(
      (combinedId) => combinedId.indexOf(id) !== -1
    )
    if (
      errorSampleIDs.indexOf(id.toString()) === -1 &&
      !isPresentInSampleFound &&
      id.toString() !== '' &&
      propOrPegasusIds.indexOf(id.toString()) === -1
    ) {
      samplesNotFound.push(id)
    }
  })

  const notFoundList = samplesNotFound.map((pgid = '') => {
    const isFilemakerId = sampleType === PROPS && pgid.toString().length < 7
    const withPrefix = isFilemakerId
      ? pgid
      : sampleType === PROPS
        ? 'PP' + pgid
        : pgid.toString().length === 7
          ? 'WH' + pgid
          : 'PG' + pgid
    return withPrefix
  })
  return notFoundList
}

const callForUnknownSamples = (
  validSamples = [],
  errorSampleIDs = [],
  validatedPegasusIds = [],
  sampleType = SAMPLE,
  propOrPegasusIds = []
) => {
  return (dispatch) => {
    let samplesNotFound = []
    samplesNotFound =
      getUnknownSamplesList(
        validSamples,
        errorSampleIDs,
        validatedPegasusIds,
        sampleType,
        propOrPegasusIds
      ) || []
    if (samplesNotFound.length > 0) {
      dispatch(setUnknownSamples(samplesNotFound))
    } else {
      dispatch(setUnknownSamples([]))
    }
  }
}

export function addSamples(
  pegasusIds,
  userId,
  shootTypeId,
  shootType,
  projectName,
  projectChannel,
  collectionName,
  checkFlag,
  sampleType = SAMPLE
) {
  const [validatedPegasusIds, propOrPegasusIds] = validateArguments(
    pegasusIds,
    sampleType
  )

  return (dispatch) => {
    // const currentState = getState()
    // const {
    //   newShootType: { unavailableSamples: stateUnavailableSamples = [] } = {},
    // } = currentState

    if (validatedPegasusIds.length > 0 || propOrPegasusIds.length > 0) {
      dispatch(fetchShootTypeDataPending(true))
      dispatch(addSamplesRequestPending(true))
      shootTypeAPI
        .addSamples(
          shootTypeId,
          shootType,
          validatedPegasusIds,
          projectName,
          projectChannel,
          collectionName,
          checkFlag,
          propOrPegasusIds
        )
        .then((sampleShootTypeResult) => {
          const { status: currentHttpStatus = '' } = sampleShootTypeResult
          let returnObj = {}
          if (currentHttpStatus === 207) {
            const { data: { result: response = {} } = {} } =
              sampleShootTypeResult
            const { errors: errorSamples = [] } = response
            const sampleShootTypes =
              sampleShootTypeMapper.sampleShootTypeResponseToSampleShootTypesNew(
                response
              )
            returnObj = {
              errorSamples: errorSamples,
              sampleShootTypes: sampleShootTypes,
            }
          } else {
            const sampleShootTypes =
              sampleShootTypeMapper.sampleShootTypeResponseToSampleShootTypes(
                sampleShootTypeResult
              )
            returnObj = {
              errorSamples: [],
              sampleShootTypes: sampleShootTypes,
            }
          }
          dispatch(addSamplesResponse(returnObj))
          return returnObj
        })
        .then(({ sampleShootTypes = [], errorSamples = [] }) => {
          if (sampleShootTypes.length > 0) {
            dispatch(
              getSampleDetails(
                sampleShootTypes,
                validatedPegasusIds,
                (pegasusIds = []),
                errorSamples,
                sampleType
              )
            )
            //
            // if(stateUnavailableSamples.length > 0) {

            // }
          } else if (errorSamples.length > 0) {
            dispatch(errorSamplesDispatch(errorSamples, sampleType))
          }
          const errorSampleIDs = errorSamples.map((obj) => {
            const { pegasusId = '' } = obj
            return pegasusId.toString()
          })
          dispatch(
            callForUnknownSamples(
              sampleShootTypes,
              errorSampleIDs,
              validatedPegasusIds,
              sampleType,
              propOrPegasusIds
            )
          )
          dispatch(resetOverlayCheckBox())
          dispatch(fetchShootTypeDataPending(false))
        })
        .catch((err = {}) => {
          const { response: { status: errorStatus = '' } = {} } = err
          dispatch(addSamplesRequestPending(false))
          dispatch(fetchShootTypeDataPending(false))
          if (errorStatus === 400) {
            let errorCodes = orderMapper.getErrorCodes(err)
            if (
              errorCodes != null &&
              'PROJECT_NAME_IS_REQUIRED' === errorCodes[0]
            ) {
              let errorMessages = orderMapper.getErrorMessages(err)
              dispatch(showNotification(true, errorMessages[0]))
            } else {
              dispatch(
                unavailableSamplesSuccess(
                  orderMapper.addSamplesErrorRequests(err, sampleType)
                )
              )
            }
          } else {
            dispatch(
              unavailableSamplesSuccess(
                orderMapper.addSamplesErrorRequests(err, sampleType)
              )
            )
          }
        })
    }
  }
}

export function addSamplesRequestSuccess(
  mergeData,
  isExistsErrorSamples = false
) {
  return {
    type: types.ADD_SHOOT_TYPE_SAMPLES_REQUEST_SUCCESS,
    payload: { data: mergeData, isExistsErrorSamples: isExistsErrorSamples },
  }
}

export function addSamplesRequestPending(pending = false) {
  return {
    type: types.ADD_SHOOT_TYPE_SAMPLES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function addSamplesRequestFailure() {
  return {
    type: types.ADD_SHOOT_TYPE_SAMPLES_REQUEST_FAILURE,
  }
}

export function unavailableSamplesSuccess(data = []) {
  return {
    type: types.UNAVAILABLE_SHOOT_TYPE_SAMPLES_SUCCESS,
    payload: { data: data },
  }
}

export function unavailableSamplesPending(pending = false) {
  return {
    type: types.UNAVAILABLE_SHOOT_TYPE_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export function unavailableSamplesFailure() {
  return {
    type: types.UNAVAILABLE_SHOOT_TYPE_SAMPLES_FAILURE,
  }
}

export function setUnknownSamples(data = []) {
  return {
    type: types.SHOOT_TYPE_SET_UNKNOWN_DATA,
    payload: { data: data },
  }
}
export function removeRequestedSamples(selectedData = [], callBack = () => {}) {
  const tcinShootTypeIDs = selectedData.map(
    (obj = {}) => obj.tcin_shoot_type_id
  )
  return (dispatch) => {
    dispatch(removeRequestedSamplePending(true))
    projectAPI
      .removeRequestedSamples(tcinShootTypeIDs)
      .then(() => {
        dispatch(removeRequestedSampleSuccess(tcinShootTypeIDs))
        callBack()
      })
      .catch(() => {
        dispatch(removeRequestedSampleFailure())
      })
      .finally(() => {
        dispatch(removeRequestedSamplePending(false))
      })
  }
}

export function removeRequestedSampleSuccess(tcinShootTypeIDs = []) {
  return {
    type: types.REMOVE_REQUESTED_SAMPLE_REQUEST_SUCCESS,
    payload: { data: tcinShootTypeIDs },
  }
}

export function removeRequestedSamplePending(pending = false) {
  return {
    type: types.REMOVE_REQUESTED_SAMPLE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function removeRequestedSampleFailure() {
  return {
    type: types.REMOVE_REQUESTED_SAMPLE_REQUEST_FAILURE,
  }
}

export function removeSamples(
  selectedData,
  shootType,
  shootTypeId,
  callBack = () => {}
) {
  return (dispatch) => {
    dispatch(removeSampleRequestPending(true))
    shootTypeAPI
      .removeSamples(selectedData, shootType, shootTypeId)
      .then(() => {
        dispatch(removeSampleRequestSuccess(selectedData))
        callBack()
        dispatch(
          showNotification(
            true,
            `Item(s) Removed From Project Successfully, If item(s) were present in picklist it would've been removed from there as well`,
            'success'
          )
        )
      })
      .catch(() => {
        dispatch(removeSampleRequestFailure())
      })
      .finally(() => {
        dispatch(removeSampleRequestPending(false))
      })
  }
}

export function removeSampleRequestSuccess(selectedData) {
  return {
    type: types.REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_SUCCESS,
    payload: { data: selectedData },
  }
}

export function removeSampleRequestPending(pending = false) {
  return {
    type: types.REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function removeSampleRequestFailure() {
  return {
    type: types.REMOVE_SHOOT_TYPE_SAMPLE_REQUEST_FAILURE,
  }
}

const getCurrentFiltersObj = (include_filters = {}) => {
  const currentFilters = {}
  if (Object.entries(include_filters).length > 0) {
    Object.keys(include_filters).map((obj) => {
      if (obj !== 'project_id') {
        currentFilters[obj] = (include_filters[obj] || []).join(',') || []
      }
      return true
    })
  }
  return currentFilters
}
/**
 * Action is dispatch from **GetAProject** action along with directly form components
 * @param {*} row
 * @param {*} shootType
 * @param {*} page
 * @param {*} rowsPerPage
 * @param {*} goToViewPage
 * @param {ORDERED/REQUESTED/PROPS} tabName
 * @returns
 */
export function getShootType(
  row,
  shootType,
  page,
  rowsPerPage,
  goToViewPage = true,
  tabName = ORDERED,
  filters = {},
  additionalFilters = {}
) {
  return (dispatch, getState = () => {}) => {
    dispatch(fetchShootTypeDataPending(true))
    switch (shootType) {
      case ShootTypes.PROJECT:
        {
          let apiCallBack = null
          const currentParams = paramsFromAutoOrderFiltersPost(
            filters,
            page,
            rowsPerPage,
            '',
            additionalFilters,
            '',
            '',
            '',
            ShootTypes.PROJECT
          )
          if (tabName === REQUESTED) {
            const {
              requestPayload: { include_filters = {} },
            } = currentParams
            const currentFilters = getCurrentFiltersObj(include_filters) || {}

            apiCallBack = projectAPI.getProjectRequesetSamples(
              row?.id,
              page,
              rowsPerPage,
              currentFilters
            )
          }
          if (tabName === ORDERED || tabName === PROPS) {
            const sampleType = tabName === ORDERED ? SAMPLE : tabName
            apiCallBack = inventoryApi.fetchInventorySamples(
              currentParams,
              sampleType
            )
          }
          apiCallBack &&
            apiCallBack
              .then((sampleResult) => {
                dispatch(updateSuccess(false))
                dispatch(setTotalRowsCount(sampleResult.data.total_count))
                if (tabName === ORDERED || tabName === PROPS) {
                  return orderMapper.samplesResponseToSamples(
                    sampleResult,
                    row?.id
                  )
                } else {
                  return orderMapper.samplesResponseToReqSamples(sampleResult)
                }
              })
              .then(async (samples) => {
                if (samples.length > 0) {
                  await shipmentApi
                    .fetchShipments(
                      samples
                        .map((it) => it.shipmentId)
                        .filter((shipmentId) => shipmentId)
                    )
                    .then((shipmentResult) =>
                      shipmentMapper.shipmentsResponseToShipments(
                        shipmentResult.data.shipments
                      )
                    )
                    .then(async (shipments) => {
                      await itemAPI
                        .getItemsGraphQL(samples.map((it) => it.tcin))
                        .then((itemResult) =>
                          itemMapper.itemsResponseToItems(itemResult)
                        )
                        .then(async (items) => {
                          let shipmentMap = new Map(
                            shipments.map((shipment) => [
                              shipment.shipmentId,
                              shipment,
                            ])
                          )
                          let itemMap = new Map(
                            items.map((item) => [item.tcin?.toString(), item])
                          )
                          let mergeData = []
                          samples.map((sample) => {
                            let item = itemMap.get(sample.tcin?.toString())
                            let shipment = shipmentMap.get(sample.shipmentId)
                            let mergedObj = Object.assign(
                              {},
                              sample,
                              shipment,
                              item
                            )
                            if (tabName === REQUESTED) {
                              mergeData =
                                orderMapper.requestedSampleResponseToRequestedSample(
                                  mergeData
                                )
                            }
                            return mergeData.push(mergedObj)
                          })
                          await dispatch(
                            fetchShootTypeDataSucess(row, mergeData, shootType)
                          )
                          if (goToViewPage) dispatch(moveToViewPage())
                        })
                    })
                } else {
                  dispatch(fetchShootTypeDataSucess(row, [], shootType))
                  if (goToViewPage) dispatch(moveToViewPage())
                }
              })
              .catch((error) => {
                console.log(error)
                dispatch(fetchShootTypeDataFailure())
              })
              .finally(() => {
                dispatch(fetchShootTypeDataPending(false))
              })
        }
        break
      case ShootTypes.COLLECTION:
        collectionAPI
          .getCollectionSamples(row.id, page, rowsPerPage)
          .then((sampleResult) => {
            dispatch(setTotalRowsCount(sampleResult.data.total_count))
            return orderMapper.samplesResponseToSamples(sampleResult)
          })
          .then((samples) => {
            if (samples.length > 0) {
              itemAPI
                .getItemsGraphQL(samples.map((it) => it.tcin))
                .then((itemResult) =>
                  itemMapper.itemsResponseToItems(itemResult)
                )
                .then((items) => {
                  let itemMap = new Map()
                  let mergeData = []
                  items.map((item) => itemMap.set(item.tcin.toString(), item))
                  samples.map((sample) => {
                    let item = itemMap.get(sample.tcin.toString())
                    let mergedObj = Object.assign({}, sample, item)
                    return mergeData.push(mergedObj)
                  })
                  dispatch(fetchShootTypeDataSucess(row, mergeData, shootType))
                  if (goToViewPage) dispatch(moveToViewPage())
                })
            } else {
              dispatch(fetchShootTypeDataSucess(row, [], shootType))
              if (goToViewPage) dispatch(moveToViewPage())
            }
          })
          .catch((error) => {
            console.log(error)
            dispatch(fetchShootTypeDataFailure())
          })
          .finally(() => {
            dispatch(fetchShootTypeDataPending(false))
          })
        break
      default:
        break
    }
  }
}

export function fetchShootTypeDataPending(pending = false) {
  return {
    type: types.SHOOT_TYPE_FETCH_DATA_PENDING,
    payload: { pending: pending },
  }
}

export function fetchShootTypeDataFailure() {
  return {
    type: types.SHOOT_TYPE_FETCH_DATA_FAILURE,
  }
}

export function fetchShootTypeDataSucess(
  shootTypesResult,
  mergeData,
  shootType
) {
  return {
    type: types.SHOOT_TYPE_FETCH_DATA_SUCCESS,
    payload: {
      shootTypesData: shootTypesResult,
      data: mergeData,
      shootType: shootType,
    },
  }
}

export function getProjectChannel() {
  return (dispatch) => {
    dispatch(getShootTypeChannelRequestPending(true))
    return projectAPI
      .getShootTypeChannel()
      .then((data) => {
        dispatch(getShootTypeChannelRequestSuccess(data))
      })
      .catch((error) => {
        dispatch(getShootTypeChannelRequestFailure())
        dispatch(getShootTypeChannelRequestPending(false))
        dispatch(showNotification(true, 'Unable to get channel'))
      })
  }
}

export function getProjectEditors() {
  return (dispatch) => {
    dispatch(getShootTypeChannelRequestPending(true))
    return projectAPI
      .getShootTypeEditors()
      .then((response) => {
        const { data: editors = {} } = response
        dispatch(getShootTypeEditorsSuccess(editors))
      })
      .catch((error) => {
        dispatch(getShootTypeEditorsFailure())
        dispatch(showNotification(true, 'Unable to get editors'))
      })
  }
}

export const getShootTypeChannelRequestSuccess = (data) => {
  return {
    type: types.SHOOT_TYPE_CHANNEL_REQUEST_SUCCESS,
    payload: { data: data },
  }
}

export function getShootTypeChannelRequestPending(pending = false) {
  return {
    type: types.SHOOT_TYPE_CHANNEL_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function getShootTypeChannelRequestFailure() {
  return {
    type: types.SHOOT_TYPE_CHANNEL_REQUEST_FAILURE,
  }
}

export const getShootTypeEditorsSuccess = (data) => {
  return {
    type: types.SHOOT_TYPE_GET_EDITORS_SUCCESS,
    payload: { data: data },
  }
}

export function getShootTypeEditorsFailure() {
  return {
    type: types.SHOOT_TYPE_GET_EDITORS_FAILURE,
  }
}

export function clearSearchData() {
  return {
    type: types.SHOOT_TYPE_CLEAR_DATA,
  }
}

export function showAlert(message) {
  return {
    type: types.SHOW_SHOOT_TYPE_PAGE_ALERT,
    payload: { message: message },
  }
}

export function closeAlert() {
  return {
    type: types.CLOSE_SHOOT_TYPE_PAGE_ALERT,
  }
}

export function showPopup(item) {
  const { imageUrl, sampleImageDPURL, sampleImageUrl, ...rest } = item
  return {
    type: types.SHOW_SHOOT_TYPE_POPUP,
    payload: { item: rest },
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_SHOOT_TYPE_POPUP,
  }
}

export function setPage(page = 1) {
  return {
    type: types.SHOOT_TYPE_SET_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage = 10) {
  return {
    type: types.SHOOT_TYPE_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function setTotalRowsCount(totalRowsCount = 0) {
  return {
    type: types.SHOOT_TYPE_SET_TOTAL_ROWS_COUNT,
    payload: { totalRowsCount: totalRowsCount },
  }
}

export function resetNewShootTypeState() {
  return {
    type: types.RESET_NEW_SHOOT_TYPE_STATE,
  }
}

export function backToLandingPage() {
  return {
    type: types.BACK_TO_LANDING_PAGE,
  }
}

export function moveToEditPage() {
  return {
    type: types.MOVE_TO_EDIT_PAGE,
  }
}

export function moveToViewPage() {
  return {
    type: types.MOVE_TO_VIEW_PAGE,
  }
}

export function clearTableData() {
  return {
    type: types.SHOOT_TYPE_CLEAR_TABLE_DATA,
  }
}

export function updateSampleFieldsData(data = {}) {
  return {
    type: types.SHOOT_TYPE_CALL_EDIT_FIELDS_API,
    payload: data,
  }
}

export function isRowsPerPageUpdated() {
  return {
    type: types.ROWS_PER_PAGE_UPDATED,
    payload: { isRowsPerPageUpdated: true },
  }
}

export function resetIsRowsUpdated() {
  return {
    type: types.RESET_ROWS_PER_PAGE_UPDATED,
    payload: { isRowsPerPageUpdated: false },
  }
}

export function addSamplesResponse(addSamplesResponse) {
  return {
    type: types.ADD_SAMPLES_RESPONSE,
    payload: { addSamplesResponse: addSamplesResponse },
  }
}

export function updateSampleQuantity(updatedQuantity, tcinShootTypeId) {
  return {
    type: types.UPDATE_REQUEST_SAMPLE_QUANTITY,
    payload: { updatedQuantity, tcinShootTypeId },
  }
}

export function cascadeSampleQuantity(cascadeQuantity, tcinShootTypeId) {
  return {
    type: types.CASCADE_REQUEST_SAMPLE_QUANTITY,
    payload: { cascadeQuantity, tcinShootTypeId },
  }
}

export function setTabvalue(tabValue = '') {
  return {
    type: types.SET_SHOOTTYPE_TABVALUE,
    payload: { tabValue: tabValue },
  }
}
