export const SAVE_FILTERS_PENDING = 'SAVE_FILTERS_PENDING'
export const SAVE_FILTERS_SUCCESS = 'SAVE_FILTERS_SUCCESS'
export const SAVE_FILTERS_FAILURE = 'SAVE_FILTERS_FAILURE'
export const SET_FILTERS = 'SET_FILTERS'
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS'
export const GET_FILTERS_PENDING = 'GET_FILTERS_PENDING'
export const GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE'
export const SET_FILTER_NOTFOUND_LIST = 'SET_FILTER_NOTFOUND_LIST'
export const SET_FILTERS_AUTO_ORDER = 'SET_FILTERS_AUTO_ORDER'
export const GET_AUTO_ORDER_FILTERS_FAILURE = 'GET_AUTO_ORDER_FILTERS_FAILURE'
export const GET_AUTO_ORDER_FILTERS_PENDING = 'GET_AUTO_ORDER_FILTERS_PENDING'
export const GET_AUTO_ORDER_FILTERS_SUCCESS = 'GET_AUTO_ORDER_FILTERS_SUCCESS'
export const SET_IS_SAVED_FILTERS = 'SET_IS_SAVED_FILTERS'
export const DELETE_FILTERS_SUCCESS = 'DELETE_FILTERS_SUCCESS'
export const DELETE_FILTERS_PENDING = 'DELETE_FILTERS_PENDING'
export const DELETE_FILTERS_FAILURE = 'DELETE_FILTERS_FAILURE'
export const SAVE_FILTERS_DATA = 'SAVE_FILTERS_DATA'
export const SET_SEARCH_INVENTORY_CURRENT_TAB =
  'SET_SEARCH_INVENTORY_CURRENT_TAB'
