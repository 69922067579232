export const DETAILED_LOCATIONS = [
  'Royalston - Samples & Props',
  'Royalston - TCIN Studio',
  'Target Plaza',
  'Target Plaza South (TPS)',
  'Target Plaza North (TPN)',
]

export const STEPPER_ACTIONS = Object.freeze({
  SCAN: 'Scan Samples',
  ASSIGN_BUILDING: 'Assign Building',
  ASSIGN_LOCATION: 'Assign Warehouse Location',
})
