import Grid from '@material-ui/core/Grid'
import SampleDetailCard from '../ExpandableCard/SampleDetailCard'
import * as PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import NodeContentModule from './NodeContentModule'
import CheckCircle from '@material-ui/icons/CheckCircle'

class CardSelection extends NodeContentModule {
  constructor(props) {
    super(props)

    this.state = {
      selected: null,
      value: null,
    }
  }

  onTransitionForward = (_, callback) => {
    const { onTransitionForward } = this.props
    onTransitionForward(this.state.value, callback)
  }

  handleChange = (selected, value) => {
    const { onChange } = this.props
    this.setState({
      selected: selected,
      value: value,
    })

    onChange(value)
  }

  render() {
    const { samples } = this.props
    const { selected } = this.state

    return (
      <Grid container spacing={4}>
        {samples[0].length === 0 && samples[1].length === 0 && (
          <Grid item xs={12}>
            <Typography variant={'h4'} id="sameOrder">
              No Matches Found
            </Typography>
          </Grid>
        )}
        {samples[0].length > 0 && (
          <Grid item xs={12}>
            <Typography variant={'h4'} id="sameOrder">
              Matching from This Order
            </Typography>
          </Grid>
        )}
        {samples[0].map((sample) => (
          <Grid item key={sample.pegasusId} id={'card_' + sample.pegasusId}>
            <SampleDetailCard
              sample={sample}
              titleAction={
                selected === sample.pegasusId ? <CheckCircle /> : null
              }
              action="Select"
              raised
              actionOnClick={() =>
                this.handleChange(sample.pegasusId, () =>
                  Promise.resolve(sample.pegasusId)
                )
              }
            />
          </Grid>
        ))}
        {samples[1].length > 0 && (
          <Grid item xs={12}>
            <Typography variant={'h4'} id="otherOrder">
              Matching from Other Orders
            </Typography>
          </Grid>
        )}
        {samples[1].map((sample) => (
          <Grid item key={sample.pegasusId} id={'card_' + sample.pegasusId}>
            <SampleDetailCard
              sample={sample}
              titleAction={
                selected === sample.pegasusId ? <CheckCircle /> : null
              }
              action="Select"
              raised
              actionOnClick={() => {
                this.handleChange(sample.pegasusId, () =>
                  Promise.resolve(sample.pegasusId)
                )
              }}
            />
          </Grid>
        ))}
      </Grid>
    )
  }
}

CardSelection.defaultProps = {
  transitionOnSelection: false,
  onTransitionForward: () => {},
}

CardSelection.propTypes = {
  paths: PropTypes.array,
  samples: PropTypes.array,
  onChange: PropTypes.func,
  onTransitionForward: PropTypes.func,
  setPath: PropTypes.func,
  transitionOnSelection: PropTypes.bool,
}

export default CardSelection
