/*
👋 “Hey” from the Praxis team
Praxis ships with support for modern browsers, but you may have users or external vendors that require IE11.
If that’s the case - uncomment the below imports.
Learn more about our the default browser support in the README.

import '@praxis/react-app-polyfill/ie11'
import '@praxis/react-app-polyfill/stable'
*/

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { onError } from '@praxis/component-logging'
import App from './App'
import * as serviceWorker from './serviceWorker'
import configureStore from './store/configureStore'

// Standard errors will be automatically logged to STDOUT when thrown.
// The logging sidecar in TAP will process and send logs to the appropriate environment in Kibana.
// Learn more about TAP sidecars: https://tapdocs.prod.target.com/runtime/sidecars/#_platform_sidecars
window.onerror = onError
const store = configureStore()
window.__PRELOADED_STATE__ = store.getState() || {}
const rootEl = ReactDOM.createRoot(document.getElementById('root'))

rootEl.render(
  <Provider store={store}>
    <App />
  </Provider>
)
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    rootEl.render(
      <Provider store={store}>
        <NextApp />
      </Provider>
    )
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

const consoleWarn = console.warn
const SUPPRESSED_WARNINGS = [
  'validateDOMNesting',
  'componentWillReceiveProps has been renamed',
  'Move data fetching code',
  'cannot appear as a descendant',
]

console.warn = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
    consoleWarn(msg, ...args)
  }
}
