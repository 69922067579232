import { startTransition } from 'react'
import { isEmpty } from 'lodash'
import { Grid } from '@mui/material'
import CustomAutoComplete from './CustomAutoComplete'
import { useShipSamplesContext } from './ShipSamplesContext'

const filterLabels = {
  locations: 'Ship To Location',
  users: 'Shipment Creator',
  partners: 'Partners',
}
export default function ShipmentFilters(props = {}) {
  const {
    filtersOptions = {},
    filterValues = {},
    setSelectedFilters = () => {},
  } = useShipSamplesContext()

  const handleUpdate = (selectedValue = '', filterName = '') => {
    const updFilterValues = Object.assign({}, filterValues, {
      [filterName]: selectedValue,
    })
    const updFilterValuesObj = Object.entries(updFilterValues).reduce(
      (a, [k, v]) => (isEmpty(v) ? a : ((a[k] = v), a)),
      {}
    )
    startTransition(() => {
      setSelectedFilters(updFilterValuesObj)
    })
  }

  return (
    <Grid container spacing={2} justifyContent={'start'} alignItems={'start'}>
      <Grid
        item
        container
        xs
        style={{
          flexWrap: 'nowrap',
          flex: 0.15,
          color: '#666',
          fontSize: '16px',
          fontWeight: 600,
        }}
      >
        <span>{'Filter By'}</span>
      </Grid>
      {Object.keys(filtersOptions)?.map((filterName = '') => {
        const optionsList = filtersOptions[filterName] ?? []
        return (
          <Grid item>
            <CustomAutoComplete
              handleUpdate={handleUpdate}
              optionsList={optionsList}
              selectedValue={filterValues[filterName] ?? []}
              label={filterLabels[filterName] ?? ''}
              id={filterLabels[filterName] ?? ''}
              name={filterName}
              disabled={!optionsList?.length}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
