export const locationLevels = {
  building: 'aisle',
  aisle: 'section',
  section: 'shelf',
  shelf: 'container',
}

export const locationsLevelLabels = {
  aisle: 'Level 1',
  section: 'Level 2',
  shelf: 'Level 3',
  container: 'Level 4',
}

export const defaultLocationsByLevels = {
  aisle: [],
  section: [],
  shelf: [],
  container: [],
}

export const levelsMappersObj = {
  studio: 'building',
  building: 'aisle',
  aisle: 'section',
  section: 'shelf',
  shelf: 'container',
}

export const levelsToParentMappersObj = {
  aisle: 'aisle',
  section: 'aisle',
  shelf: 'section',
  container: 'shelf',
}

export const defaultLocationLabelByLevels = {
  building: '',
  aisle: '',
  section: '',
  shelf: '',
  container: '',
}

export const defaultValueByLevels = {
  aisle: {},
  section: {},
  shelf: {},
  container: {},
}

export const createLocationDefaultObj = {
  building: null,
  aisle: null,
  section: null,
  shelf: null,
  container: null,
}

export const levels = ['aisle', 'section', 'shelf', 'container']

/**
 * @example
 * 1. for a aisle: aisle is not null and section, shelf, container will be null
 * 2: for a shelf: aisle, section, shelf all be not null and container will be null
 * etc
 * @param {*} location
 * @param {*} levelCheck
 * @returns
 */
export const isCorrectLevel = (location, levelCheck) => {
  const skipLevelCheckIndex = levels.indexOf(levelCheck)
  const skipLevelCheck = [...levels].slice(
    skipLevelCheckIndex + 1,
    levels.length
  )
  return skipLevelCheck.every((levelCheck) => location[levelCheck] === null)
}

export const EDIT_LOCATIONS_COMPONENT_TYPE = 'edit'
export const NEW_LOCATIONS_COMPONENT_TYPE = 'new'
export const SEARCH_LOCATIONS_COMPONENT_TYPE = 'search'
