import React from 'react'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid'
import MarketingList from '../Marketing/MarketingList'
import ShowIf from 'components/ShowIf'
import ShipmentPartners from './ShipmentPartners'
import { Divider } from '@material-ui/core'
import { Edit } from '@mui/icons-material'

export function ShipmentReadOnlyField({
  lable = '',
  value = '',
  fieldType = 'text',
}) {
  switch (fieldType) {
    case 'MarketingList':
      return <MarketingList pidData={value} isReadOnly />
    case 'PartnersList':
      return (
        <ShipmentPartners
          partners={value}
          showPartnerInputFields
          isReadOnly
          partnersList={<ShipmentPartners.PartnersList isReadOnly />}
        />
      )

    default:
      return (
        <>
          <div
            style={{
              fontSize: '12px',
              lineHeight: '14px',
              color: '#333333',
            }}
          >
            {lable}:
          </div>
          <div
            style={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '16px',
              color: '#333333',
              paddingTop: '10px',
            }}
          >
            {value}
          </div>
        </>
      )
  }
}

export function ShipmentReadOnlyList({
  fieldList = [],
  showReuseShipment,
  setEditShipment,
}) {
  return (
    <>
      <Grid container spacing={4}>
        {fieldList.map((field) => (
          <React.Fragment key={field.label}>
            <ShowIf condition={field.addDivider}>
              <Grid item xs={10}>
                <Divider />
              </Grid>
            </ShowIf>
            <Grid item xs={field.gridSize || 4} key={field.lable}>
              <ShipmentReadOnlyField
                lable={field.lable}
                value={field.value}
                fieldType={field.fieldType}
                isReadOnly={showReuseShipment}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <ShowIf condition={!showReuseShipment}>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="primary"
            disabled={showReuseShipment}
            onClick={setEditShipment}
            startIcon={<Edit />}
          >
            EDIT SHIPMENT INFO
          </Button>
        </Grid>
      </ShowIf>
    </>
  )
}

export default ShipmentReadOnlyList
