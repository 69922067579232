import * as types from './actionType'
import { findIndex, isEmpty } from 'lodash'
import { shipToOnChange } from '../../util/CommonUtils'

const getFinalObj = (stateData = [], payloadTcin = '', payloadValue = '') => {
  const finalObj = stateData.map((i) => ({
    ...i,
    dueDate:
      i.tcin === payloadTcin
        ? shipToOnChange(payloadValue, i.tcin, i.dueDate, i.launchDate)
        : i.dueDate,
  }))
  return finalObj
}

const getFinalObjAll = (stateData = [], payloadValue = '') => {
  const finalObj = stateData.map((i) => ({
    ...i,
    dueDate: shipToOnChange(payloadValue, i.tcin, i.dueDate, i.launchDate),
  }))
  return finalObj
}

export const initialState = (window.Cypress && window.initialState) || {
  businessPartners: new Map(),
  businessPartnerIds: new Map(),
  checkBoxEnabled: true,
  columnDataSelected: {
    vendorEmail: [],
  },
  selectedDataColumn: {
    vendorEmail: [],
  },
  columnData: [
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'subClassId',
      numeric: false,
      disablePadding: false,
      label: 'Sub Class ID',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Item Description',
    },
    { id: 'size', numeric: true, disablePadding: true, label: 'Size' },
    { id: 'color', numeric: false, disablePadding: false, label: 'Color' },
    {
      id: 'launchDate',
      numeric: false,
      disablePadding: true,
      label: 'Launch Date',
    },
    {
      id: 'vendorId',
      numeric: false,
      disablePadding: false,
      label: 'Vendor ID',
    },
    {
      id: 'vendorEmail',
      numeric: false,
      disablePadding: false,
      label: 'Input Emails',
      textRestriction: true,
    },
    {
      id: 'vendorBPIdsDisplay',
      numeric: false,
      disablePadding: false,
      label: 'Partners IDs',
      textRestriction: false,
    },
    {
      id: 'quantity',
      numeric: true,
      disablePadding: false,
      label: 'Quantity',
    },
    {
      id: 'shipTo',
      numeric: false,
      disablePadding: false,
      label: 'Ship To',
      textRestriction: true,
    },
    {
      id: 'dueDate',
      numeric: false,
      disablePadding: false,
      label: 'Due Date',
    },
    {
      id: 'project',
      numeric: false,
      disablePadding: false,
      label: 'Projects/Collections',
    },
    {
      id: 'vendorNotes',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Notes',
      textRestriction: true,
    },
    {
      id: 'studioNotes',
      numeric: false,
      disablePadding: false,
      label: 'Studio Notes',
      textRestriction: true,
    },
  ],
  data: [],
  projectNames: [],
  fetchBusinessPartnersError: '',
  fetchBusinessPartnersRequestFailure: false,
  fetchBusinessPartnersRequestPending: false,
  fetchBusinessPartnersIdsPending: false,
  fetchBusinessPartnersRequestSuccess: false,
  fetchProjectsFailure: false,
  fetchProjectsPending: false,
  onClick: () => {},
  submitOrderFailure: false,
  submitOrderPending: false,
  submitOrderResponse: [],
  submitOrderSuccess: false,
  getCollectionsPending: false,
  getCollectionsFailure: false,
  tabValue: '',
  orderSamplesFrom: '',
  selectedData: [],
  numSelected: 0,
}

const isValidForCascade = (column) => {
  return !isEmpty(column)
}

export default function order(state = initialState, action) {
  switch (action.type) {
    case types.ORDER_PAGE_SET_SAMPLES:
      return Object.assign({}, state, {
        data: action.payload.samples,
      })
    case types.FETCH_BUSINESS_PARTNER_FAILURE:
      return Object.assign({}, state, {
        fetchBusinessPartnersRequestFailure: true,
        fetchBusinessPartnersError: action.payload.error,
        businessPartners: new Map(),
        businessPartnerIds: new Map(),
      })
    case types.FETCH_BUSINESS_PARTNER_SUCCESS: {
      return Object.assign({}, state, {
        fetchBusinessPartnersRequestSuccess: true,
        businessPartners: action.payload?.businessPartners?.vmmemails,
        businessPartnerIds: action.payload?.businessPartners?.bpids,
      })
    }
    case types.FETCH_BUSINESS_PARTNER_RESET: {
      return Object.assign({}, state, {
        fetchBusinessPartnersRequestSuccess: false,
        businessPartners: new Map(),
        businessPartnerIds: new Map(),
      })
    }
    case types.FETCH_BUSINESS_PARTNER_REQUEST_PENDING:
      return Object.assign({}, state, {
        fetchBusinessPartnersRequestPending: action.payload.pending,
      })
    case types.FETCH_BUSINESS_PARTNER_ID_PENDING:
      return Object.assign({}, state, {
        fetchBusinessPartnersIdsPending: action.payload.pending,
      })
    case types.ORDER_PAGE_SET_FIELD_VALUE: {
      const { data: stateData = [] } = state
      const {
        payload: {
          field: payloadField = '',
          tcin: payloadTcin = '',
          value: payloadValue = '',
          isOrderfromProject = false,
        },
      } = action
      const updateObj =
        payloadField === 'shipTo' && !isOrderfromProject
          ? getFinalObj(stateData, payloadTcin, payloadValue)
          : stateData
      return Object.assign({}, state, {
        data: updateObj.map((i) => ({
          ...i,
          [payloadField]:
            i.tcin === payloadTcin ? payloadValue : i[payloadField],
        })),
      })
    }
    case types.ORDER_PAGE_SET_CASCADE: {
      const { data: stateData = [] } = state
      const {
        payload: { isOrderfromProject = false },
      } = action
      const updateObj =
        action.payload.event.column === 'shipTo' && !isOrderfromProject
          ? getFinalObjAll(stateData, action.payload.event.value)
          : stateData
      return Object.assign({}, state, {
        data: updateObj.map((i) => ({
          ...i,
          [action.payload.event.column]: isValidForCascade(
            action.payload.event.column
          )
            ? action.payload.event.value
            : i[action.payload.event.column],
        })),
      })
    }

    case types.ORDER_PAGE_SET_BULK_VALUES: {
      const { data: stateData = [] } = state
      const { payload: { editObj = {}, selectedData = [] } = {} } = action
      const { shipTo = '' } = editObj
      const tcinList = selectedData.map((obj) => obj.tcin)

      const newStateData = stateData.map((stateObj) => {
        if (tcinList.indexOf(stateObj.tcin) !== -1) {
          return Object.assign({}, stateObj, editObj)
        }
        return stateObj
      })
      const updateObj =
        Object.keys(editObj)?.indexOf('shipTo') !== -1 &&
        Object.keys(editObj)?.indexOf('dueDate') === -1
          ? getFinalObjAll(newStateData, shipTo)
          : newStateData
      return Object.assign({}, state, {
        data: updateObj,
      })
    }
    case types.ORDER_PAGE_SUBMIT_ORDER_SUCCESS: {
      return Object.assign({}, state, {
        submitOrderSuccess: true,
        data: action.payload.samples,
      })
    }
    case types.ORDER_PAGE_SUBMIT_ORDER_PENDING: {
      return Object.assign({}, state, {
        submitOrderPending: action.payload.pending,
      })
    }
    case types.ORDER_PAGE_SUBMIT_ORDER_FAILURE: {
      return Object.assign({}, state, {
        submitOrderResponse: [],
        submitOrderFailure: true,
      })
    }
    case types.FETCH_PROJECTS_SUCCESS: {
      var date = new Date()
      let filteredProject = action.payload.data.filter(
        (project) => Date.parse(project.shootEndDate) >= Date.parse(date)
      )
      let projectsName = filteredProject.map(function (obj) {
        return {
          projectId: obj.id,
          projectName: obj.name,
          autoOrderEnabled: obj.auto_order_enabled,
        }
      })
      return Object.assign({}, state, {
        projectNames: projectsName,
      })
    }
    case types.FETCH_PROJECTS_PENDING:
      return Object.assign({}, state, {
        fetchProjectsPending: action.payload.pending,
      })
    case types.FETCH_PROJECTS_FAILURE:
      return Object.assign({}, state, {
        fetchProjectsFailure: true,
      })
    case types.RESET_ORDER_STATE: {
      return Object.assign({}, initialState)
    }
    case types.SET_ORDERPAGE_TABVALUE: {
      return Object.assign({}, state, {
        ...state,
        tabValue: action.payload.tabValue,
      })
    }
    case types.SET_ORDERSAMPLES_FROM: {
      return Object.assign({}, state, {
        ...state,
        orderSamplesFrom: action.payload.orderSamplesFrom,
      })
    }
    case types.REVIEW_ORDER_SET_CHECKBOX: {
      const { selectedData = {} } = state
      const {
        payload: { selected = {} },
      } = action
      const { tcin: selectedTcin = '' } = selected
      const selectedIndex = findIndex(selectedData, function (obj = {}) {
        return obj.tcin === selectedTcin
      })

      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          state.selectedData,
          action.payload.selected
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selectedData.slice(1))
      } else if (selectedIndex === state.selectedData.length - 1) {
        newSelected = newSelected.concat(state.selectedData.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selectedData.slice(0, selectedIndex),
          state.selectedData.slice(selectedIndex + 1)
        )
      }

      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.REVIEW_ORDER_SET_CHECKBOX_HEADER: {
      const { selectedData = [] } = state
      const {
        payload: { selected: selectedIds = {} },
      } = action
      const selectedDiffCount = selectedData.filter(
        (i) =>
          findIndex(selectedIds, function (obj) {
            return obj.tcin === i.tcin
          }) !== -1
      ).length

      var newDataObj = [].concat.apply(selectedData, selectedIds)

      let newSelected = []
      if (selectedDiffCount === 0) {
        newSelected = newDataObj
      } else {
        newSelected = selectedData.filter(
          (i) =>
            findIndex(selectedIds, function (obj) {
              return obj.tcin === i.tcin
            }) === -1
        )
      }

      return Object.assign({}, state, {
        ...state,
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.REVIEW_ORDER_EMPTY_SELECTED_DATA: {
      return Object.assign({}, state, {
        ...state,
        selectedData: [],
        numSelected: Number(0),
      })
    }
    case types.UPDATE_SELECTED_COLUMN_INFO: {
      const updatedColumnDataSelected = { ...action.payload.data }
      return Object.assign({}, state, {
        ...state,
        columnDataSelected: updatedColumnDataSelected,
      })
    }
    case types.SET_MOUSE_LOCATIOIN: {
      return Object.assign({}, state, {
        ...state,
        mouseLocationX: action.payload.xLocation,
        mouseLocationY: action.payload.yLocation,
      })
    }
    case types.UPDATE_SELECTED_COLUMN_DATA: {
      const updatedColumnDataSelected = { ...action.payload.data }
      return Object.assign({}, state, {
        ...state,
        iconClick: action.payload.iconClick,
        keyName: action.payload.keyName,
        selectedDataColumn: updatedColumnDataSelected,
      })
    }
    default:
      return state
  }
}
