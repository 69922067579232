import * as types from '../searchArchivedSamples/actionType'
import * as inventoryApi from '../../services/inventoryArchiveService'
import * as reportsAPi from '../../services/externalReportsService'
import {
  samplesResponseToArchSamples,
  locationResponseToLocationId,
} from '../../mappers/inventoryMapper'
import * as itemApi from '../../services/itemService'
import * as shipmentApi from '../../services/shipmentService'
import * as shootShipmentApi from '../../services/shootShipmentService'
import * as barcodeApi from '../../services/barcodeService'
import * as shipmentMapper from '../../mappers/shipmentMapper'
import * as itemMapper from '../../mappers/itemMapper'
import * as userAPI from '../../services/userService'
import * as ordersApi from '../../services/ordersService'
import * as orderMapper from '../../mappers/OrderMapper'
import * as barcodeMapper from '../../mappers/barcodeMapper'
import { fetchFilteredLocations } from '../../services/locationService'
import { showNotification } from '../notification/actionCreator'
import { download } from '../../util/DownloadCsv'
import {
  editableFieldsMapper,
  getKeyByValue,
  DATE_FORMAT,
  convertTableDateFormat,
} from '../../util/CommonUtils'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { renameKeys } from '../../pages/AddFilters/constants'

export const updateSampleFields =
  (payload, callback = () => {}) =>
  async (dispatch, getState) => {
    const { sample_id = '' } = payload
    const promise = inventoryApi.updateSampleFields(payload)
    promise
      .then((response = {}) => {
        dispatch(updateSampleFieldsSuccess(response, sample_id, callback))
      })
      .catch((error) => {
        dispatch(
          showNotification(true, 'We encountered an error while updating')
        )
      })
  }

const updateSampleFieldsSuccess = (
  response,
  sample_id,
  callback = () => {}
) => {
  const { data: responseData = {} } = response
  const editableFieldsMapperObj = editableFieldsMapper()
  const finalData = {}
  Object.keys(responseData?.sample_data).map((obj) => {
    const objKey = getKeyByValue(editableFieldsMapperObj, obj)
    if (!isEmpty(objKey)) {
      finalData[objKey] = responseData?.sample_data[obj]
    }
    return obj
  })
  return (dispatch) => {
    dispatch(callback({ data: finalData, sample_id }))
  }
}

export function updateSampleFieldsData(data = {}) {
  return {
    type: types.CALL_EDIT_FIELDS_API,
    payload: data,
  }
}

export function clearSearchData() {
  return {
    type: types.SEARCH_ARCHIVED_CLEAR_DATA,
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_SEARCH_ARCHIVED_PAGE_POPUP,
  }
}
export function updateHistoryData(data = []) {
  return {
    type: types.CALL_HISTORY_API,
    payload: data,
  }
}
export function historyDataFailure() {
  return {
    type: types.CALL_HISTORY_API_FAILED,
  }
}
export function updateHistoryFilters(data = []) {
  return {
    type: types.CALL_HISTORY_FILTERS_API,
    payload: data,
  }
}
export function historyFiltersFailure() {
  return {
    type: types.CALL_HISTORY_FILTERS_API_FAILED,
  }
}

export const getLastShipDate =
  (sampleData = {}, callback = () => {}) =>
  async (dispatch, getState) => {
    const { sampleId = '' } = sampleData
    let shootShipmentMap = new Map()
    shootShipmentApi
      .fetchShootShipmentByPegId(sampleId)
      .then((shipments = {}) => {
        const { data: responseData = [] } = shipments
        let lastShipDate = ''
        if (!isEmpty(responseData)) {
          responseData.find(({ shootShipment }) => {
            const { shipDate = '' } = shootShipment
            if (!isEmpty(shipDate)) {
              lastShipDate = shipDate
              return true
            }
            return null
          })
        }

        shootShipmentMap.set(
          sampleId,
          !isEmpty(lastShipDate) ? moment(lastShipDate).format('L') : ''
        )
      })
      .catch(() => shootShipmentMap.set(sampleId, []))
      .finally(() => {
        const finalSampleData = Object.assign({}, sampleData, {
          lastShipDate: convertTableDateFormat(
            shootShipmentMap.get(sampleId),
            DATE_FORMAT
          ),
        })
        callback(finalSampleData)
      })
  }

export const setColumnSettings =
  (payload = [], lanId = '', callBack = () => {}) =>
  async (dispatch, getState) => {
    dispatch(setColumnSettingsPending(true))
    const promise = inventoryApi.saveColumnSettings(payload, lanId)
    promise
      .then((data = {}) => {
        dispatch(setColumnSettingsPending(false))
        dispatch(
          showNotification(true, 'Columns saved successfully', 'success')
        )
        callBack()
      })
      .catch((error) => {
        dispatch(setColumnSettingsPending(false))
        dispatch(
          showNotification(
            true,
            'We encountered an error while saving the columns'
          )
        )
      })
  }

export const getColumnSettings = () => async (dispatch, getState) => {
  const promise = inventoryApi.getColumnSettings()
  promise
    .then((response = {}) => {
      const { data: responseData = {} } = response
      const { fields: fetchedColumns = [] } = responseData
      dispatch(updateColumnSettings(fetchedColumns))
    })
    .catch((error) => {
      dispatch(updateColumnSettings([]))
    })
}

export function updateColumnSettings(data = {}) {
  return {
    type: types.CALL_SEARCH_FIELDS,
    payload: { searchedColumns: data },
  }
}

export function resetIsCallSearchFields() {
  return {
    type: types.RESET_IS_CALL_SEARCH_FIELDS,
  }
}

export const getHistoryData =
  (payload = {}) =>
  async (dispatch, getState) => {
    const promise = inventoryApi.fetchHistoryData(payload)
    promise
      .then((data = {}) => {
        dispatch(updateHistoryData(data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(historyDataFailure())
      })
  }

export function updateSamples(
  updateData = [],
  userId,
  params,
  successCallBack = () => {}
) {
  return (dispatch) => {
    dispatch(updateSamplesRequestPending(true))
    ordersApi
      .updateOrder(orderMapper.samplesToSearchUpdateRequest(updateData))
      .then((res) => {
        successCallBack()
        dispatch(updateSamplesRequestPending(false))
        dispatch(
          showNotification(true, 'Samples saved successfully', 'success')
        )
      })
      .catch(() => {
        dispatch(updateSamplesRequestFailure())
        dispatch(updateSamplesRequestPending(false))
        dispatch(
          showNotification(
            true,
            'We encountered an error while updating the samples'
          )
        )
      })
      .finally(() => {
        dispatch(updateSamplesRequestPending(false))
      })
  }
}

export function updateSamplesRequestFailure(failure = false) {
  return {
    type: types.SEARCH_ARCHIVED_UPDATE_SAMPLES_REQUEST_FAILURE,
  }
}

export function updateSamplesRequestPending(pending = false) {
  return {
    type: types.SEARCH_ARCHIVED_SAMPLES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export const getHistoryFilters =
  (payload = {}) =>
  async (dispatch, getState) => {
    const promise = inventoryApi.fetchHistoryFilters(payload)
    promise
      .then((data = {}) => {
        dispatch(updateHistoryFilters(data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(historyFiltersFailure())
      })
  }

/**
 * Items api related actions. Use the exported wrapper function to abstract
 * away usage of redux for cases where we are dealing with an API lifecycle
 */
export function getData(
  params,
  successCallback = () => {},
  failCallback = () => {}
) {
  return (dispatch, getState = () => {}) => {
    const currentState = getState() || {}
    const {
      searchInventory: { totalRowsCount: currentTotalRows = Number(0) } = {},
    } = currentState
    const { requestParams = {} } = renameKeys(params) || params
    const currentPage = requestParams.get('page')
    if (currentPage > 1 && Number(currentTotalRows) > Number(100)) {
      params?.requestParams?.append('total', currentTotalRows)
    }
    let totalRowsCount
    let filterNFList = {}
    dispatch(fetchDataRequestPending(true))
    inventoryApi
      .fetchInventorySamples(params)
      .then((orderResult) => {
        const {
          data: { total_count = 0, filter_list_notfound = {} },
        } = orderResult
        totalRowsCount = total_count
        filterNFList = Object.assign({}, filter_list_notfound)
        return samplesResponseToArchSamples(orderResult)
      })
      .then((samples = []) => {
        shipmentApi
          .fetchShipments(
            samples
              .map((it) => it.shipmentId)
              .filter((shipmentId) => shipmentId)
          )
          .then((shipmentResult) =>
            shipmentMapper.shipmentsResponseToShipments(
              shipmentResult.data.shipments
            )
          )
          .then((shipments) => {
            const listOfTcins = samples.map((it) => it.tcin)
            const finalListOfTcins = Array.from(new Set([...listOfTcins]))
            itemApi
              .getItemsGraphQL(finalListOfTcins)
              .then((itemResults) =>
                itemMapper.itemsResponseToItems(itemResults)
              )
              .then((items) => {
                let shipmentMap = new Map(
                  shipments.map((shipment) => [shipment.shipmentId, shipment])
                )
                let itemMap = new Map(
                  items.map((item) => [item.tcin.toString(), item])
                )
                let orderResults = [].concat.apply(
                  samples.map((order) =>
                    Object.assign(
                      {},
                      order,
                      shipmentMap.get(order.shipmentId),
                      itemMap.get(order.tcin.toString())
                    )
                  )
                )
                //const dataComparator = EnhancedTableSortComparator( 'asc', 'dueDate' )
                //let sortedOrderResults = orderResults.sort(dataComparator)
                dispatch(fetchDataSuccess(orderResults))
                dispatch(setTotalRowsCount(totalRowsCount))
                dispatch(setFilterNFList(filterNFList))
                successCallback()
              })
          })
      })
      .catch((error) => {
        console.log(error)
        dispatch(fetchDataFailure())
        dispatch(
          showNotification(
            true,
            'We encountered an error while applying your last filter archieve'
          )
        )
        failCallback()
      })
      .finally(() => {
        dispatch(fetchDataRequestPending(false))
      })
  }
}

export function fetchLocationDetails(params, successCallback = () => {}) {
  return (dispatch) => {
    return fetchFilteredLocations(params)
      .then((filteredLocations = {}) => {
        const {
          data: { locations: dataLocations = [] },
        } = filteredLocations
        return dataLocations.map((locations) => ({
          value: locations.location_id || '',
          label: locationResponseToLocationId(locations),
        }))
      })
      .then((locations = []) => {
        dispatch(fetchLocationSuccess(locations))
        successCallback()
      })
      .catch((error) => {})
  }
}

export function fetchLocationSuccess(location = []) {
  return {
    type: types.FETCH_LOCATION_SUCCESS,
    payload: { location: location },
  }
}

export function triggerUpdateHistoryData(item) {
  let itemCopy = Object.assign({}, item)
  const { histories = [] } = itemCopy

  if (histories.length) {
    const totalCount = (histories || []).length
    const pageSize = 10
    const newObj = Object.assign(
      {},
      {
        data: {
          items: histories || [],
          page: Number(1),
          total_count: totalCount,
          pages_count:
            totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize),
          per_page: 10,
        },
      }
    )
    return (dispatch) => {
      dispatch(updateHistoryData(newObj))
    }
  }
}

export function showPopup(item) {
  let itemCopy = Object.assign({}, item)
  if (itemCopy.userId) {
    return (dispatch) => {
      userAPI
        .getUser(itemCopy.userId)
        .then((user) => {
          itemCopy.userId = user.data.first_name + ' ' + user.data.last_name
          dispatch(displayPopup(itemCopy))
        })
        .catch(() => {
          dispatch(displayPopup(itemCopy))
        })
    }
  } else {
    return (dispatch) => {
      dispatch(displayPopup(itemCopy))
    }
  }
}

export function downloadSamples(params) {
  return (dispatch) => {
    inventoryApi
      .downloadInventorySamplesPost(params)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function downloadContactSheet(params = {}, downloadType = '') {
  return (dispatch) => {
    inventoryApi
      .downloadContactSheetPost(params, downloadType)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function downloadLaunchDateReport() {
  return (dispatch) => {
    reportsAPi
      .downloadLaunchDateReport()
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'Encountered an error while trying to download launch date report'
          )
        )
      })
  }
}

export function fetchDataFailure() {
  return {
    type: types.FETCH_ARCHIVED_DATA_FAILURE,
    payload: { fetchDataError: 'Failed to fetch data' },
  }
}

export function fetchDataRequestPending(pending = false) {
  return {
    type: types.FETCH_ARCHIVED_DATA_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function setColumnSettingsPending(pending = false) {
  return {
    type: types.SET_COLUMN_SETTINGS_PENDING,
    payload: { pending: pending },
  }
}

export function fetchDataSuccess(data = []) {
  return {
    type: types.FETCH_ARCHIVED_DATA_SUCCESS,
    payload: { data: [...data] },
  }
}

export function setCheckBox(selected) {
  return {
    type: types.SEARCH_ARCHIVED_SET_CHECKBOX,
    payload: { selected: selected },
  }
}

export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.SEARCH_ARCHIVED_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}

export function setInvalidData(data = []) {
  return {
    type: types.SEARCH_ARCHIVED_SET_INVALID_DATA,
    payload: { data: data },
  }
}

export function displayPopup(item) {
  return {
    type: types.SHOW_SEARCH_ARCHIVED_PAGE_POPUP,
    payload: { item: item },
  }
}

export function setFilters(filters) {
  return {
    type: types.SEARCH_ARCHIVED_SET_FILTERS,
    payload: { data: filters },
  }
}
export function setPage(page = 1) {
  return {
    type: types.SEARCH_ARCHIVED_SET_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage = 25) {
  return {
    type: types.SEARCH_ARCHIVED_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function setTotalRowsCount(totalRowsCount = 99) {
  return {
    type: types.SEARCH_ARCHIVED_SET_TOTAL_ROWS_COUNT,
    payload: { totalRowsCount: totalRowsCount },
  }
}

export function setFilterNFList(filterNFList = {}) {
  return {
    type: types.SEARCH_ARCHIVED_SET_FILTER_NOTFOUND_LIST,
    payload: { filterNFList: filterNFList },
  }
}

export function duplicateSamplesAS(samples = [], userId = '') {
  const itemIdList = samples.map((obj) => obj.itemId) || []
  return (dispatch) => {
    dispatch(duplicateSamplesPending(true))
    return itemApi
      .getItemsGraphQL(itemIdList)
      .then((itemResponse) => itemMapper.itemsResponseToItems(itemResponse))
      .then((items) => {
        let itemMap = new Map(items.map((item) => [item.tcin.toString(), item]))
        return userAPI.getUser(userId).then((userInfo) => {
          let finalRequest = samples.map((obj) =>
            Object.assign({}, obj, {
              location: userInfo.data.default_location,
              shipTo: userInfo.data.default_location,
            })
          )
          return ordersApi
            .duplicateSamplesAS({
              samples: orderMapper.samplesToSampleCreateRequests(finalRequest),
            })
            .then((res) => orderMapper.samplesResponseToSamples(res))
            .then((sampleResponse) => {
              return sampleResponse.map((s) =>
                Object.assign({}, s, itemMap.get(s.tcin.toString()), {
                  ...s,
                  generalDescription:
                    items.find((sam) => sam.tcin === s.tcin.toString())
                      .description || '',
                })
              )
            })
            .then((result) => {
              dispatch(duplicateSamplesPending(false))
              dispatch(duplicateSamplesSuccess(result))
              return result
            })
            .catch((error) => {
              try {
                if (error.response.data.errors[0].field === 'brandId') {
                  dispatch(
                    showNotification(
                      true,
                      "Couldn't duplicate this sample because it has no primary brand"
                    )
                  )
                } else if (error.response.data.errors[0].field === 'vendorId') {
                  dispatch(
                    showNotification(
                      true,
                      "Couldn't duplicate this sample because it has no primary vendor"
                    )
                  )
                }
              } catch (e) {
                dispatch(
                  showNotification(true, "Couldn't duplicate this sample")
                )
              }
              dispatch(duplicateSamplesFailure())
              dispatch(duplicateSamplesPending(false))
            })
        })
      })
  }
}

export function duplicateSamples(sample = {}) {
  return (dispatch) => {
    dispatch(duplicateSamplesPending(true))
    return itemApi
      .getItemsGraphQL([sample.itemId])
      .then((itemResponse) => itemMapper.itemsResponseToItems(itemResponse))
      .then((items) => {
        let itemMap = new Map(items.map((item) => [item.tcin.toString(), item]))
        let orderResults = Object.assign(
          {},
          sample,
          itemMap.values().next().value
        )
        return userAPI.getUser(sample.userId).then((userInfo) => {
          let finalRequest = Object.assign({}, orderResults, {
            ...orderResults,
            location: userInfo.data.default_location,
            shipTo: userInfo.data.default_location,
          })
          return ordersApi
            .duplicateSamples(
              orderMapper.sampleToSampleCreateRequest(finalRequest)
            )
            .then((res) => orderMapper.samplesResponseToSamples(res))
            .then((sampleResponse) => {
              return sampleResponse.map((s) =>
                Object.assign({}, s, itemMap.get(s.tcin.toString()), {
                  ...s,
                  generalDescription:
                    items.find((sam) => sam.tcin === s.tcin.toString())
                      .description || '',
                })
              )
            })
            .then((result) => {
              dispatch(duplicateSamplesPending(false))
              return result
            })
            .catch((error) => {
              try {
                if (error.response.data.errors[0].field === 'brandId') {
                  dispatch(
                    showNotification(
                      true,
                      "Couldn't duplicate this sample because it has no primary brand"
                    )
                  )
                } else if (error.response.data.errors[0].field === 'vendorId') {
                  dispatch(
                    showNotification(
                      true,
                      "Couldn't duplicate this sample because it has no primary vendor"
                    )
                  )
                }
              } catch (e) {
                dispatch(
                  showNotification(true, "Couldn't duplicate this sample")
                )
              }
              dispatch(duplicateSamplesFailure())
              dispatch(duplicateSamplesPending(false))
            })
        })
      })
  }
}

export function getDuplicateBarcode(samples = [], type = 'MULTILINE') {
  return (dispatch) => {
    return barcodeApi
      .getBarcode(barcodeMapper.samplesToBarcodeRequest(samples), type)
      .then((barcodeResult) => barcodeResult.data)
      .then((barcodePdf) => {
        return URL.createObjectURL(
          new Blob([barcodePdf], { type: 'application/pdf' })
        )
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            "Couldn't retrieve barcodes at this time.  Please try again."
          )
        )
      })
  }
}

export function duplicateSamplesPending(pending = false) {
  return {
    type: types.SEARCH_ARCHIVED_DUPLICATE_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export function duplicateSamplesSuccess(data = []) {
  return {
    type: types.SEARCH_ARCHIVED_DUPLICATE_SAMPLES_SUCCESS,
    payload: { duplicateSamples: data },
  }
}

export function duplicateSamplesFailure() {
  return {
    type: types.SEARCH_ARCHIVED_DUPLICATE_SAMPLES_FAILURE,
  }
}

export function emptySelectedData() {
  return {
    type: types.EMPTY_SELECTED_DATA,
  }
}

export function autoRefreshSearch(autoRefreshData = false) {
  return {
    type: types.AUTO_REFRESH_SEARCH,
    payload: { autoRefreshData: autoRefreshData },
  }
}

// Unarcheive samples requests
export function unArchieveSamples(selectedData) {
  return (dispatch) => {
    dispatch(unarchiveSampleRequestPending(true))
    return inventoryApi
      .unarchiveSamples(selectedData.map((it) => it?.sampleId))
      .then((unarchiveResponse) => {
        dispatch(unarchiveSampleRequestSuccess(selectedData))
        dispatch(closeAlert())
      })
      .catch(() => {
        dispatch(unarchiveSampleRequestFailure())
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to unarchive samples'
          )
        )
      })
      .finally(() => {
        dispatch(unarchiveSampleRequestPending(false))
      })
  }
}

export const unarchiveSampleRequestPending = (pending) => {
  return {
    type: types.SEARCH_UNARCHIVED_SAMPLES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export const unarchiveSampleRequestSuccess = (selectedData) => {
  return {
    type: types.SEARCH_UNARCHIVED_SAMPLES_REQUEST_SUCCESS,
    payload: { data: selectedData },
  }
}

export const unarchiveSampleRequestFailure = () => {
  return {
    type: types.SEARCH_UNARCHIVED_SAMPLES_REQUEST_FAILURE,
  }
}

export function showAlert(selectedData = []) {
  const message = ` Are you sure you want to unarchive sample/s ${selectedData
    .map((item) => item.pegasusId)
    .join(', ')}?`
  const response = true
  const dialogTitle = 'Unarchive Sample(s)?'
  return {
    type: types.SEARCH_ARCHIVED_PAGE_ALERT,
    payload: { message, response, dialogTitle },
  }
}

export function closeAlert() {
  return {
    type: types.CLOSE_SEARCH_ARCHIVED_PAGE_ALERT,
  }
}
