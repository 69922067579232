import React from 'react'
import withHOCs from 'util/withHocs'
import { withStyles } from '@material-ui/core/styles'
import { isEqual } from 'lodash'
import DialogBox from '../../components/DialogBox/DialogBox'
import { Grid, Paper } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'

import { locationStrByData, locationDataToPrint } from '../../util/locations'
import { NEW_LOCATIONS_COMPONENT_TYPE } from '../../enums/locations'
import {
  getLevelsWithLabelsStr,
  getLevelsWithLabels,
  getCurrentBuilding,
  getIsNewLocationCreated,
  getCreatedLocationData,
} from '../../store/locations/selectors'

import {
  createANewLocation,
  printNewLocationLabel,
  resetToInitState,
} from '../../store/locations/actionCreator'

import NewLocationPopUpDD from '../../components/LocationDropdowns'

const styles = (theme) => ({
  inputLabel: {
    transform: 'translate(0, 20px) scale(1)',
    '&.shrink': {
      transform: 'translate(0, -6px) scale(0.75)',
    },
  },
  inputLabelControl: {
    margin: 8,
    minWidth: 120,
    width: '165px',
  },
  inputLabelControlAuto: {
    margin: 8,
    minWidth: 120,
    width: 'auto',
  },
  inputLabelControlZero: {
    margin: 8,
    minWidth: 120,
    width: '165px',
  },
  barcodeInput: {
    minWidth: 120,
    width: '165px',
  },
  vl: {
    borderLeft: '2px solid grey',
    height: '35px',
  },
  greyClass: {
    color: 'grey',
    fontSize: '22px',
  },
  activeStepClass: {
    color: '#2684FF',
    fontSize: '22px',
  },
  greenClass: {
    color: 'green',
    fontSize: '20px',
  },
  greenClassIcon: {
    color: 'green',
    fontSize: '45px',
  },
  errorColor: {
    color: '#B85300',
  },
  paper: {
    width: '97%',
    marginTop: 20,
    // align: 'center',
    backgroundColor: '#F7F7F7',
  },
  locationDetailPaper: {
    width: '97%',
    marginTop: 20,
    marginBottom: 40,
    backgroundColor: '#F7F7F7',
  },
})

const DialogBoxWithStyles = withStyles((theme) => ({
  paper: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}))(DialogBox)

class NewLocationPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNewOption: false,
      resetLocations: false,
      displayCreateLocation: false,
      dropdownValue: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isNewLocationCreated } = this.props
    const { isNewLocationCreated: prevIsNewLocationCreated } = prevProps
    if (
      !!isNewLocationCreated &&
      !isEqual(prevIsNewLocationCreated, isNewLocationCreated)
    ) {
      this.setState({
        isNewOption: false,
        displayCreateLocation: true,
      })
    }
  }

  componentWillUnmount() {
    const { resetToInitState = () => {} } = this.props
    resetToInitState(NEW_LOCATIONS_COMPONENT_TYPE)
  }

  onPrintLableSuccess = (barcodes) => {
    const file = new Blob([barcodes], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    window.open(fileURL).print()
  }

  onPrintLabel = () => {
    const {
      createdLocationData = {},
      printNewLocationLabel = () => {},
      // barcodeType = 'MULTILINE', //todo
    } = this.props
    const { location_id: createdLocationID = '' } = createdLocationData
    let dataToPrint = locationDataToPrint(createdLocationData)
    dataToPrint['location_id'] = createdLocationID
    printNewLocationLabel([dataToPrint], this.onPrintLableSuccess, 'DYMO')
  }

  onAddLocation = () => {
    const { createANewLocation = () => {}, levelsWithLabels = {} } = this.props
    createANewLocation(levelsWithLabels, NEW_LOCATIONS_COMPONENT_TYPE)
  }

  renderPrintLabel = () => {
    const { createdLocationData = {} } = this.props
    const locationDataStr = locationStrByData(createdLocationData)
    const { locationId: createdLocationID = '' } = createdLocationData
    const { classes = {} } = this.props
    return (
      <Grid container>
        <Grid container justify={'center'}>
          <Grid item container xs={12} sm={12} justify={'center'}>
            <LocationOnIcon className={classes.greenClassIcon} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            justify={'center'}
            className={classes.greenClass}
          >
            {'Location Created!'}
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Paper className={classes.locationDetailPaper}>
            <Grid item container spacing={2} style={{ padding: '12px' }}>
              <Grid item xs={12}>{`Location ID: ${createdLocationID}`}</Grid>
              <Grid item xs={12}>
                {locationDataStr}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  setIsNewOption = (isNewOption) => {
    this.setState({
      isNewOption: isNewOption,
    })
  }

  onPopUpClose = () => {
    this.setState(
      {
        resetLocations: true,
        displayCreateLocation: false,
      },
      () => {
        this.props.onClose()
      }
    )
  }

  updateDropdownValue = (dropdownValue) => {
    this.setState({ dropdownValue: dropdownValue })
  }

  renderInitPage = () => {
    const { classes = {}, levelsWithLabelsStr = '' } = this.props
    const { dropdownValue = '', isNewOption, resetLocations } = this.state
    return (
      <Grid container>
        <Grid item container>
          <NewLocationPopUpDD
            isModalOpen={this.props.isModalOpen}
            resetLocations={resetLocations}
            setIsNewOption={this.setIsNewOption}
            componentType={NEW_LOCATIONS_COMPONENT_TYPE}
            updateDropdownValue={this.updateDropdownValue}
          />
        </Grid>
        <Grid item container>
          <Paper className={classes.paper}>
            <Grid item container spacing={2} style={{ padding: '12px' }}>
              <Grid item xs={12}>{`Location ID: ${dropdownValue}`}</Grid>
              <Grid item xs={12}>
                {levelsWithLabelsStr}
              </Grid>
            </Grid>
          </Paper>
          <Grid item className={classes.errorColor}>
            {!isNewOption ? 'This location already exists' : ''}
          </Grid>
        </Grid>
      </Grid>
    )
  }
  render() {
    const {
      isNewOption,
      displayCreateLocation = false,
      //   isModalOpen,
    } = this.state
    return (
      <Grid container spacing={2}>
        {displayCreateLocation ? (
          <DialogBoxWithStyles
            isAlertVisible={this.props.isModalOpen}
            message={this.renderPrintLabel()}
            onClose={this.onPopUpClose}
            closeOnAgree={false}
            response={'true'}
            onAgree={this.onPrintLabel}
            title={'Add New Location to ' + this.props.currentBuilding}
            primaryButtonText="Print Label"
            secondaryButtonText="Close"
          />
        ) : (
          <DialogBoxWithStyles
            isAlertVisible={this.props.isModalOpen}
            message={this.renderInitPage()}
            onClose={this.onPopUpClose}
            closeOnAgree={false}
            response={'true'}
            onAgree={isNewOption ? this.onAddLocation : undefined}
            title={'Add New Location to ' + this.props.currentBuilding}
            primaryButtonText="Add Location"
            secondaryButtonText="Cancel"
          />
        )}
      </Grid>
    )
  }
}

const mapStateToProps = (state) => ({
  levelsWithLabelsStr: getLevelsWithLabelsStr(NEW_LOCATIONS_COMPONENT_TYPE)(
    state
  ),
  levelsWithLabels: getLevelsWithLabels(NEW_LOCATIONS_COMPONENT_TYPE)(state),
  currentBuilding: getCurrentBuilding(NEW_LOCATIONS_COMPONENT_TYPE)(state),
  isNewLocationCreated: getIsNewLocationCreated(NEW_LOCATIONS_COMPONENT_TYPE)(
    state
  ),
  createdLocationData: getCreatedLocationData(NEW_LOCATIONS_COMPONENT_TYPE)(
    state
  ),
  barcodeType: state.user.barcodeType,
})

function mapDispatchToProps(dispatch) {
  return {
    createANewLocation: (params = {}, componentType = '') => {
      dispatch(createANewLocation(params, componentType))
    },
    printNewLocationLabel: (
      params = [],
      callBack = () => {},
      printType = ''
    ) => {
      dispatch(printNewLocationLabel(params, callBack, printType))
    },
    resetToInitState: (componentType = '') => {
      dispatch(resetToInitState(componentType))
    },
  }
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(NewLocationPopup)
