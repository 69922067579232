import * as types from './actionType'
// import { isEmpty } from 'lodash'
import {
  resetSelectedLevelLocations,
  resetLevelsWithLabels,
  resetLevelsToCreateLocation,
  resetSelectedValueByLevel,
  resetSelectedLocationIdByLevel,
} from '../../util/locations'

export const resetInitialState = {
  fetchCurrentUserLocationPending: false,
  locationsByLevels: {
    aisle: [],
    section: [],
    shelf: [],
    container: [],
  },
  levelsWithLabels: {},
  locationId: '',
  levelsToCreateLocation: {},
  selectedValueByLevel: {},
  selectedLocationIdByLevel: {},
  newLocationData: {},
  isNewLocationCreated: false,
  createLocationPending: false,
}
export const initialState = {}

export default function locations(state = initialState, action = {}) {
  switch (action.type) {
    case types.CREATE_A_NEW_LOCATION_SUCCESS: {
      const {
        payload: {
          newLocationData: propsNewLocationData = {},
          componentType = '',
        },
      } = action
      const componentBasedState = state[componentType]
      // const { locationId = '' } = propsNewLocationData
      return Object.assign({}, state, {
        [componentType]: Object.assign({}, componentBasedState, {
          newLocationData: propsNewLocationData,
          isNewLocationCreated: Object.entries(propsNewLocationData).length > 0,
        }),
      })
    }
    case types.RESET_TO_INIT_STATE: {
      const {
        payload: { componentType = '' },
      } = action
      return Object.assign({}, state, {
        [componentType]: resetInitialState,
      })
    }
    case types.CREATE_A_NEW_LOCATION_PENDING: {
      return Object.assign({}, state, {
        createLocationPending: action.payload.pending,
      })
    }
    case types.FIND_CURRENT_USER_LOCATION_PENDING: {
      return Object.assign({}, state, {
        fetchCurrentUserLocationPending: action.payload.pending,
      })
    }
    case types.FIND_SUBLOCATION_BYID_SUCCESS: {
      const {
        payload: { componentType = 'edit' },
      } = action
      const componentBasedState = state[componentType] || resetInitialState
      const {
        locationsByLevels = {},
        levelsWithLabels = {},
        levelsToCreateLocation = {},
        selectedValueByLevel = {},
        selectedLocationIdByLevel = {},
        // locationId = {},
      } = componentBasedState
      const locationsByLevelsCopy = Object.assign({}, locationsByLevels)
      // const locationsByLevelsCopyByType =
      //   locationsByLevelsCopy[componentType] || {}
      // const levelsWithLabelsByType = levelsWithLabels[componentType] || {}
      // const levelsToCreateLocationByType =
      //   levelsToCreateLocation[componentType] || {}
      // const selectedValueByLevelByType =
      //   selectedValueByLevel[componentType] || {}
      // const locationIdByType = locationId[componentType] || ''
      // const locationsByLevelsnew = Object.values(
      //   locationsByLevels
      // ).every((obj) => isEmpty(obj))
      //   ? {}
      //   : locationsByLevels

      // const currentComponentUpdState = {
      //   locationsByLevels: Object.assign({}, locationsByLevelsnew, {
      //     [componentType]: resetSelectedLevelLocations(
      //       action.payload.level,
      //       action.payload.locations,
      //       locationsByLevelsCopyByType
      //     ),
      //   }),
      //   locationId: Object.assign({}, locationId, {
      //     [componentType]: action.payload.locationId,
      //   }),
      //   levelsWithLabels: Object.assign({}, levelsWithLabels, {
      //     [componentType]: resetLevelsWithLabels(
      //       action.payload.initLevel,
      //       action.payload.locationLabel,
      //       levelsWithLabelsByType
      //     ),
      //   }),
      //   levelsToCreateLocation: Object.assign({}, levelsToCreateLocation, {
      //     [componentType]: Object.assign({}, levelsToCreateLocationByType, {
      //       [action.payload.initLevel]: action.payload.locationId,
      //     }),
      //   }),
      //   selectedValueByLevel: Object.assign({}, selectedValueByLevel, {
      //     [componentType]: Object.assign({}, selectedValueByLevelByType, {
      //       [action.payload.initLevel]: action.payload.selectedOption,
      //     }),
      //   }),
      // }
      return Object.assign({}, state, {
        [componentType]: {
          locationsByLevels: resetSelectedLevelLocations(
            action.payload.level,
            action.payload.locations,
            locationsByLevelsCopy
          ),
          locationId: action.payload.locationId || '',
          levelsWithLabels: resetLevelsWithLabels(
            action.payload.initLevel,
            action.payload.locationLabel,
            levelsWithLabels
          ),
          // levelsToCreateLocation: Object.assign({}, levelsToCreateLocation, {
          //   [action.payload.initLevel]: action.payload.locationId,
          // }),
          levelsToCreateLocation: resetLevelsToCreateLocation(
            action.payload.initLevel,
            action.payload.locationLabel,
            levelsToCreateLocation
          ),
          selectedValueByLevel: resetSelectedValueByLevel(
            action.payload.initLevel,
            action.payload.locationLabel,
            selectedValueByLevel
          ),
          selectedLocationIdByLevel: resetSelectedLocationIdByLevel(
            action.payload.initLevel,
            action.payload.locationId || '',
            selectedLocationIdByLevel
          ),
        },
      })
    }
    default:
      return state
  }
}
