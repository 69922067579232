import React from 'react'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid'
import ShowIf from 'components/ShowIf'
import { Divider } from '@material-ui/core'
import { Edit } from '@mui/icons-material'

export function ScenicRoomReadOnlyField({ lable = '', value = '', type = '' }) {
  return (
    <>
      <div
        style={{
          fontSize: '12px',
          lineHeight: '14px',
          color: '#333333',
        }}
      >
        {lable}:
      </div>
      <div
        style={{
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '16px',
          color: '#333333',
          paddingTop: '10px',
        }}
      >
        {value}
      </div>
    </>
  )
}

export function ScenicRoomReadOnlyList({
  fieldList = [],
  isRoomReadOnly,
  setEditScenicRoom,
  isArchivedRoom = false,
}) {
  return (
    <>
      <Grid container spacing={4}>
        {fieldList.map((field) => (
          <React.Fragment key={field.label}>
            <ShowIf condition={field.addDivider}>
              <Grid item xs={10}>
                <Divider />
              </Grid>
            </ShowIf>
            <Grid item xs={field.gridSize || 4} key={field.lable}>
              <ScenicRoomReadOnlyField
                lable={field.lable}
                value={field.value}
                type={field?.type || ''}
                // fieldType={field.fieldType}
                isReadOnly={isRoomReadOnly}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <ShowIf condition={!isRoomReadOnly && !isArchivedRoom}>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="primary"
            disabled={isRoomReadOnly}
            onClick={setEditScenicRoom}
            startIcon={<Edit />}
          >
            EDIT ROOM INFO
          </Button>
        </Grid>
      </ShowIf>
    </>
  )
}

export default ScenicRoomReadOnlyList
