export function hasField(object, field = '') {
  let flag = false
  const regExp = new RegExp(field.toLowerCase())
  const values = Object.keys(object).map((key) => object[key])
  values.forEach((value) => {
    if (value != null && value !== undefined) {
      const valString = value.toString().toLowerCase()
      if (regExp.test(valString)) {
        flag = true
      }
    }
  })
  return flag
}

export function hasFields(object, field = '') {
  let flag = true
  const fieldList = field.split(' ')
  const regExpList = []
  fieldList.forEach((value) => {
    const regExp = new RegExp(value.toLowerCase())
    regExpList.push(regExp)
  })
  const values = Object.keys(object).map((key) => object[key])
  regExpList.forEach((regExp) => {
    if (!testAllValues(values, regExp)) {
      flag = false
    }
  })
  return flag
}

function testAllValues(valueArray, regExp) {
  let flag = false
  valueArray.forEach((value) => {
    if (value != null && value !== undefined) {
      const valString = value.toString().toLowerCase()
      if (regExp.test(valString)) {
        flag = true
      }
    }
  })
  return flag
}

export function compareArrays(arr1 = [], arr2 = []) {
  return (
    arr1.length === arr2.length &&
    arr1.every((value, index) => value === arr2[index])
  )
}

export const addLeadingZeros = (stringToAddZero = '', intendedStringLength) => {
  if (!stringToAddZero) {
    return
  }
  let returnString = stringToAddZero
  while (returnString.length < intendedStringLength) {
    returnString = '0' + returnString
  }
  return returnString
}
