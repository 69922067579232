import * as types from './actionType'

export const initialState = {
  columnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: false,
      label: 'PG ID',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: false,
      disablePadding: false,
      label: 'tcin',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      textAlign: 'right',
    },
  ],
  stepOneColumnData: [
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      textAlign: 'right',
    },
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: false,
      label: 'Pegasus ID',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: false,
      disablePadding: false,
      label: 'TCIN',
      textAlign: 'right',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'dueDate',
      numeric: false,
      disablePadding: false,
      label: 'Due Date',
      date: true,
      textAlign: 'right',
    },
    {
      id: 'shipToLoc',
      numeric: false,
      disablePadding: false,
      label: 'Ship To',
      textAlign: 'right',
    },
  ],
  data: [],
  onClick: () => {},
  onSortSelectedChange: () => {},
  order: 'asc',
  orderBy: 'pegasusId',
  page: 0,
  popUpIsVisible: false,
  popUpItem: {},
  rowCount: 10,
  rowsPerPage: 10,
  processShipmentRequestPending: false,
  processShipmentRequestFailure: false,
  shipmentBarcodeRequestFailure: false,
  shipmentBarcodeRequestFileStream: '',
  shipmentBarcodeRequestPending: false,
  shipmentDestination: '',
  submitOrderRequestPending: false,
  submitOrderRequestFailure: false,
  visible: false,
}

export default function shipmentBarcode(state = initialState, action = {}) {
  switch (action.type) {
    case types.SHIPMENT_BARCODE_SUBMIT_ORDER: {
      return Object.assign({}, state, {})
    }
    case types.SHIPMENT_BARCODE_SET_DATA: {
      return Object.assign({}, state, {
        data: action.payload.data,
        shipmentDestination: action.payload.shipmentDestination,
      })
    }
    case types.SHIPMENT_BARCODE_SET_PAGE: {
      return Object.assign({}, state, {
        page: action.payload.page,
      })
    }
    case types.SHIPMENT_BARCODE_SET_ROWS_PER_PAGE: {
      return Object.assign({}, state, {
        rowsPerPage: action.payload.rowsPerPage,
      })
    }
    case types.SHIPMENT_BARCODE_SET_TRACKING_NUMBER: {
      return Object.assign({}, state, {
        data: state.data.map((i) => ({
          ...i,
          trackingNumber: action.payload.event.trackingNumber,
        })),
      })
    }
    case types.SHIPMENT_BARCODE_CLEAR_TRACKING_INFO: {
      return Object.assign({}, state, {
        data: state.data.map((i) => ({
          ...i,
          trackingNumber: '',
          carrier: '',
        })),
      })
    }
    case types.SHIPMENT_BARCODE_SET_CARRIER: {
      return Object.assign({}, state, {
        data: state.data.map((i) => ({
          ...i,
          carrier: action.payload.event.carrier,
        })),
      })
    }
    case types.SHIPMENT_BARCODE_SET_VISIBILITY: {
      return Object.assign({}, state, {
        data: state.data.map((i) => ({
          ...i,
          visible: action.payload.event.visible,
        })),
      })
    }
    case types.SHIPMENT_BARCODE_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        shipmentBarcodeRequestFileStream: action.payload.data,
      })
    }
    case types.SHIPMENT_BARCODE_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        shipmentBarcodeRequestFailure: true,
      })
    }
    case types.SHIPMENT_BARCODE_REQUEST_PENDING: {
      return Object.assign({}, state, {
        shipmentBarcodeRequestPending: action.payload.pending,
      })
    }
    case types.SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        data: [],
        submitOrderRequestFailure: true,
      })
    }
    case types.SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        data: [],
        shipmentDestination: '',
        submitOrderRequestFailure: false,
      })
    }
    case types.SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_PENDING: {
      return Object.assign({}, state, {
        submitOrderRequestPending: action.payload.pending,
      })
    }
    case types.SHIPMENT_BARCODE_CLOSE_POPUP: {
      return Object.assign({}, state, {
        popUpIsVisible: false,
      })
    }
    case types.SHIPMENT_BARCODE_SHOW_POPUP: {
      return Object.assign({}, state, {
        popUpIsVisible: true,
        popUpItem: action.payload.item,
      })
    }
    default:
      return state
  }
}
