import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import clsx from 'classnames'
import Button from '@mui/material/Button'
import { Divider, withStyles } from '@material-ui/core'

import ShipmentPartners from '../Partners'
import ShipmentMarketing from '../Marketing'
import { NewShipmentStyles } from './NewShipmentStyles'
import ShowIf from 'components/ShowIf'
import Autocomplete from '@material-ui/lab/Autocomplete'

const disableShipmentChannel = (shipmentData) => {
  if (
    (shipmentData.project && shipmentData.project.id === null) ||
    shipmentData.project === undefined
  ) {
    return false
  } else {
    return true
  }
}

const ChannelOptions = ({ channels = [] }) => {
  return getProjectChannelNames(channels).map((option) => (
    <option value={option.name} key={option.id}>
      {option.name}
    </option>
  ))
}

const getProjectChannelNames = (channels) => {
  const shipmentChannels = channels.map((it) =>
    Object.assign(
      {},
      {
        id: it.id,
        name: it.channel,
      }
    )
  )
  return shipmentChannels.sort((a, b) => (a.name > b.name ? 1 : -1))
}

const handleProjectChange = (
  event,
  projectNames,
  shipmentData,
  updateShipment
) => {
  const projectId = event.target.value !== '0' ? event.target.value : null
  const selectedIndex = event.target.options.selectedIndex
  const projectChannel =
    selectedIndex > 0 ? projectNames[selectedIndex - 1].projectChannel : 'None'
  const projectName = event.target.options[selectedIndex].label

  updateShipment('project', {
    ...shipmentData.project,
    id: projectId,
    name: projectName,
  })
  updateShipment('shipmentChannel', projectChannel)
}

const NewShipmentForm = ({
  classes,
  shipmentData,
  projectNames = [],
  shipFromLocName,
  shipFromLocSampleExists,
  shipToLocations = [],
  updateShipment,
  shipFromLocations = [],
  channels,
  handleShipmentUpdate,
  allPartnersList = [],
  onCancel,
  isCreatePage = false,
}) => {
  const handleFieldChange = (event, fieldName) => {
    const newValue = event.target.value
    updateShipment(fieldName, newValue)
  }

  const handleShipFromChange = (newLocation) => {
    const newShipFromLocation = shipFromLocations.find(
      (location) => location.building === newLocation
    )
    if (newShipFromLocation) {
      updateShipment('shipFromLocId', newShipFromLocation.location_id)
      updateShipment('shipFromLocName', newShipFromLocation.building)
    } else {
      updateShipment('shipFromLocId', '')
      updateShipment('shipFromLocName', '')
    }
  }

  const handleShipToChange = (newLocation) => {
    if (newLocation?.building) {
      updateShipment('shipToLocation', newLocation)
      updateShipment('shipToLocName', newLocation?.building)
    } else {
      updateShipment('shipToLocation', {})
    }
  }

  const isShipmentChannelDisabled = disableShipmentChannel(shipmentData)
  const [showPartnerInputFields, setShowPartnerInputFields] = useState(false)
  const [showMaketingPidInput, setShowMaketingPidInput] = useState(false)
  const [selectedPartnerType, setSelectedPartnerType] = useState('')

  const doesAllRequiredFieldsExists =
    !!shipmentData.shipmentName &&
    !!shipmentData.shipFromLocId &&
    !!shipmentData.shipToLocation?.building

  const pidData = shipmentData.marketingPid

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <TextField
          className={clsx(classes.textField)}
          id="shipmentName"
          inputProps={{
            maxLength: 100,
          }}
          placeholder="Shipment Name"
          label="Shipment Name"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={shipmentData.shipmentName || ''}
          onChange={(event) => handleFieldChange(event, 'shipmentName')}
          error={!shipmentData.shipmentName}
          required
          disabled={!!shipmentData.shipDate}
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          onChange={(e, selected) => handleShipFromChange(selected)}
          value={shipFromLocName}
          options={shipFromLocations.map(
            (locationInfo) => locationInfo.building
          )}
          disabled={!!shipmentData.shipDate}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ship From"
              variant="outlined"
              error={!!shipFromLocSampleExists || !shipmentData.shipFromLocId}
              helperText={
                shipFromLocSampleExists
                  ? 'Samples in this shipment have a conflicting current location'
                  : ''
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          onChange={(e, selected) => handleShipToChange(selected)}
          value={shipmentData.shipToLocation || {}}
          options={shipToLocations}
          getOptionLabel={(locationInfo) =>
            locationInfo?.building
              ? `${locationInfo?.building} (${locationInfo?.location_id})`
              : locationInfo
          }
          disabled={!!shipmentData.shipDate}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ship To"
              variant="outlined"
              error={
                !!shipFromLocSampleExists ||
                !shipmentData.shipToLocation?.building
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}>
        <TextField
          className={classes.textField}
          id="carriers"
          inputProps={{
            maxLength: 20,
          }}
          placeholder="Carrier"
          label="Carrier"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={shipmentData.carrier}
          onChange={(event) => handleFieldChange(event, 'carrier')}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          className={classes.textField}
          id="trackingNum"
          inputProps={{
            maxLength: 40,
          }}
          placeholder="Tracking #"
          label="Tracking Number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={shipmentData.tracking}
          onChange={(event) => handleFieldChange(event, 'tracking')}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          className={classes.textField}
          id="shipmentChannel"
          label="Shipment Channel"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          placeholder="shipmentChannel"
          disabled={isShipmentChannelDisabled}
          onChange={(event) => handleFieldChange(event, 'shipmentChannel')}
          select
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
            native: true,
          }}
          value={shipmentData.shipmentChannel || ''}
        >
          <option> None </option>
          <ChannelOptions channels={channels} />
        </TextField>
      </Grid>
      <Grid item xs={6}>
        {/* eslint-disable react/jsx-no-duplicate-props */}
        <TextField
          className={classes.textField}
          id="shipmentNotes"
          InputProps={{
            classes: {
              input: classes.notesTextField,
            },
          }}
          inputProps={{
            maxLength: 250,
          }}
          label="Shipment Notes"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginBottom: '14px' }}
          multiline
          placeholder="Shipment Notes"
          variant="outlined"
          value={shipmentData.notes}
          maxRows={3}
          onChange={(event) => handleFieldChange(event, 'notes')}
        />
      </Grid>
      <ShowIf condition={!isCreatePage}>
        <Grid item xs={3}>
          <TextField
            className={classes.textField}
            id="projectName"
            label="Project Name"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={
              projectNames.length && shipmentData.project
                ? shipmentData.project.id
                : 0
            }
            onChange={(event) => {
              handleProjectChange(
                event,
                projectNames,
                shipmentData,
                updateShipment
              )
            }}
            select
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
              native: true,
            }}
          >
            <option value={0} key={0}>
              None
            </option>
            {projectNames.map((option) => (
              <option
                value={option.projectId}
                channel={option.projectChannel}
                key={option.projectId}
              >
                {option.projectName}
              </option>
            ))}
          </TextField>
        </Grid>
      </ShowIf>
      <Grid item xs={9}>
        <Divider style={{ marginTop: '8px' }} />
      </Grid>
      <Grid item xs={3} />
      <ShipmentMarketing
        onRemove={() => {}}
        pidData={pidData}
        showMaketingPidInput={showMaketingPidInput}
        updateMarketingFieldVisiblity={(value) =>
          setShowMaketingPidInput(value)
        }
        addNewMarketingPid={
          <ShipmentMarketing.AddNewMarketingPid
            pidData={pidData}
            updateMarketingFieldVisiblity={(value) =>
              setShowMaketingPidInput(value)
            }
            handleUpdate={(updatedPIDList) => {
              updateShipment('marketingPid', updatedPIDList)
              setShowMaketingPidInput(false)
            }}
          />
        }
        marketingList={
          <ShipmentMarketing.MarketingList
            isReadOnly={false}
            pidData={pidData}
            updateMarketingFieldVisiblity={setShowMaketingPidInput}
            showMaketingPidInput={showMaketingPidInput}
            onRemove={(removedOne) => {
              const marketingPid = shipmentData.marketingPid.filter(
                (pid) => removedOne !== pid
              )
              updateShipment('marketingPid', marketingPid)
            }}
          />
        }
      />
      <Grid item xs={9}>
        <Divider style={{ marginTop: '8px' }} />
      </Grid>
      <Grid item xs={3} />
      <ShipmentPartners
        shipment={shipmentData}
        allPartnersList={allPartnersList}
        partners={shipmentData.shipmentPartners}
        showPartnerInputFields={showPartnerInputFields}
        updateShowHidePartnerInputFields={(value) =>
          setShowPartnerInputFields(value)
        }
        selectedPartnerType={selectedPartnerType}
        classes={classes}
        partnersList={
          <ShipmentPartners.PartnersList
            onRemove={(removedOne) => {
              const updatedPartners = shipmentData.shipmentPartners.filter(
                (partner) => {
                  const isSameParterWithType =
                    partner.partner_type === removedOne.partner_type &&
                    partner.partner_name === removedOne.partner_name
                  return !isSameParterWithType
                }
              )
              updateShipment('shipmentPartners', updatedPartners)
            }}
          />
        }
        partnerType={
          <ShipmentPartners.PartnerType
            onSelect={(e) => {
              setSelectedPartnerType(e.target.value)
            }}
          />
        }
        partnerNames={
          <ShipmentPartners.AddNewPartners
            handleUpdate={(updatedPartners) => {
              updateShipment('shipmentPartners', updatedPartners)
              setShowPartnerInputFields(false)
            }}
          />
        }
      />
      <Grid item xs={9}>
        <Divider style={{ margin: '10px 0 10px 0' }} />
      </Grid>

      <Grid container xs={9} justifyContent="flex-end">
        <Grid item xs={1}>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            CANCEL
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            data-id={isCreatePage ? 'PENDING' : 'EDITED'}
            variant="contained"
            color="primary"
            disabled={!doesAllRequiredFieldsExists}
            onClick={handleShipmentUpdate}
          >
            {isCreatePage ? 'Create SHIPMENT' : 'SAVE SHIPMENT INFO'}
          </Button>
        </Grid>
      </Grid>
      {/* eslint-enable react/jsx-no-duplicate-props */}
    </Grid>
  )
}

export default withStyles(NewShipmentStyles)(NewShipmentForm)
