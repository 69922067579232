import React from 'react'
import withHOCs from 'util/withHocs'
import apiConfig from '../../apiConfig'
import { isEqual } from 'lodash'
/**Material Components */
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  IconButton,
  CardActions,
} from '@material-ui/core'
import { CustomPaper } from '../../util/CommonUtils'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
/**Material Icons */
import { Edit } from '@material-ui/icons'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
/**Internal Components */
import BarcodeTypePopUp from '../../components/PopUp/BarcodeTypePopUp'
import PersonalInformationCard from '../../components/EditCard/PersonalInformationCard'
import ShipToCard from '../../components/EditCard/ShipToCard'
import HelpCard from '../../components/EditCard/HelpCard'
import ShipToLocation from '../../components/EditCard/ShipToLocation'
import CircularPageLoader from '../../components/Loader/CircularPageLoader'
/**Action Creators */
import * as actions from '../../store/settings/actionCreator'
import {
  updateUser,
  updateUserDept,
  updateDefaultLocation,
  getUserDepts,
} from '../../store/user/actionCreator'
import {
  getCheckInLocations,
  downloadCheckinLocations,
} from '../../store/settings/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { setBarcodeType } from '../../store/user/actionCreator'
import { getAllDepartments } from '../../store/autoOrderFilters/actionCreator'
/**Selectors */
import { getAllDepartmentsOptions } from '../../store/autoOrderFilters/selectors'
import AutoOrderingGrid from '../SearchPage/AutoOrderingGrid'
import AdminSettingsPage from './AdminSettingsPage'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const styles = (theme) => ({
  title: {
    textAlign: 'center',
  },
  root: {
    flexGrow: 1,
    height: 440,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
  },
  card: {
    padding: '10px',
    margin: '10px',
  },
  typography: {
    padding: '10px',
  },
  list: {
    height: '100%',
  },
  drawerPaper: {
    position: 'relative',
    width: '200px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  defaultButton2: {
    maxWidth: '250px',
    width: 'auto',
  },
  defaultButton1: {
    maxWidth: '250px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
})

class SettingsPage extends React.Component {
  constructor(props) {
    super(props)
    const { departments = [] } = props
    this.state = {
      barcodeTypePopUpIsVisible: false,
      editContentVisible: false,
      selectedDepartments: departments || [],
      defaultDepartments: [],
    }
  }
  componentDidMount() {
    const {
      setHeaderTitleArray,
      getCheckInLocations,
      getAllDepartments = () => {},
      settingsLocation = '',
      getUserDepts = () => {},
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
    } = this.props
    if (settingsLocation === 'Auto_Order_Settings') {
      this.handleSelectSection(settingsLocation)
    }

    setHeaderTitleArray([{ title: 'Settings', link: '/settings' }])
    getAllDepartments()
    getCheckInLocations()
    getUserDepts(lanId)
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { departments = [] } = this.props
    const { departments: prevDepartments = [] } = prevProps
    if (departments && !isEqual(departments, prevDepartments)) {
      this.setState({
        selectedDepartments: departments,
        defaultDepartments: departments,
      })
    }
  }

  handleSelectSection = (section) => {
    const { selectSection } = this.props
    selectSection(section)
  }

  handleEditIconClick = () => {
    this.setState((state) => ({ ...state, editContentVisible: true }))
  }

  handleCancelClick = () => {
    const { defaultDepartments = [] } = this.state
    this.setState((state) => ({
      ...state,
      selectedDepartments: defaultDepartments,
      editContentVisible: false,
    }))
  }

  handleSaveClick = () => {
    const { saveUserDept = () => {} } = this.props
    const { selectedDepartments = [] } = this.state
    this.setState((state) => {
      saveUserDept(selectedDepartments)
      return { ...state, editContentVisible: false }
    })
  }
  componentWillUnmount() {
    this.handleSelectSection('')
  }
  renderDepCard = () => {
    const { allDepartmentsOptions = [] } = this.props
    const { editContentVisible, selectedDepartments = [] } = this.state
    const { classes = {} } = this.props
    const depCard = !editContentVisible ? (
      <Card className={classes.card}>
        <CardHeader
          title="User Department(s)"
          action={
            <IconButton onClick={this.handleEditIconClick}>
              <Edit />
            </IconButton>
          }
        />
        <CardContent>
          <Typography>User belongs to these departments:</Typography>
          {
            <Stack direction="row" spacing={1} style={{ flexWrap: 'wrap' }}>
              {selectedDepartments.map((depObj) => {
                return (
                  <Chip
                    label={depObj.displayName}
                    variant={depObj.displayName}
                  />
                )
              })}
            </Stack>
          }
        </CardContent>
      </Card>
    ) : (
      <Card className={classes.card}>
        <CardHeader
          title="User Department(s)"
          action={
            <IconButton onClick={this.handleEditIconClick}>
              <Edit />
            </IconButton>
          }
        />
        <CardContent>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={allDepartmentsOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.displayName}
            onChange={(event, newValue, reason) => {
              this.setState({
                selectedDepartments: newValue,
              })
            }}
            PaperComponent={CustomPaper}
            getOptionSelected={(option, value) => {
              return option.id === value.id
            }}
            value={selectedDepartments}
            renderOption={(option, { selected, inputValue }) => {
              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.displayName}
                </React.Fragment>
              )
            }}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Department(s)"
                placeholder="Department(s)"
              />
            )}
          />
        </CardContent>
        <CardActions>
          <Button color="primary" onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleSaveClick}
          >
            Save
          </Button>
        </CardActions>
      </Card>
    )
    return depCard
  }

  makeSelectedSettingsList() {
    const {
      accountInformation,
      locations,
      classes,
      currentRole,
      selectedSection,
      saveUserInformation,
      saveShipToLocation,
      barcodeType = 'MULTILINE',
      columnData = [],
      downloadCheckinLocations = () => {},
      auth: { isAuthorized = () => {} } = {},
      settingsLocation = '',
    } = this.props

    const {
      roles: { requestedSamples: requestedSamplesRoles = [] },
    } = apiConfig

    const { barcodeTypePopUpIsVisible } = this.state
    switch (selectedSection) {
      case 'help_information': {
        return (
          <List>
            <HelpCard />
          </List>
        )
      }
      case 'vendor_address': {
        return (
          <List>
            <ShipToLocation
              columnData={columnData}
              data={locations}
              downloadLocations={downloadCheckinLocations}
            />
          </List>
        )
      }
      case 'Auto_Order_Settings': {
        return (
          <List>
            <AutoOrderingGrid
              renderBackButton={
                settingsLocation === 'Auto_Order_Settings' ? true : false
              }
            />
          </List>
        )
      }
      case 'admin_operations': {
        return (
          <List>
            <AdminSettingsPage />
          </List>
        )
      }
      default: {
        return (
          <List>
            <PersonalInformationCard
              handleSaveClick={saveUserInformation}
              accountInformation={accountInformation}
            />
            <ShipToCard
              locations={locations}
              handleSaveClick={saveShipToLocation}
              defaultLocation={accountInformation.default_ship_to}
              lanId={accountInformation.lanId}
              setBarcodeCallback={() => {
                saveUserInformation({ printer_type: 'MULTILINE' })
              }}
            />
            <Card className={classes.card}>
              <CardHeader title="User Role" />
              <CardContent>
                <Typography>
                  Your current role is <b> {currentRole} </b>
                </Typography>
              </CardContent>
            </Card>
            {isAuthorized(requestedSamplesRoles) ? this.renderDepCard() : ''}
            <Card className={classes.card}>
              <CardHeader title="Printer Settings" />
              <CardContent>
                <Typography>
                  Your current printer type is{' '}
                  <b>
                    {' '}
                    {barcodeType === 'DYMO'
                      ? 'Dymo Printer'
                      : 'Label Paper'}{' '}
                  </b>
                  {
                    <Button
                      id="changePaper"
                      data-id="CHANGEPAPERFORMAT"
                      className={classes.defaultButton2}
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() =>
                        this.setState((state) => {
                          return { barcodeTypePopUpIsVisible: true }
                        })
                      }
                    >
                      Change
                    </Button>
                  }
                </Typography>
              </CardContent>
            </Card>
            {!!barcodeTypePopUpIsVisible && (
              <BarcodeTypePopUp
                isVisible={barcodeTypePopUpIsVisible}
                onClose={() =>
                  this.setState({ barcodeTypePopUpIsVisible: false })
                }
                setterCallback={(barcodeType) =>
                  saveUserInformation({ printer_type: barcodeType })
                }
                barcodeType={barcodeType}
              />
            )}
          </List>
        )
      }
    }
  }

  render() {
    const {
      classes,
      settingsSections,
      selectSection,
      updateDefaultLocRequestPending,
      auth={}
    } = this.props
    const settingsCards = this.makeSelectedSettingsList()
    const settingsSectionsBasedonRole = settingsSections.filter((setting) => {
      if (setting.role && !auth.isAuthorized(setting.role)) {
        return false
      }
      return true
    })

    return updateDefaultLocRequestPending ? (
      <CircularPageLoader open={updateDefaultLocation} />
    ) : (
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List className={classes.list}>
            {settingsSectionsBasedonRole.map((section) => (
              <ListItem
                key={section.key}
                button
                onClick={() => selectSection(section.key)}
              >
                <ListItemIcon>{section.icon}</ListItemIcon>
                <ListItemText primary={section.label} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar}>{settingsCards}</div>
        </main>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectSection: (section) => dispatch(actions.setSelectedSection(section)),
    saveUserInformation: (userInformation) =>
      dispatch(updateUser(userInformation)),
    saveUserDept: (deptList = []) => dispatch(updateUserDept(deptList)),
    setBarcodeType: (barcodeType) => dispatch(setBarcodeType(barcodeType)),
    saveShipToLocation: (locationUpdate) =>
      dispatch(updateDefaultLocation(locationUpdate)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    getCheckInLocations: () => dispatch(getCheckInLocations()),
    downloadCheckinLocations: () => dispatch(downloadCheckinLocations()),
    getAllDepartments: () => dispatch(getAllDepartments('')),
    getUserDepts: (lanId = '') => dispatch(getUserDepts(lanId)),
  }
}

function mapStateToProps(state) {
  const {
    user: {
      lanId = '',
      firstName = '',
      lastName = '',
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state: userState = '',
      zipCode = '',
      mailstop = '',
      emailAddress = '',
      location = '',
      currentRole = '',
      barcodeType = 'MULTILINE',
      updateDefaultLocRequestPending = false,
      departments = [],
    },
    settings: {
      selectedSection = 'personal_information',
      settingsSections = [],
      checkInLocations = [],
      columnData = [],
    },
    autoOrderSamples: { settingsLocation = '' },
  } = state

  const accountInformation = {
    lanId: lanId,
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    contactInformation: {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: userState,
      zipCode: zipCode,
      mailstop: mailstop,
    },
    default_ship_to: location,
  }
  return {
    selectedSection: selectedSection,
    settingsSections: settingsSections,
    accountInformation: accountInformation,
    locations: checkInLocations,
    currentRole: currentRole,
    userLocation: location,
    barcodeType: barcodeType,
    columnData: columnData,
    updateDefaultLocRequestPending: updateDefaultLocRequestPending,
    allDepartmentsOptions: getAllDepartmentsOptions()(state),
    departments: departments,
    settingsLocation: settingsLocation,
  }
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(SettingsPage)
