import ChipArray from '../../components/Chips/ChipArray'
import copy from 'copy-to-clipboard'
import FlexibleTable from 'components/EnhancedTable/FlexibleTable'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import Search from '../../components/Search/Search'
import * as propTypes from 'prop-types'
import { showNotification } from '../../store/notification/actionCreator'
import { findIndex, isEqual, isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import withHOCs from 'util/withHocs'
import {
  getVendorEmailsById,
  setSampleData,
} from '../../store/order/actionCreator'
import {
  clearSearchData,
  closePopup,
  getData,
  setCheckBox,
  setCheckBoxHeaderChange,
  showPopup,
  resetSearchState,
  updateSampleQuantity,
} from '../../store/addSample/actionCreator'
import {
  duplicateSamplesAS,
  getDuplicateBarcode,
  autoRefreshSearch,
} from '../../store/searchInventory/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'

const styles = {
  addSample: {
    flexGrow: 1,
    margin: '5px',
  },
  addSampleIntro: {
    fontSize: 'xx-large',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
  typographyGreen: {
    color: 'green',
    fontSize: 18,
    textAlign: 'center',
  },
}

const StyledSearch = withStyles((theme) => ({
  textField: {
    width: '100%',
  },
  card: {
    padding: theme.spacing(1),
    boxShadow: 'unset',
  },
  actionButton: {},
}))(Search)

class AddSample extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checkAll: true,
      selectedData: [],
      searchedData: [],
      isPrintedBarcode: false,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { pageName = '' },
      },
      setHeaderTitleArray = () => {},
      resetSearchState = () => {},
    } = this.props
    pageName === 'myOrders'
      ? setHeaderTitleArray([
          { title: 'My Order History', link: '/myOrders' },
          { title: 'Add Samples' },
        ])
      : setHeaderTitleArray([
          { title: 'Search', link: '/searchInventory' },
          { title: 'Add Samples' },
        ])
    resetSearchState()
  }

  componentDidUpdate(prevProps, prevState) {
    const { data: prevPropsData = [] } = prevProps
    const { data: propsData = [] } = this.props
    if (!isEmpty(propsData) && !isEqual(propsData, prevPropsData)) {
      const newData = propsData.map((obj = {}) => {
        const { tcin: objTcin = '', item_quantity = Number(1) } = obj
        return Object.assign({}, obj, {
          itemId: objTcin,
          quantity: item_quantity || Number(1),
          item_quantity: item_quantity || Number(1),
        })
      })
      this.setState({
        searchedData: newData,
      })
    }
  }

  onSelected = (selectedData, selected) => {
    const { tcin = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.tcin === tcin
      }) !== -1
    )
  }

  handleChipClick = (value) => {
    const { handleChipClick = () => {} } = this.props
    copy(value)
    handleChipClick()
  }

  orderItems(index, data = []) {
    const {
      getVendorEmailsById = () => {},
      setSampleData = () => {},
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
    } = this.props
    const newSelected = data.map((i) => ({
      ...i,
      dueDate: null,
      vendorEmail: '',
      emailVmm: null,
      quantity: 1,
      shipTo: null,
      studioNotes: '',
      vendorNotes: '',
      userId: lanId,
    }))

    setSampleData(newSelected)
    getVendorEmailsById(data.map((data) => data.vendorId))
  }

  disableButton = () => {
    const { isPrintedBarcode } = this.state
    const { selectedData = [] } = this.props
    return selectedData.length === 0 || isPrintedBarcode
  }

  updateQuantity = (data = {}) => {
    const { searchedData = [] } = this.state
    const { tcin: updatedTcin = '', item_quantity = Number(1) } = data
    const newDataObj = searchedData.map((obj) => {
      if (obj.tcin === updatedTcin) {
        return Object.assign({}, obj, data, {
          quantity: item_quantity || Number(1),
          item_quantity: item_quantity || Number(1),
        })
      }
      return obj
    })
    this.setState({
      searchedData: [...newDataObj],
    })
  }

  duplicateSamples = () => {
    const { searchedData = [] } = this.state
    const {
      selectedData = [],
      duplicateSamples = () => {},
      getDuplicateBarcode = () => {},
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
      barcodeType = 'DYMO',
    } = this.props
    const selectedDataTcins = selectedData.map((obj) => obj.tcin)
    const filteredData =
      searchedData.filter(
        (obj) => selectedDataTcins.indexOf(obj.tcin) !== -1
      ) || []
    const newTcinsList = filteredData.map((obj) =>
      Object.assign({}, obj, {
        ...obj,
        userId: lanId,
      })
    )
    duplicateSamples(newTcinsList, lanId).then((samples) => {
      if (samples) {
        getDuplicateBarcode(samples, barcodeType).then(async (pdf) => {
          if (pdf !== undefined) {
            window.open(pdf).print()
          }
          this.setState({
            isPrintedBarcode: true,
          })
          // await autoRefreshSearch(true)
          // await this.props.history.push(`/searchInventory`)
        })
      }
    })
    this.setState({
      newSamplePopUpIsVisible: false,
    })
  }

  renderAddSamplePages = () => {
    const { searchedData = [], isPrintedBarcode } = this.state
    const {
      fetchData = () => {},
      clearSearchCallback = () => {},
      invalidData = [],
      classes = {},
      checkBoxEnabled,
      columnData = [],
      clickableColumn = [],
      data = [],
      numSelected,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      onClick,
      selectedData,
      confirmColumnData = [],
      duplicateSamplesList = [],
    } = this.props
    let chipData = invalidData.map((renderData, index) => ({
      label: renderData.tcin,
      key: index,
    }))
    const renderData = searchedData || data
    const dataFieldKeys = columnData.map((column) => column.id)
    switch (isPrintedBarcode) {
      case false: {
        return (
          <Grid container>
            <Grid item xs={12}>
              <StyledSearch
                label="Search"
                helperText="TCIN, DPCI, or UPC"
                buttonName="Search"
                buttonVariant="outlined"
                submitSearch={fetchData}
                clearSearchCallback={clearSearchCallback}
              />
            </Grid>
            <Grid item xs={12}>
              {invalidData.length > 0 && (
                <ChipArray
                  className={classes.invalidTcins}
                  data={chipData}
                  enableCopyAllButton
                  handleClick={this.handleChipClick}
                  title="Invalid TCIN/DPCI/UPCs"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FlexibleTable
                cascadeFieldKeys={[]}
                checkBoxEnabled={checkBoxEnabled}
                columnData={columnData}
                clickableColumn={clickableColumn}
                data={renderData}
                dataFieldKeys={dataFieldKeys}
                numSelected={numSelected}
                onCheckBoxChange={onCheckBoxChange}
                onCheckBoxHeaderChange={onCheckBoxHeaderChange}
                onClick={onClick}
                onSelected={this.onSelected}
                selected={selectedData}
                showPopup={showPopup}
                editEnabled={true}
                editItems={['item_quantity']}
                updateData={this.updateQuantity}
                uniqueIdentifierInData={'tcin'}
              />
            </Grid>
          </Grid>
        )
      }
      case true: {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.typographyGreen}>
                {`${'Samples created successfully'}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FlexibleTable
                cascadeFieldKeys={[]}
                columnData={confirmColumnData}
                data={duplicateSamplesList}
                dataFieldKeys={dataFieldKeys}
                onClick={onClick}
                onPopupChange={() => {}}
                showPopup={() => {}}
              />
            </Grid>
          </Grid>
        )
      }
      default:
        break
    }
  }

  handleBackSample(e) {
    const { pageName = '' } = this.props
    return this.props.history.push(`/${pageName}`)
  }

  render() {
    const { classes, pageName = '' } = this.props
    return (
      <div className={classes.addSample}>
        <Grid container>
          <Grid
            item
            container
            alignItems={'center'}
            justify={'space-between'}
            spacing={2}
          >
            <Grid item>
              <p className={classes.addSampleIntro} style={styles.header}>
                Add Samples
              </p>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    id="addASample"
                    color="primary"
                    style={{ marginRight: '10px' }}
                    type="button"
                    variant="contained"
                    onClick={(e) => this.handleBackSample(e)}
                  >
                    Back to {pageName === 'myOrders' ? 'my orders' : 'search'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    id="addSamples"
                    disabled={this.disableButton()}
                    onClick={this.duplicateSamples}
                    variant="contained"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {this.renderAddSamplePages()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    checkBoxEnabled: state.addSamples.checkBoxEnabled,
    columnData: state.addSamples.columnData,
    confirmColumnData: state.addSamples.confirmColumnData,
    clickableColumn: state.addSamples.clickableColumn,
    data: state.addSamples.data,
    invalidData: state.addSamples.invalidData,
    numSelected: state.addSamples.numSelected,
    onCheckBoxChange: state.addSamples.onCheckBoxChange,
    onCheckBoxHeaderChange: state.addSamples.onCheckBoxHeaderChange,
    onClick: state.addSamples.onClick,
    selectedData: state.addSamples.selectedData,
    showPopup: state.addSamples.showPopup,
    duplicateSamplesList: state.searchInventory.duplicateSamples,
    barcodeType: state.user.barcodeType,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearSearchCallback: () => {
      dispatch(clearSearchData())
    },
    closePopup: () => {
      dispatch(closePopup())
    },
    fetchData: (tcins = [], dpcis = []) => {
      dispatch(getData(tcins, dpcis))
    },
    getVendorEmailsById: (ids = []) => dispatch(getVendorEmailsById(ids)),
    handleChipClick: () =>
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info')),
    onCheckBoxChange: (selected) => dispatch(setCheckBox(selected)),
    onCheckBoxHeaderChange: (selected) =>
      dispatch(setCheckBoxHeaderChange(selected)),
    setSampleData: (samples) => {
      dispatch(setSampleData(samples))
    },
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    resetSearchState: () => {
      dispatch(resetSearchState())
    },
    updateSampleQuantity: () => {
      dispatch(updateSampleQuantity())
    },
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    duplicateSamples: (samples = [], userId = '') =>
      dispatch(duplicateSamplesAS(samples, userId)),
    autoRefreshSearch: (pending = false) =>
      dispatch(autoRefreshSearch(pending)),
    getDuplicateBarcode: (samples, type) =>
      dispatch(getDuplicateBarcode(samples, type)),
  }
}

AddSample.propTypes = {
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  data: propTypes.array,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onClick: propTypes.func,
}
export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(AddSample)
