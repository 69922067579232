import * as types from './actionType'
import * as ordersAPI from '../../services/ordersService'
import * as OrderMapper from '../../mappers/OrderMapper'
import { resetSearchState } from '../search/actionCreator'
import { resetOrderState } from '../order/actionCreator'
import { resetOrderConfirmationState } from '../orderConfirmation/actionCreator'

export function promoteToOrder(data) {
  return (dispatch) => {
    dispatch(promoteToOrderPending(true))
    return ordersAPI
      .promoteSamplesToOrder(
        data.map((it) => it.pegasusId.substring(2, it.pegasusId.length))
      )
      .then((res) => OrderMapper.samplesResponseToSamples(res))
      .then((samples) => {
        return samples.map((sample) => ({
          ...sample,
          generalDescription:
            data.find((s) => s.pegasusId === sample.pegasusId.toString())
              .generalDescription || '',
        }))
      })
      .then((order) => {
        dispatch(resetSearchState())
        dispatch(resetOrderState())
        dispatch(resetOrderConfirmationState())
        dispatch(promoteToOrderSuccess(order))
        dispatch(promoteToOrderPending(false))
      })
      .catch(() => {
        dispatch(promoteToOrderFailure())
        dispatch(promoteToOrderPending(false))
      })
  }
}

export function promoteToOrderSuccess(data = []) {
  return {
    type: types.ORDER_RECEIPT_PROMOTE_ORDER_SUCCESS,
    payload: { data: data },
  }
}

export function promoteToOrderPending(pending = false) {
  return {
    type: types.ORDER_RECEIPT_PROMOTE_ORDER_PENDING,
    payload: { pending: pending },
  }
}

export function promoteToOrderFailure() {
  return {
    type: types.ORDER_RECEIPT_PROMOTE_ORDER_FAILURE,
  }
}
