export const getShippedScenicRooms = ({ scenicRoom = {} }) => {
  const { shippedScenicRooms = [] } = scenicRoom
  return shippedScenicRooms
}
export const getActiveScenicRooms = ({ scenicRoom = {} }) => {
  const { activeScenicRooms = [] } = scenicRoom
  return activeScenicRooms
}

export const getShipToLocationList = ({ scenicRoom = {} }) => {
  const { shipToLocations = [] } = scenicRoom
  const allLocations = shipToLocations?.map((obj) => {
    return Object.assign({}, obj, {
      value: obj?.location_id ?? '',
      label: obj?.building ?? '',
    })
  })
  return allLocations
}
