export const sampleStatuses = [
  { key: 'SHIPMENT_CREATED', value: 'Shipment Created' },
  { key: 'SHIPMENT_SHIPPED', value: 'Shipment Shipped' },
  { key: 'SHIPMENT_RECEIVED', value: 'Shipment Received' },
  { key: 'SAMPLE_INITIATED', value: 'Sample Initiated' },
  { key: 'SAMPLE_ORDERED', value: 'Sample Ordered' },
  { key: 'SAMPLE_SHIPPED', value: 'Sample Shipped from Vendor' },
  { key: 'SAMPLE_RECEIVED', value: 'Sample Received' },
  { key: 'SAMPLE_CANCELLED', value: 'Sample Cancelled' },
  { key: 'SAMPLE_HOLD', value: 'Held For Photography' },
  { key: 'SAMPLE_SHOOT_SHIPPED', value: 'Shipped for Photography' },
  { key: 'SAMPLE_DISPOSED', value: 'Sample Disposed' },
  { key: 'TEST_STATUS', value: 'Test Sample Status' },
  { key: 'SAMPLE_ARCHIVED', value: 'Sample Archived' },
]

export const requestedSampleStatuses = [
  { key: 'INITIATED', value: 'Initiated' },
  { key: 'READY_FOR_ORDER', value: 'Ready for Order' },
  { key: 'READY_FOR_LAUNCH', value: 'Ready for Launch' },
  // For handling API preformated state
  { key: 'Initiated', value: 'Initiated' },
  { key: 'Ready for Order', value: 'Ready for Order' },
  { key: 'Ready for Launch', value: 'Ready for Launch' },
]

export const userRoles = [
  { key: 'APP-PEG-ADMIN-STG', value: 'ADMIN' },
  { key: 'APP-PEG-ADMIN-PRD', value: 'ADMIN' },
  { key: 'APP-PEG-IDS-STG', value: 'ITEM DATA SPECIALIST' },
  { key: 'APP-PEG-IDS-PRD', value: 'ITEM DATA SPECIALIST' },
  { key: 'APP-PEG-MS-STG', value: 'MERCHANDISING SPECIALIST' },
  { key: 'APP-PEG-MS-PRD', value: 'MERCHANDISING SPECIALIST' },
  { key: 'APP-PEG-SM-STG', value: 'SAMPLE MANAGEMENT' },
  { key: 'APP-PEG-SM-PRD', value: 'SAMPLE MANAGEMENT' },
  { key: 'APP-PEG-MERCH-VENDOR', value: 'MERCHANDISING VENDOR' },
  { key: 'APP-PEG-MKT-VENDOR', value: 'MARKETING VENDOR' },
  { key: 'TEST-USER_ROLE', value: 'TEST-USER' },
  { key: 'APP-PEG_SCENIC_STG', value: 'SCENIC USER' },
  { key: 'APP-PEG_PROPS_STG', value: 'PROPS USER' },
  { key: 'APP-PEG_SCENIC_PRD', value: 'SCENIC USER' },
  { key: 'APP-PEG_PROPS_PRD', value: 'PROPS USER' },
]

export const buildingNames = {
  'Royalston - Samples & Props': 'Roy',
  'Winter Street Samples Props and Workshop': 'Winter St',
  'TP3 - 3 Mock Store A': 'TP3-3',
}

export const partnerTypes = [
  'Stylists',
  'Stylist Assistant',
  'Creative Producer',
  'Production Company',
  'Other Contacts',
]
