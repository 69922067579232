export const ValidationOperation = Object.freeze({
  CHECKIN: 'CHECKIN',
  MOVE_SAMPLES: 'MOVE_SAMPLES',
})

export const ValidationOperationLabels = Object.freeze({
  CHECKIN: 'checked-in',
  MOVE_SAMPLES: 'moved',
})

export const AssignLocationOperationLabels = Object.freeze({
  CHECKIN: 'assigned',
  MOVE_SAMPLES: 'moved',
})
