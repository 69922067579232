import Button from '@material-ui/core/Button/Button'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import * as propTypes from 'prop-types'
import withHOCs from '../../util/withHocs'
import { isEmpty, isEqual } from 'lodash'
import {
  getActiveScenicRoom,
  getArchivedScenicRoom,
  savePendingFilterValue,
  saveShippedFilterValue,
  saveScenicRoomsTabValue,
  saveScenicRoomsTabsAttributes,
  saveUsers,
  getActiveScenicRoomSuccess,
} from '../../store/scenicRoomMain/actionCreator'
import {
  getShippedScenicRooms,
  getActiveScenicRooms,
} from '../../store/scenicRoomMain/selectors'
import {
  createScenicRoom,
  getScenicRoom,
  setRedirectToNewScenicRoom,
} from '../../store/scenicRoomItems/actionCreator'
import {
  setHeaderTitle,
  setHeaderTitleArray,
} from '../../store/layout/actionCreator'
import { userNamesResponseToSelect } from 'mappers/inventoryMapper'
import { fetchScenicMetadata, getAllUsers } from 'services/inventoryService'
import {
  STATUS_ACTIVE,
  STATUS_ARCHIVED,
  defaultScenicRoomsTab,
} from '../../enums/Tabs'
import CustomTabs from '../../components/Tabs/Tabs'
import { Grid } from '@mui/material'
import ScenicRoomFilters from './ScenicRoomFilters'
import ScenicRoomContext from './ScenicRoomContext'
import { getScenicRoomsDataWithMetaDataMapping } from 'mappers/scenicRoomMapper'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'grid',
    overflowX: 'auto',
  },
  pageHeader: {
    width: '100%',
    marginTop: '30px',
    height: '40px',
    position: 'relative',
  },
  pageHeaderText: {
    display: 'inline',
    fontSize: '34px',
    position: 'absolute',
    top: -5,
    left: 0,
  },
  pageHeaderButton: {
    display: 'inline',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  paper: {
    backgroundColor: '#F7F7F7',
  },
  tableHeader: {
    fontSize: 'x-large',
    textAlign: 'left',
  },
  addScenicPagePagination: {
    overflowX: 'auto',
  },
  textField: {
    width: '50%',
  },
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '284px',
    padding: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
  },
})

// Use this when the Praxis Bug that fires ComponentDidMount excessively is fixed
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />
// })

class ScenicRoomMainPage extends React.Component {
  constructor(props) {
    super(props)
    const { tabValue = '', tabBasedAttributes = {} } = props
    const currentTabValue = tabValue ?? defaultScenicRoomsTab ?? ''
    this.state = {
      checkAll: true,
      rawText: '',
      tooltipIsOpen: false,
      currentTabValue: currentTabValue,
      tabBasedAttributes: tabBasedAttributes || {},
      users: [],
      metaData: {},
    }
  }

  async componentDidMount() {
    const { setHeaderTitle = () => {}, setHeaderTitleArray = () => {} } =
      this.props
    setHeaderTitle('Pegasus   >   Scenic Rooms')
    setHeaderTitleArray([{ title: 'Scenic Rooms', link: '/scenicRooms' }])
    await this.fetchData()
    await this.getUsersList()
    await this.getScenicRoomData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentTabValue = '', tabBasedAttributes = {} } = this.state
    const {
      currentTabValue: prevCurrentTabValue = '',
      tabBasedAttributes: preTabBasedAttributes = {},
    } = prevState
    const {
      page = Number(1),
      rowsPerPage = 100,
      selFilterValues = [],
    } = tabBasedAttributes[currentTabValue] || {}

    const {
      page: prevPage = Number(1),
      rowsPerPage: prevRowsPerPage = 100,
      selFilterValues: prevSelFilterValues = [],
    } = preTabBasedAttributes[prevCurrentTabValue] || {}

    if (!isEqual(currentTabValue, prevCurrentTabValue)) {
      this.getScenicRoomData()
    }

    if (
      // TODO: Current TAB
      currentTabValue !== STATUS_ACTIVE &&
      !isEqual(selFilterValues, prevSelFilterValues) &&
      page > 1
    ) {
      this.setState({
        tabBasedAttributes: this.formatTabAttributes('page', Number(1)),
      })
    }

    if (
      !isEqual(prevPage, page) ||
      !isEqual(prevRowsPerPage, rowsPerPage) ||
      (currentTabValue !== STATUS_ACTIVE &&
        !isEqual(selFilterValues, prevSelFilterValues) &&
        page === Number(1))
    ) {
      this.getScenicRoomData()
    }
  }

  getScenicRoomData = () => {
    const { currentTabValue = '', tabBasedAttributes = {} } = this.state
    const {
      page = Number(1),
      rowsPerPage = 100,
      selFilterValues = [],
    } = tabBasedAttributes[currentTabValue] || {}
    const { getActiveScenicRoom = () => {}, getArchivedScenicRoom = () => {} } =
      this.props
    currentTabValue === STATUS_ACTIVE
      ? getActiveScenicRoom()
      : getArchivedScenicRoom({
          status: currentTabValue,
          page,
          rowsPerPage,
          filters: selFilterValues,
        })
  }

  getUsersList = async () => {
    const { saveUsers = () => {} } = this.props
    const usersResponse = await getAllUsers()
    const allUsers = userNamesResponseToSelect(usersResponse)
    this.setState({
      users: allUsers || [],
    })
    saveUsers(allUsers)
  }

  fetchData = async () => {
    try {
      const { data = {} } = await fetchScenicMetadata()
      this.setState({
        metaData: data,
      })
    } catch (e) {
      console.error(e)
    }
  }

  onSelected = (selectedData, selected) => {
    return selectedData.indexOf(selected) !== -1
  }

  handleNewScenicRoom(e) {
    this.props.navigate(`/scenicRoom/create`)
  }

  handleTooltipOpen = () => {
    this.setState({ tooltipIsOpen: !this.state.tooltipIsOpen })
    this.autoCloseTimer = setTimeout(this.handleTooltipClose, 10000)
  }

  handleTooltipClose = () => {
    this.setState({ tooltipIsOpen: false })
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer)
    }
  }

  editActiveScenicRoom(scenicData = {}) {
    const { scenicRoomId = '', status = '' } = scenicData
    if (!isEmpty(String(scenicRoomId))) {
      // TODO: no status
      const subPage = status === 'ARCHIVED' ? 'view' : 'edit'
      this.props.navigate(`/scenicRoom/${scenicRoomId}/${subPage}`)
    }
  }

  saveFilterValue = (filterValue = '') => {
    const { savePendingFilterValue = () => {} } = this.props
    savePendingFilterValue(filterValue)
  }

  saveFilterValueShipped = (filterValue = '') => {
    const { saveShippedFilterValue = () => {} } = this.props
    saveShippedFilterValue(filterValue)
  }

  handleViewDetails = (scenicData) => {
    const { scenicRoomId = '' } = scenicData
    if (!isEmpty(String(scenicRoomId))) {
      this.props.navigate(`/scenicRoom/${scenicRoomId}/view`)
    }
  }

  componentWillUnmount() {
    const {
      saveScenicRoomsTabsAttributes = () => {},
      getActiveScenicRoomSuccess = () => {},
    } = this.props
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer)
    }
    const locationHref = window.location.href
    if (locationHref.indexOf('scenicRoom') === -1) {
      this.saveFilterValue('')
      this.saveFilterValueShipped('')
    }
    const { tabBasedAttributes = {} } = this.state
    saveScenicRoomsTabsAttributes(tabBasedAttributes)
    getActiveScenicRoomSuccess([])
  }

  setCurrentTabValue = (currentTab = '') => {
    const { saveScenicRoomsTabValue = () => {} } = this.props
    this.setState({
      currentTabValue: currentTab,
    })
    saveScenicRoomsTabValue(currentTab)
  }

  setSelectedFilters = (selFilterValues) => {
    this.setState((prevState) => {
      return {
        tabBasedAttributes: this.formatTabAttributes(
          'selFilterValues',
          selFilterValues
        ),
      }
    })
  }

  getTabBasedData = () => {
    const { currentTabValue = '' } = this.state
    const {
      archivedColumnData,
      activeColumnData,
      activeScenicRooms = [],
      shippedScenicRooms = [],
      shippedScenicRoomPending = false,
      activeScenicRoomPending = false,
      filterValueShipped = '',
      filterValue = '',
    } = this.props

    switch (currentTabValue) {
      case STATUS_ACTIVE: {
        return {
          shippedDataFieldKeys: activeColumnData?.map((column) => column.id),
          columnData: activeColumnData,
          scenicRooms: activeScenicRooms,
          showPopupFun: this.editActiveScenicRoom.bind(this),
          rowButtonName: 'OPEN',
          loadingStatus: activeScenicRoomPending,
          saveFilterFun: this.saveFilterValue,
          savedSearchValue: filterValue,
        }
      }
      default:
        return {
          shippedDataFieldKeys: archivedColumnData?.map((column) => column.id),
          columnData: archivedColumnData,
          scenicRooms: activeScenicRooms || shippedScenicRooms, // TODO: remove cases
          showPopupFun: this.handleViewDetails,
          rowButtonName: 'VIEW DETAILS',
          loadingStatus: shippedScenicRoomPending,
          saveFilterFun: this.saveFilterValueShipped,
          savedSearchValue: filterValueShipped,
        }
    }
  }

  formatTabAttributes = (key, value) => {
    const { tabBasedAttributes = {}, currentTabValue } = this.state
    const tabBasedAttributesCopy = Object.assign({}, tabBasedAttributes)
    const previousValue = tabBasedAttributesCopy[currentTabValue]
    tabBasedAttributesCopy[currentTabValue] = {}
    tabBasedAttributesCopy[currentTabValue] = Object.assign({}, previousValue, {
      [key]: value,
    })
    return tabBasedAttributesCopy
  }
  setPageAndRefresh = (page) => {
    this.setState({
      tabBasedAttributes: this.formatTabAttributes('page', page),
    })
  }

  setRowsPerPageAndRefresh = (rowsPerPage) => {
    this.setState({
      tabBasedAttributes: this.formatTabAttributes('rowsPerPage', rowsPerPage),
    })
  }

  renderTabsContent = () => {
    const { onClick, selectedData, totalCount = Number(0) } = this.props
    const {
      currentTabValue = '',
      tabBasedAttributes = {},
      metaData = {},
      allUsers = [],
    } = this.state
    const { page = Number(1), rowsPerPage = 100 } =
      tabBasedAttributes[currentTabValue] || {}
    const {
      columnData = [],
      shippedDataFieldKeys = [],
      scenicRooms = [],
      showPopupFun = () => {},
      rowButtonName = '',
      loadingStatus = '',
      saveFilterFun = () => {},
      savedSearchValue = '',
    } = this.getTabBasedData()

    const scenicRoomsDataWithMetaDataMapping =
      getScenicRoomsDataWithMetaDataMapping(scenicRooms, metaData, allUsers)

    return (
      <Grid container>
        <Grid container item style={{ marginTop: 20, marginLeft: 20 }}>
          {currentTabValue !== STATUS_ACTIVE && <ScenicRoomFilters />}
        </Grid>
        <Grid item xs={12}>
          <FlexibleTable
            cascadeFieldKeys={[]}
            checkBoxEnabled={false}
            columnData={columnData}
            data={[...scenicRoomsDataWithMetaDataMapping]}
            dataFieldKeys={shippedDataFieldKeys}
            editEnabled={false}
            enableSearch
            enableScrolls
            onClick={onClick}
            selected={selectedData}
            showPopup={showPopupFun}
            disableItemCount={true}
            openOptions
            showAllRows
            isAllRowsRequired
            paginationAtApiLevel={
              currentTabValue !== STATUS_ACTIVE ? true : false
            }
            openButtonName={rowButtonName}
            isLoading={loadingStatus}
            saveFilterValue={saveFilterFun}
            savedSearchValue={savedSearchValue}
            enableMultiSearch
            page={page}
            rowsPerPage={rowsPerPage}
            totalRowsCount={totalCount}
            setPage={this.setPageAndRefresh}
            setRowsPerPage={this.setRowsPerPageAndRefresh}
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes, shipToLocations = [] } = this.props
    const {
      currentTabValue = '',
      tabBasedAttributes = {},
      users = [],
    } = this.state
    const { selFilterValues } = tabBasedAttributes[currentTabValue] || {}
    return (
      <div style={{ margin: '0px 28px' }}>
        <div className={classes.pageHeader}>
          <div className={classes.pageHeaderText}>
            <span>Scenic Rooms</span>
          </div>
          <div className={classes.pageHeaderButton}>
            <Button
              id="newScenicRoom"
              color="primary"
              style={{ width: '182px' }}
              type="button"
              variant="contained"
              onClick={(e) => this.handleNewScenicRoom(e)}
            >
              Add Room
            </Button>
          </div>
        </div>
        <Paper className={classes.root}>
          <ScenicRoomContext.Provider
            value={{
              currentTabValue,
              filtersOptions: {
                locations: shipToLocations,
                users,
              },
              filterValues: selFilterValues,
              setSelectedFilters: this.setSelectedFilters,
            }}
          >
            <CustomTabs
              tabLabels={[
                // TODO: { label: STATUS_CURRENT, value: STATUS_CURRENT },
                { label: STATUS_ACTIVE, value: STATUS_ACTIVE },
                { label: STATUS_ARCHIVED, value: STATUS_ARCHIVED },
              ]}
              renderTabsContent={this.renderTabsContent}
              setCurrentTabValue={this.setCurrentTabValue}
              // defaultTab={STATUS_CURRENT}
              defaultTab={this.state?.currentTabValue || STATUS_ACTIVE}
              showTabsPanel={false}
            />
          </ScenicRoomContext.Provider>
        </Paper>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    scenicRoom: {
      numSelected = Number(0),
      onClick = () => {},
      activeColumnData = [],
      archivedColumnData = [],
      selectedData = [],
      shippedScenicRoomPending = false,
      activeScenicRoomPending = false,
      filterValue = '',
      filterValueShipped = '',
      tabValue = '',
      totalCount = Number(0),
      tabBasedAttributes = {},
    },
  } = state
  return {
    numSelected,
    onClick: onClick,
    activeColumnData,
    activeScenicRooms: getActiveScenicRooms(state),
    archivedColumnData,
    shippedScenicRooms: getShippedScenicRooms(state),
    selectedData,
    shippedScenicRoomPending,
    activeScenicRoomPending,
    filterValue,
    filterValueShipped,
    tabValue,
    totalCount,
    tabBasedAttributes,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getActiveScenicRoom: () => dispatch(getActiveScenicRoom()),
    getArchivedScenicRoom: (payload = {}) =>
      dispatch(getArchivedScenicRoom(payload)),
    createScenicRoom: () => dispatch(createScenicRoom()),
    getScenicRoom: (data) => dispatch(getScenicRoom(data)),
    setHeaderTitle: (headerTitle) => dispatch(setHeaderTitle(headerTitle)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setRedirectToNewScenicRoom: (flag) =>
      dispatch(setRedirectToNewScenicRoom(flag)),
    savePendingFilterValue: (filterValue = '') =>
      dispatch(savePendingFilterValue(filterValue)),
    saveShippedFilterValue: (filterValue = '') =>
      dispatch(saveShippedFilterValue(filterValue)),
    saveScenicRoomsTabValue: (tabValue = '') =>
      dispatch(saveScenicRoomsTabValue(tabValue)),
    saveScenicRoomsTabsAttributes: (tabBasedAttributes = {}) =>
      dispatch(saveScenicRoomsTabsAttributes(tabBasedAttributes)),
    saveUsers: (users = []) => dispatch(saveUsers(users)),
    getActiveScenicRoomSuccess: (payload = []) =>
      dispatch(getActiveScenicRoomSuccess(payload)),
  }
}

ScenicRoomMainPage.propTypes = {
  data: propTypes.array,
  numSelected: propTypes.number,
  onClick: propTypes.func,
  activeColumnData: propTypes.array,
  archivedColumnData: propTypes.array,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(ScenicRoomMainPage)
