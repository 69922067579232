import { Accordion, AccordionDetails, Divider } from '@material-ui/core'
import ScenicRoomReadOnlyList from './ScenicRoomReadOnlyList'
import { STATUS_ARCHIVED } from 'enums/Tabs'

const ViewScenicRoomAccordion = ({
  classes,
  scenicRoomData,
  isRoomReadOnly = false,
  setEditScenicRoom,
  scenicRoomAccordionSummary,
}) => {
  const fieldList = getFieldList(scenicRoomData)
  return (
    <Accordion defaultExpanded>
      {scenicRoomAccordionSummary}
      <Divider />

      <AccordionDetails style={{ padding: '25px' }}>
        <ScenicRoomReadOnlyList
          fieldList={fieldList}
          classes={classes}
          isRoomReadOnly={isRoomReadOnly}
          setEditScenicRoom={setEditScenicRoom}
          isArchivedRoom={scenicRoomData.status === STATUS_ARCHIVED}
        />
      </AccordionDetails>
    </Accordion>
  )
}

const getFieldList = (scenicRoomData) => {
  return [
    {
      lable: 'Room Name',
      value: scenicRoomData.roomName,
    },
    // { lable: 'Room ID', value: scenicRoomData.scenicRoomId },
    { lable: 'Category', value: scenicRoomData.roomCategory },
    {
      lable: 'Sub Category',
      value: scenicRoomData.roomSubCategory,
    },
    { lable: 'TotalCost', value: scenicRoomData.totalCost },
    { lable: 'Source', value: scenicRoomData.source },
    { lable: 'Created On', value: scenicRoomData.createdOn },
    { lable: 'Update Date', value: scenicRoomData.updateDate },
    { lable: 'Room Notes', value: scenicRoomData.notes },
    { lable: 'Room Image', value: scenicRoomData.roomImageUrl, type: 'image' },
    { lable: 'Target Brand', value: scenicRoomData.scenicBrand },
  ]
}

export default ViewScenicRoomAccordion
