import * as types from './actionType'
import * as inventoryApi from '../../services/inventoryService'
import { first, cloneDeep } from 'lodash'
import mime from 'mime'
import { showNotification } from 'store/notification/actionCreator'
import { SCENIC, SAMPLES } from 'enums/Tabs'

export function callFetchDataSuccess(createPropByKeyName = '') {
  return (dispatch, getState) => {
    const currentState = getState() || {}
    const {
      searchInventory: { data: inventoryData = [] },
    } = currentState
    let currentStateData = inventoryData || []
    if (createPropByKeyName === 'tcin') {
      const {
        search: { data = [] },
      } = currentState
      currentStateData = [...data]
    }
    dispatch(fetchDataSuccess(currentStateData))
  }
}

export function fetchDataSuccess(data = []) {
  const dataCopy = cloneDeep(data)
  const newDataCopy = dataCopy?.map((obj) => {
    const itemBrand = obj?.manufacturerBrand || ''
    obj['propDescription'] = obj?.propDescription || obj?.description || ''
    obj['propBrand'] = obj?.propBrand
      ? obj?.propBrand
      : itemBrand
      ? {
          label: itemBrand,
          id: itemBrand,
          value: itemBrand,
          displayName: itemBrand,
        }
      : null
    return obj
  })
  return {
    type: types.ADDPROP_FETCH_DATA_SUCCESS,
    payload: { data: [...newDataCopy] },
  }
}

export function addPropClearData() {
  return {
    type: types.ADDPROP_CLEAR_DATA,
  }
}

export function setCheckBox(selected, compareId = '') {
  return {
    type: types.ADDPROP_SEARCH_SET_CHECKBOX,
    payload: { selected: selected, compareId },
  }
}

export function setCheckBoxHeaderChange(selected) {
  return {
    type: types.ADDPROP_SEARCH_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}

export const setCurPropImages =
  (sampleImages = [], pegasusId = '') =>
  (dispatch) => {
    if (pegasusId) {
      sampleImages?.forEach(async function (singleImage) {
        const { image_url = '', isNotDeleted } = singleImage
        const fileName = image_url?.substring(
          image_url?.lastIndexOf('/') + 1,
          image_url?.length
        )
        await dispatch(
          setFilesUploadProgress(
            fileName,
            pegasusId,
            isNotDeleted ? 'nodelete' : 'completed',
            image_url
          )
        )
      })
    }
  }

export const uploadTCINImage =
  ({ imageUrl = '', tcin = '', pegasusId = '', callBack = () => {} }) =>
  (dispatch) => {
    inventoryApi
      .downloadImage(imageUrl)
      .then(async (response) => {
        const contentType = response.headers.get('content-type')

        const blob = await response.blob()
        const fileExtension = mime.getExtension(contentType)
        const fileName = `${tcin}.${fileExtension}`
        const file = new File([blob], fileName, { contentType })
        pegasusId &&
          (await dispatch(
            addPropsImageUpload({
              fileToUpload: file,
              pegasusId: pegasusId,
              callBack: callBack,
              callFrom: 'TCINIMAGE',
            })
          ))
      })
      .catch((error) => {
        dispatch(setAddPropState('PROPCREATEDPARTIALLY'))
        callBack({ error: 'Encountered an error while adding an image' })
      })
  }
export const uploadDuplicateImage =
  ({ imageUrl = [], tcin = '', pegasusId = '', callBack = () => {} }) =>
  (dispatch) => {
    imageUrl.map((imageUrlInstance) => {
      inventoryApi
        .downloadImage(imageUrlInstance?.image_url)
        .then(async (response) => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const fileExtension = mime.getExtension(contentType)
          const fileName =
            pegasusId && `${pegasusId}-${Math.random()}.${fileExtension}`
          const file = new File([blob], fileName, { contentType })
          pegasusId &&
            (await dispatch(
              addPropsImageUpload({
                fileToUpload: file,
                pegasusId: pegasusId,
                callBack: callBack,
                callFrom: 'TCINIMAGE',
              })
            ))
        })
        .catch((error) => {
          dispatch(setAddPropState('PROPCREATEDPARTIALLY'))
          callBack({ error: 'Encountered an error while adding an image' })
        })
      return {}
    })
  }

export const startUploadPropImages =
  (uploadFiles = [], pegasusId = '', sampleType = SAMPLES) =>
  (dispatch) => {
    if (pegasusId) {
      uploadFiles?.forEach(async function (singleFile) {
        await dispatch(
          addPropsImageUpload({
            fileToUpload: singleFile,
            pegasusId: pegasusId,
            sampleType: sampleType,
          })
        )
      })
    }
  }

export const addPropsImageUpload = ({
  fileToUpload,
  pegasusId = '',
  callBack = () => {},
  callFrom = '',
  sampleType = SAMPLES,
}) => {
  const { name: fileName = '' } = fileToUpload
  return async (dispatch) => {
    dispatch(setFilesUploadProgress(fileName, pegasusId, 'inprogress'))
    return await inventoryApi
      .uploadPropImage(fileToUpload, pegasusId, sampleType)
      .then(async (response = {}) => {
        const {
          data: { image_urls = [] },
        } = response
        const { image_url = '' } = first(image_urls)
        await dispatch(
          setFilesUploadProgress(fileName, pegasusId, 'completed', image_url)
        )
        await dispatch(
          setAddPropState(
            callFrom ? 'UPLOADTCINIMAGESUCCESS' : 'UPLOADIMAGESUCCESS'
          )
        )
        await callBack({
          pegasusId,
          sample_type: sampleType === SCENIC ? 'Scenic ID' : 'Prop ID',
        })
      })
      .catch((error = {}) => {
        dispatch(setFilesUploadProgress(fileName, pegasusId, 'failed'))
        dispatch(setAddPropState('PROPCREATEDPARTIALLY'))
        callBack({ error: 'Encountered an error while adding an image' })
      })
  }
}

export const deletePropImages = (params = {}, fileNames = []) => {
  const { pegasus_id: pegasusId = '' } = params
  return async (dispatch) => {
    return await inventoryApi
      .deleteImage(params)
      .then((response = {}) => {
        dispatch(removeFileFromUploadList(fileNames, pegasusId))
      })
      .catch((error = {}) => {
        showNotification(true, 'Encountered an error while deleting an image')
      })
  }
}

export function setFilesUploadProgress(
  fileName = '',
  pegasusId = '',
  status = '',
  imageUrl = ''
) {
  return {
    type: types.ADDPROP_FILE_UPLOAD_PROGRESS,
    payload: {
      fileName: fileName,
      pegasusId: pegasusId,
      status: status,
      imageUrl: imageUrl,
    },
  }
}

export function removeFileFromUploadList(fileNames = '', pegasusId = '') {
  return {
    type: types.ADDPROP_REMOVE_FILE_FROM_UPLOADLIST,
    payload: {
      fileNames: fileNames,
      pegasusId: pegasusId,
    },
  }
}

export function resetAddPropData() {
  return {
    type: types.RESET_ADDPROP_DATA,
  }
}

export function setAddPropState(propState = '') {
  return {
    type: types.ADD_PROP_STATE,
    payload: {
      propState,
    },
  }
}
