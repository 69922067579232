import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { isEmpty, pick, invertBy } from 'lodash'
import {
  Grid,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  Divider,
  Tooltip,
  Link,
  MenuItem,
  ListItemIcon,
  ListSubheader,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MakePopupLists from '../../util/Lists'
import CustomDropdown from '../../util/CustomDropdown'
import PersonIcon from '@material-ui/icons/Person'
import {
  searchByOptions,
  PER_PAGE_CONST,
  displayAdditionalData,
  additionalDataNames,
  changeTypeConsts,
  filtersByOptions,
  filterMomentsLabel,
  hrefPagesList,
  changeTypePrimaryText,
  changeTypePropText,
} from '../../util/HistoryPage'
import {
  getHistoryData,
  getHistoryFilters,
} from '../../store/searchInventory/actionCreator'

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 12,
  },
  popper: {
    zIndex: 3301,
  },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: 'white',
    height: 'auto',
    backgroundColor: '#666',
    borderRadius: '5px',
  },
  chipLabel: {
    maxWidth: '490px',
    // overflow: 'unset !important',
    // whiteSpace: 'normal !important',
  },
  listAvatar: {
    minWidth: 24,
    marginRight: 16,
    '& > svg': {
      color: '#666',
      height: 24,
      width: 24,
    },
  },
  listItemText: {
    fontSize: 14,
  },
  divider: {
    marginLeft: 0,
  },
  primaryText: {
    fontSize: '14px',
    color: '#666',
  },
  primaryTextPH: {
    fontWeight: '700',
  },
  primaryTextEmail: {
    textDecoration: 'unset',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'unset',
    },
  },
  secondaryTextHref: {
    // textDecoration: 'unset',
    color: '#fff',
    '&:hover': {
      // textDecoration: 'unset',
    },
  },
  iconClass: {
    minWidth: '36px !important',
  },
  menuRoot: {
    fontSize: '14px',
    color: '#666',
  },
  strickyHeader: {
    top: '0px',
    left: '0px',
    position: 'sticky',
    background: 'white',
    zIndex: 2,
  },
}))

export default function HistoryPage({ sampleData = {}, pageName = '' }) {
  const itemCopy = Object.assign({}, sampleData)
  const classes = useStyles()
  const { sampleId = '', pegasusId = '' } = itemCopy
  const dispatch = useDispatch()
  const [sortOrder, setSortOrder] = useState('DESC')
  const [filterByAPI, setFilterByAPI] = useState({})
  const [filterBy, setFilterBy] = useState('none')
  const [page] = useState(Number(0))
  const [perPage, setPerPage] = useState(Number(PER_PAGE_CONST))
  const [payload, setPayload] = useState({})
  const [usersInHistory, setUsersInHistory] = useState([])
  const [historyMoments, setHistoryMoments] = useState({})

  const {
    items: historyDataList = [],
    total_count = 0,
    filterUsers = [],
    filterMoments = [],
  } = useSelector(
    ({
      history: {
        historyData: { items, total_count } = {},
        historyFilters: {
          users: filterUsers = [],
          moments: filterMoments = [],
        } = {},
      },
    }) => {
      return {
        items,
        total_count,
        filterUsers,
        filterMoments,
      }
    }
  )
  useEffect(() => {
    return function cleanup() {
      setSortOrder('DESC')
      setPerPage(Number(PER_PAGE_CONST))
      setFilterByAPI({})
      setFilterBy('none')
      setUsersInHistory([])
      setHistoryMoments({})
    }
  }, [])

  useEffect(() => {
    if ((filterMoments || []).length > 0) {
      const filterMomentsPicks = pick(filterMomentsLabel, filterMoments)
      const filterMomentsRefs = invertBy(filterMomentsPicks || {})
      const filterMomentData = Object.keys(filterMomentsRefs).map((obj) => {
        let obj1 = filtersByOptions[obj] || {}
        obj1['value'] = filterMomentsRefs[obj]
        return obj1
      })
      setHistoryMoments(filterMomentData)
    }
  }, [filterMoments])

  useEffect(() => {
    if (!isEmpty(String(sampleId)) && pageName !== 'searchArchivedSamples') {
      dispatch(
        getHistoryFilters({
          sampleId: sampleId,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleId, dispatch])

  useEffect(() => {
    if (filterUsers.length > 0) {
      const finalUsersList = filterUsers.filter((obj) => !isEmpty(obj))
      setUsersInHistory(finalUsersList)
    }
  }, [filterUsers])

  useEffect(() => {
    setPayload(
      Object.assign(
        {},
        {
          page: page,
          perPage: perPage,
          sortOrder: sortOrder,
          sampleId: sampleId,
        },
        filterByAPI
      )
    )
  }, [sortOrder, perPage, page, sampleId, filterByAPI])

  useEffect(() => {
    if (
      Object.entries(payload).length > 0 &&
      pageName !== 'searchArchivedSamples'
    ) {
      const {
        sortOrder: sort_dir = 'ASC',
        perPage: per_page = PER_PAGE_CONST,
        ...restParams
      } = payload
      dispatch(
        getHistoryData({
          sort_dir,
          per_page,
          ...restParams,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, dispatch])

  const onSeemoreClick = () => {
    setPerPage(Number(perPage) + PER_PAGE_CONST)
  }

  const onSortChange = (event) => {
    setSortOrder(event.target.value)
  }
  const onFilterChange = (event) => {
    const {
      currentTarget: {
        dataset: { name: filterType = '' },
      },
      target: { value: filterValue = 'none' },
    } = event
    setFilterByAPI({ [filterType]: filterValue })
    setFilterBy(filterValue)
  }

  const createFilterMenu = (
    options = {},
    usersInHistory = [],
    propsClasses
  ) => {
    let str = []
    str.push(
      <MenuItem
        key={Number(0)}
        data-value={'none'}
        value={'none'}
        classes={{ root: propsClasses.menuRoot }}
      >
        None
      </MenuItem>
    )
    str.push(createMomentsFilters(options, propsClasses))
    str.push(createUsersFilters(usersInHistory, propsClasses))
    return str
  }
  const createUsersFilters = (usersInHistory = [], propsClasses) => {
    let str = []
    if (usersInHistory.length > 0) {
      str.push(<ListSubheader>PEOPLE</ListSubheader>)
      const newArr = [...new Set(usersInHistory.map((obj) => obj.user_id))].map(
        (obj) => {
          return usersInHistory.find((obj1) => obj1.user_id === obj)
        }
      )
      newArr.map((obj = {}) => {
        const { user_id: userZID = '' } = obj
        const userDisplayName = getUserDisplayname(obj || {})
        str.push(
          <MenuItem
            key={userZID}
            value={userZID}
            data-name="users"
            classes={{ root: propsClasses.menuRoot }}
          >
            <ListItemIcon classes={{ root: classes.iconClass }}>
              <PersonIcon />
            </ListItemIcon>
            {userDisplayName}
          </MenuItem>
        )
        return obj
      })
    }
    return str
  }
  const createMomentsFilters = (options = {}, propsClasses) => {
    let str = []
    if (Object.entries(options).length > 0) {
      str.push(<ListSubheader>MOMENTS</ListSubheader>)
      Object.keys(options).map((key, index) => {
        const keyindex = index + 1
        const keyValue = (options[key].value || []).join(',')
        str.push(
          <MenuItem
            key={keyindex}
            value={keyValue}
            classes={{ root: propsClasses.menuRoot }}
            data-name="moments"
          >
            <ListItemIcon classes={{ root: propsClasses.iconClass }}>
              {options[key].icon}
            </ListItemIcon>
            {options[key].label}
          </MenuItem>
        )
        return key
      })
    }
    return str
  }

  const getCurrentTypeName = (changeType = '') => {
    return (
      Object.keys(changeTypeConsts).find(
        (obj) => changeType.indexOf(obj) !== -1
      ) || ''
    )
  }
  const getChangeTypeConsts = (changeType = '') => {
    const currentChangeType =
      Object.keys(changeTypeConsts).find(
        (obj) => changeType.indexOf(obj) !== -1
      ) || ''
    return changeTypeConsts[currentChangeType] || {}
  }
  const getChangeTypeName = (changeType = '', changedTypeAction = '') => {
    const { displayNameList = {} } = getChangeTypeConsts(changeType)
    const changeTypeName =
      displayNameList[changedTypeAction] ||
      displayNameList[changeType] ||
      displayNameList['DEFAULT'] ||
      ''
    return changeTypeName
  }
  const getChangeTypeHref = (changeType = '') => {
    const { displayHrefName = {} } = getChangeTypeConsts(changeType)
    const changeTypeName =
      displayHrefName[changeType] || displayHrefName['DEFAULT'] || ''
    return changeTypeName
  }
  const getChangeTypeIcon = (changeType = '', changedTypeAction = '') => {
    const { displayIconList = {} } = getChangeTypeConsts(changeType)
    const changeTypeIcon =
      displayIconList[changedTypeAction] || displayIconList['DEFAULT'] || ''
    return changeTypeIcon
  }
  const getAddDataFieldString = (
    objData = '',
    objValue = '',
    changedTypeAction = ''
  ) => {
    if (
      ['SAMPLE_SHOOT_SHIPPED', 'SAMPLE_RECEIVED'].indexOf(changedTypeAction) !==
        -1 &&
      objData === 'ship_to_loc'
    ) {
      objData = 'ship_to_loc_shipped'
    }
    const isExistsInNames = Object.keys(additionalDataNames).some(
      (obj) => obj === objData
    )
    const dataString = isExistsInNames
      ? `${additionalDataNames[objData] || ''}${objValue}`
      : objValue
    return dataString
  }
  const getCurrentActionData = (
    changedTypeAction = '',
    additional_data = {},
    changeTypeName = ''
  ) => {
    const { displayHrefName = {} } = getChangeTypeConsts(changeTypeName)
    const isExistsInHref = Object.keys(displayHrefName).some(
      (obj) => obj === changedTypeAction
    )
    const displayActionData = displayAdditionalData[changedTypeAction] || []
    return (
      Object.keys(additional_data)
        .filter((item) => displayActionData.indexOf(item) !== -1)
        .map((obj) =>
          isExistsInHref
            ? getChangeTypeDataStr(
                obj,
                getAddDataFieldString(
                  obj,
                  additional_data[obj],
                  changedTypeAction
                ),
                additional_data
              )
            : getAddDataFieldString(
                obj,
                additional_data[obj],
                changedTypeAction
              )
        ) || []
    )
  }

  const getDateString = (dateString = '') => {
    return dateString
      ? moment.utc(dateString).local().format('MMM DD, YYYY h:mm a')
      : ''
  }

  const getPrimaryText = (
    userEmail = '',
    userDisplayName = '',
    changedType = '',
    changedAt = ''
  ) => {
    const mailtohref = userEmail
      ? `mailto:${userEmail}`
      : 'mailto:TargetTech-Pegasus@target.com'
    const mailtoSubject = pegasusId ? `subject=Regarding ${pegasusId}` : ''
    const changeTypeName = getChangeTypeName(changedType)
    const changeAtString = getDateString(changedAt)
    const linkHrefStr = `${mailtohref}?${mailtoSubject}`
    const changeTypePrimaryStr = changeTypePrimaryText[changedType]
    const changeTypePropsStr = changeTypePropText[changedType]
    return (
      <div className={classes.primaryText}>
        <Link
          href={linkHrefStr}
          className={classes.primaryTextEmail}
          color="primary"
        >
          {userDisplayName !== 'Account' ? userDisplayName : 'Pegasus Admin'}
        </Link>
        <span>
          {changeTypePropsStr ? (
            <>
              {`  `}
              <span>{changeTypeName}</span>
              {` on `}
            </>
          ) : changeTypePrimaryStr ? (
            <>
              {` ${changeTypePrimaryStr} `}
              <span className={classes.primaryTextPH}>{changeTypeName}</span>
              {` on `}
            </>
          ) : (
            <>
              {` changed the `}{' '}
              <span className={classes.primaryTextPH}>{changeTypeName}</span>
              {` on `}
            </>
          )}
          <span className={classes.primaryTextPH}>{changeAtString}</span>
        </span>
      </div>
    )
  }
  const getAddDataString = (dataFieldArr = []) => {
    const dataFieldString = dataFieldArr.map((obj) => {
      return (
        <CustomTooltip title={(obj || '').length > 50 ? obj : ''}>
          <Chip
            label={obj}
            className={classes.chip}
            classes={{
              label: classes.chipLabel,
            }}
          />
        </CustomTooltip>
      )
    })
    return dataFieldString
  }
  const getIsExistSecText = (changeTypeName) => {
    const { displaySecondaryText = false } = getChangeTypeConsts(changeTypeName)
    return displaySecondaryText
  }
  const getIsExistAddFields = (changeTypeName) => {
    const { displayAddtionData = false } = getChangeTypeConsts(changeTypeName)
    return displayAddtionData
  }
  /* 
  changeTypeHref - Href Path
  changedTypeAction - Display Label
  additional_data - Additional data of ChangeType(moment)
  */
  const getChangeTypeDataStr = (
    changeTypeHref,
    changedTypeAction = '',
    additional_data
  ) => {
    let dataStr = changedTypeAction
    const changeTypeHrefAD = additional_data[changeTypeHref] || ''
    const hrefPageName = hrefPagesList[changeTypeHref] || ''
    if (
      !isEmpty(changeTypeHref) &&
      !isEmpty(String(changeTypeHrefAD)) &&
      !isEmpty(String(hrefPageName))
    ) {
      dataStr = (
        <Link
          href={`/${hrefPageName}/${changeTypeHrefAD}/view`}
          className={classes.secondaryTextHref}
          color="primary"
          target="_blank"
          underline="always"
        >
          {changedTypeAction}
        </Link>
      )
    }
    return dataStr
  }

  const getUserDisplayname = (userObj = {}, vendorName = '') => {
    const {
      first_name: userFirstname = '',
      last_name: userLastname = '',
      user_id: userZID = '',
    } = userObj
    const userDPName = [userFirstname, userLastname]
      .filter((obj) => obj)
      .join(' ')
    const userDisplayName = userDPName || `${userZID}` || 'Pegasus User'
    const finalDisplayName = isEmpty(vendorName)
      ? userDisplayName
      : `${userDisplayName + '(' + vendorName + ')'}`
    return finalDisplayName
  }

  const renderHistoryData = (dataList = []) => {
    const dataListRenderStr =
      dataList.length > 0 ? (
        dataList.map((mainObj) => {
          const {
            user: userObj = {},
            timestamp: changedAt = '',
            change_type: changeTypeList = [],
            history_data = {},
          } = mainObj
          const { additional_data = {} } = history_data
          const { email: userEmail = '' } = userObj || {}
          const { vendor_name = '' } = additional_data
          const userDisplayName = getUserDisplayname(userObj || {}, vendor_name)
          return changeTypeList.map((changedType = '') => {
            const changeTypeDataValue =
              history_data[changedType.toLowerCase()] || ''
            const isLocationType = changedType?.toLowerCase() === 'location'
            let changedTypeAction = changeTypeDataValue
            if (isLocationType) {
              const locationBuilding = changeTypeDataValue?.building
              changedTypeAction = locationBuilding ?? changeTypeDataValue
            }
            const changeTypeName = getCurrentTypeName(changedType)
            const isExistSecText = getIsExistSecText(changeTypeName)
            const isExistAddFields = getIsExistAddFields(changeTypeName)
            const additionalData = getCurrentActionData(
              changedTypeAction,
              additional_data,
              changeTypeName
            )
            const changeTypeHref = getChangeTypeHref(changeTypeName)
            const changeTypeDataStr = getChangeTypeDataStr(
              changeTypeHref,
              changedTypeAction,
              additional_data
            )
            return (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar className={classes.listAvatar}>
                    {getChangeTypeIcon(changedType, changedTypeAction)}
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography={true}
                    className={classes.listItemText}
                    primary={getPrimaryText(
                      userEmail,
                      userDisplayName,
                      changedType,
                      changedAt
                    )}
                    secondary={
                      !!isExistSecText && (
                        <React.Fragment>
                          {getAddDataString([
                            !isExistAddFields
                              ? changeTypeDataStr || changedTypeAction
                              : getChangeTypeName(
                                  changedType,
                                  changedTypeAction
                                ),
                          ])}
                          {!!isExistAddFields &&
                            getAddDataString(additionalData)}
                        </React.Fragment>
                      )
                    }
                  />
                </ListItem>
                <Divider
                  variant="inset"
                  component="li"
                  className={classes.divider}
                />
              </>
            )
          })
        })
      ) : (
        <ListItem alignItems="flex-start">
          <ListItemText className={classes.primaryText}>
            <div className={classes.primaryTextPH}>
              {filterBy !== 'none'
                ? `There are no history results for this filter ${filterBy}`
                : 'There are no history results'}
            </div>
          </ListItemText>
        </ListItem>
      )
    return dataListRenderStr
  }

  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item className={classes.strickyHeader}>
        <Grid container spacing={2} justify={'space-between'}>
          <Grid item>
            <CustomDropdown
              options={searchByOptions}
              handleChange={onSortChange}
              value={sortOrder}
              variantType={'outlined'}
              id={'historySortBy'}
              labelId={'Sort'}
              isDisabled={!((historyDataList || []).length > 0)}
            />
          </Grid>
          <Grid item>
            <CustomDropdown
              options={filtersByOptions}
              handleChange={onFilterChange}
              value={filterBy}
              variantType={'outlined'}
              id={'historyFilterBy'}
              labelId={'Filter By'}
              isChildExists={true}
              children={createFilterMenu(
                historyMoments,
                usersInHistory,
                classes
              )}
              isDisabled={!((historyDataList || []).length > 0)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{MakePopupLists(renderHistoryData(historyDataList))}</Grid>
      {total_count >= perPage && (
        <Grid item container justify={'center'}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            onClick={onSeemoreClick}
            startIcon={<KeyboardArrowDownIcon />}
          >
            see more
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
