/**
 * resolveDuplicate
 * Handles the resolve duplicate flow.
 * @param pegasusId
 * @param currentCard
 * @param relatedSamples
 * @param cloneSample
 * @param removeSample
 * @param addToPrintQueue
 * @returns {{nodes: {findMatchNode: {nextNode: string, label: string, content: {variant: string, options: *[], required: boolean, samples: *}}, mismatchNode: {label: string, content: {}}, matchingNode: {nextNode: string, label: string, content: {variant: string, options: *[], description: *, required: boolean}}, cloneSampleNode: {nextNode: string, label: string, content: {variant: string, description: string, action: (function(): string)}}, printNode: {label: string, content: {variant: string, description: string, action: (function(*): void)}}}, startNode: string}}
 */
import ReactHtmlParser from 'react-html-parser'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { ValidationOperation } from '../../enums/validationOperations'

export function resolveDuplicate(
  pegasusId,
  currentCard,
  relatedSamples,
  cloneSample,
  removeSample,
  addToPrintQueue
) {
  return {
    activeSample: pegasusId,
    startNode: 'matchingNode',
    nodes: {
      // decide if system can handle resolving flow
      matchingNode: {
        label: 'Resolve Duplicate',
        content: {
          variant: 'SimpleContent',
          description: (
            <Grid
              container
              direction={'row'}
              justify={'center'}
              alignItems={'center'}
              spacing={2}
            >
              <Grid item xs={8}>
                <Typography variant={'body1'}>
                  Does the item description match?
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {currentCard}
              </Grid>
            </Grid>
          ),
        },
        secondaryAction: {
          label: 'No',
          node: 'mismatchNode',
        },
        next: {
          label: 'Yes',
          node: 'findMatchNode',
        },
      },
      // remove the sample from the checkin flow
      mismatchNode: {
        label: 'Remove Item and Contact Vendor',
        content: {
          variant: 'SimpleContent',
          description: (
            <Typography variant={'body1'}>
              This sample will be removed from your check-in list. To resolve
              this issue contact: Order Contact:
            </Typography>
          ),
        },
        next: {
          label: 'Remove Sample',
          node: 'done',
          action: () => {
            removeSample(pegasusId)
          },
        },
      },
      /**
       * ask user to select correct match
       * if match found, update sample id
       * else generate a new sample
       */
      findMatchNode: {
        label: 'Select the Correct Label for this Sample:',
        content: {
          variant: 'CardSelection',
          required: true,
          options: [
            { label: 'notFound', value: 'cloneSampleNode' },
            { label: 'found', value: 'printNode' },
          ],
          samples: relatedSamples,
        },
        secondaryAction: {
          label: 'No Match Found',
          node: 'cloneSampleNode',
        },
        next: {
          label: 'print updated label',
          node: 'printNode',
        },
      },
      // clone the sample and generate a new id
      cloneSampleNode: {
        label: 'Resolve Duplicate',
        content: {
          variant: 'SimpleContent',
          description: (
            <Typography variant={'body1'}>
              Would you like to make a new label for this sample?
            </Typography>
          ),
        },
        secondaryAction: {
          label: 'No, Remove Sample',
          node: 'done',
          action: () => {
            removeSample(pegasusId)
          },
        },
        next: {
          label: 'Yes',
          node: 'printNode',
          action: cloneSample,
        },
      },
      // add the pegasus id to the print queue
      printNode: {
        label: 'Add to Print Queue',
        content: {
          variant: 'SimpleContent',
          description: (
            <Typography variant={'body1'}>
              New label will be added to print queue
            </Typography>
          ),
        },
        next: {
          label: 'Add to Print Queue',
          node: 'done',
          action: (value) => {
            addToPrintQueue(value)
            return [value]
          },
        },
      },
    },
  }
}

export function resolveLocation(
  pegasusId,
  intendedLocation,
  currentLocation,
  operation,
  removeSample,
  updateLocation
) {
  return {
    activeSample: pegasusId,
    startNode: 'resolveLocation',
    nodes: {
      resolveLocation: {
        label: 'Resolve Location',
        content: {
          variant: 'SimpleContent',
          description: (
            <Typography variant={'body1'}>
              {operation === ValidationOperation.CHECKIN
                ? 'This sample was intended for ' +
                  intendedLocation +
                  '. What would you like to do? '
                : 'This sample is currently located at ' +
                  intendedLocation +
                  '. If this is in error, the sample should be checked in instead.'}
            </Typography>
          ),
          options: [],
        },
        secondaryAction: operation === ValidationOperation.CHECKIN && {
          label: 'Remove from sample list',
          node: 'done',
          action: () => {
            removeSample(pegasusId)
          },
        },
        next: {
          label: 'Keep sample at ' + currentLocation,
          node: 'done',
          action: () => {
            updateLocation()
            return pegasusId
          },
        },
      },
    },
  }
}

export function resolveLocations(
  pegasusIds = [],
  intendedLocation,
  currentLocation,
  operation,
  removeSample,
  updateLocation
) {
  const pegasusIdList = pegasusIds.map((obj) => obj.pegasusId)
  return {
    activeSample: pegasusIdList,
    startNode: 'resolveLocations',
    nodes: {
      resolveLocations: {
        label: 'Resolve Location',
        content: {
          variant: 'SimpleContent',
          description: (
            <Typography variant={'body1'}>
              {operation === ValidationOperation.CHECKIN
                ? 'This sample was intended for ' +
                  intendedLocation +
                  '. What would you like to do? '
                : 'This sample is currently located at ' +
                  intendedLocation +
                  '. If this is in error, the sample should be checked in instead.'}
            </Typography>
          ),
          options: [],
        },
        secondaryAction: operation === ValidationOperation.CHECKIN && {
          label: 'Remove from sample list',
          node: 'done',
          action: () => {
            removeSample(pegasusIdList)
          },
        },
        next: {
          label: 'Keep sample at ' + currentLocation,
          node: 'done',
          action: () => {
            updateLocation()
            return pegasusIdList
          },
        },
      },
    },
  }
}

export function assignDetailedLocation(pegasusId, assignLocation) {
  return {
    activeSample: pegasusId,
    startNode: 'assignLocation',
    nodes: {
      assignLocation: {
        label: 'Assign Location',
        content: {
          variant: 'LocationScan',
          action: (value) => {
            assignLocation(value)
            return pegasusId
          },
        },
      },
    },
  }
}
const assignLocationLabel = () => {
  return 'Assign Location<br /><div style="font-weight:400;font-size:16px;">Select a location or enter a barcode below</div></div>'
}
export function assignDetailedLocationNew(
  pegasusIds,
  assignLocation,
  scanType = ''
) {
  return {
    activeSample: pegasusIds,
    startNode: 'assignLocation',
    nodes: {
      assignLocation: {
        label: ReactHtmlParser(assignLocationLabel()),
        content: {
          variant: 'LocationScan',
          required: true,
          action: (value, labelList = {}) => {
            assignLocation(value, labelList)
            return pegasusIds
          },
          scanType: scanType,
        },
        secondaryAction: {
          label: 'Cancel',
          node: 'cancel',
        },
      },
    },
  }
}

const itemsGroupLabels = {
  SAMPLE_ORDERED: 'Ordered',
  SAMPLE_CANCELLED: 'Cancelled',
  DUPLICATE: 'Duplicate',
}
export function removeInvalidSampleNew(
  pegasusIds = [],
  sampleStatus = '',
  operation,
  removeSample,
  setSampleStatusShipped
) {
  const sampleStatusLabel = sampleStatus.replace('_', ' ').toLowerCase()
  const sampleStatusLabel1 = itemsGroupLabels[sampleStatus]
  const samplecount = pegasusIds.length || Number(0)
  const pegasusIdList = pegasusIds.map((obj) => obj.pegasusId)
  return {
    activeSample: pegasusIdList,
    startNode: 'removeInvalidSample',
    nodes: {
      removeInvalidSample: {
        label: `${'Resolve ' + sampleStatusLabel1 + ' Samples'}`,
        content: {
          variant: 'SimpleContent',
          description: (
            <Typography variant={'body1'}>
              {samplecount} samples selected have invalid sample status:{' '}
              <span style={{ textTransform: 'capitalize', fontWeight: 700 }}>
                {sampleStatusLabel}
              </span>
              .
              <p>
                Only items with status
                <b>
                  {operation === ValidationOperation.CHECKIN
                    ? ' SAMPLE_SHIPPED or SAMPLE_SHOOT_SHIPPED '
                    : ' SAMPLE_RECEIVED '}
                </b>
                can be
                {operation === ValidationOperation.CHECKIN
                  ? ' checked in. Please contact the vendor for more details'
                  : ' moved'}
                .
              </p>
              <p>
                If the samples were shipped by the vendor with status as{' '}
                {sampleStatusLabel}, you can resolve the status by clicking on
                'SET SAMPLE AS SHIPPED'
              </p>
            </Typography>
          ),
          options: [],
        },
        secondaryAction: operation === ValidationOperation.CHECKIN && {
          label: 'Set sample as shipped',
          node: 'done',
          action: () => {
            setSampleStatusShipped()
            return pegasusIdList //todo
          },
        },
        next: {
          label: 'Remove from sample list',
          node: 'done',
          action: () => {
            removeSample(pegasusIdList) //todo
          },
        },
      },
    },
  }
}

export function removeInvalidSample(
  pegasusId,
  sampleStatus,
  operation,
  removeSample,
  setSampleStatusShipped
) {
  return {
    activeSample: pegasusId,
    startNode: 'removeInvalidSample',
    nodes: {
      removeInvalidSample: {
        label: 'Remove Invalid Sample',
        content: {
          variant: 'SimpleContent',
          description: (
            <Typography variant={'body1'}>
              Invalid sample status: <b>{sampleStatus}</b>. Only items with
              status
              <b>
                {operation === ValidationOperation.CHECKIN
                  ? ' SAMPLE_SHIPPED or SAMPLE_SHOOT_SHIPPED '
                  : ' SAMPLE_RECEIVED '}
              </b>
              can be
              {operation === ValidationOperation.CHECKIN
                ? ' checked in. Please contact the vendor for more details'
                : ' moved'}
              .
            </Typography>
          ),
          options: [],
        },
        secondaryAction: operation === ValidationOperation.CHECKIN && {
          label: 'Set sample as shipped',
          node: 'done',
          action: () => {
            setSampleStatusShipped()
            return pegasusId
          },
        },
        next: {
          label: 'Remove from sample list',
          node: 'done',
          action: () => {
            removeSample(pegasusId)
          },
        },
      },
    },
  }
}
