import axios from 'axios'
import apiConfig from '../apiConfig'
import { renameKeys } from '../pages/AddFilters/constants'

const {
  managements: { url: managementsUrl = '' },
  user: { url: usersUrl = '' },
  archives: { url: archivesUrl = '' },
  history: { url: historyUrl = '' } = {},
  apiKey = '',
} = apiConfig

export function updateSampleFields(params) {
  const { field_name = '', field_value = '', sample_id = '' } = params
  const updatedList = {
    field_name: field_name.toUpperCase(),
    field_value: field_value,
  }
  if (sample_id) {
    const updateSampleFieldsURL = `${managementsUrl}samples/notes/${sample_id}`
    return axios.put(updateSampleFieldsURL, updatedList, {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiKey,
      },
    })
  }
}

export function fetchHistoryData(params = {}) {
  let { sampleId = '', ...requestParams } = params
  return axios.get(`${historyUrl}sample_histories/${sampleId}`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      ...requestParams,
      key: apiKey,
    },
  })
}

export function fetchHistoryFilters(params = {}) {
  let { sampleId = '' } = params

  return axios.get(`${historyUrl}sample_users_and_events/${sampleId}`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function fetchInventorySamples(params) {
  const {
    requestParams = {},
    requestPayload = {
      include_filters: {},
      exclude_filters: {},
    },
  } = params
  return axios.post(archivesUrl + 'search', requestPayload?.include_filters, {
    headers: { 'Content-Type': 'application/json' },
    params: requestParams,
  })
}

export function downloadInventorySamples(params) {
  return axios.get(archivesUrl + 'search', {
    headers: { Accept: 'text/csv' },
    params: params,
  })
}

export function downloadInventorySamplesPost(params) {
  const {
    requestParams = {},
    requestPayload = {
      include_filters: {},
      exclude_filters: {},
    },
  } = params
  return axios.post(archivesUrl, requestPayload?.include_filters, {
    headers: { Accept: 'text/csv' },
    params: requestParams,
  })
}

export function downloadContactSheet(params = {}, downloadType = '') {
  return axios.get(archivesUrl + 'search?downloadAs=' + downloadType, {
    headers: { Accept: '*/*' },
    params: params,
    responseType: 'blob',
  })
}

export function downloadContactSheetPost(params = {}, downloadType = '') {
  const { requestParams = {} } = params
  const { requestPayload = {} } = renameKeys(params) || params
  return axios.post(
    archivesUrl + '?downloadAs=' + downloadType,
    requestPayload?.include_filters,
    {
      headers: { Accept: '*/*' },
      params: requestParams,
      responseType: 'blob',
    }
  )
}

export function getAllBrands() {
  return axios.get(managementsUrl + 'search/brands', {
    params: {
      key: apiKey,
    },
  })
}

export function getAllUsers() {
  return axios.get(usersUrl + 'users/active', {
    params: {
      key: apiKey,
    },
  })
}

export function saveColumnSettings(columnsToSave = [], lanId = '') {
  const reqObj = {
    lan_id: lanId,
    fields: columnsToSave,
  }
  return axios.post(managementsUrl + 'search/searchFields', reqObj, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function getColumnSettings() {
  return axios.get(managementsUrl + 'search/searchFields', {
    params: {
      key: apiKey,
    },
  })
}

export function unarchiveSamples(pegasusIds = []) {
  return axios.post(
    archivesUrl + 'unarchive_samples',
    {},
    {
      headers: { 'Content-Type': 'application/json' },
      params: {
        key: apiKey,
        sample_ids: pegasusIds?.join(','),
      },
    }
  )
}
