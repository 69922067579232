export const GET_AUTO_ORDER_SAMPLES_PENDING = 'GET_AUTO_ORDER_SAMPLES_PENDING'
export const GET_AUTO_ORDER_SAMPLES_FAILURE = 'GET_AUTO_ORDER_SAMPLES_FAILURE'
export const GET_AUTO_ORDER_SAMPLES_SUCCESS = 'GET_AUTO_ORDER_SAMPLES_SUCCESS'
export const AUTO_ORDER_SET_FILTER_NOTFOUND_LIST =
  'AUTO_ORDER_SET_FILTER_NOTFOUND_LIST'
export const AUTO_ORDER_SET_ROWS_PER_PAGE = 'AUTO_ORDER_SET_ROWS_PER_PAGE'
export const AUTO_ORDER_SET_TOTAL_ROWS_COUNT = 'AUTO_ORDER_SET_TOTAL_ROWS_COUNT'
export const AUTO_ORDER_SET_PAGE = 'AUTO_ORDER_SET_PAGE'
export const AUTO_ORDER_SET_CHECKBOX_HEADER = 'AUTO_ORDER_SET_CHECKBOX_HEADER'
export const AUTO_ORDER_SET_CHECKBOX = 'AUTO_ORDER_SET_CHECKBOX'
export const UNSET_SELECTED_DATA = 'UNSET_SELECTED_DATA'
export const RESET_FETCH_DATA_REQUEST_SUCCESS =
  'RESET_FETCH_DATA_REQUEST_SUCCESS'
export const REMOVE_AUTO_ORDER_SAMPLES_FULFILLED =
  'REMOVE_AUTO_ORDER_SAMPLES_FULFILLED'
export const REMOVE_AUTO_ORDER_SAMPLES_PENDING =
  'REMOVE_AUTO_ORDER_SAMPLES_PENDING'
export const REFRESH_AUTO_ORDER_SAMPLES = 'REFRESH_AUTO_ORDER_SAMPLES'
export const FETCH_AUTOORDER_SAMPLES_SUCCESS = 'FETCH_AUTOORDER_SAMPLES_SUCCESS'
export const FETCH_AUTOORDER_SAMPLES_FAILURE = 'FETCH_AUTOORDER_SAMPLES_FAILURE'
export const AUTO_ORDER_OPEN = 'AUTO_ORDER_OPEN'
export const FETCH_AUTO_ORDER_LOADING = 'FETCH_AUTO_ORDER_LOADING'
export const UPDATE_SIZE_DATA = 'UPDATE_SIZE_DATA'
