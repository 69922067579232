import moment from 'moment'
import * as types from './actionType'
import * as itemAPI from '../../services/itemService'
import * as orderAPI from '../../services/ordersService'
import * as userAPI from '../../services/userService'
// import * as locationAPI from '../../services/locationService'
import * as orderMapper from '../../mappers/OrderMapper'
import * as itemMapper from '../../mappers/itemMapper'
import * as barcodeApi from '../../services/barcodeService'
import * as barcodeMapper from '../../mappers/barcodeMapper'
import * as shipmentApi from '../../services/shipmentService'
import * as shipmentMapper from '../../mappers/shipmentMapper'
// import * as locationMapper from '../../mappers/locationMapper'
import { showNotification } from '../notification/actionCreator'
import {
  ValidationOperation,
  ValidationOperationLabels,
} from '../../enums/validationOperations'
import {
  PAYLOAD_DATE_FORMAT,
  convertTableDateFormat,
  getSampleIDPrefix,
  isHavingPrefix,
  isNumber,
} from '../../util/CommonUtils'
import { uniq, filter, isEmpty } from 'lodash'
import { getPegasusID } from 'util/CommonUtils'
import apiConfig from 'apiConfig'

// Get sample id for loction and remove invalid pegasus id's
export function validateArgs(
  argsList = [],
  operation,
  moveType = '',
  selectedLocation = ''
) {
  const args = Array.from(new Set(argsList))
  const pegasusIds = []
  const locationIds = []
  const fileMakerIds = []
  args.forEach((id) => {
    const isHavingPegasusPrefix = isHavingPrefix(id)
    if (isNumber(id)) {
      locationIds.push(id)
    } else {
      const pegasusId = getPegasusID(id)
      if (pegasusId.toString() !== '') {
        if (!isNaN(pegasusId)) {
          pegasusIds.push(id)
        }
      }
    }
    if (!isHavingPegasusPrefix) {
      fileMakerIds.push(id)
    }
  })
  return (dispatch) => {
    if (operation === ValidationOperation.MOVE_SAMPLES) {
      const pegIdList = getSampleIdsFromArgs(argsList)
      const duplicatePayloadItems = uniq(
        filter(pegIdList, (v, i, a) => a.indexOf(v) !== i)
      )
      if (duplicatePayloadItems.length > 0) {
        dispatch(setDuplicateSamples(duplicatePayloadItems))
      }
      dispatch(
        callFetchSampleWithLocation(
          { pegasusIds, locationIds, fileMakerIds },
          operation,
          moveType,
          selectedLocation
        )
      )
    } else {
      const newPegasusIds = [...pegasusIds, ...locationIds]
      return dispatch(
        fetchSamples(
          { pegasusIds: newPegasusIds, fileMakerIds: fileMakerIds },
          operation,
          selectedLocation
        )
      )
    }
  }
}

export function getSampleIdsFromArgs(argsList = []) {
  const returnList = []
  const sampleIdsFromArgs = (argsList || []).map((argId) => {
    const pegasusId = getPegasusID(argId)
    if (isNaN(argId) && !isNaN(pegasusId)) {
      returnList.push(pegasusId)
    }
    return argId
  })
  return sampleIdsFromArgs
}

export function fetchNoSamples(locationIds = []) {
  return {
    type: types.FETCH_NO_SAMPLES,
    payload: { isFetchNoSamples: true, locationIds },
  }
}

export function callFetchSampleWithLocation(
  filterValues = {},
  operation,
  moveType = '',
  selectedLocation = ''
) {
  const { pegasusIds = [], locationIds = [], fileMakerIds = [] } = filterValues
  const locationPegasusIds = []
  let fetchedLocationObj = {}
  return (dispatch) => {
    return orderAPI
      .getLocationSamples(locationIds, moveType)
      .then((res) => {
        const {
          data: { locations = [] },
        } = res
        if (moveType === 'C') {
          fetchedLocationObj = locations.find(
            (obj) => Number(obj.location_id) === Number(locationIds[0])
          )
        }
        return orderMapper.samplesResponseToSamplesCheckIn(res)
      })
      .then((sampleResult) => {
        sampleResult.forEach((it) => {
          locationPegasusIds.push(it.pegasusId)
        })
        const newPegasusIds = [...pegasusIds, ...locationPegasusIds]
        if (
          newPegasusIds.length > 0 ||
          (fileMakerIds?.length > 0 && moveType !== 'C')
        ) {
          let fetchSamplesObj = { pegasusIds: newPegasusIds }
          if (moveType !== 'C') {
            fetchSamplesObj = Object.assign({}, fetchSamplesObj, {
              fileMakerIds,
            })
          }
          dispatch(fetchSamples(fetchSamplesObj, operation, selectedLocation))
        } else if (
          moveType === 'C' &&
          isEmpty(sampleResult) &&
          isEmpty(fetchedLocationObj)
        ) {
          dispatch(fetchNoSamples(locationIds))
        } else {
          dispatch(fetchNoSamples())
          moveType !== 'C' && dispatch(setUnknownSamples(locationIds))
        }
        dispatch(fetchedLocationDetails(fetchedLocationObj))
      })
      .catch((error) => {
        const { response: { data: { errors: errorMessage = '' } } = {} } = error
        dispatch(showNotification(true, errorMessage))
      })
  }
}

export function setPreviousPage(previousPage) {
  return {
    type: types.CHECKIN_SET_PREVIOUS_STATE,
    payload: { previousPage: previousPage },
  }
}

// Fetch order information, item data, merge and find unknown pegasus id's
export function fetchSamples(
  { pegasusIds = [], fileMakerIds = [] },
  operation = ValidationOperation.CHECKIN,
  selectedLocation = ''
) {
  let validatedPegasusIds = pegasusIds
  let defaultLocation = ''
  const NO_LOCATION_ERROR = 'NO_LOCATION_ERROR'
  return (dispatch) => {
    dispatch(fetchSamplesRequestPending(true))
    return userAPI
      .getDetails()
      .then((result) => {
        defaultLocation = selectedLocation || result.data.default_location
        return selectedLocation || result.data.default_location
      })
      .then((location = '') => {
        if (!location || location === 'None') {
          return Promise.reject(NO_LOCATION_ERROR)
        }
        return Promise.all([
          getSamples(
            { sample_id: validatedPegasusIds, prop_ids: fileMakerIds },
            location,
            operation
          ),
          location,
        ])
      })
      .catch((error) => {
        if (error?.name === 'AxiosError') return true
        if (error === NO_LOCATION_ERROR) {
          return Promise.reject(NO_LOCATION_ERROR)
        }
        if (error.response && error.response.data) {
          for (let errorIndex in error.response.data) {
            if (error.response.data[errorIndex].field === 'pegasus_id') {
              validatedPegasusIds = validatedPegasusIds.filter(
                (id) =>
                  id.substring(2) !== error.response.data[errorIndex].resource
              )
            }
          }
        }
        return Promise.all([
          getSamples(
            { sample_id: validatedPegasusIds },
            defaultLocation,
            operation
          ),
          defaultLocation,
        ])
      })
      .then(([samples, location]) => {
        const searchedSamples = []
        samples.forEach((it) => {
          searchedSamples.push(it.pegasusId)
        })
        let missingPegasusIds = pegasusIds.filter(
          (id) =>
            !samples.some(
              (sample) =>
                getPegasusID(sample?.pegasusId) === getPegasusID(id) ||
                sample?.sampleId === id ||
                sample?.propId === id
            )
        )
        dispatch(fetchSamplesRequestPending(false))
        if (operation === ValidationOperation.CHECKIN) {
          dispatch(fetchSamplesCheckinSuccess(searchedSamples, samples))
        } else {
          dispatch(fetchSamplesRequestSuccess(samples, operation))
        }
        dispatch(setUnknownSamples(missingPegasusIds))
      })
      .catch((error) => {
        if (error === NO_LOCATION_ERROR) {
          dispatch(showNotification(true, 'Please select check-in location'))
        } else {
          dispatch(
            showNotification(
              true,
              'Something went wrong while fetching samples. Please try again.'
            )
          )
        }
        dispatch(fetchSamplesRequestFailure())
        dispatch(fetchSamplesRequestPending(false))
        dispatch(setUnknownSamples([...pegasusIds, ...fileMakerIds]))
      })
  }
}

export async function getMappedStatuses(
  pegasusIds = [],
  location,
  operation = ValidationOperation.CHECKIN
) {
  return orderAPI
    .validateSamples(
      orderMapper.createValidationRequest(pegasusIds, location, operation)
    )
    .then((statusResult) =>
      orderMapper.validationResponseToValidation(statusResult, pegasusIds)
    )
}

export async function getMappedSamples(pegasusIds = []) {
  return orderAPI
    .getSamples(pegasusIds)
    .then((res) => orderMapper.samplesResponseToSamplesCheckIn(res))
}

export async function getMappedSamplesNew(params = {}) {
  return orderAPI
    .fetchSamplesByUserPost(params)
    .then((res) => orderMapper.samplesResponseToSamplesCheckIn(res))
}

export async function getMappedItems(tcins = []) {
  return itemAPI
    .getItemsGraphQL(tcins)
    .then((itemResult) => itemMapper.itemsResponseToItems(itemResult))
    .then((items) => new Map(items.map((item) => [item.tcin.toString(), item])))
}

export function fetchSamplesRequestSuccess(data = [], operation) {
  return {
    type: types.FETCH_SAMPLES_REQUEST_SUCCESS,
    payload: { data: data, operation: operation },
  }
}

export function fetchedLocationDetails(locationData = {}) {
  return {
    type: types.FETCH_LOCATION_DETAILS_SUCCESS,
    payload: { locationData: locationData },
  }
}

export function fetchSamplesCheckinSuccess(pegasusIds = [], data = []) {
  return {
    type: types.FETCH_SAMPLES_CHECKIN_SUCCESS,
    payload: { data: data, pegasusIds: pegasusIds },
  }
}

export function fetchSamplesRequestPending(pending = false) {
  return {
    type: types.FETCH_SAMPLES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchSamplesRequestFailure() {
  return {
    type: types.FETCH_SAMPLES_REQUEST_FAILURE,
  }
}

export function checkinSamples(data = [], location) {
  const samplesWithLaunchDates = data.map((it = {}) => {
    const { launchDate = '', pegasusId = '' } = it
    const launchDateNew = launchDate ? moment(launchDate) : ''
    return {
      pegasus_id: pegasusId.slice(2, it.length),
      launch_date: convertTableDateFormat(launchDateNew, PAYLOAD_DATE_FORMAT),
    }
  })
  return (dispatch) => {
    dispatch(checkinRequestPending(true))
    return orderAPI
      .checkinSamples(samplesWithLaunchDates, location)
      .then((result) => {
        const { data: responseData = {} } = result
        dispatch(checkinRequestSuccess(responseData))
        dispatch(
          showNotification(
            true,
            data.length +
              (data.length === 1 ? ' sample was ' : ' samples were ') +
              'successfully checked-in to ' +
              location,
            'success'
          )
        )
        dispatch(checkinRequestPending(false))
      })
      .catch((error) => {
        dispatch(checkinRequestFailure())
        dispatch(
          showNotification(
            true,
            'Something went wrong. Please try again later.'
          )
        )
        dispatch(checkinRequestPending(false))
      })
  }
}

export function checkinRequestSuccess(data = []) {
  return {
    type: types.CHECKIN_REQUEST_SUCCESS,
    payload: { data: data },
  }
}

export function checkinRequestPending(pending = false) {
  return {
    type: types.CHECKIN_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function checkinRequestFailure() {
  return {
    type: types.CHECKIN_REQUEST_FAILURE,
  }
}

export function getCheckinLocation() {
  return (dispatch) => {
    dispatch(locationRequestPending(true))
    return userAPI
      .getDetails()
      .then((userResult) => {
        dispatch(locationRequestSuccess(userResult))
      })
      .catch(() => {
        dispatch(locationRequestFailure())
      })
      .finally(() => {
        dispatch(locationRequestPending(false))
      })
  }
}

export function locationRequestSuccess(data) {
  return {
    type: types.LOCATION_REQUEST_SUCCESS,
    payload: { data: data },
  }
}

export function locationRequestPending(pending = false) {
  return {
    type: types.LOCATION_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function locationRequestFailure() {
  return {
    type: types.LOCATION_REQUEST_FAILURE,
  }
}

export const cloneSample = (sample) => {
  return (dispatch) => {
    return orderAPI
      .createSamples(
        orderMapper.samplesToDeleteSampleNotesAndProject([
          Object.assign({}, sample, { sampleStatus: 'SAMPLE_SHIPPED' }),
        ])
      )
      .then((response) => orderMapper.samplesResponseToSamplesCheckIn(response))
      .then((samples) => samples[0].pegasusId)
  }
}

export const getLocationLabel = (
  response = {},
  locationId = '',
  currentLocation = ''
) => {
  let currentLocationObj = {}
  let currentLocationLabel = ''
  const {
    data: { locations = [] },
  } = response
  currentLocationObj =
    locations.find((obj) => obj.location_id === Number(locationId)) || {}
  currentLocationLabel =
    Object.entries(currentLocationObj).length > 0 &&
    !isEmpty(locationId.toString())
      ? orderMapper.sampleLocationResponseToLocation(currentLocationObj)
      : currentLocation
  return currentLocationLabel
}

/**
 * Handles calling warehouse managements API and assigning sample location
 * @param samples - list of samples to be assigned to a location
 * @param location - number representing physical location of sample
 * @param operation
 * @returns {function(*): *}
 */
export function assignLocation(
  locationLabelDetail = {},
  samples = [],
  currentLocation = '',
  locationId = '',
  operation = ValidationOperation.CHECKIN,
  searchedLocations = [],
  moveType = 'S',
  callBack = () => {}
) {
  const pegasusIds = samples.map((id) => id.slice(2, id.length))
  let currentLocationLabel = ''

  return (dispatch) => {
    dispatch(assignLocationPending(true))
    return orderAPI
      .assignLocation(
        pegasusIds,
        locationId,
        searchedLocations,
        moveType,
        operation
      )
      .then((response) => {
        currentLocationLabel = Object.values(locationLabelDetail)
          .filter((id) => !isEmpty(id))
          .join(' > ')
        const {
          data: { locations: dataLocations = [] },
        } = response
        const resultedSamples = !isEmpty(dataLocations)
          ? getSamples(
              {
                sample_id: dataLocations.map((sample) =>
                  getSampleIDPrefix(sample.pegasus_id, sample.type)
                ),
              },
              currentLocation,
              operation
            )
          : []
        return resultedSamples
      })
      .then((result) => {
        dispatch(
          showNotification(
            true,
            (result.length === 0
              ? 'Container ' + searchedLocations + ' with '
              : '') +
              result.length +
              (result.length === 1 ? ' sample was ' : ' samples were ') +
              'successfully ' +
              ValidationOperationLabels[operation] +
              ' to ' +
              (currentLocationLabel || currentLocation),
            'success'
          )
        )
        dispatch(assignLocationSuccess(result, operation))
        dispatch(assignLocationPending(false))
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            'Something went wrong. Please try again later.'
          )
        )
        dispatch(assignLocationPending(false))
      })
      .finally(() => {
        callBack()
      })
  }
}

//Missing in production action
export function assignLocationToMIP(
  samples = [],
  sampleId = '',
  currentLocation,
  locationId,
  operation = ValidationOperation.CHECKIN,
  callBack = () => {}
) {
  const pegasusIds = samples.map((id) => id.slice(2, id.length))
  let currentLocationLabel = ''
  return (dispatch) => {
    dispatch(assignLocationPending(true))
    return orderAPI
      .assignLocation(pegasusIds, locationId)
      .then((response) => {
        currentLocationLabel = getLocationLabel(
          response,
          locationId,
          currentLocation
        )
        return getSamples(
          {
            sample_id: response.data.locations.map((sample) =>
              getSampleIDPrefix(sample.pegasus_id, sample.type)
            ),
          },
          currentLocation,
          operation
        )
      })
      .then((result) => {
        dispatch(
          showNotification(
            true,
            result.length +
              (result.length === 1 ? ' sample was ' : ' samples were ') +
              'successfully ' +
              ValidationOperationLabels[operation] +
              ' to ' +
              (currentLocationLabel || currentLocation),
            'success'
          )
        )
        // dispatch(assignLocationSuccess(result, operation))
        dispatch(removeSample(sampleId))
        dispatch(assignLocationPending(false))
        callBack(sampleId)
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            'Something went wrong. Please try again later.'
          )
        )
        dispatch(assignLocationPending(false))
      })
  }
}

export function assignLocationSuccess(
  data,
  operation = ValidationOperation.CHECKIN
) {
  return {
    type: types.ASSIGN_LOCATION_REQUEST_SUCCESS,
    payload: { samples: data, operation },
  }
}
export function assignLocationPending(pending = false) {
  return {
    type: types.ASSIGN_LOCATION_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function setSamplesLocation(
  samples = [],
  location = '',
  operation = ValidationOperation.CHECKIN
) {
  // const { pegasusId = '' } = sample
  const pegasusIds = samples.map((obj) => obj.pegasusId)
  const pegasusIdsWithLocation = samples.map((obj) => {
    return { pegasusId: obj.pegasusId, shipToLoc: location }
  })
  return (dispatch) => {
    return orderAPI
      .updateOrder(
        orderMapper.samplesToSampleUpdateRequest(pegasusIdsWithLocation)
      )
      .then((res) => getSamples({ sample_id: pegasusIds }, location, operation))
      .then((result) => {
        dispatch(
          showNotification(
            true,
            'Sample location updated successfully',
            'success'
          )
        )
        dispatch(setSampleStatusSuccess(result))
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            "Couldn't update sample location at this time. Please try again."
          )
        )
      })
  }
}

export function setSampleLocation(
  sample,
  location,
  operation = ValidationOperation.CHECKIN
) {
  const { pegasusId = '' } = sample
  return (dispatch) => {
    return orderAPI
      .updateOrder(
        orderMapper.samplesToSampleUpdateRequest([
          {
            pegasusId: pegasusId,
            shipTo: location,
          },
        ])
      )
      .then((res) =>
        getSamples({ sample_id: [pegasusId] }, location, operation)
      )
      .then((result) => {
        dispatch(
          showNotification(
            true,
            'Sample location updated successfully',
            'success'
          )
        )
        dispatch(setSampleStatusSuccess(result))
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            "Couldn't update sample location at this time. Please try again."
          )
        )
      })
  }
}

export function getRelatedSamples(pegasusId, shipTo) {
  return () => {
    return orderAPI
      .getRelatedSamples(pegasusId)
      .then((response) =>
        orderMapper.multiSamplesResponseToSamples(
          response.data.samples.filter(
            (sample) => sample.ship_to_loc === shipTo
          )
        )
      )
      .then((samples) =>
        samples.length > 0
          ? getMappedItems([samples[0].tcin]).then((response) => [
              samples,
              response,
            ])
          : [samples, new Map()]
      )
      .then(([samples, tcinData]) =>
        samples.map((sample) =>
          Object.assign({}, sample, tcinData.get(sample.tcin.toString()))
        )
      )
  }
}

export function checkSampleStatusesOnNext(
  ids = [],
  location,
  operation = ValidationOperation.CHECKIN,
  callBack = () => {}
) {
  return (dispatch) => {
    return getSamples({ sample_id: ids }, location, operation)
      .then((result) => {
        dispatch(setSampleStatusSuccessNext(result))
      })
      .then((samples) => {
        callBack()
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            'Unable to update sample status.  Please try again.'
          )
        )
      })
  }
}

/**
 * Gets samples with all item data and validation statues
 * @param filterValues {sample_id: [], prop_ids: []}
 * @param location
 * @param operation
 * @returns {Promise<any[] | never>} - list of mapped samples
 */
export async function getSamples(
  filterValues,
  location,
  operation = ValidationOperation.CHECKIN
) {
  const { sample_id: ids = [], prop_ids = [] } = filterValues
  const scenic_filemaker_ids = [...prop_ids]
  const { apiKey = '' } = apiConfig
  const requestParams = {
    page: 1,
    per_page: 2000,
    key: apiKey,
  }

  const params = {
    requestParams,
    requestPayload: {
      include_filters: {
        sample_id: ids.map((id = '') => getPegasusID(id)),
        prop_ids,
        scenic_filemaker_ids,
        is_combined_search: true,
      },
      exclude_filters: {},
    },
  }
  return getMappedSamplesNew(params)
    .then((samples) => {
      const searchedSamples = []
      samples.forEach((it) => {
        searchedSamples.push(it.pegasusId)
      })
      return Promise.all([
        ids,
        samples,
        getMappedStatuses(searchedSamples, location, operation),
        getMappedItems(samples.map((sample) => sample?.tcin)),
      ])
    })
    .then(([ids, samples, statuses, tcinData]) =>
      samples.map((sample) =>
        Object.assign(
          {},
          sample,
          { checkInStatus: statuses.get(sample?.pegasusId) },
          tcinData.get(sample?.tcin?.toString())
        )
      )
    )
}

export function updateSampleId(
  existingId,
  updatedId = [],
  location,
  operation = ValidationOperation.CHECKIN
) {
  return (dispatch) => {
    return getSamples({ sample_id: updatedId }, location, operation)
      .then((samples) => {
        dispatch(setUpdatedSample(existingId, samples))
        return updatedId
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            'Unable to update sample status.  Please try again.'
          )
        )
      })
  }
}

export function setUpdatedSample(id, sample) {
  return {
    type: types.CHECKIN_UPDATE_SAMPLE_ID,
    payload: { id: id, sample: sample },
  }
}

export function addSampleToPrintQueue(id) {
  return {
    type: types.CHECKIN_ADD_SAMPLE_TO_PRINT_QUEUE,
    payload: { id: id },
  }
}

export function removeSampleFromPrintQueue(id) {
  return {
    type: types.CHECKIN_REMOVE_SAMPLE_TO_PRINT_QUEUE,
    payload: { id: id },
  }
}

export function removeSampleFromPrintQueueAll(id) {
  return {
    type: types.CHECKIN_REMOVE_SAMPLE_TO_PRINT_QUEUE_ALL,
    payload: { id: id },
  }
}

export function getBarcodes(samples = [], type = 'MULTILINE') {
  return (dispatch) => {
    return barcodeApi
      .getBarcode(barcodeMapper.samplesToBarcodeRequest(samples), type)
      .then((barcodeResult) => barcodeResult.data)
      .then((barcodePdf) => {
        return URL.createObjectURL(
          new Blob([barcodePdf], { type: 'application/pdf' })
        )
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            "Couldn't retrieve barcodes at this time.  Please try again."
          )
        )
      })
  }
}
export function printAllBarcodes(samples = [], type = 'MULTILINE') {
  return (dispatch) => {
    return barcodeApi
      .getCombinedBarcodes(barcodeMapper.samplesToBarcodeRequest(samples), type)
      .then((barcodeResult) => barcodeResult.data)
      .then((barcodePdf) => {
        return URL.createObjectURL(
          new Blob([barcodePdf], { type: 'application/pdf' })
        )
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            "Couldn't retrieve barcodes at this time.  Please try again."
          )
        )
      })
  }
}

export function setUnknownSamples(data = []) {
  return {
    type: types.CHECKIN_SET_UNKNOWN_DATA,
    payload: { data: data },
  }
}
export function setDuplicateSamples(data = []) {
  return {
    type: types.CHECKIN_SET_DUPLICATE_DATA,
    payload: { data: data },
  }
}

export function removeUnknownSampleFromList(sampleId = '', type = '') {
  return {
    type: types.CHECKIN_REMOVE_UNKNOWN_SAMPLE_FROM_LIST,
    payload: { sampleId: sampleId, type: type },
  }
}

export function removeUnknownLocationFromList() {
  return {
    type: types.MOVESAMPLES_REMOVE_UNKNOWN_SAMPLE_FROM_LIST,
  }
}

export function removeDuplicateSampleFromList(sampleId = '', type = '') {
  return {
    type: types.CHECKIN_REMOVE_DUPLICATE_SAMPLE_FROM_LIST,
    payload: { sampleId: sampleId, type: type },
  }
}

export function removeSample(id) {
  return {
    type: types.CHECKIN_REMOVE_SAMPLE,
    payload: { id: id },
  }
}

export function removeSamples(samplesList) {
  return {
    type: types.CHECKIN_REMOVE_SAMPLES,
    payload: { samplesList: samplesList },
  }
}

export function setMultipleSampleStatusShipped(
  samplesList = [],
  location = '',
  operation = ValidationOperation.CHECKIN
) {
  return (dispatch) => {
    let erroredIds = []
    let promises = []
    for (let sample in samplesList) {
      const { pegasusId = '' } = samplesList[sample]
      promises.push(
        shipmentApi
          .submitShipment(
            shipmentMapper.sampleToShipmentRequest(samplesList[sample], false)
          )
          .then((res) =>
            getSamples({ sample_id: [pegasusId] }, location, operation)
          )
          .then((result) => {
            dispatch(setSampleStatusSuccess(result))
          })
          .catch(() => {
            erroredIds.push(pegasusId)
          })
      )
    }

    Promise.all(promises).then(() => {
      if (erroredIds.length) {
        dispatch(showNotification(true, erroredIds))
      }
    })
  }
}

export function setSampleStatusShipped(
  sample,
  location,
  operation = ValidationOperation.CHECKIN
) {
  return (dispatch) => {
    const { pegasusId = '' } = sample
    return shipmentApi
      .submitShipment(shipmentMapper.sampleToShipmentRequest(sample, false))
      .then((res) =>
        getSamples({ sample_id: [pegasusId] }, location, operation)
      )
      .then((result) => {
        dispatch(
          showNotification(
            true,
            result.length +
              'sample status' +
              (result.length === 1 ? ' was ' : ' were ') +
              'successfully updated'
          )
        )
        dispatch(setSampleStatusSuccess(result))
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            "Couldn't change sample status at this time. Please try again."
          )
        )
      })
  }
}

export function setSampleStatusSuccess(result) {
  return {
    type: types.CHECKIN_UPDATE_SAMPLE_STATUS_SUCCESS,
    payload: { samples: result },
  }
}

export function setSampleStatusSuccessNext(result) {
  return {
    type: types.CHECKIN_UPDATE_SAMPLE_STATUS_SUCCESS_NEXT,
    payload: { samples: result },
  }
}

export function clearCheckinData() {
  return {
    type: types.CHECKIN_CLEAR_DATA,
  }
}

export function showPopup(item) {
  return {
    type: types.SHOW_CHECKIN_PAGE_POPUP,
    payload: { item: item },
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_CHECKIN_PAGE_POPUP,
  }
}

export function updateSampleFieldsData(data = {}) {
  return {
    type: types.CALL_EDIT_FIELDS_API_CHECKIN,
    payload: data,
  }
}
