export const SampleStatus = Object.freeze({
  ORDERED: 'SAMPLE_ORDERED',
  INITIATED: 'SAMPLE_INITIATED',
  SHIPPED: 'SAMPLE_SHIPPED',
  SHOOT_SHIPPED: 'SAMPLE_SHOOT_SHIPPED',
  HOLD: 'SAMPLE_HOLD',
  CANCELED: 'SAMPLE_CANCELLED',
  RECEIVED: 'SAMPLE_RECEIVED',
  ARCHIVED: 'SAMPLE_ARCHIVED',
})
