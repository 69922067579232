// eslint-disable-next-line no-unused-vars
import React, { Children, isValidElement, cloneElement } from 'react'

export const ShowIf = ({ children, condition }) => {
  if (condition) {
    return <>{children}</>
  } else {
    return <></>
  }
}

export const ShowIfElse = ({ condition, success, fail }) => {
  if (condition) {
    return <>{success}</>
  } else {
    return <>{fail}</>
  }
}

export const ShowIfElseBak = ({ children, condition }) => {
  const ifComponentList = []
  const elseComponentList = []

  Children.forEach(children, (child) => {
    const { condition: childCondition } = child.props
    // const {type} = child.type;

    if (typeof childCondition !== 'undefined' && isValidElement(child)) {
      ifComponentList.push(cloneElement(child))
    } else if (typeof childCondition === 'undefined' && isValidElement(child)) {
      elseComponentList.push(cloneElement(child))
    }
  })

  if (condition) {
    return <>{ifComponentList}</>
  } else {
    return <>{elseComponentList}</>
  }
}

export default ShowIf
