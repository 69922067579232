import * as types from './actionType'
import * as itemAPI from '../../services/itemService'
import * as locationAPI from '../../services/locationService'
import * as userAPI from '../../services/userService'
import * as itemMapper from '../../mappers/itemMapper'
import * as orderMapper from '../../mappers/OrderMapper'
import * as shootShipmentMapper from '../../mappers/shootShipmentMapper'
import * as shootShipmentAPI from '../../services/shootShipmentService'
import { showNotification } from '../../store/notification/actionCreator'
import { ShootTypePagename } from '../../enums/shootTypes'
import { download } from '../../util/DownloadCsv'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { SAMPLE } from 'enums/Tabs'
import { isHavingPrefix } from 'util/CommonUtils'

export function setCheckBox(selectedData) {
  return {
    type: types.SHOOT_SHIP_SET_CHECKBOX,
    payload: { selected: selectedData },
  }
}

export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.SHOOT_SHIP_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}

// Validate arguments and remove invalid pegasus id's
export function validateArguments(args = []) {
  const propOrPegasusIds = []
  args = Array.from(new Set(args))
  const pegasusIds = []
  args.forEach((id) => {
    const isHavingPegasusPrefix = isHavingPrefix(id)
    let pegasusId = id
    if (isHavingPegasusPrefix) {
      pegasusId = id.slice(2, id.length)
    } else if (!isHavingPegasusPrefix) {
      propOrPegasusIds.push(id)
    }

    if (pegasusId.toString() !== '') {
      if (!isNaN(pegasusId)) {
        pegasusIds.push(pegasusId.toString())
      }
    }
  })
  return [pegasusIds, propOrPegasusIds]
}

export function createShootShipment(shipmentDetails, callback = () => {}) {
  const shipmentRequestPayload =
    shootShipmentMapper.shipmentToShipmentUpdateRequest(shipmentDetails)

  return (dispatch) => {
    dispatch(createShipmentRequestPending(true))
    return shootShipmentAPI
      .createShootShipment(shipmentRequestPayload)
      .then((shipmentResult) => {
        dispatch(
          showNotification(true, 'Shipment successfully created', 'success')
        )
        dispatch(
          createShipmentRequestSuccess(
            shootShipmentMapper.shipmentResponseToShipment(shipmentResult.data)
          )
        )
        dispatch(getShootShipment(shipmentResult.data?.shoot_shipment_id))
        callback(ShootTypePagename.SHIPSAMPLES, 'create', shipmentResult.data)
      })
      .catch(() => {
        dispatch(createShipmentRequestFailure())
      })
      .finally(() => {
        dispatch(createShipmentRequestPending(false))
      })
  }
}

export function createShipmentRequestSuccess(data) {
  return {
    type: types.SHOOT_SHIP_CREATE_REQUEST_SUCCESS,
    payload: { data: data },
  }
}

export function createShipmentRequestPending(pending = false) {
  return {
    type: types.SHOOT_SHIP_CREATE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function createShipmentRequestFailure() {
  return {
    type: types.SHOOT_SHIP_CREATE_REQUEST_FAILURE,
  }
}

export function getShootShipment(shipmentId) {
  return (dispatch) => {
    dispatch(getShootShipmentRequestPending(true))
    dispatch(setSampleData())
    shootShipmentAPI
      .getShootShipment(shipmentId)
      .then((sampleResult) => {
        userAPI
          .getUser(sampleResult.data.created_by)
          .then((user) => {
            let sampleCopy = Object.assign({}, sampleResult)
            sampleCopy.data.created_by =
              user.data.first_name + ' ' + user.data.last_name
            dispatch(
              getShootShipmentRequestSuccess(
                shootShipmentMapper.pendingShipmentToNewShipment(sampleCopy)
              )
            )
          })
          .catch((e) => {
            dispatch(
              getShootShipmentRequestSuccess(
                shootShipmentMapper.pendingShipmentToNewShipment(sampleResult)
              )
            )
          })
        let mergeData = []
        let pegasusIds = []
        for (let sample in sampleResult.data.samples) {
          pegasusIds.push('PG' + sample.pegasus_id)
        }
        const [validatedPegasusIds] = validateArguments(pegasusIds)

        if (sampleResult.data.samples.length > 0) {
          itemAPI
            .getItemsGraphQL(
              sampleResult.data.samples.map((it) => it?.item_data?.tcin)
            )
            .then((itemResult) => itemMapper.itemsResponseToItems(itemResult))
            .then((items) => {
              let itemMap = new Map()
              let samplesNotFound = []
              items.map((item) => itemMap.set(item?.tcin?.toString(), item))
              orderMapper
                .samplesResponseToSamples(sampleResult)
                .map((samples) => {
                  let item = itemMap.get(samples?.tcin?.toString())
                  let mergedObj = Object.assign({}, samples, item)
                  return mergeData.push(mergedObj)
                })
              let sampleFound = []
              sampleFound = sampleResult.data.samples.map((it) =>
                it.pegasus_id.toString()
              )
              validatedPegasusIds.forEach((id) => {
                if (
                  sampleFound.indexOf(id.toString()) < 0 &&
                  id.toString() !== ''
                ) {
                  samplesNotFound.push(orderMapper.sampleIdBuilder(id))
                }
              })
              dispatch(addSamplesRequestSuccess(mergeData))
              dispatch(setUnknownSamples(samplesNotFound))
            })
        } else {
          dispatch(setUnknownSamples(pegasusIds))
          dispatch(addSamplesRequestSuccess(mergeData))
        }
      })
      .catch((e) => {
        dispatch(getShootShipmentRequestFailure())
      })
      .finally(() => {
        dispatch(getShootShipmentRequestPending(false))
      })
  }
}

export function getShootShipmentRequestSuccess(shipmentData) {
  return {
    type: types.SHOOT_SHIP_GET_REQUEST_SUCCESS,
    payload: { shipmentData: shipmentData },
  }
}

export function getShootShipmentRequestPending(pending = false) {
  return {
    type: types.SHOOT_SHIP_GET_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function getShootShipmentRequestFailure() {
  return {
    type: types.SHOOT_SHIP_GET_REQUEST_FAILURE,
  }
}

export function deleteShootShipment(shipmentId, callback = () => {}) {
  return (dispatch) => {
    dispatch(deleteShipmentRequestPending(true))
    return shootShipmentAPI
      .deleteShootShipment(shipmentId)
      .then(() => {
        dispatch(deleteShipmentRequestSuccess())
        callback(ShootTypePagename.SHIPSAMPLES, 'delete')
      })
      .catch(() => {
        dispatch(deleteShipmentRequestFailure())
        dispatch(showNotification(true, 'Unable to delete this shipment'))
      })
      .finally(() => {
        dispatch(deleteShipmentRequestPending(false))
      })
  }
}

export const deleteShipmentRequestSuccess = () => {
  return {
    type: types.SHOOT_SHIP_DELETE_REQUEST_SUCCESS,
    payload: { data: true },
  }
}

export function deleteShipmentRequestPending(pending = false) {
  return {
    type: types.SHOOT_SHIP_DELETE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function deleteShipmentRequestFailure() {
  return {
    type: types.SHOOT_SHIP_DELETE_REQUEST_FAILURE,
  }
}

export function downloadConflictSamples(
  pegasusIds = [],
  userId = '',
  shipmentId = '',
  shipFromLocation = ''
) {
  const [validatedPegasusIds] = validateArguments(pegasusIds)
  return (dispatch) => {
    if (validatedPegasusIds.length > 0) {
      return shootShipmentAPI
        .downloadConflictSamples(shipmentId, pegasusIds, shipFromLocation)
        .then((response) => {
          download(response)
        })
        .catch(() => {
          dispatch(
            showNotification(
              true,
              'We encountered an error while trying to download'
            )
          )
        })
    }
  }
}
export function downloadShipmentContactSheet(
  params = {},
  downloadType = '',
  itemType = SAMPLE
) {
  return (dispatch) => {
    return shootShipmentAPI
      .downloadShipmentContactSheetPost(params, downloadType, itemType)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function CsvDwonloadScenic(
 shipmentId = ''
) {
  return (dispatch) => {
    return shootShipmentAPI
      .csvDwonloadScenicData(shipmentId)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export const getUnknownSamplesList = (
  validSamples = [],
  errorSampleIDs = [],
  validatedPegasusIds = [],
  propOrPegasusIds = []
) => {
  let sampleFound = []
  let samplesNotFound = []

  sampleFound = validSamples.map((it) => it.pegasus_id.toString())
  validatedPegasusIds.forEach((id = '') => {
    if (
      errorSampleIDs.indexOf(id.toString()) === -1 &&
      sampleFound.indexOf(id.toString()) === -1 &&
      id.toString() !== '' &&
      propOrPegasusIds.indexOf(id.toString()) === -1
    ) {
      samplesNotFound.push(id)
    }
  })
  return samplesNotFound
}

export const callForUnknownSamples = (
  validSamples = [],
  errorSampleIDs = [],
  validatedPegasusIds = [],
  propOrPegasusIds = []
) => {
  return (dispatch) => {
    let samplesNotFound = []
    samplesNotFound =
      getUnknownSamplesList(
        validSamples,
        errorSampleIDs,
        validatedPegasusIds,
        propOrPegasusIds
      ) || []
    if (samplesNotFound.length > 0) {
      dispatch(setUnknownSamples(samplesNotFound))
    }
  }
}

export function addSamples(
  pegasusIds,
  userId,
  shipmentId,
  shipFromLocation = ''
) {
  const [validatedPegasusIds, propOrPegasusIds] = validateArguments(pegasusIds)
  return (dispatch) => {
    let mergeData = []
    if (validatedPegasusIds.length > 0 || propOrPegasusIds.length > 0) {
      dispatch(addSamplesRequestPending(true))
      shootShipmentAPI
        .addShootShipment(
          shipmentId,
          validatedPegasusIds,
          shipFromLocation,
          propOrPegasusIds
        )
        .then((sampleResult) => {
          const { status: currentHttpStatus = '' } = sampleResult
          let returnObj = {}
          if (currentHttpStatus === 207) {
            const { data: { result: response = {} } = {} } = sampleResult
            const { errors: errorSamples = [], samples: validSamples = [] } =
              response
            returnObj = {
              errorSamples: errorSamples || [],
              validSamples: validSamples || [],
            }
          } else {
            const { data: { samples: validSamples = [] } = {} } = sampleResult
            returnObj = {
              errorSamples: [],
              validSamples: validSamples || [],
            }
          }
          return returnObj
        })
        .then(({ validSamples = [], errorSamples = [] }) => {
          const errorSampleIDs = errorSamples.map((obj) => {
            const { resource = {} } = obj
            const { pegasusId = '' } = resource
            return pegasusId.toString()
          })
          dispatch(
            callForUnknownSamples(
              validSamples,
              errorSampleIDs,
              validatedPegasusIds,
              propOrPegasusIds
            )
          )
          if (validSamples.length > 0) {
            const updatedValidSamples =
              orderMapper.samplesResponseToSamplesNew(validSamples)

            const listOfTcins = updatedValidSamples
              .map((it) => it.tcin)
              .filter((tcin) => !!tcin)
            const finalListOfTcins = Array.from(new Set([...listOfTcins]))

            if (isEmpty(finalListOfTcins) || !finalListOfTcins[0]) {
              // Handling for props with no tcins
              dispatch(addSamplesRequestSuccess(updatedValidSamples, true))
            } else {
              itemAPI
                .getItemsGraphQL(updatedValidSamples.map((it) => it.tcin))
                .then((itemResult) =>
                  itemMapper.itemsResponseToItems(itemResult)
                )
                .then((items) => {
                  let itemMap = new Map()
                  items.map((item) => itemMap.set(item.tcin.toString(), item))
                  updatedValidSamples.map((samples) => {
                    const { tcin: sampleTcin = '' } = samples
                    let item = itemMap.get(sampleTcin.toString())
                    let mergedObj = Object.assign({}, samples, item)
                    return mergeData.push(mergedObj)
                  })
                  dispatch(addSamplesRequestSuccess(mergeData, true))
                })
            }
          } else {
            // TODO: remove since this will be empty ?
            dispatch(addSamplesRequestSuccess(mergeData))
          }
          if (errorSamples.length > 0) {
            const listOfTcins = errorSamples
              .map((it) => it.tcin)
              .filter((tcin) => !!tcin)
            const finalListOfTcins = Array.from(new Set([...listOfTcins]))

            if (isEmpty(finalListOfTcins) || !finalListOfTcins[0]) {
              // Handling for props with no tcins
              dispatch(unavailableSamplesSuccess(errorSamples))
            } else {
              itemAPI
                .getItemsGraphQL(errorSamples.map((it) => it?.resource?.tcin))
                .then((items) => {
                  const detailedErrorSamples = []
                  errorSamples.forEach((sample) => {
                    const detailedSample = cloneDeep(sample)
                    const itemDetails = items.find(
                      (item) =>
                        item.tcin.toString() ===
                        sample?.resource?.tcin.toString()
                    )
                    detailedSample.resource.imageUrl = (
                      <img
                        src={itemDetails.image_url}
                        alt="sample"
                        width={72}
                        height={72}
                      />
                    )
                    detailedSample.resource.description =
                      itemDetails.general_description
                    detailedErrorSamples.push(detailedSample)
                  })
                  dispatch(unavailableSamplesSuccess(detailedErrorSamples))
                })
            }
          }
          dispatch(addSamplesRequestPending(false))
        })
        .catch((err = {}) => {
          const { response: { status: responseStatus = '' } = {} } = err
          dispatch(addSamplesRequestPending(false))
          if (responseStatus === 404) {
            dispatch(setUnknownSamples(orderMapper.getUnknownSamples(err)))
          } else {
            dispatch(
              showNotification(
                true,
                'We encountered an error while trying to add samples'
              )
            )
          }
        })
    }
  }
}

export function addSamplesRequestSuccess(data = [], isFlyout = false) {
  return {
    type: types.ADD_SAMPLES_REQUEST_SUCCESS,
    payload: { data: data, isFlyout },
  }
}

export function addSamplesRequestPending(pending = false) {
  return {
    type: types.ADD_SAMPLES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function addSamplesRequestFailure() {
  return {
    type: types.ADD_SAMPLES_REQUEST_FAILURE,
  }
}

export function unavailableSamplesSuccess(data = []) {
  return {
    type: types.UNAVAILABLE_SAMPLES_SUCCESS,
    payload: { data: data },
  }
}

export function unavailableSamplesPending(pending = false) {
  return {
    type: types.UNAVAILABLE_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export function unavailableSamplesFailure() {
  return {
    type: types.UNAVAILABLE_SAMPLES_FAILURE,
  }
}

export function closeErrorPopUp() {
  return {
    type: types.CLOSE_UNAVAILABLE_SAMPLES_POPUP,
  }
}
export function setSampleData() {
  return {
    type: types.CLEAR_STATE_DATA,
  }
}

export function updateShootShipment(
  shipmentId,
  updateDetails,
  callback = () => {}
) {
  return async (dispatch) => {
    dispatch(updateShipmentRequestPending(true))
    return shootShipmentAPI
      .updateShootShipment(
        shipmentId,
        shootShipmentMapper.shipmentToShipmentUpdateRequest(updateDetails)
      )
      .then(async (shipmentResult) => {
        dispatch(updateShipmentRequestSuccess(shipmentResult))
        await dispatch(
          updateShipmentRequestSuccess(
            shootShipmentMapper.shipmentResponseToShipment(shipmentResult.data)
          )
        )
        callback(ShootTypePagename.SHIPSAMPLES, 'update', shipmentResult.data)
        dispatch(
          showNotification(true, 'Shipment successfully updated', 'success')
        )
        dispatch(setSampleData([]))
      })
      .catch((err) => {
        dispatch(updateShipmentRequestFailure())
        if (err.response.status === 400) {
          let errorMessages = orderMapper.getErrorMessages(err)
          dispatch(showNotification(true, errorMessages[0]))
        } else {
          dispatch(showNotification(true, 'Unable to save this shipment'))
        }
      })
      .finally(() => {
        dispatch(updateShipmentRequestPending(false))
      })
  }
}

export function updateShipmentRequestSuccess(shipmentResult) {
  return {
    type: types.SHOOT_SHIP_UPDATE_REQUEST_SUCCESS,
    payload: { data: shipmentResult },
  }
}

export function updateShipmentRequestPending(pending = false) {
  return {
    type: types.SHOOT_SHIP_UPDATE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function updateShipmentRequestFailure() {
  return {
    type: types.SHOOT_SHIP_UPDATE_REQUEST_FAILURE,
  }
}

export function removeSamples(selectedData, shipmentId) {
  return (dispatch) => {
    dispatch(removeSampleRequestPending(true))
    return shootShipmentAPI
      .removeSamples(selectedData, shipmentId)
      .then(() => {
        dispatch(removeSampleRequestSuccess(selectedData))
      })
      .catch(() => {
        dispatch(removeSampleRequestFailure())
      })
      .finally(() => {
        dispatch(removeSampleRequestPending(false))
      })
  }
}

export function removeSampleRequestSuccess(selectedData) {
  return {
    type: types.REMOVE_SAMPLE_REQUEST_SUCCESS,
    payload: { data: selectedData },
  }
}

export function removeSampleRequestPending(pending = false) {
  return {
    type: types.REMOVE_SAMPLE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function removeSampleRequestFailure() {
  return {
    type: types.REMOVE_SAMPLE_REQUEST_FAILURE,
  }
}

export function getShipFromLocation() {
  return (dispatch) => {
    dispatch(fetchShipFromLocationRequestPending(true))
    return locationAPI
      .getCheckInLocation()
      .then((buildingResult) => {
        dispatch(fetchShipFromLocationRequestSuccess(buildingResult))
      })
      .catch(() => {
        dispatch(fetchShipFromLocationRequesFailure())
      })
      .finally(() => {
        dispatch(fetchShipFromLocationRequestPending(false))
      })
  }
}

export function getShipToLocation() {
  return (dispatch) => {
    dispatch(fetchShipFromLocationRequestPending(true))
    return locationAPI
      .getBuildings()
      .then((buildingResult) => {
        dispatch(fetchShipToLocationRequestSuccess(buildingResult))
      })
      .catch(() => {
        dispatch(fetchShipFromLocationRequesFailure())
      })
      .finally(() => {
        dispatch(fetchShipFromLocationRequestPending(false))
      })
  }
}

/**
 * Action to update Shipment Bundled Quantity
 * @param {Number} shipmentId
 * @param {Number} quantity
 * @returns
 */
export function updateShipmentBundleQuantity(
  shipmentId,
  pegasusIdForUpdate,
  updatedBundledQuantity
) {
  return async (dispatch) => {
    try {
      const { data = [] } = await shootShipmentAPI.updateShipmentBundles(
        shipmentId,
        pegasusIdForUpdate,
        updatedBundledQuantity
      )
      dispatch(
        updateBundleQuantity(
          shipmentId,
          data[0]?.pegasus_id,
          data[0]?.bundle_quantity
        )
      )
      dispatch(
        showNotification(true, 'Quantity updated successfully', 'success')
      )
    } catch (e) {
      dispatch(showNotification(true, "Couldn't update the quantity"))
    }
  }
}

export function updateBundleQuantity(shipmentId, pegasusId, updatedQuantity) {
  return {
    type: types.UPDATE_BUNDLED_QUANTITY,
    payload: { shipmentId, pegasusId, updatedQuantity },
  }
}

export const fetchShipFromLocationRequestSuccess = (buildingResult) => {
  return {
    type: types.FETCH_SHIP_FROM_LOCATION_REQUEST_SUCCESS,
    payload: { data: buildingResult },
  }
}

export const fetchShipToLocationRequestSuccess = (buildingResult) => {
  return {
    type: types.FETCH_SHIP_TO_LOCATION_REQUEST_SUCCESS,
    payload: { data: buildingResult },
  }
}

export function fetchShipFromLocationRequestPending(pending = false) {
  return {
    type: types.FETCH_SHIP_LOCATION_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchShipFromLocationRequesFailure() {
  return {
    type: types.FETCH_SHIP_LOCATION_REQUEST_FAILURE,
  }
}

export function setUnknownSamples(data = []) {
  return {
    type: types.SHOOT_SAMPLE_SET_UNKNOWN_DATA,
    payload: { data: data },
  }
}

export function setPage(page = 0) {
  return {
    type: types.SHOOT_SAMPLE_SET_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage = 20) {
  return {
    type: types.SHOOT_SAMPLE_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function clearSearchData() {
  return {
    type: types.SHOOT_SAMPLE_CLEAR_DATA,
  }
}

export function showAlert(message) {
  return {
    type: types.SHOW_SHOOT_SHIP_PAGE_ALERT,
    payload: { message: message },
  }
}

export function closeAlert() {
  return {
    type: types.CLOSE_SHOOT_SHIP_PAGE_ALERT,
  }
}

export function showPopup(item) {
  return {
    type: types.SHOW_SHOOT_SHIP_POPUP,
    payload: { item: item },
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_SHOOT_SHIP_POPUP,
  }
}

export function resetNewShipmentState() {
  return {
    type: types.RESET_NEW_SHIPMENT_STATE,
  }
}

export function setRedirectToNewShipment(goNextFlag) {
  return {
    type: types.SET_REDIRECT_TO_NEW_SHIPMENT,
    payload: { goNextFlag: goNextFlag },
  }
}

export function updateSampleFieldsData(data = {}) {
  return {
    type: types.SHOOT_SHIP_CALL_EDIT_FIELDS_API,
    payload: data,
  }
}
