import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { getLastShipDate } from '../../store/searchInventory/actionCreator'
import { makePopUpTable } from '../../util/Table'

export default function MarkDownPage({
  sampleData = {},
  markdownTabDataKeyLabels = [],
  updateSamplePageCallback = () => {},
  shootTypesData = [],
  pageName=''
}) {
  const itemCopy = Object.assign({}, sampleData)
  const { sampleId = '' } = itemCopy
  const markdownTabDataKeyLabelsCopy = [...markdownTabDataKeyLabels]
  const [finalItemCopy, setFinalItemCopy] = useState({})
  const [isTheAPISucess, setIsTheAPISucess] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    return function cleanup() {
      setFinalItemCopy({})
      setIsTheAPISucess(false)
    }
  }, [])

  const sucessCallBack = (finalSampleData = {}) => {
    setFinalItemCopy(finalSampleData)
    setIsTheAPISucess(true)
  }

  useEffect(() => {
    if (!isEmpty(String(sampleId))) {
      setFinalItemCopy(itemCopy)
      dispatch(getLastShipDate(itemCopy, sucessCallBack))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleId, dispatch])

  return (
    <div>
      {!!isTheAPISucess && Object.entries(finalItemCopy).length > 0
        ? makePopUpTable(
            finalItemCopy,
            markdownTabDataKeyLabelsCopy,
            updateSamplePageCallback,
            shootTypesData,
            {},
            pageName
          )
        : ''}
    </div>
  )
}
