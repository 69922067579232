import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  itemId: {
    marginRight: theme.spacing(1),
  },
})

class NewSamplePopUp extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      itemId: '',
      quantity: '',
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleCreate = () => {
    const { duplicateSamples } = this.props
    duplicateSamples({
      itemId: this.state.itemId,
      quantity: this.state.quantity,
      dueDate: '0001-01-01',
    })
  }

  render() {
    const { classes, isVisible, onClose } = this.props
    return (
      <Dialog
        aria-labelledby="Add Samples"
        aria-describedby="Add a sample using a TCIN, DPCI, or UPC and a location"
        open={isVisible}
        onClose={onClose}
      >
        <DialogTitle>Create A Sample</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.itemId}
            id="itemId"
            name="itemId"
            label="TCIN, DPCI, or UPC"
            type="text"
            margin="normal"
            variant="outlined"
            onChange={this.handleChange}
          />
          <TextField
            id="quantity"
            name="quantity"
            label="Quantity"
            type="text"
            margin="normal"
            variant="outlined"
            onChange={this.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            id="createSamples"
            onClick={this.handleCreate}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(NewSamplePopUp)
