import {
  locationLevels,
  levelsMappersObj,
  levelsToParentMappersObj,
  defaultLocationsByLevels,
  defaultValueByLevels,
  createLocationDefaultObj,
  defaultLocationLabelByLevels,
  locationsLevelLabels,
} from '../enums/locations'
import { pick, omit, isEmpty, omitBy } from 'lodash'

export const locationStrByData = (locationData = {}) => {
  const locationDataList = pick(
    locationData,
    Object.keys(createLocationDefaultObj)
  )
  const locationDataListValues = Object.values(locationDataList) || []
  const locationDataStr = locationDataListValues.filter((x) => x).join(' > ')
  return locationDataStr
}

export const findLocationLevelId = (location = '') => {
  const currentLocationIndex =
    Object.keys(locationsLevelLabels).indexOf(location)
  return currentLocationIndex !== -1
    ? Object.keys(locationsLevelLabels).indexOf(location) + Number(1)
    : false
}

export const findEditLevelFromLocation = (locationData = {}) => {
  const locationDataList = pick(
    locationData,
    Object.keys(createLocationDefaultObj)
  )
  //pick up the empty valued level from the location
  const pickByValues = omitBy(locationDataList, isEmpty)
  //first Key of the empty values object
  // const editColumnName = get(keys(pickByValues), -1)
  const editColumnName = Object.keys(pickByValues).pop()
  return !isEmpty(editColumnName) && editColumnName !== 'container'
    ? [editColumnName]
    : []
}

export const locationDataToPrint = (locationData = {}) => {
  const locationDataList = pick(
    locationData,
    Object.keys(createLocationDefaultObj) || {}
  )
  return locationDataList
}

export const resetSelectedValues = (
  level = '',
  levelValue = {},
  selectedValueByLevel = {}
) => {
  let newArrObj = []
  let isObjExists = true
  Object.values(locationLevels).map((obj) => {
    if (obj === level) {
      isObjExists = false
    }
    if (isObjExists) newArrObj.push(obj)
    return obj
  })

  const remainingLevels = omit(defaultValueByLevels, newArrObj)
  const objListTillCurrentLevel = Object.assign(
    {},
    pick(selectedValueByLevel, newArrObj),
    remainingLevels,
    { [level]: levelValue }
  )
  return objListTillCurrentLevel
}

export const resetSelectedLevelLocations = (
  level = '',
  levelLocations = {},
  locationsByLevels = {}
) => {
  let newArrObj = []
  let isObjExists = true
  Object.values(locationLevels).map((obj) => {
    if (obj === level) {
      isObjExists = false
    }
    if (isObjExists) newArrObj.push(obj)
    return obj
  })
  const currentLevelObj = isEmpty(level) ? {} : { [level]: levelLocations }
  const remainingLevels = omit(defaultLocationsByLevels, newArrObj)
  const objListTillCurrentLevel = Object.assign(
    {},
    pick(locationsByLevels, newArrObj),
    remainingLevels,
    currentLevelObj
  )
  return objListTillCurrentLevel
}

export const resetSelectedValueByLevel = (
  level = '',
  locationLevelLabel = '',
  selectedValueByLevel = {}
) => {
  let newArrObj = []
  let isObjExists = true
  Object.values(levelsMappersObj).map((obj) => {
    if (obj === level) {
      isObjExists = false
    }
    if (isObjExists) newArrObj.push(obj)
    return obj
  })
  const remainingLevels = omit(defaultLocationLabelByLevels, newArrObj)
  const objListTillCurrentLevel = Object.assign(
    {},
    pick(selectedValueByLevel, newArrObj),
    remainingLevels,
    { [level]: locationLevelLabel }
  )
  return objListTillCurrentLevel
}

export const resetSelectedLocationIdByLevel = (
  level = '',
  locationLevelLabel = '',
  selectedValueByLevel = {}
) => {
  let newArrObj = []
  let isObjExists = true
  Object.values(levelsMappersObj).map((obj) => {
    if (obj === level) {
      isObjExists = false
    }
    if (isObjExists) newArrObj.push(obj)
    return obj
  })
  const remainingLevels = omit(defaultLocationLabelByLevels, newArrObj)
  const objListTillCurrentLevel = Object.assign(
    {},
    pick(selectedValueByLevel, newArrObj),
    remainingLevels,
    { [level]: locationLevelLabel }
  )
  return objListTillCurrentLevel
}

export const resetLevelsWithLabels = (
  level = '',
  locationLevelLabel = '',
  locationsByLevels = {}
) => {
  let newArrObj = []
  let isObjExists = true
  Object.keys(createLocationDefaultObj).map((obj) => {
    if (obj === level) {
      isObjExists = false
    }
    if (isObjExists) newArrObj.push(obj)
    return obj
  })
  const remainingLevels = omit(createLocationDefaultObj, newArrObj)
  const objListTillCurrentLevel = Object.assign(
    {},
    pick(locationsByLevels, newArrObj),
    remainingLevels,
    { [level]: locationLevelLabel }
  )
  return objListTillCurrentLevel
}

export const resetLevelsToCreateLocation = (
  level = '',
  locationLevelLabel = '',
  levelsToCreateLocation = {}
) => {
  let newArrObj = []
  let isObjExists = true
  Object.keys(createLocationDefaultObj).map((obj) => {
    if (obj === level) {
      isObjExists = false
    }
    if (isObjExists) newArrObj.push(obj)
    return obj
  })
  const remainingLevels = omit(createLocationDefaultObj, newArrObj)
  const objListTillCurrentLevel = Object.assign(
    {},
    pick(levelsToCreateLocation, newArrObj),
    remainingLevels,
    { [level]: locationLevelLabel }
  )
  return objListTillCurrentLevel
}

const getLocationParent = (
  entry,
  nextLevel,
  locationsByLevels = {},
  parentLabel = ''
) => {
  const { parent_location_id: parentId } = entry
  const parentLevel = levelsToParentMappersObj[nextLevel]
  if (!parentId || !locationsByLevels[parentLevel]) {
    return ''
  }

  if (nextLevel !== 'aisle') {
    locationsByLevels[parentLevel].some((data) => {
      const { location_id: locationId } = data
      if (locationId === parentId) {
        parentLabel = `${data[parentLevel]} > ${parentLabel}`
        parentLabel = getLocationParent(
          data,
          parentLevel,
          locationsByLevels,
          parentLabel
        )
        return true
      }
      return false
    })
  }
  if (parentLabel.charAt(parentLabel.length - 2) === '>') {
    parentLabel = parentLabel.slice(0, -2)
  }
  return parentLabel
}

export const convertToDropdownOptions = (
  options,
  nextLevel,
  locationsByLevels = []
) => {
  return options.map((entry) => {
    let parentLabel = getLocationParent(entry, nextLevel, locationsByLevels)
    return {
      value: entry.location_id,
      label: entry[nextLevel],
      currentLevel: nextLevel,
      parentLabel,
    }
  })
}
