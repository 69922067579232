import { createContext, useContext } from 'react'

const PartnerContext = createContext(null)

export function usePartnerContext() {
  const context = useContext(PartnerContext)
  if (!context) {
    throw new Error(
      'Partner.* component must be rendered as child of Partner component'
    )
  }
  return context
}

export default PartnerContext
