import { SvgIcon } from '@material-ui/core'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import DescriptionIcon from '@material-ui/icons/Description'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

function BoxOpenIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z" />
    </SvgIcon>
  )
}
export const searchByOptions = {
  DESC: 'New to Old',
  ASC: 'Old to New',
}
export const PER_PAGE_CONST = 10

export const displayAdditionalData = {
  SAMPLE_INITIATED: [],
  SAMPLE_ORDERED: ['order_num'],
  SAMPLE_SHIPPED: ['shipment_id', 'ship_to_loc', 'shipment_channel'],
  SAMPLE_RECEIVED: ['shipment_id', 'ship_to_loc', 'is_bundled'],
  SAMPLE_UNARCHIVED: [],
  SAMPLE_ARCHIVED: [],
  SAMPLE_HOLD: ['shipment_id', 'ship_to_loc', 'shipment_channel'],
  SAMPLE_SHOOT_SHIPPED: ['shipment_id', 'ship_to_loc', 'shipment_channel'],
  PROJECT: ['project_id'],
  COLLECTION: ['collection_id'],
}
export const additionalDataNames = {
  ship_to_loc: 'Ordered to: ',
  ship_to_loc_shipped: 'Shipped to: ',
  shipment_id: 'Shipment ',
  order_num: '# ',
  project_id: ' ',
  collection_id: '',
  is_bundled: 'Bundled item: ',
  shipment_channel: 'Shipment Channel: ',
}
export const hrefPagesList = {
  project_id: 'projects',
  collection_id: 'collections',
  shipment_id: 'shipSamplesList',
}
export const filterMomentsLabel = {
  LOCATION: 'location',
  SAMPLE_SHIPPED: 'shipped',
  COLLECTION: 'project_collection',
  SAMPLE_RECEIVED: 'received',
  SAMPLE_DISPOSED: 'disposed',
  NOTES: 'note',
  PROJECT: 'project_collection',
  SAMPLE_ORDERED: 'ordered',
  SAMPLE_HOLD: 'held_for_photography',
  SAMPLE_UNARCHIVED: 'unarchived',
  SAMPLE_ARCHIVED: 'archived',
  SAMPLE_INITIATED: 'initiated',
  SAMPLE_SHOOT_SHIPPED: 'shipped',
  LAUNCH_DATE: 'launch_date',
}
export const filtersByOptions = {
  heading: 'MOMENTS',
  ordered: {
    label: 'Ordered',
    icon: <ShoppingCartIcon />,
    value: ['SAMPLE_ORDERED'],
  },
  received: {
    label: 'Received',
    icon: <BoxOpenIcon viewBox="0 0 640 512" />,
    value: ['SAMPLE_RECEIVED'],
  },
  archived: {
    label: 'Archived',
    icon: <ArchiveIcon />,
    value: ['SAMPLE_ARCHIVED'],
  },
  unarchived: {
    label: 'Unarchived',
    icon: <UnarchiveIcon />,
    value: ['SAMPLE_UNARCHIVED'],
  },
  shipped: {
    label: 'Shipped',
    icon: <LocalShippingIcon />,
    value: ['SAMPLE_SHIPPED', 'SAMPLE_SHOOT_SHIPPED'],
  },
  held_for_photography: {
    label: 'Held for Photography',
    icon: <PauseCircleFilledIcon />,
    value: ['SAMPLE_HOLD'],
  },
  project_collection: {
    label: 'Project/Collection',
    icon: <DeviceHubIcon />,
    value: ['PROJECT', 'COLLECTION'],
  },
  note: { label: 'Notes', icon: <DescriptionIcon />, value: ['NOTES'] },
  location: {
    label: 'Location',
    icon: <GpsFixedIcon />,
    value: ['LOCATION'],
  },
  cancelled: {
    label: 'Cancelled',
    icon: <RemoveShoppingCartIcon />,
    value: ['SAMPLE_CANCELLED'],
  },
  disposed: {
    label: 'Disposed',
    icon: <DeleteForeverIcon />,
    value: ['SAMPLE_DISPOSED'],
  },
  launch_date: {
    label: 'Launch Date',
    icon: <DateRangeIcon />,
    value: ['LAUNCH_DATE'],
  },
}
export const changeTypeConsts = {
  ADD_PROP: {
    displayNameList: {
      ADD_PROP: 'Added the Prop',
    },
    displayIconList: {
      add_prop: <AddCircleIcon />,
      DEFAULT: '',
    },
  },
  ADD_SCENIC: {
    displayNameList: {
      ADD_SCENIC: 'Added the Scenic',
    },
    displayIconList: {
      add_scenic: <AddCircleIcon />,
      DEFAULT: '',
    },
  },
  CONVERTED_TO_PROP: {
    displayNameList: {
      CONVERTED_TO_PROP: 'Converted The Sample To Prop',
    },
    displayIconList: {
      converted_to_prop: <ChangeCircleIcon />,
      DEFAULT: '',
    },
  },
  STATUS: {
    displayNameList: {
      SAMPLE_INITIATED: 'Order Initiated',
      SAMPLE_ORDERED: 'Ordered',
      SAMPLE_SHIPPED: 'Shipped from Vendor',
      SAMPLE_RECEIVED: 'Received',
      SAMPLE_ARCHIVED: 'Archived',
      SAMPLE_UNARCHIVED: 'Unarchived',
      SAMPLE_HOLD: 'Held for Photography',
      SAMPLE_SHOOT_SHIPPED: 'Shipped for Photography',
      SAMPLE_DISPOSED: 'Disposed',
      SAMPLE_CANCELLED: 'Cancelled',
      DEFAULT: 'Status',
    },
    displayHrefName: {
      SAMPLE_SHOOT_SHIPPED: 'shipment_id',
      SAMPLE_RECEIVED: 'shipment_id',
    },
    displayIconList: {
      SAMPLE_INITIATED: <ShoppingCartIcon />,
      SAMPLE_ORDERED: <ShoppingCartIcon />,
      SAMPLE_SHIPPED: <LocalShippingIcon />,
      SAMPLE_RECEIVED: <BoxOpenIcon viewBox="0 0 640 512" />,
      SAMPLE_UNARCHIVED: <UnarchiveIcon />,
      SAMPLE_ARCHIVED: <ArchiveIcon />,
      SAMPLE_HOLD: <PauseCircleFilledIcon />,
      SAMPLE_SHOOT_SHIPPED: <LocalShippingIcon />,
      SAMPLE_DISPOSED: <DeleteForeverIcon />,
      SAMPLE_CANCELLED: <RemoveShoppingCartIcon />,
      ADD_PROP: <ShoppingCartIcon />,
      ADD_SCENIC: <ShoppingCartIcon />,
      CONVERTED_TO_PROP: <ShoppingCartIcon />,
      DEFAULT: '',
    },
    displayAddtionData: true,
    displaySecondaryText: true,
  },
  NOTES: {
    displayNameList: {
      VENDOR_NOTES: 'Vendor Notes',
      STUDIO_NOTES: 'Studio Notes',
      WAREHOUSE_NOTES: 'Warehouse Notes',
      DISPOSITION_NOTES: 'Disposition Notes',
      DEFAULT: 'Notes',
    },
    displayIconList: {
      DEFAULT: <DescriptionIcon />,
    },
    displayAddtionData: false,
    displaySecondaryText: true,
  },
  PROJECT: {
    displayNameList: {
      PROJECT: 'Project',
      DEFAULT: 'Project',
    },
    displayHrefName: {
      PROJECT: 'project_id',
      DEFAULT: 'project_id',
    },
    displayIconList: {
      DEFAULT: <DeviceHubIcon />,
    },
    displayAddtionData: false,
    displaySecondaryText: true,
  },
  COLLECTION: {
    displayNameList: {
      COLLECTION: 'Collection',
      DEFAULT: 'Collection',
    },
    displayHrefName: {
      COLLECTION: 'collection_id',
      DEFAULT: 'collection_id',
    },
    displayIconList: {
      DEFAULT: <DeviceHubIcon />,
    },
    displayAddtionData: false,
    displaySecondaryText: true,
  },
  LOCATION: {
    displayNameList: {
      LOCATION: 'Location',
      DEFAULT: 'Location',
    },
    displayIconList: {
      DEFAULT: <GpsFixedIcon />,
    },
    displayAddtionData: false,
    displaySecondaryText: true,
  },
  LAUNCH_DATE: {
    displayNameList: {
      LAUNCH_DATE: 'Launch date',
      DEFAULT: 'Launch date',
    },
    displayIconList: {
      DEFAULT: <DateRangeIcon />,
    },
    displayAddtionData: false,
    displaySecondaryText: true,
  },
}

export const changeTypePrimaryText = {
  PROJECT: 'added the sample to below',
}

export const changeTypePropText = {
  ADD_PROP: 'Add Prop',
  CONVERTED_TO_PROP: 'Converted The Sample To Prop',
  ADD_SCENIC: 'Add Scenic'
}
