export const ADD_SAMPLES_REQUEST_SUCCESS = 'ADD_SAMPLES_REQUEST_SUCCESS'
export const ADD_SAMPLES_REQUEST_PENDING = 'ADD_SAMPLES_REQUEST_PENDING'
export const ADD_SAMPLES_REQUEST_FAILURE = 'ADD_SAMPLES_REQUEST_FAILURE'
export const CLOSE_SHOOT_SHIP_PAGE_ALERT = 'CLOSE_SHOOT_SHIP_PAGE_ALERT'
export const FETCH_SHIP_TO_LOCATION_REQUEST_SUCCESS =
  'FETCH_SHIP_TO_LOCATION_REQUEST_SUCCESS'
export const FETCH_SHIP_FROM_LOCATION_REQUEST_SUCCESS =
  'FETCH_SHIP_FROM_LOCATION_REQUEST_SUCCESS'
export const FETCH_SHIP_LOCATION_REQUEST_PENDING =
  'FETCH_SHIP_LOCATION_REQUEST_PENDING'
export const FETCH_SHIP_LOCATION_REQUEST_FAILURE =
  'FETCH_SHIP_LOCATION_REQUEST_FAILURE'
export const SHOOT_SHIP_SET_CHECKBOX = 'SHOOT_SHIP_SET_CHECKBOX'
export const SHOOT_SHIP_SET_CHECKBOX_HEADER = 'SHOOT_SHIP_SET_CHECKBOX_HEADER'
export const SHOOT_SAMPLE_CLEAR_DATA = 'SHOOT_SAMPLE_CLEAR_DATA'
export const SHOOT_SAMPLE_SET_UNKNOWN_DATA = 'SHOOT_SAMPLE_SET_UNKNOWN_DATA'
export const SHOOT_SAMPLE_SET_PAGE = 'SHOOT_SAMPLE_SET_PAGE'
export const SHOOT_SAMPLE_SET_ROWS_PER_PAGE = 'SHOOT_SAMPLE_SET_ROWS_PER_PAGE'
export const SHOW_SHOOT_SHIP_PAGE_ALERT = 'SHOW_SHOOT_SHIP_PAGE_ALERT'
export const SHOOT_SHIP_CREATE_REQUEST_SUCCESS =
  'SHOOT_SHIP_CREATE_REQUEST_SUCCESS'
export const SHOOT_SHIP_CREATE_REQUEST_PENDING =
  'SHOOT_SHIP_CREATE_REQUEST_PENDING'
export const SHOOT_SHIP_CREATE_REQUEST_FAILURE =
  'SHOOT_SHIP_CREATE_REQUEST_FAILURE'
export const SHOOT_SHIP_DELETE_REQUEST_SUCCESS =
  'SHOOT_SHIP_DELETE_REQUEST_SUCCESS'
export const SHOOT_SHIP_DELETE_REQUEST_PENDING =
  'SHOOT_SHIP_DELETE_REQUEST_PENDING'
export const SHOOT_SHIP_DELETE_REQUEST_FAILURE =
  'SHOOT_SHIP_DELETE_REQUEST_FAILURE'
export const SHOOT_SHIP_UPDATE_REQUEST_SUCCESS =
  'SHOOT_SHIP_UPDATE_REQUEST_SUCCESS'
export const SHOOT_SHIP_UPDATE_REQUEST_PENDING =
  'SHOOT_SHIP_UPDATE_REQUEST_PENDING'
export const SHOOT_SHIP_UPDATE_REQUEST_FAILURE =
  'SHOOT_SHIP_UPDATE_REQUEST_FAILURE'
export const REMOVE_SAMPLE_REQUEST_SUCCESS = 'REMOVE_SAMPLE_REQUEST_SUCCESS'
export const REMOVE_SAMPLE_REQUEST_PENDING = 'REMOVE_SAMPLE_REQUEST_PENDING'
export const REMOVE_SAMPLE_REQUEST_FAILURE = 'REMOVE_SAMPLE_REQUEST_FAILURE'
export const SHOW_SHOOT_SHIP_POPUP = 'SHOW_SHOOT_SHIP_POPUP'
export const CLOSE_SHOOT_SHIP_POPUP = 'CLOSE_SHOOT_SHIP_POPUP'
export const SHOOT_SHIP_GET_REQUEST_PENDING = 'SHOOT_SHIP_GET_REQUEST_PENDING'
export const SHOOT_SHIP_GET_REQUEST_SUCCESS = 'SHOOT_SHIP_GET_REQUEST_SUCCESS'
export const SHOOT_SHIP_GET_REQUEST_FAILURE = 'SHOOT_SHIP_GET_REQUEST_FAILURE'
export const RESET_NEW_SHIPMENT_STATE = 'RESET_NEW_SHIPMENT_STATE'
export const SET_REDIRECT_TO_NEW_SHIPMENT = 'SET_REDIRECT_TO_NEW_SHIPMENT'
export const UNAVAILABLE_SAMPLES_SUCCESS = 'UNAVAILABLE_SAMPLES_SUCCESS'
export const UNAVAILABLE_SAMPLES_PENDING = 'UNAVAILABLE_SAMPLES_PENDING'
export const UNAVAILABLE_SAMPLES_FAILURE = 'UNAVAILABLE_SAMPLES_FAILURE'
export const CLOSE_UNAVAILABLE_SAMPLES_POPUP = 'CLOSE_UNAVAILABLE_SAMPLES_POPUP'
export const SHOOT_SHIP_CALL_EDIT_FIELDS_API = 'SHOOT_SHIP_CALL_EDIT_FIELDS_API'
export const UPDATE_BUNDLED_QUANTITY = 'UPDATE_BUNDLED_QUANTITY'
export const CLEAR_STATE_DATA = 'CLEAR_STATE_DATA'
