import { useState } from 'react'
import { cloneDeep, uniqWith, isEqual } from 'lodash'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { usePartnerContext } from './PartnerContext'
import ShowIf from 'components/ShowIf'

const filter = createFilterOptions()

const getPartnersOfSameType = (partners, partnerType) =>
  partners.filter((partner) => partner.partner_type === partnerType)

function AddNewPartners({ allowCreateNew = true, handleUpdate }) {
  const {
    allPartnersList = [],
    partners = [],
    updateShowHidePartnerInputFields,
    selectedPartnerType,
  } = usePartnerContext()
  const [updatedPartners, setUpdatedPartners] = useState(partners)

  const partnersFilteredByType = getPartnersOfSameType(
    updatedPartners,
    selectedPartnerType
  )
  const allPartnersListSameType = getPartnersOfSameType(
    allPartnersList,
    selectedPartnerType
  )

  const options = [
    ...new Set(...[allPartnersListSameType, partnersFilteredByType]),
  ]

  /**
   * Updating the partner list by first removing all partners of same type: eg Stylists
   * And then adding back the all the selected partners for that partner type including
   * the Newing created partners
   */
  const handleChange = (e, selectedOptions) => {
    const existingPartners = cloneDeep(updatedPartners)
    let updatedPartnersList = existingPartners.reduce(
      (accumulator, partner) => {
        if (partner.partner_type !== selectedPartnerType) {
          accumulator.push(partner)
        }
        return accumulator
      },
      [...selectedOptions]
    )
    updatedPartnersList = uniqWith(updatedPartnersList, isEqual)
    setUpdatedPartners(updatedPartnersList)
  }

  return (
    <Grid container>
      <ShowIf condition={!!selectedPartnerType}>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            includeInputInList
            id="shipment-partners"
            options={options}
            onChange={handleChange}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option
              }
              // Add prefix to option created dynamically
              if (option.newValue) {
                return `Add ${option.partner_name}`
              }
              // Regular option
              return option.partner_name
            }}
            disableCloseOnSelect
            value={partnersFilteredByType}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return (
                  <Chip
                    variant="outlined"
                    label={option.partner_name}
                    {...getTagProps({ index })}
                  />
                )
              })
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Partner names" />
            )}
            selectOnFocus
            clearOnBlur
            freeSolo
            filterOptions={(options, params) => {
              const filtered = filter(options, params)
              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) =>
                  inputValue?.toLowerCase() ===
                  option.partner_name?.toLowerCase()
              )
              if (inputValue.length > 2 && !isExisting && allowCreateNew) {
                filtered.push({
                  partner_type: selectedPartnerType,
                  partner_name: inputValue,
                  newValue: true,
                })
              }

              return filtered
            }}
          />
        </Grid>
      </ShowIf>

      <Grid item xs={1} />
      <Grid
        item
        xs={2}
        style={{
          paddingTop: '8px',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => updateShowHidePartnerInputFields(false)}
        >
          Cancel
        </Button>
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          paddingTop: '8px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isEqual(updatedPartners, partners)}
          onClick={() => handleUpdate(updatedPartners)}
        >
          Add Partner
        </Button>
      </Grid>
    </Grid>
  )
}

export default AddNewPartners
