import { isEmpty } from 'lodash'

import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid'

import TabbedPopUp from 'components/PopUp/TabbedPopUp'

import { Error } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import FlexibleTable from 'components/EnhancedTable/FlexibleTable'

import { sampleStatuses } from 'util/ConstUtils'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { TableCellthemeNew } from 'config/themeConfig'
import Divider from '@material-ui/core/Divider'

import { dataTabLabelsShipped } from 'enums/tableTabs'
import { makeTabTables, getTitle } from 'util/Table'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ShowIf from 'components/ShowIf'
import { ReportProblemOutlined } from '@mui/icons-material'

const popupStyles = {
  errorBlock: {
    display: 'flex',
    color: '#B85300',
    alignItems: 'center',
  },
  errorDownloadIcon: { marginLeft: '60%' },
  clearIcon: {
    color: '#aaa !important',
  },
}

const displayUnavailableSamples = (
  unavailableSamples,
  unavailableSampleColumnData,
  unavailableSampleLocStatusData,
  clickableColumn,
  unavailableSamplePopUpIsVisible,
  downloadConflict = () => {}
) => {
  const shipmentConfilcts = unavailableSamples
    .filter((obj) => obj.field === 'shipment_conflict')
    .map((obj) => {
      const { resource: objResource = {} } = obj
      const { status: objResourceStatus = '' } = objResource
      const resourceObj = Object.assign({}, objResource, {
        status:
          sampleStatuses
            .filter((status) => objResourceStatus === status.key)
            .map((status) => status.value)
            .toString() || objResourceStatus,
      })
      return resourceObj
    })
  const locStatusConfilcts = unavailableSamples
    .filter(
      (obj) =>
        [
          'status_location_conflict',
          'location_conflict',
          'status_conflict',
        ].indexOf(obj.field) !== -1
    )
    .map((obj) => {
      const { resource: objResource = {}, field: errorType = '' } = obj
      const { status: objResourceStatus = '' } = objResource
      const resourceObj = Object.assign({}, objResource, {
        status:
          sampleStatuses
            .filter((status) => objResourceStatus === status.key)
            .map((status) => status.value)
            .toString() || objResourceStatus,
      })

      const updatedResourceObj =
        formatStatusLocationLabel(resourceObj, errorType) || {}
      return Object.assign({}, obj.resource, updatedResourceObj)
    })
  const FlexibleTableNew = withStyles((theme) => ({
    root: {
      boxShadow: 'unset !important',
    },
    container: {
      maxHeight: 'unset !important',
    },
    header: {
      backgroundColor: 'unset',
      fontWeight: 700,
    },
  }))(FlexibleTable)
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        The following samples/props were not added to this shipment because they
        have the following conflicts:
      </Grid>
      {shipmentConfilcts.length > 0 && (
        <>
          <Grid item xs={12}>
            <span
              style={{
                margin: '0 10px 0 36px',
                color: '#FF7A00',
                float: 'left',
              }}
            >
              <ReportProblemOutlined />
            </span>
            <div>
              The following samples/props <b>have been shipped elsewhere</b>:
            </div>
          </Grid>
          <Grid item xs={12}>
            <MuiThemeProvider theme={TableCellthemeNew}>
              <FlexibleTableNew
                cascadeFieldKeys={[]}
                data={shipmentConfilcts}
                disableItems
                disableTablePagination
                disableTableSorting
                columnData={unavailableSampleColumnData}
                disableItemCount
                showPopup={() => {}}
                clickableColumn={clickableColumn}
                enableToolbar={false}
                enableSpacer={false}
                cellTheme={TableCellthemeNew}
                enableMultiSearch
              />
            </MuiThemeProvider>
          </Grid>
        </>
      )}
      {shipmentConfilcts.length > 0 && locStatusConfilcts.length > 0 && (
        <Grid item xs={12}>
          <Divider style={{ margin: '0px', height: '1px' }} variant="inset" />
        </Grid>
      )}
      {locStatusConfilcts.length > 0 && (
        <>
          <Grid item xs={12}>
            <div style={{ margin: '24px 40px 15px 40px' }}>
              <span
                style={{
                  margin: '8px 10px 0 0',
                  color: '#FF7A00',
                  float: 'left',
                }}
              >
                <ReportProblemOutlined />
              </span>
              <div>
                The following have a conflcting <b>status and/or location</b>:
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <MuiThemeProvider theme={TableCellthemeNew}>
              <FlexibleTableNew
                cascadeFieldKeys={[]}
                data={locStatusConfilcts}
                disableItems
                disableTablePagination
                disableTableSorting
                columnData={unavailableSampleLocStatusData}
                disableItemCount
                showPopup={() => {}}
                clickableColumn={clickableColumn}
                enableToolbar={false}
                enableSpacer={false}
                cellTheme={TableCellthemeNew}
              />
            </MuiThemeProvider>
          </Grid>
        </>
      )}
      <ShowIf condition={unavailableSamplePopUpIsVisible}>
        <Button
          color="primary"
          onClick={downloadConflict}
          variant="outlined"
          startIcon={<Error />}
          style={popupStyles.errorDownloadIcon}
        >
          DOWNLOAD THIS SAMPLE DATA
        </Button>
      </ShowIf>
    </Grid>
  )
}

const renderErrorText = (renderText = '') => {
  return !isEmpty(renderText) ? (
    <div style={popupStyles.errorBlock}>
      <ErrorOutlineIcon />
      {renderText}
    </div>
  ) : (
    ''
  )
}

const formatStatusLocationLabel = (resourceObj = {}, errorType = '') => {
  const { status: errorStatus = '', location: errorLocation = '' } = resourceObj

  let renderStatus = errorStatus
  let renderLocation = errorLocation

  switch (errorType) {
    case 'status_conflict':
      renderStatus = renderErrorText(renderStatus)
      break
    case 'location_conflict':
      renderLocation = renderErrorText(renderLocation)
      break
    case 'status_location_conflict':
      renderStatus = renderErrorText(renderStatus)
      renderLocation = renderErrorText(renderLocation)
      break
    default:
      break
  }
  return {
    status: renderStatus,
    location: renderLocation,
  }
}

const showUnAvailSamplesPopup = (unavailableSamples = []) => {
  return (
    unavailableSamples.length > 0 &&
    unavailableSamples.some(
      (obj) =>
        [
          'shipment_conflict',
          'status_location_conflict',
          'location_conflict',
          'status_conflict',
        ].indexOf(obj.field) !== -1
    )
  )
}

const downloadConflictSampleCSV = (
  shipFromLocName,
  shipmentId = '',
  unavailableSamples = [],
  auth = {},
  downloadConflictSamples
) => {
  const {
    session: {
      userInfo: { lanId = '' },
    },
  } = auth
  const pgIds = [
    ...new Set(
      unavailableSamples.map((error = {}) => {
        const {
          resource: { pegasusId = '' },
        } = error
        return pegasusId.toString().substring(2, pegasusId.length)
      })
    ),
  ]
  downloadConflictSamples(pgIds, lanId, shipmentId, shipFromLocName)
}

const UnavailableShipmentSamplesAndProps = ({
  classes,
  popupIsVisible,
  popupItem,
  shipmentData,
  closePopup,
  unavailableSamplePopUpIsVisible,
  unavailableSamples = [],
  unavailableSampleColumnData,
  unavailableSampleLocStatusData,
  updateSampleFieldsData,
  clickableColumn,

  shipmentId = '',
  auth = {},
  downloadConflictSamples,
  shipFromLocName,
}) => {
  const downloadConflict = () =>
    downloadConflictSampleCSV(
      shipFromLocName,
      shipmentId,
      unavailableSamples,
      auth,
      downloadConflictSamples
    )
  return (
    <>
      <TabbedPopUp
        dataTabLabels={dataTabLabelsShipped}
        isVisible={popupIsVisible}
        data={makeTabTables(
          popupItem,
          classes,
          dataTabLabelsShipped,
          updateSampleFieldsData,
          shipmentData
        )}
        onClose={closePopup}
        title={getTitle(popupItem)}
        fullWidth={true}
      />
      {showUnAvailSamplesPopup(unavailableSamples)
        ? displayUnavailableSamples(
            unavailableSamples,
            unavailableSampleColumnData,
            unavailableSampleLocStatusData,
            clickableColumn,
            unavailableSamplePopUpIsVisible,
            downloadConflict
          )
        : ''}
    </>
  )
}

export default UnavailableShipmentSamplesAndProps
