export const data = [
  {
    name: 'tilak',
    scannedOn: '12/08/2023',
    scannedBy: 'tilak',
    id: 1,
    ids: 35,
  },
  {
    name: 'siddhi',
    scannedOn: '12/08/2023',
    scannedBy: 'tilak',
    id: 2,
    ids: 38,
  },
  {
    name: 'ankitha',
    scannedOn: '12/08/2023',
    scannedBy: 'tilak',
    id: 3,
    ids: 41,
  },
  {
    name: 'bharath',
    scannedOn: '12/08/2023',
    scannedBy: 'tilak',
    id: 4,
    ids: 77,
  },
  {
    name: 'mallik',
    scannedOn: '12/08/2023',
    scannedBy: 'tilak',
    id: 5,
    ids: 78,
  },
  {
    name: 'durga',
    scannedOn: '12/08/2023',
    scannedBy: 'tilak',
    id: 6,
    ids: 55,
  },
]

export const columnData = [
  {
    id: 'batch_id',
    numeric: false,
    disablePadding: false,
    label: 'Batch Id',
    align: 'center',
  },
  {
    id: 'batch_name',
    numeric: false,
    disablePadding: false,
    label: 'Batch Name',
    align: 'center',
  },
  {
    id: 'importedIds',
    numeric: false,
    disablePadding: false,
    label: '#Pegasus Ids',
    align: 'center',
  },
  {
    id: 'import_date',
    numeric: false,
    disablePadding: true,
    label: 'Imported Date',
    align: 'center',
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'Imported By',
    textAlign: 'right',
  },
]
export const textFieldData = {
  id: 'batchName',
  label: 'Batch Name',
  placeholder: 'Enter Batch Name',
}
export const headerFieldData = {
  headerText: 'Batch',
}
