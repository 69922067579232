import * as React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1 / 2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default function CustomizedAccordions({ filterComponents = {} }) {
  const { defaultPanel = '', panelsList = [] } = filterComponents
  const [expanded, setExpanded] = React.useState(defaultPanel)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  const selectedPannel = expanded || defaultPanel

  return (
    <div>
      {panelsList.length > 0 &&
        panelsList.map((panelObj = {}) => {
          const {
            currentPanelId = '',
            currentPanelName = '',
            currentPanelDetails = '',
          } = panelObj
          return (
            <Accordion
              defaultExpanded={selectedPannel === currentPanelId}
              onChange={handleChange(currentPanelId)}
              key={currentPanelId}
            >
              <AccordionSummary
                aria-controls={`${currentPanelId}Content`}
                id={`${currentPanelId}Header`}
              >
                <Typography style={{ fontSize: '17px' }}>
                  {currentPanelName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{currentPanelDetails}</Typography>
              </AccordionDetails>
            </Accordion>
          )
        })}
    </div>
  )
}
