import MenuItem from '@mui/material/MenuItem'
import TextField from '@material-ui/core/TextField'
import { FormControl } from '@material-ui/core'
import { partnerTypes } from 'util/ConstUtils'
import { usePartnerContext } from './PartnerContext'

function PartnerType({ onSelect }) {
  const { selectedPartnerType, classes } = usePartnerContext()
  return (
    <FormControl variant="outlined" className={classes.SelectDropdown}>
      <TextField
        value={selectedPartnerType}
        onChange={onSelect}
        className={classes.shipToLoc}
        variant="outlined"
        select
        label="Partner Type"
      >
        {partnerTypes.map((partnerType) => (
          <MenuItem key={partnerType} value={partnerType}>
            {partnerType}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}

export default PartnerType
