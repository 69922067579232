import * as types from './actionType'

export const initialState = (window.Cypress && window.initialState) || {
  columnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: false,
      label: 'PG ID',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      textAlign: 'right',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'generalDescription',
      numeric: false,
      disablePadding: false,
      label: 'Item Description',
      textAlign: 'right',
    },
    {
      id: 'vendorId',
      numeric: true,
      disablePadding: false,
      label: 'Vendor ID',
      textAlign: 'right',
    },
    {
      id: 'vendorEmail',
      numeric: false,
      disablePadding: false,
      label: 'Input Emails',
      textAlign: 'right',
    },
    {
      id: 'shipTo',
      numeric: false,
      disablePadding: false,
      label: 'Ship To Location',
      textAlign: 'right',
    },
    {
      id: 'dueDate',
      numeric: false,
      disablePadding: false,
      label: 'Due Date',
    },
    {
      id: 'otherProjects',
      numeric: false,
      disablePadding: false,
      label: 'Projects/Collections',
      textAlign: 'right',
    },
    {
      id: 'vendorNotes',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Notes',
      textAlign: 'right',
    },
    {
      id: 'studioNotes',
      numeric: false,
      disablePadding: false,
      label: 'Studio Notes',
      textAlign: 'right',
    },
  ],
  reviewOrderColumnData: [
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      textAlign: 'right',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Item Description',
      textAlign: 'right',
    },
    {
      id: 'dueDate',
      numeric: false,
      disablePadding: false,
      label: 'Due Date',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'vendorId',
      numeric: true,
      disablePadding: false,
      label: 'Vendor ID',
      textAlign: 'right',
    },
    {
      id: 'vendorName',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Name',
      textAlign: 'right',
    },
    {
      id: 'quantity',
      numeric: true,
      disablePadding: false,
      label: 'Quantity',
      textAlign: 'right',
    },
    {
      id: 'shipTo',
      numeric: false,
      disablePadding: false,
      label: 'Ship To',
      textAlign: 'right',
    },
    {
      id: 'project',
      numeric: false,
      disablePadding: false,
      label: 'Projects/Collections',
      textAlign: 'right',
    },
  ],
  data: [],
  orderNumber: null,
  orderSamplesPending: false,
  orderSamplesSuccess: false,
  orderStatus: '',
}

export default function orderSamples(state = initialState, action = {}) {
  switch (action.type) {
    case types.RAISE_SAMPLES_ORDER_SUCCESS: {
      return Object.assign({}, state, {
        data: action.payload.samples,
        orderStatus: 'success',
        orderNumber:
          action.payload.samples.length > 0
            ? action.payload.samples[0].orderNum
            : null,
      })
    }
    case types.RAISE_SAMPLES_ORDER_PENDING: {
      return Object.assign({}, state, {
        orderSamplesPending: action.payload.pending,
      })
    }
    case types.RAISE_SAMPLES_ORDER_FAILURE: {
      return Object.assign({}, state, {
        orderSamplesPending: false,
        data: [],
        orderNumber: '',
        orderStatus: 'fail',
      })
    }
    case types.RESET_ORDER_SAMPLES_STATE: {
      return Object.assign({}, initialState)
    }
    default:
      return state
  }
}
