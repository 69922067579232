import React from 'react'
import * as propTypes from 'prop-types'
import { pickBy, isEqual, get } from 'lodash'
import withHOCs from 'util/withHocs'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close'
import { regexExpressions } from '../AddFilters/constants'
import CheckBoxAutoComplete from './CheckBoxAutoComplete' //need to convert into component
import { getAllLocationList } from 'store/searchInventory/actionCreator'
import {
  getAddFilterOptions,
  getAllBrandOptions,
  getAllCategoryOptions,
  getAllDepartmentsOptions,
  getFilterOptionsByFilter,
  getSelectedFilters,
} from '../../store/autoOrderFilters/selectors'
import {
  getAllBrands,
  getAllDepartments,
  getClassByMultiDepartment,
  setSelectedFilters,
} from '../../store/autoOrderFilters/actionCreator'
import { saveFilters } from '../../store/filters/actionCreator'
import { setFiltersAutoOrder } from '../../store/filters/actionCreator'
import {
  listInputs,
  formatListInputValue,
  popupFiltersUsedBy,
} from '../AddFilters/constants'
import { selectPageFilters } from '../../store/filters/selectors'
import { DATE_FORMAT } from '../../util/CommonUtils'
import {
  getDropdownData,
  setSubcatsByCatIds,
} from 'store/searchInventory/actionCreator'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  chipFilterTextField: {
    width: '100%',
  },
  applyButtonMargin: {
    margin: '15px',
    width: 'auto',
    height: '35px',
  },
  rootDiv: {
    width: '600px',
    display: 'flex',
    flexFlow: 'column wrap',
  },
  footDiv: {
    position: 'fixed',
    top: 'auto',
    right: 0,
    bottom: 0,
  },
  appBarToolBar: { display: 'flex', justifyContent: 'space-between' },
  renderContentDiv: { minHeight: '450px', height: 'auto' },
  renderContentInnerDiv: { margin: '15px 25px' },
})

class Filters extends React.Component {
  constructor(props) {
    super(props)
    const { filters = {}, selectedFilters = [] } = this.props
    this.state = {
      selectedFilters: selectedFilters || [],
      filterOptions: {},
      filters: filters,
      isDepartmentChanged: false,
      isCategoryChanged: false,
      previousFilters: filters,
      previousSelectedFilters: selectedFilters || [],
      firstRender: false,
      error: true,
    }
  }
  async componentDidMount() {
    const {
      getAllBrands = () => {},
      getAllDepartments = () => {},
      setFilters = () => {},
      pageName = 'AutoOrder',
      filters: propsFilters = {},
      getClassByMultiDepartment = () => {},
      getPropsDropdownData = () => {},
      getAllLocationList = () =>{}
    } = this.props
    const departmentFilter = get(propsFilters, 'Department', {})
    const selectedDepIds = get(departmentFilter, 'requestParamValue', [])
    const categoryFilter = get(propsFilters, 'Category', {})
    const selectedCatIds = get(categoryFilter, 'requestParamValue', [])
    await setFilters({}, pageName)
    await getAllBrands()
    await getAllDepartments()
    await getPropsDropdownData()
    await getAllLocationList()
    if (selectedDepIds.length > 0) {
      await getClassByMultiDepartment(selectedDepIds)
    }
    if (selectedCatIds.length > 0) {
      await this.getSubcatsByCatIds(selectedCatIds)
    }
    const { filters: alteredFilters = {} } = this.alterFilters()
    await setFilters(alteredFilters, pageName)
    await setFilters({}, pageName)
    this.setState({
      firstRender: true,
    })
  }
  alterFilters = () => {
    const { filters = {} } = this.state
    const filtersCopy = Object.assign({}, filters)
    let isEnteredDept = false
    let isEnteredCategory = false
    Object.keys(filtersCopy).map((obj) => {
      const requestedParams = filtersCopy[obj].requestParamValue || []
      if (requestedParams.length > 0) {
        switch (obj) {
          case 'Brand': {
            filtersCopy[obj]['value'] = this.props.allBrandOptions.filter(
              (filterValue) => requestedParams.indexOf(filterValue.label) !== -1
            )
            return true
          }
          case 'Department': {
            filtersCopy[obj]['value'] = this.props.allDepartmentsOptions.filter(
              (filterValue) => requestedParams.indexOf(filterValue.label) !== -1
            )
            isEnteredDept = true
            return true
          }
          case 'Class': {
            const classFilters = this.props.filterOptionsByFilter['Class'] || []
            filters[obj]['value'] = classFilters.filter(
              (filterValue) => requestedParams.indexOf(filterValue.label) !== -1
            )
            return true
          }
          case 'Category': {
            filtersCopy[obj]['value'] = (
              this.props.filterOptionsByFilter['Category'] || []
            ).filter(
              (filterValue) => requestedParams.indexOf(filterValue.label) !== -1
            )
            isEnteredCategory = true
            return true
          }
          case 'Sub Category': {
            const classFilters =
              this.props.filterOptionsByFilter['Sub Category'] || []
            filters[obj]['value'] = classFilters.filter(
              (filterValue) => requestedParams.indexOf(filterValue.label) !== -1
            )
            return true
          }
          case 'Props Color': {
            filtersCopy[obj]['value'] = (
              this.props.filterOptionsByFilter['Props Color'] || []
            ).filter(
              (filterValue) => requestedParams.indexOf(filterValue.label) !== -1
            )
            isEnteredCategory = true
            return true
          }
          case 'Location':{
            filtersCopy[obj]['value'] = (
              this.props.filterOptionsByFilter['Location'] || []
            ).filter(
              (filterValue) => requestedParams.indexOf(filterValue.label) !== -1
            )
            isEnteredCategory = true
            return true
          }
          case 'Launch Date': {
            filtersCopy[obj]['value'] = requestedParams
            filtersCopy[obj]['startDate'] = get(requestedParams, 0, null)
            filtersCopy[obj]['endDate'] = get(requestedParams, 1, null)
            return true
          }
          default: {
            filtersCopy[obj]['value'] = requestedParams
            return true
          }
        }
      }
      return obj
    })
    if (isEnteredDept || isEnteredCategory) {
      this.setState()
    }
    return {
      filters: filtersCopy,
      isEnteredDept: isEnteredDept,
      isEnteredCategory: isEnteredCategory,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      filters: stateFilters = {},
      isDepartmentChanged,
      isCategoryChanged,
    } = this.state
    const { getSubcatsByCatIds = () => {} } = this
    const { filters: prevStateFilters = {} } = prevProps
    const { getClassByMultiDepartment = () => {} } = this.props
    if (
      Object.entries(stateFilters).length > 0 &&
      !isEqual(stateFilters, prevStateFilters) &&
      isDepartmentChanged
    ) {
      //enter on filter update on department change
      const departmentFilter = get(stateFilters, 'Department', {})
      const selectedDepIds = get(departmentFilter, 'requestParamValue', [])
      this.setState((state) => {
        getClassByMultiDepartment(selectedDepIds)
        return {
          isDepartmentChanged: false,
        }
      })
    }
    if (
      Object.entries(stateFilters).length > 0 &&
      !isEqual(stateFilters, prevStateFilters) &&
      isCategoryChanged
    ) {
      //enter on filter update on department change
      const propCategoryFilter = get(stateFilters, 'Category', {})
      const selectedCatIds = get(propCategoryFilter, 'requestParamValue', [])
      this.setState((state) => {
        getSubcatsByCatIds(selectedCatIds)
        return {
          isCategoryChanged: false,
        }
      })
    }
  }
  getSubcatsByCatIds = (categories = [], catIdsList = []) => {
    const { setSubcatsByCatIds = () => {} } = this.props
    setSubcatsByCatIds(categories)
  }
  addFiltersChange =
    (label) =>
    (event, value = [], reason) => {
      const currentFilterLabel =
        event.target.innerText ||
        event.currentTarget.parentElement.innerText ||
        ''
      const { filters: stateFilters = {} } = this.state
      const filtersToPick = value.map((obj) => obj.label)
      let newFilters = pickBy(stateFilters, (value, key) => {
        return filtersToPick.indexOf(key) !== -1
      })
      let currentValueList = [...value]
      if (reason === 'removeOption') {
        currentValueList = currentValueList.filter(
          (obj) => obj.label !== currentFilterLabel
        )
      }
      if (
        Object.keys(newFilters)?.includes('Department') &&
        Object.keys(stateFilters)?.includes('Class')
      ) {
        newFilters['Class'] = stateFilters['Class']
      }
      if (
        Object.keys(newFilters)?.includes('Category') &&
        Object.keys(stateFilters)?.includes('Sub Category')
      ) {
        newFilters['Sub Category'] = stateFilters['Sub Category']
      }
      this.setState((state = {}) => {
        return {
          ...state,
          selectedFilters: currentValueList,
          filters: newFilters,
        }
      })
    }
  onCloseClick = (event) => {
    const {
      toggleDrawer = () => {},
      setFilters = () => {},
      pageName = 'AutoOrder',
      setSelectedFilters = () => {},
    } = this.props
    const { previousSelectedFilters = [], previousFilters = {} } = this.state
    const filtersCopy = Object.assign({}, previousFilters)
    toggleDrawer('right', false)(event)
    setFilters(filtersCopy, pageName)
    if (popupFiltersUsedBy.indexOf(pageName) !== -1) {
      setSelectedFilters(previousSelectedFilters, pageName)
    }
  }
  renderAppbar = (content = '') => {
    const { classes = {} } = this.props
    return (
      <AppBar
        position="sticky"
        color="transparent"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Toolbar className={classes.appBarToolBar}>
          <Typography variant="h6" component="div">
            {content}
          </Typography>
          <IconButton aria-label="close" onClick={this.onCloseClick}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {this.renderAddFiltersComponent()}
      </AppBar>
    )
  }
  renderAddFiltersComponent = () => {
    const { addFilterOptions = [] } = this.props
    const { selectedFilters = [] } = this.state
    const addFilterOptionsCopy = addFilterOptions.filter(
      (obj) => obj.value !== 'classId' && obj.value !== 'PropsSubCategory'
    )
    return (
      <CheckBoxAutoComplete
        options={addFilterOptionsCopy}
        label={'Add Filters'}
        styles={{ width: '500px', alignSelf: 'center', margin: '15px 0px' }}
        onChange={this.addFiltersChange}
        selectedValue={selectedFilters}
        getOptionDisabled={(option) =>
          ['Class', 'Sub Category'].indexOf(option.label) !== -1
        }
        filterSelectedOptions={true}
      />
    )
  }
  renderAutoComplete = (
    filterOptions = [],
    label = '',
    onChange = () => {},
    selectedValue = []
  ) => {
    return (
      <CheckBoxAutoComplete
        options={filterOptions}
        label={label}
        styles={{ alignSelf: 'center' }}
        onChange={onChange}
        selectedValue={selectedValue}
        filterOption={
          label === 'Category' ||
          label === 'Sub Category' ||
          label === 'Props Color' //this value refers to filterby key name in autocomplete
            ? 'displayName'
            : 'label'
        }
      />
    )
  }
  onAutoCompleChange =
    (label) =>
    (event, value = [], reason) => {
      const { filters: stateFilters = {} } = this.state
      this.setState((state = {}) => {
        const selectedValues = value.map((obj) => (obj || {}).label)
        let updatedFilter = {
          [label]: { requestParamValue: selectedValues, value: value },
        }
        if (label === 'Department' && reason === 'removeOption') {
          const classFilterValues = stateFilters['Class'] || {}
          const { value: classFilterValueList = [] } = classFilterValues
          const updFilterValueList = classFilterValueList.filter(
            (obj) => selectedValues.indexOf(obj.group_by_id) !== -1
          )
          const classSelectedValues = updFilterValueList.map((obj) => obj.label)
          updatedFilter = Object.assign({}, updatedFilter, {
            Class: {
              requestParamValue: classSelectedValues,
              value: updFilterValueList,
            },
          })
          if (!classSelectedValues.length) {
            delete updatedFilter['Class']
            delete stateFilters['Class']
          }
        }

        if (label === 'Category' && reason === 'removeOption') {
          const subCatFilterValues = stateFilters['Sub Category'] || {}
          const { value: filterValueList = [] } = subCatFilterValues
          const updFilterValueList = filterValueList.filter(
            (obj) => selectedValues.indexOf(obj.group_by_id) !== -1
          )
          const curSelectedValues = updFilterValueList.map((obj) => obj.label)
          updatedFilter = Object.assign({}, updatedFilter, {
            'Sub Category': {
              requestParamValue: curSelectedValues,
              value: updFilterValueList,
            },
          })
          if (!curSelectedValues.length) {
            delete updatedFilter['Sub Category']
            delete stateFilters['Sub Category']
          }
        }

        const stateFiltersCopy = Object.assign({}, stateFilters)
        const finalFilters = Object.assign({}, stateFiltersCopy, updatedFilter)
        if (!selectedValues.length) {
          delete finalFilters[label]
          delete stateFilters[label]
        }
        return {
          ...state,
          filters: finalFilters,
          isDepartmentChanged:
            label === 'Department' &&
            (reason === 'selectOption' || reason === 'removeOption'),
          isCategoryChanged:
            label === 'Category' &&
            (reason === 'selectOption' || reason === 'removeOption'),
        }
      })
    }
  handleTextFieldChange = (filter) => (event) => {
    const { filters: stateFilters = {} } = this.state
    const { pageName } = this.props
    let filtersCopy = Object.assign({}, stateFilters)

    filtersCopy[filter] = filtersCopy[filter] || {}
    filtersCopy[filter].requestParamValue = event.target.value
    filtersCopy[filter].value = event.target.value
    let errorLocal = true
    if (pageName !== 'OrderedSamples' && ['Vendor ID'].indexOf(filter) !== -1) {
      errorLocal = this.getErrorLocal(event.target.value, filter)
    }
    this.setState({
      filters: filtersCopy,
      error: errorLocal,
    })
  }
  getErrorLocal = (value = '', filter = '') => {
    let splitArray = value.split(',')
    let errorLocal = true
    splitArray.map((splitArrayInstance) => {
      errorLocal = regexExpressions[filter].test(splitArrayInstance.trim())
      return {}
    })
    return errorLocal
  }
  handleDateInput = (filter = '', date, startOrEnd = '') => {
    const { filters: stateFilters = {} } = this.state
    let filtersCopy = Object.assign({}, stateFilters)
    filtersCopy[filter] = filtersCopy[filter] || {}
    filtersCopy[filter][startOrEnd] = date
    let selectedStartDate = filtersCopy[filter]['startDate'] || null
    let selectedEndDate = filtersCopy[filter]['endDate'] || null

    if (startOrEnd === 'startDate') {
      selectedStartDate =
        startOrEnd === 'startDate'
          ? date
          : filtersCopy[filter]?.requestParamValue[0]
    }
    if (startOrEnd === 'endDate') {
      selectedEndDate =
        startOrEnd === 'endDate'
          ? date
          : filtersCopy[filter]?.requestParamValue[1]
    }

    filtersCopy[filter]['requestParamValue'] =
      filtersCopy[filter]['requestParamValue'] || []
    filtersCopy[filter]['requestParamValue'][0] = selectedStartDate
    filtersCopy[filter]['requestParamValue'][1] = selectedEndDate
    this.setState({ filters: filtersCopy })
  }
  renderComponentByType = (compObj = {}) => {
    const { type: compObjType = '', label: compObjLabel = '' } = compObj
    const { filterOptionsByFilter = {}, classes = {} } = this.props
    const { filters = {} } = this.state
    const currentFilter = filters[compObjLabel] || {}
    const {
      requestParamValue = '',
      startDate: currFilterStartDate,
      endDate: currFilterEndDate,
      value: selectedValue,
    } = currentFilter
    const currentValue = selectedValue || requestParamValue || []
    switch (compObjType) {
      case 'autoComplete': {
        return currentValue
          ? this.renderAutoComplete(
              filterOptionsByFilter[compObjLabel],
              compObjLabel,
              this.onAutoCompleChange,
              currentValue
            )
          : ''
      }
      case 'listInput': {
        return (
          <TextField
            id="chipTextField"
            label={'enter ' + compObjLabel + '(s)'}
            className={classes.chipFilterTextField}
            value={selectedValue || requestParamValue}
            onChange={this.handleTextFieldChange(compObjLabel)}
            variant="outlined"
            error={!this.state.error}
            helperText={
              !this.state.error
                ? 'Some of the Vendor ID(s) Entered are Wrong'
                : ' '
            }
            multiline
            InputLabelProps={{
              style: {
                fontSize: '15px',
              },
            }}
          />
        )
      }
      case 'dateInput': {
        return (
          <>
            <p className={classes.helperText}>Launch Date:</p>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="outlined"
                  id="startDate"
                  label="From"
                  autoOk
                  clearable={'true'}
                  onChange={(value) => {
                    this.handleDateInput(compObjLabel, value, 'startDate')
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                  error={currFilterStartDate > currFilterEndDate}
                  maxDate={currFilterEndDate}
                  maxDateMessage={"Can't select a date after the end date"}
                  value={currFilterStartDate || 'Start Date'}
                  format={DATE_FORMAT}
                  placeholder={'Start Date'}
                  invalidDateMessage=""
                  renderInput={(params) => <TextField {...params} />}
                  InputProps={
                    currFilterStartDate
                      ? [8, 10, 11].includes(
                          moment(currFilterStartDate).month()
                        )
                        ? { className: classes.dateInput }
                        : {}
                      : {}
                  }
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="outlined"
                  id="endDate"
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ readOnly: true }}
                  autoOk
                  clearable={'true'}
                  onChange={(value) => {
                    this.handleDateInput(compObjLabel, value, 'endDate')
                  }}
                  error={currFilterEndDate < currFilterStartDate}
                  minDate={currFilterStartDate}
                  minDateMessage={"Can't select a date before the start date"}
                  value={currFilterEndDate || 'End Date'}
                  format={DATE_FORMAT}
                  placeholder={'End Date'}
                  invalidDateMessage=""
                  renderInput={(params) => <TextField {...params} />}
                  InputProps={
                    currFilterStartDate
                      ? [8, 10, 11].includes(
                          moment(currFilterStartDate).month()
                        )
                        ? { className: classes.dateInput }
                        : {}
                      : {}
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
          </>
        )
      }
      default:
        return true
    }
  }
  renderContent = () => {
    const { selectedFilters = [] } = this.state
    const { classes = {}, addFilterOptions = [] } = this.props
    const classObj = addFilterOptions.find((obj1) => obj1.label === 'Class')
    const propsSubCatObj = addFilterOptions.find(
      (obj1) => obj1.label === 'Sub Category'
    )
    return (
      <div className={classes.renderContentDiv}>
        {(selectedFilters || []).map((obj = {}) => {
          return (
            <>
              <div className={classes.renderContentInnerDiv}>
                {this.renderComponentByType(obj)}
              </div>
              {obj.label === 'Department' && (
                <div className={classes.renderContentInnerDiv}>
                  {this.renderComponentByType(classObj)}
                </div>
              )}
              {obj.label === 'Category' && (
                <div className={classes.renderContentInnerDiv}>
                  {this.renderComponentByType(propsSubCatObj)}
                </div>
              )}
            </>
          )
        })}
      </div>
    )
  }
  onApplyClick =
    (event) =>
    (applyType = '') => {
      const { filters: stateFilters = {}, selectedFilters = [] } = this.state
      const {
        setFiltersUpdated = () => {},
        setFilters = () => {},
        pageName = 'AutoOrder',
        setSelectedFilters = () => {},
        toggleDrawer = () => {},
      } = this.props
      const filtersCopy = Object.assign({}, stateFilters)
      setFilters(filtersCopy, pageName)
      if (applyType === 'applysave' && pageName === 'AutoOrder') {
        this.triggerSaveFilters(filtersCopy)
      }
      toggleDrawer(event)('right', false)
      setFiltersUpdated(true, filtersCopy)
      if (popupFiltersUsedBy.indexOf(pageName) !== -1) {
        setSelectedFilters(selectedFilters, pageName)
      }
    }
  triggerSaveFilters = (filters = {}) => {
    const { saveFilters = () => {}, auth = {} } = this.props
    const { session = {} } = auth
    const { userInfo = {} } = session || {}
    const { lanId = '' } = userInfo || {}
    const formattedFilters =
      Object.keys(filters).map((obj) => {
        return {
          filter_name: obj,
          filter_values:
            listInputs.indexOf(obj) !== -1
              ? formatListInputValue(filters[obj]['requestParamValue'])
              : filters[obj]['requestParamValue'],
        }
      }) || []
    const saveFiltersReqParams = {
      lanId: lanId,
      title: 'default_test',
      accessType: false,
      feature: 'AUTO_ORDER',
      auto_order_filters: formattedFilters,
    }
    saveFilters(saveFiltersReqParams)
  }
  onClearClick = () => {
    const {
      setFiltersUpdated = () => {},
      setSelectedFilters = () => {},
      pageName = 'AutoOrder',
      setFilters = () => {},
    } = this.props
    const { triggerSaveFilters = () => {} } = this
    this.setState(
      {
        selectedFilters: [],
        filters: {},
        isDepartmentChanged: false,
        isCategoryChanged: false,
        previousFilters: {},
        previousSelectedFilters: [],
      },
      async (state = {}) => {
        await setFiltersUpdated(true, {})
        await triggerSaveFilters()
        if (popupFiltersUsedBy.indexOf(pageName) !== -1) {
          await setSelectedFilters([], pageName)
        }
        await setFilters({}, pageName)
      }
    )
  }
  render() {
    const { classes = {} } = this.props
    const { filters: stateFilters = {} } = this.state
    const { selectedFilters = {} } = this.props
    return (
      <Grid container justify={'center'} className={classes.rootDiv}>
        <Grid item xs={12}>
          {this.renderAppbar('Filters')}
        </Grid>
        <Grid item xs={12}>
          {this.renderContent()}
        </Grid>
        <Grid item xs={12} className={classes.footDiv}>
          <Button
            id="clear"
            className={classes.applyButtonMargin}
            variant="outlined"
            color="primary"
            onClick={this.onClearClick}
            disabled={
              !this.state.firstRender ||
              (!Object.entries(stateFilters).length &&
                !Object.entries(selectedFilters).length)
            }
          >
            CLEAR
          </Button>
          <Button
            id="apply"
            className={classes.applyButtonMargin}
            variant="outlined"
            color="primary"
            onClick={(event) => {
              this.onApplyClick(event)('applysave')
            }}
            disabled={
              !this.state.firstRender ||
              !this.state.error ||
              (!Object.entries(stateFilters).length &&
                !Object.entries(selectedFilters).length)
            }
          >
            {'APPLY'}
          </Button>
        </Grid>
      </Grid>
    )
  }
}

Filters.propTypes = {
  addFilterOptions: propTypes.array,
}
function mapStateToProps(state, ownProps) {
  const { pageName = 'AutoOrder' } = ownProps
  return {
    isFiltersPending: state.addFilters.getFiltersPending,
    addFilterOptions: getAddFilterOptions(pageName)(state),
    allBrandOptions: getAllBrandOptions()(state),
    allDepartmentsOptions: getAllDepartmentsOptions()(state),
    allCategoryOptions: getAllCategoryOptions()(state),
    filterOptionsByFilter: getFilterOptionsByFilter()(state),
    filters: selectPageFilters(pageName)(state),
    selectedFilters: getSelectedFilters(pageName)(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAllBrands: () => dispatch(getAllBrands()),
    getAllDepartments: () => dispatch(getAllDepartments('')),
    getPropsDropdownData: (selectedTab) => dispatch(getDropdownData(() => {})),
    setSubcatsByCatIds: (categories = []) =>
      dispatch(setSubcatsByCatIds(categories)),
    getClassByMultiDepartment: (deptIds = []) =>
      dispatch(getClassByMultiDepartment(deptIds)),
    setFilters: (filters, pageName) =>
      dispatch(setFiltersAutoOrder(filters, pageName)),
    setSelectedFilters: (filters = [], pageName = 'AutoOrder') =>
      dispatch(setSelectedFilters(filters, pageName)),
    saveFilters: (filterRequest) => dispatch(saveFilters(filterRequest)),
    getAllLocationList:() => dispatch(getAllLocationList())
  }
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(Filters)
