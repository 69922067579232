import { useState } from 'react'
import { TextField, IconButton } from '@material-ui/core'
import { Edit } from '@mui/icons-material'
import ShowIf from 'components/ShowIf'
import { CheckCircleOutlined } from '@material-ui/icons'

const BundledPropsQuantity = ({
  isReadOnly = true,
  isBundle = false,
  bundledQuantity,
  pegasusId,
  updateBundleQuantity,
}) => {
  const [editQuantityId, setEditQuantityId] = useState(0)
  const [bundledPropsQuantity, setBundledPropsQuantity] = useState({
    [pegasusId]: bundledQuantity,
  })

  const saveQuantityChange = () => {
    updateBundleQuantity(pegasusId, bundledPropsQuantity[pegasusId])
    setEditQuantityId(0)
  }

  const handleEditIconClick = () => {
    setEditQuantityId(pegasusId)
  }

  if (!isBundle) {
    return <>No</>
  } else if (isReadOnly) {
    return <>{bundledQuantity}</>
  }

  const hasError =
    bundledPropsQuantity[pegasusId] <= 0 ||
    bundledPropsQuantity[pegasusId] > 500

  return (
    <div style={{ display: 'flex' }}>
      <ShowIf condition={editQuantityId !== pegasusId}>
        <div>
          {bundledPropsQuantity[pegasusId]
            ? bundledPropsQuantity[pegasusId]
            : 1}
          <IconButton onClick={() => handleEditIconClick()}>
            <Edit />
          </IconButton>
        </div>
      </ShowIf>
      <ShowIf condition={editQuantityId === pegasusId}>
        <TextField
          id="standard-number"
          name="quantity"
          InputProps={{ inputProps: { min: 1, max: 500 } }}
          onChange={(e) => {
            setBundledPropsQuantity({ [pegasusId]: Number(e.target.value) })
          }}
          error={hasError}
          value={bundledPropsQuantity[pegasusId] || 0}
          style={{ padding: '10px', margin: '10px', width: '75px' }}
          type="number"
        />
        <IconButton
          disabled={hasError}
          onClick={() => saveQuantityChange(pegasusId)}
        >
          <CheckCircleOutlined />
        </IconButton>
      </ShowIf>
    </div>
  )
}
export default BundledPropsQuantity
