// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Grid,
} from '@material-ui/core'
import FormControl from '@mui/material/FormControl'
import Add from '@material-ui/icons/Add'
import CreatableSelect from '../../components/ReactSelect/CreatableSelect'
import {
  getCheckInLocation,
  addNewShipToLocation,
} from '../../services/locationService'
import { locationResponseToSelect } from '../../mappers/locationMapper'
import { usaCities, usaStates, countries } from './locationsConstants'
import { useDispatch } from 'react-redux'
import { adminAddNewShipToLocation } from 'store/locations/actionCreator'

const styles = (theme) => ({
  root: {
    padding: '10px',
    margin: '10px',
  },
  formRoot: {
    padding: '10px',
    margin: '10px',
    paddingBottom: '50px',
  },
  textField: {
    padding: '10px',
    margin: '10px',
  },
  typography: {
    padding: '10px',
  },
})

const fieldNames = [
  'display_name',
  'building',
  'address',
  'city',
  'state',
  'country',
  'zipcode',
]

const AddShipToLocation = ({ classes }) => {
  const [editContentVisible, setEditContentVisible] = useState(false)
  const [shipToLocationInfo, setShipToLocationInfo] = useState({ type: 'L' })
  const [shipToLocations, setShipToLocations] = useState([])
  const [validationErrors, setValidationErrors] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    getShipToLocations()
  }, [])

  useEffect(() => {
    if (shipToLocationInfo) {
      const invalidFields = []
      fieldNames.forEach((fieldName) => {
        if (!shipToLocationInfo[fieldName]) {
          invalidFields.push(fieldName)
        }
      })
      setValidationErrors([...invalidFields])
    }
  }, [shipToLocationInfo])

  const handleShipToLocationChange = (data, keyName) => {
    const currentAdminData = { ...shipToLocationInfo }
    setShipToLocationInfo({ ...currentAdminData, [keyName]: data })
  }

  const handleEditIconClick = () => {
    setEditContentVisible(!editContentVisible)
  }

  const handleCancelClick = () => {
    setEditContentVisible(false)
    setShipToLocationInfo({ type: 'L' })
  }

  const handleSaveClick = async () => {
    const newShipmentData = { ...shipToLocationInfo }
    delete newShipmentData.city
    delete newShipmentData.state
    delete newShipmentData.country

    newShipmentData.city = shipToLocationInfo.city.value
    newShipmentData.state = shipToLocationInfo.state.value
    newShipmentData.country = shipToLocationInfo.country.value
    dispatch(adminAddNewShipToLocation(newShipmentData, callBack))
  }
  const callBack = () =>{
    getShipToLocations()
    handleCancelClick()
  }

  const getShipToLocations = async () => {
    await getCheckInLocation().then((shipToLocations) => {
      const buildings = shipToLocations?.data?.buildings || []
      setShipToLocations(buildings)
      return locationResponseToSelect(shipToLocations)
    })
  }
const NAME_LENGTH = 100;
  return (
    <div>
      {!editContentVisible ? (
        <>
          <Card className={classes.root}>
            <CardHeader
              title="Add new ship to location"
              subheader={`Total: ${shipToLocations.length} locations`}
              action={
                <Button
                  id="addASample"
                  color="primary"
                  style={{
                    marginRight: '10px',
                    fontSize: '15px',
                    height: '35px',
                  }}
                  type="button"
                  variant="contained"
                  onClick={handleEditIconClick}
                >
                  <Add style={{ marginRight: '5px' }} />
                  Add Location
                </Button>
              }
            />
          </Card>
          {shipToLocations &&
            shipToLocations.length > 0 &&
            shipToLocations?.map((shipToLocation) => (
              <Card className={classes.root} key={shipToLocation.location_id}>
                <CardHeader title={`${shipToLocation.building}`} />
                <CardContent>
                  <Typography>{shipToLocation.address}</Typography>
                </CardContent>
              </Card>
            ))}
          {!shipToLocations ||
            (shipToLocations.length === 0 && <div>Loading</div>)}
        </>
      ) : (
        <Card className={classes.formRoot}>
          <FormControl>
            <CardHeader title="Add new ship to location" />
            <CardContent>
              <Grid container>
                <Grid item xs={4}>
                  <TextField
                    className={classes.textField}
                    label="Display name"
                    defaultValue={shipToLocationInfo.display_name}
                    required
                    helperText={
                      NAME_LENGTH - (shipToLocationInfo['display_name']?.length || 0) + ' characters remaining'
                    }
                    inputProps={{ maxLength: 100 }}
                    onChange={(event) =>
                      handleShipToLocationChange(
                        event.target.value,
                        'display_name'
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className={classes.textField}
                    label="Building"
                    defaultValue={shipToLocationInfo.building}
                    required
                    helperText={
                      NAME_LENGTH - (shipToLocationInfo['building']?.length || 0) + ' characters remaining'
                    }
                    inputProps={{ maxLength: 100 }}
                    onChange={(event) =>
                      handleShipToLocationChange(event.target.value, 'building')
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className={classes.textField}
                    label="Address"
                    defaultValue={shipToLocationInfo.address}
                    required
                    onChange={(event) =>
                      handleShipToLocationChange(event.target.value, 'address')
                    }
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: '10px 0 10px 10px' }}>
                  <CreatableSelect
                    distinct={usaCities}
                    classes={classes}
                    selectedValue={shipToLocationInfo.city}
                    handleDropdownChange={(value) =>
                      handleShipToLocationChange(value, 'city')
                    }
                    isCreatable
                    componentLabel={'Select City'}
                    isNewOption={false}
                    buttonVariant={'text'}
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: '10px 0 10px 10px' }}>
                  <CreatableSelect
                    distinct={usaStates}
                    classes={classes}
                    selectedValue={shipToLocationInfo.state}
                    handleDropdownChange={(value) =>
                      handleShipToLocationChange(value, 'state')
                    }
                    isCreatable
                    componentLabel={'Select State'}
                    isNewOption={false}
                    buttonVariant={'text'}
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: '10px 0 10px 10px' }}>
                  <CreatableSelect
                    distinct={countries}
                    classes={classes}
                    selectedValue={shipToLocationInfo.country}
                    handleDropdownChange={(value) =>
                      handleShipToLocationChange(value, 'country')
                    }
                    isCreatable
                    componentLabel={'Select Country'}
                    isNewOption={false}
                    buttonVariant={'text'}
                  />
                </Grid>
                <TextField
                  className={classes.textField}
                  label="Zip Code"
                  defaultValue={shipToLocationInfo.zipcode}
                  required
                  onChange={(event) =>
                    handleShipToLocationChange(event.target.value, 'zipcode')
                  }
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Button color="primary" onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSaveClick}
                disabled={validationErrors?.length > 0}
              >
                Save
              </Button>
            </CardActions>
          </FormControl>
        </Card>
      )}
    </div>
  )
}

export default withStyles(styles)(AddShipToLocation)
