export const SHOW_SEARCH_PAGE_POPUP = 'SHOW_SEARCH_PAGE_POPUP'
export const CLOSE_SEARCH_PAGE_POPUP = 'CLOSE_SEARCH_PAGE_POPUP'
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE'
export const FETCH_DATA_REQUEST_PENDING = 'FETCH_DATA_PENDING'
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
export const SEARCH_CLEAR_DATA = 'SEARCH_CLEAR_DATA'
export const RESET_SEARCH_STATE = 'RESET_SEARCH_STATE'
export const SEARCH_SET_CHECKBOX = 'SEARCH_SET_CHECKBOX'
export const SEARCH_SET_CHECKBOX_HEADER = 'SEARCH_SET_CHECKBOX_HEADER'
export const SEARCH_SET_INVALID_DATA = 'SEARCH_SET_INVALID_DATA'
export const UNSET_SEARCH_SELECTED_DATA = 'UNSET_SEARCH_SELECTED_DATA'
