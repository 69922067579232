import * as propTypes from 'prop-types'
import moment from 'moment'
import Button from '@material-ui/core/Button/Button'
import copy from 'copy-to-clipboard'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import withHOCs from 'util/withHocs'
import { showNotification } from '../../store/notification/actionCreator'
import { Tooltip } from '@material-ui/core'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@material-ui/core'
import Popper from '@mui/material/Popper'
import { FormControlLabel, Checkbox } from '@mui/material'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ArrowBack, DeleteOutline, Create } from '@material-ui/icons'
import { isEmpty, findIndex } from 'lodash'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Autosuggest from 'react-autosuggest'
import apiConfig from '../../apiConfig'
import { ShootTypes, ShootTypePagename } from '../../enums/shootTypes'
import {
  addSamples,
  closeAlert,
  closeErrorPopUp,
  clearSearchData,
  getProjectChannel,
  updateShootType,
  deleteShootType,
  showAlert,
  showPopup,
  closePopup,
  clearTableData,
  getShootType,
  setPage,
  setCheckBox,
  setCheckBoxHeaderChange,
  setOverlayCheckBox,
  setOverlayHeaderCheckBox,
  setRowsPerPage,
  removeSamples,
  resetNewShootTypeState,
  createShootType,
  updateSampleFieldsData,
  getProjectEditors,
} from '../../store/newShootType/actionCreator'
import { getAProject, getProjects } from '../../store/projects/actionCreator'
import { getACollection } from '../../store/collections/actionCreator'
import { getShipToLocation } from '../../store/newShipment/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { DATE_FORMAT, getDisplayName } from '../../util/CommonUtils'
import DialogBox from '../../components/DialogBox/DialogBox'

const styles = (theme) => ({
  detailsHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  textField: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 'normal',
    fontSize: 16,
    width: '100%',
  },
  label: {
    fontSize: '20px',
    fontWeight: 'bold',
    position: 'relative',
    padding: '10px 0 20px 0',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '2%',
    overflow: 'hidden',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
  },
  SwipeableDrawerElements: {
    // fontSize: 'large',
    textAlign: 'left',
    overflow: 'hidden',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  container: {
    position: 'relative',
    zIndex: 10,
  },
  input: {
    width: '100%',
    height: 55,
    padding: '10px 20px',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    border: '1px solid #aaa',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: 'none',
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  sampleSearch: {
    marginTop: '20px',
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 51,
    width: 280,
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 1,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    display: 'block',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
  // Project info
  pageHeader: {
    marginBottom: '20px',
    // height: '40px',
    position: 'relative',
    fontSize: '34px',
  },
  pageHeaderText: {
    position: 'absolute',
    top: -0,
  },
  pageHeaderButton1: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 30,
  },
  pageHeaderButton2: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 220,
  },
  accordionButton1: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 250,
  },
  accordionButton2: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 20,
  },
  itemColumnMargin: {
    flexBasis: '435px',
  },
  descriptionPaper: {
    padding: '24px 24px 48px 24px',
    marginTop: '50px',
  },
  sampleListTable: {
    marginTop: '34px',
  },
  shootTypeLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rootDiv: {
    // margin: '0px 28px',
  },
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '284px',
    padding: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    whiteSpace: 'pre-wrap',
  },
  filterTitle: {
    width: '300px',
    paddingTop: '10px',
    paddingBottom: '20px',
  },
  toolBar: {
    display: 'flex',
  },
  editButton: {
    width: 'auto',
    margin: theme.spacing(1),
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
  tabsWrapper: {
    marginLeft: '40px',
  },
  addSamplesBtn: {
    float: 'right',
    margin: '10px 15px 10px 0',
    border: `1px solid ${theme.palette.divider}`,
  },
})

const pageNameLabels = {
  projects: 'Project',
  collections: 'Collection',
}

const DEFAULT_ROWS_PER_PAGE = '9999'
const getSuggestionValue = (suggestion) => suggestion.name
const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>
const MyPopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: 380, border: '1px solid #666' }}
      placement="bottom-start"
    />
  )
}

class NewShootTypePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      callAction: '',
      page: 0,
      rowsPerPage: 20,
      tooltipIsOpen: false,
      suggestions: [],
      pegasusIds: [],
      projectData: {},
      collectionData: {},
      isStartDatePickerOpen: false,
      isEndDatePickerOpen: false,
      isSampleRequiredByDatePickerOpen: false,
      sampleidsCount: 0,
      isProjectEdit: false,
      isAccordionExpanded: false,
    }
  }

  onSelected = (selectedData, selected) => {
    const { pegasusId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.pegasusId === pegasusId
      }) !== -1
    )
  }
  onOverlaySelected = (selectedData, selected) => {
    const { pegasusId: selectedPegId = '' } = selected
    return selectedData.find((obj = {}) => obj.pegasusId === selectedPegId)
  }

  componentWillMount() {
    const { match: { params: { pageId: projectID = '' } = {} } = {} } =
      this.props
    if (!Object.keys(this.props.shootTypesData).length) {
      this.props.clearTableData()
    } else if (this.props.shootType) {
      this.props.getShootType(
        this.props.shootTypesData,
        this.props.shootType,
        1,
        9999,
        false,
        '',
        {},
        {
          project_id: [projectID],
        }
      )
    }
  }

  componentDidMount = () => {
    const { getProjectLocation } = this.props
    this.setShootTypeHeader()
    this.triggerActionBasedOnURI()
    getProjectLocation()
  }

  // Trigger action based on URI params(action and shoottype)
  triggerActionBasedOnURI = () => {
    const {
      auth,
      pageName = '',
      subPageName = '',
      createShootType,
      match: { params: { pageId: projectID = '' } = {} } = {},
      rowsPerPage = '9999',
      page = 1,
      getAProject = () => {},
      getACollection = () => {},
    } = this.props
    const {
      session: {
        userInfo: { lanId = '' },
      },
    } = auth
    const currentRowsPerpage = DEFAULT_ROWS_PER_PAGE || rowsPerPage
    if (!isEmpty(pageName)) {
      switch (subPageName) {
        case 'create':
          createShootType(lanId, pageNameLabels[pageName] || '')
          break
        case 'edit':
          switch (pageName) {
            case ShootTypePagename.PROJECT:
              getAProject(projectID, page, currentRowsPerpage)
              break
            case ShootTypePagename.COLLECTION:
              getACollection(projectID, page, currentRowsPerpage)
              break
            default:
              break
          }
          break
        default:
          break
      }
    }
  }
  // Set breadcrumb based on shoottype
  setShootTypeHeader = () => {
    const {
      pageName,
      setHeaderTitleArray,
      getProjectChannel,
      getProjectEditors = () => {},
    } = this.props
    switch (pageName) {
      case ShootTypePagename.PROJECT:
        getProjectChannel()
        getProjectEditors()
        setHeaderTitleArray([
          { title: 'Projects', link: '/projects' },
          { title: 'Create/Edit Project' },
        ])
        break
      case ShootTypePagename.COLLECTION:
        setHeaderTitleArray([
          { title: 'Collections', link: '/collections' },
          { title: 'Create/Edit Collection' },
        ])
        break
      default:
        break
    }
  }

  disableCreateButton = () => {
    const { projectData = {}, collectionData = {} } = this.state
    const { auth: { isAuthorized = () => {} } = {} } = this.props
    const { name = '', channel = '', samplesRequiredBy = null } = projectData
    const {
      name: collectionName = '',
      marketingPid: collectionMarketingPid = '',
    } = collectionData

    const {
      roles: { requestedSamples: requestedSamplesRoles = [] },
    } = apiConfig

    const isProjectProducer =
      requestedSamplesRoles.length > 0 && isAuthorized(requestedSamplesRoles)

    let projectReqCheck = name && channel
    if (isProjectProducer) {
      projectReqCheck =
        projectReqCheck &&
        moment(moment(samplesRequiredBy), 'MM/DD/YYYY', false).isValid()
    }
    if (projectReqCheck || (collectionName && collectionMarketingPid)) {
      return false
    } else {
      return true
    }
  }

  getSuggestions = (value) => {
    const { shipToLocations = [] } = this.props
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const filteredLocation =
      inputLength === 0
        ? []
        : shipToLocations.filter(
            (loc) =>
              loc.building.toLowerCase().slice(0, inputLength) === inputValue
          )

    const locations = filteredLocation.map((item) =>
      Object.assign(
        {},
        {
          name: item.building,
        }
      )
    )
    return locations
  }

  handleRemoveSample = () => {
    const { onEditChange, selectedData, shootType, shootTypeId } = this.props
    onEditChange(selectedData, shootType, shootTypeId)
  }

  handleAlertMessage = (e) => {
    const { showAlert } = this.props

    this.setState({ callAction: e.currentTarget.dataset.id })

    let message = ''
    if (e.currentTarget.dataset.id === 'DELETE') {
      message = 'Are you sure you want to delete?'
    }
    showAlert(message)
  }

  handleUpdate = (e) => {
    const {
      shootTypeId,
      pageName = '',
      onShootTypeUpdate,
      shootType: currentShoottype = '',
      shipToLocations = [],
    } = this.props
    const shootType = pageNameLabels[pageName] || currentShoottype || ''
    let updateRequest = {}

    switch (shootType) {
      case ShootTypes.PROJECT:
        {
          const {
            name,
            channel,
            locationName,
            shootStartDate,
            shootEndDate,
            marketingPid,
            samplesRequiredBy,
            notes,
            auto_order_enabled,
            ship_to_phone_number,
            project_editors,
          } = this.state.projectData

          updateRequest = {
            name: name,
            channel: channel,
            locationId: shipToLocations.filter(
              (x) => x.building === locationName
            ),
            shootStartDate: shootStartDate,
            shootEndDate: shootEndDate,
            marketingPid: marketingPid,
            samplesRequiredBy,
            notes: notes,
            auto_order_enabled: auto_order_enabled,
            ship_to_phone_number: ship_to_phone_number,
            project_editors: project_editors?.map((obj) => obj.lan_id) || [],
          }
        }
        break
      case ShootTypes.COLLECTION:
        // TODO: when UX update for collection
        break
      default:
        break
    }

    if (!updateRequest.locationId || updateRequest.locationId.length === 0) {
      updateRequest.locationId = [{ location_id: '' }]
    }

    if (Object.entries(updateRequest).length > 0) {
      onShootTypeUpdate(
        shootTypeId,
        updateRequest,
        shootType,
        this.onShootTypeUpdateCB
      )
      this.setState({ isProjectEdit: false })
      this.setState({ isAccordionExpanded: true })
    }
  }

  onShootTypeUpdateCB = async (updatedType = '', status = '', newProjectID) => {
    const {
      shootType,
      subPageName,
      getAllProjects,
      onProjectInfoUpdate = () => {},
    } = this.props
    if (!isEmpty(updatedType) && status === 'success') {
      // Keeping the user in the Project page
      if (
        shootType === ShootTypes.PROJECT &&
        subPageName === 'create' &&
        newProjectID
      ) {
        // Get the updated list of projects and redirect to newly created Project Page
        await getAllProjects()
        this.props.history.push(`/projects/${newProjectID}/view`)
      }
      onProjectInfoUpdate(true)
    }
  }

  onAlertAgree = async () => {
    const {
      handleShootTypeDelete,
      shootTypeId,
      pageName: shootType = '',
      getAllProjects,
    } = this.props
    await handleShootTypeDelete(
      shootTypeId,
      shootType,
      this.onShootTypeUpdateCB
    )
    await getAllProjects()
    this.handleBackToProjects()
  }

  onPhotoshootLocation = (event, { newValue }) => {
    this.setState((prevState) => ({
      projectData: {
        ...prevState.projectData,
        locationName: newValue,
      },
    }))
  }

  onCollectionPhotoshootLocation = (event, { newValue }) => {
    this.setState((prevState) => ({
      collectionData: {
        ...prevState.collectionData,
        locationName: newValue,
      },
    }))
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  onBlur = (e) => {
    let existCheck = this.props.shipToLocations.filter(
      (loc) => loc.building === e.target.defaultValue
    )

    if (existCheck.length === 0) {
      this.setState((prevState) => ({
        projectData: {
          ...prevState.projectData,
          shipFromLocName: '',
        },
      }))
    }
  }

  toggleOpen = () => {
    const { numSelected } = this.props
    if (numSelected !== 0) {
      return true
    } else {
      return false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { shootTypesData = {}, subPageName, shootType } = this.props
    const {
      name = '',
      channel = '',
      locationName = '',
      shootStartDate = '',
      shootEndDate = '',
      marketingPid = '',
      samplesRequiredBy = '',
      notes = '',
      auto_order_enabled,
      ship_to_phone_number,
      project_editors = [],
    } = shootTypesData
    if (prevProps.projectData !== this.props.projectData) {
      this.setState({ projectData: this.props.projectData })
    }

    if (
      (!this.state.projectData || isEmpty(this.state.projectData)) &&
      this.state.projectData !== this.props.projectData
    ) {
      this.setState({ projectData: this.props.projectData })
    }

    if (prevProps.shootTypesData !== shootTypesData) {
      this.setState((prevState) => ({
        projectData: {
          ...prevState.projectData,
          name: name,
          channel: channel,
          locationName: locationName,
          shootStartDate: shootStartDate,
          shootEndDate: shootEndDate,
          marketingPid: marketingPid,
          samplesRequiredBy,
          notes: notes,
          auto_order_enabled: auto_order_enabled,
          ship_to_phone_number: ship_to_phone_number,
          project_editors: project_editors,
        },
        collectionData: {
          ...prevState.collectionData,
          name: name,
          marketingPid: marketingPid,
          locationName: locationName,
          notes: notes,
        },
      }))
      if (shootType === ShootTypes.PROJECT && subPageName === 'create') {
        this.setState({ isAccordionExpanded: true })
      }
    }
  }

  componentWillUnmount() {
    this.props.resetNewShootTypeState()
  }

  getProjectChannelNames = (channels) => {
    const channelNames = channels.map((it) =>
      Object.assign(
        {},
        {
          id: it.id,
          name: it.channel,
        }
      )
    )
    return channelNames.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  handleCloseErrorPopUp = () => {
    const { closeErrorPopUp = () => {} } = this.props
    closeErrorPopUp()
  }

  handleForceSave = () => {
    const {
      addSamplesWithoutCheck,
      auth,
      shootTypeId,
      shootType,
      overlaySelectedData = [],
    } = this.props
    const {
      session: {
        userInfo: { lanId = '' },
      },
    } = auth
    const {
      projectData: { name: projectName = '', channel: projectChannel = '' },
      collectionData: { name: collectionName = '' },
    } = this.state
    const selectedIds = overlaySelectedData.map((obj) => obj.pegasusId)
    addSamplesWithoutCheck(
      selectedIds,
      lanId,
      shootTypeId,
      shootType,
      projectName,
      projectChannel,
      collectionName
    )
  }

  getSearchValue = (pgIds) => {
    this.setState({ pegasusIds: pgIds })
  }

  setStartDatePickerStatus = (status) => {
    this.setState({
      isStartDatePickerOpen: status,
    })
  }

  setEndDatePickerStatus = (status) => {
    this.setState({
      isEndDatePickerOpen: status,
    })
  }

  setSampleRequiredByDatePickerStatus = (status) => {
    this.setState({
      isSampleRequiredByDatePickerOpen: status,
    })
  }

  renderChannelOptions = () => {
    const { channels = [] } = this.props
    return this.getProjectChannelNames(channels).map((option) => (
      <option value={option.name} key={option.id}>
        {option.name}
      </option>
    ))
  }
  getSamplesCount = (sampleCount) => {
    this.setState({
      sampleidsCount: sampleCount,
    })
  }

  handleEdit = (e) => {
    this.setState({ isProjectEdit: true })
  }

  handleEditCancel = () => {
    this.setState({ isProjectEdit: false })
  }


  handleBackToProjects = () => {
    const { pageName = '' } = this.props
    this.props.history.push(`/${pageName}`)
  }

  render() {
    const {
      classes = {},
      shootTypeId,
      pageName = '',
      shootType: currentShoottype = '',
      shootTypesData,
      subPageName = '',
      auth: { isAuthorized = () => {} } = {},
      alertIsVisible = false,
      alertMessage,
      response,
      closeAlert,
      editors = [],
      isProjectOwner,
      handlePickListAction = () => {},
    } = this.props
    const {
      roles: { requestedSamples: requestedSamplesRoles = [] },
    } = apiConfig

    const isProjectProducer =
      requestedSamplesRoles.length > 0 && isAuthorized(requestedSamplesRoles)
    const {
      createdBy = '',
      createDate = '',
      project_editors: propsProjectEditors = [],
    } = shootTypesData
    const shootType = pageNameLabels[pageName] || currentShoottype || ''

    const {
      suggestions,
      isEndDatePickerOpen,
      isStartDatePickerOpen,
      isSampleRequiredByDatePickerOpen,
      projectData = {},
      isProjectEdit,
      isAccordionExpanded,
    } = this.state

    const {
      name = '',
      channel = '',
      shootStartDate,
      shootEndDate,
      marketingPid = '',
      notes = '',
      locationName = '',
      samplesRequiredBy = '',
      auto_order_enabled = false,
      ship_to_phone_number = '',
      project_editors = [],
    } = projectData
    const shootStartDateTs = !isEmpty(shootStartDate)
      ? new Date(shootStartDate).getTime()
      : null
    const shootEndDateTs = !isEmpty(shootEndDate)
      ? new Date(shootEndDate).getTime()
      : null
    const samplesRequiredByTs = !isEmpty(samplesRequiredBy)
      ? new Date(samplesRequiredBy).getTime()
      : null
    const nowTs = new Date().getTime()

    const locationProps = {
      id: 'Location',
      label: 'Photoshoot Location',
      placeholder: 'Photoshoot Location',
      value: locationName || '',
      maxLength: 100,
      onChange: this.onPhotoshootLocation,
    }

    return (
      <div className={classes.rootDiv}>
        <Grid container style={{ minHeight: '70px' }}>
          <Grid item xs={9} className={classes.pageHeader}>
            <span className={classes.pageHeaderText1}>
              {subPageName === 'create' ? `Create ${shootType}` : name}
            </span>
          </Grid>
          <Grid item xs={1} className={classes.pageHeaderButton2}>
            <Tooltip title="Pick List">
              <IconButton
                id="sideNavHamburger"
                onClick={handlePickListAction}
                classes={{ root: classes.button }}
                aria-label="Menu"
                style={{ position: 'absolute', right: '2%' }}
              >
                <ShoppingCartIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={3} className={classes.pageHeaderButton1}>
            <Button
              id="back"
              data-id="BACK"
              data-cy="btnBackTo"
              className={classes.editButton}
              color="primary"
              onClick={this.handleBackToProjects}
              disabled={!(Number(shootTypeId) > 0)}
              startIcon={<ArrowBack />}
            >
              <span> BACK TO {shootType + 's'}</span>
            </Button>
          </Grid>
        </Grid>
        {shootType === ShootTypes.PROJECT && (
          <Accordion
            onChange={(e, expanded) => {
              // Absoulte positioning of button will be visible in JSX without state check
              this.setState({ isAccordionExpanded: expanded })
            }}
            defaultExpanded={subPageName === 'create'}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className={classes.toolBar}>
                <span className={classes.filterTitle}>
                  <Typography variant="h5">{shootType} Info</Typography>{' '}
                </span>
              </div>
            </AccordionSummary>
            <Divider />

            {shootType === ShootTypes.PROJECT &&
              subPageName === 'view' &&
              !isProjectEdit && (
                <AccordionDetails style={{ padding: '25px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <span className={classes.shootTypeLabel}>
                            Project Name:{' '}
                          </span>
                          <span> {name}</span>
                        </Grid>

                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Channel:{' '}
                          </span>
                          <span> {channel}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Start Date:{' '}
                          </span>
                          <span>
                            {isEmpty(shootStartDate)
                              ? ''
                              : moment.isMoment(shootStartDate)
                                ? shootStartDate.format(DATE_FORMAT)
                                : shootStartDate}
                          </span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Photoshoot Location:{' '}
                          </span>
                          <span>
                            {isEmpty(locationName) ? '' : locationName}
                          </span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            End Date:{' '}
                          </span>
                          <span>
                            {isEmpty(shootEndDate)
                              ? ''
                              : moment.isMoment(shootEndDate)
                                ? shootEndDate.format(DATE_FORMAT)
                                : shootEndDate}
                          </span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Marketing PID:{' '}
                          </span>
                          <span>
                            {isEmpty(marketingPid) ? '' : marketingPid}
                          </span>
                        </Grid>
                        {isProjectProducer && (
                          <Grid item xs={6}>
                            <span className={classes.shootTypeLabel}>
                              Samples Required By:{' '}
                            </span>
                            <span>
                              {isEmpty(samplesRequiredBy)
                                ? ''
                                : moment.isMoment(samplesRequiredBy)
                                  ? samplesRequiredBy.format(DATE_FORMAT)
                                  : samplesRequiredBy}
                            </span>
                          </Grid>
                        )}
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Created By:{' '}
                          </span>
                          <span>{createdBy}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Created Date:{' '}
                          </span>
                          <span>{createDate}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Ship To Phone Number:{' '}
                          </span>
                          <span>
                            {isEmpty(ship_to_phone_number)
                              ? ''
                              : ship_to_phone_number}
                          </span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Project Editor(s):{' '}
                          </span>
                          <span>
                            {isEmpty(propsProjectEditors)
                              ? ''
                              : propsProjectEditors
                                  ?.map((obj) => getDisplayName(obj))
                                  .join(', ')}
                          </span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Notes:{' '}
                          </span>
                          <span>{isEmpty(notes) ? '' : notes}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <span className={classes.shootTypeLabel}>
                            Item Team Project:{' '}
                          </span>
                          <span>{auto_order_enabled ? 'True' : 'False'}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      {isAccordionExpanded && (
                        <>
                          <Grid
                            item
                            xs={6}
                            className={classes.accordionButton1}
                          >
                            <Button
                              id="delete"
                              data-id="DELETE"
                              data-cy="btnDelete"
                              className={classes.editButton}
                              variant="outlined"
                              color="primary"
                              onClick={this.handleAlertMessage}
                              disabled={
                                !(Number(shootTypeId) > 0) || !isProjectOwner
                              }
                              startIcon={<DeleteOutline />}
                            >
                              <span> DELETE {shootType}</span>
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            className={classes.accordionButton2}
                          >
                            <Button
                              id="editShootType"
                              data-cy="btnEditShootType"
                              data-id="shootType"
                              color="primary"
                              className={classes.editButton}
                              type="button"
                              variant="outlined"
                              disabled={
                                !(Number(shootTypeId) > 0) || !isProjectOwner
                              }
                              onClick={this.handleEdit}
                              startIcon={<Create />}
                            >
                              EDIT {shootType} INFO
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              )}

            <DialogBox
              isAlertVisible={alertIsVisible}
              message={alertMessage}
              onClose={closeAlert}
              response={response}
              items={this.state.callAction}
              onAgree={this.onAlertAgree}
            />
            {shootType === ShootTypes.PROJECT &&
              (subPageName === 'edit' ||
                isProjectEdit ||
                subPageName === 'create') && (
                <>
                  <AccordionDetails style={{ padding: '25px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              className={classes.textField}
                              data-cy="projectName"
                              id="Name"
                              label="Project Name*"
                              inputProps={{
                                maxLength: 100,
                              }}
                              InputLabelProps={{ shrink: true }}
                              placeholder="Name"
                              variant="outlined"
                              value={name || ''}
                              onChange={(event) => {
                                const name = event.target.value
                                this.setState((prevState) => ({
                                  projectData: {
                                    ...prevState.projectData,
                                    name: name,
                                  },
                                }))
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              className={classes.textField}
                              name="Channel"
                              id="Channel"
                              label="Channel*"
                              placeholder="Channel"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              onChange={(event) => {
                                const channel = event.target.value
                                this.setState((prevState) => ({
                                  projectData: {
                                    ...prevState.projectData,
                                    channel: channel,
                                  },
                                }))
                              }}
                              error={!channel}
                              select
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu,
                                },
                                native: true,
                              }}
                              value={channel || ''}
                            >
                              <option> Select channel </option>
                              {this.renderChannelOptions()}
                            </TextField>
                          </Grid>
                          <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                onClick={() =>
                                  this.setStartDatePickerStatus(true)
                                }
                                onClose={() =>
                                  this.setStartDatePickerStatus(false)
                                }
                                open={isStartDatePickerOpen}
                                className={classes.textField}
                                variant="inline"
                                inputVariant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true }}
                                id="ShootStartDate"
                                label="Start Date"
                                autoOk
                                clearable={'true'}
                                disablePast
                                error={isEmpty(shootStartDate)}
                                onChange={(date) => {
                                  const selectedDate = date
                                  this.setState((prevState) => ({
                                    projectData: {
                                      ...prevState.projectData,
                                      shootStartDate: selectedDate,
                                    },
                                  }))
                                }}
                                value={
                                  shootStartDate === undefined
                                    ? null
                                    : moment(shootStartDate).format(
                                        'YYYY/MM/DD'
                                      )
                                }
                                format={DATE_FORMAT}
                                placeholder={'__/__/____'}
                                disableOpenOnEnter
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={6}>
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={locationProps}
                              InputLabelProps={{ shrink: true }}
                              theme={{
                                container: classes.container,
                                suggestionsContainerOpen:
                                  classes.suggestionsContainerOpen,
                                suggestionsList: classes.suggestionsList,
                                suggestion: classes.suggestion,
                                suggestionHighlighted:
                                  classes.suggestionHighlighted,
                                suggestionsContainer:
                                  classes.suggestionsContainer,
                                input: classes.input,
                                inputFocused: classes.inputFocused,
                                inputOpen: classes.inputFocused,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                onClick={() =>
                                  this.setEndDatePickerStatus(true)
                                }
                                onClose={() =>
                                  this.setEndDatePickerStatus(false)
                                }
                                open={isEndDatePickerOpen}
                                className={classes.textField}
                                variant="inline"
                                inputVariant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true }}
                                id="ShootEndDate"
                                label="End Date"
                                autoOk
                                clearable={'true'}
                                disablePast
                                error={
                                  isEmpty(shootEndDate) ||
                                  shootEndDateTs < shootStartDateTs ||
                                  shootEndDateTs < nowTs
                                }
                                onChange={(date) => {
                                  const selectedDate = date
                                  this.setState((prevState) => ({
                                    projectData: {
                                      ...prevState.projectData,
                                      shootEndDate: selectedDate,
                                    },
                                  }))
                                }}
                                value={
                                  shootEndDate === undefined
                                    ? null
                                    : moment(shootEndDate).format('YYYY/MM/DD')
                                }
                                format={DATE_FORMAT}
                                placeholder={'__/__/____'}
                                disableOpenOnEnter
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              className={classes.textField}
                              id="marketingPid"
                              label="Marketing PID"
                              placeholder="marketingPid"
                              inputProps={{
                                maxLength: 100,
                              }}
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={marketingPid || ''}
                              onChange={(event) => {
                                const marketingPid = event.target.value
                                this.setState((prevState) => ({
                                  projectData: {
                                    ...prevState.projectData,
                                    marketingPid: marketingPid,
                                  },
                                }))
                              }}
                            />
                          </Grid>
                          {isProjectProducer && (
                            <Grid item xs={6}>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  onClick={() =>
                                    this.setSampleRequiredByDatePickerStatus(
                                      true
                                    )
                                  }
                                  onClose={() =>
                                    this.setSampleRequiredByDatePickerStatus(
                                      false
                                    )
                                  }
                                  open={isSampleRequiredByDatePickerOpen}
                                  className={classes.textField}
                                  variant="inline"
                                  inputVariant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{ readOnly: true }}
                                  id="samplesRequiredBy"
                                  label="Samples Required By*"
                                  autoOk
                                  clearable={'true'}
                                  disablePast
                                  error={
                                    isEmpty(samplesRequiredBy) ||
                                    (!isEmpty(samplesRequiredBy) &&
                                      samplesRequiredByTs < shootStartDateTs)
                                  }
                                  onChange={(date) => {
                                    const selectedDate = date
                                    this.setState((prevState) => ({
                                      projectData: {
                                        ...prevState.projectData,
                                        samplesRequiredBy: selectedDate,
                                      },
                                    }))
                                  }}
                                  value={
                                    samplesRequiredBy === undefined
                                      ? null
                                      : moment(samplesRequiredBy).format(
                                          'YYYY/MM/DD'
                                        )
                                  }
                                  format={DATE_FORMAT}
                                  placeholder={'__/__/____'}
                                  disableOpenOnEnter
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          )}
                          <Grid item xs={6}>
                            <TextField
                              className={classes.textField}
                              id="ship_to_phone_number"
                              label="Ship To Phone Number"
                              placeholder="Phone Number"
                              inputProps={{
                                maxLength: 20,
                              }}
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              type="number"
                              value={ship_to_phone_number || ''}
                              onChange={(event) => {
                                const phoneNumber = event.target.value
                                this.setState((prevState) => ({
                                  projectData: {
                                    ...prevState.projectData,
                                    ship_to_phone_number: phoneNumber,
                                  },
                                }))
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              id="project_editors"
                              data-cy="project_editors"
                              options={editors}
                              multiple
                              limitTags={2}
                              onChange={(event, newValue) => {
                                this.setState((prevState) => ({
                                  projectData: {
                                    ...prevState.projectData,
                                    project_editors: newValue,
                                  },
                                }))
                              }}
                              PopperComponent={MyPopper}
                              value={
                                !isEmpty(project_editors) ? project_editors : []
                              }
                              isOptionEqualToValue={(option, value) => {
                                return option.lan_id === value.lan_id
                              }}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option
                                }
                                // Regular option
                                return option.label
                              }}
                              clearOnBlur
                              filterOption={'label'}
                              sx={{ width: 390 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  fullWidth={true}
                                  label="Editor(s)"
                                  placeholder="Editor(s)"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              className={classes.textField}
                              id="notes"
                              label="Notes"
                              placeholder="Notes"
                              inputProps={{
                                maxLength: 250,
                              }}
                              multiline
                              rows={5}
                              maxRows={8}
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={notes || ''}
                              onChange={(event) => {
                                const notes = event.target.value
                                this.setState((prevState) => ({
                                  projectData: {
                                    ...prevState.projectData,
                                    notes: notes,
                                  },
                                }))
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={'auto_order_enabled'}
                                  checked={auto_order_enabled}
                                  onChange={(event) => {
                                    const isChecked = event.target.checked
                                    this.setState((prevState) => ({
                                      projectData: {
                                        ...prevState.projectData,
                                        auto_order_enabled: isChecked,
                                      },
                                    }))
                                  }}
                                />
                              }
                              label="Item Team Project"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>

                  {isAccordionExpanded && subPageName !== 'create' && (
                    <div style={{ float: 'right', padding: '25px' }}>
                      <Button
                        id="back"
                        data-id="BACK"
                        data-cy="btnBackTo"
                        className={classes.editButton}
                        variant="outlined"
                        color="secondary"
                        onClick={this.handleEditCancel}
                        disabled={!(Number(shootTypeId) > 0)}
                      >
                        <span> CANCEL</span>
                      </Button>

                      <Button
                        id="handleUpdate"
                        data-id="UPDATE"
                        data-cy="projectSave"
                        className={classes.defaultButton}
                        variant="contained"
                        color="primary"
                        disabled={this.disableCreateButton()}
                        onClick={this.handleUpdate}
                      >
                        UPDATE
                      </Button>
                    </div>
                  )}

                  {shootType === ShootTypes.PROJECT &&
                    subPageName === 'create' && (
                      <>
                        <div style={{ float: 'right', padding: '20px' }}>
                          <Button
                            id="back"
                            data-id="BACK"
                            data-cy="btnBackTo"
                            className={classes.editButton}
                            variant="outlined"
                            color="secondary"
                            onClick={this.handleBackToProjects}
                            disabled={!(Number(shootTypeId) > 0)}
                          >
                            <span> CANCEL</span>
                          </Button>

                          <Button
                            id="handleUpdate"
                            data-id="SAVE"
                            data-cy="projectSave"
                            className={classes.defaultButton}
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={this.disableCreateButton()}
                            onClick={this.handleUpdate}
                          >
                            CREATE
                          </Button>
                        </div>
                      </>
                    )}
                </>
              )}
          </Accordion>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alertIsVisible: state.newShootType.alertIsVisible,
    alertMessage: state.newShootType.alertMessage,
    clickableColumn: state.newShootType.clickableColumn,
    columnData: state.newShootType.columnData,
    checkBoxEnabled: state.newShootType.checkBoxEnabled,
    channels: state.newShootType.channels,
    editors: state.newShootType.editors,
    data: state.newShootType.data,
    goNextUpdatePageFlag: state.newShootType.goNextUpdatePageFlag,
    headerTitle: state.layout.headerTitle,
    numSelected: state.newShootType.numSelected,
    overlayNumSelected: state.newShootType.overlayNumSelected,
    onCheckBoxChange: state.newShootType.onCheckBoxChange,
    onOverlayCheckBoxHeadChange: state.newShootType.onOverlayCheckBoxHeadChange,
    onOverlayCheckBoxChange: state.newShootType.onOverlayCheckBoxChange,
    onClick: state.newShootType.onClick,
    onEditChange: state.newShootType.onEditChange,
    order: state.newShootType.order,
    orderBy: state.newShootType.orderBy,
    response: state.newShootType.response,
    rowCount: state.newShootType.rowCount,
    rowsPerPage: state.newShootType.rowsPerPage,
    unknownPegasusIds: state.newShootType.unknownPegasusIds,
    shipToLocations: state.newShipment.shipToLocations,
    shootType: state.newShootType.shootType,
    shootTypeId: state.newShootType.shootTypeId,
    shootTypesData: state.newShootType.shootTypesData,
    selectedData: state.newShootType.selectedData,
    overlaySelectedData: state.newShootType.overlaySelectedData,
    showPopup: state.newShootType.showPopup,
    showAlert: state.newShootType.showAlert,
    projectNames: state.newShootType.projectNames,
    page: state.newShootType.page,
    popupIsVisible: state.newShootType.popupIsVisible,
    popupItem: state.newShootType.item,
    unavailableSamples: state.newShootType.unavailableSamples,
    unavailableSampleColumnData: state.newShootType.unavailableSampleColumnData,
    unavailableSampleProjectColumnData:
      state.newShootType.unavailableSampleProjectColumnData,
    totalRowsCount: state.newShootType.totalRowsCount,
    unavailableSamplePopUpIsVisible:
      state.newShootType.unavailableSamplePopUpIsVisible,
    fetchShootTypeDataPending: state.newShootType.fetchShootTypeDataPending,
    flyoutOpen: state.projects.flyoutOpen,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addSamplesWithoutCheck: (
      pgIds,
      userId,
      shootTypeId,
      shootType,
      name,
      channel,
      collectionName
    ) => {
      dispatch(
        addSamples(
          pgIds,
          userId,
          shootTypeId,
          shootType,
          name,
          channel,
          collectionName,
          false
        )
      )
    },
    clearSearch: () => {
      dispatch(clearSearchData())
    },
    closeAlert: () => dispatch(closeAlert()),
    closeErrorPopUp: () => dispatch(closeErrorPopUp()),
    fetchSamples: (
      pgIds,
      userId,
      shootTypeId,
      shootType,
      name,
      channel,
      collectionName
    ) => {
      dispatch(
        addSamples(
          pgIds,
          userId,
          shootTypeId,
          shootType,
          name,
          channel,
          collectionName,
          true
        )
      )
    },
    getProjectChannel: () => dispatch(getProjectChannel()),
    getProjectEditors: () => dispatch(getProjectEditors()),
    getProjectLocation: () => dispatch(getShipToLocation()),
    getShootType: (
      row,
      shootType,
      page,
      rowsPerPage,
      goToViewPage,
      sampleType,
      filters,
      additionalFilters
    ) =>
      dispatch(
        getShootType(
          row,
          shootType,
          page,
          rowsPerPage,
          goToViewPage,
          sampleType,
          filters,
          additionalFilters
        )
      ),
    handleShootTypeDelete: (shootTypeId, shootType, callback = () => {}) =>
      dispatch(deleteShootType(shootTypeId, shootType, callback)),
    handleChipClick: (chip) => {
      let c = chip.target.innerText
      copy(c)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    onOverlayCheckBoxChange: (selectedData = {}) => {
      const { pegasusId = '' } = selectedData
      dispatch(setOverlayCheckBox({ pegasusId: pegasusId }))
    },
    onOverlayCheckBoxHeadChange: (selected = []) => {
      const newData = selected.map((obj = {}) => ({
        pegasusId: obj.pegasusId,
      }))
      dispatch(setOverlayHeaderCheckBox(newData))
    },
    onCheckBoxChange: (selectedData) => dispatch(setCheckBox(selectedData)),
    onCheckBoxHeaderChange: (selected = []) =>
      dispatch(setCheckBoxHeaderChange(selected)),
    onShootTypeUpdate: (
      shootTypeId,
      updateRequest,
      shootType,
      callback = () => {}
    ) =>
      dispatch(
        updateShootType(shootTypeId, updateRequest, shootType, callback)
      ),
    onEditChange: (selectedData, shootType, shipmentId) =>
      dispatch(removeSamples(selectedData, shootType, shipmentId)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    showAlert: (message) => dispatch(showAlert(message)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    closePopup: () => {
      dispatch(closePopup())
    },
    clearTableData: () => {
      dispatch(clearTableData())
    },
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    resetNewShootTypeState: () => dispatch(resetNewShootTypeState()),
    createShootType: (userId, shootType) =>
      dispatch(createShootType(userId, shootType)),
    getAProject: (projectID, page, rowsPerPage) => {
      dispatch(getAProject(projectID, page, rowsPerPage))
    },
    getACollection: (projectID, page, rowsPerPage) =>
      dispatch(getACollection(projectID, page, rowsPerPage)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
    getAllProjects: async (params = {}) => {
      dispatch(getProjects(params))
    },
  }
}

NewShootTypePage.propTypes = {
  alertIsVisible: propTypes.bool,
  onCreate: propTypes.func,
  callAction: propTypes.string,
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  clickableColumn: propTypes.array,
  data: propTypes.array,
  handleShootTypeDelete: propTypes.func,
  handleUpdate: propTypes.func,
  numSelected: propTypes.number,
  overlayNumSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onOverlayCheckBoxHeadChange: propTypes.func,
  onClick: propTypes.func,
  selectedData: propTypes.array,
  overlaySelectedData: propTypes.array,
  shipFrom: propTypes.string,
  order: propTypes.string,
  orderBy: propTypes.string,
  page: propTypes.number,
  rowCount: propTypes.number,
  rowsPerPage: propTypes.number,
  userId: propTypes.string,
  unavailableSamples: propTypes.array,
  unavailableSampleColumnData: propTypes.array,
  unavailableSampleProjectColumnData: propTypes.array,
  unavailableSamplePopUpIsVisible: propTypes.bool,
  getSearchValue: propTypes.func,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(NewShootTypePage)
