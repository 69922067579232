import * as types from './actionType'
import { getDisplayImage } from '../../util/CommonUtils'

export const initialState = {
  checkBoxEnabled: true,
  clickableColumn: ['description', 'size', 'departmentName', 'className'],
  columnData: [
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
    },
    {
      id: 'imageUrl',
      numeric: false,
      disablePadding: true,
      label: 'Image',
      align: 'center',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Item Description',
    },
    {
      id: 'launchDate',
      numeric: false,
      disablePadding: true,
      label: 'Launch Date',
      type: 'date',
    },
    {
      id: 'vendorId',
      numeric: true,
      disablePadding: false,
      label: 'Vendor ID',
    },
    {
      id: 'vendorName',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Name',
    },
    {
      id: 'item_quantity',
      numeric: true,
      disablePadding: false,
      label: 'Quantity',
    },
  ],
  confirmColumnData: [
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      align: 'center',
    },
    {
      id: 'imageUrl',
      numeric: false,
      disablePadding: false,
      label: 'Image',
      align: 'center',
    },
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: true,
      label: 'Pegasus ID',
      align: 'center',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Item Description',
    },
    {
      id: 'launchDate',
      numeric: false,
      disablePadding: true,
      label: 'Launch Date',
      type: 'date',
    },
    {
      id: 'vendorId',
      numeric: true,
      disablePadding: false,
      label: 'Vendor ID',
    },
    {
      id: 'vendorName',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Name',
    },
  ],
  data: [],
  fetchDataError: '',
  fetchDataRequestFailure: false,
  fetchDataRequestPending: false,
  fetchDataRequestSuccess: false,
  invalidData: [],
  isVisible: false,
  item: {},
  numSelected: 0,
  onCheckBoxChange: () => {},
  onCheckBoxHeaderChange: () => {},
  onClick: () => {},
  popupIsVisible: false,
  searchField: '',
  selectedData: [],
}

export default function addSample(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_DATA_REQUEST_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        fetchDataRequestPending: action.payload.pending,
      })
    case types.FETCH_DATA_SUCCESS: // Request has succeeded and we can set the data state
      return Object.assign({}, state, {
        ...state,
        data: action.payload.data.map((item) => ({
          ...item,
          imagePopupUrl: item.imageUrl,
          imageUrl:
            item.imageUrl !== undefined &&
            item.imageUrl !== null &&
            item.imageUrl !== ''
              ? getDisplayImage(item.imageUrl)
              : 'Image Not Found',
        })),
        fetchDataRequestSuccess: true,
      })
    case types.FETCH_DATA_FAILURE: // request has failed with an error message to display
      return Object.assign({}, state, {
        ...state,
        fetchDataError: action.payload.error,
        fetchDataRequestFailure: true,
      })
    case types.ADDSAMPLE_CLEAR_DATA:
      return Object.assign({}, state, {
        ...state,
        data: [],
        invalidData: [],
        searchField: '',
      })
    case types.ADDSAMPLE_SET_INVALID_DATA:
      return Object.assign({}, state, {
        ...state,
        invalidData: action.payload.data,
      })
    case types.SHOW_ADDSAMPLE_PAGE_POPUP: {
      const newState = {
        ...state,
        popupIsVisible: true,
        item: action.payload.item,
      }
      return newState
    }
    case types.CLOSE_ADDSAMPLE_PAGE_POPUP: {
      const newState = {
        ...state,
        popupIsVisible: false,
      }
      return newState
    }
    case types.ADDSAMPLE_SET_CHECKBOX: {
      const selectedIndex = state.selectedData.indexOf(action.payload.selected)
      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          state.selectedData,
          action.payload.selected
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selectedData.slice(1))
      } else if (selectedIndex === state.selectedData.length - 1) {
        newSelected = newSelected.concat(state.selectedData.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selectedData.slice(0, selectedIndex),
          state.selectedData.slice(selectedIndex + 1)
        )
      }

      return Object.assign({}, state, {
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.ADDSAMPLE_SET_CHECKBOX_HEADER: {
      let newSelected = []
      if (action.payload.selected.length > state.selectedData.length) {
        newSelected = action.payload.selected
      } else if (action.payload.selected.length === state.selectedData.length) {
        newSelected = []
      }

      return Object.assign({}, state, {
        selectedData: newSelected,
        numSelected: newSelected.length,
      })
    }
    case types.UNSET_ADDSAMPLE_SELECTED_DATA: {
      return Object.assign({}, state, {
        selectedData: [],
        numSelected: 0,
      })
    }
    case types.RESET_ADDSAMPLE_STATE: {
      return Object.assign({}, initialState)
    }
    case types.UPDATE_SAMPLE_QUANTITY: {
      return Object.assign({}, state, {})
    }
    default:
      return state
  }
}
