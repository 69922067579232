import axios from 'axios'
import apiConfig from '../apiConfig'

const {
  managements: { url = '' },
  apiKey = '',
} = apiConfig

export function getAllCollections(params = {}) {
  return axios.get(url + 'collections', {
    headers: { 'Content-type': 'application/json' },
    params: Object.assign({}, params, { key: apiKey }),
  })
}

export function getAllCollectionNames() {
  return axios.get(url + 'collections/collection_names', {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function updateCollectionStatus(updateParams = {}) {
  return axios.put(url + 'collections/update_status', updateParams, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function getCollection(collectionId) {
  return axios.get(url + 'collections/' + collectionId, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function getCollectionSamples(collectionId, page, rowsPerPage) {
  return axios.get(url + 'collections/' + collectionId + '/samples', {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
      page: page,
      per_page: rowsPerPage,
    },
  })
}

export function downloadCollectionSamples(collectionId) {
  return axios.get(url + 'collections/' + collectionId, {
    headers: { Accept: 'text/csv' },
    params: {
      key: apiKey,
    },
  })
}

export function downloadCollectionExcel(collectionId) {
  return axios.get(url + 'collections/downloads/' + collectionId, {
    headers: { Accept: '*/*' },
    params: {
      key: apiKey,
    },
    responseType: 'blob',
  })
}

export function createCollection(collection) {
  return axios.post(url + 'collections', collection, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export function updateCollection(shootTypeId, updateRequest) {
  return axios.put(url + 'collections/' + shootTypeId, updateRequest, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export const deleteCollection = (shootTypeId) => {
  return axios.delete(url + 'collections/' + shootTypeId, {
    params: {
      key: apiKey,
    },
  })
}
