import React from 'react'

class Internal extends React.Component {
  render() {
    return (
      <div>
        <div>
          <div>
            <h1>Please follow below instructions to get Access</h1>
            <div>
              <h2>
                1. Login to{' '}
                <a
                  href="https://myaccess.target.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  myAccess
                </a>
                <br />
                2. Search for the right access based on your role and request
                access <br />
              </h2>
              <h3>
                Here is the list of roles and its corressponding entitlement
                name: <br /> <br />
                Item Data Specalists (IDS) &emsp; &nbsp; : &nbsp;
                APP-PEG-IDS-PRD
                <br />
                Merchendise Specalists (MS) &nbsp;: &nbsp; APP-PEG-MS-PRD <br />
                Sample Management (SM) &emsp;&nbsp; : &nbsp; APP-PEG-SM-PRD
                <br />
              </h3>
              <h2>Thanks! </h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Internal
