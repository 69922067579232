import { createTheme } from '@material-ui/core/styles'
export const pegasusGrey = {
  light: '#4d4d4d',
  main: '#333333',
  dark: '#242424',
  contrastText: '#fff',
}

export const pegasusBlue = {
  light: '#4c84f5',
  main: '#366CD9',
  dark: '#2f5ebd',
  contrastText: '#fff',
}

export const pegasusError = {
  main: '#cc0000',
}

export const praxisTheme = createTheme({
  zIndex: {
    mobileStepper: 3000,
    appBar: 3100,
    drawer: 3200,
    modal: 3300,
    snackbar: 3400,
    tooltip: 3050,
  },
  palette: {
    background: {
      default: '#F7F7F7',
    },
    primary: pegasusBlue,
    secondary: pegasusGrey,
    error: pegasusError,
  },
  typography: {
    useNextVariants: true,
  },
})

export const successTheme = createTheme({
  palette: {
    primary: {
      light: '#56d442',
      main: '#008300',
      dark: '#02a102',
      contrastText: '#000',
    },
    typography: {
      useNextVariants: true,
    },
  },
})

export const warningTheme = createTheme({
  palette: {
    primary: {
      light: '#ffff57',
      main: '#fde30b',
      dark: '#c6b100',
      contrastText: '#000',
    },
    typography: {
      useNextVariants: true,
    },
  },
})

export const errorTheme = createTheme({
  palette: {
    primary: {
      light: '#f08137',
      main: '#B85300',
      dark: '#822600',
      contrastText: '#fff',
    },
    typography: {
      useNextVariants: true,
    },
  },
})

export const TableCelltheme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        minWidth: '120px',
      },
      paddingCheckbox: {
        minWidth: '50px !important',
      },
    },
  },
})
export const TableCellthemeNew = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        minWidth: '120px',
        borderBottom: 'unset !important',
      },
      paddingCheckbox: {
        minWidth: '50px !important',
      },
    },
  },
})
export const Popovertheme = createTheme({
  overrides: {
    MuiPopover: {
      root: {
        zIndex: 3300 + ' !important',
      },
    },
  },
})

export default praxisTheme
