import * as itemApi from '../../services/itemService'
import * as ordersApi from '../../services/ordersService'
import * as orderMapper from '../../mappers/OrderMapper'
import * as itemMapper from '../../mappers/itemMapper'
import * as types from './actionType'
import { SampleStatus } from '../../enums/sampleStatus'
import { showNotification } from '../../store/notification/actionCreator'
import { download } from '../../util/DownloadCsv'

export function locationChange(selectedData = []) {
  return {
    type: types.LOCATION_CHANGE,
    payload: { selectedData: selectedData },
  }
}

export function setData(data = []) {
  return {
    type: types.ORDER_SUMMARY_SET_DATA,
    payload: { data: data },
  }
}

export function showPopup(item) {
  return {
    type: types.SHOW_ORDER_SUMMARY_PAGE_POPUP,
    payload: { item: item },
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_ORDER_SUMMARY_PAGE_POPUP,
  }
}

export function setCheckBox(selectedData) {
  return {
    type: types.ORDER_SUMMARY_SET_CHECKBOX,
    payload: { selected: selectedData },
  }
}

export function setCheckBoxHeaderChange(selectedData) {
  return {
    type: types.ORDER_SUMMARY_SET_CHECKBOX_HEADER,
    payload: { selected: selectedData },
  }
}
// Fetch order information, item data, merge, then set data and filter
export function getVendorOrders(params = {}, doesFiltersUpdated = false) {
  return (dispatch) => {
    dispatch(getVendorOrderRequestPending(true))
    getVendorSamples(params)
      .then((orders = {}) => {
        if (Object.entries(orders).length > 0) {
          const {
            samples: orderSamples = [],
            totalRowsCount = 0,
            filterNFList = {},
          } = orders
          dispatch(getVendorOrderRequestSuccess(orderSamples))
          dispatch(setTotalRowsCount(totalRowsCount))
          dispatch(setFilterNFList(filterNFList))
          if (doesFiltersUpdated) dispatch(locationChange([]))
        } else {
          dispatch(getVendorOrderRequestFailure())
        }
      })
      .catch(() => {
        dispatch(getVendorOrderRequestFailure())
      })
      .finally(() => {
        dispatch(getVendorOrderRequestPending(false))
      })
  }
}

export function getVendorOrdersNew(params = {}, doesFiltersUpdated = false) {
  return (dispatch) => {
    dispatch(getVendorOrderRequestPending(true))
    getVendorSamplesNew(params)
      .then((orders = {}) => {
        if (Object.entries(orders).length > 0) {
          const {
            samples: orderSamples = [],
            totalRowsCount = 0,
            filterNFList = {},
          } = orders
          dispatch(getVendorOrderRequestSuccess(orderSamples))
          dispatch(setTotalRowsCount(totalRowsCount))
          dispatch(setFilterNFList(filterNFList))
          if (doesFiltersUpdated) dispatch(locationChange([]))
        } else {
          dispatch(getVendorOrderRequestFailure())
        }
      })
      .catch(() => {
        dispatch(getVendorOrderRequestFailure())
      })
      .finally(() => {
        dispatch(getVendorOrderRequestPending(false))
      })
  }
}

function getVendorSamples(params = {}) {
  return ordersApi
    .fetchRequestedSamplesByVendor(params)
    .then((results) => {
      const {
        data: { total_count = 0, filter_list_notfound = {} },
      } = results
      return {
        samples: orderMapper.samplesResponseToSamples(results),
        totalRowsCount: total_count,
        filterNFList: filter_list_notfound,
      }
    })
    .then((orders = {}) => {
      const {
        samples: orderSamples = [],
        totalRowsCount = 0,
        filterNFList = {},
      } = orders

      return orderSamples.length > 0
        ? itemApi
            .getItemsGraphQL(orderSamples.map((it) => it.tcin))
            .then((itemResult) => itemMapper.itemsResponseToItems(itemResult))
            .then(
              (items) =>
                new Map(
                  items.map((item) => [(item.tcin || '').toString(), item])
                )
            )
            .then((itemMap) => {
              return {
                samples: orderSamples.map((order = {}) => {
                  const { tcin: orderTcin = '' } = order
                  return Object.assign(
                    {},
                    order,
                    itemMap.get(orderTcin.toString())
                  )
                }),
                totalRowsCount: totalRowsCount,
                filterNFList: filterNFList,
              }
            })
        : {
            samples: orderSamples,
            totalRowsCount: totalRowsCount,
            filterNFList: filterNFList,
          }
    })
    .catch(() => {})
}

function getVendorSamplesNew(params = {}) {
  return ordersApi
    .fetchRequestedSamplesByVendorNew(params)
    .then((results) => {
      const {
        data: { total_count = 0, filter_list_notfound = {} },
      } = results
      return {
        samples: orderMapper.samplesResponseToSamples(results),
        totalRowsCount: total_count,
        filterNFList: filter_list_notfound,
      }
    })
    .then((orders = {}) => {
      const {
        samples: orderSamples = [],
        totalRowsCount = 0,
        filterNFList = {},
      } = orders

      return orderSamples.length > 0
        ? itemApi
            .getItemsGraphQL(orderSamples.map((it) => it.tcin))
            .then((itemResult) => itemMapper.itemsResponseToItems(itemResult))
            .then(
              (items) =>
                new Map(
                  items.map((item) => [(item.tcin || '').toString(), item])
                )
            )
            .then((itemMap) => {
              return {
                samples: orderSamples.map((order = {}) => {
                  const { tcin: orderTcin = '' } = order
                  return Object.assign(
                    {},
                    order,
                    itemMap.get(orderTcin.toString())
                  )
                }),
                totalRowsCount: totalRowsCount,
                filterNFList: filterNFList,
              }
            })
            .catch(() => {
              return {
                samples: orderSamples,
                totalRowsCount: totalRowsCount,
                filterNFList: filterNFList,
              }
            })
        : {
            samples: orderSamples,
            totalRowsCount: totalRowsCount,
            filterNFList: filterNFList,
          }
    })
    .catch((error) => {
      console.log(error)
    })
    .then((finalObj) => {
      return finalObj
    })
}

export function downloadSamples() {
  return (dispatch) => {
    return ordersApi
      .fetchSamplesCsvByVendors(SampleStatus.ORDERED)
      .then((response) => {
        download(response)
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function setFilterNFList(filterNFList = {}) {
  return {
    type: types.ORDER_SUMMARY_SET_FILTER_NOTFOUND_LIST,
    payload: { filterNFList: filterNFList },
  }
}

export function submittedShipment(response) {
  return {
    type: types.ORDER_SUMMARY_SUBMIT_ORDER,
    payload: { response: response },
  }
}

export function getVendorOrderRequestPending(pending = false) {
  return {
    type: types.ORDER_SUMMARY_VENDOR_ORDER_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function getVendorOrderRequestFailure() {
  return {
    type: types.ORDER_SUMMARY_VENDOR_ORDER_REQUEST_FAILURE,
  }
}

export function getVendorOrderRequestSuccess(data = []) {
  return {
    type: types.ORDER_SUMMARY_VENDOR_ORDER_REQUEST_SUCCESS,
    payload: { data: data },
  }
}

export function showAlert(message) {
  return {
    type: types.SHOW_ORDER_SUMMARY_PAGE_ALERT,
    payload: { message: message },
  }
}

export function closeAlert() {
  return {
    type: types.CLOSE_ORDER_SUMMARY_PAGE_ALERT,
  }
}

export function setPage(page) {
  return {
    type: types.SET_ORDER_SUMMARY_PAGE_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage) {
  return {
    type: types.SET_ORDER_SUMMARY_PAGE_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function setTotalRowsCount(totalRowsCount) {
  return {
    type: types.SET_ORDER_SUMMARY_PAGE_TOTAL_ROWS_COUNT,
    payload: { totalRowsCount: totalRowsCount },
  }
}

export function setSortedData(sortedData) {
  return {
    type: types.SET_ORDER_SUMMARY_PAGE_SORTED_DATA,
    payload: { data: sortedData },
  }
}
