//

// eslint-disable-next-line no-unused-vars
import * as React from 'react'

import ListItemIcon from '@material-ui/core/ListItemIcon'

export function Icon(props) {
  const { children, classes, className, ...additionalProps } = props
  return (
    <ListItemIcon classes={classes} className={className} {...additionalProps}>
      {children}
    </ListItemIcon>
  )
}

Icon.displayName = 'Icon'

export default Icon
