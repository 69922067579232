import { findEditLevelFromLocation } from '../util/locations'
import { isEmpty, omitBy } from 'lodash'
import { DATE_FORMAT, convertTableDateFormat } from '../util/CommonUtils'

export function locationToId(location) {
  switch (location) {
    case 'Royalston - Samples & Props':
      return 3000
    case 'Splashlight':
      return 6
    case 'Periscope Studio Hong Kong':
      return 19970
    case 'Schawk':
      return 19971
    case 'Target Plaza North (TPN)':
      return 30600
    case 'testLocation':
      return 11111
    default:
      return null
  }
}

export function locationToLocationRequest(req) {
  return {
    building: req[0],
    aisle: req[1],
    section: req[2],
    shelf: req[3],
    container: req[4],
  }
}

export function locationsResponseToData(locations = []) {
  return locations.map((location) => locationResponseToData(location))
}

export function locationResponseToData(response) {
  return {
    locationId: response.location_id,
    building: response.building,
    aisle: response.aisle,
    section: response.section,
    shelf: response.shelf,
    container: response.container,
    type: response.type,
    address: response.address || '',
  }
}

export function locationResponseToDataML(response) {
  return {
    location_id: response.location_id,
    building: response.building,
    aisle: response.aisle,
    section: response.section,
    shelf: response.shelf,
    container: response.container,
    type: response.type,
    dateAdded: response.create_ts_cst
      ? convertTableDateFormat(response.create_ts_cst, DATE_FORMAT)
      : '',
  }
}

export function locationResponseToOrdered(response) {
  return {
    0: response.building,
    1: response.aisle,
    2: response.section,
    3: response.shelf,
    4: response.container,
  }
}

export function locationResponseToSelect(response) {
  let returnArray = []
  let distinctBuildings = []

  for (let index in response?.data?.buildings) {
    if (
      !distinctBuildings.includes(response?.data?.buildings[index]?.building)
    ) {
      distinctBuildings.push(response?.data?.buildings[index]?.building)
      returnArray.push({
        value: response?.data?.buildings[index]?.building,
        label: response?.data?.buildings[index]?.building,
      })
    }
  }
  return returnArray
}

export function locationResponseToLocations(locations = []) {
  const locationsCopy = [...locations]
  return locationsCopy.map((location = {}) =>
    Object.assign(
      {},
      location,
      locationResponseToDataML(location),
      locationResponseToLocation(location)
    )
  )
}

export function locationResponseToLocation(location = {}) {
  const locationCopy = Object.assign({}, location)
  if (!isEmpty(locationCopy)) {
    const newObj = findEditLevelFromLocation(locationCopy)
    const pickByValues = omitBy(locationCopy, ['editColumn'])
    return Object.assign({}, pickByValues, {
      editColumn: [...newObj],
    })
  }
}
