import * as propTypes from 'prop-types'
import Button from '@material-ui/core/Button/Button'
import Divider from '@material-ui/core/Divider'
import copy from 'copy-to-clipboard'
import FlexibleTable from '../../../components/EnhancedTable/FlexibleTable'
import Grid from '@material-ui/core/Grid'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import React from 'react'
import SampleSearch from '../../../components/Search/SampleSearch'
import { ReportProblemOutlined, CheckCircleOutlined } from '@material-ui/icons'
import withHOCs from 'util/withHocs'
import { withStyles } from '@material-ui/core/styles'
import { showNotification } from '../../../store/notification/actionCreator'
import CircularPageLoader from '../../../components/Loader/CircularPageLoader'
import { isEmpty, findIndex, upperFirst } from 'lodash'
import { ShootTypes, ShootTypePagename } from '../../../enums/shootTypes'
import { SAMPLE } from 'enums/Tabs'
import {
  addSamples,
  clearSearchData,
  getProjectChannel,
  updateShootType,
  deleteShootType,
  showAlert,
  showPopup,
  closePopup,
  clearTableData,
  getShootType,
  setPage,
  setCheckBox,
  setCheckBoxHeaderChange,
  setOverlayCheckBox,
  setOverlayHeaderCheckBox,
  setRowsPerPage,
  removeSamples,
  resetNewShootTypeState,
  createShootType,
  updateSampleFieldsData,
  closeErrorPopUp,
} from '../../../store/newShootType/actionCreator'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { getData } from '../../../store/searchInventory/actionCreator' // TODO: not needed
import { getAProject } from '../../../store/projects/actionCreator'
import { getACollection } from '../../../store/collections/actionCreator'
import { getShipToLocation } from '../../../store/newShipment/actionCreator'
import { DATE_FORMAT, convertTableDateFormat } from '../../../util/CommonUtils'

const styles = (theme) => ({
  pageHeader: {
    height: '40px',
    position: 'relative',
    fontSize: '20px',
    fontWeight: 500,
    margin: '24px 0 15px 40px',
  },
  appBarToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#333333',
    color: '#ffffff',
  },
  pageInfo: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '20px 0 0px 25px',
  },
  unavailableDataContainer: {
    margin: '24px 40px 15px 40px',
  },
  pageHeaderText: {
    position: 'absolute',
    top: -30,
  },
  pageHeaderButton1: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 250,
  },
  pageHeaderButton2: {
    display: 'inline',
    height: '40px',
    position: 'absolute',
    right: 20,
  },
  detailsHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  textField: {
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 'normal',
    fontSize: 16,
    width: '100%',
  },
  label: {
    fontSize: '20px',
    fontWeight: 'bold',
    position: 'relative',
    padding: '10px 0 20px 0',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '2%',
    overflow: 'hidden',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
  },
  SwipeableDrawerElements: {
    // fontSize: 'large',
    textAlign: 'left',
    overflow: 'hidden',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  container: {
    position: 'relative',
    zIndex: 10,
  },
  divider: {
    borderBottom: '1px solid rgba(51, 51, 51, 0.5)',
    borderColor: '#DDDDDD',
  },
  input: {
    width: '100%',
    height: 55,
    padding: '10px 20px',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    border: '1px solid #aaa',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: 'none',
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  sampleSearch: {
    margin: '0px 15px 10px 25px',
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 51,
    width: 280,
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: 16,
    fontWeight: 'bold',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 1,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    display: 'block',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '284px',
    padding: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    whiteSpace: 'pre-wrap',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
  iconWrapper: {
    margin: '8px 10px 0 0',
    color: '#FF7A00',
    float: 'left',
  },
  iconWrapperSucess: {
    margin: '0 10px 0 0',
    color: '#21A968',
    float: 'left',
  },
})

const pageNameLabels = {
  projects: 'Project',
  collections: 'Collection',
}

const DEFAULT_ROWS_PER_PAGE = '9999'

class AddSampleToProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      callAction: '',
      page: 0,
      rowsPerPage: 20,
      tooltipIsOpen: false,
      suggestions: [],
      pegasusIds: [],
      projectData: {},
      collectionData: {},
      isStartDatePickerOpen: false,
      isEndDatePickerOpen: false,
      sampleidsCount: 0,
      errorData: [],
      errorMessage: '',
      successData: [],
      sampleType: SAMPLE,
    }
    this.myRef = React.createRef()
  }

  onSelected = (selectedData, selected) => {
    const { pegasusId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.pegasusId === pegasusId
      }) !== -1
    )
  }
  onOverlaySelected = (selectedData, selected) => {
    const { pegasusId: selectedPegId = '' } = selected
    return selectedData.find((obj = {}) => obj.pegasusId === selectedPegId)
  }

  componentWillMount() {
    if (!Object.keys(this.props.shootTypesData).length) {
      this.props.clearTableData()
    } else if (this.props.shootType) {
      //need to come back on this
      // this.props.getShootType(
      //   this.props.shootTypesData,
      //   this.props.shootType,
      //   1,
      //   9999,
      //   false
      // )
    }
  }

  componentDidMount = () => {
    const { getProjectLocation } = this.props
    this.setShootTypeHeader()
    this.triggerActionBasedOnURI()
    getProjectLocation()
  }

  // Trigger action based on URI params(action and shoottype)
  triggerActionBasedOnURI = () => {
    const {
      auth,
      pageName = '',
      subPageName = '',
      createShootType,
      match: { params: { pageId: projectID = '' } = {} } = {},
      rowsPerPage = '9999',
      page = 1,
      getAProject = () => {},
      getACollection = () => {},
    } = this.props
    const {
      session: {
        userInfo: { lanId = '' },
      },
    } = auth
    const currentRowsPerpage = DEFAULT_ROWS_PER_PAGE || rowsPerPage
    if (!isEmpty(pageName)) {
      switch (subPageName) {
        case 'create':
          createShootType(lanId, pageNameLabels[pageName] || '')
          break
        case 'edit':
          switch (pageName) {
            case ShootTypePagename.PROJECT:
              getAProject(projectID, page, currentRowsPerpage)
              break
            case ShootTypePagename.COLLECTION:
              getACollection(projectID, page, currentRowsPerpage)
              break
            default:
              break
          }
          break
        default:
          break
      }
    }
  }
  // Set breadcrumb based on shoottype
  setShootTypeHeader = () => {
    const { pageName, getProjectChannel } = this.props
    switch (pageName) {
      case ShootTypePagename.PROJECT:
        getProjectChannel()
        break
      case ShootTypePagename.COLLECTION:
        break
      default:
        break
    }
  }

  handleRemoveSample = () => {
    const {
      shootType,
      shootTypeId,
      overlaySelectedData,
      unavailableSamples,
      auth,
      fetchSamples,
      clearUnavailableSamples,
    } = this.props
    const {
      session: {
        userInfo: { lanId = '' },
      },
    } = auth
    const sampleIds = unavailableSamples.reduce((acc, obj) => {
      if (
        !overlaySelectedData.some(
          (selectedData) => selectedData.pegasusId === obj.pegasusId
        )
      ) {
        acc.push(obj.pegasusId)
      }
      return acc
    }, [])
    if (sampleIds.length === 0) {
      clearUnavailableSamples()
    } else {
      fetchSamples(sampleIds, lanId, shootTypeId, shootType)
    }
  }

  handleUpdate = (e) => {
    const {
      shootTypeId,
      pageName = '',
      onShootTypeUpdate,
      shootType: currentShoottype = '',
      shipToLocations = [],
    } = this.props
    const shootType = pageNameLabels[pageName] || currentShoottype || ''
    let updateRequest = {}

    switch (shootType) {
      case ShootTypes.PROJECT:
        {
          const {
            name,
            channel,
            locationName,
            shootStartDate,
            shootEndDate,
            marketingPid,
            notes,
          } = this.props.projectData

          updateRequest = {
            name: name,
            channel: channel,
            locationId: shipToLocations.filter(
              (x) => x.building === locationName
            ),
            shootStartDate: shootStartDate,
            shootEndDate: shootEndDate,
            marketingPid: marketingPid,
            notes: notes,
          }
        }
        break
      case ShootTypes.COLLECTION:
        {
          const { name, marketingPid, notes, locationName } =
            this.state.collectionData
          updateRequest = {
            name: name,
            marketingPid: marketingPid,
            notes: notes,
            locationId: shipToLocations.filter(
              (x) => x.building === locationName
            ),
          }
        }
        break
      default:
        break
    }

    if (!updateRequest.locationId || updateRequest.locationId.length === 0) {
      updateRequest.locationId = [{ location_id: '' }]
    }

    if (Object.entries(updateRequest).length > 0) {
      onShootTypeUpdate(
        shootTypeId,
        updateRequest,
        shootType,
        this.onShootTypeUpdateCB
      )
    }
  }

  onShootTypeUpdateCB = (updatedType = '', status = '') => {
    if (!isEmpty(updatedType) && status === 'success') {
      this.props.history.push(`/${updatedType}`)
    }
  }

  onPhotoshootLocation = (event, { newValue }) => {
    this.setState((prevState) => ({
      projectData: {
        ...prevState.projectData,
        locationName: newValue,
      },
    }))
  }

  onCollectionPhotoshootLocation = (event, { newValue }) => {
    this.setState((prevState) => ({
      collectionData: {
        ...prevState.collectionData,
        locationName: newValue,
      },
    }))
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  onBlur = (e) => {
    let existCheck = this.props.shipToLocations.filter(
      (loc) => loc.building === e.target.defaultValue
    )

    if (existCheck.length === 0) {
      this.setState((prevState) => ({
        projectData: {
          ...prevState.projectData,
          shipFromLocName: '',
        },
      }))
    }
  }

  toggleOpen = () => {
    const { numSelected } = this.props
    if (numSelected !== 0) {
      return true
    } else {
      return false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { shootTypesData = {} } = this.props
    const {
      name = '',
      channel = '',
      locationName = '',
      shootStartDate = '',
      shootEndDate = '',
      marketingPid = '',
      notes = '',
    } = shootTypesData
    if (prevProps.projectData !== this.props.projectData) {
      this.setState({ projectData: this.props.projectData })
    }

    if (prevProps.shootTypesData !== shootTypesData) {
      this.setState((prevState) => ({
        projectData: {
          ...prevState.projectData,
          name: name,
          channel: channel,
          locationName: locationName,
          shootStartDate: shootStartDate,
          shootEndDate: shootEndDate,
          marketingPid: marketingPid,
          notes: notes,
        },
        collectionData: {
          ...prevState.collectionData,
          name: name,
          marketingPid: marketingPid,
          locationName: locationName,
          notes: notes,
        },
      }))
    }
  }

  componentWillUnmount() {
    this.props.resetNewShootTypeState()
  }

  getProjectChannelNames = (channels) => {
    const channelNames = channels.map((it) =>
      Object.assign(
        {},
        {
          id: it.id,
          name: it.channel,
        }
      )
    )
    return channelNames.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  unavailableSamplesRender = (unavailableSamples = []) => {
    const newData = []
    unavailableSamples.forEach((dataObj) => {
      const { projects = [] } = dataObj

      const multiColumnData = (fieldName, isDate) => {
        const fieldData = (obj) =>
          isDate
            ? convertTableDateFormat(obj[fieldName], DATE_FORMAT)
            : obj[fieldName]
        return (
          <Grid container spacing={1} direction={'column'}>
            {projects.map((obj) => (
              <Grid item>{fieldData(obj)}</Grid>
            ))}
          </Grid>
        )
      }

      const projectName = multiColumnData('projectName')
      const shootStartDate = multiColumnData('shootStartDate', true)
      const shootEndDate = multiColumnData('shootEndDate', true)
      const createdBy = multiColumnData('createdBy')

      newData.push({
        ...dataObj,
        projectName,
        shootStartDate,
        shootEndDate,
        createdBy,
      })
    })
    return newData
  }

  displayUnavailableSamples = () => {
    const {
      unavailableSamples,
      unavailableSampleProjectColumnDataSlider,
      onOverlayCheckBoxChange = () => {},
      overlaySelectedData = [],
      overlayNumSelected = 0,
      onOverlayCheckBoxHeadChange = () => {},
    } = this.props

    const FlexibleTableNew = withStyles((theme) => ({
      root: {
        boxShadow: 'unset !important',
        // background: 'transparent',
      },
      container: {
        maxHeight: 'unset !important',
      },
      header: {
        backgroundColor: 'unset',
      },
    }))(FlexibleTable)
    const data = this.unavailableSamplesRender(unavailableSamples)
    return (
      <div>
        <FlexibleTableNew
          cascadeFieldKeys={[]}
          data={data}
          // disableItems
          disableTablePagination
          disableTableSorting
          columnData={unavailableSampleProjectColumnDataSlider}
          disableItemCount
          enableToolbar={false}
          enableSpacer={false}
          checkBoxEnabled={true}
          onCheckBoxChange={onOverlayCheckBoxChange}
          onSelected={this.onOverlaySelected}
          selected={overlaySelectedData}
          editEnabled={false}
          stickyHeader={false}
          showPopup={() => {}}
          tableRowHover={true}
          numSelected={overlayNumSelected}
          onCheckBoxHeaderChange={onOverlayCheckBoxHeadChange}
          // cellTheme={TableCellthemeNew}
        />
      </div>
    )
  }

  requestSamplesCallback = (type = '', data = []) => {
    const { errors = [], success = [] } = data
    switch (type) {
      case 'error': {
        this.setState({ errorData: data })
        this.setState({ successData: [] })
        break
      }
      case 'partialsuccess': {
        this.setState({ errorData: errors })
        this.setState({ successData: success })
        break
      }
      default: {
        this.setState({ errorData: [] })
        this.setState({ successData: data })
        break
      }
    }
  }

  addSamples = () => {
    const { unavailableSamples = [] } = this.props
    if (unavailableSamples.length > 0) {
      this.handleForceSave()
    } else {
      // invoking child SampleSearch method for reuse component
      this.myRef.current.handleSearch()
    }
  }

  handleForceSave = async () => {
    const {
      addSamplesWithoutCheck,
      auth,
      shootTypeId,
      shootType,
      overlaySelectedData = [],
      clearUnavailableSamples,
      projectData: { name: projectName = '', channel: projectChannel = '' },
    } = this.props
    const {
      session: {
        userInfo: { lanId = '' },
      },
    } = auth
    const {
      collectionData: { name: collectionName = '' },
    } = this.state
    const selectedIds = overlaySelectedData.map((obj) => obj.pegasusId)

    const callbackData = addSamplesWithoutCheck(
      selectedIds,
      lanId,
      shootTypeId,
      shootType,
      projectName,
      projectChannel,
      collectionName
    )
    this.requestSamplesCallback(callbackData)
    await clearUnavailableSamples()
  }

  getSearchValue = (pgIds) => {
    this.setState({ pegasusIds: pgIds })
  }

  setStartDatePickerStatus = (status) => {
    this.setState({
      isStartDatePickerOpen: status,
    })
  }

  setEndDatePickerStatus = (status) => {
    this.setState({
      isEndDatePickerOpen: status,
    })
  }

  renderChannelOptions = () => {
    const { channels = [] } = this.props
    return this.getProjectChannelNames(channels).map((option) => (
      <option value={option.name} key={option.id}>
        {option.name}
      </option>
    ))
  }
  getSamplesCount = (sampleCount) => {
    this.setState({
      sampleidsCount: sampleCount,
    })
  }
  renderSuccessData = () => {
    const { classes } = this.props
    const { successData } = this.state
    return (
      <Grid container spacing={2}>
        <Grid item>
          <CheckCircleOutlineIcon className={classes.successIcon} />
        </Grid>
        <Grid item>
          <Typography component={'subtitle2'} sx={{ fontWeight: 'bold' }}>
            {`You have requested sample(s) for ${
              successData.length || Number(0)
            } items`}
          </Typography>
        </Grid>
      </Grid>
    )
  }
  renderSuccessSampleData = () => {
    const { classes, addSamplesResponse, sampleType = SAMPLE } = this.props
    const successfulSamples = addSamplesResponse?.sampleShootTypes?.length
    return (
      <>
        <div className={classes.unavailableDataContainer}>
          <span className={classes.iconWrapperSucess}>
            <CheckCircleOutlined />
          </span>
          {`${successfulSamples || Number(0)} ${upperFirst(
            sampleType?.toLowerCase()
          )} added successfully`}
        </div>
      </>
    )
  }
  renderErrorMessage = () => {
    const { classes } = this.props
    const { errorMessage } = this.state
    return (
      <Grid container spacing={2}>
        <Grid item>
          <ErrorOutlineIcon className={classes.errorIcon} />
        </Grid>
        <Grid item>
          <Typography component={'subtitle2'} sx={{ fontWeight: 'bold' }}>
            <Typography
              color="error"
              component={'subtitle2'}
              sx={{ fontWeight: 'bold', color: '#B85300' }}
            >
              {errorMessage}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  renderChipList = (itemList = '') => {
    const { classes } = this.props
    return (
      <Stack direction="row" spacing={1}>
        {itemList.map((item) => {
          return (
            <Chip
              id={`${item}chipLabel`}
              className={classes.errorChip}
              onClick={() => {}}
              label={item}
              key={item}
              onDelete={undefined}
              variant="outlined"
            />
          )
        })}
      </Stack>
    )
  }

  onCopytoClipboard = () => {
    const { unknownPegasusIds = [] } = this.props
    copy(unknownPegasusIds.map((item) => item || '').join('\r\n'), {
      format: 'text/plain',
    })
  }

  renderErrorData = () => {
    const { classes, unknownPegasusIds, sampleType = SAMPLE } = this.props
    return (
      <Grid container spacing={2} direction={'column'}>
        {unknownPegasusIds.length > 0 && (
          <div style={{ margin: '20px 0px 20px 20px' }}>
            <Grid container spacing={2}>
              <Grid item>
                <ErrorOutlineIcon className={classes.errorIcon} />
              </Grid>
              <Grid item>
                <Typography
                  color="error"
                  component={'subtitle2'}
                  sx={{ fontWeight: 'bold', color: '#B85300' }}
                >
                  Unknown {upperFirst(sampleType?.toLowerCase())} Ids or Already
                  added
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              {unknownPegasusIds.map((data) => {
                return <Grid item>{this.renderChipList([data])}</Grid>
              })}
            </Grid>
            <Grid item>
              <Button
                id="editSelectedItems"
                data-id={`Edit ${sampleType}`}
                color="primary"
                className={classes.defaultButton}
                startIcon={<ContentCopyIcon />}
                onClick={this.onCopytoClipboard}
              >
                Copy All
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
    )
  }

  render() {
    const {
      auth,
      classes = {},
      fetchSamples,
      unknownPegasusIds,
      // numSelected,
      shootTypeId,
      pageName = '',
      shootType: currentShoottype = '',
      unavailableSamples = [],
      // totalRowsCount,
      fetchShootTypeDataPending = false,
      closeDrawer = () => {},
      // data,
      addSamplesResponse,
      overlaySelectedData,
      projectData = {},
      sampleType = SAMPLE,
    } = this.props

    const { sampleidsCount, errorData, successData, errorMessage } = this.state
    const {
      session: {
        userInfo: { lanId = '' },
      },
    } = auth
    const shootType = pageNameLabels[pageName] || currentShoottype || ''

    let isSuccessOperation = false
    if (
      addSamplesResponse?.errorSamples?.length === 0 &&
      addSamplesResponse?.sampleShootTypes?.length > 0
    ) {
      isSuccessOperation = true
    }

    const isAddSamplesDisabled =
      ((!sampleidsCount || sampleidsCount === 0) &&
        unavailableSamples?.length === 0) ||
      (unavailableSamples?.length > 0 && overlaySelectedData?.length === 0)

    return (
      <div>
        <AppBar
          position="sticky"
          color="transparent"
          elevation={1}
          sx={{
            top: 'auto',
            bottom: 0,
            backgroundColor: '#333333',
            color: '#ffffff',
          }}
        >
          <Toolbar className={classes.appBarToolBar}>
            <Typography variant="h6" component="div">
              Add {upperFirst(sampleType?.toLowerCase())}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={closeDrawer}
              style={{ color: '#ffffff' }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        {unknownPegasusIds.length > 0 && this.renderErrorData()}
        {successData.length > 0 && this.renderSuccessData()}{' '}
        {/* requested sample */}
        {addSamplesResponse?.sampleShootTypes?.length > 0 &&
          this.renderSuccessSampleData()}{' '}
        {/* added sample */}
        {errorMessage && this.renderErrorMessage()}
        {(errorData.length > 0 || successData.length > 0 || errorMessage) && (
          <Divider
            style={{ margin: '20px 0px', height: '1px' }}
            variant="inset"
          />
        )}
        <Divider />
        <div className={classes.pageInfo}>
          Add {upperFirst(sampleType?.toLowerCase())} that have already been
          ordered
        </div>
        <div className={classes.pageInfo}></div>
        <div className={classes.sampleSearch}>
          <SampleSearch
            ref={this.myRef}
            label="Add Pegasus ID"
            // buttonName="Search"
            buttonVariant="text"
            submitSearch={fetchSamples}
            getSearchValue={this.getSearchValue}
            userId={lanId}
            shipmentNumber={shootTypeId}
            shootType={shootType}
            projectData={projectData}
            leftColumn
            // clearSearchCallback={this.clearSearch}
            clearOnSubmit
            onChangeText={this.getSamplesCount}
            hideBorder
            hideButtons
            helperText={
              sampleidsCount
                ? `${sampleidsCount} Pegasus ID(s)`
                : 'Pegasus ID(s)'
            }
            sampleType={sampleType}
            showNotification={this.props.showNotification}
          />
        </div>
        {fetchShootTypeDataPending ? (
          <div>
            <CircularPageLoader open={fetchShootTypeDataPending} />
          </div>
        ) : (
          <div>
            {isSuccessOperation && unavailableSamples?.length === 0 && (
              <>
                <div className={classes.unavailableDataContainer}>
                  <span className={classes.iconWrapperSucess}>
                    <CheckCircleOutlined />
                  </span>
                  {upperFirst(sampleType?.toLowerCase())} added successfully
                </div>
              </>
            )}
            {unavailableSamples?.length > 0 && (
              <div className={classes.unavailableDataContainer}>
                <span className={classes.iconWrapper}>
                  <ReportProblemOutlined />
                </span>
                <div>
                  {upperFirst(sampleType?.toLowerCase())} already included in
                  other projects are listed below. You can remove them or if
                  they need to be in more than one project go ahead and add them
                  to this project too!
                </div>
                {this.displayUnavailableSamples()}

                <Button
                  className={classes.defaultButton}
                  color="primary"
                  onClick={this.handleRemoveSample}
                  variant="outlined"
                  disabled={isAddSamplesDisabled}
                >
                  Remove
                </Button>
              </div>
            )}
          </div>
        )}
        <Divider />
        <Grid item xs={6} className={classes.pageHeaderButton2}>
          <Button
            id="handleCancel"
            data-id="CANCEL"
            data-cy="cancel"
            className={classes.defaultButton}
            variant="outlined"
            color="primary"
            // disabled={this.disableCreateButton()}
            onClick={closeDrawer}
          >
            Close
          </Button>
          <Button
            id="handleUpdate"
            data-id="UPDATE"
            data-cy="projectSave"
            className={classes.defaultButton}
            variant="contained"
            color="primary"
            disabled={isAddSamplesDisabled}
            onClick={this.addSamples}
            // onClick={this.handleUpdate.bind(this)}
          >
            Add {sampleType}
          </Button>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    checkBoxEnabled: state.newShootType.checkBoxEnabled,
    channels: state.newShootType.channels,
    data: state.newShootType.data,
    goNextUpdatePageFlag: state.newShootType.goNextUpdatePageFlag,
    numSelected: state.newShootType.numSelected,
    overlayNumSelected: state.newShootType.overlayNumSelected,
    onCheckBoxChange: state.newShootType.onCheckBoxChange,
    onOverlayCheckBoxHeadChange: state.newShootType.onOverlayCheckBoxHeadChange,
    onOverlayCheckBoxChange: state.newShootType.onOverlayCheckBoxChange,
    onClick: state.newShootType.onClick,
    onEditChange: state.newShootType.onEditChange,
    order: state.newShootType.order,
    orderBy: state.newShootType.orderBy,
    response: state.newShootType.response,
    rowCount: state.newShootType.rowCount,
    rowsPerPage: state.newShootType.rowsPerPage,
    unknownPegasusIds: state.newShootType.unknownPegasusIds,
    shipToLocations: state.newShipment.shipToLocations,
    shootType: state.newShootType.shootType,
    shootTypeId: state.newShootType.shootTypeId,
    shootTypesData: state.newShootType.shootTypesData,
    selectedData: state.newShootType.selectedData,
    overlaySelectedData: state.newShootType.overlaySelectedData,
    showPopup: state.newShootType.showPopup,
    showAlert: state.newShootType.showAlert,
    projectNames: state.newShootType.projectNames,
    page: state.newShootType.page,
    popupIsVisible: state.newShootType.popupIsVisible,
    popupItem: state.newShootType.item,
    unavailableSamples: state.newShootType.unavailableSamples,
    unavailableSampleColumnData: state.newShootType.unavailableSampleColumnData,
    unavailableSampleProjectColumnDataSlider:
      state.newShootType.unavailableSampleProjectColumnDataSlider,
    unavailableSampleProjectColumnData:
      state.newShootType.unavailableSampleProjectColumnData,
    totalRowsCount: state.newShootType.totalRowsCount,
    unavailableSamplePopUpIsVisible:
      state.newShootType.unavailableSamplePopUpIsVisible,
    fetchShootTypeDataPending: state.newShootType.fetchShootTypeDataPending,
    addSamplesResponse: state.newShootType.addSamplesResponse,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showNotification: (...args) => {
      dispatch(showNotification(...args))
    },
    addSamplesWithoutCheck: (
      pgIds,
      userId,
      shootTypeId,
      shootType,
      name,
      channel,
      collectionName
    ) => {
      dispatch(
        addSamples(
          pgIds,
          userId,
          shootTypeId,
          shootType,
          name,
          channel,
          collectionName,
          false
        )
      )
    },
    clearSearch: () => {
      dispatch(clearSearchData())
    },
    clearUnavailableSamples: async () => dispatch(closeErrorPopUp()),
    fetchSamples: (
      pgIds,
      userId,
      shootTypeId,
      shootType,
      name,
      channel,
      collectionName
    ) => {
      dispatch(
        addSamples(
          pgIds,
          userId,
          shootTypeId,
          shootType,
          name,
          channel,
          collectionName,
          true,
          ownProps?.sampleType
        )
      )
    },
    getProjectChannel: () => dispatch(getProjectChannel()),
    getProjectLocation: () => dispatch(getShipToLocation()),
    getShootType: (row, shootType, page, rowsPerPage, goToViewPage) =>
      dispatch(getShootType(row, shootType, page, rowsPerPage, goToViewPage)),
    handleShootTypeDelete: (shootTypeId, shootType, callback = () => {}) =>
      dispatch(deleteShootType(shootTypeId, shootType, callback)),
    handleChipClick: (chip) => {
      let c = chip.target.innerText
      copy(c)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    onOverlayCheckBoxChange: (selectedData = {}) => {
      const { pegasusId = '' } = selectedData
      dispatch(setOverlayCheckBox({ pegasusId: pegasusId }))
    },
    onOverlayCheckBoxHeadChange: (selected = []) => {
      const newData = selected.map((obj = {}) => ({
        pegasusId: obj.pegasusId,
      }))
      dispatch(setOverlayHeaderCheckBox(newData))
    },
    onCheckBoxChange: (selectedData) => dispatch(setCheckBox(selectedData)),
    onCheckBoxHeaderChange: (selected = []) =>
      dispatch(setCheckBoxHeaderChange(selected)),
    onShootTypeUpdate: (
      shootTypeId,
      updateRequest,
      shootType,
      callback = () => {}
    ) =>
      dispatch(
        updateShootType(shootTypeId, updateRequest, shootType, callback)
      ),
    onEditChange: (selectedData, shootType, shipmentId) =>
      dispatch(removeSamples(selectedData, shootType, shipmentId)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    showAlert: (message) => dispatch(showAlert(message)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    closePopup: () => {
      dispatch(closePopup())
    },
    clearTableData: () => {
      dispatch(clearTableData())
    },
    resetNewShootTypeState: () => dispatch(resetNewShootTypeState()),
    createShootType: (userId, shootType) =>
      dispatch(createShootType(userId, shootType)),
    getAProject: (projectID, page, rowsPerPage) => {
      dispatch(getAProject(projectID, page, rowsPerPage))
    },
    getACollection: (projectID, page, rowsPerPage) =>
      dispatch(getACollection(projectID, page, rowsPerPage)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
    // new
    fetchData: (
      params,
      successCallback = () => {},
      failCallback = () => {}
    ) => {
      dispatch(getData(params, successCallback, failCallback))
    },
  }
}

AddSampleToProject.propTypes = {
  alertIsVisible: propTypes.bool,
  onCreate: propTypes.func,
  callAction: propTypes.string,
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  data: propTypes.array,
  handleShootTypeDelete: propTypes.func,
  handleUpdate: propTypes.func,
  numSelected: propTypes.number,
  overlayNumSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onOverlayCheckBoxHeadChange: propTypes.func,
  onClick: propTypes.func,
  selectedData: propTypes.array,
  overlaySelectedData: propTypes.array,
  shipFrom: propTypes.string,
  order: propTypes.string,
  orderBy: propTypes.string,
  page: propTypes.number,
  rowCount: propTypes.number,
  rowsPerPage: propTypes.number,
  userId: propTypes.string,
  unavailableSamples: propTypes.array,
  unavailableSampleColumnData: propTypes.array,
  unavailableSampleProjectColumnDataSlider: propTypes.array,
  unavailableSampleProjectColumnData: propTypes.array,
  unavailableSamplePopUpIsVisible: propTypes.bool,
  getSearchValue: propTypes.func,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(AddSampleToProject)
