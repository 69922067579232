import React from 'react'
import { connect } from 'react-redux'
import { isEmpty, findIndex } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { autocompleteSelectData } from '../AddFilters/constants'
import { setFilters } from '../../store/filters/actionCreator'
import FilterListIcon from '@material-ui/icons/FilterList'

const styles = (theme) => ({
  filterButton: {
    margin: '5px',
  },
})

class QuickFilters extends React.Component {
  constructor(props) {
    super(props)
    const { filters = {} } = this.props
    const filtersCopy = Object.assign({}, filters)
    const { include_filters: currentFilters = {} } = filtersCopy
    this.state = {
      filters: currentFilters,
    }
  }

  setStoreFilters = (filterCopy = {}) => {
    const { pageName = '', setFilters = () => {} } = this.props
    if (!isEmpty(filterCopy)) {
      setFilters(filterCopy, pageName)
    }
  }

  filterClick = (filterName = '', filterValue = '') => {
    const {
      setQuickFiltersUpdated = () => {},
      refreshTableWithNewData = () => {},
    } = this.props
    const { filters = {} } = this.state
    const filtersCopy = Object.assign({}, filters)
    let returnArray = []
    const seleteData = autocompleteSelectData[filterName] || []
    returnArray = seleteData.some((obj) => obj.value === filterValue)
      ? seleteData.filter((obj) => obj.value === filterValue)
      : []
    if (isEmpty(filtersCopy[filterName])) {
      filtersCopy[filterName] = {}
      filtersCopy[filterName].selectData = seleteData
    } else {
      if (!isEmpty(filtersCopy[filterName].chipArray)) {
        const currentFilterChipArray = [...filtersCopy[filterName].chipArray]
        const currentFilterExists =
          findIndex(currentFilterChipArray, function (obj) {
            return obj.value === filterValue
          }) !== -1
        if (currentFilterExists) {
          const toggleCurrentFilterValue = currentFilterChipArray.filter(
            (obj) => obj.value !== filterValue
          )
          returnArray = [...toggleCurrentFilterValue]
        } else {
          returnArray = [...filtersCopy[filterName].chipArray, ...returnArray]
          returnArray = [...new Set(returnArray)]
        }
      }
    }
    filtersCopy[filterName].chipArray = returnArray
    this.setState({
      filters: filtersCopy,
    })
    const formattedFilters = Object.assign({}, { include_filters: filtersCopy })
    refreshTableWithNewData(formattedFilters, true)
    setQuickFiltersUpdated(true, filtersCopy)
  }
  render() {
    const { filters = {} } = this.state
    const { quickFilterName = '', classes = {} } = this.props
    const filtersCopy = Object.assign({}, filters)
    const selectedFilters =
      !isEmpty(filtersCopy[quickFilterName]) &&
      !isEmpty(filtersCopy[quickFilterName].chipArray)
        ? filtersCopy[quickFilterName].chipArray
        : []
    return (
      <div>
        {autocompleteSelectData[quickFilterName].map((statusObj = {}) => {
          const {
            value: statusValue = '',
            quickFilterLabel: statusLabel = '',
          } = statusObj
          return (
            <Tooltip title={statusLabel}>
              <Button
                color="secondary"
                className={classes.filterButton}
                variant={
                  selectedFilters.some((obj) => obj.value === statusValue)
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  this.filterClick(quickFilterName, statusValue)
                }}
                startIcon={<FilterListIcon />}
              >
                {statusLabel}
              </Button>
            </Tooltip>
          )
        })}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    setFilters: (filters, pageName) => dispatch(setFilters(filters, pageName)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuickFilters))
