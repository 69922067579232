import axios from 'axios'
import apiConfig from '../apiConfig'

const {
  managements: { url: managementsUrl = '' },
  apiKey = '',
} = apiConfig

export function getPartners() {
  return axios.get(managementsUrl + 'shoot_shipments/shipment_partners', {
    params: {
      key: apiKey,
    },
  })
}
