import axios from 'axios'
import apiConfig from '../apiConfig'

export function downloadLaunchDateReport(params) {
  const {
    externalReports: { url = '', apiKey = '' },
  } = apiConfig
  return axios.get(url + 'launch_date_reports', {
    headers: { Accept: 'text/csv' },
    params: {
      key: apiKey,
    },
  })
}
