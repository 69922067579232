export const SHOW_SEARCH_INVENTORY_PAGE_POPUP =
  'SHOW_SEARCH_INVENTORY_PAGE_POPUP'
export const CLOSE_SEARCH_INVENTORY_PAGE_POPUP =
  'CLOSE_SEARCH_INVENTORY_PAGE_POPUP'
export const FETCH_INVENTORY_DATA_FAILURE = 'FETCH_INVENTORY_DATA_FAILURE'
export const FETCH_INVENTORY_DATA_REQUEST_PENDING =
  'FETCH_INVENTORY_DATA_PENDING'
export const FETCH_INVENTORY_DATA_SUCCESS = 'FETCH_INVENTORY_DATA_SUCCESS'
export const SEARCH_INVENTORY_CLEAR_DATA = 'SEARCH_INVENTORY_CLEAR_DATA'
export const SEARCH_INVENTORY_SET_CHECKBOX = 'SEARCH_INVENTORY_SET_CHECKBOX'
export const SEARCH_INVENTORY_SET_CHECKBOX_HEADER =
  'SEARCH_INVENTORY_SET_CHECKBOX_HEADER'
export const SEARCH_INVENTORY_SET_INVALID_DATA =
  'SEARCH_INVENTORY_SET_INVALID_DATA'
export const SEARCH_INVENTORY_SET_FILTERS = 'SEARCH_INVENTORY_SET_FILTERS'
export const SEARCH_INVENTORY_SET_PAGE = 'SEARCH_INVENTORY_SET_PAGE'
export const SEARCH_INVENTORY_SET_ROWS_PER_PAGE =
  'SEARCH_INVENTORY_SET_ROWS_PER_PAGE'
export const SEARCH_INVENTORY_SET_TOTAL_ROWS_COUNT =
  'SEARCH_INVENTORY_SET_TOTAL_ROWS_COUNT'
export const SHOW_SELECTED_REPRINT_LABELS_POPUP =
  'SHOW_SELECTED_REPRINT_LABELS_POPUP'
export const SEARCH_INVENTORY_SAVE_FILTERS_FAILURE =
  'SEARCH_INVENTORY_SAVE_FILTERS_FAILURE'
export const SEARCH_INVENTORY_SAVE_FILTERS_PENDING =
  'SEARCH_INVENTORY_SAVE_FILTERS_PENDING'
export const SEARCH_INVENTORY_SAVE_FILTERS_SUCCESS =
  'SEARCH_INVENTORY_SAVE_FILTERS_SUCCESS'
export const SEARCH_INVENTORY_GET_FILTERS_SUCCESS =
  'SEARCH_INVENTORY_GET_FILTERS_SUCCESS'
export const SEARCH_INVENTORY_GET_FILTERS_FAILURE =
  'SEARCH_INVENTORY_GET_FILTERS_FAILURE'
export const SEARCH_INVENTORY_GET_FILTERS_PENDING =
  'SEARCH_INVENTORY_GET_FILTERS_PENDING'
export const SEARCH_INVENTORY_DUPLICATE_SAMPLES_PENDING =
  'SEARCH_INVENTORY_DUPLICATE_SAMPLES_PENDING'
export const SEARCH_INVENTORY_DUPLICATE_SAMPLES_FAILURE =
  'SEARCH_INVENTORY_DUPLICATE_SAMPLES_FAILURE'
export const SEARCH_INVENTORY_SET_FILTER_NOTFOUND_LIST =
  'SEARCH_INVENTORY_SET_FILTER_NOTFOUND_LIST'
export const SEARCH_INVENTORY_UPDATE_SAMPLES_REQUEST_FAILURE =
  'SEARCH_INVENTORY_UPDATE_SAMPLES_REQUEST_FAILURE'
export const SEARCH_INVENTORY_SAMPLES_REQUEST_PENDING =
  'SEARCH_INVENTORY_SAMPLES_REQUEST_PENDING'

export const CALL_EDIT_FIELDS_API = 'CALL_EDIT_FIELDS_API'

export const CALL_HISTORY_API = 'CALL_HISTORY_API'
export const CALL_HISTORY_API_FAILED = 'CALL_HISTORY_API_FAILED'
export const CALL_HISTORY_FILTERS_API = 'CALL_HISTORY_FILTERS_API'
export const CALL_HISTORY_FILTERS_API_FAILED = 'CALL_HISTORY_FILTERS_API_FAILED'
export const CALL_SEARCH_FIELDS = 'CALL_SEARCH_FIELDS'
export const RESET_IS_CALL_SEARCH_FIELDS = 'RESET_IS_CALL_SEARCH_FIELDS'
export const SET_COLUMN_SETTINGS_PENDING = 'SET_COLUMN_SETTINGS_PENDING'
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS'
export const EMPTY_SELECTED_DATA = 'EMPTY_SELECTED_DATA'
export const AUTO_REFRESH_SEARCH = 'AUTO_REFRESH_SEARCH'
export const SEARCH_INVENTORY_DUPLICATE_SAMPLES_SUCCESS =
  'SEARCH_INVENTORY_DUPLICATE_SAMPLES_SUCCESS'
export const SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_PENDING =
  'SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_PENDING'
export const SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_SUCCESS =
  'SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_SUCCESS'
export const SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_FAILURE =
  'SEARCH_INVENTORY_CANCEL_SAMPLES_REQUEST_FAILURE'
export const SEARCH_INVENTORY_PAGE_ALERT = 'SEARCH_INVENTORY_PAGE_ALERT'
export const CLOSE_SEARCH_INVENTORY_PAGE_ALERT =
  'CLOSE_SEARCH_INVENTORY_PAGE_ALERT'
export const SET_PROPS_METADATA = 'SET_PROPS_METADATA'
export const SET_PROPS_METADATA_FAILED = 'SET_PROPS_METADATA_FAILED'
export const SET_ALL_BRANDS = 'SET_ALL_BRANDS'
export const SET_ALL_BRANDS_FAILED = 'SET_ALL_BRANDS_FAILED'
export const ADD_A_PROP_API_STATUS = 'ADD_A_PROP_API_STATUS'
export const SET_ADD_PROP_DATA = 'SET_ADD_PROP_DATA'
export const SEARCH_INVENTORY_SET_FILTER_NOTFOUND_LIST_PROP =
  'SEARCH_INVENTORY_SET_FILTER_NOTFOUND_LIST_PROP'
export const SET_PROPS_METADATA_SUBCAT_FAILED =
  'SET_PROPS_METADATA_SUBCAT_FAILED'
export const SET_PROPS_METADATA_SUBCAT = 'SET_PROPS_METADATA_SUBCAT'
export const SET_SCENIC_METADATA = 'SET_SCENIC_METADATA'
export const SET_SCENIC_METADATA_FAILED = 'SET_SCENIC_METADATA_FAILED'
export const DOWNLOAD_LOADING = 'DOWNLOAD_LOADING'
export const LOCATION_FILTER_DATA = 'LOCATION_FILTER_DATA'
