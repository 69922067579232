import { connect } from 'react-redux'
import { useAuth } from '@praxis/component-auth'
import { isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import withNav from './withNavigation'
import { withEnv } from './withEnv'

const withHOCs =
  ({ auth = true, nav = true, styles, connectParams = {}, env = false }) =>
  (Component) => {
    let ComposedHoc = Component

    if (styles) {
      // TODO: useStyles hook instead of withStyles
      ComposedHoc = withStyles(styles)(ComposedHoc)
    }
    if (auth) {
      ComposedHoc = InjectAuthPropsHoc(ComposedHoc)
    }
    if (!isEmpty(connectParams)) {
      const { mapStateToProps = {}, mapDispatchToProps = {} } = connectParams
      ComposedHoc = connect(mapStateToProps, mapDispatchToProps)(ComposedHoc)
    }
    if (nav) {
      // TODO: Doesn't work withEnv
      ComposedHoc = withNav(ComposedHoc)
    }
    if (env) {
      ComposedHoc = withEnv(ComposedHoc)
    }
    return ComposedHoc
  }

export const InjectAuthPropsHoc = (Component) => {
  return function WrappedComponent(props) {
    const {
      login,
      logout,
      isAuthorized,
      isAuthenticated,
      session,
      ...restAuthProps
    } = useAuth()

    const injectedProp = {
      login,
      logout,
      isAuthorized,
      isAuthenticated,
      session: session ?? { userInfo: {} },
      ...restAuthProps,
    }

    return <Component {...props} auth={injectedProp} />
  }
}

export default withHOCs
