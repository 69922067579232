import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { clearSearchData } from '../../store/searchInventory/actionCreator'
import withHOCs from 'util/withHocs'
import { SCENIC } from 'enums/Tabs'

const styles = (theme) => ({
  settingsPrimaryText: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
})

const createPropByList = {
  DUPLICATE_PROP: 'Duplicate Existing Prop',
  CONVERT_SAMPLE_TO_PROP: 'Convert Sample Into Prop',
  CONVERT_TCIN_TO_PROP: 'Add Prop Using TCIN/DPCI/UPC',
  NEW_PROP: 'Similar Props, Sample or TCIN not available',
}
const createScenicByList = {
  DUPLICATE_SCENIC: 'Duplicate Existing Scenic Asset/Edit Details',
  NEW_SCENIC: 'New Scenic Asset, Enter Details From Scratch',
}
const createPropByKeyList = {
  DUPLICATE_PROP: 'Prop ID',
  CONVERT_SAMPLE_TO_PROP: 'Pegasus ID',
  CONVERT_TCIN_TO_PROP: 'TCIN',
  NEW_PROP: '',
}
const createScenicByKeyList = {
  DUPLICATE_SCENIC: 'Scenic ID',
  NEW_SCENIC: '',
}

function AddPropHeader({
  captureSelectedOption = () => {},
  existCreatePropBy = 'DUPLICATE_PROP',
  selectedTab = '',
  classes = {},
}) {
  const [createPropBy, setCreatePropBy] = React.useState(existCreatePropBy)
  const dispatch = useDispatch()

  useEffect(() => {
    return function cleanup() {
      setCreatePropBy(existCreatePropBy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(clearSearchData())
  }, [createPropBy, dispatch])

  const renderRadioButton = (
    addPropType = selectedTab !== SCENIC
      ? 'DUPLICATE_PROP'
      : 'DUPLICATE_SCENIC',
    updateStateValues = () => {}
  ) => {
    const renderList =
      selectedTab !== SCENIC ? createPropByList : createScenicByList
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Grid container alignItems={'center'}>
            <Grid item xs={10}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={addPropType}
                  onChange={(event) => {
                    updateStateValues(event.target.value)
                  }}
                >
                  {Object.keys(renderList).map((lKey = '', index) => {
                    return (
                      <FormControlLabel
                        value={lKey}
                        control={<Radio />}
                        label={renderList[lKey]}
                        style={index !== Number(0) ? { marginLeft: 20 } : {}}
                      />
                    )
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const createPropByChange = (propBy = 'DUPLICATE_PROP') => {
    const propByKey =
      selectedTab !== SCENIC
        ? createPropByKeyList[propBy]
        : createScenicByKeyList[propBy]
    setCreatePropBy(propBy)
    captureSelectedOption({ createPropBy: propBy, propByKey })
  }
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          {renderRadioButton(createPropBy, createPropByChange)}
        </Grid>
      </Grid>
    </div>
  )
}

export default withHOCs({
  auth: false,
  nav: false,
  styles,
})(AddPropHeader)
