import * as types from './actionType'
import * as projectAPI from '../../services/projectsService'
import * as projectMapper from '../../mappers/projectMapper'
import { download, createErrorString } from '../../util/DownloadCsv'
import { showNotification } from '../../store/notification/actionCreator'
import {
  getShootType,
  setRowsPerPage,
  isRowsPerPageUpdated,
  setTotalRowsCount,
} from '../../store/newShootType/actionCreator'
import { ShootTypes } from '../../enums/shootTypes'
import { ORDERED } from '../../enums/Tabs'

import { isEmpty } from 'lodash'
import { downloadLoading } from 'store/searchInventory/actionCreator'

export function getProjects(params = {}) {
  let projectSamples = []
  return (dispatch) => {
    dispatch(getProjectsPending(true))
    return projectAPI
      .getAllProjects(params)
      .then((res) => projectMapper.projectResponseToProjects(res.data.projects))
      .then((projects) => {
        projectSamples = projects.map(({ id, sampleCount }) => ({
          key: id,
          value: sampleCount,
        }))
        dispatch(getProjectSamples(projectSamples))
        dispatch(getProjectsSuccess(projects))
      })
      .catch((error) => {
        dispatch(getProjectsFailure())
      })
      .finally(() => {
        dispatch(getProjectsPending(false))
      })
  }
}
export function editSamplesSubmit(params = {}, lanId) {
  return (dispatch) => {
    dispatch(getProjectsPending(true))
    return projectAPI
      .editSamplesSubmit(params, lanId)
      .then((res) => {
        dispatch(updateSuccess(true))
        dispatch(editFlyoutOpen())
        dispatch(showNotification(true, 'Data Updated Succesfully', 'success'))
      })
      .catch((error) => {
        const { response = {} } = error
        const { data = {} } = response
        const { errors = '' } = data
        errors === ''
          ? dispatch(showNotification(true, 'Error While Updating'))
          : dispatch(showNotification(true, errors))
      })
      .finally(() => {
        dispatch(getProjectsPending(false))
      })
  }
}
export function getAProject(
  projectID = '',
  page,
  rowsPerPage,
  sampleType = ORDERED,
  filters = {}
) {
  return (dispatch, getState) => {
    const state = getState() || {}
    const {
      newShootType: { rowsPerPage: stateRowsPerPage = Number(100) },
    } = state
    dispatch(getProjectsPending(true))
    return projectAPI
      .getProject(projectID)
      .then((res) => {
        const currentProjectData = projectMapper.projectResponseToProject(
          res.data
        )
        const { sampleCount = 50 } = currentProjectData
        const finalSampleCount =
          sampleCount <= 10
            ? 10
            : sampleCount <= 50
              ? 50
              : sampleCount <= 100
                ? 100
                : sampleCount >= 1000
                  ? 1000
                  : sampleCount
        if (stateRowsPerPage === 9999) {
          dispatch(setRowsPerPage(finalSampleCount))
        }
        dispatch(setTotalRowsCount(sampleCount))
        dispatch(isRowsPerPageUpdated())
        dispatch(getAProjectsSuccess(currentProjectData))
        return currentProjectData
      })
      .then((projectData = {}) => {
        const { id: projectId = '' } = projectData
        const defaultParams = {
          project_id: [projectId],
        }
        dispatch(
          getShootType(
            projectData,
            ShootTypes.PROJECT,
            page,
            rowsPerPage,
            true,
            sampleType,
            filters,
            defaultParams
          )
        )
      })
      .catch(() => {
        dispatch(getProjectsFailure())
      })
      .finally(() => {
        dispatch(getProjectsPending(false))
      })
  }
}

export function downloadOrderSamplesBySelect(shootTypeId, selectedPGIds) {
  return (dispatch) => {
    projectAPI
      .downloadInvenSampBySelect(shootTypeId, selectedPGIds)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function downloadProjectSamples(projectId) {
  return (dispatch) => {
    return projectAPI
      .downloadProjectSamples(projectId)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function downloadRequestedSamples(projectId) {
  return (dispatch) => {
    dispatch(downloadLoading(true,'CSV'))
    return projectAPI
      .downloadRequestedSamples(projectId)
      .then((response) => {
        download(response, dispatch, "CSV")
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function downloadCSVRequestedSamples(projectId, shootTypeIds = []) {
  return (dispatch) => {
    return projectAPI
      .downloadCSVRequestedSamples(projectId, shootTypeIds)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function submitOrderForSelected(shootTypeIds = []) {
  return (dispatch) => {
    return projectAPI
      .submitOrderForSelected(shootTypeIds)
      .then((response) => {
        dispatch(showNotification(true, 'Submited successfully', 'success'))
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while submitting the order'
          )
        )
      })
  }
}

export function downloadProjectContactSheet(params = {}, downloadType = '') {
  return (dispatch) => {
    return projectAPI
      .downloadProjectContactSheetPost(params, downloadType)
      .then((response) => {
        download(response)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

export function downloadAllProjectSamples(projects) {
  return (dispatch) => {
    let erroredProjects = []
    let promises = []
    for (let index in projects) {
      promises.push(
        projectAPI
          .downloadProjectSamples(projects[index].id)
          .then((response) => {
            download(response)
          })
          .catch((e) => {
            erroredProjects.push(projects[index].name)
          })
      )
    }

    return Promise.all(promises).then(() => {
      if (erroredProjects.length) {
        dispatch(showNotification(true, createErrorString(erroredProjects)))
      }
    })
  }
}

export function getProjectsPending(pending = false) {
  return {
    type: types.PROJECTS_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function getProjectsFailure() {
  return {
    type: types.PROJECTS_REQUEST_FAILURE,
  }
}

export function updateSuccess(payload = false) {
  return {
    type: types.EDIT_UPDATE_SUCCESS,
    payload: payload,
  }
}
export function editFlyoutOpen() {
  return {
    type: types.EDIT_FLYOUT_OPEN,
  }
}
export function getProjectsSuccess(projects = []) {
  return {
    type: types.PROJECTS_REQUEST_SUCCESS,
    payload: { projects: projects },
  }
}

export function getAProjectsSuccess(projects = []) {
  return {
    type: types.CURRENT_PROJECT_REQUEST_SUCCESS,
    payload: { projects: projects },
  }
}

export function setFilter(filteredData) {
  return {
    type: types.PROJECTS_SET_FILTER,
    payload: { filter: filteredData },
  }
}

export function clearFilter() {
  return {
    type: types.PROJECTS_CLEAR_FILTER,
  }
}

export function showCreateProjectPopup() {
  return {
    type: types.PROJECTS_SHOW_CREATE_POPUP,
  }
}

export function closeCreateProjectPopup() {
  return {
    type: types.PROJECTS_CLOSE_CREATE_POPUP,
  }
}

export function setTabvalue(tabValue = '') {
  if (!isEmpty(tabValue)) {
    return {
      type: types.SET_TABVALUE,
      payload: { tabValue: tabValue },
    }
  }
}

export function getProjectSamples(sampleCount = 10) {
  return {
    type: types.SET_SAMPLE_PROJECTS_VALUE,
    payload: { sampleCount: sampleCount },
  }
}

export const saveProjectSearchValue = (filterValue = '') => {
  return {
    type: types.SET_PROJECTS_SEARCH_VALUE,
    payload: { filterValue },
  }
}

export const requestSamplesWithCSV = (
  fileToUpload,
  projectID = '',
  callback = () => {}
) => {
  return async (dispatch) => {
    return await projectAPI
      .uploadCSVFile(fileToUpload, projectID)
      .then((response = {}) => {
        const {
          data: { tcinShootTypes = [], result = {} },
        } = response
        const resultData = tcinShootTypes.length > 0 ? tcinShootTypes : result
        const isExists = Object.entries(result || {}).length > 0
        const statusType = isExists ? 'partialsuccess' : 'success'
        dispatch(setRequestSamplesStatus(statusType))
        callback(statusType, resultData)
      })
      .catch((error = {}) => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while performing the operation'
          )
        )
        const { response: { data: { errors: { errors = [] } = {} } } = {} } =
          error
        callback('error', errors)
        dispatch(setRequestSamplesStatus('error'))
      })
  }
}

export const requestSamplesWithoutCSV = (
  itemList = [],
  projectID = '',
  callback = () => {}
) => {
  return (dispatch) => {
    return projectAPI
      .requestSamplesWithOutCSV(itemList, projectID)
      .then((response = {}) => {
        const {
          data: { tcinShootTypes = [], result = {} },
        } = response
        const isExists = Object.entries(result || {}).length > 0
        const statusType = isExists ? 'partialsuccess' : 'success'
        const resultData = tcinShootTypes.length > 0 ? tcinShootTypes : result
        dispatch(setRequestSamplesStatus(statusType))
        callback(statusType, resultData)
      })
      .catch((error) => {
        const { response: { data: { errors: { errors = [] } = {} } } = {} } =
          error
        callback('error', errors)
        dispatch(setRequestSamplesStatus('error'))
      })
  }
}

export function setRequestSamplesStatus(status) {
  return {
    type: types.SET_REQUEST_SAMPLES_STATUS,
    payload: { requestSamplesStatus: status },
  }
}
