import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'

function NumericFormatCustom(props) {
  const { onChange, ref, ...other } = props
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
    />
  )
}

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
export default NumericFormatCustom
