//

// eslint-disable-next-line no-unused-vars
import * as React from 'react'

export function Footer(props) {
  const { children, ...additionalProps } = props
  return <div {...additionalProps}>{children}</div>
}

Footer.displayName = 'Footer'

export default Footer
