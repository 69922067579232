import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import {
  Button,
  DialogActions,
  DialogContent,
  withStyles,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const DialogTitle = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  closeButton: {
    color: theme.palette.grey[900],
  },
}))((props) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          id="nodeCloseButton"
          aria-label="Close"
          className={classes.closeButton}
          onClick={() => onClose(null)}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const styles = {
  previous: {
    marginRight: 'auto',
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'baseline',
    padding: '16px 36px',
  },
}

class Node extends PureComponent {
  render() {
    const {
      classes,
      label,
      content,
      disableNext,
      hasNext,
      hasPrev,
      isOpen,
      next,
      nextLabel,
      prev,
      secondaryAction,
      secondaryActionLabel,
      onClose,
    } = this.props

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={'md'}
        aria-labelledby={label}
        aria-describedby={label}
      >
        <DialogTitle onClose={onClose}>{label}</DialogTitle>
        {content !== undefined && (
          <DialogContent id="nodeContent">{content}</DialogContent>
        )}
        <DialogActions id="nodeActions" className={classes.dialogActions}>
          {hasPrev && (
            <Button
              className={classes.previous}
              id="nodePrev"
              color="primary"
              onClick={prev}
            >
              Previous
            </Button>
          )}
          {secondaryActionLabel !== null && (
            <Button
              id="secondaryAction"
              variant="outlined"
              color="primary"
              onClick={
                secondaryActionLabel === 'Cancel'
                  ? () => {
                      onClose(null)
                    }
                  : secondaryAction
              }
            >
              {secondaryActionLabel}
            </Button>
          )}
          <Button
            id="nodeNext"
            disabled={disableNext}
            variant="contained"
            color={'primary'}
            onClick={next}
          >
            {nextLabel ? nextLabel : hasNext ? 'Next' : 'Done'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

Node.defaultProps = {
  nextLabel: 'Next',
  secondaryActionLabel: 'Secondary',
}

Node.propTypes = {
  disableNext: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool,
  secondaryAction: PropTypes.func,
  secondaryActionLabel: PropTypes.string,
  next: PropTypes.func,
  nextLabel: PropTypes.string,
  prev: PropTypes.func,
  onClose: PropTypes.func,
  label: PropTypes.string,
  content: PropTypes.object,
}

export default withStyles(styles)(Node)
