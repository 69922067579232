//

/**
 * There is currently an issue combining MUI's `withStyles`
 * and react-router-dom's `withRouter`. The issue is being
 * tracked here:
 *
 * https://github.com/digiaonline/react-flow-types/issues/12#issuecomment-337336269
 *
 * Once that is resolved, `withRouter` can be moved into `Tab.js`
 * and `Hack.js` can be deleted without breaking API changes.
 */
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Tab from './Tab'

export const Hack = () => {
  const { ...passThrough } = this.props
  const location = useLocation()
  let navigate = useNavigate()
  return <Tab navigate={navigate} location={location} {...passThrough} />
}

export default Hack
