//

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CaptureContent from './CaptureContent'
import { MAX_TAB_CONTENT_WIDTH_MAP, getScreenSizeKey } from '../constants'

/**
 * Converts child Nodes into an array of objects
 * and caches the results.
 */
export class TabManager extends React.Component {
  static defaultProps = {
    classes: {},
  }

  state = {
    childList: [],
    screenSizeKey: getScreenSizeKey(),
    isIndexed: false,
  }

  render() {
    const { children, rawInput, classes } = this.props
    const { childList, isIndexed, screenSizeKey } = this.state

    return (
      <div>
        <div className={classes.visuallyHidden}>
          {React.Children.map(rawInput, ({ props }, index) => {
            const { to, label, ...passThroughProps } = props

            return (
              <CaptureContent
                key={index}
                maxWidth={MAX_TAB_CONTENT_WIDTH_MAP[screenSizeKey]}
                onWidthSet={this.setTabInfo({
                  index,
                  to,
                  ...passThroughProps,
                })}
              >
                {label}
              </CaptureContent>
            )
          })}
        </div>
        {isIndexed &&
          React.Children.map(children, (child) =>
            React.cloneElement(child, {
              childrenAsPropsList: childList,
            })
          )}
      </div>
    )
  }

  setTabInfo =
    ({ index, to, ...childProps }) =>
    ({ children, isRambling, width }) => {
      const childList = this.state.childList

      const tab = {
        ...childProps,
        index,
        to,
        children,
        isRambling,
        width,
      }

      childList.push(tab)

      this.setState({
        childList,
        isIndexed: true,
      })
    }
}

const styleSheet = {
  visuallyHidden: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
}

export default withStyles(styleSheet)(TabManager)
