export const CLOSE_ORDER_SUMMARY_PAGE_ALERT = 'CLOSE_ORDER_SUMMARY_PAGE_ALERT'
export const CLOSE_ORDER_SUMMARY_PAGE_POPUP = 'CLOSE_ORDER_SUMMARY_PAGE_POPUP'
export const ORDER_SUMMARY_SET_CHECKBOX = 'ORDER_SUMMARY_SET_CHECKBOX'
export const ORDER_SUMMARY_SET_CHECKBOX_HEADER =
  'ORDER_SUMMARY_SET_CHECKBOX_HEADER'
export const ORDER_SUMMARY_SET_DATA = 'ORDER_SUMMARY_SET_DATA'
export const ORDER_SUMMARY_SELECT_SORT = 'ORDER_SUMMARY_SELECT_SORT'
export const ORDER_SUMMARY_SUBMIT_ORDER = 'ORDER_SUMMARY_SUBMIT_ORDER'
export const ORDER_SUMMARY_VENDOR_ORDER_REQUEST_FAILURE =
  'ORDER_SUMMARY_VENDOR_ORDER_REQUEST_FAILURE'
export const ORDER_SUMMARY_VENDOR_ORDER_REQUEST_PENDING =
  'ORDER_SUMMARY_VENDOR_ORDER_REQUEST_PENDING'
export const ORDER_SUMMARY_VENDOR_ORDER_REQUEST_SUCCESS =
  'ORDER_SUMMARY_VENDOR_ORDER_REQUEST_SUCCESS'
export const SHOW_ORDER_SUMMARY_PAGE_ALERT = 'SHOW_ORDER_SUMMARY_PAGE_ALERT'
export const SHOW_ORDER_SUMMARY_PAGE_POPUP = 'SHOW_ORDER_SUMMARY_PAGE_POPUP'
export const LOCATION_CHANGE = 'LOCATION_CHANGE'
export const SET_ORDER_SUMMARY_PAGE_PAGE = 'SET_ORDER_SUMMARY_PAGE_PAGE'
export const SET_ORDER_SUMMARY_PAGE_ROWS_PER_PAGE =
  'SET_ORDER_SUMMARY_PAGE_ROWS_PER_PAGE'
export const SET_ORDER_SUMMARY_PAGE_TOTAL_ROWS_COUNT =
  'SET_ORDER_SUMMARY_PAGE_TOTAL_ROWS_COUNT'
export const ORDER_SUMMARY_SET_FILTER_NOTFOUND_LIST =
  'ORDER_SUMMARY_SET_FILTER_NOTFOUND_LIST'
export const SET_ORDER_SUMMARY_PAGE_SORTED_DATA =
  'SET_ORDER_SUMMARY_PAGE_SORTED_DATA'
