import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { ProtectedElement } from '@praxis/component-auth'
import ProjectsPage from '../../pages/ShootTypePages/ProjectsPage'
import ProjectCrud from '../../pages/ShootTypePages/ProjectCrud'
import CollectionsPage from '../../pages/ShootTypePages/CollectionsPage'
import NewShootTypePage from '../../pages/ShootTypePages/NewShootTypePage'
import ShootTypeView from '../../pages/ShootTypePages/ShootTypeView'
import ShipSamplesPage from '../../pages/ShipSamplesPages/ShipSamplesPage'
import NewShipmentPage from '../../pages/ShipSamplesPages/NewShipmentPage'
import SearchInventoryPage from '../../pages/SearchInventoryPage/SearchInventoryPage'
import NotFound from '../../pages/NotFoundPage/NotFoundPage'
import AddSample from '../../pages/SearchInventoryPage/AddSample'
import MyOrdersPage from '../../pages/MyOrdersPage/MyOrdersPage'
import AddProp from '../../pages/SearchInventoryPage/AddProp'
import withHOCs from 'util/withHocs'
import apiConfig from 'apiConfig'
import NewScenicPage from 'pages/ScenicRooms/NewScenicPage'

const subViewList = {
  projects: {
    view: {
      component: ProjectCrud, // TODO: use same for collection wrt Design changes
      roles: apiConfig.roles.projects,
    },
    edit: {
      component: ProjectCrud,
      roles: apiConfig.roles.projects,
    },
    create: {
      component: ProjectCrud,
      roles: apiConfig.roles.projects,
    },
    default: {
      component: ProjectsPage,
      roles: apiConfig.roles.projects,
    },
  },
  collections: {
    view: {
      component: ShootTypeView,
      roles: apiConfig.roles.projects,
    },
    edit: {
      component: NewShootTypePage,
      roles: apiConfig.roles.projects,
    },
    create: {
      component: NewShootTypePage,
      roles: apiConfig.roles.projects,
    },
    default: {
      component: CollectionsPage,
      roles: apiConfig.roles.projects,
    },
  },
  shipSamplesList: {
    view: {
      // component: ShippedDetailsPage,
      // Using same component for both view and edit
      component: NewShipmentPage,
      roles: apiConfig.roles.shipSamples,
    },
    edit: {
      component: NewShipmentPage,
      roles: apiConfig.roles.shipSamples,
    },
    create: {
      component: NewShipmentPage,
      roles: apiConfig.roles.shipSamples,
    },
    default: {
      component: ShipSamplesPage,
      roles: apiConfig.roles.shipSamples,
    },
  },
  scenicRoom: {
    view: {
      component: NewScenicPage,
      roles: apiConfig.roles.scenicRoom,
    },
    edit: {
      component: NewScenicPage,
      roles: apiConfig.roles.scenicRoom,
    },
    create: {
      component: NewScenicPage,
      roles: apiConfig.roles.scenicRoom,
    },
    default: {
      component: NewScenicPage,
      roles: apiConfig.roles.scenicRoom,
    },
  },
  searchInventory: {
    addProp: {
      component: AddProp,
      roles: apiConfig.roles.search,
    },
    addSample: {
      component: AddSample,
      roles: apiConfig.roles.search,
    },
    default: {
      component: SearchInventoryPage,
      roles: apiConfig.roles.search,
    },
  },
  myOrders: {
    addSample: {
      component: AddSample,
      roles: apiConfig.roles.mySamples,
    },
    default: {
      component: MyOrdersPage,
      roles: apiConfig.roles.mySamples,
    },
  },
  default: NotFound,
}
const SubView = (props) => {
  let SubViewPage = subViewList.default
  let { pageName = '', subPageName = '', pageId = '' } = useParams()
  if (pageName && Object.keys(subViewList).indexOf(pageName) !== -1) {
    SubViewPage =
      subViewList[pageName][subPageName]?.component ||
      subViewList[pageName].default?.component
  }
  return (
    <ProtectedElement
      allowed={
        subViewList[pageName]?.[subPageName]?.['roles'] ||
        subViewList[pageName]?.['default']?.['roles']
      }
      render={() => (
        <SubViewPage
          pageName={pageName}
          subPageName={subPageName}
          pageId={pageId}
          {...props}
        />
      )}
    />
  )
}

SubView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      pageName: PropTypes.string,
      subPageName: PropTypes.string,
      pageId: PropTypes.string,
    }),
  }),
}
export default withHOCs({
  auth: true,
})(SubView)
