import React from 'react'
import { Route, Routes } from 'react-router-dom'
import withHOCs from 'util/withHocs'
import { useDispatch } from 'react-redux'
import BarcodePage from 'pages/Barcode/BarcodePage'
import AutoOrderingGrid from '../../pages/SearchPage/AutoOrderingGrid'
import SearchPageRouter from '../../pages/SearchInventoryPage/SearchPageRouter'
import SearchArchivedSamples from '../../pages/SearchInventoryPage/SearchArchivedSamples'
import NotFound from '../../pages/NotFoundPage/NotFoundPage'
import UnAuthorized from '../../pages/UnAuthorized/UnAuthorizedPage'
import OrderPage from '../../pages/OrderPage/OrderPage'
import OrderSamples from '../../pages/OrderSamples/OrderSamples'
import SettingsPage from '../../pages/SettingsPage/SettingsPage'
import OrderConfirmationPage from '../../pages/OrderConfirmationPage/OrderConfirmationPage'
import OrderReceiptPage from '../../pages/OrderReceiptPage/OrderReceiptPage'
import VendorLoginPage from '../../pages/VendorLoginPage/VendorLoginPage'
import CheckInPage from '../../pages/CheckInPage/CheckInPage'
import MovePage from '../../pages/CheckInPage/MovePage'
import OrderSummaryPage from '../../pages/OrderSummaryPage/OrderSummaryPage'
import OrderSummaryPageNew from 'pages/OrderSummaryPage/OrderSummaryPageNew'
import ShipmentBarcodePage from '../../pages/ShipmentBarcodePage/ShipmentBarcodePage'
import ShipmentSummaryPage from '../../pages/ShipmentSummaryPage/ShipmentSummaryPage'
import MyOrderPage from '../../pages/MyOrdersPage/MyOrdersPage'
import ProjectsPage from '../../pages/ShootTypePages/ProjectsPage'
import CollectionsPage from '../../pages/ShootTypePages/CollectionsPage'
import NewShootTypePage from '../../pages/ShootTypePages/NewShootTypePage'
import ShootTypeView from '../../pages/ShootTypePages/ShootTypeView'
import ShipSamplesPage from '../../pages/ShipSamplesPages/ShipSamplesPage'
import NewShipmentPage from '../../pages/ShipSamplesPages/NewShipmentPage'
import ManageLocationsPage from '../../pages/ManageLocationsPage/ManageLocationsPage'
import HelpPage from '../../pages/HelpPage/HelpPage'
import VendorShipSamples from '../../pages/VendorShipSamples/VendorShipSamples'
import { ProtectedElement } from '@praxis/component-auth'
import apiConfig from '../../apiConfig'
import SubView from './subViews'
import { PROPS, SCENIC } from 'enums/Tabs'
import ScenicRoomMainPage from 'pages/ScenicRooms/ScenicRoomMainPage'
import SearchInventoryPage from 'pages/SearchInventoryPage/SearchInventoryPage'
import {
  openBarcodeSideNav,
  closeBarcodeSideNav,
} from '../../store/layout/actionCreator'

const styles = (theme) => ({})

const LayoutRouter = (props) => {
  const dispatch = useDispatch()
  const { classes, auth, barcodeSideNavIsOpen, navigate = () => {} } = props

  const { isAuthorized = () => {} } = auth

  const handleBarcodeAction = () => {
    if (barcodeSideNavIsOpen) {
      dispatch(closeBarcodeSideNav())
    } else {
      dispatch(openBarcodeSideNav())
    }
  }

  const appDefaultRouteElement = () => {
    return isAuthorized(apiConfig.roles.search) ? (
      <OrderSamples />
    ) : isAuthorized(apiConfig.roles.vendor) ? (
      <VendorLoginPage />
    ) : isAuthorized(apiConfig.roles.props) ? (
      <SearchInventoryPage pageName={'searchInventory'} selectedTab={PROPS} />
    ) : isAuthorized(apiConfig.roles.scenic) ? (
      <SearchInventoryPage pageName={'searchInventory'} selectedTab={SCENIC} />
    ) : isAuthorized(apiConfig.roles.checkIn) ? (
      <CheckInPage />
    ) : (
      <UnAuthorized />
    )
  }

  return (
    <>
      <Routes>
        <Route exact path="/" element={appDefaultRouteElement()} />
        <Route exact path="/pegasus" element={appDefaultRouteElement()} />
        <Route
          path="/searchInventory/:selectedTab?/:action?/:type?/:id?"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.inventory}
              render={() => (
                <SearchPageRouter handleBarcodeAction={handleBarcodeAction} />
              )}
            />
          }
        />
        <Route
          path="/SearchArchivedSamples"
          exact
          element={
            <ProtectedElement
              allowed={apiConfig.roles.inventory}
              render={() => <SearchArchivedSamples />}
            />
          }
        />
        <Route
          path="/vendorLogin"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.vendor}
              render={() => <VendorLoginPage />}
            />
          }
        />
        <Route
          path="/order"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.search}
              render={() => <OrderPage />}
            />
          }
        />
        <Route
          path="/OrderSamples"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.search}
              render={() => <OrderSamples />}
            />
          }
        />
        <Route
          path="/AutoOrderGrid"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.search}
              render={() => <AutoOrderingGrid />}
            />
          }
        />
        <Route
          path="/orderConfirmation"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.search}
              render={() => <OrderConfirmationPage />}
            />
          }
        />
        <Route
          path="/orderReceipt"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.search}
              render={() => <OrderReceiptPage />}
            />
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.setting}
              render={() => <SettingsPage />}
            />
          }
        />
        <Route
          path="/projects"
          exact
          element={
            <ProtectedElement
              allowed={apiConfig.roles.projects}
              render={() => <ProjectsPage />}
            />
          }
        />
        <Route
          path="/collections"
          exact
          element={
            <ProtectedElement
              allowed={apiConfig.roles.projects}
              render={() => <CollectionsPage />}
            />
          }
        />
        <Route
          path="/newShootType"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.projects}
              render={() => <NewShootTypePage />}
            />
          }
        />
        <Route
          path="/shootTypeView"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.projects}
              render={() => <ShootTypeView />}
            />
          }
        />
        <Route
          path="/orderSummary"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.vendor}
              render={() => <OrderSummaryPage />}
            />
          }
        />
        <Route
          path="/orderSummaryNew"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.vendor}
              render={() => <OrderSummaryPageNew />}
            />
          }
        />

        <Route
          path="/shipmentBarcode"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.vendor}
              render={() => <ShipmentBarcodePage />}
            />
          }
        />
        <Route
          path="/vendorShipSamples"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.vendor}
              render={() => <VendorShipSamples />}
            />
          }
        />
        <Route
          path="/OrderSamples"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.search}
              render={() => <OrderSamples />}
            />
          }
        />
        <Route
          path="/shipmentSummary"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.vendor}
              render={() => <ShipmentSummaryPage />}
            />
          }
        />
        <Route
          path="/scenicRooms"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.scenic}
              render={() => <ScenicRoomMainPage />}
            />
          }
        />
        <Route
          path="/myOrders"
          exact
          element={
            <ProtectedElement
              allowed={apiConfig.roles.mySamples}
              render={() => <MyOrderPage />}
            />
          }
        />
        <Route
          path="/checkIn"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.checkIn}
              render={() => (
                <CheckInPage handleBarcodeAction={handleBarcodeAction} />
              )}
            />
          }
        />
        <Route
          path="/moveSamples"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.checkIn}
              render={() => (
                <MovePage handleBarcodeAction={handleBarcodeAction} />
              )}
            />
          }
        />
        <Route
          path="/shipSamples"
          exact
          element={
            <ProtectedElement
              allowed={apiConfig.roles.shipSamples}
              render={() => (
                <ShipSamplesPage handleBarcodeAction={handleBarcodeAction} />
              )}
            />
          }
        />
        <Route
          path="/newShipment"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.shipSamples}
              render={() => <NewShipmentPage />}
            />
          }
        />
        <Route
          path="/manageLocations"
          element={
            <ProtectedElement
              allowed={apiConfig.roles.checkIn}
              render={() => <ManageLocationsPage />}
            />
          }
        />
        <Route
          path="/help"
          element={
            <ProtectedElement
              allowed={[
                ...(apiConfig.roles.helpUsers || []),
                ...(apiConfig.roles.helpvendor || []),
              ]}
              render={() => <HelpPage />}
            />
          }
        />
        <Route
          path="/batchScanner"
          element={
            <ProtectedElement
              allowed={[
                ...(apiConfig.roles.helpUsers || []),
                ...(apiConfig.roles.helpvendor || []),
                ...apiConfig.roles.props,
              ]}
              render={() => <BarcodePage />}
            />
          }
        />
        {['/:pageName/:pageId/:subPageName', '/:pageName/:subPageName'].map(
          (path) => (
            <Route path={path} element={<SubView />} key={path} />
          )
        )}
        <Route path="/unauthorized" component={UnAuthorized} />
        <Route component={NotFound} />
      </Routes>
    </>
  )
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {},
})(LayoutRouter)
