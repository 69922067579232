import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import AddSample from './AddSample'
import SearchInventoryPage from './SearchInventoryPage'
import AddProp from './AddProp'
import { SAMPLES } from '../../enums/Tabs'

const searchInventory = {
  addProp: AddProp,
  convertToProp: AddProp,
  duplicateProp: AddProp,
  addSample: AddSample,
  addScenic: AddProp,
  default: SearchInventoryPage,
}

const SearchPageRouter = () => {
  let { selectedTab = SAMPLES, action = '', type = '', id = '' } = useParams()
  let SearchPageView = searchInventory['default']
  if (action) {
    SearchPageView = searchInventory[action]
  }
  return (
    <SearchPageView
      pageName={'searchInventory'}
      selectedTab={selectedTab.toUpperCase()}
      action={action}
      type={type}
      id={id}
    />
  )
  /*
  TODO: use nested routers 
  return (
    <Routes>
      <Route path=":selectedTab/addProp" element={<AddProp />} />
      <Route path="/:selectedTab?/addSample" element={<AddSample />} />
      <Route path="/" exact element={<SearchInventoryPage />} />
    </Routes>
  )
  */
}

SearchPageRouter.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      pageName: PropTypes.string,
      subPageName: PropTypes.string,
    }),
  }),
}

export default SearchPageRouter
