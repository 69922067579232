import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CustomPaper } from 'util/CommonUtils'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  notchedOutlineNew: {
    borderColor: '#8d8d8d !important',
    borderWidth: '2px !important',
  },
  searchInputLabel: {
    color: '#666 !important',
    fontSize: '14px',
    fontWeight: 'initial',
  },
}))

export default function CustomAutoComplete({
  handleUpdate,
  optionsList = [],
  selectedValue = '',
  label: componentLabel = '',
  name = '',
  disabled = false,
}) {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={6}>
        <Autocomplete
          value={selectedValue}
          limitTags={2}
          disabled={disabled}
          onChange={(event, newValue) => handleUpdate(newValue, name)}
          componentsProps={{
            popper: { style: { border: '2px solid #666', width: 360 } },
            paper: {
              sx: {
                marginLeft: 0.5,
              },
            },
          }}
          selectOnFocus
          clearOnBlur
          id={`${componentLabel + '_shipfilter'}`}
          options={optionsList}
          getOptionLabel={(option) => option.label}
          style={{ width: 360 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={componentLabel}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                classes: {
                  notchedOutline: classes.notchedOutlineNew,
                },
              }}
              InputLabelProps={{
                className: classes.searchInputLabel,
              }}
            />
          )}
          PaperComponent={CustomPaper}
          multiple
        />
      </Grid>
    </Grid>
  )
}
