export const getLocationsByLevels =
  (componentType = '') =>
  ({ locations = {} }) => {
    const componentBasedLocations = locations[componentType] || {}
    const locationsCopy = Object.assign({}, componentBasedLocations)
    const { locationsByLevels = {} } = locationsCopy
    return locationsByLevels
  }

export const getCurrentLocationId =
  (componentType = '') =>
  ({ locations = {} }) => {
    const componentBasedLocations = locations[componentType] || {}
    const locationsCopy = Object.assign({}, componentBasedLocations)
    const { locationId = '' } = locationsCopy
    return locationId
  }

export const getLevelsWithLabelsStr =
  (componentType = '') =>
  ({ locations = {} }) => {
    const componentBasedLocations = locations[componentType] || {}
    const locationsCopy = Object.assign({}, componentBasedLocations)
    const { levelsWithLabels = {} } = locationsCopy
    const levelsWithLabelsValues = Object.values(levelsWithLabels) || []
    const levelsWithLabelsStr = levelsWithLabelsValues
      .filter((x) => x)
      .join(' > ')
    return levelsWithLabelsStr
  }

export const getLevelsWithLabels =
  (componentType = '') =>
  ({ locations = {} }) => {
    const componentBasedLocations = locations[componentType] || {}
    const locationsCopy = Object.assign({}, componentBasedLocations)
    const { levelsWithLabels = {} } = locationsCopy
    return levelsWithLabels
  }

export const getCurrentBuilding =
  (componentType = '') =>
  ({ locations = {} }) => {
    const componentBasedLocations = locations[componentType] || {}
    const locationsCopy = Object.assign({}, componentBasedLocations)
    const { levelsWithLabels = {} } = locationsCopy
    const levelsWithLabelsStr = levelsWithLabels['building'] || ''
    return levelsWithLabelsStr
  }

export const getLevelsToCreateLocation =
  (componentType = '') =>
  ({ locations = {} }) => {
    const componentBasedLocations = locations[componentType] || {}
    const locationsCopy = Object.assign({}, componentBasedLocations)
    const { selectedValueByLevel = {} } = locationsCopy
    return selectedValueByLevel
  }

export const getLocationIdsByLevel =
  (componentType = '') =>
  ({ locations = {} }) => {
    const componentBasedLocations = locations[componentType] || {}
    const locationsCopy = Object.assign({}, componentBasedLocations)
    const { selectedLocationIdByLevel = {} } = locationsCopy
    return selectedLocationIdByLevel
  }

export const getIsNewLocationCreated =
  (componentType = '') =>
  ({ locations = {} }) => {
    const componentBasedLocations = locations[componentType] || {}
    const locationsCopy = Object.assign({}, componentBasedLocations)
    const { isNewLocationCreated } = locationsCopy
    return isNewLocationCreated
  }

export const getCreatedLocationData =
  (componentType = '') =>
  ({ locations = {} }) => {
    const componentBasedLocations = locations[componentType] || {}
    const locationsCopy = Object.assign({}, componentBasedLocations)
    const { newLocationData = {} } = locationsCopy
    return newLocationData
  }
