import axios from 'axios'
import apiConfig from '../apiConfig'
import { getPegasusID } from 'util/CommonUtils'
import { SAMPLE } from 'enums/Tabs'

const {
  managements: { url = '' },
  apiKey = '',
} = apiConfig

export const fetchScenicRoomByPegId = (pegasusId) => {
  return axios.get(url + 'scenic/rooms/' + pegasusId, {
    params: {
      key: apiKey,
    },
  })
}

export const fetchScenicRooms = (payload = {}) => {
  const { page = 1, rowsPerPage = 100, status = 'ACTIVE' } = payload
  return axios.get(url + 'scenic/rooms', {
    params: {
      status,
      page,
      rowsPerPage,
      key: apiKey,
    },
  })
}

export const createScenicRoom = (roomDetails) => {
  const formdata = createFormData(roomDetails, true)
  return axios.post(url + 'scenic/rooms', formdata, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    params: {
      key: apiKey,
    },
  })
}

export const downloadConflictSamples = (scenicRoomId, pegasusIds) => {
  return axios.post(
    url + 'scenic/rooms/' + scenicRoomId + '/export_conflicts',
    {
      pegasus_ids: [...pegasusIds],
    },
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export function downloadScenicRoomContactSheet(params = {}, downloadType = '') {
  return axios.get(url + 'search?downloadAs=' + downloadType, {
    headers: { Accept: '*/*' },
    params: Object.assign(
      {},
      {
        key: apiKey,
      },
      params
    ),
    responseType: 'blob',
  })
}

export function downloadScenicRoomContactSheetPost(
  params = {},
  downloadType = '',
  itemType = SAMPLE
) {
  return axios.post(
    url + 'search?downloadAs=' + downloadType + '&sample_type=' + itemType,
    params,
    {
      headers: { Accept: '*/*' },
      params: {
        key: apiKey,
      },
      responseType: 'blob',
    }
  )
}

export const addScenicRoom = (
  scenicRoomId,
  pegasusIds,
  fileMakerOrPegasusIds = [],
  checkRequired = true
) => {
  const requestPayload = {
    pegasus_ids: pegasusIds.map((id) => getPegasusID(id)),
    filemaker_ids: [],
    check_required: checkRequired,
  }
  if (fileMakerOrPegasusIds && fileMakerOrPegasusIds.length > 0) {
    requestPayload.filemaker_ids = fileMakerOrPegasusIds
  }
  return axios.post(
    url + 'scenic/rooms/' + scenicRoomId + '/scenic_items',
    requestPayload,
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export const getScenicRoom = (scenicRoomId) => {
  return axios.get(url + 'scenic/rooms/' + scenicRoomId, {
    params: {
      key: apiKey,
    },
  })
}

export const deleteScenicRoom = (scenicRoomId, updateDetails) => {
  return updateScenicRoom(scenicRoomId, {
    ...updateDetails,
    status: 'ARCHIVED',
  })
}

export const removeScenic = (selectedData, scenicRoomId) => {
  const pegasusIds = selectedData.map((data) => data.pegasusId)
  return axios.delete(`${url}scenic/rooms/scenic_assets/${scenicRoomId}`, {
    headers: {
      'Content-Type': `application/json`,
    },
    data: {
      pegasus_ids: pegasusIds.map((id) => getPegasusID(id)),
    },
    params: {
      key: apiKey,
    },
  })
}

export const createFormData = (updateDetails, isCreateNewRoom = false) => {
  let formdata = new window.FormData()
  const inputFile = updateDetails.selectedImage
  const jsonFormData = { ...updateDetails }
  delete jsonFormData.selectedImage

  formdata.append(
    isCreateNewRoom ? 'scenic_room_request' : 'update_scenic_request',
    new window.Blob(
      [
        JSON.stringify({
          ...jsonFormData,
        }),
      ],
      {
        type: 'application/json',
      }
    )
  )

  if (inputFile) {
    formdata.append('file', inputFile)
  }

  return formdata
}

export const updateScenicRoom = async (roomId, updateDetails) => {
  const formdata = createFormData(updateDetails, false)
  return await axios.put(url + 'scenic/rooms/' + roomId, formdata, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    params: {
      key: apiKey,
    },
  })
}

export function fetchAllScenicRooms(params = {}) {
  return axios.get(url + 'scenic/rooms/names', {
    headers: { 'Content-type': 'application/json' },
    params: Object.assign(params, { key: apiKey }),
  })
}
