export const countries = [
  {
    label: 'United States',
    value: 'United States',
  },
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    label: 'Åland Islands',
    value: 'Åland Islands',
  },
  {
    label: 'Albania',
    value: 'Albania',
  },
  {
    label: 'Algeria',
    value: 'Algeria',
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
  },
  {
    label: 'Andorra',
    value: 'Andorra',
  },
  {
    label: 'Angola',
    value: 'Angola',
  },
  {
    label: 'Anguilla',
    value: 'Anguilla',
  },
  {
    label: 'Antarctica',
    value: 'Antarctica',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
  },
  {
    label: 'Aruba',
    value: 'Aruba',
  },
  {
    label: 'Australia',
    value: 'Australia',
  },
  {
    label: 'Austria',
    value: 'Austria',
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
  },
  {
    label: 'Belarus',
    value: 'Belarus',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
  },
  {
    label: 'Belize',
    value: 'Belize',
  },
  {
    label: 'Benin',
    value: 'Benin',
  },
  {
    label: 'Bermuda',
    value: 'Bermuda',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
  },
  {
    label: 'Bolivia, Plurinational State of',
    value: 'Bolivia, Plurinational State of',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
  },
  {
    label: 'Bouvet Island',
    value: 'Bouvet Island',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
  },
  {
    label: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
  },
  {
    label: 'Burundi',
    value: 'Burundi',
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
  },
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'Cape Verde',
    value: 'Cape Verde',
  },
  {
    label: 'Cayman Islands',
    value: 'Cayman Islands',
  },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
  },
  {
    label: 'Chad',
    value: 'Chad',
  },
  {
    label: 'Chile',
    value: 'Chile',
  },
  {
    label: 'China',
    value: 'China',
  },
  {
    label: 'Christmas Island',
    value: 'Christmas Island',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
  },
  {
    label: 'Comoros',
    value: 'Comoros',
  },
  {
    label: 'Congo',
    value: 'Congo',
  },
  {
    label: 'Congo, the Democratic Republic of the',
    value: 'Congo, the Democratic Republic of the',
  },
  {
    label: 'Cook Islands',
    value: 'Cook Islands',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
  },
  {
    label: "Côte d'Ivoire",
    value: "Côte d'Ivoire",
  },
  {
    label: 'Croatia',
    value: 'Croatia',
  },
  {
    label: 'Cuba',
    value: 'Cuba',
  },
  {
    label: 'Curaçao',
    value: 'Curaçao',
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
  },
  {
    label: 'Czech Republic',
    value: 'Czech Republic',
  },
  {
    label: 'Denmark',
    value: 'Denmark',
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
  },
  {
    label: 'Dominica',
    value: 'Dominica',
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)',
  },
  {
    label: 'Faroe Islands',
    value: 'Faroe Islands',
  },
  {
    label: 'Fiji',
    value: 'Fiji',
  },
  {
    label: 'Finland',
    value: 'Finland',
  },
  {
    label: 'France',
    value: 'France',
  },
  {
    label: 'French Guiana',
    value: 'French Guiana',
  },
  {
    label: 'French Polynesia',
    value: 'French Polynesia',
  },
  {
    label: 'French Southern Territories',
    value: 'French Southern Territories',
  },
  {
    label: 'Gabon',
    value: 'Gabon',
  },
  {
    label: 'Gambia',
    value: 'Gambia',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Germany',
    value: 'Germany',
  },
  {
    label: 'Ghana',
    value: 'Ghana',
  },
  {
    label: 'Gibraltar',
    value: 'Gibraltar',
  },
  {
    label: 'Greece',
    value: 'Greece',
  },
  {
    label: 'Greenland',
    value: 'Greenland',
  },
  {
    label: 'Grenada',
    value: 'Grenada',
  },
  {
    label: 'Guadeloupe',
    value: 'Guadeloupe',
  },
  {
    label: 'Guam',
    value: 'Guam',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
  },
  {
    label: 'Guernsey',
    value: 'Guernsey',
  },
  {
    label: 'Guinea',
    value: 'Guinea',
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
  },
  {
    label: 'Heard Island and McDonald Mcdonald Islands',
    value: 'Heard Island and McDonald Mcdonald Islands',
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'Holy See (Vatican City State)',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
  },
  {
    label: 'Hong Kong',
    value: 'Hong Kong',
  },
  {
    label: 'Hungary',
    value: 'Hungary',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
  },
  {
    label: 'India',
    value: 'India',
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
  },
  {
    label: 'Iran, Islamic Republic of',
    value: 'Iran, Islamic Republic of',
  },
  {
    label: 'Iraq',
    value: 'Iraq',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
  },
  {
    label: 'Isle of Man',
    value: 'Isle of Man',
  },
  {
    label: 'Israel',
    value: 'Israel',
  },
  {
    label: 'Italy',
    value: 'Italy',
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
  },
  {
    label: 'Japan',
    value: 'Japan',
  },
  {
    label: 'Jersey',
    value: 'Jersey',
  },
  {
    label: 'Jordan',
    value: 'Jordan',
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
  },
  {
    label: "Korea, Democratic People's Republic of",
    value: "Korea, Democratic People's Republic of",
  },
  {
    label: 'Korea, Republic of',
    value: 'Korea, Republic of',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
  },
  {
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
  },
  {
    label: 'Latvia',
    value: 'Latvia',
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
  },
  {
    label: 'Liberia',
    value: 'Liberia',
  },
  {
    label: 'Libya',
    value: 'Libya',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
  },
  {
    label: 'Macao',
    value: 'Macao',
  },
  {
    label: 'Macedonia, the Former Yugoslav Republic of',
    value: 'Macedonia, the Former Yugoslav Republic of',
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
  },
  {
    label: 'Malawi',
    value: 'Malawi',
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
  },
  {
    label: 'Mali',
    value: 'Mali',
  },
  {
    label: 'Malta',
    value: 'Malta',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    label: 'Martinique',
    value: 'Martinique',
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
  },
  {
    label: 'Mayotte',
    value: 'Mayotte',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
  },
  {
    label: 'Micronesia, Federated States of',
    value: 'Micronesia, Federated States of',
  },
  {
    label: 'Moldova, Republic of',
    value: 'Moldova, Republic of',
  },
  {
    label: 'Monaco',
    value: 'Monaco',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
  },
  {
    label: 'Montenegro',
    value: 'Montenegro',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
  },
  {
    label: 'Namibia',
    value: 'Namibia',
  },
  {
    label: 'Nauru',
    value: 'Nauru',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
  },
  {
    label: 'New Caledonia',
    value: 'New Caledonia',
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
  },
  {
    label: 'Niger',
    value: 'Niger',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
  },
  {
    label: 'Niue',
    value: 'Niue',
  },
  {
    label: 'Norfolk Island',
    value: 'Norfolk Island',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
  },
  {
    label: 'Norway',
    value: 'Norway',
  },
  {
    label: 'Oman',
    value: 'Oman',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
  },
  {
    label: 'Palau',
    value: 'Palau',
  },
  {
    label: 'Palestine, State of',
    value: 'Palestine, State of',
  },
  {
    label: 'Panama',
    value: 'Panama',
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
  },
  {
    label: 'Peru',
    value: 'Peru',
  },
  {
    label: 'Philippines',
    value: 'Philippines',
  },
  {
    label: 'Pitcairn',
    value: 'Pitcairn',
  },
  {
    label: 'Poland',
    value: 'Poland',
  },
  {
    label: 'Portugal',
    value: 'Portugal',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
  },
  {
    label: 'Qatar',
    value: 'Qatar',
  },
  {
    label: 'Réunion',
    value: 'Réunion',
  },
  {
    label: 'Romania',
    value: 'Romania',
  },
  {
    label: 'Russian Federation',
    value: 'Russian Federation',
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
  },
  {
    label: 'Saint Barthélemy',
    value: 'Saint Barthélemy',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'Saint Martin (French part)',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    label: 'Samoa',
    value: 'Samoa',
  },
  {
    label: 'San Marino',
    value: 'San Marino',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
  },
  {
    label: 'Serbia',
    value: 'Serbia',
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'Sint Maarten (Dutch part)',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
  },
  {
    label: 'Somalia',
    value: 'Somalia',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
  },
  {
    label: 'South Sudan',
    value: 'South Sudan',
  },
  {
    label: 'Spain',
    value: 'Spain',
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
  },
  {
    label: 'Sudan',
    value: 'Sudan',
  },
  {
    label: 'Suriname',
    value: 'Suriname',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
  },
  {
    label: 'Swaziland',
    value: 'Swaziland',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
  },
  {
    label: 'Taiwan, Province of China',
    value: 'Taiwan, Province of China',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
  },
  {
    label: 'Timor-Leste',
    value: 'Timor-Leste',
  },
  {
    label: 'Togo',
    value: 'Togo',
  },
  {
    label: 'Tokelau',
    value: 'Tokelau',
  },
  {
    label: 'Tonga',
    value: 'Tonga',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
  },
  {
    label: 'United Kingdom',
    value: 'United Kingdom',
  },
  {
    label: 'United States',
    value: 'United States',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
  },
  {
    label: 'Venezuela, Bolivarian Republic of',
    value: 'Venezuela, Bolivarian Republic of',
  },
  {
    label: 'Viet Nam',
    value: 'Viet Nam',
  },
  {
    label: 'Virgin Islands, British',
    value: 'Virgin Islands, British',
  },
  {
    label: 'Virgin Islands, U.S.',
    value: 'Virgin Islands, U.S.',
  },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
  },
  {
    label: 'Western Sahara',
    value: 'Western Sahara',
  },
  {
    label: 'Yemen',
    value: 'Yemen',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
  },
]

export const usaStates = [
  {
    label: 'ALABAMA',
    value: 'ALABAMA',
  },
  {
    label: 'ALASKA',
    value: 'ALASKA',
  },
  {
    label: 'AMERICAN SAMOA',
    value: 'AMERICAN SAMOA',
  },
  {
    label: 'ARIZONA',
    value: 'ARIZONA',
  },
  {
    label: 'ARKANSAS',
    value: 'ARKANSAS',
  },
  {
    label: 'CALIFORNIA',
    value: 'CALIFORNIA',
  },
  {
    label: 'COLORADO',
    value: 'COLORADO',
  },
  {
    label: 'CONNECTICUT',
    value: 'CONNECTICUT',
  },
  {
    label: 'DELAWARE',
    value: 'DELAWARE',
  },
  {
    label: 'DISTRICT OF COLUMBIA',
    value: 'DISTRICT OF COLUMBIA',
  },
  {
    label: 'FEDERATED STATES OF MICRONESIA',
    value: 'FEDERATED STATES OF MICRONESIA',
  },
  {
    label: 'FLORIDA',
    value: 'FLORIDA',
  },
  {
    label: 'GEORGIA',
    value: 'GEORGIA',
  },
  {
    label: 'GUAM',
    value: 'GUAM',
  },
  {
    label: 'HAWAII',
    value: 'HAWAII',
  },
  {
    label: 'IDAHO',
    value: 'IDAHO',
  },
  {
    label: 'ILLINOIS',
    value: 'ILLINOIS',
  },
  {
    label: 'INDIANA',
    value: 'INDIANA',
  },
  {
    label: 'IOWA',
    value: 'IOWA',
  },
  {
    label: 'KANSAS',
    value: 'KANSAS',
  },
  {
    label: 'KENTUCKY',
    value: 'KENTUCKY',
  },
  {
    label: 'LOUISIANA',
    value: 'LOUISIANA',
  },
  {
    label: 'MAINE',
    value: 'MAINE',
  },
  {
    label: 'MARSHALL ISLANDS',
    value: 'MARSHALL ISLANDS',
  },
  {
    label: 'MARYLAND',
    value: 'MARYLAND',
  },
  {
    label: 'MASSACHUSETTS',
    value: 'MASSACHUSETTS',
  },
  {
    label: 'MICHIGAN',
    value: 'MICHIGAN',
  },
  {
    label: 'MINNESOTA',
    value: 'MINNESOTA',
  },
  {
    label: 'MISSISSIPPI',
    value: 'MISSISSIPPI',
  },
  {
    label: 'MISSOURI',
    value: 'MISSOURI',
  },
  {
    label: 'MONTANA',
    value: 'MONTANA',
  },
  {
    label: 'NEBRASKA',
    value: 'NEBRASKA',
  },
  {
    label: 'NEVADA',
    value: 'NEVADA',
  },
  {
    label: 'NEW HAMPSHIRE',
    value: 'NEW HAMPSHIRE',
  },
  {
    label: 'NEW JERSEY',
    value: 'NEW JERSEY',
  },
  {
    label: 'NEW MEXICO',
    value: 'NEW MEXICO',
  },
  {
    label: 'NEW YORK',
    value: 'NEW YORK',
  },
  {
    label: 'NORTH CAROLINA',
    value: 'NORTH CAROLINA',
  },
  {
    label: 'NORTH DAKOTA',
    value: 'NORTH DAKOTA',
  },
  {
    label: 'NORTHERN MARIANA ISLANDS',
    value: 'NORTHERN MARIANA ISLANDS',
  },
  {
    label: 'OHIO',
    value: 'OHIO',
  },
  {
    label: 'OKLAHOMA',
    value: 'OKLAHOMA',
  },
  {
    label: 'OREGON',
    value: 'OREGON',
  },
  {
    label: 'PALAU',
    value: 'PALAU',
  },
  {
    label: 'PENNSYLVANIA',
    value: 'PENNSYLVANIA',
  },
  {
    label: 'PUERTO RICO',
    value: 'PUERTO RICO',
  },
  {
    label: 'RHODE ISLAND',
    value: 'RHODE ISLAND',
  },
  {
    label: 'SOUTH CAROLINA',
    value: 'SOUTH CAROLINA',
  },
  {
    label: 'SOUTH DAKOTA',
    value: 'SOUTH DAKOTA',
  },
  {
    label: 'TENNESSEE',
    value: 'TENNESSEE',
  },
  {
    label: 'TEXAS',
    value: 'TEXAS',
  },
  {
    label: 'UTAH',
    value: 'UTAH',
  },
  {
    label: 'VERMONT',
    value: 'VERMONT',
  },
  {
    label: 'VIRGIN ISLANDS',
    value: 'VIRGIN ISLANDS',
  },
  {
    label: 'VIRGINIA',
    value: 'VIRGINIA',
  },
  {
    label: 'WASHINGTON',
    value: 'WASHINGTON',
  },
  {
    label: 'WEST VIRGINIA',
    value: 'WEST VIRGINIA',
  },
  {
    label: 'WISCONSIN',
    value: 'WISCONSIN',
  },
  {
    label: 'WYOMING',
    value: 'WYOMING',
  },
]

export const usaCities = [
  {
    label: 'Aberdeen',
    value: 'Aberdeen',
  },
  {
    label: 'Abilene',
    value: 'Abilene',
  },
  {
    label: 'Akron',
    value: 'Akron',
  },
  {
    label: 'Albany',
    value: 'Albany',
  },
  {
    label: 'Albuquerque',
    value: 'Albuquerque',
  },
  {
    label: 'Alexandria',
    value: 'Alexandria',
  },
  {
    label: 'Allentown',
    value: 'Allentown',
  },
  {
    label: 'Amarillo',
    value: 'Amarillo',
  },
  {
    label: 'Anaheim',
    value: 'Anaheim',
  },
  {
    label: 'Anchorage',
    value: 'Anchorage',
  },
  {
    label: 'Ann Arbor',
    value: 'Ann Arbor',
  },
  {
    label: 'Antioch',
    value: 'Antioch',
  },
  {
    label: 'Apple Valley',
    value: 'Apple Valley',
  },
  {
    label: 'Appleton',
    value: 'Appleton',
  },
  {
    label: 'Arlington',
    value: 'Arlington',
  },
  {
    label: 'Arvada',
    value: 'Arvada',
  },
  {
    label: 'Asheville',
    value: 'Asheville',
  },
  {
    label: 'Athens',
    value: 'Athens',
  },
  {
    label: 'Atlanta',
    value: 'Atlanta',
  },
  {
    label: 'Atlantic City',
    value: 'Atlantic City',
  },
  {
    label: 'Augusta',
    value: 'Augusta',
  },
  {
    label: 'Aurora',
    value: 'Aurora',
  },
  {
    label: 'Austin',
    value: 'Austin',
  },
  {
    label: 'Bakersfield',
    value: 'Bakersfield',
  },
  {
    label: 'Baltimore',
    value: 'Baltimore',
  },
  {
    label: 'Barnstable',
    value: 'Barnstable',
  },
  {
    label: 'Baton Rouge',
    value: 'Baton Rouge',
  },
  {
    label: 'Beaumont',
    value: 'Beaumont',
  },
  {
    label: 'Bel Air',
    value: 'Bel Air',
  },
  {
    label: 'Bellevue',
    value: 'Bellevue',
  },
  {
    label: 'Berkeley',
    value: 'Berkeley',
  },
  {
    label: 'Bethlehem',
    value: 'Bethlehem',
  },
  {
    label: 'Billings',
    value: 'Billings',
  },
  {
    label: 'Birmingham',
    value: 'Birmingham',
  },
  {
    label: 'Bloomington',
    value: 'Bloomington',
  },
  {
    label: 'Boise',
    value: 'Boise',
  },
  {
    label: 'Boise City',
    value: 'Boise City',
  },
  {
    label: 'Bonita Springs',
    value: 'Bonita Springs',
  },
  {
    label: 'Boston',
    value: 'Boston',
  },
  {
    label: 'Boulder',
    value: 'Boulder',
  },
  {
    label: 'Bradenton',
    value: 'Bradenton',
  },
  {
    label: 'Bremerton',
    value: 'Bremerton',
  },
  {
    label: 'Bridgeport',
    value: 'Bridgeport',
  },
  {
    label: 'Brighton',
    value: 'Brighton',
  },
  {
    label: 'Brownsville',
    value: 'Brownsville',
  },
  {
    label: 'Bryan',
    value: 'Bryan',
  },
  {
    label: 'Buffalo',
    value: 'Buffalo',
  },
  {
    label: 'Burbank',
    value: 'Burbank',
  },
  {
    label: 'Burlington',
    value: 'Burlington',
  },
  {
    label: 'Cambridge',
    value: 'Cambridge',
  },
  {
    label: 'Canton',
    value: 'Canton',
  },
  {
    label: 'Cape Coral',
    value: 'Cape Coral',
  },
  {
    label: 'Carrollton',
    value: 'Carrollton',
  },
  {
    label: 'Cary',
    value: 'Cary',
  },
  {
    label: 'Cathedral City',
    value: 'Cathedral City',
  },
  {
    label: 'Cedar Rapids',
    value: 'Cedar Rapids',
  },
  {
    label: 'Champaign',
    value: 'Champaign',
  },
  {
    label: 'Chandler',
    value: 'Chandler',
  },
  {
    label: 'Charleston',
    value: 'Charleston',
  },
  {
    label: 'Charlotte',
    value: 'Charlotte',
  },
  {
    label: 'Chattanooga',
    value: 'Chattanooga',
  },
  {
    label: 'Chesapeake',
    value: 'Chesapeake',
  },
  {
    label: 'Chicago',
    value: 'Chicago',
  },
  {
    label: 'Chula Vista',
    value: 'Chula Vista',
  },
  {
    label: 'Cincinnati',
    value: 'Cincinnati',
  },
  {
    label: 'Clarke County',
    value: 'Clarke County',
  },
  {
    label: 'Clarksville',
    value: 'Clarksville',
  },
  {
    label: 'Clearwater',
    value: 'Clearwater',
  },
  {
    label: 'Cleveland',
    value: 'Cleveland',
  },
  {
    label: 'College Station',
    value: 'College Station',
  },
  {
    label: 'Colorado Springs',
    value: 'Colorado Springs',
  },
  {
    label: 'Columbia',
    value: 'Columbia',
  },
  {
    label: 'Columbus',
    value: 'Columbus',
  },
  {
    label: 'Concord',
    value: 'Concord',
  },
  {
    label: 'Coral Springs',
    value: 'Coral Springs',
  },
  {
    label: 'Corona',
    value: 'Corona',
  },
  {
    label: 'Corpus Christi',
    value: 'Corpus Christi',
  },
  {
    label: 'Costa Mesa',
    value: 'Costa Mesa',
  },
  {
    label: 'Dallas',
    value: 'Dallas',
  },
  {
    label: 'Daly City',
    value: 'Daly City',
  },
  {
    label: 'Danbury',
    value: 'Danbury',
  },
  {
    label: 'Davenport',
    value: 'Davenport',
  },
  {
    label: 'Davidson County',
    value: 'Davidson County',
  },
  {
    label: 'Dayton',
    value: 'Dayton',
  },
  {
    label: 'Daytona Beach',
    value: 'Daytona Beach',
  },
  {
    label: 'Deltona',
    value: 'Deltona',
  },
  {
    label: 'Denton',
    value: 'Denton',
  },
  {
    label: 'Denver',
    value: 'Denver',
  },
  {
    label: 'Des Moines',
    value: 'Des Moines',
  },
  {
    label: 'Detroit',
    value: 'Detroit',
  },
  {
    label: 'Downey',
    value: 'Downey',
  },
  {
    label: 'Duluth',
    value: 'Duluth',
  },
  {
    label: 'Durham',
    value: 'Durham',
  },
  {
    label: 'El Monte',
    value: 'El Monte',
  },
  {
    label: 'El Paso',
    value: 'El Paso',
  },
  {
    label: 'Elizabeth',
    value: 'Elizabeth',
  },
  {
    label: 'Elk Grove',
    value: 'Elk Grove',
  },
  {
    label: 'Elkhart',
    value: 'Elkhart',
  },
  {
    label: 'Erie',
    value: 'Erie',
  },
  {
    label: 'Escondido',
    value: 'Escondido',
  },
  {
    label: 'Eugene',
    value: 'Eugene',
  },
  {
    label: 'Evansville',
    value: 'Evansville',
  },
  {
    label: 'Fairfield',
    value: 'Fairfield',
  },
  {
    label: 'Fargo',
    value: 'Fargo',
  },
  {
    label: 'Fayetteville',
    value: 'Fayetteville',
  },
  {
    label: 'Fitchburg',
    value: 'Fitchburg',
  },
  {
    label: 'Flint',
    value: 'Flint',
  },
  {
    label: 'Fontana',
    value: 'Fontana',
  },
  {
    label: 'Fort Collins',
    value: 'Fort Collins',
  },
  {
    label: 'Fort Lauderdale',
    value: 'Fort Lauderdale',
  },
  {
    label: 'Fort Smith',
    value: 'Fort Smith',
  },
  {
    label: 'Fort Walton Beach',
    value: 'Fort Walton Beach',
  },
  {
    label: 'Fort Wayne',
    value: 'Fort Wayne',
  },
  {
    label: 'Fort Worth',
    value: 'Fort Worth',
  },
  {
    label: 'Frederick',
    value: 'Frederick',
  },
  {
    label: 'Fremont',
    value: 'Fremont',
  },
  {
    label: 'Fresno',
    value: 'Fresno',
  },
  {
    label: 'Fullerton',
    value: 'Fullerton',
  },
  {
    label: 'Gainesville',
    value: 'Gainesville',
  },
  {
    label: 'Garden Grove',
    value: 'Garden Grove',
  },
  {
    label: 'Garland',
    value: 'Garland',
  },
  {
    label: 'Gastonia',
    value: 'Gastonia',
  },
  {
    label: 'Gilbert',
    value: 'Gilbert',
  },
  {
    label: 'Glendale',
    value: 'Glendale',
  },
  {
    label: 'Grand Prairie',
    value: 'Grand Prairie',
  },
  {
    label: 'Grand Rapids',
    value: 'Grand Rapids',
  },
  {
    label: 'Grayslake',
    value: 'Grayslake',
  },
  {
    label: 'Green Bay',
    value: 'Green Bay',
  },
  {
    label: 'GreenBay',
    value: 'GreenBay',
  },
  {
    label: 'Greensboro',
    value: 'Greensboro',
  },
  {
    label: 'Greenville',
    value: 'Greenville',
  },
  {
    label: 'Gulfport-Biloxi',
    value: 'Gulfport-Biloxi',
  },
  {
    label: 'Hagerstown',
    value: 'Hagerstown',
  },
  {
    label: 'Hampton',
    value: 'Hampton',
  },
  {
    label: 'Harlingen',
    value: 'Harlingen',
  },
  {
    label: 'Harrisburg',
    value: 'Harrisburg',
  },
  {
    label: 'Hartford',
    value: 'Hartford',
  },
  {
    label: 'Havre de Grace',
    value: 'Havre de Grace',
  },
  {
    label: 'Hayward',
    value: 'Hayward',
  },
  {
    label: 'Hemet',
    value: 'Hemet',
  },
  {
    label: 'Henderson',
    value: 'Henderson',
  },
  {
    label: 'Hesperia',
    value: 'Hesperia',
  },
  {
    label: 'Hialeah',
    value: 'Hialeah',
  },
  {
    label: 'Hickory',
    value: 'Hickory',
  },
  {
    label: 'High Point',
    value: 'High Point',
  },
  {
    label: 'Hollywood',
    value: 'Hollywood',
  },
  {
    label: 'Honolulu',
    value: 'Honolulu',
  },
  {
    label: 'Houma',
    value: 'Houma',
  },
  {
    label: 'Houston',
    value: 'Houston',
  },
  {
    label: 'Howell',
    value: 'Howell',
  },
  {
    label: 'Huntington',
    value: 'Huntington',
  },
  {
    label: 'Huntington Beach',
    value: 'Huntington Beach',
  },
  {
    label: 'Huntsville',
    value: 'Huntsville',
  },
  {
    label: 'Independence',
    value: 'Independence',
  },
  {
    label: 'Indianapolis',
    value: 'Indianapolis',
  },
  {
    label: 'Inglewood',
    value: 'Inglewood',
  },
  {
    label: 'Irvine',
    value: 'Irvine',
  },
  {
    label: 'Irving',
    value: 'Irving',
  },
  {
    label: 'Jackson',
    value: 'Jackson',
  },
  {
    label: 'Jacksonville',
    value: 'Jacksonville',
  },
  {
    label: 'Jefferson',
    value: 'Jefferson',
  },
  {
    label: 'Jersey City',
    value: 'Jersey City',
  },
  {
    label: 'Johnson City',
    value: 'Johnson City',
  },
  {
    label: 'Joliet',
    value: 'Joliet',
  },
  {
    label: 'Kailua',
    value: 'Kailua',
  },
  {
    label: 'Kalamazoo',
    value: 'Kalamazoo',
  },
  {
    label: 'Kaneohe',
    value: 'Kaneohe',
  },
  {
    label: 'Kansas City',
    value: 'Kansas City',
  },
  {
    label: 'Kennewick',
    value: 'Kennewick',
  },
  {
    label: 'Kenosha',
    value: 'Kenosha',
  },
  {
    label: 'Killeen',
    value: 'Killeen',
  },
  {
    label: 'Kissimmee',
    value: 'Kissimmee',
  },
  {
    label: 'Knoxville',
    value: 'Knoxville',
  },
  {
    label: 'Lacey',
    value: 'Lacey',
  },
  {
    label: 'Lafayette',
    value: 'Lafayette',
  },
  {
    label: 'Lake Charles',
    value: 'Lake Charles',
  },
  {
    label: 'Lakeland',
    value: 'Lakeland',
  },
  {
    label: 'Lakewood',
    value: 'Lakewood',
  },
  {
    label: 'Lancaster',
    value: 'Lancaster',
  },
  {
    label: 'Lansing',
    value: 'Lansing',
  },
  {
    label: 'Laredo',
    value: 'Laredo',
  },
  {
    label: 'Las Cruces',
    value: 'Las Cruces',
  },
  {
    label: 'Las Vegas',
    value: 'Las Vegas',
  },
  {
    label: 'Layton',
    value: 'Layton',
  },
  {
    label: 'Leominster',
    value: 'Leominster',
  },
  {
    label: 'Lewisville',
    value: 'Lewisville',
  },
  {
    label: 'Lexington',
    value: 'Lexington',
  },
  {
    label: 'Lincoln',
    value: 'Lincoln',
  },
  {
    label: 'Little Rock',
    value: 'Little Rock',
  },
  {
    label: 'Long Beach',
    value: 'Long Beach',
  },
  {
    label: 'Lorain',
    value: 'Lorain',
  },
  {
    label: 'Los Angeles',
    value: 'Los Angeles',
  },
  {
    label: 'Louisville',
    value: 'Louisville',
  },
  {
    label: 'Lowell',
    value: 'Lowell',
  },
  {
    label: 'Lubbock',
    value: 'Lubbock',
  },
  {
    label: 'Macon',
    value: 'Macon',
  },
  {
    label: 'Madison',
    value: 'Madison',
  },
  {
    label: 'Manchester',
    value: 'Manchester',
  },
  {
    label: 'Marina',
    value: 'Marina',
  },
  {
    label: 'Marysville',
    value: 'Marysville',
  },
  {
    label: 'McAllen',
    value: 'McAllen',
  },
  {
    label: 'McHenry',
    value: 'McHenry',
  },
  {
    label: 'Medford',
    value: 'Medford',
  },
  {
    label: 'Melbourne',
    value: 'Melbourne',
  },
  {
    label: 'Memphis',
    value: 'Memphis',
  },
  {
    label: 'Merced',
    value: 'Merced',
  },
  {
    label: 'Mesa',
    value: 'Mesa',
  },
  {
    label: 'Mesquite',
    value: 'Mesquite',
  },
  {
    label: 'Miami',
    value: 'Miami',
  },
  {
    label: 'Milwaukee',
    value: 'Milwaukee',
  },
  {
    label: 'Minneapolis',
    value: 'Minneapolis',
  },
  {
    label: 'Miramar',
    value: 'Miramar',
  },
  {
    label: 'Mission Viejo',
    value: 'Mission Viejo',
  },
  {
    label: 'Mobile',
    value: 'Mobile',
  },
  {
    label: 'Modesto',
    value: 'Modesto',
  },
  {
    label: 'Monroe',
    value: 'Monroe',
  },
  {
    label: 'Monterey',
    value: 'Monterey',
  },
  {
    label: 'Montgomery',
    value: 'Montgomery',
  },
  {
    label: 'Moreno Valley',
    value: 'Moreno Valley',
  },
  {
    label: 'Murfreesboro',
    value: 'Murfreesboro',
  },
  {
    label: 'Murrieta',
    value: 'Murrieta',
  },
  {
    label: 'Muskegon',
    value: 'Muskegon',
  },
  {
    label: 'Myrtle Beach',
    value: 'Myrtle Beach',
  },
  {
    label: 'Naperville',
    value: 'Naperville',
  },
  {
    label: 'Naples',
    value: 'Naples',
  },
  {
    label: 'Nashua',
    value: 'Nashua',
  },
  {
    label: 'Nashville',
    value: 'Nashville',
  },
  {
    label: 'New Bedford',
    value: 'New Bedford',
  },
  {
    label: 'New Haven',
    value: 'New Haven',
  },
  {
    label: 'New London',
    value: 'New London',
  },
  {
    label: 'New Orleans',
    value: 'New Orleans',
  },
  {
    label: 'New York',
    value: 'New York',
  },
  {
    label: 'New York City',
    value: 'New York City',
  },
  {
    label: 'Newark',
    value: 'Newark',
  },
  {
    label: 'Newburgh',
    value: 'Newburgh',
  },
  {
    label: 'Newport News',
    value: 'Newport News',
  },
  {
    label: 'Norfolk',
    value: 'Norfolk',
  },
  {
    label: 'Normal',
    value: 'Normal',
  },
  {
    label: 'Norman',
    value: 'Norman',
  },
  {
    label: 'North Charleston',
    value: 'North Charleston',
  },
  {
    label: 'North Las Vegas',
    value: 'North Las Vegas',
  },
  {
    label: 'North Port',
    value: 'North Port',
  },
  {
    label: 'Norwalk',
    value: 'Norwalk',
  },
  {
    label: 'Norwich',
    value: 'Norwich',
  },
  {
    label: 'Oakland',
    value: 'Oakland',
  },
  {
    label: 'Ocala',
    value: 'Ocala',
  },
  {
    label: 'Oceanside',
    value: 'Oceanside',
  },
  {
    label: 'Odessa',
    value: 'Odessa',
  },
  {
    label: 'Ogden',
    value: 'Ogden',
  },
  {
    label: 'Oklahoma City',
    value: 'Oklahoma City',
  },
  {
    label: 'Olathe',
    value: 'Olathe',
  },
  {
    label: 'Olympia',
    value: 'Olympia',
  },
  {
    label: 'Omaha',
    value: 'Omaha',
  },
  {
    label: 'Ontario',
    value: 'Ontario',
  },
  {
    label: 'Orange',
    value: 'Orange',
  },
  {
    label: 'Orem',
    value: 'Orem',
  },
  {
    label: 'Orlando',
    value: 'Orlando',
  },
  {
    label: 'Overland Park',
    value: 'Overland Park',
  },
  {
    label: 'Oxnard',
    value: 'Oxnard',
  },
  {
    label: 'Palm Bay',
    value: 'Palm Bay',
  },
  {
    label: 'Palm Springs',
    value: 'Palm Springs',
  },
  {
    label: 'Palmdale',
    value: 'Palmdale',
  },
  {
    label: 'Panama City',
    value: 'Panama City',
  },
  {
    label: 'Pasadena',
    value: 'Pasadena',
  },
  {
    label: 'Paterson',
    value: 'Paterson',
  },
  {
    label: 'Pembroke Pines',
    value: 'Pembroke Pines',
  },
  {
    label: 'Pensacola',
    value: 'Pensacola',
  },
  {
    label: 'Peoria',
    value: 'Peoria',
  },
  {
    label: 'Philadelphia',
    value: 'Philadelphia',
  },
  {
    label: 'Phoenix',
    value: 'Phoenix',
  },
  {
    label: 'Pittsburgh',
    value: 'Pittsburgh',
  },
  {
    label: 'Plano',
    value: 'Plano',
  },
  {
    label: 'Pomona',
    value: 'Pomona',
  },
  {
    label: 'Pompano Beach',
    value: 'Pompano Beach',
  },
  {
    label: 'Port Arthur',
    value: 'Port Arthur',
  },
  {
    label: 'Port Orange',
    value: 'Port Orange',
  },
  {
    label: 'Port Saint Lucie',
    value: 'Port Saint Lucie',
  },
  {
    label: 'Port St. Lucie',
    value: 'Port St. Lucie',
  },
  {
    label: 'Portland',
    value: 'Portland',
  },
  {
    label: 'Portsmouth',
    value: 'Portsmouth',
  },
  {
    label: 'Poughkeepsie',
    value: 'Poughkeepsie',
  },
  {
    label: 'Providence',
    value: 'Providence',
  },
  {
    label: 'Provo',
    value: 'Provo',
  },
  {
    label: 'Pueblo',
    value: 'Pueblo',
  },
  {
    label: 'Punta Gorda',
    value: 'Punta Gorda',
  },
  {
    label: 'Racine',
    value: 'Racine',
  },
  {
    label: 'Raleigh',
    value: 'Raleigh',
  },
  {
    label: 'Rancho Cucamonga',
    value: 'Rancho Cucamonga',
  },
  {
    label: 'Reading',
    value: 'Reading',
  },
  {
    label: 'Redding',
    value: 'Redding',
  },
  {
    label: 'Reno',
    value: 'Reno',
  },
  {
    label: 'Richland',
    value: 'Richland',
  },
  {
    label: 'Richmond',
    value: 'Richmond',
  },
  {
    label: 'Richmond County',
    value: 'Richmond County',
  },
  {
    label: 'Riverside',
    value: 'Riverside',
  },
  {
    label: 'Roanoke',
    value: 'Roanoke',
  },
  {
    label: 'Rochester',
    value: 'Rochester',
  },
  {
    label: 'Rockford',
    value: 'Rockford',
  },
  {
    label: 'Roseville',
    value: 'Roseville',
  },
  {
    label: 'Round Lake Beach',
    value: 'Round Lake Beach',
  },
  {
    label: 'Sacramento',
    value: 'Sacramento',
  },
  {
    label: 'Saginaw',
    value: 'Saginaw',
  },
  {
    label: 'Saint Louis',
    value: 'Saint Louis',
  },
  {
    label: 'Saint Paul',
    value: 'Saint Paul',
  },
  {
    label: 'Saint Petersburg',
    value: 'Saint Petersburg',
  },
  {
    label: 'Salem',
    value: 'Salem',
  },
  {
    label: 'Salinas',
    value: 'Salinas',
  },
  {
    label: 'Salt Lake City',
    value: 'Salt Lake City',
  },
  {
    label: 'San Antonio',
    value: 'San Antonio',
  },
  {
    label: 'San Bernardino',
    value: 'San Bernardino',
  },
  {
    label: 'San Buenaventura',
    value: 'San Buenaventura',
  },
  {
    label: 'San Diego',
    value: 'San Diego',
  },
  {
    label: 'San Francisco',
    value: 'San Francisco',
  },
  {
    label: 'San Jose',
    value: 'San Jose',
  },
  {
    label: 'Santa Ana',
    value: 'Santa Ana',
  },
  {
    label: 'Santa Barbara',
    value: 'Santa Barbara',
  },
  {
    label: 'Santa Clara',
    value: 'Santa Clara',
  },
  {
    label: 'Santa Clarita',
    value: 'Santa Clarita',
  },
  {
    label: 'Santa Cruz',
    value: 'Santa Cruz',
  },
  {
    label: 'Santa Maria',
    value: 'Santa Maria',
  },
  {
    label: 'Santa Rosa',
    value: 'Santa Rosa',
  },
  {
    label: 'Sarasota',
    value: 'Sarasota',
  },
  {
    label: 'Savannah',
    value: 'Savannah',
  },
  {
    label: 'Scottsdale',
    value: 'Scottsdale',
  },
  {
    label: 'Scranton',
    value: 'Scranton',
  },
  {
    label: 'Seaside',
    value: 'Seaside',
  },
  {
    label: 'Seattle',
    value: 'Seattle',
  },
  {
    label: 'Sebastian',
    value: 'Sebastian',
  },
  {
    label: 'Shreveport',
    value: 'Shreveport',
  },
  {
    label: 'Simi Valley',
    value: 'Simi Valley',
  },
  {
    label: 'Sioux City',
    value: 'Sioux City',
  },
  {
    label: 'Sioux Falls',
    value: 'Sioux Falls',
  },
  {
    label: 'South Bend',
    value: 'South Bend',
  },
  {
    label: 'South Lyon',
    value: 'South Lyon',
  },
  {
    label: 'Spartanburg',
    value: 'Spartanburg',
  },
  {
    label: 'Spokane',
    value: 'Spokane',
  },
  {
    label: 'Springdale',
    value: 'Springdale',
  },
  {
    label: 'Springfield',
    value: 'Springfield',
  },
  {
    label: 'St. Louis',
    value: 'St. Louis',
  },
  {
    label: 'St. Paul',
    value: 'St. Paul',
  },
  {
    label: 'St. Petersburg',
    value: 'St. Petersburg',
  },
  {
    label: 'Stamford',
    value: 'Stamford',
  },
  {
    label: 'Sterling Heights',
    value: 'Sterling Heights',
  },
  {
    label: 'Stockton',
    value: 'Stockton',
  },
  {
    label: 'Sunnyvale',
    value: 'Sunnyvale',
  },
  {
    label: 'Syracuse',
    value: 'Syracuse',
  },
  {
    label: 'Tacoma',
    value: 'Tacoma',
  },
  {
    label: 'Tallahassee',
    value: 'Tallahassee',
  },
  {
    label: 'Tampa',
    value: 'Tampa',
  },
  {
    label: 'Temecula',
    value: 'Temecula',
  },
  {
    label: 'Tempe',
    value: 'Tempe',
  },
  {
    label: 'Thornton',
    value: 'Thornton',
  },
  {
    label: 'Thousand Oaks',
    value: 'Thousand Oaks',
  },
  {
    label: 'Toledo',
    value: 'Toledo',
  },
  {
    label: 'Topeka',
    value: 'Topeka',
  },
  {
    label: 'Torrance',
    value: 'Torrance',
  },
  {
    label: 'Trenton',
    value: 'Trenton',
  },
  {
    label: 'Tucson',
    value: 'Tucson',
  },
  {
    label: 'Tulsa',
    value: 'Tulsa',
  },
  {
    label: 'Tuscaloosa',
    value: 'Tuscaloosa',
  },
  {
    label: 'Tyler',
    value: 'Tyler',
  },
  {
    label: 'Utica',
    value: 'Utica',
  },
  {
    label: 'Vallejo',
    value: 'Vallejo',
  },
  {
    label: 'Vancouver',
    value: 'Vancouver',
  },
  {
    label: 'Vero Beach',
    value: 'Vero Beach',
  },
  {
    label: 'Victorville',
    value: 'Victorville',
  },
  {
    label: 'Virginia Beach',
    value: 'Virginia Beach',
  },
  {
    label: 'Visalia',
    value: 'Visalia',
  },
  {
    label: 'Waco',
    value: 'Waco',
  },
  {
    label: 'Warren',
    value: 'Warren',
  },
  {
    label: 'Washington',
    value: 'Washington',
  },
  {
    label: 'Waterbury',
    value: 'Waterbury',
  },
  {
    label: 'Waterloo',
    value: 'Waterloo',
  },
  {
    label: 'West Covina',
    value: 'West Covina',
  },
  {
    label: 'West Valley City',
    value: 'West Valley City',
  },
  {
    label: 'Westminster',
    value: 'Westminster',
  },
  {
    label: 'Wichita',
    value: 'Wichita',
  },
  {
    label: 'Wilmington',
    value: 'Wilmington',
  },
  {
    label: 'Winston',
    value: 'Winston',
  },
  {
    label: 'Winter Haven',
    value: 'Winter Haven',
  },
  {
    label: 'Worcester',
    value: 'Worcester',
  },
  {
    label: 'Yakima',
    value: 'Yakima',
  },
  {
    label: 'Yonkers',
    value: 'Yonkers',
  },
  {
    label: 'York',
    value: 'York',
  },
  {
    label: 'Youngstown',
    value: 'Youngstown',
  },
]
