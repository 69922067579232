import PartnerType from './PartnerType'
import AddNewPartners from './AddNewPartners'
import PartnersList from './PartnersList'
import ShipmentPartners from './ShipmentPartners'

ShipmentPartners.PartnerType = PartnerType
ShipmentPartners.AddNewPartners = AddNewPartners
ShipmentPartners.PartnersList = PartnersList

export default ShipmentPartners
