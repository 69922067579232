export const SHOW_ADDSAMPLE_PAGE_POPUP = 'SHOW_ADDSAMPLE_PAGE_POPUP'
export const CLOSE_ADDSAMPLE_PAGE_POPUP = 'CLOSE_ADDSAMPLE_PAGE_POPUP'
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE'
export const FETCH_DATA_REQUEST_PENDING = 'FETCH_DATA_PENDING'
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
export const ADDSAMPLE_CLEAR_DATA = 'ADDSAMPLE_CLEAR_DATA'
export const RESET_ADDSAMPLE_STATE = 'RESET_ADDSAMPLE_STATE'
export const ADDSAMPLE_SET_CHECKBOX = 'ADDSAMPLE_SET_CHECKBOX'
export const ADDSAMPLE_SET_CHECKBOX_HEADER = 'ADDSAMPLE_SET_CHECKBOX_HEADER'
export const ADDSAMPLE_SET_INVALID_DATA = 'ADDSAMPLE_SET_INVALID_DATA'
export const UNSET_ADDSAMPLE_SELECTED_DATA = 'UNSET_ADDSAMPLE_SELECTED_DATA'
export const UPDATE_SAMPLE_QUANTITY = 'UPDATE_SAMPLE_QUANTITY'
