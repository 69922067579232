// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Tab, Tabs } from '@material-ui/core'
import ShowIf from 'components/ShowIf'
import AddShipToLocation from './AddShipToLocation'

const styles = () => ({
  root: {
    padding: '10px',
    margin: '10px',
  },
  textField: {
    padding: '10px',
    margin: '10px',
  },
  typography: {
    padding: '10px',
  },
})

const addShipToLocationTab = 'addShipToLocation'
const adminPageTabs = [
  { label: 'Add Ship to location', value: addShipToLocationTab },
  // { label: 'Other admin operations', value: 'other' },
]

const AdminSettingsPage = ({ classes }) => {
  const [currentTabValue, setCurrentTabValue] = useState(addShipToLocationTab)

  const handleTabChange = (e, newValue) => {
    setCurrentTabValue(newValue)
  }

  return (
    <>
      <Tabs
        value={currentTabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="standard"
        aria-label="project-tabs"
      >
        {adminPageTabs.map((obj) => {
          const { label = '', value = '' } = obj
          return (
            <Tab
              data-cy={label}
              label={label}
              value={value}
              className={classes.tabsWrapper}
              key={label}
            />
          )
        })}
      </Tabs>
      <ShowIf condition={currentTabValue === addShipToLocationTab}>
        <AddShipToLocation />
      </ShowIf>
      <ShowIf condition={currentTabValue === 'other'}></ShowIf>
    </>
  )
}

export default withStyles(styles)(AdminSettingsPage)
