//

'no babel-plugin-flow-react-proptypes'

import * as React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import BodyClass from '../BodyClass'
import ScrollContainer from './ScrollContainer'
import TabManager from './TabManager'
import { hasSecondaryNavClass, hasSecondaryNavTallClass } from './constants'

export class SecondaryNav extends React.Component {
  getChildContext() {
    return {
      praxis: {
        accentClass: this.props.accentClass,
      },
    }
  }

  static defaultProps = {
    classes: {},
  }

  state = {
    hasIconTabs: false,
    hasLabelTabs: false,
  }

  componentWillMount() {
    const firstChild = React.Children.toArray(this.props.children)[0]

    if (firstChild) {
      this.setState({
        hasIconTabs: !!firstChild.props.icon,
        hasLabelTabs: !!firstChild.props.label,
      })
    }
  }

  render() {
    const { children, classes } = this.props
    const { hasIconTabs, hasLabelTabs } = this.state
    const isTall = hasIconTabs && hasLabelTabs

    return (
      <div>
        <div className={classes.secondaryNav}>
          <BodyClass classNames={hasSecondaryNavClass} />
          {isTall && <BodyClass classNames={hasSecondaryNavTallClass} />}
          <TabManager rawInput={children}>
            {/* `TabManager` will pass props to `ScrollContainer` */}
            <ScrollContainer />
          </TabManager>
        </div>
        <div className={classes.spacer} />
      </div>
    )
  }
}

SecondaryNav.childContextTypes = {
  praxis: PropTypes.shape({
    accentClass: PropTypes.string,
  }),
}

SecondaryNav.propTypes = {
  children: PropTypes.node,
}

const stylesheet = (theme) => ({
  secondaryNav: {
    height: 48,
    zIndex: theme.zIndex.appBar + 1,
    position: 'fixed',
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48,
    },
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
    [theme.breakpoints.down('md')]: {
      overflowY: 'hidden',
      width: '100%',
    },
  },
  spacer: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  contentOffset__tall: {
    height: 72,
  },
  toolbarRoot__tall: {
    height: '72px !important',
  },
})

export default withStyles(stylesheet)(SecondaryNav)
