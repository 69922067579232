import { object, func } from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { appBarStyles } from '../../praxis-dependencies/SecondaryNav'
import { Link } from 'react-router-dom'
import Logo from '../../images/PegasusLogo.svg'
import withHOCs from 'util/withHocs'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import { Tooltip } from '@material-ui/core'
import { headerBatchScannerPages } from 'util/CommonUtils'
import { matchPath } from 'react-router'
import { isNull } from 'lodash'
export function Header({
  classes,
  menuAction,
  headerTitleArray,
  history,
  barcodeAction,
}) {
  const { location = {} } = history
  const { pathname = '/' } = location

  const renderBatchScanner = () => {
    let renderValue = false
    let match = {}
    headerBatchScannerPages.map((headerBatchScannerPage) => {
      match = matchPath(
        {
          path: headerBatchScannerPage,
          exact: true,
          strict: false,
        },
        pathname
      )
      if (!isNull(match)) {
        renderValue = true
      }
      return {}
    })

    return renderValue
  }
  return (
    <div className={classes.header}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {menuAction && (
            <IconButton
              id="sideNavHamburger"
              onClick={menuAction}
              classes={{ root: classes.button }}
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Logo alt="PegasusLogo" className={classes.logo} />

          <Typography
            variant="h6"
            color="inherit"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            <Link
              to={'/'}
              style={{
                textDecoration: 'none',
                color: 'white',
                pointerEvents: pathname !== '/' ? '' : 'none',
              }}
            >
              {'Pegasus'}
            </Link>
            {headerTitleArray
              ? headerTitleArray.map((titleElement) => (
                  <span key={titleElement.title}>
                    {'  >  '}
                    <Link
                      to={titleElement.link}
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      {titleElement.title}
                    </Link>
                  </span>
                ))
              : null}
          </Typography>
          {renderBatchScanner() ? (
            <Tooltip title="Batch Scanning">
              <IconButton
                id="sideNavHamburger"
                onClick={barcodeAction}
                classes={{ root: classes.button }}
                aria-label="Menu"
                style={{ position: 'absolute', right: '2%' }}
              >
                <DocumentScannerIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Toolbar>
        {!/Chrome/.test(navigator.userAgent) ? (
          <div className={classes.browserAlert}>
            Chrome is the supported browser for Pegasus. Switch to{' '}
            <a href="https://www.google.com/chrome">Chrome</a> for a better user
            experience.
          </div>
        ) : null}
      </AppBar>
    </div>
  )
}

Header.displayName = 'Header'

Header.propTypes = {
  classes: object,
  menuAction: func,
}

Header.defaultProps = {
  classes: {},
}

const styles = (theme) => ({
  header: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  appBar: {
    ...appBarStyles,
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    height: 50,
    margin: '0 18px 0 6px',
    width: 50,
    fill: 'white',
  },
  button: {
    color: theme.palette.primary.contrastText,
  },
  browserAlert: {
    textAlign: 'center',
    color: '#CC0000',
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: '22px',
  },
})

const mapStateToProps = (state) => ({
  headerTitleArray: state.layout.headerTitleArray,
})

export default withHOCs({
  auth: false,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
  },
})(Header)
