import AppBar from '@material-ui/core/AppBar'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@material-ui/core/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
export default function EditFieldHeader(props) {
  const {
    setIconClick = () => {},
    headerData = {},
    classes = {},
    id = '',
  } = props
  const { headerText = '' } = headerData

  return (
    <Grid>
      <AppBar className={classes.appBar}>
        <Grid container direction="column">
          <Grid item xs={12} md={16} style={{ marginTop: '3%' }}>
            <Typography variant="h7" className={classes.popoverText}>
              {headerText} - {id}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={16}
            style={{ marginLeft: 'auto', marginRight: '1%' }}
          >
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              onClick={() => setIconClick(false)}
            >
              <CloseIcon sx={{ color: 'grey' }} />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
      <br></br>
    </Grid>
  )
}
