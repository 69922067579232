import * as types from './actionType'
import * as userApi from '../../services/userService'
import * as UserMapper from '../../mappers/userMapper'

export function saveUser() {
  return (dispatch) => {
    dispatch(saveUserRequestPending(true))
    userApi
      .getDetails()
      .then((saveResult = {}) => {
        dispatch(
          saveUserRequestSuccess(
            UserMapper.saveUserToUserInformation(saveResult?.data)
          )
        )
      })
      .catch(() => {
        dispatch(saveUserRequestFailure())
      })
      .finally(() => {
        dispatch(saveUserRequestPending(false))
      })
  }
}

export function getUserDepts(lan_id = '') {
  return (dispatch) => {
    dispatch(updateUserDeptRequestPending(true))
    userApi
      .getUserDepts(lan_id)
      .then((result) => {
        const {
          data: { departments = '' },
        } = result
        let updateUserDepts = UserMapper.formatUserDeptRes(departments)
        dispatch(updateUserDeptRequestSuccess(updateUserDepts))
      })
      .catch((error) => {
        dispatch(updateUserDeptRequestFailure())
      })
      .finally(() => {
        dispatch(updateUserDeptRequestPending(false))
      })
  }
}

export function saveUserRequestPending(pending = false) {
  return {
    type: types.USER_INFORMATION_SAVE_USER_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function saveUserRequestFailure() {
  return {
    type: types.USER_INFORMATION_SAVE_USER_REQUEST_FAILURE,
  }
}

export function saveUserRequestSuccess(saveResult) {
  return {
    type: types.USER_INFORMATION_SAVE_USER_REQUEST_SUCCESS,
    payload: { user: saveResult },
  }
}

export function updateUser(user) {
  return (dispatch) => {
    dispatch(updateUserRequestPending(true))
    userApi
      .updateUser(UserMapper.userInformationToUpdateUser(user))
      .then((result) => {
        let updateUser = UserMapper.updateUserToUserInformation(result.data)
        dispatch(updateUserRequestSuccess(updateUser))
      })
      .catch(() => {
        dispatch(updateUserRequestFailure())
      })
      .finally(() => {
        dispatch(updateUserRequestPending(false))
      })
  }
}

export function updateUserRequestPending(pending = false) {
  return {
    type: types.USER_INFORMATION_UPDATE_USER_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function updateUserRequestFailure() {
  return {
    type: types.USER_INFORMATION_UPDATE_USER_REQUEST_FAILURE,
  }
}

export function updateUserRequestSuccess(user) {
  return {
    type: types.USER_INFORMATION_UPDATE_USER_REQUEST_SUCCESS,
    payload: { user: user },
  }
}

export function updateUserDept(deptList = []) {
  return (dispatch) => {
    dispatch(updateUserDeptRequestPending(true))
    userApi
      .updateUserDept(UserMapper.formatUserDeptList(deptList))
      .then((result = {}) => {
        const {
          data: { departments = [] },
        } = result
        let updateUser = UserMapper.formatUserDeptRes(departments)
        dispatch(updateUserDeptRequestSuccess(updateUser))
      })
      .catch(() => {
        dispatch(updateUserDeptRequestFailure())
      })
      .finally(() => {
        dispatch(updateUserDeptRequestPending(false))
      })
  }
}

export function updateUserDeptRequestPending(pending = false) {
  return {
    type: types.UPDATE_USER_DEPT_PENDING,
    payload: { pending: pending },
  }
}

export function updateUserDeptRequestFailure() {
  return {
    type: types.UPDATE_USER_DEPT_FAILURE,
  }
}

export function updateUserDeptRequestSuccess(departments = []) {
  return {
    type: types.UPDATE_USER_DEPT_SUCCESS,
    payload: { departments },
  }
}

export function updateDefaultLocation(locationUpdate) {
  return (dispatch) => {
    dispatch(updateDefaultLocationRequestPending(true))
    return userApi
      .updateUser(UserMapper.userInformationToUpdateUser(locationUpdate))
      .then((result) => {
        dispatch(updateDefaultLocationRequestSuccess(locationUpdate))
      })
      .catch(() => {
        dispatch(updateDefaultLocationRequestFailure())
      })
      .finally(() => {
        dispatch(updateDefaultLocationRequestPending(false))
      })
  }
}

export function updateDefaultLocationRequestPending(pending = false) {
  return {
    type: types.USER_DEFAULT_LOCATION_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function updateDefaultLocationRequestFailure() {
  return {
    type: types.USER_DEFAULT_LOCATION_REQUEST_FAILURE,
  }
}

export function updateDefaultLocationRequestSuccess(user) {
  return {
    type: types.USER_DEFAULT_LOCATION_REQUEST_SUCCESS,
    payload: { user: user },
  }
}

export function setBarcodeType(barcodeType) {
  return {
    type: types.USER_INFORMATION_SET_BARCODE_TYPE,
    payload: { barcodeType: barcodeType },
  }
}

export function clearUserData() {
  return {
    type: types.USER_INFORMATION_CLEAR_USER_DATA,
  }
}
