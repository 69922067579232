import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import apiConfig from '../apiConfig'
import withHOCs from 'util/withHocs'
import { isEmpty } from 'lodash'
import { Grid, Button, TextField } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import { sampleFieldLabelMapper, DATE_FORMAT } from './CommonUtils'
import { updateSampleFields } from '../store/searchInventory/actionCreator'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    minWidth: '1200px',
    width: 'auto',
  },
  buttonClass: {
    margin: '8px',
  },
  coloredButton: {
    margin: '8px 0px',
    color: '#B85300',
    padding: '4px 0px',
  },
  filledColorButton: {
    margin: '8px',
    color: '#FFFFFF',
    backgroundColor: '#B85300',
    '&:hover': {
      backgroundColor: '#B85300',
      color: '#FFFFFF',
      boxShadow: 'none',
    },
  },
  helperText: {
    textAlign: 'right',
  },
  optionViews: {},
})
const renderButton = (
  buttonLabel = '',
  callBack = () => {},
  buttonClassname = {},
  buttonIcon = '',
  tableCellKey = '',
  variant = 'text',
  buttonId = '',
  dataActionId = ''
) => {
  return (
    <Button
      color="primary"
      size="small"
      id={buttonId + '-' + tableCellKey}
      onClick={callBack}
      className={buttonClassname}
      startIcon={buttonIcon}
      variant={variant}
      data-actionid={dataActionId}
    >
      {buttonLabel}
    </Button>
  )
}
const sampleLabelMapperObj = sampleFieldLabelMapper()
const editTypeLabel = {
  textField: 'Note',
  datePicker: 'Date',
}
export const EditableFields = ({
  tableCellLabel = '',
  tableCellKey = '',
  isEditable = false,
  editType = 'textField',
  sampleId = '',
  classes = {},
  auth = {},
  updateSamplePageCallback = () => {},
  shootTypesData = [],
  editOptions = [],
  autoEdit = true,
  pageName = '',
}) => {
  const [isInEditMode, setInEditMode] = useState(autoEdit)
  const [isInDeleteMode, setInDeleteMode] = useState(false)
  const [renderContorlItem, setRenderContorlItem] = useState('default')
  const [saveClicked, setSaveClicked] = useState(false)
  const [cancelClicked, setCancelClicked] = useState(false)
  const [editedValue, setEditedValue] = useState('')
  const [initEditedValue, setInitEditedValue] = useState('')
  const [currentClicked, setCurrentClicked] = useState('')
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const { id, shipmentId } = shootTypesData
  const isEditableFinal =
    (pageName !== 'checkin' &&
      [
        '/searchInventory/samples',
        '/searchInventory/SAMPLES',
        '/searchInventory/props',
        '/searchInventory/PROPS',
        '/searchInventory',
        '/checkIn',
        '/searchInventory/addProps',
        '/moveSamples',
        `/projects/${id}/view`,
        `/projects/${id}/edit`,
        `/shipSamples/${shipmentId}/view`,
        `/shipSamples/${shipmentId}/edit`,
      ].indexOf(window.location.pathname) === -1) ||
    auth.isAuthorized(apiConfig.roles.helpvendor)
      ? false
      : isEditable
  useEffect(() => {
    if (tableCellLabel) {
      setEditedValue(tableCellLabel)
      setInitEditedValue(tableCellLabel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onControlsClick = (event, params = {}) => {
    const {
      currentTarget: {
        dataset: { actionid: currentActionId = '' },
      },
    } = event
    if (/^save-/.test(currentActionId)) {
      setSaveClicked(true)
    }
    if (/^cancel-/.test(currentActionId)) {
      setCancelClicked(true)
    }
    setCurrentClicked(currentActionId)
  }
  const onEditClick = () => {
    if (editType === 'datePicker' && isEmpty(editedValue)) {
      setEditedValue(moment().local().format('MM/DD/YYYY'))
    }
    setInEditMode(!isInEditMode)
    setInDeleteMode(false)
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        const inputRefVal = inputRef.current.value
        inputRef.current.value = '' //clear the value of the element
        inputRef.current.value = inputRefVal
        inputRef.current.focus()
      }
    }, 0)
  }
  const onDeleteClick = () => {
    setInDeleteMode(!isInDeleteMode)
  }
  const onValueChange = (event) => {
    setEditedValue(event.target.value || '')
  }

  const onDateChange = (date) => {
    setEditedValue(date === null ? null : date.format('MM/DD/YYYY'))
  }

  const actionControlsList = {
    add: {
      left: [
        {
          label: 'Add',
          action: onEditClick,
          classname: classes.buttonClass,
          icon: <AddIcon />,
          key: tableCellKey,
          variant: 'text',
          id: 'click_to_add',
        },
      ],
      right: [],
    },
    delete: {
      left: [
        {
          label: 'click to delete ' + editTypeLabel[editType],
          action: (event) => {
            onControlsClick(event)
          },
          dataActionId: 'save-delete',
          classname: classes.filledColorButton,
          icon: <DeleteIcon />,
          key: tableCellKey,
          variant: 'text',
          id: 'click_to_delete',
        },
        {
          label: 'keep ' + editTypeLabel[editType],
          action: (event) => {
            onControlsClick(event)
          },
          dataActionId: 'cancel-delete',
          classname: classes.buttonClass,
          icon: '',
          key: tableCellKey,
          variant: 'text',
          id: 'keep_note',
        },
      ],
      right: [],
    },
    edit: {
      left: [
        {
          label: 'delete',
          action: onDeleteClick,
          classname: classes.coloredButton,
          icon: <DeleteIcon />,
          key: tableCellKey,
          variant: 'text',
          id: 'delete_note',
        },
      ],
      right: [
        {
          label: 'cancel',
          action: (event) => {
            onControlsClick(event)
          },
          dataActionId: 'cancel-edit',
          classname: classes.buttonClass,
          icon: '',
          key: tableCellKey,
          variant: 'text',
          id: 'cancel_note',
        },
        {
          label: 'save',
          action: (event) => {
            onControlsClick(event)
          },
          dataActionId: 'save-edit',
          classname: classes.buttonClass,
          icon: <SaveIcon />,
          key: tableCellKey,
          variant: 'outlined',
          id: 'save_note',
        },
      ],
    },
    default: {
      left: [
        {
          label: 'delete',
          action: onDeleteClick,
          classname: classes.coloredButton,
          icon: <DeleteIcon />,
          key: tableCellKey,
          variant: 'text',
          id: 'delete_note',
        },
      ],
      right: [
        {
          label: 'edit',
          action: onEditClick,
          classname: classes.buttonClass,
          icon: <EditIcon />,
          key: tableCellKey,
          variant: 'text',
          id: 'edit_note',
        },
      ],
    },
  }

  useEffect(() => {
    let renderContorlItem = ''
    if (isInDeleteMode) {
      renderContorlItem = 'delete'
    } else if (isInEditMode) {
      renderContorlItem = 'edit'
    } else {
      renderContorlItem = isEmpty(editedValue) ? 'add' : 'default'
    }
    setRenderContorlItem(renderContorlItem)
  }, [isInDeleteMode, isInEditMode, editedValue])

  useEffect(() => {
    const updateFieldId = sampleLabelMapperObj[tableCellKey || '']
    const currentAction = (currentClicked || '').replace(/^save-/, '')
    const updateFieldValue =
      currentAction === 'delete'
        ? ''
        : formatEditValue(editType, editedValue, 'YYYY-MM-DD')

    if (saveClicked && !isEmpty(updateFieldId)) {
      dispatch(
        updateSampleFields(
          {
            field_name: updateFieldId,
            field_value: updateFieldValue,
            sample_id: sampleId,
          },
          updateSamplePageCallback
        )
      )
    }
    return function cleanup() {
      setSaveClicked(false)
      setInEditMode(false)
      setInDeleteMode(false)
      if (saveClicked && !isEmpty(updateFieldId)) {
        setInitEditedValue(updateFieldValue)
        setEditedValue(updateFieldValue)
      }
      setCurrentClicked('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveClicked, dispatch])

  useEffect(() => {
    if (cancelClicked) {
      const currentAction = (currentClicked || '').replace(/^cancel-/, '')
      switch (currentAction) {
        case 'edit':
          setEditedValue(initEditedValue)
          setInEditMode(false)
          break
        case 'delete':
          setInDeleteMode(!isInDeleteMode)
          break
        default:
          break
      }
    }
    return function cleanup() {
      setCancelClicked(false)
      setCurrentClicked('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelClicked])

  const renderControlItems = (renderItem) => {
    return (
      renderItem.length > 0 &&
      renderItem.map((currentItem, index) => {
        const {
          label: ci_label = '',
          action: ci_action = () => {},
          classname: ci_classname = '',
          icon: ci_icon = '',
          key: ci_key = '',
          variant: ci_variant = '',
          id: ci_id = '',
          dataActionId: ci_dataActionId = '',
        } = currentItem
        return (
          <Grid item key={`${ci_id + '_' + index}`}>
            {renderButton(
              ci_label,
              ci_action,
              ci_classname,
              ci_icon,
              ci_key,
              ci_variant,
              ci_id,
              ci_dataActionId
            )}
          </Grid>
        )
      })
    )
  }
  const renderControls = () => {
    if (!isEmpty(renderContorlItem) && actionControlsList[renderContorlItem]) {
      const {
        left: renderContorlItemLeft = [],
        right: renderContorlItemRight = [],
      } = actionControlsList[renderContorlItem] || actionControlsList['default']
      return (
        <Grid container justify={'space-between'}>
          {renderContorlItemLeft.length > 0 && (
            <Grid item>
              <Grid container>{renderControlItems(renderContorlItemLeft)}</Grid>
            </Grid>
          )}
          {renderContorlItemRight.length > 0 && (
            <Grid item>
              <Grid container>
                {renderControlItems(renderContorlItemRight)}
              </Grid>
            </Grid>
          )}
        </Grid>
      )
    }
  }

  const dateFormatter = (str) => {
    return str
  }

  const editComponent = () => {
    switch (editType) {
      case 'datePicker': {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                variant="inline"
                id="dispositionOverrideDate"
                autoOk
                clearable={'true'}
                disablePast
                onChange={onDateChange}
                value={moment(editedValue).local().format('MM/DD/YYYY')}
                format={DATE_FORMAT}
                rifmFormatter={dateFormatter}
                style={{ width: '170px' }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )
      }
      case 'dropDown': {
        return (
          <Autocomplete
            // fullWidth={false}
            id="Category-tags-demo"
            options={editOptions}
            getOptionLabel={(option) => option?.label}
            onChange={(event, newValue, reason) => {
              setEditedValue(newValue)
              // autoEdit && setSaveClicked(true)
            }}
            getOptionSelected={(option, value) => {
              return option.id === value.id
            }}
            value={editedValue}
            renderOption={(option, { selected, inputValue }) => {
              return <>{option?.label}</>
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={false}
                label="Category(s)"
                placeholder="Category(s)"
              />
            )}
          />
        )
      }
      default:
        return (
          <TextField
            className={classes.optionViews}
            variant="outlined"
            onChange={onValueChange}
            multiline
            style={{ minWidth: '361px' }}
            value={editedValue}
            inputProps={{
              maxLength: 250,
              autoFocus: true,
            }}
            inputRef={inputRef}
            helperText={`${250 - (editedValue || '').length + ' / 250'}`}
            FormHelperTextProps={{
              classes: { root: classes.helperText },
            }}
          />
        )
    }
  }

  const formatEditValue = (editType, editedValue, format = DATE_FORMAT) => {
    switch (editType) {
      case 'textField':
        return editedValue
      case 'datePicker':
        return !isEmpty(editedValue)
          ? moment(editedValue).local().format(format)
          : ''
      case 'dropDown':
        return editedValue?.label || ''
      default:
        return editedValue
    }
  }

  return (
    <>
      {!isEditableFinal ? (
        formatEditValue(editType, editedValue)
      ) : (
        <Grid container>
          <Grid item xs={12}>
            {isInEditMode
              ? editComponent()
              : formatEditValue(editType, editedValue)}
          </Grid>
          {!autoEdit && (
            <Grid item xs={12}>
              {renderControls()}
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
})(EditableFields)
