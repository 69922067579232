import { convertTableDateFormat, CSV_DATE_FORMAT } from '../../util/CommonUtils'
import { SAMPLE, PROPS, SCENIC } from '../../enums/Tabs'
import { first } from 'lodash'

export const createSingleSampleCSV = (item) => {
  return `${
    '"' +
    item.description +
    '",' +
    item.tcin +
    ',' +
    item.pegasusId +
    ',' +
    item.dpci +
    ',' +
    item.subClassId +
    ',' +
    item.subClassName +
    ',' +
    item.sampleStatus +
    ',"' +
    item.location +
    '","' +
    item.projectName +
    '",' +
    convertTableDateFormat(item.shootDate, CSV_DATE_FORMAT) +
    ',' +
    convertTableDateFormat(item.launchDate, CSV_DATE_FORMAT)
  }`
}

export const createSinglePropsCSV = (item) => {
  return `${
    ',"' +
    item.propDescription +
    '","' +
    item.propCategoryName +
    '","' +
    item.propSubcategoryName +
    '",' +
    item.propId +
    ',' +
    item.propBrand?.displayName +
    ',"' +
    item.propColorFamilyName +
    '",' +
    convertTableDateFormat(item.propCreatedDate, CSV_DATE_FORMAT) +
    ',' +
    item.shipmentCount
  }`
}
export const createSingleScenicCSV = (item) => {
  return `${
    item.scenicDescription +
    '","' +
    item.scenicCategoryName +
    '","' +
    item.scenicSubcategoryName +
    '",' +
    item.pegasusId +
    ',' +
    convertTableDateFormat(item.scenicCreatedDate, CSV_DATE_FORMAT) +
    ',' +
    item.scenicChannel?.displayName
  }`
}

export const createCsv = (data, csvData = []) => {
  data.forEach((item) => {
    let csvString =
      item.itemType !== SCENIC && createSingleSampleCSV(item, csvData)
    if (item.itemType === PROPS) {
      const propsCSVData = createSinglePropsCSV(item, csvString)
      csvString = `${csvString}${propsCSVData}`
    } else if (item.itemType === SCENIC) {
      csvString = createSingleScenicCSV(item, csvData)
    } // remove all null and undefined from CSV string
    csvString = csvString.replace(/undefined/g, '')
    csvString = csvString.replace(/null/g, '')
    csvData.push(csvString)
  })
  return csvData
}

export const createCsvUtil = (data) => {
  if (!data || data.length === 0) {
    return ''
  }
  const dataTypes = [...new Set(data.map((item) => item.itemType))]

  let csvData = []
  const returnStringForSamples = [
    'Description,TCIN,Pegasus ID,DPCI,Sub Class ID, Sub Class,Status,Location,' +
      'Project/Collection Name,Shoot Date,Launch Date,',
  ]
  const returnStringForProps = [
    'Prop Description,Category,Sub Category,Prop ID,Prop Brand,' +
      'Color family,Created date,Shipment Count',
  ]
  const returnStringForScenic = [
    'Description,Category,Sub Category, Pegasus ID,Created date, Channel',
  ]
  const isOnlySampleTypeDataSelected =
    dataTypes.length === 1 && first(dataTypes) === SAMPLE
  const isOnlyScenicTypeSelected =
    dataTypes.length === 1 && first(dataTypes) === SCENIC

  // If both Sample and Prop is selected or only Prop type is selected then show all the columns from both
  csvData = isOnlySampleTypeDataSelected
    ? returnStringForSamples
    : isOnlyScenicTypeSelected
      ? returnStringForScenic
      : [`${returnStringForSamples[0]}${returnStringForProps[0]}`]
  csvData = createCsv(data, csvData)

  return csvData.join('\n')
}
