//

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { ELLIPSIS } from '../constants'

/**
 * `CaptureContent` calculates how text in tabs should be displayed
 * to best match Material UI's design spec.
 *
 * Given a string of text, determine if the string:
 *
 * - is short enough to fit on a single line
 * - is short enough to fit on two lines completely
 * - is too long to fit on two lines and needs to be truncated
 *
 * Then broadcast these results to the parent as a string or
 * array of strings.
 *
 * https://material.io/guidelines/components/tabs.html#tabs-types-of-tabs
 */
export class CaptureContent extends React.Component {
  static defaultProps = {
    classes: {},
  }

  widthChecker // see flow docs https://flow.org/en/docs/react/refs/

  componentDidMount() {
    this.calculateWidthState(this.props.children)
  }

  render() {
    const { children, classes } = this.props

    return (
      <span
        ref={(el) => {
          this.widthChecker = el
        }}
        className={classes.widthChecker}
      >
        {children}
      </span>
    )
  }

  /**
   * Calculates how text should be displayed
   *
   * [1] if the content is smaller than the max width, set the
   *     display content to be the raw content
   *
   * [2] Calculate line breaks and if an ellipsis is needed
   *
   * [3] Find the longest resulting line and capture it's width
   */
  calculateWidthState = (children) => {
    const { maxWidth, onWidthSet } = this.props
    const width = this.getWidth()

    if (width < maxWidth) {
      // [1]
      onWidthSet({
        // todo: rename children
        children: [children],
        isRambling: false,
        width,
      })
    } else {
      const content = splitContent(children, (snippet, isLastLine) => {
        // [3]
        if (this.widthChecker) {
          this.widthChecker.innerText = snippet

          if (isLastLine) {
            this.widthChecker.innerText += ELLIPSIS
          }
        }

        return this.getWidth() > maxWidth
      })

      const finalWidth = Math.max.apply(
        Math,
        content.map((line) => {
          // [3]
          if (this.widthChecker) {
            this.widthChecker.innerText = line
          }
          return this.getWidth()
        })
      )

      onWidthSet({
        children: content,
        isRambling: true,
        width: finalWidth,
      })
    }
  }

  getWidth = () => {
    if (this.widthChecker && this.widthChecker.getBoundingClientRect) {
      return this.widthChecker.getBoundingClientRect().width
    }

    return 0 // TODO: What is a good default?
  }
}

/**
 * Constants
 */

const MAX_LINE_COUNT = 2
const DEFAULT_SPLIT_STRING = ' '

/**
 * Splits a string in to a pair of [pass, fail] chunks
 * @param {String} str - input content
 * @param {Function} fn - tests if content should be broken, called
 *                        with params [content, key]
 * @param {String} splitter - character to `String.split()` with
 * @return {Array} - [pass, fail] values
 */

export function partitionString(str, fn, splitter = DEFAULT_SPLIT_STRING) {
  const chunks = str.split(splitter)
  let passCount = 0

  for (let key = 1; key <= chunks.length; key++) {
    const tester = chunks.slice(0, key).join(splitter)
    if (fn(tester)) {
      break
    }
    passCount = key
  }

  return [
    chunks.slice(0, passCount).join(splitter),
    chunks.slice(passCount, chunks.length).join(splitter),
  ]
}

/**
 * Given an input string, calculate where lines should break
 * and if it needs to be truncated
 *
 * [1] ensure that really long words don't break the layout by
 *     splitting them across two lines
 *
 * [2] save results and continue looping
 *
 * [3] stop searching if maximum content is met
 *
 * [4] append ellipsis if there is more lines than MAX_LINE_COUNT
 *
 * @param  {String} str - input content
 * @param  {Function} fn - tests if content should be broken,
 *                         called with params [content, key]
 * @return {Array} - results containing a list of strings divided where
 *                   `partitionString` decided nessecary
 */

export function splitContent(str, fn) {
  const lineNumberFn = (snippet) => fn(snippet, lineNumber)

  let res = []
  let content = str
  let lineNumber = 0

  while (content.length) {
    let [output, remaining] = partitionString(
      content,
      lineNumberFn,
      DEFAULT_SPLIT_STRING
    )

    if (output.length === 0 && remaining.length > 0) {
      // [1]
      ;[output, remaining] = partitionString(content, lineNumberFn, '')
    }

    // [2]
    res.push(output)
    content = remaining
    lineNumber = lineNumber + 1

    if (MAX_LINE_COUNT === res.length) {
      // [3]
      break
    }
  }

  if (content.length) {
    // [4]
    const lastLine = res.pop()
    res.push(lastLine + ELLIPSIS)
  }

  return res
}

const stylesheet = {
  widthChecker: {
    whiteSpace: 'nowrap',
  },
}

export default withStyles(stylesheet)(CaptureContent)
