import history from 'components/Router/history'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

function withNav(Component) {
  return function WrappedComponent(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const match = {
      params: params,
    }

    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
        history={history}
        match={match}
      />
    )
  }
}

export default withNav
