export const GET_AUTO_ORDER_FILTERS_PENDING = 'GET_AUTO_ORDER_FILTERS_PENDING'
export const GET_AUTO_ORDER_BRANDS_SUCCESS = 'GET_AUTO_ORDER_BRANDS_SUCCESS'
export const GET_AUTO_ORDER_BRANDS_PENDING = 'GET_AUTO_ORDER_BRANDS_PENDING'
export const GET_AUTO_ORDER_DEPARTMENTS_SUCCESS =
  'GET_AUTO_ORDER_DEPARTMENTS_SUCCESS'
export const GET_AUTO_ORDER_DEPARTMENTS_PENDING =
  'GET_AUTO_ORDER_DEPARTMENTS_PENDING'
export const GET_AUTO_ORDER_CLASS_SUCCESS = 'GET_AUTO_ORDER_CLASS_SUCCESS'
export const GET_AUTO_ORDER_CLASS_PENDING = 'GET_AUTO_ORDER_CLASS_PENDING'
export const SET_AUTO_ORDER_SELECTED_FILTERS = 'SET_AUTO_ORDER_SELECTED_FILTERS'
export const EDIT_FLYOUT = 'EDIT_FLYOUT'
export const EDIT_SUBMIT_SUCCESS = 'EDIT_SUBMIT_SUCCESS'
