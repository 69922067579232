import React from 'react'
import * as propTypes from 'prop-types'
import { isEmpty, first, isEqual } from 'lodash'
import apiConfig from '../../apiConfig'
import copy from 'copy-to-clipboard'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import withHOCs from 'util/withHocs'
import GetApp from '@material-ui/icons/GetApp'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import {
  nonIDSOrderPageTabLabels,
  orderPageTabLabels,
  defaultOrderPageTab,
  nonIDSDefOrderPageTab,
} from '../../enums/Tabs'
import CustomTabs from '../../components/Tabs/Tabs'
import DialogBox from '../../components/DialogBox/DialogBox'
import BottomBar from 'components/BottomBar'
import SearchPage from '../SearchPage/SearchPage'
import AutoOrderSamples from '../SearchPage/AutoOrderSamples'
import { selectPageFilters } from '../../store/filters/selectors'
import { getCurrentTabSelectedData } from '../../store/order/selectors'
import { setFilters } from '../../store/filters/actionCreator'
import { convertTableDateFormat, CSV_DATE_FORMAT } from '../../util/CommonUtils'
import {
  getVendorEmailsById,
  setSampleData,
  setTabvalue,
  setOrderSamplesFrom,
} from '../../store/order/actionCreator'
import {
  unsetSelectedData,
  removeAutoOrderSamples,
  refreshAutoOrderSamples,
} from '../../store/autoOrderSamples/actionCreator'
import { unsetSearchSelectedData } from '../../store/search/actionCreator'

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  pageHeader: {
    width: '100%',
    height: '40px',
    position: 'relative',
  },
  pageHeaderButton: {
    display: 'inline',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  add: {
    marginRight: '10px',
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'grid',
    overflowX: 'auto',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
  orderButton: {
    textDecoration: 'auto',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
  },
  SwipeableDrawerElements: {
    fontSize: 'normal',
    textAlign: 'left',
    overflow: 'hidden',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    alignItems: 'center',
  },
})

class OrderHome extends React.Component {
  constructor(props) {
    super(props)
    const { filters: propsFilter = {}, tabValue = '' } = this.props
    this.state = {
      tooltipIsOpen: false,
      currentTabValue: tabValue,
      filters: propsFilter,
      showAlertOnDelete: false,
      alertMessageOnDelete: '',
      deleteResponse: false,
    }
  }

  componentDidMount() {
    const {
      setHeaderTitleArray,
      setFilters = () => {},
      setTabvalue = () => {},
      setOrderSamplesFrom = () => {},
      auth = {},
    } = this.props
    const { isAuthorized = () => {} } = auth
    setHeaderTitleArray([{ title: 'Order Samples' }])
    setFilters({}, 'autoordersamples')
    const curTabVal = isAuthorized(apiConfig.roles.autoOrder)
      ? defaultOrderPageTab
      : nonIDSDefOrderPageTab
    setTabvalue(curTabVal)
    setOrderSamplesFrom('')
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { currentTabValue = '' } = this.state
    const { currentTabValue: prevCurrentTabValue = '' } = this.state
    const {
      setOrderSamplesFrom = () => {},
      triggerOrderItems,
      selectedData = [],
      triggerOrderItemsClick = () => {},
    } = this.props

    if (
      currentTabValue &&
      currentTabValue === 'READYTOORDER' &&
      !isEqual(currentTabValue, prevCurrentTabValue)
    ) {
      setOrderSamplesFrom(currentTabValue)
    }
    if (triggerOrderItems) {
      this.orderItems(selectedData)
      triggerOrderItemsClick(false)
    }
  }

  saveFilters() {
    const { setFilters, setTabvalue = () => {} } = this.props
    const { filters = {}, currentTabValue = '' } = this.state
    const filterCopy = Object.assign({}, filters)
    if (!isEmpty(filterCopy)) {
      setFilters(filterCopy, 'autoordersamples')
    }
    setTabvalue(currentTabValue)
  }

  handleTooltipOpen = () => {
    this.setState({ tooltipIsOpen: !this.state.tooltipIsOpen })
    this.autoCloseTimer = setTimeout(this.handleTooltipClose.bind(this), 10000)
  }

  handleTooltipClose = () => {
    this.setState({ tooltipIsOpen: false })
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer)
    }
  }

  setCurrentTabValue = (currentTab = '') => {
    const { filters: stateFilters = {} } = this.state
    const { filters: propsFilters = {}, setTabvalue = () => {} } = this.props
    if (!propsFilters['created_by']) {
      delete stateFilters['created_by']
    }
    this.setState(() => {
      setTabvalue(currentTab)
      return {
        currentTabValue: currentTab,
        filters: Object.assign({}, stateFilters, {
          status: currentTab,
        }),
      }
    })
  }

  filterClick = () => {
    const {
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
    } = this.props

    const { filters: stateFilters = {} } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    const currentFilterExists =
      Object.keys(filtersCopy).indexOf('created_by') !== -1
    if (currentFilterExists) {
      delete filtersCopy['created_by']
      this.setFilters(Object.assign({}, filtersCopy))
    } else {
      this.setFilters(Object.assign({}, stateFilters, { created_by: lanId }))
    }
  }

  setFilters = (filters = {}) => {
    this.setState({
      filters: filters,
    })
  }

  orderItems(data = []) {
    //setting default value to render order page first page
    const { getVendorEmailsById, setSampleData } = this.props
    const { lanId } = this.props.auth.session.userInfo
    const newSelected = data?.map((i = {}) => {
      const { projects = [] } = i
      const firstKeyProject = first(projects)
      return {
        ...i,
        vendorEmail: '',
        emailVmm: null,
        vendorBPIds: '',
        bpidsVmm: null,
        quantity: 1,
        studioNotes: '',
        vendorNotes: '',
        userId: lanId,
        project: firstKeyProject || '',
      }
    })

    setSampleData(newSelected)
    getVendorEmailsById(data.map((data) => data.vendorId))
  }

  renderTabsContent = () => {
    const { currentTabValue = '' } = this.state

    switch (currentTabValue) {
      case 'READYTOORDER': {
        return <AutoOrderSamples />
      }
      case 'SEARCH':
        return <SearchPage />
      default:
        break
    }
  }

  disableButton = () => {
    return this.props.selectedData.length === 0
  }

  createCsv(data) {
    let returnString = [
      'TCIN,DPCI,Description,Size,Department,Class,Brand,Launch Date,Vendor ID,Vendor Name,',
    ]

    for (let itemIndex in data) {
      let item = data[itemIndex]
      returnString.push(
        `${
          item.tcin +
          ',' +
          item.dpci +
          ',"' +
          item.description +
          '",' +
          item.size +
          ',"' +
          item.departmentName +
          '","' +
          item.className +
          '","' +
          item.brand +
          '",' +
          convertTableDateFormat(item.launchDate, CSV_DATE_FORMAT) +
          ',' +
          item.vendorId +
          ',"' +
          item.vendorName +
          '"'
        }`
      )
    }
    return returnString.join('\n')
  }

  onCopytoClipboard = () => {
    const { selectedData = [] } = this.props
    copy(selectedData.map((item) => item.tcin).join('\r\n'), {
      format: 'text/plain',
    })
  }

  onResetSelection = () => {
    const { currentTabValue = '' } = this.state
    const { unsetSelectedData = () => {}, unsetSearchSelectedData = () => {} } =
      this.props

    switch (currentTabValue) {
      case 'READYTOORDER':
        unsetSelectedData()
        break
      default:
        unsetSearchSelectedData()
        break
    }
  }

  getActionTypeByTab = () => {
    const { currentTabValue = '' } = this.state
    let currentTabActionType = 'REST_ALL_MANUALORDER'
    if (currentTabValue === 'READYTOORDER') {
      currentTabActionType = 'REST_ALL_AUTOORDER'
    }
    return currentTabActionType
  }

  onRemoveSamplesClick = () => {
    const { selectedData = [] } = this.props
    const selectedDataCount = selectedData.length || Number(0)
    const message =
      'You have selected ' + selectedDataCount + ' items to delete?'

    this.setState({
      showAlertOnDelete: true,
      alertMessageOnDelete: message,
      deleteResponse: true,
    })
  }

  onRemoveSamplesOk = () => {
    const { selectedData = [] } = this.props
    const { removeAutoOrderSamples = () => {} } = this.props
    //unique ids
    const selectedDataSamples = selectedData
      .map((obj = {}) => {
        const { pegasusId = '' } = obj
        return (pegasusId || '').substring(2, pegasusId.length)
      })
      .filter((pegasusId, index, arr) => arr.indexOf(pegasusId) === index)

    removeAutoOrderSamples(selectedDataSamples, this.onRemoveSamplesSuccess)
  }

  onRemoveSamplesSuccess = () => {
    const {
      unsetSelectedData = () => {},
      setTabvalue = () => {},
      refreshAutoOrderSamples = () => {},
    } = this.props
    const { currentTabValue = '' } = this.state
    this.setState(
      {
        showAlertOnDelete: false,
        alertMessageOnDelete: '',
        deleteResponse: false,
      },
      () => {
        unsetSelectedData()
        setTabvalue(currentTabValue)
        refreshAutoOrderSamples()
      }
    )
  }

  closeAlert = (e) => {
    this.setState({
      showAlertOnDelete: false,
      alertMessageOnDelete: '',
      deleteResponse: false,
    })
  }

  renderBottomBar = () => {
    const { classes = {}, selectedData = [] } = this.props
    const numSelected = selectedData?.length || Number(0)
    const { currentTabValue = '' } = this.state
    const csvData = new Blob([this.createCsv(selectedData)], {
      type: 'text/csv',
    })
    const csvUrl = URL.createObjectURL(csvData)
    const csvName = 'AutoOrderList.csv'

    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={numSelected > 0}
        onOpen={() => null}
        onClose={() => null}
        showClearAll={numSelected > 0}
        actionType={this.getActionTypeByTab()}
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          <Grid container className={classes.swipeableDrawer}>
            <Grid item>
              <Typography
                variant="body1"
                component="body1"
                className={classes.defaultButton}
              >
                {numSelected} Selected
              </Typography>
              <Button
                href={csvUrl}
                id="downloadCsv"
                data-id="EXPORT TO CSV"
                className={classes.defaultButton}
                startIcon={<GetApp />}
                download={csvName}
              >
                DOWNLOAD CSV
              </Button>
              {currentTabValue === 'READYTOORDER' && (
                <Button
                  id="removesamples"
                  data-id="removesamples"
                  className={classes.defaultButton}
                  onClick={this.onRemoveSamplesClick}
                >
                  Remove
                </Button>
              )}
              <Button
                id="copytoClipboardTcins"
                data-id="copytoClipboardTcins"
                className={classes.defaultButton}
                onClick={this.onCopytoClipboard}
              >
                Copy to clipboard
              </Button>
            </Grid>
          </Grid>
        </div>
      </BottomBar>
    )
  }

  render() {
    const { auth = {}, classes = {}, selectedData = [] } = this.props
    const {
      showAlertOnDelete = false,
      alertMessageOnDelete = '',
      deleteResponse = false,
      currentTabValue = '',
    } = this.state
    const csvData = new Blob([this.createCsv(selectedData)], {
      type: 'text/csv',
    })
    const { isAuthorized = () => {} } = auth
    const csvUrl = URL.createObjectURL(csvData)
    const csvName = 'AutoOrderList.csv'

    const numSelected = selectedData.length
    const curSelTab = isAuthorized(apiConfig.roles.autoOrder)
      ? defaultOrderPageTab
      : nonIDSDefOrderPageTab
    return (
      <div className={classes.root}>
        <Paper className={classes.paper} id={'orderPageTabs'}>
          <CustomTabs
            tabLabels={
              isAuthorized(apiConfig.roles.autoOrder)
                ? orderPageTabLabels
                : nonIDSOrderPageTabLabels
            }
            renderTabsContent={this.renderTabsContent}
            setCurrentTabValue={this.setCurrentTabValue}
            defaultTab={this.props.tabValue || curSelTab}
            showTabsPanel={false}
          />
        </Paper>
        {this.renderBottomBar()}
        <DialogBox
          isAlertVisible={showAlertOnDelete}
          message={alertMessageOnDelete}
          onClose={this.closeAlert}
          response={deleteResponse}
          onAgree={this.onRemoveSamplesOk}
          title="DELETE ITEMS?"
          primaryButtonText="YES, DELETE ITEMS"
          secondaryButtonText="NEVERMIND"
          fullWidth
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    filters: selectPageFilters('projects')(state),
    tabValue: state.order.tabValue,
    selectedData: getCurrentTabSelectedData()(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setFilters: (filters, pageName) =>
      dispatch(setFilters(filters, 'autoordersamples')),
    setTabvalue: (tabValue) => dispatch(setTabvalue(tabValue)),
    setOrderSamplesFrom: (pagename = '') =>
      dispatch(setOrderSamplesFrom(pagename)),
    getVendorEmailsById: (ids = []) => dispatch(getVendorEmailsById(ids)),
    setSampleData: (samples) => {
      dispatch(setSampleData(samples))
    },
    unsetSelectedData: () => dispatch(unsetSelectedData()),
    unsetSearchSelectedData: () => dispatch(unsetSearchSelectedData()),
    removeAutoOrderSamples: (samples = [], callBack = () => {}) =>
      dispatch(removeAutoOrderSamples(samples, callBack)),
    refreshAutoOrderSamples: () => {
      dispatch(refreshAutoOrderSamples(true))
    },
    resetAllGlobal: (type = '') => {
      dispatch({ type: type })
    },
  }
}

OrderHome.propTypes = {
  history: propTypes.shape({
    push: propTypes.func,
  }),
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(OrderHome)
