import { useState, useEffect } from 'react'
import clsx from 'classnames'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { HelpOutline, Save } from '@material-ui/icons'
import { ShowIf, ShowIfElse } from 'components/ShowIf'
import { NewShipmentStyles } from './NewShipmentStyles'
import { AccordionSummary, withStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { DATE_FORMAT, convertTableDateFormat } from 'util/CommonUtils'

const tooltipTitle =
  'HOLD FOR PHOTOGRAPHY:  Saves all Shipment Info and sets ' +
  'all samples added to this shipment as "Pending Shipment".\n\nMark as ' +
  'Shipped:  Saves a record of items shipped and sets all samples added ' +
  'to this shipment as "Shipped for Photography".\n\nDelete:  Removes ' +
  "this shipment and all it's shipping info. All samples go back to " +
  'their previous status.'

const ShipmentAccordionSummary = ({
  classes,
  shipmentData,
  shipmentId,
  shipFromLocSampleExists,
  handleAlertMessage,
  handleShipmentUpdate,
  isCreatePage = false,
  showReuseShipment = false,
  setIsReuseShipment,
  hasUnCommitedSampleOrProp = false,
  hasAnySampleOrPropAdded = false,
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const [autoCloseTimer, setAutoCloseTimer] = useState(null)

  useEffect(() => {
    return () => clearTimeout(autoCloseTimer)
  }, [autoCloseTimer])

  const handleTooltipOpen = () => {
    setTooltipIsOpen(!tooltipIsOpen)
    const timer = setTimeout(handleTooltipClose, 15000)
    setAutoCloseTimer(timer)
  }

  const handleTooltipClose = () => {
    setTooltipIsOpen(false)
    if (autoCloseTimer) {
      clearTimeout(autoCloseTimer)
    }
  }

  const disableSaveButton = () => {
    if (
      hasUnCommitedSampleOrProp &&
      shipmentData.shipFromLocName &&
      shipmentData.shipToLocName &&
      (shipmentData.shipFromLocName.length >= 0 ||
        shipmentData.shipToLocName.length >= 0)
    ) {
      return false
    } else {
      return true
    }
  }

  const disableShipButton = () => {
    if (
      hasAnySampleOrPropAdded &&
      shipmentData.shipFromLocName &&
      shipmentData.shipToLocName &&
      (shipmentData.shipFromLocName.length >= 0 ||
        shipmentData.shipToLocName.length >= 0 ||
        this.props.data.length > 0)
    ) {
      return false
    } else {
      return true
    }
  }
  const shipDate = convertTableDateFormat(shipmentData.shipDate, DATE_FORMAT)

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="shipment-content"
      id="shipment-header"
      style={{ minHeight: '70px' }}
    >
      <div className={classes.toolBar}>
        <span className={classes.filterTitle}>
          <Typography variant="h5">Shipment Info</Typography>{' '}
        </span>
      </div>
      <ShowIf condition={!isCreatePage}>
        <>
          <div style={{ marginLeft: '100px', marginTop: '8px' }}>
            <span>Shipment ID:</span>{' '}
            <span className={classes.shipmentLabel}> {shipmentId} </span>
          </div>
          <div style={{ marginLeft: '60px', marginTop: '8px' }}>
            <span>Shipment Created by:</span>
            <span className={classes.shipmentLabel}>
              {' '}
              {shipmentData.shipmentCreator}
            </span>
          </div>
          <div style={{ marginLeft: '60px', marginTop: '8px' }}>
            <span>Last Shipped date:</span>{' '}
            <span className={classes.shipmentLabel}>
              {shipDate || 'Pending'}
            </span>
          </div>

          <div className={classes.filler}></div>
          <ShowIfElse
            condition={showReuseShipment}
            success={
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  // disabled={this.disableSaveButton() || shipFromLocSampleExists}
                  // onClick={this.handleShipmentUpdate.bind(this)}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsReuseShipment()
                  }}
                >
                  Reuse Shipment
                </Button>
              </div>
            }
            fail={
              <div>
                <ShowIf condition={!shipDate}>
                  <Button
                    id="handleDelete"
                    data-id="DELETE"
                    // className={clsx(classes.deleteButton)}
                    style={{
                      marginRight: '24px',
                      color: '#B85300',
                    }}
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleAlertMessage(e)
                    }}
                  >
                    DELETE
                  </Button>
                </ShowIf>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={tooltipTitle}
                  onClose={(e) => {
                    e.stopPropagation()
                    handleTooltipClose(e)
                  }}
                  open={tooltipIsOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <HelpOutline
                    style={{
                      transform: 'translateY(30%)',
                      marginRight: '10px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleTooltipOpen(e)
                    }}
                  />
                </Tooltip>
                <Button
                  id="handleSave"
                  data-id="PENDING"
                  className={clsx(classes.defaultButton)}
                  variant="outlined"
                  color="primary"
                  disabled={disableSaveButton() || shipFromLocSampleExists}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleShipmentUpdate(e)
                  }}
                  startIcon={<Save />}
                >
                  HOLD FOR PHOTOGRAPHY
                </Button>
                <Button
                  data-id="SHIPPED"
                  className={classes.defaultButton}
                  style={{ marginLeft: '20px' }}
                  variant="contained"
                  color="primary"
                  disabled={disableShipButton() || shipFromLocSampleExists}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleAlertMessage(e)
                  }}
                >
                  MARK AS SHIPPED
                </Button>
              </div>
            }
          />
        </>
      </ShowIf>
    </AccordionSummary>
  )
}

export default withStyles(NewShipmentStyles)(ShipmentAccordionSummary)
