import { useState } from 'react'
import { isEqual } from 'lodash'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

const filter = createFilterOptions()

function AddNewMarketingPid({
  allowCreateNew = true,
  handleUpdate,
  pidData = [],
  updateMarketingFieldVisiblity,
}) {
  const [updatedMarketing, setUpdatedMarketing] = useState(pidData)

  const handleChange = (e, selectedOptions) => {
    setUpdatedMarketing(selectedOptions)
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <Autocomplete
          multiple
          includeInputInList
          id="shipment-marketing"
          options={pidData} // TODO use original pidList as options
          onChange={handleChange}
          getOptionLabel={(option) => {
            // Add prefix to option created dynamically
            if (!pidData.includes(option)) {
              return `Add ${option}`
            }
            // Regular option
            return option
          }}
          disableCloseOnSelect
          defaultValue={pidData}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Marketing PID" />
          )}
          selectOnFocus
          clearOnBlur
          freeSolo
          filterOptions={(options, params) => {
            const filtered = filter(options, params)
            const { inputValue } = params
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue?.toLowerCase() === option?.toLowerCase()
            )
            if (inputValue.length > 2 && !isExisting && allowCreateNew) {
              filtered.push(inputValue)
            }

            return filtered
          }}
        />
      </Grid>

      <Grid item xs={1} />
      <Grid
        item
        xs={2}
        style={{
          paddingTop: '8px',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => updateMarketingFieldVisiblity(false)}
        >
          Cancel
        </Button>
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          paddingTop: '8px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isEqual(updatedMarketing, pidData)}
          onClick={() => handleUpdate(updatedMarketing)}
        >
          Add PID
        </Button>
      </Grid>
    </Grid>
  )
}

export default AddNewMarketingPid
