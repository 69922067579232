import { userRoles } from '../util/ConstUtils'
import { addLeadingZeros } from '../util/Filter'

export function saveUserToUserInformation(saveUser) {
  return {
    lanId: saveUser.lan_id,
    firstName: saveUser.first_name,
    lastName: saveUser.last_name,
    emailAddress: saveUser.email_address,
    addressLine1: saveUser.address_line1,
    addressLine2: saveUser.address_line2,
    mailstop: saveUser.mail_stop,
    zipCode: saveUser.zipcode,
    city: saveUser.city,
    state: saveUser.state,
    userRole: userRoles
      .filter(
        (role) => saveUser.user_role.toLowerCase() === role.key.toLowerCase()
      )
      .map((role) => role.value)
      .toString(),
    defaultLocation: saveUser.default_location,
    printer_type: saveUser.printer_type,
  }
}

export function userInformationToSaveUser(userInformation) {
  return {
    lan_id: userInformation.lanId,
    first_name: userInformation.firstName,
    last_name: userInformation.lastName,
    email_address: userInformation.emailAddress,
  }
}

export function updateUserToUserInformation(updateUser) {
  return {
    lanId: updateUser.lan_id,
    addressLine1: updateUser.address_line1,
    addressLine2: updateUser.address_line2,
    mailstop: updateUser.mail_stop,
    city: updateUser.city,
    state: updateUser.state,
    zipCode: updateUser.zipcode,
    defaultLocation: updateUser.default_location,
    printer_type: updateUser.printer_type,
  }
}

export function userInformationToUpdateUser(userInformation) {
  return {
    address_line1: userInformation.addressLine1,
    address_line2: userInformation.addressLine2,
    mail_stop: userInformation.mailstop,
    city: userInformation.city,
    state: userInformation.state,
    zipcode: userInformation.zipCode,
    default_location: userInformation.defaultLocation,
    printer_type: userInformation.printer_type,
  }
}

export function formatUserDeptList(deptList = []) {
  return deptList?.map((deptObj = {}) => formatOneDept(deptObj))
}

export function formatOneDept(deptObj = {}) {
  return {
    department_name: deptObj.value,
    department_id: deptObj.label,
  }
}

export function formatUserDeptRes(deptList = []) {
  return deptList?.map((deptObj = {}) => formatOneDeptRes(deptObj))
}

export function formatOneDeptRes(deptObj = {}) {
  const { departmentId: deptId } = deptObj
  const departmentId = addLeadingZeros(String(deptId), 3)
  return {
    label: isNaN(departmentId) ? departmentId || deptObj : departmentId,
    value: isNaN(departmentId)
      ? deptObj.departmentName || deptObj
      : deptObj.departmentName,
    displayName: deptObj.departmentName
      ? `${departmentId} - ${deptObj.departmentName || ''}`
      : `${departmentId}`,
    id: deptId,
  }
}
