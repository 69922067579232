import { defaultScenicRoomsTab } from '../../enums/Tabs'
import * as types from './actionType'

const activeColumnData = [
  {
    id: 'scenicRoomId',
    numeric: false,
    disablePadding: false,
    label: 'Room ID',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'roomImageUrl',
    numeric: true,
    disablePadding: false,
    label: 'Room Image',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'roomName',
    numeric: false,
    disablePadding: false,
    label: 'Room Name',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'roomCategory',
    numeric: false,
    disablePadding: true,
    label: 'Category',
    textAlign: 'right',
  },
  {
    id: 'roomSubCategory',
    numeric: false,
    disablePadding: false,
    label: 'Sub Category',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'source',
    numeric: false,
    disablePadding: false,
    label: 'Source',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: 'Notes',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: false,
    label: 'Created By',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'modifiedBy',
    numeric: false,
    disablePadding: false,
    label: 'Modified By',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'createdOn',
    numeric: true,
    disablePadding: false,
    label: 'Created On',
    textAlign: 'right',
    type: 'date',
  },
  {
    id: 'updateDate',
    numeric: true,
    disablePadding: false,
    label: 'Last Updated',
    textAlign: 'right',
    type: 'date',
  },
  {
    id: 'scenicAssets',
    numeric: false,
    disablePadding: false,
    label: '#Scenic Assets',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'scenicBrand',
    numeric: false,
    disablePadding: false,
    label: 'Target Brand',
    textAlign: 'right',
    type: '',
  },
  {
    id: 'totalCost',
    numeric: false,
    disablePadding: false,
    label: 'Total Cost',
    textAlign: 'right',
    type: '',
  },
]

export const initialState = {
  alertIsVisable: false,
  alertMessage: '',
  checkBoxEnabled: true,
  detailsPopupIsVisible: false,
  activeColumnData: activeColumnData,
  archivedColumnData: activeColumnData,
  activeScenicRooms: [],
  archivedScenicRooms: [],
  numSelected: 0,
  onClick: () => {},
  response: false,
  selectedData: [],
  activeScenicRoomPending: false,
  activeScenicRoomFailure: false,
  archivedScenicRoomPending: false,
  archivedScenicRoomFailure: false,
  filterValue: '',
  filterValueShipped: '',
  tabValue: defaultScenicRoomsTab,
  tabBasedAttributes: {},
  allPartnersList: [],
  fetchPartnersFailure: false,
  fetchPartnersPending: false,
  totalCount: Number(0),
}

export default function scenicRoom(state = initialState, action = {}) {
  switch (action.type) {
    case types.ACTIVE_SCENIC_ROOM_SUCCESS: {
      return Object.assign({}, state, {
        activeScenicRooms: action.payload.activeScenicRooms,
        totalCount: action?.payload?.activeScenicRooms?.length || Number(0),
      })
    }
    case types.ACTIVE_SCENIC_ROOM_PENDING: {
      return Object.assign({}, state, {
        activeScenicRoomPending: action.payload.pending,
      })
    }
    case types.ACTIVE_SCENIC_ROOM_FAILURE: {
      return Object.assign({}, state, {
        activeScenicRoomFailure: true,
      })
    }

    case types.SHIPPED_SHIP_SAMPLES_SUCCESS: {
      return Object.assign({}, state, {
        archivedScenicRooms: action.payload.archivedScenicRooms,
        totalCount: action.payload.totalCount || Number(0),
      })
    }

    case types.SHIPPED_SHIP_SAMPLES_PENDING: {
      return Object.assign({}, state, {
        archivedScenicRoomPending: action.payload.pending,
      })
    }

    case types.SHIPPED_SHIP_SAMPLES_FAILURE: {
      return Object.assign({}, state, {
        archivedScenicRoomFailure: true,
        archivedScenicRooms: [],
      })
    }
    case types.SCENIC_ROOMS_TABLE_FILTER_VALUE: {
      return Object.assign({}, state, {
        filterValue: action.payload.filterValue,
      })
    }
    case types.SCENIC_ROOMS_TABLE_SHIPPED_FILTER_VALUE: {
      return Object.assign({}, state, {
        filterValueShipped: action.payload.filterValue,
      })
    }
    case types.SCENIC_ROOMS_SET_TAB_VALUE: {
      const {
        payload: { tabValue = '' },
      } = action
      return Object.assign({}, state, {
        ...state,
        tabValue,
      })
    }

    case types.SCENIC_ROOMS_SET_TAB_ATTRIBUTES: {
      const {
        payload: { tabBasedAttributes = '' },
      } = action
      return Object.assign({}, state, {
        ...state,
        tabBasedAttributes,
      })
    }

    case types.FETCH_PARTNERS_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        allPartnersList: action.payload.allPartnersList,
      })
    }
    case types.FETCH_PARTNERS_REQUEST_PENDING:
      return Object.assign({}, state, {
        fetchPartnersPending: action.payload.pending,
      })
    case types.FETCH_PARTNERS_REQUEST_FAILURE:
      return Object.assign({}, state, {
        fetchPartnersFailure: true,
      })
    case types.SAVE_USERS:
      return Object.assign({}, state, {
        allUsers: [...action.payload],
      })
    default:
      return state
  }
}
