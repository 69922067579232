import * as inventoryApi from '../../services/inventoryService'
import * as inventoryMapper from '../../mappers/inventoryMapper'
import { setSelectedFilters } from '../autoOrderFilters/actionCreator'
import * as types from './actionType'
import { isEmpty } from 'lodash'
import { getFilterRequestParams } from '../../pages/AddFilters/constants'
import { showNotification } from '../notification/actionCreator'

const formatFilters = (filters = {}) => {
  const { data = {} } = filters
  const filtersData = {}
  if (Object.entries(data)?.length) {
    Object.keys(data)?.map((fobj) => {
      if (Object.entries(data[fobj])?.length) {
        filtersData[fobj] = getFilterRequestParams({ data: data[fobj] || {} })
      }
      return true
    })
  }
  return filtersData
}
export function saveFilters(
  filterRequest = {},
  filterCopy = {},
  pageName = ''
) {
  return (dispatch) => {
    const saveFiltersRequestParams =
      pageName === 'searchInventory' ? formatFilters(filterCopy) : filterRequest
    if (pageName === 'searchInventory') {
      filterRequest['filters'] = saveFiltersRequestParams
    }
    dispatch(saveFiltersPending(true))
    inventoryApi
      .saveFilters(
        inventoryMapper.filtersToFiltersRequest(filterRequest, pageName)
      )
      .then((response) => {
        dispatch(
          saveFiltersSuccess(inventoryMapper.filtersResponseToFilters(response))
        )
        pageName === 'searchInventory' &&
          dispatch(
            showNotification(true, 'Filter Saved Succesfully', 'success')
          )
        dispatch(saveFiltersPending(false))
        dispatch(
          getFilters(pageName, {
            feature: 'search_include_filters',
          })
        )
      })
      .catch((err) => {
        const { response = {} } = err
        const { data = '' } = response
        dispatch(saveFiltersFailure())
        dispatch(saveFiltersPending(false))
        pageName === 'searchInventory' && dispatch(showNotification(true, data))
      })
  }
}
export function saveFilterData(params = {}) {
  return {
    type: types.SAVE_FILTERS_DATA,
    payload: { params: params },
  }
}
export function saveFiltersPending(pending = false) {
  return {
    type: types.SAVE_FILTERS_PENDING,
    payload: { pending: pending },
  }
}

export function saveFiltersSuccess(filters = {}) {
  return {
    type: types.SAVE_FILTERS_SUCCESS,
    payload: { filters: filters },
  }
}

export function saveFiltersFailure() {
  return {
    type: types.SAVE_FILTERS_FAILURE,
  }
}

export function setFilters(filters, pageName = '') {
  if (!isEmpty(pageName)) {
    return {
      type: types.SET_FILTERS,
      payload: { data: { [pageName]: filters } },
    }
  }
}

export function setSearchCurrentTab(tabName = '') {
  if (!isEmpty(tabName)) {
    return {
      type: types.SET_SEARCH_INVENTORY_CURRENT_TAB,
      payload: { tabName: tabName },
    }
  }
}

export function setFiltersAutoOrder(filters, pageName = '') {
  if (!isEmpty(pageName)) {
    return {
      type: types.SET_FILTERS_AUTO_ORDER,
      payload: {
        data: { [pageName]: filters },
        pageName: pageName,
        filters: filters,
      },
    }
  }
}

export function deleteFilters(filterId = '', pageName = '') {
  return async (dispatch) => {
    await dispatch(deleteFiltersPending(true))
    try {
      inventoryApi.deleteFilters(filterId).then((response) => {
        dispatch(deleteFiltersSuccess(filterId, pageName))
        dispatch(deleteFiltersPending(false))
        dispatch(
          getFilters(pageName, {
            feature: 'search_include_filters',
          })
        )
        dispatch(
          showNotification(true, 'Filter Deleted Succesfully', 'success')
        )
      })
    } catch (err) {
      await dispatch(deleteFiltersFailure())
      await dispatch(deleteFiltersPending(false))
    }
  }
}

export function getFilters(pageName = '', addParams = {}) {
  return async (dispatch) => {
    await dispatch(getFiltersPending(true))
    try {
      const response = await inventoryApi.getFilters(addParams, true)
      await dispatch(
        getFiltersSuccess(
          inventoryMapper.filtersResponseToFilters(response),
          pageName
        )
      )
      await dispatch(getFiltersPending(false))
    } catch (err) {
      const { response = {} } = err
      const { data = {}, status = '' } = response
      if (data === 'No filters with that username exist' && status === 500) {
        await dispatch(getFiltersSuccess([], pageName))
      }
      await dispatch(getFiltersFailure())
      await dispatch(getFiltersPending(false))
    }
  }
}

export function getFiltersSuccess(filters = {}, pageName) {
  return {
    type: types.GET_FILTERS_SUCCESS,
    payload: { availableFilters: filters, pageName: pageName },
  }
}

export function getFiltersPending(pending = false) {
  return {
    type: types.GET_FILTERS_PENDING,
    payload: { pending: pending },
  }
}

export function getFiltersFailure() {
  return {
    type: types.GET_FILTERS_FAILURE,
  }
}

export function deleteFiltersSuccess(filterId = '', pageName) {
  return {
    type: types.DELETE_FILTERS_SUCCESS,
    payload: { filterId: filterId, pageName: pageName },
  }
}

export function deleteFiltersPending(pending = false) {
  return {
    type: types.DELETE_FILTERS_PENDING,
    payload: { pending: pending },
  }
}

export function deleteFiltersFailure() {
  return {
    type: types.DELETE_FILTERS_FAILURE,
  }
}

export function getAutoOrderFilters(pageName = '', addParams = {}) {
  return (dispatch, getState) => {
    const currentState = getState() || {}
    const {
      autoOrderFilters: { addFilterOptions = [] },
    } = currentState
    dispatch(getAOFiltersPending(true))
    inventoryApi
      .getFilters(addParams)
      .then((response) => {
        const updatedFilters =
          inventoryMapper.filtersResponseToAOFilters(response)
        const updatedFiltersKeys = Object.keys(updatedFilters) || []
        dispatch(getAOFiltersSuccess(updatedFilters, pageName))
        // addFilterOptions
        const updatedFiltersKeysObj = addFilterOptions.filter(
          (obj) => updatedFiltersKeys.indexOf(obj.label) !== -1
        )
        dispatch(setSelectedFilters(updatedFiltersKeysObj, pageName))
        dispatch(getAOFiltersPending(false))
        // dispatch(setIsSavedFilters(true))
      })
      .catch((err) => {
        dispatch(setIsSavedFilters(true))
        dispatch(getAOFiltersFailure())
        dispatch(getAOFiltersPending(false))
      })
  }
}

export function getAOFiltersSuccess(filters = {}, pageName) {
  return {
    type: types.GET_AUTO_ORDER_FILTERS_SUCCESS,
    payload: { availableFilters: filters, pageName: pageName },
  }
}

export function setIsSavedFilters(isSaved) {
  return {
    type: types.SET_IS_SAVED_FILTERS,
    payload: { isSavedFilters: isSaved },
  }
}

export function getAOFiltersPending(pending = false) {
  return {
    type: types.GET_AUTO_ORDER_FILTERS_PENDING,
    payload: { pending: pending },
  }
}

export function getAOFiltersFailure() {
  return {
    type: types.GET_AUTO_ORDER_FILTERS_FAILURE,
  }
}

export function setFilterNFList(filterNFList = {}) {
  return {
    type: types.SET_FILTER_NOTFOUND_LIST,
    payload: { filterNFList: filterNFList },
  }
}
