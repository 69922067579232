import React from 'react'
import * as propTypes from 'prop-types'
import {
  Paper,
  Checkbox,
  StepLabel,
  Step,
  Stepper,
  Grid,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
} from '@material-ui/core'
import CreatableSelect from '../../components/ReactSelect/CreatableSelect'
import ChipArray from '../../components/Chips/ChipArray'
import copy from 'copy-to-clipboard'
import Search from '../../components/Search/Search'
import {
  clearCheckinData,
  closePopup,
  checkinSamples,
  validateArgs,
  getCheckinLocation,
  showPopup,
  removeSample,
  removeSamples,
  setSampleStatusShipped,
  setMultipleSampleStatusShipped,
  getRelatedSamples,
  cloneSample,
  updateSampleId,
  addSampleToPrintQueue,
  removeSampleFromPrintQueue,
  getBarcodes,
  setSampleLocation,
  setSamplesLocation,
  assignLocation,
  setPreviousPage,
  removeUnknownSampleFromList,
  removeUnknownLocationFromList,
  removeDuplicateSampleFromList,
  updateSampleFieldsData,
  checkSampleStatusesOnNext,
  removeSampleFromPrintQueueAll,
  printAllBarcodes,
} from '../../store/checkIn/actionCreator'
import { getCheckInLocations } from '../../store/settings/actionCreator'
import SampleDetailCard from '../../components/ExpandableCard/SampleDetailCard'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Error from '@material-ui/icons/Error'
import Print from '@material-ui/icons/Print'
import Warning from '@material-ui/icons/Warning'
import Clear from '@material-ui/icons/Clear'
import {
  resolveDuplicate,
  // resolveLocation,
  resolveLocations,
  // removeInvalidSample,
  removeInvalidSampleNew,
  assignDetailedLocationNew,
} from './Resolution'
import ResolutionTree from '../../components/ResolutionTree/ResolutionTree'
import LocalPrintshop from '@material-ui/icons/LocalPrintshop'
import { partition, isEmpty, isEqual, groupBy } from 'lodash'
import { showNotification } from '../../store/notification/actionCreator'
import withHOCs from 'util/withHocs'
import { withStyles } from '@material-ui/core/styles'
import {
  ValidationOperation,
  AssignLocationOperationLabels,
} from '../../enums/validationOperations'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import BarcodeTypePopUp from '../../components/PopUp/BarcodeTypePopUp'
import PopUp from '../../components/PopUp/PopUp'
import { setBarcodeType } from '../../store/user/actionCreator'
import ClearAllIcon from '@material-ui/icons/ClearAll'

import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import {
  ITME_TAB_LABEL,
  DETAILS_TAB_LABEL,
  dataTabLabelsShippedWithScenic,
} from '../../enums/tableTabs'
import {
  dataTabLabelsShippedWithProps,
  dataTabLabelsShipped,
} from '../../enums/tableTabs'
import { PROPS, SCENIC } from 'enums/Tabs'
import { makeTabTables, getTitle } from '../../util/Table'
import { sampleStatuses } from '../../util/ConstUtils'
import { getLocationNames } from 'util/CommonUtils'

const styles = (theme) => ({
  checkInPage: {
    flexGrow: 1,
  },
  checkInPageIntro: {
    fontSize: 'xx-large',
    textAlign: 'center',
  },
  checkInPageLocation: {
    fontSize: 'x-large',
    textAlign: 'center',
    width: '100%',
    color: '#cc0000',
  },
  button: {
    position: 'absolute',
    height: '50px',
    right: 0,
    width: '100px',
  },
  chip: {
    border: 'none',
  },
  barcodeText: {
    margin: '3px 0px 7px 0px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 'normal',
    display: 'inline-block',
  },
  stepperRoot: {
    padding: 'unset',
  },
  assignLocSubLabel: {
    height: '36px',
  },
  selectAllControl: {
    height: '36px',
    minWidth: '130px',
    width: 'auto',
  },
  card: {
    padding: '20px',
  },
  messageArea: {
    padding: '26px !important',
  },
  selectAllArea: {
    padding: '16px 0px !important',
  },
  assignMessageArea: {
    padding: '15px 30px 15px 30px !important',
  },
  actionButtonsLarge: {
    color: theme.palette.primary.darkBlue,
    boxShadow: 'unset',
    backgroundColor: theme.palette.primary.lightestGrey,
    marginLeft: '10px',
    fontSize: '12px',
    width: 150,
  },
  filterCheckboxChecked: {
    color: '#366CD9 !important',
  },
  completeAlertTitle: {
    fontSize: '24px',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  clearAllClass: {
    textAlign: 'end',
  },
  nextButton: {
    margin: '6px 15px 18px 0px',
    height: '36px',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
  searchInput: {
    width: '360px',
    paddingTop: '2px',
  },
  searchInputLabel: {
    color: '#666 !important',
    fontSize: '14px',
    fontWeight: 'initial',
  },
  notchedOutlineNew: {
    borderColor: '#8d8d8d !important',
    borderWidth: '2px !important',
  },
  clearAll: {
    margin: 10,
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  searchButton: {
    margin: 10,
  },
  locationScanPaper: {
    padding: '35px',
    width: '960px',
  },
})

let operation = ValidationOperation.CHECKIN
const detailedLocations = [
  'Royalston - Samples & Props',
  'Winter Street Samples Props and Workshop',
  'Royalston - TCIN Studio',
  'Target Plaza',
  'Target Plaza South (TPS)',
  'Target Plaza North (TPN)',
  'TP3 - 3 Mock Store A',
]
const actions = Object.freeze({
  SCAN: 'Scan Samples',
  ASSIGN_BUILDING: 'Assign Building',
  ASSIGN_LOCATION: 'Assign Warehouse Location',
})

const itemsGroupLabels = {
  SAMPLE_ORDERED: 'Select All Ordered Samples',
  SAMPLE_CANCELLED: 'Select All Cancelled Samples',
  DUPLICATE: 'Resolve Duplicate Samples',
  // otherItems: '',
  validItems:
    'Please resolve and remove the invalid samples above to check in these valid samples by clicking the NEXT button on the top right',
}

const StyledCreatableSelect = withStyles((theme) => ({
  addFilter: {
    width: 'auto',
    minWidth: '300px',
    margin: '6px 0px 18px 0px',
  },
  locationDetailPaper: {},
  paper: {},
  errorColor: {},
  greenClassIcon: {},
  greenClass: {},
  activeStepClass: {},
  vl: {},
  barcodeInput: {},
  inputLabelControlZero: {},
  inputLabelControlAuto: {},
  inputLabelControl: {},
  inputLabel: {},
  greyClass: {},
}))(CreatableSelect)

class CheckInPage extends React.Component {
  constructor(props) {
    super(props)
    this.ResolutionElement = React.createRef()
    this.apiTextInput = React.createRef()
    this.state = {
      popupItem: {},
      popupIsVisible: false,
      selectedData: [],
      activeStep: 0,
      resolutionOpen: false,
      printedLabels: false,
      barcodeTypePopUpIsVisible: false,
      isSelectAllAssets: false,
      isSelectAllClicked: false,
      selectedAssets: [],
      openCompleteAlert: false,
      popupTabLabels: [ITME_TAB_LABEL, DETAILS_TAB_LABEL],
      searchMVStr: '',
      searchedLocations: [],
      isReqCurrentNodeContentRequired: false,
      locationBarcodeMove: '',
      resetLocation: false,
      currentSectionStatus: '',
      currentSectionType: '',
      selectedLocation: '',
      sampleidsCount: 0,
    }
  }

  componentDidMount = () => {
    // get checkin location for user
    this.setActionType()
    const { getCheckInLocations = () => {} } = this.props
    getCheckInLocations()
  }

  static getDerivedStateFromProps(props, state) {
    const { locations = [], location: userLocation = '' } = props
    if (
      !isEmpty(userLocation) &&
      !isEmpty(locations) &&
      isEmpty(state.selectedLocation)
    ) {
      const currentLocation =
        locations.find((obj) => obj.building === userLocation) || {}
      const currentLocationObj = {
        label: currentLocation.building,
        value: currentLocation.locationId,
      }
      return {
        selectedLocation: currentLocationObj,
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isSelectAllAssets, isSelectAllClicked } = this.state
    const { data: pageData = [] } = this.props
    const { data: prevPageData = [] } = prevProps
    const { isSelectAllAssets: prevIsSelectAllAssets } = prevState
    let filterAssets = []
    if (prevIsSelectAllAssets !== isSelectAllAssets && !!isSelectAllClicked) {
      if (isSelectAllAssets) {
        filterAssets =
          (pageData.filter((itemObj) => !itemObj.isAssigned) || []).map(
            (obj) => obj.pegasusId
          ) || []
      }
      this.setState({
        selectedAssets: filterAssets,
      })
    }
    const { currentSectionStatus = '', currentSectionType = '' } = this.state
    const {
      [currentSectionStatus]: {
        isSelectAllAssets: isSelectAllAssetsByStatus,
        isSelectAllClicked: isSelectAllClickedByStatus,
      } = {},
    } = this.state
    const {
      [currentSectionStatus]: {
        isSelectAllAssets: prevIsSelectAllAssetsByStatus,
      } = {},
    } = prevState
    if (
      prevIsSelectAllAssetsByStatus !== isSelectAllAssetsByStatus &&
      !!isSelectAllClickedByStatus &&
      !isEmpty(currentSectionStatus)
    ) {
      const filterData = this.getFilterData()
      // const filterDataByLocation = groupBy(filterData, (obj) => obj.shipTo)
      const filterDataCopy = [...filterData]
      const filterDataByStatus =
        currentSectionType === 'Location'
          ? groupBy(filterDataCopy, (obj) => obj.shipTo)
          : this.setFilterDataCopyByStatus(filterDataCopy)
      if (isSelectAllAssetsByStatus) {
        filterAssets = filterDataByStatus[currentSectionStatus]
      }
      const filterAssetsIds = filterAssets.map((obj) => obj.pegasusId)
      this.setState({
        [currentSectionStatus]: {
          selectedAssets: filterAssetsIds,
          isSelectAllAssets: filterAssets.length > 0,
        },
        currentSectionStatus: '',
        currentSectionType: '',
      })
    }

    if (!isEqual(pageData, prevPageData)) {
      const filterData = this.getFilterData()
      const filterDataCopy = [...filterData]
      const filterDataByStatus = this.setFilterDataCopyByStatus(filterDataCopy)
      let updatedSampleByStatus = {}
      Object.keys(filterDataByStatus).length > 0 &&
        Object.keys(filterDataByStatus).map((statusType = '') => {
          const { [statusType]: { selectedAssets = [] } = {} } = this.state
          const statusTypeUpdatedSamples = filterDataByStatus[statusType]
          const statusTypeUpdatedSampleIds = statusTypeUpdatedSamples.map(
            (obj) => obj.pegasusId
          )
          const updatedSelectedAssets = selectedAssets.filter(
            (obj) => statusTypeUpdatedSampleIds.indexOf(obj) !== -1
          )
          updatedSampleByStatus[statusType] = {}
          updatedSampleByStatus[statusType]['selectedAssets'] =
            updatedSelectedAssets
          updatedSampleByStatus[statusType]['isSelectAllAssets'] =
            selectedAssets.length > 0
              ? isEqual(statusTypeUpdatedSampleIds, selectedAssets)
              : false
          return statusType
        })
      this.setState((state) => {
        return Object.assign({}, state, updatedSampleByStatus)
      })
    }

    this.setActionType()
  }

  onSelectAll = () => {
    this.setState((state) => ({
      isSelectAllAssets: !state.isSelectAllAssets,
      isSelectAllClicked: true,
    }))
  }
  onSelectAllByStatus = (statusType = '', sectionType = '') => {
    const { [statusType]: { isSelectAllAssets } = {} } = this.state
    this.setState((state) => ({
      [statusType]: {
        isSelectAllClicked: true,
        isSelectAllAssets: !isSelectAllAssets,
      },
      currentSectionStatus: statusType,
      currentSectionType: sectionType,
    }))
  }
  onAssetSelect = (checked, asset) => {
    const { pegasusId } = asset
    let filterAssets = []
    const { selectedAssets = [] } = this.state
    const { data: pageData = [] } = this.props
    let newIsSelectAllAssets = false
    if (!checked) {
      filterAssets = selectedAssets.filter((item) => item !== pegasusId)
    } else {
      filterAssets = [...(selectedAssets || []), pegasusId]
    }
    const newfilterAssets = Array.from(new Set([...filterAssets]))
    if (newfilterAssets.length === pageData.length) {
      newIsSelectAllAssets = true
    }
    this.setState((state) => ({
      selectedAssets: filterAssets,
      isSelectAllClicked: false,
      isSelectAllAssets: newIsSelectAllAssets,
    }))
  }

  isAssetCheckedByStatus = (assetId = '', statusType = '') => {
    const currentStatusObj =
      sampleStatuses.find((obj) => obj.value === statusType) || {}
    const statusTypeKey = currentStatusObj.key || statusType
    const { [statusTypeKey]: { selectedAssets = [] } = {} } = this.state
    return selectedAssets.indexOf(assetId) !== -1
  }

  isSelectAllCheckedByStatus = (statusType = '') => {
    const { [statusType]: { isSelectAllAssets } = {} } = this.state
    const newisSelectAllAssets = isSelectAllAssets
    return newisSelectAllAssets
  }

  isSelectAllCheckedByLocation = (location = '') => {
    const { [location]: { isSelectAllAssets } = {} } = this.state
    const newisSelectAllAssets = isSelectAllAssets
    return newisSelectAllAssets
  }

  isDisabledResolve = (statusType = '') => {
    const { [statusType]: { selectedAssets = [] } = {} } = this.state
    return selectedAssets.length
  }

  onAssetSelectByStatus = (
    checked,
    asset = {},
    statusType = '',
    sectionType = ''
  ) => {
    const { pegasusId = '' } = asset
    let filterAssets = []
    const currentStatusObj =
      sampleStatuses.find((obj) => obj.value === statusType) || {}
    const statusTypeKey = currentStatusObj.key || statusType
    const { [statusTypeKey]: { selectedAssets = [] } = {} } = this.state
    // const { data: pageData = [] } = this.props
    let newIsSelectAllAssets = false
    if (!checked) {
      filterAssets = selectedAssets.filter((item) => item !== pegasusId)
    } else {
      filterAssets = [...(selectedAssets || []), pegasusId]
    }
    const filterData = this.getFilterData()
    const filterDataCopy = [...filterData]
    const filterDataByStatus =
      sectionType === 'Location'
        ? groupBy(filterDataCopy, (obj) => obj.shipTo)
        : this.setFilterDataCopyByStatus(filterDataCopy)
    if (
      (filterAssets || []).length ===
      (filterDataByStatus[statusTypeKey] || []).length
    ) {
      newIsSelectAllAssets = true
    }
    this.setState((state) => ({
      [statusTypeKey]: {
        selectedAssets: filterAssets,
        isSelectAllClicked: false,
        isSelectAllAssets: newIsSelectAllAssets,
      },
      currentSectionStatus: statusTypeKey,
    }))
  }

  getSteps = () => {
    const { selectedLocation = {} } = this.state
    const { label: location = '' } = selectedLocation
    if (detailedLocations.indexOf(location) !== -1) {
      return [actions.SCAN, actions.ASSIGN_BUILDING, actions.ASSIGN_LOCATION]
    }
    return [actions.SCAN, actions.ASSIGN_BUILDING]
  }

  handleCompleteAlert = () => {
    this.setState((state) => ({
      openCompleteAlert: !state.openCompleteAlert,
    }))
  }

  validateSampleAndNext = (validItems = []) => {
    const { activeStep } = this.state
    const { checkSampleStatusesOnNext = () => {} } = this.props
    const { selectedLocation = {} } = this.state
    const { label: location = '' } = selectedLocation
    if (activeStep === 0) {
      const filterAssetsIds = validItems.map((obj) => obj.pegasusId)
      checkSampleStatusesOnNext(filterAssetsIds, location, this.handleNext)
    } else {
      this.handleNext()
    }
  }

  /*
   * Regardless of location, assign building happens at step 1
   * Royalston - Samples & Props, Royalston - TCIN Studio, TPN, TPS only: step 2 triggers detailed location assignment
   * */
  handleNext = () => {
    const { activeStep } = this.state
    const { data: pageData = [], onHandleReset = () => {} } = this.props
    let isShownNotification = false
    let message = ''
    let totalRecievedIds = []
    const { CHECKIN = '' } = ValidationOperation
    if (this.getSteps()[activeStep] === actions.ASSIGN_BUILDING) {
      this.handleCheckin()
    }

    if (activeStep === this.getSteps().length - 1) {
      this.handleReset()
      totalRecievedIds =
        pageData.filter((itemObj) => !!itemObj.isAssigned) || []

      if (totalRecievedIds.length > 0 && [CHECKIN].indexOf(operation) !== -1) {
        isShownNotification = totalRecievedIds.length > 0 ? true : false
        message =
          totalRecievedIds.length > 0
            ? totalRecievedIds.length +
              (totalRecievedIds.length === 1
                ? ' sample was '
                : ' samples were ') +
              'successfully ' +
              AssignLocationOperationLabels[operation]
            : ''
        if (!isEmpty(message)) {
          onHandleReset(isShownNotification, message, 'sucess')
        }
      }
    } else {
      this.setState((state) => {
        const { activeStep } = state
        return {
          activeStep: activeStep + 1,
        }
      })
    }
  }

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleReset = () => {
    const { clearCheckinData = () => {} } = this.props
    this.setState({
      activeStep: 0,
      printedLabels: false,
      openCompleteAlert: false,
      searchMVStr: '',
      searchedLocations: [],
      isReqCurrentNodeContentRequired: false,
      locationBarcodeMove: '',
      selectedAssets: [],
      isSelectAllClicked: false,
      isSelectAllAssets: false,
    })
    clearCheckinData()
  }

  setActionType = () => {
    const pathName = window.location.pathname
    const {
      setHeaderTitleArray = () => {},
      setPreviousPage = () => {},
      previousPage = '',
    } = this.props
    setHeaderTitleArray([{ title: 'Check In', link: '/checkIn' }])
    if (previousPage !== pathName) {
      this.handleReset()
      setPreviousPage(pathName)
    }
  }

  handleCheckin = () => {
    const { selectedLocation = {} } = this.state
    const { label: location = '' } = selectedLocation
    const { data, checkIn } = this.props
    checkIn(data, location)
  }

  stepperDisabled = (operation) => {
    const { activeStep, printedLabels } = this.state
    const { printQueue, data } = this.props

    const step = this.getSteps()[activeStep]
    if (data.length === 0) {
      return true
    }
    switch (step) {
      case actions.SCAN: {
        let scanSamplesCheck = data.flatMap(
          (item) =>
            item.checkInStatus === 'VALID' ||
            (item.checkInStatus === 'INVALID_LOCATION' &&
              operation === ValidationOperation.CHECKIN)
        )
        return (
          scanSamplesCheck.indexOf(false) >= 0 ||
          (printQueue.length > 0 && !printedLabels)
        )
      }
      case actions.ASSIGN_BUILDING: {
        let assignLocationCheck = data.flatMap(
          (item) => item.checkInStatus !== 'INVALID_LOCATION'
        )
        return assignLocationCheck.indexOf(false) >= 0
      }
      case actions.ASSIGN_LOCATION:
        return false
      default:
        return true
    }
  }

  setSampleStatusBar = (sample, status, activeStep) => {
    const {
      addToPrintQueue,
      getRelatedSamples,
      cloneSample,
      removeSample,
      // setSampleLocation,
      // location,
      // setSampleStatusShipped,
    } = this.props
    // const { selectedLocation = {} } = this.state
    // const { label: location = '' } = selectedLocation
    const step = this.getSteps()[activeStep]
    const { pegasusId = '' } = sample
    const duplicateString =
      pegasusId.substring(0, 2) === 'PP' || pegasusId.substring(0, 2) === 'PS'
        ? 'Ready to Move/Invalid Checkin'
        : 'Duplicate'
    switch (status) {
      case 'VALID':
        return ['Ready', <CheckCircle />, null, null, 'success']
      case 'DUPLICATE':
        // as sample is now received it will be marked as a duplicate
        if (step === actions.ASSIGN_LOCATION) {
          return ['Ready', <CheckCircle />, null, null, 'success']
        }
        return [
          duplicateString,
          <Error />,
          'Resolve',
          () => {
            getRelatedSamples(sample.pegasusId, sample.shipTo).then(
              (samples) => {
                this.openResolution(
                  resolveDuplicate(
                    [sample.pegasusId],
                    <SampleDetailCard sample={sample} />,
                    partition(samples, (s) => {
                      return s.orderNum === sample.orderNum
                    }),
                    () => cloneSample(sample),
                    () => {
                      removeSample(sample.id)
                      return [sample.pegasusId]
                    },
                    addToPrintQueue
                  )
                )
              }
            )
          },
          'error',
        ]
      case 'INVALID_LOCATION':
        if (
          step === actions.SCAN &&
          operation === ValidationOperation.CHECKIN
        ) {
          return ['Ready', <CheckCircle />, null, null, 'success']
        }
        // if (operation === ValidationOperation.CHECKIN) {
        //   return [
        //     'Incorrect Location',
        //     <Warning />,
        //     'Resolve Location',
        //     () => {
        //       this.openResolution(
        //         resolveLocation(
        //           [sample.pegasusId],
        //           sample.shipTo,
        //           location,
        //           operation,
        //           () => {
        //             removeSample(sample.id)
        //           },
        //           () => {
        //             setSampleLocation(sample, location)
        //           }
        //         )
        //       )
        //     },
        //     'error',
        //   ]
        // }
        return [sample.shipTo, <Warning />, null, null, 'error']
      case 'INVALID_STATUS':
        return [sample.sample_status, <Warning />, null, null, 'error']
      default:
        return [null, null, null, null, 'error']
    }
  }

  openResolution = (setup) => {
    this.setState({
      resolutionOpen: true,
      activeResolutionId: setup.activeSample,
      startNode: setup.startNode,
      nodes: setup.nodes,
    })
  }

  closeResolution = (pegasusId) => {
    const { updateSampleId } = this.props
    const existingId = this.state.activeResolutionId
    this.setState({
      activeResolutionId: '',
      resolutionOpen: false,
      nodes: {},
    })
    const { selectedLocation = {} } = this.state

    const { label: location = '' } = selectedLocation
    if (!isEmpty(pegasusId)) {
      // updates id if different, otherwise revalidates
      updateSampleId(existingId, pegasusId, location)
      this.setState((state) => ({
        selectedAssets: [],
        isSelectAllClicked: false,
        isSelectAllAssets: false,
      }))
    }
  }

  printAllBarcodes = () => {
    const {
      data = [],
      printAllBarcodes = () => {},
      barcodeType = 'MULTILINE',
      removeSampleFromPrintQueueAll = () => {},
    } = this.props
    printAllBarcodes(data, barcodeType).then((pdf) => {
      if (pdf !== undefined) {
        window.open(pdf).print()
      }
    })

    this.setState(
      {
        printedLabels: true,
      },
      () => {
        removeSampleFromPrintQueueAll()
      }
    )
  }

  printBarcodes = () => {
    const {
      data = [],
      printQueue = [],
      printAllBarcodes = () => {},
      barcodeType = 'MULTILINE',
    } = this.props
    const samples = data.filter((sample = {}) =>
      printQueue.includes(sample.pegasusId)
    )
    printAllBarcodes(samples, barcodeType).then((pdf) => {
      if (pdf !== undefined) {
        window.open(pdf).print()
      }
    })

    this.setState({
      printedLabels: true,
    })
  }

  printBarcodesSingle = (sampleId = '') => {
    const {
      data = [],
      printQueue = [],
      printAllBarcodes = () => {},
      barcodeType = 'MULTILINE',
      removeToPrintQueue = () => {},
    } = this.props
    const samples = data.filter(
      (sample = {}) =>
        printQueue.includes(sample.pegasusId) && sample.pegasusId === sampleId
    )
    printAllBarcodes(samples, barcodeType).then((pdf) => {
      if (pdf !== undefined) {
        window.open(pdf).print()
      }
    })
    const updatedPrintQueue = printQueue.filter((obj) => obj !== sampleId)
    this.setState(
      {
        printedLabels: !updatedPrintQueue.length,
      },
      () => {
        removeToPrintQueue(sampleId)
      }
    )
  }

  getFilterData = () => {
    const steps = this.getSteps()
    const { activeStep } = this.state
    const { data = [] } = this.props

    const currentStep = steps[activeStep] || Number(0)
    const { ASSIGN_LOCATION = '' } = actions
    const filterData =
      currentStep === ASSIGN_LOCATION
        ? data.filter(
            (itemObj) =>
              !isEmpty(itemObj.sample_status) &&
              itemObj.checkInStatus === 'VALID' &&
              !itemObj.isAssigned
          )
        : data
    return filterData
  }

  getAssignedSamples = (activeStep, operation, data = []) => {
    const steps = this.getSteps()
    const currentStep = steps[activeStep] || Number(0)
    const { ASSIGN_LOCATION = '' } = actions
    const { CHECKIN = '' } = ValidationOperation
    const assignedSamples =
      currentStep === ASSIGN_LOCATION && operation === CHECKIN
        ? data.filter((itemObj) => !!itemObj.isAssigned)
        : []
    return assignedSamples
  }

  handleDeleteChip = (sampleId = '', type = '') => {
    const {
      removeUnknownSampleFromList = () => {},
      unknownLocationIds = [],
      removeUnknownLocationFromList = () => {},
      removeDuplicateSampleFromList = () => {},
    } = this.props
    removeUnknownSampleFromList(sampleId, type)
    if (!isEmpty(unknownLocationIds)) {
      removeUnknownLocationFromList()
      this.handleReset()
    }
    if (['duplicate', 'all'].indexOf(type) !== -1) {
      removeDuplicateSampleFromList(sampleId, type)
    } // for MOVE_container remove clear_all should not effect samples remove
  }

  renderClearButton = () => {
    const { classes = {} } = this.props
    return (
      <Button
        id="clearallcards"
        color="primary"
        onClick={this.handleReset}
        className={classes.defaultButton}
        startIcon={<ClearAllIcon />}
      >
        Clear All
      </Button>
    )
  }

  renderPrintAllButton = () => {
    const { classes = {} } = this.props
    return (
      <Button
        id="printalllabels"
        color="primary"
        onClick={this.printAllBarcodes}
        className={classes.defaultButton}
        startIcon={<Print />}
      >
        Print All
      </Button>
    )
  }

  renderClearAll = () => {
    const { classes = {} } = this.props
    return (
      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        id={'checkin_clearall'}
        className={classes.clearAllClass}
      >
        {this.renderPrintAllButton()}
        {this.renderClearButton()}
      </Grid>
    )
  }

  getCardActions = (activeStep = Number(0), item) => {
    const { addToPrintQueue = () => {}, removeSample = () => {} } = this.props
    const { pegasusId: itemPegasusId = '', id: itemId = '' } = item
    let sampleCardAction = [
      {
        label: 'Remove',
        action: () => removeSample(itemId),
      },
    ]
    Number(activeStep) === Number(0) &&
      sampleCardAction.push({
        label: 'Print New Label',
        disable: Number(activeStep) === Number(1) ? true : false,
        action: () => addToPrintQueue(itemPegasusId),
      })

    return sampleCardAction
  }

  handleDoneSuccess = () => {
    this.setState({ resetLocation: false })
  }

  renderStepper = (activeStep = '') => {
    const { classes = {} } = this.props
    const steps = this.getSteps()
    return (
      <Stepper
        activeStep={activeStep}
        id={'CheckinStepper'}
        alternativeLabel
        classes={{
          root: classes.stepperRoot,
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }

  setFilterDataCopyByStatus = (dataToRender = []) => {
    const dataToRenderCopy = [...dataToRender]
    const orderedItems = dataToRenderCopy.filter(
      (obj) =>
        obj.sample_status === 'SAMPLE_ORDERED' &&
        obj.checkInStatus === 'INVALID_STATUS'
    )
    const dataCopyAfterOderedItems = dataToRenderCopy.filter(
      (obj) =>
        !(
          obj.sample_status === 'SAMPLE_ORDERED' &&
          obj.checkInStatus === 'INVALID_STATUS'
        )
    )
    const cancelledItems = dataCopyAfterOderedItems.filter(
      (obj) =>
        obj.sample_status === 'SAMPLE_CANCELLED' &&
        obj.checkInStatus === 'INVALID_STATUS'
    )
    const dataCopyAfterCancelItems = dataCopyAfterOderedItems.filter(
      (obj) =>
        !(
          obj.sample_status === 'SAMPLE_CANCELLED' &&
          obj.checkInStatus === 'INVALID_STATUS'
        )
    )
    const duplicateStatusItems = dataCopyAfterCancelItems.filter(
      (obj) => obj.checkInStatus === 'DUPLICATE'
    )
    const remainingItems1 = dataCopyAfterCancelItems.filter(
      (obj) => obj.checkInStatus !== 'DUPLICATE'
    )
    const validStatusItems = remainingItems1.filter(
      (obj) => obj.checkInStatus === 'VALID'
    )
    const remainingItems2 = remainingItems1.filter(
      (obj) => obj.checkInStatus !== 'VALID'
    )
    return {
      SAMPLE_ORDERED: [...orderedItems],
      SAMPLE_CANCELLED: [...cancelledItems],
      DUPLICATE: [...duplicateStatusItems],
      // otherItems: [...remainingItems2],
      validItems: [...remainingItems2, ...validStatusItems],
    }
  }

  handleLocationChange = (value) => {
    this.setState((state) => ({
      ...state,
      selectedLocation: value,
    }))
  }

  onLocationFilterClear = (event) => {
    this.setState((state) => ({
      ...state,
      selectedLocation: {},
    }))
  }

  renderLocationDropdown = () => {
    const { classes = {}, locations = [], userLocation = '' } = this.props
    const { onLocationFilterClear = () => {} } = this
    const { selectedLocation = {} } = this.state
    const {
      label: selectedLocationLabel = '',
      value: selectedLocationValue = '',
    } = selectedLocation
    const locationsList = getLocationNames(locations)
    const locationsListFinal =
      !isEmpty(selectedLocationLabel) &&
      userLocation !== 'Royalston - Samples & Props'
        ? locationsList.filter(
            (id) => id.label !== 'Royalston - Samples & Props'
          )
        : locationsList
    return (
      <StyledCreatableSelect
        distinct={locationsListFinal}
        classes={classes}
        selectedValue={selectedLocation}
        handleDropdownChange={(selectedObj) => {
          this.handleLocationChange(selectedObj)
        }}
        isCreatable={false}
        componentLabel={
          !isEmpty(selectedLocation) ? selectedLocationLabel : 'Change Location'
        }
        isNewOption={false}
        buttonVariant={'text'}
        // startIcon={<FilterListIcon />}
        endIcon={
          !isEmpty(selectedLocationValue) ? (
            <Clear
              className={classes.getFilterClear}
              onClick={(event) => {
                onLocationFilterClear()
              }}
            />
          ) : undefined
        }
        // isMulti
      />
    )
  }

  fetchSamplesWithLocation = (sampleIds = [], arg2 = '', arg3 = '') => {
    const { fetchSamples = () => {} } = this.props
    const { selectedLocation = {} } = this.state

    const { label: location = '' } = selectedLocation
    fetchSamples(sampleIds, '', location)
  }

  renderStepOneHeader = () => {
    const { activeStep } = this.state
    return activeStep === Number(0) ? (
      <Grid container direction={'column'} alignItems="stretch">
        <Grid item>{this.renderLocationDropdown()}</Grid>
        <Grid item>{this.renderStepOnePrintHeader()}</Grid>
      </Grid>
    ) : null
  }

  renderStepOnePrintHeader = () => {
    const {
      printQueue = [],
      // location = '',
      classes = {},
      barcodeType = 'MULTILINE',
    } = this.props

    const { selectedLocation = {} } = this.state
    const { label: location = '' } = selectedLocation

    const printQueueLength = printQueue.length || Number(0)
    return (
      <>
        <div style={{ display: 'block' }}>
          <Button
            id="printLabels"
            variant="outlined"
            color="primary"
            disabled={!printQueueLength}
            onClick={this.printBarcodes}
            style={{ height: '36px' }}
          >
            <LocalPrintshop style={{ marginRight: '5px' }} /> Print{' '}
            {printQueueLength} New Labels
          </Button>
        </div>
        {[
          'Royalston - Samples & Props',
          'Royalston - TCIN Studio',
          'Target Plaza',
        ].indexOf(location) !== -1 && (
          <div
            style={{
              display: 'inline-block',
              width: '190px',
            }}
          >
            <p
              className={classes.barcodeText}
              style={{ color: 'black', marginRight: '5px' }}
            >
              {'Format: '}
              {barcodeType === 'DYMO' ? 'Dymo Printer' : 'Label Paper'}
            </p>
            {/* <p
              className={classes.barcodeText}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() =>
                this.setState({
                  barcodeTypePopUpIsVisible: true,
                })
              }
            >
              Change
            </p> */}
          </div>
        )}
      </>
    )
  }

  renderNextButton = (filterDataByStatus = {}) => {
    const { activeStep } = this.state
    const { classes = {} } = this.props
    const steps = this.getSteps()
    return (
      <Button
        id="next"
        variant="contained"
        color="primary"
        disabled={this.stepperDisabled(operation)}
        onClick={(event) => {
          this.validateSampleAndNext(filterDataByStatus['validItems'])
        }}
        className={classes.nextButton}
      >
        {activeStep === steps.length - 1 ? 'Complete Sample Check-In' : 'Next'}
      </Button>
    )
  }
  getSamplesCount = (sampleCount) => {
    this.setState({ sampleidsCount: sampleCount })
  }

  renderSearchSamples = () => {
    const { activeStep, sampleidsCount } = this.state
    const { handleChipClick = () => {}, unknownPegasusIds = [] } = this.props
    const unknownSampleLabel = 'Unknown Pegasus/FileMaker ID(s)'
    let unknownPegasusIdsChips = unknownPegasusIds.map((data, index) => ({
      label: data,
      key: index,
      type: 'unknown',
    }))

    const chipData = [...unknownPegasusIdsChips]
    return activeStep === Number(0) ? (
      <Grid item xs={12}>
        <Grid item container justify={'center'}>
          <Grid item xs={8}>
            <Search
              id="scan"
              clearOnSubmit
              label={'Scan or Enter Pegasus ID(s)'}
              // TODO: Support filemaker id also label={'Scan or Enter Pegasus/FileMaker ID(s)'}
              helperText={
                sampleidsCount
                  ? `${sampleidsCount} Pegasus/FileMaker ID(s)`
                  : 'Pegasus/FileMaker ID(s)'
              }
              onChangeText={this.getSamplesCount}
              buttonName="Search"
              submitSearch={this.fetchSamplesWithLocation}
              submitOnEnter
              childRef={this.apiTextInput}
            />
          </Grid>
          <Grid item xs={8} id="unknownIDChips" data-cy={chipData.length}>
            {chipData.length > 0 && (
              <ChipArray
                data={chipData}
                enableCopyAllButton
                handleClick={handleChipClick}
                title={unknownSampleLabel}
                onDelete={this.handleDeleteChip}
                clearAllRequired={true}
                chipDeleteRequired={true}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    ) : null
  }

  renderProgressBar = () => {
    const { classes = {} } = this.props
    return (
      <Grid
        item
        container
        id="samplesProgressBar"
        xs={12}
        spacing={3}
        justify="center"
        alignItems="center"
      >
        <CircularProgress className={classes.progress} />
      </Grid>
    )
  }

  renderMainHeader = () => {
    const { classes = {} } = this.props
    const { activeStep, selectedLocation = {} } = this.state
    const { label: location = '' } = selectedLocation
    const filterData = this.getFilterData()
    const filterDataCopy = [...filterData]
    const filterDataByStatus = this.setFilterDataCopyByStatus(filterDataCopy)

    return (
      <Grid item xs={12}>
        <Paper className={classes.card}>
          <Grid
            container
            justify="space-between"
            spacing={2}
            alignItems="stretch"
          >
            <Grid item xs={8}>
              <Grid container justify="center" spacing={2} direction={'column'}>
                <Grid
                  item
                  xs={12}
                  className={classes.checkInPageIntro}
                  id="CheckinTitle"
                >
                  {location} {'Sample Check-In'}
                </Grid>
                <Grid item xs={12}>
                  {this.renderStepper(activeStep)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                spacing={2}
                direction={'row'}
                justify={'flex-end'}
              >
                <Grid item>{this.renderStepOneHeader()}</Grid>
                <Grid item>{this.renderNextButton(filterDataByStatus)}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }

  renderPageTitleByStep = () => {
    const steps = this.getSteps()
    const { classes = {}, assignSampleLocation = () => {} } = this.props
    const {
      activeStep,
      selectedLocation = {},
      isSelectAllAssets,
      selectedAssets: stateSelectedAssests = [],
    } = this.state
    const { label: location = '' } = selectedLocation
    const currentStep = steps[activeStep] || null
    const filterData = this.getFilterData()
    // const filterDataCopy = [...filterData]
    // const filterDataByStatus = this.setFilterDataCopyByStatus(filterDataCopy)

    const { ASSIGN_BUILDING = '', ASSIGN_LOCATION = '' } = actions

    switch (currentStep) {
      case ASSIGN_BUILDING: {
        return (
          <Grid
            item
            xs={12}
            container
            justify={'center'}
            alignItems={'center'}
            className={classes.messageArea}
          >
            <Typography variant={'h5'} id="AssignBuilding">
              {`${'By selecting "Next" the following samples will be checked-in to '}`}
              <b>{location}</b>.
            </Typography>
          </Grid>
        )
      }
      case ASSIGN_LOCATION: {
        return filterData.length > 0 ? (
          <>
            <Grid
              item
              xs={12}
              container
              justify={'center'}
              alignItems={'center'}
              className={classes.messageArea}
            >
              <Typography variant={'h5'}>
                {`${'(Optional) To assign samples to a specific warehouse location, select samples then select Assign Location.'}`}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems={'center'}
              justify={'flex-start'}
              spacing={3}
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="selectAllCheckinSamples"
                      color="primary"
                      onChange={this.onSelectAll}
                      checked={!!isSelectAllAssets}
                      classes={{
                        checked: classes.filterCheckboxChecked,
                      }}
                    />
                  }
                  label={'Select All'}
                  className={classes.selectAllControl}
                />
              </Grid>
              <Grid item>
                <Button
                  id="assignToLocation"
                  variant="outlined"
                  color="primary"
                  disabled={!(stateSelectedAssests || []).length}
                  onClick={() => {
                    this.openResolution(
                      assignDetailedLocationNew(
                        stateSelectedAssests,
                        (locationId, locationLabelDetail = {}) => {
                          assignSampleLocation(
                            locationLabelDetail,
                            [...stateSelectedAssests],
                            location,
                            locationId
                          )
                        }
                      )
                    )
                  }}
                  className={classes.assignLocSubLabel}
                >
                  Assign location to selected
                </Button>
              </Grid>
            </Grid>
          </>
        ) : null
      }
      default:
        return true
    }
  }

  renderLocationWiseSectionTitle = (
    filterDataByLocation = {},
    obj = {},
    selectedItems = []
  ) => {
    const {
      classes = {},
      removeSamples = () => {},
      setSamplesLocation = () => {},
    } = this.props
    const { selectedLocation = {} } = this.state
    const { label: location = '' } = selectedLocation

    return (
      <Grid
        container
        item
        xs={12}
        alignItems={'center'}
        justify={'flex-start'}
        className={classes.selectAllArea}
      >
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                id={'resolve_checkbox_' + obj}
                color="primary"
                onChange={() => {
                  this.onSelectAllByStatus(obj, 'Location')
                }}
                checked={
                  !!this.isSelectAllCheckedByLocation(
                    obj,
                    filterDataByLocation[obj]
                  )
                }
                classes={{
                  checked: classes.filterCheckboxChecked,
                }}
              />
            }
            label={obj}
            className={classes.selectAllControl}
          />
        </Grid>
        <Grid item>
          <Button
            id={'resolve_' + obj}
            variant="outlined"
            color="primary"
            disabled={!this.isDisabledResolve(obj)}
            onClick={() => {
              this.openResolution(
                resolveLocations(
                  selectedItems,
                  obj,
                  location,
                  operation,
                  () => {
                    removeSamples(selectedItems)
                  },
                  () => {
                    setSamplesLocation(selectedItems, location)
                  }
                )
              )
            }}
            className={classes.assignLocSubLabel}
          >
            Resolve
          </Button>
        </Grid>
      </Grid>
    )
  }

  renderStatusWiseSectionTitle = (
    filterDataByStatus = {},
    obj = {},
    selectedItems = []
  ) => {
    const {
      classes = {},
      setMultipleSampleStatusShipped = () => {},
      removeSamples = () => {},
    } = this.props
    const { selectedLocation = {} } = this.state
    const { label: location = '' } = selectedLocation

    return (
      <Grid
        container
        item
        xs={12}
        alignItems={'center'}
        justify={'flex-start'}
        className={classes.selectAllArea}
      >
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={() => {
                  this.onSelectAllByStatus(obj, 'status')
                }}
                checked={
                  !!this.isSelectAllCheckedByStatus(
                    obj,
                    filterDataByStatus[obj]
                  )
                }
                classes={{
                  checked: classes.filterCheckboxChecked,
                }}
              />
            }
            label={itemsGroupLabels[obj]}
            className={classes.selectAllControl}
          />
        </Grid>
        <Grid item>
          <Button
            id={'resolve_' + obj}
            variant="outlined"
            color="primary"
            disabled={!this.isDisabledResolve(obj)}
            onClick={() => {
              this.openResolution(
                removeInvalidSampleNew(
                  selectedItems,
                  obj,
                  operation,
                  () => {
                    removeSamples(selectedItems)
                  },
                  () => {
                    setMultipleSampleStatusShipped(selectedItems, location)
                  }
                )
              )
            }}
            className={classes.assignLocSubLabel}
          >
            Resolve
          </Button>
        </Grid>
      </Grid>
    )
  }

  renderStatusWiseSectionHeader = (
    filterDataByStatus = {},
    obj = {},
    selectedItems = []
  ) => {
    const { activeStep } = this.state
    return filterDataByStatus[obj].length > 0 && activeStep === Number(0) ? (
      <Grid item xs={12}>
        {['SAMPLE_CANCELLED', 'SAMPLE_ORDERED'].indexOf(obj) !== -1 ? (
          this.renderStatusWiseSectionTitle(
            filterDataByStatus,
            obj,
            selectedItems
          )
        ) : (
          <Typography variant={'subtitle1'}>{itemsGroupLabels[obj]}</Typography>
        )}
      </Grid>
    ) : null
  }

  renderLocationWiseSectionHeader = (
    filterDataByLocation = {},
    obj = {},
    selectedItems = []
  ) => {
    const { activeStep } = this.state
    const { selectedLocation = {} } = this.state
    const { label: location = '' } = selectedLocation

    const locationsList = Object.keys(filterDataByLocation) || []
    return filterDataByLocation[obj].length > 0 && activeStep === Number(1) ? (
      <Grid item xs={12}>
        {locationsList.indexOf(obj) >= Number(0) && obj !== location
          ? this.renderLocationWiseSectionTitle(
              filterDataByLocation,
              obj,
              selectedItems
            )
          : null}
        {/* <Typography variant={'subtitle1'}>{obj}</Typography> */}
      </Grid>
    ) : null
  }

  renderTabbedPopUp = () => {
    const {
      popupIsVisible,
      popupItem = {},
      classes = {},
      updateSampleFieldsData = () => {},
      closePopup = () => {},
    } = this.props
    const { type: itemType = '' } = popupItem
    const tabsList =
      itemType === PROPS
        ? dataTabLabelsShippedWithProps
        : itemType === SCENIC
          ? dataTabLabelsShippedWithScenic
          : dataTabLabelsShipped
    return (
      <TabbedPopUp
        dataTabLabels={tabsList}
        isVisible={popupIsVisible}
        data={makeTabTables(
          popupItem,
          classes,
          tabsList,
          updateSampleFieldsData,
          {},
          'checkin'
        )}
        onClose={closePopup}
        title={getTitle(popupItem)}
        fullWidth={true}
      />
    )
  }

  render() {
    const {
      barcodeType,
      classes,
      data = [],
      assignLocationRequestPending,
      checkinRequestPending,
      locationRequestPending,
      fetchSamplesRequestPending,
      unknownLocationIds = [],
      printQueue,
      popupIsVisible,
      showPopup = () => {},
      setBarcodeType = () => {},
    } = this.props

    const {
      activeStep,
      selectedAssets: stateSelectedAssests = [],
      openCompleteAlert = false,
      selectedLocation = {},
      resolutionOpen,
      nodes = {},
      barcodeTypePopUpIsVisible,
    } = this.state

    const { label: location = '' } = selectedLocation
    const steps = this.getSteps()
    const filterData = this.getFilterData()
    const assignedSamples = this.getAssignedSamples(activeStep, operation, data)
    const completeAlertTitle = 'Complete sample check-in?'
    const completeAlertTitleStr = (
      <span className={classes.completeAlertTitle}>{completeAlertTitle}</span>
    )
    const completeAlertPBName = 'yes, check-in samples'
    const filterDataCopy = [...filterData]
    const filterDataByStatus = this.setFilterDataCopyByStatus(filterDataCopy)
    const filterDataByLocation = groupBy(filterData, (obj) => obj.shipTo)

    return (
      <>
        <ResolutionTree
          innerRef={this.ResolutionElement}
          open={resolutionOpen}
          onClose={this.closeResolution}
          startNode={this.state.startNode}
          nodes={nodes}
          userLocation={location}
          operation={operation}
        />
        <Grid container spacing={4} justify="center" alignItems="center">
          {this.renderMainHeader()}
          {this.renderSearchSamples()}
          {this.renderPageTitleByStep()}
          {(assignLocationRequestPending ||
            locationRequestPending ||
            checkinRequestPending ||
            fetchSamplesRequestPending) &&
            this.renderProgressBar()}
          <Grid
            item
            container
            id="samplesLabelSection"
            xs={12}
            spacing={2}
            justify="flex-start"
            alignItems="stretch"
          >
            {activeStep === 0 && !isEmpty(unknownLocationIds)
              ? ''
              : activeStep === 0 && filterData.length > 0
                ? this.renderClearAll()
                : ''}
            {(steps[activeStep] === actions.ASSIGN_LOCATION ||
              steps[activeStep] === actions.SCAN) &&
              Object.keys(filterDataByStatus).map((obj) => {
                const { [obj]: { selectedAssets = [] } = {} } = this.state
                const selectedItems = filterDataByStatus[obj].filter(
                  (obj) => selectedAssets.indexOf(obj.pegasusId) !== -1
                )
                return (
                  <>
                    {this.renderStatusWiseSectionHeader(
                      filterDataByStatus,
                      obj,
                      selectedItems
                    )}
                    <Grid item container spacing={2} xs={12}>
                      {filterDataByStatus[obj].map((item) => {
                        const statusBar = this.setSampleStatusBar(
                          item,
                          item.checkInStatus,
                          activeStep
                        )
                        const isAssetChecked =
                          (stateSelectedAssests || []).indexOf(
                            item.pegasusId
                          ) !== -1
                        return (
                          <Grid
                            item
                            key={item.id}
                            id={'checkin_' + item.pegasusId}
                          >
                            <SampleDetailCard
                              variant={statusBar[4]}
                              sample={item}
                              menuOptions={this.getCardActions(
                                activeStep,
                                item
                              )}
                              titleAction={null}
                              cardActionsList={
                                steps[activeStep] === actions.ASSIGN_LOCATION ||
                                (steps[activeStep] === actions.SCAN &&
                                  statusBar[0] === obj) ? (
                                  <Checkbox
                                    onChange={(event) =>
                                      activeStep === 0
                                        ? this.onAssetSelectByStatus(
                                            event.target.checked,
                                            item,
                                            statusBar[0],
                                            'Status'
                                          )
                                        : this.onAssetSelect(
                                            event.target.checked,
                                            item
                                          )
                                    }
                                    checked={
                                      activeStep === 0
                                        ? this.isAssetCheckedByStatus(
                                            item.pegasusId,
                                            statusBar[0]
                                          )
                                        : isAssetChecked
                                    }
                                    classes={{
                                      checked: classes.filterCheckboxChecked,
                                    }}
                                  />
                                ) : null
                              }
                              status={statusBar[0]}
                              statusIcon={statusBar[1]}
                              action={statusBar[2]}
                              actionOnClick={statusBar[3]}
                              showPopup={showPopup}
                              extraIcon={
                                activeStep === 0 &&
                                printQueue.includes(item.pegasusId) ? (
                                  <span>
                                    <IconButton
                                      aria-label="Print Label"
                                      className={classes.searchButton}
                                      onClick={() => {
                                        this.printBarcodesSingle(item.pegasusId)
                                      }}
                                    >
                                      <Print />
                                    </IconButton>
                                  </span>
                                ) : null
                              }
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </>
                )
              })}
            {steps[activeStep] === actions.ASSIGN_BUILDING &&
              Object.keys(filterDataByLocation).map((obj) => {
                const { [obj]: { selectedAssets = [] } = {} } = this.state
                const selectedItems = filterDataByLocation[obj].filter(
                  (obj) => selectedAssets.indexOf(obj.pegasusId) !== -1
                )
                return (
                  <>
                    {this.renderLocationWiseSectionHeader(
                      filterDataByLocation,
                      obj,
                      selectedItems
                    )}
                    <Grid item container spacing={2} xs={12}>
                      {filterDataByLocation[obj].map((item) => {
                        const statusBar = this.setSampleStatusBar(
                          item,
                          item.checkInStatus,
                          activeStep
                        )
                        const isAssetChecked =
                          (stateSelectedAssests || []).indexOf(
                            item.pegasusId
                          ) !== -1
                        return (
                          <Grid
                            item
                            key={item.id}
                            id={'checkin_' + item.pegasusId}
                          >
                            <SampleDetailCard
                              variant={statusBar[4]}
                              sample={item}
                              menuOptions={this.getCardActions(
                                activeStep,
                                item
                              )}
                              titleAction={null}
                              cardActionsList={
                                statusBar[0] === obj && obj !== location ? (
                                  <Checkbox
                                    onChange={(event) =>
                                      activeStep === 1
                                        ? this.onAssetSelectByStatus(
                                            event.target.checked,
                                            item,
                                            statusBar[0],
                                            'Location'
                                          )
                                        : this.onAssetSelect(
                                            event.target.checked,
                                            item
                                          )
                                    }
                                    checked={
                                      activeStep === 1
                                        ? this.isAssetCheckedByStatus(
                                            item.pegasusId,
                                            statusBar[0]
                                          )
                                        : isAssetChecked
                                    }
                                    classes={{
                                      checked: classes.filterCheckboxChecked,
                                    }}
                                  />
                                ) : null
                              }
                              status={statusBar[0]}
                              statusIcon={statusBar[1]}
                              action={statusBar[2]}
                              actionOnClick={statusBar[3]}
                              showPopup={showPopup}
                              extraIcon={
                                activeStep === 0 &&
                                printQueue.includes(item.pegasusId) ? (
                                  <span>
                                    <IconButton
                                      aria-label="Print Label"
                                      className={classes.searchButton}
                                      onClick={() => {
                                        this.printBarcodesSingle(item.pegasusId)
                                      }}
                                    >
                                      <Print />
                                    </IconButton>
                                  </span>
                                ) : null
                              }
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </>
                )
              })}
          </Grid>
          {steps[activeStep] === actions.ASSIGN_LOCATION &&
            assignedSamples.length > 0 && (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  justify={'center'}
                  alignItems={'center'}
                  className={classes.assignMessageArea}
                >
                  <Typography variant={'h5'}>{'Assigned Samples'}</Typography>
                </Grid>
                <Grid
                  item
                  container
                  id="sampleCardsList"
                  xs={12}
                  spacing={3}
                  justify="flex-start"
                  alignItems="stretch"
                >
                  {(assignLocationRequestPending ||
                    locationRequestPending ||
                    checkinRequestPending ||
                    fetchSamplesRequestPending) && (
                    <CircularProgress className={classes.progress} />
                  )}
                  {assignedSamples.map((item) => {
                    const statusBar = this.setSampleStatusBar(
                      item,
                      item.checkInStatus,
                      activeStep
                    )
                    return (
                      <Grid item key={item.id} id={'checkin_' + item.pegasusId}>
                        <SampleDetailCard
                          sample={item}
                          titleAction={null}
                          cardActionsList={null}
                          status={statusBar[0]}
                          statusIcon={statusBar[1]}
                          action={statusBar[2]}
                          actionOnClick={statusBar[3]}
                          extraIcon={null}
                          showPopup={showPopup}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </>
            )}
        </Grid>
        {!!popupIsVisible && this.renderTabbedPopUp()}
        <BarcodeTypePopUp
          isVisible={barcodeTypePopUpIsVisible}
          onClose={() => this.setState({ barcodeTypePopUpIsVisible: false })}
          setterCallback={(barcodeType) => setBarcodeType(barcodeType)}
          barcodeType={barcodeType}
        />
        <PopUp
          title={completeAlertTitleStr}
          isButtonVisible={true}
          onProceed={this.handleNext}
          isVisible={openCompleteAlert}
          onClose={this.handleCompleteAlert}
          primaryButtonText={completeAlertPBName}
          maxWidth="xs"
          fullWidth={true}
          secondaryButtonText="not yet"
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    barcodeType: state.user.barcodeType,
    columnData: state.checkIn.columnData,
    data: state.checkIn.data,
    checkinRequestPending: state.checkIn.checkinRequestPending,
    assignLocationRequestPending: state.checkIn.assignLocationRequestPending,
    locationRequestPending: state.checkIn.locationRequestPending,
    fetchSamplesRequestPending: state.checkIn.fetchSamplesRequestPending,
    unknownPegasusIds: state.checkIn.unknownPegasusIds,
    unknownLocationIds: state.checkIn.unknownLocationIds,
    location: state.user.location,
    popupIsVisible: state.checkIn.popupIsVisible,
    popupItem: state.checkIn.item,
    printQueue: state.checkIn.printQueue,
    relatedSamples: state.checkIn.relatedSamples,
    selectedData: state.checkIn.selectedData,
    showPopup: state.checkIn.showPopup,
    previousPage: state.checkIn.previousPage,
    userLocation: state.user.location,
    isFetchNoSamples: state.checkIn.isFetchNoSamples,
    fetchedLocationData: state.checkIn.fetchedLocationData,
    assignedSamplesSuccess: state.checkIn.assignedSamplesSuccess,
    locations: state.settings.checkInLocations,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addToPrintQueue: (id) => dispatch(addSampleToPrintQueue(id)),
    removeToPrintQueue: (id) => dispatch(removeSampleFromPrintQueue(id)),
    removeSampleFromPrintQueueAll: () =>
      dispatch(removeSampleFromPrintQueueAll()),
    assignSampleLocation: (
      locationLabelDetail = {},
      samples = [],
      location = '',
      locationId = '',
      searchedLocations = [],
      moveType = 'S',
      callBack = () => {}
    ) =>
      dispatch(
        assignLocation(
          locationLabelDetail,
          samples,
          location,
          locationId,
          operation,
          searchedLocations,
          moveType,
          callBack
        )
      ),
    clearCheckinData: () => {
      dispatch(clearCheckinData())
    },
    closePopup: () => dispatch(closePopup()),
    cloneSample: (sample) => dispatch(cloneSample(sample)),
    fetchSamples: (pgIds, moveType = '', location = '') => {
      dispatch(validateArgs(pgIds, operation, moveType, location))
    },
    getCheckinLocation: dispatch(getCheckinLocation()),
    getRelatedSamples: (pegasusId, shipTo) =>
      dispatch(getRelatedSamples(pegasusId.slice(2, pegasusId.length), shipTo)),
    checkIn: (data, location) => {
      dispatch(checkinSamples(data, location))
    },
    onHandleReset: (isShown, message) => {
      // dispatch(showNotification(isShown, message))
    },
    handleChipClick: (chip) => {
      copy(chip.target.innerText)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    printBarcodes: (ids, type) => dispatch(getBarcodes(ids, type)),
    printAllBarcodes: (ids, type) => dispatch(printAllBarcodes(ids, type)),
    removeSample: (id) => dispatch(removeSample(id)),
    removeSamples: (idList = []) => dispatch(removeSamples(idList)),
    setSampleLocation: (sample, location) =>
      dispatch(setSampleLocation(sample, location, operation)),
    setSamplesLocation: (samples = [], location) =>
      dispatch(setSamplesLocation(samples, location, operation)),
    setSampleStatusShipped: (sample, location) =>
      dispatch(setSampleStatusShipped(sample, location, operation)),
    setMultipleSampleStatusShipped: (sampleList = [], location) =>
      dispatch(setMultipleSampleStatusShipped(sampleList, location, operation)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    updateSampleId: (existingId, updatedId, location = '') =>
      dispatch(updateSampleId(existingId, updatedId, location, operation)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setPreviousPage: (page) => dispatch(setPreviousPage(page)),
    removeUnknownSampleFromList: (sampleId = '', type = '') =>
      dispatch(removeUnknownSampleFromList(sampleId, type)),
    removeUnknownLocationFromList: () =>
      dispatch(removeUnknownLocationFromList()),
    removeDuplicateSampleFromList: (sampleId = '', type = '') =>
      dispatch(removeDuplicateSampleFromList(sampleId, type)),
    setBarcodeType: (barcodeType) => dispatch(setBarcodeType(barcodeType)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
    getCheckInLocations: () => dispatch(getCheckInLocations()),
    checkSampleStatusesOnNext: (ids = [], location = '', callBack = () => {}) =>
      dispatch(checkSampleStatusesOnNext(ids, location, operation, callBack)),
  }
}

CheckInPage.propTypes = {
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  data: propTypes.array,
  assignLocationRequestPending: propTypes.bool,
  locationRequestPending: propTypes.bool,
  checkinRequestPending: propTypes.bool,
  fetchSamplesRequestPending: propTypes.bool,
  location: propTypes.string,
}
export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(CheckInPage)
