import * as types from './actionType'
import { hasFields } from '../../util/Filter'

export const initialState = {
  columnData: [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Project Name',
      align: 'center',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Project Creator',
      align: 'center',
    },
    {
      id: 'createDate',
      numeric: false,
      disablePadding: false,
      label: 'Created Date',
      align: 'center',
      type: 'date',
    },
    {
      id: 'channel',
      numeric: false,
      disablePadding: false,
      label: 'Channel',
      align: 'center',
    },
    {
      id: 'locationName',
      numeric: false,
      disablePadding: false,
      label: 'Photoshoot Location',
      align: 'center',
    },
    {
      id: 'shootStartDate',
      numeric: false,
      disablePadding: false,
      label: 'Start Date',
      align: 'center',
      type: 'date',
    },
    {
      id: 'shootEndDate',
      numeric: false,
      disablePadding: false,
      label: 'End Date',
      align: 'center',
      type: 'date',
    },
    {
      id: 'sampleCount',
      numeric: false,
      disablePadding: false,
      label: 'Samples',
      align: 'center',
    },
  ],
  order: 'asc',
  orderBy: 'id',
  page: 0,
  projectLocation: [],
  rowsPerPage: 25,
  createProjectRequest: {
    name: '',
    type: '',
    marketingPid: '',
    notes: '',
    shootStartDate: null,
    shootEndDate: null,
  },
  projectTypes: ['', 'ISM', 'TCIN', 'CGI', 'COLLECTION'],
  projects: [],
  sampleProjectData: new Map(),
  filteredProjects: [],
  filterText: '',
  getProjectsPending: false,
  getProjectSamplesFailure: false,
  getProjectSamplesPending: false,
  createProjectPending: false,
  createProjectPopupVisible: false,
  getBuildingsPending: false,
  getBuildingsFailure: false,
  tabValue: '',
  sampleCount: [],
  filterValue: '',
  requestSamplesStatus: '',
  flyoutOpen: false,
  editSuccess: false,
}

export default function projects(state = initialState, action) {
  switch (action.type) {
    case types.PROJECTS_SHOW_CREATE_POPUP: {
      return Object.assign({}, state, {
        createProjectPopupVisible: true,
      })
    }
    case types.PROJECTS_CLOSE_CREATE_POPUP: {
      return Object.assign({}, state, {
        createProjectPopupVisible: false,
        createProjectRequest: {
          name: '',
          type: '',
          marketingPid: '',
          notes: '',
          shootStartDate: null,
          shootEndDate: null,
        },
      })
    }
    case types.PROJECTS_REQUEST_PENDING: {
      return Object.assign({}, state, {
        getProjectsPending: action.payload.pending,
      })
    }
    case types.PROJECTS_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        projects: action.payload.projects,
        filteredProjects: action.payload.projects,
      })
    }
    case types.CURRENT_PROJECT_REQUEST_SUCCESS: {
      const { projects: existingProjects = [] } = state
      const {
        payload: { projects: currentProject = {} },
      } = action
      const { id: currentProjectID = '' } = currentProject
      const listWOCurrentProject = existingProjects.filter(
        (obj) => obj.id !== currentProjectID
      )
      return Object.assign({}, state, {
        projects: [currentProject, ...listWOCurrentProject],
        filteredProjects: [currentProject, ...listWOCurrentProject],
      })
    }
    case types.BUILDINGS_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        projectLocation: action.payload.data.data.buildings,
      })
    }

    case types.BUILDINGS_REQUEST_PENDING:
      return Object.assign({}, state, {
        getBuildingsPending: action.payload.pending,
      })

    case types.BUILDINGS_REQUEST_FAILURE:
      return Object.assign({}, state, {
        getBuildingsFailure: true,
      })
    case types.PROJECTS_SET_FILTER: {
      return Object.assign({}, state, {
        filterText: action.payload.filter,
        filteredProjects: state.projects.filter((i) =>
          hasFields(i, action.payload.filter)
        ),
      })
    }
    case types.PROJECTS_CLEAR_FILTER: {
      return Object.assign({}, state, {
        filterText: '',
        filteredProjects: state.projects,
      })
    }
    case types.PROJECTS_UPDATE_CREATE_FIELD: {
      return Object.assign({}, state, {
        createProjectRequest: Object.assign({}, state.createProjectRequest, {
          [action.payload.field]: action.payload.value,
        }),
      })
    }
    case types.PROJECT_GET_SAMPLES_SUCCESS: {
      let newSampleProjectData = new Map(state.sampleProjectData)
      newSampleProjectData.set(
        action.payload.projectId.toString(),
        action.payload.data
      )
      return Object.assign({}, state, {
        getProjectSamplesFailure: false,
        sampleProjectData: newSampleProjectData,
      })
    }
    case types.PROJECT_GET_SAMPLES_FAILURE: {
      return Object.assign({}, state, {
        getProjectSamplesFailure: true,
      })
    }
    case types.PROJECT_GET_SAMPLES_PENDING: {
      return Object.assign({}, state, {
        getProjectSamplesPending: action.payload.pending,
      })
    }
    case types.SET_TABVALUE: {
      return Object.assign({}, state, {
        tabValue: action.payload.tabValue,
      })
    }
    case types.SET_SAMPLE_PROJECTS_VALUE: {
      return Object.assign({}, state, {
        ...state,
        sampleCount: action.payload.sampleCount,
      })
    }
    case types.SET_PROJECTS_SEARCH_VALUE: {
      const {
        payload: { filterValue = '' },
      } = action
      return Object.assign({}, state, {
        ...state,
        filterValue,
      })
    }
    case types.SET_REQUEST_SAMPLES_STATUS: {
      return Object.assign({}, state, {
        ...state,
        requestSamplesStatus: action.payload.requestSamplesStatus,
      })
    }
    case types.EDIT_FLYOUT_OPEN: {
      return Object.assign({}, state, {
        ...state,
        flyoutOpen: !state.flyoutOpen,
      })
    }
    case types.EDIT_UPDATE_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        editSuccess: action.payload,
      })
    }
    default:
      return state
  }
}
