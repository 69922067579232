import React from 'react'
import classnames from 'classnames'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { default as _ } from 'lodash'
import MoreVert from '@material-ui/icons/MoreVert'
import MenuList from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const styles = (theme) => ({
  card: {
    maxWidth: 405,
    height: '100%',
    display: 'flex',
    flexFlow: 'column wrap',
  },
  cardHeader: {
    alignItems: 'flex-start !important',
    padding: '16px 16px 0',
  },
  errorCard: {
    maxWidth: 405,
    height: '100%',
    display: 'flex',
    flexFlow: 'column wrap',
    border: '2px solid #B85300',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
})

class ExpandableCard extends React.Component {
  state = {
    expanded: false,
    anchorEl: null,
  }

  handleExpandClick = () => {
    this.setState((state) => ({
      ...state,
      expanded: !state.expanded,
    }))
  }

  handleOpenMenu = (event) => {
    // stored as const to prevent synthetic event from becoming null
    const anchorEl = event.currentTarget
    this.setState((state) => ({
      ...state,
      anchorEl: anchorEl,
    }))
  }

  handleCloseMenu = () => {
    this.setState((state) => ({
      ...state,
      anchorEl: null,
    }))
  }

  handleMenuSelect = (action) => {
    this.handleCloseMenu(action())
  }

  render() {
    const {
      classes,
      title,
      subtitle,
      cardMedia,
      content,
      expandedContent,
      actionBar,
      menuOptions,
      raised,
      titleAction,
      isError,
      avatar = '',
    } = this.props

    const anchorEl = this.state.anchorEl
    const open = Boolean(anchorEl)

    return (
      <Card
        className={isError ? classes.errorCard : classes.card}
        raised={raised}
      >
        <CardHeader
          avatar={avatar}
          title={title}
          subheader={subtitle}
          className={classes.cardHeader}
          action={
            titleAction === null ? (
              <IconButton
                aria-label="More"
                aria-owns={open ? 'props-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleOpenMenu}
              >
                {menuOptions && <MoreVert />}
              </IconButton>
            ) : (
              titleAction
            )
          }
        />
        {menuOptions && (
          <MenuList
            id="props-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleCloseMenu}
          >
            {menuOptions.map((option) => (
              <MenuItem
                key={option.label}
                onClick={() => {
                  this.handleMenuSelect(option.action)
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        )}
        <CardContent id={'cardContent'}>{content}</CardContent>
        {cardMedia}
        {!_.isEmpty(expandedContent) && (
          <Collapse in={this.state.expanded} timeout={'auto'} unmountOnExit>
            <CardContent>{expandedContent}</CardContent>
          </Collapse>
        )}
        {!_.isEmpty(expandedContent) && (
          <CardActions>
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
        )}
        {actionBar}
      </Card>
    )
  }
}

ExpandableCard.defaultProps = {
  cardMedia: null,
  subtitle: '',
  titleAction: null,
  isError: false,
}

ExpandableCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  titleAction: PropTypes.object,
  cardMedia: PropTypes.object,
  content: PropTypes.object.isRequired,
  expandedContent: PropTypes.object,
  actionBar: PropTypes.object,
  menuOptions: PropTypes.array,
  raised: PropTypes.bool,
  isError: PropTypes.bool,
}

export default withStyles(styles)(ExpandableCard)
