import { useState, memo } from 'react'
import Popover from '@mui/material/Popover'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

/**
 * @param {Children} React child component
 * @param {PopoverTriggerComponent} React component for trigger popup
 * @returns React component with Popover with anchor
 */
function PopoverWithAnchor({
  PopoverTriggerComponent,
  children,
  uniqueId = '',
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? `simple-popover${uniqueId}` : undefined

  if (!PopoverTriggerComponent) {
    PopoverTriggerComponent = () => (
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
    )
  }

  return (
    <>
      <PopoverTriggerComponent onClick={handleClick} />
      <Popover
        disableEnforceFocus
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </>
  )
}

const areEqual = (prevProps, nextProps) => {
  if (prevProps?.uniqueId === nextProps?.uniqueId) {
    return true
  }
  return false
}

export default memo(PopoverWithAnchor, areEqual)
