import { PureComponent } from 'react'
import FlexibleTable from '../EnhancedTable/FlexibleTable'

class ShipToLocation extends PureComponent {
  render() {
    const {
      columnData = [],
      data = [],
      downloadLocations = () => {},
    } = this.props

    return (
      <FlexibleTable
        cascadeFieldKeys={[]}
        checkBoxEnabled={false}
        columnData={columnData}
        data={data}
        uniqueIdentifierInData={'locationId'}
        dataFieldKeys={[]}
        editEnabled={false}
        enableSearch
        enableDownload={true}
        downloadFunction={downloadLocations}
        showPopup={() => {}}
        tableTitle={'Ship to location'}
        disableTablePagination={true}
        disableItemCount={true}
        additionalActions={true}
        additionalFiltersContent={() => {}}
      />
    )
  }
}

export default ShipToLocation
