import * as vendorsAPI from '../../services/vendorsService'
import * as projectsAPI from '../../services/projectsService'
import * as projectMapper from '../../mappers/projectMapper'
import * as types from './actionType'

export function vendorsEmailResponse(response) {
  const map = new Map(
    response.vendors?.map((vendor) => [vendor.vendor_id, vendor.emails])
  )
  return map
}

export function businessPartnerResponse(response) {
  const map = new Map(
    response.vendors?.map((vendor) => {
      if (process.env.REACT_APP_ENV !== 'prod') {
        vendor.bp_ids = [
          {
              "bp_id": 1763,
              "bp_name": "Jazzies LLC"
          },
          {
              "bp_id": 4225,
              "bp_name": "Jazwares Inc."
          },
          {
              "bp_id": 10688,
              "bp_name": "Jazzman Sportswear"
          },
          {
              "bp_id": 20711,
              "bp_name": "JAZ Foods, Inc"
          }
        ]
      }
      const bpIds =  Array.from(new Set([...(vendor?.bp_ids || [])]))
      return [vendor.vendor_id, bpIds]
    })
  )
  return map
}

export const getBPIDS = (searchText = '', callback = () => {}) => async (dispatch, getState) => {
  dispatch(fetchBusinessPartnerIDsPending(true))
  return vendorsAPI.fetchBPIDs(searchText)
    .then((response = {}) => {
      callback(response)
    })
    .catch((error) => {
      console.log('enter while searching venderbpids',error)
    })
    .finally(() => {
      dispatch(fetchBusinessPartnerIDsPending(false))
    })
}

export function getVendorEmailsById(ids = []) {
  return (dispatch) => {
    dispatch(fetchBusinessPartnerRequestPending(true))
    return vendorsAPI
      .getVendorEmailsById(ids)
      .then((response) => ({ "vmmemails": vendorsEmailResponse(response.data), "bpids": businessPartnerResponse(response.data)}))
      .then((response = []) => {
        dispatch(fetchBusinessPartnerSuccess(response))
      })
      .catch(() => {
        dispatch(fetchBusinessPartnerFailure())
      })
      .finally(() => {
        dispatch(fetchBusinessPartnerRequestPending(false))
      })
  }
}

export function setSampleData(samples = []) {
  return {
    type: types.ORDER_PAGE_SET_SAMPLES,
    payload: { samples: samples },
  }
}

export function fetchBusinessPartnerSuccess(businessPartners = {}) {
  return {
    type: types.FETCH_BUSINESS_PARTNER_SUCCESS,
    payload: { businessPartners: businessPartners },
  }
}

export function fetchBusinessPartnerReset() {
  return {
    type: types.FETCH_BUSINESS_PARTNER_RESET,
  }
}

export function fetchBusinessPartnerFailure() {
  return {
    type: types.FETCH_BUSINESS_PARTNER_FAILURE,
    payload: { error: 'Failed to fetch vendor email addresses' },
  }
}

export function fetchBusinessPartnerRequestPending(pending = false) {
  return {
    type: types.FETCH_BUSINESS_PARTNER_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function fetchBusinessPartnerIDsPending(pending = false) {
  return {
    type: types.FETCH_BUSINESS_PARTNER_ID_PENDING,
    payload: { pending: pending },
  }
}

export function submitOrderRequestPending(pending = false) {
  return {
    type: types.ORDER_PAGE_SUBMIT_ORDER_PENDING,
    payload: { pending: pending },
  }
}

export function submitOrderRequestSuccess(orderResponse = []) {
  return {
    type: types.ORDER_PAGE_SUBMIT_ORDER_SUCCESS,
    payload: { samples: orderResponse },
  }
}

export function submitOrderRequestFailure() {
  return {
    type: types.ORDER_PAGE_SUBMIT_ORDER_FAILURE,
  }
}

export function getProjects() {
  return (dispatch) => {
    dispatch(fetchProjectsRequestPending(true))
    return projectsAPI
      .getAllProjects()
      .then((result) =>
        projectMapper.projectResponseToProjects(result.data.projects)
      )
      .then((projects) => {
        dispatch(fetchProjectsRequestSuccess(projects))
      })
      .catch(() => {
        dispatch(fetchProjectsRequestFailure())
      })
      .finally(() => {
        dispatch(fetchProjectsRequestPending(false))
      })
  }
}

export function fetchProjectsRequestSuccess(data = []) {
  return {
    type: types.FETCH_PROJECTS_SUCCESS,
    payload: { data: data },
  }
}

export function fetchProjectsRequestFailure() {
  return {
    type: types.FETCH_PROJECTS_FAILURE,
  }
}

export function fetchProjectsRequestPending(pending = false) {
  return {
    type: types.FETCH_PROJECTS_PENDING,
    payload: { pending: pending },
  }
}
export function handleFieldUpdate(field, value, tcin, isOrderfromProject) {
  return {
    type: types.ORDER_PAGE_SET_FIELD_VALUE,
    payload: {
      field: field,
      value: value,
      tcin: tcin,
      isOrderfromProject: isOrderfromProject,
    },
  }
}

export function setCascade(event, isOrderfromProject) {
  return {
    type: types.ORDER_PAGE_SET_CASCADE,
    payload: { event: event, isOrderfromProject },
  }
}

export function handleBulkUpdate(editObj = {}, selectedData = []) {
  return {
    type: types.ORDER_PAGE_SET_BULK_VALUES,
    payload: { editObj, selectedData },
  }
}

export function resetOrderState() {
  return {
    type: types.RESET_ORDER_STATE,
  }
}

export function setTabvalue(tabValue = '') {
  return {
    type: types.SET_ORDERPAGE_TABVALUE,
    payload: { tabValue: tabValue },
  }
}

export function setOrderSamplesFrom(pagename = '') {
  return {
    type: types.SET_ORDERSAMPLES_FROM,
    payload: { orderSamplesFrom: pagename },
  }
}

export function setCheckBox(selected) {
  return {
    type: types.REVIEW_ORDER_SET_CHECKBOX,
    payload: { selected: selected },
  }
}

export function emptySelectedData() {
  return {
    type: types.REVIEW_ORDER_EMPTY_SELECTED_DATA,
  }
}

export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.REVIEW_ORDER_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}
export const updateSelectedColumnData = (
  columnData = [],
  iconClick = false,
  keyName = ''
) => {
  return {
    type: types.UPDATE_SELECTED_COLUMN_DATA,
    payload: { data: columnData, iconClick: iconClick, keyName: keyName },
  }
}
export const updateSelectedColumnInfo = (columnData = []) => {
  return {
    type: types.UPDATE_SELECTED_COLUMN_INFO,
    payload: { data: columnData },
  }
}

export const setMouseLocation = (mouseLocationX = '', mouseLocationY = '') => {
  return {
    type: types.SET_MOUSE_LOCATIOIN,
    payload: { xLocation: mouseLocationX, yLocation: mouseLocationY },
  }
}
