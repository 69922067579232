import React from 'react'
import * as propTypes from 'prop-types'
import withHOCs from 'util/withHocs'
import { isEqual, capitalize, isEmpty } from 'lodash'
import {
  Button,
  Paper,
  Grid,
  SwipeableDrawer,
  Typography,
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import BlockIcon from '@material-ui/icons/Block'
import Clear from '@material-ui/icons/Clear'
import AddIcon from '@material-ui/icons/Add'
import GetApp from '@material-ui/icons/GetApp'
import CustomTabs from '../../components/Tabs/Tabs'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import CreatableSelect from '../../components/ReactSelect/CreatableSelect'
import {
  getCollections,
  downloadAllCollectionSamples,
  setCheckBox,
  setCheckBoxHeaderChange,
  updateCollectionStatus,
  setCollectionTabvalue,
  setCreatorValue,
  setLocationValue,
  saveCollectionSearchValue,
  downloaCollectionExcel,
} from '../../store/collections/actionCreator'
import {
  createShootType,
  getShootType,
} from '../../store/newShootType/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { ShootTypes } from '../../enums/shootTypes'
import {
  collectionTabLabels,
  defaultCollectionsTab,
  STATUS_COMPLETED,
  STATUS_CANCELLED,
} from '../../enums/Tabs'
import { selectPageFilters } from '../../store/filters/selectors'
import { setFilters } from '../../store/filters/actionCreator'
import BottomBar from 'components/BottomBar'

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  pageHeader: {
    width: '100%',
    height: '40px',
    position: 'relative',
  },
  pageHeaderButton: {
    display: 'inline',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  add: {
    marginRight: '10px',
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'grid',
    overflowX: 'auto',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  addFilter: {
    width: 'auto',
  },
  getFilterClear: {
    cursor: 'pointer',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    height: '60px',
    alignItems: 'center',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
  },
  toolBar: {
    display: 'flex',
    marginLeft: '100px',
  },
  defaultButtonSD: {
    maxWidth: '250px',
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
})

class CollectionsPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      filters: propsFilter = {},
      tabValue = '',
      creatorValue = {},
      locationValue = {},
    } = this.props
    this.state = {
      tooltipIsOpen: false,
      currentTabValue: tabValue,
      filters: propsFilter,
      creatorSelectedValue: creatorValue,
      locationSelectedValue: locationValue,
    }
  }

  componentDidMount() {
    const {
      setHeaderTitleArray,
      setFilters = () => {},
      setCollectionTabvalue = () => {},
      setLocationValue = () => {},
      setCreatorValue = () => {},
    } = this.props
    setHeaderTitleArray([{ title: 'Collections', link: '/collections' }])
    setFilters({}, 'collection')
    setCollectionTabvalue('')
    setLocationValue({})
    setCreatorValue({})
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentTabValue = '', filters = {} } = this.state
    const { getCollections = () => {} } = this.props
    const {
      currentTabValue: prevCurrentTabValue = '',
      filters: prevFilters = {},
    } = prevState
    if (
      !isEqual(currentTabValue, prevCurrentTabValue) ||
      !isEqual(filters, prevFilters)
    ) {
      getCollections(filters)
    }
  }

  componentWillUnmount() {
    const locationHref = window.location.href
    if (locationHref.indexOf('collections') === -1) {
      this.saveSearchValue('')
    }
  }

  setCurrentTabValue = (currentTab = '') => {
    const {
      filters: stateFilters = {},
      creatorSelectedValue: stateCreatorsValue = {},
      locationSelectedValue: stateLocationValue = {},
    } = this.state
    const {
      onCheckBoxHeaderChange = () => {},
      filters: propsFilters = {},
      creatorValue = {},
      locationValue = {},
    } = this.props
    if (!propsFilters['created_by']) {
      delete stateFilters['created_by']
    }
    if (!propsFilters['location_id']) {
      delete stateFilters['location_id']
    }
    onCheckBoxHeaderChange()
    this.setState({
      currentTabValue: currentTab,
      filters: Object.assign({}, stateFilters, {
        status: currentTab,
      }),
      creatorSelectedValue: !isEmpty(creatorValue)
        ? Object.assign({}, stateCreatorsValue)
        : {},
      locationSelectedValue: !isEmpty(locationValue)
        ? Object.assign({}, stateLocationValue)
        : {},
    })
  }

  saveFilters() {
    const {
      setFilters,
      setCollectionTabvalue = () => {},
      setCreatorValue = () => {},
      setLocationValue = () => {},
    } = this.props
    const {
      filters = {},
      currentTabValue = '',
      creatorSelectedValue = {},
      locationSelectedValue = {},
    } = this.state
    const filterCopy = Object.assign({}, filters)
    const creatorCopy = Object.assign({}, creatorSelectedValue)
    const locationCopy = Object.assign({}, locationSelectedValue)
    if (!isEmpty(filterCopy)) {
      setFilters(filterCopy, 'collection')
      if (!isEmpty(creatorCopy)) {
        setCreatorValue(creatorCopy)
      }
      if (!isEmpty(locationCopy)) {
        setLocationValue(locationCopy)
      }
    }
    if (!isEmpty(currentTabValue)) {
      setCollectionTabvalue(currentTabValue)
    }
  }
  setFilters = (filters = {}) => {
    const { filters: stateFilters = {} } = this.state
    this.setState({
      filters: Object.assign({}, stateFilters, filters),
    })
  }

  handleCreatorChange = (event, actionMeta = {}) => {
    const { value: currentValue = '' } = event
    this.setState({
      creatorSelectedValue: event,
    })
    this.setFilters({ created_by: currentValue })
  }
  handleLocationChange = (event, actionMeta = {}) => {
    const { value: currentValue = '' } = event
    this.setState({
      locationSelectedValue: event,
    })
    this.setFilters({ location_id: currentValue })
  }

  onCreatorFilterClear = (event) => {
    this.setState({
      creatorSelectedValue: {},
    })
    this.setFilters({ created_by: '' })
  }

  onLocationFilterClear = (event) => {
    this.setState({
      locationSelectedValue: {},
    })
    this.setFilters({ location_id: '' })
  }

  additionalFiltersContent = () => {
    const {
      classes = {},
      collectionCreators = [],
      collectionLocations = [],
    } = this.props
    const {
      onCreatorFilterClear = () => {},
      onLocationFilterClear = () => {},
    } = this
    const { creatorSelectedValue = {}, locationSelectedValue = {} } = this.state
    return (
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item data-cy="collectionCreator">
          <CreatableSelect
            distinct={collectionCreators}
            classes={classes}
            selectedValue={creatorSelectedValue}
            handleDropdownChange={this.handleCreatorChange}
            isCreatable={false}
            componentLabel={
              !isEmpty(creatorSelectedValue)
                ? creatorSelectedValue.label
                : 'Collection Creator'
            }
            isNewOption={false}
            buttonVariant={'text'}
            startIcon={<FilterListIcon />}
            endIcon={
              !isEmpty(creatorSelectedValue) ? (
                <Clear
                  className={classes.getFilterClear}
                  onClick={(event) => {
                    onCreatorFilterClear()
                  }}
                />
              ) : undefined
            }
            // isMulti
          />
        </Grid>
        <Grid item data-cy="collectionLocation">
          <CreatableSelect
            distinct={collectionLocations}
            classes={classes}
            selectedValue={locationSelectedValue}
            handleDropdownChange={this.handleLocationChange}
            isCreatable={false}
            componentLabel={
              !isEmpty(locationSelectedValue)
                ? locationSelectedValue.label
                : 'Collection Location'
            }
            isNewOption={false}
            buttonVariant={'text'}
            startIcon={<FilterListIcon />}
            endIcon={
              !isEmpty(locationSelectedValue) ? (
                <Clear
                  className={classes.getFilterClear}
                  onClick={(event) => {
                    onLocationFilterClear()
                  }}
                />
              ) : undefined
            }
            // isMulti
          />
        </Grid>
      </Grid>
    )
  }

  handleTooltipOpen = () => {
    this.setState({ tooltipIsOpen: !this.state.tooltipIsOpen })
    this.autoCloseTimer = setTimeout(this.handleTooltipClose.bind(this), 10000)
  }

  handleTooltipClose = () => {
    this.setState({ tooltipIsOpen: false })
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer)
    }
  }

  handleNewShootType(e) {
    this.props.history.push(`/collections/create`)
  }

  handleGetShootType(e) {
    this.saveFilters()
    const { id: collectionID = '' } = e
    this.props.history.push(`/collections/${collectionID}/view`)
  }

  onSelected = (selectedData = [], selected = {}) => {
    const { id: selectedId = '' } = selected
    return selectedData.some((obj) => obj.id === selectedId)
  }

  toggleOpen = () => {
    const { numSelected } = this.props
    return numSelected !== 0
  }

  onStatusUpdateCallBack = () => {
    const { onCheckBoxHeaderChange = () => {} } = this.props
    this.setState(
      {
        filters: {},
        creatorSelectedValue: {},
        locationSelectedValue: {},
        currentTabValue: '',
      },
      () => {
        onCheckBoxHeaderChange()
      }
    )
  }

  onCollectionStatusUpdate = (status = '') => {
    const { onStatusUpdate = () => {}, selectedData = [] } = this.props
    const selectedIds = selectedData.map((obj) => obj.id)
    const requestParams = {
      status: status,
      collection_ids: selectedIds,
    }
    onStatusUpdate(requestParams, this.onStatusUpdateCallBack)
  }

  onDownloadCSV = () => {
    const {
      selectedData = [],
      downloadAllCollectionSamples = () => {},
      onCheckBoxHeaderChange = () => {},
    } = this.props
    downloadAllCollectionSamples(selectedData)
    onCheckBoxHeaderChange()
  }

  onDownloadExcel = () => {
    const {
      selectedData = [],
      downloaCollectionExcel = () => {},
      onCheckBoxHeaderChange = () => {},
    } = this.props
    downloaCollectionExcel(selectedData)
    onCheckBoxHeaderChange()
  }

  saveSearchValue = (searchValue = '') => {
    const { saveCollectionSearchValue = () => {} } = this.props
    saveCollectionSearchValue(searchValue)
  }

  renderTabsContent = () => {
    const {
      columnData,
      downloadAllCollectionSamples,
      allCollections,
      getCollectionsPending = false,
      checkBoxEnabled,
      numSelected = Number(0),
      onCheckBoxChange = () => {},
      onCheckBoxHeaderChange = () => {},
      selectedData = [],
      searchValue = '',
    } = this.props

    const { currentTabValue = '' } = this.state
    const currentTabValueObj =
      collectionTabLabels.find((obj) => obj.value === currentTabValue) || {}
    const { label: currentTabLabel = '' } = currentTabValueObj
    const tableLabel = `${capitalize(currentTabLabel) + ' Collections'}`
    return (
      <FlexibleTable
        cascadeFieldKeys={[]}
        checkBoxEnabled={
          [STATUS_CANCELLED, STATUS_COMPLETED].indexOf(currentTabValue) !== -1
            ? false
            : checkBoxEnabled
        }
        columnData={columnData}
        data={allCollections}
        uniqueIdentifierInData={'id'}
        dataFieldKeys={[]}
        editEnabled={false}
        enableSearch
        enableDownload={false}
        downloadFunction={() => downloadAllCollectionSamples(allCollections)}
        showPopup={(e) => this.handleGetShootType(e)}
        tableTitle={tableLabel}
        disableTablePagination={true}
        disableItemCount={true}
        clickableRowButton="OPEN"
        additionalActions={true}
        additionalFiltersContent={this.additionalFiltersContent}
        isLoading={getCollectionsPending}
        numSelected={numSelected}
        onCheckBoxChange={onCheckBoxChange}
        onCheckBoxHeaderChange={onCheckBoxHeaderChange}
        selected={selectedData}
        onSelected={this.onSelected}
        saveFilterValue={this.saveSearchValue}
        savedSearchValue={searchValue}
        enableMultiSearch
      />
    )
  }

  renderBottomBar = () => {
    const { classes = {}, selectedData = [] } = this.props
    const numSelected = selectedData?.length || Number(0)
    const { currentTabValue = '' } = this.state

    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={this.toggleOpen()}
        onOpen={() => null}
        onClose={() => null}
        showClearAll={numSelected > 0}
        actionType={'REST_ALL_COLLECTIONLIST'}
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          <Grid container className={classes.swipeableDrawer}>
            <Typography
              variant="body1"
              component="body1"
              className={classes.defaultButtonSD}
            >
              {numSelected} Selected
            </Typography>
            <div className={classes.toolBar}>
              <Button
                id="downloadCsv"
                data-id="EXPORT TO CSV"
                className={classes.defaultButtonSD}
                startIcon={<GetApp />}
                onClick={this.onDownloadCSV}
              >
                DOWNLOAD CSV
              </Button>
              <Button
                id="downloadExcel"
                data-id="EXPORT TO EXCEL"
                className={classes.defaultButtonSD}
                startIcon={<GetApp />}
                onClick={this.onDownloadExcel}
              >
                COP DOWNLOAD
              </Button>
              {[STATUS_CANCELLED, STATUS_COMPLETED].indexOf(currentTabValue) ===
                -1 && (
                <Button
                  id="markAsComplete"
                  data-id="MARKASCOMPLETE"
                  className={classes.defaultButtonSD}
                  onClick={() => {
                    this.onCollectionStatusUpdate(STATUS_COMPLETED)
                  }}
                  startIcon={<DoneAllIcon />}
                >
                  MARK AS COMPLETE
                </Button>
              )}
              {[STATUS_CANCELLED, STATUS_COMPLETED].indexOf(currentTabValue) ===
                -1 && (
                <Button
                  id="cancelCollection"
                  data-id="CANCELCOLLECTION"
                  className={classes.defaultButtonSD}
                  onClick={() => {
                    this.onCollectionStatusUpdate(STATUS_CANCELLED)
                  }}
                  startIcon={<BlockIcon />}
                >
                  CANCEL
                </Button>
              )}
            </div>
          </Grid>
        </div>
      </BottomBar>
    )
  }

  render() {
    const { classes, tabValue = defaultCollectionsTab } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.pageHeader}>
          <Typography variant="h4" gutterBottom data-cy="collectionsHeader">
            Collections
          </Typography>
          <div className={classes.pageHeaderButton}>
            <Button
              id="createNewCollection"
              data-cy="newCollectionBtn"
              data-id={ShootTypes.COLLECTION}
              color="primary"
              className={classes.defaultButton}
              type="button"
              variant="contained"
              onClick={this.handleNewShootType.bind(this)}
              startIcon={<AddIcon />}
            >
              New Collection
            </Button>
          </div>
        </div>
        <Paper className={classes.paper}>
          <CustomTabs
            tabLabels={collectionTabLabels}
            renderTabsContent={this.renderTabsContent}
            setCurrentTabValue={this.setCurrentTabValue}
            defaultTab={tabValue}
            showTabsPanel={false}
          />
        </Paper>
        {this.renderBottomBar()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    collections: {
      columnData = [],
      allCollections = [],
      getCollectionsPending = false,
      collectionCreators = [],
      collectionLocations = [],
      numSelected = Number(0),
      selectedData = [],
      checkBoxEnabled = true,
      tabValue = '',
      creatorValue = {},
      locationValue = {},
      filterValue = '',
    },
    newShootType: {
      goNextUpdatePageFlag = false,
      shootType = '',
      goNextViewPageFlag = false,
      page = 1,
      rowsPerPage = 0,
    },
  } = state
  return {
    columnData,
    goNextUpdatePageFlag,
    shootType,
    allCollections,
    goNextViewPageFlag,
    page,
    rowsPerPage,
    getCollectionsPending,
    collectionCreators,
    collectionLocations,
    numSelected,
    selectedData,
    checkBoxEnabled,
    filters: selectPageFilters('collection')(state),
    tabValue,
    creatorValue,
    locationValue,
    searchValue: filterValue,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCollections: (params = {}) => dispatch(getCollections(params)),
    downloadAllCollectionSamples: (collections) => {
      dispatch(downloadAllCollectionSamples(collections))
    },
    downloaCollectionExcel: (collections) => {
      dispatch(downloaCollectionExcel(collections))
    },
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    createShootType: (userId, shootType) =>
      dispatch(createShootType(userId, shootType)),
    getShootType: (row, shootType, page, rowsPerPage) =>
      dispatch(getShootType(row, shootType, page, rowsPerPage)),
    onCheckBoxChange: (selected = {}) => dispatch(setCheckBox(selected)),
    onCheckBoxHeaderChange: (selected = []) =>
      dispatch(setCheckBoxHeaderChange(selected)),
    onStatusUpdate: (params = {}, callback = () => {}) => {
      dispatch(updateCollectionStatus(params, callback))
    },
    setFilters: (filters, pageName) =>
      dispatch(setFilters(filters, 'collection')),
    setCollectionTabvalue: (tabValue) =>
      dispatch(setCollectionTabvalue(tabValue)),
    setCreatorValue: (creatorValue) => dispatch(setCreatorValue(creatorValue)),
    setLocationValue: (locationValue) =>
      dispatch(setLocationValue(locationValue)),
    saveCollectionSearchValue: (searchValue) =>
      dispatch(saveCollectionSearchValue(searchValue)),
  }
}

CollectionsPage.propTypes = {
  columnData: propTypes.array,
  allCollections: propTypes.array,
  history: propTypes.shape({
    push: propTypes.func,
  }),
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(CollectionsPage)
