import Button from '@material-ui/core/Button/Button'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import React from 'react'
import * as propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { promoteToOrder } from '../../store/orderReceipt/actionCreator'
import { withStyles } from '@material-ui/core/styles'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    display: 'grid',
    overflowX: 'auto',
  },
  orderConfirmationPageHeader: {
    fontSize: 'large',
    textAlign: 'center',
  },
  orderConfirmationPagePagination: {
    overflowX: 'auto',
  },
})

class OrderConfirmationPage extends React.Component {
  componentDidMount() {
    const { setHeaderTitleArray } = this.props
    setHeaderTitleArray([{ title: 'Order Confirmation' }])
  }

  disableButton = () => {
    return this.props.data.length < 1
  }

  handleSubmitOrder = () => {
    const { promoteToOrder, data } = this.props

    promoteToOrder(data)
  }

  render() {
    const { classes, columnData, data, onClick, orderNumber } = this.props

    const dataFieldKeys = columnData.map((column) => column.id)

    return (
      <div>
        <p className={classes.orderConfirmationPageHeader}>
          Order Confirmation: {orderNumber}
        </p>
        <Link to="/orderReceipt">
          <Button
            color="primary"
            id="submitOrderButton"
            onClick={this.handleSubmitOrder}
            disabled={this.disableButton()}
            style={{ position: 'absolute', right: '40px', top: '90px' }}
            variant="contained"
          >
            Submit ORDER
          </Button>
        </Link>
        <FlexibleTable
          cascadeFieldKeys={[]}
          columnData={columnData}
          data={data}
          dataFieldKeys={dataFieldKeys}
          onClick={onClick}
          onPopupChange={() => {}}
          showPopup={() => {}}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    columnData: state.orderConfirmation.columnData,
    data: state.orderConfirmation.data,
    onClick: state.orderConfirmation.onClick,
    orderNumber: state.orderConfirmation.orderNumber,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    promoteToOrder: (data) => dispatch(promoteToOrder(data)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
  }
}

OrderConfirmationPage.propTypes = {
  columnData: propTypes.array,
  data: propTypes.array,
  onClick: propTypes.func,
  onPopupChange: propTypes.func,
  orderNumber: propTypes.number,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrderConfirmationPage))
