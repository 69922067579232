import { MuiThemeProvider } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { Popovertheme } from 'config/themeConfig'
export default function EditTextFields(props) {
  const {
    textValue = '',
    onChange = () => {},
    textFieldData = {},
    classes = {},
  } = props
  const { id = '', label = '', placeholder = '' } = textFieldData
  return (
    <MuiThemeProvider theme={Popovertheme}>
      <TextField
        className={classes.optionViews}
        id={id}
        autoFocus
        label={label}
        multiline
        variant="outlined"
        onChange={(event) => onChange(event)}
        value={textValue}
        placeholder={placeholder}
        style={{ minWidth: '100px', width: '90%' }}
        InputProps={{
          classes: {
            inputMultiline: classes.vendorEmailTextMul,
          },
        }}
      />
    </MuiThemeProvider>
  )
}
