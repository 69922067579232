//

import * as React from 'react'
import classnames from 'classnames'
import { debounce, isObjectLike } from 'lodash'
import { useLocation } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import AnimationAccent from '../AnimationAccent'
import MoreDropDown from '../MoreDropDown'
import TabList from '../TabList'
import { BREAKPOINTS_MAP } from '../constants'
import { partitionChildren } from './ScrollContainer.helpers'
import stylesheet from './ScrollContainer.stylesheet'

/**
 * `ScrollContainer`'s job is to decide how tabs are displayed.
 *
 * - If it's a small screen or mobile device, show the tabs
 *   as a continuous scrollable list.
 *
 * - If it's a larger screen, show the tabs as a constant width.
 *
 * - Also on large screens, show extra tabs in a drop down menu
 *   if there are more than the spec allows.
 */
export class ScrollContainer extends React.PureComponent {
  static defaultProps = {
    classes: {},
    location: {
      pathname: '',
    },
  }

  scrollContent

  state = {
    accent: {
      from: {},
      isAnimating: false,
      to: {},
      offset: 0,
    },
    displayedTabList: [],
    hasOverflowTabs: false,
    hasScroll: false,
    overflowTabList: [],
  }

  componentWillMount() {
    const { childrenAsPropsList, location } = this.props

    this.setWidthState(childrenAsPropsList, location.pathname)

    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentWillReceiveProps(nextProps) {
    const { childrenAsPropsList, location } = nextProps
    const { displayedTabList } = this.state
    const isNextTabDisplayed = displayedTabList.find(
      (tab) => tab.to === location.pathname
    )

    if (!isNextTabDisplayed) {
      this.setWidthState(childrenAsPropsList, location.pathname)
    }
  }

  render() {
    const { classes } = this.props

    const {
      accent,
      displayedTabList,
      hasOverflowTabs,
      hasScroll,
      overflowTabList,
    } = this.state

    const containerClassList = classnames(classes.container, {
      [classes.container__isScrollable]: hasScroll,
    })

    return (
      <div className={containerClassList}>
        <TabList
          childMap={displayedTabList}
          onNavigate={this.handleNavigate}
          showAccent={!accent.isAnimating}
          innerRef={this.captureRef}
        >
          {hasOverflowTabs && <MoreDropDown childMap={overflowTabList} />}
        </TabList>
        <AnimationAccent onAnimationEnd={this.handleAnimationEnd} {...accent} />
      </div>
    )
  }

  captureRef = (comp) => {
    // eslint-disable-next-line no-prototype-builtins
    if (isObjectLike(comp) && comp.hasOwnProperty('list')) {
      this.scrollContent = comp.list
    } else {
      this.scrollContent = null
    }
  }

  handleAnimationEnd = () => {
    const { accent } = this.state

    this.setState({
      accent: {
        from: accent.to,
        isAnimating: false,
        offset: 0,
        to: {},
      },
    })
  }

  // : { from?: AccentMetaType, to?: AccentMetaType}
  handleNavigate = ({ from, to }) => {
    const nextAccentState = {}

    if (this.scrollContent) {
      const scrollContent = this.scrollContent
      const marginStr = window.getComputedStyle(scrollContent).marginLeft
      const marginValueStr = /^([\d]{1,3})/.exec(marginStr)
      const marginValueInt = parseInt(marginValueStr, 10)
      nextAccentState.offset =
        scrollContent.getBoundingClientRect().left - marginValueInt
    }

    if (to) {
      nextAccentState.to = to
      nextAccentState.isAnimating = true
    }

    if (from) {
      nextAccentState.from = from
    }

    this.setState({
      accent: {
        ...this.state.accent,
        ...nextAccentState,
      },
    })
  }

  handleResize = debounce(() => {
    const { childrenAsPropsList, location } = this.props

    this.setWidthState(childrenAsPropsList, location.pathname)
  }, 25)

  setWidthState = (childrenList, pathname) => {
    if (this.hasScroll) {
      this.setState({
        displayedTabList: childrenList,
        overflowTabList: [],
        hasScroll: true,
        hasOverflowTabs: false,
      })
    } else {
      const [tabs, remaining] = partitionChildren(childrenList, pathname)
      this.setState({
        displayedTabList: tabs,
        overflowTabList: remaining,
        hasScroll: false,
        hasOverflowTabs: !!remaining.length,
      })
    }
  }

  get hasScroll() {
    const isIE = !!window.MSInputMethodContext && !!document.documentMode
    const query = `screen and (max-width: ${BREAKPOINTS_MAP.MD - 0.1}px)`
    const isSmallerScreen = window.matchMedia(query).matches

    return !isIE && isSmallerScreen
  }
}

function componentWithHook(Component) {
  return function WrappedComponent(props) {
    const location = useLocation()
    return <Component {...props} location={location} />
  }
}

export default withStyles(stylesheet)(componentWithHook(ScrollContainer))
