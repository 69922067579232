export const ShootTypes = Object.freeze({
  PROJECT: 'Project',
  COLLECTION: 'Collection',
})
export const ShootTypePagename = Object.freeze({
  PROJECT: 'projects',
  COLLECTION: 'collections',
  SHIPSAMPLES: 'shipSamples',
})

export const additionalColumn = 'updateDate'
