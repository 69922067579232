import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import React from 'react'
import * as propTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { withStyles } from '@material-ui/core/styles'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { convertTableDateFormat, CSV_DATE_FORMAT } from '../../util/CommonUtils'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    display: 'grid',
    overflowX: 'auto',
  },
  orderReceiptPageBody: {
    // fontSize: 'large',
    textAlign: 'center',
  },
  OrderReceiptPageHeader: {
    fontSize: 'xx-large',
    textAlign: 'center',
  },
  OrderReceiptPagePagination: {
    overflowX: 'auto',
  },
  csv: {
    color: 'white',
    textDecoration: 'none',
  },
  csvDisabled: {
    color: 'rgba(0,0,0,0.26)',
    textDecoration: 'none',
  },
})

class OrderReceiptPage extends React.Component {
  componentDidMount() {
    const { setHeaderTitleArray } = this.props
    setHeaderTitleArray([{ title: 'Order Receipt' }])
  }

  disableButton = () => {
    const { data } = this.props
    if (data.length === 0) {
      return true
    } else {
      return false
    }
  }

  render() {
    const { classes, columnData, data, onClick, orderNumber } = this.props

    const dataFieldKeys = columnData.map((column) => column.id)

    const csvHeaders = columnData.map((column) => ({
      label: column.label,
      key: column.id,
    }))

    const csvData = data.map((i) => ({
      ...i,
      dueDate: convertTableDateFormat(i.dueDate, CSV_DATE_FORMAT).toString(),
    }))

    return (
      <div>
        <p className={classes.OrderReceiptPageHeader} style={styles.header}>
          Order: {orderNumber} has been processed
        </p>
        <p className={classes.orderReceiptPageBody} style={styles.header}>
          Thank you for your sample order. Email communication will be sent to
          your vendor(s) and you will receive a copy of the email communication
        </p>
        <p className={classes.orderReceiptPageBody} style={styles.header}>
          Thanks for using Pegasus!
        </p>
        {data ? (
          <Button
            id="exportButton"
            variant="contained"
            color="primary"
            disabled={this.disableButton()}
          >
            <CSVLink
              id={this.disableButton() ? 'disabledCSVLink' : 'enabledCSVLink'}
              className={
                this.disableButton() ? classes.csvDisabled : classes.csv
              }
              style={styles.header}
              data={csvData}
              headers={csvHeaders}
              filename="orderConfirmation.csv"
            >
              Export as CSV
            </CSVLink>
          </Button>
        ) : null}
        <FlexibleTable
          cascadeFieldKeys={[]}
          columnData={columnData}
          data={data}
          dataFieldKeys={dataFieldKeys}
          onClick={onClick}
          showPopup={() => {}}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    columnData: state.orderReceipt.columnData,
    data: state.orderReceipt.data,
    onClick: state.orderReceipt.onClick,
    orderNumber: state.orderReceipt.orderNumber,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
  }
}

OrderReceiptPage.propTypes = {
  columnData: propTypes.array,
  data: propTypes.array,
  numSelected: propTypes.number,
  onClick: propTypes.func,
  orderNumber: propTypes.number,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrderReceiptPage))
