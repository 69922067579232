export const USER_INFORMATION_SAVE_USER_REQUEST_PENDING =
  'USER_INFORMATION_SAVE_USER_REQUEST_PENDING'
export const USER_INFORMATION_SAVE_USER_REQUEST_FAILURE =
  'USER_INFORMATION_SAVE_USER_REQUEST_FAILURE'
export const USER_INFORMATION_SAVE_USER_REQUEST_SUCCESS =
  'USER_INFORMATION_SAVE_USER_REQUEST_SUCCESS'
export const USER_INFORMATION_UPDATE_USER_REQUEST_PENDING =
  'USER_INFORMATION_UPDATE_USER_REQUEST_PENDING'
export const USER_INFORMATION_UPDATE_USER_REQUEST_FAILURE =
  'USER_INFORMATION_UPDATE_USER_REQUEST_FAILURE'
export const USER_INFORMATION_UPDATE_USER_REQUEST_SUCCESS =
  'USER_INFORMATION_UPDATE_USER_REQUEST_SUCCESS'
export const USER_INFORMATION_CLEAR_USER_DATA =
  'USER_INFORMATION_CLEAR_USER_DATA'
export const USER_DEFAULT_LOCATION_REQUEST_PENDING =
  'USER_DEFAULT_LOCATION_REQUEST_PENDING'
export const USER_DEFAULT_LOCATION_REQUEST_FAILURE =
  'USER_DEFAULT_LOCATION_REQUEST_FAILURE'
export const USER_DEFAULT_LOCATION_REQUEST_SUCCESS =
  'USER_DEFAULT_LOCATION_REQUEST_SUCCESS'
export const USER_INFORMATION_SET_BARCODE_TYPE =
  'USER_INFORMATION_SET_BARCODE_TYPE'
export const UPDATE_USER_DEPT_PENDING = 'UPDATE_USER_DEPT_PENDING'
export const UPDATE_USER_DEPT_FAILURE = 'UPDATE_USER_DEPT_FAILURE'
export const UPDATE_USER_DEPT_SUCCESS = 'UPDATE_USER_DEPT_SUCCESS'
