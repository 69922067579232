import axios from 'axios'
import apiConfig from '../apiConfig'
import { isEmpty } from 'lodash'

const {
  vendors: { url = '' },
  apiKey = '',
} = apiConfig

export const getVendorEmailsById = (vendorIds) => {
  if (!isEmpty(vendorIds)) {
    let params = new URLSearchParams()
    vendorIds.map((id) => params.append('ids', id))
  }
  return axios.post(
    url + 'multi_vendors',
    {
      vendor_ids: vendorIds,
    },
    {
      headers: { 'Content-Type': 'application/json' },
      params: { key: apiKey },
    }
  )
}

export const getVendorIdsByVendorUser = (lanId) => {
  return axios.get(url + 'business_partners/' + lanId, {
    headers: { 'Content-Type': 'application/json' },
    params: { key: apiKey },
  })
}

export function fetchBPIDs(searchText = '') {
  const vendorUrl = `${url}business_partners?search_text=${searchText}`
  return axios.get(vendorUrl, {
    params: { key: apiKey },
  })
}
