export const carriers = [
  { value: '' },
  { value: 'USPS' },
  { value: 'UPS' },
  { value: 'FEDEX' },
  { value: 'DHL' },
  { value: 'HAND DELIVERED' },
  { value: 'SF Express' },
  { value: 'Estes' },
  { value: 'YRC' },
  { value: 'OTHER' },
]
