import { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Grid,
  IconButton,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import * as propTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    padding: '10px',
    margin: '10px',
  },
  textField: {
    padding: '10px',
    margin: '10px',
  },
  typography: {
    padding: '10px',
  },
})

class PersonalInformationCard extends PureComponent {
  constructor(props) {
    super(props)
    const { accountInformation } = this.props
    this.state = {
      editContentVisible: false,
      contactInformation: accountInformation.contactInformation,
    }
  }

  handleSaveClick = () => {
    this.setState((state) => ({ ...state, editContentVisible: false }))
    const { handleSaveClick, accountInformation } = this.props
    if (this.handleSaveClick) {
      handleSaveClick({
        lanId: accountInformation.lanId,
        addressLine1: this.state.contactInformation.addressLine1,
        addressLine2: this.state.contactInformation.addressLine2,
        city: this.state.contactInformation.city,
        state: this.state.contactInformation.state,
        zipCode: this.state.contactInformation.zipCode,
        mailstop: this.state.contactInformation.mailstop,
      })
    }
  }

  handleEditIconClick = () => {
    this.setState((state) => ({ ...state, editContentVisible: true }))
  }

  handleCancelClick = () => {
    const { accountInformation } = this.props
    this.setState((state) => ({
      ...state,
      editContentVisible: false,
      contactInformation: accountInformation.contactInformation,
    }))
  }

  handleAddressLine1Change(event) {
    this.setState((state) => ({
      ...state,
      contactInformation: {
        ...state.contactInformation,
        addressLine1: event,
      },
    }))
  }

  handleAddressLine2Change(event) {
    this.setState((state) => ({
      ...state,
      contactInformation: {
        ...state.contactInformation,
        addressLine2: event,
      },
    }))
  }

  handleZipCodeChange(event) {
    this.setState((state) => ({
      ...state,
      contactInformation: {
        ...state.contactInformation,
        zipCode: event,
      },
    }))
  }

  handleCityChange(event) {
    this.setState((state) => ({
      ...state,
      contactInformation: {
        ...state.contactInformation,
        city: event,
      },
    }))
  }

  handleStateChange(event) {
    this.setState((state) => ({
      ...state,
      contactInformation: {
        ...state.contactInformation,
        state: event,
      },
    }))
  }

  handleMailstopChange(event) {
    this.setState((state) => ({
      ...state,
      contactInformation: {
        ...state.contactInformation,
        mailstop: event,
      },
    }))
  }

  componentWillReceiveProps() {
    const accountInformation = this.props.accountInformation
    this.setState((state) => ({
      ...state,
      contactInformation: accountInformation.contactInformation,
    }))
  }

  render() {
    const { contactInformation, editContentVisible } = this.state
    const { classes, accountInformation } = this.props
    return (
      <div>
        {!editContentVisible ? (
          <Card className={classes.root}>
            <CardHeader
              title="Contact Information"
              action={
                <IconButton onClick={this.handleEditIconClick}>
                  <Edit />
                </IconButton>
              }
            />
            <CardContent>
              <Typography>
                {accountInformation.firstName} {accountInformation.lastName}
              </Typography>
              <Typography>{contactInformation.addressLine1}</Typography>
              <Typography>{contactInformation.addressLine2}</Typography>
              <Typography>{contactInformation.city}</Typography>
              <Typography>{contactInformation.state}</Typography>
              <Typography>{contactInformation.zipCode}</Typography>
              <Typography>{contactInformation.mailstop}</Typography>
            </CardContent>
          </Card>
        ) : (
          <Card className={classes.root}>
            <CardHeader
              title="Edit Target Address"
              subheader={`${accountInformation.firstName} ${accountInformation.lastName}`}
            />
            <CardContent>
              <Grid container direction="column" spacing={24}>
                <TextField
                  className={classes.textField}
                  label="Address Line 1"
                  defaultValue={contactInformation.addressLine1}
                  onChange={(event) =>
                    this.handleAddressLine1Change(event.target.value)
                  }
                />
                <TextField
                  className={classes.textField}
                  label="Address Line 2"
                  defaultValue={contactInformation.addressLine2}
                  onChange={(event) =>
                    this.handleAddressLine2Change(event.target.value)
                  }
                />
                <TextField
                  className={classes.textField}
                  label="City"
                  defaultValue={contactInformation.city}
                  onChange={(event) =>
                    this.handleCityChange(event.target.value)
                  }
                />
                <TextField
                  className={classes.textField}
                  label="State"
                  defaultValue={contactInformation.state}
                  onChange={(event) =>
                    this.handleStateChange(event.target.value)
                  }
                />
                <TextField
                  className={classes.textField}
                  label="Zip Code"
                  defaultValue={contactInformation.zipCode}
                  type="number"
                  onChange={(event) =>
                    this.handleZipCodeChange(event.target.value)
                  }
                />
                <TextField
                  className={classes.textField}
                  label="Mailstop"
                  defaultValue={contactInformation.mailstop}
                  onChange={(event) =>
                    this.handleMailstopChange(event.target.value)
                  }
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Button color="primary" onClick={this.handleCancelClick}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleSaveClick}
              >
                Save
              </Button>
            </CardActions>
          </Card>
        )}
      </div>
    )
  }
}

PersonalInformationCard.propTypes = {
  handleSaveClick: propTypes.func,
  accountInformation: propTypes.object,
}

PersonalInformationCard.defaultProps = {
  handleSaveClick: () => {},
}

export default withStyles(styles)(PersonalInformationCard)
