import * as types from './actionType'
import { getDisplayImage } from '../../util/CommonUtils'

export const initialState = {
  fetchDataRequestFailure: false,
  fetchDataRequestPending: false,
  fetchDataRequestSuccess: false,
  batchScannerData: [],
  totalCount: Number(0),
  deleteBatchConfirmModal: false,
}

export default function batchReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_DATA_REQUEST_PENDING: // Request has been initiated
      return Object.assign({}, state, {
        ...state,
        fetchDataRequestPending: action.payload.pending,
      })
    case types.FETCH_DATA_SUCCESS: // Request has succeeded and we can set the data state
      return Object.assign({}, state, {
        ...state,
        data: action.payload.data.map((item) => ({
          ...item,
          imagePopupUrl: item.imageUrl,
          imageUrl:
            item.imageUrl !== undefined &&
            item.imageUrl !== null &&
            item.imageUrl !== ''
              ? getDisplayImage(item.imageUrl)
              : 'Image Not Found',
        })),
        fetchDataRequestSuccess: true,
      })
    case types.FETCH_DATA_FAILURE: // request has failed with an error message to display
      return Object.assign({}, state, {
        ...state,
        fetchDataError: action.payload.error,
        fetchDataRequestFailure: true,
      })
    case types.GET_BATCH_SUCCESSFUL:
      return Object.assign({}, state, {
        ...state,
        batchScannerData: action.payload.data,
        totalCount: action.payload.totalCount,
        fetchDataRequestPending: false,
      })
    case types.COPY_BATCH_SUCCESSFUL:
      return Object.assign({}, state, {
        ...state,
        copyBatchScannerData: action.payload.data,
        fetchDataRequestPending: false,
      })
    case types.DELETE_BATCH_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        deleteBatchConfirmModal: !state.deleteBatchConfirmModal,
      })
    default:
      return state
  }
}
