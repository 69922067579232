import axios from 'axios'
import apiConfig from '../apiConfig'
import { getPegasusID } from 'util/CommonUtils'
import { getSampleIDWithouPrefix } from 'util/CommonUtils'
import { SAMPLE } from 'enums/Tabs'

const {
  managements: { url = '' },
  apiKey = '',
} = apiConfig

export const fetchShootShipmentByPegId = (pegasusId) => {
  return axios.get(url + 'shoot_shipments/sample/' + pegasusId, {
    params: {
      key: apiKey,
    },
  })
}

export const fetchPendingShipments = () => {
  return axios.get(url + 'shoot_shipments/pending_shipments', {
    params: {
      key: apiKey,
    },
  })
}

export const fetchShippedShipments = (payload = {}) => {
  const { status = '', page = '', rowsPerPage, filtersList = [] } = payload
  return axios.post(url + 'shoot_shipments/shipped_shipments', filtersList, {
    params: {
      shipment_status: status,
      page,
      per_page: rowsPerPage,
      key: apiKey,
    },
  })
}

export const createShootShipment = (shipmentDetails) => {
  return axios.post(url + 'shoot_shipments', shipmentDetails, {
    params: {
      key: apiKey,
    },
  })
}

export const downloadConflictSamples = (
  shootShipmentId,
  pegasusIds,
  shipFromLocation = ''
) => {
  return axios.post(
    url + 'shoot_shipments/' + shootShipmentId + '/export_conflicts',
    {
      pegasus_ids: [...pegasusIds],
    },
    {
      params: {
        key: apiKey,
        ship_from: shipFromLocation,
      },
    }
  )
}

export function downloadShipmentContactSheet(params = {}, downloadType = '') {
  return axios.get(url + 'search?downloadAs=' + downloadType, {
    headers: { Accept: '*/*' },
    params: Object.assign(
      {},
      {
        key: apiKey,
      },
      params
    ),
    responseType: 'blob',
  })
}

export function downloadShipmentContactSheetPost(
  params = {},
  downloadType = '',
  itemType = SAMPLE
) {
  return axios.post(
    url + 'search?downloadAs=' + downloadType + '&sample_type=' + itemType,
    params,
    {
      headers: { Accept: '*/*' },
      params: {
        key: apiKey,
      },
      responseType: 'blob',
    }
  )
}

export const addShootShipment = (
  shootShipmentId,
  pegasusIds,
  shipFromLocation = '',
  propOrPegasusIds = []
) => {
  const requestPayload = {
    pegasus_ids: pegasusIds.map((id) => getPegasusID(id)),
    prop_ids: [],
  }
  if (propOrPegasusIds && propOrPegasusIds.length > 0) {
    requestPayload.prop_ids = propOrPegasusIds
  }
  return axios.put(
    url + 'shoot_shipments/' + shootShipmentId + '/add_samples',
    requestPayload,
    {
      params: {
        key: apiKey,
        ship_from: shipFromLocation,
      },
    }
  )
}

export const getShootShipment = (shootShipmentId) => {
  return axios.get(url + 'shoot_shipments/' + shootShipmentId, {
    params: {
      key: apiKey,
    },
  })
}

export const deleteShootShipment = (shootShipmentId) => {
  return axios.delete(url + 'shoot_shipments/' + shootShipmentId, {
    params: {
      key: apiKey,
    },
  })
}

export const removeSamples = (selectedData, shootShipmentId) => {
  const pegasusIds = selectedData.map((data) => data.pegasusId)
  return axios.put(
    url + 'shoot_shipments/' + shootShipmentId + '/remove_samples',
    {
      pegasus_ids: pegasusIds.map((id) => getPegasusID(id)),
    },
    {
      params: {
        key: apiKey,
      },
    }
  )
}

export const updateShootShipment = (shipmentId, updateDetails) => {
  return axios.put(url + 'shoot_shipments/' + shipmentId, updateDetails, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export const csvDwonloadScenicData = (shipmentId) => {
  return axios.post(url + 'scenic/shipments/' + shipmentId + '/downloads', {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}

export async function updateShipmentBundles(
  shipmentId,
  pegasusId,
  updatedBundledQuantity
) {
  const requestUrl = `${url}shoot_shipments/${shipmentId}/shoot_samples`
  const updateRequest = [
    {
      pegasus_id: getSampleIDWithouPrefix(pegasusId),
      bundle_quantity: Number(updatedBundledQuantity),
    },
  ]
  return await axios.put(requestUrl, updateRequest, {
    headers: { 'Content-type': 'application/json' },
    params: {
      key: apiKey,
    },
  })
}
