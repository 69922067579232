export const FETCH_BUSINESS_PARTNER_FAILURE = 'FETCH_BUSINESS_PARTNER_FAILURE'
export const FETCH_BUSINESS_PARTNER_SUCCESS = 'FETCH_BUSINESS_PARTNER_SUCCESS'
export const FETCH_BUSINESS_PARTNER_REQUEST_PENDING =
  'FETCH_BUSINESS_PARTNER_REQUEST_PENDING'
export const ORDER_PAGE_SET_CASCADE = 'ORDER_PAGE_SET_CASCADE'
export const ORDER_PAGE_SET_FIELD_VALUE = 'ORDER_PAGE_SET_FIELD_VALUE'
export const ORDER_PAGE_SET_SAMPLES = 'ORDER_PAGE_SET_SAMPLES'
export const ORDER_PAGE_SUBMIT_ORDER_FAILURE = 'ORDER_PAGE_SUBMIT_ORDER_FAILURE'
export const ORDER_PAGE_SUBMIT_ORDER_PENDING = 'ORDER_PAGE_SUBMIT_ORDER_PENDING'
export const ORDER_PAGE_SUBMIT_ORDER_SUCCESS = 'ORDER_PAGE_SUBMIT_ORDER_SUCCESS'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE'
export const FETCH_PROJECTS_PENDING = 'FETCH_PROJECTS_PENDING'
export const RESET_ORDER_STATE = 'RESET_ORDER_STATE'
export const FETCH_BUSINESS_PARTNER_RESET = 'FETCH_BUSINESS_PARTNER_RESET'
export const SET_ORDERPAGE_TABVALUE = 'SET_ORDERPAGE_TABVALUE'
export const SET_ORDERSAMPLES_FROM = 'SET_ORDERSAMPLES_FROM'
export const REVIEW_ORDER_SET_CHECKBOX = 'REVIEW_ORDER_SET_CHECKBOX'
export const REVIEW_ORDER_SET_CHECKBOX_HEADER =
  'REVIEW_ORDER_SET_CHECKBOX_HEADER'
export const ORDER_PAGE_SET_BULK_VALUES = 'ORDER_PAGE_SET_BULK_VALUES'
export const REVIEW_ORDER_EMPTY_SELECTED_DATA =
  'REVIEW_ORDER_EMPTY_SELECTED_DATA'
export const UPDATE_SELECTED_COLUMN_INFO = 'UPDATE_SELECTED_COLUMN_INFO'
export const SET_MOUSE_LOCATIOIN = 'SET_MOUSE_LOCATIOIN'
export const UPDATE_SELECTED_COLUMN_DATA = 'UPDATE_SELECTED_COLUMN_DATA'
export const FETCH_BUSINESS_PARTNER_ID_PENDING = 'FETCH_BUSINESS_PARTNER_ID_PENDING'
