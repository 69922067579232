import { sortingOrder, dateSortingOrder } from '../../util/CommonUtils'
export function EnhancedTableSortComparator(order, orderBy, orderType = '') {
  return function (a, b) {
    let comparison = 0
    let varA = ''
    let varB = ''
    if (orderType === 'date') {
      const distantFuture = new Date(-4683916800000)
      varA = a[orderBy]
        ? new Date(a[orderBy]) - new Date(b[orderBy])
        : distantFuture
      varB = b[orderBy]
        ? new Date(b[orderBy]) - new Date(a[orderBy])
        : distantFuture
      comparison = dateSortingOrder(varA, varB)
    } else {
      varA =
        typeof a[orderBy] === 'string' ? a[orderBy].toUpperCase() : a[orderBy]
      varB =
        typeof b[orderBy] === 'string' ? b[orderBy].toUpperCase() : b[orderBy]
      comparison = sortingOrder(varA, varB)
    }
    return order === 'desc' ? comparison * -1 : comparison
  }
}
