//

// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import ListItem from '@material-ui/core/ListItem'

export function Label(props) {
  const { children, ...additionalProps } = props
  return <ListItem {...additionalProps}>{children}</ListItem>
}

Label.displayName = 'Label'

export default Label
