import ExpandableCard from './ExpandableCard'
import React from 'react'
import { Grid, CardActions, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import { MuiThemeProvider } from '@material-ui/core/styles'
import {
  warningTheme,
  successTheme,
  errorTheme,
  praxisTheme,
} from '../../config/themeConfig'
import withStyles from '@material-ui/core/styles/withStyles'
import { isEmpty, isNull } from 'lodash'
import defaultThumbnail from 'images/defaultThumbnail.jpg'

const styles = {
  chip: {
    border: 'none',
  },
  expandableCardContent: {
    height: 'auto',
    display: 'flex',
    marginTop: 'auto',
    justifyContent: 'center',
  },
  expandableCardAction: {
    margin: 'auto 0 0',
  },
  expandableCardBorder: {
    margin: 'auto 0 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  avatar: {
    width: '75px',
    height: '75px',
    borderRadius: 'unset !important',
    boxShadow: '0 1px 2px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      transform: 'scale(1.5)',
      boxShadow: '0 2px 3px rgba(0,0,0,0.16), 0 2px 3px rgba(0,0,0,0.23)',
    },
  },
  buttonLabel: {
    fontWeight: 700,
  },
}

class SampleDetailCard extends React.Component {
  getTheme = (theme) => {
    switch (theme) {
      case 'warning':
        return warningTheme
      case 'success':
        return successTheme
      case 'error':
        return errorTheme
      default:
        return praxisTheme
    }
  }

  renderRow = (rowLabel = '', rowValue = '') => {
    return (
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <b>{rowLabel}</b>
        </Grid>
        <Grid item xs={1}>
          :
        </Grid>
        <Grid item xs={8}>
          {isEmpty(rowValue) ? '--' : rowValue}
        </Grid>
      </Grid>
    )
  }

  getSampleProjectStr = (sampleProjects = []) => {
    return (
      sampleProjects.map((project = {}) =>
        sampleProjects.indexOf(project) === sampleProjects.length - 1
          ? project.name
          : project.name + ','
      ) || ''
    )
  }
  getFormattedSize = (size = {}) => {
    const dimensionsConsts = {
      height: 'H',
      width: 'W',
      depth: 'D',
      weight: 'Wt',
      other_dimension: 'OTHERS',
    }
    let sizeStr =
      Object.keys(size)
        ?.map((sObj) => {
          return size[sObj] ? dimensionsConsts[sObj] + ':' + size[sObj] : null
        })
        ?.filter((fobj) => !isNull(fobj))
        ?.join(' | ') || ''
    return sizeStr
  }
  getTitle = (sample = {}) => {
    const {
      pegasusId = '',
      propDescription = '',
      description = '',
      scenicDescription = '',
    } = sample
    const { showPopup = () => {}, classes = {} } = this.props
    return (
      <Grid container>
        <Grid item xs={12}>
          <Button
            id="showSampleDetailCard"
            color="primary"
            onClick={() => {
              showPopup(sample)
            }}
            style={{ padding: 'unset' }}
            classes={{
              label: classes.buttonLabel,
            }}
          >
            {pegasusId}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {propDescription || description || scenicDescription}
        </Grid>
      </Grid>
    )
  }
  getDisabled = (pegasusId = '') => {
    return (
      pegasusId.substring(0, 2) !== 'PP' && pegasusId.substring(0, 2) !== 'PS'
    )
  }
  render() {
    const {
      action,
      actionOnClick,
      classes,
      extraIcon,
      menuOptions,
      sample,
      status,
      statusIcon,
      titleAction,
      cardActionsList = null,
      variant,
    } = this.props
    const {
      tcin: sampleTcin = '',
      dpci: sampleDpci = '',
      shipTo: sampleShipTo = '',
      userId: sampleUserId = '',
      scenicId = '',
      color: sampleColor = '',
      size: sampleSize = '',
      manufacturerBrand: sampleManufacturerBrand = '',
      projects: sampleProjects = [],
      location: sampleLocation = '',
      propId = '',
      pegasusId = '',
      scenicBrand = '',
      scenicColorFamilyName: scenicColor = '',
      scenicSize = [],
      scenicRoomNames = '',
    } = sample
    const renderImageUrl =
      sample?.type === 'PROPS' || sample?.type === 'SCENIC'
        ? sample?.sampleImagePopupUrl.find((obj) => obj.primary === true)
            ?.image_url
        : sample.imageUrl
    return (
      <ExpandableCard
        avatar={
          <Avatar aria-label="Sample Image" className={classes.avatar}>
            <img
              alt="complex"
              src={renderImageUrl || defaultThumbnail}
              width={75}
              onError={(e) => {
                e.target.src = defaultThumbnail
              }}
            />
          </Avatar>
        }
        title={this.getTitle(sample)}
        subtitle={'order number: ' + sample.orderNum}
        titleAction={titleAction}
        isError={variant === 'error'}
        content={
          <Grid
            container
            spacing={2}
            justify="center"
            className={classes.expandableCardContent}
          >
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {this.renderRow('Filemaker Id', propId || scenicId)}
                {sample?.type !== 'SCENIC' &&
                  this.renderRow('TCIN', sampleTcin)}
                {sample?.type !== 'SCENIC' &&
                  this.renderRow('DPCI', sampleDpci)}
                {this.renderRow('Ship To', sampleShipTo)}
                {this.renderRow('Ordered By', sampleUserId)}
                {!isEmpty(sampleColor) && this.renderRow('Color', sampleColor)}
                {!isEmpty(scenicBrand) &&
                  this.renderRow('Brand', scenicBrand.displayName)}
                {!isEmpty(scenicColor) && this.renderRow('Color', scenicColor)}
                {!isEmpty(sampleSize) && this.renderRow('Size', sampleSize)}
                {!isEmpty(scenicSize) &&
                  this.renderRow(
                    'Size',
                    this.getFormattedSize(scenicSize || [])
                  )}
                {!isEmpty(sampleManufacturerBrand) &&
                  this.renderRow('Brand', sampleManufacturerBrand)}
                {sample?.type !== 'SCENIC'
                  ? this.renderRow(
                      'Projects',
                      this.getSampleProjectStr(sampleProjects || [])
                    )
                  : this.renderRow('Scenic Rooms', scenicRoomNames)}
                {this.renderRow('Location', sampleLocation)}
              </Grid>
            </Grid>
          </Grid>
        }
        menuOptions={menuOptions}
        actionBar={
          <MuiThemeProvider theme={this.getTheme(variant)}>
            <CardActions
              className={
                statusIcon || status || action
                  ? classes.expandableCardBorder
                  : classes.expandableCardAction
              }
            >
              <Grid container justify="space-between" alignItems="center">
                {(statusIcon || status) && (
                  <Grid item>
                    <Chip
                      id="statusIcon"
                      className={classes.chip}
                      style={{ textTransform: 'capitalize' }}
                      color="primary"
                      icon={statusIcon}
                      label={status.replace('_', ' ')}
                      variant="outlined"
                    />
                  </Grid>
                )}
                {extraIcon && <Grid item>{extraIcon}</Grid>}
                {cardActionsList && <Grid item>{cardActionsList}</Grid>}
                {action && (
                  <Grid
                    item
                    style={{
                      display: !this.getDisabled(pegasusId) ? 'none' : '',
                    }}
                  >
                    <Button
                      id="cardAction"
                      color="primary"
                      onClick={actionOnClick}
                    >
                      {action}
                    </Button>
                  </Grid>
                )}
                {/* {cardActionsList ? (
                  <>
                    <Grid item>{cardActionsList}</Grid>
                    {action && (
                      <Grid item>
                        <Chip
                          id="statusIcon"
                          className={classes.chip}
                          color="primary"
                          style={{ textTransform: 'uppercase' }}
                          // icon={statusIcon}
                          label={action}
                          variant="outlined"
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {(statusIcon || status) && (
                      <Grid item>
                        <Chip
                          id="statusIcon"
                          className={classes.chip}
                          color="primary"
                          icon={statusIcon}
                          label={status}
                          variant="outlined"
                        />
                      </Grid>
                    )}
                    {action && (
                      <Grid item>
                        <Button
                          id="cardAction"
                          color="primary"
                          onClick={actionOnClick}
                        >
                          {action}
                        </Button>
                      </Grid>
                    )}
                  </>
                )} */}
              </Grid>
            </CardActions>
          </MuiThemeProvider>
        }
      />
    )
  }
}

SampleDetailCard.defaultProps = {
  titleAction: null,
  cardActionsList: null,
  showPopup: () => {},
}

SampleDetailCard.propTypes = {
  sample: PropTypes.object.isRequired,
  menuOptions: PropTypes.array,
  status: PropTypes.string,
  statusIcon: PropTypes.object,
  variant: PropTypes.string,
  action: PropTypes.string,
  actionOnClick: PropTypes.func,
  extraIcon: PropTypes.object,
  titleAction: PropTypes.object,
  cardActionsList: PropTypes.object,
}

export default withStyles(styles)(SampleDetailCard)
