import * as scenicRoomAPI from '../../services/scenicRoomService'
import * as scenicRoomMapper from '../../mappers/scenicRoomMapper'
import * as types from './actionType'
import * as partnersAPI from '../../services/partnersService'

export const getActiveScenicRoom = (payload = {}) => {
  const { filters = [] } = payload
  const payloadFilters = scenicRoomMapper.getPayloadFilters(filters)
  payload['filtersList'] = payloadFilters
  payload['status'] = 'ACTIVE'
  return (dispatch, getState) => {
    dispatch(getAllScenicRooms(payload))
  }
}

export const getAllScenicRooms = (payload = {}) => {
  return (dispatch, getState) => {
    const { allUsers=[]} = getState().scenicRoom
    dispatch(getActiveScenicRoomPending(true))
    return scenicRoomAPI
      .fetchScenicRooms(payload)
      .then((res) => scenicRoomMapper.scenicRoomsResponseToScenicRooms(res, allUsers))
      .then((scenicRooms = []) => {
        dispatch(getActiveScenicRoomSuccess(scenicRooms))
      })
      .catch((error) => {
        dispatch(getActiveScenicRoomFailure())
      })
      .finally(() => {
        dispatch(getActiveScenicRoomPending(false))
      })
  }
}

export const getActiveScenicRoomPending = (pending = false) => {
  return {
    type: types.ACTIVE_SCENIC_ROOM_PENDING,
    payload: { pending: pending },
  }
}

export const getActiveScenicRoomFailure = () => {
  return {
    type: types.ACTIVE_SCENIC_ROOM_FAILURE,
  }
}

export const getActiveScenicRoomSuccess = (activeScenicRooms) => {
  return {
    type: types.ACTIVE_SCENIC_ROOM_SUCCESS,
    payload: { activeScenicRooms: activeScenicRooms },
  }
}

export const getArchivedScenicRoom = (payload = {}) => {
  const { filters = [] } = payload
  const payloadFilters = scenicRoomMapper.getPayloadFilters(filters)
  payload['filtersList'] = payloadFilters
  payload['status'] = 'ARCHIVED'
  return (dispatch) => {
    dispatch(getAllScenicRooms(payload))
  }
}

export const savePendingFilterValue = (filterValue) => {
  return {
    type: types.SCENIC_ROOMS_TABLE_FILTER_VALUE,
    payload: { filterValue },
  }
}
export const saveShippedFilterValue = (filterValue) => {
  return {
    type: types.SCENIC_ROOMS_TABLE_SHIPPED_FILTER_VALUE,
    payload: { filterValue },
  }
}

export const saveScenicRoomsTabValue = (status = '') => {
  return {
    type: types.SCENIC_ROOMS_SET_TAB_VALUE,
    payload: { tabValue: status },
  }
}

export const saveUsers =(users=[]) =>{
  return{
    type: types.SAVE_USERS,
    payload:[...users]
  }
}

export const saveScenicRoomsTabsAttributes = (tabBasedAttributes = {}) => {
  return {
    type: types.SCENIC_ROOMS_SET_TAB_ATTRIBUTES,
    payload: { tabBasedAttributes: tabBasedAttributes },
  }
}

export function getPartners() {
  return async (dispatch) => {
    dispatch(fetchPartnersRequestPending(true))
    try {
      const { data: partnersResult } = await partnersAPI.getPartners()
      await dispatch(fetchPartnersRequestSuccess(partnersResult))
    } catch (err) {
      await dispatch(fetchPartnersRequestFailure())
    }

    dispatch(fetchPartnersRequestPending(false))
  }
}

export function fetchPartnersRequestPending(pending = false) {
  return {
    type: types.FETCH_PARTNERS_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export const fetchPartnersRequestSuccess = (allPartnersList) => {
  return {
    type: types.FETCH_PARTNERS_REQUEST_SUCCESS,
    payload: { allPartnersList },
  }
}

export function fetchPartnersRequestFailure() {
  return {
    type: types.FETCH_PARTNERS_REQUEST_FAILURE,
  }
}
