import React from 'react'
import * as propTypes from 'prop-types'
import {
  isEmpty,
  findIndex,
  isEqual,
  cloneDeep,
  isArray,
  isUndefined,
  first,
} from 'lodash'
import copy from 'copy-to-clipboard'
import {
  Grid,
  Button,
  TextField,
  IconButton,
  Typography,
  SwipeableDrawer,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
} from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Clear from '@material-ui/icons/Clear'
import MoreVert from '@material-ui/icons/MoreVert'
import Add from '@material-ui/icons/Add'
import GetApp from '@material-ui/icons/GetApp'
import Print from '@material-ui/icons/Print'
import EditIcon from '@material-ui/icons/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import ChipArray from '../../components/Chips/ChipArray'
import CircularPageLoader from '../../components/Loader/CircularPageLoader'
import ItemEditCard from '../../components/EditCard/ItemEditCard'

import CloseIcon from '@material-ui/icons/Close'

import {
  getSettingsColumnListByPage,
  settingsColumnCategories,
  settingsColumnCategoriesWithProps,
  settingsColumnCategoriesWithScenic,
  launchInNewTabColumns,
} from './constants'

import {
  getFromBrowserStorage,
  removeFromBrowserStorage,
} from '../../util/CommonUtils'
import { makeTabTables, getTitle } from '../../util/Table'
import { getSampleIDPrefix } from '../../util/CommonUtils'
import withHOCs from 'util/withHocs'
import {
  dataTabLabelsShipped,
  dataTabLabelsShippedWithProps,
  dataTabLabelsShippedWithScenic,
} from '../../enums/tableTabs'
import {
  SAMPLE,
  SAMPLES,
  PROPS,
  searchPageTabs,
  SCENIC,
} from '../../enums/Tabs'
import { filter } from 'lodash'
import { showNotification } from '../../store/notification/actionCreator'
import { getVendorEmailsById } from '../../store/order/actionCreator'
import { setBarcodeType } from '../../store/user/actionCreator'
import {
  clearSearchData,
  closePopup,
  downloadContactSheet,
  getData,
  getFilters,
  getDuplicateBarcode,
  saveFilters,
  setCheckBox,
  setCheckBoxHeaderChange,
  setPage,
  setRowsPerPage,
  showPopup,
  downloadLaunchDateReport,
  duplicateSamples,
  updateSampleFieldsData,
  setColumnSettings,
  getColumnSettings,
  resetIsCallSearchFields,
  updateSamples,
  emptySelectedData,
  autoRefreshSearch,
  showAlert,
  cancelOrderedSample,
  closeAlert,
  reverseSampleStatus,
  downloadLoading,
} from '../../store/searchInventory/actionCreator'
import {
  getBarcodes,
  getBarcodesProps,
  getBarcodesScenic,
} from '../../store/shipmentBarcode/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { getProjectChannel } from '../../store/newShootType/actionCreator'
import NewSamplePopUp from '../MyOrdersPage/NewSamplePopUp'
import DialogBox from '../../components/DialogBox/DialogBox'
import {
  paramsFromFilters,
  SEARCH_SELECTABLE_FILTERS,
  SEARCH_VENDOR_SELECTABLE_FILTERS,
  SEARCH_EXCLUDABLE_FILTERS,
  SEARCH_SCENIC_FILTERS,
} from '../AddFilters/constants'
import AddFilters from '../AddFilters/AddFilters'
import { selectPageFilters } from '../../store/filters/selectors'
import {
  setFilters,
  setSearchCurrentTab,
} from '../../store/filters/actionCreator'
import Accordion from '../../components/Accordion/Accordion'
import ThumbnailBlock from 'components/Search/ThumbnailBlock'
import AddPropImage from './AddPropImage'
import apiConfig from 'apiConfig'
import { setCurPropImages } from 'store/addProp/actionCreator'
import { createCsvUtil } from './SearchUtils'
import ShowIf from 'components/ShowIf'
import { InputBase, Paper } from '@mui/material'
import BottomBar from 'components/BottomBar'

const styles = (theme) => ({
  searchInventoryPage: {
    flexGrow: 1,
  },
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  pageHeader: {
    width: '100%',
    height: '40px',
    position: 'relative',
  },
  pageHeaderButton: {
    display: 'inline',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  searchInventoryPageIntro: {
    fontSize: 'xx-large',
    textAlign: 'center',
  },
  filterTitle: {
    paddingLeft: '25px',
    width: '300px',
  },
  toolBar: {
    display: 'flex',
  },
  spacer: {
    flex: '1 1 auto',
  },
  input: {
    width: '106px',
    height: '24px',
    fontFamily: 'Roboto',
    fontSize: '15.7px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.53',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    margin: '15px',
    paddingLeft: '37px',
  },
  addFilterColumn: {
    minHeight: '250px',
    width: '284px',
    padding: '0px 17px',
    marginBottom: '10px',
  },
  addFilterButton: {
    cursor: 'pointer',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontSize: '17px',
  },
  addFilter: {
    margin: '0 0px 17px 0px',
    height: '48px',
    width: '250px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 2px 3px rgba(0,0,0,0.16), 0 2px 3px rgba(0,0,0,0.23)',
    },
  },
  dateInput: {
    fontSize: '14.5px',
  },
  chipFilterTextField: {
    width: '100%',
  },
  chipRoot: {
    height: '26px',
  },
  clearAll: {
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  clearAllIcon: {
    width: '18px',
    height: '18px',
    margin: '8px',
  },
  applyButton: {
    width: '70px',
    height: '35px',
    float: 'right',
  },
  addDeptClassButton: {
    height: '35px',
    float: 'right',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    height: '48px',
    alignItems: 'center',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
  },
  columnSettingsDrawer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'white',
    justifyContent: 'end',
  },
  columnSettingsDrawerButtons: {
    margin: 10,
  },
  errorIcon: { paddingRight: '5px' },
  SwipeableDrawerElements: {
    fontSize: 'normal',
    textAlign: 'left',
    overflow: 'hidden',
  },
  tableTabActions: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '30%',
    color: 'rgba(0, 0, 0, 0.87)',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    height: '48px',
    alignItems: 'right',

    // backgroundColor: 'blue',
  },
  tableTabActionsScenic: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '40%',
    color: 'rgba(0, 0, 0, 0.87)',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    height: '48px',
    alignItems: 'right',
  },
  tabButton: {
    width: 'auto',
    margin: '0px 8px',
  },
  tabButton1: {
    maxWidth: '250px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  tabButton2: {
    maxWidth: '250px',
    width: 'auto',
    textDecoration: 'underline !important',
    textTransform: 'unset !important',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
  sectionMargin: {
    margin: '0px 24px',
  },
  tableTabContainer: {
    margin: '24px',
  },
  tabContainer: {
    display: 'flex',
    textAlign: 'end',
    minWidth: '500px',
    position: 'sticky',
    top: '7%',
    backgroundColor: 'white',
    zIndex: 15,
    minHeight: '60px',
    paddingLeft: '20px',
    borderBottom: '1px solid #ccc',
  },
  defaultButton: {
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
  defaultButton1: {
    maxWidth: '250px',
    width: 'auto',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  defaultButton2: {
    maxWidth: '250px',
    width: 'auto',
    color: 'white',
    textDecoration: 'underline !important',
    textTransform: 'unset !important',
  },
  popupPaper: {
    height: 'auto',
    overflowY: 'auto',
    maxHeight: '200px',
  },
  settingsPrimaryText: {
    fontSize: '14px',
    color: '#666',
  },
  settingsPrimaryTextLabel: {
    fontSize: '14px',
    color: '#666',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  FormControlLabelCls: {
    fontSize: '12px',
  },

  FormControlRootCls: {
    height: 30,
  },
  FormControlRootClsScenic: {
    height: 40,
    width: 200,
  },
  textField: {
    width: 'calc(90%)',
    margin: '11px',
  },
  searchDiv: {
    width: 'calc(50%)',
    margin: '11px',
    border: '1px solid #6666',
    borderRadius: '9px',
  },
  card: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  clearButton: {
    marginRight: 15,
  },
  addfiltersPaper: {
    paddingBottom: '0px',
    paddingTop: '0px',
    margin: '0px 0px',
    boxShadow: 'unset !important',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

class SearchInventoryPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.anchorRef = React.createRef()
    const { filters = {}, auth = {}, selectedTab = SAMPLES } = this.props
    const settingsColumnListForCurrentTab =
      getSettingsColumnListByPage(selectedTab)

    this.state = {
      checkAll: true,
      popupItem: {},
      showPropsImage: false,
      showPropsImageDataId: {},
      popupIsVisible: false,
      newSamplePopUpIsVisible: false,
      selectedData: [],
      anchorEl: null,
      menuAnchor: null,
      selectIsOpen: false,
      selectableFilters: this.getFilterOptions(),
      filters: filters,
      filtersTitle: '',
      saveFiltersPopUpIsVisible: false,
      openFiltersPopUpIsVisible: false,
      barcodeTypePopUpIsVisible: false,
      lastSuccesfulFilters: {},
      searchQuery: '',
      isFilterUpdated: false,
      isEditRowsSuccess: false,
      sortParam: '',
      sortOrder: '',
      showSettings: false,
      showEditPopUp: false,
      selectedSettingsCols: settingsColumnListForCurrentTab,
      stateSettingsColumnList: settingsColumnListForCurrentTab,
      initialSettingsColumnList: settingsColumnListForCurrentTab,
      defaultColList: getSettingsColumnListByPage(selectedTab, true),
      isDefaultSettings: true,
      isAuthorizedToViewProps: false,
      columnsChanged: false,
      isAuthorizedForPropsAdd: false,
    }
    this.defaultParams = {}
  }

  getFilterOptions = () => {
    const { auth = {} } = this.props
    switch (true) {
      case auth.isAuthorized(apiConfig.roles.admin):
        return Array.from(
          new Set([...SEARCH_SELECTABLE_FILTERS, ...SEARCH_SCENIC_FILTERS])
        )
      case auth.isAuthorized(apiConfig.roles.scenic):
        return SEARCH_SCENIC_FILTERS
      case auth.isAuthorized(['APP-PEG-MKT-VENDOR']):
        return SEARCH_VENDOR_SELECTABLE_FILTERS
      default:
        return SEARCH_SELECTABLE_FILTERS
    }
  }

  componentDidMount = async () => {
    const {
      setHeaderTitleArray = () => {},
      pageName = '',
      getColumnSettings = () => {},
      autoRefreshData = false,
      setPage = () => {},
      autoRefreshSearch = () => {},
      getFilters = () => {},
      auth = {},
      selectedTab = SAMPLES,
      downloadLoading = () => {},
    } = this.props
    downloadLoading(false, '')
    setHeaderTitleArray([{ title: 'Search', link: '/searchInventory/samples' }])

    /** TCIN FILTER IN NEWTAB */
    const items = { ...localStorage }
    const localStorageList = Object.keys(items)
    if (
      auth.isAuthorized(apiConfig.roles.scenic) &&
      !auth.isAuthorized(apiConfig.roles.admin)
    ) {
      setSearchCurrentTab(SCENIC)
    }
    if (localStorageList.indexOf('newTab') !== -1) {
      const searchTermData = getFromBrowserStorage('newTab') || ''
      removeFromBrowserStorage('newTab')
      const searchTermDataJson = JSON.parse(searchTermData)
      const { searchTerm = '', searchValue = '' } = searchTermDataJson
      this.setStorageFilters(searchValue, searchTerm)
    }
    /** TCIN FILTER IN NEWTAB */
    // setFilters({}, pageName)
    getColumnSettings(selectedTab)
    getFilters(pageName)
    if (autoRefreshData) {
      await setPage(1)
      await autoRefreshSearch()
    }

    const isAuthorizedToViewProps = auth.isAuthorized(apiConfig.roles.viewProps)
    const isAuthorizedForPropsAdd = auth.isAuthorized(apiConfig.roles.addProps)
    const isAuthorizedToViewScenic = auth.isAuthorized(apiConfig.roles.scenic)
    const isAdmin = auth.isAuthorized(apiConfig.roles.admin)
    this.setState({
      isAuthorizedToViewProps: isAuthorizedToViewProps,
      isAuthorizedForPropsAdd,
      isAuthorizedToViewScenic,
      isAdmin,
    })
  }

  setStorageFilters = (searchValue = '', searchTerm = '') => {
    const { filters: stateFilters = {} } = this.state
    const filterCompType = 'include_filters'
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    let filtersCopyNew = Object.assign({}, filtersCopy, { [searchTerm]: {} })
    filtersCopyNew[searchTerm].chipArray = [searchValue]
    filtersCopyNew[searchTerm].unrecognizedValues = []
    filtersCopyNew[searchTerm].textFieldValue = ''
    filtersCopyNew[searchTerm].islatest = true
    const updateFiltersObj = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopyNew,
    })
    this.setState({ filters: updateFiltersObj })
  }

  setDefaultSettingsCols = () => {
    const { initialSettingsColumnList = [], defaultColList = [] } = this.state
    this.setState(() => ({
      selectedSettingsCols: defaultColList,
      stateSettingsColumnList: initialSettingsColumnList,
    }))
  }

  componentWillUnmount() {
    const {
      setFilters = () => {},
      pageName = '',
      setSearchCurrentTab = () => {},
      selectedTab = SAMPLES,
      downloadLoading = () => {},
    } = this.props
    const { filters = {} } = this.state
    const filterCopy = Object.assign({}, filters)
    if (!isEmpty(filterCopy)) {
      setFilters(filterCopy, pageName)
    }
    setSearchCurrentTab(selectedTab)
    downloadLoading(false, '')
  }

  displaySelectedData = () => {
    const { selectedData } = this.props

    return (
      <div>
        <p>
          Would you like to create a new sample and label for the following
          items?
        </p>
        <FlexibleTable
          cascadeFieldKeys={[]}
          data={selectedData}
          disableItems
          disableTablePagination
          disableTableSorting
          disableItemCount
        />
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page: propsPage,
      rowsPerPage: propsRowsPerPage,
      fetchData = () => {},
      data: propsData = [],
      setPage = () => {},
      isSearchedColumns,
      searchedColumns = [],
      resetIsCallSearchFields = () => {},
      selectedTab = SAMPLES,
    } = this.props

    const {
      filters: stateFilters = {},
      isFilterUpdated: stateIsFilterUpdated,
      isDefaultSettings,
      searchQuery: searchText = '',
      isEditRowsSuccess,
      sortParam: stateSortParam,
      sortOrder: stateSortOrder,
      showPropsImage = false,
    } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    const {
      page: prevPropsPage,
      rowsPerPage: prevPropsRowsPerPage,
      isSearchedColumns: prevIsSearchedColumns,
      selectedTab: prevTabValue = SAMPLES,
    } = prevProps
    const {
      isFilterUpdated: prevStateIsFilterUpdated,
      isEditRowsSuccess: prevIsEditRowsSuccess,
      showPropsImage: prevShowPropsImage = false,
    } = prevState

    if (
      (propsPage > 1 &&
        (propsPage !== prevPropsPage ||
          propsRowsPerPage !== prevPropsRowsPerPage)) ||
      (!showPropsImage && showPropsImage !== prevShowPropsImage)
    ) {
      fetchData(
        paramsFromFilters(
          filtersCopy,
          propsPage,
          propsRowsPerPage,
          this.userProfilename,
          this.defaultParams,
          stateSortParam,
          stateSortOrder,
          searchText,
          false,
          selectedTab
        ),
        () => {},
        () => {},
        selectedTab
      )
    }
    if (this.state.savedData) {
      this.refreshTableWithNewData(filtersCopy, stateIsFilterUpdated)
      this.setState({
        savedData: false,
      })
    }
    if (
      (propsPage === Number(1) &&
        (propsPage !== prevPropsPage ||
          propsRowsPerPage !== prevPropsRowsPerPage)) ||
      (!!stateIsFilterUpdated &&
        stateIsFilterUpdated !== prevStateIsFilterUpdated) ||
      (!!isEditRowsSuccess &&
        isEditRowsSuccess !== prevIsEditRowsSuccess &&
        !this.state.savedData)
    ) {
      this.refreshTableWithNewData(filtersCopy, stateIsFilterUpdated)
      this.setState(
        {
          isFilterUpdated: false,
        },
        () => {
          setPage(1)
        }
      )
    }
    if (!propsData.length && propsPage !== 1) {
      setPage(1)
    }

    const settingsColumnListForCurrentTab =
      getSettingsColumnListByPage(selectedTab)
    const pegDefaultCols = getSettingsColumnListByPage(selectedTab, true)

    if (selectedTab !== prevTabValue) {
      fetchData(
        paramsFromFilters(
          filtersCopy,
          propsPage,
          propsRowsPerPage,
          this.userProfilename,
          this.defaultParams,
          stateSortParam,
          stateSortOrder,
          '',
          false,
          selectedTab
        ),
        () => {},
        () => {},
        selectedTab
      )
      this.setState({
        initialSettingsColumnList: settingsColumnListForCurrentTab,
        defaultColList: pegDefaultCols,
        selectedSettingsCols: settingsColumnListForCurrentTab,
        stateSettingsColumnList: settingsColumnListForCurrentTab,
      })
    } else if (
      !!isSearchedColumns &&
      isEqual(isSearchedColumns, prevIsSearchedColumns)
    ) {
      /** column setting changes */
      const stateSettingsColumnListUpdated =
        settingsColumnListForCurrentTab.map((obj) => {
          const newObj =
            searchedColumns.indexOf(obj.id) !== -1
              ? Object.assign({}, obj, { default: true })
              : isSearchedColumns
                ? Object.assign({}, obj, { default: false })
                : obj
          return newObj
        })
      const defaultColList = stateSettingsColumnListUpdated.filter((obj) => {
        return !!obj.default
      })
      this.setState((state) => {
        resetIsCallSearchFields()
        return {
          selectedSettingsCols: isDefaultSettings
            ? settingsColumnListForCurrentTab
            : defaultColList,
          stateSettingsColumnList: isDefaultSettings
            ? settingsColumnListForCurrentTab
            : stateSettingsColumnListUpdated,
          isDefaultSettings: isEqual(pegDefaultCols, defaultColList),
        }
      })
    }
    if (propsPage !== prevPropsPage) {
      window.scrollTo(0, 500)
    }
    /** column setting changes **/
  }

  createCsv(data) {
    return createCsvUtil(data)
  }

  handleGenerateBarcode = () => {
    const {
      getBarcodes = () => {},
      selectedData = [],
      barcodeType,
    } = this.props
    // Remove props from selected date
    const selectedSamples = selectedData.filter(
      (item) => item.itemType === SAMPLE
    )
    getBarcodes(
      selectedSamples,
      (barcodes) => {
        const file = new Blob([barcodes], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL).print()
      },
      barcodeType
    )

    this.setState((state) => ({
      ...state,
      printButton: true,
    }))
  }
  handleGenerateBarcodeProps = (format = '') => {
    const {
      getBarcodesProps = () => {},
      selectedData = [],
      barcodeType,
    } = this.props
    // Remove samples from selected data
    const selectedSamples = selectedData.filter(
      (item) => item.itemType === PROPS
    )
    getBarcodesProps(
      selectedSamples,
      (barcodes) => {
        const file = new Blob([barcodes], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL).print()
      },
      barcodeType,
      format
    )

    this.setState((state) => ({
      ...state,
      printButton: true,
    }))
  }
  handleGenerateBarcodeScenic = () => {
    const { getBarcodesScenic = () => {}, selectedData = [] } = this.props
    // Remove samples from selected data
    const selectedSamples = selectedData.filter(
      (item) => item.itemType === SCENIC
    )
    getBarcodesScenic(selectedSamples, (barcodes) => {
      const file = new Blob([barcodes], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL).print()
    })

    this.setState((state) => ({
      ...state,
      printButton: true,
    }))
  }
  onSelected = (selectedData, selected) => {
    const { pegasusId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.pegasusId === pegasusId
      }) !== -1
    )
  }

  setFilters(filters) {
    this.setState({ filters })
  }

  disableButton = () => {
    const { selectedData = [] } = this.props
    return selectedData.length === 0
  }

  setFiltersUpdated = (
    isFilterUpdated = false,
    filters = {},
    sortParam,
    sortOrder,
    savedData = false
  ) => {
    const filtersCopy = Object.assign({}, filters)
    this.setState({
      isFilterUpdated: isFilterUpdated,
      filters: filtersCopy,
      sortParam: sortParam,
      sortOrder: sortOrder,
      savedData: savedData,
    })
  }

  refreshTableWithNewData(filters = {}, stateIsFilterUpdated = false) {
    const filtersCopy = Object.assign({}, filters)
    const {
      page,
      rowsPerPage,
      onCheckBoxHeaderChange,
      fetchData = () => {},
      pageName = '',
      setFilters = () => {},
      selectedTab = SAMPLES,
    } = this.props
    const {
      lastSuccesfulFilters = {},
      searchQuery = '',
      sortParam: stateSortParam,
      sortOrder: stateSortOrder,
    } = this.state

    var encodedStr = searchQuery?.replace(/[\u00A0-\u9999<>]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';'
    })
    onCheckBoxHeaderChange()
    const filtersFromParams = paramsFromFilters(
      filtersCopy,
      stateIsFilterUpdated ? Number(1) : page,
      rowsPerPage,
      this.userProfilename,
      this.defaultParams,
      stateSortParam,
      stateSortOrder,
      encodedStr,
      false,
      selectedTab
    )
    fetchData(
      filtersFromParams,
      () => this.setState({ lastSuccesfulFilters: filtersFromParams }),
      () => setFilters(lastSuccesfulFilters, pageName),
      selectedTab
    )
  }

  toggleOpen = () => {
    const { numSelectedTab, selectedTab = SAMPLES } = this.props
    if (
      !isUndefined(numSelectedTab[selectedTab]) &&
      numSelectedTab[selectedTab] !== 0
    ) {
      return true
    } else {
      return false
    }
  }

  commonDownloadVisible = () => {
    const { numSelected } = this.props
    if (numSelected !== 0) {
      return true
    } else {
      return false
    }
  }

  setPageAndRefresh = (page) => {
    const { setPage = () => {} } = this.props
    setPage(page)
  }

  setRowsPerPageAndRefresh = (rowsPerPage) => {
    const { setRowsPerPage = () => {} } = this.props
    setRowsPerPage(rowsPerPage)
  }

  nameSavedFilters = () => {
    const { filtersTitle = '' } = this.state
    return (
      <TextField
        id="filtersTitle"
        label="Name Your Saved Filters"
        type="text"
        name="title"
        margin="normal"
        variant="outlined"
        fullWidth
        value={filtersTitle}
      />
    )
  }

  downloadLDReport = () => {
    const { downloadLaunchDateReport = () => {} } = this.props
    downloadLaunchDateReport()
  }

  handleOptionsClick = (event) => {
    this.setState({ menuAnchor: event.currentTarget })
  }

  handleSettingClick = (event) => {
    this.setState((state, props) => {
      return { showSettings: !state.showSettings }
    })
  }

  onEditItemsClick = (event) => {
    this.setState((state, props) => {
      return { showEditPopUp: !state.showEditPopUp }
    })
  }

  handleNewSamplePopupOpen = () => {
    this.setState({ newSamplePopUpIsVisible: true })
  }

  handleNewSamplePopupClose = () => {
    this.setState({ newSamplePopUpIsVisible: false })
  }
  handleElasticSearchQuery(searchValue = '') {
    const {
      rowsPerPage,
      page,
      fetchData = () => {},
      selectedTab = SAMPLES,
    } = this.props
    const {
      filters: stateFilters = {},
      sortParam: stateSortParam,
      sortOrder: stateSortOrder,
    } = this.state
    var encodedStr = searchValue.replace(/[\u00A0-\u9999<>]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';'
    })
    const filtersCopy = Object.assign({}, stateFilters)
    fetchData(
      paramsFromFilters(
        filtersCopy,
        page,
        rowsPerPage,
        this.userProfilename,
        this.defaultParams,
        stateSortParam,
        stateSortOrder,
        encodedStr
      ),
      () => {},
      () => {},
      selectedTab
    )
  }

  handleChange = (event) => {
    const searchValue = event.target.value || ''
    this.setState({ searchQuery: searchValue })
    // TODO: reset on tab change
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleElasticSearchQuery(event.target.value)
    }
  }

  handleSearchClear = () => {
    this.setState({
      searchQuery: '',
    })
    const { fetchData, page, rowsPerPage, selectedTab = SAMPLES } = this.props
    const {
      filters: stateFilters,
      sortParam: stateSortParam,
      sortOrder: stateSortOrder,
    } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    fetchData(
      paramsFromFilters(
        filtersCopy,
        page,
        rowsPerPage,
        this.userProfilename,
        this.defaultParams,
        stateSortParam,
        stateSortOrder
      ),
      () => {},
      () => {},
      selectedTab
    )
  }
  duplicateSamples = (sample) => {
    const {
      duplicateSamples,
      getDuplicateBarcode,
      barcodeType,
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
    } = this.props
    let newSample = Object.assign({}, sample, {
      ...sample,
      userId: lanId,
    })
    const { filters: stateFilters = {} } = this.state
    duplicateSamples(newSample).then((samples) => {
      if (samples) {
        getDuplicateBarcode(samples, barcodeType).then((pdf) => {
          if (pdf !== undefined) {
            window.open(pdf).print()
          }
          this.refreshTableWithNewData(stateFilters)
        })
      }
    })
    this.setState({
      newSamplePopUpIsVisible: false,
    })
  }

  getNotFoundBlock = (filterNFList = {}, stateFilters = {}) => {
    const { handleChipClick = () => {}, selectedTab = SAMPLES } = this.props
    const itemType = selectedTab === PROPS ? PROPS : SAMPLE
    const { include_filters: includedFilters } = stateFilters
    let latestFilterStr =
      includedFilters &&
      Object.keys(includedFilters).find(
        (obj) => !!includedFilters[obj]['islatest']
      )
    const latestFilterKey = (latestFilterStr || '')
      .replace(' ', '_')
      .toLowerCase()

    const latestFilterList = filterNFList[latestFilterKey] || []
    const chipArrayData = latestFilterList.reduce(function (
      result,
      item = '',
      index,
      array
    ) {
      const itemStr =
        latestFilterKey === 'sample_id'
          ? getSampleIDPrefix(item, itemType)
          : item
      result[index] = { key: itemStr, label: itemStr }
      return result
    }, [])
    const nfFilterCount = (latestFilterList || []).length
    if (nfFilterCount > 1) {
      latestFilterStr += 's'
    }
    return nfFilterCount > 0 ? (
      <ChipArray
        data={chipArrayData}
        enableCopyAllButton
        handleClick={handleChipClick}
        title={`${nfFilterCount} ${latestFilterStr} not found`}
        textAlign={'left'}
        variant={'h6'}
      />
    ) : (
      ''
    )
  }

  downloadCSFunction =
    (downloadType = '') =>
    (event) => {
      const { filters: stateFilters = {}, searchQuery: searchText = '' } =
        this.state
      const { downloadContactSheet = () => {}, selectedTab = SAMPLES } =
        this.props
      const filtersCopy = Object.assign({}, stateFilters)
      const itemType = selectedTab === SAMPLES ? SAMPLE : selectedTab

      var encodedStr = searchText.replace(/[\u00A0-\u9999<>]/g, function (i) {
        return '&#' + i.charCodeAt(0) + ';'
      })

      if (!isEmpty(downloadType)) {
        downloadContactSheet(
          paramsFromFilters(
            filtersCopy,
            '',
            '',
            '',
            {},
            '',
            '',
            encodedStr,
            false,
            selectedTab
          ),
          downloadType,
          itemType
        )
      }
    }

  downloadCSSelected = (downloadType = '') => {
    const { selectedData = [], downloadContactSheet = () => {} } = this.props

    const selectedPgIds = selectedData.map((obj) =>
      obj.pegasusId.substring(2, obj.pegasusId.length)
    )
    const dataTypes = [...new Set(selectedData.map((item) => item.itemType))]
    const itemType =
      dataTypes.length === 2
        ? 'COMBINED'
        : first(dataTypes) === SAMPLE
          ? SAMPLE
          : first(dataTypes) === SCENIC
            ? SCENIC
            : PROPS

    if (!isEmpty(selectedPgIds)) {
      downloadContactSheet(
        paramsFromFilters(
          {},
          '',
          '',
          '',
          { selectedPg_Ids: [...selectedPgIds] },
          '',
          '',
          ''
        ),
        downloadType,
        itemType
      )
    }
  }

  onSettingsCBChange = (checkedValue, selectedColumn = {}) => {
    let filteredColumns = []
    const { initialSettingsColumnList = [], stateSettingsColumnList = [] } =
      this.state
    const settingsColumnListCopy = [...stateSettingsColumnList]
    filteredColumns = settingsColumnListCopy.map((obj) => {
      if (obj.id === selectedColumn.id) {
        return Object.assign({}, obj, { default: checkedValue })
      }
      return obj
    })
    const pegDefaultCols = [...filteredColumns].filter((obj) => !!obj.default)
    let newObj = {
      selectedSettingsCols: pegDefaultCols,
      stateSettingsColumnList: filteredColumns,
      columnsChanged: true,
    }
    if (isEmpty(pegDefaultCols)) {
      newObj = {
        selectedSettingsCols: initialSettingsColumnList,
        stateSettingsColumnList: initialSettingsColumnList,
        isDefaultSettings: true,
      }
    }
    this.setState(() => {
      return newObj
    })
  }

  defaultsSwitch = (event) => {
    const { searchedColumns = [] } = this.props
    const { selectedSettingsCols = [], initialSettingsColumnList = [] } =
      this.state
    const isDefaultsChecked = event.target.checked
    const defaultColList =
      searchedColumns.length > 0
        ? selectedSettingsCols.map((obj) => {
            const newObj =
              searchedColumns.indexOf(obj.id) !== -1
                ? Object.assign({}, obj, { default: true })
                : Object.assign({}, obj, { default: false })
            return newObj
          })
        : initialSettingsColumnList
    this.setState((state) => ({
      selectedSettingsCols: isDefaultsChecked
        ? initialSettingsColumnList
        : defaultColList,
      stateSettingsColumnList: isDefaultsChecked
        ? initialSettingsColumnList
        : defaultColList,
      isDefaultSettings: isDefaultsChecked,
    }))
  }
  columnFooter = () => {
    const { classes = {} } = this.props
    return (
      <Grid container className={classes.columnSettingsDrawer}>
        <Grid item>
          <Button
            onClick={() => this.callSetColumnSettings()}
            variant="outlined"
            className={classes.columnSettingsDrawerButtons}
            color="primary"
          >
            Apply & Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.columnSettingsDrawerButtons}
            onClick={() => this.handleSettingClick()}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    )
  }

  columnSettingsContent = () => {
    const { classes = {}, selectedTab = SAMPLES } = this.props
    const {
      isDefaultSettings,
      selectedSettingsCols = [],
      initialSettingsColumnList = [],
    } = this.state
    let updatedSettingsColumnCategories = { ...settingsColumnCategories }
    switch (selectedTab) {
      case PROPS:
        updatedSettingsColumnCategories = {
          ...settingsColumnCategoriesWithProps,
        }
        break
      case SCENIC:
        updatedSettingsColumnCategories = {
          ...settingsColumnCategoriesWithScenic,
        }
        break
      default:
        updatedSettingsColumnCategories = { ...settingsColumnCategories }
    }
    return (
      <Grid
        container
        spacing={1}
        style={{ maxHeight: '100%', overflowY: 'scroll', overflowX: 'hidden' }}
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={this.handleSettingClick}
        >
          <CloseIcon />
        </IconButton>
        <Grid item container justifyContent={'flex-start'}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isDefaultSettings}
                  onChange={(event) => {
                    this.defaultsSwitch(event)
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={isDefaultSettings ? 'Default View' : 'My Custom View'}
            />
          </FormGroup>
        </Grid>
        <Grid item container spacing={4}>
          {Object.keys(updatedSettingsColumnCategories).map((obj) => {
            const categoryLabel = updatedSettingsColumnCategories[obj]
            const categoryColList = initialSettingsColumnList.filter(
              (columnObj) => {
                let localColumnData = ''
                if (isArray(columnObj.category)) {
                  let localColumns = columnObj.category.filter(
                    (tabInstance) => tabInstance.toLowerCase() === obj
                  )[0]
                  localColumnData = localColumns?.toLowerCase() === obj
                } else {
                  localColumnData = columnObj.category === obj
                }
                return localColumnData
              }
            )
            return (
              <Grid
                container
                item
                xs={selectedTab === PROPS ? 3 : 4}
                direction={'column'}
              >
                <Grid item className={classes.settingsPrimaryTextLabel}>
                  {categoryLabel}
                </Grid>
                <Grid item>
                  <FormControl
                    required
                    component="fieldset"
                    className={classes.settingsPrimaryText}
                  >
                    <FormGroup>
                      {categoryColList.length > 0 &&
                        categoryColList.map((colObj) => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    findIndex(
                                      selectedSettingsCols,
                                      function (obj) {
                                        return (
                                          obj.id === colObj.id && !!obj.default
                                        )
                                      }
                                    ) !== -1
                                  }
                                  onChange={(event) =>
                                    this.onSettingsCBChange(
                                      event.target.checked,
                                      colObj
                                    )
                                  }
                                  disabled={!!isDefaultSettings}
                                  name={colObj.id}
                                />
                              }
                              classes={{
                                label: classes.FormControlLabelCls,
                                root:
                                  selectedTab === SCENIC
                                    ? classes.FormControlRootClsScenic
                                    : classes.FormControlRootCls,
                              }}
                              label={colObj.label}
                            />
                          )
                        })}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    )
  }

  setColumnSettingsCB = () => {
    this.handleSettingClick()
  }

  callSetColumnSettings = () => {
    const { selectedSettingsCols = [], isDefaultSettings } = this.state
    const { setColumnSettings = () => {}, selectedTab = SAMPLES } = this.props
    const selectedSettingsColsCopy = [...selectedSettingsCols]
    const selectedSettingsColsLabel = selectedSettingsColsCopy
      .filter((obj) => !!obj.default)
      .map((column) => column.id)
    const {
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
    } = this.props
    const colsToApi = isDefaultSettings ? [] : selectedSettingsColsLabel
    setColumnSettings(colsToApi, lanId, this.setColumnSettingsCB, selectedTab)
  }

  itemCardSaveClickCB = () => {
    const { emptySelectedData = () => {} } = this.props
    const { onEditItemsClick = () => {} } = this
    this.setState((state) => {
      onEditItemsClick()
      emptySelectedData()
      return {
        isEditRowsSuccess: true,
      }
    })
  }

  itemCardSaveClick = (editInformation, userId) => {
    const { page, rowsPerPage, saveEditInformation = () => {} } = this.props
    const { filters: stateFilters = {} } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    saveEditInformation(
      editInformation,
      userId,
      paramsFromFilters(filtersCopy, page, rowsPerPage, this.userProfilename),
      this.itemCardSaveClickCB
    )
  }

  renderSaveFilters = () => {
    const { pageName = '', classes = {}, selectedTab = SAMPLES } = this.props
    const { filters: stateFilters = {} } = this.state
    return (
      <AddFilters
        selectableFilters={[]}
        refreshTableWithNewData={this.refreshTableWithNewData}
        setFiltersUpdated={this.setFiltersUpdated}
        filterCompType={'save_filters'}
        pageName={pageName}
        mergedFilters={stateFilters}
        classes={{
          paper: classes.addfiltersPaper,
        }}
        displayFiltersTitle={false}
        currentTabValue={selectedTab}
        fromSearch={true}
      />
    )
  }

  renderFilters = () => {
    const { pageName = '', classes = {}, selectedTab = SAMPLES } = this.props
    const { selectableFilters = [], filters: stateFilters = {} } = this.state
    const filterComponents = {
      defaultPanel: 'includeFilters',
      panelsList: [
        {
          currentPanelId: 'includeFilters',
          currentPanelName: 'Include Filters',
          currentPanelDetails: (
            <AddFilters
              selectableFilters={selectableFilters}
              refreshTableWithNewData={this.refreshTableWithNewData}
              setFiltersUpdated={this.setFiltersUpdated}
              filterCompType={'include_filters'}
              pageName={pageName}
              mergedFilters={stateFilters}
              classes={{
                paper: classes.addfiltersPaper,
              }}
              displayFiltersTitle={false}
              currentTabValue={selectedTab}
              fromSearch={true}
              renderFilterIcons={false}
            />
          ),
        },
        {
          currentPanelId: 'excludeFilters',
          currentPanelName: 'Exclude Filters',
          currentPanelDetails: (
            <AddFilters
              selectableFilters={SEARCH_EXCLUDABLE_FILTERS}
              refreshTableWithNewData={this.refreshTableWithNewData}
              setFiltersUpdated={this.setFiltersUpdated}
              filterCompType={'exclude_filters'}
              pageName={pageName}
              mergedFilters={stateFilters}
              renderFilterIcons={false}
              currentTabValue={selectedTab}
              classes={{
                paper: classes.addfiltersPaper,
              }}
              displayFiltersTitle={false}
            />
          ),
        },
      ],
    }
    return <Accordion filterComponents={filterComponents} />
  }

  handleCancelItems = () => {
    const { showAlert = () => {}, selectedData = [] } = this.props
    // Remove props from selected date
    const selectedSamples = selectedData.filter(
      (item) => item.itemType === SAMPLE
    )
    showAlert(selectedSamples)
  }
  handleAddSamples = (e) => {
    this.props.history.push(`/searchInventory/samples/addSample`)
  }
  handleAddProps = (e) => {
    this.props.history.push(`/searchInventory/props/addProp`)
  }

  handleAddScenic = (e) => {
    this.props.history.push(`/searchInventory/scenic/addScenic`)
  }

  handleTabChange = (e, newValue) => {
    const {
      setTabvalue = () => {},
      getColumnSettings,
      clearSearchCallback,
      navigate,
    } = this.props

    this.setState(
      {
        // resetting elastic search query on tab change,
        searchQuery: '',
        // selectedSettingsCols and stateSettingsColumnList update handled in component update
      },
      () => {
        navigate(`/searchInventory/${newValue}`)
        setTabvalue(newValue)
        clearSearchCallback()
        getColumnSettings(newValue)
      }
    )
  }

  renderTabbedPopUp = () => {
    const {
      popupIsVisible,
      popupItem = {},
      classes = {},
      updateSampleFieldsData = () => {},
      closePopup = () => {},
      selectedTab = SAMPLES,
      navigate = () => {},
    } = this.props
    const tabsList =
      selectedTab === PROPS
        ? dataTabLabelsShippedWithProps
        : selectedTab === SCENIC
          ? dataTabLabelsShippedWithScenic
          : dataTabLabelsShipped
    return (
      <TabbedPopUp
        dataTabLabels={tabsList}
        isVisible={popupIsVisible}
        data={makeTabTables(
          popupItem,
          classes,
          tabsList,
          updateSampleFieldsData
        )}
        onClose={closePopup}
        title={getTitle(popupItem)}
        fullWidth={true}
        currentTabValue={selectedTab}
        propId={popupItem?.propId}
        pegasusId={popupItem?.pegasusId}
        sampleStatus={popupItem?.sample_status}
        navigate={navigate}
      />
    )
  }

  onCopytoClipboard = () => {
    const { selectedData = [] } = this.props
    copy(selectedData.map((item) => item?.pegasusId || '').join('\r\n'), {
      format: 'text/plain',
    })
  }
  toggleShowPropsImage = (item = []) => {
    const { showPropsImage } = this.state
    this.setState({
      showPropsImage: !showPropsImage,
      showPropsImageDataId: item,
    })
  }
  renderPropsImages = () => {
    const { showPropsImageDataId = '' } = this.state
    const {
      classes = {},
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
      selectedTab = SAMPLES,
    } = this.props
    return (
      <Box sx={{ width: 'auto', minWidth: 600 }} role="presentation">
        <AddPropImage
          toggleDrawer={this.toggleShowPropsImage}
          item={showPropsImageDataId}
          classes={classes}
          lanId={lanId}
          sampleType={selectedTab}
        />
      </Box>
    )
  }
  reverseStatus = async () => {
    const {
      selectedData = [],
      reverseSampleStatus = () => {},
      selectedTab,
    } = this.props
    let pegasusIds = selectedData.map((selectedDataInstance) => {
      return selectedDataInstance.pegasusId
    })

    await reverseSampleStatus(pegasusIds, selectedTab)
    this.refreshTableWithNewData(this.state.filters, this.state.isFilterUpdated)
  }
  reverseButtonDisabled = () => {
    const { selectedData = [] } = this.props
    const updatedSelectedData = this.props.data.map((dataInstance) => {
      return filter(selectedData, function (selectedDataInstance) {
        return selectedDataInstance.pegasusId === dataInstance.pegasusId
      })[0]
    })
    let sampleReverseData = filter(
      updatedSelectedData,
      function (selectedDataInstance) {
        const { type = SAMPLE, sample_status = '' } = selectedDataInstance || {}
        return (
          (type === 'SAMPLE' && sample_status === 'SAMPLE_SHIPPED') ||
          sample_status === 'SAMPLE_DISPOSED'
        )
      }
    )
    return !(selectedData.length === sampleReverseData.length)
  }
  renderCopyToClipboard = () => {
    const { classes = {} } = this.props
    return (
      <Button
        id="editSelectedItems"
        data-id="Edit Samples"
        className={classes.defaultButton}
        startIcon={<ContentCopyIcon />}
        onClick={this.onCopytoClipboard}
      >
        Copy To Clipboard
      </Button>
    )
  }

  renderNumberOfSamples = () => {
    const { classes, numSelectedTab, selectedTab = SAMPLES } = this.props
    let text =
      selectedTab.slice(0, 1).toUpperCase() +
      selectedTab.slice(1, selectedTab.length).toLowerCase()
    return (
      <Typography
        variant="body1"
        component="body1"
        className={classes.defaultButton}
      >
        {numSelectedTab[selectedTab]} {text} Selected
      </Typography>
    )
  }
  renderSwipeableDrawer = (constant = {}) => {
    const {
      selectedTab = SAMPLES,
      barcodeType,
      classes = {},
      auth = {},
      userLocation,
    } = this.props
    switch (selectedTab) {
      case SAMPLES:
        return (
          <Grid container className={classes.swipeableDrawer}>
            {this.renderNumberOfSamples()}
            <Button
              id="editSelectedItems"
              data-id="Edit Samples"
              className={classes.defaultButton}
              startIcon={<EditIcon />}
              onClick={this.onEditItemsClick}
            >
              Edit Samples
            </Button>
            <div className={classes.toolBar}>
              <Button
                id="printLabel"
                data-id="PRINTLABEL"
                className={classes.defaultButton}
                onClick={this.handleGenerateBarcode}
                startIcon={<Print />}
              >
                PRINT LABEL
              </Button>
              {userLocation === 'Royalston - Samples & Props' ||
              userLocation === 'Target Plaza' ? (
                <div className={classes.defaultButton1}>
                  {'FORMAT: '}
                  {barcodeType === 'DYMO' ? 'Dymo Printer' : 'Label Paper'}
                </div>
              ) : null}
            </div>
            {this.renderCopyToClipboard()}
            {auth.isAuthorized(apiConfig.roles.admin) && (
              <div>
                <Button
                  className={classes.defaultButton}
                  color="primary"
                  onClick={this.handleCancelItems}
                >
                  Cancel Order
                </Button>
                <Button
                  disabled={this.reverseButtonDisabled()}
                  className={classes.defaultButton}
                  color="primary"
                  onClick={this.reverseStatus}
                >
                  Reverse Status
                </Button>
              </div>
            )}
          </Grid>
        )
      case PROPS:
        return (
          <Grid container className={classes.swipeableDrawer}>
            {this.renderNumberOfSamples()}
            <div className={classes.toolBar}>
              <Button
                id="printLabel"
                data-id="PRINTLABEL"
                className={classes.defaultButton}
                onClick={() => this.handleGenerateBarcodeProps('Big')}
                startIcon={<Print />}
              >
                PRINT LABEL (2 5/16” x 4”)
              </Button>
              {userLocation === 'Royalston - Samples & Props' ||
              userLocation === 'Target Plaza' ? (
                <div className={classes.defaultButton1}>
                  {'FORMAT: '}
                  {barcodeType === 'DYMO' ? 'Dymo Printer' : 'Label Paper'}
                </div>
              ) : null}
            </div>
            <Button
              id="printLabel"
              data-id="PRINTLABEL"
              className={classes.defaultButton}
              onClick={() => this.handleGenerateBarcodeProps('Small')}
              startIcon={<Print />}
            >
              PRINT LABEL (1/2” x 1 7/8”)
            </Button>
            {this.renderCopyToClipboard()}
            <Button
              disabled={this.reverseButtonDisabled()}
              className={classes.defaultButton}
              color="primary"
              onClick={this.reverseStatus}
            >
              Reverse Status
            </Button>
          </Grid>
        )
      case SCENIC:
        return (
          <Grid container className={classes.swipeableDrawer}>
            {this.renderNumberOfSamples()}
            <div className={classes.toolBar}>
              <Button
                id="printLabel"
                data-id="PRINTLABEL"
                className={classes.defaultButton}
                onClick={() => this.handleGenerateBarcodeScenic()}
                startIcon={<Print />}
              >
                PRINT LABEL
              </Button>
              {userLocation === 'Royalston - Samples & Props' ||
              userLocation === 'Target Plaza' ? (
                <div className={classes.defaultButton1}>
                  {'FORMAT: '}
                  {barcodeType === 'DYMO' ? 'Dymo Printer' : 'Label Paper'}
                </div>
              ) : null}
            </div>
            {this.renderCopyToClipboard()}
          </Grid>
        )
      default:
        return <div></div>
    }
  }

  renderElasticSearchBox = () => {
    const { searchQuery } = this.state
    return (
      <Paper
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 'calc(50%)',
          margin: '11px',
          border: '1px solid #BDBDBD',
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          id="searchTextField"
          inputProps={{ 'aria-label': 'search' }}
          value={searchQuery}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
        <IconButton
          type="button"
          aria-label="clearFilters"
          id="clearFilters"
          sx={{ p: '10px' }}
          onClick={this.handleSearchClear}
        >
          <Clear />
        </IconButton>
      </Paper>
    )
  }
  getTabs = (searchPageTabsBasedOnADGroup = []) => {
    const { classes = {}, selectedTab = SAMPLES } = this.props
    return (
      <Grid item xs={5}>
        <Tabs
          value={selectedTab}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          aria-label="project-tabs"
        >
          {searchPageTabsBasedOnADGroup.map((obj) => {
            const { label = '', value = '' } = obj
            return (
              <Tab
                data-cy={label}
                data-testId={label}
                label={label}
                value={value}
                className={classes.tabsWrapper}
                key={label}
              />
            )
          })}
        </Tabs>
      </Grid>
    )
  }

  getFormattedData = (data = []) => {
    const { setCurPropImages = () => {} } = this.props
    const formattedData = cloneDeep(data)?.map((dobj = {}) => {
      const {
        sampleImageDPURL = '',
        sampleImagePopupUrl = [],
        sampleImageDPURLView = '',
        sampleImagePopupUrlView = [],
        type = SAMPLE,
      } = dobj
      return Object.assign({}, dobj, {
        sampleImageDPURL: (
          <ThumbnailBlock
            imgSrc={sampleImageDPURL}
            enablePreview={true}
            val={dobj}
            additionalImagesDisplay={
              sampleImagePopupUrl?.length > 0 ? true : false
            }
            tcinImagesDisplay={
              sampleImagePopupUrl?.filter((obj) => obj.isNotDeleted)?.length >
                0 || type !== SAMPLE
                ? 'isExists' // PUT TCIN image inFront, also used for SCENIC/PROP because they don't have TCIN image
                : 'noExists' // PUT empty image inFront
            }
            additionalImages={sampleImageDPURL}
            previewHandler={async (item = {}) => {
              const { sampleId = '', sampleImagePopupUrl = [] } = item
              await setCurPropImages(sampleImagePopupUrl, sampleId)
              await this.toggleShowPropsImage(item)
            }}
          />
        ),

        sampleImageDPURLView: (
          <ThumbnailBlock
            imgSrc={sampleImageDPURLView}
            enablePreview={true}
            val={dobj}
            additionalImagesDisplay={
              sampleImagePopupUrlView?.length > 0 ? true : false
            }
            additionalImages={sampleImageDPURLView}
            previewHandler={async (item = {}) => {
              const { sampleId = '', sampleImagePopupUrlView = [] } = item
              await setCurPropImages(sampleImagePopupUrlView, sampleId)
              await this.toggleShowPropsImage(item)
            }}
          />
        ),
      })
    })
    return formattedData
  }

  renderBottomBar = () => {
    const {
      classes = {},
      selectedTab = SAMPLES,
      numSelected = Number(0),
    } = this.props
    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={this.toggleOpen()}
        onOpen={() => null}
        onClose={() => null}
        showClearAll={numSelected > 0}
        actionType={
          selectedTab === PROPS
            ? 'REST_ALL_SEARCHINVENTORY_PROPS'
            : selectedTab === SCENIC
              ? 'REST_ALL_SEARCHINVENTORY_SCENIC'
              : 'REST_ALL_SEARCHINVENTORY_SAMPLES'
        }
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          {this.renderSwipeableDrawer()}
        </div>
      </BottomBar>
    )
  }

  render() {
    const {
      availableFilters,
      checkBoxEnabled,
      classes = {},
      clickableColumn,
      data,
      numSelected,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      onClick,
      page,
      popupIsVisible,
      rowsPerPage,
      selectedData,
      showPopup,
      totalRowsCount,
      filterNFList = {},
      fetchDataRequestPending = false,
      pageName = '',
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },

      editColumnData = [],
      alertIsVisible,
      alertMessage,
      response,
      closeAlert = () => {},
      cancelAgree = () => {},
      dialogTitle = '',
      selectedTab = SAMPLES,
      navigate = () => {},
      numSelectedTab = {},
      downloadLoadingStatus = false,
      downloadLoadingStatusPdf = false,
      downloadLoadingStatusXlsx = false,
    } = this.props
    const {
      selectedSettingsCols = [],
      showSettings,
      showEditPopUp,
      filters: stateFilters,
      saveFiltersPopUpIsVisible,
      openFiltersPopUpIsVisible,
      newSamplePopUpIsVisible,
      isAuthorizedToViewProps = false,
      isAuthorizedToViewScenic = false,
      isAdmin = false,

      showPropsImage = false,
      isAuthorizedForPropsAdd = false,
    } = this.state
    const selectedSettingsColsCopy = selectedSettingsCols.filter(
      (obj) => !!obj.default
    )

    const dataFieldKeys = selectedSettingsCols.map((column) => column.id)
    const csvData = new Blob([this.createCsv(selectedData)], {
      type: 'text/csv',
    })
    const csvUrl = URL.createObjectURL(csvData)
    const paramsReturnedFromFilters = paramsFromFilters(stateFilters)
    const {
      requestPayload: {
        include_filters: paramsIncludeFilters = {},
        exclude_filters: paramsExcludeFilters = {},
      },
    } = paramsReturnedFromFilters

    const formattedData = this.getFormattedData(data)
    let searchPageTabsBasedOnADGroup = [...searchPageTabs]
    if (!isAuthorizedToViewProps) {
      searchPageTabsBasedOnADGroup = [{ label: SAMPLES, value: SAMPLES }]
    } else if (isAdmin) {
      searchPageTabsBasedOnADGroup = [...searchPageTabs]
    } else if (isAuthorizedToViewScenic) {
      searchPageTabsBasedOnADGroup = [{ label: SCENIC, value: SCENIC }]
    }

    const moreOptions = []
    switch (selectedTab) {
      case SAMPLES:
        moreOptions.push({
          value: 'convert',
          label: 'Convert to Prop',
          disabled: ({ sample_status }) =>
            sample_status !== 'SAMPLE_RECEIVED' || !isAuthorizedForPropsAdd,
          action: ({ pegasusId }) =>
            navigate(`addProp/convertToProp/${pegasusId}`),
        })
        break
      case PROPS:
        // isAuthorizedForPropsAdd &&
        moreOptions.push(
          {
            value: 'duplicate',
            label: 'Duplicate Prop',
            disabled: ({ sample_status }) =>
              sample_status !== 'SAMPLE_RECEIVED' || !isAuthorizedForPropsAdd,
            action: ({ propId }) => navigate(`addProp/duplicateProp/${propId}`),
          },
          {
            value: 'editProp',
            label: 'Edit Prop',
            disabled: ({ sample_status }) =>
              sample_status !== 'SAMPLE_RECEIVED' || !isAuthorizedForPropsAdd,
            action: ({ pegasusId }) =>
              navigate(`addProp/editProp/${pegasusId}`),
          }
        )
        break
      case SCENIC:
        moreOptions.push(
          {
            value: 'duplicate',
            label: 'Duplicate Senic',
            disabled: ({ sample_status }) =>
              sample_status !== 'SAMPLE_RECEIVED',
            action: ({ pegasusId }) =>
              this.props.history.push(`/searchInventory/scenic/addScenic/duplicateScenic/${pegasusId}`)
          },
          {
            value: 'editScenic',
            label: 'Edit Scenic',
            disabled: ({ sample_status }) =>
              sample_status !== 'SAMPLE_RECEIVED',
            action: ({ pegasusId }) =>
              this.props.history.push(`/searchInventory/scenic/addScenic/editScenic/${pegasusId}`)
          }
        )
        break
      default:
        break
    }

    return (
      <div className={classes.searchInventoryPage}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.root}>
              <div className={classes.pageHeader}>
                <Typography variant="h4" gutterBottom>
                  Search All Samples
                </Typography>
                <div className={classes.pageHeaderButton}>
                  <ShowIf condition={selectedTab === SAMPLES}>
                    <Button
                      id="addASample"
                      color="primary"
                      style={{ marginRight: '15px' }}
                      type="button"
                      variant="contained"
                      onClick={(e) => this.handleAddSamples(e)}
                    >
                      <Add style={{ marginRight: '5px' }} /> Add Samples
                    </Button>
                  </ShowIf>
                  <ShowIf
                    condition={selectedTab === PROPS && isAuthorizedForPropsAdd}
                  >
                    <Button
                      id="addAProp"
                      color="primary"
                      style={{ marginRight: '15px' }}
                      type="button"
                      variant="contained"
                      onClick={(e) => this.handleAddProps(e)}
                    >
                      <Add style={{ marginRight: '5px' }} /> Add Props
                    </Button>
                  </ShowIf>
                  <ShowIf
                    condition={
                      [SCENIC].indexOf(selectedTab) !== -1 &&
                      isAuthorizedToViewScenic
                    }
                  >
                    <Button
                      id="addAProp"
                      color="primary"
                      style={{ marginRight: '15px' }}
                      type="button"
                      variant="contained"
                      onClick={(e) => this.handleAddScenic(e)}
                    >
                      <Add style={{ marginRight: '5px' }} /> Add Scenic
                    </Button>
                  </ShowIf>
                  <ShowIf condition={selectedTab === SAMPLES}>
                    <Button
                      id="launchDateReport"
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={(e) => this.downloadLDReport()}
                    >
                      <GetApp style={{ marginRight: '5px' }} /> Launch date
                      report
                    </Button>
                  </ShowIf>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin}>
            <Paper>
              <Grid container>
                <Grid item xs={12} className={classes.card}>
                  {this.renderElasticSearchBox()}
                </Grid>
                <Grid item xs={12}>
                  {this.renderSaveFilters()}
                  {this.renderFilters()}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin}>
            {this.getNotFoundBlock(filterNFList, stateFilters)}
          </Grid>
          <Grid item xs={12} className={classes.tableTabContainer}>
            <div className={classes.tabContainer}>
              {isAuthorizedToViewScenic ? (
                !isAdmin ? (
                  <div></div>
                ) : (
                  this.getTabs(searchPageTabsBasedOnADGroup)
                )
              ) : (
                this.getTabs(searchPageTabsBasedOnADGroup)
              )}

              <Grid
                item
                xs={7}
                className={
                  selectedTab === SCENIC
                    ? classes.tableTabActionsScenic
                    : classes.tableTabActions
                }
              >
                <ShowIf condition={this.commonDownloadVisible()}>
                  <>
                    <Typography
                      variant="body1"
                      component="body1"
                      className={classes.tabButton}
                    >
                      {isAuthorizedToViewProps && selectedTab !== SCENIC
                        ? `Selected samples (${numSelectedTab['SAMPLES']}) and props (${numSelectedTab['PROPS']})`
                        : selectedTab === SCENIC
                          ? `Selected scenic (${
                              numSelectedTab['SCENIC'] || Number(0)
                            })`
                          : `Selected samples (${numSelectedTab['SAMPLES']})`}
                      {/* ({numSelected}) */}
                    </Typography>

                    <Button
                      href={csvUrl}
                      id="downloadCsv"
                      data-id="EXPORT TO CSV"
                      className={classes.tabButton}
                      startIcon={<GetApp />}
                      download={'Search Inventory.csv'}
                    >
                      CSV
                    </Button>
                    <Button
                      id="downloadContactSheet"
                      className={classes.tabButton}
                      startIcon={<GetApp />}
                      onClick={this.downloadCSSelected.bind(this, 'XLSX')}
                    >
                      Contact Sheet XLSX
                    </Button>
                    <Button
                      id="downloadContactSheet"
                      className={classes.tabButton}
                      startIcon={<GetApp />}
                      onClick={this.downloadCSSelected.bind(this, 'PDF')}
                    >
                      Contact Sheet PDF
                    </Button>
                  </>
                </ShowIf>
              </Grid>
            </div>

            {fetchDataRequestPending ? (
              <CircularPageLoader open={fetchDataRequestPending} />
            ) : (
              <FlexibleTable
                cascadeFieldKeys={[]}
                checkBoxEnabled={checkBoxEnabled}
                columnData={selectedSettingsColsCopy}
                data={formattedData}
                uniqueIdentifierInData={'pegasusId'}
                dataFieldKeys={dataFieldKeys}
                numSelected={numSelected}
                onCheckBoxChange={onCheckBoxChange}
                onCheckBoxHeaderChange={onCheckBoxHeaderChange}
                onClick={onClick}
                onSelected={this.onSelected}
                selected={selectedData}
                clickableColumn={clickableColumn}
                showPopup={showPopup}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={this.setPageAndRefresh}
                setRowsPerPage={this.setRowsPerPageAndRefresh}
                paginationAtApiLevel={true}
                enableSearch
                enableDownload={
                  Object.values(paramsIncludeFilters).length >= 1 ||
                  Object.values(paramsExcludeFilters).length >= 1
                }
                enableContactSheetDownload={
                  Object.values(paramsIncludeFilters).length >= 1 ||
                  Object.values(paramsExcludeFilters).length >= 1
                }
                downloadFunction={this.downloadCSFunction('CSV')}
                downloadCSFunction={this.downloadCSFunction('XLSX')}
                downloadCSFunctionPDF={this.downloadCSFunction('PDF')}
                enableSettings
                enableScrolls
                menuClickFunction={this.handleOptionsClick}
                settingsClickFunction={this.handleSettingClick}
                totalRowsCount={totalRowsCount}
                launchInNewTabColumns={launchInNewTabColumns}
                moreOptions={moreOptions}
                downloadLoadingStatus={downloadLoadingStatus}
                downloadLoadingStatusPdf={downloadLoadingStatusPdf}
                downloadLoadingStatusXlsx={downloadLoadingStatusXlsx}
                // valueToCheckAgainst={selectedData}
              >
                <IconButton style={{ float: 'right' }}>
                  <MoreVert />
                </IconButton>
              </FlexibleTable>
            )}
          </Grid>
          {/*
          {!!showSettings && (
            <PopUp
              title={'Column Settings'}
              isButtonVisible={true}
              onProceed={this.handleSettingClick}
              isVisible={!!showSettings}
              onClose={this.callSetColumnSettings}
              primaryButtonText={'Apply'}
              maxWidth="md"
              fullWidth={true}
              secondaryButtonText="Apply &#38; Save"
              content={this.columnSettingsContent()}
              onPopupClose={this.handleSettingClick}
            /> */}

          {!!showSettings && (
            <SwipeableDrawer
              anchor="right"
              // open={numSelected !== 0}
              open={true}
              onClose={this.handleSettingClick}
            >
              <Box
                sx={{
                  overflowX: 'hidden',
                  padding: '32px',
                }}
                role="presentation"
              >
                {this.columnSettingsContent()}
                {this.columnFooter()}
              </Box>
            </SwipeableDrawer>
          )}
          {!!popupIsVisible && this.renderTabbedPopUp()}
          {!!showEditPopUp && (
            <ItemEditCard
              dataKeyLabels={[]}
              handleSaveClick={this.itemCardSaveClick}
              userId={lanId}
              isVisible={true}
              items={selectedData}
              onClose={this.onEditItemsClick}
              title="Edit Samples"
              editColumnData={editColumnData}
              dataFieldKeys={dataFieldKeys}
              onChangeRowsPerPage={() => {}}
              onPopupChange={() => {}}
              cascadeFieldKeys={[
                'disposition_override_date',
                'dispositionNotes',
                'warehouseNotes',
              ]}
              sourcePage={pageName}
            />
          )}
          {!!alertIsVisible && (
            <DialogBox
              isAlertVisible={alertIsVisible}
              message={alertMessage}
              onClose={closeAlert}
              response={response}
              items={selectedData}
              onAgree={cancelAgree}
              title={dialogTitle}
              primaryButtonText="Yes, Cancel Order"
              secondaryButtonText="Nevermind"
            />
          )}
        </Grid>
        {showPropsImage && (
          <SwipeableDrawer
            anchor="right"
            id="vieworuploadpropimage"
            open={showPropsImage}
          >
            <Box
              sx={{
                width: 750,
              }}
              role="presentation"
            >
              {this.renderPropsImages()}
            </Box>
          </SwipeableDrawer>
        )}

        <ShowIf condition={this.toggleOpen()}>{this.renderBottomBar()}</ShowIf>
        {!!newSamplePopUpIsVisible && (
          <NewSamplePopUp
            isVisible={newSamplePopUpIsVisible}
            onClose={this.handleNewSamplePopupClose}
            duplicateSamples={this.duplicateSamples}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    searchInventory: {
      availableFilters = [],
      checkBoxEnabled = false,
      columnData = [],
      clickableColumn = [],
      data = [],
      numSelected = Number(0),
      onCheckBoxChange = () => {},
      onCheckBoxHeaderChange = () => {},
      onClick = () => {},
      page = 1,
      popupIsVisible = false,
      item = [],
      selectedData = [],
      pageName = '',
      rowsPerPage,
      totalRowsCount,
      filterNFList = {},
      fetchDataRequestPending = false,
      setColumnSettingsPending = false,
      searchedColumns = [],
      isSearchedColumns = false,
      editColumnData = [],
      autoRefreshData = false,
      alertIsVisible = false,
      alertMessage = '',
      response = false,
      dialogTitle = '',
      numSelectedTab = {},
      downloadLoadingStatus = false,
      downloadLoadingStatusPdf = false,
      downloadLoadingStatusXlsx = false,
    },
    addFilters: { currentTabValue = '' },
    user: { barcodeType = '', location = '' },
    newShootType: { channels = [] },
    newShipment: { projectNames = [] },
  } = state
  return {
    availableFilters: availableFilters,
    barcodeType: barcodeType,
    checkBoxEnabled: checkBoxEnabled,
    columnData: columnData,
    clickableColumn: clickableColumn,
    channels: channels,
    data: data,
    userLocation: location,
    numSelected: numSelected,
    onCheckBoxChange: onCheckBoxChange,
    onCheckBoxHeaderChange: onCheckBoxHeaderChange,
    onClick: onClick,
    page: page,
    popupIsVisible: popupIsVisible,
    popupItem: item,
    selectedData: selectedData,
    filters: selectPageFilters(pageName)(state),
    currentTabValue: currentTabValue,
    rowsPerPage: rowsPerPage,
    totalRowsCount: totalRowsCount,
    filterNFList: filterNFList,
    projectNames: projectNames,
    fetchDataRequestPending: fetchDataRequestPending,
    setColumnSettingsPending: setColumnSettingsPending,
    searchedColumns: searchedColumns,
    isSearchedColumns: isSearchedColumns,
    pageName: pageName,
    editColumnData: editColumnData,
    autoRefreshData: autoRefreshData,
    alertIsVisible: alertIsVisible,
    alertMessage: alertMessage,
    response: response,
    dialogTitle: dialogTitle,
    numSelectedTab: numSelectedTab,
    downloadLoadingStatus: downloadLoadingStatus,
    downloadLoadingStatusPdf: downloadLoadingStatusPdf,
    downloadLoadingStatusXlsx: downloadLoadingStatusXlsx,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cancelAgree: (selectedData) => dispatch(cancelOrderedSample(selectedData)),
    clearSearchCallback: () => {
      dispatch(clearSearchData())
    },
    closeAlert: () => dispatch(closeAlert()),
    getBarcodes: (samples, showBarcodesCallback, type) => {
      dispatch(getBarcodes(samples, showBarcodesCallback, type))
    },
    getBarcodesProps: (samples, showBarcodesCallback, type, format) => {
      dispatch(getBarcodesProps(samples, showBarcodesCallback, type, format))
    },
    getBarcodesScenic: (sample, showBarcodesCallback, type) => {
      dispatch(getBarcodesScenic(sample, showBarcodesCallback, type))
    },
    getDuplicateBarcode: (samples, type) =>
      dispatch(getDuplicateBarcode(samples, type)),
    getProjectChannel: () => dispatch(getProjectChannel()),
    closePopup: () => {
      dispatch(closePopup())
    },
    downloadContactSheet: (params, downloadType = '', itemType = SAMPLE) => {
      dispatch(downloadContactSheet(params, downloadType, itemType))
    },
    downloadLaunchDateReport: () => {
      dispatch(downloadLaunchDateReport())
    },
    duplicateSamples: (samples) => dispatch(duplicateSamples(samples)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
    setColumnSettings: (
      data = [],
      lanId = '',
      callBack = () => {},
      selectedTab = SAMPLE
    ) => dispatch(setColumnSettings(data, lanId, callBack, selectedTab)),
    getColumnSettings: (selectedTab) =>
      dispatch(getColumnSettings(selectedTab)),
    resetIsCallSearchFields: () => dispatch(resetIsCallSearchFields()),
    fetchData: (
      params,
      successCallback = () => {},
      failCallback = () => {},
      sampleType = SAMPLE
    ) => {
      dispatch(getData(params, successCallback, failCallback, sampleType))
    },
    getFilters: () => dispatch(getFilters()),
    getVendorEmailsById: (ids = []) => dispatch(getVendorEmailsById(ids)),
    onCheckBoxChange: (selected) => dispatch(setCheckBox(selected)),
    onCheckBoxHeaderChange: (selectedList = []) =>
      dispatch(setCheckBoxHeaderChange(selectedList)),
    saveFilters: (filterRequest) => dispatch(saveFilters(filterRequest)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setFilters: (filters, pageName) => dispatch(setFilters(filters, pageName)),
    setSearchCurrentTab: (tabName = '') =>
      dispatch(setSearchCurrentTab(tabName)),
    setBarcodeType: (barcodeType) => dispatch(setBarcodeType(barcodeType)),
    showPopup: (item) => dispatch(showPopup(item)),
    handleChipClick: (chip) => {
      copy(chip.target.innerText)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    emptySelectedData: () => dispatch(emptySelectedData()),
    autoRefreshSearch: (pending = false) =>
      dispatch(autoRefreshSearch(pending)),
    saveEditInformation: (
      itemInformation,
      userId,
      params,
      successCallBack = () => {}
    ) =>
      dispatch(updateSamples(itemInformation, userId, params, successCallBack)),
    showAlert: (selectedData = []) => dispatch(showAlert(selectedData)),
    setCurPropImages: (propImages = [], sampleId = '') =>
      dispatch(setCurPropImages(propImages, sampleId)),
    reverseSampleStatus: (pegasusIds = [], selectedTab) =>
      dispatch(reverseSampleStatus(pegasusIds, selectedTab)),
    downloadLoading: (data, downloadType) =>
      dispatch(downloadLoading(data, downloadType)),
  }
}

SearchInventoryPage.propTypes = {
  availableFilters: propTypes.array,
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  data: propTypes.array,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onClick: propTypes.func,
  page: propTypes.number,
  rowsPerPage: propTypes.number,
  filterNFList: propTypes.object,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(SearchInventoryPage)
