import * as types from './actionType'
import * as locationAPI from '../../services/locationService'
import * as locationMapper from '../../mappers/locationMapper'
import { showNotification } from '../../store/notification/actionCreator'

export function setSelectedSection(section = '') {
  return {
    type: types.SETTINGS_PAGE_SET_SECTION,
    payload: { section: section },
  }
}

export function setDefaultShipTo(shipToLocation) {
  return {
    type: types.SETTINGS_PAGE_SET_DEFAULT_SHIP_TO,
    payload: { shipToLocation: shipToLocation },
  }
}

export function getCheckInLocations() {
  return (dispatch) => {
    dispatch(getCheckInLocationsPending(true))
    return locationAPI
      .getCheckInLocation()
      .then((res) => locationMapper.locationsResponseToData(res.data.buildings))
      .then((checkInLocations) => {
        dispatch(getCheckInLocationsSuccess(checkInLocations))
        dispatch(getCheckInLocationsPending(false))
      })
      .catch(() => {
        dispatch(getCheckInLocationsFailure())
        dispatch(getCheckInLocationsPending(false))
      })
  }
}

function getCheckInLocationsPending(pending = false) {
  return {
    type: types.CHECKIN_LOCATIONS_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

function getCheckInLocationsFailure() {
  return {
    type: types.CHECKIN_LOCATIONS_REQUEST_FAILURE,
  }
}

function getCheckInLocationsSuccess(checkInLocations = []) {
  return {
    type: types.CHECKIN_LOCATIONS_REQUEST_SUCCESS,
    payload: { checkInLocations: checkInLocations },
  }
}

export function downloadCheckinLocations() {
  return (dispatch) => {
    return locationAPI
      .getCheckInLocation()
      .then((response) => response.data.buildings)
      .then((buildings) => {
        downloadCSV(buildings)
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while trying to download'
          )
        )
      })
  }
}

const downloadCSV = (response) => {
  var file = new Blob([createCsv(response)], {
    type: 'application/csv',
  })
  var fileURL = window.URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.setAttribute('download', 'ShipToLocation.csv')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const createCsv = (data) => {
  let returnString = ['Building,Location']

  data.map((location) =>
    returnString.push(
      `${'"' + location.building + '","' + location.address + '"'}`
    )
  )
  return returnString.join('\n')
}
