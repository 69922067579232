import * as itemApi from '../../services/itemService'
import * as inventoryApi from '../../services/inventoryService'
import * as ordersApi from '../../services/ordersService'
import * as shipmentApi from '../../services/shipmentService'
import * as barcodeApi from '../../services/barcodeService'
import * as userApi from '../../services/userService'
import * as inventoryMapper from '../../mappers/inventoryMapper'
import * as barcodeMapper from '../../mappers/barcodeMapper'
import * as orderMapper from '../../mappers/OrderMapper'
import * as shipmentMapper from '../../mappers/shipmentMapper'
import * as itemMapper from '../../mappers/itemMapper'
import * as types from './actionType'
import { submitOrderRequestPending } from '../order/actionCreator'
import { showNotification } from '../notification/actionCreator'
import { isEmpty } from 'lodash'
export function saveFilters(filterRequest) {
  return (dispatch) => {
    dispatch(saveFiltersPending(true))
    return inventoryApi
      .saveFilters(inventoryMapper.filtersToFiltersRequest(filterRequest))
      .then((response) => {
        dispatch(
          saveFiltersSuccess(inventoryMapper.filtersResponseToFilters(response))
        )
      })
      .catch((err) => {
        dispatch(saveFiltersFailure())
      })
      .finally(() => {
        dispatch(saveFiltersPending(false))
      })
  }
}

export function saveFiltersPending(pending = false) {
  return {
    type: types.MY_ORDERS_SAVE_FILTERS_PENDING,
    payload: { pending: pending },
  }
}

export function saveFiltersSuccess(filters = {}) {
  return {
    type: types.MY_ORDERS_SAVE_FILTERS_SUCCESS,
    payload: { filters: filters },
  }
}

export function saveFiltersFailure() {
  return {
    type: types.MY_ORDERS_SAVE_FILTERS_FAILURE,
  }
}

export function setFilters(filters) {
  return {
    type: types.MY_ORDERS_SET_FILTERS,
    payload: { data: filters },
  }
}

export function getFilters() {
  return (dispatch) => {
    dispatch(getFiltersPending(true))
    return inventoryApi
      .getFilters()
      .then((response) => {
        dispatch(
          getFiltersSuccess(inventoryMapper.filtersResponseToFilters(response))
        )
      })
      .catch((err) => {
        dispatch(getFiltersFailure())
      })
      .finally(() => {
        dispatch(getFiltersPending(false))
      })
  }
}

export function getFiltersSuccess(filters = {}) {
  return {
    type: types.MY_ORDERS_GET_FILTERS_SUCCESS,
    payload: { availableFilters: filters },
  }
}

export function getFiltersPending(pending = false) {
  return {
    type: types.MY_ORDERS_GET_FILTERS_PENDING,
    payload: { pending: pending },
  }
}

export function getFiltersFailure() {
  return {
    type: types.MY_ORDERS_GET_FILTERS_FAILURE,
  }
}

export function submitOrder(data = []) {
  return {
    type: types.MY_ORDERS_SUBMIT_ORDER,
    payload: { data: data },
  }
}

export function setData(data = []) {
  return {
    type: types.MY_ORDERS_SET_DATA,
    payload: { data: data },
  }
}

export function showPopup(item) {
  delete item.shipping_info
  return {
    type: types.SHOW_MY_ORDERS_PAGE_POPUP,
    payload: { item: item },
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_MY_ORDERS_PAGE_POPUP,
  }
}

export function setCheckBox(selectedData) {
  delete selectedData.shipping_info
  return {
    type: types.MY_ORDERS_SET_CHECKBOX,
    payload: { selected: selectedData },
  }
}

export function setCheckBoxHeaderChange(selectedData) {
  delete selectedData.shipping_info
  return {
    type: types.MY_ORDERS_SET_CHECKBOX_HEADER,
    payload: { selected: selectedData },
  }
}

// Fetch order information, shipment information, item data, merge, then set data and filter
export function getUserOrdersById(params) {
  return (dispatch, getState) => {
    const currentState = getState() || {}
    const { myOrder: { totalRowsCount: currentTotalRows = Number(0) } = {} } =
      currentState
    const { requestParams = {} } = params
    const currentPage = requestParams.get('page')
    if (currentPage > 1 && Number(currentTotalRows) > Number(100)) {
      params?.requestParams?.append('total', currentTotalRows)
    }
    let filterNFList = {}
    let totalRowsCount
    dispatch(getUserOrderRequestPending(true))
    return ordersApi
      .fetchSamplesByUserPost(params)
      .then((orderResult) => {
        const {
          data: { total_count = 0, filter_list_notfound = {} },
        } = orderResult
        filterNFList = Object.assign({}, filter_list_notfound)
        totalRowsCount = total_count
        return orderMapper.samplesResponseToSamples(orderResult)
      })
      .then((samples) => {
        shipmentApi
          .fetchShipments(
            samples
              .map((it) => it?.shipmentId)
              .filter((shipmentId) => shipmentId)
          )
          .then((shipmentResult) =>
            shipmentMapper.shipmentsResponseToShipments(
              shipmentResult.data.shipments
            )
          )
          .then((shipments) => {
            const listOfTcins = samples
              .map((it) => it.tcin)
              .filter((tcin) => !!tcin)
            const finalListOfTcins = Array.from(new Set([...listOfTcins]))
            let shipmentMap = new Map(
              shipments.map((shipment) => [shipment.shipmentId, shipment])
            )
            let orderResults = [].concat.apply(
              samples.map((order) =>
                Object.assign({}, order, shipmentMap.get(order.shipmentId))
              )
            )

            if (isEmpty(finalListOfTcins) || !finalListOfTcins[0]) {
              // Handling for props with no tcins
              dispatch(getUserOrderRequestSuccess(orderResults))
              dispatch(setFilterNFList(filterNFList))
              dispatch(setTotalRowsCount(totalRowsCount))
            } else {
              itemApi
                .getItemsGraphQL(finalListOfTcins)
                .then((itemResults) =>
                  itemMapper.itemsResponseToItems(itemResults)
                )
                .then((items) => {
                  let itemMap = new Map(
                    items.map((item) => [item?.tcin?.toString(), item])
                  )
                  orderResults = [].concat.apply(
                    orderResults.map((order) =>
                      Object.assign(
                        {},
                        order,
                        itemMap.get(order?.tcin?.toString())
                      )
                    )
                  )
                  dispatch(getUserOrderRequestSuccess(orderResults))
                  dispatch(setFilterNFList(filterNFList))
                  dispatch(setTotalRowsCount(totalRowsCount))
                })
            }
          })
      })
      .catch((err) => {
        dispatch(getUserOrderRequestFailure())
      })
      .finally(() => {
        dispatch(getUserOrderRequestPending(false))
      })
  }
}

export function setFilterNFList(filterNFList = {}) {
  return {
    type: types.MY_ORDERS_SET_FILTER_NOTFOUND_LIST,
    payload: { filterNFList: filterNFList },
  }
}

export function getUserOrderRequestPending(pending = false) {
  return {
    type: types.MYORDER_USER_ORDER_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function getUserOrderRequestFailure() {
  return {
    type: types.MYORDER_USER_ORDER_REQUEST_FAILURE,
  }
}

export function getUserOrderRequestSuccess(data = []) {
  return {
    type: types.MYORDER_USER_ORDER_REQUEST_SUCCESS,
    payload: { data: data },
  }
}

// Cancel sample requests
export function cancelSample(selectedData) {
  return (dispatch) => {
    dispatch(cancelSampleRequestPending(true))
    return ordersApi
      .cancelSamples(
        selectedData.map((it) => it.pegasusId.slice(2, it.pegasusId.length))
      )
      .then((cancelResult) => {
        dispatch(cancelSampleRequestSuccess(selectedData))
        dispatch(closeAlert())
      })
      .catch(() => {
        dispatch(cancelSampleRequestFailure())
      })
      .finally(() => {
        dispatch(cancelSampleRequestPending(false))
      })
  }
}

export function cancelSampleRequestPending(pending = false) {
  return {
    type: types.CANCEL_SAMPLE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function cancelSampleRequestFailure() {
  return {
    type: types.CANCEL_SAMPLE_REQUEST_FAILURE,
  }
}

export function cancelSampleRequestSuccess(data = []) {
  return {
    type: types.CANCEL_SAMPLE_REQUEST_SUCCESS,
    payload: { data: data },
  }
}

export function showAlert(message) {
  return {
    type: types.MY_ORDERS_PAGE_ALERT,
    payload: { message: message },
  }
}

export function closeAlert() {
  return {
    type: types.CLOSE_MY_ORDERS_PAGE_ALERT,
  }
}

export function setQuantity(event) {
  return {
    type: types.MY_ORDERS_SET_QUANTITY,
    payload: { event: event },
  }
}

export function setDate(event) {
  return {
    type: types.MY_ORDERS_SET_DATE,
    payload: { event: event },
  }
}

export function setShipTo(event) {
  return {
    type: types.MY_ORDERS_SET_SHIP_TO,
    payload: { event: event },
  }
}

export function setVendorNotes(event) {
  return {
    type: types.MY_ORDERS_SET_VENDOR_NOTES,
    payload: { event: event },
  }
}

export function setStudioNotes(event) {
  return {
    type: types.MY_ORDERS_SET_STUDIO_NOTES,
    payload: { event: event },
  }
}

export function openEdit(item) {
  delete item.shipping_info
  return {
    type: types.MY_ORDERS_PAGE_OPEN_EDIT,
    payload: item,
  }
}

export function closeEdit() {
  return {
    type: types.MY_ORDERS_PAGE_CLOSE_EDIT,
  }
}

export function updateSamples(
  updateData = [],
  userId,
  params,
  callback = () => {}
) {
  return (dispatch) => {
    dispatch(updateSamplesRequestPending(true))
    ordersApi
      .updateOrder(orderMapper.samplesToSampleUpdateRequestMyorder(updateData))
      .then((res) => {
        dispatch(getUserOrdersById(params))
        callback()
        dispatch(
          showNotification(true, 'Samples updated successfully', 'success')
        )
      })
      .catch(() => {
        dispatch(updateSamplesRequestFailure())
        dispatch(submitOrderRequestPending(false))
        dispatch(
          showNotification(
            true,
            'We encountered an error while updating the samples'
          )
        )
      })
      .finally(() => {
        dispatch(updateSamplesRequestPending(false))
      })
  }
}

export function updateSamplesRequestPending(pending = false) {
  return {
    type: types.MYORDER_UPDATE_SAMPLES_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function updateSamplesRequestFailure(failure = false) {
  return {
    type: types.MYORDER_UPDATE_SAMPLES_REQUEST_FAILURE,
  }
}

export function cancelAgree(cancelItems) {
  return {
    type: types.CLOSE_MY_ORDERS_PAGE_CANCEL_ITEMS,
    payload: cancelItems,
  }
}

export function duplicateSamples(sample = {}) {
  return (dispatch) => {
    dispatch(duplicateSamplesPending(true))
    return itemApi
      .getItemsGraphQL([sample.itemId])
      .then((itemResponse) => itemMapper.itemsResponseToItems(itemResponse))
      .then((items) => {
        let itemMap = new Map(items.map((item) => [item.tcin.toString(), item]))
        let orderResults = Object.assign(
          {},
          sample,
          itemMap.values().next().value
        )
        return userApi.getUser(sample.userId).then((userInfo) => {
          let finalRequest = Object.assign({}, orderResults, {
            ...orderResults,
            location: userInfo.data.default_location,
            shipTo: userInfo.data.default_location,
          })
          return ordersApi
            .duplicateSamples(
              orderMapper.sampleToSampleCreateRequest(finalRequest)
            )
            .then((res) => orderMapper.samplesResponseToSamples(res))
            .then((sampleResponse) => {
              return sampleResponse.map((s) =>
                Object.assign({}, s, itemMap.get(s.tcin.toString()), {
                  ...s,
                  generalDescription:
                    items.find((sam) => sam.tcin === s.tcin.toString())
                      .description || '',
                })
              )
            })
            .then((result) => {
              dispatch(duplicateSamplesSuccess(result))
              return result
            })
            .catch(() => {
              dispatch(duplicateSamplesFailure())
            })
            .finally(() => {
              dispatch(duplicateSamplesPending(false))
            })
        })
      })
  }
}

export function duplicateSamplesPending(pending = false) {
  return {
    type: types.MY_ORDERS_DUPLICATE_SAMPLES_PENDING,
    payload: { pending: pending },
  }
}

export function duplicateSamplesSuccess(response = []) {
  return {
    type: types.MY_ORDERS_DUPLICATE_SAMPLES_SUCCESS,
    payload: { samples: response },
  }
}

export function duplicateSamplesFailure() {
  return {
    type: types.MY_ORDERS_DUPLICATE_SAMPLES_FAILURE,
  }
}

export function getBarcodes(samples = []) {
  return (dispatch) => {
    return barcodeApi
      .getBarcode(barcodeMapper.samplesToBarcodeRequest(samples))
      .then((barcodeResult) => barcodeResult.data)
      .then((barcodePdf) => {
        return URL.createObjectURL(
          new Blob([barcodePdf], { type: 'application/pdf' })
        )
      })
      .catch(() => {
        dispatch(
          showNotification(
            true,
            "Couldn't retrieve barcodes at this time.  Please try again."
          )
        )
      })
  }
}

export function setPage(page) {
  return {
    type: types.MY_ORDERS_SET_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage) {
  return {
    type: types.MY_ORDERS_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function setTotalRowsCount(totalRowsCount = 0) {
  return {
    type: types.MY_ORDERS_SET_TOTAL_ROWS_COUNT,
    payload: { totalRowsCount: totalRowsCount },
  }
}

export function emptySelectedData() {
  return {
    type: types.MY_ORDERS_EMPTY_SELECTED_DATA,
  }
}
