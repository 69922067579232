import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

export default function EditButtons(props) {
  const {
    onCloseClick = () => {},
    onSaveClick = () => {},
    saveLabel = '',
    closeLabel = '',
  } = props
  return (
    <Grid>
      <Button color="secondary" onClick={() => onCloseClick(false)}>
        {closeLabel}
      </Button>
      <Button color="primary" onClick={() => onSaveClick()}>
        {saveLabel}
      </Button>
    </Grid>
  )
}
