import { useReducer, useEffect, useState, startTransition } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import clsx from 'classnames'
import Button from '@mui/material/Button'
import { Divider, withStyles } from '@material-ui/core'
import { isEmpty, isNull, filter } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import mime, { load } from 'mime'
import * as inventoryApi from '../../../services/inventoryService'
import { NewScenicRoomStyles } from './NewScenicRoomStyles'
import ShowIf from 'components/ShowIf'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { fetchScenicMetadata } from 'services/inventoryService'
import UploadAndDisplayImage from 'components/UploadAndDisplayImage'
import { showNotification } from 'store/notification/actionCreator'
import CircularPageLoader from 'components/Loader/CircularPageLoader'
import { getAllBrands } from '../../../services/inventoryService'

const NewScenicRoomForm = ({
  classes,
  scenicRoomData = {},
  handleScenicRoomUpdate,
  onCancel,
  isCreatePage = false,
  scenicRoomUpdateRequestPending = false,
}) => {
  const [scenicRoomState, dispatch] = useReducer(reducer, initialState)
  const [loading, setLoading] = useState(true)
  const [disbaledScenicImage, setDisableScenicImage] = useState(true)
  useEffect(() => {
    getAllBrands()
    fetchData()
  }, [])
  const { selectedData } = useSelector(
    ({ scenicRoomItems: { selectedData = [] } }) => {
      return { selectedData }
    }
  )
  const dispatchAction = useDispatch()

  useEffect(() => {
    if (selectedData.length === 1 && !isEmpty(selectedData[0].sampleImageUrl)) {
      setDisableScenicImage(false)
    } else {
      setDisableScenicImage(true)
    }
  }, [selectedData])
  useEffect(() => {
    if (!isEmpty(scenicRoomData) && !isEmpty(scenicRoomState.metaData)) {
      dispatch({
        type: PREPOPULATE_WITH_DATA,
        data: { scenicRoomData },
      })
      setLoading(false)
    }
  }, [scenicRoomData, scenicRoomState.metaData, scenicRoomState.brandOptions])

  useEffect(() => {
    if (scenicRoomData?.roomImageUrl) {
      uploadScenicAssetImage(false)
    }
  }, [scenicRoomData])

  const fetchData = async () => {
    setLoading(true)
    try {
      const { data = {} } = await fetchScenicMetadata()
      dispatch({ type: SET_META_DATA, data })
      setLoading(false)
    } catch (e) {
      console.error(e)
      dispatchAction(
        showNotification(
          true,
          'There has been an error while fetching category data',
          'error'
        )
      )
    }
  }
  useEffect(() => {
    const fetchbrand = async () => {
      setLoading(true)
      try {
        const { data = {} } = await getAllBrands()
        dispatch({ type: SET_BRANDS, data })
      } catch (e) {
        console.error(e)
        dispatchAction(
          showNotification(
            true,
            'There has been an error while fetching brands data',
            'error'
          )
        )
      }
    }
    fetchbrand()
  }, [])
  const handleFieldChange = (event, fieldName) => {
    const newValue = event.target.value
    dispatch({
      type: UPDATE_FIELD,
      data: { fieldName, newValue },
    })
  }

  const uploadScenicAssetImage = async (selectedDataImage = false) => {
    let selectedDataPrimaryImage =
      selectedDataImage &&
      selectedData[0]?.scenicImageUrl.filter((obj) => obj.primary)
    let url = selectedDataImage
      ? selectedDataPrimaryImage[0]?.image_url
      : scenicRoomData?.roomImageUrl || ''

    try {
      inventoryApi.downloadImage(url).then(async (response) => {
        const contentType = response.headers.get('content-type')
        const blob = await response.blob()
        const fileExtension = mime.getExtension(contentType)
        const fileName = `new-${Math.random()}.${fileExtension}`
        const file = new File([blob], fileName, { contentType })
        setSelectedImage(file)
      })
    } catch (e) {
      console.error(e)
    }
  }

  const setSelectedImage = (file, size) => {
    if (Math.round(size / 1024) > 2048) {
      dispatch({
        type: FILE_SIZE_ERROR,
        data: true,
      })
    } else {
      dispatch({
        type: UPDATE_FIELD,
        data: { fieldName: 'selectedImage', newValue: file },
      })
      dispatch({
        type: FILE_SIZE_ERROR,
        data: false,
      })
    }
  }

  const doesAllRequiredFieldsExists =
    !!scenicRoomState.roomName &&
    !!scenicRoomState.selectedCateogory &&
    !!scenicRoomState.selectedSubCateogory &&
    !isEmpty(scenicRoomState.selectedCateogory) &&
    !isEmpty(scenicRoomState.selectedSubCateogory)
  return (
    <Grid container spacing={2}>
      {loading ? (
        <CircularPageLoader open />
      ) : (
        <>
          <Grid item xs={3}>
            <TextField
              className={clsx(classes.textField)}
              id="roomName"
              inputProps={{
                maxLength: 100,
              }}
              placeholder="Room Name"
              label="Room Name"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={scenicRoomState.roomName || ''}
              onChange={(event) => handleFieldChange(event, 'roomName')}
              error={!scenicRoomState.roomName}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              onChange={(e, selected) =>
                dispatch({
                  type: UPDATE_CATEGORY,
                  data: selected,
                })
              }
              value={scenicRoomState.selectedCateogory || {}}
              options={scenicRoomState.categoryOptions || []}
              getOptionLabel={(category) => category.name || ''}
              disabled={!scenicRoomState.categoryOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  variant="outlined"
                  error={
                    !scenicRoomState.categoryOptions ||
                    isEmpty(scenicRoomState.selectedCateogory)
                  }
                  required={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              onChange={(e, selected) =>
                dispatch({
                  type: UPDATE_FIELD,
                  data: {
                    fieldName: 'selectedSubCateogory',
                    newValue: selected,
                  },
                })
              }
              value={scenicRoomState.selectedSubCateogory || {}}
              options={scenicRoomState.subCategoryOptions || []}
              getOptionLabel={(subCategory) => subCategory.name || ''}
              disabled={!scenicRoomState.subCategoryOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sub Category"
                  variant="outlined"
                  error={
                    !scenicRoomState.subCategoryOptions ||
                    isEmpty(scenicRoomState.selectedSubCateogory)
                  }
                  required
                />
              )}
            />
            {/* <ImageUploadContainer
          selectedFiles={acceptedFiles}
          onDropFiles={onDrop}
          currentPropImages={currentPropImages}
        /> */}
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              onChange={(e, selected) =>
                dispatch({
                  type: UPDATE_FIELD,
                  data: { fieldName: 'selectedSource', newValue: selected },
                })
              }
              value={scenicRoomState.selectedSource || {}}
              options={scenicRoomState.sourceOptions || []}
              getOptionLabel={(source) => source.source_name || ''}
              disabled={!scenicRoomState.sourceOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source"
                  variant="outlined"
                  error={!scenicRoomState.sourceOptions}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              onChange={(e, selected) =>
                dispatch({
                  type: UPDATE_FIELD,
                  data: { fieldName: 'selectedBrand', newValue: selected },
                })
              }
              value={scenicRoomState.selectedBrand || {}}
              options={scenicRoomState?.brandOptions || []}
              getOptionLabel={(brand) => brand || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Target Brand"
                  placeholder="Target Brand(s)"
                  variant="outlined"
                  error={!scenicRoomState.brandOptions}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            {/* eslint-disable react/jsx-no-duplicate-props */}
            <TextField
              className={classes.textField}
              id="scenicRoomNotes"
              InputProps={{
                classes: {
                  input: classes.notesTextField,
                },
              }}
              inputProps={{
                maxLength: 250,
              }}
              label="Room Notes"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginBottom: '14px' }}
              multiline
              placeholder="Room Notes"
              variant="outlined"
              value={scenicRoomState.notes}
              maxRows={3}
              onChange={(event) => handleFieldChange(event, 'notes')}
            />
          </Grid>
        </>
      )}

      {/* <Grid item xs={1} /> */}

      <>
        {loading ? (
          <CircularPageLoader open />
        ) : (
          <Grid item xs={5}>
            <UploadAndDisplayImage
              selectedImage={scenicRoomState.selectedImage}
              setSelectedImage={setSelectedImage}
              disbaledScenicImage={disbaledScenicImage}
              uploadScenicAssetImage={uploadScenicAssetImage}
              fileSizeError={scenicRoomState?.fileSizeError}
            />
          </Grid>
        )}
      </>
      <ShowIf condition={!isCreatePage}></ShowIf>
      <Grid item xs={9}>
        <Divider style={{ margin: '10px 0 10px 0' }} />
      </Grid>

      <Grid container xs={9} spacing={4} justifyContent="flex-end">
        <Grid item xs={1}>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            CANCEL
          </Button>
        </Grid>
        <Grid item xs={9}>
          <Button
            data-id={isCreatePage ? 'PENDING' : 'EDITED'}
            variant="contained"
            color="primary"
            disabled={!doesAllRequiredFieldsExists}
            onClick={(e) => {
              setLoading(true)
              handleScenicRoomUpdate(e, { ...scenicRoomState })
            }}
          >
            {isCreatePage ? 'Create ROOM' : 'SAVE ROOM INFO'}
          </Button>
        </Grid>
      </Grid>
      {/* eslint-enable react/jsx-no-duplicate-props */}
    </Grid>
  )
}

const initialState = {
  metaData: {},
  categoryOptions: [],
  subCategoryOptions: [],
  selectedCateogory: {},
  selectedSubCateogory: {},
  selectedSource: {},
  selectedBrand: '',
  selectedImage: null,
}

/**
 * Reducer to update scenic room creation local state data
 * @param {*} state
 * @param {*} action
 * @returns
 */
const reducer = (state, action) => {
  const { type = '', data = {} } = action

  switch (type) {
    case UPDATE_FIELD:
      return { ...state, [data.fieldName]: data.newValue }
    case SET_META_DATA: {
      return {
        ...state,
        metaData: data,
        categoryOptions: data?.categories,
        sourceOptions: data?.sources,
        // brandOptions: data?.brand_names,
      }
    }
    case SET_BRANDS: {
      return {
        ...state,
        brandOptions: data?.brand_names,
        loading: !state.loading,
      }
    }
    case UPDATE_CATEGORY: {
      const subCategoryOptions = data?.scenic_sub_categories || []
      return {
        ...state,
        selectedCateogory: data,
        subCategoryOptions,
        selectedSubCateogory: {},
      }
    }
    case PREPOPULATE_WITH_DATA: {
      const { scenicRoomData } = data
      const selectedCateogory = state?.metaData.categories?.find(
        (category) => category.category_id === scenicRoomData.roomCategory
      )
      const selectedSubCateogory =
        selectedCateogory?.scenic_sub_categories?.find(
          (subCategory) =>
            subCategory.sub_category_id === scenicRoomData.roomSubCategory
        )
      const selectedSource = state?.metaData.sources?.find(
        (source) => source.source_id === scenicRoomData.source
      )
      const selectedBrand = state?.brandOptions?.find(
        (brand) => brand === scenicRoomData.scenicBrand
      )

      return {
        ...state,
        roomName: scenicRoomData.roomName,
        selectedCateogory,
        selectedSubCateogory,
        selectedBrand,
        subCategoryOptions: selectedCateogory?.scenic_sub_categories || [],
        selectedSource,
        notes: scenicRoomData.notes,
        image_url: scenicRoomData.roomImageUrl,
      }
    }
    case FILE_SIZE_ERROR: {
      return {
        ...state,
        fileSizeError: data,
      }
    }
    default:
      return state
  }
}

const UPDATE_FIELD = 'UPDATE_FIELD'
const SET_META_DATA = 'SET_META_DATA'
const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
const PREPOPULATE_WITH_DATA = 'PREPOPULATE_WITH_DATA'
const FILE_SIZE_ERROR = 'FILE_SIZE_ERROR'
const SET_BRANDS = 'SET_BRANDS'

export default withStyles(NewScenicRoomStyles)(NewScenicRoomForm)
