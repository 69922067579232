import * as types from './actionType'

export const initialState = {
  availableFilters: [],
  pageFilters: {},
  filterNFList: {},
  saveFiltersFailure: false,
  saveFiltersPending: false,
  getFiltersFailure: false,
  getFiltersPending: false,
  isSavedFilters: false,
  saveFiltersData: {},
  currentTabValue: '',
}

export default function manageFilters(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_SEARCH_INVENTORY_CURRENT_TAB: {
      const { payload: { tabName = '' } = {} } = action
      return Object.assign(
        {},
        {
          ...state,
          currentTabValue: tabName,
        }
      )
    }
    case types.SET_FILTERS: {
      const { payload: { data: payloadData = {} } = {} } = action
      const { pageFilters: stateFilters = {} } = state
      const stateFiltersCopy = Object.assign({}, stateFilters)
      return Object.assign(
        {},
        {
          ...state,
          pageFilters: { ...stateFiltersCopy, ...payloadData },
        }
      )
    }
    case types.SET_FILTERS_AUTO_ORDER: {
      const { payload: { pageName = '', filters = {} } = {} } = action
      const stateCopy = Object.assign({}, state)
      const { pageFilters: stateFilters = {} } = stateCopy
      return Object.assign({}, state, {
        ...stateCopy,
        pageFilters: Object.assign(
          {},
          { ...stateFilters },
          {
            [pageName]: Object.assign({}, filters),
          }
        ),
      })
    }
    case types.SAVE_FILTERS_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        saveFiltersPending: false,
        saveFiltersFailure: false,
      })
    }
    case types.SAVE_FILTERS_DATA: {
      return Object.assign({}, state, {
        saveFiltersData: action.payload.params,
      })
    }
    case types.SAVE_FILTERS_FAILURE: {
      return Object.assign({}, state, {
        saveFiltersFailure: true,
      })
    }
    case types.SAVE_FILTERS_PENDING: {
      return Object.assign({}, state, {
        saveFiltersPending: action.payload.pending,
      })
    }
    case types.GET_FILTERS_SUCCESS: {
      const { payload: { availableFilters = [], pageName = '' } = {} } = action
      const { availableFilters: stateAvailableFilters = {} } = state
      return Object.assign({}, state, {
        ...state,
        availableFilters: Object.assign({}, stateAvailableFilters, {
          [pageName]: availableFilters,
        }),
      })
    }
    case types.GET_FILTERS_FAILURE: {
      return Object.assign({}, state, {
        getFiltersFailure: true,
      })
    }
    case types.GET_FILTERS_PENDING: {
      return Object.assign({}, state, {
        getFiltersPending: action.payload.pending,
      })
    }
    case types.GET_AUTO_ORDER_FILTERS_SUCCESS: {
      const { payload: { availableFilters = [], pageName = '' } = {} } = action
      const { pageFilters: stateAvailableFilters = {} } = state
      return Object.assign({}, state, {
        ...state,
        pageFilters: Object.assign({}, stateAvailableFilters, {
          [pageName]: availableFilters,
        }),
        isSavedFilters: true,
      })
    }
    case types.SET_IS_SAVED_FILTERS: {
      const { payload: { isSavedFilters } = {} } = action
      return Object.assign({}, state, {
        isSavedFilters: isSavedFilters,
      })
    }
    case types.GET_AUTO_ORDER_FILTERS_FAILURE: {
      return Object.assign({}, state, {
        getFiltersFailure: true,
      })
    }
    case types.GET_AUTO_ORDER_FILTERS_PENDING: {
      return Object.assign({}, state, {
        getFiltersPending: action.payload.pending,
      })
    }
    case types.SET_FILTER_NOTFOUND_LIST: {
      const { payload: { filterNFList = {}, pageName = '' } = {} } = action
      const { filterNFList: stateFilterNFList = {} } = state
      return Object.assign({}, state, {
        ...state,
        filterNFList: Object.assign({}, stateFilterNFList, {
          [pageName]: filterNFList,
        }),
      })
    }
    default:
      return state
  }
}
