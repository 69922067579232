import axios from 'axios'
import apiConfig from '../apiConfig'

export function getBarcode(sampleRequests = [], type = '') {
  const {
    barcode: { url = '' },
    apiKey = '',
  } = apiConfig
  return axios.post(url + 'barcodes', sampleRequests, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      key: apiKey,
      barcode_type: type,
    },
    responseType: 'blob',
  })
}
export function getCombinedBarcodes(sampleRequests = [], type = '') {
  const {
    combinedBarcode: { url = '' },
    apiKey = '',
  } = apiConfig
  return axios.post(url, sampleRequests, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      key: apiKey,
      barcode_type: type,
    },
    responseType: 'blob',
  })
}
export function getBarcodeProps(
  sampleRequests = [],
  type = '',
  label_format = 'Big'
) {
  const {
    barcode: { url = '' },
    apiKey = '',
  } = apiConfig
  return axios.post(url + 'prop_barcodes', sampleRequests, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      key: apiKey,
      barcode_type: type,
      label_format: label_format,
    },
    responseType: 'blob',
  })
}

export function getBarcodeScenic(sampleRequests = [], type = '') {
  const {
    barcode: { url = '' },
    apiKey = '',
  } = apiConfig
  return axios.post(url + 'scenic_barcodes', sampleRequests, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      key: apiKey,
      barcode_type: type,
    },
    responseType: 'blob',
  })
}
