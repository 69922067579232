import NodeContentModule from './NodeContentModule'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { debounce, isEqual, isEmpty } from 'lodash'
import {
  getLLBuildingByLocation,
  getAisles,
  getSections,
  getShelves,
  getContainers,
  getLocationById,
  findLocation,
} from '../../services/locationService'
import * as locationMapper from '../../mappers/locationMapper'

const styles = (theme) => ({
  inputLabel: {
    transform: 'translate(0, 20px) scale(1)',
    '&.shrink': {
      transform: 'translate(0, -6px) scale(0.75)',
    },
  },
  inputLabelControl: {
    margin: 8,
    minWidth: 120,
    width: '165px',
  },
  inputLabelControlAuto: {
    margin: 8,
    minWidth: 120,
    width: 'auto',
  },
  inputLabelControlZero: {
    margin: 8,
    minWidth: 120,
    width: '165px',
  },
  barcodeInput: {
    minWidth: 120,
    width: '165px',
  },
})

class LocationScan extends NodeContentModule {
  constructor(props) {
    super(props)

    this.state = {
      barcode: '',
      values: {},
      buildings: [],
      aisles: [],
      sections: [],
      shelves: [],
      containers: [],
    }
    this.callBarcodeChangeAPI = debounce(this.callBarcodeChangeAPI, 500)
  }

  componentWillMount() {
    this.updateLevels()
  }

  componentDidUpdate(prevProps, prevState) {
    const { resetLocation } = this.props
    const { resetLocation: prevResetLocation } = prevProps
    if (!!resetLocation && !isEqual(prevResetLocation, resetLocation)) {
      this.resetToInitState()
    }
  }

  resetToInitState = () => {
    const { updateLevels = () => {} } = this
    this.setState(
      {
        barcode: '',
        values: {},
        buildings: [],
        aisles: [],
        sections: [],
        shelves: [],
        containers: [],
      },
      () => {
        updateLevels()
      }
    )
  }

  onTransitionForward = (_, transitionAction) => {
    const { onTransitionForward, action } = this.props
    transitionAction()
    onTransitionForward(action(this.state.barcode, this.state.values))
  }

  callBarcodeChangeAPI = async (barcode) => {
    await getLocationById(barcode)
      .then((response) => {
        return locationMapper.locationResponseToOrdered(response.data)
      })
      .catch(() => ({}))
      .then((location) => {
        this.setState(
          {
            values: location || {},
          },
          () => {
            return location ? this.updateLevels(location, null) : () => {}
          }
        )
      })
  }

  handleBarcodeChange = async (event) => {
    const barcode = event && event.target && event.target.value
    const { callBarcodeChangeAPI } = this
    this.setState(
      {
        barcode: barcode,
      },
      () => {
        callBarcodeChangeAPI(barcode)
      }
    )
  }
  handleBarcodeChangePaste = async (event) => {
    event.preventDefault()
    const pastedBarcode = event.clipboardData.getData('text/plain') || ''
    const { callBarcodeChangeAPI } = this
    this.setState(
      {
        barcode: pastedBarcode,
      },
      () => {
        callBarcodeChangeAPI(pastedBarcode)
      }
    )
  }

  handleDropdownChange = (event) => {
    const updatedValues = {
      ...this.state.values,
      [event.target.name]: event.target.value,
    }

    if (event.target.name !== this.state.values[event.target.name]) {
      for (let i = Number(event.target.name) + 1; i < 5; i++) {
        updatedValues[i] = undefined
      }
    }

    // lookup barcode
    findLocation(updatedValues)
      .then((response) => {
        return locationMapper.locationResponseToData(response.data)
      })
      .catch((err) => {
        // don't want 404 errors to be treated as an error
        return err && err.response && err.response.status === 404
          ? null
          : Promise.reject(err)
      })
      .then((location) => {
        this.setState(
          {
            values: updatedValues,
            barcode: (location && location.locationId) || '',
          },
          this.updateLevels(updatedValues, event.target.name)
        )
      })
  }

  updateLevels = (values, updatedField) => {
    const isDisableNext =
      (updatedField === '4' || (!isEmpty(values) && !isEmpty(values['4']))) &&
      this.props.scanType === 'container'
        ? true
        : false
    const getPromises = () => {
      switch (updatedField) {
        case '0': // updated building
          return Promise.all([
            Promise.resolve(this.state.buildings),
            getAisles(values),
            Promise.resolve([]),
            Promise.resolve([]),
            Promise.resolve([]),
          ])
        case '1': // updated aisle
          return Promise.all([
            Promise.resolve(this.state.buildings),
            Promise.resolve(this.state.aisles),
            getSections(values),
            Promise.resolve([]),
            Promise.resolve([]),
          ])
        case '2': // updated section
          return Promise.all([
            Promise.resolve(this.state.buildings),
            Promise.resolve(this.state.aisles),
            Promise.resolve(this.state.sections),
            getShelves(values),
            Promise.resolve([]),
          ])
        case '3': // updated shelf
          return Promise.all([
            Promise.resolve(this.state.buildings),
            Promise.resolve(this.state.aisles),
            Promise.resolve(this.state.sections),
            Promise.resolve(this.state.shelves),
            getContainers(values),
          ])
        case '4': // updated container
          return Promise.all([
            Promise.resolve(this.state.buildings),
            Promise.resolve(this.state.aisles),
            Promise.resolve(this.state.sections),
            Promise.resolve(this.state.shelves),
            Promise.resolve(this.state.containers),
          ])
        default:
          return Promise.all([
            getLLBuildingByLocation(
              this.props.userLocation,
              this.props.operation
            ),
            getAisles(values),
            getSections(values),
            getShelves(values),
            getContainers(values),
          ])
      }
    }

    getPromises()
      .then(([buildings, aisles, sections, shelves, containers]) => {
        return [
          (buildings &&
            buildings.data &&
            buildings.data.buildings.map((a) => a.building)) ||
            buildings,
          (aisles && aisles.data && aisles.data.aisles) || aisles,
          (sections && sections.data && sections.data.sections) || sections,
          (shelves && shelves.data && shelves.data.shelves) || shelves,
          (containers && containers.data && containers.data.containers) ||
            containers,
        ]
      })
      .then(([buildings, aisles, sections, shelves, containers]) => {
        this.setState((prevState) => ({
          ...prevState,
          buildings: buildings,
          aisles: aisles,
          sections: sections,
          shelves: shelves,
          containers: containers,
        }))
        // this.props.onChange(values, null, this.state.barcode)
        this.props.onChange(values, null, isDisableNext)
      })
  }

  render() {
    const { description, classes = {} } = this.props
    const { barcode, values } = this.state

    const levels = (index) => {
      const subLocation = [
        this.state.buildings,
        this.state.aisles,
        this.state.sections,
        this.state.shelves,
        this.state.containers,
      ]
      return subLocation[index]
    }
    const indexes = () => {
      return Array.apply(null, { length: 5 }).map(Number.call, Number)
    }
    return (
      <Grid container spacing={2} alignItems={'flex-end'}>
        <Grid item xs={12}>
          {description}
        </Grid>
        <Grid container spacing={2} alignItems={'flex-end'}>
          {indexes().map((index) => {
            const distinct = levels(index)
            const selectedOptionValue = values[index] || ''
            return (
              ((distinct && distinct.length > 0) || values[index]) && (
                <Grid item>
                  <FormControl
                    key={index}
                    className={
                      selectedOptionValue === ''
                        ? index === Number(0)
                          ? classes.inputLabelControlZero
                          : classes.inputLabelControl
                        : classes.inputLabelControlAuto
                    }
                  >
                    <InputLabel
                      id={'demo-simple-select-label' + index}
                      classes={{
                        root: classes.inputLabel,
                        shrink: 'shrink',
                      }}
                    >
                      {index === Number(0)
                        ? 'Location or status'
                        : 'Sub-location'}
                    </InputLabel>
                    <Select
                      labelId={'demo-simple-select-label' + index}
                      value={selectedOptionValue}
                      onChange={this.handleDropdownChange}
                      name={index.toString()}
                      id={'demo-simple-select-outlined' + index}
                    >
                      {distinct &&
                        distinct.map((entry) => {
                          return (
                            <MenuItem key={entry} value={entry}>
                              {entry}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              )
            )
          })}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="barcode"
            label="barcode"
            value={barcode}
            onChange={this.handleBarcodeChange}
            onPaste={this.handleBarcodeChangePaste}
            margin="normal"
            autoFocus
            className={classes.barcodeInput}
          />
        </Grid>
      </Grid>
    )
  }
}

LocationScan.defaultProps = {
  action: () => {},
  onTransitionForward: () => {},
}

LocationScan.propTypes = {
  action: PropTypes.func,
  description: PropTypes.string,
  onTransitionForward: PropTypes.func,
  onChange: PropTypes.func,
}

export default withStyles(styles)(LocationScan)
