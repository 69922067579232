export const PENDING_SHIP_SAMPLES_FAILURE = 'PENDING_SHIP_SAMPLES_FAILURE'
export const PENDING_SHIP_SAMPLES_PENDING = 'PENDING_SHIP_SAMPLES_PENDING'
export const PENDING_SHIP_SAMPLES_SUCCESS = 'PENDING_SHIP_SAMPLES_SUCCESS'
export const SHIPPED_SHIP_SAMPLES_FAILURE = 'SHIPPED_SHIP_SAMPLES_FAILURE'
export const SHIPPED_SHIP_SAMPLES_PENDING = 'SHIPPED_SHIP_SAMPLES_PENDING'
export const SHIPPED_SHIP_SAMPLES_SUCCESS = 'SHIPPED_SHIP_SAMPLES_SUCCESS'
export const SCENIC_ROOMS_TABLE_FILTER_VALUE = 'SCENIC_ROOMS_TABLE_FILTER_VALUE'
export const SCENIC_ROOMS_TABLE_SHIPPED_FILTER_VALUE =
  'SCENIC_ROOMS_TABLE_SHIPPED_FILTER_VALUE'
export const SCENIC_ROOMS_SET_TAB_VALUE = 'SCENIC_ROOMS_SET_TAB_VALUE'

export const FETCH_PARTNERS_REQUEST_PENDING = 'FETCH_PARTNERS_REQUEST_PENDING'
export const FETCH_PARTNERS_REQUEST_SUCCESS = 'FETCH_PARTNERS_REQUEST_SUCCESS'
export const FETCH_PARTNERS_REQUEST_FAILURE = 'FETCH_PARTNERS_REQUEST_FAILURE'
export const SCENIC_ROOMS_SET_TAB_ATTRIBUTES = 'SCENIC_ROOMS_SET_TAB_ATTRIBUTES'
export const ACTIVE_SCENIC_ROOM_SUCCESS = 'ACTIVE_SCENIC_ROOM_SUCCESS'
export const ACTIVE_SCENIC_ROOM_FAILURE = 'ACTIVE_SCENIC_ROOM_FAILURE'
export const ACTIVE_SCENIC_ROOM_PENDING = 'ACTIVE_SCENIC_ROOM_PENDING'
export const SAVE_USERS = 'SAVE_USERS'
