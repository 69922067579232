import * as types from './actionType'

export const initialState = (window.Cypress && window.initialState) || {
  columnData: [
    {
      id: 'pegasusId',
      numeric: false,
      disablePadding: false,
      label: 'PG ID',
      textAlign: 'right',
    },
    {
      id: 'tcin',
      numeric: true,
      disablePadding: false,
      label: 'TCIN',
      textAlign: 'right',
    },
    {
      id: 'dpci',
      numeric: false,
      disablePadding: false,
      label: 'DPCI',
      textAlign: 'right',
    },
    {
      id: 'generalDescription',
      numeric: false,
      disablePadding: false,
      label: 'Item Description',
      textAlign: 'right',
    },
    {
      id: 'vendorId',
      numeric: false,
      disablePadding: false,
      label: 'Vendor ID',
      textAlign: 'right',
    },
    { id: 'dueDate', numeric: false, disablePadding: false, label: 'Due Date' },
    { id: 'shipTo', numeric: false, disablePadding: false, label: 'Ship To' },
    {
      id: 'vendorEmail',
      numeric: false,
      disablePadding: false,
      label: 'Input Emails',
    },
    {
      id: 'vendorNotes',
      numeric: false,
      disablePadding: false,
      label: 'Vendor Notes',
    },
    {
      id: 'studioNotes',
      numeric: false,
      disablePadding: false,
      label: 'Studio Notes',
    },
  ],
  data: [],
  onClick: () => {},
  orderNumber: 0,
  orderReceiptCreateOrderStatusPending: false,
}

export default function orderReceipt(state = initialState, action = {}) {
  switch (action.type) {
    case types.ORDER_RECEIPT_PROMOTE_ORDER_PENDING: {
      return Object.assign({}, state, {
        orderReceiptCreateOrderStatusPending: action.payload.pending,
      })
    }
    case types.ORDER_RECEIPT_PROMOTE_ORDER_SUCCESS: {
      return Object.assign({}, state, {
        orderNumber: action.payload.data[0].orderNum,
        data: action.payload.data,
      })
    }
    case types.ORDER_RECEIPT_PROMOTE_ORDER_FAILURE: {
      return Object.assign({}, state, {})
    }
    default:
      return state
  }
}
