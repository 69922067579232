import * as types from './actionType'

export const initialState = {
  lanId: '',
  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  mailstop: '',
  emailAddress: '',
  location: '',
  departments: [],
  barcodeType: 'MULTILINE', //'MULTILINE',
  saveUserRequestPending: false,
  saveUserRequestFailure: false,
  saveUserRequestSuccess: false,
  updateUserRequestPending: false,
  updateUserRequestFailure: false,
  updateUserRequestSuccess: false,
  updateDefaultLocRequestPending: false,
  updateDefaultLocRequestFailure: false,
  updateUserDeptPending: false,
  updateUserDeptFailure: false,
}

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case types.USER_INFORMATION_SAVE_USER_REQUEST_PENDING: {
      return Object.assign({}, state, {
        saveUserRequestPending: action.payload.pending,
      })
    }
    case types.USER_INFORMATION_SAVE_USER_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        saveUserRequestFailure: true,
      })
    }
    case types.USER_INFORMATION_SAVE_USER_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        saveUserRequestSuccess: true,
        lanId: action.payload.user.lanId,
        firstName: action.payload.user.firstName,
        lastName: action.payload.user.lastName,
        emailAddress: action.payload.user.emailAddress,
        addressLine1: action.payload.user.addressLine1,
        addressLine2: action.payload.user.addressLine2,
        city: action.payload.user.city,
        state: action.payload.user.state,
        zipCode: action.payload.user.zipCode,
        mailstop: action.payload.user.mailstop,
        location: action.payload.user.defaultLocation,
        currentRole: action.payload.user.userRole,
        barcodeType: action.payload.user.printer_type,
      })
    }
    case types.USER_INFORMATION_UPDATE_USER_REQUEST_PENDING: {
      return Object.assign({}, state, {
        updateUserRequestPending: action.payload.pending,
      })
    }
    case types.USER_INFORMATION_UPDATE_USER_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        updateUserRequestFailure: true,
      })
    }
    case types.USER_INFORMATION_UPDATE_USER_REQUEST_SUCCESS: {
      const {
        payload: {
          user: { printer_type = 'MULTILINE' },
        },
      } = action
      return Object.assign({}, state, {
        updateUserRequestSuccess: true,
        addressLine1:
          action.payload.user.addressLine1 != null
            ? action.payload.user.addressLine1
            : '',
        addressLine2:
          action.payload.user.addressLine2 != null
            ? action.payload.user.addressLine2
            : '',
        mailstop:
          action.payload.user.mailstop != null
            ? action.payload.user.mailstop
            : '',
        city: action.payload.user.city != null ? action.payload.user.city : '',
        state:
          action.payload.user.state != null ? action.payload.user.state : '',
        zipCode:
          action.payload.user.zipCode != null
            ? action.payload.user.zipCode
            : '',
        barcodeType: printer_type || 'MULTILINE',
      })
    }
    case types.UPDATE_USER_DEPT_SUCCESS: {
      const {
        payload: { departments = [] },
      } = action
      return Object.assign({}, state, {
        departments: departments,
      })
    }
    case types.UPDATE_USER_DEPT_PENDING: {
      return Object.assign({}, state, {
        updateUserDeptPending: action.payload.pending,
      })
    }
    case types.UPDATE_USER_DEPT_FAILURE: {
      return Object.assign({}, state, {
        updateUserDeptFailure: true,
      })
    }
    case types.USER_DEFAULT_LOCATION_REQUEST_PENDING: {
      return Object.assign({}, state, {
        updateDefaultLocRequestPending: action.payload.pending,
      })
    }
    case types.USER_DEFAULT_LOCATION_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        updateDefaultLocRequestFailure: true,
      })
    }
    case types.USER_DEFAULT_LOCATION_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        location: action.payload.user.defaultLocation,
      })
    }
    case types.USER_INFORMATION_SET_BARCODE_TYPE: {
      return Object.assign({}, state, {
        barcodeType: action.payload.barcodeType,
      })
    }
    case types.USER_INFORMATION_CLEAR_USER_DATA: {
      return initialState
    }
    default: {
      return state
    }
  }
}
