// The first array will grab the item from the second array with the corresponding key value
// and merge it into itself. Order matters, as there may be a 1-to-N mapping between the arrays.

export const sortMyArray = function (
  myArray = [],
  myOrderBy,
  myOrder = 'desc'
) {
  if (myArray === null || myArray.length === 0) {
    return []
  }
  const orderedArray =
    myOrder === 'desc'
      ? myArray.sort((a, b) => (b[myOrderBy] < a[myOrderBy] ? -1 : 1))
      : myArray.sort((a, b) => (a[myOrderBy] < b[myOrderBy] ? -1 : 1))
  return orderedArray
}

export const merge = (arr1 = [], arr2 = [], key = '') => {
  let map = new Map()
  let result = []
  arr2.map((it) => map.set(it[key].toString(), it))
  arr1.forEach((it) => {
    let match = map.get(it[key].toString())
    let mergedObj = Object.assign({}, match, it)
    result.push(mergedObj)
  })
  return result
}

// existing results in arr1 are overwritten by arr2
export const combine = (arr1 = [], arr2 = [], key = '') => {
  let combined = new Map(arr1.map((it) => [it[key].toString(), it]))
  arr2.map((it) => combined.set(it[key].toString(), it))
  return Array.from(combined.values())
}
