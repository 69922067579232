import { defaultShipmentsTab } from '../../enums/Tabs'
import * as types from './actionType'

export const initialState = {
  alertIsVisable: false,
  alertMessage: '',
  checkBoxEnabled: true,
  detailsPopupIsVisible: false,
  pendingColumnData: [
    {
      id: 'shipmentId',
      numeric: false,
      disablePadding: false,
      label: 'Shipment #',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'shipmentName',
      numeric: true,
      disablePadding: false,
      label: 'Shipment Name',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'shipToLocName',
      numeric: false,
      disablePadding: false,
      label: 'Ship To',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Shipment Creator',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'createDate',
      numeric: true,
      disablePadding: false,
      label: 'Date Created',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'modifiedDate',
      numeric: true,
      disablePadding: false,
      label: 'Date Last Modified',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'shipmentChannel',
      numeric: false,
      disablePadding: true,
      label: 'Shipment Channel',
      textAlign: 'right',
    },
    {
      id: 'sampleCount',
      numeric: false,
      disablePadding: false,
      label: '#Samples',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'propCount',
      numeric: false,
      disablePadding: false,
      label: '#Props',
      textAlign: 'right',
      type: '',
    },
  ],
  shippedColumnData: [
    {
      id: 'shipmentId',
      numeric: false,
      disablePadding: false,
      label: 'Shipment #',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'shipmentDate',
      numeric: true,
      disablePadding: false,
      label: 'Date Shipped',
      textAlign: 'right',
      type: 'date',
    },
    {
      id: 'shipToLocName',
      numeric: false,
      disablePadding: false,
      label: 'Shipped To',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'shipmentName',
      numeric: true,
      disablePadding: false,
      label: 'Shipment Name',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'shipmentChannel',
      numeric: false,
      disablePadding: true,
      label: 'Shipment Channel',
      textAlign: 'right',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Shipment Creator',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'sampleCount',
      numeric: false,
      disablePadding: false,
      label: 'Sample Count',
      textAlign: 'right',
      type: '',
    },
    {
      id: 'propCount',
      numeric: false,
      disablePadding: false,
      label: 'Props Count',
      textAlign: 'right',
      type: '',
    },
  ],
  pendingShipments: [],
  shippedShipments: [],
  numSelected: 0,
  onClick: () => {},
  response: false,
  selectedData: [],
  pendingShipmentPending: false,
  pendingShipmentFailure: false,
  shippedShipmentPending: false,
  shippedShipmentFailure: false,
  filterValue: '',
  filterValueShipped: '',
  tabValue: defaultShipmentsTab,
  tabBasedAttributes: {},
  allPartnersList: [],
  fetchPartnersFailure: false,
  fetchPartnersPending: false,
  totalCount: Number(0),
}

export default function shipSamples(state = initialState, action = {}) {
  switch (action.type) {
    case types.PENDING_SHIP_SAMPLES_SUCCESS: {
      return Object.assign({}, state, {
        pendingShipments: action.payload.pendingShipments,
        totalCount: action?.payload?.pendingShipments?.length || Number(0),
      })
    }
    case types.PENDING_SHIP_SAMPLES_PENDING: {
      return Object.assign({}, state, {
        pendingShipmentPending: action.payload.pending,
      })
    }
    case types.PENDING_SHIP_SAMPLES_FAILURE: {
      return Object.assign({}, state, {
        pendingShipmentFailure: true,
      })
    }

    case types.SHIPPED_SHIP_SAMPLES_SUCCESS: {
      return Object.assign({}, state, {
        shippedShipments: action.payload.shippedShipments,
        totalCount: action.payload.totalCount || Number(0),
      })
    }

    case types.SHIPPED_SHIP_SAMPLES_PENDING: {
      return Object.assign({}, state, {
        shippedShipmentPending: action.payload.pending,
      })
    }

    case types.SHIPPED_SHIP_SAMPLES_FAILURE: {
      return Object.assign({}, state, {
        shippedShipmentFailure: true,
        shippedShipments: [],
      })
    }
    case types.SHIPMENTS_TABLE_FILTER_VALUE: {
      return Object.assign({}, state, {
        filterValue: action.payload.filterValue,
      })
    }
    case types.SHIPMENTS_TABLE_SHIPPED_FILTER_VALUE: {
      return Object.assign({}, state, {
        filterValueShipped: action.payload.filterValue,
      })
    }
    case types.SHIPMENTS_SET_TAB_VALUE: {
      const {
        payload: { tabValue = '' },
      } = action
      return Object.assign({}, state, {
        ...state,
        tabValue,
      })
    }

    case types.SHIPMENTS_SET_TAB_ATTRIBUTES: {
      const {
        payload: { tabBasedAttributes = '' },
      } = action
      return Object.assign({}, state, {
        ...state,
        tabBasedAttributes,
      })
    }

    case types.FETCH_PARTNERS_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        allPartnersList: action.payload.allPartnersList,
      })
    }
    case types.FETCH_PARTNERS_REQUEST_PENDING:
      return Object.assign({}, state, {
        fetchPartnersPending: action.payload.pending,
      })
    case types.FETCH_PARTNERS_REQUEST_FAILURE:
      return Object.assign({}, state, {
        fetchPartnersFailure: true,
      })
    default:
      return state
  }
}
