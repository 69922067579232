export const FETCH_AISLES_FAILURE = 'FETCH_AISLES_FAILURE'
export const FETCH_AISLES_REQUEST_PENDING = 'FETCH_AISLES_PENDING'
export const FETCH_AISLES_SUCCESS = 'FETCH_AISLES_SUCCESS'
export const FETCH_SECTIONS_FAILURE = 'FETCH_SECTIONS_FAILURE'
export const FETCH_SECTIONS_REQUEST_PENDING = 'FETCH_SECTIONS_PENDING'
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS'
export const FETCH_SHELVES_FAILURE = 'FETCH_SHELVES_FAILURE'
export const FETCH_SHELVES_REQUEST_PENDING = 'FETCH_SHELVES_PENDING'
export const FETCH_SHELVES_SUCCESS = 'FETCH_SHELVES_SUCCESS'
export const FETCH_CONTAINER_FAILURE = 'FETCH_CONTAINER_FAILURE'
export const FETCH_CONTAINER_REQUEST_PENDING = 'FETCH_CONTAINER_PENDING'
export const FETCH_CONTAINER_SUCCESS = 'FETCH_CONTAINER_SUCCESS'
export const FETCH_FILTER_FAILURE = 'FETCH_FILTER_FAILURE'
export const FETCH_FILTER_REQUEST_PENDING = 'FETCH_FILTER_PENDING'
export const FETCH_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS'
export const RESET_TO_START_OVER = 'RESET_TO_START_OVER'
export const MANAGE_LOCATIONS_SET_ROWS_PER_PAGE =
  'MANAGE_LOCATIONS_SET_ROWS_PER_PAGE'
export const MANAGE_LOCATIONS_SET_PAGE = 'MANAGE_LOCATIONS_SET_PAGE'
export const MANAGE_LOCATIONS_SET_CHECKBOX_HEADER =
  'MANAGE_LOCATIONS_SET_CHECKBOX_HEADER'
export const MANAGE_LOCATIONS_SET_CHECKBOX = 'MANAGE_LOCATIONS_SET_CHECKBOX'
export const DELETE_LOCATIONS_FAILURE = 'DELETE_LOCATIONS_FAILURE'
export const RENAME_LOCATION_PENDING = 'RENAME_LOCATION_PENDING'
export const RENAME_LOCATION_SUCCESS = 'RENAME_LOCATION_SUCCESS'
export const RENAME_LOCATION_FAILURE = 'RENAME_LOCATION_FAILURE'
export const MANAGE_LOCATIONS_EDIT_SET_FIELD_VALUE =
  'MANAGE_LOCATIONS_EDIT_SET_FIELD_VALUE'
export const RESET_TO_INIT_STATE_ML = 'RESET_TO_INIT_STATE_ML'
export const DELETE_LOCATIONS_SUCCESS = 'DELETE_LOCATIONS_SUCCESS'
export const RENAME_LOCATION_SUCCESS_STATUS = 'RENAME_LOCATION_SUCCESS_STATUS'
export const MANAGE_LOCATIONS_SET_SORTED_DATA =
  'MANAGE_LOCATIONS_SET_SORTED_DATA'
// export const SHOW_ML_DELETE_ALERT = 'SHOW_ML_DELETE_ALERT'
