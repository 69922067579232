import React from 'react'
import * as propTypes from 'prop-types'
import withHOCs from 'util/withHocs'
import { isEmpty, findIndex, isEqual } from 'lodash'
import copy from 'copy-to-clipboard'
import {
  Grid,
  TextField,
  IconButton,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  SwipeableDrawer,
  Button,
} from '@material-ui/core'
import MoreVert from '@material-ui/icons/MoreVert'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import ChipArray from '../../components/Chips/ChipArray'
import CircularPageLoader from '../../components/Loader/CircularPageLoader'

import PopUp from '../../components/PopUp/PopUp'
import {
  archSettingsColumnList as settingsColumnList,
  settingsColumnCategories,
  launchInNewTabColumns,
} from './constants'

import {
  convertTableDateFormat,
  CSV_DATE_FORMAT,
  getFromBrowserStorage,
  removeFromBrowserStorage,
} from '../../util/CommonUtils'
import { makeTabTables, getTitle } from '../../util/Table'
import { getSampleIDPrefix } from '../../util/CommonUtils'
import { dataTabLabelsShipped } from '../../enums/tableTabs'

import { showNotification } from '../../store/notification/actionCreator'
import { setBarcodeType } from '../../store/user/actionCreator'
import {
  closePopup,
  downloadContactSheet,
  getData,
  getDuplicateBarcode,
  setCheckBox,
  setCheckBoxHeaderChange,
  setPage,
  setRowsPerPage,
  showPopup,
  triggerUpdateHistoryData,
  downloadLaunchDateReport,
  duplicateSamples,
  updateSampleFieldsData,
  setColumnSettings,
  resetIsCallSearchFields,
  updateSamples,
  emptySelectedData,
  autoRefreshSearch,
  showAlert,
  unArchieveSamples,
  closeAlert,
} from '../../store/searchArchivedSamples/actionCreator'
import { getBarcodes } from '../../store/shipmentBarcode/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import { getProjectChannel } from '../../store/newShootType/actionCreator'
import DialogBox from '../../components/DialogBox/DialogBox'
import {
  paramsFromFilters,
  SEARCH_ARCHIVED_SELECTABLE_FILTERS,
  SEARCH_ARCHIVED_VENDOR_SELECTABLE_FILTERS,
} from '../AddFilters/constants'
import AddFilters from '../AddFilters/AddFilters'
import { selectPageFilters } from '../../store/filters/selectors'
import { setFilters } from '../../store/filters/actionCreator'
import BottomBar from 'components/BottomBar'

const styles = (theme) => ({
  searchInventoryPage: {
    flexGrow: 1,
  },
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  pageHeader: {
    width: '100%',
    height: '40px',
    position: 'relative',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    height: '48px',
    alignItems: 'center',
  },
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
  },
  errorIcon: { paddingRight: '5px' },
  SwipeableDrawerElements: {
    fontSize: 'normal',
    textAlign: 'left',
    overflow: 'hidden',
  },
  sectionMargin: {
    margin: '0px 24px',
  },
  sectionMargin1: {
    margin: '24px',
  },
  defaultButton: {
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
  settingsPrimaryText: {
    fontSize: '14px',
    color: '#666',
  },
  settingsPrimaryTextLabel: {
    fontSize: '14px',
    color: '#666',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  FormControlLabelCls: {
    fontSize: '12px',
  },
  FormControlRootCls: {
    height: 30,
  },
  addfiltersPaper: {
    paddingBottom: '0px',
    paddingTop: '0px',
    margin: '0px 0px',
    boxShadow: 'unset !important',
  },
  zoomCls: {
    transition: 'transform .2s' /* Animation */,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      // transform: 'scale(1.5)',
    },
  },
})

class SearchArchivedSamples extends React.PureComponent {
  constructor(props) {
    super(props)
    this.anchorRef = React.createRef()
    const { filters = {}, auth = {} } = this.props
    this.state = {
      checkAll: true,
      popupItem: {},
      popupIsVisible: false,
      newSamplePopUpIsVisible: false,
      selectedData: [],
      anchorEl: null,
      menuAnchor: null,
      selectIsOpen: false,
      selectableFilters: auth.isAuthorized(['APP-PEG-MKT-VENDOR'])
        ? SEARCH_ARCHIVED_VENDOR_SELECTABLE_FILTERS
        : SEARCH_ARCHIVED_SELECTABLE_FILTERS,
      filters: filters,
      filtersTitle: '',
      saveFiltersPopUpIsVisible: false,
      openFiltersPopUpIsVisible: false,
      barcodeTypePopUpIsVisible: false,
      lastSuccesfulFilters: {},
      searchQuery: '',
      isFilterUpdated: false,
      isEditRowsSuccess: false,
      sortParam: '',
      sortOrder: '',
      showSettings: false,
      showEditPopUp: false,
      selectedSettingsCols: settingsColumnList || [],
      stateSettingsColumnList: settingsColumnList || [],
      isDefaultSettings: true,
    }
    this.defaultParams = {}
  }

  componentDidMount = async () => {
    const {
      setHeaderTitleArray = () => {},
      setFilters = () => {},
      pageName = '',
      autoRefreshData = false,
      setPage = () => {},
      autoRefreshSearch = () => {},
    } = this.props
    setHeaderTitleArray([
      { title: 'Archived Samples', link: '/SearchArchivedSamples' },
    ])

    /** TCIN FILTER IN NEWTAB */
    const items = { ...localStorage }
    const localStorageList = Object.keys(items)
    if (localStorageList.indexOf('newTab') !== -1) {
      const searchTermData = getFromBrowserStorage('newTab') || ''
      removeFromBrowserStorage('newTab')
      const searchTermDataJson = JSON.parse(searchTermData)
      const { searchTerm = '', searchValue = '' } = searchTermDataJson
      this.setStorageFilters(searchValue, searchTerm)
    }
    /** TCIN FILTER IN NEWTAB */

    setFilters({}, pageName)
    if (autoRefreshData) {
      await setPage(1)
      await autoRefreshSearch()
    }
  }

  setStorageFilters = (searchValue = '', searchTerm = '') => {
    const { filters: stateFilters = {} } = this.state
    const filterCompType = 'include_filters'
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    let filtersCopyNew = Object.assign({}, filtersCopy, { [searchTerm]: {} })
    filtersCopyNew[searchTerm].chipArray = [searchValue]
    filtersCopyNew[searchTerm].unrecognizedValues = []
    filtersCopyNew[searchTerm].textFieldValue = ''
    filtersCopyNew[searchTerm].islatest = true
    const updateFiltersObj = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopyNew,
    })
    this.setState({ filters: updateFiltersObj })
  }

  setDefaultSettingsCols = () => {
    const defaultColList = settingsColumnList.filter((obj) => {
      return !!obj.default
    })
    this.setState((state) => ({
      selectedSettingsCols: defaultColList,
      stateSettingsColumnList: settingsColumnList,
    }))
  }

  componentWillUnmount() {
    const { setFilters, pageName = '' } = this.props
    const { filters = {} } = this.state
    const filterCopy = Object.assign({}, filters)
    if (!isEmpty(filterCopy)) {
      setFilters(filterCopy, pageName)
    }
  }

  displaySelectedData = () => {
    const { selectedData } = this.props

    return (
      <div>
        <p>
          Would you like to create a new sample and label for the following
          items?
        </p>
        <FlexibleTable
          cascadeFieldKeys={[]}
          data={selectedData}
          disableItems
          disableTablePagination
          disableTableSorting
          disableItemCount
        />
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page: propsPage,
      rowsPerPage: propsRowsPerPage,
      fetchData = () => {},
      data: propsData = [],
      setPage = () => {},
      isSearchedColumns,
      searchedColumns = [],
      resetIsCallSearchFields = () => {},
      unarchiveSamplePending,
    } = this.props

    const {
      filters: stateFilters = {},
      isFilterUpdated: stateIsFilterUpdated,
      isDefaultSettings,
      searchQuery: searchText = '',
      isEditRowsSuccess,
      sortParam: stateSortParam,
      sortOrder: stateSortOrder,
    } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    const {
      page: prevPropsPage,
      rowsPerPage: prevPropsRowsPerPage,
      isSearchedColumns: prevIsSearchedColumns,
    } = prevProps
    const {
      isFilterUpdated: prevStateIsFilterUpdated,
      isEditRowsSuccess: prevIsEditRowsSuccess,
    } = prevState

    if (
      propsPage > 1 &&
      (propsPage !== prevPropsPage || propsRowsPerPage !== prevPropsRowsPerPage)
    ) {
      fetchData(
        paramsFromFilters(
          filtersCopy,
          propsPage,
          propsRowsPerPage,
          this.userProfilename,
          this.defaultParams,
          stateSortParam,
          stateSortOrder,
          searchText
        )
      )
    }
    if (
      (propsPage === Number(1) &&
        (propsPage !== prevPropsPage ||
          propsRowsPerPage !== prevPropsRowsPerPage)) ||
      (!!stateIsFilterUpdated &&
        stateIsFilterUpdated !== prevStateIsFilterUpdated) ||
      (!!isEditRowsSuccess && isEditRowsSuccess !== prevIsEditRowsSuccess) ||
      (!unarchiveSamplePending &&
        prevProps.unarchiveSamplePending !== unarchiveSamplePending)
    ) {
      this.refreshTableWithNewData(filtersCopy, stateIsFilterUpdated)
      this.setState(
        {
          isFilterUpdated: false,
        },
        () => {
          setPage(1)
        }
      )
    }
    if (!propsData.length && propsPage !== 1) {
      setPage(1)
    }
    /** column setting changes */
    if (
      !!isSearchedColumns &&
      isEqual(isSearchedColumns, prevIsSearchedColumns)
    ) {
      const pegDefaultCols = [...settingsColumnList].filter(
        (obj) => !!obj.default
      )
      const stateSettingsColumnListUpdated = settingsColumnList.map((obj) => {
        const newObj =
          searchedColumns.indexOf(obj.id) !== -1
            ? Object.assign({}, obj, { default: true })
            : isSearchedColumns
            ? Object.assign({}, obj, { default: false })
            : obj
        return newObj
      })
      const defaultColList = stateSettingsColumnListUpdated.filter((obj) => {
        return !!obj.default
      })

      this.setState((state) => {
        resetIsCallSearchFields()
        return {
          selectedSettingsCols: isDefaultSettings
            ? settingsColumnList
            : defaultColList,
          stateSettingsColumnList: isDefaultSettings
            ? settingsColumnList
            : stateSettingsColumnListUpdated,
          isDefaultSettings: isEqual(pegDefaultCols, defaultColList),
        }
      })
    }
    if (propsPage !== prevPropsPage) {
      window.scrollTo(0, 500)
    }
    /** column setting changes **/
  }

  createCsv(data) {
    let returnString = [
      'Description,TCIN,Pegasus ID,DPCI,Status,Location,' +
        'Project/Collection Name,Shoot Date,Launch Date,',
    ]

    for (let itemIndex in data) {
      let item = data[itemIndex]
      returnString.push(
        `${
          '"' +
          item.description +
          '",' +
          item.tcin +
          ',' +
          item.pegasusId +
          ',' +
          item.dpci +
          ',' +
          item.sampleStatus +
          ',"' +
          item.location +
          '","' +
          item.projectName +
          '",' +
          convertTableDateFormat(item.shootDate, CSV_DATE_FORMAT) +
          ',' +
          convertTableDateFormat(item.launchDate, CSV_DATE_FORMAT) +
          ''
        }`
      )
    }
    return returnString.join('\n')
  }

  handleGenerateBarcode = () => {
    const {
      getBarcodes = () => {},
      selectedData = [],
      barcodeType,
    } = this.props
    getBarcodes(
      selectedData,
      (barcodes) => {
        const file = new Blob([barcodes], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL).print()
      },
      barcodeType
    )

    this.setState((state) => ({
      ...state,
      printButton: true,
    }))
  }

  onSelected = (selectedData, selected) => {
    const { pegasusId = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.pegasusId === pegasusId
      }) !== -1
    )
  }

  setFilters(filters) {
    this.setState({ filters })
  }

  disableButton = () => {
    const { selectedData = [] } = this.props
    return selectedData.length === 0
  }

  setFiltersUpdated = (
    isFilterUpdated = false,
    filters = {},
    sortParam,
    sortOrder
  ) => {
    const filtersCopy = Object.assign({}, filters)
    this.setState({
      isFilterUpdated: isFilterUpdated,
      filters: filtersCopy,
      sortParam: sortParam,
      sortOrder: sortOrder,
    })
  }

  refreshTableWithNewData(filters = {}, stateIsFilterUpdated = false) {
    const filtersCopy = Object.assign({}, filters)
    const {
      page,
      rowsPerPage,
      onCheckBoxHeaderChange,
      fetchData = () => {},
      pageName = '',
      setFilters = () => {},
    } = this.props
    const {
      lastSuccesfulFilters = {},
      searchQuery = '',
      sortParam: stateSortParam,
      sortOrder: stateSortOrder,
    } = this.state

    var encodedStr = searchQuery.replace(/[\u00A0-\u9999<>]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';'
    })
    onCheckBoxHeaderChange()
    const filtersFromParams = paramsFromFilters(
      filtersCopy,
      stateIsFilterUpdated ? Number(1) : page,
      rowsPerPage,
      this.userProfilename,
      this.defaultParams,
      stateSortParam,
      stateSortOrder,
      encodedStr
    )
    fetchData(
      filtersFromParams,
      () => this.setState({ lastSuccesfulFilters: filters }),
      () => setFilters(lastSuccesfulFilters, pageName)
    )
  }

  toggleOpen = () => {
    const { numSelected } = this.props
    if (numSelected !== 0) {
      return true
    } else {
      return false
    }
  }

  setPageAndRefresh = (page) => {
    const { setPage = () => {} } = this.props
    setPage(page)
  }

  setRowsPerPageAndRefresh = (rowsPerPage) => {
    const { setRowsPerPage = () => {} } = this.props
    setRowsPerPage(rowsPerPage)
  }

  nameSavedFilters = () => {
    const { filtersTitle = '' } = this.state
    return (
      <TextField
        id="filtersTitle"
        label="Name Your Saved Filters"
        type="text"
        name="title"
        margin="normal"
        variant="outlined"
        fullWidth
        value={filtersTitle}
      />
    )
  }

  downloadLDReport = () => {
    const { downloadLaunchDateReport = () => {} } = this.props
    downloadLaunchDateReport()
  }

  handleOptionsClick = (event) => {
    this.setState({ menuAnchor: event.currentTarget })
  }

  handleSettingClick = (event) => {
    this.setState((state, props) => {
      return { showSettings: !state.showSettings }
    })
  }

  onEditItemsClick = (event) => {
    this.setState((state, props) => {
      return { showEditPopUp: !state.showEditPopUp }
    })
  }

  handleChange = (event) => {
    const searchValue = event.target.value || ''
    this.setState({ searchQuery: searchValue })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleElasticSearchQuery(event.target.value)
    }
  }

  handleSearchClear = () => {
    this.setState({
      searchQuery: '',
    })
    const { fetchData, page, rowsPerPage } = this.props
    const {
      filters: stateFilters,
      sortParam: stateSortParam,
      sortOrder: stateSortOrder,
    } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    fetchData(
      paramsFromFilters(
        filtersCopy,
        page,
        rowsPerPage,
        this.userProfilename,
        this.defaultParams,
        stateSortParam,
        stateSortOrder
      )
    )
  }
  duplicateSamples = (sample) => {
    const {
      duplicateSamples,
      getDuplicateBarcode,
      barcodeType,
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
    } = this.props
    let newSample = Object.assign({}, sample, {
      ...sample,
      userId: lanId,
    })
    const { filters: stateFilters = {} } = this.state
    duplicateSamples(newSample).then((samples) => {
      if (samples) {
        getDuplicateBarcode(samples, barcodeType).then((pdf) => {
          if (pdf !== undefined) {
            window.open(pdf).print()
          }
          this.refreshTableWithNewData(stateFilters)
        })
      }
    })
    this.setState({
      newSamplePopUpIsVisible: false,
    })
  }

  getNotFoundBlock = (filterNFList = {}, stateFilters = {}) => {
    const { handleChipClick = () => {} } = this.props
    const { include_filters: includedFilters } = stateFilters
    let latestFilterStr =
      includedFilters &&
      Object.keys(includedFilters).find(
        (obj) => !!includedFilters[obj]['islatest']
      )
    const latestFilterKey = (latestFilterStr || '')
      .replace(' ', '_')
      .toLowerCase()

    const latestFilterList = filterNFList[latestFilterKey] || []
    const chipArrayData = latestFilterList.reduce(function (
      result,
      item = '',
      index,
      array
    ) {
      const itemStr =
        latestFilterKey === 'sample_id' ? getSampleIDPrefix(item) : item
      result[index] = { key: itemStr, label: itemStr }
      return result
    }, [])
    const nfFilterCount = (latestFilterList || []).length
    if (nfFilterCount > 1) {
      latestFilterStr += 's'
    }
    return nfFilterCount > 0 ? (
      <ChipArray
        data={chipArrayData}
        enableCopyAllButton
        handleClick={handleChipClick}
        title={`${nfFilterCount} ${latestFilterStr} not found`}
        textAlign={'left'}
        variant={'h6'}
      />
    ) : (
      ''
    )
  }

  downloadCSFunction =
    (downloadType = '') =>
    (event) => {
      const { filters: stateFilters = {}, searchQuery: searchText = '' } =
        this.state
      const filtersCopy = Object.assign({}, stateFilters)
      const { downloadContactSheet = () => {} } = this.props

      var encodedStr = searchText.replace(/[\u00A0-\u9999<>]/g, function (i) {
        return '&#' + i.charCodeAt(0) + ';'
      })

      if (!isEmpty(downloadType)) {
        downloadContactSheet(
          paramsFromFilters(filtersCopy, '', '', '', {}, '', '', encodedStr),
          downloadType
        )
      }
    }

  onSettingsCBChange = (checkedValue, selectedColumn = {}) => {
    let filteredColumns = []
    const { stateSettingsColumnList = [] } = this.state
    const settingsColumnListCopy = [...stateSettingsColumnList]
    filteredColumns = settingsColumnListCopy.map((obj) => {
      if (obj.id === selectedColumn.id) {
        return Object.assign({}, obj, { default: checkedValue })
      }
      return obj
    })
    const pegDefaultCols = [...filteredColumns].filter((obj) => !!obj.default)
    let newObj = {
      selectedSettingsCols: pegDefaultCols,
      stateSettingsColumnList: filteredColumns,
    }
    if (isEmpty(pegDefaultCols)) {
      newObj = {
        selectedSettingsCols: settingsColumnList,
        stateSettingsColumnList: settingsColumnList,
        isDefaultSettings: true,
      }
    }
    this.setState((state) => {
      return newObj
    })
  }

  defaultsSwitch = (event) => {
    const { searchedColumns = [] } = this.props
    const isDefaultsChecked = event.target.checked
    const defaultColList =
      searchedColumns.length > 0
        ? settingsColumnList.map((obj) => {
            const newObj =
              searchedColumns.indexOf(obj.id) !== -1
                ? Object.assign({}, obj, { default: true })
                : Object.assign({}, obj, { default: false })
            return newObj
          })
        : settingsColumnList

    this.setState((state) => ({
      selectedSettingsCols: isDefaultsChecked
        ? settingsColumnList
        : defaultColList,
      stateSettingsColumnList: isDefaultsChecked
        ? settingsColumnList
        : defaultColList,
      isDefaultSettings: isDefaultsChecked,
    }))
  }

  columnSettingsContent = () => {
    const { classes = {} } = this.props
    const { isDefaultSettings, selectedSettingsCols = [] } = this.state
    return (
      <Grid container spacing={1}>
        <Grid item container justify={'flex-start'}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isDefaultSettings}
                  onChange={(event) => {
                    this.defaultsSwitch(event)
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={isDefaultSettings ? 'Default View' : 'My Custom View'}
            />
          </FormGroup>
        </Grid>
        <Grid item container spacing={4}>
          {Object.keys(settingsColumnCategories).map((obj) => {
            const categoryLabel = settingsColumnCategories[obj]
            const categoryColList = settingsColumnList.filter(
              (columnObj) => columnObj.category === obj
            )
            return (
              <Grid container item xs={4} direction={'column'}>
                <Grid item className={classes.settingsPrimaryTextLabel}>
                  {categoryLabel}
                </Grid>
                <Grid item>
                  <FormControl
                    required
                    component="fieldset"
                    className={classes.settingsPrimaryText}
                  >
                    <FormGroup>
                      {categoryColList.length > 0 &&
                        categoryColList.map((colObj) => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    findIndex(
                                      selectedSettingsCols,
                                      function (obj) {
                                        return (
                                          obj.id === colObj.id && !!obj.default
                                        )
                                      }
                                    ) !== -1
                                  }
                                  onChange={(event) =>
                                    this.onSettingsCBChange(
                                      event.target.checked,
                                      colObj
                                    )
                                  }
                                  disabled={!!isDefaultSettings}
                                  name={colObj.id}
                                />
                              }
                              classes={{
                                label: classes.FormControlLabelCls,
                                root: classes.FormControlRootCls,
                              }}
                              label={colObj.label}
                            />
                          )
                        })}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    )
  }

  setColumnSettingsCB = () => {
    this.handleSettingClick()
  }

  callSetColumnSettings = () => {
    const { selectedSettingsCols = [], isDefaultSettings } = this.state
    const { setColumnSettings = () => {} } = this.props
    const selectedSettingsColsCopy = [...selectedSettingsCols]
    const selectedSettingsColsLabel = selectedSettingsColsCopy
      .filter((obj) => !!obj.default)
      .map((column) => column.id)
    const {
      auth: {
        session: {
          userInfo: { lanId = '' },
        },
      },
    } = this.props
    const colsToApi = isDefaultSettings ? [] : selectedSettingsColsLabel
    setColumnSettings(colsToApi, lanId, this.setColumnSettingsCB)
  }

  itemCardSaveClickCB = () => {
    const { emptySelectedData = () => {} } = this.props
    const { onEditItemsClick = () => {} } = this
    this.setState((state) => {
      onEditItemsClick()
      emptySelectedData()
      return {
        isEditRowsSuccess: true,
      }
    })
  }

  itemCardSaveClick = (editInformation, userId) => {
    const {
      page,
      rowsPerPage,
      saveEditInformation = () => {},
      fetchData = () => {},
    } = this.props
    const { filters: stateFilters = {} } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    saveEditInformation(
      editInformation,
      userId,
      fetchData(
        paramsFromFilters(filtersCopy, page, rowsPerPage, this.userProfilename)
      ),
      this.itemCardSaveClickCB
    )
  }

  renderFilters = () => {
    const { pageName = '', classes = {} } = this.props
    const { selectableFilters = [], filters: stateFilters = {} } = this.state

    return (
      <AddFilters
        selectableFilters={selectableFilters}
        refreshTableWithNewData={this.refreshTableWithNewData}
        setFiltersUpdated={this.setFiltersUpdated}
        filterCompType={'include_filters'}
        pageName={pageName}
        mergedFilters={stateFilters}
        classes={{
          paper: classes.addfiltersPaper,
        }}
        renderFilterIcons={false}
        displayFiltersTitle={false}
      />
    )
  }

  handleUnarchiveItems = () => {
    const { showAlert = () => {}, selectedData = [] } = this.props
    showAlert(selectedData)
  }

  handleAddSamples = (e) => {
    this.props.history.push(`/searchInventory/samples/addSample`)
  }

  renderBottomBar = () => {
    const { classes = {}, selectedData = [] } = this.props
    const numSelected = selectedData?.length || Number(0)

    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={this.toggleOpen()}
        onOpen={() => null}
        onClose={() => null}
        showClearAll={numSelected > 0}
        actionType={'REST_ALL_SEARCHARCHIVEDSAMPLES'}
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          <Grid container className={classes.swipeableDrawer}>
            <Button
              className={classes.defaultButton}
              color="primary"
              onClick={this.handleUnarchiveItems}
              startIcon={<UnarchiveIcon />}
            >
              {`${'Unarchive Sample(s)'}`}
            </Button>
          </Grid>
        </div>
      </BottomBar>
    )
  }

  render() {
    const {
      classes = {},
      closePopup,
      clickableColumn,
      data,
      numSelected,
      onCheckBoxChange,
      onCheckBoxHeaderChange,
      onClick,
      page,
      popupIsVisible,
      rowsPerPage,
      selectedData,
      showPopup,
      totalRowsCount,
      filterNFList = {},
      popupItem = {},
      fetchDataRequestPending = false,
      updateSampleFieldsData = () => {},
      alertIsVisible,
      alertMessage,
      response,
      closeAlert = () => {},
      unarchiveAgree = () => {},
      dialogTitle = '',
      pageName = '',
    } = this.props
    const {
      selectedSettingsCols = [],
      showSettings,
      filters: stateFilters,
    } = this.state
    const selectedSettingsColsCopy = selectedSettingsCols.filter(
      (obj) => !!obj.default
    )

    const dataFieldKeys = selectedSettingsCols.map((column) => column.id)

    return (
      <div className={classes.searchInventoryPage}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.root}>
              <div className={classes.pageHeader}>
                <Typography variant="h4" gutterBottom>
                  Archived Samples
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin}>
            {this.renderFilters()}
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin}>
            {this.getNotFoundBlock(filterNFList, stateFilters)}
          </Grid>
          <Grid item xs={12} className={classes.sectionMargin1}>
            {fetchDataRequestPending ? (
              <CircularPageLoader open={fetchDataRequestPending} />
            ) : (
              <FlexibleTable
                cascadeFieldKeys={[]}
                checkBoxEnabled={true}
                columnData={selectedSettingsColsCopy}
                data={data}
                uniqueIdentifierInData={'pegasusId'}
                dataFieldKeys={dataFieldKeys}
                numSelected={numSelected}
                onCheckBoxChange={onCheckBoxChange}
                onCheckBoxHeaderChange={onCheckBoxHeaderChange}
                onClick={onClick}
                onSelected={this.onSelected}
                selected={selectedData}
                clickableColumn={clickableColumn}
                showPopup={showPopup}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={this.setPageAndRefresh}
                setRowsPerPage={this.setRowsPerPageAndRefresh}
                paginationAtApiLevel={true}
                enableSearch
                enableDownload={true}
                downloadFunction={this.downloadCSFunction('CSV')}
                enableMenu
                menuClickFunction={this.handleOptionsClick}
                totalRowsCount={totalRowsCount}
                launchInNewTabColumns={launchInNewTabColumns}
              >
                <IconButton style={{ float: 'right' }}>
                  <MoreVert />
                </IconButton>
              </FlexibleTable>
            )}
          </Grid>
          {!!showSettings && (
            <PopUp
              title={'Column Settings'}
              isButtonVisible={true}
              onProceed={this.handleSettingClick}
              isVisible={!!showSettings}
              onClose={this.callSetColumnSettings}
              primaryButtonText={'Apply'}
              maxWidth="md"
              fullWidth={true}
              secondaryButtonText="Apply &#38; Save"
              content={this.columnSettingsContent()}
              onPopupClose={this.handleSettingClick}
            />
          )}
          {!!popupIsVisible && (
            <TabbedPopUp
              dataTabLabels={dataTabLabelsShipped}
              isVisible={popupIsVisible}
              data={makeTabTables(
                popupItem,
                classes,
                dataTabLabelsShipped,
                updateSampleFieldsData,
                [],
                pageName
              )}
              onClose={closePopup}
              title={getTitle(popupItem)}
              fullWidth={true}
            />
          )}
          {!!alertIsVisible && (
            <DialogBox
              isAlertVisible={alertIsVisible}
              message={alertMessage}
              onClose={closeAlert}
              response={response}
              items={selectedData}
              onAgree={unarchiveAgree}
              title={dialogTitle}
              primaryButtonText="Yes, Unarchive Sample(s)"
              secondaryButtonText="Nevermind"
            />
          )}
        </Grid>
        {!!this.toggleOpen() && this.renderBottomBar()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    searchArchivedSamples: {
      availableFilters = [],
      columnData = [],
      clickableColumn = [],
      data = [],
      numSelected = Number(0),
      onCheckBoxChange = () => {},
      onCheckBoxHeaderChange = () => {},
      onClick = () => {},
      page = 1,
      popupIsVisible = false,
      item = [],
      selectedData = [],
      pageName = '',
      rowsPerPage,
      totalRowsCount,
      filterNFList = {},
      fetchDataRequestPending = false,
      setColumnSettingsPending = false,
      searchedColumns = [],
      isSearchedColumns = false,
      editColumnData = [],
      autoRefreshData = false,
      alertIsVisible = false,
      alertMessage = '',
      response = false,
      dialogTitle = '',
      unarchiveSamplePending = false,
    },
    user: { barcodeType = '', location = '' },
    newShootType: { channels = [] },
    newShipment: { projectNames = [] },
  } = state
  return {
    availableFilters: availableFilters,
    barcodeType: barcodeType,
    columnData: columnData,
    clickableColumn: clickableColumn,
    channels: channels,
    data: data,
    userLocation: location,
    numSelected: numSelected,
    onCheckBoxChange: onCheckBoxChange,
    onCheckBoxHeaderChange: onCheckBoxHeaderChange,
    onClick: onClick,
    page: page,
    popupIsVisible: popupIsVisible,
    popupItem: item,
    selectedData: selectedData,
    filters: selectPageFilters(pageName)(state),
    rowsPerPage: rowsPerPage,
    totalRowsCount: totalRowsCount,
    filterNFList: filterNFList,
    projectNames: projectNames,
    fetchDataRequestPending: fetchDataRequestPending,
    setColumnSettingsPending: setColumnSettingsPending,
    searchedColumns: searchedColumns,
    isSearchedColumns: isSearchedColumns,
    pageName: pageName,
    editColumnData: editColumnData,
    autoRefreshData: autoRefreshData,
    alertIsVisible: alertIsVisible,
    alertMessage: alertMessage,
    response: response,
    dialogTitle: dialogTitle,
    unarchiveSamplePending: unarchiveSamplePending,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    unarchiveAgree: (selectedData) => dispatch(unArchieveSamples(selectedData)),
    closeAlert: () => dispatch(closeAlert()),
    getBarcodes: (samples, showBarcodesCallback, type) => {
      dispatch(getBarcodes(samples, showBarcodesCallback, type))
    },
    getDuplicateBarcode: (samples, type) =>
      dispatch(getDuplicateBarcode(samples, type)),
    getProjectChannel: () => dispatch(getProjectChannel()),
    closePopup: () => {
      dispatch(closePopup())
    },
    downloadContactSheet: (params, downloadType = '') => {
      dispatch(downloadContactSheet(params, downloadType))
    },
    downloadLaunchDateReport: () => {
      dispatch(downloadLaunchDateReport())
    },
    duplicateSamples: (samples) => dispatch(duplicateSamples(samples)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
    setColumnSettings: (data = [], lanId = '', callBack = () => {}) =>
      dispatch(setColumnSettings(data, lanId, callBack)),
    resetIsCallSearchFields: () => dispatch(resetIsCallSearchFields()),
    fetchData: (
      params,
      successCallback = () => {},
      failCallback = () => {}
    ) => {
      dispatch(getData(params, successCallback, failCallback))
    },
    onCheckBoxChange: (selected) => dispatch(setCheckBox(selected)),
    onCheckBoxHeaderChange: (selected = []) =>
      dispatch(setCheckBoxHeaderChange(selected)),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setFilters: (filters, pageName) => dispatch(setFilters(filters, pageName)),
    setBarcodeType: (barcodeType) => dispatch(setBarcodeType(barcodeType)),
    showPopup: async (item) => {
      await dispatch(showPopup(item))
      await dispatch(triggerUpdateHistoryData(item))
    },
    handleChipClick: (chip) => {
      copy(chip.target.innerText)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    emptySelectedData: () => dispatch(emptySelectedData()),
    autoRefreshSearch: (pending = false) =>
      dispatch(autoRefreshSearch(pending)),
    saveEditInformation: (
      itemInformation,
      userId,
      params,
      successCallBack = () => {}
    ) =>
      dispatch(updateSamples(itemInformation, userId, params, successCallBack)),
    showAlert: (selectedData = []) => dispatch(showAlert(selectedData)),
  }
}

SearchArchivedSamples.propTypes = {
  availableFilters: propTypes.array,
  columnData: propTypes.array,
  data: propTypes.array,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onClick: propTypes.func,
  page: propTypes.number,
  rowsPerPage: propTypes.number,
  filterNFList: propTypes.object,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(SearchArchivedSamples)
