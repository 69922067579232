import React from 'react'
import withHOCs from 'util/withHocs'
import { isEqual } from 'lodash'
import { Create } from '@material-ui/icons'
import { SwipeableDrawer, Box } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { CSVLink } from 'react-csv'
import apiConfig from '../../apiConfig'
import DownloadIcon from '@mui/icons-material/Download'
import {
  fetchAutoOrderGridSample,
  openAutoOrder,
} from '../../store/autoOrderSamples/actionCreator'
import {
  getAutoOrderGridColumn,
  getAutoOrderSamples,
  getCsvCompleteAutoOrderSamples,
  getCsvHeaders,
} from '../../store/autoOrderSamples/selectors'
import {
  getAllBrands,
  getAllDepartments,
  editFlyOut,
  editSubmit,
} from '../../store/autoOrderFilters/actionCreator'
import {
  getAllBrandOptions,
  getAllDepartmentsOptions,
} from '../../store/autoOrderFilters/selectors'
import { Link } from 'react-router-dom'
import { Grid, Button } from '@material-ui/core'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CheckBoxAutoComplete from './CheckBoxAutoComplete' //need to convert into component
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import EditAutoOrderSamplesFlyout from './EditAutoOrderSamplesFlyout'
const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  autoOrderDownload: {
    marginLeft: '77%',
    position: 'absolute',
    marginTop: '75px',
  },
  autoOrderDownloadButton: {
    textDecoration: 'none',
    color: '#366CD9',
  },
  autoOrderEdit: {
    marginLeft: '85%',
    position: 'absolute',
    marginTop: '75px',
  },
  autoOrderNotAuthorizedEdit: {
    marginLeft: '78%',
    position: 'absolute',
    marginTop: '75px',
  },
  downloadButton: {
    marginLeft: '64.5%',
    position: 'absolute',
    marginTop: '75px',
  },
})
export class AutoOrderingGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: {},
    }
  }
  componentDidMount() {
    const {
      getAllBrands = () => {},
      getAllDepartments = () => {},
      setHeaderTitleArray = () => {},
    } = this.props
    setHeaderTitleArray([{ title: 'Auto Ordering Settings' }])
    getAllBrands()
    getAllDepartments()
    this.refreshAutoOrderGrid()
  }
  componentDidUpdate(prevProps, prevState) {
    const { filters: prevStateFilters = {} } = prevState
    const { filters = {} } = this.state
    if (
      Object.entries(filters).length > 0 &&
      !isEqual(filters, prevStateFilters)
    ) {
      this.refreshAutoOrderGrid()
    }
  }
  componentWillUnmount() {
    const { openAutoOrder = () => {} } = this.props
    openAutoOrder('')
  }
  refreshAutoOrderGrid = () => {
    const { fetchAutoOrderGridSample = () => {} } = this.props
    const { filters = {} } = this.state
    let updatedFilters = {}
    const filterTypeKeys = {
      Department: 'departmentId',
      Brand: 'brandName',
    }
    Object.keys(filters).map((filterType = '') => {
      const currentFilter = filters[filterType] || []
      const curFilterSelValues = currentFilter.map((obj) => obj.id)
      updatedFilters[filterTypeKeys[filterType] || filterType] = {}
      updatedFilters[filterTypeKeys[filterType] || filterType][
        'requestParamValue'
      ] = curFilterSelValues
      return filterType
    })
    fetchAutoOrderGridSample(updatedFilters)
  }
  renderAutoComplete = (
    filterOptions = [],
    label = '',
    onChange = () => {},
    selectedValue = []
  ) => {
    return (
      <CheckBoxAutoComplete
        options={filterOptions}
        label={label}
        styles={{ alignSelf: 'center' }}
        onChange={onChange}
        selectedValue={selectedValue}
        limitTags={2}
      />
    )
  }
  onAutoCompleChange =
    (label) =>
    (event, value = [], reason) => {
      const { filters: stateFilters = {} } = this.state
      const finalStateFilters = Object.assign({}, stateFilters, {
        [label]: value,
      })
      this.setState((state) => {
        return {
          ...state,
          filters: finalStateFilters,
        }
      })
    }
  additionalFiltersContent = () => {
    const {
      allDepartmentsOptions = [],
      editFlyOut = () => {},
      csvCompleteAutoOrderSamples = [],
      csvHeaders = [],
      classes = {},
    } = this.props
    const { filters = {} } = this.state

    const csvReport = {
      data: csvCompleteAutoOrderSamples,
      headers: csvHeaders,
      filename: 'Auto_ordering_Settings.csv',
    }
    const {
      roles: { autoOrderEdit = [] },
    } = apiConfig
    const {
      auth: { isAuthorized = () => {} },
    } = this.props
    return (
      <Grid container>
        <Grid
          container
          spacing={2}
          style={{
            margin: '14px 0px',
          }}
        >
          {/** commenting for sometime */}
          {/* <Grid item style={{ width: '300px' }}>
          {this.renderAutoComplete(
            allBrandOptions,
            'Brand',
            this.onAutoCompleChange,
            filters['Brand']
          )}
        </Grid> */}
          <Grid item style={{ width: '300px' }}>
            {this.renderAutoComplete(
              allDepartmentsOptions,
              'Department',
              this.onAutoCompleChange,
              filters['Department']
            )}
          </Grid>
        </Grid>
        {isAuthorized(autoOrderEdit) ? (
          <Grid item className={classes.autoOrderEdit}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="primary"
                to="/"
                startIcon={<Create />}
                onClick={() => editFlyOut(true)}
              >
                {'Edit'}
              </Button>
            </Box>
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid item xs={12} className={classes.downloadButton}>
          <Button
            id="rsf_downloadshiptoloc"
            color="primary"
            variant="outlined"
            onClick={() => {}}
            // className={classes.}
            startIcon={<DownloadIcon />}
            href="/pegasusdocs/TCIN Assets_Approved Exceptions List.xlsx"
          >
            Exception List
          </Button>
        </Grid>
        <Grid
          item
          className={
            isAuthorized(autoOrderEdit)
              ? classes.autoOrderDownload
              : classes.autoOrderNotAuthorizedEdit
          }
        >
          <Button variant="outlined" color="primary" startIcon={<GetApp />}>
            <CSVLink {...csvReport} className={classes.autoOrderDownloadButton}>
              As CSV
            </CSVLink>
          </Button>
        </Grid>
      </Grid>
    )
  }
  editSubmitData = (requestBody) => {
    const { editSubmit = () => {} } = this.props
    editSubmit(requestBody)
  }
  renderEditFlyout = () => {
    const {
      editFlyOut = () => {},
      selectedData = [],
      allDepartmentsOptions = [],
      editSubmit = () => {},
      autoOrderSamples = [],
    } = this.props
    return (
      <Box sx={{ width: 'auto', minWidth: 400 }} role="presentation">
        <EditAutoOrderSamplesFlyout
          closePopup={editFlyOut}
          selectedOptions={selectedData}
          handleSubmit={editSubmit}
          allDepartmentsOptions={allDepartmentsOptions}
          refreshAutoOrderGrid={this.refreshAutoOrderGrid}
          data={autoOrderSamples}
        />
      </Box>
    )
  }

  render() {
    const {
      classes = {},
      autoOrderGridColumn = [],
      autoOrderSamples = [],
      editFlyoutOpen = false,
      renderBackButton = true,
      fetchAutoORderGridLoading = false,
    } = this.props

    return (
      <div className={classes.root}>
        <Grid container justify={'space-between'} alignItems={'center'}>
          <Grid item>
            <h1>Auto Ordering Grid</h1>{' '}
          </Grid>
          {renderBackButton && (
            <Grid item>
              <Button
                variant="text"
                color="primary"
                component={Link}
                to="/"
                startIcon={<ArrowBackIcon />}
                className={classes.filtersContentCls}
              >
                {'Back To Auto Order'}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <SwipeableDrawer anchor="right" open={editFlyoutOpen}>
            <Box
              sx={{
                width: 500,
              }}
              role="presentation"
            >
              {this.renderEditFlyout()}
            </Box>
          </SwipeableDrawer>
          <Grid item xs={12}>
            <FlexibleTable
              cascadeFieldKeys={[]}
              checkBoxEnabled={false}
              columnData={autoOrderGridColumn}
              data={autoOrderSamples}
              uniqueIdentifierInData={'brandId'}
              dataFieldKeys={[]}
              editEnabled={false}
              enableSearch
              isLoading={fetchAutoORderGridLoading}
              paginationAtApiLevel={false}
              showPopup={() => {}}
              additionalActions={true}
              additionalFiltersContent={this.additionalFiltersContent}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    tabValue: state.order.tabValue,
    autoOrderSamples: getAutoOrderSamples()(state),
    autoOrderGridColumn: getAutoOrderGridColumn()(state),
    csvHeaders: getCsvHeaders()(state),
    allBrandOptions: getAllBrandOptions()(state),
    allDepartmentsOptions: getAllDepartmentsOptions()(state),
    csvCompleteAutoOrderSamples: getCsvCompleteAutoOrderSamples()(state),
    editFlyoutOpen: state.autoOrderFilters.editFlyoutOpen,
    fetchAutoORderGridLoading: state.autoOrderSamples.fetchAutoORderGridLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAutoOrderGridSample: (params = {}) =>
      dispatch(fetchAutoOrderGridSample(params)),
    getAllBrands: () => dispatch(getAllBrands()),
    getAllDepartments: () => dispatch(getAllDepartments('')),
    editFlyOut: (data) => dispatch(editFlyOut(data)),
    editSubmit: (requestBody = []) => dispatch(editSubmit(requestBody)),
    setHeaderTitleArray: (headerTitleArray = []) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    openAutoOrder: (data) => dispatch(openAutoOrder(data)),
  }
}
export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(AutoOrderingGrid)
