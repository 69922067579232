import Grid from '@material-ui/core/Grid'
import { Modal, Popover } from '@material-ui/core'
import { CustomPaper } from 'util/CommonUtils'
export default function EditPopOver(props) {
  const {
    iconClick = false,
    setIconClick = () => {},
    mouseLocationX,
    mouseLocationY,
    fields = [],
    pathname = '',

    // renderPopOver = () => {},
  } = props
  const renderCustomPaper = () => {
    return (
      <CustomPaper
        style={{
          width: '100px',
          minHeight: '100px',
          minWidth: '250px',
        }}
      >
        {fields.map((fieldInstance) => {
          return (
            <Grid container spacing={6}>
              <Grid item xs={12} md={16} style={{ marginLeft: '5%' }}>
                {fieldInstance}
              </Grid>
            </Grid>
          )
        })}
      </CustomPaper>
    )
  }
  const renderContent = () => {
    let cmp = ''
    !pathname.includes('batchScanner')
      ? (cmp = (
          <Modal
            open={iconClick}
            onClose={() => setIconClick(false)}
            className="popover"
            id={Math.random()}
            hideBackdrop
            style={{
              zIndex: 3300,
              left: mouseLocationX + 'px',
              top:
                window.innerHeight - mouseLocationY > 150
                  ? mouseLocationY + 'px'
                  : mouseLocationY - 130 + 'px',
              position: 'absolute',
            }}
          >
            {renderCustomPaper()}
          </Modal>
        ))
      : (cmp = (
          <Popover
            open={iconClick}
            onClose={() => setIconClick(false)}
            className="popover"
            id="simple-popover"
            anchorReference="anchorPosition"
            transformOrigin={{
              vertical: 'left',
              horizontal: 'left',
            }}
            anchorPosition={{
              top: mouseLocationY,
              left: mouseLocationX,
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {renderCustomPaper()}
          </Popover>
        ))
    return cmp
  }
  return <div>{renderContent()}</div>
}
