//

import { cloneDeep } from 'lodash'
import {
  MAX_TABS_LIST_WIDTH,
  TAB_PADDING_MAP,
  getScreenSizeKey,
} from '../constants'

/**
 * Reduces an array of objects with `width` properties
 * into a single value.
 *
 * @param  {Array} children - list of object
 * @param {Number} children[n].width - pixel with of the longest content line
 * @return {Number}
 */
export function getChildWidthSum(children, padding = 0) {
  const paddingSum = children.length * padding * 2
  const childWidthSum = children.reduce((res, next) => res + next.width, 0)

  return childWidthSum + paddingSum
}

/**
 * Takes an array of objects and sorts them first by
 * matching each object's `to` prop to passed `activePathname`
 * value and secondly by each object's `index` value.
 *
 * @param  {Array} childList - list of objects
 * @param  {String} childList[n].to - unique pathname
 * @param  {Number} childList[n].index - original index in list
 * @param  {String} activePathname - current pathname
 * @return {Array} sorted version of input
 */
export function sortByActive(childList = [], activePathname) {
  const head = []
  const tail = []

  for (let key = 0; key < childList.length; key++) {
    const match = childList[key].to === activePathname ? head : tail

    match.push(childList[key])
  }

  tail.sort((a, b) => a.index - b.index)

  return head.concat(tail)
}

/**
 * Reverts sorting that occured in `sortByActive`
 *
 * @param  {Array} childList - list of children as objects
 * @return {Array} sorted input
 */
export function sortByIndex(childList) {
  return childList.sort((prev, next) => prev.index - next.index)
}

/**
 * Given a list of children with a minimum of `to` and `width`
 * attributes, split the list into two lists.
 *
 * The first list contains the child where `to` matches the
 * passed `activePathname` and X amount of additional children
 *  who's `width`s are close to but not greater than
 * `MAX_TABS_LIST_WIDTH`.
 *
 * The second list contains all remaining children.
 *
 * [1] sort list so active item is always at index 0
 * [2] Initial `width` attributes don't account for button padding
 * [3] add items until max width is reached
 * [4] set remaining items
 * [5] undo the previous sort to ensure consistent display of content
 * [6] build expected return format
 *
 * @param {Array} childList list of objects with minumum `to` and
 *                          `width` attributes
 * @param {String} activePathname the url to match against
 * @returns {Array} [`pass`, `fail`] pair of entries matching the input
 */
export function partitionChildren(childList = [], activePathname) {
  const workingList = sortByActive(childList, activePathname) // [1]
  const length = workingList.length
  const tabPadding = TAB_PADDING_MAP[getScreenSizeKey()] // [2]

  let pass = []
  let fail = []

  for (let key = 0; key < length; key++) {
    const maybePass = cloneDeep(pass)

    maybePass.push(workingList[key])

    if (getChildWidthSum(maybePass, tabPadding) > MAX_TABS_LIST_WIDTH) {
      // [3]
      fail = workingList.splice(key, length) // [4]
      break
    }

    pass = maybePass
  }

  return [
    sortByIndex(pass), // [5]
    sortByIndex(fail), // [5]
  ] // [6]
}
