export const GET_COLLECTIONS_REQUEST_PENDING = 'GET_COLLECTIONS_REQUEST_PENDING'
export const GET_COLLECTIONS_REQUEST_SUCCESS = 'GET_COLLECTIONS_REQUEST_SUCCESS'
export const GET_COLLECTIONS_REQUEST_FAILURE = 'GET_COLLECTIONS_REQUEST_FAILURE'
export const COLLECTIONS_CREATE_REQUEST_PENDING =
  'COLLECTIONS_CREATE_REQUEST_PENDING'
export const COLLECTIONS_CREATE_REQUEST_SUCCESS =
  'COLLECTIONS_CREATE_REQUEST_SUCCESS'
export const COLLECTIONS_CREATE_REQUEST_FAILURE =
  'COLLECTIONS_CREATE_REQUEST_FAILURE'
export const COLLECTIONS_SET_FILTER = 'COLLECTIONS_SET_FILTER'
export const COLLECTIONS_CLEAR_FILTER = 'COLLECTIONS_CLEAR_FILTER'
export const COLLECTIONS_SHOW_CREATE_POPUP = 'COLLECTIONS_SHOW_CREATE_POPUP'
export const COLLECTIONS_CLOSE_CREATE_POPUP = 'COLLECTIONS_CLOSE_CREATE_POPUP'
export const CURRENT_COLLECTION_REQUEST_SUCCESS =
  'CURRENT_COLLECTION_REQUEST_SUCCESS'
export const UPDATE_COLLECTION_FILTERS = 'UPDATE_COLLECTION_FILTERS'
export const COLLECTIONS_SET_CHECKBOX = 'COLLECTIONS_SET_CHECKBOX'
export const COLLECTIONS_SET_CHECKBOX_HEADER = 'COLLECTIONS_SET_CHECKBOX_HEADER'
export const UPDATE_COLLECTIONS_STATUS_PENDING =
  'UPDATE_COLLECTIONS_STATUS_PENDING'
export const SET_COLLECTION_TABVALUE = 'SET_COLLECTION_TABVALUE'
export const SET_CREATOR_VALUE = 'SET_CREATOR_VALUE'
export const SET_LOCATION_VALUE = 'SET_LOCATION_VALUE'
export const SET_COLLECTIONS_SEARCH_VALUE = 'SET_COLLECTIONS_SEARCH_VALUE'
