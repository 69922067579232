import { SwipeableDrawer, Box } from '@material-ui/core'
import ShowIf from 'components/ShowIf'

function AddScenicItems({
  searchAndAdd,
  unavailableScenicRoomItems,
  isAddSamplesSliderOpen,
  onCloseAddItemSlider = () => {},
}) {
  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isAddSamplesSliderOpen}
        onClose={onCloseAddItemSlider}
      >
        <Box
          sx={{
            width: 750,
            height: 1000,
            overflowX: 'hidden',
          }}
          role="presentation"
        >
          <ShowIf condition={isAddSamplesSliderOpen}>
            {/* To reset all field data */}
            {searchAndAdd}
            <div style={{ margin: '32px 0 0 8px' }}>
              {unavailableScenicRoomItems}
            </div>
          </ShowIf>
        </Box>
      </SwipeableDrawer>
    </>
  )
}

export default AddScenicItems
