//

import * as React from 'react'
import classnames from 'classnames'
import { matchPath } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Accent from '../Accent'
import { MAX_TAB_WIDTH, TAB_PADDING_MAP } from '../constants'

export class Tab extends React.Component {
  static defaultProps = {
    classes: {},
    navigate: () => {},
    location: {
      pathname: '',
    },
    showAccent: false,
  }

  wrapper // see flow docs https://flow.org/en/docs/react/refs/

  state = {
    isActive: false,
  }

  componentWillMount() {
    this.setActiveState(this.props)
  }

  componentDidMount() {
    if (this.state.isActive) {
      this.props.onNavigate({
        from: this.getWrapperPosition(),
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setActiveState(nextProps)
  }

  render() {
    const { children, classes, isRambling, showAccent } = this.props

    const { isActive } = this.state

    const buttonRootClassList = classnames(classes.buttonRoot, {
      [classes.isContentRambling]: isRambling,
      [classes.isActive]: isActive,
    })

    return (
      <li
        className={classes.container}
        ref={(el) => {
          this.wrapper = el
        }}
      >
        <Button
          classes={{
            root: buttonRootClassList,
          }}
          onClick={this.handleClick}
        >
          {children.map((line) => (
            <span className={classes.line} key={line}>
              {line}
            </span>
          ))}
          {showAccent && isActive && <Accent />}
        </Button>
      </li>
    )
  }

  handleClick = () => {
    const { navigate, onNavigate, to } = this.props

    navigate(to)
    onNavigate({
      to: this.getWrapperPosition(),
    })
  }

  setActiveState = (targetProps) => {
    const { location, to, exact } = targetProps

    const isActive = !!matchPath(location.pathname, {
      path: typeof to === 'object' ? to.pathname : to,
      exact: typeof exact === 'boolean' ? exact : true,
    })

    this.setState({
      isActive,
    })
  }

  getWrapperPosition = () => {
    let left
    let width

    if (this.wrapper && this.wrapper.getBoundingClientRect) {
      const boundingRect = this.wrapper.getBoundingClientRect()
      left = boundingRect.left
      width = boundingRect.width
    }

    return {
      left,
      width,
    }
  }
}

const stylesheet = ({ breakpoints, palette }) => ({
  container: {
    listStyle: 'none',
  },
  buttonRoot: {
    color: palette.primary.contrastText,
    opacity: 0.7,
    display: 'block',
    fontSize: 14,
    height: 48,
    maxWidth: MAX_TAB_WIDTH,
    padding: 0,
    textAlign: 'center',
    position: 'relative',
    [breakpoints.down('md')]: {
      paddingLeft: TAB_PADDING_MAP.SM,
      paddingRight: TAB_PADDING_MAP.SM,
    },
    [breakpoints.up('md')]: {
      paddingLeft: TAB_PADDING_MAP.MD,
      paddingRight: TAB_PADDING_MAP.MD,
    },
  },
  isActive: {
    opacity: 1,
  },
  isContentRambling: {
    fontSize: 12,
  },
  line: {
    display: 'block',
    whiteSpace: 'nowrap',
    '& + &': {
      marginTop: 2,
    },
  },
})

export default withStyles(stylesheet)(Tab)
