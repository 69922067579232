//

// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { get } from 'lodash'
import { shape, string } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { getContrastRatio } from '@material-ui/core/styles/colorManipulator'
import classnames from 'classnames'

export function Accent({ classes }, context) {
  const contextClass = get(context, 'praxis.accentClass', '')
  const accentClassList = classnames(classes.root, {
    [contextClass]: contextClass.length,
  })

  return <div className={accentClassList} />
}

Accent.defaultProps = {
  classes: {},
}

Accent.contextTypes = {
  praxis: shape({
    accentClass: string,
  }),
}

const styleSheet = ({ palette }) => {
  const navBackgroundColor = palette.primary.main
  let accentBackgroundColor = palette.common.white

  if (getContrastRatio(navBackgroundColor, accentBackgroundColor) < 7) {
    accentBackgroundColor = palette.common.black
  }

  return {
    root: {
      background: accentBackgroundColor,
      position: 'absolute',
      height: 2,
      left: 0,
      right: 0,
      bottom: 0,
    },
  }
}

export default withStyles(styleSheet)(Accent)
