import {
  CLOSE_SIDE_NAV,
  OPEN_SIDE_NAV,
  SHOW_AUTH_POPUP,
  SET_HEADER_TITLE,
  SET_HEADER_TITLE_ARRAY,
  POPUP_APP_OPEN,
  OPEN_BARCODE_SIDE_NAV,
  CLOSE_BARCODE_SIDE_NAV,
} from './actionType'

export const initialState = {
  sideNavIsOpen: false,
  openPopup: 0,
  popupType: '',
  headerTitle: 'Pegasus',
  headerTitleArray: [],
  popupAppOpen: true,
  barcodeSideNavIsOpen: false,
}

export default function layoutReducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_SIDE_NAV: {
      const newState = {
        ...state,
        sideNavIsOpen: true,
      }
      return newState
    }
    case CLOSE_SIDE_NAV: {
      const newState = {
        ...state,
        sideNavIsOpen: false,
      }
      return newState
    }
    case SHOW_AUTH_POPUP: {
      const { payload } = action
      const newState = {
        ...state,
        openPopup: state.openPopup + 1,
        popupType: payload,
      }
      return newState
    }
    case SET_HEADER_TITLE: {
      const { payload } = action
      const newState = {
        ...state,
        headerTitle: payload,
      }
      return newState
    }
    case OPEN_BARCODE_SIDE_NAV: {
      const newState = {
        ...state,
        barcodeSideNavIsOpen: true,
      }
      return newState
    }
    case CLOSE_BARCODE_SIDE_NAV: {
      const newState = {
        ...state,
        barcodeSideNavIsOpen: false,
      }
      return newState
    }
    case SET_HEADER_TITLE_ARRAY: {
      //headerTitleArray should be an array of objects
      //Ex:[{ title: 'Ship Samples', link: '/shipSamples' },{ title: 'New Shipment' }]
      //Title is required, but link is optional.
      const { payload } = action
      const newState = {
        ...state,
        headerTitleArray: payload,
      }
      return newState
    }
    case POPUP_APP_OPEN: {
      const newState = {
        ...state,
        popupAppOpen: true,
      }
      return newState
    }
    default:
      return state
  }
}
