import moment from 'moment'
import {
  DATE_FORMAT,
  PAYLOAD_DATE_FORMAT,
  convertTableDateFormat,
  getDisplayName,
  getSampleIDPrefix,
  isHavingPrefix,
} from '../util/CommonUtils'
import { sampleLocationResponseToLocation } from './inventoryMapper'
import { isEmpty } from 'lodash'
import { PROPS, SAMPLE } from 'enums/Tabs'

export function ToProjectCreateRequest(userId) {
  return {
    created_by: userId,
  }
}

export function generatePicklistParams(pegasusIds, projectId) {
  const localPegasusIds = []
  pegasusIds.map((pegasusId) => {
    const isHavingPegasusPrefix = isHavingPrefix(pegasusId)
    if (isHavingPegasusPrefix) {
      pegasusId = pegasusId.slice(2, pegasusId.length)
      localPegasusIds.push(pegasusId)
    }
  })
  const params = {
    shoot_type_id: parseInt(projectId),
    pegasus_ids: localPegasusIds,
  }

  return params
}
export function getProjectPicklistData(pickListData = []) {
  let localeData = []
  pickListData.map((pickListDataInstance) => {
    const {
      item_data = {},
      sample_data = {},
      prop_data = {},
      type = SAMPLE,
      location = {},
      pegasus_id = '',
      description = '',
    } = pickListDataInstance
    let result = {}
    result['location'] =
      typeof sampleLocationResponseToLocation(location) === 'string'
        ? sampleLocationResponseToLocation(location)
        : ''
    result['dpci'] = item_data?.dpci
    result['pegasusId'] = getSampleIDPrefix(pegasus_id, type)
    result['description'] =
      type === PROPS ? prop_data?.description : description
    localeData.push(result)
  })
  return localeData
}
export function ToProjectUpdateRequest(updateRequest) {
  return {
    name: updateRequest.name,
    channel: updateRequest.channel,
    shoot_start_date: !isEmpty(updateRequest.shootStartDate)
      ? moment(updateRequest.shootStartDate).format(PAYLOAD_DATE_FORMAT)
      : null,
    shoot_end_date: !isEmpty(updateRequest.shootEndDate)
      ? moment(updateRequest.shootEndDate).format(PAYLOAD_DATE_FORMAT)
      : null,
    samples_required_by_date: !isEmpty(updateRequest.samplesRequiredBy)
      ? moment(updateRequest.samplesRequiredBy).format(PAYLOAD_DATE_FORMAT)
      : null,
    marketing_pid: updateRequest.marketingPid,
    notes: updateRequest.notes,
    location_id:
      updateRequest.locationId.length > 0
        ? updateRequest.locationId[0].location_id
        : null,
    active_f: true,
    auto_order_enabled: updateRequest?.auto_order_enabled,
    ship_to_phone_number: updateRequest?.ship_to_phone_number,
    project_editors: updateRequest?.project_editors,
  }
}

export function projectResponseToProjects(projects = []) {
  return projects.map((project = {}) => projectResponseToProject(project))
}

export const formattedUserObject = (obj = {}) => {
  const objDisplayName = getDisplayName(obj)
  const { lan_id: lanId = '' } = obj
  const formattedObj = Object.assign({}, obj, {
    displayName: objDisplayName || lanId,
    label: objDisplayName || lanId,
    value: lanId,
    id: lanId,
  })
  return formattedObj
}

export function projectResponseToProject(projectResponse = {}) {
  if (projectResponse !== null) {
    const { created_by = {}, project_editors = [] } = projectResponse
    const createdBy = formattedUserObject(created_by)
    const projectEditors = project_editors?.map((obj) => {
      return formattedUserObject(obj)
    })
    const projOwners = Array.from(new Set([...projectEditors, ...[createdBy]]))
    const projOwnersList = Array.from(
      new Set(projOwners.map((item) => item.lan_id))
    )
    return {
      id: projectResponse.project_id || '',
      channel: projectResponse.channel || '',
      hasPicklist: projectResponse?.has_picklist || false,
      createdBy:
        typeof created_by === 'string'
          ? created_by
          : getDisplayName(created_by),
      name: projectResponse.name || '',
      shootStartDate:
        convertTableDateFormat(projectResponse.shoot_start_date, DATE_FORMAT) ||
        '',
      shootEndDate:
        convertTableDateFormat(projectResponse.shoot_end_date, DATE_FORMAT) ||
        '',
      samplesRequiredBy:
        convertTableDateFormat(
          projectResponse.samples_required_by_date,
          DATE_FORMAT
        ) || '',
      marketingPid: projectResponse.marketing_pid || '',
      notes: projectResponse.notes || '',
      locationName:
        projectResponse.location !== null
          ? projectResponse?.location?.building
          : null,
      sampleCount:
        projectResponse.sample_count !== null
          ? projectResponse.sample_count
          : '',
      createDate: convertTableDateFormat(
        projectResponse.created_ts,
        DATE_FORMAT
      ),
      auto_order_enabled: projectResponse?.auto_order_enabled,
      ship_to_phone_number: projectResponse?.ship_to_phone_number,
      project_editors: projectEditors || [],
      project_owners: projOwnersList || [],
      percentageSampleReceived: projectResponse?.percentage_sample_received,
    }
  }
}

export function projectNamesResponseToSelect(response) {
  let returnArray = []

  for (let index in response.data.project_names) {
    if (response.data.project_names[index]) {
      returnArray.push({
        value: response.data.project_names[index],
        label: response.data.project_names[index],
      })
    }
  }

  return returnArray
}

export function projectNamesResponse(response = {}) {
  let returnArray = []
  const {
    data: { projects = [] },
  } = response
  const groupByReduce = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item.project_id],
      }),
      []
    )
  const arrayGrouped = groupByReduce(projects, 'name')
  returnArray = Object.keys(arrayGrouped).map((uniqueProject) =>
    Object.assign(
      {},
      {
        value: arrayGrouped[uniqueProject],
        label: uniqueProject,
      }
    )
  )
  return returnArray
}
