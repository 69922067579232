import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { isEqual } from 'lodash'
import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core'
const styles = (theme) => ({
  dialog: {
    width: '600px',
  },
})

class NameFiltersPopUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      checkBoxSelected: !this.props.title,
    }
  }
  handleChange = (event) => {
    this.setState((prevState) => ({
      title: event.target.value,
    }))
  }

  handleSave = () => {
    const { saveFilters } = this.props
    const { title = '', checkBoxSelected = false } = this.state
    saveFilters(title.trim(), checkBoxSelected)
  }
  onCheckBoxChange = () => {
    const { checkBoxSelected = false } = this.state
    this.setState({
      checkBoxSelected: !checkBoxSelected,
    })
  }
  render() {
    const { classes, onClose, isVisible, selectedFilter, title } = this.props
    const { title: stateTitle } = this.state
    return (
      <Dialog
        aria-labelledby="Save Filters"
        aria-describedby="A text field where you can give your custom filters a title before saving."
        open={isVisible}
        onClose={onClose}
      >
        <DialogTitle>Save Filters</DialogTitle>
        <DialogContent className={classes.dialog}>
          <TextField
            id="filtersTitle"
            label="Name/Rename Your Saved Filters"
            fullWidth
            type="text"
            margin="normal"
            variant="outlined"
            onChange={this.handleChange}
            value={stateTitle}
          />
        </DialogContent>
        <DialogActions>
          <FormGroup>
            <FormControlLabel
              disabled={isEqual(stateTitle.trim(), title.trim())}
              control={
                <Checkbox
                  checked={this.state.checkBoxSelected}
                  onChange={() => this.onCheckBoxChange()}
                />
              }
              label="Save As a New Filter"
            />
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            id="saveFiltersFinal"
            onClick={this.handleSave}
            defaultValue={selectedFilter ? title : ''}
            disabled={stateTitle.trim() === ''}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(NameFiltersPopUp)
