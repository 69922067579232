import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  iconMargin: {
    margin: `${theme.spacing(2)}px 0px 0px ${theme.spacing(2)}px`,
  },
  menuRoot: {
    fontSize: '14px',
    color: '#666',
  },
  notchedOutlineNew: {
    borderColor: '#8d8d8d !important',
    borderWidth: '2px !important',
  },
  searchInput: {
    margin: 'unset',
    color: '#666',
    fontSize: '14px',
    width: 'auto',
    minWidth: '165px',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      backgroundColor: 'unset !important',
    },
  },
  searchInputLabel: {
    color: '#666 !important',
    fontSize: '14px',
    fontWeight: 'initial',
  },
}))

const createOptionsList = (options = {}, optionClass) =>
  Object.keys(options).map((key, index) => {
    return (
      <MenuItem key={index} value={key} classes={{ root: optionClass }}>
        {options[key] || key}
      </MenuItem>
    )
  })

export default function CustomizedSelects(props) {
  const classes = useStyles()
  const {
    options: renderOptions = {},
    handleChange = () => {},
    value: selectedValue = '',
    variantType = 'standard',
    labelId = '',
    id: elementID = '',
    isChildExists = false,
    children = { none: 'None' },
    isDisabled = false,
  } = props
  return (
    <div className={classes.iconMargin}>
      <FormControl className={classes.formControl} variant={variantType}>
        <TextField
          id={elementID}
          label={labelId}
          variant={variantType}
          color="secondary"
          select
          disabled={isDisabled}
          onChange={handleChange}
          value={selectedValue}
          size="small"
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          InputLabelProps={{
            className: classes.searchInputLabel,
          }}
          InputProps={{
            classes: {
              input: classes.searchInput,
              notchedOutline: classes.notchedOutlineNew,
              focused: classes.notchedOutlineNew,
            },
          }}
        >
          {isChildExists
            ? children
            : createOptionsList(renderOptions, classes.menuRoot)}
        </TextField>
      </FormControl>
    </div>
  )
}
