export const ADDPROP_FETCH_DATA_FAILURE = 'ADDPROP_FETCH_DATA_FAILURE'
export const ADDPROP_FETCH_DATA_REQUEST_PENDING = 'ADDPROP_FETCH_DATA_PENDING'
export const ADDPROP_FETCH_DATA_SUCCESS = 'ADDPROP_FETCH_DATA_SUCCESS'
export const ADDPROP_SEARCH_SET_CHECKBOX = 'ADDPROP_SEARCH_SET_CHECKBOX'
export const ADDPROP_SEARCH_SET_CHECKBOX_HEADER =
  'ADDPROP_SEARCH_SET_CHECKBOX_HEADER'
export const ADDPROP_CLEAR_DATA = 'ADDPROP_CLEAR_DATA'
export const ADDPROP_FILE_UPLOAD_PROGRESS = 'ADDPROP_FILE_UPLOAD_PROGRESS'
export const ADDPROP_REMOVE_FILE_FROM_UPLOADLIST =
  'ADDPROP_REMOVE_FILE_FROM_UPLOADLIST'
export const RESET_ADDPROP_DATA = 'RESET_ADDPROP_DATA'
export const ADD_PROP_STATE = 'ADD_PROP_STATE'
