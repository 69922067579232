import { useRef } from 'react'
import { withStyles } from '@material-ui/core'
import ChipArray from 'components/Chips/ChipArray'
import SampleSearch from 'components/Search/SampleSearch'
import { Button } from '@mui/material'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { CheckCircleOutlined } from '@mui/icons-material'

const styles = () => ({
  appBarToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#333333',
    color: '#ffffff',
  },
  pageInfo: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '20px 0 0px 25px',
  },
  sampleSearch: {
    margin: '0px 15px 10px 12px',
  },
  customButton: {
    display: 'inline',
    marginLeft: '60%',
  },
  defaultButton: {
    maxWidth: '300px',
    width: 'auto',
  },
  unavailableDataContainer: {
    margin: '24px 40px 15px 16px',
  },
  iconWrapperSucess: {
    margin: '0 10px 0 0',
    color: '#21A968',
    float: 'left',
  },
})

const SuccessSampleData = ({ classes, addSamplesResponse }) => {
  const successfulSamples = addSamplesResponse?.length
  return (
    <>
      <div className={classes.unavailableDataContainer}>
        <span className={classes.iconWrapperSucess}>
          <CheckCircleOutlined />
        </span>
        {`${
          successfulSamples || Number(0)
        } Sample/Prop(s) were added successfully`}
      </div>
    </>
  )
}

const SearchAndAddToShipment = ({
  shipFromLocName,
  fetchSamples,
  shipmentId,
  clearSearch,
  sampleidsCount,
  unknownPegasusIds,
  chipData,
  handleChipClick,
  userId,
  getSamplesCount,
  classes,
  setIsAddSamplesSliderOpen,
  addSamplesResponse = {},
  // closeDrawer,
}) => {
  const searchRef = useRef()

  const addSamples = () => {
    if (unknownPegasusIds.length > 0) {
      // this.handleForceSave()
    } else {
      // invoking child SampleSearch method for reuse component
      searchRef.current.handleSearch()
    }
  }

  const closeFlyout = () => {
    clearSearch()
    setIsAddSamplesSliderOpen(false)
  }

  return (
    <div>
      <>
        <Divider />
        <AppBar
          position="sticky"
          color="transparent"
          elevation={1}
          sx={{
            top: 'auto',
            bottom: 0,
            backgroundColor: '#333333',
            color: '#ffffff',
          }}
        >
          <Toolbar className={classes.appBarToolBar}>
            <Typography variant="h6" component="div">
              Add Item
            </Typography>
            <IconButton
              aria-label="close"
              // onClick={closeDrawer}
              onClick={closeFlyout}
              style={{ color: '#ffffff' }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />

        <div className={classes.pageInfo}>
          Add items that have already been ordered
        </div>
        <div className={classes.pageInfo}></div>
        <div className={classes.sampleSearch}>
          <SampleSearch
            ref={searchRef}
            label="Scan or Enter Pegasus ID"
            buttonName="Enter"
            buttonVariant="text"
            submitSearch={fetchSamples}
            userId={userId}
            shipmentNumber={shipmentId}
            shipFromLocation={shipFromLocName}
            clearSearchCallback={clearSearch}
            clearOnSubmit
            onChangeText={getSamplesCount}
            hideBorder
            hideButtons
            helperText={
              sampleidsCount
                ? `${sampleidsCount} Pegasus Id(s)`
                : 'Pegasus Id(s)'
            }
          />
        </div>

        {/* added sample */}
        {addSamplesResponse?.length > 0 && (
          <SuccessSampleData
            classes={classes}
            addSamplesResponse={addSamplesResponse}
          />
        )}

        {unknownPegasusIds.length > 0 && (
          <div style={{ marginTop: '34px' }}>
            <ChipArray
              data={chipData}
              enableCopyAllButton
              handleClick={handleChipClick}
              title="Unknown Sample Ids or Already added"
            />
          </div>
        )}

        <Divider />
        <div className={classes.customButton}>
          <Button
            id="handleCancel"
            data-id="CANCEL"
            data-cy="cancel"
            className={classes.defaultButton}
            variant="outlined"
            color="primary"
            style={{ margin: '16px' }}
            // disabled={this.disableCreateButton()}
            // onClick={closeDrawer}
            onClick={closeFlyout}
          >
            Close
          </Button>
          <Button
            id="handleUpdate"
            data-id="UPDATE"
            data-cy="projectSave"
            className={classes.defaultButton}
            variant="contained"
            color="primary"
            // disabled={isAddSamplesDisabled}
            onClick={addSamples}
            // onClick={this.handleUpdate.bind(this)}
          >
            Add to Shipment
          </Button>
        </div>
      </>
    </div>
  )
}

export default withStyles(styles)(SearchAndAddToShipment)
