import React from 'react'
import * as propTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import {
  Card,
  Checkbox,
  StepLabel,
  Step,
  Stepper,
  Grid,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  TextField,
  IconButton,
} from '@material-ui/core'
import ChipArray from '../../components/Chips/ChipArray'
import copy from 'copy-to-clipboard'
import CustomDropdown from '../../util/CustomDropdown'
import {
  clearCheckinData,
  closePopup,
  checkinSamples,
  validateArgs,
  getCheckinLocation,
  showPopup,
  removeSample,
  setSampleStatusShipped,
  getRelatedSamples,
  cloneSample,
  updateSampleId,
  addSampleToPrintQueue,
  getBarcodes,
  printAllBarcodes,
  setSampleLocation,
  assignLocation,
  assignLocationToMIP,
  setPreviousPage,
  removeUnknownSampleFromList,
  removeUnknownLocationFromList,
  removeDuplicateSampleFromList,
  updateSampleFieldsData,
  removeSampleFromPrintQueueAll,
} from '../../store/checkIn/actionCreator'
import SampleDetailCard from '../../components/ExpandableCard/SampleDetailCard'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Error from '@material-ui/icons/Error'
import Print from '@material-ui/icons/Print'
import Warning from '@material-ui/icons/Warning'
import {
  resolveDuplicate,
  removeInvalidSample,
  assignDetailedLocationNew,
} from './Resolution'
import ResolutionTree from '../../components/ResolutionTree/ResolutionTree'
import LocalPrintshop from '@material-ui/icons/LocalPrintshop'
import { partition, isEmpty } from 'lodash'
import { showNotification } from '../../store/notification/actionCreator'
import withHOCs from 'util/withHocs'
import {
  ValidationOperation,
  AssignLocationOperationLabels,
} from '../../enums/validationOperations'
import { PROPS, SCENIC } from 'enums/Tabs'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
import BarcodeTypePopUp from '../../components/PopUp/BarcodeTypePopUp'
import PopUp from '../../components/PopUp/PopUp'
import { setBarcodeType } from '../../store/user/actionCreator'
import ClearAllIcon from '@material-ui/icons/ClearAll'

import TabbedPopUp from '../../components/PopUp/TabbedPopUp'
import {
  ITME_TAB_LABEL,
  DETAILS_TAB_LABEL,
  dataTabLabelsShippedWithScenic,
} from '../../enums/tableTabs'
import {
  dataTabLabelsShippedWithProps,
  dataTabLabelsShipped,
} from '../../enums/tableTabs'
import { makeTabTables, getTitle } from '../../util/Table'
import { DETAILED_LOCATIONS, STEPPER_ACTIONS } from './constants'

const styles = (theme) => ({
  checkInPage: {
    flexGrow: 1,
  },
  checkInPageIntro: {
    fontSize: 'x-large',
    textAlign: 'center',
  },
  barcodeText: {
    margin: '3px 0px 7px 0px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 'normal',
    display: 'inline-block',
  },
  stepper: {
    padding: '24px 10px',
    float: 'left',
  },
  assignLocSubLabel: {
    height: '42px',
  },
  selectAllControl: {
    height: '42px',
    width: '130px',
  },
  card: {
    padding: '0px 20px',
  },
  messageArea: {
    padding: '15px 26px !important',
  },
  searchArea: {
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  selectAllArea: {
    padding: '16px 26px !important',
  },
  assignMessageArea: {
    padding: '15px 30px 15px 30px !important',
  },
  actionButtonsLarge: {
    color: theme.palette.primary.darkBlue,
    boxShadow: 'unset',
    backgroundColor: theme.palette.primary.lightestGrey,
    marginLeft: '10px',
    fontSize: '12px',
    width: 150,
  },
  filterCheckboxChecked: {
    color: '#366CD9 !important',
  },
  completeAlertTitle: {
    fontSize: '24px',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: theme.spacing(1),
  },
  searchInput: {
    width: '360px',
    paddingTop: '2px',
  },
  searchInputLabel: {
    color: '#666 !important',
    fontSize: '14px',
    fontWeight: 'initial',
  },
  notchedOutlineNew: {
    borderColor: '#8d8d8d !important',
    borderWidth: '2px !important',
  },
  clearAll: {
    margin: 10,
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  searchButton: {
    margin: 10,
  },
})

const theme = createTheme({
  spacing: 3,
})

let operation = ValidationOperation.MOVE_SAMPLES

class MovePage extends React.Component {
  constructor(props) {
    super(props)
    this.ResolutionElement = React.createRef()
    this.state = {
      popupItem: {},
      popupIsVisible: false,
      selectedData: [],
      activeStep: 0,
      resolutionOpen: false,
      printedLabels: false,
      barcodeTypePopUpIsVisible: false,
      isSelectAllAssets: false,
      isSelectAllClicked: false,
      selectedAssets: [],
      openCompleteAlert: false,
      popupTabLabels: [ITME_TAB_LABEL, DETAILS_TAB_LABEL],
      searchBy: 'searchBySample',
      searchMVStr: '',
      searchedLocations: [],
      isReqCurrentNodeContentRequired: false,
      locationBarcodeMove: '',
      resetLocation: false,
    }
    this.apiTextInput = React.createRef()
    this.nodeContentModuleRef = React.createRef()
  }

  onSelectAll = () => {
    this.setState((state) => ({
      isSelectAllAssets: !state.isSelectAllAssets,
      isSelectAllClicked: true,
    }))
  }
  onAssetSelect = (checked, asset) => {
    const { pegasusId } = asset
    let filterAssets = []
    const { selectedAssets = [] } = this.state
    const { data: pageData = [] } = this.props
    let newIsSelectAllAssets = false
    if (!checked) {
      filterAssets = selectedAssets.filter((item) => item !== pegasusId)
    } else {
      filterAssets = [...(selectedAssets || []), pegasusId]
    }
    if (filterAssets.length === pageData.length) {
      newIsSelectAllAssets = true
    }
    this.setState((state) => ({
      selectedAssets: filterAssets,
      isSelectAllClicked: false,
      isSelectAllAssets: newIsSelectAllAssets,
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    const { isSelectAllAssets, isSelectAllClicked } = this.state
    const { data: pageData = [] } = this.props
    const { isSelectAllAssets: prevIsSelectAllAssets } = prevState
    let filterAssets = []
    if (prevIsSelectAllAssets !== isSelectAllAssets && !!isSelectAllClicked) {
      if (isSelectAllAssets) {
        const { MOVE_SAMPLES = '' } = ValidationOperation
        filterAssets =
          (operation === MOVE_SAMPLES
            ? (pageData.filter((itemObj) => !itemObj.isMoved) || []).map(
                (obj) => obj.pegasusId
              )
            : (pageData.filter((itemObj) => !itemObj.isAssigned) || []).map(
                (obj) => obj.pegasusId
              )) || []
      }
      this.setState({
        selectedAssets: filterAssets,
      })
    }
    this.setActionType()
  }

  getSteps = () => {
    const { location } = this.props
    if (operation === ValidationOperation.MOVE_SAMPLES) {
      return [STEPPER_ACTIONS.SCAN, STEPPER_ACTIONS.ASSIGN_LOCATION]
    }
    if (DETAILED_LOCATIONS.indexOf(location) !== -1) {
      return [
        STEPPER_ACTIONS.SCAN,
        STEPPER_ACTIONS.ASSIGN_BUILDING,
        STEPPER_ACTIONS.ASSIGN_LOCATION,
      ]
    }
    return [STEPPER_ACTIONS.SCAN, STEPPER_ACTIONS.ASSIGN_BUILDING]
  }

  handleCompleteAlert = () => {
    this.setState((state) => ({
      openCompleteAlert: !state.openCompleteAlert,
    }))
  }

  /*
   * Regardless of location, assign building happens at step 1
   * Royalston - Samples & Props, Royalston - TCIN Studio, TPN, TPS only: step 2 triggers detailed location assignment
   * */
  handleNext = () => {
    const { activeStep, searchBy = '' } = this.state
    const { data: pageData = [] } = this.props
    let isShownNotification = false
    let message = ''
    let totalRecievedIds = []
    const { MOVE_SAMPLES = '' } = ValidationOperation
    if (this.getSteps()[activeStep] === STEPPER_ACTIONS.ASSIGN_BUILDING) {
      this.handleCheckin()
    }

    if (activeStep === this.getSteps().length - 1) {
      this.handleReset()
      totalRecievedIds =
        (operation === MOVE_SAMPLES
          ? pageData.filter((itemObj) => !!itemObj.isMoved)
          : pageData.filter((itemObj) => !!itemObj.isAssigned)) || []

      if (
        totalRecievedIds.length > 0 &&
        [MOVE_SAMPLES].indexOf(operation) !== -1
      ) {
        isShownNotification = totalRecievedIds.length > 0 ? true : false
        message =
          totalRecievedIds.length > 0
            ? totalRecievedIds.length +
              (totalRecievedIds.length === 1
                ? ' sample was '
                : ' samples were ') +
              'successfully ' +
              AssignLocationOperationLabels[operation]
            : ''
        if (!isEmpty(message)) {
          this.props.onHandleReset(isShownNotification, message)
        }
      }
    } else {
      if (operation === MOVE_SAMPLES && searchBy === 'searchByContainer') {
        this.setState((state) => ({
          isSelectAllAssets: !state.isSelectAllAssets,
          isSelectAllClicked: true,
          activeStep: state.activeStep + 1,
        }))
      } else {
        this.setState((state) => ({
          activeStep: state.activeStep + 1,
        }))
      }
    }
  }

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleReset = () => {
    const { clearCheckinData = () => {} } = this.props
    this.setState({
      activeStep: 0,
      printedLabels: false,
      openCompleteAlert: false,
      searchBy: 'searchBySample',
      searchMVStr: '',
      searchedLocations: [],
      isReqCurrentNodeContentRequired: false,
      locationBarcodeMove: '',
      selectedAssets: [],
      isSelectAllClicked: false,
      isSelectAllAssets: false,
    })
    clearCheckinData()
    setTimeout(() => {
      if (this.apiTextInput && this.apiTextInput.current) {
        const inputRefVal = this.apiTextInput.current.value
        this.apiTextInput.current.value = '' //clear the value of the element
        this.apiTextInput.current.value = inputRefVal
        this.apiTextInput.current.focus()
      }
    }, 1000)
  }

  setActionType = () => {
    const pathName = window.location.pathname
    this.props.setHeaderTitleArray([
      { title: 'Move Samples', link: '/moveSamples' },
    ])
    if (this.props.previousPage !== pathName) {
      this.handleReset()
      this.props.setPreviousPage(pathName)
    }
  }

  componentDidMount = () => {
    // get checkin location for user
    this.setActionType()
  }

  componentWillUnmount() {
    const { searchBy = '' } = this.state
    if (searchBy === 'searchByContainer') {
      this.handleReset()
    }
  }

  handleCheckin = () => {
    const { data, location, checkIn } = this.props
    checkIn(data, location)
  }

  fetchContainerWithNoSamples = () => {
    const { MOVE_SAMPLES = '' } = ValidationOperation
    const { searchBy } = this.state
    const { isFetchNoSamples } = this.props
    return (
      !!isFetchNoSamples &&
      searchBy === 'searchByContainer' &&
      operation === MOVE_SAMPLES
    )
  }

  stepperDisabled = (operation) => {
    const { activeStep, printedLabels } = this.state
    const { printQueue, data } = this.props

    const step = this.getSteps()[activeStep]
    if (data.length === 0) {
      if (this.fetchContainerWithNoSamples()) {
        return false
      } else {
        return true
      }
    }
    switch (step) {
      case STEPPER_ACTIONS.SCAN: {
        let scanSamplesCheck = data.flatMap(
          (item) => item.checkInStatus === 'VALID'
        )
        return (
          scanSamplesCheck.indexOf(false) >= 0 ||
          (printQueue.length > 0 && !printedLabels)
        )
      }
      case STEPPER_ACTIONS.ASSIGN_BUILDING: {
        let assignLocationCheck = data.flatMap(
          (item) => item.checkInStatus !== 'INVALID_LOCATION'
        )
        return assignLocationCheck.indexOf(false) >= 0
      }
      case STEPPER_ACTIONS.ASSIGN_LOCATION:
        return false
      default:
        return true
    }
  }

  setSampleStatusBar = (sample, status, activeStep) => {
    const {
      addToPrintQueue,
      getRelatedSamples,
      cloneSample,
      removeSample,
      location,
      setSampleStatusShipped,
    } = this.props
    const step = this.getSteps()[activeStep]

    switch (status) {
      case 'VALID':
        return ['Ready', <CheckCircle />, null, null, 'success']
      case 'DUPLICATE':
        // as sample is now received it will be marked as a duplicate
        if (step === STEPPER_ACTIONS.ASSIGN_LOCATION) {
          return ['Ready', <CheckCircle />, null, null, 'success']
        }
        return [
          'Duplicate',
          <Error />,
          'Resolve',
          () => {
            getRelatedSamples(sample.pegasusId, sample.shipTo).then(
              (samples) => {
                this.openResolution(
                  resolveDuplicate(
                    [sample.pegasusId],
                    <SampleDetailCard sample={sample} />,
                    partition(samples, (s) => {
                      return s.orderNum === sample.orderNum
                    }),
                    () => cloneSample(sample),
                    () => {
                      removeSample(sample.id)
                      return [sample.pegasusId]
                    },
                    addToPrintQueue
                  )
                )
              }
            )
          },
          'error',
        ]
      case 'INVALID_LOCATION':
        if (operation === ValidationOperation.MOVE_SAMPLES) {
          return ['Wrong Location', <Warning />, null, null, 'error']
        }
        break
      case 'INVALID_STATUS':
        return [
          'Invalid Status',
          <Warning />,
          'Invalid Status',
          () => {
            this.openResolution(
              removeInvalidSample(
                [sample.pegasusId],
                sample.sampleStatus,
                operation,
                () => {
                  removeSample(sample.id)
                },
                () => {
                  setSampleStatusShipped(sample, location)
                }
              )
            )
          },
          'error',
        ]
      default:
        return [null, null, null, null, 'error']
    }
  }

  openResolution = (setup) => {
    this.setState({
      resolutionOpen: true,
      activeResolutionId: setup.activeSample,
      startNode: setup.startNode,
      nodes: setup.nodes,
    })
  }

  closeResolution = (pegasusId) => {
    const { updateSampleId } = this.props
    const existingId = this.state.activeResolutionId
    this.setState({
      activeResolutionId: '',
      resolutionOpen: false,
      nodes: {},
    })
    if (!isEmpty(pegasusId)) {
      // updates id if different, otherwise revalidates
      updateSampleId(existingId, pegasusId)
      this.setState((state) => ({
        selectedAssets: [],
        isSelectAllClicked: false,
        isSelectAllAssets: false,
      }))
    }
  }

  printAllBarcodes = () => {
    const {
      data = [],
      printAllBarcodesMove = () => {},
      barcodeType = 'MULTILINE',
    } = this.props
    printAllBarcodesMove(data, barcodeType).then((pdf) => {
      if (pdf !== undefined) {
        window.open(pdf).print()
      }
    })

    this.setState(
      {
        printedLabels: true,
      },
      () => {
        removeSampleFromPrintQueueAll()
      }
    )
  }

  printBarcodes = () => {
    const { data, printQueue, printAllBarcodesMove } = this.props
    const samples = data.filter((sample) =>
      printQueue.includes(sample.pegasusId)
    )
    printAllBarcodesMove(samples, this.props.barcodeType).then((pdf) => {
      if (pdf !== undefined) {
        window.open(pdf).print()
      }
    })

    this.setState({
      printedLabels: true,
    })
  }

  getFilterData = (steps, activeStep, operation, data) => {
    const currentStep = steps[activeStep] || Number(0)
    const { ASSIGN_LOCATION = '' } = STEPPER_ACTIONS
    const { MOVE_SAMPLES = '' } = ValidationOperation
    const filterData =
      currentStep === ASSIGN_LOCATION && operation === MOVE_SAMPLES
        ? data.filter(
            (itemObj) =>
              !isEmpty(itemObj.sample_status) &&
              itemObj.sample_status === 'SAMPLE_RECEIVED' &&
              !itemObj.isMoved
          )
        : data
    return filterData
  }

  getAssignedSamples = (steps, activeStep, operation, data = []) => {
    const currentStep = steps[activeStep] || Number(0)
    const { ASSIGN_LOCATION = '' } = STEPPER_ACTIONS
    const { MOVE_SAMPLES = '' } = ValidationOperation
    const assignedSamples =
      currentStep === ASSIGN_LOCATION && operation === MOVE_SAMPLES
        ? data.filter((itemObj) => !!itemObj.isMoved)
        : []
    return assignedSamples
  }

  handleDeleteChip = (sampleId = '', type = '') => {
    this.props.removeUnknownSampleFromList(sampleId, type)
    if (!isEmpty(this.props.unknownLocationIds)) {
      this.props.removeUnknownLocationFromList()
      this.handleReset()
    }
    if (['duplicate', 'all'].indexOf(type) !== -1) {
      this.props.removeDuplicateSampleFromList(sampleId, type)
    } // for MOVE_container remove clear_all should not affect samples remove
  }

  handleAPISearchChange = (event) => {
    const searchStr = event.target.value || ''
    var temp = searchStr.replace(/,/gi, '\r\n').toUpperCase()
    var listOfSearchItem = temp
      .trim()
      .replace(/ /gi, ',\r\n')
      .split(/[,\r\n\s]+/g)

    for (var i = 0; i < listOfSearchItem.length - 1; i++) {
      if (listOfSearchItem[i] === '' || listOfSearchItem[i] === ',') {
        listOfSearchItem.splice(i, 1)
      }
    }
    this.setState({
      searchedLocations: listOfSearchItem,
      searchMVStr: searchStr,
    })
  }

  renderSearchLocationsBox = (isDisableSearch) => {
    const { searchMVStr = '', searchBy = '' } = this.state
    const { classes = {}, fetchedLocationData = {} } = this.props
    const { location_id = '' } = fetchedLocationData
    let searchMVStrCopy = searchMVStr
    let searchMVCount = !isEmpty(searchMVStrCopy)
      ? (searchMVStrCopy.trim(), searchMVStrCopy.split(/[,\r\n\s]+/g).length)
      : ''
    const currentLabel =
      searchBy === 'searchByContainer'
        ? 'Scan or Enter Location ID(s) for a Level 4 container'
        : 'Scan or Enter Pegasus/FileMaker ID(s) or Location ID(s)'
    const helperTextLabel =
      searchBy === 'searchByContainer'
        ? 'Only one Level 4 container can be moved at a time'
        : searchMVCount
        ? `${searchMVCount} Pegasus ID(s) or Location ID(s)`
        : 'Pegasus ID(s) or Location ID(s)'
    return (
      <TextField
        label={currentLabel}
        value={searchMVStr || location_id}
        data-ddtype={searchBy}
        id={searchBy}
        onChange={this.handleAPISearchChange}
        InputProps={{
          classes: {
            input: classes.searchInput,
            notchedOutline: classes.notchedOutlineNew,
            focused: classes.notchedOutlineNew,
          },
        }}
        InputLabelProps={{
          className: classes.searchInputLabel,
        }}
        multiline
        rows="1"
        rowsMax="6"
        inputRef={this.apiTextInput}
        helperText={helperTextLabel}
        disabled={isDisableSearch}
        autoFocus
        onKeyPress={this.catchReturn}
      />
    )
  }

  searchByFilters = (event) => {
    const { handleReset = () => {} } = this
    handleReset()
    this.setState({
      searchBy: event.target.value,
    })
  }

  searchLocations = () => {
    const { searchedLocations = [], searchBy = '' } = this.state
    const { fetchSamples = () => {} } = this.props
    const moveType = searchBy === 'searchByContainer' ? 'C' : 'S'
    fetchSamples(searchedLocations, moveType)
    this.setState({
      searchMVStr: '',
    })
  }

  getContainerName = (data = {}) => {
    const { container = '' } = data
    return container
  }

  renderClearButton = () => {
    const { classes = {} } = this.props
    return (
      <Button
        id="clearallcards"
        color="primary"
        onClick={this.handleReset}
        className={classes.defaultButton}
        startIcon={<ClearAllIcon />}
      >
        Clear All
      </Button>
    )
  }

  renderPrintAllButton = () => {
    const { classes = {} } = this.props
    return (
      <Button
        id="printalllabels"
        color="primary"
        onClick={this.printAllBarcodes}
        className={classes.defaultButton}
        startIcon={<Print />}
      >
        Print All
      </Button>
    )
  }

  renderClearAll = () => {
    return (
      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        id={'move_clearall'}
        style={{ textAlign: 'end' }}
      >
        {this.renderPrintAllButton()}
        {this.renderClearButton()}
      </Grid>
    )
  }

  getCardActions = (activeStep = Number(0), item, addLocation) => {
    const { addToPrintQueue = () => {}, removeSample = () => {} } = this.props
    const { searchBy = '' } = this.state
    const { pegasusId: itemPegasusId = '', id: itemId = '' } = item
    let sampleCardAction = [
      {
        label: 'Print New Label',
        disable: Number(activeStep) === Number(1) ? true : false,
        action: () => addToPrintQueue(itemPegasusId),
      },
    ]
    let enableMissingInProduction =
      itemPegasusId.substring(0, 2) === 'PP' ? false : true
    switch (searchBy) {
      case 'searchByContainer':
        switch (activeStep) {
          case Number(0):
            enableMissingInProduction &&
              sampleCardAction.push({
                label: 'Missing In Production',
                action: () => addLocation(40046),
              })
            break
          default:
            sampleCardAction = enableMissingInProduction
              ? [
                  {
                    label: 'Missing In Production',
                    action: () => addLocation(40046),
                  },
                ]
              : false
            break
        }
        break
      default:
        sampleCardAction.push({
          label: 'Remove',
          action: () => removeSample(itemId),
        })
        break
    }
    return sampleCardAction
  }

  handleDoneSuccess = () => {
    this.setState({ resetLocation: false })
  }

  handleDoneSuccessMIP = (sampleId = '') => {
    const { selectedAssets = [] } = this.state
    const selectedDataCopy = [...selectedAssets]
    const newSelectedData = selectedDataCopy.filter(
      (sample) => !sampleId.includes(sample)
    )
    this.setState({
      selectedAssets: newSelectedData,
    })
  }

  catchReturn = (event) => {
    if (event.key === 'Enter') {
      this.searchLocations()
      event.preventDefault()
    }
  }

  renderStepper = (activeStep = '', steps = []) => {
    const { classes = {} } = this.props
    return (
      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        id={'MoveStepper'}
        alternativeLabel
        style={
          activeStep === steps.length - 1
            ? { width: 'calc(100% - 470px)' }
            : { width: 'calc(100% - 300px)' }
        }
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }

  renderTabbedPopUp = () => {
    const {
      popupIsVisible,
      popupItem = {},
      classes = {},
      updateSampleFieldsData = () => {},
      closePopup = () => {},
    } = this.props
    const { type: itemType = '' } = popupItem
    const tabsList =
      itemType === PROPS
        ? dataTabLabelsShippedWithProps
        : itemType === SCENIC
        ? dataTabLabelsShippedWithScenic
        : dataTabLabelsShipped
    return (
      <TabbedPopUp
        dataTabLabels={tabsList}
        isVisible={popupIsVisible}
        data={makeTabTables(
          popupItem,
          classes,
          tabsList,
          updateSampleFieldsData
        )}
        onClose={closePopup}
        title={getTitle(popupItem)}
        fullWidth={true}
      />
    )
  }

  render() {
    const {
      barcodeType,
      classes,
      data = [],
      assignLocationRequestPending,
      checkinRequestPending,
      locationRequestPending,
      fetchSamplesRequestPending,
      handleChipClick,
      unknownPegasusIds = [],
      unknownLocationIds = [],
      duplicatePegasusIdsForMove = [],
      userLocation,
      printQueue,
      location,
      assignSampleLocation,
      popupIsVisible,
      showPopup = () => {},
      assignLocationToMIP = () => {},
      isFetchNoSamples,
      fetchedLocationData = {},
    } = this.props

    const {
      activeStep,
      isSelectAllAssets,
      selectedAssets,
      openCompleteAlert = false,
      searchBy = '',
      searchedLocations = [],
    } = this.state
    const disableSearchButton =
      searchBy === 'searchByContainer' && searchedLocations.length > 1
        ? true
        : false
    const steps = this.getSteps()
    const isDisableSearch =
      searchBy === 'searchByContainer' ? !isEmpty(data) : false
    const filterData = this.getFilterData(steps, activeStep, operation, data)
    const selectedContainer =
      searchBy === 'searchByContainer'
        ? this.getContainerName(fetchedLocationData)
        : ''
    const assignedSamples = this.getAssignedSamples(
      steps,
      activeStep,
      operation,
      data
    )

    const moveType = searchBy === 'searchByContainer' ? 'C' : 'S'
    const { handleDoneSuccess = () => {}, handleDoneSuccessMIP = () => {} } =
      this

    const completeAlertTitle =
      searchBy === 'searchByContainer'
        ? 'Complete move?'
        : 'Complete move samples?'
    const completeAlertTitleStr = (
      <span className={classes.completeAlertTitle}>{completeAlertTitle}</span>
    )

    const completeAlertPBName = 'yes, move samples'

    const unknownSampleLabel = !isEmpty(unknownLocationIds)
      ? 'Unknown Location Id'
      : 'Unknown Pegasus/FileMaker ID(s) or Already added'

    operation = ValidationOperation.MOVE_SAMPLES

    let unknownPegasusIdsChips = unknownPegasusIds.map((data, index) => ({
      label: data,
      key: index,
      type: 'unknown',
    }))
    const duplicatePegasusIdsForMoveChips = duplicatePegasusIdsForMove.map(
      (data, index) => ({
        label: data,
        key: index,
        type: 'duplicate',
      })
    )
    const unknownLocationIdsChips = unknownLocationIds.map((data, index) => ({
      label: data,
      key: index,
      type: 'unknown',
    }))
    const chipData = [
      ...unknownPegasusIdsChips,
      ...duplicatePegasusIdsForMoveChips,
      ...unknownLocationIdsChips,
    ]

    return (
      <div className={classes.CheckInPage}>
        <ResolutionTree
          innerRef={this.ResolutionElement}
          open={this.state.resolutionOpen}
          onClose={this.closeResolution}
          startNode={this.state.startNode}
          nodes={this.state.nodes}
          userLocation={location}
          operation={operation}
        />
        <Grid container spacing={4} justify="center" alignItems="center">
          <Grid item xs={12} className={classes.messageArea}>
            <Card className={classes.card}>
              <Grid item xs={12}>
                <p
                  className={classes.checkInPageIntro}
                  id={'moveTitle'}
                  style={styles.header}
                >
                  {location} {'Move Samples'}
                </p>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={8}>
                    {this.renderStepper(activeStep, steps)}
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container justify={'flex-end'}>
                      <Grid item>
                        <div style={{ display: 'block' }}>
                          {activeStep === 0 && (
                            <Button
                              id="printLabels"
                              variant="outlined"
                              color="primary"
                              disabled={printQueue.length === 0}
                              onClick={this.printBarcodes}
                              style={{ height: '42px' }}
                            >
                              <LocalPrintshop style={{ marginRight: '5px' }} />{' '}
                              Print {printQueue.length} New Labels
                            </Button>
                          )}
                        </div>
                        {activeStep === 0 &&
                          [
                            'Royalston - Samples & Props',
                            'Royalston - TCIN Studio',
                            'Target Plaza',
                          ].indexOf(userLocation) !== -1 && (
                            <div
                              style={{
                                display: 'inline-block',
                                width: '190px',
                              }}
                            >
                              <p
                                className={classes.barcodeText}
                                style={{ color: 'black', marginRight: '5px' }}
                              >
                                {'Format: '}
                                {this.props.barcodeType === 'DYMO'
                                  ? 'Dymo Printer'
                                  : 'Label Paper'}
                              </p>
                              {/* <p
                                className={classes.barcodeText}
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  this.setState({
                                    barcodeTypePopUpIsVisible: true,
                                  })
                                }
                              >
                                Change
                              </p> */}
                            </div>
                          )}
                      </Grid>
                      <Grid item>
                        <Button
                          id="next"
                          variant="contained"
                          color="primary"
                          disabled={this.stepperDisabled(operation)}
                          onClick={this.handleNext}
                          style={{ marginLeft: '15px', height: '42px' }}
                        >
                          {activeStep === steps.length - 1
                            ? this.fetchContainerWithNoSamples() ||
                              searchBy === 'searchByContainer'
                              ? 'Complete Move'
                              : 'Complete Move Samples'
                            : 'Next'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {activeStep === 0 && (
            <>
              {operation === ValidationOperation.MOVE_SAMPLES && (
                <Grid item xs={8}>
                  <Grid
                    container
                    alignItems={'center'}
                    spacing={4}
                    className={classes.searchArea}
                  >
                    <Grid item xs={8}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <ThemeProvider theme={theme}>
                            <CustomDropdown
                              options={{
                                searchBySample: 'Move Samples',
                                searchByContainer: 'Move Level 4 Container',
                              }}
                              handleChange={this.searchByFilters}
                              value={searchBy}
                              id={'searchLocationBy'}
                              labelId={
                                searchBy === 'searchByContainer'
                                  ? 'Move Level 4 Container'
                                  : 'Move Samples'
                              }
                            />
                          </ThemeProvider>
                        </Grid>
                        <Grid item>
                          {this.renderSearchLocationsBox(isDisableSearch)}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} container justify={'flex-end'}>
                      <Button
                        id="clearSearch"
                        color="primary"
                        onClick={this.handleReset}
                        className={classes.clearAll}
                        variant="outlined"
                      >
                        Clear
                      </Button>
                      <Button
                        id="searchSamples"
                        color="primary"
                        onClick={this.searchLocations}
                        className={classes.searchButton}
                        variant="contained"
                        disabled={disableSearchButton || isDisableSearch}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={8} id="unknownIDChips" data-cy={chipData.length}>
                {chipData.length > 0 && (
                  <ChipArray
                    data={chipData}
                    enableCopyAllButton
                    handleClick={handleChipClick}
                    title={unknownSampleLabel}
                    onDelete={this.handleDeleteChip}
                    clearAllRequired={true}
                    chipDeleteRequired={true}
                  />
                )}
              </Grid>
            </>
          )}
          {steps[activeStep] === STEPPER_ACTIONS.ASSIGN_BUILDING && (
            <Grid
              item
              xs={12}
              container
              justify={'center'}
              alignItems={'center'}
              className={classes.messageArea}
            >
              <Typography variant={'h5'}>
                By selecting "Next" the following samples will be moved to{' '}
                <b>{location}</b>.
              </Typography>
            </Grid>
          )}
          {steps[activeStep] === STEPPER_ACTIONS.ASSIGN_LOCATION &&
            operation === ValidationOperation.MOVE_SAMPLES &&
            searchBy === 'searchBySample' &&
            filterData.length > 0 && (
              <Grid
                container
                item
                xs={12}
                alignItems={'center'}
                justify={'flex-start'}
                className={classes.selectAllArea}
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={this.onSelectAll}
                        checked={!!isSelectAllAssets}
                        classes={{
                          checked: classes.filterCheckboxChecked,
                        }}
                      />
                    }
                    id="SelectAll"
                    label={'Select All'}
                    className={classes.selectAllControl}
                  />
                </Grid>
                <Grid item>
                  <Button
                    id="assignToLocation"
                    variant="outlined"
                    color="primary"
                    disabled={!(selectedAssets || []).length}
                    onClick={() => {
                      this.openResolution(
                        assignDetailedLocationNew(
                          selectedAssets,
                          (locationId, locationLabelDetail = {}) => {
                            assignSampleLocation(
                              locationLabelDetail,
                              [...selectedAssets],
                              location,
                              locationId
                            )
                          }
                        )
                      )
                    }}
                    className={classes.assignLocSubLabel}
                  >
                    Assign location to selected
                  </Button>
                </Grid>
              </Grid>
            )}
          {/* {((steps[activeStep] === STEPPER_ACTIONS.ASSIGN_LOCATION &&
            operation === ValidationOperation.MOVE_SAMPLES &&
            searchBy === 'searchByContainer' &&
            !assignedSamples.length) ||
            (steps[activeStep] === STEPPER_ACTIONS.ASSIGN_LOCATION &&
              this.fetchContainerWithNoSamples())) && (
            <Grid
              item
              container
              id="containerAddToButton"
              xs={12}
              spacing={3}
              alignItems="center"
            ></Grid>
          )} */}
          {((steps[activeStep] === STEPPER_ACTIONS.ASSIGN_LOCATION &&
            operation === ValidationOperation.MOVE_SAMPLES &&
            // !assignedSamples.length &&
            searchBy === 'searchByContainer') ||
            (steps[activeStep] === STEPPER_ACTIONS.ASSIGN_LOCATION &&
              this.fetchContainerWithNoSamples())) && (
            <Grid
              item
              container
              id="samples"
              xs={12}
              spacing={3}
              alignItems="center"
            >
              <Grid
                item
                container
                // justify={'space-between'}
                spacing={4}
                alignItems={'center'}
                id={'checkin_clearall'}
              >
                <Grid item>
                  <Typography variant={'h5'}>
                    {`${
                      selectedContainer +
                      (!assignedSamples.length
                        ? this.props.assignedSamplesSuccess
                          ? ' Moved'
                          : ' Selected'
                        : ' Moved')
                    }`}
                  </Typography>
                  <Typography variant={'caption'}>
                    {
                      'You are about to move the level 4 container and these samples inside it.'
                    }
                  </Typography>
                </Grid>
                {!assignedSamples.length &&
                  !this.props.assignedSamplesSuccess && (
                    <Grid item>
                      <Button
                        id="assignToLocation"
                        variant="outlined"
                        color="primary"
                        disabled={
                          isFetchNoSamples
                            ? !(selectedAssets || []).length &&
                              !isFetchNoSamples
                            : !(selectedAssets || []).length
                        }
                        onClick={() => {
                          this.openResolution(
                            assignDetailedLocationNew(
                              selectedAssets,
                              (locationId, locationLabelDetail = {}) => {
                                assignSampleLocation(
                                  locationLabelDetail,
                                  [...selectedAssets],
                                  location,
                                  locationId,
                                  searchedLocations,
                                  moveType,
                                  handleDoneSuccess
                                )
                              },
                              'container'
                            )
                          )
                        }}
                        className={classes.assignLocSubLabel}
                      >
                        Assign location to container
                      </Button>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          )}
          {(assignLocationRequestPending ||
            locationRequestPending ||
            checkinRequestPending ||
            fetchSamplesRequestPending) && (
            <Grid
              item
              container
              id="samplesProgressBar"
              xs={12}
              spacing={3}
              justify="center"
              alignItems="center"
            >
              <CircularProgress className={classes.progress} />
            </Grid>
          )}
          <Grid
            item
            container
            id="samplesLabelSection"
            xs={12}
            spacing={7}
            justify="flex-start"
            alignItems="stretch"
          >
            {activeStep === 0 && !isEmpty(unknownLocationIds) ? (
              ''
            ) : activeStep === 0 &&
              (filterData.length > 0 || this.fetchContainerWithNoSamples()) ? (
              operation === ValidationOperation.MOVE_SAMPLES ? (
                searchBy === 'searchBySample' ? (
                  this.renderClearAll()
                ) : (
                  <Grid
                    item
                    container
                    justify={'space-between'}
                    id={'checkin_clearall'}
                  >
                    <Grid item>
                      <Typography variant={'h5'}>
                        {`${selectedContainer + ' Selected'}`}
                      </Typography>
                      <Typography variant={'caption'}>
                        {
                          'You are about to move the level 4 container and these samples inside it.'
                        }
                      </Typography>
                    </Grid>
                    <Grid item>{this.renderClearButton()}</Grid>
                  </Grid>
                )
              ) : (
                this.renderClearAll()
              )
            ) : (
              ''
            )}
            {filterData.map((item) => {
              const statusBar = this.setSampleStatusBar(
                item,
                item.checkInStatus,
                activeStep
              )
              const isAssetChecked =
                (selectedAssets || []).indexOf(item.pegasusId) !== -1
              return (
                <Grid item key={item.id} id={'checkin_' + item.pegasusId}>
                  <SampleDetailCard
                    variant={statusBar[4]}
                    sample={item}
                    menuOptions={this.getCardActions(
                      activeStep,
                      item,
                      (locationId) => {
                        assignLocationToMIP(
                          [item.pegasusId],
                          location,
                          locationId,
                          item.id,
                          handleDoneSuccessMIP
                        )
                      }
                    )}
                    titleAction={
                      steps[activeStep] === STEPPER_ACTIONS.ASSIGN_LOCATION &&
                      operation === ValidationOperation.MOVE_SAMPLES &&
                      searchBy === 'searchBySample' ? (
                        <Checkbox
                          onChange={(event) =>
                            this.onAssetSelect(event.target.checked, item)
                          }
                          checked={isAssetChecked}
                          classes={{
                            checked: classes.filterCheckboxChecked,
                          }}
                        />
                      ) : null
                    }
                    status={statusBar[0]}
                    statusIcon={statusBar[1]}
                    action={statusBar[2]}
                    actionOnClick={statusBar[3]}
                    showPopup={showPopup}
                    extraIcon={
                      activeStep === 0 &&
                      printQueue.includes(item.pegasusId) ? (
                        <IconButton
                          aria-label="Print Label"
                          className={classes.searchButton}
                          onClick={() => {
                            this.printBarcodes(item.pegasusId)
                          }}
                        >
                          <Print />
                        </IconButton>
                      ) : null
                    }
                  />
                </Grid>
              )
            })}
          </Grid>
          {isEmpty(filterData) &&
            operation === ValidationOperation.MOVE_SAMPLES &&
            searchBy === 'searchByContainer' &&
            this.fetchContainerWithNoSamples() &&
            isEmpty(unknownLocationIds) && (
              <Grid
                item
                xs={12}
                container
                justify={'center'}
                alignItems={'center'}
                className={classes.assignMessageArea}
              >
                <Typography variant={'h5'}>{'No Samples'}</Typography>
              </Grid>
            )}
          {steps[activeStep] === STEPPER_ACTIONS.ASSIGN_LOCATION &&
            assignedSamples.length > 0 && (
              <Grid
                item
                xs={12}
                container
                justify={'center'}
                alignItems={'center'}
                className={classes.assignMessageArea}
              >
                <Typography variant={'h5'}>
                  {searchBy === 'searchByContainer' ? '' : 'Moved Samples'}
                </Typography>
              </Grid>
            )}
          {steps[activeStep] === STEPPER_ACTIONS.ASSIGN_LOCATION &&
            assignedSamples.length > 0 && (
              <Grid
                item
                container
                id="sampleCardsList"
                xs={12}
                spacing={3}
                justify="flex-start"
                alignItems="stretch"
              >
                {(assignLocationRequestPending ||
                  locationRequestPending ||
                  checkinRequestPending ||
                  fetchSamplesRequestPending) && (
                  <CircularProgress className={classes.progress} />
                )}
                {assignedSamples.map((item) => {
                  const statusBar = this.setSampleStatusBar(
                    item,
                    item.checkInStatus,
                    activeStep
                  )
                  return (
                    <Grid item key={item.id} id={'move_' + item.pegasusId}>
                      <SampleDetailCard
                        sample={item}
                        titleAction={null}
                        status={statusBar[0]}
                        statusIcon={statusBar[1]}
                        action={statusBar[2]}
                        actionOnClick={statusBar[3]}
                        extraIcon={null}
                        showPopup={showPopup}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )}
        </Grid>
        {!!popupIsVisible && this.renderTabbedPopUp()}

        <BarcodeTypePopUp
          isVisible={this.state.barcodeTypePopUpIsVisible}
          onClose={() => this.setState({ barcodeTypePopUpIsVisible: false })}
          setterCallback={(barcodeType) =>
            this.props.setBarcodeType(barcodeType)
          }
          barcodeType={barcodeType}
        />
        <PopUp
          title={completeAlertTitleStr}
          isButtonVisible={true}
          onProceed={this.handleNext}
          isVisible={openCompleteAlert}
          onClose={this.handleCompleteAlert}
          primaryButtonText={completeAlertPBName}
          maxWidth="xs"
          fullWidth={true}
          secondaryButtonText="not yet"
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alertIsVisible: state.checkIn.alertIsVisible,
    alertMessage: state.checkIn.alertMessage,
    barcodeType: state.user.barcodeType,
    columnData: state.checkIn.columnData,
    data: state.checkIn.data,
    checkinRequestPending: state.checkIn.checkinRequestPending,
    assignLocationRequestPending: state.checkIn.assignLocationRequestPending,
    locationRequestPending: state.checkIn.locationRequestPending,
    fetchSamplesRequestPending: state.checkIn.fetchSamplesRequestPending,
    unknownPegasusIds: state.checkIn.unknownPegasusIds,
    unknownLocationIds: state.checkIn.unknownLocationIds,
    duplicatePegasusIdsForMove: state.checkIn.duplicatePegasusIdsForMove,
    location: state.user.location,
    numSelected: state.checkIn.numSelected,
    onCheckBoxChange: state.checkIn.onCheckBoxChange,
    onCheckBoxHeaderChange: state.checkIn.onCheckBoxHeaderChange,
    onClick: state.checkIn.onClick,
    popupIsVisible: state.checkIn.popupIsVisible,
    popupItem: state.checkIn.item,
    printQueue: state.checkIn.printQueue,
    relatedSamples: state.checkIn.relatedSamples,
    selectedData: state.checkIn.selectedData,
    showPopup: state.checkIn.showPopup,
    previousPage: state.checkIn.previousPage,
    userLocation: state.user.location,
    isFetchNoSamples: state.checkIn.isFetchNoSamples,
    fetchedLocationData: state.checkIn.fetchedLocationData,
    assignedSamplesSuccess: state.checkIn.assignedSamplesSuccess,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addToPrintQueue: (id) => dispatch(addSampleToPrintQueue(id)),
    assignSampleLocation: (
      locationLabelDetail = {},
      samples = [],
      location = '',
      locationId = '',
      searchedLocations = [],
      moveType = 'S',
      callBack = () => {}
    ) =>
      dispatch(
        assignLocation(
          locationLabelDetail,
          samples,
          location,
          locationId,
          operation,
          searchedLocations,
          moveType,
          callBack
        )
      ),
    assignLocationToMIP: (
      samples = [],
      location,
      locationId,
      sampleId = '',
      callBack = () => {}
    ) =>
      dispatch(
        assignLocationToMIP(
          samples,
          sampleId,
          location,
          locationId,
          operation,
          callBack
        )
      ),
    clearCheckinData: () => {
      dispatch(clearCheckinData())
    },
    closePopup: () => dispatch(closePopup()),
    cloneSample: (sample) => dispatch(cloneSample(sample)),
    fetchSamples: (pgIds, moveType = '') => {
      dispatch(validateArgs(pgIds, operation, moveType))
    },
    getCheckinLocation: dispatch(getCheckinLocation()),
    getRelatedSamples: (pegasusId, shipTo) =>
      dispatch(getRelatedSamples(pegasusId.slice(2, pegasusId.length), shipTo)),
    checkIn: (data, location) => {
      dispatch(checkinSamples(data, location))
    },
    onHandleReset: (isShown, message) => {
      // dispatch(showNotification(isShown, message))
    },
    handleChipClick: (chip) => {
      copy(chip.target.innerText)
      dispatch(showNotification(true, 'Value Copied to Clipboard', 'info'))
    },
    printBarcodes: (ids, type) => dispatch(getBarcodes(ids, type)),
    printAllBarcodesMove: (ids, type) => dispatch(printAllBarcodes(ids, type)),
    removeSampleFromPrintQueueAll: () =>
      dispatch(removeSampleFromPrintQueueAll()),
    removeSample: (id) => dispatch(removeSample(id)),
    setSampleLocation: (sample, location) =>
      dispatch(setSampleLocation(sample, location, operation)),
    setSampleStatusShipped: (sample, location) =>
      dispatch(setSampleStatusShipped(sample, location, operation)),
    showPopup: (item) => {
      dispatch(showPopup(item))
    },
    updateSampleId: (existingId, updatedId) =>
      dispatch(updateSampleId(existingId, updatedId, operation)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setPreviousPage: (page) => dispatch(setPreviousPage(page)),
    removeUnknownSampleFromList: (sampleId = '', type = '') =>
      dispatch(removeUnknownSampleFromList(sampleId, type)),
    removeUnknownLocationFromList: () =>
      dispatch(removeUnknownLocationFromList()),
    removeDuplicateSampleFromList: (sampleId = '', type = '') =>
      dispatch(removeDuplicateSampleFromList(sampleId, type)),
    setBarcodeType: (barcodeType) => dispatch(setBarcodeType(barcodeType)),
    updateSampleFieldsData: (data = {}) =>
      dispatch(updateSampleFieldsData(data)),
  }
}

MovePage.propTypes = {
  alertIsVisible: propTypes.bool,
  checkBoxEnabled: propTypes.bool,
  columnData: propTypes.array,
  data: propTypes.array,
  assignLocationRequestPending: propTypes.bool,
  locationRequestPending: propTypes.bool,
  checkinRequestPending: propTypes.bool,
  fetchSamplesRequestPending: propTypes.bool,
  location: propTypes.string,
  numSelected: propTypes.number,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
  onClick: propTypes.func,
  onSortSelectedChange: propTypes.func,
}
export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(MovePage)
