import React, { Suspense } from 'react'
import * as propTypes from 'prop-types'
import apiConfig from '../../apiConfig'
import Grid from '@material-ui/core/Grid'
import withHOCs from 'util/withHocs'
import { isEmpty, isEqual, cloneDeep, isUndefined } from 'lodash'
import cx from 'classnames'
import {
  fetchPropsMetadata,
  fetchScenicMetadata,
} from '../../services/inventoryService'
import ReactSelect from 'react-select'
import { PROPS, SCENIC } from 'enums/Tabs'
import { getFormattedOptions, CustomPaper } from '../../util/CommonUtils'
import {
  Typography,
  IconButton,
  FormControl,
  MenuItem,
  TextField,
  Chip,
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import DialogBox from '../../components/DialogBox/DialogBox'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import Add from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Save from '@material-ui/icons/Save'
import Clear from '@material-ui/icons/Clear'
import NameFiltersPopUp from './NameFiltersPopUp'
import { fetchAllScenicRooms } from '../../services/scenicRoomService'

import {
  getBuildings,
  getCheckInLocation,
  getLLBuildingByLocation,
} from '../../services/locationService'
import { locationResponseToSelect } from '../../mappers/locationMapper'
import {
  getAllProjectNames,
  getAllProjects,
} from '../../services/projectsService'
import {
  projectNamesResponse,
  projectNamesResponseToSelect,
} from '../../mappers/projectMapper'
import {
  getAllCollectionNames,
  getAllCollections,
} from '../../services/collectionsService'
import { getAllBrands, getAllUsers } from '../../services/inventoryService'
import {
  getAllDepartments,
  getClassByDepartment,
} from '../../services/itemService'

import {
  collectionNamesResponse,
  collectionNamesResponseToSelect,
} from '../../mappers/collectionMapper'
import {
  brandNamesResponseToSelect,
  userNamesResponseToSelect,
  departmentResponseToSelect,
  departmentClassResponseToSelect,
  getFormattedSubCategories,
} from '../../mappers/inventoryMapper'

import {
  getFilters,
  saveFilters,
  setFilters,
  deleteFilters,
  saveFilterData,
} from '../../store/filters/actionCreator'
import { getProjectChannel } from '../../store/newShootType/actionCreator'
import { fetchLocationDetails } from '../../store/searchInventory/actionCreator'
import {
  selectPageAvailableFilters,
  selectPageFilters,
  getStoreChannels,
  getLocationDetails,
} from '../../store/filters/selectors'
import { getUserName, DATE_FORMAT } from '../../util/CommonUtils'
import { SEARCH_LOCATIONS_COMPONENT_TYPE } from '../../enums/locations'
import {
  regexExpressions,
  autocompleteSelectData,
  deptClassInputs,
  locationLevelsInputs,
  listInputs,
  dateInputs,
  nameBasedListInputs,
  booleanInputs,
  filtersFromParamsSearch,
  paramsFromFilters,
} from './constants'
import { filterLocations } from '../../store/manageLocations/actionCreator'
// import { Autocomplete } from '@mui/material'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Divider } from '@mui/material'
import { getDropdownData } from '../../store/searchInventory/actionCreator'
import { scenicRoomResponse } from '../../mappers/scenicRoomMapper'

const OtherComponent = React.lazy(() => {
  return new Promise((resolve) => setTimeout(resolve, 3000)).then(
    () => import('../../components/LocationDropdowns')
  )
})

const renderSuggestion = (props, option, clickDeleteFilters = () => {}) => {
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>{props.title}</Grid>
      <Grid item>
        <IconButton
          id="search_deletefilter"
          onClick={(event) => {
            event.stopPropagation()
            clickDeleteFilters(props)
          }}
          aria-label="search delete filter"
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const styles = (theme) => ({
  root: {
    overflowX: 'visible',
  },
  paper: {
    paddingBottom: '16px',
    paddingTop: '16px',
    margin: '24px 0px',
    backgroundColor: 'unset',
  },
  formControl: {
    visibility: 'hidden',
    width: '0px',
    height: '0px',
  },
  addFilterColumn: {
    minHeight: '250px',
    width: '294px',
    padding: '0px 17px',
    marginBottom: '10px',
    zIndex: '16',
  },
  addFilterLocationColumn: {
    minHeight: '250px',
    width: '384px',
    padding: '0px 17px',
    marginBottom: '10px',
  },
  addFilterOuterDiv: {
    minHeight: '250px',
    width: '284px',
    padding: '0px 17px',
    marginBottom: '10px',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  addFilterPopper: {
    zIndex: 2,
    width: '250px',
  },
  popperPaper: {
    height: 'auto',
    maxHeight: '200px',
    overflowY: 'auto',
  },
  addFilterButton: {
    cursor: 'pointer',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontSize: '17px',
  },
  addDeptClassButton: {
    height: '35px',
    float: 'right',
  },
  addCatSubCatButton: {
    height: '55px',
    float: 'right',
    width: 'auto',
  },
  clearAll: {
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  clearAllIcon: {
    width: '18px',
    height: '18px',
    margin: '8px',
  },
  applyButton: {
    width: '70px',
    height: '35px',
    float: 'right',
  },
  applyButtonParent: {
    margin: '15px 0 10px 0',
    height: '35px',
  },
  applyButtonMargin: {
    marginTop: '15px',
    width: '70px',
    height: '35px',
    float: 'right',
  },
  chipFilterTextField: {
    width: '100%',
  },
  chipFilterTextFieldMargin: {
    width: '100%',
    marginTop: '15px',
  },
  helperText: {
    fontSize: '11.5px',
    color: '#666666',
  },
  chipRoot: {
    margin: '2px',
    minHeight: '26px',
    height: 'auto',
  },
  chipArrayLabel: {
    whiteSpace: 'pre-wrap',
  },
  addFilter: {
    margin: '0 0px 17px 0px',
    height: '48px',
    width: '250px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 2px 3px rgba(0,0,0,0.16), 0 2px 3px rgba(0,0,0,0.23)',
    },
    borderRadius: '4px',
  },
  addFilterContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dateInput: {
    fontSize: '14.5px',
  },
  select: {
    width: '0px',
    height: '0px',
    padding: '0px',
  },
  icon: {
    width: '0px',
    height: '0px',
  },
  selectRoot: {
    width: '0px',
    height: '0px',
  },
  toolBar: {
    position: 'absolute',
    left: '1059px',
    width: '37%',
    top: '169px',
  },
  filterTitle: {
    paddingLeft: '25px',
    width: '300px',
  },
  spacer: {
    flex: '1 1 auto',
  },
  getFilterClearName: {
    fontSize: '17px',
    marginTop: '12px',
    marginLeft: '10px',
  },
  getFilterClear: {
    margin: '12px 7px 0 0',
    cursor: 'pointer',
  },
  autocompleteStyle: {
    width: '20vw',
    paddingBottom: '10px',
    marginTop: '5px',
  },
})

class AddFilters extends React.PureComponent {
  constructor(props) {
    super(props)
    const { selectableFilters = [], filters = {} } = this.props
    const filtersCopy = Object.assign({}, filters)
    this.anchorRef = React.createRef()
    this.selectRef = React.createRef()
    this.state = {
      selectIsOpen: false,
      selectableFilters: selectableFilters,
      filters: filtersCopy,
      saveFiltersPopUpIsVisible: false,
      openFiltersPopUpIsVisible: false,
      autocompleteSelectData: autocompleteSelectData,
      sortParam: '',
      sortOrder: '',
      handleDropDownTrigger: false,
      handleTriggerProps: {},
      resetLocations: false,
      locationsLabel: [],
      isFetchedlocation: false,
      selectedFilter: false,
      filterTitle: '',
      filterId: '',
      showAlertOnDelete: false,
      alertMessageOnDelete: '',
      deleteFilterId: '',
      deleteResponse: false,
    }
    this.autoC = React.createRef(null)
    const {
      auth: {
        session: { userInfo = {} },
      },
    } = this.props
    this.userProfilename = getUserName(userInfo)
  }

  componentDidMount() {
    const { filters: stateFilters = {} } = this.state
    let filtersCopy = Object.assign({}, stateFilters)
    const {
      setFiltersUpdated = () => {},
      getProjectChannel = () => {},
      locations = [],
    } = this.props
    if (!isEmpty(locations)) {
      this.setState({ locationsLabel: locations })
    }
    this.openSavedFilters()
    getProjectChannel()
    setFiltersUpdated(true, filtersCopy)
  }

  componentDidUpdate(prevProps = {}, prevState = {}) {
    const {
      isQuickFilterUpdated: stateIsFilterUpdated,
      locations: stateLocations = [],
      mergedFilters = {},
      setFiltersUpdated = () => {},
    } = this.props
    const {
      isQuickFilterUpdated: prevStateIsFilterUpdated,
      mergedFilters: prevMergedFilters = {},
    } = prevProps
    const { quickFilters = {}, pageName = '', channels = [] } = this.props
    const {
      autocompleteSelectData: autocompleteSelectDataCopy = {},
      locationsLabel: stateLocationsLabel = [],
      isFetchedlocation,
      filters: stateFilters = {},
    } = this.state
    if (
      Object.entries(mergedFilters).length > 0 &&
      !isEqual(mergedFilters, prevMergedFilters)
    ) {
      this.setState({
        filters: Object.assign({}, stateFilters, mergedFilters),
      })
    }
    const filtersCopy = Object.assign({}, quickFilters)
    if (
      !!stateIsFilterUpdated &&
      stateIsFilterUpdated !== prevStateIsFilterUpdated
    ) {
      this.setState({
        filters: filtersCopy,
      })
    }
    if (
      !!stateLocations &&
      stateLocations !== stateLocationsLabel &&
      !!isFetchedlocation
    ) {
      this.setState({
        locationsLabel: stateLocations,
        isFetchedlocation: false,
      })
    }
    if (
      channels.length > 0 &&
      prevProps.channels !== channels &&
      ['searchInventory', 'myOrders'].indexOf(pageName) !== -1
    ) {
      const ChannelList = this.getFormattedChannels(channels)
      const newAutoCompleteData = Object.assign(
        {},
        autocompleteSelectDataCopy,
        { 'Project Channel': ChannelList, 'Shipment Channel': ChannelList }
      )
      this.setState({
        autocompleteSelectData: newAutoCompleteData,
      })
    }
    if (this.state.savedData) {
      setFiltersUpdated(true, this.state.filters, this.state.savedData)
      this.setState({
        savedData: false,
      })
    }
  }

  getFormattedChannels = (channels = []) => {
    const channelsCopy = [...channels]
    const ChannelList = channelsCopy.map((it) =>
      Object.assign(
        {},
        {
          value: it.channel,
          label: it.channel,
        }
      )
    )
    return ChannelList.sort((a, b) => (a.name > b.name ? -1 : 1))
  }

  getSelectableFilters = () => {
    const { filters = {} } = this.state
    const { filterCompType = '' } = this.props
    const { [filterCompType]: currentCompFilters = {} } = filters
    let { selectableFilters = [] } = this.state
    const returnArray = (selectableFilters = selectableFilters.filter(
      function (el) {
        return Object.keys(currentCompFilters).indexOf(el) < 0
      }
    ))
    return returnArray || []
  }
  openSavedFilters = () => {
    const { getFilters = () => {}, pageName = '' } = this.props
    let additionalParams = {
      feature: 'search_include_filters',
    }
    getFilters(pageName, additionalParams)
  }

  showSaveFiltersPopUp = (event) => {
    event.preventDefault()
    this.setState({
      saveFiltersPopUpIsVisible: true,
    })
  }

  // componentWillUnmount() {
  //   const { setFilters, pageName = '' } = this.props
  //   const { filters = {} } = this.state
  //   const filterCopy = Object.assign({}, filters)
  //   if (!isEmpty(filterCopy)) {
  //     setFilters(filterCopy, pageName)
  //   }
  // }

  fetchAutocompleteData = (filter, currentDeptValue = '') => {
    if (['Location', 'Shipment Ship To'].indexOf(filter) !== -1) {
      let otherLocations = getBuildings().then((data) => {
        return locationResponseToSelect(data)
      })
      let defaultLocations = getLLBuildingByLocation('generic').then((data) => {
        return locationResponseToSelect(data)
      })
      return Promise.all([otherLocations, defaultLocations]).then(
        function (values) {
          return values.flat().sort((a, b) => {
            const One = a.label.toUpperCase()
            const Two = b.label.toUpperCase()
            return One < Two ? -1 : One > Two ? 1 : 0
          })
        }
      )
    } else if (
      ['Ship To Location', 'Shipment Ship From'].indexOf(filter) !== -1
    ) {
      return getCheckInLocation().then((data) => {
        return locationResponseToSelect(data)
      })
    } else if (filter === 'Project/Collection Name') {
      let projectNames = getAllProjectNames().then((data) => {
        return projectNamesResponseToSelect(data)
      })
      let collectionNames = getAllCollectionNames().then((data) => {
        return collectionNamesResponseToSelect(data)
      })
      return Promise.all([projectNames, collectionNames]).then(
        function (values) {
          return values
            .flat()
            .filter((it) => it)
            .sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )
        }
      )
    } else if (filter === 'Projects-Current') {
      let params = { status: 'PENDING' }
      let currentProjectNames = getAllProjects(params).then((res) => {
        return projectNamesResponse(res)
      })
      return currentProjectNames
    } else if (filter === 'Projects-Completed') {
      let params = { status: 'COMPLETED' }
      let completedProjectNames = getAllProjects(params).then((res) => {
        return projectNamesResponse(res)
      })
      return completedProjectNames
    } else if (filter === 'Collections-Current') {
      let params = { status: '' }
      let currentCollectionNames = getAllCollections(params).then((res) => {
        return collectionNamesResponse(res)
      })
      return currentCollectionNames
    } else if (filter === 'Collections-Completed') {
      let params = { status: 'COMPLETED' }
      let completedCollectionNames = getAllCollections(params).then((res) => {
        return collectionNamesResponse(res)
      })
      return completedCollectionNames
    } else if (filter === 'Collections-Cancelled') {
      let params = { status: 'CANCELLED' }
      let cancelledCollectionNames = getAllCollections(params).then((res) => {
        return collectionNamesResponse(res)
      })
      return cancelledCollectionNames
    } else if (filter === 'Brand') {
      return getAllBrands().then((data) => {
        return brandNamesResponseToSelect(data)
      })
    } else if (filter === 'Scenic Room') {
      let scenicRooms = fetchAllScenicRooms({}).then((res) => {
        return scenicRoomResponse(res)
      })
      return scenicRooms
    } else if (
      [
        'Ordered By',
        'Shipment Creator',
        'ordered_by',
        'Props Specialist',
        'Scenic Created By',
      ].indexOf(filter) !== -1
    ) {
      return getAllUsers().then((data) => {
        return userNamesResponseToSelect(data)
      })
    } else if (filter === 'Dept-Class' && currentDeptValue === '') {
      return getAllDepartments(currentDeptValue).then((data) => {
        return departmentResponseToSelect(data)
      })
    } else if (filter === 'Dept-Class' && currentDeptValue !== '') {
      return getClassByDepartment(currentDeptValue).then((data) => {
        return departmentClassResponseToSelect(data)
      })
    } else if (filter === 'Props Color') {
      return fetchPropsMetadata().then((res) => {
        const { data = {} } = res
        const { colorFamily = [] } = data
        return getFormattedOptions(
          colorFamily,
          {
            value: 'color_id',
            label: 'name',
          },
          true
        )
      })
    } else if (
      filter === 'Props Category- Sub Category' &&
      currentDeptValue === ''
    ) {
      return fetchPropsMetadata().then((res) => {
        const { data = {} } = res
        const { categories = [] } = data
        return getFormattedOptions(
          categories,
          {
            value: 'category_id',
            label: 'name',
          },
          true
        )
      })
    } else if (
      filter === 'Props Category- Sub Category' &&
      currentDeptValue !== ''
    ) {
      return fetchPropsMetadata().then((res) => {
        const { data = {} } = res
        const { categories = [] } = data
        return getFormattedSubCategories(categories, currentDeptValue)
      })
    } else if (
      filter === 'Scenic Category - Sub Category' &&
      currentDeptValue === ''
    ) {
      return fetchScenicMetadata().then((res) => {
        const { data = {} } = res
        const { categories = [] } = data
        return getFormattedOptions(
          categories,
          {
            value: 'category_id',
            label: 'name',
          },
          true
        )
      })
    } else if (
      filter === 'Scenic Category - Sub Category' &&
      currentDeptValue !== ''
    ) {
      return fetchScenicMetadata().then((res) => {
        const { data = {} } = res
        const { categories = [] } = data
        return getFormattedSubCategories(categories, currentDeptValue)
      })
    } else if (filter === 'Scenic Source') {
      return fetchScenicMetadata().then((res) => {
        const { data = {} } = res
        const { sources = [] } = data
        return getFormattedOptions(
          sources,
          {
            value: 'source_id',
            label: 'source_name',
          },
          true
        )
      })
    } else if (filter === 'Props Material') {
      return fetchPropsMetadata().then((res) => {
        const { data = {} } = res
        const { materials = [] } = data
        return getFormattedOptions(
          materials,
          {
            value: 'material_id',
            label: 'material_name',
          },
          true
        )
      })
    }
  }

  setFiltersData = (filters) => {
    const formattedFilters = filtersFromParamsSearch(filters)
    this.setState({
      filters: formattedFilters,
    })
  }

  setFilters = (filters) => {
    this.setState({
      filters: filters,
    })
  }

  closeWithoutSaving = () => {
    this.setState({
      saveFiltersPopUpIsVisible: false,
    })
  }

  closeWithoutChoosing = () => {
    this.setState({
      openFiltersPopUpIsVisible: false,
    })
  }
  handleChooseFilters = (filterTitle = '') => {
    const formattedFilters = filtersFromParamsSearch(cloneDeep(filterTitle))
    this.setState({
      openFiltersPopUpIsVisible: false,
      filters: formattedFilters,
      savedData: true,
    })
  }

  handleSaveFilters = (title = '', checkBoxSelected = false) => {
    const {
      saveFilters = () => {},
      auth: {
        session: { userInfo: { lanId = '' } = {} },
      },
      filterCompType = '',
      pageName = '',
    } = this.props
    const { filterId = '' } = this.state
    const { filters: stateFilters = {} } = this.state
    const filtersCopy = Object.assign({}, stateFilters)
    const compBasedFilters = filtersCopy[filterCompType] || {}
    const filtersList = Object.keys(compBasedFilters)
    const filterData = paramsFromFilters(
      filtersCopy,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      true,
      this.props.currentTabValue
    )
    const { requestPayload = {} } = filterData
    const filterRequest = {
      lanId: lanId,
      title: title,
      filters: filtersList,
      accessType: false,
      id: !checkBoxSelected ? filterId : '',
      feature: 'search_include_filters',
    }
    const filtersCopySave = {
      title: title,
      data: requestPayload,
    }
    saveFilters(filterRequest, filtersCopySave, pageName)
    this.setState({
      saveFiltersPopUpIsVisible: false,
    })
    this.openSavedFilters()
  }

  clearFilters = () => {
    const { setFiltersUpdated = () => {}, fromSearch = false } = this.props
    const { filters = {} } = this.state
    const stateFiltersCopy = Object.assign({}, filters)
    const filtersCopy = Object.assign({}, stateFiltersCopy, {
      exclude_filters: {},
      include_filters: {},
      savedData: false,
    })
    this.openSavedFilters()
    const ele =
      fromSearch &&
      this.autoC.current.getElementsByClassName(
        'MuiAutocomplete-clearIndicator'
      )[0]
    if (ele) ele.click()
    this.setState(
      {
        filters: filtersCopy,
        openFiltersPopUpIsVisible: false,
        filterTitle: '',
        filterId: '',
      },
      () => {
        setFiltersUpdated(true, filtersCopy)
      }
    )
  }

  getSelectOptions = (filter, index, handleChange = () => {}) => {
    const { filters: stateFilters = {} } = this.state
    const { filterCompType = '' } = this.props
    const stateFiltersCopy = Object.assign({}, stateFilters)
    const filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    if (!filtersCopy[filter]) {
      return (
        <MenuItem
          key={index}
          id={filter}
          value={filter}
          data-value={filter}
          onClick={(event) => handleChange(event)}
        >
          {filter}
        </MenuItem>
      )
    }
  }

  handleTriggerCallback = () => {
    this.setState({
      handleDropDownTrigger: false,
      handleTriggerProps: {},
    })
  }

  searchLocationByID = (filter = '', locationId = '') => {
    const loctionIdString = locationId.split(/[,\r\n\s]+/g).join(',')
    const { filterCompType = '' } = this.props
    // this.currentUserLocations(loctionIdString)
    const { filters: stateFilters = {} } = this.state
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    filtersCopy[filter].textFieldValue = loctionIdString
    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({ filters: updateStateFilters })
  }

  currentUserLocations = async (locationId = '') => {
    const { filterLocations, userLocation = '' } = this.props
    let params = new URLSearchParams()
    params.append('key', apiConfig.apiKey)

    if (!isEmpty(locationId)) {
      params.append('location_ids', locationId)
    } else {
      params.append('building', userLocation)
    }
    await filterLocations(params)
  }

  removeFilter = (filter) => {
    const { filters: stateFilters = {} } = this.state
    let filtersCopy = cloneDeep(stateFilters)
    const { setFiltersUpdated = () => {}, filterCompType = '' } = this.props
    delete filtersCopy[filterCompType][filter]
    const { sortParam = '', sortOrder = '' } = this.state
    let { sortParamCopy = '', sortOrderCopy = '' } = { sortParam, sortOrder }
    if (sortParam === filter.split(' ').join('_')) {
      sortParamCopy = ''
      sortOrderCopy = ''
    }
    this.setState({
      filters: filtersCopy,
      sortParam: sortParamCopy,
      sortOrder: sortOrderCopy,
    })
    setFiltersUpdated(true, filtersCopy, sortParamCopy, sortOrderCopy)
  }

  getFilter = (filter = '') => {
    const { classes = {}, filterCompType } = this.props
    const { filters: stateFilters = {} } = this.state
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = !isUndefined(stateFiltersCopy[filterCompType])
      ? Object.assign({}, stateFiltersCopy[filterCompType])
      : Object.assign({}, stateFiltersCopy)
    if (filtersCopy[filter]) {
      return (
        <FormControl>
          <div
            className={
              filter === 'Location-WinterStreet'
                ? classes.addFilterLocationColumn
                : classes.addFilterColumn
            }
          >
            <div
              className={cx(classes.addFilter, classes.addFilterContent)}
              style={{
                width: filter === 'Location-WinterStreet' ? '100%' : 'inherit',
              }}
            >
              <span className={classes.getFilterClearName}>{filter}</span>
              <Clear
                className={classes.getFilterClear}
                onClick={() => {
                  this.removeFilter(filter)
                }}
              />
            </div>
            {this.getInputMethod(filter)}
          </div>
        </FormControl>
      )
    }
  }
  getChipArray = (chipArray = [], selectData = []) => {
    let returnArray = chipArray.map((chipArrayInstance, i) => {
      let tempArray = selectData.find(
        (selectDataInstance) =>
          selectDataInstance.value.toString() ===
          chipArrayInstance.value.toString()
      )

      return tempArray
    })
    return returnArray
  }
  getHelperText = (filter, textFieldValue) => {
    const sampleCount = !isEmpty(textFieldValue)
      ? (textFieldValue.trim(), textFieldValue.split(/[,\r\n\s]+/g).length)
      : ''
    switch (filter) {
      case 'TCIN':
        return sampleCount ? `${sampleCount} TCIN(s)` : ''
      case 'DPCI':
        return sampleCount ? `${sampleCount} DPCI(s)` : ''
      case 'Pegasus ID':
        return sampleCount ? `${sampleCount} Pegasus ID(s)` : ''
      case 'Barcode Number':
        return sampleCount ? `${sampleCount} Barcode Number(s)` : ''
      default:
        return ''
    }
  }
  getFilterChips = (filter) => {
    const { classes = {}, filterCompType = '' } = this.props
    const { filters: stateFilters = [], locationsLabel = [] } = this.state
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    const currentFilterData = filtersCopy[filter] || {}
    const { chipArray = [] } = currentFilterData
    let returnStr = null
    let labelCopy = []
    if (filter === 'Location ID' && locationsLabel.length > 0) {
      chipArray.forEach((data) => {
        let locationLabelCopy = locationsLabel.find(
          (label) => label.value.toString() === data
        )
        labelCopy.push(locationLabelCopy?.label)
      })
    }
    let chipArrayCopy =
      filter === 'Location ID' && labelCopy.length > 0
        ? [...labelCopy]
        : [...chipArray]
    chipArrayCopy.length > 0 &&
      (returnStr = chipArrayCopy.map((data, index) => {
        return (
          <Chip
            label={data}
            onDelete={this.handleDeleteChip(filter, data, index, 'chipArray')}
            size="small"
            classes={{
              root: classes.chipRoot,
              label: classes.chipArrayLabel,
            }}
          />
        )
      })) &&
      returnStr.push(
        <Button
          id="clearall"
          color="primary"
          size="small"
          onClick={this.handleDeleteChip(filter, '', '', 'chipArray', 'all')}
          className={classes.clearAll}
        >
          <Clear className={classes.clearAllIcon} /> CLEAR ALL
        </Button>
      )
    return returnStr
  }
  getBundled = (e, value, filter) => {
    const { filterCompType = '', setFiltersUpdated = () => {} } = this.props
    const { filters: stateFilters = {} } = this.state
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    filtersCopy[filter] = value
    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({ filters: updateStateFilters })
    setFiltersUpdated(true, updateStateFilters, '', '')
  }
  getInputMethod = (filter = '') => {
    function MultiValue(props = {}) {
      const {
        selectProps: { classes = {} },
      } = props
      return (
        <Chip
          tabIndex={-1}
          label={props.children}
          onDelete={props.removeProps.onClick}
          size="small"
          className={classes.chipRoot}
        />
      )
    }

    const {
      filters: currentFilters = {},
      autocompleteSelectData: autocompleteSelectDataCopy = {},
      sortParam: stateSortParam = '',
      sortOrder: stateSortOrder = '',
      handleDropDownTrigger,
      handleTriggerProps,
    } = this.state
    const {
      classes = {},
      setFiltersUpdated = () => {},
      filterCompType = '',
      pageName = '',
    } = this.props
    const filtersCopy = Object.assign({}, currentFilters)
    const stateFilters = Object.assign({}, filtersCopy[filterCompType])
    const stateFiltersList = Object.keys(stateFilters)
    const isFilterExistsInState =
      stateFiltersList.length > 0 && stateFiltersList.indexOf(filter) !== -1
    const currentFilterList = isFilterExistsInState ? stateFilters[filter] : {}
    if (dateInputs.includes(filter)) {
      const { startDate: currFilterStartDate, endDate: currFilterEndDate } =
        currentFilterList
      return (
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              id="startDate"
              autoOk
              clearable={'true'}
              onChange={(value) =>
                this.handleDateInput(filter, value, 'startDate')
              }
              error={currFilterStartDate > currFilterEndDate}
              maxDate={currFilterEndDate}
              maxDateMessage={"Can't select a date after the end date"}
              value={currFilterStartDate || 'Start Date'}
              format={DATE_FORMAT}
              placeholder={'Start Date'}
              invalidDateMessage=""
              className={classes.chipFilterTextField}
              InputProps={
                currFilterStartDate
                  ? [8, 10, 11].includes(moment(currFilterStartDate).month())
                    ? { className: classes.dateInput }
                    : {}
                  : {}
              }
            />
          </MuiPickersUtilsProvider>
          <p className={classes.helperText}>
            Set a range by selecting a second date:
          </p>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              id="endDate"
              autoOk
              clearable={'true'}
              onChange={(value) =>
                this.handleDateInput(filter, value, 'endDate')
              }
              error={currFilterEndDate < currFilterStartDate}
              minDate={currFilterStartDate}
              minDateMessage={"Can't select a date before the start date"}
              value={currFilterEndDate || 'End Date'}
              format={DATE_FORMAT}
              placeholder={'End Date'}
              invalidDateMessage=""
              className={classes.chipFilterTextField}
              InputProps={
                currFilterStartDate
                  ? [8, 10, 11].includes(moment(currFilterStartDate).month())
                    ? { className: classes.dateInput }
                    : {}
                  : {}
              }
            />
          </MuiPickersUtilsProvider>
          <Button
            id="apply"
            className={classes.applyButtonMargin}
            variant="outlined"
            color="primary"
            onClick={() => {
              const updateStateFilters = Object.assign({}, filtersCopy, {
                [filterCompType]: stateFilters,
              })
              setFiltersUpdated(
                true,
                updateStateFilters,
                stateSortParam,
                stateSortOrder
              )
            }}
            disabled={!currFilterStartDate}
          >
            APPLY
          </Button>
        </div>
      )
    } else if (listInputs.includes(filter)) {
      const { textFieldValue = '', unrecognizedValues = [] } = currentFilterList
      this.state.savedData && this.processChips(textFieldValue, filter)
      return (
        <div>
          <TextField
            id="chipTextField"
            label={'enter ' + filter + '(s)'}
            className={classes.chipFilterTextField}
            value={textFieldValue}
            onChange={this.handleTextFieldChange(filter)}
            variant="outlined"
            multiline
            InputLabelProps={{
              style: {
                fontSize: '15px',
              },
            }}
            helperText={this.getHelperText(filter, textFieldValue)}
          />

          <div id="1" className={classes.applyButtonParent}>
            {pageName !== 'searchArchivedSamples' &&
              (filter === 'TCIN' ||
                filter === 'DPCI' ||
                filter === 'Pegasus ID') && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={filter}
                      checked={stateSortParam === filter.split(' ').join('_')}
                      onChange={this.handleCheckBoxChange}
                      disabled={!textFieldValue}
                    />
                  }
                  label="Display results in order submitted"
                />
              )}
            <Button
              id="apply"
              className={classes.applyButton}
              variant="outlined"
              color="primary"
              onClick={() => {
                const updateStateFilters = Object.assign({}, filtersCopy, {
                  [filterCompType]: stateFilters,
                })
                setFiltersUpdated(
                  true,
                  updateStateFilters,
                  stateSortParam,
                  stateSortOrder
                )
                this.processChips(textFieldValue, filter)
              }}
              disabled={!textFieldValue}
            >
              APPLY
            </Button>
          </div>
          {!isEmpty(unrecognizedValues) ? (
            <div id="2" style={{ marginBottom: '15px' }}>
              <span style={{ color: 'red' }}>
                {'Unrecognized ' +
                  filter +
                  '(s): ' +
                  unrecognizedValues.join(', ')}
              </span>
            </div>
          ) : null}
          <div id="3">{this.getFilterChips(filter)}</div>
        </div>
      )
    } else if (deptClassInputs.includes(filter)) {
      const {
        currentDeptValue = '',
        currentClassValue = '',
        deptChipArray = [],
        categorySubCategoryArray = [],
        deptClassChipArray = [],
        selectData: currFilterSelectData = [],
        classSelectData = [],
        currentCategoryValue = '',
        currentSubCategoryValue = '',
        categoryChipArray = [],
        scenicCategorySubCategoryArray=[],
        scenicCategoryChipArray=[]
      } = currentFilterList
      let localCategoryArray = this.props.currentTabValue !== SCENIC ? categoryChipArray : scenicCategoryChipArray
      let localCategorySubCategoryArray = this.props.currentTabValue !== SCENIC ? categorySubCategoryArray : scenicCategorySubCategoryArray
      if (
        !isEmpty(autocompleteSelectDataCopy[filter]) &&
        isEmpty(currFilterSelectData)
      ) {
        let stateFiltersCopy = cloneDeep(stateFilters)
        stateFiltersCopy[filter].selectData = autocompleteSelectDataCopy[filter]
        const updateStateFilters = Object.assign({}, currentFilters, {
          [filterCompType]: stateFiltersCopy,
        })
        this.setState({ filters: updateStateFilters })
      } else if (isEmpty(currFilterSelectData)) {
        this.fetchAutocompleteData(filter, currentDeptValue).then(
          (selectData) => {
            const stateFiltersCopy = cloneDeep(stateFilters)
            stateFiltersCopy[filter].selectData = selectData
            const updateStateFilters = Object.assign({}, currentFilters, {
              [filterCompType]: stateFiltersCopy,
            })
            this.setState({ filters: updateStateFilters })
          }
        )
      }
      if (
        currentDeptValue !== '' &&
        isEmpty(classSelectData) &&
        currentClassValue === ''
      ) {
        this.fetchAutocompleteData(filter, currentDeptValue).then(
          (selectData) => {
            const stateFiltersCopy = Object.assign({}, stateFilters)
            stateFiltersCopy[filter].classSelectData = selectData
            const updateStateFilters = Object.assign({}, currentFilters, {
              [filterCompType]: stateFiltersCopy,
            })
            this.setState({ filters: updateStateFilters })
          }
        )
      } else if (currentCategoryValue !== '' && isEmpty(classSelectData)) {
        this.fetchAutocompleteData(filter, currentCategoryValue).then(
          (selectData) => {
            const stateFiltersCopy = Object.assign({}, stateFilters)
            stateFiltersCopy[filter].classSelectData = selectData
            const updateStateFilters = Object.assign({}, currentFilters, {
              [filterCompType]: stateFiltersCopy,
            })
            this.setState({ filters: updateStateFilters })
          }
        )
      }
      const components = { MultiValue }
      return (
        <div>
          <div>
            <ReactSelect
              classes={classes}
              styles={{
                input: (base) => ({
                  ...base,
                  height: '48px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }),
              }}
              inputId="react-select-dept"
              TextFieldProps={{
                label:
                  (filterCompType === 'exclude_filters' ? 'Exclude' : 'Add ') +
                  filter,
                InputLabelProps: {
                  htmlFor: 'react-select-dept',
                  shrink: true,
                },
              }}
              placeholder={
                filter === 'Dept-Class' ? 'Select Dept' : 'Select Category'
              }
              options={currFilterSelectData}
              onChange={(event) =>
                this.handleDepartmentChange(filter, event.value)
              }
              components={components}
            />
          </div>
          <div>
            <ReactSelect
              classes={classes}
              ref={this.selectRef}
              styles={{
                input: (base) => ({
                  ...base,
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }),
              }}
              inputId="react-select-dept-class"
              TextFieldProps={{
                label:
                  (filterCompType === 'exclude_filters' ? 'Exclude' : 'Add ') +
                  filter,
                InputLabelProps: {
                  htmlFor: 'react-select-dept-class',
                  shrink: true,
                },
              }}
              placeholder={
                filter === 'Dept-Class' ? 'Select Class' : 'Select Sub Category'
              }
              options={classSelectData}
              onChange={(value) => this.handleClassChange(filter, value)}
              isMulti
              components={components}
              isDisabled={
                filter === 'Dept-Class'
                  ? !currentDeptValue
                  : !currentCategoryValue
              }
            />
          </div>

          <div className={classes.applyButtonParent}>
            <Button
              id="addDeptClass"
              className={
                currentSubCategoryValue
                  ? classes.addCatSubCatButton
                  : classes.addDeptClassButton
              }
              variant="outlined"
              color="secondary"
              onClick={() => {
                this.processDeptClassChips(
                  filter === 'Dept-Class'
                    ? currentDeptValue
                    : currentCategoryValue,
                  filter === 'Dept-Class'
                    ? currentClassValue
                    : currentSubCategoryValue,
                  filter
                )
              }}
              disabled={
                filter === 'Dept-Class'
                  ? !currentDeptValue
                  : !currentCategoryValue
              }
            >
              {filter === 'Dept-Class'
                ? currentClassValue
                  ? 'ADD DEPARTMENT-CLASS'
                  : 'ADD DEPARTMENT'
                : currentSubCategoryValue
                  ? 'ADD CATEGORY-SUB-CATEGORY'
                  : 'ADD CATEGORY'}
            </Button>
          </div>
          <div>
            {!isEmpty(deptChipArray) ? <span>Departments: </span> : null}
            {deptChipArray.map((data, index) => {
              return (
                <Chip
                  label={data}
                  onDelete={this.handleDeleteChip(
                    filter,
                    data,
                    index,
                    'deptChipArray'
                  )}
                  size="small"
                  classes={{
                    root: classes.chipRoot,
                  }}
                />
              )
            })}
          </div>
          <div>
            {!isEmpty(deptClassChipArray) ? (
              <span>Department-Classes: </span>
            ) : null}
            {deptClassChipArray.map((data, index) => {
              return (
                <Chip
                  label={data}
                  onDelete={this.handleDeleteChip(
                    filter,
                    data,
                    index,
                    'deptClassChipArray'
                  )}
                  size="small"
                  classes={{
                    root: classes.chipRoot,
                  }}
                />
              )
            })}
          </div>
          <div>
            {!isEmpty(localCategoryArray) ? <span>Category: </span> : null}
            {localCategoryArray.map((data, index) => {
              return (
                <Chip
                  label={data}
                  onDelete={this.handleDeleteChip(
                    filter,
                    data,
                    index,
                    'categoryChipArray'
                  )}
                  size="small"
                  classes={{
                    root: classes.chipRoot,
                  }}
                />
              )
            })}
          </div>
          <div>
            {!isEmpty(localCategorySubCategoryArray) ? (
              <span>Category - Sub Category: </span>
            ) : null}
            {localCategorySubCategoryArray.map((data, index) => {
              return (
                <Chip
                  label={data}
                  onDelete={this.handleDeleteChip(
                    filter,
                    data,
                    index,
                    'categorySubCategoryArray'
                  )}
                  size="small"
                  classes={{
                    root: classes.chipRoot,
                  }}
                />
              )
            })}
          </div>
        </div>
      )
    } else if (locationLevelsInputs.includes(filter)) {
      return (
        <>
          <Suspense fallback={<div>loading...</div>}>
            <OtherComponent
              isModalOpen={true}
              resetLocations={false}
              setIsNewOption={() => {}}
              componentType={SEARCH_LOCATIONS_COMPONENT_TYPE}
              onDropdownChange={(locationId = '') => {
                this.searchLocationByID(filter, locationId)
              }}
              handleDropDownTrigger={handleDropDownTrigger}
              handleTriggerProps={handleTriggerProps}
              handleTriggerCallback={this.handleTriggerCallback}
              multiLocationSelection
            />
          </Suspense>
          <div className={classes.applyButtonParent}>
            <Button
              id="locationWinterStFilter"
              className={classes.addDeptClassButton}
              variant="outlined"
              color="secondary"
              onClick={() => {
                const updatedFilters =
                  Object.assign({}, filtersCopy, {
                    [filterCompType]: stateFilters,
                  }) || {}
                setFiltersUpdated(
                  true,
                  updatedFilters,
                  stateSortParam,
                  stateSortOrder
                )
              }}
              disabled={
                !(
                  Object.keys(stateFilters).indexOf('Location-WinterStreet') !==
                    -1 &&
                  !isEmpty(
                    stateFilters['Location-WinterStreet']['textFieldValue']
                  )
                )
              }
            >
              APPLY
            </Button>
          </div>
        </>
      )
    } else if (booleanInputs.includes(filter)) {
      return (
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="true"
              control={<Radio checked={currentFilterList === 'bundled'} />}
              label="True"
              onChange={(e) => this.getBundled(e, 'bundled', filter)}
            />
            <FormControlLabel
              value="false"
              control={<Radio checked={currentFilterList === 'not bundled'} />}
              label="False"
              onChange={(e) => this.getBundled(e, 'not bundled', filter)}
            />
          </RadioGroup>
        </FormControl>
      )
    } else {
      const {
        selectData: currFilterSelectData = [],
        chipArray: currFilterChipArray = [],
      } = currentFilterList
      this.state.savedData && this.handleMultiSelectChange(filter)
      if (autocompleteSelectDataCopy[filter] && isEmpty(currFilterSelectData)) {
        const stateFiltersCopy = Object.assign({}, stateFilters)
        stateFiltersCopy[filter].selectData = autocompleteSelectDataCopy[filter]
        stateFiltersCopy[filter].chipArray = this.getChipArray(
          cloneDeep(stateFiltersCopy[filter].chipArray),
          autocompleteSelectDataCopy[filter]
        )
        const updateStateFilters = Object.assign({}, currentFilters, {
          [filterCompType]: stateFiltersCopy,
        })
        this.setState({ filters: updateStateFilters })
      } else if (isEmpty(currFilterSelectData)) {
        this.fetchAutocompleteData(filter).then((selectData) => {
          const stateFiltersCopy = Object.assign({}, stateFilters)
          stateFiltersCopy[filter].selectData = selectData
          stateFiltersCopy[filter].chipArray = this.getChipArray(
            cloneDeep(stateFiltersCopy[filter].chipArray),
            selectData
          )
          const updateStateFilters = Object.assign({}, currentFilters, {
            [filterCompType]: stateFiltersCopy,
          })
          this.setState({ filters: updateStateFilters })
        })
      }
      const components = { MultiValue }
      return (
        <div>
          <ReactSelect
            classes={classes}
            styles={{
              input: (base) => ({
                ...base,
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }),
            }}
            inputId="react-select-dept"
            TextFieldProps={{
              label:
                (filterCompType === 'exclude_filters' ? 'Exclude' : 'Add ') +
                filter,
              InputLabelProps: {
                htmlFor: 'react-select-dept',
                shrink: true,
              },
            }}
            placeholder={
              (filterCompType === 'exclude_filters' ? 'Exclude' : 'Add ') +
              filter
            }
            options={currFilterSelectData}
            value={currFilterChipArray}
            onChange={this.handleMultiSelectChange(filter)}
            isMulti
            components={components}
          />
        </div>
      )
    }
  }

  handleMultiSelectChange = (filter) => (event) => {
    const { setFiltersUpdated = () => {}, filterCompType = '' } = this.props
    const {
      filters: stateFilters = {},
      sortParam = '',
      sortOrder = '',
    } = this.state
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    filtersCopy[filter].chipArray = event
    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({ filters: updateStateFilters })
    setFiltersUpdated(true, updateStateFilters, sortParam, sortOrder)
  }

  handleDateInput = (filter = '', date, startOrEnd = '') => {
    const { filters: stateFilters = {} } = this.state
    const { filterCompType = '' } = this.props
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    filtersCopy[filter][startOrEnd] = date
    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({ filters: updateStateFilters })
  }

  handleDepartmentChange = async (filter, value) => {
    const { filters: stateFilters = {} } = this.state
    const { filterCompType = '' } = this.props
    value = '' + value
    this.selectRef.current?.clearValue()

    if (!/^[0-9]{0,3}$/.test(value)) {
      return
    }
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    filter === 'Dept-Class'
      ? (filtersCopy[filter].currentDeptValue = value)
      : (filtersCopy[filter].currentCategoryValue = value)
    filter === 'Dept-Class'
      ? (filtersCopy[filter].currentClassValue = '')
      : (filtersCopy[filter].currentSubCategoryValue = '')
    await this.fetchAutocompleteData(filter, value).then((selectData) => {
      filtersCopy[filter].classSelectData = selectData
    })
    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({ filters: updateStateFilters })
  }

  handleClassChange = (filter, selectedOption) => {
    const { filterCompType = '' } = this.props
    var valueArray = Array.isArray(selectedOption)
      ? selectedOption.map((x) => x.value)
      : []
    var value = []
    for (let index in valueArray) {
      if (filter === 'Dept-Class' && !/^[0-9]{0,2}$/.test(valueArray[index])) {
        return
      }
      value = valueArray + ',' + valueArray[index]
    }
    const { filters: stateFilters = {} } = this.state
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    filter === 'Dept-Class'
      ? (filtersCopy[filter].currentClassValue = value)
      : (filtersCopy[filter].currentSubCategoryValue = value)
    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({ filters: updateStateFilters })
  }

  handleTextFieldChange = (filter) => (event) => {
    const { filterCompType = '' } = this.props
    const { filters: stateFilters = {} } = this.state
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    filtersCopy[filter].textFieldValue = event.target.value
    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({ filters: updateStateFilters })
  }

  handleDeleteChip =
    (filter, chipData, index, arrayName, deletetype = 'single') =>
    (event) => {
      const { setFiltersUpdated = () => {}, filterCompType = '' } = this.props
      const {
        filters: stateFilters = {},
        sortParam: stateSortParam = '',
        sortOrder: stateSortOrder = '',
      } = this.state
      const stateFiltersCopy = Object.assign({}, stateFilters)
      let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
      let chipArray = []
      if (deletetype === 'all') {
        filtersCopy[filter][arrayName] = chipArray
        filtersCopy[filter]['unrecognizedValues'] = []
        this.setState({ sortParam: '', sortOrder: '' })
      } else {
        chipArray = filtersCopy[filter][arrayName]
        chipArray.splice(index, 1)
      }
      const updateStateFilters = Object.assign({}, stateFilters, {
        [filterCompType]: filtersCopy,
      })
      this.setState({ filters: updateStateFilters }, () => {
        setFiltersUpdated(
          true,
          updateStateFilters,
          stateSortParam,
          stateSortOrder
        )
      })
    }

  handleChange = (event) => {
    const currentTargetValue =
      event.target.value || (event.target.dataset || {}).value
    const { filters: stateFilters = {} } = this.state
    const { filterCompType = '' } = this.props
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    filtersCopy[currentTargetValue] = {}
    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({
      filters: updateStateFilters,
      selectIsOpen: false,
    })
  }

  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      this.setState({ selectIsOpen: false })
    }
  }

  processChips = (inputString, filter) => {
    const {
      setFiltersUpdated = () => {},
      fetchLocationDetails = () => {},
      filterCompType = '',
    } = this.props
    let splitInputArray = []
    let returnArray = []
    let unrecognizedValuesArray = []

    if (
      listInputs.indexOf(filter) !== -1 &&
      inputString.split(/[,\r\n\s,]+/g).length > 1 && ['']
    ) {
      inputString.trim()
      if (nameBasedListInputs.indexOf(filter) !== -1) {
        splitInputArray = inputString.split(/[,\r\n]+/g)
      } else {
        splitInputArray = inputString.split(/[,\r\n\s]+/g)
      }
    } else if (inputString.split('\n').length > 1) {
      inputString.trim()
      splitInputArray = inputString.split('\n')
    } else if (inputString.split(',').length > 0) {
      inputString.trim()
      if (inputString.charAt(inputString.length - 1) === ',') {
        inputString = inputString.substring(0, inputString.length - 1)
      }
      splitInputArray = inputString.split(',')
    } else {
      splitInputArray = inputString.trim()
    }

    splitInputArray = [...new Set(splitInputArray)]

    for (let index in splitInputArray) {
      let localFilter =
        ['Prop ID', 'Scenic Filemaker ID', 'Filemaker ID'].indexOf(filter) !==
        -1
          ? 'Filemaker ID'
          : filter
      if (regexExpressions[localFilter].test(splitInputArray[index].trim())) {
        if (
          [
            'Ordered By',
            'Project/Collection Creator',
            'Shipment Creator',
            'Scenic Created By',
          ].indexOf(filter) !== -1 &&
          splitInputArray[index].trim().split(' ').length > 1
        ) {
          returnArray.push(splitInputArray[index].trim().split(' ').join('.'))
        } else {
          returnArray.push(splitInputArray[index].trim())
        }
      } else if (splitInputArray[index].trim() !== '') {
        unrecognizedValuesArray.push(splitInputArray[index].trim())
      }
    }
    const {
      filters: stateFilters = {},
      sortParam: stateSortParam = '',
      sortOrder: stateSortOrder = '',
    } = this.state
    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    if (Object.entries(filtersCopy).length > 0) {
      Object.values(filtersCopy).map((obj = []) => {
        if (!isEmpty(obj) && typeof obj !== 'string') {
          obj['islatest'] = false
        }
        return obj
      })
    }
    if (filtersCopy[filter].chipArray) {
      returnArray = filtersCopy[filter].chipArray.concat(returnArray)
      returnArray = [...new Set(returnArray)]
    }

    filtersCopy[filter].chipArray = returnArray
    filtersCopy[filter].unrecognizedValues = unrecognizedValuesArray
    filtersCopy[filter].textFieldValue = ''
    filtersCopy[filter].islatest = true
    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({ filters: updateStateFilters })
    setFiltersUpdated(true, updateStateFilters, stateSortParam, stateSortOrder)
    if (!isEmpty(returnArray) && filter === 'Location ID') {
      let params = new URLSearchParams()
      params.append('key', apiConfig.apiKey)
      params.append('location_ids', returnArray)
      fetchLocationDetails(params, () => {
        this.setState({
          isFetchedlocation: true,
        })
      })
    }
  }

  processDeptClassChips = (deptString, classString, filter) => {
    const {
      filters: stateFilters = {},
      sortParam: stateSortParam = '',
      sortOrder: stateSortOrder = '',
    } = this.state
    const { setFiltersUpdated = () => {}, filterCompType = '' } = this.props
    const departmentID = this.addLeadingZeros(deptString, 3)
    const splitInputArray =
      typeof classString === 'string' && classString.split(',')

    const stateFiltersCopy = Object.assign({}, stateFilters)
    let filtersCopy = Object.assign({}, stateFiltersCopy[filterCompType])
    filtersCopy[filter].currentCategoryValue = ''
    filtersCopy[filter].currentSubCategoryValue = ''

    filtersCopy[filter].currentDeptValue = ''
    filtersCopy[filter].currentClassValue = ''
    if (!classString) {
      if (filter === 'Dept-Class' && !filtersCopy[filter].deptChipArray) {
        filtersCopy[filter].deptChipArray = [departmentID]
      } else if (
        filter !== 'Dept-Class' &&
        !filtersCopy[filter].categoryChipArray
      ) {
        filtersCopy[filter].categoryChipArray = [departmentID]
      } else if (
        filter === 'Dept-Class' &&
        filtersCopy[filter].deptChipArray &&
        !filtersCopy[filter].deptChipArray.includes(departmentID)
      ) {
        filtersCopy[filter].deptChipArray.push(departmentID)
      } else if (
        filter !== 'Dept-Class' &&
        filtersCopy[filter].categoryChipArray &&
        !filtersCopy[filter].categoryChipArray.includes(departmentID)
      ) {
        filtersCopy[filter].categoryChipArray.push(departmentID)
      }
    } else {
      for (let index in splitInputArray) {
        var classID = this.addLeadingZeros(splitInputArray[index], 2)

        if (
          filter === 'Dept-Class' &&
          !filtersCopy[filter].deptClassChipArray
        ) {
          filtersCopy[filter].deptClassChipArray = [
            departmentID + '-' + classID,
          ]
        } else if (
          filter !== 'Dept-Class' &&
          !filtersCopy[filter].categorySubCategoryArray
        ) {
          filtersCopy[filter].categorySubCategoryArray = [
            departmentID + '-' + classID,
          ]
        } else if (
          filter === 'Dept-Class' &&
          filtersCopy[filter].deptClassChipArray &&
          !filtersCopy[filter].deptClassChipArray.includes(
            departmentID + '-' + classID
          )
        ) {
          filtersCopy[filter].deptClassChipArray.push(
            departmentID + '-' + classID
          )
        } else if (
          filter !== 'Dept-Class' &&
          filtersCopy[filter].categorySubCategoryArray &&
          !filtersCopy[filter].categorySubCategoryArray.includes(
            departmentID + '-' + classID
          )
        ) {
          filtersCopy[filter].categorySubCategoryArray.push(
            departmentID + '-' + classID
          )
        }
      }
    }

    const updateStateFilters = Object.assign({}, stateFilters, {
      [filterCompType]: filtersCopy,
    })
    this.setState({ filters: updateStateFilters })
    setFiltersUpdated(true, updateStateFilters, stateSortParam, stateSortOrder)
  }

  addLeadingZeros = (stringToAddZero = '', intendedStringLength) => {
    if (!stringToAddZero) {
      return
    }
    let returnString = stringToAddZero
    while (returnString.length < intendedStringLength) {
      returnString = '0' + returnString
    }
    return returnString
  }

  handleCheckBoxChange = (event) => {
    const item = event.target.name
    const isChecked = event.target.checked
    var checkedParam = ''
    var sortOrderParam = ''
    if (isChecked) {
      checkedParam = item.split(' ').join('_')
      sortOrderParam = 'INPUT'
    }
    this.setState((state) => ({
      ...state,
      sortParam: checkedParam,
      sortOrder: sortOrderParam,
    }))
  }
  handleFilterClick(filter = '', id = '') {
    const { availableFilters = [] } = this.props
    for (let index in availableFilters) {
      const { title: saveFilterTitle = '', filters: savedFilters = [] } =
        availableFilters[index]
      if (saveFilterTitle === filter) {
        this.setFiltersData(savedFilters)
        this.handleChooseFilters(savedFilters)
        this.setState({
          selectedFilter: true,
          filterTitle: filter,
          filterId: id,
        })
      }
    }
  }

  deleteFilters = (filterId = '') => {
    const { deleteFilters = () => {}, pageName = '' } = this.props
    deleteFilters(filterId, pageName)
  }

  handleDeleteFilterClick = (selectedFilter = {}) => {
    const { id: filterId = '', title: filterName = '' } = selectedFilter
    const message = `${
      'You have selected "' + filterName + '" filter to delete?'
    }`
    this.setState({
      showAlertOnDelete: true,
      alertMessageOnDelete: message,
      deleteFilterId: filterId,
      deleteResponse: true,
    })
  }

  closeAlert = (e) => {
    this.setState({
      showAlertOnDelete: false,
      alertMessageOnDelete: '',
      deleteResponse: false,
      deleteFilterId: '',
    })
  }

  onDeleteFilter = () => {
    const { deleteFilterId = '' } = this.state
    const { deleteFilters = () => {}, pageName = '' } = this.props
    this.setState(
      {
        showAlertOnDelete: false,
        alertMessageOnDelete: '',
        deleteFilterId: '',
        deleteResponse: false,
      },
      () => {
        deleteFilters(deleteFilterId, pageName)
      }
    )
  }

  renderSavedFilters = () => {
    const {
      classes = {},
      availableFilters = [],
      renderFilterIcons = true,
      fetchDataRequestPending = false,
      fromSearch = false,
    } = this.props

    const { filters: filtersCopy = {} } = this.state
    const stateFilters = Object.assign({}, filtersCopy)
    const availableFiltersCopy = [...availableFilters]

    return (
      renderFilterIcons &&
      fromSearch && (
        <Paper
          style={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: 'unset',
            margin: '11px',
          }}
        >
          <Autocomplete
            id="project-dropdown"
            className={classes.autocompleteStyle}
            getOptionLabel={(option) => option.title}
            options={availableFiltersCopy}
            autoComplete
            fullWidth
            PaperComponent={CustomPaper}
            ref={this.autoC}
            includeInputInList
            onChange={(e, newValue) =>
              this.handleFilterClick(newValue?.title || '', newValue?.id || '')
            }
            clearOnBlur={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="My Saved Filters"
                id="My Saved Filters"
                placeholder="My Saved Filters"
                variant="outlined"
              />
            )}
            renderOption={(props, option) => {
              return renderSuggestion(
                props,
                option,
                this.handleDeleteFilterClick
              )
            }}
          />
          <IconButton
            type="button"
            aria-label="clearFilters"
            id="clearFilters"
            sx={{ p: '10px' }}
            onClick={() => {
              this.clearFilters()
            }}
            disabled={fetchDataRequestPending}
          >
            <Clear />
          </IconButton>
          {!Object.values(stateFilters).every(
            (obj) => obj === null || isEmpty(obj)
          ) && (
            <>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <Button
                id="saveFilters"
                onClick={(event) => this.showSaveFiltersPopUp(event)}
                startIcon={<Save />}
                style={{ width: 'auto', margin: 10 }}
                variant="outlined"
                color="primary"
              >
                Save Filters
              </Button>
            </>
          )}
        </Paper>
      )
    )
  }

  render() {
    const {
      classes = {},
      filterCompType = '',
      displayFiltersTitle = false,
    } = this.props
    const selectableFiltersList = this.getSelectableFilters()
    const {
      filters: filtersCopy = {},
      selectIsOpen = false,
      saveFiltersPopUpIsVisible = false,
      showAlertOnDelete = false,
      alertMessageOnDelete = '',
      deleteResponse = false,
    } = this.state
    const stateFilters = Object.assign({}, filtersCopy)
    const compTypeFilters = stateFilters[filterCompType] || {}
    return (
      <div className={classes.root}>
        <div className={classes.toolBar}>
          {displayFiltersTitle && (
            <div className={classes.filterTitle}>
              <Typography variant="h6">Filters</Typography>
            </div>
          )}
          <div className={classes.spacer} />
          {this.renderSavedFilters()}
        </div>
        <Paper elevation={8} className={classes.paper}>
          {Object.keys(compTypeFilters).map((filter) => this.getFilter(filter))}
          {!isEmpty(selectableFiltersList) ? (
            <div className={classes.addFilterOuterDiv}>
              <Button
                className={cx(classes.addFilter, classes.addFilterButton)}
                id="addFilter"
                onClick={() => this.setState({ selectIsOpen: true })}
                startIcon={
                  filterCompType === 'exclude_filters' ? (
                    <RemoveIcon />
                  ) : (
                    <Add />
                  )
                }
                ref={this.anchorRef}
              >
                {`${
                  (filterCompType === 'exclude_filters' ? 'Exclude' : 'Add') +
                  ' Filter'
                }`}
              </Button>
              <Popper
                open={selectIsOpen}
                anchorEl={this.anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className={classes.addFilterPopper}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom'
                          ? 'left-start top'
                          : 'left-start bottom',
                    }}
                  >
                    <CustomPaper className={classes.popperPaper}>
                      <ClickAwayListener
                        onClickAway={() =>
                          this.setState({ selectIsOpen: false })
                        }
                      >
                        <MenuList
                          autoFocusItem={selectIsOpen}
                          id="menu-list-grow"
                          onKeyDown={this.handleListKeyDown}
                          onClose={() => this.setState({ selectIsOpen: false })}
                        >
                          {selectableFiltersList
                            .sort()
                            .map((filter, index) =>
                              this.getSelectOptions(
                                filter,
                                index,
                                this.handleChange
                              )
                            )}
                        </MenuList>
                      </ClickAwayListener>
                    </CustomPaper>
                  </Grow>
                )}
              </Popper>
            </div>
          ) : null}
        </Paper>
        {saveFiltersPopUpIsVisible && (
          <NameFiltersPopUp
            isVisible={saveFiltersPopUpIsVisible}
            onClose={this.closeWithoutSaving}
            saveFilters={this.handleSaveFilters}
            title={this.state.filterTitle}
          />
        )}
        <DialogBox
          isAlertVisible={showAlertOnDelete}
          message={alertMessageOnDelete}
          onClose={this.closeAlert}
          response={deleteResponse}
          onAgree={this.onDeleteFilter}
          title="Delete Filter?"
          primaryButtonText="YES, DELETE FILTER"
          secondaryButtonText="NEVERMIND"
          fullWidth
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { pageName = '' } = ownProps

  return {
    availableFilters: selectPageAvailableFilters(pageName)(state),
    filters: selectPageFilters(pageName)(state),
    channels: getStoreChannels()(state),
    userLocation: state.user.location,
    locations: getLocationDetails()(state),
    saveFiltersData: state.addFilters.saveFiltersData,
    getFiltersPending: state.addFilters.getFiltersPending,
    fetchDataRequestPending: state.searchInventory.fetchDataRequestPending,
    pageName: pageName,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getFilters: (pageName, additionalParams) =>
      dispatch(getFilters(pageName, additionalParams)),
    saveFilters: (filterRequest, filterCopy, pageName) =>
      dispatch(saveFilters(filterRequest, filterCopy, pageName)),
    setFilters: (filters, pageName) => dispatch(setFilters(filters, pageName)),
    deleteFilters: (filterId = '', pageName = '') =>
      dispatch(deleteFilters(filterId, pageName)),
    getProjectChannel: () => dispatch(getProjectChannel()),
    filterLocations: (params) => dispatch(filterLocations(params)),
    saveFilterData: (params) => dispatch(saveFilterData(params)),
    fetchLocationDetails: (params, successCallback = () => {}) =>
      dispatch(fetchLocationDetails(params, successCallback)),
    getDropdownData: (selectedTab = PROPS) =>
      dispatch(getDropdownData(selectedTab)),
  }
}

AddFilters.propTypes = {
  availableFilters: propTypes.array,
  selectableFilters: propTypes.array,
  filterCompType: propTypes.string,
  renderFilterIcons: propTypes.bool,
  fromSearch: propTypes.bool,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(AddFilters)
