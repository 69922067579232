import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { makePopUpTable } from '../../util/Table'

export default function ItemTabPage({
  sampleData = {},
  markdownTabDataKeyLabels = [],
  updateSamplePageCallback = () => {},
}) {
  const itemCopy = Object.assign({}, sampleData)
  const { tcin = '' } = itemCopy
  const markdownTabDataKeyLabelsCopy = [...markdownTabDataKeyLabels]
  const [finalItemCopy, setFinalItemCopy] = useState({})
  const [isTheAPISucess, setIsTheAPISucess] = useState(false)

  useEffect(() => {
    return function cleanup() {
      setFinalItemCopy({})
      setIsTheAPISucess(false)
    }
  }, [])

  const sucessCallBack = (finalSampleData = {}) => {
    setFinalItemCopy(itemCopy)
    setIsTheAPISucess(true)
  }

  useEffect(() => {
    if (!isEmpty(String(tcin))) {
      setFinalItemCopy({})
      sucessCallBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tcin])

  return (
    <div key={finalItemCopy ? 'dataExists' : 'noData'}>
      {!!isTheAPISucess && Object.entries(finalItemCopy).length > 0
        ? makePopUpTable(
            finalItemCopy,
            markdownTabDataKeyLabelsCopy,
            updateSamplePageCallback
          )
        : ''}
    </div>
  )
}
