import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import { usePartnerContext } from './PartnerContext'

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

export default function PartnersList({
  onRemove = () => {},
  isReadOnly = false,
}) {
  const { partners = [] } = usePartnerContext()
  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          m: 0,
        }}
      >
        {partners.map((partner) => {
          const fullPartnerText = `${partner.partner_type}: ${partner.partner_name}`
          const onDelete = isReadOnly ? undefined : () => onRemove(partner)
          return (
            <ListItem key={fullPartnerText}>
              <Chip label={fullPartnerText} onDelete={onDelete} />
            </ListItem>
          )
        })}
      </Paper>
    </>
  )
}
