import React from 'react'
import moment from 'moment'
import * as propTypes from 'prop-types'
import {
  isEmpty,
  isEqual,
  isUndefined,
  find,
  first,
  findIndex,
  isArray,
} from 'lodash'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import AutoOrdePopOverComponent from './AutoOrdePopOverComponent'
/**Material components */
import {
  CircularProgress,
  Grid,
  SwipeableDrawer,
  Button,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
/**Components */
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
/**Material Icons */
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import EditIcon from '@mui/icons-material/Edit'
/**Action creators */
import {
  fetchBusinessPartnerReset,
  setCheckBoxHeaderChange,
  setCheckBox,
  setSampleData,
  emptySelectedData,
} from '../../store/order/actionCreator'
import {
  setMouseLocation,
  updateSelectedColumnData,
} from '../../store/order/actionCreator'
import { setHeaderTitleArray } from '../../store/layout/actionCreator'
/**Helpers/Utils */
import { DATE_FORMAT, convertTableDateFormat } from '../../util/CommonUtils'
import BottomBar from 'components/BottomBar'
import withHOCs from 'util/withHocs'
import { data } from 'pages/Barcode/BarcodeConstants'
import { Delete } from '@material-ui/icons'

/**Styles/Classes */
const styles = (theme) => ({
  swipeableDrawerElementsContainer: {
    overflow: 'hidden',
    backgroundColor: '#366cd9',
  },
  SwipeableDrawerElements: {
    fontSize: 'normal',
    textAlign: 'left',
    overflow: 'hidden',
  },
  swipeableDrawer: {
    paddingRight: '2%',
    paddingLeft: '2%',
    paddingTop: '10px',
    paddingBottom: '13px',
    overflow: 'hidden',
    alignItems: 'center',
  },
  defaultButton: {
    maxWidth: '250px',
    width: 'auto',
    margin: '0px 8px',
    color: 'white',
  },
})

const FlexibleTableNew = withStyles((theme) => ({
  container: {
    'max-height': '1000px',
  },
}))(FlexibleTable)

class OrderPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      toSuggestions: [],
      projects: [],
      date: new Date(),
      anchorOpen: false,
    }
  }

  componentDidMount = () => {
    const { setHeaderTitleArray } = this.props
    const {
      allProjects = [],
      allCollections = [],
      resetUpdateProjects = () => {},
    } = this.props
    setHeaderTitleArray([{ title: 'Order' }])
    this.disableButton()
    this.setState(
      {
        projects: this.getShootTypes(allProjects, allCollections),
      },
      () => {
        resetUpdateProjects()
      }
    )
  }

  componentWillUnmount = () => {
    const { fetchBusinessPartnerReset = () => {} } = this.props
    // fetchBusinessPartnerReset()
  }
  componentDidUpdate = (prevProps, prevState) => {
    const {
      allProjects: prevAllProjects = [],
      allCollections: prevAllCollections = [],
      data: prevPropsData = [],
      updateProjects: prevUpdateProjects,
      prevIsBPartnersLoading,
    } = prevProps
    const {
      data: propsData = [],
      allProjects = [],
      allCollections = [],
      updateProjects,
      resetUpdateProjects = () => {},
      isBPartnersLoading,
    } = this.props
    if (
      !isEqual(allProjects, prevAllProjects) ||
      !isEqual(allCollections, prevAllCollections) ||
      (!isEqual(updateProjects, prevUpdateProjects) && !!updateProjects)
    ) {
      this.setState(
        {
          projects: this.getShootTypes(allProjects, allCollections),
        },
        () => {
          resetUpdateProjects()
        }
      )
    }
    if (
      !isEqual(prevPropsData, propsData) ||
      isBPartnersLoading !== prevIsBPartnersLoading
    ) {
      this.disableButton()
    }
  }

  disableButton = () => {
    const { getROrderStatus = () => {}, data = [] } = this.props
    const propsData = this.getFormattedData(data)
    const buttonStatus =
      propsData.length > 0
        ? propsData.reduce((acc, item) => {
            const vendorEmailData = this.getVendorEmailData(
              item?.vendorId,
              item?.vendorEmail
            )
            const vendorBPsData = this.getVendorBPIDsData(
              item?.vendorId,
              item?.vendorBPIds
            )
            return (
              acc ||
              !!isEmpty(item.shipTo) ||
              item.shipTo === 'none' ||
              !!isEmpty(vendorEmailData) ||
              vendorEmailData === null ||
              vendorEmailData.length === 0 ||
              vendorEmailData === '' ||
              item.quantity <= 0 ||
              !!isEmpty(item.project) ||
              (typeof item.project === 'object' &&
                !!isUndefined(item.project.name)) ||
              isEmpty(item.dueDate) ||
              isUndefined(item.dueDate) ||
              item.dueDate < moment().toDate() ||
              !moment(moment(item.dueDate), 'MM/DD/YYYY', false).isValid()
            )
          }, false)
        : true
    const updatePageData = this.callCreateOrder()
    getROrderStatus(buttonStatus, updatePageData)
  }

  getShootTypes = (allProjects, allCollections) => {
    const projectNames = allProjects.map((item) =>
      Object.assign(
        {},
        {
          id: item?.projectId,
          name: item?.projectName,
          autoOrderEnabled: item?.autoOrderEnabled,
        }
      )
    )
    const collectionNames = allCollections.map((item) =>
      Object.assign(
        {},
        {
          id: item.id,
          name: item.name,
          autoOrderEnabled: false,
        }
      )
    )
    return [...projectNames, ...collectionNames].sort((a, b) =>
      a.name > b.name ? 1 : -1
    )
  }

  getLocationNames = (locations) => {
    const locationNames = locations.map((it) =>
      Object.assign({}, { name: it.building })
    )
    return locationNames.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  callCreateOrder = () => {
    const { data: propsData = [], locations = [] } = this.props
    const { projects = [] } = this.state
    const finalCreateData = propsData.map((obj = {}) => {
      const {
        project: objProject = {},
        shipTo: objShipTo = '',
        vendorId = '',
        vendorEmail = '',
        vendorBPIds = '',
      } = obj
      const vendorEmailData = this.getVendorEmailData(vendorId, vendorEmail)
      const vendorBPsData = this.getVendorBPIDsData(vendorId, vendorBPIds)
      const projectData = this.getProjectColumnData(objProject, objShipTo)

      const { name = '' } = projectData || {}
      const objProjectName = typeof objProject === 'object' ? name : projectData
      const curProject =
        projects.find((project) => project.name === objProjectName) ||
        obj?.project ||
        {}
      const { id: curProjectId = '', name: curProjectName = '' } = curProject
      const currentShipTo = find(locations, { building: objShipTo }) || {}
      const vendorEmailFinal = isArray(vendorEmailData)
        ? (vendorEmailData || []).join(',')
        : ''
      const vendorBPsFinalDisp = isArray(vendorBPsData) ? (vendorBPsData || []).map(bpData => `${bpData?.bp_name + '(' + bpData?.bp_id + ')'}`).join(',\n') : ''
      const vendorBPsFinal = isArray(vendorBPsData) ? (vendorBPsData || []).map(bpData => bpData?.bp_id || null) : []
      return Object.assign({}, obj, {
        vendorEmail: vendorEmailFinal,
        vendorBPIds: vendorBPsFinal,
        vendorBPIdsDisplay: vendorBPsFinalDisp,
        projectName: curProjectName || curProject,
        project: curProjectId,
        address: currentShipTo['address'] || objShipTo || '',
      })
    })
    return finalCreateData
  }

  // onProjectChange = (changeType = '', newValue = '', currentTcin = '') => {
  //   this.handleChange(changeType, newValue, currentTcin)
  // }

  onSelected = (selectedData, selected) => {
    const { tcin = '' } = selected
    return (
      findIndex(selectedData, function (obj) {
        return obj.tcin === tcin
      }) !== -1
    )
  }

  toggleFlyout = () => {
    const { anchorOpen } = this.state
    this.setState({ anchorOpen: !anchorOpen })
  }

  renderColError = () => {
    return (
      <span color="error" style={{ fontWeight: 'bold', color: '#B85300' }}>
        {`${'*Required'}`}
      </span>
    )
  }
  disclaimerFunction = () => {
    return (
      <em>
        <span
          color="error"
          style={{ fontWeight: 'bold', fontSize: 14, color: '#B85300' }}
        >{`${'*'}`}</span>
        <span
          color="error"
          style={{ fontWeight: 'bold', fontSize: 12, color: '#B85300' }}
        >
          {`${'Required'}`}
        </span>
        <span style={{ fontSize: 12 }}>
          {' '}
          Fields are Mandatory, Please Click on the Icon Next to Each Field to
          Start Editing
        </span>
      </em>
    )
  }

  getDueDateColumnData = (dueDate = null) => {
    return dueDate === null ||
      isUndefined(dueDate) ||
      !moment(moment(dueDate), 'MM/DD/YYYY', false).isValid() ||
      typeof dueDate !== 'string'
      ? this.renderColError()
      : convertTableDateFormat(dueDate, DATE_FORMAT)
  }

  getQuantityColumnData = (quantity) => {
    return quantity <= 0 ? this.renderColError() : quantity
  }

  getVendorEmailData = (vendorId, vendorEmail) => {
    const { businessPartners } = this.props
    const vendorPartners = businessPartners.get(vendorId) || []
    const result = (vendorEmail || vendorPartners || []).length
      ? vendorEmail || vendorPartners || []
      : null
    return result
  }

  getVendorBPIDsData = (vendorId, vendorBPIds) => {
    const { businessPartnerIds } = this.props
    const vendorBPs = businessPartnerIds.get(vendorId) || []
    const result = (vendorBPIds || vendorBPs || []).length
      ? (vendorBPIds || vendorBPs || [])
      : null
    return result
  }

  getVendorBPIDsDispData = (vendorId, vendorBPIds) => {
    const { businessPartnerIds } = this.props
    const vendorBPs = businessPartnerIds.get(vendorId) || []
    const result = (vendorBPIds || vendorBPs || []).length
      ? (vendorBPIds || vendorBPs || []).map(bpData => `${bpData?.bp_name + '(' + bpData?.bp_id + ')'}`)
      : null
    return result
  }

  getVendorEmailsColumnData = (vendorId, vendorEmail) => {
    const { isBPartnersLoading } = this.props
    const vendorEmailData = this.getVendorEmailData(vendorId, vendorEmail)
    return isBPartnersLoading ? (
      <CircularProgress />
    ) : vendorEmailData ? (
      vendorEmailData
    ) : (
      this.renderColError()
    )
  }

  getVendorBPIDsColumnData = (vendorId, vendorBPIds) => {
    const { isBPartnersLoading } = this.props
    const vendorBPIDsData = this.getVendorBPIDsData(vendorId, vendorBPIds)
    const finalBPIDsList = Array.isArray(vendorBPIDsData) ? vendorBPIDsData?.join(',\r\n') : vendorBPIDsData
    return vendorBPIDsData ? (
      vendorBPIDsData
    ) : (
      ''
    )
  }

  getVendorBPIDsDisplayData = (vendorId, vendorBPIds) => {
    const { isBPartnersLoading } = this.props
    const vendorBPIDsData = this.getVendorBPIDsDispData(vendorId, vendorBPIds)
    const finalBPIDsList = Array.isArray(vendorBPIDsData) ? vendorBPIDsData?.join(',\n') : vendorBPIDsData
    return isBPartnersLoading ? (
      <CircularProgress />
    ) : finalBPIDsList ? (
      finalBPIDsList
    ) : (
      ''
    )
  }

  getProjectColumnData = (dObjProject = {}, dObjShipTo = {}) => {
    let { env = {} } = this.props
    const { projects = [] } = this.state
    const { locationProjectValues = [] } = env
    const { orderSamplesFrom = 'READYTOORDER' } = this.props
    const projectIdFromPathname = first(
      (orderSamplesFrom || '/')
        .match(/\/(\d+)+[\\/]?/g)
        ?.map((id) => id.replace(/\//g, ''))
    )
    const autoOrderProjects =
      projects.filter((item) => item.autoOrderEnabled === true) || []
    let projectLocal = {}
    locationProjectValues.map((locationProjectValueInstance) => {
      const { location = '', project = '' } = locationProjectValueInstance
      if (dObjShipTo === location) {
        projectLocal = autoOrderProjects.find((element) => {
          return element?.name === project
        })
      }
    })
    const { name = '' } = dObjProject
    return !isEmpty(projectIdFromPathname) ||
      Object.entries(dObjProject).length > 0
      ? typeof dObjProject === 'object' && !isUndefined(dObjProject.name)
        ? name
        : dObjProject
      : !isEmpty(projectLocal)
        ? projectLocal?.name
        : this.renderColError()
  }

  getShipToColumnData = (shipTo = '') => {
    return !!isEmpty(shipTo) || shipTo === 'none'
      ? this.renderColError()
      : shipTo
  }
  mouseMoveEvent = (event) => {
    const { setMouseLocation = () => {} } = this.props
    setMouseLocation(event.clientX, event.clientY)
  }
  onIconClick = (event, selectedColumnData, keyName) => {
    this.mouseMoveEvent(event)
    const { updateSelectedColumnData = () => {} } = this.props
    !isArray(selectedColumnData?.vendorEmail) &&
      delete selectedColumnData?.vendorEmail
    selectedColumnData?.project &&
      typeof selectedColumnData?.project !== 'string' &&
      delete selectedColumnData?.project
    selectedColumnData?.dueDate &&
      typeof selectedColumnData?.dueDate !== 'string' &&
      delete selectedColumnData?.dueDate
    selectedColumnData?.shipTo &&
      typeof selectedColumnData?.shipTo !== 'string' &&
      delete selectedColumnData?.shipTo
    selectedColumnData['quantity'] =
      selectedColumnData?.quantity &&
      typeof selectedColumnData?.quantity === 'object'
        ? Number(0)
        : selectedColumnData?.quantity
    updateSelectedColumnData(selectedColumnData, true, keyName)
  }
  handleClose = () => {
    const { updateSelectedColumnData = () => {} } = this.props
    updateSelectedColumnData([], false, '')
  }
  getFormattedData = (data = []) => {
    const newData =
      data.length > 0
        ? data.map((dataObj) => {
            const {
              vendorId: dObjVendorId = '',
              dueDate: dObjDueDate = null,
              project: dObjProject = '',
              quantity: dObjQuantity = Number(1),
              shipTo: dObjShipTo = '',
              vendorEmail: dObjVendorEmail = [],
              vendorBPIds: dObjVendorBPIds = [],
            } = dataObj
            return {
              ...dataObj,
              dueDate: this.getDueDateColumnData(dObjDueDate),
              quantity: this.getQuantityColumnData(dObjQuantity),
              shipTo: this.getShipToColumnData(dObjShipTo),
              vendorEmail: this.getVendorEmailsColumnData(
                dObjVendorId,
                dObjVendorEmail
              ),
              vendorBPIds: this.getVendorBPIDsColumnData(
                dObjVendorId,
                dObjVendorBPIds
              ),
              vendorBPIdsDisplay: this.getVendorBPIDsDisplayData(
                dObjVendorId,
                dObjVendorBPIds
              ),
              project: this.getProjectColumnData(dObjProject, dObjShipTo),
            }
          })
        : []
    return newData
  }
  removeSamples = () => {
    let {
      selectedData = [],
      data = [],
      setSampleData = () => {},
      emptySelectedData = () => {},
    } = this.props

    data = data.filter((val) => {
      return !selectedData.find((val2) => {
        return val.tcin === val2.tcin
      })
    })
    setSampleData(data)
    emptySelectedData()
  }

  renderBottomSwipableDrawer = () => {
    const { classes, selectedData = [] } = this.props
    const numSelected = selectedData?.length || Number(0)

    return (
      <BottomBar
        anchor="bottom"
        variant={'persistent'}
        open={numSelected > 0}
        onOpen={() => null}
        onClose={() => null}
        showClearAll={numSelected > 0}
        actionType={'REST_ALL_REVIEWORDER'}
      >
        <div className={classes.swipeableDrawerElementsContainer}>
          <Grid
            container
            className={classes.swipeableDrawer}
            justify={'space-between'}
          >
            <Grid item>
              <Typography
                variant="body1"
                component="body1"
                className={classes.defaultButton}
              >
                {numSelected} Selected
              </Typography>
              <Button
                id="Edit items"
                data-id="Edit"
                className={classes.defaultButton}
                startIcon={<Delete />}
                onClick={this.removeSamples}
              >
                REMOVE SAMPLES
              </Button>
            </Grid>
          </Grid>
        </div>
      </BottomBar>
    )
  }
  disableCascadeIcon = (cascadeFieldKeys = []) => {
    const { orderSamplesFrom = '' } = this.props
    return orderSamplesFrom.includes('/projects/')
      ? cascadeFieldKeys.filter((cascadeFieldKey) => {
          return cascadeFieldKey !== 'project'
        })
      : cascadeFieldKeys
  }

  render() {
    const {
      columnData,
      data,
      checkBoxEnabled,
      onCheckBoxChange = () => {},
      onCheckBoxHeaderChange = () => {},
      selectedDataColumn = [],
      numSelected = Number(0),
      emailDataSelected = [],
      selectedField = '',
      updateEmailData = () => {},
      onFieldUpdate = () => {},
      mouseLocationX = '',
      mouseLocationY = '',
      iconClick = false,
      keyName = '',
      locations = '',
      isBPartnersLoading = '',
      isBPIdsLoading = false,
      businessPartners = new Map(),
      businessPartnerIds = new Map(),
      orderSamplesFrom,
      tabValue,
      selectedData,
    } = this.props
    let cascadeFieldKeys = [
      'dueDate',
      'vendorEmail',
      'vendorBPIdsDisplay',
      'quantity',
      'shipTo',
      'vendorNotes',
      'studioNotes',
      'project',
    ]
    const newData = this.getFormattedData(data)
    const dataFieldKeys = columnData.map((column) => {
      const { id = '', textRestriction = false } = column
      return { id: id, textRestriction: textRestriction }
    }) //todo
    return (
      <Grid container spacing={4}>
        <Grid container>
          {iconClick && (
            <div style={{ position: 'relative' }}>
              <AutoOrdePopOverComponent
                mouseLocationX={mouseLocationX}
                mouseLocationY={mouseLocationY}
                handleClose={this.handleClose}
                iconClick={iconClick}
                keyName={keyName}
                getLocationNames={this.getLocationNames}
                locations={locations}
                isBPartnersLoading={isBPartnersLoading}
                isBPIdsLoading={isBPIdsLoading}
                stateProjects={this.state.projects}
                businessPartners={businessPartners}
                businessPartnerIds={businessPartnerIds}
                tabValue={tabValue}
                updateEmailData={updateEmailData}
                dataObj={selectedDataColumn}
                onFieldUpdate={onFieldUpdate}
                orderSamplesFrom={orderSamplesFrom}
                sampleData={newData}
                selectedData={selectedData}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <FlexibleTableNew
            cascadeFieldKeys={this.disableCascadeIcon(cascadeFieldKeys)}
            editEnabled={false}
            numSelected={numSelected}
            onSelected={this.onSelected}
            selected={selectedData}
            columnData={columnData}
            data={newData}
            dataFieldKeys={dataFieldKeys}
            onClick={() => {}}
            onPopupChange={() => {}}
            showPopup={() => {}}
            checkBoxEnabled={checkBoxEnabled}
            onCheckBoxChange={onCheckBoxChange}
            onCheckBoxHeaderChange={onCheckBoxHeaderChange}
            uniqueIdentifierInData={'tcin'}
            enableScrolls
            onSelectColumn={true}
            selectedColumnName={selectedField}
            selectedColumnData={emailDataSelected}
            onIconClick={this.onIconClick}
            cascadeIcon={false}
            emailRestriction={true}
            notesRestriction={true}
            enableDisclaimer={this.disclaimerFunction()}
          />
        </Grid>
        {this.renderBottomSwipableDrawer()}
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    allProjects: state.order.projectNames,
    allCollections: state.collections.allCollections,
    businessPartners: state.order.businessPartners,
    businessPartnerIds: state.order.businessPartnerIds,
    columnData: state.order.columnData,
    data: state.order.data,
    locations: state.settings.checkInLocations,
    onClick: state.order.onClick,
    isBPartnersLoading: state.order.fetchBusinessPartnersRequestPending,
    isBPIdsLoading: state.order.fetchBusinessPartnersIdsPending,
    orderSamplesFrom: state.order.orderSamplesFrom,
    tabValue: state.order.tabValue,
    checkBoxEnabled: state.order.checkBoxEnabled,
    numSelected: state.order.numSelected,
    selectedData: state.order.selectedData,
    selectedField: state.order.selectedField,
    emailDataSelected: state.order.emailDataSelected,
    columnDataSelected: state.order.columnDataSelected,
    mouseLocationX: state.order.mouseLocationX,
    mouseLocationY: state.order.mouseLocationY,
    iconClick: state.order.iconClick,
    selectedDataColumn: state.order.selectedDataColumn,
    keyName: state.order.keyName,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchBusinessPartnerReset: () => dispatch(fetchBusinessPartnerReset()),
    setMouseLocation: (mouseLocationX, mouseLocationY) =>
      dispatch(setMouseLocation(mouseLocationX, mouseLocationY)),
    updateSelectedColumnData: (columnData, iconClick, keyName) =>
      dispatch(updateSelectedColumnData(columnData, iconClick, keyName)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    onCheckBoxChange: (selected) => {
      const { project, vendorEmail, quantity, dueDate, shipTo, ...rest } =
        selected
      dispatch(setCheckBox(rest))
    },
    onCheckBoxHeaderChange: (selected = []) => {
      const filteredObj = selected.map((selectedObj) => {
        const { project, vendorEmail, quantity, dueDate, shipTo, ...rest } =
          selectedObj
        return { ...rest }
      })
      dispatch(setCheckBoxHeaderChange(filteredObj))
    },
    setSampleData: (data = []) => {
      dispatch(setSampleData(data))
    },
    emptySelectedData: () => {
      dispatch(emptySelectedData())
    },
  }
}

OrderPage.propTypes = {
  allProjects: propTypes.array,
  businessPartners: propTypes.object,
  businessPartnerIds: propTypes.object,
  locations: propTypes.array,
  columnData: propTypes.array,
  data: propTypes.array,
  onClick: propTypes.func,
  onPopupChange: propTypes.func,
  isBPartnersLoading: propTypes.bool,
  isBPIdsLoading: propTypes.bool,
  orderSamplesFrom: propTypes.string,
  tabValue: propTypes.string,
  checkBoxEnabled: propTypes.bool,
  onCheckBoxChange: propTypes.func,
  onCheckBoxHeaderChange: propTypes.func,
}

export default withHOCs({
  auth: false,
  nav: false,
  env: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(OrderPage)
