//

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { ARROW_ICON_PADDING, TAB_PADDING_MAP } from '../constants'

export class MoreDropDown extends React.Component {
  static defaultProps = {
    classes: {},
    navigate: () => {},
  }

  state = {
    anchorEl: undefined,
    open: false,
  }

  render() {
    const { childMap, classes } = this.props

    return (
      <div>
        <Button
          classes={{
            root: classes.buttonRoot,
          }}
          onClick={this.onButtonClick}
        >
          More <ArrowDropDownIcon />
        </Button>
        <Menu
          id="SecondaryNavMoreLinks"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.onCloseMenu}
        >
          {childMap.map((child) => {
            const { children, index, to } = child

            return (
              <MenuItem key={index} onClick={this.onMenuItemClick(to)}>
                {Array.isArray(children) ? children.join(' ') : children}
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    )
  }

  onButtonClick = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  }

  onCloseMenu = () => {
    this.setState({
      open: false,
    })
  }

  onMenuItemClick = (url) => () => {
    this.props.navigate(url)
    this.onCloseMenu()
  }
}

const styleSheet = ({ breakpoints, palette }) => ({
  buttonRoot: {
    color: palette.primary.contrastText,
    opacity: 0.7,
    paddingTop: 0,
    paddingBottom: 0,
    height: 48,
    [breakpoints.down('md')]: {
      paddingLeft: TAB_PADDING_MAP.SM,
      paddingRight: TAB_PADDING_MAP.SM - ARROW_ICON_PADDING,
    },
    [breakpoints.up('md')]: {
      paddingLeft: TAB_PADDING_MAP.MD,
      paddingRight: TAB_PADDING_MAP.MD - ARROW_ICON_PADDING,
    },
  },
})

export default withStyles(styleSheet)(MoreDropDown)
