import { SwipeableDrawer, Box } from '@material-ui/core'
import ShowIf from 'components/ShowIf'

function AddSampleAndProps({
  searchAndAdd,
  unavailableShipmentSamplesAndProps,
  isAddSamplesSliderOpen,
  setIsAddSamplesSliderOpen,
}) {
  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isAddSamplesSliderOpen}
        onClose={() => setIsAddSamplesSliderOpen(false)}
      >
        <Box
          sx={{
            width: 750,
            height: 1000,
            overflowX: 'hidden',
          }}
          role="presentation"
        >
          <ShowIf condition={isAddSamplesSliderOpen}>
            {/* To reset all field data */}
            {searchAndAdd}
            <div style={{ margin: '32px 0 0 8px' }}>
              {unavailableShipmentSamplesAndProps}
            </div>
          </ShowIf>
        </Box>
      </SwipeableDrawer>

      {/*
        TODO check below use case
        
        {isEmpty(shipFromLocName) && (
          <Paper style={{ padding: '16px 24px', marginTop: '82px' }}>
            <div className={classes.shipFromLocConflict}>
              <div>{`Enter the "Ship From" Location to Add Samples`}</div>
              <div
                className={classes.conflictFonSize}
              >{`You may change the "Ship From" until you select "Mark as Shipped"`}</div>
            </div>
          </Paper>
        )} */}
    </>
  )
}

export default AddSampleAndProps
