export const CLOSE_MY_ORDERS_PAGE_ALERT = 'CLOSE_MY_ORDERS_PAGE_ALERT'
export const CLOSE_MY_ORDERS_PAGE_POPUP = 'CLOSE_MY_ORDERS_PAGE_POPUP'
export const MY_ORDERS_PAGE_ALERT = 'MY_ORDERS_PAGE_ALERT'
export const CLOSE_MY_ORDERS_PAGE_CANCEL_ITEMS =
  'CLOSE_MY_ORDERS_PAGE_CANCEL_ITEMS'
export const MY_ORDERS_PAGE_OPEN_EDIT = 'MY_ORDERS_PAGE_OPEN_EDIT'
export const MY_ORDERS_PAGE_CLOSE_EDIT = 'MY_ORDERS_PAGE_CLOSE_EDIT'
export const MY_ORDERS_SET_CHECKBOX = 'MY_ORDERS_SET_CHECKBOX'
export const MY_ORDERS_SET_CHECKBOX_HEADER = 'MY_ORDERS_SET_CHECKBOX_HEADER'
export const MY_ORDERS_SET_DATA = 'MY_ORDERS_SET_DATA'
export const MY_ORDERS_SET_DATE = 'MY_ORDERS_SET_DATE'
export const MY_ORDERS_SET_QUANTITY = 'MY_ORDERS_SET_QUANTITY'
export const MY_ORDERS_SET_SHIP_TO = 'MY_ORDERS_SET_SHIP_TO'
export const MY_ORDERS_SET_STUDIO_NOTES = 'MY_ORDERS_SET_STUDIO_NOTES'
export const MY_ORDERS_SET_VENDOR_NOTES = 'MY_ORDERS_SET_VENDOR_NOTES'
export const MY_ORDERS_SUBMIT_ORDER = 'MY_ORDERS_SUBMIT_ORDER'
export const SHOW_MY_ORDERS_PAGE_ALERT = 'SHOW_MY_ORDERS_PAGE_ALERT'
export const SHOW_MY_ORDERS_PAGE_POPUP = 'SHOW_MY_ORDERS_PAGE_POPUP'
export const MYORDER_USER_ORDER_REQUEST_FAILURE =
  'MYORDER_USER_ORDER_REQUEST_FAILURE'
export const MYORDER_USER_ORDER_REQUEST_PENDING =
  'MYORDER_USER_ORDER_REQUEST_PENDING'
export const MYORDER_USER_ORDER_REQUEST_SUCCESS =
  'MYORDER_USER_ORDER_REQUEST_SUCCESS'
export const SET_FILTERED_DATA = 'SET_FILTERED_DATA'
export const CANCEL_SAMPLE_REQUEST_SUCCESS = 'CANCEL_SAMPLE_REQUEST_SUCCESS'
export const CANCEL_SAMPLE_REQUEST_FAILURE = 'CANCEL_SAMPLE_REQUEST_FAILURE'
export const CANCEL_SAMPLE_REQUEST_PENDING = 'CANCEL_SAMPLE_REQUEST_PENDING'
export const MYORDER_UPDATE_SAMPLES_REQUEST_FAILURE =
  'MYORDER_UPDATE_SAMPLE_REQUEST_FAILURE'
export const MYORDER_UPDATE_SAMPLES_REQUEST_PENDING =
  'MYORDER_UPDATE_SAMPLE_REQUEST_PENDING'
export const MY_ORDERS_DUPLICATE_SAMPLES_FAILURE =
  'MY_ORDERS_DUPLICATE_SAMPLES_FAILURE'
export const MY_ORDERS_DUPLICATE_SAMPLES_PENDING =
  'MY_ORDERS_DUPLICATE_SAMPLES_PENDING'
export const MY_ORDERS_DUPLICATE_SAMPLES_SUCCESS =
  'MY_ORDERS_DUPLICATE_SAMPLES_SUCCESS'
export const MY_ORDERS_SET_PAGE = 'MY_ORDERS_SET_PAGE'
export const MY_ORDERS_SET_ROWS_PER_PAGE = 'MY_ORDERS_SET_ROWS_PER_PAGE'
export const MY_ORDERS_SET_TOTAL_ROWS_COUNT = 'MY_ORDERS_SET_TOTAL_ROWS_COUNT'
export const MY_ORDERS_SAVE_FILTERS_FAILURE = 'MY_ORDERS_SAVE_FILTERS_FAILURE'
export const MY_ORDERS_SAVE_FILTERS_PENDING = 'MY_ORDERS_SAVE_FILTERS_PENDING'
export const MY_ORDERS_SAVE_FILTERS_SUCCESS = 'MY_ORDERS_SAVE_FILTERS_SUCCESS'
export const MY_ORDERS_GET_FILTERS_SUCCESS = 'MY_ORDERS_GET_FILTERS_SUCCESS'
export const MY_ORDERS_GET_FILTERS_FAILURE = 'MY_ORDERS_GET_FILTERS_FAILURE'
export const MY_ORDERS_GET_FILTERS_PENDING = 'MY_ORDERS_GET_FILTERS_PENDING'
export const MY_ORDERS_SET_FILTERS = 'MY_ORDERS_SET_FILTERS'
export const MY_ORDERS_SET_FILTER_NOTFOUND_LIST =
  'MY_ORDERS_SET_FILTER_NOTFOUND_LIST'
export const MY_ORDERS_EMPTY_SELECTED_DATA = 'MY_ORDERS_EMPTY_SELECTED_DATA'
