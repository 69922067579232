import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import Node from './Node'
import RadioSelect from './RadioSelect'
import { default as _ } from 'lodash'
import CardSelection from './CardSelection'
import NodeContentModule from './NodeContentModule'
import LocationScan from './LocationScan'

class ResolutionTree extends PureComponent {
  constructor(props) {
    super(props)

    this.nodeContentModuleRef = React.createRef()

    this.state = {
      prevNodes: [],
      activeNode: props.startNode,
      accumulatedActions: [],
      savedValues: {},
      isReqCurrentNodeContentRequired: false,
      operation: 'generic',
    }
  }

  resetState = () => {
    const { startNode } = this.props
    this.setState({
      activeNode: startNode,
      prevNodes: [],
      accumulatedActions: [],
      savedValues: {},
    })
  }

  /**
   * handleClose
   * resets the state and calls the onClose callback function
   */
  handleClose = (result) => {
    const { onClose } = this.props
    this.resetState()
    onClose(result)
  }

  /**
   * handleDone
   * calls all accumulated functions in order of being stored
   */
  handleDone = () => {
    this.state.accumulatedActions
      .reduce((previousValue, currentValue) => {
        return previousValue.then(currentValue.action)
      }, Promise.resolve([]))
      .then((r) => {
        return this.handleClose(
          r !== undefined && r !== null && r.length === 0
            ? this.state.savedValues[this.state.activeNode]
            : r
        )
      })
  }

  /**
   * handleSetAction
   * stores function in accumulated list that will be executed when the resolution tree completes
   * @param action to be stored
   * @param callback to be executed upon state change completing
   */
  handleSetAction = (action, callback = () => {}) => {
    this.setState(
      (prevState) => ({
        accumulatedActions: [
          ...prevState.accumulatedActions,
          {
            node: prevState.activeNode,
            action: action,
          },
        ],
      }),
      callback
    )
  }

  /**
   * handleValueChange
   * stores a value in map associated with the current node as the key
   * @param value to be stored
   * @param callback function to be call upon completion of setState
   */
  handleValueChange = (value, callback) => {
    this.setState(
      (prevState) => ({
        savedValues: {
          ...prevState.savedValues,
          [this.state.activeNode]: value,
        },
      }),
      callback
    )
  }

  /**
   * handleValueChange
   * stores a value in map associated with the current node as the key
   * @param value to be stored
   * @param callback function to be call upon completion of setState
   */
  handleLocationChange = (value, callback, isDisableNext = false) => {
    this.setState(
      (prevState) => ({
        savedValues:
          !!isDisableNext ||
          _.isEmpty(value) ||
          (value && Object.values(value).indexOf('Unknown Location') !== -1)
            ? {}
            : {
                ...prevState.savedValues,
                [this.state.activeNode]: value,
              },
        isReqCurrentNodeContentRequired:
          !!isDisableNext ||
          _.isEmpty(value) ||
          (value && Object.values(value).indexOf('Unknown Location') !== -1),
      }),
      callback
    )
  }

  /**
   * handlePrevNode
   * return to previous node, removing all stored values and actions from current node
   */
  handlePrevNode = () => {
    this.setState((prevState) => ({
      savedValues: _.omit(prevState.savedValues, this.state.activeNode),
      accumulatedActions: _.partition(
        prevState.accumulatedActions,
        (n) => n.node === prevState.activeNode
      )[1],
      activeNode: prevState.prevNodes.pop(),
    }))
  }

  /**
   * handleNextNode
   * transition from current node to next node, appending current node to history
   * @param action the action to be triggered before transitioning to the next node
   * @param nextNode the node being transitioned to
   */
  handleNextNode = (action, nextNode) => {
    if (this.nodeContentModuleRef.current !== null) {
      this.nodeContentModuleRef.current.onTransitionForward(action, () =>
        this.transitionNode(nextNode)
      )
    } else {
      this.transitionNode(nextNode)
    }
  }

  transitionNode = (nextNode) => {
    if (nextNode === 'done') {
      this.handleDone()
    } else {
      this.setState((prevState) => ({
        prevNodes: update(prevState.prevNodes, {
          $push: [prevState.activeNode],
        }),
        activeNode: nextNode,
      }))
    }
  }

  /**
   * nodeDisableNext
   * disable next node if current node has not yet stored a value
   * @param node current node
   * @param {boolean} required if the current node requires a stored value
   * @returns {boolean}
   */
  nodeDisableNext = (node, required = false) => {
    return required
      ? !Object.prototype.hasOwnProperty.call(this.state.savedValues, node)
      : false
  }

  /**
   * getNextNode
   * returns the next node
   * @param nextNode
   * @returns {string|*|string|*}
   */
  getNextNode = (nextNode) => {
    switch (nextNode) {
      case undefined: {
        return 'done'
      }
      case '_dynamic': {
        return this.state.savedValues[this.state.activeNode] || ''
      }
      default:
        return nextNode
    }
  }

  /**
   * getActiveNode
   * Gets the current active node.  If the node does not exist, the initial node is returned instead.
   * @returns {*} currently active node
   */
  getActiveNode = () => {
    const { nodes, startNode } = this.props
    const { isReqCurrentNodeContentRequired = false } = this.state
    let returnNode =
      nodes[
        {}.hasOwnProperty.call(nodes, this.state.activeNode)
          ? this.state.activeNode
          : startNode
      ] || {}
    const { content: returnNodeContent = {} } = returnNode
    returnNode = isReqCurrentNodeContentRequired
      ? Object.assign({}, returnNode, {
          content: {
            ...returnNodeContent,
            required: isReqCurrentNodeContentRequired,
          },
        })
      : returnNode
    return returnNode
  }

  /**
   * setContent
   * Sets the node content to be displayed.
   * @param content
   * @returns {*}
   */
  setContent = (content) => {
    switch (content.variant) {
      case 'RadioSelect': {
        return (
          <RadioSelect
            ref={this.nodeContentModuleRef}
            currentValue={this.state.savedValues[this.state.activeNode] || ''}
            options={content.options || null}
            description={content.description || ''}
            onChange={this.handleValueChange}
          />
        )
      }
      case 'CardSelection': {
        return (
          <CardSelection
            ref={this.nodeContentModuleRef}
            samples={content.samples}
            paths={content.options}
            onChange={this.handleValueChange}
            setPath={this.handleNextNode}
            onTransitionForward={this.handleSetAction}
          />
        )
      }
      case 'SimpleContent': {
        return (
          <NodeContentModule
            ref={this.nodeContentModuleRef}
            description={content.description || ''}
            value={content.action}
            setActions={this.handleSetAction}
            onTransitionForward={this.handleValueChange}
          />
        )
      }
      case 'LocationScan': {
        return (
          <LocationScan
            ref={this.nodeContentModuleRef}
            description={content.description || ''}
            action={content.action}
            onTransitionForward={this.handleLocationChange}
            userLocation={this.props.userLocation}
            onChange={this.handleLocationChange}
            scanType={content.scanType}
            operation={this.props.operation}
          />
        )
      }
      default:
        return null
    }
  }

  render() {
    const { open } = this.props
    const activeNode = this.getActiveNode() || {}
    const content = activeNode.content || {}
    return (
      <Node
        id="resolutionNode"
        disableNext={this.nodeDisableNext(
          this.state.activeNode,
          content.required || false
        )}
        isOpen={open}
        label={activeNode.label || ''}
        content={this.setContent(content)}
        hasNext={activeNode.nextNode !== undefined}
        hasPrev={this.state.prevNodes.length > 0}
        onClose={this.handleClose}
        prev={() => this.handlePrevNode()}
        secondaryAction={() =>
          activeNode.secondaryAction
            ? this.handleNextNode(
                activeNode.secondaryAction.action
                  ? activeNode.secondaryAction.action
                  : () => {},
                this.getNextNode(activeNode.secondaryAction.node || null)
              )
            : null
        }
        secondaryActionLabel={
          activeNode.secondaryAction ? activeNode.secondaryAction.label : null
        }
        next={() =>
          this.handleNextNode(
            activeNode.next
              ? activeNode.next.action
                ? activeNode.next.action
                : () => {}
              : () => {},
            this.getNextNode(activeNode.next ? activeNode.next.node : undefined)
          )
        }
        nextLabel={activeNode.next ? activeNode.next.label : null}
      />
    )
  }
}

ResolutionTree.defaultProps = {
  startNode: 'default',
  nodes: {
    default: {
      label: '',
      content: {},
      nextNode: 'secondNode',
    },
  },
  onClose: () => {},
}

ResolutionTree.propTypes = {
  open: PropTypes.bool.isRequired,
  startNode: PropTypes.string.isRequired,
  nodes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
}

export default ResolutionTree
