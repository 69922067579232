export const RenderEditFields = [
  {
    id: 'Location',
    label: 'Ship To Location',
    renderValue: 'Location',
    renderType: 'AutoComplete',
  },
  {
    id: 'Quantity',
    label: 'Quantity',
    renderValue: 'quantity',
    renderType: 'Text',
  },

  {
    id: 'Submit',
    label: 'Submit',
    renderValue: 'Submit',
    renderType: 'Button',
  },
]
