import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import Select, { createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { defaultTheme } from 'react-select'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { isEmpty, isEqual } from 'lodash'
const { colors } = defaultTheme

const selectStyles = {
  control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
  menuList: (provided) => ({ ...provided, maxHeight: 150 }),
}
const styles = (theme) => ({
  addFilter: {
    // margin: '0px 0px 0px 15px',
    height: '35px',
    width: '250px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 2px 3px rgba(0,0,0,0.16), 0 2px 3px rgba(0,0,0,0.23)',
    },
    borderRadius: '4px',
  },
  buttonLabel: {
    justifyContent: 'space-between !important',
  },
  locationDetailPaper: {},
  paper: {},
  errorColor: {},
  greenClassIcon: {},
  greenClass: {},
  activeStepClass: {},
  vl: {},
  barcodeInput: {},
  inputLabelControlZero: {},
  inputLabelControlAuto: {},
  inputLabelControl: {},
  inputLabel: {},
  greyClass: {},
})

class CustomCreatableSelect extends React.Component {
  constructor(props) {
    super(props)
    const { distinct: propsData = [] } = this.props
    this.state = {
      isOpen: false,
      value: undefined,
      optionsList: propsData,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { distinct: propsData = [], selectedValue = {} } = this.props
    const {
      distinct: prevPropsData = [],
      selectedValue: prevSelectedValue = {},
    } = prevProps
    if (!isEqual(prevPropsData, propsData)) {
      this.setState({
        optionsList: propsData,
      })
    }
    if (!isEqual(prevSelectedValue, selectedValue)) {
      this.setState({
        value: selectedValue,
      })
    }
  }

  toggleOpen = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }))
  }
  onSelectChange = (value, actionMeta = {}) => {
    const { handleDropdownChange = () => {}, nextLevel = '' } = this.props
    const { action: ddOptionCreateType = '' } = actionMeta
    const currentValue =
      Object.keys(value).indexOf('currentLevel') === -1 ||
      ddOptionCreateType === 'create-option'
        ? Object.assign({}, value, { currentLevel: nextLevel })
        : value
    this.toggleOpen()
    this.setState({ currentValue })
    if (!isEmpty(value)) {
      handleDropdownChange(currentValue, actionMeta)
    }
  }
  render() {
    const { isOpen, value, optionsList = [] } = this.state
    const {
      isCreatable = false,
      componentLabel = '',
      classes = {},
      isReadOnly = false,
      buttonVariant = 'outlined',
      // isNewOption,
      isDisabled,
      endIcon = <ChevronDown />,
      startIcon = undefined,
    } = this.props
    // console.log(isNewOption)

    const customFilter = createFilter({ matchFrom: 'start' })

    return (
      <Dropdown
        isOpen={isOpen}
        onClose={this.toggleOpen}
        target={
          <Button
            endIcon={endIcon}
            startIcon={startIcon}
            onClick={!isReadOnly ? this.toggleOpen : () => {}}
            isselected={isOpen}
            variant={buttonVariant}
            color="secondary"
            className={classes.addFilter}
            disabled={isDisabled}
            classes={{
              label: classes.buttonLabel,
            }}
          >
            {!isEmpty(value) ? `${value.label}` : `${componentLabel}`}
          </Button>
        }
      >
        {isCreatable ? (
          <CreatableSelect
            autoFocus
            menuIsOpen
            options={optionsList}
            placeholder={'Search...'}
            isClearable={false}
            controlShouldRenderValue={false}
            isDisabled={isDisabled}
            onChange={this.onSelectChange}
            styles={selectStyles}
            value={value}
            components={{ DropdownIndicator, IndicatorSeparator: null }} //to add search icon instead of dropdown indicator
            hideSelectedOptions={false}
            backspaceRemovesValue={false}
            tabSelectsValue={false}
            filterOption={(option, rawInput) => {
              // Data says its new so we return true.
              if (option.data.__isNew__) return true

              // Filter using the filter function
              return customFilter(option, rawInput)
            }}
            formatCreateLabel={(userInput) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AddCircleOutlineIcon
                  style={{ fontSize: 15, marginRight: 5 }}
                />
                {`${' Add "' + userInput + '"'}`}
              </div>
            )}
          />
        ) : (
          <Select
            autoFocus
            backspaceRemovesValue={false}
            components={{ DropdownIndicator, IndicatorSeparator: null }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={this.onSelectChange}
            options={optionsList}
            placeholder="Search..."
            styles={selectStyles}
            tabSelectsValue={false}
            value={value}
            filterOption={createFilter({ matchFrom: 'any' })}
          />
        )}
      </Dropdown>
    )
  }
}

// styled components

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)'
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 22,
      }}
      {...props}
    />
  )
}
const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 12,
    }}
    {...props}
  />
)
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
)
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
)
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
)

export default withStyles(styles)(CustomCreatableSelect)
