export const getCurrentPageName =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.pageName
  }
export const getCurrentPageNumber =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.page
  }
export const getCurrentPageRowsPerPage =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.rowsPerPage
  }
export const getFetchDataRequestPending =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.fetchDataRequestPending
  }
export const getFetchedData =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.data
  }
export const getClickableColumns =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.clickableColumn
  }
export const getColumnData =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.columnData
  }
export const getNumSelected =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.numSelected
  }
export const getCheckBoxEnabled =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.checkBoxEnabled
  }
export const getPage =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.page
  }
export const getRowsPerPage =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.rowsPerPage
  }
export const getTotalRowsCount =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.totalRowsCount
  }
export const getSelectedData =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.selectedData
  }
export const getFetchDataRequestSuccess =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.fetchDataRequestSuccess
  }
export const getRefreshAutoOrderSamples =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.refreshAutoOrderSamples
  }
export const getAutoOrderSamples =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.autoOrderSamples
  }

export const getAutoOrderGridColumn =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.autoOrderGridColumn
  }

export const getCsvHeaders =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.csvHeaders
  }
export const getCsvCompleteAutoOrderSamples =
  () =>
  ({ autoOrderSamples = {} }) => {
    return autoOrderSamples?.csvCompleteAutoOrderSamples
  }
