import { isValidElement } from 'react'
import apiConfig from '../apiConfig'
import Cookies from 'js-cookie'
import ReactImageMagnify from 'react-image-magnify'
import queryString from 'query-string'
import { isEmpty, flatten } from 'lodash'
import moment from 'moment'
import momentBusinessDays from 'moment-business-days'
import defaultThumbnail from '../images/defaultThumbnail.jpg'
import Paper from '@material-ui/core/Paper'
import ThumbnailBlock from 'components/Search/ThumbnailBlock'
import { PROPS, SAMPLE, SCENIC } from 'enums/Tabs'
export const sampleFieldLabelMapper = () => {
  return {
    pegasusId: 'pegasus_id',
    orderNum: 'order_num',
    sample_status: 'sample_status',
    tcin: 'tcin',
    dpci: 'dpci',
    userId: 'user_id',
    vendorId: 'vendor_id',
    vendorEmail: 'vendor_email_id',
    shipTo: 'ship_to_loc',
    dueDate: 'sample_due_date',
    brand: 'brand',
    vendorNotes: 'vendor_notes',
    studioNotes: 'studio_notes',
    shipmentId: 'shipment_id',
    createDate: 'create_ts',
    sampleLaunchDate: 'launch_date',
    location: 'location',
    receivedDate: 'received_date',
    dispositionNotes: 'disposition_notes',
    warehouseNotes: 'warehouse_notes',
    digital_markdown_date: 'digital_markdown_date',
    store_markdown_date: 'store_markdown_date',
    disposition_date: 'disposition_date',
    disposition_override_date: 'disposition_override_date',
    isOwnedBrand: 'is_owned_brand',
    propCategory: 'category',
  }
}
export const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />
}
export const editableFieldsMapper = () => {
  return {
    vendorNotes: 'vendor_notes',
    studioNotes: 'studio_notes',
    dispositionNotes: 'disposition_notes',
    warehouseNotes: 'warehouse_notes',
    disposition_override_date: 'disposition_override_date',
    propCategory: 'propCategory',
    propSubcategory: 'propSubcategory',
    propColorFamily: 'propColorFamily',
    propMaterial: 'propMaterial',
    propSource: 'propSource',
  }
}

export function getKeyByValue(object = {}, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

export function getUserName(userInfo = {}) {
  const { firstName = '', lastName = '', fullName = '' } = userInfo
  const userName =
    [firstName, lastName].filter((obj) => obj).join('.') || fullName || ''
  return userName
}

export const getDisplayImage = (imageUrl = '') => {
  return !isEmpty(imageUrl) ? (
    <div>
      <img height={80} width={80} src={imageUrl} alt="Not Found" />
    </div>
  ) : (
    'Image not found'
  )
}
export const getDisplayImageWithProps = (
  imageUrl = '',
  height = 80,
  width = 80
) => {
  return !isEmpty(imageUrl) ? (
    <div>
      <img height={height} width={width} src={imageUrl} alt="Not Found" />
    </div>
  ) : (
    'Image not found'
  )
}
export const getDisplayImageList = (imageUrl = []) => {
  const imageListDiv = imageUrl.length
    ? imageUrl?.map((currentImage) => {
        return currentImage?.image_url ? (
          <div>
            <img height={80} src={currentImage?.image_url} alt="Not Found" />
          </div>
        ) : (
          ''
        )
      })
    : ''
  return imageListDiv
}

export const getDisplayThumbnailList = (imageUrl = []) => {
  const imageListDiv = imageUrl?.map((currentImage) => {
    return currentImage?.image_url ? (
      <div>
        <ThumbnailBlock
          imgSrc={currentImage?.image_url}
          enablePreview={true}
          val={currentImage?.image_url}
          previewHandler={() => {
            currentImage?.image_url &&
              window.open(currentImage?.image_url, '_blank')
            return false
          }}
          previewType={'view'}
        />
      </div>
    ) : (
      'Image not found'
    )
  })
  return <>{imageListDiv}</>
}
export const getImageMagnifier = (imageUrl = '') => {
  const bigImageQueryParts = {
    wid: 1000,
    hei: 1000,
    make_square: true,
  }
  const bigImageQuery = `${imageUrl}?${queryString.stringify(
    bigImageQueryParts
  )}`
  const imageQueryParts = {
    wid: 200,
    hei: 200,
    make_square: true,
  }
  const imageQuery = `${imageUrl}?${queryString.stringify(imageQueryParts)}`
  return !isEmpty(imageUrl) ? (
    <ReactImageMagnify
      smallImage={{
        isFluidWidth: true,
        alt: 'Not Found',
        src: imageQuery,
      }}
      largeImage={{
        alt: 'Not Found',
        src: bigImageQuery,
        width: 1000,
        height: 1000,
      }}
      enlargedImagePosition="over"
      enlargedImageContainerDimensions={310}
    />
  ) : (
    <img
      height={200}
      width={200}
      src={imageUrl}
      alt="Not Found"
      onError={(e) => {
        e.target.src = defaultThumbnail
      }}
    />
  )
}
export const PGPREFIX = 'PG'
export const WHPREFIX = 'WH'
export const PPPREFIX = 'PP'
export const PSPREFIX = 'PS'
export const DATE_FORMAT = 'MMM D, YYYY'
export const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD'
export const CSV_DATE_FORMAT = 'MM/DD/YYYY'
export const isDateInPast = (inputDate) => {
  if (inputDate) {
    // Create a date object...
    const dateToCompare = moment(inputDate)
    // Date object for today
    var today = moment().startOf('day') // At midnight earlier today
    return dateToCompare < today
  }
  return false
}
export const convertTableDateFormat = (inputDate = '', dateformat = '') => {
  const returnValue =
    !isEmpty(inputDate) && inputDate !== '0001-01-01'
      ? moment(inputDate).format(dateformat)
      : ''
  return returnValue
}

export const getSampleIDPrefix = (sampleid = '', type = SAMPLE) => {
  if (!sampleid) {
    return ''
  }
  if (type === PROPS) {
    return PPPREFIX + sampleid
  } else if (type === SCENIC) {
    return PSPREFIX + sampleid
  }
  return sampleid?.toString().length === 7
    ? WHPREFIX + sampleid
    : PGPREFIX + sampleid
}

export const getSampleIDWithouPrefix = (pegasusId = '') => {
  return pegasusId && Number(pegasusId.slice(2, pegasusId.length))
}

/**
 * Check for invalid prefix for Props and Samples type
 * @param {String} pegasusId
 * @param {String} type
 * @returns
 */
export const doesPegasusIdPrefixMatchType = (pegasusId = '', type = SAMPLE) => {
  const prefix = pegasusId && pegasusId.slice(0, 2)
  let isValidPrefix = true

  if (
    (type === SAMPLE && [PPPREFIX, PSPREFIX].includes(prefix)) ||
    (type === PROPS && [WHPREFIX, PGPREFIX, PSPREFIX].includes(prefix)) ||
    (type === SCENIC && [WHPREFIX, PGPREFIX, PPPREFIX].includes(prefix))
  ) {
    isValidPrefix = false
  }
  return isValidPrefix
}

export const getInvalidPegasusIdListBasedOnPrefixAndType = (
  pegasusIdList = [],
  type = SAMPLE
) => {
  const validPegasusIds = pegasusIdList.filter(
    (pegasusId) => !doesPegasusIdPrefixMatchType(pegasusId, type)
  )
  return validPegasusIds
}

export const getDomainName = (hostName) => {
  return hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
  )
}

export const defaultDueDateColumns = [
  'Royalston',
  'Schawk',
  'Periscope',
  'Splashlight',
]

export const shipToOnChange = (
  shipTo = '',
  tcin = '',
  dueDate = '',
  launchDate = '',
  days = 40
) => {
  let defaultDueDate = null
  const todayDate = new Date()
  defaultDueDateColumns.map((obj) => {
    if ((shipTo || '').indexOf(obj) !== -1 && !isEmpty(launchDate)) {
      const curLaunchDate = new Date(launchDate)
      defaultDueDate = momentBusinessDays(launchDate)
        .businessSubtract(days)
        .toDate()
      if (todayDate > defaultDueDate || curLaunchDate < todayDate) {
        defaultDueDate = null
      }
      defaultDueDate = moment(defaultDueDate)
    }
    return obj
  })
  return defaultDueDate
}

export const getFromBrowserStorage = function (storageKey = '') {
  return window.localStorage.getItem(storageKey)
}

export const setInBrowserStorage = function (
  storageKey = '',
  storageValue = ''
) {
  window.localStorage.setItem(storageKey, storageValue)
}

export const removeFromBrowserStorage = function (storageKey = '') {
  return window.localStorage.removeItem(storageKey)
}

export const sortByDate = function (
  myArray = [],
  myOrderBy = '',
  myOrder = 'desc'
) {
  if (myArray === null || myArray.length === 0) {
    return []
  }
  const orderedArray =
    myOrder === 'desc'
      ? myArray.sort(function (a, b) {
          let nameA = new Date(a[myOrderBy]).getTime()
          let nameB = new Date(b[myOrderBy]).getTime()
          return nameB < nameA ? -1 : 1
        })
      : myArray.sort(function (a, b) {
          let nameA = new Date(a[myOrderBy]).getTime()
          let nameB = new Date(b[myOrderBy]).getTime()
          return nameA < nameB ? -1 : 1
        })
  return orderedArray
}

export const sortingOrder = (varA, varB) => {
  let comparison = 0
  if (varA && varB) {
    if (varA === varB) {
      comparison = 0
    } else {
      varA > varB ? (comparison = -1) : (comparison = 1)
    }
  } else if (varA === null) {
    comparison = 1
  } else if (varB === null) {
    comparison = -1
  }
  return comparison
}

export const dateSortingOrder = (varA, varB) => {
  let comparison = 0
  if (varA && varB) {
    if (varA === varB) {
      comparison = 0
    } else {
      varA > varB ? (comparison = 1) : (comparison = -1)
    }
  } else if (varA === null || isNaN(varA)) {
    comparison = 1
  } else if (varB === null || isNaN(varB)) {
    comparison = -1
  }
  return comparison
}

export const SortAlphaNum = (order, orderBy) => {
  return function (a, b) {
    let comparison = 0
    if (a[orderBy] && b[orderBy]) {
      const reA = /[^a-zA-Z]/g
      const reN = /[^0-9]/g
      const aA = a[orderBy].replace(reA, '')
      const bA = b[orderBy].replace(reA, '')
      if (aA === bA) {
        const aN = parseInt(a[orderBy].replace(reN, ''), 10)
        const bN = parseInt(b[orderBy].replace(reN, ''), 10)
        aN === bN
          ? (comparison = 0)
          : aN > bN
            ? (comparison = 1)
            : (comparison = -1)
      } else {
        aA > bA ? (comparison = 1) : (comparison = -1)
      }
    } else if (a[orderBy] === null) {
      comparison = 1
    } else if (b[orderBy] === null) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  }
}

export const getFormattedOptions = (optionsList = [], optionProps = {}) => {
  const { label: optionPropsLabel = '', value: optionsPropsValue = '' } =
    optionProps
  // Removing null fileds
  const filteredOptionsList = optionsList.filter((obj) => obj)

  const formattedOptions = filteredOptionsList.map((obj) => {
    switch (optionPropsLabel) {
      case 'class_id': {
        return {
          label: `${obj['department_id']}-${obj[optionPropsLabel]}`,
          value: `${obj['department_id']}-${obj[optionPropsLabel]}`,
          displayName: obj[optionsPropsValue]
            ? `${obj[optionPropsLabel]} - ${obj[optionsPropsValue] || ''}`
            : `${obj[optionPropsLabel]}`,
          group_by: obj['department_name']
            ? `${obj['department_id']} - ${obj['department_name']}`
            : `${obj['department_id']}`,
          group_by_id: obj['department_id'],
          id: `${obj['department_id']}-${obj[optionPropsLabel]}`,
        }
      }
      case 'department_id': {
        return {
          label: isNaN(obj[optionPropsLabel])
            ? obj[optionPropsLabel] || obj
            : obj[optionPropsLabel],
          value: isNaN(obj[optionPropsLabel])
            ? obj[optionsPropsValue] || obj
            : obj[optionsPropsValue],
          displayName: obj[optionsPropsValue]
            ? `${obj[optionPropsLabel]} - ${obj[optionsPropsValue] || ''}`
            : `${obj[optionPropsLabel]}`,
          id: obj['org_department_id'],
        }
      }
      default: {
        return {
          label: isNaN(obj[optionPropsLabel])
            ? obj[optionPropsLabel] || obj
            : obj[optionPropsLabel],
          value: isNaN(obj[optionPropsLabel])
            ? obj[optionsPropsValue] || obj
            : obj[optionsPropsValue],
          displayName: `${obj[optionsPropsValue] || obj}`,
          id: isNaN(obj[optionPropsLabel])
            ? obj[optionsPropsValue] || obj
            : obj[optionsPropsValue],
        }
      }
    }
  })
  return formattedOptions
}

export const getSubCategories = (categories = [], category_id) => {
  let itemCatSubCatArr = (categories || []).map(
    (mObj) => mObj.subCategories || mObj.scenic_sub_categories
  )
  if (category_id) {
    itemCatSubCatArr = itemCatSubCatArr?.find(
      (fObj) => fObj.category_id === category_id
    )
  }
  const itemCatSubCatList = flatten(itemCatSubCatArr || [])?.map((sObj) => {
    const {
      category_id: categoryId = '',
      category_name: categoryName = '',
      name: subCategoryName = '',
      sub_category_id: subCategoryId = '',
    } = sObj
    return sObj
      ? {
          label: subCategoryName,
          id: subCategoryId,
          value: subCategoryId,
          displayName: subCategoryId,
          categoryId: categoryId || '',
          categoryName: categoryName || '',
        }
      : true
  })
  return itemCatSubCatList
}

export const OAUTH_PARTNERSONLINE_PERF_URL =
  'https://oauth.iam.perf.partnersonline.com'
export const PARTNERSONLINE_PERF_HOST = 'xycpeg.perf.partnersonline.com'

export const headerBatchScannerPages = [
  '/searchInventory',
  '/searchInventory/samples',
  '/searchInventory/PROPS',
  '/searchInventory/SCENIC',
  '/checkIn',
  '/moveSamples',
  '/shipSamples',
  '/shipSamplesList/create',
  '/shipSamplesList/:id/edit',
]

export const getLocationNames = (locations) => {
  const locationNames = locations.map((it) =>
    Object.assign(
      {},
      {
        label: it.building,
        value: it.locationId,
      }
    )
  )
  return locationNames.sort((a, b) => (a.label > b.label ? 1 : -1))
}
/**
 * Function to check for react elements in data and remove them to avoid data clone errors
 * @param {*} data
 * @returns
 */
export const removeReactElementsFromObject = (data) => {
  const updatedData = {}
  Object.keys(data).forEach((key) => {
    const dataInstance = data[key]
    if (!!dataInstance && !isValidElement(dataInstance)) {
      // NULL is valid react element hence first do null check
      updatedData[key] = dataInstance
    }
  })
  return updatedData
}

export const removeReactElements = (data) => {
  let result
  if (Array.isArray(data)) {
    result = data.map((instance) => removeReactElementsFromObject(instance))
  } else {
    result = removeReactElementsFromObject(data)
  }
  return result
}

export const checkIsEnabledRow = (valueToCheckAgainst, item) => {
  if (!valueToCheckAgainst) {
    return true
  }
  const { keyToCheckAgainst, value } = valueToCheckAgainst
  const isEnabled = item[keyToCheckAgainst]
    ? item[keyToCheckAgainst] === value
    : true
  return isEnabled
}

export const isNumber = (input) => /^-?\d+$/.test(input)

export const getPegasusID = (id = '') => {
  return isHavingPrefix(id) ? id.slice(2, id.length) : id
}

export const isHavingPrefix = (id = '') => {
  return ['WH', 'PP', 'PS', 'PG', 'PS'].includes(
    id.substring(0, 2)?.toUpperCase()
  )
}

/**
 * Get displayname from user object
 * @param userObj object with first_name and last_name
 * @returns a string displayname
 */
export const getDisplayName = (userObj = {}) => {
  const { first_name = '', last_name = '' } = userObj
  const projectCreator = [first_name, last_name]
    .filter(function (el) {
      return el
    })
    .join(' ')
  return projectCreator
}

//Bottom bar clear all button action types
export const CLEARALL_ACTIONS_BY_PAGE = {
  REST_ALL_SEARCHINVENTORY: 'searchInventory',
  REST_ALL_AUTOORDER: 'autoOrderSamples',
  REST_ALL_MANUALORDER: 'search',
  REST_ALL_MANAGELOCATIONS: 'manageLocations',
  REST_ALL_NEWSHIPMENT: 'newShipment',
  REST_ALL_COLLECTIONLIST: 'collections',
  REST_ALL_NEWSHOOTTYPE: 'newShootType',
  REST_ALL_SEARCHARCHIVEDSAMPLES: 'searchArchivedSamples',
  REST_ALL_REVIEWORDER: 'order',
  REST_ALL_MYORDER: 'myOrder',
  REST_ALL_SEARCHINVENTORY_SAMPLES: 'searchInventory',
  REST_ALL_SEARCHINVENTORY_PROPS: 'searchInventory',
  REST_ALL_SEARCHINVENTORY_SCENIC: 'searchInventory',
}

/**
 * Method to mutates current page state on clear all action for the pages present in CLEARALL_ACTIONS_BY_PAGE
 * @param {*} appInitState - initial state of application store
 * @param {*} updatedState - present state of the application store
 * @param {*} actionType - current triggered actiontype
 * @returns mutated state of current page
 */

export const getCurrentPageNewState = (
  appInitState,
  updatedState,
  actionType
) => {
  const currentPage = CLEARALL_ACTIONS_BY_PAGE[actionType]
  let currentSelectedData = []
  let currentNumSelected = Number(0)
  let currentNumSelectedTab = {
    PROPS: 0,
    SAMPLES: 0,
    SCENIC: 0,
  }
  if (actionType && currentPage) {
    switch (actionType) {
      case 'REST_ALL_SEARCHINVENTORY_SAMPLES':
        {
          currentSelectedData =
            updatedState[currentPage]?.selectedData?.filter(
              (obj) => obj?.itemType !== 'SAMPLE'
            ) || []
          currentNumSelected = currentSelectedData?.length || Number(0)
          currentNumSelectedTab = Object.assign(
            {},
            updatedState[currentPage]?.numSelectedTab,
            { SAMPLES: 0 }
          )
        }
        break
      case 'REST_ALL_SEARCHINVENTORY_PROPS':
        {
          currentSelectedData =
            updatedState[currentPage]?.selectedData?.filter(
              (obj) => obj?.itemType !== 'PROPS'
            ) || []
          currentNumSelected = currentSelectedData?.length || Number(0)
          currentNumSelectedTab = Object.assign(
            {},
            updatedState[currentPage]?.numSelectedTab,
            { PROPS: Number(0) }
          )
        }
        break
      case 'REST_ALL_SEARCHINVENTORY_SCENIC':
        {
          currentSelectedData =
            updatedState[currentPage]?.selectedData?.filter(
              (obj) => obj?.itemType !== 'SCENIC'
            ) || []
          currentNumSelected = currentSelectedData?.length || Number(0)
          currentNumSelectedTab = Object.assign(
            {},
            updatedState[currentPage]?.numSelectedTab,
            { SCENIC: Number(0) }
          )
        }
        break
      default:
        {
          currentSelectedData = appInitState[currentPage]?.selectedData || []
          currentNumSelected =
            appInitState[currentPage]?.numSelected || Number(0)
          currentNumSelectedTab =
            appInitState[currentPage]?.numSelectedTab || {}
        }
        break
    }
    const currentPageState = {
      ...updatedState[currentPage],
      selectedData: currentSelectedData,
      numSelected: currentNumSelected,
    }
    const currentPageUpdatedState =
      actionType?.indexOf('SEARCHINVENTORY') !== -1
        ? Object.assign({}, currentPageState, {
            numSelectedTab: currentNumSelectedTab,
          })
        : currentPageState
    updatedState = {
      ...updatedState,
      [currentPage]: currentPageUpdatedState,
    }
  }
  return updatedState
}

export const isSessionActive = (props) => {
  const { auth } = props
  const { session = {} } = auth
  const { userInfo = {} } = session || {}
  const { lanId = '' } = userInfo || {}
  const domain = getDomainName(window.location.hostname)

  if (domain === 'partnersonline.com') {
    //Check Single Sign On only for POL
    const ssoSessionInfo = Cookies.get('SSOSESSIONINFO') //oAuth will set this cookie
    let logOutUrl = '/'

    if (ssoSessionInfo) {
      const ssoSessionObj = JSON.parse(window.atob(ssoSessionInfo)) //Decode session and parse to json object
      if (
        ssoSessionObj.user &&
        ssoSessionObj.login_status &&
        lanId &&
        ssoSessionObj.user === lanId
      ) {
        //Valid only when user mathces and login status is true
        return true
      }
    } else {
      const locationHref = window.location.href
      logOutUrl = encodeURI(
        apiConfig.auth.logoutUrl + '?TARGET=' + locationHref
      )
    }
    //Invalid case
    localStorage.removeItem('access_token') //Remove token from LS will trigger the login route
    localStorage.removeItem('id_token')

    // toast.info(' POL session logged out or company changed.')
    setTimeout(() => {
      if (logOutUrl) {
        window.location.replace(logOutUrl) //Redirect to home page to login again
      }
    }, 3000)
    return false
  }
  return true
}

export const sideNavMenuList = [
  {
    name: 'Requested Samples',
    path: '/orderSummary',
    reload: false,
    iconName: 'DashboardIcon',
    groups: [],
  },
  {
    name: 'Sample History',
    path: '/shipmentSummary',
    reload: false,
    iconName: 'HistoryIcon',
    groups: [],
  },
]
