export const PENDING_SHIP_SAMPLES_FAILURE = 'PENDING_SHIP_SAMPLES_FAILURE'
export const PENDING_SHIP_SAMPLES_PENDING = 'PENDING_SHIP_SAMPLES_PENDING'
export const PENDING_SHIP_SAMPLES_SUCCESS = 'PENDING_SHIP_SAMPLES_SUCCESS'
export const SHIPPED_SHIP_SAMPLES_FAILURE = 'SHIPPED_SHIP_SAMPLES_FAILURE'
export const SHIPPED_SHIP_SAMPLES_PENDING = 'SHIPPED_SHIP_SAMPLES_PENDING'
export const SHIPPED_SHIP_SAMPLES_SUCCESS = 'SHIPPED_SHIP_SAMPLES_SUCCESS'
export const SHIPMENTS_TABLE_FILTER_VALUE = 'SHIPMENTS_TABLE_FILTER_VALUE'
export const SHIPMENTS_TABLE_SHIPPED_FILTER_VALUE =
  'SHIPMENTS_TABLE_SHIPPED_FILTER_VALUE'
export const SHIPMENTS_SET_TAB_VALUE = 'SHIPMENTS_SET_TAB_VALUE'

export const FETCH_PARTNERS_REQUEST_PENDING = 'FETCH_PARTNERS_REQUEST_PENDING'
export const FETCH_PARTNERS_REQUEST_SUCCESS = 'FETCH_PARTNERS_REQUEST_SUCCESS'
export const FETCH_PARTNERS_REQUEST_FAILURE = 'FETCH_PARTNERS_REQUEST_FAILURE'
export const SHIPMENTS_SET_TAB_ATTRIBUTES = 'SHIPMENTS_SET_TAB_ATTRIBUTES'
