export const sampleToBarcodeRequest = (sample) => {
  return {
    pegasus_id: sample?.pegasusId,
    tcin: sample?.tcin || '',
    dpci: sample?.dpci || '',
    destination: sample?.shipToLocToPrint || sample?.shipToLoc || '',
  }
}

export const propToBarcodeRequest = (sample) => {
  return {
    pegasus_id: sample.pegasusId,
    description: sample.propDescription,
    category: sample.propCategoryName,
    subCategory: sample.propSubcategoryName,
    color_family: sample.propColorFamily,
    dimension: sample.propSizeView,
    retailer: sample.propRetailer,
    brand: sample.propBrand?.label,
    destination: '',
  }
}

export const scenicToBarcodeRequest = (sample) => {
  return {
    pegasus_id: sample?.pegasusId,
    description: sample?.propDescription,
    category: sample?.propCategoryName,
    subCategory: sample?.propSubcategoryName,
    color_family: sample?.propColorFamily,
    dimension: sample?.propSizeView,
    brand: sample.propBrand?.label,
    destination: '',
  }
}

export const samplesToBarcodeRequest = (samples = []) =>
  samples.map((sample) => sampleToBarcodeRequest(sample))

export const propsToBarcodeRequest = (samples = []) =>
  samples.map((sample) => propToBarcodeRequest(sample))

export const scenicToBarcodeRequests = (samples = []) =>
  samples.map((sample) => scenicToBarcodeRequest(sample))
