import { PureComponent } from 'react'
import * as PropTypes from 'prop-types'

class NodeContentModule extends PureComponent {
  onTransitionForward = (action, callback) => {
    const { onTransitionForward, setActions } = this.props
    onTransitionForward(setActions(action, callback))
  }

  render() {
    const { description } = this.props

    return <div>{description}</div>
  }
}

NodeContentModule.defaultProps = {
  description: '',
  value: () => Promise.resolve(null),
  onChange: () => {},
  onTransitionForward: () => {},
  setActions: () => {},
}

NodeContentModule.propTypes = {
  description: PropTypes.string,
  paths: PropTypes.array,
  onChange: PropTypes.func,
  setActions: PropTypes.func,
  value: PropTypes.func,
  onTransitionForward: PropTypes.func,
}

export default NodeContentModule
