export const projectTabLabels = [
  { label: 'CURRENT', value: 'PENDING' },
  { label: 'COMPLETED', value: 'COMPLETED' },
]

export const defaultProjectsTab = 'PENDING'

export const STATUS_COMPLETED = 'COMPLETED'
export const STATUS_CANCELLED = 'CANCELLED'
export const STATUS_CURRENT = 'CURRENT'
export const STATUS_ACTIVE = 'ACTIVE'
export const collectionTabLabels = [
  { label: STATUS_CURRENT, value: '' },
  { label: STATUS_COMPLETED, value: STATUS_COMPLETED },
  { label: STATUS_CANCELLED, value: STATUS_CANCELLED },
]

export const STATUS_SHIPPED = 'SHIPPED'
export const STATUS_ARCHIVED = 'ARCHIVED'
export const STATUS_PENDING = 'PENDING'
export const shipmentTabLabels = [
  { label: STATUS_PENDING, value: STATUS_PENDING },
  { label: STATUS_SHIPPED, value: STATUS_SHIPPED },
  { label: STATUS_ARCHIVED, value: STATUS_ARCHIVED },
]
export const defaultShipmentsTab = STATUS_PENDING
export const defaultScenicRoomsTab = STATUS_ACTIVE

export const defaultCollectionsTab = ''

export const orderPageTabLabels = [
  { label: 'READY TO ORDER', value: 'READYTOORDER' },
  { label: 'MANUAL ORDER', value: 'SEARCH' },
]
export const nonIDSOrderPageTabLabels = [{ label: 'SEARCH', value: 'SEARCH' }]

export const defaultOrderPageTab = 'READYTOORDER'
export const nonIDSDefOrderPageTab = 'SEARCH'

// Project details
export const ORDERED = 'ORDERED'
export const REQUESTED = 'REQUESTED'
export const projectDetailsTabs = [
  { label: 'SAMPLES INVENTORY', value: ORDERED },
  { label: 'REQUESTED SAMPLES', value: REQUESTED },
]
export const projectDetailsTabsNonPP = [
  { label: 'SAMPLES INVENTORY', value: ORDERED },
]
export const defaultProjectsDetailsTab = ORDERED

// Search Page
export const SAMPLE = 'SAMPLE'
export const SAMPLES = 'SAMPLES'
export const PROPS = 'PROPS'
export const PROP = 'PROP'
export const SCENIC = 'SCENIC'
export const searchPageTabs = [
  { label: 'SAMPLES', value: SAMPLES },
  { label: 'PROPS', value: PROPS },
  { label: 'SCENIC', value: SCENIC },
]
