export const PROJECTS_REQUEST_PENDING = 'PROJECTS_REQUEST_PENDING'
export const PROJECTS_REQUEST_SUCCESS = 'PROJECTS_REQUEST_SUCCESS'
export const PROJECTS_REQUEST_FAILURE = 'PROJECTS_REQUEST_FAILURE'
export const BUILDINGS_REQUEST_PENDING = 'BUILDINGS_REQUEST_PENDING'
export const BUILDINGS_REQUEST_FAILURE = 'BUILDINGS_REQUEST_FAILURE'
export const BUILDINGS_REQUEST_SUCCESS = 'BUILDINGS_REQUEST_SUCCESS'
export const PROJECTS_SET_FILTER = 'PROJECTS_SET_FILTER'
export const PROJECTS_CLEAR_FILTER = 'PROJECTS_CLEAR_FILTER'
export const PROJECTS_SHOW_CREATE_POPUP = 'PROJECTS_SHOW_CREATE_POPUP'
export const PROJECTS_CLOSE_CREATE_POPUP = 'PROJECTS_CLOSE_CREATE_POPUP'
export const PROJECTS_UPDATE_CREATE_FIELD = 'PROJECTS_UPDATE_CREATE_FIELD'
export const PROJECT_GET_SAMPLES_PENDING = 'PROJECT_GET_SAMPLES_PENDING'
export const PROJECT_GET_SAMPLES_SUCCESS = 'PROJECT_GET_SAMPLES_SUCCESS'
export const PROJECT_GET_SAMPLES_FAILURE = 'PROJECT_GET_SAMPLES_FAILURE'
export const CURRENT_PROJECT_REQUEST_SUCCESS = 'CURRENT_PROJECT_REQUEST_SUCCESS'
export const SET_TABVALUE = 'SET_TABVALUE'
export const SET_SAMPLE_PROJECTS_VALUE = 'SET_SAMPLE_PROJECTS_VALUE'
export const SET_PROJECTS_SEARCH_VALUE = 'SET_PROJECTS_SEARCH_VALUE'
export const SET_REQUEST_SAMPLES_STATUS = 'SET_REQUEST_SAMPLES_STATUS'
export const EDIT_FLYOUT_OPEN = 'EDIT_FLYOUT_OPEN'
export const EDIT_UPDATE_SUCCESS = 'EDIT_UPDATE_SUCCESS'
