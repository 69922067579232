import ReactHtmlParser from 'react-html-parser'
import { DATE_FORMAT, convertTableDateFormat } from '../util/CommonUtils'

export const itemsResponseToItems = (items = []) => {
  return items.map((item) => itemResponseToItem(item))
}

export const itemResponseToItem = (item) => {
  return {
    barcodeNumber: item.barcode_number || '',
    classId: item.class_id || '',
    className: item.class_name || '',
    color: item.color || '',
    countryOfOrigin: item.country_of_origin,
    departmentId: item.department_id || '',
    subClassId: item.sub_class_id || '',
    subClassName: item.sub_class_name || '',
    departmentName: item.department_name || '',
    dpci: item.dpci || '',
    description: ReactHtmlParser(item.general_description || '')[0],
    imageUrl: item.image_url || '',
    launchDate: convertTableDateFormat(item.launch_date, DATE_FORMAT) || '',
    lifecycleState: item.lifecycle_state || '',
    manufacturerBrand: item.manufacturer_brand || '',
    manufacturerStyle: item.manufacturer_style || '',
    intendedSellingChannels:
      (item.intended_selling_channels || []).join(',') || '',
    childTcins:
      relationshipFields(item.relationships) !== null
        ? relationshipFields(item.relationships).child_details
        : '',
    parentTcins:
      relationshipFields(item.relationships) !== null
        ? relationshipFields(item.relationships).parent_identifiers.tcins.join(
            ', '
          )
        : '',
    isParent:
      relationshipFields(item.relationships) !== null
        ? relationshipFields(item.relationships).is_parent
        : '',
    relationshipType:
      relationshipFields(item.relationships) !== null
        ? relationshipFields(item.relationships).type.name
        : '',
    size: item.size || '',
    tcin: item.tcin || '',
    vendorId: item.vendor_id || null,
    vendorName: item.vendor_name || '',
    brandId: item.brand_id || null,
    isOwnedBrand: item.is_owned_brand,
  }
}

export function relationshipFields(relationships = []) {
  if (
    relationships.findIndex(
      (item) => item.is_parent || item.is_parent === false
    ) >= 0
  ) {
    return relationships[
      relationships.findIndex(
        (item) => item.is_parent || item.is_parent === false
      )
    ]
  } else {
    return null
  }
}
