import { Button, SwipeableDrawer, Tooltip } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useDispatch } from 'react-redux'

/**
 * @param {Children} React child component
 * @param {rest} additonalprops
 * @param {actionType} action to reset selection
 * @param {showClearAll} flag to show clearall button
 * @returns React component with SwipeableDrawer
 *
 */

function BottomBar({
  children,
  showClearAll = false,
  actionType = '',
  onClearAllClick = () => {},
  ...rest
}) {
  const dispatch = useDispatch()
  const resetSelection = () => {
    if (actionType) {
      dispatch({ type: actionType })
    }
  }
  const ClearAllButton = () => (
    <Tooltip title="Clear all selections">
      <Button
        id={`${'clearAllSelection_' + actionType}`}
        data-id={`${'clearAllSelection_' + actionType}`}
        style={{
          margin: '0px 8px',
          color: 'white',
          position: 'absolute',
          bottom: '14px',
          right: '10px',
        }}
        startIcon={<HighlightOffIcon />}
        onClick={actionType ? resetSelection : onClearAllClick}
      >
        {''}
      </Button>
    </Tooltip>
  )
  return (
    <>
      <SwipeableDrawer {...rest} id={`${'bottomBar_' + actionType}`}>
        {children}
        {showClearAll && <ClearAllButton />}
      </SwipeableDrawer>
    </>
  )
}

export default BottomBar
