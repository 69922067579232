import Button from '@material-ui/core/Button/Button'
import FlexibleTable from '../../components/EnhancedTable/FlexibleTable'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import * as propTypes from 'prop-types'
import withHOCs from '../../util/withHocs'
import { isEmpty, isEqual, remove } from 'lodash'
import {
  getPendingShootShipment,
  getShippedShootShipment,
  savePendingFilterValue,
  saveShippedFilterValue,
  saveShipmentsTabValue,
  saveShipmentsTabsAttributes,
  getPartners,
} from '../../store/shipSamples/actionCreator'
import {
  getShippedShipments,
  getPendingShipments,
  getPartnersList,
  getShipToLocationList,
} from '../../store/shipSamples/selectors'
import {
  createShootShipment,
  getShipToLocation,
  getShootShipment,
  setRedirectToNewShipment,
} from '../../store/newShipment/actionCreator'
import {
  setHeaderTitle,
  setHeaderTitleArray,
} from '../../store/layout/actionCreator'
import { userNamesResponseToSelect } from 'mappers/inventoryMapper'
import { getAllUsers } from 'services/inventoryService'
import { HelpOutline } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import { defaultShipmentsTab, shipmentTabLabels } from '../../enums/Tabs'
import CustomTabs from '../../components/Tabs/Tabs'
import { Grid } from '@mui/material'
import ShipmentFilters from './ShipmentFilters'
import ShipSamplesContext from './ShipSamplesContext'
import apiConfig from 'apiConfig'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'grid',
    overflowX: 'auto',
  },
  pageHeader: {
    width: '100%',
    marginTop: '30px',
    height: '40px',
    position: 'relative',
  },
  pageHeaderText: {
    display: 'inline',
    fontSize: '34px',
    position: 'absolute',
    top: -5,
    left: 0,
  },
  pageHeaderButton: {
    display: 'inline',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  paper: {
    backgroundColor: '#F7F7F7',
  },
  tableHeader: {
    fontSize: 'x-large',
    textAlign: 'left',
  },
  shipSamplesPagePagination: {
    overflowX: 'auto',
  },
  textField: {
    width: '50%',
  },
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '284px',
    padding: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
  },
})

// Use this when the Praxis Bug that fires ComponentDidMount excessively is fixed
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />
// })

class ShipSamplesPage extends React.Component {
  constructor(props) {
    super(props)
    const { tabValue = '', tabBasedAttributes = {} } = props
    const currentTabValue = tabValue ?? defaultShipmentsTab ?? ''
    this.state = {
      checkAll: true,
      rawText: '',
      tooltipIsOpen: false,
      currentTabValue: currentTabValue,
      tabBasedAttributes: tabBasedAttributes || {},
      locations: [],
      users: [],
    }
  }

  async componentDidMount() {
    const {
      setHeaderTitle = () => {},
      setHeaderTitleArray = () => {},
      getPartners = () => {},
      getShipToLocation = () => {},
    } = this.props
    setHeaderTitle('Pegasus   >   Ship Samples')
    setHeaderTitleArray([{ title: 'Ship Samples', link: '/shipSamples' }])
    this.getShipmentData()
    this.getUsersList()
    getShipToLocation()
    getPartners() // Get partners for filters
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentTabValue = '', tabBasedAttributes = {} } = this.state
    const {
      currentTabValue: prevCurrentTabValue = '',
      tabBasedAttributes: preTabBasedAttributes = {},
    } = prevState
    const {
      page = Number(1),
      rowsPerPage = 10,
      selFilterValues = [],
    } = tabBasedAttributes[currentTabValue] || {}

    const {
      page: prevPage = Number(1),
      rowsPerPage: prevRowsPerPage = 10,
      selFilterValues: prevSelFilterValues = [],
    } = preTabBasedAttributes[prevCurrentTabValue] || {}

    if (!isEqual(currentTabValue, prevCurrentTabValue)) {
      this.getShipmentData()
    }

    if (
      currentTabValue !== 'PENDING' &&
      !isEqual(selFilterValues, prevSelFilterValues) &&
      page > 1
    ) {
      this.setState({
        tabBasedAttributes: this.formatTabAttributes('page', Number(1)),
      })
    }

    if (
      !isEqual(prevPage, page) ||
      !isEqual(prevRowsPerPage, rowsPerPage) ||
      (currentTabValue !== 'PENDING' &&
        !isEqual(selFilterValues, prevSelFilterValues) &&
        page === Number(1))
    ) {
      this.getShipmentData()
    }
  }

  getShipmentData = () => {
    const { currentTabValue = '', tabBasedAttributes = {} } = this.state
    const {
      page = Number(1),
      rowsPerPage = 10,
      selFilterValues = [],
    } = tabBasedAttributes[currentTabValue] || {}
    const {
      getPendingShootShipment = () => {},
      getShippedShootShipment = () => {},
    } = this.props
    currentTabValue === 'PENDING'
      ? getPendingShootShipment()
      : getShippedShootShipment({
          status: currentTabValue,
          page,
          rowsPerPage,
          filters: selFilterValues,
        })
  }

  getUsersList = async () => {
    const usersResponse = await getAllUsers()
    const allUsers = userNamesResponseToSelect(usersResponse)
    this.setState({
      users: allUsers || [],
    })
  }

  onSelected = (selectedData, selected) => {
    return selectedData.indexOf(selected) !== -1
  }

  handleNewShipment(e) {
    this.props.navigate(`/shipSamplesList/create`)
  }

  handleTooltipOpen = () => {
    this.setState({ tooltipIsOpen: !this.state.tooltipIsOpen })
    this.autoCloseTimer = setTimeout(this.handleTooltipClose, 10000)
  }

  handleTooltipClose = () => {
    this.setState({ tooltipIsOpen: false })
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer)
    }
  }

  editPendingShipment(shipmentData = {}) {
    const { shipmentId = '', status = '' } = shipmentData
    if (!isEmpty(String(shipmentId))) {
      const subPage = status === 'SHIPPED' ? 'view' : 'edit'
      this.props.navigate(`/shipSamplesList/${shipmentId}/${subPage}`)
    }
  }

  saveFilterValue = (filterValue = '') => {
    const { savePendingFilterValue = () => {} } = this.props
    savePendingFilterValue(filterValue)
  }

  saveFilterValueShipped = (filterValue = '') => {
    const { saveShippedFilterValue = () => {} } = this.props
    saveShippedFilterValue(filterValue)
  }

  handleViewDetails = (shipmentData) => {
    const { shipmentId = '' } = shipmentData
    if (!isEmpty(String(shipmentId))) {
      // this.props.history.push(`/shipSamples/${shipmentId}/view`)
      this.props.navigate(`/shipSamplesList/${shipmentId}/view`)
    }
  }

  componentWillUnmount() {
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer)
    }
    const locationHref = window.location.href
    if (locationHref.indexOf('shipSamples') === -1) {
      this.saveFilterValue('')
      this.saveFilterValueShipped('')
    }
    const { tabBasedAttributes = {} } = this.state
    this.props?.saveShipmentsTabsAttributes(tabBasedAttributes)
  }

  setCurrentTabValue = (currentTab = '') => {
    const { saveShipmentsTabValue = () => {} } = this.props
    this.setState({
      currentTabValue: currentTab,
    })
    saveShipmentsTabValue(currentTab)
  }

  setSelectedFilters = (selFilterValues) => {
    this.setState((prevState) => {
      return {
        tabBasedAttributes: this.formatTabAttributes(
          'selFilterValues',
          selFilterValues
        ),
      }
    })
  }

  getTabBasedData = () => {
    const { currentTabValue = '' } = this.state
    const {
      shippedColumnData,
      pendingColumnData,
      pendingShipments = [],
      shippedShipments = [],
      shippedShipmentPending = false,
      pendingShipmentPending = false,
      filterValueShipped = '',
      filterValue = '',
      auth = {},
    } = this.props
    const { isAuthorized = () => {} } = auth
    let localColumnData =
      currentTabValue === 'PENDING'
        ? [...pendingColumnData]
        : [...shippedColumnData]
    let updatedColumn = this.getUpdatedColumn(
      [...localColumnData],
      currentTabValue
    )
    switch (currentTabValue) {
      case 'PENDING': {
        return {
          shippedDataFieldKeys: updatedColumn?.map((column) => column.id),
          columnData: updatedColumn,
          shipmentDataList: pendingShipments,
          showPopupFun: this.editPendingShipment.bind(this),
          rowButtonName: 'OPEN',
          loadingStatus: pendingShipmentPending,
          saveFilterFun: this.saveFilterValue,
          savedSearchValue: filterValue,
        }
      }
      default: {
        return {
          shippedDataFieldKeys: updatedColumn?.map((column) => column.id),
          columnData: updatedColumn,
          shipmentDataList: shippedShipments,
          showPopupFun: this.handleViewDetails,
          rowButtonName: 'VIEW DETAILS',
          loadingStatus: shippedShipmentPending,
          saveFilterFun: this.saveFilterValueShipped,
          savedSearchValue: filterValueShipped,
        }
      }
    }
  }

  getUpdatedColumn = (columnData = [], currentTabValue = '') => {
    let localColumnData = [...columnData]
    const { auth = {} } = this.props
    const { isAuthorized = () => {} } = auth
    if (isAuthorized(apiConfig.roles.scenic)) {
      if (!isAuthorized(apiConfig.roles.admin)) {
        remove(localColumnData, function (currentObject) {
          return currentObject.id === 'propCount'
        })
        remove(localColumnData, function (currentObject) {
          return currentObject.id === 'sampleCount'
        })
      }
      currentTabValue === 'PENDING'
        ? localColumnData.push({
            id: 'scenicCount',
            numeric: false,
            disablePadding: false,
            label: '#Scenic',
            textAlign: 'right',
            type: '',
          })
        : localColumnData.push({
            id: 'scenicCount',
            numeric: false,
            disablePadding: false,
            label: 'Scenic Count',
            textAlign: 'right',
            type: '',
          })
    }
    return localColumnData
  }

  formatTabAttributes = (key, value) => {
    const { tabBasedAttributes = {}, currentTabValue } = this.state
    const tabBasedAttributesCopy = Object.assign({}, tabBasedAttributes)
    const previousValue = tabBasedAttributesCopy[currentTabValue]
    tabBasedAttributesCopy[currentTabValue] = {}
    tabBasedAttributesCopy[currentTabValue] = Object.assign({}, previousValue, {
      [key]: value,
    })
    return tabBasedAttributesCopy
  }
  setPageAndRefresh = (page) => {
    this.setState({
      tabBasedAttributes: this.formatTabAttributes('page', page),
    })
  }

  setRowsPerPageAndRefresh = (rowsPerPage) => {
    this.setState({
      tabBasedAttributes: this.formatTabAttributes('rowsPerPage', rowsPerPage),
    })
  }

  renderTabsContent = () => {
    const { onClick, selectedData, totalCount = Number(0) } = this.props
    const { currentTabValue = '', tabBasedAttributes = {} } = this.state
    const { page = Number(1), rowsPerPage = 10 } =
      tabBasedAttributes[currentTabValue] || {}
    const {
      columnData = [],
      shippedDataFieldKeys = [],
      shipmentDataList = [],
      showPopupFun = () => {},
      rowButtonName = '',
      loadingStatus = '',
      saveFilterFun = () => {},
      savedSearchValue = '',
    } = this.getTabBasedData()
    return (
      <Grid container>
        <Grid container item style={{ marginTop: 20, marginLeft: 20 }}>
          {currentTabValue !== 'PENDING' && <ShipmentFilters />}
        </Grid>
        <Grid item xs={12}>
          <FlexibleTable
            cascadeFieldKeys={[]}
            checkBoxEnabled={false}
            columnData={columnData}
            data={shipmentDataList}
            dataFieldKeys={shippedDataFieldKeys}
            editEnabled={false}
            enableSearch
            onClick={onClick}
            selected={selectedData}
            showPopup={showPopupFun}
            disableItemCount={true}
            paginationAtApiLevel={currentTabValue !== 'PENDING' ? true : false}
            clickableRowButton={rowButtonName}
            clickableRowButtonColor="#333333"
            isLoading={loadingStatus}
            saveFilterValue={saveFilterFun}
            savedSearchValue={savedSearchValue}
            enableMultiSearch
            page={page}
            rowsPerPage={rowsPerPage}
            totalRowsCount={totalCount}
            setPage={this.setPageAndRefresh}
            setRowsPerPage={this.setRowsPerPageAndRefresh}
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes, partnersList = [], shipToLocations = [] } = this.props
    const {
      currentTabValue = '',
      tabBasedAttributes = {},
      users = [],
    } = this.state
    const { selFilterValues } = tabBasedAttributes[currentTabValue] || {}
    const tooltipTitle =
      'By creating a "New Shipment" you can gather samples' +
      ' to mark as "Pending Shipment". You may choose to ship immediately or ' +
      'save the shipment for later.'

    return (
      <div style={{ margin: '0px 28px' }}>
        <div className={classes.pageHeader}>
          <div className={classes.pageHeaderText}>
            <span>Shipments</span>
          </div>
          <div className={classes.pageHeaderButton}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={tooltipTitle}
              onClose={this.handleTooltipClose}
              open={this.state.tooltipIsOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <HelpOutline
                style={{ transform: 'translateY(30%)', marginRight: '14px' }}
                onClick={this.handleTooltipOpen}
              />
            </Tooltip>
            <Button
              id="newShipment"
              color="primary"
              style={{ width: '182px' }}
              type="button"
              variant="contained"
              onClick={(e) => this.handleNewShipment(e)}
            >
              New Shipment
            </Button>
          </div>
        </div>
        <Paper className={classes.root}>
          <ShipSamplesContext.Provider
            value={{
              currentTabValue,
              filtersOptions: {
                locations: shipToLocations,
                users,
                partners: partnersList,
              },
              filterValues: selFilterValues,
              setSelectedFilters: this.setSelectedFilters,
            }}
          >
            <CustomTabs
              tabLabels={shipmentTabLabels}
              renderTabsContent={this.renderTabsContent}
              setCurrentTabValue={this.setCurrentTabValue}
              defaultTab={currentTabValue}
              showTabsPanel={false}
            />
          </ShipSamplesContext.Provider>
        </Paper>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    shipSamples: {
      numSelected = Number(0),
      onClick = () => {},
      pendingColumnData = [],
      shippedColumnData = [],
      selectedData = [],
      shippedShipmentPending = false,
      pendingShipmentPending = false,
      filterValue = '',
      filterValueShipped = '',
      tabValue = '',
      partners = [],
      totalCount = Number(0),
      tabBasedAttributes = {},
    },
  } = state
  return {
    numSelected,
    onClick: onClick,
    pendingColumnData,
    pendingShipments: getPendingShipments(state),
    shippedColumnData,
    shippedShipments: getShippedShipments(state),
    selectedData,
    shippedShipmentPending,
    pendingShipmentPending,
    filterValue,
    filterValueShipped,
    tabValue,
    partners,
    partnersList: getPartnersList(state),
    shipToLocations: getShipToLocationList(state),
    totalCount,
    tabBasedAttributes,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPendingShootShipment: () => dispatch(getPendingShootShipment()),
    getShippedShootShipment: (payload = {}) =>
      dispatch(getShippedShootShipment(payload)),
    createShootShipment: () => dispatch(createShootShipment()),
    getShootShipment: (data) => dispatch(getShootShipment(data)),
    setHeaderTitle: (headerTitle) => dispatch(setHeaderTitle(headerTitle)),
    setHeaderTitleArray: (headerTitleArray) =>
      dispatch(setHeaderTitleArray(headerTitleArray)),
    setRedirectToNewShipment: (flag) =>
      dispatch(setRedirectToNewShipment(flag)),
    savePendingFilterValue: (filterValue = '') =>
      dispatch(savePendingFilterValue(filterValue)),
    saveShippedFilterValue: (filterValue = '') =>
      dispatch(saveShippedFilterValue(filterValue)),
    saveShipmentsTabValue: (tabValue = '') =>
      dispatch(saveShipmentsTabValue(tabValue)),
    saveShipmentsTabsAttributes: (tabBasedAttributes = {}) =>
      dispatch(saveShipmentsTabsAttributes(tabBasedAttributes)),
    getPartners: () => dispatch(getPartners()),
    getShipToLocation: () => dispatch(getShipToLocation()),
  }
}

ShipSamplesPage.propTypes = {
  data: propTypes.array,
  numSelected: propTypes.number,
  onClick: propTypes.func,
  pendingColumnData: propTypes.array,
  shippedColumnData: propTypes.array,
}

export default withHOCs({
  auth: true,
  nav: true,
  styles,
  connectParams: {
    mapStateToProps,
    mapDispatchToProps,
  },
})(ShipSamplesPage)
