import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ShowIf from 'components/ShowIf'
import PartnerContext from './PartnerContext'

function ShipmentPartners({
  allPartnersList = [],
  partnersList,
  partnerType,
  partnerNames,
  showPartnerInputFields,
  partners,
  updateShowHidePartnerInputFields,
  selectedPartnerType,
  classes,
  isReadOnly,
}) {
  const existingPartners = partners && partners.length > 0
  return (
    <PartnerContext.Provider
      value={{
        allPartnersList,
        partners,
        updateShowHidePartnerInputFields,
        selectedPartnerType,
        classes,
      }}
    >
      <>
        <span
          style={{
            fontSize: isReadOnly ? '12px' : '14px',
            color: '#333333',
            padding: '8px',
            lineHeight: '14px',
            marginBottom: isReadOnly ? '16px' : 0,
          }}
        >
          Partners
        </span>
        <Grid container>
          <ShowIf condition={existingPartners}>
            <Grid item xs={partners?.length * 2}>
              {partnersList}
            </Grid>
          </ShowIf>
          <ShowIf condition={!showPartnerInputFields}>
            <Grid
              item
              xs={2}
              style={{
                paddingLeft: `8px`,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => updateShowHidePartnerInputFields(true)}
              >
                Add Partners
              </Button>
            </Grid>
          </ShowIf>
          <ShowIf condition={showPartnerInputFields}>
            <Grid container>
              <Grid
                item
                xs={2}
                style={{
                  paddingTop: '16px',
                }}
              >
                {partnerType}
              </Grid>
              <Grid item xs={5} style={{ padding: '16px 0 0 16px' }}>
                {partnerNames}
              </Grid>
            </Grid>
          </ShowIf>
        </Grid>
      </>
    </PartnerContext.Provider>
  )
}

export default ShipmentPartners
