import * as types from './actionType'
import * as locationAPI from '../../services/locationService'
import * as userAPI from '../../services/userService'
import { showNotification } from '../../store/notification/actionCreator'
import {
  fetchFilterSuccess,
  fetchFilterPending,
  fetchFilterFailure,
} from '../../store/manageLocations/actionCreator'

export function findCurrentUserLocation(
  userId = '',
  level = '',
  initLevel = '',
  componentType = ''
) {
  return (dispatch) => {
    dispatch(findCurrentUserLocationPending(true))
    userAPI
      .getUser(userId)
      .then((user = {}) => {
        const {
          data: { default_location = '' },
        } = user
        dispatch(
          findCurrentUserLocationDetails(
            default_location,
            level,
            initLevel,
            componentType
          )
        )
      })
      .catch((error = {}) => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while searching user details'
          )
        )
      })
      .finally(() => {
        dispatch(findCurrentUserLocationPending(false))
      })
  }
}

export function adminAddNewShipToLocation(params, callBack = () => {}) {
  return (dispatch) => {
    try {
      locationAPI
        .addNewShipToLocation(params)
        .then((response = {}) => {
          callBack()
        })
        .catch((error) => {
          const {
            response: { status = '', data = '' },
          } = error
          if (
            status === 500 &&
            data.startsWith(`Given type 'C' studio location already exist`)
          ) {
            dispatch(showNotification(true, 'Studio location already exists'))
          }
        })
    } catch {
      dispatch(
        showNotification(
          true,
          'We encountered an error while adding the locations'
        )
      )
    }
  }
}

export function findCurrentUserLocationDetails(
  defaultLocation = '',
  level = '',
  initLevel = '',
  componentType = ''
) {
  return (dispatch) => {
    dispatch(findCurrentUserLocationPending(true))
    locationAPI
      .findLocationByBuilding({ building: defaultLocation })
      .then((response = {}) => {
        const {
          data: { location_id = '' },
        } = response
        dispatch(
          findSubLocationById(
            {},
            location_id,
            level,
            initLevel,
            defaultLocation,
            componentType
          )
        )
        dispatch(findCurrentUserLocationPending(false))
      })
      .catch((error = {}) => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while loading the locations'
          )
        )
      })
      .finally(() => {
        dispatch(findCurrentUserLocationPending(false))
      })
  }
}

export function createANewLocation(params = {}, componentType = '') {
  return (dispatch) => {
    dispatch(createANewLocationPending(true))
    return locationAPI
      .createANewLocation(params)
      .then((response = {}) => {
        const { data: responseData = {} } = response
        dispatch(createANewLocationSuccess(responseData, componentType))
      })
      .catch((error = {}) => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while creating the new location'
          )
        )
      })
      .finally(() => {
        dispatch(createANewLocationPending(false))
      })
  }
}

export function printNewLocationLabel(
  params = [],
  callBack = () => {},
  printType = ''
) {
  return (dispatch) => {
    dispatch(createANewLocationPending(true))
    return locationAPI
      .locationPrintLabel(params, printType)
      .then((response = {}) => {
        const { data: responseData = {} } = response
        callBack(responseData)
        // dispatch(createANewLocationSuccess(responseData))
      })
      .catch((error = {}) => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while creating the new location'
          )
        )
      })
      .finally(() => {
        dispatch(createANewLocationPending(false))
      })
  }
}

export function findSubLocationById(
  selectedOption = {},
  locationId = '',
  level = '',
  initLevel = '',
  locationLabel = '',
  componentType = '',
  callBack = () => {}
) {
  return (dispatch) => {
    dispatch(findCurrentUserLocationPending(true))
    return locationAPI
      .fetchSubLocationById(locationId)
      .then((response = {}) => {
        const { data: locationsList = [] } = response
        let locationsListCopy = [...locationsList]
        if (level === 'container' && componentType === 'new') {
          locationsListCopy = [
            { location_id: 'NEW', container: 'Container-NEW' },
          ]
        }
        dispatch(
          findSubLocationByIdSuccess(
            selectedOption,
            locationsListCopy,
            level,
            locationId,
            initLevel,
            locationLabel,
            componentType
          )
        )
        dispatch(findCurrentUserLocationPending(false))
        callBack()
      })
      .catch((error = {}) => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while fetch sub-location'
          )
        )
      })
      .finally(() => {
        dispatch(findCurrentUserLocationPending(false))
      })
  }
}

export function findSubLocationsByIdList(
  selectedOption = {},
  locationIdList = [],
  level = '',
  initLevel = '',
  locationLabels = [],
  componentType = '',
  callBack = () => {}
) {
  let locationIds = ''
  locationIds = locationIdList?.join()
  return (dispatch) => {
    dispatch(findCurrentUserLocationPending(true))
    return locationAPI
      .fetchSubLocationsByIdList(locationIds) // array to string for GET call
      .then((response = {}) => {
        const { data: locationsList = [] } = response
        dispatch(
          findSubLocationByIdSuccess(
            selectedOption,
            locationsList,
            level,
            locationIdList,
            initLevel,
            locationLabels,
            componentType
          )
        )
        dispatch(findCurrentUserLocationPending(false))
        callBack()
      })
      .catch((error = {}) => {
        dispatch(
          showNotification(
            true,
            'We encountered an error while fetch sub-location'
          )
        )
      })
      .finally(() => {
        dispatch(findCurrentUserLocationPending(false))
      })
  }
}

export function findSubLocationByIdSuccess(
  selectedOption = {},
  locationsList = [],
  level = '',
  locationId = '',
  initLevel = '',
  locationLabel = '',
  componentType = 'edit'
) {
  return {
    type: types.FIND_SUBLOCATION_BYID_SUCCESS,
    payload: {
      locations: locationsList,
      level: level,
      locationId: locationId,
      initLevel: initLevel,
      locationLabel: locationLabel,
      selectedOption: selectedOption,
      componentType: componentType,
    },
  }
}

export function fetchByLocationID(locationId = '') {
  return (dispatch) => {
    dispatch(fetchFilterPending(true))
    locationAPI
      .fetchSubLocationById(locationId)
      .then((filteredLocations = {}) => {
        dispatch(fetchFilterSuccess(filteredLocations))
      })
      .catch((error = {}) => {
        dispatch(fetchFilterFailure())
      })
      .finally(() => {
        dispatch(fetchFilterPending(false))
      })
  }
}

export function findCurrentUserLocationPending(pendingFlag = false) {
  return {
    type: types.FIND_CURRENT_USER_LOCATION_PENDING,
    payload: { pending: pendingFlag },
  }
}

export function createANewLocationSuccess(data = {}, componentType = '') {
  return {
    type: types.CREATE_A_NEW_LOCATION_SUCCESS,
    payload: { newLocationData: data, componentType: componentType },
  }
}

export function createANewLocationPending(pendingFlag = false) {
  return {
    type: types.CREATE_A_NEW_LOCATION_PENDING,
    payload: { createLocationPending: false },
  }
}

export function resetToInitState(componentType = '') {
  return {
    type: types.RESET_TO_INIT_STATE,
    payload: { componentType: componentType },
  }
}
