import * as barcodeApi from '../../services/barcodeService'
import * as barcodeMapper from '../../mappers/barcodeMapper'
import * as types from './actionType'
import * as shipmentApi from '../../services/shipmentService'
import * as shipmentMapper from '../../mappers/shipmentMapper'
import { submittedShipment } from '../orderSummary/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'

export function selectSort(orderBy = '', order = 'asc') {
  return {
    type: types.SHIPMENT_BARCODE_SELECT_SORT,
    payload: { orderBy: orderBy, order: order },
  }
}

export function showPopup(item) {
  return {
    type: types.SHIPMENT_BARCODE_SHOW_POPUP,
    payload: { item: item },
  }
}

export function closePopup() {
  return {
    type: types.SHIPMENT_BARCODE_CLOSE_POPUP,
  }
}

export function updateShipmentSummary(data = []) {
  return {
    type: types.SHIPMENT_BARCODE_SUBMIT_ORDER,
    payload: { data: data },
  }
}

export function setShipmentData(data = []) {
  const mappedData = shipmentMapper.orderDataToShipmentData(data)
  return {
    type: types.SHIPMENT_BARCODE_SET_DATA,
    payload: {
      data: mappedData,
      shipmentDestination: mappedData[0].shipToDestination || null,
    },
  }
}

export function setRowsPerPage(rowsPerPage = 20) {
  return {
    type: types.SHIPMENT_BARCODE_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function setPage(page = 0) {
  return {
    type: types.SHIPMENT_BARCODE_SET_PAGE,
    payload: { page: page },
  }
}

export function setTrackingNumber(event) {
  return {
    type: types.SHIPMENT_BARCODE_SET_TRACKING_NUMBER,
    payload: { event: event },
  }
}

export function clearTrackingInfo() {
  return {
    type: types.SHIPMENT_BARCODE_CLEAR_TRACKING_INFO,
  }
}

export function setCarrier(event) {
  return {
    type: types.SHIPMENT_BARCODE_SET_CARRIER,
    payload: { event: event },
  }
}

export function setVisibility(event) {
  return {
    type: types.SHIPMENT_BARCODE_SET_VISIBILITY,
    payload: { event: event },
  }
}

export function getBarcodeRequestPending(pending = false) {
  return {
    type: types.SHIPMENT_BARCODE_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function getBarcodeRequestFailure() {
  return {
    type: types.SHIPMENT_BARCODE_REQUEST_FAILURE,
  }
}

export function getBarcodeRequestSuccess(pdf) {
  return {
    type: types.SHIPMENT_BARCODE_REQUEST_SUCCESS,
    payload: { data: pdf },
  }
}

export function getBarcodes(
  samples = [],
  displayBarcodesCallback,
  type = 'MULTILINE'
) {
  return (dispatch) => {
    dispatch(getBarcodeRequestPending(true))
    barcodeApi
      .getBarcode(barcodeMapper.samplesToBarcodeRequest(samples), type)
      .then((barcodeResult) => {
        dispatch(getBarcodeRequestSuccess(barcodeResult.data))
        displayBarcodesCallback(barcodeResult.data)
      })
      .catch(() => {
        dispatch(getBarcodeRequestFailure())
      })
      .finally(() => {
        dispatch(getBarcodeRequestPending(true))
      })
  }
}

export function getBarcodesProps(
  samples = [],
  displayBarcodesCallback,
  type = 'MULTILINE',
  format = ''
) {
  return (dispatch) => {
    dispatch(getBarcodeRequestPending(true))
    barcodeApi
      .getBarcodeProps(
        barcodeMapper.propsToBarcodeRequest(samples),
        type,
        format
      )
      .then((barcodeResult) => {
        dispatch(getBarcodeRequestSuccess(barcodeResult.data))
        displayBarcodesCallback(barcodeResult.data)
      })
      .catch(() => {
        dispatch(getBarcodeRequestFailure())
      })
      .finally(() => {
        dispatch(getBarcodeRequestPending(true))
      })
  }
}
export function getBarcodesScenic(
  samples = [],
  displayBarcodesCallback,
  type = 'MULTILINE',
  format = ''
) {
  return (dispatch) => {
    dispatch(getBarcodeRequestPending(true))
    barcodeApi
      .getBarcodeScenic(
        barcodeMapper.propsToBarcodeRequest(samples),
        type,
        format
      )
      .then((barcodeResult) => {
        dispatch(getBarcodeRequestSuccess(barcodeResult.data))
        displayBarcodesCallback(barcodeResult.data)
      })
      .catch(() => {
        dispatch(getBarcodeRequestFailure())
      })
      .finally(() => {
        dispatch(getBarcodeRequestPending(true))
      })
  }
}

// Creates the initial shipment record in the db when moving from the Samples Summary page to the
// barcode page
export function submitShipment(data, callBack = () => {}) {
  return (dispatch) => {
    dispatch(submitShipmentRequestPending(true))
    shipmentApi
      .submitShipment(shipmentMapper.shipmentToShipmentRequest(data))
      .then((result) => {
        let mappedData = shipmentMapper.shipmentResponseToShipment(result.data)
        dispatch(submittedShipment(mappedData))
        // dispatch(submitShipmentRequestSuccess(mappedData))
        callBack()
      })
      .catch(() => {
        dispatch(submitShipmentRequestFailure())
        dispatch(
          showNotification(true, 'We were unable to process that shipment')
        )
      })
      .finally(() => {
        dispatch(submitShipmentRequestPending(false))
      })
  }
}

export function submitShipmentRequestPending(pending = false) {
  return {
    type: types.SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export function submitShipmentRequestFailure() {
  return { type: types.SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_FAILURE }
}

export function submitShipmentRequestSuccess(data = []) {
  return {
    type: types.SHIPMENT_BARCODE_SUBMIT_ORDER_REQUEST_SUCCESS,
    payload: { data: data },
  }
}
