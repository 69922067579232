import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ShowIf from 'components/ShowIf'

function ShipmentMarketing({
  marketingList,
  addNewMarketingPid,
  showMaketingPidInput,
  updateMarketingFieldVisiblity,
  pidData,
}) {
  return (
    <>
      <span
        style={{
          fontSize: '14px',
          color: '#333333',
          padding: '8px',
        }}
      >
        Marketing PID
      </span>
      <Grid container>
        <ShowIf
          condition={!showMaketingPidInput && pidData && pidData.length > 0}
        >
          <Grid item xs={pidData?.length}>
            {marketingList}
          </Grid>
        </ShowIf>
        <ShowIf condition={!showMaketingPidInput}>
          <Grid
            item
            xs={2}
            style={{
              paddingLeft: `${pidData?.length * 3 || 8}px`,
              paddingTop: '8px',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => updateMarketingFieldVisiblity(true)}
            >
              Add Marketing PID
            </Button>
          </Grid>
        </ShowIf>
        <ShowIf condition={showMaketingPidInput}>
          <Grid
            item
            xs={6}
            style={{
              paddingTop: '16px',
            }}
          >
            {addNewMarketingPid}
          </Grid>
        </ShowIf>
      </Grid>
    </>
  )
}

export default ShipmentMarketing
