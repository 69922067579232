import { createStore, applyMiddleware } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from './index'
import thunk from 'redux-thunk'

export const middleware = [thunk]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(
    reduxImmutableStateInvariant({
      ignore: [
        'search.data',
        'order.data',
        'orderSummary.selected',
        'myOrder.data',
        'myOrder.filteredData',
        'myOrder.selectedData',
        'myOrder.item',
        'myOrder.updateData',
      ],
    })
  )
}

export default function configureStore(preloadedState = {}) {
  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware))
  )
}
