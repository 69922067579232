import { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import * as propTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core'

const styles = (theme) => ({
  paper: {},
  dialogActions: {
    padding: '16px 24px',
  },
  dialogActionClose: {
    padding: '12px',
  },
})

class DialogBox extends PureComponent {
  handleOpen = () => {
    const { onOpen } = this.props
    onOpen()
  }

  handleClose = () => {
    const { onClose } = this.props
    onClose()
  }

  handleAgree = () => {
    const { onAgree, items, onClose, closeOnAgree } = this.props
    onAgree(items)
    if (closeOnAgree) onClose(items)
  }

  render() {
    const {
      isAlertVisible,
      title = 'ALERT',
      message,
      response,
      primaryButtonText = 'AGREE',
      secondaryButtonText = 'CANCEL',
      closeButtonText = 'CLOSE',
      scroll = 'paper',
      maxWidth = 'xs',
      fullWidth = false,
      onAgree,
      classes = {},
    } = this.props

    return (
      <div>
        <Dialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          scroll={scroll}
          open={isAlertVisible}
          onClose={this.handleClose}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          classes={{
            paper: classes.paper,
          }}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{message}</DialogContent>
          {response ? (
            <DialogActions className={classes.dialogActions}>
              <Button
                id="secondaryButtonText"
                onClick={this.handleClose}
                color="primary"
                variant="outlined"
              >
                {secondaryButtonText}
              </Button>
              <Button
                id="handleAgree"
                data-cy="handleAgree"
                onClick={this.handleAgree}
                color="primary"
                variant="contained"
                disabled={!onAgree}
              >
                {primaryButtonText}
              </Button>
            </DialogActions>
          ) : (
            <DialogActions className={classes.dialogActionClose}>
              <Button onClick={this.handleClose} color="primary">
                {closeButtonText}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
    )
  }
}

DialogBox.defaultProps = {
  alertMessage: '',
  handleAgree: () => {},
  items: [],
  isAlertVisible: false,
  response: false,
  onAgree: undefined,
  onClose: () => {},
}

DialogBox.propTypes = {
  alertMessage: propTypes.string,
  handleAgree: propTypes.func,
  isAlertVisible: propTypes.bool,
  items: propTypes.array,
  response: propTypes.bool,
  showAlert: propTypes.func,
  onAgree: propTypes.func,
  onClose: propTypes.func,
}

export default withStyles(styles)(DialogBox)
